import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  RowStyled,
  FormGroupStyled,
  TableStyled,
  InputStyled,
} from '~/Components/Styled';

import CheckboxComponent from '~/Components/Checkbox';

export const Container = styled.div``;

export const Checkbox = styled(CheckboxComponent).attrs({
  style: {
    marginTop: -10,
    display: 'inline',
  },
})``;

export const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  flex: 1;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  padding: 20px;
  button {
    margin: 0;
  }
`;

export const RowPagination = styled(RowStyled)`
  margin: 0;
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex: 1;
  margin: 0;
`;

export const Table = styled(TableStyled)`
  width: 1160px;
  table-layout: fixed;
  border-collapse: collapse;

  thead {
    tr {
      display: block;
      th {
        white-space: nowrap;
      }
      th[align="center"] {
        text-align: center !important;
      }
    }
  }

  tbody {
    display:block;
    width: 100%;
    overflow: auto;
    max-height: 400px;
    tr {
      display: block;
      td {
        padding: 0 5px;
        height: 40px;
        span.nowrap {
          white-space: nowrap;
        }
        span.auctionModified {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          background-color: ${props => props.theme.colors.warning};
          color: ${props => props.theme.colors.darker};
          margin-left: 5px;
          padding: 0 15px;
          height: 30px;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
`;

export const TableButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      margin-left: 0;
    }
    svg {
      font-size: 20px;
      margin: 0;
    }
  }
`;

export const Select = styled(ReactSelect)`
  width: 200px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const HeaderForm = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
`;

export const HeaderFormInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    font-size: 16px;
    margin-right: 10px;
      margin-left: 10px;
  }
  &:last-child {
    margin-bottom: 0;
  }
  &:first-child {
    label {
      font-size: 16px;
      margin-left: 0;
    }
  }
`;

export const HeaderFormButton = styled.div`
  display: flex;
  flex-direction: row-reverse;
`;

export const HeaderFormGroup = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  span {
    margin: 0 10px;
  }
`;

export const InputFilter = styled(InputStyled)`
  width: 100px;
  margin: 0;
`;

export const LinkButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border: 1px solid;
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 0;
  padding-left: 10px;
  padding-right: 10px;
  ${props => props.disabled && css`
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  `};
`;

export const ButtonGroup = styled.div`
  display: flex;
  margin-left: 10px;
  > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      margin: 0;
    }
  }
`;
