import api from './axios';

const pointsManagementGeoPointsRequest = async () => {
  try {
    const { data } = await api.get('mcare-artemis-audience-service/geopoints');
    return data;
  } catch (error) {
    if (!error.response) throw error;
    throw error.response.data ? error.response.data : error.response;
  }
};

const pointsManagementGeoPointsIconRequest = async () => {
  try {
    const { data } = await api.get('mcare-artemis-audience-service/geopoints/icon');
    return data;
  } catch (error) {
    if (!error.response) throw error;
    throw error.response.data ? error.response.data : error.response;
  }
};

const pointsManagementGeoPointsIconSave = async (iconPoint) => {
  const form = new FormData();
  form.append('file', iconPoint);
  try {
    const { data } = await api.put('mcare-artemis-audience-service/geopoints/icon', form);
    return data;
  } catch (error) {
    if (!error.response) throw error;
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  pointsManagementGeoPointsRequest,
  pointsManagementGeoPointsIconRequest,
  pointsManagementGeoPointsIconSave,
};
