import styled from 'styled-components';

import { RowStyled } from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const LogBody = styled.div`
  max-height: calc(100vh - 140px);
  overflow-y: scroll;
  margin-right: -15px;
  padding-right: 15px;
`;

export const LogContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .left {
    width: 60px;
    flex: 1;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon-circle {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.primary};

      img {
        max-width: 25px;
      }
    }

    .line {
      width: 5px;
      background-color: ${props => props.theme.colors.primary};
      border-radius: 4px;
      flex: 1;
      display: flex;
    }
  }
  .right {
    flex: 4;
    .content-log {
      background-color: ${props => props.theme.colors.lighterWhite};
      border-radius: 2px;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 15px;
      margin-left: -15px;
    }
    .status {
      height: 60px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        &.label {
          font-size: 14px;
          color: ${props => props.theme.colors.dark};
          padding: 0;
        }
        &.desc {
          font-weight: 200;
          font-size: 18px;
          color: ${props => props.theme.colors.darker};
        }
        &.desc-bold {
          font-weight: bold;
          font-size: 18px;
          color: ${props => props.theme.colors.darker};
        }
      }
    }

    .content-log-info {
      margin-bottom: 0;
    }

    .content-log-justify {
      margin-top: 20px;
      min-height: 35px;
    }
  }
`;

export const CloseModal = styled.img`
  cursor: pointer;
  width: 20px;
`;
