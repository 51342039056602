/* Types */
export const Types = {
  LOAD_DEMO_LIST: 'demo/LOAD_DEMO_LIST',
  LOAD_DEMO_LIST_SUCCESS: 'demo/LOAD_DEMO_LIST_SUCCESS',
  ADD_NEW_TERMINAL_DEMO: 'demo/ADD_NEW_TERMINAL_DEMO',
  REMOVE_TERMINAL_DEMO: 'demo/REMOVE_TERMINAL_DEMO',
  SAVE_DEMO_LIST: 'demo/SAVE_DEMO_LIST',
  UPDATE_DEMO: 'demo/UPDATE_DEMO',
};

const INITIAL_STATE = {
  data: [],
};

export default function demo(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD_DEMO_LIST:
      return {
        ...state,
        data: [],
      };
    case Types.LOAD_DEMO_LIST_SUCCESS:
      return {
        ...state,
        data: [...action.payload],
      };
    case Types.UPDATE_DEMO:
      return {
        ...state,
        data: state.data.map(item => (item.id === action.payload.id ? action.payload : item)),
      };
    default:
      return state;
  }
}

/* Actions Creators */
export const Creators = {
  loadDemoList: () => ({
    type: Types.LOAD_DEMO_LIST,
  }),
  loadDemoListSuccess: demoList => ({
    type: Types.LOAD_DEMO_LIST_SUCCESS,
    payload: demoList,
  }),
  addNewTerminalDemo: () => ({
    type: Types.ADD_NEW_TERMINAL_DEMO,
  }),
  removeTerminalDemo: idItem => ({
    type: Types.REMOVE_TERMINAL_DEMO,
    payload: idItem,
  }),
  saveDemoList: () => ({
    type: Types.SAVE_DEMO_LIST,
  }),
  updateDemo: demoUpdate => ({
    type: Types.UPDATE_DEMO,
    payload: demoUpdate,
  }),
};
