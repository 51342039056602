import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

import { FormGroupStyled, InputMaskStyled } from '~/Components/Styled';

export const Container = styled.div`
  flex: 1;
  border: 2px solid ${props => props.theme.colors.light};
  border-radius: 3px;
  padding: 15px;
  margin-bottom: 10px;

  ${props => props.error && css`
    border-color: ${props.theme.colors.danger2};
  `}
`;

export const AnswerHeader = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  margin-bottom: 5px;

  > span {
    font-size: 18px;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    padding-left: 5px;
    padding-right: 5px;

    :first-child {
      width: 90px;
    }
  }

`;

export const EditText = styled.input`
  flex: 1;
  color: ${props => (props.color ? props.color : props.theme.colors.primary)};
  font-size: 24px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1px 5px;
  border: 2px solid ${props => props.theme.colors.light};
  border-radius: 4px;
  outline: none;
  opacity: 1;

  ${props => props.invalid && css`
    border-color: ${props.theme.colors.danger2};
  `};
  ${props => props.disabled && css`
    opacity: 0.7;
  `};
`;

export const Answers = styled.div`
  flex: 1;
  margin-top: 10px;
`;

export const Answer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-top: 10px;
`;

export const AnswerCheckCorrect = styled.div`
  width: 90px;
  display: flex;
  justify-content: center;
  margin-top: -15px;
`;

export const AddAnswer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  margin-top: 10px;

  > a {
    color: ${props => props.theme.colors.primary};
    font-size: 18px;
    font-weight: bold;

    > span {
      padding-left: 5px;
      color: ${props => props.theme.colors.primary};
    }
  }

  ${props => props.disabled && css`
    opacity: 0.7;
    > a {
      text-decoration: none;
      cursor: default;
    }
  `}
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  label {
    font-size: 18px;
  }
`;

export const FieldWithLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    margin: 0;
  }
  input {
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > label {
    font-size: 16px;
  }
`;

export const WrapActionButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 30px;
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.color};
  background-color: ${props => props.theme.colors.white};
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-left: 5px;
  outline: none;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  svg {
    font-size: 14px;
    color: ${props => props.color};
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  flex: 1;
  margin-right: 0;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
  input {
    margin: 0;
  }
`;

export const StarItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
  margin-right: 10px;
  &:last-child {
    margin-right: 0px;
  }
  input {
    margin: 0;
    text-align: center;
    width: 100px;
  }
  svg {
    color: ${props => props.theme.colors.primary};
    font-size: 25px;
    margin-bottom: 5px;
  }
`;

export const Select = styled(ReactSelect).attrs({
  className: 'input-select-custom',
  classNamePrefix: 'input-select-custom',
})`
  width: 100%;
  > div {
    border-width: 2px !important;
  }
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
  .input-select-custom__control,
  .input-select-custom__control:hover,
  .input-select-custom__control--is-focused,
  .input-select-custom__control--menu-is-open {
    border-color: ${props => props.theme.colors.light} !important;
  }
`;

export const InputMask = styled(InputMaskStyled)`
  margin: 0;
  outline: none;
  height: 40px;
`;
