import React from 'react';
import PropTypes from 'prop-types';

import Const from '~/Utils/Const';
import { Container } from './styles';

const SMSTextMessagePreview = ({ data: { content } }) => {
  const getValueMessage = () => {
    if (content && content.channelData && content.channelData.message) {
      const { message, url } = content.channelData;
      const isExistsLink = message.indexOf(Const.TAG_LINK_INCLUDE) >= 0;
      if (!isExistsLink) {
        return <span>{message}</span>;
      }
      const firstIndexLink = message.indexOf(Const.TAG_LINK_INCLUDE);
      const lastIndexLink = firstIndexLink + Const.TAG_LINK_INCLUDE.length;
      return (
        <>
          <span>{message.substring(0, firstIndexLink)}</span>
          <a
            href={url.indexOf('http') === 0 ? url : `http://${content.channelData.url}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {Const.TAG_LINK_INCLUDE}
          </a>
          <span>{message.substring(lastIndexLink, message.length)}</span>
        </>
      );
    }
    return '';
  };

  return (
    <Container>
      <label>Mensagem </label>
      <span className="dot-now">
        <span className="dot">&#183;</span>
              agora
      </span>
      <p>
        {getValueMessage()}
      </p>
      {/* {content && content.channelData && content.channelData.url && (
        <div className="base-button">
          <a href={content.channelData.url.indexOf('http') === 0 ? content.channelData.url : `http://${content.channelData.url}`} target="_blank">Abrir link SMS</a>
        </div>
      )} */}
    </Container>
  );
};

SMSTextMessagePreview.defaultProps = {
  data: {
    content: null,
  },
};

SMSTextMessagePreview.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape().isRequired,
  }),
};

export default SMSTextMessagePreview;
