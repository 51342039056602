/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';

import { withTheme } from 'styled-components';
import {
  FaBell, FaExclamationTriangle, FaMinusCircle, FaPencilAlt,
} from 'react-icons/fa';
import { general } from '~/Styles';
import { Creators as CampaignActions } from '~/redux/ducks/campaign';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';
import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import { Creators as RedirectActions } from '~/redux/ducks/redirect';

import CampaignButtonsActions from '../Components/CampaignActions';
import CampaignsBoxContent from './Components/CampaignsBoxContent';
import Breadcrumb from '~/Components/Breadcrumb';
import Button from '~/Components/Button';
import IncludeHasPermission from '~/Components/IncludeHasPermission';

import ModalEditOrcamento from './Components/ModalEditOrcamento';
import ModalEditCriativo from './Components/ModalEditCriativo';
import ModalEditPeriodo from './Components/ModalEditPeriodo';
import ModalEditBriefing from './Components/ModalEditBriefing';
import ModalEditBeneficio from './Components/ModalEditBeneficio';
import ModalEditCampaignAttribute from './Components/ModalEditCampaignAttribute';
import ModalEditEstimativaImpacto from './Components/ModalEditEstimativaImpacto';
import ModalEditEstrategiaEntrega from './Components/ModalEditEstrategiaEntrega';

import PreviewCriativo from './Components/PreviewCriativo';
import ScreenContainer from './Components/ScreenContainer';

import { BoxContentItem } from './Components/ScreenContainer/styles';

import {
  convertFloatReal, replaceAll, normalizeDecimal, getBudgetLabelString,
} from '~/Utils';

import {
  Container,
  Header,
  HeaderTitle,
  HeaderButtons,
  Row,
  BaseLinkHistoryCampaign,
  LinkHistoryCampaign,
  Content,
  CampaignData,
  CampaignDataColumn,
  CampaignDataPreview,
  RowScreensContainer,
  MessageNoContent,
  ItemAtributoVisualizacao,
  ItemAcompanhamentoStatus,
  Table,
} from './styles';
import CreativeName from '~/Utils/CreativeName';
import ModalCloneCampanha from './Components/ModalCloneCampanha';
import { ButtonCopyClipboard } from './Components/ButtonCopyClipboard';
import ModalEditCarrotPackage from './Components/ModalEditCarrotPackage';

const STATUS_EDIT_UUID = '2956c79e-8eeb-4523-9c2d-04fc7cc161a0';

class Form extends Component {
  handleCloneCampanha = () => {
    const { campaign, campaignSetClone } = this.props;
    campaignSetClone({
      uuid: campaign.uuid,
      name: `Cópia - ${campaign.name}`,
    });
  };

  renderDadosGerais = () => {
    const { campaign, hasCampaignOfferKey } = this.props;

    const getProductName = () => campaign.productOffers.map(item => `${item.product.name} - ${item.name}`).join(', ');
    const getKeywords = () => campaign.productOffers.map(item => item.CampaignProductOffer.keyword).join(', ');
    const getOfferkeys = () => campaign.productOffers.filter(item => item.extra && item.extra.key).map(item => item.extra.key).join(', ');

    const getGeneralDataItems1 = () => {
      const uuidTenant = localStorage.getItem('uuidTenant');
      const generalData = [
        {
          title: 'Nome',
          value: campaign.name,
          visible: true,
          flex: 1,
        },
      ];

      if (
        uuidTenant !== '27e9610a-6d78-4ddd-af88-003eb8cb9b40'
        && uuidTenant !== '2563fe93-87c1-494e-983e-509bb03e4ade'
      ) {
        generalData.push({
          title: 'Produto - Oferta',
          value: getProductName(),
          visible: true,
          flex: 1,
        });
      } else {
        generalData.push({
          title: 'Keyword',
          value: getKeywords(),
          visible: true,
          flex: 1,
        });
      }
      if (hasCampaignOfferKey) {
        generalData.push({
          title: 'Offerkey',
          value: getOfferkeys(),
          visible: true,
          flex: 1,
        });
      }
      return generalData;
    };

    const getGeneralDataItems2 = () => {
      const uuidTenant = localStorage.getItem('uuidTenant');
      const generalData = [];

      if (
        uuidTenant !== '27e9610a-6d78-4ddd-af88-003eb8cb9b40'
        && uuidTenant !== '2563fe93-87c1-494e-983e-509bb03e4ade'
      ) {
        generalData.push({
          title: 'Keyword',
          value: getKeywords(),
          visible: true,
          flex: 1,
        });
      }

      generalData.push({
        title: 'Tipo de Campanha',
        value: campaign.campaignType.label,
        visible: true,
        flex: 1,
      });
      generalData.push({
        title: 'Status',
        value: campaign.campaignStatus.label,
        visible: true,
        flex: 1,
      });
      generalData.push({
        title: 'Modalidade',
        value: campaign.zone.description,
        visible: true,
        flex: 1,
      });
      return generalData;
    };

    return (
      <CampaignsBoxContent
        title="Dados Gerais"
        items={getGeneralDataItems1()}
        items2={getGeneralDataItems2()}
        items3={[
          {
            title: 'Descrição',
            value: campaign.description || 'Não definido',
            visible: true,
            flex: 1,
          },
        ]}
      />
    );
  };

  validateDisabledStartDate = ({
    zone,
    campaignStatus: { uuid: campaignStatusUuid },
  }) => {
    if (zone.channel.channelType.type) {
      if (campaignStatusUuid !== STATUS_EDIT_UUID) {
        return true;
      }
    }
    return false;
  };

  // name: "FIXED_CPA", label: "Campanha de CPA Fixo"
  // name: "AUCTION", label: "Campanha de Leilão"
  // name: "BONUS", label: "Campanha Bonificada"
  // name: "PROBE", label: "Campanha de Teste"

  renderOrcamento = () => {
    const {
      campaign,
      campaignEditChangeModalEditStatus,
      campaignEditSetOrcamentoToEdit,
    } = this.props;

    const cpaIsVisible = ['FIXED_CPA', 'AUCTION', 'PROBE']
      .includes(campaign.campaignType.name);
    const budgetIsVisible = ['AUCTION']
      .includes(campaign.campaignType.name);
    const volumeIsVisible = ['FIXED_CPA', 'PROBE']
      .includes(campaign.campaignType.name);

    return (
      <CampaignsBoxContent
        title="Orçamento"
        items={[
          {
            title: 'CPA',
            value: `R$ ${convertFloatReal(campaign.cpa)}`,
            visible: cpaIsVisible,
          },
          {
            title: 'Budget',
            value: getBudgetLabelString(campaign.budget),
            visible: budgetIsVisible,
          },
          {
            title: 'Volume',
            value: replaceAll(campaign.volume.toLocaleString(), ',', '.'),
            visible: volumeIsVisible,
          },
        ]}
        hasAction={campaign.screenSchema.budget.editable}
        action={() => {
          const objToedit = {
            uuid: campaign.uuid,
            extra: campaign.zone.extra || null,
          };
          if (cpaIsVisible) objToedit.cpa = campaign.cpa;
          if (budgetIsVisible) objToedit.budget = campaign.budget;
          if (volumeIsVisible) objToedit.volume = campaign.volume;

          campaignEditSetOrcamentoToEdit(objToedit);
          campaignEditChangeModalEditStatus({
            editOrcamentoStatus: true,
          });
        }}
      />
    );
  };

  renderPeriodo = () => {
    const {
      campaign,
      campaignEditChangeModalEditStatus,
      campaignEditSetPeriodoToEdit,
    } = this.props;

    const startDateFormat = campaign.startDate
      ? moment(campaign.startDate).utc().format('DD/MM/YYYY')
      : null;
    const startHourFormat = campaign.startDate
      ? moment(campaign.startDate).utc().format('HH:mm')
      : null;
    const endDateFormat = campaign.endDate
      ? moment(campaign.endDate).utc().format('DD/MM/YYYY')
      : null;
    const endHourFormat = campaign.endDate
      ? moment(campaign.endDate).utc().format('HH:mm')
      : null;

    return (
      <CampaignsBoxContent
        title="Período"
        items={[
          {
            title: 'Inicial',
            value: campaign.startDate
              ? `${startDateFormat} ${startHourFormat}`
              : 'Não definido',
            visible: true,
          },
          {
            title: 'Final',
            value: campaign.endDate
              ? `${endDateFormat} ${endHourFormat}`
              : 'Não definido',
            visible: true,
          },
        ]}
        hasAction={campaign.screenSchema.schedule.editable}
        action={() => {
          campaignEditSetPeriodoToEdit({
            uuid: campaign.uuid,
            startDate: startDateFormat,
            startHour: startHourFormat,
            endDate: endDateFormat,
            endHour: endHourFormat,
          });

          campaignEditChangeModalEditStatus({
            editPeriodoStatus: true,
            modeDisabledStartDate: this.validateDisabledStartDate(campaign),
          });
        }}
      />
    );
  };

  renderEstrategiaEntrega = () => {
    const {
      campaign,
      campaignEditChangeModalEditStatus,
      campaignEditSetEstrategiaEntregaToEdit,
    } = this.props;

    const getDeliveryStrategyLabel = () => {
      switch (campaign.deliveryStrategy) {
        case 'evenly': return 'Uniformemente';
        case 'asap': return 'O quanto antes';
        default: return 'Não definido';
      }
    };

    const getFrequencyPerUserPeriodLabel = () => {
      switch (campaign.frequencyPerUserPeriod) {
        case 'hour': return 'Hora';
        case 'day': return 'A cada 24 horas';
        case 'midnight': return 'Diário';
        case 'week': return 'Semana';
        case 'month': return 'Mês';
        case 'campaign_lifetime': return 'Sempre';
        default: return 'Não definido';
      }
    };

    const getDeliveryStrategyValue = () => {
      switch (campaign.deliveryStrategy) {
        case 'evenly': return 1;
        case 'asap': return 2;
        default: return null;
      }
    };

    const getFrequencyPerUserPeriodValue = () => {
      switch (campaign.frequencyPerUserPeriod) {
        case 'hour': return 1;
        case 'day': return 2;
        case 'midnight': return 5;
        case 'week': return 3;
        case 'month': return 4;
        case 'campaign_lifetime': return 5;
        default: return null;
      }
    };

    return (
      <CampaignsBoxContent
        title="Estratégia de Entrega"
        items={[
          {
            title: 'Prioridade',
            value: campaign.priority || 0,
            visible: true,
          },
          {
            title: 'Estratégia',
            value: getDeliveryStrategyLabel(),
            visible: true,
          },
          {
            title: 'Limite de Utilização por Usuário',
            value: campaign.frequencyPerUser ? campaign.frequencyPerUser : 'Ilimitado',
            visible: true,
          },
          {
            title: 'Período',
            value: getFrequencyPerUserPeriodLabel(),
            visible: true,
          },
        ]}
        hasAction={
          campaign.screenSchema.delivery_strategy
          && campaign.screenSchema.delivery_strategy.editable
        }
        action={() => {
          campaignEditSetEstrategiaEntregaToEdit({
            uuid: campaign.uuid,
            priority: campaign.priority,
            deliveryStrategy: getDeliveryStrategyValue(),
            hasFrequency: campaign.hasFrequency,
            frequencyPerUser: campaign.frequencyPerUser,
            frequencyPerUserPeriod: getFrequencyPerUserPeriodValue(),
          });
          campaignEditChangeModalEditStatus({
            editEstrategiaEntregaStatus: true,
          });
        }}
      />
    );
  };

  renderUrlCompartilhamentoTable = campaignShare => (
    <Table>
      <thead>
        <tr>
          <th>Canal</th>
          <th>Zona</th>
          <th>URL</th>
          <th className="table-column-center">Ação</th>
        </tr>
      </thead>
      <tbody>
        {campaignShare.length > 0
          && campaignShare.map(item => (
            item.zones.map(zone => (
              <tr>
                <td>{item.channelName}</td>
                <td>{zone.zoneName}</td>
                <td>{zone.link}</td>
                <td width={180} className="table-column-center">
                  <ButtonCopyClipboard text={zone.link} />
                </td>
              </tr>
            ))
          ))
        }
      </tbody>
    </Table>
  );

  renderUrlCompartilhamento = () => {
    const {
      campaign,
      campaignShare,
    } = this.props;

    return (
      <>
        {
          (
            campaign.screenSchema
            && campaign.screenSchema['campaign-share']
            && campaign.screenSchema['campaign-share'].visible
          )
          && (
            <CampaignsBoxContent
              title="URL de compartilhamento"
              children={this.renderUrlCompartilhamentoTable(campaignShare)}
            />
          )
        }
      </>
    );
  };

  renderBriefing = () => {
    const {
      campaign,
      campaignEditSetBriefingToEdit,
      campaignEditBriefingDownload,
      campaignEditChangeModalEditStatus,
    } = this.props;
    const { screenSchema } = campaign;
    return (
      <ScreenContainer
        title="Briefing"
        schema={screenSchema.briefing}
        action={() => {
          campaignEditSetBriefingToEdit({
            uuid: campaign.uuid,
            templateId: campaign.templateId,
          });
          campaignEditChangeModalEditStatus({
            editBriefingStatus: true,
          });
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <BoxContentItem>
            <b>Template Id</b>
            <span>{campaign.templateId || 'Não definido'}</span>
          </BoxContentItem>
          <BoxContentItem>
            <Button
              icon="FaDownload"
              noLabel
              bordered
              onClick={() => {
                campaignEditBriefingDownload(campaign.uuid);
              }}
            />
          </BoxContentItem>
        </div>
      </ScreenContainer>
    );
  };

  renderPreview = () => {
    const { criativoEdit } = this.props;
    return (
      <PreviewCriativo data={criativoEdit} />
    );
  };

  renderCriativo = () => {
    const {
      campaign,
      navigateToRoute,
      mode,
    } = this.props;
    return (
      <CampaignsBoxContent
        title="Criativo"
        hasAction
        actionTitle={campaign.screenSchema.creative.editable ? 'Editar' : 'Visualizar'}
        action={() => {
          navigateToRoute(
            mode === 'APPROVAL'
              ? `/campaigns/approval/${campaign.uuid}/creative`
              : `/campaigns/edit/${campaign.uuid}/creative`,
          );
        }}
      >
        {this.renderPreview()}
      </CampaignsBoxContent>
    );
  };

  renderEstimativaImpacto = () => {
    const {
      campaign,
      campaignEditChangeModalEditStatus,
      campaignEditSetImpactSettings,
    } = this.props;

    const getEstimatedCost = () => {
      if (
        campaign
        && campaign.volume
        && campaign.cpm
      ) {
        return parseFloat(campaign.cpm * campaign.volume) / 1000;
      }
      return 0;
    };

    return (
      <CampaignsBoxContent
        title="Estimativa de impacto"
        items={[
          {
            title: 'Audiência selecionada',
            value: campaign.audience ? campaign.audience.name : 'Não informado',
            visible: true,
          },
          {
            title: 'Audiência excluída',
            value: campaign.purgeAudience ? campaign.purgeAudience.name : 'Não informado',
            visible: true,
          },
          {
            title: 'Impacto estimado',
            value: campaign.estimatedAudience.toLocaleString(),
            visible: true,
          },
          {
            title: 'Impacto Contratado',
            value: campaign.volume.toLocaleString(),
            visible: true,
          },
          {
            title: 'Custo total estimado',
            value: normalizeDecimal(getEstimatedCost()),
            visible: true,
          },
        ]}
        hasAction={campaign.screenSchema.audience.editable}
        action={() => {
          campaignEditSetImpactSettings({
            uuid: campaign.uuid,
            volume: campaign.volume || 0,
            cpm: campaign.cpm || 0,
            purgeAudience: campaign.purgeAudience ? {
              audienceUuid: campaign.purgeAudience.uuid,
            } : null,
            estimatedAudience: campaign.estimatedAudience || 0,
            audienceUuid: campaign.audience ? campaign.audience.uuid : null,
          });
          campaignEditChangeModalEditStatus({
            editImpactEstimateStatus: true,
          });
        }}
      />
    );
  };

  renderBeneficio = () => {
    const {
      campaign,
      campaignEditChangeModalEditStatus,
      campaignEditBenefitSetList,
      campaignEditLoadMediaEventList,
    } = this.props;

    const getGroupBenefits = () => {
      let benefits = [];

      campaign.benefitOffers.forEach((item) => {
        if (item && item.CampaignBenefitOffer) {
          const hasBenefitInList = benefits.find(
            bene => bene.uuid === item.benefit.uuid,
          );

          if (hasBenefitInList) {
            benefits = benefits.map((bene) => {
              if (bene.uuid === item.benefit.uuid) {
                return {
                  ...bene,
                  offers: [
                    ...bene.offers,
                    {
                      id: item.uuid,
                      label: item.name,
                      type: item.CampaignBenefitOffer
                        && item.CampaignBenefitOffer.mediaEvent
                        && item.CampaignBenefitOffer.mediaEvent.description
                        ? item.CampaignBenefitOffer.mediaEvent.description
                        : item.description,
                    },
                  ],
                };
              }
              return bene;
            });
          } else {
            benefits.push({
              uuid: item.benefit.uuid,
              benefit: item.benefit.name,
              offers: [
                {
                  id: item.uuid,
                  label: item.name,
                  type: item.CampaignBenefitOffer
                    && item.CampaignBenefitOffer.mediaEvent
                    && item.CampaignBenefitOffer.mediaEvent.description
                    ? item.CampaignBenefitOffer.mediaEvent.description
                    : item.description,
                },
              ],
            });
          }
        }
      });
      return benefits;
    };

    const groupBenefits = getGroupBenefits();
    const getItemsBenefit = () => {
      let itemFlexWidth = 1;
      switch (groupBenefits.length) {
        case 1: {
          itemFlexWidth = 'none';
          break;
        }
        case 2: {
          itemFlexWidth = 0.5;
          break;
        }
        case 3: {
          itemFlexWidth = 0.33;
          break;
        }
        default:
          itemFlexWidth = 0.4;
      }

      if (groupBenefits.length > 3) {
        return [
          {
            title: groupBenefits[0].benefit,
            value: groupBenefits[0].offers,
            visible: true,
            flex: 0.37,
          },
          {
            title: groupBenefits[1].benefit,
            value: groupBenefits[1].offers,
            visible: true,
            flex: 0.37,
          },
          {
            title: 'Outros',
            value: [
              {
                id: 'outros',
                label: `+ ${groupBenefits.length - 2} Benefício(s)`,
                type: null,
              },
            ],
            visible: true,
            flex: 0.25,
          },
        ];
      }

      return groupBenefits.map(item => ({
        title: item.benefit,
        value: item.offers,
        visible: true,
        flex: itemFlexWidth,
      }));
    };

    return (
      <CampaignsBoxContent
        title="Benefício"
        items={[]}
        hasAction={campaign.screenSchema.benefit.editable}
        action={() => {
          campaignEditLoadMediaEventList();
          campaignEditBenefitSetList(
            campaign.benefitOffers.filter(item => item.CampaignBenefitOffer),
          );
          campaignEditChangeModalEditStatus({
            editBenefitStatus: true,
          });
        }}
      >
        {groupBenefits.length < 1 ? (
          <MessageNoContent>
            Sem benefício configurado
          </MessageNoContent>
        ) : (
          <div className="benefit-box-items">
            {getItemsBenefit().map((itm, idx) => (
              <div className="benefit-box-item" key={idx} style={{ flex: itm.flex || 'none' }}>
                <b>{itm.title}</b>
                <div className="benefit-box-item-content">
                  {itm.value.map((wrap, indexWrap) => (
                    <>
                      <div className="benefit-box-item-wrap" key={wrap.id}>
                        {!!wrap.type && (
                          <span className="benefit-box-item-wrap-type">
                            {wrap.type}
                          </span>
                        )}
                        <span
                          className="benefit-box-item-wrap-value"
                          style={{ borderLeft: wrap.type ? 0 : 3 }}
                        >
                          {wrap.label}
                        </span>
                      </div>
                      {indexWrap < (itm.value.length - 1) && (
                        <div className="benefit-box-item-separator">+</div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </CampaignsBoxContent>
    );
  };

  renderCarrotPackage = () => {
    const {
      campaign,
      campaignEditChangeModalEditStatus,
      campaignEditCarrotPackageSetList,
    } = this.props;

    const getGroupCarrotPackage = () => {
      let carrotPackages = [];

      campaign.benefitOffers.forEach((item) => {
        if (item && item.CampaignCarrotPackage && item.CampaignCarrotPackage.length) {
          const hasBenefitInList = carrotPackages.find(
            bene => bene.uuid === item.benefit.uuid,
          );

          if (hasBenefitInList) {
            carrotPackages = carrotPackages.map((bene) => {
              if (bene.uuid === item.benefit.uuid) {
                return {
                  ...bene,
                  offers: [
                    ...bene.offers,
                    {
                      id: item.uuid,
                      label: item.name,
                      type: item.CampaignBenefitOffer
                        && item.CampaignBenefitOffer.mediaEvent
                        && item.CampaignBenefitOffer.mediaEvent.description
                        ? item.CampaignBenefitOffer.mediaEvent.description
                        : item.description,
                    },
                  ],
                };
              }
              return bene;
            });
          } else {
            carrotPackages.push({
              uuid: item.benefit.uuid,
              benefit: item.benefit.name,
              offers: [
                {
                  id: item.uuid,
                  label: item.name,
                  type: item.CampaignBenefitOffer
                    && item.CampaignBenefitOffer.mediaEvent
                    && item.CampaignBenefitOffer.mediaEvent.description
                    ? item.CampaignBenefitOffer.mediaEvent.description
                    : item.description,
                },
              ],
            });
          }
        }
      });
      return carrotPackages;
    };

    const groupsCarrotPackage = getGroupCarrotPackage();
    const getItemsCarrotPackage = () => {
      let itemFlexWidth = 1;
      switch (groupsCarrotPackage.length) {
        case 1: {
          itemFlexWidth = 'none';
          break;
        }
        case 2: {
          itemFlexWidth = 0.5;
          break;
        }
        case 3: {
          itemFlexWidth = 0.33;
          break;
        }
        default:
          itemFlexWidth = 0.4;
      }

      if (groupsCarrotPackage.length > 3) {
        return [
          {
            title: groupsCarrotPackage[0].benefit,
            value: groupsCarrotPackage[0].offers,
            visible: true,
            flex: 0.37,
          },
          {
            title: groupsCarrotPackage[1].benefit,
            value: groupsCarrotPackage[1].offers,
            visible: true,
            flex: 0.37,
          },
          {
            title: 'Outros',
            value: [
              {
                id: 'outros',
                label: `+ ${groupsCarrotPackage.length - 2} Benefício(s)`,
                type: null,
              },
            ],
            visible: true,
            flex: 0.25,
          },
        ];
      }

      return groupsCarrotPackage.map(item => ({
        title: item.benefit,
        value: item.offers,
        visible: true,
        flex: itemFlexWidth,
      }));
    };

    return (
      <CampaignsBoxContent
        title="Pacote Cenoura"
        items={[]}
        hasAction
        action={() => {
          campaignEditCarrotPackageSetList(
            campaign.benefitOffers.filter(item => item.CampaignCarrotPackage
              && item.CampaignCarrotPackage.length),
          );
          campaignEditChangeModalEditStatus({
            editCarrotPackageStatus: true,
          });
        }}
      >
        {groupsCarrotPackage.length < 1 ? (
          <MessageNoContent>
            Sem pacote cenoura configurado
          </MessageNoContent>
        ) : (
          <div className="benefit-box-items">
            {getItemsCarrotPackage().map((itm, idx) => (
              <div className="benefit-box-item" key={idx} style={{ flex: itm.flex || 'none' }}>
                <b>{itm.title}</b>
                <div className="benefit-box-item-content">
                  {itm.value.map((wrap, indexWrap) => (
                    <>
                      <div className="benefit-box-item-wrap" key={wrap.id}>
                        {!!wrap.type && (
                          <span className="benefit-box-item-wrap-type">
                            {wrap.type}
                          </span>
                        )}
                        <span
                          className="benefit-box-item-wrap-value"
                          style={{ borderLeft: wrap.type ? 0 : 3 }}
                        >
                          {wrap.label}
                        </span>
                      </div>
                      {indexWrap < (itm.value.length - 1) && (
                        <div className="benefit-box-item-separator">+</div>
                      )}
                    </>
                  ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </CampaignsBoxContent>
    );
  };

  validateShowBtnResultado = () => {
    const { criativoEdit } = this.props;
    return criativoEdit
      && (
        criativoEdit.creativeDefinition.name === CreativeName.SMS_TEXT_MESSAGE
        || criativoEdit.creativeDefinition.name === CreativeName.SMS_MEDIA_MESSAGE
        || criativoEdit.creativeDefinition.name === CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE
        || criativoEdit.creativeDefinition.name === CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE
        || criativoEdit.creativeDefinition.name === CreativeName.MIDIA_NO_CREDIT_AD
        || criativoEdit.creativeDefinition.name === CreativeName.CREATIVE_CUSTOM
        || criativoEdit.creativeDefinition.name === CreativeName.SPONSOR_NOTIFY_MEDIA
        || criativoEdit.creativeDefinition.name === CreativeName.SPONSOR_NOTIFY_TEXT
      );
  }

  renderButtonIncludeProbeGrid = () => {
    const {
      campaign: { includeProbeGrid, uuid, name },
      includeToProbeGrid,
      confirmModal,
    } = this.props;
    if (!includeProbeGrid.visible) return null;

    return (
      <div data-tip={includeProbeGrid.message} data-for="include-probe-grid" style={{ display: 'inline-block', height: 'fit-content' }}>
        <Button
          bordered
          title="Enviar para teste"
          type="button"
          disabled={!includeProbeGrid.enabled}
          icon={!includeProbeGrid.enabled && !!includeProbeGrid.message ? 'FaInfoCircle' : ''}
          onClick={() => {
            confirmModal(
              'Deseja enviar a campanha para teste?',
              `${name}`,
              () => {
                includeToProbeGrid(uuid);
              },
            );
          }}
        />
        {!!includeProbeGrid.message && (
          <ReactTooltip
            place="bottom"
            effect="solid"
            id="include-probe-grid"
          />
        )}
      </div>
    );
  };

  renderHeader = () => {
    const {
      mode,
      campaign,
      openHistoryCampaignModal,
      transitionState,
      setTransitionState,
      workflowStatus,
      navigateToRoute,
      executeTestAudience,
      hasDisabledCloneCampaign,
    } = this.props;

    return (
      <Header className="campaign-header">
        <HeaderTitle>
          <Breadcrumb
            items={[
              {
                route: '/',
                name: 'Home',
              },
              {
                route: mode === 'APPROVAL' ? '/campaigns/approval' : '/campaigns',
                name: mode === 'APPROVAL' ? 'Aprovação Campanha' : 'Campanhas',
              },
              {
                name: 'Editar',
              },
            ]}
          />
          <h1>Editar Campanha</h1>
        </HeaderTitle>
        <HeaderButtons>
          <BaseLinkHistoryCampaign>
            <LinkHistoryCampaign
              href="/"
              onClick={(e) => {
                e.preventDefault();
                openHistoryCampaignModal(campaign.uuid);
              }}
            >
              <img
                style={{ width: 20, marginRight: 5 }}
                src={general.image['icon-log.png']}
                alt="log imagem"
              />
              Visualizar histórico de status
            </LinkHistoryCampaign>
          </BaseLinkHistoryCampaign>
          <Row style={{ justifyContent: 'flex-end' }}>
            <div className="box-search">
              {!hasDisabledCloneCampaign && (
                <Button
                  bordered
                  title="Clonar Campanha"
                  onClick={() => this.handleCloneCampanha()}
                  type="button"
                />
              )}
              {this.renderButtonIncludeProbeGrid()}
              {campaign.campaignStatus.name === 'WAITING_APPROVAL' && (
                <IncludeHasPermission roles={['AUDIENCE_TEST_EXECUTION']}>
                  <Button
                    title="Executar Teste"
                    type="button"
                    onClick={() => {
                      executeTestAudience(campaign.uuid);
                    }}
                  />
                </IncludeHasPermission>
              )}
              {
                this.validateShowBtnResultado() && (
                  <Button
                    bordered
                    title="Resultado"
                    onClick={() => {
                      navigateToRoute(
                        mode === 'APPROVAL'
                          ? `/campaigns/approval/${campaign.uuid}/report`
                          : `/campaigns/edit/${campaign.uuid}/report`,
                      );
                    }}
                    type="button"
                  />
                )
              }
              <CampaignButtonsActions
                campaign={campaign}
                onConfirm={() => {
                  workflowStatus();
                }}
                transitionState={transitionState}
                setTransitionState={setTransitionState}
              />
            </div>
          </Row>
        </HeaderButtons>
      </Header>
    );
  };

  renderBriefing = () => {
    const {
      campaign,
      campaignEditSetBriefingToEdit,
      campaignEditBriefingDownload,
      campaignEditChangeModalEditStatus,
    } = this.props;
    const { screenSchema } = campaign;
    return (
      <ScreenContainer
        title="Briefing"
        schema={screenSchema.briefing}
        action={() => {
          campaignEditSetBriefingToEdit({
            uuid: campaign.uuid,
            templateId: campaign.templateId,
          });
          campaignEditChangeModalEditStatus({
            editBriefingStatus: true,
          });
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <BoxContentItem>
            <b>Template Id</b>
            <span>{campaign.templateId || 'Não definido'}</span>
          </BoxContentItem>
          <BoxContentItem>
            <Button
              icon="FaDownload"
              noLabel
              bordered
              onClick={() => {
                campaignEditBriefingDownload(campaign.uuid);
              }}
            />
          </BoxContentItem>
        </div>
      </ScreenContainer>
    );
  };

  renderAtributos = () => {
    const {
      campaign: { uuid: campaignUuid },
      attributes,
      theme,
      campaignEditSetAttributeToEdit,
      campaignEditChangeModalEditStatus,
    } = this.props;
    if (!attributes || attributes.length === 0) {
      return null;
    }

    function renderValueAttribute(attribute) {
      if (attribute.fieldtype === 'UPLOAD') {
        if (attribute.value) {
          return (
            <Button
              icon="FaDownload"
              title="Realizar download"
              style={{ marginLeft: 0 }}
              small
              bordered
              onClick={() => {
                window.open(attribute.value, '_blank');
              }}
            />
            // <a href={attribute.value} target="_blank" rel="noreferrer">Realizar download</a>
          );
        }
        return (<p>Sem valor informado</p>);
      }

      return (<p>{attribute.value || 'Sem valor informado'}</p>);
    }

    return (
      <CampaignsBoxContent title="Atributos">
        <div className="base-atributos">
          {attributes.map(attribute => (
            <ItemAtributoVisualizacao key={attribute.uuid} editable={attribute.editable}>
              <div
                className="base-button-icon-attribute"
                onClick={() => {
                  if (attribute.editable) {
                    campaignEditSetAttributeToEdit({
                      campaignUuid,
                      attribute,
                    });
                    campaignEditChangeModalEditStatus({
                      editAttributeStatus: true,
                    });
                  }
                }}
              >
                <FaPencilAlt
                  size={20}
                  color={attribute.editable ? theme.colors.darker : theme.colors.regular}
                />
              </div>
              <div className="value">
                <label>{attribute.label}</label>
                {renderValueAttribute(attribute)}
              </div>
            </ItemAtributoVisualizacao>
          ))}
        </div>
      </CampaignsBoxContent>
    );
  };

  renderAcompanhamento = () => {
    const { fact, theme } = this.props;
    if (!fact || fact.length === 0) {
      return null;
    }

    function getColorLevel(level) {
      switch (level) {
        case 'INFO':
          return theme.colors.blue;
        case 'WARNING':
          return theme.colors.warning;
        case 'ERROR':
          return theme.colors.danger2Hex;
        default:
          return null;
      }
    }

    function getIconLevel(level) {
      switch (level) {
        case 'INFO':
          return <FaBell color={getColorLevel(level)} size={20} />;
        case 'WARNING':
          return <FaExclamationTriangle color={getColorLevel(level)} size={20} />;
        case 'ERROR':
          return <FaMinusCircle color={getColorLevel(level)} size={20} />;
        default:
          return null;
      }
    }

    return (
      <CampaignsBoxContent title="Acompanhamento">
        <div className="base-acompanhamento">
          {fact.map((f, index) => (
            <ItemAcompanhamentoStatus key={f.eventDate} colorStatus={getColorLevel(f.level)}>
              <div className="status">
                {getIconLevel(f.level)}
              </div>
              <div className="base-body-content">
                <span className="data-hora-status">
                  {moment(f.eventDate).format('DD/MM/YYYY HH:mm')}
                </span>
                <p>{f.message}</p>
                {(index + 1) < fact.length && (<span className="divider-bottom-acompanhamento" />)}
              </div>
            </ItemAcompanhamentoStatus>
          ))}
        </div>
      </CampaignsBoxContent>
    );
  }

  render() {
    const {
      campaign,
      modalEdit,
    } = this.props;
    const { screenSchema } = campaign;

    return !!campaign && !!screenSchema && (
      <Container>
        {this.renderHeader()}
        <Content>
          <CampaignData className="campaign-columns">
            <CampaignDataColumn>
              <RowScreensContainer className="row-1">
                {screenSchema.general.visible && this.renderDadosGerais()}
              </RowScreensContainer>
              <RowScreensContainer className="row-2">
                {screenSchema.budget.visible && this.renderOrcamento()}
                {screenSchema.audience.visible && this.renderEstimativaImpacto()}
                {this.renderBriefing()}
              </RowScreensContainer>
              <RowScreensContainer className="row-3">
                {screenSchema.schedule.visible && this.renderPeriodo()}
                {screenSchema.benefit.visible && this.renderBeneficio()}
              </RowScreensContainer>
              <RowScreensContainer className="row-4">
                {
                  screenSchema.delivery_strategy
                  && screenSchema.delivery_strategy.visible
                  && this.renderEstrategiaEntrega()
                }
                {
                  // screenSchema['benefit-carrot-package'].visible &&
                  this.renderCarrotPackage()
                }
              </RowScreensContainer>
              <RowScreensContainer className="row-5">
                {
                  this.renderUrlCompartilhamento()
                }
              </RowScreensContainer>
              <RowScreensContainer className="row-1">
                {this.renderAtributos()}
              </RowScreensContainer>
              <RowScreensContainer className="row-1">
                {this.renderAcompanhamento()}
              </RowScreensContainer>
            </CampaignDataColumn>
            <CampaignDataPreview>
              {screenSchema.creative.visible && this.renderCriativo()}
            </CampaignDataPreview>
          </CampaignData>
        </Content>
        <ModalEditOrcamento />
        <ModalEditCriativo />
        <ModalEditPeriodo />
        <ModalEditBriefing />
        <ModalEditBeneficio />
        <ModalEditCarrotPackage />
        <ModalEditCampaignAttribute />
        <ModalCloneCampanha />
        {modalEdit.editImpactEstimateStatus && (
          <ModalEditEstimativaImpacto
            campaignId={campaign.uuid}
            zone={campaign.zone}
          />
        )}
        <ModalEditEstrategiaEntrega />
      </Container>
    );
  }
}

Form.defaultProps = {
  campaign: null,
  criativoEdit: null,
  mode: 'CAMPAIGN',
  attributes: [],
  hasCampaignOfferKey: false,
};

Form.propTypes = {
  mode: PropTypes.string,
  campaign: PropTypes.shape(),
  criativoEdit: PropTypes.shape(),
  openHistoryCampaignModal: PropTypes.func.isRequired,
  transitionState: PropTypes.shape().isRequired,
  setTransitionState: PropTypes.func.isRequired,
  workflowStatus: PropTypes.func.isRequired,
  campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
  campaignEditSetOrcamentoToEdit: PropTypes.func.isRequired,
  campaignEditSetPeriodoToEdit: PropTypes.func.isRequired,
  campaignEditSetEstrategiaEntregaToEdit: PropTypes.func.isRequired,
  campaignEditSetBriefingToEdit: PropTypes.func.isRequired,
  campaignEditBriefingDownload: PropTypes.func.isRequired,
  campaignEditSetAttributeToEdit: PropTypes.func.isRequired,
  navigateToRoute: PropTypes.func.isRequired,
  campaignEditBenefitSetList: PropTypes.func.isRequired,
  campaignEditCarrotPackageSetList: PropTypes.func.isRequired,
  campaignEditSetImpactSettings: PropTypes.func.isRequired,
  executeTestAudience: PropTypes.func.isRequired,
  modalEdit: PropTypes.shape().isRequired,
  includeToProbeGrid: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  campaignSetClone: PropTypes.func.isRequired,
  attributes: PropTypes.arrayOf(PropTypes.shape()),
  fact: PropTypes.arrayOf(PropTypes.shape()),
  campaignShare: PropTypes.arrayOf(PropTypes.shape()),
  theme: PropTypes.shape().isRequired,
  hasDisabledCloneCampaign: PropTypes.bool.isRequired,
  campaignEditLoadMediaEventList: PropTypes.func.isRequired,
  hasCampaignOfferKey: PropTypes.bool,

  // campaignEditSetCriativoToEdit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  criativoEdit: state.campaignEdit.criativoEdit,
  modalEdit: state.campaignEdit.modalEdit,
  hasDisabledCloneCampaign: state.auth.hasDisabledCloneCampaign,
  hasCampaignOfferKey: state.auth.hasCampaignOfferKey,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignSetClone: CampaignEditActions.campaignSetClone,
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditSetOrcamentoToEdit: CampaignEditActions.campaignEditSetOrcamentoToEdit,
    campaignEditSetPeriodoToEdit: CampaignEditActions.campaignEditSetPeriodoToEdit,
    campaignEditSetEstrategiaEntregaToEdit:
      CampaignEditActions.campaignEditSetEstrategiaEntregaToEdit,
    campaignEditSetCriativoToEdit: CampaignEditActions.campaignEditSetCriativoToEdit,
    campaignEditSetBriefingToEdit: CampaignEditActions.campaignEditSetBriefingToEdit,
    campaignEditBriefingDownload: CampaignEditActions.campaignEditBriefingDownload,
    campaignEditBenefitSetList: CampaignEditActions.campaignEditBenefitSetList,
    campaignEditCarrotPackageSetList: CampaignEditActions.campaignEditCarrotPackageSetList,
    campaignEditSetImpactSettings: CampaignEditActions.campaignEditSetImpactSettings,
    campaignEditSetAttributeToEdit: CampaignEditActions.campaignEditSetAttributeToEdit,
    executeTestAudience: CampaignEditActions.executeTestAudience,
    navigateToRoute: RedirectActions.navigateToRoute,
    includeToProbeGrid: CampaignActions.includeToProbeGrid,
    confirmModal: ConfirmModalActions.showConfirmModal,
    campaignEditLoadMediaEventList: CampaignEditActions.campaignEditLoadMediaEventList,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(Form));
