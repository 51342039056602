/* eslint-disable max-len */
/* eslint-disable no-console */
import { call, put } from 'redux-saga/effects';

import { Creators as CockpitActions } from '~/redux/ducks/cockpit';
import CockpitService from '~/services/CockpitService';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* cockpitRequest({ filter }) {
  // Start loading
  yield put(startRequest());
  try {
    // const responseDashboard = yield call(CockpitService.cockpitRequest, filter);
    const responseTable = yield call(CockpitService.cockpitTableRequest, filter);
    yield put(
      CockpitActions.cockpitRequestSuccess({
        // ...responseDashboard,
        ...responseTable,
      }),
    );
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    console.error(e);
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar os dados do cockpit.',
    ));
  }
}
