/* eslint-disable no-plusplus */
import { call, put } from 'redux-saga/effects';

import { replace } from 'connected-react-router';

import ReportV2Service from '~/services/ReportV2Service';
import { Creators as ReportActions } from '~/redux/ducks/reportV2';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

function changeValuesResult(data) {
  const result = { ...data };

  if (result && result.campaign && result.campaign.uuid === '9d709df4-5f5d-4efc-a65f-b18fec2862fa') {
    // CTR
    result.campaignResult.accomplishedStatus.event.delivered = 26784;
    const { delivered, clicks } = result.campaignResult.accomplishedStatus.event;
    result.campaignResult.accomplishedStatus.event.ctrPercent = (clicks / delivered * 100).toFixed(1);

    // CRONIGRAMA X ENTREGAS
    result.campaignResult.accomplishedStatus.impact.value = 26784;
    const { campaign: { impact }, campaignResult: { accomplishedStatus: { impact: { value } } } } = result;
    result.campaignResult.accomplishedStatus.impact.percent = (value / impact * 100).toFixed(1);

    const { dailyResults } = result.periodResults.results[0];
    const daily = dailyResults.find(d => d.date === '2021-11-06');
    daily.delivered = 8577;
    daily.impact = 8577;
    daily.ctrPercent = (daily.clicks / daily.delivered * 100).toFixed(0);

    result.periodResults.results[0].dailyResults = [...dailyResults.map(d => (d.date !== daily.date ? d : daily))];

    // ERRORS
    const { delivery } = result.campaignResult.errors;
    delivery.base = 26964;
    delivery.percent = (delivery.value / delivery.base * 100).toFixed(2);
    result.campaignResult.errors.delivery = {
      ...delivery,
    };
  } else if (result && result.campaign && result.campaign.uuid === '6f856b53-65b6-4bff-b3ab-aaedb5d18b8b') {
    // CTR
    result.campaignResult.accomplishedStatus.event.delivered = 170531;
    const { delivered, clicks } = result.campaignResult.accomplishedStatus.event;
    result.campaignResult.accomplishedStatus.event.ctrPercent = (clicks / delivered * 100).toFixed(1);

    // CRONIGRAMA X ENTREGAS
    result.campaignResult.accomplishedStatus.impact.value = 170531;
    const { campaign: { impact }, campaignResult: { accomplishedStatus: { impact: { value } } } } = result;
    result.campaignResult.accomplishedStatus.impact.percent = (value / impact * 100).toFixed(1);

    const { dailyResults } = result.periodResults.results[0];
    const daily = dailyResults.find(d => d.date === '2021-11-06');
    daily.delivered = 119379;
    daily.impact = 119379;
    daily.ctrPercent = (daily.clicks / daily.delivered * 100).toFixed(0);

    result.periodResults.results[0].dailyResults = [...dailyResults.map(d => (d.date !== daily.date ? d : daily))];

    // ERRORS
    const { delivery } = result.campaignResult.errors;
    delivery.base = 171243;
    delivery.percent = (delivery.value / delivery.base * 100).toFixed(2);
    result.campaignResult.errors.delivery = {
      ...delivery,
    };
  }

  return result;
}

export function* loadDataReportDashboard({ campaignUuid }) {
  try {
    // Start loading
    yield put(startRequest());
    // GET Auctions
    let result = yield call(
      ReportV2Service.reportDashboard,
      campaignUuid,
    );

    result = changeValuesResult(result);

    yield put(
      ReportActions.loadDataReportDashboardSuccess(result),
    );
    yield put(requestSuccess());
  } catch (e) {
    console.error(e);
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados de campanha. Tente novamente!',
    ));
    yield put(replace(`/campaigns/edit/${campaignUuid}`));
  }
}
