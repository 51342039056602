/* eslint-disable max-len */
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  dashboardRequest: ['filter'],
  dashboardRequestSuccess: ['dashboardResult'],
});

export const DashboardTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  dashboardResult: null,
});

/* Reducers */
const dashboardRequestSuccess = (state, { dashboardResult }) => ({
  ...state,
  dashboardResult,
});

export const reducer = createReducer(INITIAL_STATE, {
  [DashboardTypes.DASHBOARD_REQUEST_SUCCESS]: dashboardRequestSuccess,
});
