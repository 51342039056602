import styled, { css } from 'styled-components';
import {
  ContainerStyled,
  RowStyled,
} from '~/Components/Styled';
import UploadComponent from '~/Components/Upload';

export const Container = styled(ContainerStyled)`
  min-height: 100%;
  max-height: 100%;
  overflow-y: auto;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 10px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
`;

export const ActionsBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const IconBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    > small {
      color: ${props => props.theme.colors.dark};
      font-size: 14px;
    }
  }
`;

export const IconMarker = styled.img`
  width: 40px;
  height: 40px;
  background-color: #ffffff;
  object-fit: contain;
  object-position: center;
`;

export const ButtonLink = styled.button`
  border: 0;
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  text-decoration: underline;
  outline: none;
  padding: 0px;
  ${props => props.disabled && css`
    opacity: 0.5;
  `}
`;

export const UploadIcon = styled(UploadComponent).attrs(props => ({
  styleContainer: {
    width: 'auto',
    height: 'fit-content',
    marginRight: 0,
  },
  style: {
    margin: 0,
    padding: 0,
    height: 'fit-content',
    width: 'auto',
    border: 0,
    backgroundColor: 'transparent',
    color: props.theme.colors.primary,
    fontSize: 18,
    textDecoration: 'underline',
    outline: 'none',
  },
}))`
`;

export const UploadFile = styled(UploadComponent).attrs(props => ({
  styleContainer: {
    width: '100%',
    height: 'fit-content',
    marginBottom: 5,
    backgroundColor: props.theme.colors.primary,
    borderRadius: 4,
  },
  style: {
    margin: 0,
    height: 40,
    width: '100%',
    outline: 'none',
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: props.theme.colors.white,
  },
}))`
`;

export const UploadBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: fit-content;
  margin-left: 20px;
`;

export const DescriptionBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid ${props => props.theme.colors.primary};
  padding: 10px;
  border-radius: 8px;
  margin-right: 20px;
  justify-content: space-around;
`;

export const DescriptionBoxItem = styled.div`
  display: flex;
  flex-direction: column;
  > b {
    font-size: 16px;
  }
  > span {
    font-size: 16px;
  }
  &:last-child {
    margin-right: 0px;
  }
`;

export const ButtonsBox = styled.div`
  display: flex;
  width: max-content;
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 10px;
  button {
    margin: 0;
  }
`;

export const ModalAlertMessage = styled.div`
  width: 100%;
  border: 2px solid ${props => props.theme.colors.primaryHex};
  background-color: ${props => props.theme.colors.primaryHex}50;
  padding: 5px;
  font-size: 15px;
  text-align: center;
`;

export const ModalContentUpload = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  .modal-content-upload {
    margin-bottom: 10px;
    label {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      font-size: 20px;
      a {
        font-size: 16px;
        color: ${props => props.theme.colors.primaryHex};
      }
      i {
        display: flex;
        align-items: center;
        font-size: 16px;
        color: ${props => props.theme.colors.regular};
        font-weight: normal;
        svg {
          margin-right: 5px;
        }
      }
    }
    .dropzone-base-file {
      display: flex;
      height: 70px;
      width: 100%;
      justify-content: center;
      align-items: center;
      border: 2px dashed ${props => props.theme.colors.regular};
      outline: none;
      cursor: pointer;
      margin-bottom: 10px;
      font-size: 16px;
      position: relative;
    }
    .icon-description-box {
      display: flex;
      align-items: center;
      .dropzone-icon-file {
        display: flex;
        min-height: 80px;
        min-width: 80px;
        max-height: 80px;
        max-width: 80px;
        justify-content: center;
        align-items: center;
        border: 2px dashed ${props => props.theme.colors.regular};
        outline: none;
        cursor: pointer;
        margin-bottom: 10px;
        font-size: 15px;
        position: relative;
        > img {
          height: 50px;
          width: 50px;
          object-fit: contain;
          object-position: center;
        }
      }
      .icon-description-box-labels {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-left: 10px;
        span {
          font-size: 16px;
          b {}
        }
        i {
          font-size: 15px;
          text-align: center;
        }
      }
    }
    .dropzone-file-ok {
      border-color: #00d318 !important;
    }
    .dropzone-file-error {
      border-color: ${props => props.theme.colors.danger2} !important;
    }
  }
`;

export const DropzoneBaseBox = styled.div`
  display: flex;
  height: 70px;
  width: 100%;
  position: relative;
`;

export const DropzoneIconBox = styled.div`
  display: flex;
  min-height: 80px;
  min-width: 80px;
  max-height: 80px;
  max-width: 80px;
  position: relative;
`;

export const ButtonRemoveFile = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid ${props => props.theme.colors.danger2};
  background-color: ${props => props.theme.colors.danger2};
  color: ${props => props.theme.colors.white};
  position: absolute;
  top: -10px;
  right: -10px;
  justify-content: center;
  align-items: center;
  padding: 0;
  svg {
    font-size: 15px;
  }
`;
