import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    width: 100vw;
    height:100vh;
    margin:0;
    left:0;
    top:0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.theme.colors.dark}70;
    .box-preview{
        position: relative;
        margin: 60px;
        filter: drop-shadow( 0 14px 28px  ${props => props.theme.colors.black}50);
    }
   
    z-index:9999;
  
`;


export const ButtonExpand = styled.div`
    position: absolute;
    bottom:30px;
    right:30px;
    cursor: pointer;
    border-radius: 14px;
    padding:20px;

    img{
        width:30px;
    }
    background-color:  ${props => props.theme.colors.primary};
    &&:hover{
        
        opacity:.9;
    }
    z-index:9999;
`;
