import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';


export const SelectComponent = styled(ReactSelect)`
  width: ${props => (props.width ? `${props.width}px` : '100%')};
  margin-right: ${props => (props.marginRight ? `${props.marginRight}px` : 0)};
  margin-left: ${props => (props.marginLeft ? `${props.marginLeft}px` : 0)};
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  ${props => props.invalid
    && css`
      border-color: ${props => props.theme.colors.danger2};
    `}
  > div {
    border: 0;
    outline: none;
  }
  [class*='-control'] {
    background-color: transparent;
  }
`;
