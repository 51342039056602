import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  loadSettings: [],
  loadSettingsSuccess: ['settings'],
  onChangeSettings: ['settings'],
  updateSettings: ['settings'],
});

export const ReportV2Types = Types;
export default Creators;

/* Initial State */

const INITIAL_STATE = Immutable({
  settings: null,
  periodOptions: [],
});

/* Reducers */
const loadSettings = state => ({
  ...state,
  ...INITIAL_STATE,
});

const loadSettingsSuccess = (state, {
  settings
}) => ({
  ...state,
  settings,
  periodOptions: settings.packageLimits.periodOptions.map(item => {
    const optionsMap = {
      day: 'Dia',
      hour: 'Hora',
      week: 'Semana',
      month: 'Mês'
    };
    return {
      value: item,
      label: optionsMap[item] ? optionsMap[item] : item
    }
  }),
});

const onChangeSettings = (state, {
  settings
}) => ({
  ...state,
  settings,
});


export const reducer = createReducer(INITIAL_STATE, {
  [ReportV2Types.LOAD_SETTINGS]: loadSettings,
  [ReportV2Types.LOAD_SETTINGS_SUCCESS]: loadSettingsSuccess,
  [ReportV2Types.ON_CHANGE_SETTINGS]: onChangeSettings,
});
