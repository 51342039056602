import ReactSelect from 'react-select';
import styled, { css } from 'styled-components';
import {
  ContainerStyled,
  RowStyled,
  TableStyled,
  TextareaStyled,
  FormGroupStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)``;

export const TabContainer = styled.div`
  display: flex;
  width: 100%;

  .react-tabs {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    /* margin-top: 15px; */
    /* font-size: 24px; */
    color: ${props => props.theme.colors.primary};
  }

  .react-tabs__tab-list {
    margin-bottom: 0;
    border-bottom: none;
  }

  .react-tabs__tab {
    border: solid 2px ${props => props.theme.colors.primary};
    border-right: none;
    font-size: 24px;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.white};
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-right: solid 2px ${props => props.theme.colors.primary};
    }
  }

  .react-tabs__tab.react-tabs__tab--selected {
    border-radius: 0;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  .react-tabs__tab-panel--selected {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0 8px 8px 8px;
    border: solid 1px ${props => props.theme.colors.light};
    align-items: flex-start;
    justify-content: flex-start;
    .tab-body {
      padding: 15px;
      flex: 1;
      display: flex;
      flex-direction: column;
      width: calc(100% - 30px);
      flex-shrink: 0;
      background-color: ${props => props.theme.colors.bgBody};
    }
  }
`;

export const Table = styled(TableStyled)`
  thead {
    tr {
      th {
        white-space: nowrap;
      }
      th[align="center"] {
        text-align: center !important;
      }
    }
  }
  tbody {
    tr {
      td {
        height: 68px;
        span.nowrap {
          white-space: nowrap;
        }
        span.auctionModified {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          background-color: ${props => props.theme.colors.warning};
          color: ${props => props.theme.colors.darker};
          margin-left: 5px;
          padding: 0 15px;
          height: 30px;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  .empty-campaign {
    font-weight: 800;
    font-size: 18px;
    color: ${props => props.theme.colors.primary};
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
`;

export const HeaderForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderFormInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  label {
    font-size: 16px;
    width: 80px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const Select = styled(ReactSelect)`
  width: 300px;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

// box table
export const BoxTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
`;

export const BoxTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 30px;
`;

export const BoxTableHeaderTitle = styled.span`
  display: flex;
  height: 30px;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  margin-left: 10px;
`;

export const BoxTableHeaderSubtitle = styled.span`
  color: ${props => props.theme.colors.dark};
  font-size: 16px;
  display: flex;
  height: 30px;
  align-items: center;
  margin-right: 10px;
`;

export const BoxTableContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

export const BoxTableFooter = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  padding: 0 10px 10px 0;
`;

export const BoxTableContentItem = styled.div`
  font-size: 16px;
  span.successDate{
    color: ${props => props.theme.colors.checked};
    letter-spacing: 1px;
  }

  span.dangerDate{
    color: ${props => props.theme.colors.danger};
    letter-spacing: 1px;
  }
`;

export const TableDisplayRow = styled.div`
  display: flex;
`;

export const TableDisplayColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonSignature = styled.button.attrs({
  type: 'button',
})`
  outline: none;
  color: ${props => props.theme.colors.primary};
  border: 0;
  background-color: transparent;
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 18px;
  width: fit-content;
  svg {
    font-size: 18px;
    color: ${props => props.theme.colors.primary};
    margin-right: 5px;
  }
`;

export const ButtonSignatureValidate = styled(ButtonSignature)`
`;

export const ButtonSignatureInvalidate = styled(ButtonSignature)`
`;

export const Textarea = styled(TextareaStyled)`
  width: 100%;
`;

export const FormGroup = styled(FormGroupStyled)`
  width: 100%;
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)};
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const RowFooter = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
`;

export const LabelValidation = styled.span`
  white-space: nowrap;
  color: ${props => props.theme.colors.checked};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 20px;
    margin-right: 5px;
  }
`;

export const LabelInvalidation = styled.span`
  white-space: nowrap;
  color: ${props => props.theme.colors.danger2};
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 20px;
    margin-right: 5px;
  }
`;

export const LabelNoResult = styled.span`
  white-space: nowrap;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      margin-left: 0;
    }
    svg {
      font-size: 20px;
      margin: 0;
    }
  }
`;
