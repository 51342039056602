import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'styled-components';

import {
  Button,
} from '../../Components';
import { general } from '~/Styles';
import {
  Row,
  Column,
} from '../../styles';

function MediaQuestionario({ media }) {
  const { dailyResultsFile } = media;
  return (
    <div className="media-questionario">
      <Row>
        <Column className="box">
          <Row className="space">
            <div className="type-box">
              <img src={general.imageCampaign['icon-download.png']} alt="Download" />
            </div>
            <h2>DownLoad Pesquisa</h2>
          </Row>
          <Button
            disabled={!dailyResultsFile || dailyResultsFile === 'unavailable'}
            tooltip={!dailyResultsFile || dailyResultsFile === 'unavailable' ? 'Não existe arquivo para download no momento' : null}
            title="Fazer Download"
            onClick={() => {
              window.open(dailyResultsFile, '_blank');
            }}
          />
        </Column>

        {/* <Column className="box">
          <Row className="space">
            <div className="type-box">
              <img src={general.imageCampaign['icon-file-outline.png']} alt="Total Arquivos" />
            </div>
            <h2>Total de Questões</h2>
          </Row>
          <span className="bigger">08</span>
        </Column>


        <div className="box">
          <Row>
            <Column>
              <Row>
                <img src={general.imageCampaign['icon-ctr.png']} alt="CTR" />
                <h2>CTR</h2>

              </Row>
              <Column className="values">
                <label>Cliques por Entrega:</label>
                <span>
                  <span className="bigger">800.000</span>
                  /1.000.000
                </span>
              </Column>
            </Column>


            <Column className="chart">
              <RadialChart
                size={130}
                values={[90]}
                showDataLabels
                padding={{
                  top: -20,
                  bottom: -20,
                }}
              />
            </Column>

          </Row>
        </div> */}

      </Row>

    </div>
  );
}

MediaQuestionario.propTypes = {
  media: PropTypes.shape().isRequired,
};


export default withTheme(MediaQuestionario);
