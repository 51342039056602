import styled from 'styled-components';

import { InputStyled, RowStyled } from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  > label {
    margin-right: 10px;
  }
`;

export const Questions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const ModalWrapBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 600px;
`;

export const ModalPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: 20px;
`;

export const ModalPreviewTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 580px;
  overflow-y: auto;
  padding: 0 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const Input = styled(InputStyled)`
  margin-right: 0;
`;

export const Info = styled.div`
  width: 100%;
  background: #ecebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin-left: 25px;
  margin-right: 25px;
  border-radius: 7px;
  border: 1px solid #ccc;

  p {
    margin-bottom: 0;
    font-weight: 600;
    font-style: italic;
  }
`;

export const ItensRanking = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 10px 25px;

  .base-buttom-add-item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
  }
`;

export const BaseItem = styled.div`
  .base-item {
    display: flex;
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 8px;

    .base-item-content {
      flex: 1;

      p {
        margin-bottom: 0;
        font-style: italic;
        font-weight: 600;
      }

      .base-item-content-inputs {
        display: flex;
        width: 100%;
        gap: 15px;

        .base-item-content-inputs-label-input {
          display: flex;
          flex-direction: column;
        }
      }
    }

    .base-item-remove {
      display: flex;
      align-items: flex-start;
      justify-content: center;
    }
  }
`;
