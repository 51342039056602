import styled, { css } from 'styled-components';
import { RowStyled, FormGroupStyled, InputStyled } from '~/Components/Styled';
import CheckboxComponent from '~/Components/Checkbox';
import RadioboxComponent from '~/Components/Radiobox';

export const Row = styled(RowStyled)`
  justify-content: flex-start;
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldItem = styled(FormGroupStyled)`
  margin-bottom: 20px;
  > label{
    margin-bottom: 5px;
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Input = styled(InputStyled)`
  margin-right: 0;
  width: 600px;
`;

export const FormGroup = styled(FormGroupStyled)`
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const BannerEmpty = styled.div`
  padding: 20px;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  position: relative;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  margin-top: 5px;
  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.danger2};
  font-style: italic;
  font-size: 18px;
`;

export const Checkbox = styled(CheckboxComponent)``;

export const Radiobox = styled(RadioboxComponent)``;

export const CheckContainer = styled.div`
  display: flex;
  width: 100%;
  .checkmark {
    border: 2px solid ${props => (props.invalid ? props.theme.colors.danger2 : props.theme.colors.primary)} !important;
  }
  .checkmark-box {
    color: ${props => (props.invalid ? props.theme.colors.danger2 : props.theme.colors.regular)} !important;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  .radio {
    border: 2px solid ${props => (props.invalid ? props.theme.colors.danger2 : props.theme.colors.primary)} !important;
  }
  .radio-box {
    color: ${props => (props.invalid ? props.theme.colors.danger2 : props.theme.colors.regular)} !important;
  }
`;
