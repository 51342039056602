import styled, { css } from 'styled-components';

import CurrencyInput from 'react-currency-input';
import InputMask from 'react-input-mask';

import { hex2rgba } from '~/Utils';

export const ContainerStyled = styled.div`
  width: calc(100% - 150px);
  min-height: calc(100vh - 181px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 1;
  flex-shrink: 0;
  margin-left: 150px;
  margin-top: 80px;
  padding: 30px;
  background-color: ${props => props.theme.colors.bgBody};
  h1 {
    font-size: 25px;
    color: ${props => props.theme.colors.primary};
    margin: 0;
  }

  h2 {
    font-size: 22px;
    color: ${props => props.theme.colors.primary};
    margin: 0;
  }

  h3 {
    font-size: 19px;
    color: ${props => props.theme.colors.primary};
    margin: 0;
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  thead {
    tr {
      background-color: ${props => props.theme.colors.light};
      th {
        padding: 9px;
        font-family: "Simplon BP";
        font-size: 18px;
        font-weight: 600;
      }
      th.table-column-center {
        text-align: center;
      }
    }
  }
  tbody tr{
    margin-top:15px;
    margin-bottom:15px;
    td {
      padding: 15px 5px 5px 7.5px;
      &.link{
        /* max-width:350px; */
        /* white-space: nowrap; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
      }
      span{
        font-size:18px;
      }
      p {
        font-size:18px;
      }
    }
  }
  .empty-table-desc{
    margin-top: 10px;
    width: 100%;
    text-align: center;
    //border: solid 1px ${props => props.theme.colors.primary};
    background-color: ${props => hex2rgba(props.theme.colors.primary, 0.05)};
    border-radius: 2px;

    span{
      font-weight: 800;
      font-size: 18px;
      color:${props => props.theme.colors.primary};
    }

    padding: 10px;
  }
`;

export const RowStyled = styled.div`
  display: flex;
  flex: initial;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const CurrentInputStyled = styled(CurrencyInput)`
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  margin-right: 15px;

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}

  &::placeholder {
    color: ${props => props.theme.colors.light};
  }

  /* RANGE STYLE TYPE */
  &[type='range'] {
    -webkit-appearance: none;
    margin: 0 0 3px 0;

    width: 100%;
    border: none;
    border-radius: 0;
  }
  &[type='range']:focus {
    outline: none;
  }
  &[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-webkit-slider-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  &[type='range']:focus::-webkit-slider-runnable-track {
    background: ${props => props.theme.colors.primary};
  }
  &[type='range']::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-moz-range-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  &[type='range']::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-ms-fill-lower {
    background: ${props => props.theme.colors.primary};
    border: 0.2px solid #fff;
    border-radius: 2.6px;
    box-shadow: none;
  }
  &[type='range']::-ms-fill-upper {
    background: ${props => props.theme.colors.primary};
    border: 0.2px solid #fff;
    border-radius: 2.6px;
    box-shadow: none;
  }
  &[type='range']::-ms-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
  &[type='range']:focus::-ms-fill-lower {
    background: ${props => props.theme.colors.primary};
  }
  &[type='range']:focus::-ms-fill-upper {
    background: ${props => props.theme.colors.primary};
  }
`;

export const InputStyled = styled.input`
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  margin-right: 15px;

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}

  &::placeholder {
    color: ${props => props.theme.colors.light};
  }

  /* RANGE STYLE TYPE */
  &[type='range'] {
    -webkit-appearance: none;
    margin: 0 0 3px 0;

    width: 100%;
    border: none;
    border-radius: 0;
  }
  &[type='range']:focus {
    outline: none;
  }
  &[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-webkit-slider-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  &[type='range']:focus::-webkit-slider-runnable-track {
    background: ${props => props.theme.colors.primary};
  }
  &[type='range']::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-moz-range-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  &[type='range']::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-ms-fill-lower {
    background: ${props => props.theme.colors.primary};
    border: 0.2px solid #fff;
    border-radius: 2.6px;
    box-shadow: none;
  }
  &[type='range']::-ms-fill-upper {
    background: ${props => props.theme.colors.primary};
    border: 0.2px solid #fff;
    border-radius: 2.6px;
    box-shadow: none;
  }
  &[type='range']::-ms-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
  &[type='range']:focus::-ms-fill-lower {
    background: ${props => props.theme.colors.primary};
  }
  &[type='range']:focus::-ms-fill-upper {
    background: ${props => props.theme.colors.primary};
  }
`;

export const InputMaskStyled = styled(InputMask)`
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  margin-right: 15px;

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}

  &::placeholder {
    color: ${props => props.theme.colors.light};
  }

  /* RANGE STYLE TYPE */
  &[type='range'] {
    -webkit-appearance: none;
    margin: 0 0 3px 0;

    width: 100%;
    border: none;
    border-radius: 0;
  }
  &[type='range']:focus {
    outline: none;
  }
  &[type='range']::-webkit-slider-runnable-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-webkit-slider-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  &[type='range']:focus::-webkit-slider-runnable-track {
    background: ${props => props.theme.colors.primary};
  }
  &[type='range']::-moz-range-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-moz-range-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -7px;
  }
  &[type='range']::-ms-track {
    width: 100%;
    height: 6px;
    cursor: pointer;
    animate: 0.2s;
    box-shadow: none;
    background: ${props => props.theme.colors.primary};
    border-radius: 4px;
  }
  &[type='range']::-ms-fill-lower {
    background: ${props => props.theme.colors.primary};
    border: 0.2px solid #fff;
    border-radius: 2.6px;
    box-shadow: none;
  }
  &[type='range']::-ms-fill-upper {
    background: ${props => props.theme.colors.primary};
    border: 0.2px solid #fff;
    border-radius: 2.6px;
    box-shadow: none;
  }
  &[type='range']::-ms-thumb {
    box-shadow: none;
    border: 2px solid #fff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: ${props => props.theme.colors.primary};
    cursor: pointer;
  }
  &[type='range']:focus::-ms-fill-lower {
    background: ${props => props.theme.colors.primary};
  }
  &[type='range']:focus::-ms-fill-upper {
    background: ${props => props.theme.colors.primary};
  }
`;

export const TextareaStyled = styled.textarea`
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  margin-right: 15px;
  &::placeholder {
    color: ${props => props.theme.colors.light};
  }

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}
`;

export const FormGroupStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  label {
    color: ${props => props.theme.colors.regular};
    font-size: 18px;
    margin-bottom: 0;
    font-weight: normal;
  }
`;

export const SelectStyled = styled.select`
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  height: 36px;

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;
