import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

class IncludeHasPermission extends Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node,
    ]).isRequired,
    currentUser: PropTypes.shape().isRequired,
    roles: PropTypes.arrayOf(PropTypes.string).isRequired,
  };

  componentDidMount() {}

  render() {
    const { currentUser, children, roles } = this.props;
    const userRoles = currentUser.roles.map(item => item.name);
    const hasPermissionRole = userRoles.some(v => roles.includes(v));
    return <>{hasPermissionRole && children}</>;
  }
}

const mapStateToProps = state => ({
  currentUser: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(IncludeHasPermission);
