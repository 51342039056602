/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import uuid4 from 'uuid4';
import { withTheme } from 'styled-components';
import RichTextEditor from 'react-rte';
import {
  // FaQuestionCircle,
  // FaRegCheckSquare,
  // FaRegDotCircle,
  // FaFont,
  // FaSortNumericUp,
  // FaRegStar,
  // FaRegCalendarAlt,
  FaPencilAlt,
  FaEye,
  FaRegCheckSquare,
  FaRegDotCircle,
  FaFont,
  FaRegCalendarAlt,
} from 'react-icons/fa';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import ReactTooltip from 'react-tooltip';

// import Checkbox from '~/Components/Checkbox';
// import Modal from '~/Components/Modal';
// import Button from '~/Components/Button';
// import Checkbox from '~/Components/Checkbox';
// import Radiobox from '~/Components/Radiobox';
// import Item from './components/Item';
// import PreviewResearch from '../../../../../../../../Components/PreviewResearch';

import {
  Container,
  Row,
  Column,
  // Questions,
  // LabelQuestion,
  // NewItem,
  // AddNewItem,
  // modal
  // ModalHeader,
  // ModalWrapBody,
  // ModalPreview,
  // ModalBody,
  // ModalFooter,
  // AddItemsWrap,
  // GroupButtons,
  // ButtonInsert,
  FormGroup,
  ButtonEditForm,
  ButtonViewForm,
  GroupActionsButton,
  SelectPosition,
  BaseQuestions,
  BaseQuestionsContent,
  QuestionsList,
  QuestionsItem,
  InfoQuestionNoBenefit,
} from './styles';
import { ModalSurveyEdit } from '../ModalSurveyEdit';
import { ModalRankingSuccess } from '../ModalRankingSuccess';
import Button from '~/Components/Button';
import { ModalBenefitQuestion } from '../ModalBenefitQuestion';

class ResearchForm extends Component {
  state = {
    modalResearchOpen: false,
    modalRankingSuccessOpen: false,
    modalBenefitQuestion: {
      show: false,
      question: null,
    },
    editorValue: RichTextEditor.createEmptyValue(),
    formModalCopy: null,
    formHasError: false,
  };

  refModalBody = null;

  editorRef = null;

  componentDidMount() {
    const { content } = this.props;
    this.setState({
      editorValue: RichTextEditor.createValueFromString(
        content.confirmationTerm ? content.confirmationTerm : '',
        'html',
      ),
    });
  }

  renderModalResearch = () => {
    const { formModalCopy } = this.state;
    const {
      onChangeForm,
      content,
      disabledFields,
    } = this.props;
    const { formHasError } = this.state;
    const { modalResearchOpen } = this.state;

    return (
      <ModalSurveyEdit
        modalOpen={modalResearchOpen}
        disabledFields={disabledFields}
        onChangeForm={onChangeForm}
        content={content}
        formModalOriginal={formModalCopy}
        formHasError={formHasError}
        onCloseModal={() => {
          onChangeForm({
            ...content,
            ...formModalCopy,
          });
          this.setState({
            modalResearchOpen: false,
            formModalCopy: null,
            formHasError: false,
          });
        }}
        onCancelar={() => {
          onChangeForm({
            ...content,
            ...formModalCopy,
          });
          this.setState({
            modalResearchOpen: false,
            formModalCopy: null,
            formHasError: false,
          });
        }}
        onConfirmar={(formIsValid) => {
          if (formIsValid) {
            this.setState({
              modalResearchOpen: false,
              formHasError: false,
            });
          } else {
            this.setState({
              formHasError: true,
            });
          }
        }}
      />
    );
  };

  renderModalBenefitQuestion = () => {
    const {
      onChangeForm,
      content,
      disabledFields,
    } = this.props;
    const { modalBenefitQuestion } = this.state;

    return (
      <ModalBenefitQuestion
        modalOpen={modalBenefitQuestion.show}
        question={modalBenefitQuestion.question}
        disabledFields={disabledFields}
        onCancelar={() => {
          this.setState({
            modalBenefitQuestion: {
              show: false,
              question: null,
            },
          });
        }}
        onConfirmar={(benefits) => {
          onChangeForm({
            ...content,
            questions: content.questions.map((q) => {
              if (q.uuid === modalBenefitQuestion.question.uuid) {
                return {
                  ...q,
                  benefits,
                };
              }
              return q;
            }),
          });
          this.setState({
            modalBenefitQuestion: {
              show: false,
              question: null,
            },
          });
        }}
      />
    );
  }

  renderModalRankingSuccess = () => {
    const {
      onChangeForm,
      content,
      disabledFields,
    } = this.props;
    const { modalRankingSuccessOpen } = this.state;

    return (
      <ModalRankingSuccess
        modalOpen={modalRankingSuccessOpen}
        disabledFields={disabledFields}
        content={content}
        onCancelar={() => {
          this.setState({
            modalRankingSuccessOpen: false,
          });
        }}
        onConfirmar={(ranking) => {
          if (ranking && ranking.length > 0) {
            onChangeForm({
              ...content,
              ranking,
            });
          } else {
            const { ranking: _, ...rest } = content; // remove item de ranking do content
            onChangeForm({
              ...rest,
            });
          }

          this.setState({
            modalRankingSuccessOpen: false,
          });
        }}
      />
    );
  };

  getIconQuestionType = (questionType) => {
    switch (questionType) {
      case 'check':
        return <FaRegCheckSquare />;
      case 'radio':
        return <FaRegDotCircle />;
      case 'text':
        return <FaFont />;
      case 'date':
        return <FaRegCalendarAlt />;
      default:
        return null;
    }
  };

  renderBotaoAcaoBeneficio(q) {
    const { type } = q;

    if (type !== 'check' && type !== 'radio') {
      return <InfoQuestionNoBenefit><p>Somente para tipos de Escolha e Múltipla Escolha</p></InfoQuestionNoBenefit>;
    }

    const { options } = q;

    if (!options || options.length === 0) {
      return <InfoQuestionNoBenefit><p>Necessário ter uma resposta correta configurada</p></InfoQuestionNoBenefit>;
    }

    const isExistIsAnswerTrue = options.some(o => o.isAnswer === true);

    if (!isExistIsAnswerTrue) {
      return <InfoQuestionNoBenefit><p>Necessário ter uma resposta correta configurada</p></InfoQuestionNoBenefit>;
    }

    return (
      <Button
        icon="FaGift"
        iconSize={16}
        title={`${q.benefits && q.benefits.length ? 'Alterar' : 'Incluir'} benefícios`}
        small
        bordered
        onClick={() => {
          this.setState({
            modalBenefitQuestion: {
              show: true,
              question: q,
            },
          });
        }}
      />
    );
  }

  renderQuestions = () => {
    const { content } = this.props;
    const { questions } = content;
    return (
      <Row style={{ marginTop: 10 }}>
        <BaseQuestions>
          <div className="base-header-questions">
            <h4>Perguntas</h4>
            <h4>Benefícios</h4>
          </div>
          <BaseQuestionsContent>
            {questions.length === 0 && (<span>Sem Perguntas cadastradas</span>)}
            {questions.length > 0 && (
            <QuestionsList>
              {questions.map((q, index) => (
                <QuestionsItem key={q.uuid}>
                  {this.getIconQuestionType(q.type)}
                  <span>{`${index + 1}#`}</span>
                  <p>{q.title}</p>
                  {this.renderBotaoAcaoBeneficio(q)}
                </QuestionsItem>
              ))}
            </QuestionsList>
            )}
          </BaseQuestionsContent>
        </BaseQuestions>
      </Row>
    );
  }

  render() {
    const { editorValue } = this.state;
    const {
      onChangeForm,
      content,
      hasError,
      disabledFields,
    } = this.props;

    const typeList = [
      { label: 'Antes da pesquisa', value: 'before' },
      { label: 'Depois da pesquisa', value: 'after' },
      { label: 'Não se aplica', value: '' },
    ];

    return (
      <Container>
        <>
          <Row style={{ justifyContent: 'center' }}>
            <GroupActionsButton>
              {disabledFields ? (
                <>
                  <ButtonViewForm
                    onClick={() => {
                      this.setState({
                        modalResearchOpen: true,
                        formModalCopy: content,
                        formHasError: false,
                      });
                    }}
                  >
                    Visualizar formulário
                    <FaEye />
                  </ButtonViewForm>
                  <ButtonViewForm
                    onClick={() => {
                      this.setState({
                        modalRankingSuccessOpen: true,
                      });
                    }}
                  >
                    Visualizar ranking
                    <FaEye />
                  </ButtonViewForm>
                </>
              ) : (
                <>
                  <ButtonEditForm
                    onClick={() => {
                      this.setState({
                        modalResearchOpen: true,
                        formModalCopy: content,
                        formHasError: false,
                      });
                    }}
                  >
                    Editar formulário
                    <FaPencilAlt />
                  </ButtonEditForm>
                  <ButtonEditForm
                    onClick={() => {
                      this.setState({
                        modalRankingSuccessOpen: true,

                      });
                    }}
                  >
                    Editar ranking
                    <FaPencilAlt />
                  </ButtonEditForm>

                </>
              )}
            </GroupActionsButton>
          </Row>
          {this.renderQuestions()}
          <Row style={{ marginTop: 10 }}>
            <h4>Configurações</h4>
          </Row>
          <Row style={{ justifyContent: 'flex-start' }}>
            <Column>
              <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
                <label style={{ margin: 0 }}>Posição do termo</label>
                <SelectPosition
                  isDisabled={disabledFields}
                  menuPlacement="top"
                  placeholder="Selecione a posição"
                  value={typeList.find(item => item.value === (content.termPosition ? content.termPosition : ''))}
                  onChange={(position) => {
                    onChangeForm({
                      ...content,
                      termPosition: position.value,
                      ...(!position.value ? { confirmationTerm: '' } : {}),
                    });
                    if (!position.value) {
                      this.setState({ editorValue: RichTextEditor.createValueFromString('', 'html') });
                    }
                  }}
                  options={typeList}
                />
              </FormGroup>
            </Column>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <FormGroup>
              <label>
                Termo de confirmação
                {!!content.termPosition && (
                  <span>*</span>
                )}
              </label>
              <RichTextEditor
                disabled={disabledFields || !content.termPosition}
                className={`editor-rich ${hasError && (!content.confirmationTerm && content.termPosition) ? 'invalid-editor' : ''}`}
                value={editorValue}
                ref={(ref) => { this.editorRef = ref; }}
                toolbarConfig={{
                  display: [
                    'INLINE_STYLE_BUTTONS',
                  ],
                  INLINE_STYLE_BUTTONS: [
                    { label: 'Negrito', style: 'BOLD' },
                    { label: 'Itálico', style: 'ITALIC' },
                    { label: 'Sublinhado', style: 'UNDERLINE' },
                  ],
                }}
                onChange={(val) => {
                  this.setState({ editorValue: val });
                  onChangeForm({
                    ...content,
                    confirmationTerm: val.toString('html'),
                  });
                }}
              />
            </FormGroup>
          </Row>
        </>
        {this.renderModalResearch()}
        {this.renderModalRankingSuccess()}
        {this.renderModalBenefitQuestion()}
      </Container>
    );
  }
}

ResearchForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onChangeForm: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(withTheme(ResearchForm));
