import api from './axios';

const list = async () => {
  try {
    const { data } = await api.get('mcare-artemis-advertising-delivery/setups?profile=CASCADE');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const update = async (ad) => {
  try {
    const { data } = await api.put(
      `mcare-artemis-advertising-delivery/setups/${ad.id}`,
      ad,
    );
    return data;
  } catch (error) {
    const { status } = error.response;
    const message = status === 400
      ? 'Verifique os campos obrigatórios'
      : 'Houve um problema ao salvar o Anúncio';
    throw new Error(message);
  }
};

export default {
  list,
  update,
};
