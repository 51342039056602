import styled from 'styled-components';
import {
  PreviewMessageBox as PreviewMessageBoxStyled,
  PreviewMessageBoxFooter as PreviewMessageBoxFooterStyled,
} from '../../styles';


export const PreviewMessageBox = styled(PreviewMessageBoxStyled)``;
export const PreviewMessageBoxFooter = styled(PreviewMessageBoxFooterStyled)``;

export const ContentVoiceMessage = styled.div`
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  .buttonPlay {
    width: 150px;
    height: 150px;
    margin-top: -75px;
    border: 2px solid ${props => props.theme.colors.darker};
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.darker};
    cursor: pointer;
  }
  .contentPlaying {
    display: flex;
    width: 100%;
    /* height: 200px; */
    background-color: ${props => props.theme.colors.darker};
    margin-top: -100px;
    padding: 5px;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    label {
      color: ${props => props.theme.colors.white};
    }
    p {
      color: ${props => props.theme.colors.white};
    }
  }
`;
