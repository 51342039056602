import React, { Component } from 'react';
import uuid4 from 'uuid4';
import {
  Map, TileLayer, FeatureGroup, GeoJSON,
} from 'react-leaflet';
import L from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './css.css';

import {
  Container,
} from './styles';

// eslint-disable-next-line no-underscore-dangle
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});

class AudienceMap extends Component {
  state = {
    listPlots: [],
  };

  editableFG = null;

  leafletMapRef = null;

  onCreated = ({ layer }) => {
    const { listPlots } = this.state;
    layer.remove();

    this.setState({
      listPlots: [
        ...listPlots,
        {
          uuid: uuid4(),
          title: `Camada de número ${listPlots.length + 1}`,
          plots: layer.toGeoJSON(),
        },
      ],
    });
  }

  render() {
    const { listPlots } = this.state;
    // http://{s}.tile.osm.org/{z}/{x}/{y}.png

    return (
      <Container>
        <Map
          ref={(ref) => {
            this.leafletMapRef = ref;
          }}
          className="custom-map"
          center={[-22.905503, -43.262965]}
          zoom={8}
          maxZoom={18}
          minZoom={5}
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <TileLayer
            attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <FeatureGroup ref={(reactFGref) => { this.editableFG = reactFGref; }}>
            <EditControl
              position="topright"
              onCreated={this.onCreated}
              draw={{
                rectangle: false,
                polyline: false,
                marker: false,
                circlemarker: false,
              }}
              edit={{
                edit: false,
                remove: false,
              }}
            />
          </FeatureGroup>
          {listPlots.map(item => (
            <GeoJSON
              data={item.plots}
              key={item.uuid}
              onEachFeature={(feature, layer) => {
                const { leafletElement } = this.editableFG;
                const bounds = layer.getBounds();
                const center = bounds.getCenter();
                const layerName = L.marker(center, {
                  icon: L.divIcon({
                    className: 'text-labels',
                    html: item.title,
                  }),
                  zIndexOffset: 1000,
                });
                leafletElement.addLayer(layerName);

                layer.on({
                  click: () => {

                  },
                });
              }}
              style={{
                fillColor: '#9700e2',
                fillOpacity: 0.7,
                color: '#4b0096',
                weight: 1,
              }}
            />
          ))}
        </Map>
      </Container>
    );
  }
}

export default AudienceMap;
