import api from './axios';

const partnersRequest = async () => {
  try {
    const { data } = await api.get('contract');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getCampaigns = async ({ contractUuid, typeUuid, date }) => {
  try {
    const { data } = await api.get(
      `workflow/campaign?size=9999&page=0&date=${date}&contractUuid=${contractUuid}&typeUuid=${typeUuid}&statusUuid=f9bebe29-3a8c-40c0-a429-25c6d6afe9aa,0f2789d9-83c9-4165-96c2-f5633f995dd4`,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getSchedule = async (date) => {
  try {
    const { data } = await api.get(
      `sheduling?date=${date}`,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const save = async (schedule) => {
  try {
    const { data } = await api.post(
      'sheduling',
      schedule,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  partnersRequest,
  getCampaigns,
  getSchedule,
  save,
};
