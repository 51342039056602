import api from './axios';

const getWarning = async (body) => {
  try {
    const { data } = await api.post(
      'warnings',
      body,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const accept = async (request) => {
  try {
    const { data } = await api.put(
      request.path,
      request.body,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const refuse = async (request) => {
  try {
    const { data } = await api.put(
      request.path,
      request.body,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  getWarning,
  accept,
  refuse,
};
