import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { FaPencilAlt, FaCheckSquare } from 'react-icons/fa';

import {
  Container, Value, EditButton, Input, CurrentInput,
} from './styles';

import { convertFloatReal } from '~/Utils';

class DinamicStaticEditInput extends Component {
  state = {
    edit: false,
  };

  renderInput = () => {
    const {
      name,
      value,
      onChange,
      onBlur,
      fontSize,
      currency,
      maxWidth,
      maxLength,
      invalid,
    } = this.props;

    return currency ? (
      <CurrentInput
        prefix="R$"
        decimalSeparator=","
        thousandSeparator="."
        name={name}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        maxWidth={maxWidth}
        maxLength={maxLength}
        invalid={invalid}
      />
    ) : (
      <Input
        maxLength={maxLength}
        fontSize={fontSize}
        name={name}
        onChange={onChange}
        value={value}
        onBlur={onBlur}
        maxWidth={maxWidth}
        invalid={invalid}
      />
    );
  };

  render() {
    const { edit } = this.state;
    const {
      value, fontSize, disabled, currency, colorLabel, invalid, theme,
    } = this.props;

    return (
      <Container>
        {!edit ? (
          <Value invalid={invalid} fontSize={fontSize} colorLabel={colorLabel}>
            {currency ? `R$${convertFloatReal(value ? value.toFixed(2) : 0.0)}` : value}
          </Value>
        ) : (
          this.renderInput()
        )}
        {!disabled && (
          <EditButton onClick={() => this.setState({ edit: !edit })}>
            {edit ? (
              <FaCheckSquare color={theme.colors.primary} size={20} />
            ) : (
              <FaPencilAlt color={theme.colors.primary} />
            )}
          </EditButton>
        )}
      </Container>
    );
  }
}

DinamicStaticEditInput.defaultProps = {
  disabled: false,
  currency: false,
  invalid: false,
  colorLabel: null,
  maxWidth: null,
  maxLength: null,
};

DinamicStaticEditInput.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  fontSize: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  currency: PropTypes.bool,
  invalid: PropTypes.bool,
  colorLabel: PropTypes.string,
  maxWidth: PropTypes.number,
  maxLength: PropTypes.string,
  theme: PropTypes.shape().isRequired,
};

export default withTheme(DinamicStaticEditInput);
