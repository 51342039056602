/* eslint-disable max-len */
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  campaignScheduleSetup: null,
  campaignScheduleTypesRequestSuccess: ['types'],
  campaignScheduleSetSchedule: ['schedule'],
  campaignSchedulePartnersRequestSuccess: ['partners'],
  campaignScheduleCampaignRequest: ['position', 'contractUuid', 'typeUuid'],
  campaignScheduleRequest: ['date', 'forceSetup'],
  campaignScheduleRequestSuccess: ['monthSchedule'],
  campaignScheduleSetupByDate: ['date'],
  campaignScheduleSaveSendRequest: ['postType'],
});

export const CampaignSchedulingTypes = Types;
export default Creators;

export const INNITIAL_SCHEDULE = {
  uuid: null,
  date: null,
  type: null,
  isEnd: false,
  isOpen: false,
  partners: [],
};
/* Initial State */
const INITIAL_STATE = Immutable({
  types: [],
  partners: [],
  schedule: INNITIAL_SCHEDULE,
  monthSchedule: {
    date: null,
    schedules: [],
  },
});

/* Reducers */
const campaignScheduleSetSchedule = (state, { schedule }) => ({
  ...state,
  schedule: {
    ...state.schedule,
    ...schedule,
  },
});
const campaignScheduleTypesRequestSuccess = (state, { types }) => ({
  ...state,
  types,
});
const campaignSchedulePartnersRequestSuccess = (state, { partners }) => ({
  ...state,
  partners,
});
const campaignScheduleRequestSuccess = (state, { monthSchedule }) => ({
  ...state,
  monthSchedule,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_SET_SCHEDULE]: campaignScheduleSetSchedule,
  [CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_TYPES_REQUEST_SUCCESS]: campaignScheduleTypesRequestSuccess,
  [CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_PARTNERS_REQUEST_SUCCESS]: campaignSchedulePartnersRequestSuccess,
  [CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_REQUEST_SUCCESS]: campaignScheduleRequestSuccess,
});
