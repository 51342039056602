import styled from 'styled-components';

export const Container = styled.div`
  /* width: 100%; */
  height: 100%;
  overflow-x: scroll;
  max-width: 935px;

  margin-top: 5px;

  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
`;


export const BarComponent = styled.div`
  flex-grow:0;
  width:60px !important;
  margin-right:35px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  flex-shrink: 0;

  flex: 0 0 auto;

  .bar-total{
    margin-bottom: 5px;
  }

  .bar-day{
    margin-top: 5px;
  }

  .line{
    height: ${props => ((props.barHeight || 100) - 100) * -1}%;
    width:3px;
    background-color:${props => props.theme.colors.lighterWhite} ;
  }

  .bar-color{
    height: ${props => props.barHeight || 0}%;
    background-color: ${props => props.theme.colors.lighter};
    width:30px;
    border-radius: 15px;
  }

  .bar-color.primary{
    background-color: ${props => props.theme.colors.chart[0]};
  }

  .bar-color.secundary{
    background-color: ${props => props.theme.colors.chart[1]};
  }

  .bar-color.third{
      background-color: ${props => props.theme.colors.chart[2]};
  }

  .bar-color.fourth{
      background-color: ${props => props.theme.colors.chart[3]};
  }
`;
