import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Container, Title } from './styles';

const Button = ({
  id,
  title, onClick, children, className, disabled, tooltip, bordered,
}) => (
  <>
    <Container id={id} data-tip={tooltip || null} data-for={`tooltip-${id}`} onClick={onClick} bordered={bordered} space={children} iconAlone={!title} className={className} disabled={disabled}>
      {children}
      {title && <Title bordered={bordered}>{title}</Title>}
    </Container>
    <ReactTooltip place="right" effect="solid" id={`tooltip-${id}`} />
  </>
);

Button.defaultProps = {
  disabled: false,
  className: null,
  tooltip: null,
  bordered: false,
};

Button.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  tooltip: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
  ]).isRequired,
  disabled: PropTypes.bool,
  bordered: PropTypes.bool,
};

Button.defaultProps = {
  title: null,
};


export default Button;
