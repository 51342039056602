/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import uuidv4 from 'uuid/v4';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FaTimes,
  FaQuestionCircle,
  FaChevronLeft,
  FaChevronRight,
  FaTrash,
} from 'react-icons/fa';
import { Creators as CampaignActions } from '~/redux/ducks/campaign';
import { Creators as TermsActions } from '~/redux/ducks/terms';

import Modal from '~/Components/Modal';
import Image from '~/Components/Image';
import Checkbox from '~/Components/Checkbox';

import {
  ContentModal,
  Header,
  CloseHeader,
  Row,
  Body,
  Footer,
  ListRecipes,
  Recipe,
  ImageRecipe,
  InfoRecipe,
  Description,
  Details,
  FormNewCampaign,
  FormGroup,
  Input,
  Textarea,
  Space,
  Select,
  QuestionLink,
  RecipeAction,
  FooterSteps,
  Step,
  LinkTerms,
} from './styles';

class New extends Component {
  state = {
    recipeSelected: null,
    hasError: false,
    linkTerms: false,
  };

  renderSecondStep = () => {
    const {
      form, types, updateForm,
      products, zones, termsRequest,
      updateProductsListByZone,
      hasProductDefaultNewCampaign,
      hasLinkTermsNewCampaign,
    } = this.props;

    const getSelectProducts = () => products.map(item => ({
      value: item.uuid,
      label: item.name,
      data: item,
    }));

    const {
      hasError,
      linkTerms,
    } = this.state;

    // const maxProductOffers = 50;
    const maxProductOffers = form.zone
      && form.zone.data
      && form.zone.data.extra
      && form.zone.data.extra.maxProducts
      ? form.zone.data.extra.maxProducts
      : 0;

    return (
      <FormNewCampaign>
        <Row>
          <FormGroup>
            <label>
              Nome
              <span>*</span>
            </label>
            <Input
              invalid={hasError && !form.name}
              maxLength="60"
              placeholder="Digite o nome"
              value={form.name}
              onChange={e => updateForm({
                ...form,
                name: e.target.value,
              })}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label>
              Descrição
            </label>
            <Textarea
              placeholder="Digite a descrição"
              rows="4"
              maxLength="200"
              value={form.description}
              onChange={e => updateForm({
                ...form,
                description: e.target.value,
              })}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup spaceRight={10}>
            <label>
              Tipo
              <span>*</span>
            </label>
            <Select
              invalid={hasError && !form.type}
              placeholder="Selecione Tipo..."
              noOptionsMessage={() => 'Sem registros'}
              value={form.type}
              onChange={type => updateForm({
                ...form,
                type,
              })}
              options={types}
              isClearable
              menuPlacement="top"
              styles={{
                menuList: provided => ({
                  ...provided,
                  maxHeight: 200,
                }),
              }}
            />
          </FormGroup>
          {zones.length > 1 ? (
            <FormGroup>
              <label>
                Modalidade
                <span>*</span>
              </label>
              <Select
                invalid={hasError && !form.zone}
                placeholder="Selecione Modalidade..."
                noOptionsMessage={() => 'Sem registros'}
                value={form.zone}
                onChange={(zone) => {
                  updateForm({
                    ...form,
                    zone,
                  });
                  if (!hasProductDefaultNewCampaign) {
                    updateProductsListByZone(zone);
                  }
                }}
                options={zones}
                isClearable
                menuPlacement="top"
                styles={{
                  menuList: provided => ({
                    ...provided,
                    maxHeight: 200,
                  }),
                }}
              />
            </FormGroup>
          ) : (
            <Space />
          )}
        </Row>
        {!hasProductDefaultNewCampaign && form.productOffers.map((item, index) => (
          <Row key={item.uuid}>
            <FormGroup spaceRight={10}>
              <label>
                {`${form.productOffers.length > 1 ? `Produto ${index + 1}` : 'Produto'}`}
                <span>*</span>
              </label>
              <Select
                invalid={hasError && !item.product}
                placeholder="Selecione Produto..."
                noOptionsMessage={() => 'Sem registros'}
                value={item.product}
                onChange={(product) => {
                  updateForm({
                    ...form,
                    productOffers: form.productOffers.map((po) => {
                      if (po.uuid === item.uuid) {
                        return {
                          ...po,
                          product,
                          offer: null,
                        };
                      }
                      return po;
                    }),
                  });
                }}
                options={getSelectProducts()}
                isClearable
                menuPlacement="top"
                styles={{
                  menuList: provided => ({
                    ...provided,
                    maxHeight: 200,
                  }),
                }}
              />
            </FormGroup>
            <FormGroup>
              <label>
                {`${form.productOffers.length > 1 ? `Oferta ${index + 1}` : 'Oferta'}`}
                <span>*</span>
              </label>
              <Select
                invalid={hasError && !item.offer}
                placeholder="Selecione Oferta..."
                noOptionsMessage={() => 'Sem registros'}
                value={item.offer}
                onChange={(offer) => {
                  updateForm({
                    ...form,
                    productOffers: form.productOffers.map((po) => {
                      if (po.uuid === item.uuid) {
                        return {
                          ...po,
                          offer,
                        };
                      }
                      return po;
                    }),
                  });
                }}
                options={item.product ? item.product.data.productOffers.map(po => ({
                  value: po.uuid,
                  label: po.name,
                  data: po,
                })) : []}
                isClearable
                menuPlacement="top"
                styles={{
                  menuList: provided => ({
                    ...provided,
                    maxHeight: 200,
                  }),
                }}
              />
            </FormGroup>
            {form.productOffers.length > 1 && (
              <button
                type="button"
                onClick={() => {
                  updateForm({
                    ...form,
                    productOffers: form.productOffers.filter(po => po.uuid !== item.uuid),
                  });
                }}
                className="product-offer-remove"
              >
                <FaTrash />
              </button>
            )}
          </Row>
        ))}
        {maxProductOffers > 1 && form.productOffers.length < maxProductOffers && (
          <Row style={{ justifyContent: 'center' }}>
            <button
              type="button"
              onClick={() => {
                updateForm({
                  ...form,
                  productOffers: [
                    ...form.productOffers,
                    { uuid: uuidv4(), product: null, offer: null },
                  ],
                });
              }}
              className="product-offer-add"
            >
              Incluir novo Produto/Oferta
            </button>
          </Row>
        )}
        {hasLinkTermsNewCampaign && (
          <LinkTerms>
            <Checkbox
              value={linkTerms}
              onChange={(value) => {
                this.setState({
                  linkTerms: value,
                });
              }}
            />
            Li e aceito os
            <a onClick={() => termsRequest()}>
              Termos e Condições de Uso
            </a>
          </LinkTerms>
        )}
      </FormNewCampaign>
    );
  }

  renderFirstStep = () => {
    const { recipes } = this.props;
    const { recipeSelected } = this.state;
    return (
      <ListRecipes>
        {recipes.map(recipe => (
          <Recipe
            key={recipe.uuid}
            active={recipeSelected && recipeSelected.uuid === recipe.uuid}
          >
            <RecipeAction onClick={() => this.setState({ recipeSelected: recipe })}>
              <ImageRecipe>
                <Image
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  src={recipe.previewUrl}
                  alt="recipe.previewUrl"
                />
              </ImageRecipe>
              <InfoRecipe>
                <Description>{recipe.description}</Description>
                <Details>
                  <span>{`Tipo: ${recipe.channel.channelType.type || 'Não definido'}`}</span>
                  <span>{`Canal: ${recipe.channel.name || 'Não definido'}`}</span>
                  <span>{`Formato: ${recipe.creativeType.description || 'Não definido'}`}</span>
                </Details>
              </InfoRecipe>
            </RecipeAction>
            <QuestionLink
              href={recipe.helpUrl}
              target="_blank"
            >
              <FaQuestionCircle />
            </QuestionLink>
          </Recipe>
        ))}
      </ListRecipes>
    );
  }

  handleSubmitForm = () => {
    const {
      confirmCreateCampaign,
      form,
      zones,
    } = this.props;

    let zoneValid = true;
    if (zones.length > 1) {
      if (!form.zone) {
        zoneValid = false;
      }
    }

    let productOffersValid = true;
    form.productOffers.forEach((item) => {
      if (!item.product || !item.offer) {
        productOffersValid = false;
      }
    });

    if (!(
      form.name
      && form.type
      && productOffersValid
      && zoneValid
    )) {
      this.setState({ hasError: true });
    } else {
      confirmCreateCampaign();
    }
  };

  handleClearForm = () => {
    const { updateForm, form } = this.props;
    updateForm({
      ...form,
      name: '',
      description: '',
      type: '',
    });
    this.setState({
      recipeSelected: null,
      hasError: false,
      linkTerms: false,
    });
  };

  handleSelectRecipe = (itemRecipe) => {
    const {
      setRecipe,
      hasProductDefaultNewCampaign,
      updateProductsListByZone, setDefaultProductOffers,
    } = this.props;

    setRecipe(itemRecipe);

    if (hasProductDefaultNewCampaign) {
      setDefaultProductOffers();
      this.setState({
        recipeSelected: null,
        hasError: false,
      });
    } else {
      const { zones } = itemRecipe.channel;
      let productionSearch = null;
      if (zones.length === 1) {
        const zone = zones[0];
        productionSearch = {
          value: zone.uuid,
          label: zone.description,
          data: zone,
        };
      }
      updateProductsListByZone(productionSearch);
    }
  };

  render() {
    const {
      show, state, close, voltarState, form, theme,
      hasLinkTermsNewCampaign,
    } = this.props;
    const {
      recipeSelected,
      hasError,
      linkTerms,
    } = this.state;

    if (!show) {
      return null;
    }

    return (
      <Modal width={750}>
        <ContentModal>
          <Header>
            <h1>Nova Campanha</h1>
            <CloseHeader onClick={() => {
              this.handleClearForm();
              close();
            }}
            >
              <FaTimes color={theme.colors.darker} size={24} />
            </CloseHeader>
          </Header>
          <Body style={{ height: hasLinkTermsNewCampaign ? 460 : 420 }}>
            {state === 'FIRST' && this.renderFirstStep()}
            {state === 'SECOND' && this.renderSecondStep()}
          </Body>
          <Footer>
            <FooterSteps>
              <Step active={state === 'FIRST'} />
              <Step active={state === 'SECOND'} />
            </FooterSteps>
            {state === 'SECOND' && (
              <button
                className="recipe-previous"
                // disabled={!recipeSelected}
                type="button"
                onClick={() => {
                  this.handleClearForm();
                  voltarState();
                }}
              >
                <FaChevronLeft />
                VOLTAR
              </button>
            )}
            {state === 'FIRST' && (
              <button
                className="recipe-next"
                disabled={!recipeSelected}
                type="button"
                onClick={() => {
                  this.handleSelectRecipe(recipeSelected);
                }}
              >
                AVANÇAR
                <FaChevronRight />
              </button>
            )}
            {state === 'SECOND' && (
              <button
                disabled={
                  (hasLinkTermsNewCampaign && !linkTerms)
                  || (hasError && !(form.name && form.type))
                }
                className="recipe-register"
                type="button"
                onClick={() => {
                  this.handleSubmitForm();
                }}
              >
                CADASTRAR
              </button>
            )}
          </Footer>
        </ContentModal>
      </Modal>
    );
  }
}

New.defaultProps = {
  show: false,
  state: 'FIRST',
  recipes: [],
  types: [],
  zones: [],
  form: null,
  hasProductDefaultNewCampaign: false,
  hasLinkTermsNewCampaign: false,
};

New.propTypes = {
  show: PropTypes.bool,
  state: PropTypes.string,
  recipes: PropTypes.arrayOf(PropTypes.shape()),
  types: PropTypes.arrayOf(PropTypes.shape()),
  zones: PropTypes.arrayOf(PropTypes.shape()),
  form: PropTypes.shape(),
  products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setRecipe: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
  voltarState: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  updateProductsListByZone: PropTypes.func.isRequired,
  setDefaultProductOffers: PropTypes.func.isRequired,
  confirmCreateCampaign: PropTypes.func.isRequired,
  termsRequest: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
  hasProductDefaultNewCampaign: PropTypes.bool,
  hasLinkTermsNewCampaign: PropTypes.bool,
};

const mapStateToProps = (state) => {
  const {
    campaign: {
      modalNewCampaign,
    },
    auth: {
      hasProductDefaultNewCampaign,
      hasLinkTermsNewCampaign,
    },
  } = state;

  const result = {
    products: state.campaign.productsRaw,
    show: modalNewCampaign.show,
    state: modalNewCampaign.state,
    recipes: modalNewCampaign.recipes,
    types: modalNewCampaign.types,
    zones: modalNewCampaign.zones,
    recipe: modalNewCampaign.recipe,
    form: modalNewCampaign.form,
    hasProductDefaultNewCampaign,
    hasLinkTermsNewCampaign,
  };

  return result;
};

const mapDispatchToProps = dispatch => bindActionCreators({
  setRecipe: CampaignActions.setRecipeNewCampaign,
  voltarState: CampaignActions.voltarStateNewCampaign,
  updateForm: CampaignActions.updateFormNewCampaign,
  updateProductsListByZone: CampaignActions.updateProductsListByZoneNewCampaign,
  setDefaultProductOffers: CampaignActions.setDefaultProductOffersNewCampaign,
  confirmCreateCampaign: CampaignActions.confirmCreateCampaign,
  close: CampaignActions.closeModalNewCampaign,
  termsRequest: TermsActions.termsRequest,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(New));
