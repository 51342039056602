/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import TypeProvedor from './components/TypeProvedor';

import { InternalMediaComponentForm } from '../../Components/InternalMediaComponent';

import {
  FormGroup,
  Row,
  Select,
} from './styles';

class CreativeCustomForm extends Component {
  renderInternoMode = () => {
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      campaign,
      disabledFields,
      modeMidia,
    } = this.props;
    return (
      <InternalMediaComponentForm
        campaign={campaign}
        criativoEdit={criativoEdit}
        campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
        hasError={hasError}
        disabledFields={disabledFields}
        modeMidia={modeMidia}
      />
    );
  };

  renderProvedorMode = () => {
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      campaign,
      disabledFields,
    } = this.props;
    const { content } = criativoEdit;
    return (
      <TypeProvedor
        hasError={hasError}
        campaign={campaign}
        content={content}
        criativoEdit={criativoEdit}
        campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
        disabledFields={disabledFields}
      />
    );
  };

  render() {
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      disabledFields,
      modeMidia,
    } = this.props;

    if (modeMidia) {
      return this.renderInternoMode();
    }

    const { config: { creatives }, creative } = criativoEdit;

    const creativeType = creative ? creative.type : null;
    const isShowTypesCreatives = creatives.length > 1;
    const typeList = [];

    if (isShowTypesCreatives) {
      const creativeInternal = creatives.find(c => c.type === 'internal');
      if (creativeInternal) {
        typeList.push({ label: 'Interno', value: 'internal', creative: creativeInternal });
      }
      const creativeProvedor = creatives.find(c => c.type === 'provider');
      if (creativeProvedor) {
        typeList.push({ label: 'Provedor', value: 'provider', creative: creativeProvedor });
      }
    } else if (!creativeType) {
      campaignEditCriativoRequestSuccess({
        ...criativoEdit,
        content: {
          ...criativoEdit.content,
          mainData: {
            type: creatives[0].type,
          },
        },
      });
    }
    return (
      <>
        {isShowTypesCreatives && (
          <Row>
            <FormGroup>
              <label>
                Tipo
                <span>*</span>
              </label>
              <Select
                isDisabled={disabledFields}
                placeholder="Selecione Tipo..."
                value={creativeType ? typeList.find(t => (t.value === creativeType)) : null}
                onChange={(item) => {
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    creative: item.creative,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        type: item.value,
                      },
                    },
                  });
                }}
                options={typeList}
                invalid={hasError && !creativeType}
              />
            </FormGroup>
          </Row>
        )}

        {creativeType === 'internal' && this.renderInternoMode()}
        {creativeType === 'provider' && this.renderProvedorMode()}
      </>
    );
  }
}

CreativeCustomForm.defaultProps = {
  modeMidia: false,
};
CreativeCustomForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  modeMidia: PropTypes.bool,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(CreativeCustomForm);
