import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import RichTextEditor from 'react-rte';

export function TextEditor({ value, onChange, disabled }) {
  const [editorValue, setEditorValue] = useState(RichTextEditor.createEmptyValue());

  useEffect(() => {
    setEditorValue(RichTextEditor.createValueFromString(value, 'html'));
  }, []);
  return (
    <RichTextEditor
      className="editor-rich"
      disabled={disabled}
      value={editorValue}
      toolbarConfig={{
        display: [
          'INLINE_STYLE_BUTTONS',
        ],
        INLINE_STYLE_BUTTONS: [
          { label: 'Negrito', style: 'BOLD' },
          { label: 'Itálico', style: 'ITALIC' },
          { label: 'Sublinhado', style: 'UNDERLINE' },
        ],
      }}
      onChange={(val) => {
        setEditorValue(val);
        onChange(val.toString('html'));
      }}
    />
  );
}

TextEditor.defaultProps = {
  disabled: false,
};

TextEditor.propTypes = {
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};
