const themeDefault = {
  colors: {
    white: '#FFFFFF',
    lighterWhite: '#F2F2F2',
    lighter: '#D8D8D8',
    light: 'rgba(151, 151, 151, 0.35)',
    regular: '#929292',
    dark: '#5B5B5B',
    darker: '#212121',
    black: '#000000',

    primary: 'rgb(255,109,0)',
    primaryHex: '#ff6d00',
    danger: 'rgb(166,39,30)',
    danger2: 'rgb(255, 59, 45)',
    danger2Hex: '#ff3b2d',
    bgBody: '#FFFFFF',
    checked: '#008000',
    blue: '#339af0',
    warning: '#ffc107',
    warning2: '#e0a800',

    // CHART REPORT CAMPAIGN
    chart: ['#ff6d00', '#F2994A', '#2F80ED', '#F3DB01'],
  },
};

const themeAkross = {
  colors: {
    white: '#FFFFFF',
    lighterWhite: '#F2F2F2',
    lighter: '#D8D8D8',
    light: 'rgba(151, 151, 151, 0.35)',
    regular: '#929292',
    dark: '#5B5B5B',
    darker: '#212121',
    black: '#000000',

    primary: 'rgb(29,33,58)',
    primaryHex: '#1d213a',
    danger: 'rgb(166,39,30)',
    danger2: 'rgb(255, 59, 45)',
    danger2Hex: '#ff3b2d',
    bgBody: '#FFFFFF',
    checked: '#008000',
    blue: '#339af0',
    warning: '#ffc107',
    warning2: '#e0a800',

    // CHART REPORT CAMPAIGN
    chart: ['#1d213a', '#2F80ED', '#F2994A', '#F3DB01'],
  },
};

const themeOi = {
  colors: {
    white: '#FFFFFF',
    lighterWhite: '#F2F2F2',
    lighter: '#D8D8D8',
    light: 'rgba(151, 151, 151, 0.35)',
    regular: '#929292',
    dark: '#5B5B5B',
    darker: '#212121',
    black: '#000000',

    primary: 'rgb(0, 211, 24)',
    primaryHex: '#00d318',
    danger: 'rgb(166,39,30)',
    danger2: 'rgb(255, 59, 45)',
    danger2Hex: '#ff3b2d',
    bgBody: '#FFFFFF',
    checked: '#008000',
    blue: '#339af0',
    warning: '#ffc107',
    warning2: '#e0a800',

    // CHART REPORT CAMPAIGN
    chart: ['#00d318', '#F2994A', '#2F80ED', '#F3DB01'],
  },
};

const hostOiB2B = [
  'admin-dev.oiads.com.br',
  'admin-hml.oiads.com.br', 'admin-pme-hml.oiads.com.br',
  'admin.oiads.com.br', 'admin-pme.oiads.com.br',
];
const hostGDC = [
  'admin2.dev.artemis.com.br',
  'artemis-hml.mobicare.com.br',
  'platform.akross.com.br',
];

const hostAkross = [
  'data-ads-show.akross.com.br',
  'data-ads-show-hml.akross.com.br',
  'data-ads-show-dev.akross.com.br',
];

const validateHostname = (host, hosts) => !!hosts.find(h => h === host);

const getThemeByTenant = () => {
  const { hostname } = window.location;
  if (validateHostname(hostname, hostOiB2B)) {
    return themeOi;
  }

  if (validateHostname(hostname, hostAkross)) {
    return themeAkross;
  }

  if (validateHostname(hostname, hostGDC)) {
    return themeDefault;
  }

  return themeDefault;
};

export default {
  getThemeByTenant,
};
