import uuidv4 from 'uuid/v4';
import ChannelUtil from '~/Utils/ChannelUtil';

/* Types */
export const Types = {
  FILTER_CAMPAIGN: 'campaign/FILTER_CAMPAIGN',
  EXPORT_FILTER_CAMPAIGN: 'campaign/EXPORT_FILTER_CAMPAIGN',
  FILTER_CAMPAIGN_SUCCESS: 'campaign/FILTER_CAMPAIGN_SUCCESS',
  FILTER_CAMPAIGN_ERROR: 'campaign/FILTER_CAMPAIGN_ERROR',
  LOAD_CAMPAIGN_LIST: 'campaign/LOAD_CAMPAIGN_LIST',
  LOAD_CAMPAIGN_LIST_VIEW_SUCCESS: 'campaign/LOAD_CAMPAIGN_LIST_VIEW_SUCCESS',
  CREATE_CAMPAIGN: 'campaign/CREATE_CAMPAIGN',
  CREATE_SUCCESS: 'campaign/CREATE_SUCCESS',
  DELETE_CAMPAIGN: 'campaign/DELETE_CAMPAIGN',
  CLONE_CAMPAIGN: 'campaign/CLONE_CAMPAIGN',
  LOAD_CAMPAIGN_EDIT: 'campaign/LOAD_CAMPAIGN_EDIT',
  LOAD_CAMPAIGN_EDIT_SUCCESS: 'campaign/LOAD_CAMPAIGN_EDIT_SUCCESS',
  SET_CAMPAIGN_VALUE: 'campaign/SET_CAMPAIGN_VALUE',
  UPDATE_CAMPAIGN_EDIT: 'campaign/UPDATE_CAMPAIGN_EDIT',
  UPDATE_QNT_MAX: 'campaign/UPDATE_QNT_MAX',
  ACTIVE_CAMPAIGN_EDIT: 'campaign/ACTIVE_CAMPAIGN_EDIT',
  SAVE_CAMPAIGN_EDIT: 'campaign/SAVE_CAMPAIGN_EDIT',
  CANCEL_CAMPAIGN: 'campaign/CANCEL_CAMPAIGN',
  PAUSE_CAMPAIGN: 'campaign/PAUSE_CAMPAIGN',
  RESUME_CAMPAIGN: 'campaign/RESUME_CAMPAIGN',
  DOWNLOAD_REPORT: 'campaign/DOWNLOAD_REPORT',
  SET_FILTER_CAMPAIGN: 'campaign/SET_FILTER_CAMPAIGN',
  OPEN_MODAL_NEW_CAMPAIGN: 'campaign/OPEN_MODAL_NEW_CAMPAIGN',
  SHOW_MODAL_NEW_CAMPAIGN: 'campaign/SHOW_MODAL_NEW_CAMPAIGN',
  CLOSE_MODAL_NEW_CAMPAIGN: 'campaign/CLOSE_MODAL_NEW_CAMPAIGN',
  SET_RECIPE_NEW_CAMPAIGN: 'campaign/SET_RECIPE_NEW_CAMPAIGN',
  VOLTAR_STATE_NEW_CAMPAIGN: 'campaign/VOLTAR_STATE_NEW_CAMPAIGN',
  UPDATE_FORM_NEW_CAMPAIGN: 'campaign/UPDATE_FORM_NEW_CAMPAIGN',
  UPDATE_PRODUCTS_LIST_BY_ZONE_NEW_CAMPAIGN: 'campaign/UPDATE_PRODUCTS_LIST_BY_ZONE_NEW_CAMPAIGN',
  SET_DEFAULT_PRODUCT_OFFERS_NEW_CAMPAIGN: 'campaign/SET_DEFAULT_PRODUCT_OFFERS_NEW_CAMPAIGN',
  SET_PRODUCTS_NEW_CAMPAIGN: 'campaign/SET_PRODUCTS_NEW_CAMPAIGN',
  CONFIRM_CREATE_CAMPAIGN: 'campaign/CONFIRM_CREATE_CAMPAIGN',
  SET_CAMPAIGN_TRANSITION_STATE: 'campaign/SET_CAMPAIGN_TRANSITION_STATE',
  WORKFLOW_STATUS: 'campaign/WORKFLOW_STATUS',
  INCLUDE_TO_PROBE_GRID: 'campaign/INCLUDE_TO_PROBE_GRID',
};

const INIT_EDIT_CAMPAIGN = {
  campaign: null,
  audiences: [],
  vlrBase: 0,
  qntMax: 0,
  fortuna: [],
  partners: [],
  attributes: [],
  fact: [],
  campaignShare: [],
};

const INIT_REPORT_CAMPAIGN = {
  campaign: null,
  totals: null,
  data: [],
};

const INIT_FILTER_CAMPAIGN = {
  product: null,
  channel: null,
  type: null,
  status: null,
  name: '',
  keyword: '',
};

const INIT_FORM_MODAL_NEW_CAMPAIGN = {
  name: '',
  description: '',
  type: '',
  productOffers: [
    { uuid: uuidv4(), product: null, offer: null },
  ],
  zone: null,
};

const INIT_MODAL_NEW_CAMPAIGN = {
  show: false,
  state: 'FIRST',
  recipes: [],
  types: [],
  zones: [],
  recipe: null,
  form: INIT_FORM_MODAL_NEW_CAMPAIGN,
};

const INITIAL_STATE = {
  loading: false,
  data: {
    content: [],
    page: 0,
    pageSize: 0,
    totalElements: 0,
  },
  products: [],
  productsRaw: [],
  channels: [],
  status: [],
  types: [],
  createSuccess: false,
  edit: INIT_EDIT_CAMPAIGN,
  report: INIT_REPORT_CAMPAIGN,
  filter: INIT_FILTER_CAMPAIGN,
  modalNewCampaign: INIT_MODAL_NEW_CAMPAIGN,
  transition: null,
};

export default function campaign(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FILTER_CAMPAIGN:
      return {
        ...state,
        createSuccess: false,
      };
    case Types.FILTER_CAMPAIGN_SUCCESS:
      return { ...state, loading: false, data: action.payload };
    case Types.FILTER_CAMPAIGN_ERROR:
      return {
        ...state,
        loading: false,
        data: [],
        error: action.payload,
      };
    case Types.LOAD_CAMPAIGN_LIST_VIEW_SUCCESS:
      return {
        ...state,
        products: action.payload.products ? action.payload.products.map(item => ({
          value: item.uuid,
          label: item.name,
        })) : [],
        productsRaw: action.payload.products,

        channels: ChannelUtil.mapChannelsForSelect(action.payload.channels),
        status: action.payload.status.map(item => ({
          value: item.uuid,
          label: item.label,
        })),
        types: action.payload.types.map(item => ({
          value: item.uuid,
          label: item.label,
        })),
      };
    case Types.SET_PRODUCTS_NEW_CAMPAIGN:
      return {
        ...state,
        productsRaw: action.payload,
        modalNewCampaign: {
          ...state.modalNewCampaign,
          form: {
            ...state.modalNewCampaign.form,
            productOffers: [{ uuid: uuidv4(), product: null, offer: null }],
          },
        },
      };
    case Types.CREATE_SUCCESS:
      return {
        ...state,
        createSuccess: true,
      };
    case Types.LOAD_CAMPAIGN_EDIT:
      return {
        ...state,
        edit: INIT_EDIT_CAMPAIGN,
      };
    case Types.LOAD_CAMPAIGN_EDIT_SUCCESS:
      return {
        ...state,
        edit: {
          campaign: action.payload.campaign,
          audiences: action.payload.audiences,
          vlrBase: action.payload.vlrBase,
          qntMax: action.payload.qntMax,
          fortuna: action.payload.fortuna,
          partners: action.payload.partners,
          attributes: action.payload.attributes,
          fact: action.payload.fact,
          campaignShare: action.payload.campaignShare,
        },
      };
    case Types.SET_CAMPAIGN_VALUE:
      return {
        ...state,
        edit: {
          campaign: action.payload,
        },
      };
    case Types.UPDATE_CAMPAIGN_EDIT:
      return {
        ...state,
        edit: {
          ...state.edit,
          campaign: action.payload,
        },
      };
    case Types.UPDATE_QNT_MAX:
      return {
        ...state,
        edit: {
          ...state.edit,
          qntMax: action.payload,
        },
      };
    case Types.SET_FILTER_CAMPAIGN:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case Types.SHOW_MODAL_NEW_CAMPAIGN:
      return {
        ...state,
        modalNewCampaign: {
          show: true,
          recipes: action.payload,
        },
      };
    case Types.CLOSE_MODAL_NEW_CAMPAIGN:
      return {
        ...state,
        modalNewCampaign: INIT_MODAL_NEW_CAMPAIGN,
      };
    case Types.SET_RECIPE_NEW_CAMPAIGN:
      return {
        ...state,
        modalNewCampaign: {
          ...state.modalNewCampaign,
          recipe: action.payload,
          types: action.payload.campaignTypes.map(type => ({
            value: type.uuid,
            label: type.label,
          })),
          zones: action.payload.channel.zones.map(zone => ({
            value: zone.uuid,
            label: zone.description,
            data: zone,
          })),
          state: 'SECOND',
          form: INIT_FORM_MODAL_NEW_CAMPAIGN,
        },
      };
    case Types.SET_CAMPAIGN_TRANSITION_STATE:
      return {
        ...state,
        transition: action.payload,
      };
    case Types.VOLTAR_STATE_NEW_CAMPAIGN:
      return {
        ...state,
        modalNewCampaign: {
          ...state.modalNewCampaign,
          recipe: null,
          types: [],
          state: 'FIRST',
          form: INIT_FORM_MODAL_NEW_CAMPAIGN,
        },
      };
    case Types.UPDATE_FORM_NEW_CAMPAIGN:
      return {
        ...state,
        modalNewCampaign: {
          ...state.modalNewCampaign,
          form: action.payload,
        },
      };
    default:
      return state;
  }
}

/* Actions Creators */
export const Creators = {
  loadCampaignList: () => ({
    type: Types.LOAD_CAMPAIGN_LIST,
  }),
  filterCampaign: () => ({
    type: Types.FILTER_CAMPAIGN,
  }),
  exportFilterCampaign: () => ({
    type: Types.EXPORT_FILTER_CAMPAIGN,
  }),
  filterCampaignSuccess: result => ({
    type: Types.FILTER_CAMPAIGN_SUCCESS,
    payload: result,
  }),
  filterCampaignError: error => ({
    type: Types.FILTER_CAMPAIGN_ERROR,
    payload: error,
  }),
  loadCampaignListViewSuccess: result => ({
    type: Types.LOAD_CAMPAIGN_LIST_VIEW_SUCCESS,
    payload: result,
  }),
  createCampaign: newCampaign => ({
    type: Types.CREATE_CAMPAIGN,
    payload: newCampaign,
  }),
  createSuccess: () => ({
    type: Types.CREATE_SUCCESS,
  }),
  deleteCampaign: campaignId => ({
    type: Types.DELETE_CAMPAIGN,
    payload: campaignId,
  }),
  cloneCampaign: campaignId => ({
    type: Types.CLONE_CAMPAIGN,
    payload: campaignId,
  }),
  loadCampaignEdit: campaignId => ({
    type: Types.LOAD_CAMPAIGN_EDIT,
    payload: campaignId,
  }),
  includeToProbeGrid: campaignId => ({
    type: Types.INCLUDE_TO_PROBE_GRID,
    payload: campaignId,
  }),
  loadCampaignEditSuccess: result => ({
    type: Types.LOAD_CAMPAIGN_EDIT_SUCCESS,
    payload: result,
  }),
  setCampaignValue: result => ({
    type: Types.SET_CAMPAIGN_VALUE,
    payload: result,
  }),
  updateCampaignEdit: campaignUpdate => ({
    type: Types.UPDATE_CAMPAIGN_EDIT,
    payload: campaignUpdate,
  }),
  updateQntMax: qntMax => ({
    type: Types.UPDATE_QNT_MAX,
    payload: qntMax,
  }),
  activeCampaignEdit: activeCampaign => ({
    type: Types.ACTIVE_CAMPAIGN_EDIT,
    payload: activeCampaign,
  }),
  saveCampaignEdit: updateCampaign => ({
    type: Types.SAVE_CAMPAIGN_EDIT,
    payload: updateCampaign,
  }),
  cancelCampaign: reason => ({
    type: Types.CANCEL_CAMPAIGN,
    payload: reason,
  }),
  pauseCampaign: reason => ({
    type: Types.PAUSE_CAMPAIGN,
    payload: reason,
  }),
  resumeCampaign: () => ({
    type: Types.RESUME_CAMPAIGN,
  }),
  downloadReport: campaignId => ({
    type: Types.DOWNLOAD_REPORT,
    payload: campaignId,
  }),
  setFilterCampaign: filterField => ({
    type: Types.SET_FILTER_CAMPAIGN,
    payload: filterField,
  }),
  openModalNewCampaign: () => ({
    type: Types.OPEN_MODAL_NEW_CAMPAIGN,
  }),
  showModalNewCampaign: recipes => ({
    type: Types.SHOW_MODAL_NEW_CAMPAIGN,
    payload: recipes,
  }),
  closeModalNewCampaign: () => ({
    type: Types.CLOSE_MODAL_NEW_CAMPAIGN,
  }),
  setRecipeNewCampaign: recipe => ({
    type: Types.SET_RECIPE_NEW_CAMPAIGN,
    payload: recipe,
  }),
  setCampaignTransitionState: transition => ({
    type: Types.SET_CAMPAIGN_TRANSITION_STATE,
    payload: transition,
  }),
  voltarStateNewCampaign: () => ({
    type: Types.VOLTAR_STATE_NEW_CAMPAIGN,
  }),
  updateFormNewCampaign: form => ({
    type: Types.UPDATE_FORM_NEW_CAMPAIGN,
    payload: form,
  }),
  updateProductsListByZoneNewCampaign: zone => ({
    type: Types.UPDATE_PRODUCTS_LIST_BY_ZONE_NEW_CAMPAIGN,
    payload: zone,
  }),
  setDefaultProductOffersNewCampaign: () => ({
    type: Types.SET_DEFAULT_PRODUCT_OFFERS_NEW_CAMPAIGN,
  }),
  setProductsNewCampaign: products => ({
    type: Types.SET_PRODUCTS_NEW_CAMPAIGN,
    payload: products,
  }),
  confirmCreateCampaign: () => ({
    type: Types.CONFIRM_CREATE_CAMPAIGN,
  }),
  workflowStatus: () => ({
    type: Types.WORKFLOW_STATUS,
  }),
};
