import queryString from 'query-string';
import api from './axios';

const getDocuments = async (filter) => {
  const filterNew = {
    ...filter,
    year: filter.year ? filter.year.value : null,
    month: filter.month ? filter.month.value : null,
    partnerUuid: filter.partnerUuid ? filter.partnerUuid.value : null,
  };
  try {
    const { data } = await api.get(`documents?${queryString.stringify(filterNew)}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getDocument = async (uuid) => {
  try {
    const { data } = await api.get(`document/${uuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getProviders = async () => {
  try {
    const { data } = await api.get('providers');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getPartners = async () => {
  try {
    const { data } = await api.get('partners');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getArchives = async (filter) => {
  try {
    const { data } = await api.get(`archives?${queryString.stringify(filter)}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const saveInvoice = async ({ uuid, dueDate, amount }) => {
  try {
    const { data } = await api.put(
      `document/${uuid}`,
      {
        dueDate,
        amount,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const saveInvoiceFiles = async ({ uuid, files }) => {
  const formData = new FormData();
  files.forEach((file) => {
    formData.append('file', file);
  });

  try {
    const { data } = await api.post(
      `archive/invoice/${uuid}`,
      formData,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getArchive = async (uuid) => {
  try {
    const { data } = await api.get(
      `archives/${uuid}/download`,
      {
        responseType: 'blob',
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getArchivesFromDocument = async (uuid) => {
  try {
    const { data } = await api.get(`document/${uuid}/archives`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const deleteDocumentArchives = async (uuid) => {
  try {
    const { data } = await api.delete(`document/${uuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const deleteDocumentArchive = async (uuid) => {
  try {
    const { data } = await api.delete(`archive/${uuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const saveReceiptFiles = async ({
  archiveUuid, documentUuid, file,
}) => {
  const formData = new FormData();
  formData.append('file', file);
  try {
    const { data } = await api.put(
      `archive/receipt/${archiveUuid}/${documentUuid}`,
      formData,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  getDocuments,
  getDocument,
  getProviders,
  getPartners,
  getArchives,
  saveInvoice,
  saveInvoiceFiles,
  getArchive,
  getArchivesFromDocument,
  deleteDocumentArchives,
  deleteDocumentArchive,
  saveReceiptFiles,
};
