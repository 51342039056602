/* Types */
export const Types = {
  LOAD_CONTRACT_EDIT: 'contract/LOAD_CONTRACT_EDIT',
  LOAD_CONTRACT_EDIT_SUCCESS: 'contract/LOAD_CONTRACT_EDIT_SUCCESS',
  UPDATE_CONTRACT: 'contract/UPDATE_CONTRACT',
  SAVE_CONTRACT_EDIT: 'contract/SAVE_CONTRACT_EDIT',
  LOAD_CONTRACT_LIST: 'contract/LOAD_CONTRACT_LIST',
  LOAD_CONTRACT_LIST_SUCCESS: 'contract/LOAD_CONTRACT_LIST_SUCCESS',
  UPDATE_FILTER: 'contract/UPDATE_FILTER',
  FILTER_CONTRACT_LIST: 'contract/FILTER_CONTRACT_LIST',
};

const INITIAL_STATE = {
  contracts: [],
  filter: {
    name: null,
  },
  edit: {
    contract: null,
  },
};

export default function contract(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD_CONTRACT_EDIT_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          contract: action.payload,
        },
      };
    case Types.LOAD_CONTRACT_LIST_SUCCESS:
      return {
        ...state,
        contracts: action.payload,
      };
    case Types.UPDATE_CONTRACT:
      return {
        ...state,
        edit: {
          ...state.edit,
          contract: action.payload,
        },
      };
    case Types.UPDATE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
}

/* Actions Creators */
export const Creators = {
  filterContractList: () => ({
    type: Types.FILTER_CONTRACT_LIST,
  }),
  loadContractList: () => ({
    type: Types.LOAD_CONTRACT_LIST,
  }),
  loadContractListSuccess: contracts => ({
    type: Types.LOAD_CONTRACT_LIST_SUCCESS,
    payload: contracts,
  }),
  loadContractEdit: contractId => ({
    type: Types.LOAD_CONTRACT_EDIT,
    payload: contractId,
  }),
  loadContractEditSuccess: contractEdit => ({
    type: Types.LOAD_CONTRACT_EDIT_SUCCESS,
    payload: contractEdit,
  }),
  updateContract: contractUpdate => ({
    type: Types.UPDATE_CONTRACT,
    payload: contractUpdate,
  }),
  saveContractEdit: () => ({
    type: Types.SAVE_CONTRACT_EDIT,
  }),
  updateFilter: filter => ({
    type: Types.UPDATE_FILTER,
    payload: filter,
  }),
};
