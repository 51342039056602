import { validate as validateXml } from 'fast-xml-parser';
/* eslint-disable radix */
const types = [
  {
    id: 'SMS',
    name: 'Sms',
  },
  {
    id: 'PUSH',
    name: 'Push',
  },
  {
    id: 'PAID_NOTIFICATION',
    name: 'Notificação',
  },
];

const getNameType = id => types.find(type => type.id === id).name;

const status = [
  {
    id: 'PROGRAMADO',
    name: 'Programado',
  },
  {
    id: 'ANDAMENTO',
    name: 'Andamento',
  },
  {
    id: 'FINALIZADO',
    name: 'Finalizado',
  },
];

const getNameStatus = id => status.find(s => s.id === id).name;

const hex2rgba = (hex, alpha) => {
  const r = parseInt(hex.substring(1, 3), 16);
  const g = parseInt(hex.substring(3, 5), 16);
  const b = parseInt(hex.substring(5, 7), 16);
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

const stringToFloat = str => parseFloat(`${str}`.replace(',', '.')).toFixed(2);

const stringToInteger = str => parseInt(`${str}`)
  .toLocaleString()
  .replace(',', '.');

const convertFloatReal = (valor, isDecimal = true) => {
  let inteiro = null;

  let decimal = null;

  let c = null;

  let j = null;
  const aux = [];
  valor = `${valor}`;
  c = valor.indexOf('.', 0);
  // encontrou o ponto na string
  if (c > 0) {
    // separa as partes em inteiro e decimal
    inteiro = valor.substring(0, c);
    decimal = valor.substring(c + 1, valor.length);
    if (decimal.length === 1) {
      decimal += '0';
    }
  } else {
    inteiro = valor;
  }

  // pega a parte inteiro de 3 em 3 partes
  for (j = inteiro.length, c = 0; j > 0; j -= 3, c++) {
    aux[c] = inteiro.substring(j - 3, j);
  }

  // percorre a string acrescentando os pontos
  inteiro = '';
  for (c = aux.length - 1; c >= 0; c--) {
    inteiro += `${aux[c]}.`;
  }
  // retirando o ultimo ponto e finalizando a parte inteiro

  inteiro = inteiro.substring(0, inteiro.length - 1);

  decimal = parseInt(decimal);
  if (isNaN(decimal)) {
    decimal = '00';
  } else {
    decimal = `${decimal}`;
    if (decimal.length === 1) {
      decimal = `0${decimal}`;
    } else if (decimal.length > 2) {
      decimal = decimal.substr(0, 2);
    }
  }
  if (isDecimal) {
    valor = `${inteiro},${decimal}`;
  } else {
    valor = `${inteiro}`;
  }
  return valor;
};

const importImages = (r) => {
  const images = {};
  r.keys().map((item, index) => (images[item.replace('./', '')] = r(item)));
  return images;
};

const replaceAll = (value, search, replacement) => value.replace(new RegExp(search, 'g'), replacement);

const base62 = {
  charset: '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split(''),
  encode: (integer) => {
    let value = integer;
    if (value === 0) {
      return 0;
    }
    let s = [];
    while (value > 0) {
      s = [base62.charset[value % 62], ...s];
      value = Math.floor(value / 62);
    }
    return s.join('');
  },
  decode: chars => chars
    .split('')
    .reverse()
    .reduce((prev, curr, i) => prev + base62.charset.indexOf(curr) * 62 ** i, 0),
};

const unmaskToNumber = value => value.replace(/[^0-9]/g, '');

const normalizeDecimal = (value, convert = true, fixed = 2) => {
  const floatValue = parseFloat(value).toFixed(fixed);

  if (convert) return convertFloatReal(floatValue, fixed !== 0);

  return floatValue;
};

const tileTolonlat = (z, x, y) => {
  const tile2long = () => (x / (2 ** z) * 360 - 180);
  const tile2lat = () => {
    const n = Math.PI - 2 * Math.PI * y / (2 ** z);
    return (180 / Math.PI * Math.atan(0.5 * (Math.exp(n) - Math.exp(-n))));
  };

  return [
    tile2lat(),
    tile2long(),
  ];
};

const validateTagGA = (value) => {
  if (value) {
    if (value.length > 100) {
      return false;
    }
    return /^[a-zA-Z0-9_-]*$/.test(value);
  }
  return true;
};

const validateUrl = (value) => {
  if (value && value.indexOf) {
    if (value.indexOf('http://') === 0 || value.indexOf('https://') === 0) {
      return true;
    }
  }
  return false;
};

const getBudgetLabelString = (budget, sign = true) => {
  const convertBudget = parseFloat(budget);
  if (convertBudget && convertBudget > 0) {
    return sign ? `R$ ${convertFloatReal(convertBudget)}` : convertFloatReal(convertBudget);
  }
  return 'Ilimitado';
};

const formatNumber = value => new Intl.NumberFormat({ style: 'decimal' }).format(value);

const formatNumberDailyLimit = (content) => {
  const regExpBeforeBrackets = /^(\S+)/;
  const regExpInsideBrackets = /(?<=\().*(?=\))/;

  return content.replace(regExpBeforeBrackets, m => `${formatNumber(parseInt(m))}`).replace(regExpInsideBrackets, m => `${formatNumber(parseInt(m))}`);
};

const getFormattedValueByType = (type, value = 0) => {
  switch (type) {
    case 'decimal': return normalizeDecimal(value, true, 0);
    case 'float': return normalizeDecimal(value);
    case 'percentage': return `${normalizeDecimal(value)}%`;
    default: return value;
  }
};

export const formatBytes = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  const number = Math.round(bytes / Math.pow(1024, i), 2);

  return `${getFormattedValueByType('decimal', number)} ${sizes[i]}`;
};

const getPercent = (value, total) => {
  const result = (value / total) * 100;
  if (!result) {
    return 0;
  }
  if (result > 100) {
    return 100;
  }
  return Math.floor(result);
};

const xmlIsValid = (xml = '') => validateXml(xml) === true;

export {
  hex2rgba,
  stringToFloat,
  stringToInteger,
  convertFloatReal,
  importImages,
  types,
  getNameType,
  status,
  getNameStatus,
  replaceAll,
  base62,
  unmaskToNumber,
  normalizeDecimal,
  tileTolonlat,
  validateUrl,
  validateTagGA,
  getBudgetLabelString,
  formatNumber,
  formatNumberDailyLimit,
  getFormattedValueByType,
  xmlIsValid,
  getPercent,
};
