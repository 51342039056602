import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { push as pushRouter } from 'connected-react-router';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Pagination from '~/Components/Pagination';
import Button from '~/Components/Button';
import CampaignIcon from '~/Components/CampaignIcon';
import { Creators as ApprovalActions } from '~/redux/ducks/approval';
import { Creators as CampaignIconActions } from '~/redux/ducks/campaignIcon';

import {
  Container,
  Table,
  Row,
  Input,
  Select,
  LinkButton,
  ButtonGroup,
} from './styles';

class CampanhasApprovalList extends Component {
  uuidTenant = localStorage.getItem('uuidTenant');

  componentDidMount() {
    const { loadCampaignApprovalList, campaignIconLoadCampaignTypes } = this.props;
    campaignIconLoadCampaignTypes();
    loadCampaignApprovalList();
  }

  formatDateTime = (date, hour) => {
    if (!date || !hour) {
      return '';
    }
    const value = `${date.split('T')[0]} ${hour}`;
    return value;
  };

  onChangePage = (page) => {
    const { onFilter, updateDataSearch } = this.props;
    updateDataSearch({ page });
    onFilter();
  };

  renderRowCampaign = (campaign) => {
    const { push } = this.props;
    return (
      <tr key={campaign.uuid}>
        <td>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CampaignIcon
              name={campaign.campaignType.name}
            />
          </div>
        </td>
        <td className="link">
          <span>{campaign.name}</span>
        </td>
        <td>
          <span>{campaign.contract.name}</span>
        </td>
        {this.handleDisplayProductOffer() && (
          <td>
            {campaign.productOffers.map(item => (
              <span key={item.uuid}>
                {`${item.product.name}`}
                <br />
              </span>
            ))}
          </td>
        )}
        {this.handleDisplayKeyword() && (
          <td>
            {campaign.productOffers.map(item => (
              <span key={item.uuid}>
                {`${item.CampaignProductOffer.keyword}`}
                <br />
              </span>
            ))}
          </td>
        )}
        <td>
          <span>{moment(campaign.updatedAt).format('DD/MM/YYYY')}</span>
        </td>
        <td>
          <span>{campaign.campaignStatus.label}</span>
        </td>
        <td>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button bordered title="Visualizar" onClick={() => push(`approval/view/${campaign.uuid}`)} />
          </div>
        </td>
      </tr>
    );
  };

  renderCampaigns = () => {
    const { campaigns } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th style={{ width: 60 }}>TIPO</th>
            <th>NOME</th>
            <th style={{ width: 180 }}>CONTRATO</th>
            {this.handleDisplayProductOffer() && (
              <th style={{ width: 200 }}>PRODUTO</th>
            )}
            {this.handleDisplayKeyword() && (
              <th style={{ width: 180 }}>KEYWORD</th>
            )}
            <th style={{ width: 200 }}>ÚLTIMA ALTERAÇÃO</th>
            <th style={{ width: 180 }}>STATUS</th>
            <th style={{ width: 250 }} />
          </tr>
        </thead>
        <tbody>
          {campaigns.length < 1 && (
            <tr>
              <td colSpan="8">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de campanhas</span>
                </div>
              </td>
            </tr>
          )}
          {campaigns.length > 0 && campaigns.map(campaign => this.renderRowCampaign(campaign))}
        </tbody>
      </Table>
    );
  };

  onResetFilter = () => {
    const { updateDataSearch, statusList, onFilter } = this.props;
    const status = statusList.find(item => item.name === 'WAITING_APPROVAL');
    updateDataSearch({
      product: null,
      channel: null,
      type: null,
      contract: null,
      name: '',
      status,
    });
    onFilter();
  };

  handleDisplayKeyword() {
    return this.uuidTenant === '46a8b5fc-6636-40c8-821f-15b34b739519'
      || this.uuidTenant === 'd2b07c25-09b0-43ad-81d7-cb0588c566c8'
      || this.uuidTenant === '263cbc92-3c29-42e5-b59f-f7141eefb56d';
  }

  handleDisplayProductOffer() {
    return this.uuidTenant !== '27e9610a-6d78-4ddd-af88-003eb8cb9b40'
    && this.uuidTenant !== '2563fe93-87c1-494e-983e-509bb03e4ade';
  }

  render() {
    const {
      onExportFilter,
      products,
      channels,
      types,
      contracts,
      statusList,
      totalPages,
      page,
      campaigns,
      search,
      updateDataSearch,
    } = this.props;
    return (
      <>
        <Container>
          <Row>
            <h1>Aprovação Campanha</h1>
          </Row>
          <Row>
            <div className="box-search">
              <Input
                placeholder="Procurar por nome"
                value={search.name}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.onChangePage(0);
                  }
                }}
                onChange={(e) => {
                  updateDataSearch({ name: e.target.value });
                }}
              />
              {this.handleDisplayKeyword() && (
                <Input
                  placeholder="Keyword"
                  value={search.keyword}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.onChangePage(0);
                    }
                  }}
                  onChange={(e) => {
                    updateDataSearch({ keyword: e.target.value });
                  }}
                />
              )}
              <Select
                placeholder="Selecione Status..."
                value={search.status}
                onChange={(status) => {
                  updateDataSearch({ status });
                }}
                options={statusList}
                isClearable
              />
              <Select
                placeholder="Selecione Contrato..."
                value={search.contract}
                onChange={(contract) => {
                  updateDataSearch({ contract });
                }}
                options={contracts}
                isClearable
              />
              {this.handleDisplayProductOffer() && (
                <Select
                  placeholder="Selecione Produto..."
                  value={search.product}
                  onChange={(product) => {
                    updateDataSearch({ product });
                  }}
                  options={products}
                  isClearable
                />
              )}
              <Select
                placeholder="Selecione Canal..."
                value={search.channel}
                onChange={(channel) => {
                  updateDataSearch({ channel });
                }}
                options={channels}
                isClearable
              />
              <Select
                placeholder="Selecione Tipo..."
                value={search.type}
                onChange={(type) => {
                  updateDataSearch({ type });
                }}
                options={types}
                isClearable
              />
              <ButtonGroup>
                <LinkButton
                  data-tip="Buscar dados"
                  data-for="tooltip-search"
                  onClick={() => {
                    this.onChangePage(0);
                  }}
                >
                  Buscar
                </LinkButton>
                <Button
                  icon="FaTimes"
                  noLabel
                  data-tip="Limpar filtro"
                  data-for="tooltip-search"
                  onClick={() => {
                    this.onResetFilter();
                  }}
                />
              </ButtonGroup>
              <Button
                onClick={() => onExportFilter()}
                data-tip="Exportar dados"
                data-for="tooltip-search"
                noLabel
                bordered
                icon="FaDownload"
              />
              <ReactTooltip id="tooltip-search" place="left" effect="solid" />
            </div>
          </Row>
          {this.renderCampaigns()}
          {campaigns.length > 0 && (
            <Row>
              <Pagination
                page={parseInt(page, 10)}
                pageCount={totalPages}
                pageChange={(e) => {
                  this.onChangePage(e.selected);
                }}
              />
            </Row>
          )}
        </Container>
      </>
    );
  }
}

CampanhasApprovalList.defaultProps = {
  contracts: [],
  channels: [],
  products: [],
  statusList: [],
};

CampanhasApprovalList.propTypes = {
  loadCampaignApprovalList: PropTypes.func.isRequired,
  onFilter: PropTypes.func.isRequired,
  onExportFilter: PropTypes.func.isRequired,
  campaigns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  totalPages: PropTypes.number.isRequired,
  page: PropTypes.number.isRequired,
  push: PropTypes.func.isRequired,
  search: PropTypes.shape({
    name: PropTypes.string,
    keyword: PropTypes.string,
    status: PropTypes.shape(),
    product: PropTypes.shape(),
    channel: PropTypes.shape(),
    type: PropTypes.shape(),
    contract: PropTypes.shape(),
  }).isRequired,
  updateDataSearch: PropTypes.func.isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()),
  channels: PropTypes.arrayOf(PropTypes.shape()),
  types: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape()),
  statusList: PropTypes.arrayOf(PropTypes.shape()),
  campaignIconLoadCampaignTypes: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const {
    content,
    page,
    totalElements,
    pageSize,
  } = state.approval.data;
  const totalPages = Math.ceil(totalElements / pageSize);

  const result = {
    page: parseInt(page),
    totalPages,
    campaigns: content,
    search: state.approval.search,
    products: state.approval.products,
    channels: state.approval.channels,
    types: state.approval.types,
    contracts: state.approval.contracts,
    statusList: state.approval.statusList,
  };
  return result;
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadCampaignApprovalList: ApprovalActions.loadCampaignList,
    onFilter: ApprovalActions.filterCampaign,
    onExportFilter: ApprovalActions.exportFilterCampaign,
    updateDataSearch: ApprovalActions.updateDataSearch,
    push: pushRouter,
    campaignIconLoadCampaignTypes: CampaignIconActions.campaignIconLoadCampaignTypes,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampanhasApprovalList);
