/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import Upload from '~/Components/Upload';
import Image from '~/Components/Image';
import Button from '~/Components/Button';

import UploadService, { baseUrlMedia } from '~/services/UploadService';

import LandingPage from '../../Components/LandingPage';

import {
  FormGroup,
  Input,
  Row,
  Imagem,
  BannerEmpty,
  ErrorMessage,
} from './styles';

class SponsorNotifyMediaMessageForm extends Component {
  state = {
    showMessageErrorMaxSizeFileImage: false,
  };

  getTitleIsInvalid = () => {
    const { criativoEdit } = this.props;
    const { content } = criativoEdit;

    if (content.channelData) {
      if (content.channelData.title) {
        return false;
      }
    }
    return true;
  };

  getSubtitleIsInvalid = () => {
    const { criativoEdit } = this.props;
    const { content } = criativoEdit;

    if (content.channelData) {
      if (content.channelData.subtitle) {
        return false;
      }
    }
    return true;
  };

  uploadFileToAWS = async (campaignUuid, file) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;

    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  handleSuccessImage = (image) => {
    const {
      campaignEditCriativoRequestSuccess,
      criativoEdit,
    } = this.props;
    const { content } = criativoEdit;

    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        channelData: {
          ...content.channelData,
          image,
        },
      },
    });
  };

  uploadFileImage = async (files) => {
    const { campaign } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.handleSuccessImage(url);
    }
  };

  render() {
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      campaign,
      disabledFields,
    } = this.props;
    const {
      showMessageErrorMaxSizeFileImage,
    } = this.state;

    const { content } = criativoEdit;

    return (
      <>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <label>
              Título
              <span>*</span>
              -
              <small>
                {` ${
                  content.channelData && content.channelData.title
                    ? content.channelData.title.length : 0}/37 caracteres`}
              </small>
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite o título"
              maxLength={37}
              onChange={(e) => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...criativoEdit.content,
                    channelData: {
                      ...criativoEdit.content.channelData,
                      title: e.target.value,
                    },
                  },
                });
              }}
              value={content.channelData ? content.channelData.title || '' : ''}
              invalid={hasError && this.getTitleIsInvalid()}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <label>
              Subtítulo
              <span>*</span>
              -
              <small>
                {` ${
                  content.channelData && content.channelData.subtitle
                    ? content.channelData.subtitle.length : 0}/37 caracteres`}
              </small>
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Subtítulo"
              maxLength={37}
              onChange={(e) => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...criativoEdit.content,
                    channelData: {
                      ...criativoEdit.content.channelData,
                      subtitle: e.target.value,
                    },
                  },
                });
              }}
              value={content.channelData ? content.channelData.subtitle || '' : ''}
              invalid={hasError && this.getSubtitleIsInvalid()}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label>
              Banner
            </label>
            <Row>
              <Imagem invalid={false /* hasError && this.getImageIsInvalid() */}>
                {content.channelData && content.channelData.image ? (
                  <Image
                    className="success-img"
                    src={content.channelData.image}
                    alt="Banner"
                  />
                ) : (
                  <BannerEmpty>Sem imagem de visualização</BannerEmpty>
                )}
              </Imagem>
              <FormGroup style={{ marginLeft: '20px' }}>
                <label>Formatos: PNG, JPG</label>
                <label>Dimensão recomendada: 1024x512.</label>
                {showMessageErrorMaxSizeFileImage && (
                  <ErrorMessage>
                    Arquivo excedeu o limite de 2MB.
                  </ErrorMessage>
                )}
                <div style={{ display: 'flex' }}>
                  <Upload
                    disabled={disabledFields}
                    label={content.channelData && content.channelData.image ? 'ALTERAR' : 'ADICIONAR'}
                    onUpload={this.uploadFileImage}
                    accept="image/png,image/jpg,image/jpeg"
                    maxSize={2000000}
                    onRejectMaxSize={() => {
                      this.setState({ showMessageErrorMaxSizeFileImage: true });
                    }}
                    onClick={() => {
                      this.setState({ showMessageErrorMaxSizeFileImage: false });
                    }}
                  />
                  {content.channelData && content.channelData.image && (
                    <Button
                      disabled={disabledFields}
                      style={{ marginTop: '15px' }}
                      title="REMOVER"
                      onClick={() => {
                        this.handleSuccessImage('');
                      }}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
          </FormGroup>
        </Row>
        <LandingPage
          title="Configuração da Landing Page"
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          hasError={hasError}
          disabledFields={disabledFields}
        />
      </>
    );
  }
}

SponsorNotifyMediaMessageForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(SponsorNotifyMediaMessageForm);
