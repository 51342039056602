import React, { Component } from 'react';
import moment from 'moment';
import { FaTimes } from 'react-icons/fa';
import { AiFillInfoCircle } from 'react-icons/ai';
import Dropzone from 'react-dropzone';
import {
  Map, TileLayer,
} from 'react-leaflet';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import './css.css';

import axios from '~/services/axios';
import Breadcrumb from '~/Components/Breadcrumb';
import ModalUploadProgress from './components/ModalUploadProgress';
import MarkerList from './components/MarkerList';
import Button from '~/Components/Button';
import Modal from '~/Components/Modal';
import { Creators as PointsManagementActions } from '~/redux/ducks/pointsManagement';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import { baseUrlMedia } from '~/services/UploadService';

import {
  Container,
  Row,
  Header,
  Content,
  // IconBox,
  // ButtonLink,
  // IconMarker,
  // ActionsBox,
  // UploadIcon,
  // UploadFile,
  // UploadBox,
  DescriptionBox,
  DescriptionBoxItem,
  ButtonsBox,
  ButtonCloseModal,
  RowFooter,
  ModalAlertMessage,
  ModalContentUpload,
  ButtonRemoveFile,
  DropzoneBaseBox,
  DropzoneIconBox,
} from './styles';

class PointsManagement extends Component {
  state = {
    modalProgressOpen: false,
    modalProgress: 0,
    statusModalDownloadBase: false,
    statusModalUpdateBase: false,
    dropzoneBaseFile: null,
    dropzoneIconFile: null,
    dropzoneIconFileBase64: null,
  };

  leafletMapRef = null;

  contentRef = null;

  static propTypes = {};

  componentDidMount() {
    const {
      pointsManagementGeoPointsRequest,
      pointsManagementGeoPointsRequestSuccess,
      pointsManagementGeoPointsIconRequest,
      pointsManagementGeoPointsIconRequestSuccess,
    } = this.props;
    pointsManagementGeoPointsRequestSuccess(null);
    pointsManagementGeoPointsRequest();

    pointsManagementGeoPointsIconRequestSuccess(null);
    pointsManagementGeoPointsIconRequest();
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  clearLayers = () => {
    const { leafletElement } = this.leafletMapRef;
    leafletElement.eachLayer((layer) => {
      if (layer.options && !layer.options.url) {
        layer.remove();
      }
    });
  };

  getCustomIcon = () => {
    const { iconPoint } = this.props;
    return new L.Icon({
      iconUrl: iconPoint,
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
    });
  };

  handleUploadForm = async () => {
    const {
      pointsManagementGeoPointsRequest,
      pointsManagementGeoPointsRequestSuccess,
      errorMessage,
      successMessage,
      warningMessage,
      pointsManagementGeoPointsIconSave,
    } = this.props;
    const {
      dropzoneBaseFile,
      dropzoneIconFile,
    } = this.state;
    if (dropzoneBaseFile) {
      const config = {
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          this.setState({ modalProgress: percentCompleted });
        },
      };
      const data = new FormData();
      data.append('file', dropzoneBaseFile);
      try {
        this.setState({ modalProgressOpen: true });
        await axios.put('mcare-artemis-audience-service/geopoints', data, config);
        this.clearLayers();
        pointsManagementGeoPointsRequestSuccess(null);
        setTimeout(() => {
          this.setState({ modalProgressOpen: false, modalProgress: 0 });
          pointsManagementGeoPointsRequest();
        }, 200);
        successMessage(
          'Sucesso!',
          'Arquivo enviado com sucesso!',
        );
      } catch (error) {
        this.setState({ modalProgressOpen: false, modalProgress: 0 });
        if (error.response && error.response.data && error.response.data.detail) {
          warningMessage(
            'Atenção!',
            error.response.data.detail,
          );
        } else {
          errorMessage(
            'Atenção!',
            'Houve um problema ao enviar o arquivo. Tente novamente mais tarde!',
          );
        }
      }
    }
    if (dropzoneIconFile) {
      pointsManagementGeoPointsIconSave(dropzoneIconFile);
    }
    this.setState({
      dropzoneBaseFile: null,
      dropzoneIconFile: null,
      dropzoneIconFileBase64: null,
      statusModalUpdateBase: false,
    });
  };

  renderHeader = () => {
    const {
      geopoints,
      // iconPoint,
      // pointsManagementGeoPointsIconSave,
      // errorMessage,
    } = this.props;
    // const modelUri = '${baseUrlMedia}/model_template_geofence.csv';
    return (
      <Header>
        <Breadcrumb
          items={[
            {
              route: '/',
              name: 'Home',
            },
            {
              name: 'Rede de Estabelecimentos',
            },
          ]}
        />
        <Row style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 10 }}>
          <h1>REDE DE ESTABELECIMENTOS</h1>
          <ButtonsBox>
            <Button
              bordered
              style={{ margin: 0, marginLeft: 0 }}
              title="BAIXAR BASE"
              onClick={() => this.setState({ statusModalDownloadBase: true })}
            />
            <Button
              style={{ margin: 0, marginLeft: 10 }}
              title="ATUALIZAR BASE"
              onClick={() => this.setState({ statusModalUpdateBase: true })}
            />
          </ButtonsBox>
        </Row>
        <DescriptionBox>
          <DescriptionBoxItem>
            <b>Base atual:</b>
            <span>{`${geopoints && geopoints.totalPoints ? geopoints.totalPoints : 0} pontos cadastrados`}</span>
          </DescriptionBoxItem>
          <DescriptionBoxItem>
            <b>Última atualização:</b>
            <span>{geopoints && geopoints.geoPoint && geopoints.geoPoint.updatedAt ? moment(geopoints.geoPoint.updatedAt).format('DD/MM/YYYY HH:mm') : 'Sem informação'}</span>
          </DescriptionBoxItem>
          <DescriptionBoxItem>
            <b>Último autor:</b>
            <span>{`${geopoints && geopoints.geoPoint && geopoints.geoPoint.user && geopoints.geoPoint.user.name ? geopoints.geoPoint.user.name : 'Sem informação'}`}</span>
          </DescriptionBoxItem>
          <DescriptionBoxItem>
            <b>Tamanho do arquivo:</b>
            <span>{`${geopoints && geopoints.geoPoint && geopoints.geoPoint.sizeBytes ? `${geopoints.geoPoint.sizeBytes} bytes` : 'Sem informação'}`}</span>
          </DescriptionBoxItem>
        </DescriptionBox>
      </Header>
    );
  };

  renderMap = () => {
    const { geopoints: geo } = this.props;
    const position = [-22.905503, -43.262965];
    const tileOption = {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      url: 'https://dq0fosatyiphx.cloudfront.net/osm-intl/{z}/{x}/{y}.png',
    };
    return !!this.contentRef && (
      <Map
        ref={(ref) => {
          this.leafletMapRef = ref;
        }}
        className="custom-map"
        center={position}
        zoom={8}
        maxZoom={18}
        minZoom={5}
        style={{
          width: this.contentRef.clientWidth,
          height: this.contentRef.clientHeight,
        }}
      >
        <TileLayer attribution={tileOption.attribution} url={tileOption.url} />
        {!!geo && <MarkerList icon={this.getCustomIcon()} markers={geo.geoPoints} />}
      </Map>
    );
  };

  renderContent = () => {
    const { iconPoint } = this.props;
    return (
      <Content ref={(ref) => {
        if (ref) {
          this.contentRef = ref;
        }
      }}
      >
        {!!iconPoint && this.renderMap()}
      </Content>
    );
  };

  renderModalDownloadBase = () => {
    const { geopoints } = this.props;
    return (
      <Modal width={350}>
        <Row>
          <h1>BAIXAR BASE</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              this.setState({ statusModalDownloadBase: false });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <Row>
          <DescriptionBoxItem>
            <b>Último autor:</b>
            <span>{`${geopoints && geopoints.geoPoint && geopoints.geoPoint.user && geopoints.geoPoint.user.name ? geopoints.geoPoint.user.name : 'Sem informação'}`}</span>
          </DescriptionBoxItem>
        </Row>
        <Row>
          <DescriptionBoxItem>
            <b>Última atualização:</b>
            <span>{geopoints && geopoints.geoPoint && geopoints.geoPoint.updatedAt ? moment(geopoints.geoPoint.updatedAt).format('DD/MM/YYYY HH:mm') : 'Sem informação'}</span>
          </DescriptionBoxItem>
          <DescriptionBoxItem>
            <b>Tamanho do arquivo:</b>
            <span>{`${geopoints && geopoints.geoPoint && geopoints.geoPoint.sizeBytes ? `${geopoints.geoPoint.sizeBytes} bytes` : 'Sem informação'}`}</span>
          </DescriptionBoxItem>
        </Row>
        <RowFooter style={{ justifyContent: 'center' }}>
          <Button
            title="REALIZAR DOWNLOAD"
            disabled={!geopoints || !geopoints.geoPoint || !geopoints.geoPoint.geoPointUrl}
            onClick={() => {
              window.open(geopoints.geoPoint.geoPointUrl, '_blank');
              this.setState({ statusModalDownloadBase: false });
            }}
            style={{ width: '80%' }}
          />
        </RowFooter>
      </Modal>
    );
  }

  renderModalUpdateBase = () => {
    const { dropzoneBaseFile, dropzoneIconFileBase64, dropzoneIconFile } = this.state;
    const { iconPoint } = this.props;
    const alertMessage = 'Para atualizar a base, basta realizar o upload de um arquivo CSV seguindo o modelo disponível para download abaixo.';
    const modelUri = `${baseUrlMedia}/model_template_geofence.csv`;
    return (
      <Modal width={420}>
        <Row>
          <h1>ATUALIZAR BASE</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              this.setState({ statusModalUpdateBase: false });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <ModalAlertMessage>{alertMessage}</ModalAlertMessage>
        <ModalContentUpload>
          <div className="modal-content-upload">
            <label>
              Arquivo
              <a href={modelUri} target="_blank" rel="noreferrer">
                Faça download do arquivo modelo
              </a>
            </label>
            <DropzoneBaseBox>

              <Dropzone
                onDrop={(acceptedFiles) => {
                  if (acceptedFiles.length > 0) {
                    this.setState({ dropzoneBaseFile: acceptedFiles[0] });
                  }
                }}
                multiple={false}
                accept=".csv"
              >
                {({ getRootProps, getInputProps }) => {
                  let statusDrop = '';
                  if (dropzoneBaseFile) statusDrop = 'dropzone-file-ok';
                  return (
                    <div className={`dropzone-base-file ${statusDrop}`} {...getRootProps()}>
                      <input {...getInputProps()} />
                      <>
                        {dropzoneBaseFile ? dropzoneBaseFile.name : 'Clique aqui ou arraste o CSV'}
                      </>
                    </div>
                  );
                }}
              </Dropzone>
              {!!dropzoneBaseFile && (
                <ButtonRemoveFile onClick={() => this.setState({ dropzoneBaseFile: null })}>
                  <FaTimes />
                </ButtonRemoveFile>
              )}
            </DropzoneBaseBox>
          </div>
          <div className="modal-content-upload">
            <label>
              Ícone
              <i>
                <AiFillInfoCircle />
                Ícone que irá aparecer nos pontos do mapa
              </i>
            </label>
            <div className="icon-description-box">
              <DropzoneIconBox>

                <Dropzone
                  onDrop={async (acceptedFiles) => {
                    if (acceptedFiles.length > 0) {
                      const base64File = await this.toBase64(acceptedFiles[0]);
                      this.setState({
                        dropzoneIconFile: acceptedFiles[0],
                        dropzoneIconFileBase64: base64File,
                      });
                    }
                  }}
                  multiple={false}
                  accept="image/png"
                  maxSize={10000}
                >
                  {({ getRootProps, getInputProps }) => {
                    let statusDrop = '';
                    if (dropzoneIconFile) statusDrop = 'dropzone-file-ok';
                    return (
                      <div className={`dropzone-icon-file ${statusDrop}`} {...getRootProps()}>
                        <input {...getInputProps()} />
                        <img src={dropzoneIconFileBase64 || iconPoint} alt="Ícone" />
                      </div>
                    );
                  }}
                </Dropzone>
                {!!dropzoneIconFile && (
                  <ButtonRemoveFile
                    onClick={() => {
                      this.setState({
                        dropzoneIconFile: null,
                        dropzoneIconFileBase64: null,
                      });
                    }}
                  >
                    <FaTimes />
                  </ButtonRemoveFile>
                )}
              </DropzoneIconBox>
              <div className="icon-description-box-labels">
                <span>
                  <b>Medidas recomendadas: </b>
                  30 X 30
                </span>
                <span>
                  <b>Tamanho máximo: </b>
                  10kb
                </span>
                <i>Clique ou arraste um ícone ao lado para substituir</i>
              </div>
            </div>
          </div>
        </ModalContentUpload>
        <RowFooter>
          <Button
            disabled={!dropzoneBaseFile && !dropzoneIconFile}
            title="SALVAR"
            onClick={this.handleUploadForm}
          />
        </RowFooter>
      </Modal>
    );
  };

  render() {
    const {
      modalProgressOpen,
      modalProgress,
      statusModalDownloadBase,
      statusModalUpdateBase,
    } = this.state;
    return (
      <>
        <Container>
          {this.renderHeader()}
          {this.renderContent()}
        </Container>
        {statusModalDownloadBase && this.renderModalDownloadBase()}
        {statusModalUpdateBase && this.renderModalUpdateBase()}
        {modalProgressOpen && <ModalUploadProgress progress={modalProgress} />}
      </>
    );
  }
}

PointsManagement.defaultProps = {
  geopoints: null,
  iconPoint: null,
};

PointsManagement.propTypes = {
  geopoints: PropTypes.shape(),
  iconPoint: PropTypes.string,
  pointsManagementGeoPointsRequest: PropTypes.func.isRequired,
  pointsManagementGeoPointsRequestSuccess: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  warningMessage: PropTypes.func.isRequired,
  successMessage: PropTypes.func.isRequired,
  pointsManagementGeoPointsIconRequest: PropTypes.func.isRequired,
  pointsManagementGeoPointsIconRequestSuccess: PropTypes.func.isRequired,
  pointsManagementGeoPointsIconSave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  geopoints: state.pointsManagement.geopoints,
  iconPoint: state.pointsManagement.iconPoint,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    pointsManagementGeoPointsRequest:
      PointsManagementActions.pointsManagementGeoPointsRequest,
    pointsManagementGeoPointsRequestSuccess:
      PointsManagementActions.pointsManagementGeoPointsRequestSuccess,
    pointsManagementGeoPointsIconRequest:
      PointsManagementActions.pointsManagementGeoPointsIconRequest,
    pointsManagementGeoPointsIconRequestSuccess:
      PointsManagementActions.pointsManagementGeoPointsIconRequestSuccess,
    pointsManagementGeoPointsIconSave:
      PointsManagementActions.pointsManagementGeoPointsIconSave,
    errorMessage: ToastrMessageActions.errorMessage,
    warningMessage: ToastrMessageActions.warningMessage,
    successMessage: ToastrMessageActions.successMessage,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PointsManagement);
