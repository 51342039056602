import api from './axios';

const historyByCampaignId = async (campaignId) => {
  try {
    const { data } = await api.get(`campaign/${campaignId}/status/logs`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  historyByCampaignId,
};
