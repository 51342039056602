import styled, { css } from 'styled-components';
import { RowStyled, FormGroupStyled, InputStyled } from '~/Components/Styled';
import CheckboxComponent from '~/Components/Checkbox';
import RadioboxComponent from '~/Components/Radiobox';

export const Row = styled(RowStyled)`
  justify-content: flex-start;
`;

export const FormBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FieldItem = styled(FormGroupStyled)`
  margin-bottom: 20px;
  > label{
    margin-bottom: 5px;
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Input = styled(InputStyled)`
  margin-right: 0;
  width: 600px;
`;

export const FormGroup = styled(FormGroupStyled)`
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const BannerEmpty = styled.div`
  width: 100%;
  max-width: 350px;
  height: 100%;
  max-height: 150px;

  display: flex;
  justify-content: center;
  align-items: center;

  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  position: relative;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  margin-top: 5px;
  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.danger2};
  font-style: italic;
  font-size: 18px;
`;

export const Checkbox = styled(CheckboxComponent)``;

export const Radiobox = styled(RadioboxComponent)``;

export const CheckContainer = styled.div`
  display: flex;
  width: 100%;
  .checkmark {
    border: 2px solid ${props => (props.invalid ? props.theme.colors.danger2 : props.theme.colors.primary)} !important;
  }
  .checkmark-box {
    color: ${props => (props.invalid ? props.theme.colors.danger2 : props.theme.colors.regular)} !important;
  }
`;

export const RadioContainer = styled.div`
  display: flex;
  width: 100%;
  .radio {
    border: 2px solid ${props => (props.invalid ? props.theme.colors.danger2 : props.theme.colors.primary)} !important;
  }
  .radio-box {
    color: ${props => (props.invalid ? props.theme.colors.danger2 : props.theme.colors.regular)} !important;
  }
`;

export const BaseMultiUploads = styled.div`
  display: flex;
  border: 2px solid ${props => props.theme.colors.light};
  border-radius: 5px;

  min-height: 250px;

  ${props => props.invalid && css`
    border-color: ${props.theme.colors.danger2};
  `};
`;

export const ListItemsImage = styled.div`
  display: flex;
  flex-direction: column;
  width: 200px;
`;

export const ItemImageSelected = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-right: 2px solid ${props => props.theme.colors.light};
  cursor: pointer;
  ${props => props.selected && css`
    cursor: default;
    border-top: 2px solid ${props.theme.colors.light};
    border-bottom: 2px solid ${props.theme.colors.light};
    border-right: none;
    font-weight: bold;

    &:first-child {
      border-top: none;
    }
    &:last-child {
      border-bottom: none;
    }
  `};
  ${props => props.invalid && css`
    color: ${props.theme.colors.danger2};
  `};
`;

export const BaseChangeImage = styled.div`
  padding: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  .base-buttons-upload-image {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
  }

  .btn-remover-imagem {
    width: 100%;
    height: 35px;
    margin-top: 15px;
    color: ${props => props.theme.colors.danger2};
    border: 1px solid ${props => props.theme.colors.danger2};
    background-color: ${props => props.theme.colors.danger2Hex}30;
    border-radius: 3px;
  }
`;

export const AddImageMultiUploads = styled.div`
  height: 100%;
  border-right: 2px solid ${props => props.theme.colors.light};
  .baseBtnAdd {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
    cursor: pointer;

    svg {
      margin-right: 10px;
    }
  }
`;
