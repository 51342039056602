import api from './axios';

const actionList = async () => {
  try {
    const { data } = await api.get('behavior-manager/action');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const actionTypeList = async () => {
  try {
    const { data } = await api.get('behavior-manager/action-type');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const eventTypeList = async () => {
  try {
    const { data } = await api.get('behavior-manager/event-type');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};
const behaviorList = async () => {
  try {
    const { data } = await api.get('behavior-manager/behavior');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const behaviorGet = async (behaviorUuid) => {
  try {
    const { data } = await api.get(`behavior-manager/behavior/${behaviorUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const behaviorCreate = async (behavior) => {
  try {
    const { data } = await api.post('behavior-manager/behavior', behavior);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const behaviorUpdate = async (behavior) => {
  try {
    const { data } = await api.put(`behavior-manager/behavior/${behavior.uuid}`, behavior);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const behaviorDelete = async (behaviorUuid) => {
  try {
    await api.delete(`behavior-manager/behavior/${behaviorUuid}`);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  actionList,
  actionTypeList,
  eventTypeList,
  behaviorList,
  behaviorGet,
  behaviorCreate,
  behaviorUpdate,
  behaviorDelete,
};
