import styled from 'styled-components';


export const Header = styled.div`
  top: 0;
  height: 80px;
  padding: 9px 40px;
  width: 100%;
  box-shadow: 0 1px 4px 3px rgba(0, 0, 0, 0.2);
  background-color: ${props => props.theme.colors.bgBody};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  z-index: 100;
  .logo {
    height: 62px;
  }
  .right {
    display: flex;
    align-items: center;

    .box-link {
      cursor: pointer;
      font-weight: bold;
      border: 2px solid;
      padding: 5px 15px;
      border-radius: 5px;
      color: ${props => props.theme.colors.primary};
    }
  }
  .user-box {
    margin-left: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    .user-data {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      h1 {
        margin: 0;
        font-size: 25px;
      }
      span {
        margin: 0;
      }
    }
    .icon-user {
      height: 51px;
      margin-left: 15px;
      border-radius: 50%;
    }
  }
`;

export const Side = styled.div`
  position: fixed;
  left: 0;
  top: 79px;
  padding-top: 40px;
  width: 150px;
  z-index: 99;
  border-right: 1px solid rgba(132, 132, 132, 0.5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  height: calc(100% - 79px);
  background-color: ${props => props.theme.colors.bgBody};
  overflow-y: auto;

  .box-link {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    flex-shrink: 0;
    padding: 0 10px;
  }
  svg {
    margin-bottom: 5px;
  }
  label {
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
    color: ${props => props.theme.colors.dark};
    text-align: center;
  }
`;
