import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  changeModalEditPeriod: ['showModalEditPeriod'],
  changePeriodEdit: ['periodEdit'],
  loadPeriodList: null,
  loadPeriodListSuccess: ['periodList'],
  periodUpdate: null,
});

export const PeriodTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  showModalEditPeriod: false,
  periodEdit: {
    uuid: null,
  },
  periodList: [],
  statusList: [
    { value: 'OPENED', label: 'Aberto' },
    { value: 'CLOSED', label: 'Fechado' },
  ],
});

/* Reducers */
const loadPeriodListSuccess = (state, { periodList }) => ({
  ...state,
  periodList,
});
const changeModalEditPeriod = (state, { showModalEditPeriod }) => ({
  ...state,
  showModalEditPeriod,
});
const changePeriodEdit = (state, { periodEdit }) => ({
  ...state,
  periodEdit: {
    ...state.periodEdit,
    ...periodEdit,
  },
});

export const reducer = createReducer(INITIAL_STATE, {
  [PeriodTypes.LOAD_PERIOD_LIST_SUCCESS]: loadPeriodListSuccess,
  [PeriodTypes.CHANGE_MODAL_EDIT_PERIOD]: changeModalEditPeriod,
  [PeriodTypes.CHANGE_PERIOD_EDIT]: changePeriodEdit,
});
