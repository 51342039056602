/* eslint-disable quotes */
/* eslint-disable comma-dangle */
import React from "react";
import PropTypes from "prop-types";

import { Container, Status } from "./styles";

const SetupStatus = ({ enabled }) => (
  <Container>
    <Status enabled={enabled} />
    {enabled ? "Ativo" : "Inativo"}
  </Container>
);

SetupStatus.propTypes = {
  enabled: PropTypes.bool.isRequired
};

export default SetupStatus;
