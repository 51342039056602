/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import TypeProvedor from './components/TypeProvedor';

import { InternalMediaComponentForm } from '../../Components/InternalMediaComponent';

import {
  FormGroup,
  Row,
  Select,
} from './styles';

class MidiaNoCreditADMessageForm extends Component {
  renderInternoMode = () => {
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      campaign,
      disabledFields,
    } = this.props;
    return (
      <InternalMediaComponentForm
        campaign={campaign}
        criativoEdit={criativoEdit}
        campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
        hasError={hasError}
        disabledFields={disabledFields}
      />
    );
  };

  renderProvedorMode = () => {
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      campaign,
      disabledFields,
    } = this.props;
    const { content } = criativoEdit;
    return (
      <TypeProvedor
        hasError={hasError}
        campaign={campaign}
        content={content}
        criativoEdit={criativoEdit}
        campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
        disabledFields={disabledFields}
      />
    );
  };

  render() {
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      disabledFields,
    } = this.props;

    const { content } = criativoEdit;

    const typeList = [
      { label: 'Interno', value: 'internal' },
      { label: 'Provedor', value: 'provedor' },
    ];

    const typeValue = content.mainData && content.mainData.type ? content.mainData.type : '';

    return (
      <>
        <Row>
          <FormGroup>
            <label>
              Tipo
              <span>*</span>
            </label>
            <Select
              isDisabled={disabledFields}
              placeholder="Selecione Tipo..."
              value={typeList.find(item => item.value === typeValue)}
              onChange={({ value }) => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...criativoEdit.content,
                    mainData: {
                      type: value,
                    },
                  },
                });
              }}
              options={typeList}
              invalid={hasError && !typeValue}
            />
          </FormGroup>
        </Row>

        {content.mainData && content.mainData.type === 'internal' && this.renderInternoMode()}
        {content.mainData && content.mainData.type === 'provedor' && this.renderProvedorMode()}
      </>
    );
  }
}

MidiaNoCreditADMessageForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(MidiaNoCreditADMessageForm);
