import styled from 'styled-components';

export const MidiaWebreview = styled.div`
  position: absolute;
  top: 35px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  display: flex;
  justify-content: center !important;
  align-items: center;
  .midia-web-preview-box {
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 60%;
    border: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.white};
    border-radius: 5px;
    .midia-web-preview-box-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      color: ${props => props.theme.colors.primary};
      border-bottom: 1px solid ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.primaryHex}30;
      height: 50px;
      font-size: 16px;
      padding: 10px;
    }
    .midia-web-preview-box-contet {
      display: flex;
      flex: 1;
      flex-direction: column;
      padding: 10px;
    }
  }
`;
