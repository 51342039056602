import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTheme } from 'styled-components';
import Checkbox from '~/Components/Checkbox';
import { Creators as AudienceTestActions } from '~/redux/ducks/audienceTest';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';


import {
  Row,
  ModalWrap,
  Input,
  RowHeader,
  RowContent,
  RowFooter,
  ButtonCloseModal,
} from './styles';

const ModalTest = ({
  modalTestStatus,
  value,
  valueSms,
  valuePush,
  valueNoData,
  valueSponsored,
  hideModalAudience,
  isCheckedSms,
  isCheckedPush,
  isCheckedNoData,
  isCheckedSponsored,
  saveModalData,
  loadIdentifier,
  loadName,
  setNewAudienceUserData,
  idAudienceUser,
  theme,
}) => (modalTestStatus ? (
  <Modal width={650} height={800} padding={0}>
    <ModalWrap>
      <RowHeader>
        <Row>
          <h1>USUÁRIO DE TESTE</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => hideModalAudience()}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
      </RowHeader>
      <RowContent>
        <div className="input-wrapper">
          <Input
            placeholder="Identificação"
            value={loadName}
            style={idAudienceUser ? { color: theme.colors.primary } : { color: '#5b5b5b' }}
            name="name"
            onChange={(e) => {
              setNewAudienceUserData({
                name: e.currentTarget.value,
                identifier: loadIdentifier,
              });
            }}
          />

          <FontAwesomeIcon visibility={idAudienceUser ? 'visible' : 'hidden'} color={theme.colors.primary} icon="pen" id="icon" />
        </div>
        <Input
          placeholder="Número"
          value={loadIdentifier}
          disabled={!!idAudienceUser}
          name="identifier"
          maxLength="11"
          onChange={(e) => {
            setNewAudienceUserData({
              name: loadName,
              identifier: e.currentTarget.value,
            });
          }}
        />
      </RowContent>
      <RowContent style={{ marginTop: 30 }}>
        <span className="title">Tipo de canal</span>
        <Checkbox
          style={{ marginBottom: 10 }}
          label="SMS"
          value={valueSms}
          checked={value}
          colorChecked={theme.colors.primary}
          onChange={() => isCheckedSms()}
        />


        <Checkbox
          style={{ marginBottom: 10 }}
          label="Push"
          value={valuePush}
          checked={value}
          colorChecked={theme.colors.primary}
          onChange={() => isCheckedPush()}
        />

        <Checkbox
          style={{ marginBottom: 10 }}
          label="No Data"
          value={valueNoData}
          checked={value}
          colorChecked={theme.colors.primary}
          onChange={() => isCheckedNoData()}
        />
        <Checkbox
          style={{ marginBottom: 10 }}
          label="Notificação Patrocinada"
          value={valueSponsored}
          checked={value}
          colorChecked={theme.colors.primary}
          onChange={() => isCheckedSponsored()}
        />
      </RowContent>
      <RowFooter>
        <Button
          title="Salvar"
          onClick={e => saveModalData({
            idAudienceUser, loadName, loadIdentifier, valueSms, valuePush, valueNoData, valueSponsored,
          })}
        />
      </RowFooter>
    </ModalWrap>
  </Modal>
) : null);


const mapStateToProps = state => ({
  value: state.audienceTest.value,
  valueSms: state.audienceTest.valueSms,
  valuePush: state.audienceTest.valuePush,
  valueNoData: state.audienceTest.valueNoData,
  valueSponsored: state.audienceTest.valueSponsored,
  modalTestStatus: state.audienceTest.modalTestStatus,
  salvar: state.audienceTest.salvar,
  loadIdentifier: state.audienceTest.loadIdentifier,
  loadName: state.audienceTest.loadName,
  idAudienceUser: state.audienceTest.idAudienceUser,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    handleCheckboxStatus: AudienceTestActions.setCheckbox,
    hideModalAudience: AudienceTestActions.hideModalAudience,
    isCheckedSms: AudienceTestActions.isCheckedSms,
    isCheckedPush: AudienceTestActions.isCheckedPush,
    isCheckedNoData: AudienceTestActions.isCheckedNoData,
    isCheckedSponsored: AudienceTestActions.isCheckedSponsored,
    saveModalData: AudienceTestActions.saveModalData,
    setNewAudienceUserData: AudienceTestActions.setNewAudienceUserData,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(ModalTest));
