import styled from 'styled-components';


export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const PreviewImg = styled.img`
  width: 100%;
`;

export const PreviewMessageBox = styled.div`
  position: absolute;
  width: ${props => (props.child ? 65 : 75)}%;
  background-color: ${props => props.theme.colors.darker};
  color: ${props => props.theme.colors.lighter};
  opacity: ${props => (props.main ? 0.2 : 1)};
`;

export const PreviewMessageBoxContent = styled.div`
  font-size: 21px;
  line-height: 24px;
  padding: 15px 5px;
  font-weight: 100;
  min-height: 100px;
  h4 {
    color: ${props => props.theme.colors.lighter};
  }
`;
export const PreviewMessageBoxContentMenu = styled.div`
  display: flex;
  flex-direction: column;
  button {
    height: 50px;
    margin: 0 15px;
    background-color: transparent;
    color: ${props => props.theme.colors.lighter};
    text-align: left;
    outline: none;
    border: 0;
    border-bottom: 1px solid ${props => props.theme.colors.regular};

    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      border-bottom: 0;
    }
  }
`;

export const PreviewMessageBoxFooter = styled.div`
  display: flex;
  height: 50px;
  border-top: 1px solid ${props => props.theme.colors.regular};
  button {
    display: flex;
    flex: 1;
    margin: 5px 0;
    background-color: transparent;
    border: 0;
    color: ${props => props.theme.colors.lighter};
    justify-content: center;
    align-items: center;
    outline: none;
    &:first-child {
      border-right: 1px solid ${props => props.theme.colors.regular};
    }
  }
`;

export const PreviewButtonBox = styled.div`
  display: flex;
  position: absolute;
  width: 75%;
  button {
    display: flex;
    background-color: ${props => props.theme.colors.white};
    flex: 1;
    margin: 0;
    justify-content: center;
    align-items: center;
  }
`;
