/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Checkbox = ({
  label, value, onChange, colorChecked, disabled, style,
}) => (
  <Container colorChecked={colorChecked} disabled={disabled} style={style} className="checkmark-box">
    {label}
    <input
      type="checkbox"
      disabled={disabled}
      checked={value}
      onChange={() => {
        if (!disabled) {
          onChange(!value);
        }
      }}
    />
    <span className="checkmark" />
  </Container>
);

Checkbox.defaultProps = {
  colorChecked: null,
  disabled: false,
  style: null,
  value: false,
  label: '',
};

Checkbox.propTypes = {
  colorChecked: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
};

export default Checkbox;
