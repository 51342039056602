import { call, put, select } from 'redux-saga/effects';

import AdvertisingPartnerService from '~/services/AdvertisingPartnerService';

import { Creators as AdvertisingPartnerActions } from '~/redux/ducks/advertisingPartner';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadAdvertisingPartnerList() {
  yield put(startRequest());
  try {
    const advertisingPartners = yield call(AdvertisingPartnerService.list);
    yield put(AdvertisingPartnerActions.loadAdvertisingPartnerListSuccess(advertisingPartners));
    const filterList = advertisingPartners.map(item => ({
      id: item.id,
      name: item.name,
    }));
    yield put(AdvertisingPartnerActions.loadFilterListSuccess(filterList));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os fornecedores. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* filterAdvertisingPartnerList({ advertisingPartnerId }) {
  yield put(startRequest());
  try {
    let advertisingPartners = yield call(AdvertisingPartnerService.list);
    if (advertisingPartnerId) {
      advertisingPartners = [
        advertisingPartners.find(item => parseInt(item.id) === parseInt(advertisingPartnerId)),
      ];
    }
    yield put(AdvertisingPartnerActions.loadAdvertisingPartnerListSuccess(advertisingPartners));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao filtrar os fornecedores. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* advertisingPartnerUpdate() {
  const { advertisingPartnerEdit } = yield select(state => state.advertisingPartner);
  try {
    yield put(startRequest());
    yield call(AdvertisingPartnerService.update, advertisingPartnerEdit);
    yield put(AdvertisingPartnerActions.changeModalEditAdvertisingPartner(false));
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Fornecedor salvo com sucesso.',
    ));
    yield put(AdvertisingPartnerActions.loadAdvertisingPartnerList());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      e.message || 'Houve um problema ao atualizar o fornecedor.',
    ));
    yield put(requestError());
  }
}
