import React from 'react';
import TreeView from './components/TreeView';

import { Container } from './styles';

function ConfigsBase({ data, itemSelectedId, setItemSelected }) {
  return (
    <Container>
      <TreeView data={data} itemSelectedId={itemSelectedId} setItemSelected={setItemSelected} />
    </Container>
  );
}

export default ConfigsBase;
