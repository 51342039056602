import styled, { css } from 'styled-components';

import { Link } from 'react-router-dom';
import {
  ContainerStyled,
  TableStyled,
  RowStyled,
  InputStyled,
  FormGroupStyled,
  SelectStyled,
  CurrentInputStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)`
  .provider {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 2px;
    padding: 20px;
    width: 100%;
    .text-wrapper{
      display: flex;
      h2{
        color: ${props => props.theme.colors.dark};
      }
      .text-box{
        min-width: 662px;
      }
    }
  }

  .provider-desc {
    h1 {
      margin-bottom: 0px;
    }

    span {
      font-size: 18px;
      font-weight: 200;
      color: ${props => props.theme.colors.regular};
    }
  }
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowEditor = styled(Row)`
  border: 2px solid ${props => props.theme.colors.regular};
  ${props => props.invalid && css`
    border-color: ${props => props.theme.colors.danger2};
  `}

  > textarea {
    width: 100%;
    height: 300px;
  }
`;

export const Input = styled(InputStyled)``;

export const CurrentInput = styled(CurrentInputStyled)``;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
`;

export const FormGroup = styled(FormGroupStyled)`
  span {
    height: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
  }
  margin-right: 30px;
  &:last-child {
    margin-right: 0;
  }
`;

export const Table = styled(TableStyled)`
  thead {
    tr {
      background-color: transparent;
      color: ${props => props.theme.colors.regular};
    }
  }
  tbody {
    tr {
      td {
        padding-top: 0;
      }
    }
  }
`;

export const Select = styled(SelectStyled)``;

export const Img = styled.img`
  max-width: 250px;
`;

export const BaseLabel = styled.div`
  > label {
    display: block;
  }
`;

export const BaseTitle = styled.div`
  display: flex;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  border-right: solid 1px ${props => props.theme.colors.light};
  padding: 20px;
  border-radius: 2px;

  &:last-child {
    border-right: none;
  }

  input {
    margin-right: 0;
  }

  span {
    font-size: 20px;
    margin-bottom: 5px;
    color: ${props => props.theme.colors.primary};
  }
  .center {
    align-items: center;
  }
  img {
    height: 65px;
    margin-right: 15px;
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      margin: 0;
    }
  }
`;
