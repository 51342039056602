import api from './axios';

const dashboardRequest = async (filter) => {
  try {
    const { data } = await api.get(`mcare-artemis-report-manager/dashboard/${filter.year}/${filter.month}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  dashboardRequest,
};
