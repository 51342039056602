/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Creators */
export const { Types, Creators } = createActions({
  loadEventsList: null,
  loadEventsListSuccess: ["eventsList"]
});

export const EventsTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  eventsList: []
});

/* Reducers */
const loadEventsListSuccess = (state, { eventsList }) => ({
  ...state,
  eventsList
});

export const reducer = createReducer(INITIAL_STATE, {
  [EventsTypes.LOAD_EVENTS_LIST_SUCCESS]: loadEventsListSuccess
});
