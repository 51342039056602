import queryString from 'query-string';
import api from './axios';

const search = async (filter) => {
  const {
    contract,
    page,
    channel,
    type,
    status,
    product,
    name,
    keyword,
    zone,
  } = filter;
  const url = (`workflow/campaign?${
    queryString.stringify({
      page: page || 0,
      contractUuid: contract,
      channelUuid: channel,
      productUuid: product,
      typeUuid: type,
      statusUuid: status,
      zoneUuid: zone,
      name,
      keyword,
    }, {
      skipNull: true,
      skipEmptyString: true,
    })}
  `);
  try {
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const reportDownload = async (filter) => {
  const {
    contract,
    channel,
    type,
    status,
    product,
    name,
    keyword,
  } = filter;

  let url = 'mcare-artemis-report-manager/campaign/workflow/report?page=0';

  if (contract) {
    url += `&contractUuid=${contract}`;
  }
  if (channel) {
    url += `&channelUuid=${channel}`;
  }
  if (product) {
    url += `&productUuid=${product}`;
  }
  if (type) {
    url += `&typeUuid=${type}`;
  }
  if (status) {
    url += `&statusUuid=${status}`;
  }
  if (name) {
    url += `&name=${name}`;
  }
  if (keyword) {
    url += `&keyword=${keyword}`;
  }
  try {
    const { data } = await api.get(encodeURI(url), { responseType: 'blob' });
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  search,
  reportDownload,
};
