import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as AdvertisingPartnerActions } from '~/redux/ducks/advertisingPartner';

import Modal from '~/Components/Modal';

import {
  Container,
  Table,
  Row,
  RowFooter,
  SelectLibrary as Select,
  FormGroup,
  Input,
  Textarea,
  ButtonCancel,
} from './styles';
import Button from '~/Components/Button';

class AdvertisingPartnerList extends Component {
  static propTypes = {
    showModalEditAdvertisingPartner: PropTypes.bool.isRequired,
    advertisingPartnerList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    advertisingPartnerFilterList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    advertisingPartnerEdit: PropTypes.shape().isRequired,
    loadAdvertisingPartnerList: PropTypes.func.isRequired,
    filterAdvertisingPartnerList: PropTypes.func.isRequired,
    advertisingPartnerUpdate: PropTypes.func.isRequired,
    changeModalEditAdvertisingPartner: PropTypes.func.isRequired,
    changeAdvertisingPartnerEdit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadAdvertisingPartnerList } = this.props;
    loadAdvertisingPartnerList();
  }

  updateAdvertisingPartner = () => {
    const { advertisingPartnerUpdate } = this.props;
    advertisingPartnerUpdate();
  };

  renderRowPartner = (advertisingPartner) => {
    const {
      changeModalEditAdvertisingPartner,
      changeAdvertisingPartnerEdit,
    } = this.props;
    return (
      <tr key={advertisingPartner.id}>
        <td>
          <span align="center">
            {advertisingPartner.id}
          </span>
        </td>
        <td>
          <span align="center">
            {advertisingPartner.code}
          </span>
        </td>
        <td className="nowrap">
          <span>
            {advertisingPartner.name}
          </span>
        </td>
        <td>
          <span>
            {advertisingPartner.segmentationKey.replace(/,/g, ', ')}
          </span>
        </td>
        <td>
          <div>
            <Button
              bordered
              title="Alterar"
              onClick={() => {
                changeAdvertisingPartnerEdit(advertisingPartner);
                changeModalEditAdvertisingPartner(true);
              }}
            />
          </div>
        </td>
      </tr>
    );
  };

  renderTablePartners = () => {
    const { advertisingPartnerList } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th width="1%" align="center">ID</th>
            <th align="center">CÓDIGO</th>
            <th>NOME</th>
            <th>CHAVE DE SEGMENTAÇÃO</th>
            <th width="1%">AÇÕES</th>
          </tr>
        </thead>
        <tbody>
          {advertisingPartnerList.length < 1 ? (
            <tr>
              <td colSpan="5">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de fornecedores</span>
                </div>
              </td>
            </tr>
          ) : (
            advertisingPartnerList.map(contract => this.renderRowPartner(contract))
          )}
        </tbody>
      </Table>
    );
  };

  renderAlterModal = () => {
    const {
      changeModalEditAdvertisingPartner,
      changeAdvertisingPartnerEdit,
      advertisingPartnerEdit,
    } = this.props;
    return (
      <Modal width={650}>
        <>
          <Row>
            <h1>Edição de fornecedor</h1>
          </Row>
          <Row>
            <FormGroup>
              <label>Nome</label>
              <Input
                value={advertisingPartnerEdit.name}
                readOnly
                disabled
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Código</label>
              <Input
                value={advertisingPartnerEdit.code}
                readOnly
                disabled
              />
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>Chave de Segmentação</label>
              <Textarea
                placeholder="Chave de Segmentação"
                onChange={(e) => {
                  changeAdvertisingPartnerEdit({
                    segmentationKey: e.target.value,
                  });
                }}
                value={advertisingPartnerEdit.segmentationKey}
                rows={5}
              />
            </FormGroup>
          </Row>
          <RowFooter>
            <ButtonCancel
              color="light"
              title="CANCELAR"
              onClick={() => changeModalEditAdvertisingPartner(false)}
            />
            <Button
              title="CONFIRMAR"
              onClick={() => {
                this.updateAdvertisingPartner();
              }}
            />
          </RowFooter>
        </>
      </Modal>
    );
  };

  render() {
    const {
      filterAdvertisingPartnerList,
      advertisingPartnerFilterList,
      showModalEditAdvertisingPartner,
    } = this.props;
    const filterList = advertisingPartnerFilterList.map(item => ({
      value: item.id,
      label: item.name,
    }));

    return (
      <Container>
        <Row>
          <h1>Lista de fornecedores</h1>
          <div className="box-search">
            <Select
              placeholder="Filtrar..."
              defaultValue={null}
              onChange={(e) => {
                filterAdvertisingPartnerList(e.value);
              }}
              options={[
                {
                  value: null,
                  label: 'Filtrar...',
                },
                ...filterList,
              ]}
            />
          </div>
        </Row>
        {this.renderTablePartners()}
        {showModalEditAdvertisingPartner && this.renderAlterModal()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  showModalEditAdvertisingPartner: state.advertisingPartner.showModalEditAdvertisingPartner,
  advertisingPartnerList: state.advertisingPartner.advertisingPartnerList,
  advertisingPartnerFilterList: state.advertisingPartner.advertisingPartnerFilterList,
  advertisingPartnerEdit: state.advertisingPartner.advertisingPartnerEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changeModalEditAdvertisingPartner: AdvertisingPartnerActions.changeModalEditAdvertisingPartner,
    loadAdvertisingPartnerList: AdvertisingPartnerActions.loadAdvertisingPartnerList,
    filterAdvertisingPartnerList: AdvertisingPartnerActions.filterAdvertisingPartnerList,
    advertisingPartnerUpdate: AdvertisingPartnerActions.advertisingPartnerUpdate,
    changeAdvertisingPartnerEdit: AdvertisingPartnerActions.changeAdvertisingPartnerEdit,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AdvertisingPartnerList);
