/* eslint-disable no-plusplus */
import { put, call, select } from 'redux-saga/effects';

import WarningService from '~/services/WarningService';
import { Creators as WarningActions } from '~/redux/ducks/warning';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

export function* warningSetupRequest({ warnings }) {
  try {
    const warningItems = [];
    for (let i = 0; i < warnings.length; i++) {
      const response = yield call(
        WarningService.getWarning,
        warnings[i],
      );
      warningItems.push(response);
    }
    yield put(
      WarningActions.warningSetupRequestSuccess(
        warningItems,
      ),
    );
  } catch (e) {
  }
}

export function* warningAcceptRequest() {
  const { warnings } = yield select(state => state.warning);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      WarningService.accept,
      warnings[0].accept.request,
    );
    yield put(
      WarningActions.warningSetupRequestSuccess(
        warnings.filter((item, index) => index > 0),
      ),
    );
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao aceitar. Tente novamente mais tarde.',
    ));
    yield put(requestError());
  }
}

export function* warningRefuseRequest() {
  const { warnings } = yield select(state => state.warning);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      WarningService.refuse,
      warnings[0].refused.request,
    );
    yield put(
      WarningActions.warningSetupRequestSuccess(
        warnings.filter((item, index) => index > 0),
      ),
    );
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao rejeitar. Tente novamente mais tarde.',
    ));
    yield put(requestError());
  }
}
