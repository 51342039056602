import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import Modal from '~/Components/Modal';
import Button from '~/Components/Button';
import Checkbox from '~/Components/Checkbox';

import { Creators as PointsManagementActions } from '~/redux/ducks/pointsManagement';

import {
  ModalWrapper,
  ModalTitleBox,
  ButtonCloseModal,
  ModalAlertMessage,
  ModalFormBox,
  ModalFooterBox,
  FooterRadius,
  InputFooter,
  FormHeader,
  InputHeader,
  SelectFilterGrouping,
  SelectFilterUf,
  SelectFilterCity,
  SelectFilterNeighborhood,
  FormTable,
  FormTableHeader,
  FormTableBody,
  FormTablePointItem,
} from './styles';

class ModalRadius extends Component {
  state = {
    radius: 0,
    selectedAll: false,
    selectedPoints: [],
    filterName: '',
    filterGrouping: null,
    filterUf: null,
    filterCity: null,
    filterNeighborhood: null,
  };

  componentDidMount() {}

  renderTitle = () => {
    const { setStatusModalEditRadius } = this.props;

    return (
      <ModalTitleBox>
        <h1>Criar raios a partir de pontos</h1>
        <ButtonCloseModal
          type="button"
          onClick={() => setStatusModalEditRadius(false)}
        >
          <FaTimes size={30} />
        </ButtonCloseModal>
      </ModalTitleBox>
    );
  };

  renderAlert = () => {
    const alertMessage = 'Selecione os pontos abaixo e defina o tamanho da área para realizar a criação dos raios de forma automática.';
    return (
      <ModalAlertMessage>{alertMessage}</ModalAlertMessage>
    );
  };

  renderTable = () => {
    const {
      selectedAll,
      selectedPoints,
      filterName,
      filterGrouping,
      filterUf,
      filterCity,
      filterNeighborhood,
    } = this.state;
    const { geopoints: geo } = this.props;
    let pointsList = [...geo.geoPoints];
    if (filterName) {
      pointsList = pointsList.filter(
        i => i.identificador.includes(filterName.toUpperCase()),
      );
    }
    if (filterGrouping) {
      pointsList = pointsList.filter(
        i => i.agrupador.toUpperCase() === filterGrouping.toUpperCase(),
      );
    }
    if (filterUf) {
      pointsList = pointsList.filter(
        i => i.uf.toUpperCase() === filterUf.toUpperCase(),
      );
    }
    if (filterCity) {
      pointsList = pointsList.filter(
        i => i.cidade.toUpperCase() === filterCity.toUpperCase(),
      );
    }
    if (filterNeighborhood) {
      pointsList = pointsList.filter(
        i => i.bairro.toUpperCase() === filterNeighborhood.toUpperCase(),
      );
    }
    return (
      <FormTable>
        <FormTableHeader>
          <div className="table-header-check">
            <Checkbox
              value={selectedAll && selectedPoints.length === pointsList.length}
              onChange={(check) => {
                if (check) {
                  this.setState({
                    selectedAll: check,
                    selectedPoints: pointsList.map(item => item.uuid),
                  });
                } else {
                  this.setState({
                    selectedAll: check,
                    selectedPoints: [],
                  });
                }
              }}
            />
          </div>
          <div className="table-header-name">Nome</div>
          <div className="table-header-uf">UF</div>
          <div className="table-header-city">Cidade</div>
          <div className="table-header-neighborhood">Bairro</div>
          <div className="table-header-grouping">Agrupamento</div>
        </FormTableHeader>
        <FormTableBody>
          {pointsList.map(item => (
            <FormTablePointItem key={item.uuid}>
              <div className="table-item-check">
                <Checkbox
                  value={!!(selectedPoints.find(p => p === item.uuid))}
                  onChange={(check) => {
                    if (check) {
                      this.setState({ selectedPoints: [...selectedPoints, item.uuid] });
                    } else {
                      this.setState({
                        selectedPoints: selectedPoints.filter(p => p !== item.uuid),
                      });
                    }
                  }}
                />
              </div>
              <div className="table-item-name">{item.identificador}</div>
              <div className="table-item-uf">{item.uf}</div>
              <div className="table-item-city">{item.cidade}</div>
              <div className="table-item-neighborhood">{item.bairro}</div>
              <div className="table-item-grouping">{item.agrupador}</div>
            </FormTablePointItem>
          ))}
        </FormTableBody>
      </FormTable>
    );
  };

  renderForm = () => {
    const {
      filterName,
      filterGrouping,
      filterUf,
      filterCity,
      filterNeighborhood,
    } = this.state;
    const { filterLists } = this.props;
    const groupingList = filterLists.groupingList.map(i => ({ label: i, value: i }));
    const ufList = filterLists.ufList.map(i => i);
    const cityList = filterLists.cityList.filter(i => i.uf === filterUf);
    const neighborhoodList = filterLists.neighborhoodList.filter(
      i => i.uf === filterUf && i.city === filterCity,
    );

    return (
      <ModalFormBox>
        <FormHeader>
          <InputHeader
            placeholder="Procurar por nome"
            type="text"
            value={filterName || ''}
            onChange={(e) => {
              this.setState({
                selectedAll: false,
                selectedPoints: [],
                filterName: e.target.value,
              });
            }}
          />
          <SelectFilterGrouping
            placeholder="Selecione Agrupamentos"
            noOptionsMessage={() => 'Lista vazia'}
            value={filterGrouping ? groupingList.find(i => i.value === filterGrouping) : null}
            onChange={(sel) => {
              this.setState({
                selectedAll: false,
                selectedPoints: [],
                filterGrouping: sel ? sel.value : null,
              });
            }}
            options={groupingList}
            isClearable
            style={{ width: 210 }}
          />
          <SelectFilterUf
            placeholder="Selecione UF"
            noOptionsMessage={() => 'Lista vazia'}
            value={filterUf ? ufList.find(i => i.value === filterUf) : null}
            onChange={(sel) => {
              this.setState({
                selectedAll: false,
                selectedPoints: [],
                filterUf: sel ? sel.value : null,
                filterCity: null,
                filterNeighborhood: null,
              });
            }}
            options={ufList}
            isClearable
          />
          <SelectFilterCity
            placeholder="Selecione Cidade"
            noOptionsMessage={() => 'Lista vazia'}
            value={filterCity ? cityList.find(i => i.value === filterCity) : null}
            onChange={(sel) => {
              this.setState({
                selectedAll: false,
                selectedPoints: [],
                filterCity: sel ? sel.value : null,
                filterNeighborhood: null,
              });
            }}
            options={cityList}
            isClearable
            isDisabled={cityList.length === 0}
          />
          <SelectFilterNeighborhood
            placeholder="Selecione Bairro"
            noOptionsMessage={() => 'Lista vazia'}
            value={
              filterNeighborhood ? neighborhoodList.find(i => i.value === filterNeighborhood) : null
            }
            onChange={(sel) => {
              this.setState({
                selectedAll: false,
                selectedPoints: [],
                filterNeighborhood: sel ? sel.value : null,
              });
            }}
            options={neighborhoodList}
            isClearable
            isDisabled={neighborhoodList.length === 0}
          />
        </FormHeader>
        {this.renderTable()}
      </ModalFormBox>
    );
  };

  renderFooter = () => {
    const { radius, selectedPoints } = this.state;
    const { onCreatedRadius, setStatusModalEditRadius } = this.props;
    return (
      <ModalFooterBox>
        <b>{`${selectedPoints.length} pontos selecionados`}</b>
        <FooterRadius>
          <b>Tamanho do raio:</b>
          <InputFooter
            placeholder="Raio"
            type="number"
            value={radius}
            onChange={(e) => {
              this.setState({ radius: e.target.value });
            }}
            disabled={selectedPoints.length === 0}
          />
          <span>metros</span>
        </FooterRadius>
        <Button
          title="Criar raios"
          disabled={selectedPoints.length === 0 || radius <= 0}
          onClick={() => {
            onCreatedRadius({
              radius: parseInt(radius),
              selectedPoints,
            });
            setStatusModalEditRadius(false);
          }}
        />
      </ModalFooterBox>
    );
  };

  render() {
    const { geopoints } = this.props;
    return !!geopoints && (
      <Modal width={1000} minHeight={800} padding={0} scroll={false}>
        <ModalWrapper width={1000} height={800}>
          {this.renderTitle()}
          {this.renderAlert()}
          {this.renderForm()}
          {this.renderFooter()}
        </ModalWrapper>
      </Modal>
    );
  }
}

ModalRadius.defaultProps = {
  geopoints: null,
};

ModalRadius.propTypes = {
  setStatusModalEditRadius: PropTypes.func.isRequired,
  onCreatedRadius: PropTypes.func.isRequired,
  geopoints: PropTypes.shape(),
  filterLists: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ pointsManagement }) => ({
  geopoints: pointsManagement.geopoints,
  filterLists: pointsManagement.filterLists,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setStatusModalEditRadius: PointsManagementActions.setStatusModalEditRadius,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalRadius);
