import { call, put, select } from 'redux-saga/effects';
import ChannelUtil from '~/Utils/ChannelUtil';

import { replace } from 'connected-react-router';
import FileSaver from 'file-saver';
import CampaignService from '~/services/CampaignService';
import WorkflowService from '~/services/WorkflowService';

import { Creators as ApprovalActions } from '~/redux/ducks/approval';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadCampaignList() {
  const {
    status: searchStatus,
  } = yield select(state => state.approval.search);

  yield put(startRequest());
  let products = [];
  let channels = [];
  let types = [];
  let contracts = [];
  let statusList = [];
  let status = null;

  try {
    products = yield call(CampaignService.listAllProducts);
  } catch (e) {
    // ERROR
  }
  try {
    channels = yield call(CampaignService.listChannels);
  } catch (e) {
    // ERROR
  }
  try {
    types = yield call(CampaignService.listTypes);
  } catch (e) {
    // ERROR
  }
  try {
    contracts = yield call(CampaignService.listContracts);
  } catch (e) {
    // ERROR
  }
  try {
    statusList = yield call(CampaignService.listStatus);
    status = statusList.find(item => item.name === 'WAITING_APPROVAL');
  } catch (e) {
    // ERROR
  }
  if (!searchStatus) {
    yield put(ApprovalActions.updateDataSearch({
      status: {
        value: status.uuid,
        label: status.label,
      },
    }));
  }

  yield put(ApprovalActions.loadCampaignListViewSuccess({
    products,
    channels,
    types,
    contracts,
    statusList,
  }));
  yield put(requestSuccess());
  yield put(ApprovalActions.filterCampaign());
}

export function* exportFilterCampaign() {
  const {
    product,
    channel,
    type,
    contract,
    name,
    keyword,
    status,
  } = yield select(state => state.approval.search);
  yield put(startRequest());
  try {
    const resultStream = yield call(WorkflowService.reportDownload, {
      name,
      keyword,
      type: type ? type.value : null,
      product: product ? product.value : null,
      channel: channel ? channel.value : null,
      contract: contract ? contract.value : null,
      status: status ? status.value : null,
    });
    const blob = new Blob(
      [resultStream],
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    );
    FileSaver.saveAs(blob, 'dados.xlsx');

    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as campanhas. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* filterCampaign() {
  const {
    product,
    channel,
    type,
    contract,
    name,
    keyword,
    page,
    status,
  } = yield select(state => state.approval.search);
  yield put(startRequest());
  try {
    const { channelUuid, zoneUuid } = ChannelUtil.unwrapChannelKeyValue(channel);

    const resultList = yield call(WorkflowService.search, {
      page: page || 0,
      name,
      keyword,
      type: type ? type.value : null,
      product: product ? product.value : null,
      channel: channelUuid,
      contract: contract ? contract.value : null,
      status: status ? status.value : null,
      zone: zoneUuid
    });
    const campaigns = resultList;

    yield put(ApprovalActions.filterCampaignSuccess(campaigns));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as campanhas. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* loadCampaignApproval(action) {
  const { campaignId, action: approvalAction } = action.payload;
  yield put(startRequest());
  try {
    const campaign = yield call(CampaignService.get, campaignId);
    // if (campaign.startDate === '0000-00-00 00:00:00') {
    //   campaign.startDate = null;
    // } else if (campaign.startDate) {
    //   campaign.startDate = moment(campaign.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    // }
    // if (campaign.endDate === '0000-00-00 00:00:00') {
    //   campaign.endDate = null;
    // } else if (campaign.endDate) {
    //   campaign.endDate = moment(campaign.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY');
    // }
    // if (!campaign.priority) {
    //   campaign.priority = 0;
    // }

    // const statusList = yield call(CampaignService.listStatus);
    // const statusCampaign = statusList.find(s => s.id === campaign.campaignStatusId);
    // campaign.campaignStatusDescription = statusCampaign.description;

    // let audiences = yield call(CampaignService.listAudience);
    // audiences = audiences.filter(a => a.uuid);
    // const audience = audiences.find(a => a.uuid === campaign.audienceUuid);
    // if (audience) {
    //   campaign.audienceName = audience.name;
    // }

    yield put(
      ApprovalActions.loadCampaignApprovalSuccess({
        campaign,
        action: approvalAction,
      }),
    );
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar a campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* approvedCampaign() {
  yield put(startRequest());
  try {
    const { id: campaignId, campaignTypeId } = yield select(state => state.approval.view.campaign);

    yield call(CampaignService.approvateCampaign, campaignId);

    yield put(requestSuccess());

    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha aprovada com sucesso.',
    ));

    if (campaignTypeId === 5) {
      yield put(ApprovalActions.showModalApprovedCampaign());
    } else {
      yield put(replace('/campaigns/approval'));
    }
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao aprovar a campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* demonstrationCampaign() {
  yield put(startRequest());
  try {
    const { id: userId } = yield select(state => state.auth.currentUser);
    const { id: campaignId } = yield select(state => state.approval.view.campaign);

    yield call(CampaignService.demonstrationCampaign, {
      campaignId,
      userId,
    });
    // yield put(goBack());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Modo de testes ativo.',
    ));
    yield put(requestSuccess());
    yield put(ApprovalActions.loadCampaignApproval(campaignId));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao ativar o modo de testes da campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* runDemonstrationCampaign() {
  yield put(startRequest());
  try {
    const { id: campaignId } = yield select(state => state.approval.view.campaign);

    yield call(CampaignService.runDemonstrationCampaign, campaignId);
    // yield put(goBack());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Teste iniciado com sucesso.',
    ));
    yield put(requestSuccess());
    yield put(ApprovalActions.loadCampaignApproval(campaignId));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao executar o teste da campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* rejectCampaign(action) {
  const reason = action.payload;
  yield put(startRequest());
  try {
    const { id: userId } = yield select(state => state.auth.currentUser);
    const { id: campaignId } = yield select(state => state.approval.view.campaign);

    yield call(CampaignService.rejectCampaign, {
      campaignId,
      userId,
      reason,
    });
    yield put(replace('/campaigns/approval'));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha rejeitada com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao aprovar a campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* workflowStatus() {
  const { transition } = yield select(state => state.approval);
  yield put(startRequest());
  try {
    yield call(CampaignService.workflowStatus, transition);
    yield put(requestSuccess());
    yield put(ApprovalActions.setCampaignTransitionState(null));
    yield put(ApprovalActions.loadCampaignApproval(transition.campaignUuid));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Ocorreu um erro ao realizar a transição da campanha. Tente novamente mais tarde.',
    ));
    yield put(requestError());
  }
}
