/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DayPicker from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';
import moment from 'moment';

import Checkbox from '~/Components/Checkbox';
import Button from '~/Components/Button';
import { Creators as CampaignSchedulingActions } from '~/redux/ducks/campaignScheduling';

import {
  Container,
  Row,
  FormSchedule,
  FormScheduleLeftBox,
  FormScheduleRightBox,
  FormScheduleRightBoxTitle,
  FormScheduleRightBoxContent,
  FormScheduleRightBoxFotter,
  Select,
  Table,
} from './styles';

const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];
const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

const WEEKDAYS_LONG = [
  'Domingo',
  'Segunda',
  'Terça',
  'Quarta',
  'Quinta',
  'Sexta',
  'Sábado',
];

const LABELS = {
  nextMonth: 'Próximo',
  previousMonth: 'Anterior',
};

class CampaignScheduling extends Component {
  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { campaignScheduleSetup, campaignScheduleRequest } = this.props;
    campaignScheduleSetup();
    campaignScheduleRequest(
      moment().format('YYYY-MM-01'),
      true,
    );
  };

  formSaveSendDisabled = () => {
    const { schedule } = this.props;
    const filterItems = schedule.partners.filter(item => item.partner && item.campaign);
    if (filterItems.length !== schedule.partners.length) return true;
    if (schedule.isEnd) return true;
    return false;
  }

  renderHeader = () => {
    const { campaignScheduleSaveSendRequest, schedule } = this.props;
    return (
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>Agendamento Campanhas</h1>
        <Row>
          {schedule.isOpen && !schedule.isEnd && (
            <div className="box-search">
              <Button
                disabled={this.formSaveSendDisabled()}
                onClick={() => {
                  campaignScheduleSaveSendRequest('SAVE');
                }}
                bordered
                title="Salvar"
                style={{
                  margin: 0,
                }}
              />
              <Button
                disabled={this.formSaveSendDisabled()}
                onClick={() => {
                  campaignScheduleSaveSendRequest('SEND');
                }}
                title="Enviar"
                style={{
                  margin: 0,
                  marginLeft: 10,
                }}
              />
            </div>
          )}
        </Row>
      </Row>
    );
  };

  renderLeftBox = () => {
    const {
      types,
      campaignScheduleSetSchedule,
      schedule,
      monthSchedule,
      campaignScheduleRequest,
      campaignScheduleSetupByDate,
    } = this.props;
    const selectedDates = [
      ...monthSchedule.schedules.map(item => item.date),
    ];
    if (!selectedDates.includes(schedule.date)) {
      selectedDates.push(schedule.date);
    }
    return (
      <FormScheduleLeftBox>
        <Select
          placeholder="Tipo"
          value={schedule.type}
          onChange={(item) => {
            campaignScheduleSetSchedule({
              type: item,
            });
          }}
          options={types}
        />
        <DayPicker
          locale="pt"
          months={MONTHS}
          weekdaysLong={WEEKDAYS_LONG}
          weekdaysShort={WEEKDAYS_SHORT}
          labels={LABELS}
          initialMonth={moment().toDate()}
          selectedDays={selectedDates.map(item => moment(item).toDate())}
          onDayClick={(day) => {
            campaignScheduleSetupByDate(
              moment(day).format('YYYY-MM-DD'),
            );
          }}
          onMonthChange={(a) => {
            campaignScheduleRequest(moment(a).format('YYYY-MM-01'), false);
          }}
        />
      </FormScheduleLeftBox>
    );
  };

  renderTableRow = (row, position) => {
    const {
      schedule,
      campaignScheduleSetSchedule,
      partners,
      campaignScheduleCampaignRequest,
    } = this.props;

    const {
      partner, campaign, campaigns, status,
    } = row;

    const getLabelfromStatus = () => {
      switch (status) {
        case 'DONE': return 'Agendamento gerado';
        case 'ACCEPTED': return 'Aceito';
        case 'REFUSED': return 'Recusado';
        case 'NOT_SENDED': return 'Ainda não enviou email';
        case 'PENDING': return 'Aguardando resposta';
        case 'EXPIRED': return 'Expirado';
        default: return '';
      }
    };

    const campaignList = campaigns.map((i) => {
      const findItem = schedule.partners.find(p => p.campaign && p.campaign.value === i.value);
      return {
        ...i,
        isDisabled: !!findItem,
      };
    });

    const campaignSelected = campaignList.find(i => !!campaign && i.value === campaign.value);

    return (
      <tr key={position}>
        <td>
          <span>{position + 1}</span>
        </td>
        <td>
          <Select
            placeholder="Parceiro"
            noOptionsMessage={() => 'Sem registros'}
            value={partner}
            onChange={(selected) => {
              campaignScheduleSetSchedule({
                partners: schedule.partners.map(
                  (item, index) => {
                    if (position === index) {
                      return {
                        ...item,
                        partner: selected,
                        campaign: null,
                      };
                    }
                    return item;
                  },
                ),
              });
              campaignScheduleCampaignRequest(
                position,
                selected.value,
                schedule.type.value,
              );
            }}
            options={partners}
            isDisabled={!schedule.isOpen || schedule.isEnd}
          />
        </td>
        <td>
          <Select
            placeholder="Campanha"
            noOptionsMessage={() => 'Sem registros'}
            value={campaignSelected || null}
            isClearable
            onChange={(selected) => {
              campaignScheduleSetSchedule({
                partners: schedule.partners.map(
                  (item, index) => {
                    if (position === index) {
                      return {
                        ...item,
                        campaign: selected ? { ...selected, isBonus: false } : null,
                      };
                    }
                    return item;
                  },
                ),
              });
            }}
            options={[...campaignList]}
            isDisabled={!schedule.isOpen || schedule.isEnd}
          />
        </td>
        <td align="center">
          <Checkbox
            label=""
            value={campaign && campaign.isBonus}
            onChange={(isBonus) => {
              campaignScheduleSetSchedule({
                partners: schedule.partners.map(
                  (item, index) => {
                    if (position === index) {
                      return {
                        ...item,
                        campaign: {
                          ...campaign,
                          isBonus,
                        },
                      };
                    }
                    return item;
                  },
                ),
              });
            }}
            disabled={!campaign || !schedule.isOpen || schedule.isEnd}
            style={{
              margin: 0,
              width: 20,
              height: 20,
            }}
          />
        </td>
        {schedule.isEnd && (
          <td align="center">
            <span>{getLabelfromStatus()}</span>
          </td>
        )}
        {(schedule.isOpen && !schedule.isEnd) && (
          <td>
            <Button
              disabled={schedule.partners.length <= 1}
              onClick={() => {
                campaignScheduleSetSchedule({
                  partners: schedule.partners.filter(
                    (item, index) => index !== position,
                  ),
                });
              }}
              noLabel
              icon="FaTimes"
              style={{
                margin: 0,
                height: 40,
              }}
            />
          </td>
        )}
      </tr>
    );
  };

  renderRightBox = () => {
    const {
      schedule,
      campaignScheduleSetSchedule,
    } = this.props;

    return (
      <FormScheduleRightBox>
        <FormScheduleRightBoxTitle>
          <span>
            {`${!!schedule.date && moment(schedule.date).format('DD/MM/YYYY')} - Agendamento`}
          </span>
        </FormScheduleRightBoxTitle>
        <FormScheduleRightBoxContent>
          <Table>
            <thead>
              <tr>
                <th width="1%">#</th>
                <th width="49%">Parceiro</th>
                <th width="49%">Campanha</th>
                <th width="1%">Bonificada</th>
                {schedule.isEnd && (
                  <th width="1%" align="center">Status</th>
                )}
                {(schedule.isOpen && !schedule.isEnd) && (
                  <th width="1%" />
                )}
              </tr>
            </thead>
            <tbody>
              {schedule.partners.map(
                (item, index) => this.renderTableRow(item, index),
              )}
            </tbody>
          </Table>
        </FormScheduleRightBoxContent>
        {schedule.isOpen && !schedule.isEnd && (
          <FormScheduleRightBoxFotter>
            <Button
              onClick={() => {
                campaignScheduleSetSchedule({
                  partners: [
                    ...schedule.partners,
                    {
                      partner: null, campaign: null, campaigns: [], status: '',
                    },
                  ],
                });
              }}
              bordered
              icon="FaPlus"
              title="Adicionar novo parceiro"
              style={{
                height: 35,
                margin: 0,
              }}
            />
          </FormScheduleRightBoxFotter>
        )}
      </FormScheduleRightBox>
    );
  };

  renderContent = () => (
    <FormSchedule>
      {this.renderLeftBox()}
      {this.renderRightBox()}
    </FormSchedule>
  );

  render() {
    return (
      <Container>
        {this.renderHeader()}
        {this.renderContent()}
      </Container>
    );
  }
}

CampaignScheduling.propTypes = {
  campaignScheduleSetup: PropTypes.func.isRequired,
  campaignScheduleSetSchedule: PropTypes.func.isRequired,
  campaignScheduleCampaignRequest: PropTypes.func.isRequired,
  campaignScheduleRequest: PropTypes.func.isRequired,
  campaignScheduleSetupByDate: PropTypes.func.isRequired,
  campaignScheduleSaveSendRequest: PropTypes.func.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  partners: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  schedule: PropTypes.shape().isRequired,
  monthSchedule: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  types: state.campaignScheduling.types,
  partners: state.campaignScheduling.partners,
  schedule: state.campaignScheduling.schedule,
  monthSchedule: state.campaignScheduling.monthSchedule,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignScheduleSetup: CampaignSchedulingActions.campaignScheduleSetup,
    campaignScheduleSetSchedule: CampaignSchedulingActions.campaignScheduleSetSchedule,
    campaignScheduleCampaignRequest: CampaignSchedulingActions.campaignScheduleCampaignRequest,
    campaignScheduleRequest: CampaignSchedulingActions.campaignScheduleRequest,
    campaignScheduleSetupByDate: CampaignSchedulingActions.campaignScheduleSetupByDate,
    campaignScheduleSaveSendRequest: CampaignSchedulingActions.campaignScheduleSaveSendRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignScheduling);
