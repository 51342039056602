import styled from 'styled-components';
import { RowStyled } from '~/Components/Styled';

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const ModalContent = styled.div`
  display: flex;
  justify-content: space-around;
  flex: 1;
  button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    width: 150px;
    height: 150px;
    outline: none;
    margin-bottom: 20px;
    span {
      color: ${props => props.theme.colors.primary};
      font-size: 18px;
    }
    svg {
      font-size: 35px;
      color: ${props => props.theme.colors.primary};
      margin-bottom: 10px;
    }
  }
`;
