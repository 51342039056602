import React from 'react';
import PropTypes from 'prop-types';

import { Container, ModalBox } from './styles';

const Modal = ({
  children,
  width,
  height,
  minHeight,
  padding,
  scroll,
  className,
}) => (
  <Container>
    <ModalBox
      width={width}
      className={className}
      minHeight={minHeight}
      height={height}
      padding={padding}
      scroll={scroll}
    >
      {children}
    </ModalBox>
  </Container>
);

Modal.defaultProps = {
  width: null,
  height: null,
  minHeight: null,
  padding: 20,
  scroll: true,
};

Modal.propTypes = {
  className: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  minHeight: PropTypes.number,
  padding: PropTypes.number,
  children: PropTypes.shape().isRequired,
  scroll: PropTypes.bool,
};

export default Modal;
