import styled from 'styled-components';
import {
  RowStyled,
  TableStyled,
} from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 400px;
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0 15px;
  border-bottom: 1px solid ${props => props.theme.colors.lighter};
  margin-bottom: 10px;
  h2 {
    color: ${props => props.theme.colors.dark};
    margin-bottom: 10px;
  }
  > div {
    margin-bottom: 5px;
  }
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0;
  h2 {
    color: ${props => props.theme.colors.dark};
    margin-bottom: 10px;
  }
  .campaign {
    margin-top: 10px;
  }
`;

export const Table = styled(TableStyled)`
  thead tr th {
    font-size: 16px;
  }
  tbody tr {
    border-bottom: solid 1px ${props => props.theme.colors.light};
    &:last-child {
      border-bottom: none;
    }
  }
`;
