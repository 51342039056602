/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
  PreviewImg,
  PreviewButtonBox,
} from './styles';
import previewImg from '~/Assets/Images/preview.png';
import Button from '~/Components/Button';

import PushNotificationTextMessagePreview from './pages/PushNotificationTextMessagePreview';
import PushNotificationImageMessagePreview from './pages/PushNotificationImageMessagePreview';
import SMSMediaMessagePreview from './pages/SMSMediaMessagePreview';
import SMSTextMessagePreview from './pages/SMSTextMessagePreview';
import VoiceMessageTextPreview from './pages/VoiceMessageTextPreview';
import USSDMessageTextPreview from './pages/USSDMessageTextPreview';
import USSDMessageTextClaroPreview from './pages/USSDMessageTextClaroPreview';
import SmartMessageMenuPreview from './pages/SmartMessageMenuPreview';
import SmartMessageMenuCardTriploPreview from './pages/SmartMessageMenuCardTriploPreview';
import SmartMessageMenuTimPreview from './pages/SmartMessageMenuTimPreview';
import SmartMessageTextPreview from './pages/SmartMessageTextPreview';
import SmartMessageTextClaroPreview from './pages/SmartMessageTextClaroPreview';
import MidiaNoCreditADPreview from './pages/MidiaNoCreditADPreview';
import CreativeCustomPreview from './pages/CreativeCustomPreview';
import SponsorNotifyTextPreview from './pages/SponsorNotifyTextPreview';
import SponsorNotifyMediaPreview from './pages/SponsorNotifyMediaPreview';
import NoCreditBannerTextPreview from './pages/NoCreditBannerTextPreview';
import MidiaWebPreview from './pages/MidiaWebPreview';
import NoDataBannerTextPreview from './pages/NoDataBannerTextPreview';
import ChallengeMessagePreview from './pages/ChallengeMessagePreview';
import MediaVideoPreview from './pages/MediaVideoPreview';
import RCSClaroPreview from './pages/RCSClaroPreview';

import CreativeName from '~/Utils/CreativeName';

class PreviewCriativo extends Component {
  state = {
    currentScreen: 'screen1',
  };

  renderMessage = () => {
    const { data } = this.props;
    const { currentScreen } = this.state;

    const changeCurrentScreen = (newScreen) => {
      this.setState({ currentScreen: newScreen });
    };

    switch (data.creativeDefinition.name) {
      case CreativeName.SMART_MESSAGE_TEXT: return (
        <SmartMessageTextPreview
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
          data={data}
        />
      );
      case CreativeName.SMART_MESSAGE_TEXT_CLARO: return (
        <SmartMessageTextClaroPreview
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
          data={data}
        />
      );
      case CreativeName.SMART_MESSAGE_MENU: return (
        <SmartMessageMenuPreview
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
          data={data}
        />
      );
      case CreativeName.SATPUSH_CARD_TRIPLO: return (
        <SmartMessageMenuCardTriploPreview
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
          data={data}
        />
      );
      case CreativeName.SMART_MESSAGE_MENU_TIM: return (
        <SmartMessageMenuTimPreview
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
          data={data}
        />
      );
      case CreativeName.USSD_MESSAGE_TEXT: return (
        <USSDMessageTextPreview
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
          data={data}
        />
      );
      case CreativeName.USSD_MESSAGE_TEXT_CLARO: return (
        <USSDMessageTextClaroPreview
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
          data={data}
        />
      );
      case CreativeName.VOICE_MESSAGE_TEXT: return (
        <VoiceMessageTextPreview
          currentScreen={currentScreen}
          changeCurrentScreen={changeCurrentScreen}
          data={data}
        />
      );
      case CreativeName.SMS_TEXT_MESSAGE: return (
        <SMSTextMessagePreview
          data={data}
        />
      );
      case CreativeName.SMS_MEDIA_MESSAGE: return (
        <SMSMediaMessagePreview
          data={data}
        />
      );
      case CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE: return (
        <PushNotificationTextMessagePreview
          data={data}
        />
      );
      case CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE_MINHA_OI: return (
        <PushNotificationTextMessagePreview
          data={data}
        />
      );
      case CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE: return (
        <PushNotificationImageMessagePreview
          data={data}
        />
      );
      case CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE_MINHA_OI: return (
        <PushNotificationImageMessagePreview
          data={data}
        />
      );
      case CreativeName.MIDIA_NO_CREDIT_AD: return (
        <MidiaNoCreditADPreview
          data={data}
        />
      );
      case CreativeName.CREATIVE_CUSTOM: return (
        <CreativeCustomPreview
          data={data}
        />
      );
      case CreativeName.SPONSOR_NOTIFY_TEXT: return (
        <SponsorNotifyTextPreview
          data={data}
        />
      );
      case CreativeName.SPONSOR_NOTIFY_MEDIA: return (
        <SponsorNotifyMediaPreview
          data={data}
        />
      );
      case CreativeName.NO_CREDIT_BANNER_TEXT: return (
        <NoCreditBannerTextPreview
          data={data}
        />
      );
      case CreativeName.NO_DATA_BANNER_TEXT: return (
        <NoDataBannerTextPreview
          data={data}
        />
      );
      case CreativeName.MIDIA_WEB: return (
        <MidiaWebPreview
          data={data}
        />
      );
      case CreativeName.CHALLENGE: return (
        <ChallengeMessagePreview
          data={data}
        />
      );
      case CreativeName.MEDIA_VIDEO: return (
        <MediaVideoPreview
          data={data}
        />
      );
      case CreativeName.RCS_CLARO: return (
        <RCSClaroPreview
          data={data}
        />
      );
      default: return null;
    }
  };

  renderButtonPreview = () => (
    <PreviewButtonBox>
      <Button
        bordered
        title="Recarregar preview"
        onClick={() => {
          this.setState({
            currentScreen: 'screen1',
          });
        }}
        type="button"
      />
    </PreviewButtonBox>
  );

  render() {
    const { currentScreen } = this.state;
    const { data } = this.props;
    return (
      <Container>
        <PreviewImg alt="preview" src={previewImg} />
        {currentScreen && data && this.renderMessage()}
        {!currentScreen && data && this.renderButtonPreview()}
      </Container>
    );
  }
}

PreviewCriativo.defaultProps = {
  data: null,
};

PreviewCriativo.propTypes = {
  data: PropTypes.shape(),
};

export default PreviewCriativo;
