import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'styled-components';

import Switch from 'react-switch';

import {
  FaTimes,
} from 'react-icons/fa';

import Icone from '~/Components/Icone';

import {
  Container, Base, BaseNoItemSelected, BaseListItens,
  ItemShowHide, CloseHeader, Modal, ContentModal,
} from './styles';

import SegmentationActionConfig from './components/SegmentationActionConfig';
import BenefitActionConfig from './components/BenefitActionConfig';

function EditConfig({
  theme, data, changeShowItem, modeView, closeConfig, addCategory, removeCategory,
  errorMessage,
}) {
  const [showModalEdit, setShowModalEdit] = useState(false);
  useEffect(() => {
    if (data && modeView === 'second' && !showModalEdit) {
      setShowModalEdit(true);
    }
    if ((modeView === 'first' && showModalEdit) || (!data && showModalEdit)) {
      setShowModalEdit(false);
    }
  }, [data, modeView]);

  const noItemSelected = () => (
    <BaseNoItemSelected>
      <Icone icon="hand-point-left" color="#929292" size={80} />
      <h1>Clique um item ao lado para carregar suas configurações</h1>
    </BaseNoItemSelected>
  );

  const renderLayoutListItensData = () => (
    <BaseListItens>
      <div className="header">
        <h3>{data.title}</h3>
        <CloseHeader onClick={() => closeConfig()}>
          <FaTimes color={theme.colors.darker} size={24} />
        </CloseHeader>
      </div>
      {data.data.map(item => (
        <ItemShowHide>
          <h4>{item.title}</h4>
          <Switch checked={item.show} onChange={() => changeShowItem(item)} />
        </ItemShowHide>
      ))}
    </BaseListItens>
  );

  const renderLayoutCustomData = () => (
    <BaseListItens>
      <div className="header">
        <h3>{data.title}</h3>
        <CloseHeader onClick={() => closeConfig()}>
          <FaTimes color={theme.colors.darker} size={24} />
        </CloseHeader>
      </div>
      {data.actionType.name === 'SEGMENTATION' && (
        <SegmentationActionConfig
          categories={data.categories}
          addCategory={addCategory}
          removeCategory={removeCategory}
          errorMessage={errorMessage}
        />
      )}
      {data.actionType.name === 'BENEFIT' && (
        <BenefitActionConfig
          categories={data.categories}
          addCategory={addCategory}
          removeCategory={removeCategory}
        />
      )}
    </BaseListItens>
  );

  const renderLayoutDefault = () => (
    <BaseListItens>
      <div className="header">
        <h3>{data.title}</h3>
        <CloseHeader onClick={() => closeConfig()}>
          <FaTimes color={theme.colors.darker} size={24} />
        </CloseHeader>
      </div>
    </BaseListItens>
  );

  const editItemSelected = () => {
    if (data.layout === 'list') {
      return renderLayoutListItensData();
    }
    if (data.layout === 'custom') {
      return renderLayoutCustomData();
    }
    return renderLayoutDefault();
  };

  if (modeView === 'first') {
    return (
      <Container>
        <Base>
          {!data && noItemSelected()}
          {data && editItemSelected()}
        </Base>
      </Container>
    );
  } if (modeView === 'second') {
    return showModalEdit ? (
      <Modal width={500} minHeight={300}>
        <ContentModal>
          {data && editItemSelected()}
        </ContentModal>
      </Modal>
    ) : <div />;
  }
}

EditConfig.defaultProps = {
  data: null,
  modeView: 'first',
};

EditConfig.propTypes = {
  data: PropTypes.shape(),
  addCategory: PropTypes.func.isRequired,
  removeCategory: PropTypes.func.isRequired,
  modeView: PropTypes.string,
  closeConfig: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
};
export default withTheme(EditConfig);
