/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import {
  FaFont, FaPencilAlt, FaRegCalendarAlt, FaRegCheckSquare, FaRegDotCircle, FaTrash, FaYoutube, FaInfoCircle,
} from 'react-icons/fa';

import { validateTagGA, validateUrl, xmlIsValid } from '~/Utils';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import UploadService, { baseUrlMedia } from '~/services/UploadService';
import MediaItemAdd from './components/ItemAdd';
import MediaItemMedia from './components/ItemMedia';
import ResearchForm from './components/ResearchForm';
import Upload from '~/Components/Upload';
import UploadImage from '~/Components/UploadImage';
import Button from '~/Components/Button';

import {
  FormGroup,
  FormGroupHorizontal,
  Input,
  Row,
  HeaderButtonGroup,
  ColumnVideo,
  Column,
  //
  WrapContent,
  ListMedia,
  ListMediaItem,
  FormMediaWrap,
  FormMedia,
  Select,
  Textarea,
  MidiaVideoPreview,
  LabelXmlValid,
  PreviewVideoBox,
  PreviewThumbnailVideoBox,
  PreviewThumbnailBox,
  ErrorMessage,
  BaseInputsGA,
  BaseCleanSubType,
  BaseExternalUrlConfig,
  BasePerguntaQualificadora,
  BasePerguntaQualificadoraHeader,
  BasePerguntaQualificadoraContent,
  ButtonEditForm,
  PerguntaQualificadoraList,
  PerguntaQualificadoraItem,
  FieldWithLabel,
  InfoLinkExternoExternalRedirect,
  BaseItemsProgramatia,
  ActionButton,
  MediaHeader,
  RowTypeHeader,
  ContainerFallbackNoFill,
} from './styles';

import PreviewImageBox from './components/PreviewImageBox';
import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';
import { ModalSurveyEdit } from './components/ModalSurveyEdit';
import Radiobox from '~/Components/Radiobox';
import { TextEditor } from '~/Components/TextEditor';
import Breadcrumb from '~/Components/Breadcrumb';
import MediaNoFill from './components/MediaNoFill';

const propertiesDefaultImage = {
  resolutions: [{
    id: 'not-resolution', label: 'Resolução livre', fixed: false, width: 0, height: 0,
  }],
};

const resolutionThumbnailAppInstall = {
  id: 'app-install-resolution', label: 'Resolução App install', fixed: true, width: 192, height: 192,
};

const resolutionThumbnailExternalRedirect = {
  id: 'app-install-resolution', label: 'Resolução Redirecionamento externo', fixed: true, width: 192, height: 192,
};

const PROXY_VAST_OPTIONS = [
  { label: 'Habilitado', value: true }, { label: 'Desabilitado', value: false },
];

const SURVEY_PAGE_DEFAULT = 'step-page';
const SURVEY_PAGE_LAYOUT_OPTIONS = [
  { label: 'Passo a passo', value: 'step-page' },
  { label: 'Página única', value: 'landing-page' },
];

const SHOW_TITLE_SURVEY_OPTIONS = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];

class TabContent extends Component {
  state = {
    selectedItem: null,
    programaticaNoFillEditMode: false,
    messageError: null,
    formSurvey: {
      show: false,
      contentCopy: null,
      formHasError: false,
    },
  };

  getZoneProxyVast = () => {
    const {
      campaign: { zone: { extra } },
    } = this.props;
    if (!!extra && !!extra.creatives && !!extra.creatives.internal && !!extra.creatives.internal.medias && !!extra.creatives.internal.medias.vast) {
      const { proxy } = extra.creatives.internal.medias.vast;
      return proxy;
    }
    return null;
  }

  getZoneProxyVpaid = () => {
    const {
      campaign: { zone: { extra } },
    } = this.props;
    if (!!extra && !!extra.creatives && !!extra.creatives.internal && !!extra.creatives.internal.medias && !!extra.creatives.internal.medias.vpaid) {
      const { proxy } = extra.creatives.internal.medias.vpaid;
      return proxy;
    }
    return null;
  }

  getZoneConfigExternalUrl = (typeMidia) => {
    const {
      campaign: { zone: { extra } },
    } = this.props;
    if (!!extra && !!extra.creatives && !!extra.creatives.internal && !!extra.creatives.internal.medias && !!extra.creatives.internal.medias[typeMidia]) {
      const { externalUrl } = extra.creatives.internal.medias[typeMidia];
      return externalUrl;
    }
    return null;
  }

  uploadFileToAWS = async (campaignUuid, file) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;

    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  uploadFileVideo = async (files) => {
    const { campaign } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.setSelectedData({ content: url });
    }
  };

  uploadFileThumbnailVideo = async (files) => {
    const { campaign } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.setSelectedData({ thumbnail: url });
    }
  };

  uploadFileThumbnailVast = async (files) => {
    const { campaign } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.setSelectedData({ thumbnail: url });
    }
  };

  uploadFileThumbnailProgramatic = async (files) => {
    const { campaign } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.setSelectedData({ thumbnail: url });
    }
  };

  uploadFileThumbnailAppInstall = async (files) => {
    const { selectedItem: { content } } = this.state;
    const { campaign } = this.props;
    const file = files[0];
    if (file) {
      const { url: thumbnail } = await this.uploadFileToAWS(campaign.uuid, file);
      this.setSelectedData({ content: { ...content, thumbnail } });
    }
  };

  uploadFileImage = async (files) => {
    const { campaign } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.setSelectedData({ content: url });
    }
  };

  setSelectedData = (data) => {
    const { selectedItem } = this.state;
    const {
      criativoEdit,
      changeData,
    } = this.props;

    const item = {
      ...selectedItem,
      ...data,
    };
    this.setState({ selectedItem: item, messageError: null }, () => {
      changeData({
        ...criativoEdit,
        content: {
          ...criativoEdit.content,
          mainData: {
            ...criativoEdit.content.mainData,
            media: criativoEdit.content.mainData.media.map((i) => {
              if (i.uuid === item.uuid) {
                return item;
              }
              return i;
            }),
          },
        },
      });
    });
  };

  renderResolutionImageFormHeader = () => {
    const { hasError, disabledFields } = this.props;
    const { selectedItem: { resolution, content, properties } } = this.state;
    const { resolutions } = (properties && properties.resolutions) ? properties : propertiesDefaultImage;

    const resolutionImageList = resolutions.map(r => ({
      value: r.id,
      label: r.label,
      resolution: r,
    }));

    const selectedResolution = resolutionImageList.find(item => item.value === (resolution ? resolution.id : 'not-resolution'));

    return (
      <FormGroup style={{ flex: 1 }}>
        <label>
          Resolução
          <span>*</span>
          {!!content && <span className="info-disabled-resolution-image">Obs: remova a imagem atual para alterar a resolução.</span>}
        </label>
        <Select
          isDisabled={disabledFields || !!content}
          placeholder="Selecione Tipo..."
          value={selectedResolution}
          onChange={(item) => {
            this.setSelectedData({ resolution: item.resolution });
          }}
          options={resolutionImageList}
          invalid={hasError && !selectedResolution}
        />
      </FormGroup>
    );
  }

  renderProxyVastFormHeader = () => {
    const { disabledFields } = this.props;
    const { selectedItem } = this.state;
    if (!selectedItem) return null;

    const zoneProxyVast = this.getZoneProxyVast();

    if (!zoneProxyVast) {
      return null;
    }

    if (selectedItem.proxy !== true && selectedItem.proxy !== false) {
      this.setSelectedData({ proxy: zoneProxyVast.default });
    }

    if (!zoneProxyVast.editable) {
      return null;
    }

    const valueItem = selectedItem.proxy !== undefined && selectedItem.proxy !== null ? selectedItem.proxy : zoneProxyVast.default;

    return (
      <FormGroup style={{ flex: 1 }}>
        <label>
          Proxy interno
          <span>*</span>
        </label>
        <Select
          isDisabled={disabledFields}
          placeholder="Selecione a opção..."
          value={PROXY_VAST_OPTIONS.find(p => p.value === valueItem)}
          onChange={(item) => {
            this.setSelectedData({ proxy: item.value });
          }}
          options={PROXY_VAST_OPTIONS}
        />
      </FormGroup>
    );
  }

  renderLayoutTypeSurveyFormHeader = () => {
    const { disabledFields } = this.props;
    const { selectedItem } = this.state;
    if (!selectedItem) return null;

    const layout = selectedItem.content && selectedItem.content.layout ? selectedItem.content.layout : SURVEY_PAGE_DEFAULT;
    const showTitle = selectedItem.content && selectedItem.content.showTitle ? selectedItem.content.showTitle : false;

    return (
      <div style={{
        flex: 1,
        minWidth: 300,
        display: 'flex',
        gap: 5,
        padding: 5,
        paddingTop: 10,
      }}
      >
        <FormGroup style={{ flex: 1 }}>
          <label>
            Layout
            <span>*</span>
          </label>
          <Select
            isDisabled={disabledFields}
            placeholder="Selecione a opção..."
            value={SURVEY_PAGE_LAYOUT_OPTIONS.find(p => p.value === layout)}
            onChange={(item) => {
              this.setSelectedData({
                content: {
                  ...selectedItem.content,
                  layout: item.value,
                },
              });
            }}
            options={SURVEY_PAGE_LAYOUT_OPTIONS}
          />
        </FormGroup>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Mostrar título
            <span>*</span>
          </label>
          <Select
            isDisabled={disabledFields}
            placeholder="Selecione a opção..."
            value={SHOW_TITLE_SURVEY_OPTIONS.find(p => p.value === showTitle)}
            onChange={(item) => {
              this.setSelectedData({
                content: {
                  ...selectedItem.content,
                  showTitle: item.value,
                },
              });
            }}
            options={SHOW_TITLE_SURVEY_OPTIONS}
          />
        </FormGroup>
      </div>
    );
  }

  renderProxyVpaidFormHeader = () => {
    // const { disabledFields } = this.props;
    const { selectedItem } = this.state;
    if (!selectedItem) return null;

    if (selectedItem.proxy !== false) {
      this.setSelectedData({ proxy: false });
    }
    return null;
  }

  renderSubtypeFormHeader = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) {
      return null;
    }
    const { type } = selectedItem;

    if (type === CreativeCustomMidiaType.IMAGE) {
      return this.renderResolutionImageFormHeader();
    } if (type === CreativeCustomMidiaType.VAST || type === CreativeCustomMidiaType.VAST_SURVEY) {
      return this.renderProxyVastFormHeader();
    } if (type === CreativeCustomMidiaType.VPAID || type === CreativeCustomMidiaType.VPAID_SURVEY) {
      return this.renderProxyVpaidFormHeader();
    } if (type === CreativeCustomMidiaType.SURVEY || type === CreativeCustomMidiaType.DATA_SEARCH) {
      return this.renderLayoutTypeSurveyFormHeader();
    }
    return <BaseCleanSubType />;
  }

  renderFormHeader = () => {
    const { selectedItem, programaticaNoFillEditMode } = this.state;
    if (!selectedItem) return null;

    const {
      hasError,
      // campaign,
      disabledFields,
      criativoEdit: { creative },
      confirmModal,
      changeData,
      criativoEdit,
    } = this.props;

    const bread = [
      { name: 'Mídia', route: '#' },
      { name: selectedItem.title || 'MÍDIA SEM NOME', route: '#', handleClick: () => this.fecharMidiaFallbackNoFill() },
    ];
    if (selectedItem.fallbackNoFill && programaticaNoFillEditMode) {
      bread.push({ name: 'MÍDIA NO FILL', route: '#' });
    }

    const typeList = [{
      label: 'Sem mídia',
      value: '',
      mainOptions: {
        content: '',
      },
    }];

    if (creative && creative.medias) {
      creative.medias.forEach((media) => {
        switch (media.type) {
          case CreativeCustomMidiaType.IMAGE:
            typeList.push({
              label: 'Imagem',
              value: CreativeCustomMidiaType.IMAGE,
              mainOptions: {
                content: '',
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          case CreativeCustomMidiaType.VIDEO:
            typeList.push({
              label: 'Vídeo',
              value: CreativeCustomMidiaType.VIDEO,
              mainOptions: {
                content: '',
                thumbnail: '',
                modeVideo: true,
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          case CreativeCustomMidiaType.SURVEY:
            typeList.push({
              label: 'Pesquisa',
              value: CreativeCustomMidiaType.DATA_SEARCH,
              mainOptions: {
                content: {
                  layout: SURVEY_PAGE_DEFAULT,
                  rightAnswerRequired: false,
                  termPosition: '',
                  confirmationTerm: '',
                  questions: [],
                },
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          case CreativeCustomMidiaType.PROGRAMMATIC:
            typeList.push({
              label: 'Programática',
              value: CreativeCustomMidiaType.PROGRAMATICA,
              mainOptions: {
                content: '',
                viewed: false,
                config: {
                  type: 'google-admob-rv',
                  params: [{
                    key: 'id',
                    value: '',
                  }],
                },
                'fallbacks-config': [],
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          case CreativeCustomMidiaType.APP_INSTALL:
            typeList.push({
              label: 'App Install',
              value: CreativeCustomMidiaType.APP_INSTALL,
              mainOptions: {
                content: {
                  partner: null,
                  title: '',
                  description: '',
                  link: '',
                  thumbnail: '',
                  qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
                },
              },
              properties: { ...media.properties },
            });
            break;
          case CreativeCustomMidiaType.EXTERNAL_REDIRECT:
            typeList.push({
              label: 'Redirecionamento externo',
              value: CreativeCustomMidiaType.EXTERNAL_REDIRECT,
              mainOptions: {
                content: {
                  infoPrimary: '',
                  infoSecondary: '',
                  actionButtonLabel: '',
                  link: '',
                  thumbnail: '',
                },
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: { ...media.properties },
            });
            break;
          case CreativeCustomMidiaType.VAST:
            typeList.push({
              label: 'Vast',
              value: CreativeCustomMidiaType.VAST,
              mainOptions: {
                content: '',
                thumbnail: '',
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          case CreativeCustomMidiaType.VAST_SURVEY:
            typeList.push({
              label: 'Vast com pergunta qualificadora',
              value: CreativeCustomMidiaType.VAST_SURVEY,
              mainOptions: {
                content: '',
                thumbnail: '',
                dataSearch: {
                  questions: [],
                },
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          case CreativeCustomMidiaType.VPAID:
            typeList.push({
              label: 'Vpaid',
              value: CreativeCustomMidiaType.VPAID,
              mainOptions: {
                content: '',
                thumbnail: '',
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          case CreativeCustomMidiaType.VPAID_SURVEY:
            typeList.push({
              label: 'Vpaid com pergunta qualificadora',
              value: CreativeCustomMidiaType.VPAID_SURVEY,
              mainOptions: {
                content: '',
                thumbnail: '',
                dataSearch: {
                  questions: [],
                },
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          default:
            // eslint-disable-next-line no-console
            console.log(`type-media-not-configured: ${media.type}`);
        }
      });
    } else {
      typeList.push({
        label: 'Imagem',
        value: CreativeCustomMidiaType.IMAGE,
        mainOptions: {
          content: '',
        },
        properties: { ...propertiesDefaultImage },
      });
      typeList.push({
        label: 'Vídeo',
        value: CreativeCustomMidiaType.VIDEO,
        mainOptions: {
          content: '',
          thumbnail: '',
          modeVideo: true,
        },
      });
      typeList.push({
        label: 'Pesquisa',
        value: CreativeCustomMidiaType.DATA_SEARCH,
        mainOptions: {
          content: {
            layout: SURVEY_PAGE_DEFAULT,
            rightAnswerRequired: false,
            termPosition: '',
            confirmationTerm: '',
            questions: [],
          },
        },
      });
      typeList.push({
        label: 'Programática',
        value: CreativeCustomMidiaType.PROGRAMATICA,
        mainOptions: {
          content: '',
          viewed: false,
          config: {
            type: 'google-admob-rv',
            params: [{
              key: 'id',
              value: '',
            }],
          },
          'fallbacks-config': [],
        },
      });
      typeList.push({
        label: 'Vast',
        value: CreativeCustomMidiaType.VAST,
        mainOptions: {
          content: '',
          thumbnail: '',
        },
      });
      typeList.push({
        label: 'Vpaid',
        value: CreativeCustomMidiaType.VPAID,
        mainOptions: {
          content: '',
          thumbnail: '',
        },
      });
    }

    const { content } = criativoEdit;
    const listMedia = content.mainData && content.mainData.media ? content.mainData.media : [];
    const cloneItem = (uuid) => {
      const itemToClone = selectedItem;
      this.setState({ selectedItem: null, messageError: null }, () => {
        const newListMedia = [];
        const clonedItem = {
          ...itemToClone,
          title: `${itemToClone.title} - clone`,
          uuid: uuid4(),
        };
        listMedia.forEach((i) => {
          newListMedia.push(i);
          if (i.uuid === uuid) {
            newListMedia.push(clonedItem);
          }
        });
        this.setState({ selectedItem: clonedItem, messageError: null }, () => {
          changeData({
            ...criativoEdit,
            content: {
              ...criativoEdit.content,
              mainData: {
                ...criativoEdit.content.mainData,
                media: newListMedia,
              },
            },
          });
        });
      });
    };

    return (
      <>
        <Row>
          <Breadcrumb items={bread} />
        </Row>
        {!programaticaNoFillEditMode ? (
          <>
            <MediaHeader>
              <FormGroupHorizontal style={{ flex: 0.5, flexDirection: 'row', minWidth: '400px' }}>
                <label>
                  Tipo:
                  <span>* &nbsp;</span>
                </label>
                <Select
                  isDisabled={disabledFields}
                  placeholder="Selecione Tipo..."
                  value={typeList.find(item => item.value === selectedItem.type)}
                  onChange={({ value, mainOptions, properties }) => this.setSelectedData({ type: value, ...mainOptions, properties })}
                  options={typeList}
                  invalid={hasError && !selectedItem.type}
                />
              </FormGroupHorizontal>
              <HeaderButtonGroup>
                <Button
                  icon="FaClone"
                  color="warning"
                  iconSize={16}
                  title="Clonar"
                  small
                  bordered
                  onClick={() => {
                    confirmModal(
                      'Clonar',
                      'Tem certeza que deseja clonar esta mídia?',
                      () => cloneItem(selectedItem.uuid),
                    );
                  }}
                />
                {!disabledFields && (
                <Button
                  icon="FaTrashAlt"
                  iconSize={16}
                  title="Excluir"
                  small
                  color="danger2"
                  bordered
                  onClick={() => {
                    confirmModal(
                      'Excluir',
                      'Tem certeza que deseja excluir esta mídia?',
                      () => {
                        this.setState({ selectedItem: null, messageError: null }, () => {
                          changeData({
                            ...criativoEdit,
                            content: {
                              ...criativoEdit.content,
                              mainData: {
                                ...criativoEdit.content.mainData,
                                media: criativoEdit.content.mainData.media.filter(i => i.uuid !== selectedItem.uuid),
                              },
                            },
                          });
                        });
                      },
                    );
                  }}
                />
                )}
              </HeaderButtonGroup>
            </MediaHeader>
            {!!selectedItem.type && (
              <Row style={{ marginBottom: 10 }}>
                <FormGroup style={{ flex: 1 }}>
                  <label>
                    Nome do conteúdo
                    <span>*</span>
                    -
                    <small>{` ${selectedItem.title.length || 0}/100 caracteres`}</small>
                  </label>
                  <Input
                    disabled={disabledFields}
                    placeholder="Digite um nome para o conteúdo"
                    maxLength={100}
                    onChange={e => this.setSelectedData({ title: e.target.value })}
                    value={selectedItem.title}
                    invalid={hasError && !selectedItem.title}
                    autoFocus
                  />
                </FormGroup>

                {selectedItem.properties && selectedItem.properties.replicasEnabled === true && (
                  <FormGroup style={{ marginLeft: '8px' }}>
                    <label>
                      Réplicas
                      <span>*</span>
                      <FaInfoCircle
                        style={{ marginBottom: '4px', marginLeft: '2px' }}
                        data-tip="A quantidade de réplicas que essa mídia terá na entrega.
                        Obs: Valor mínimo é 1(um), sendo entregue uma vez."
                        data-for="tooltip-replicas"
                        size={14}
                      />
                      <ReactTooltip id="tooltip-replicas" place="top" effect="solid" data-html="true" />
                    </label>
                    <Input
                      min={1}
                      type="number"
                      placeholder="Quantidade de réplicas..."
                      onChange={e => this.setSelectedData({ qntReplicas: e.target.value })}
                      value={selectedItem.qntReplicas}
                      invalid={hasError && (!selectedItem.qntReplicas || selectedItem.qntReplicas < 1)}
                    />
                  </FormGroup>
                )}
                <RowTypeHeader>
                  {this.renderSubtypeFormHeader()}
                </RowTypeHeader>
              </Row>
            )}
          </>
        ) : (<></>)}
      </>
    );
  };

  renderVastMode = () => {
    const { selectedItem, messageError } = this.state;
    const {
      fileSizeConfigData,
    } = this.props;

    const getVastThumbnailMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vast
        && fileSizeConfigData.creative.content.media.vast.thumbnail
        && fileSizeConfigData.creative.content.media.vast.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.vast.thumbnail.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getVastThumbnailMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vast
        && fileSizeConfigData.creative.content.media.vast.thumbnail
        && fileSizeConfigData.creative.content.media.vast.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.vast.thumbnail.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    if (!selectedItem) return null;

    const {
      hasError,
      disabledFields,
    } = this.props;

    return (
      <>
        <Row style={{ justifyContent: 'flex-start' }}>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  XML
                  <span>*</span>
                </label>
                <Textarea
                  disabled={disabledFields}
                  rows="10"
                  placeholder="XML"
                  onChange={e => this.setSelectedData({ content: e.target.value })}
                  value={selectedItem.content}
                  invalid={hasError && (!selectedItem.content || !xmlIsValid(selectedItem.content))}
                />
                {!!selectedItem.content && (
                  <LabelXmlValid isValid={xmlIsValid(selectedItem.content)}>
                    <span className="label-xml-valid">XML válido</span>
                    <span className="label-xml-invalid">XML inválido</span>
                  </LabelXmlValid>
                )}
              </FormGroup>
            </Row>
          </Column>
          <Column style={{ flex: 1, marginLeft: 25 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVast}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVastThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVastThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => this.setSelectedData({ thumbnail: '' })}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.thumbnail}
                >
                  {selectedItem.thumbnail ? (
                    <>
                      <img src={selectedItem.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
              </FormGroup>
            </Row>
          </Column>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
        {this.renderExternalUrlConfig()}
      </>
    );
  };

  renderExternalUrlConfig = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) return null;
    const {
      hasError,
      // campaign,
      disabledFields,
    } = this.props;

    const externalUrlConfig = this.getZoneConfigExternalUrl(selectedItem.type);
    if (!externalUrlConfig || !externalUrlConfig.editable) return null;

    const externalUrl = selectedItem.externalUrl || { url: '' };

    const invalidInputUrl = () => {
      if (hasError) {
        if (!!externalUrl.url && !validateUrl(externalUrl.url)) {
          return true;
        }
      }
      return false;
    };

    return (
      <BaseExternalUrlConfig>
        <p className="title-base-external-url">Redirecionamento por click na mídia</p>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <label>
              URL de redirecionamento
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite a url de redirecionamento"
              onChange={(e) => {
                this.setSelectedData({
                  externalUrl: {
                    ...externalUrl,
                    url: e.target.value,
                  },
                });
              }}
              value={externalUrl.url}
              invalid={invalidInputUrl()}
            />
          </FormGroup>
        </Row>
      </BaseExternalUrlConfig>
    );
  }

  renderVastSurveyMode = () => {
    const { selectedItem, messageError } = this.state;
    if (!selectedItem) return null;

    const {
      hasError,
      disabledFields,
      fileSizeConfigData,
    } = this.props;

    const getVastSurveyThumbnailMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vastSurvey
        && fileSizeConfigData.creative.content.media.vastSurvey.thumbnail
        && fileSizeConfigData.creative.content.media.vastSurvey.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.vastSurvey.thumbnail.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getVastSurveyThumbnailMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vastSurvey
        && fileSizeConfigData.creative.content.media.vastSurvey.thumbnail
        && fileSizeConfigData.creative.content.media.vastSurvey.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.vastSurvey.thumbnail.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    return (
      <>
        <Row style={{ justifyContent: 'flex-start' }}>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  XML
                  <span>*</span>
                </label>
                <Textarea
                  disabled={disabledFields}
                  rows="5"
                  placeholder="XML"
                  onChange={e => this.setSelectedData({ content: e.target.value })}
                  value={selectedItem.content}
                  invalid={hasError && (!selectedItem.content || !xmlIsValid(selectedItem.content))}
                />
                {!!selectedItem.content && (
                  <LabelXmlValid isValid={xmlIsValid(selectedItem.content)}>
                    <span className="label-xml-valid">XML válido</span>
                    <span className="label-xml-invalid">XML inválido</span>
                  </LabelXmlValid>
                )}
              </FormGroup>
            </Row>
            {this.renderRerguntaQualificadoraItem()}
          </Column>
          <Column style={{ flex: 1, marginLeft: 25 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVast}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVastSurveyThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVastSurveyThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => this.setSelectedData({ thumbnail: '' })}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.thumbnail}
                >
                  {selectedItem.thumbnail ? (
                    <>
                      <img src={selectedItem.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
              </FormGroup>
            </Row>
          </Column>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
      </>
    );
  };

  renderVpaidMode = () => {
    const { selectedItem, messageError } = this.state;
    if (!selectedItem) return null;
    const {
      fileSizeConfigData,
    } = this.props;

    const getVpaidThumbnailMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vpaid
        && fileSizeConfigData.creative.content.media.vpaid.thumbnail
        && fileSizeConfigData.creative.content.media.vpaid.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.vpaid.thumbnail.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getVpaidThumbnailMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vpaid
        && fileSizeConfigData.creative.content.media.vpaid.thumbnail
        && fileSizeConfigData.creative.content.media.vpaid.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.vpaid.thumbnail.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    const {
      hasError,
      // campaign,
      disabledFields,
    } = this.props;
    return (
      <>
        <Row style={{ alignItems: 'flex-start' }}>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  URL Vpaid
                  <span>*</span>
                </label>
                <Input
                  disabled={disabledFields}
                  placeholder="Digite a url"
                  onChange={e => this.setSelectedData({ content: e.target.value })}
                  value={selectedItem.content}
                  invalid={hasError && (!selectedItem.content || !validateUrl(selectedItem.content))}
                  autoFocus
                />
              </FormGroup>
            </Row>
          </Column>
          <Column style={{ flex: 1, marginLeft: 25 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVast}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVpaidThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVpaidThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => this.setSelectedData({ thumbnail: '' })}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.thumbnail}
                >
                  {selectedItem.thumbnail ? (
                    <>
                      <img src={selectedItem.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
                {messageError && (
                  <Row>
                    <ErrorMessage>{messageError}</ErrorMessage>
                  </Row>
                )}
              </FormGroup>
            </Row>
          </Column>
        </Row>

        {this.renderExternalUrlConfig()}
      </>
    );
  };

  renderRerguntaQualificadoraItem = () => {
    const { selectedItem, formSurvey } = this.state;
    if (!selectedItem) return null;

    const {
      hasError,
      disabledFields,
    } = this.props;


    const onChangeForm = (data) => {
      this.setSelectedData({ dataSearch: data });
    };

    const getIconQuestionType = (questionType) => {
      switch (questionType) {
        case 'check':
          return <FaRegCheckSquare />;
        case 'radio':
          return <FaRegDotCircle />;
        case 'text':
          return <FaFont />;
        case 'date':
          return <FaRegCalendarAlt />;
        default:
          return null;
      }
    };

    const questions = selectedItem.dataSearch && selectedItem.dataSearch.questions ? selectedItem.dataSearch.questions : [];

    return (
      <>
        <Row>
          <FormGroup style={{ flex: 1, marginTop: 10, marginBottom: 10 }}>
            <label>
              Pergunta qualificadora
              <span>*</span>
            </label>
            <BasePerguntaQualificadora invalid={hasError && questions.length === 0}>
              <BasePerguntaQualificadoraHeader invalid={hasError && questions.length === 0}>
                <ButtonEditForm
                  disabled={disabledFields}
                  onClick={() => {
                    this.setState({
                      formSurvey: {
                        show: true,
                        contentCopy: { ...selectedItem.dataSearch },
                        formHasError: false,
                      },
                    });
                  }}
                >
                  Editar perguntas
                  <FaPencilAlt />
                </ButtonEditForm>
              </BasePerguntaQualificadoraHeader>
              <BasePerguntaQualificadoraContent>
                {questions.length === 0 && (<span>Sem perguntas cadastradas</span>)}
                {questions.length > 0 && (
                  <PerguntaQualificadoraList>
                    {questions.map((q, index) => (
                      <PerguntaQualificadoraItem key={q.uuid}>
                        {getIconQuestionType(q.type)}
                        <span>{`${index + 1}#`}</span>
                        <p>{q.title}</p>
                      </PerguntaQualificadoraItem>
                    ))}
                  </PerguntaQualificadoraList>
                )}
              </BasePerguntaQualificadoraContent>
            </BasePerguntaQualificadora>
          </FormGroup>
        </Row>
        <ModalSurveyEdit
          modalOpen={formSurvey.show}
          disabledFields={disabledFields}
          onChangeForm={onChangeForm}
          content={selectedItem.dataSearch}
          formModalOriginal={formSurvey.contentCopy}
          formHasError={formSurvey.formHasError}
          onCloseModal={() => {
            onChangeForm({
              ...selectedItem.dataSearch,
              ...formSurvey.contentCopy,
            });
            this.setState({
              formSurvey: {
                ...formSurvey,
                show: false,
                contentCopy: null,
                formHasError: false,
              },
            });
          }}
          onCancelar={() => {
            onChangeForm({
              ...selectedItem.dataSearch,
              ...formSurvey.contentCopy,
            });
            this.setState({
              formSurvey: {
                ...formSurvey,
                show: false,
                contentCopy: null,
                formHasError: false,
              },
            });
          }}
          onConfirmar={(formIsValid) => {
            if (formIsValid) {
              this.setState({
                formSurvey: {
                  ...formSurvey,
                  show: false,
                  formHasError: false,
                },
              });
            } else {
              this.setState({
                formSurvey: {
                  ...formSurvey,
                  formHasError: true,
                },
              });
            }
          }}
        />
      </>
    );
  }

  renderVpaidSurveyMode = () => {
    const { selectedItem, messageError } = this.state;
    if (!selectedItem) return null;

    const {
      hasError,
      disabledFields,
      fileSizeConfigData,
    } = this.props;

    const getVpaidSurveyThumbnailMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vpaidSurvey
        && fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail
        && fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getVpaidSurveyThumbnailMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vpaidSurvey
        && fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail
        && fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    return (
      <>
        <Row style={{ alignItems: 'flex-start' }}>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  URL Vpaid
                  <span>*</span>
                </label>
                <Input
                  disabled={disabledFields}
                  placeholder="Digite a url"
                  onChange={e => this.setSelectedData({ content: e.target.value })}
                  value={selectedItem.content}
                  invalid={hasError && (!selectedItem.content || !validateUrl(selectedItem.content))}
                  autoFocus
                />
              </FormGroup>
            </Row>
            {this.renderRerguntaQualificadoraItem()}
          </Column>
          <Column style={{ flex: 1, marginLeft: 25 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVast}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVpaidSurveyThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVpaidSurveyThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => this.setSelectedData({ thumbnail: '' })}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.thumbnail}
                >
                  {selectedItem.thumbnail ? (
                    <>
                      <img src={selectedItem.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
              </FormGroup>
            </Row>
          </Column>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
      </>
    );
  };

  renderVideoMode = () => {
    const { selectedItem, messageError } = this.state;
    if (!selectedItem) return null;
    const {
      fileSizeConfigData,
    } = this.props;

    const getVideoContentMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.video
        && fileSizeConfigData.creative.content.media.video.content
        && fileSizeConfigData.creative.content.media.video.content.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.video.content.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getVideoContentMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.video
        && fileSizeConfigData.creative.content.media.video.content
        && fileSizeConfigData.creative.content.media.video.content.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.video.content.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    const getVideoThumbnailMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.video
        && fileSizeConfigData.creative.content.media.video.thumbnail
        && fileSizeConfigData.creative.content.media.video.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.video.thumbnail.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getVideoThumbnailMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.video
        && fileSizeConfigData.creative.content.media.video.thumbnail
        && fileSizeConfigData.creative.content.media.video.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.video.thumbnail.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    const {
      hasError,
      // campaign,
      disabledFields,
    } = this.props;

    return (
      <>
        <Row style={{ justifyContent: 'center' }}>
          <ColumnVideo style={{ marginRight: 20 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.content ? 'Alterar mídia' : 'Buscar mídia'}
                    onUpload={this.uploadFileVideo}
                    accept="video/mp4"
                    maxSize={getVideoContentMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `O vídeo excedeu o limite de ${getVideoContentMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.content && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => this.setSelectedData({ content: '' })}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewVideoBox
                  hasVideo={!!selectedItem.content}
                  invalid={hasError && !selectedItem.content}
                >
                  {selectedItem.content ? (
                    <MidiaVideoPreview controls>
                      <source src={selectedItem.content} />
                      Seu browser não suporta o preview de vídeos
                    </MidiaVideoPreview>
                  ) : 'Preview do vídeo'}
                </PreviewVideoBox>
              </FormGroup>
            </Row>
          </ColumnVideo>
          <ColumnVideo>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVideo}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVideoThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVideoThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => {
                      this.setState({ messageError: null });
                    }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => this.setSelectedData({ thumbnail: '' })}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailVideoBox
                  hasThumbnail={!!selectedItem.thumbnail}
                >
                  {selectedItem.thumbnail ? (
                    <>
                      <img src={selectedItem.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailVideoBox>
              </FormGroup>
            </Row>
          </ColumnVideo>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
        {this.renderExternalUrlConfig()}
      </>
    );
  };

  renderImageMode = () => {
    const { selectedItem, messageError } = this.state;
    if (!selectedItem) return null;

    const {
      fileSizeConfigData,
    } = this.props;

    const getImageContentMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.image
        && fileSizeConfigData.creative.content.media.image.content
        && fileSizeConfigData.creative.content.media.image.content.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.image.content.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getImageContentMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.image
        && fileSizeConfigData.creative.content.media.image.content
        && fileSizeConfigData.creative.content.media.image.content.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.image.content.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    const {
      hasError,
      // campaign,
      disabledFields,
    } = this.props;

    const {
      content, resolution, analytics, properties,
    } = selectedItem;

    const autoNext = properties && properties.autoNext ? properties.autoNext : {
      active: false,
      timeoutSeconds: 0,
    };
    const nextButtonLabel = properties ? properties.nextButtonLabel : '';
    const analyticsProps = properties && properties.analytics ? properties.analytics : [];
    const isShowAnalytics = analyticsProps && analyticsProps.length > 0;
    const analyticsData = isShowAnalytics ? (analytics || {}) : null;

    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <FormGroup style={{ flex: 1, marginRight: 15 }}>
            <label>
              Continuar
              <span className="info-btn-continuar">Configuração de sequência para próxima mídia ou sucesso.</span>
            </label>
            <FieldWithLabel>
              <Radiobox
                label="Botão"
                value={autoNext.active === false}
                onChange={() => {
                  this.setSelectedData({
                    properties: {
                      ...properties,
                      autoNext: {
                        ...autoNext,
                        active: false,
                      },
                    },
                  });
                }}
                style={{ marginRight: 10 }}
                disabled={disabledFields}
              />
              <Radiobox
                label="Automático"
                value={autoNext.active === true}
                onChange={() => {
                  this.setSelectedData({
                    properties: {
                      ...properties,
                      autoNext: {
                        ...autoNext,
                        active: true,
                      },
                    },
                  });
                }}
                style={{ marginRight: 10 }}
                disabled={disabledFields}
              />
            </FieldWithLabel>
          </FormGroup>
          <FormGroup style={{ flex: 1 }}>
            <label>
              {autoNext.active ? 'Contagem regressiva' : 'Texto botão'}
              <span className="info-btn-continuar">
                {autoNext.active ? 'Valor em segundos' : 'Default: AVANÇAR'}
              </span>
            </label>
            {!autoNext.active && (
              <Input
                style={{ width: '100%' }}
                disabled={disabledFields}
                placeholder="Texto botão"
                value={nextButtonLabel}
                onChange={(e) => {
                  this.setSelectedData({
                    properties: {
                      ...properties,
                      nextButtonLabel: e.target.value,
                    },
                  });
                }}
              />
            )}
            {autoNext.active && (
              <Input
                style={{ width: '100%' }}
                disabled={disabledFields}
                placeholder="Contagem regressiva"
                type="number"
                value={autoNext.timeoutSeconds}
                onChange={(e) => {
                  this.setSelectedData({
                    properties: {
                      ...properties,
                      autoNext: {
                        ...autoNext,
                        timeoutSeconds: e.target.value,
                      },
                    },
                  });
                }}
              />
            )}
          </FormGroup>
        </Row>
        {analyticsProps && analyticsProps.length > 0 ? (
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Google Analytics
                <small> - Aceito somente alfanuméricos, underline e hífen(max 100 caracteres por TAG)</small>
              </label>
              <BaseInputsGA>
                {analyticsProps.map(item => (
                  <div key={item.key} className="item-ga">
                    <label>
                      {`${item.label}:`}
                    </label>
                    <Input
                      disabled={disabledFields}
                      placeholder="TAG Google Analytics"
                      maxLength={100}
                      value={analyticsData[item.key]}
                      onChange={(e) => {
                        this.setSelectedData({
                          analytics: {
                            ...analyticsData,
                            [item.key]: e.target.value,
                          },
                        });
                      }}
                      invalid={hasError && !validateTagGA(analyticsData[item.key])}
                    />
                  </div>
                ))}
              </BaseInputsGA>
            </FormGroup>
          </Row>
        ) : null}
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <div style={{
              display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
            }}
            >
              <UploadImage
                disabled={disabledFields}
                label={content ? 'Alterar mídia' : 'Buscar mídia'}
                onUpload={this.uploadFileImage}
                accept="image/jpg,image/jpeg,image/png,image/gif"
                maxSize={getImageContentMaxSize()}
                onRejectMaxSize={() => {
                  this.setState({ messageError: `A imagem excedeu o limite de ${getImageContentMaxSizeLabel()}MB` });
                }}
                onClick={() => { }}
                styleContainer={{
                  width: 200,
                }}
                style={{
                  width: 200,
                  margin: 0,
                }}
                resolution={resolution && resolution.id !== 'not-resolution' ? resolution : null}
                onRejectResolution={() => {
                  this.setState({ messageError: `Arquivo não atendeu as dimensões recomendadas de ${resolution.width}x${resolution.height}` });
                }}
              />
              {!!content && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => this.setSelectedData({ content: '' })}
                />
              )}
            </div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, alignItems: 'center' }}>
            <PreviewImageBox
              resolution={resolution}
              hasImage={!!content}
              invalid={hasError && !content}
            >
              {content ? (
                <img src={content} alt="imagem" />
              ) : (
                <>
                  <span style={{ textAlign: 'center' }}>Preview da imagem</span>
                  {resolution && resolution.id !== 'not-resolution' && (
                    <span className="resolution-info">
                      {`${resolution.width}x${resolution.height}`}
                    </span>
                  )}
                </>
              )}
            </PreviewImageBox>
          </FormGroup>
        </Row>
        <Row>
          <span className="extensoes-permitidas-image">
            Extensões permitidas: PNG, JPG, JPEG, GIF
          </span>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
        {this.renderExternalUrlConfig()}
      </>
    );
  };

  renderResearchMode = () => {
    const { selectedItem } = this.state;
    const { hasError, disabledFields } = this.props;
    return (
      <ResearchForm
        hasError={hasError}
        disabledFields={disabledFields}
        content={selectedItem.content}
        onChangeForm={(content) => {
          this.setSelectedData({ content });
        }}
      />
    );
  };

  criarNovoItemFallback = () => {
    const { selectedItem } = this.state;

    let fallbacksConfigAtual = selectedItem['fallbacks-config'];
    if (!fallbacksConfigAtual) {
      fallbacksConfigAtual = [];
    }

    this.setSelectedData({
      'fallbacks-config': [
        ...fallbacksConfigAtual,
        {
          uuid: uuid4(),
          type: 'google-admob-rv',
          params: [
            {
              key: 'id',
              value: '',
            },
          ],
        },
      ],
    });
  }

  changeItemFallback = (item) => {
    const { selectedItem } = this.state;

    this.setSelectedData({
      'fallbacks-config': [
        ...selectedItem['fallbacks-config'].map(i => (i.uuid !== item.uuid ? i : item)),
      ],
    });
  }

  removerItemFallback = (itemId) => {
    const { selectedItem } = this.state;

    this.setSelectedData({
      'fallbacks-config': [
        ...selectedItem['fallbacks-config'].filter(i => i.uuid !== itemId),
      ],
    });
  }

  criarMidiaFallbackNoFill = () => {
    const { selectedItem } = this.state;

    let fallbackNoFill = selectedItem.fallbackNoFill;
    if (!fallbackNoFill) {
      fallbackNoFill = {
        uuid: selectedItem.uuid,
        label: 'Sem mídia',
        value: '',
        mainOptions: {
          content: '',
        },
      };
    }
    this.setSelectedData({
      fallbackNoFill: {
        ...fallbackNoFill,
      },
    });
    this.setState({
      programaticaNoFillEditMode: true,
    });
  }

  fecharMidiaFallbackNoFill = () => {
    this.setState({
      programaticaNoFillEditMode: false,
    });
  }

  renderProgramaticaMode = () => {
    const { selectedItem, messageError, programaticaNoFillEditMode } = this.state;

    const {
      campaign,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      disabledFields,
      fileSizeConfigData,
    } = this.props;

    const { content } = criativoEdit;

    const getProgramaticaThumbnailMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.programatica
        && fileSizeConfigData.creative.content.media.programatica.thumbnail
        && fileSizeConfigData.creative.content.media.programatica.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.programatica.thumbnail.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getProgramaticaThumbnailMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.programatica
        && fileSizeConfigData.creative.content.media.programatica.thumbnail
        && fileSizeConfigData.creative.content.media.programatica.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.programatica.thumbnail.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    const typeList = [
      {
        label: 'Google Admob',
        value: 'google-admob-rv',
      },
      {
        label: 'inteliFI',
        value: 'intelifi',
      },
      {
        label: 'Iron Source',
        value: 'iron-source-rv',
      },
      {
        label: 'Tapjoy',
        value: 'tapjoy-rv',
      },
    ];

    const fallbackNoFillMediaIsValid = (item) => {
      if (!item.fallbackNoFill) return true;
      if (!item.fallbackNoFill.title) return false;
      if (!item.fallbackNoFill.type) return false;
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.VIDEO && !item.fallbackNoFill.content) return false;
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.IMAGE) {
        if (!item.fallbackNoFill.content) {
          return false;
        }
        if (item.fallbackNoFill.analytics) {
          const analyticsData = item.fallbackNoFill.analytics;
          const { properties: { analytics } } = item.fallbackNoFill;
          let validateAnalytics = true;
          analytics.forEach((i) => {
            if (validateAnalytics) {
              const value = analyticsData[i.key];
              if (!validateTagGA(value)) {
                validateAnalytics = false;
              }
            }
          });
          if (!validateAnalytics) {
            return false;
          }
        }
      }
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.DATA_SEARCH && !item.fallbackNoFill.content.confirmationTerm) return false;
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.PROGRAMATICA && !item.fallbackNoFill.config.params[0].value) return false;
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.APP_INSTALL) {
        const { partner, title, link } = item.fallbackNoFill.content;
        if (!partner || !title || !link || (link && !validateUrl(link))) {
          return false;
        }
      }
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.VAST && !xmlIsValid(item.fallbackNoFill.content)) return false;
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.VAST_SURVEY) {
        if (!xmlIsValid(item.fallbackNoFill.content) || !item.fallbackNoFill.dataSearch || !item.fallbackNoFill.dataSearch.questions || item.fallbackNoFill.dataSearch.questions.length <= 0) {
          return false;
        }
      }
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.VPAID && !validateUrl(item.fallbackNoFill.content)) return false;
      if (item.fallbackNoFill.type === CreativeCustomMidiaType.VPAID_SURVEY) {
        if (!validateUrl(item.fallbackNoFill.content) || !item.fallbackNoFill.dataSearch || !item.fallbackNoFill.dataSearch.questions || item.fallbackNoFill.dataSearch.questions.length <= 0) {
          return false;
        }
      }

      return true;
    };

    return (
      <>
        {!programaticaNoFillEditMode ? (
          <>
            <Row style={{ marginBottom: 10 }}>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  Integração
                  <span>*</span>
                </label>
                <Select
                  isDisabled={disabledFields}
                  placeholder="Selecione Integração..."
                  value={typeList.find(item => item.value === selectedItem.config.type)}
                  onChange={({ value }) => {
                    this.setSelectedData({
                      config: {
                        ...selectedItem.config,
                        type: value,
                      },
                    });
                  }}
                  options={typeList}
                  invalid={hasError && !selectedItem.config.type}
                />
              </FormGroup>
              <FormGroup style={{ flex: 3, marginLeft: 15 }}>
                <label>
                  Chave
                  <span>*</span>
                </label>
                <Input
                  disabled={disabledFields}
                  placeholder="Digite a chave"
                  maxLength={100}
                  onChange={(e) => {
                    const newData = selectedItem.config.params[0];
                    newData.value = e.target.value;
                    this.setSelectedData({
                      config: {
                        ...selectedItem.config,
                        params: [newData],
                      },
                    });
                  }}
                  value={selectedItem.config.params[0].value}
                  invalid={hasError && !selectedItem.config.params[0].value}
                  autoFocus
                />
              </FormGroup>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <BaseItemsProgramatia>
                <p className="title-base-items-programatica">Sequência de cascateamento</p>
                {selectedItem['fallbacks-config'] && selectedItem['fallbacks-config'].map(fallbackConfigItem => (
                  <Row key={fallbackConfigItem.uuid} style={{ marginBottom: 10 }}>
                    <FormGroup style={{ flex: 1 }}>
                      <label>
                        Integração
                      </label>
                      <Select
                        isDisabled={disabledFields}
                        placeholder="Selecione Integração..."
                        value={typeList.find(item => item.value === fallbackConfigItem.type)}
                        onChange={({ value }) => {
                          this.changeItemFallback({
                            ...fallbackConfigItem,
                            type: value,
                          });
                        }}
                        options={typeList}
                      />
                    </FormGroup>
                    <FormGroup style={{ flex: 3, marginLeft: 15 }}>
                      <label>
                        Chave
                      </label>
                      <Input
                        disabled={disabledFields}
                        placeholder="Digite a chave"
                        maxLength={100}
                        onChange={(e) => {
                          const newData = fallbackConfigItem.params[0];
                          newData.value = e.target.value;
                          this.changeItemFallback({
                            ...fallbackConfigItem,
                            params: [newData],
                          });
                        }}
                        value={fallbackConfigItem.params[0].value}

                      />
                    </FormGroup>
                    <FormGroup style={{ marginLeft: 5, marginTop: 25, marginRight: 5 }}>
                      <ActionButton
                        onClick={() => { this.removerItemFallback(fallbackConfigItem.uuid); }}
                        data-tip="Excluir item"
                        disabled={disabledFields}
                      >
                        <FaTrash />
                      </ActionButton>
                    </FormGroup>
                  </Row>
                ))}
                <Row>
                  <div className="base-buttom-add-item">
                    <Button
                      disabled={disabledFields}
                      onClick={() => this.criarNovoItemFallback()}
                      bordered
                      small
                      icon="FaPlus"
                      title="NOVO ITEM"
                    />
                  </div>
                </Row>
              </BaseItemsProgramatia>
            </Row>
            <Row>
              <ContainerFallbackNoFill
                invalid={hasError && !fallbackNoFillMediaIsValid(selectedItem)}
              >
                <p className="title-no-fill-container">Mídia NO FILL</p>
                {!selectedItem || !selectedItem.fallbackNoFill ? (
                  <p className="title-no-fill-media">Sem mídia configurada</p>
                ) : (
                  <p className="title-no-fill-media">
                    {
                      selectedItem.fallbackNoFill.title
                      && selectedItem.fallbackNoFill.title.length
                      && selectedItem.fallbackNoFill.type
                        ? `${selectedItem.fallbackNoFill.title} - ${selectedItem.fallbackNoFill.type}`
                        : 'Mídia sem título'
                    }
                  </p>
                )}
                <Row>
                  <div className="button-add-no-fill-media">
                    <Button
                      disabled={disabledFields}
                      onClick={() => this.criarMidiaFallbackNoFill()}
                      bordered
                      small
                      icon={!selectedItem.fallbackNoFill ? 'FaPlus' : 'FaPencilAlt'}
                      title={!selectedItem.fallbackNoFill ? 'ADICIONAR' : 'EDITAR'}
                    />
                  </div>
                </Row>
              </ContainerFallbackNoFill>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailProgramatic}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getProgramaticaThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getProgramaticaThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.thumbnail && !disabledFields && (
                  <Button
                    disabled={disabledFields}
                    title="REMOVER"
                    onClick={() => this.setSelectedData({ thumbnail: '' })}
                  />
                  )}
                </div>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.thumbnail}
                  style={{ alignSelf: 'center' }}
                >
                  {selectedItem.thumbnail ? (
                    <>
                      <img src={selectedItem.thumbnail} alt="imagem" />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
                {messageError && (
                  <Row>
                    <ErrorMessage>{messageError}</ErrorMessage>
                  </Row>
                )}
              </FormGroup>
            </Row>
          </>
        ) : (
          <>
            <MediaNoFill
              hasError={hasError}
              campaign={campaign}
              content={content}
              criativoEdit={criativoEdit}
              changeData={campaignEditCriativoRequestSuccess}
              disabledFields={disabledFields}
              selectedItem={selectedItem}
              setSelectedData={this.setSelectedData}
              closeMediaNoFill={this.fecharMidiaFallbackNoFill}
            />
          </>
        )}
      </>
    );
  };

  renderAppInstallMode = () => {
    const { selectedItem, messageError } = this.state;
    const {
      hasError,
      // campaign,
      disabledFields,
      fileSizeConfigData,
    } = this.props;

    const getAppInstallThumbnailMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.app_install
        && fileSizeConfigData.creative.content.media.app_install.thumbnail
        && fileSizeConfigData.creative.content.media.app_install.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.app_install.thumbnail.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getAppInstallThumbnailMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.app_install
        && fileSizeConfigData.creative.content.media.app_install.thumbnail
        && fileSizeConfigData.creative.content.media.app_install.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.app_install.thumbnail.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    const { properties: { partners } } = selectedItem;

    const partnersList = partners.map(partner => ({
      value: partner.id,
      label: partner.name,
      partner,
    }));

    return (
      <Row style={{ justifyContent: 'flex-start' }}>
        <Column style={{ flex: 2 }}>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Parceiro
                <span>*</span>
              </label>
              <Select
                isDisabled={disabledFields}
                placeholder="Selecione Integração..."
                value={selectedItem.content.partner ? partnersList.find(item => item.value === selectedItem.content.partner.id) : null}
                onChange={({ partner }) => {
                  const { content } = selectedItem;
                  this.setSelectedData({
                    content: {
                      ...content,
                      partner,
                    },
                  });
                }}
                options={partnersList}
                invalid={hasError && !selectedItem.content.partner}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Título
                <span>*</span>
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite um título"
                maxLength={50}
                onChange={(e) => {
                  const { content } = selectedItem;
                  const title = e.target.value;
                  this.setSelectedData({
                    content: {
                      ...content,
                      title,
                    },
                  });
                }}
                value={selectedItem.content.title}
                invalid={hasError && !selectedItem.content.title}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Descrição
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite uma descrição"
                maxLength={100}
                onChange={(e) => {
                  const { content } = selectedItem;
                  const description = e.target.value;
                  this.setSelectedData({
                    content: {
                      ...content,
                      description,
                    },
                  });
                }}
                value={selectedItem.content.description}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Link
                <span>*</span>
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite um link"
                onChange={(e) => {
                  const { content } = selectedItem;
                  const link = e.target.value;
                  this.setSelectedData({
                    content: {
                      ...content,
                      link,
                    },
                  });
                }}
                value={selectedItem.content.link}
                invalid={hasError && (!selectedItem.content.link || (selectedItem.content.link && !validateUrl(selectedItem.content.link)))}
              />
            </FormGroup>
          </Row>
        </Column>
        <Column style={{ flex: 1 }}>
          <Row>
            <FormGroup style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
              >
                <UploadImage
                  disabled={disabledFields}
                  label={selectedItem.content.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                  onUpload={this.uploadFileThumbnailAppInstall}
                  accept="image/jpg,image/jpeg,image/png"
                  maxSize={getAppInstallThumbnailMaxSize()}
                  onRejectMaxSize={() => {
                    this.setState({ messageError: `A imagem excedeu o limite de ${getAppInstallThumbnailMaxSizeLabel()}MB` });
                  }}
                  onClick={() => { }}
                  styleContainer={{
                    width: 200,
                  }}
                  style={{
                    width: 200,
                    margin: 0,
                  }}
                  resolution={resolutionThumbnailAppInstall}
                  onRejectResolution={() => {
                    this.setState({ messageError: `Arquivo não atendeu as dimensões recomendadas de ${192}x${192}` });
                  }}
                />
                {!!selectedItem.content.thumbnail && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    const { content } = selectedItem;
                    this.setSelectedData({
                      content: {
                        ...content,
                        thumbnail: '',
                      },
                    });
                  }}
                />
                )}
              </div>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup style={{ flex: 1, alignItems: 'center' }}>
              <PreviewImageBox
                resolution={resolutionThumbnailAppInstall}
                hasImage={!!selectedItem.content.thumbnail}
              >
                {selectedItem.content.thumbnail ? (
                  <img src={selectedItem.content.thumbnail} alt="imagem" />
                ) : (
                  <>
                    <span style={{ textAlign: 'center' }}>Preview da thumbnail</span>
                    <span className="resolution-info">
                      192x192
                    </span>
                  </>
                )}
              </PreviewImageBox>
            </FormGroup>
          </Row>
          {messageError && (
            <Row>
              <ErrorMessage>{messageError}</ErrorMessage>
            </Row>
          )}
        </Column>
      </Row>
    );
  };

  renderExternalRedirectMode = () => {
    const { selectedItem, messageError } = this.state;
    const {
      hasError,
      // campaign,
      disabledFields,
      fileSizeConfigData,
    } = this.props;

    const getExternalRedirectThumbnailMaxSize = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.external_redirect
        && fileSizeConfigData.creative.content.media.external_redirect.thumbnail
        && fileSizeConfigData.creative.content.media.external_redirect.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.external_redirect.thumbnail.maxFileSizeMB * 1000000;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
    };

    const getExternalRedirectThumbnailMaxSizeLabel = () => {
      if (
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.external_redirect
        && fileSizeConfigData.creative.content.media.external_redirect.thumbnail
        && fileSizeConfigData.creative.content.media.external_redirect.thumbnail.maxFileSizeMB
      ) {
        return fileSizeConfigData.creative.content.media.external_redirect.thumbnail.maxFileSizeMB;
      }
      if (fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
    };

    return (
      <Row style={{ justifyContent: 'flex-start' }}>
        <Column style={{ flex: 2 }}>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Texto explicativo (opcional)
              </label>
              <TextEditor
                disabled={disabledFields}
                value={selectedItem.content.infoPrimary}
                onChange={(infoPrimary) => {
                  const { content } = selectedItem;
                  this.setSelectedData({
                    content: {
                      ...content,
                      infoPrimary,
                    },
                  });
                }}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Texto complementar (opcional)
              </label>
              <TextEditor
                disabled={disabledFields}
                value={selectedItem.content.infoSecondary}
                onChange={(infoSecondary) => {
                  const { content } = selectedItem;
                  this.setSelectedData({
                    content: {
                      ...content,
                      infoSecondary,
                    },
                  });
                }}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Texto botão
                <span>*</span>
              </label>
              <Input
                style={{ width: '100%' }}
                disabled={disabledFields}
                placeholder="Texto botão"
                onChange={(e) => {
                  const { content } = selectedItem;
                  const actionButtonLabel = e.target.value;
                  this.setSelectedData({
                    content: {
                      ...content,
                      actionButtonLabel,
                    },
                  });
                }}
                value={selectedItem.content.actionButtonLabel}
                invalid={hasError && !selectedItem.content.actionButtonLabel}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Link externo
                <span>*</span>
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite um link"
                onChange={(e) => {
                  const { content } = selectedItem;
                  const link = e.target.value;
                  this.setSelectedData({
                    content: {
                      ...content,
                      link,
                    },
                  });
                }}
                value={selectedItem.content.link}
                invalid={hasError && (!selectedItem.content.link || (selectedItem.content.link && !validateUrl(selectedItem.content.link)))}
              />
              <InfoLinkExternoExternalRedirect>
                <p>Variáveis de enriquecimento de URL disponíveis:</p>
                <div className="options-info">
                  <span>(campaignuuid)</span>
                  <span>(reqid)</span>
                  <span>(userid)</span>
                  <span>(midiauuid)</span>
                  <span>(clickid)</span>
                </div>
              </InfoLinkExternoExternalRedirect>
            </FormGroup>
          </Row>
        </Column>
        <Column style={{ flex: 1 }}>
          <Row>
            <FormGroup style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
              >
                <UploadImage
                  disabled={disabledFields}
                  label={selectedItem.content.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                  onUpload={this.uploadFileThumbnailAppInstall}
                  accept="image/jpg,image/jpeg,image/png"
                  maxSize={getExternalRedirectThumbnailMaxSize()}
                  onRejectMaxSize={() => {
                    this.setState({ messageError: `A imagem excedeu o limite de ${getExternalRedirectThumbnailMaxSizeLabel()}MB` });
                  }}
                  onClick={() => { }}
                  styleContainer={{
                    width: 200,
                  }}
                  style={{
                    width: 200,
                    margin: 0,
                  }}
                  resolution={resolutionThumbnailExternalRedirect}
                  onRejectResolution={() => {
                    this.setState({ messageError: `Arquivo não atendeu as dimensões recomendadas de ${192}x${192}` });
                  }}
                />
                {!!selectedItem.content.thumbnail && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    const { content } = selectedItem;
                    this.setSelectedData({
                      content: {
                        ...content,
                        thumbnail: '',
                      },
                    });
                  }}
                />
                )}
              </div>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup style={{ flex: 1, alignItems: 'center' }}>
              <PreviewImageBox
                resolution={resolutionThumbnailExternalRedirect}
                hasImage={!!selectedItem.content.thumbnail}
              >
                {selectedItem.content.thumbnail ? (
                  <img src={selectedItem.content.thumbnail} alt="imagem" />
                ) : (
                  <>
                    <span style={{ textAlign: 'center' }}>Preview da thumbnail</span>
                    <span className="resolution-info">
                      192x192
                    </span>
                  </>
                )}
              </PreviewImageBox>
            </FormGroup>
          </Row>
          {messageError && (
            <Row>
              <ErrorMessage>{messageError}</ErrorMessage>
            </Row>
          )}
        </Column>
      </Row>
    );
  };

  renderForm = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) return null;

    // const {
    //   criativoEdit,
    //   changeData,
    //   confirmModal,
    //   disabledFields,
    // } = this.props;

    return (
      <FormMediaWrap>
        <FormMedia>
          {this.renderFormHeader()}
          {selectedItem.type === CreativeCustomMidiaType.VIDEO && this.renderVideoMode()}
          {selectedItem.type === CreativeCustomMidiaType.IMAGE && this.renderImageMode()}
          {selectedItem.type === CreativeCustomMidiaType.VAST && this.renderVastMode()}
          {selectedItem.type === CreativeCustomMidiaType.VAST_SURVEY && this.renderVastSurveyMode()}
          {selectedItem.type === CreativeCustomMidiaType.VPAID && this.renderVpaidMode()}
          {selectedItem.type === CreativeCustomMidiaType.VPAID_SURVEY && this.renderVpaidSurveyMode()}
          {selectedItem.type === CreativeCustomMidiaType.DATA_SEARCH && this.renderResearchMode()}
          {selectedItem.type === CreativeCustomMidiaType.PROGRAMATICA && this.renderProgramaticaMode()}
          {selectedItem.type === CreativeCustomMidiaType.APP_INSTALL && this.renderAppInstallMode()}
          {selectedItem.type === CreativeCustomMidiaType.EXTERNAL_REDIRECT && this.renderExternalRedirectMode()}
        </FormMedia>
      </FormMediaWrap>
    );
  };

  renderMediaList = () => {
    const { selectedItem } = this.state;
    const {
      criativoEdit,
      changeData,
      hasError,
      disabledFields,
    } = this.props;

    const { content } = criativoEdit;
    const listMedia = content.mainData && content.mainData.media ? content.mainData.media : [];

    const addNewItemAfter = (uuid) => {
      this.setState({ selectedItem: null, messageError: null, programaticaNoFillEditMode: false }, () => {
        const newListMedia = [];
        const newItem = {
          uuid: uuid4(),
          type: '',
          title: '',
          content: '',
        };
        listMedia.forEach((i) => {
          newListMedia.push(i);
          if (i.uuid === uuid) {
            newListMedia.push(newItem);
          }
        });
        this.setState({ selectedItem: newItem, messageError: null }, () => {
          changeData({
            ...criativoEdit,
            content: {
              ...criativoEdit.content,
              mainData: {
                ...criativoEdit.content.mainData,
                media: newListMedia,
              },
            },
          });
        });
      });
    };

    const SortableItem = sortableElement((element) => {
      const item = element.value;
      const tabIsValid = (item) => {
        if (!item.title) return false;
        if (!item.type) return false;
        if (item.type === CreativeCustomMidiaType.VIDEO && !item.content) return false;
        if (item.type === CreativeCustomMidiaType.IMAGE) {
          if (!item.content) {
            return false;
          }
          if (item.analytics) {
            const analyticsData = item.analytics;
            const { properties: { analytics } } = item;
            let validateAnalytics = true;
            analytics.forEach((i) => {
              if (validateAnalytics) {
                const value = analyticsData[i.key];
                if (!validateTagGA(value)) {
                  validateAnalytics = false;
                }
              }
            });
            if (!validateAnalytics) {
              return false;
            }
          }
        }
        if (item.type === CreativeCustomMidiaType.DATA_SEARCH && !item.content.confirmationTerm) return false;
        if (item.type === CreativeCustomMidiaType.PROGRAMATICA && !item.config.params[0].value) return false;
        if (item.type === CreativeCustomMidiaType.APP_INSTALL) {
          const { partner, title, link } = item.content;
          if (!partner || !title || !link || (link && !validateUrl(link))) {
            return false;
          }
        }
        if (item.type === CreativeCustomMidiaType.VAST && !xmlIsValid(item.content)) return false;
        if (item.type === CreativeCustomMidiaType.VAST_SURVEY) {
          if (!xmlIsValid(item.content) || !item.dataSearch || !item.dataSearch.questions || item.dataSearch.questions.length <= 0) {
            return false;
          }
        }
        if (item.type === CreativeCustomMidiaType.VPAID && !validateUrl(item.content)) return false;
        if (item.type === CreativeCustomMidiaType.VPAID_SURVEY) {
          if (!validateUrl(item.content) || !item.dataSearch || !item.dataSearch.questions || item.dataSearch.questions.length <= 0) {
            return false;
          }
        }

        if (item.fallbackNoFill) {
          if (!tabIsValid(item.fallbackNoFill)) return false;
        }

        return true;
      };
      return (
        <ListMediaItem>
          <MediaItemMedia
            onClick={() => {
              this.setState({ selectedItem: null, messageError: null, programaticaNoFillEditMode: false }, () => {
                this.setState({ selectedItem: item });
              });
            }}
            selected={!!selectedItem && selectedItem.uuid === item.uuid}
            hasError={hasError && !tabIsValid(item)}
            data={item}
            type={item.type || ''}
            editable={!disabledFields}

          />
          <MediaItemAdd disabledFields={disabledFields} onClick={() => addNewItemAfter(item.uuid)} />
        </ListMediaItem>
      );
    });

    const SortableContainer = sortableContainer(({ children }) => <ListMedia>{children}</ListMedia>);

    const onSortEnd = ({ oldIndex, newIndex }) => {
      changeData({
        ...criativoEdit,
        content: {
          ...criativoEdit.content,
          mainData: {
            ...criativoEdit.content.mainData,
            media: arrayMove(criativoEdit.content.mainData.media, oldIndex, newIndex),
          },
        },
      });
    };

    return (
      <SortableContainer
        helperClass="sortable-item-move"
        onSortEnd={onSortEnd}
      >
        {listMedia.length > 0 ? listMedia.map((item, index) => (
          <SortableItem
            key={item.uuid}
            index={index}
            sortIndex={index}
            value={item}
            disabled={disabledFields}
          />
        )) : (
          <MediaItemAdd
            disabledFields={disabledFields}
            onClick={() => {
              const newItem = {
                uuid: uuid4(),
                type: '',
                title: '',
                content: '',
              };
              this.setState({ selectedItem: newItem, messageError: null }, () => {
                changeData({
                  ...criativoEdit,
                  content: {
                    ...criativoEdit.content,
                    mainData: {
                      ...criativoEdit.content.mainData,
                      media: [newItem],
                    },
                  },
                });
              });
            }}
          />
        )}
      </SortableContainer>
    );
  };

  render() {
    return (
      <WrapContent>
        {this.renderMediaList()}
        {this.renderForm()}
      </WrapContent>
    );
  }
}

TabContent.defaultProps = {
  fileSizeConfigData: null,
};

TabContent.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  changeData: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  confirmModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  fileSizeConfigData: state.campaignEdit.fileSizeConfigData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabContent);
