import styled from 'styled-components';
import { RowStyled } from '~/Components/Styled';

export const Row = styled(RowStyled)`
  justify-content: space-between;
`;

export const Container = styled.div`
  position: absolute;
  width: 80.5%;
  min-height: 60px;
  top: 75px;
  padding: 10px 10px 0 10px;

  .icon-app {
    width: 22px;
    border-radius: 11px;
    overflow: hidden;
  }
  .title-app {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0;
    color: ${props => props.theme.colors.primary};
  }

  .dot-now-app {
    margin-left: 15px;
    font-size: 14px;
  }

  .dot-app {
    font-size: 30px;
    line-height: 0.1;
    position: absolute;
    margin-top: 6px;
    margin-left: -10px;
  }
  .chevron-app {
    position: absolute;
    /* right: 0; */
    top: 0;
    margin-top: 18px;
    margin-left: 35px;
    font-size: 10px;
    transform: rotate(90deg);
    transform-origin: center;
    color: ${props => props.theme.colors.primary};
  }

  span {
    color: ${props => props.theme.colors.regular};
  }
  .dot-now {
    margin-left: 15px;
    font-size: 14px;
  }
  .dot {
    font-size: 28px;
    line-height: 0.1;
    position: absolute;
    margin-top: 10px;
    margin-left: -7px;
  }
  background-color: #f9f8f8;

  .title-subtitle {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
  }

  .img-subtitle {
    width: 46px;
    overflow: hidden;
    margin-left: 10px;
  }
  label {
    font-size: 15px;
    font-weight: normal;

    color: black;

    &.title {
      margin-top: 5px;
    }
  }
  p {
    line-height: 1.3;
    font-size: 13px;
    margin-top: -5px;
    margin-bottom: 0;
  }

  .action-box {
    a {
      padding: 10px 10px;
      color: ${props => props.theme.colors.primary};
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;
      display: flex;
      max-width: 50%;
      word-break: break-all;
    }
    /* img {
      width: 100%;
    } */
    margin-top: 15px;
    margin-left: -10px;
    margin-right: -10px;
    background-color: ${props => props.theme.colors.light};
    display: flex;
    justify-content: flex-start;
  }

  .action-box-banner {
    margin-top: 15px;

    .img-banner {
      width: 100%;
    }
  }
`;

export const BaseNotification = styled.div`
  cursor: pointer;
  label {
    cursor: pointer;
  }

`;

export const ControlPreview = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -7px;
  right: 0;
  left: 0;
  align-items: center;
`;

export const ControlButton = styled.button.attrs({
  type: 'button',
})`
  background-color: transparent;
  color: ${props => (props.disabled ? `${props.theme.colors.primaryHex}50` : props.theme.colors.primary)};
  font-size: 25px;
  display: flex;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
`;

export const ControlTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${props => props.theme.colors.primary};
  font-size: 20px;
  font-weight: bold;
`;

export const IconBox = styled.div`
  display: flex;
  max-width: 60px;
`;

export const ContentPreview = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 80.5%;
  top: 75px;
  min-height: 60px;

  > div {
    justify-content: flex-start;
  }

  .external-link {
    display: flex;
    text-align: center;
    color: ${props => props.theme.colors.primary};
    align-self: center;
    margin-top: 20px;
    font-size: 15px;
  }

  .icon-app {
    width: 22px;
    border-radius: 11px;
    overflow: hidden;
  }
  .title-app {
    font-size: 11px;
    margin-left: 5px;
    margin-bottom: 0;
    color: ${props => props.theme.colors.primary};
  }

  .dot-now-app {
    margin-left: 15px;
    font-size: 10px;
  }

  .dot-app {
    font-size: 20px;
    line-height: 0.1;
    position: absolute;
    margin-top: 6px;
    margin-left: -10px;
  }
  .chevron-app {
    position: absolute;
    /* right: 0; */
    top: 0;
    margin-top: 18px;
    margin-left: 35px;
    font-size: 10px;
    transform: rotate(90deg);
    transform-origin: center;
    color: ${props => props.theme.colors.primary};
  }

  span {
    color: ${props => props.theme.colors.regular};
  }
  .dot-now {
    margin-left: 15px;
    font-size: 14px;
  }
  .dot {
    font-size: 28px;
    line-height: 0.1;
    position: absolute;
    margin-top: 10px;
    margin-left: -7px;
  }
  background-color: #f9f8f8;

  .title-subtitle {
    display: flex;
    flex: 1;
    flex-direction: column;
    align-items: flex-start;
  }

  .img-subtitle {
    width: 46px;
    overflow: hidden;
    margin-left: 10px;
  }

  label {
    font-size: 15px;
    font-weight: normal;

    color: black;

    &.title {
      margin-top: 5px;
    }
  }
  p {
    line-height: 1.3;
    font-size: 13px;
    margin-top: -5px;
    margin-bottom: 0;
  }

  .action-box {
    span {
      padding: 10px 10px;
      color: ${props => props.theme.colors.primary};
      font-size: 12px;
      font-weight: 600;
    }
    img {
      width: 100%;
    }
    margin-top: 15px;
    margin-left: -10px;
    margin-right: -10px;
    background-color: ${props => props.theme.colors.light};
  }

  .action-box-banner {
    margin-bottom: 15px;
    padding: 0 10px;

    .img-banner {
      width: 100%;
    }
  }

  .success-title {
    font-size: 20px;
    font-weight: normal;
    color: ${props => props.theme.colors.dark};
    padding-left: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    display: flex;
    flex: 1;
  }

  .img-title {
    width: 60px;
    overflow: hidden;
    margin: 5px;
  }

  .success-subtitle {
    width: 100%;
    margin: 30px 10px 10px 10px;
    font-size: 20px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }

  .box-info-base {
    flex-direction: column;
    align-items: flex-start;

    > a {
      margin: 15px;
      align-self: center;
      color: #337ab7;
      text-decoration: underline;
    }
  }
`;

export const MidiaVideoPreview = styled.video`
  width: 100%;
  max-height: 565px;
  outline: none;
`;

export const MidiaImagePreview = styled.img`
  width: 100%;
`;
