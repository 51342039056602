/* eslint-disable prefer-destructuring */
import { call, put, select } from 'redux-saga/effects';

import { push, replace } from 'connected-react-router';

import { Creators as BehaviorActions, INIT_FILTER } from '~/redux/ducks/behavior';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import BehaviorManagerService from '~/services/BehaviorManagerService';
import CampaignService from '~/services/CampaignService';

export function* listActions() {
  yield put(startRequest());
  try {
    const actions = yield call(BehaviorManagerService.actionList);
    yield put(requestSuccess());
    yield put(
      BehaviorActions.listActionsSuccess(actions),
    );
  } catch (e) {
    yield put(requestError());
  }
}

export function* listActionType() {
  yield put(startRequest());
  try {
    const actionTypes = yield call(BehaviorManagerService.actionTypeList);
    yield put(requestSuccess());
    yield put(
      BehaviorActions.listActionTypesSuccess(actionTypes),
    );
  } catch (e) {
    yield put(requestError());
  }
}

export function* listEventType() {
  yield put(startRequest());
  try {
    const eventTypes = yield call(BehaviorManagerService.eventTypeList);
    yield put(requestSuccess());
    yield put(
      BehaviorActions.listEventTypesSuccess(eventTypes),
    );
  } catch (e) {
    yield put(requestError());
  }
}

export function* listBehaviors() {
  yield put(startRequest());
  try {
    const behaviors = yield call(BehaviorManagerService.behaviorList);
    yield put(requestSuccess());
    yield put(
      BehaviorActions.listBehaviorsSuccess(behaviors),
    );
  } catch (e) {
    yield put(requestError());
  }
}

export function* listChannelsCampaign() {
  yield put(startRequest());
  try {
    const channels = yield call(CampaignService.listChannels);
    yield put(requestSuccess());
    yield put(BehaviorActions.listChannelsCampaignSuccess(channels));
  } catch (e) {
    yield put(requestError());
  }
}

export function* onNewBehaviorModal() {
  yield put(BehaviorActions.listEventTypes());
  yield put(BehaviorActions.onNewBehaviorModalSuccess());
}

export function* listCampaignByFiltersNewBehaviorModal({ filters }) {
  const { name, channel, creative } = filters;
  yield put(startRequest());
  try {
    const contractId = yield select(state => state.auth.contractId);

    const { content: campaigns } = yield call(CampaignService.search, {
      contractId,
      page: 0,
      name,
      channel: channel ? channel.value : null,
      creative: creative || null,
    });
    yield put(BehaviorActions.listCampaignByFiltersNewBehaviorModalSuccess(campaigns));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as campanhas. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* onCreateBehaviorNewBehaviorModal() {
  const { behavior, typeSelected } = yield select(state => state.behavior.newBehaviorModal);

  if (typeSelected.name === 'QUIZ') {
    if (!behavior || !behavior.name || !behavior.properties || !behavior.properties.campaignUuid) {
      yield put(BehaviorActions.onCreateBehaviorNewBehaviorModalInvalidForm());
      return;
    }
  }

  yield put(startRequest());
  try {
    const { currentUser: { email } } = yield select(state => state.auth);
    behavior.owner = email;

    const newBehavior = yield call(BehaviorManagerService.behaviorCreate, behavior);

    yield put(requestSuccess());

    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Comportamento cadastrado com sucesso.',
    ));

    yield put(BehaviorActions.onCreateBehaviorNewBehaviorModalSuccess());
    yield put(push(`/behaviors/crud/${newBehavior.uuid}`));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao cadastrar o comportamento. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

const getIconByAction = ({ name }) => {
  switch (name) {
    case 'SEGMENTATION':
      return 'align-justify';
    case 'Benefício do uber':
      return 'donate';
    case 'Benefício de minutos':
      return 'donate';
    default:
      return null;
  }
};

const makeDataQuestion = (
  filtersBase, actionType, question, actionsBehavior,
) => question.options.map(
  (option) => {
    const actionResult = actionsBehavior.find(action => action.filters.find(f => f.field === 'questionOptionUuid' && f.value === (option.uuid || option.optionUuid)));

    const categories = actionResult ? actionResult.properties.categories : [];

    const filters = [...filtersBase, {
      field: 'questionOptionUuid',
      operation: '=',
      value: option.uuid || option.optionUuid,
    }];

    return {
      id: `${actionType.uuid}-${question.uuid || question.questionUuid}-option-${option.uuid || option.optionUuid}`,
      title: option.description,
      subtitle: '0 categorias configuradas',
      name: `${question.title} - ${option.description}`,
      icon: 'comment-alt',
      layout: 'custom',
      show: true,
      data: [],
      action: actionResult || null,
      actionType,
      categories,
      filters,
    };
  },
);

const makeDataActions = (filtersBase, questions, actionType, actionsBehavior) => {
  const questionsData = questions.map((q) => {
    const filters = [...filtersBase, {
      field: 'questionUuid',
      operation: '=',
      value: q.uuid || q.questionUuid,
    }];
    return {
      id: `${actionType.uuid}-question-${q.uuid || q.questionUuid}`,
      title: q.title,
      subtitle: '0 respostas configuradas',
      icon: 'list',
      layout: q.type === 'check' || q.type === 'radio' ? 'list' : 'custom',
      show: true,
      data: q.type === 'check' || q.type === 'radio' ? makeDataQuestion(filters, actionType, q, actionsBehavior) : [],
      actionType,
      filters,
      categories: [],
    };
  });
  return questionsData;
};

const filterActionsTypeByEventType = (actionsType, eventType) => actionsType.filter(
  (actionType) => {
    const { name } = eventType;
    if (name === 'QUIZ') {
      return actionType.name === 'SEGMENTATION';
    } if (name === 'BENEFIT') {
      return actionType.name === 'BENEFIT';
    }
    return false;
  },
);

const makeConfigBehavior = ({
  actionsType, behavior, campaign, questions,
}) => {
  const config = {
    campaign,
    data: [],
  };

  actionsType = filterActionsTypeByEventType(actionsType, behavior.eventType);
  const { actions } = behavior;

  const filters = [
    {
      field: 'campaignUuid',
      operation: '=',
      value: campaign.uuid,
    },
  ];

  const dataActions = actionsType.map((actionType) => {
    if (actionType.name === 'BENEFIT') {
      return {
        id: `action-${actionType.uuid}`,
        title: actionType.description,
        subtitle: '0 questão configurada',
        icon: getIconByAction(actionType),
        layout: 'custom',
        show: true,
        actionType,
        data: null,
        filters,
      };
    }
    return {
      id: `action-${actionType.uuid}`,
      title: actionType.description,
      subtitle: '0 questão configurada',
      icon: getIconByAction(actionType),
      layout: 'list',
      show: true,
      filters,
      data: makeDataActions(filters, questions, actionType, actions),
    };
  });

  const base = {
    id: `campaign-${campaign.uuid}`,
    title: behavior.eventType.description,
    subtitle: campaign.name,
    first: true,
    icon: null,
    layout: 'list',
    show: true,
    data: [...dataActions],
  };
  config.data = [base];

  return config;
};

export function* openCrudBehavior({ behaviorUuid }) {
  yield put(BehaviorActions.setItemSelectedConfigCrud(null));
  yield put(BehaviorActions.cleanCrudBehavior());
  yield put(startRequest());
  try {
    const actionsType = yield call(BehaviorManagerService.actionTypeList);
    const behavior = yield call(BehaviorManagerService.behaviorGet, behaviorUuid);
    let campaign = null;
    let questions = null;

    if (behavior.eventType && behavior.eventType.name === 'QUIZ') {
      const { campaignUuid } = behavior.properties;
      if (campaignUuid) {
        campaign = yield call(CampaignService.get, campaignUuid);

        if (campaign) {
          const { creative: { content } } = campaign;
          if (content && content.mainData) {
            if (content.mainData.form && content.mainData.form.questions) {
              questions = content.mainData.form.questions;
            } else if (content.mainData.media) {
              const mapSearchData = content.mainData.media.filter(i => i.type === 'data_search');
              let mapQuestions = [];
              mapSearchData.forEach((i) => {
                mapQuestions = [...mapQuestions, ...i.content.questions];
              });
              questions = mapQuestions.length > 0 ? mapQuestions : null;
            } else {
              questions = null;
            }
          }
        }
      }
    }
    const config = makeConfigBehavior({
      actionsType, behavior, campaign, questions,
    });


    yield put(BehaviorActions.openCrudBehaviorSuccess({ behavior, config }));

    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados do comportamento. Tente novamente mais tarde!',
    ));
    yield put(replace('/behaviors'));
  }
}

const resultItemsCustomQuiz = (result, data) => {
  data.forEach((d) => {
    if (d.layout === 'custom') {
      result.push(d);
    }
    resultItemsCustomQuiz(result, d.data);
  });
  return result;
};

const makeBehaviorUpdate = (behavior, config) => {
  let actions = [];

  if (behavior.eventType.name === 'QUIZ') {
    const { data } = config;
    const itemsCustom = resultItemsCustomQuiz([], data);
    const itemsFilters = itemsCustom.filter(
      i => i.action || (i.categories && i.categories.length > 0),
    );
    actions = itemsFilters.map((item) => {
      if (item.action) {
        const actionUpdated = {
          ...item.action,
          actionTypeUuid: item.action.actionType.uuid,
          properties: {
            categories: item.categories,
          },
        };
        delete actionUpdated.actionType;
        return actionUpdated;
      }
      return {
        name: `Segmentação de Base - ${item.name}`,
        description: '',
        actionTypeUuid: item.actionType.uuid,
        filters: item.filters,
        properties: {
          categories: item.categories,
        },
      };
    });
  }

  const behaviorUpdate = {
    ...behavior,
    eventTypeUuid: behavior.eventType.uuid,
    actions,
  };
  delete behaviorUpdate.eventType;

  return behaviorUpdate;
};

export function* onSaveBehaviorCrud() {
  yield put(startRequest());
  try {
    const { behavior, config } = yield select(state => state.behavior.crud);

    const behaviorUpdate = makeBehaviorUpdate(behavior, config);

    yield call(BehaviorManagerService.behaviorUpdate, behaviorUpdate);

    yield put(requestSuccess());

    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Comportamento salvo com sucesso.',
    ));
    yield put(replace('/behaviors'));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualiza os dados do comportamento. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* deleteBehavior({ behaviorUuid }) {
  yield put(startRequest());
  try {
    yield call(BehaviorManagerService.behaviorDelete, behaviorUuid);

    yield put(requestSuccess());

    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Comportamento excluido com sucesso.',
    ));
    yield put(BehaviorActions.listBehaviors());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao excluir o comportamento. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* onSearchByFilter() {
  yield put(startRequest());
  try {
    const { filter } = yield select(state => state.behavior);
    let behaviors = yield call(BehaviorManagerService.behaviorList);

    behaviors = behaviors.filter((behavior) => {
      if (filter.name) {
        const nameValidate = behavior.name.toUpperCase();
        const nameFilter = filter.name.toUpperCase();
        if (nameValidate.indexOf(nameFilter) === -1) {
          return false;
        }
      }
      if (filter.status) {
        if (behavior.enabled !== filter.status.value) {
          return false;
        }
      }
      return true;
    });

    yield put(requestSuccess());
    yield put(
      BehaviorActions.listBehaviorsSuccess(behaviors),
    );
  } catch (e) {
    yield put(requestError());
  }
}

export function* onCleanFilter() {
  yield put(BehaviorActions.onChangeFilter(INIT_FILTER));
  yield put(BehaviorActions.onSearchByFilter());
}
