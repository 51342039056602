import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  width: ${props => (props.width > 0 ? `${props.width}px` : '100%')};
  margin-right: ${props => props.marginRight}px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 15px;
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;
`;

export const BoxContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
`;
