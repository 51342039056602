import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  RowStyled,
  FormGroupStyled,
  InputStyled,
} from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const FormGroup = styled(FormGroupStyled)`
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;

  label {
    b {
      margin-right: 5px;
    }
  }

  .DayPickerInput {
    .DayPicker-wrapper {
      zoom: 160%;
    }

    .DayPicker-Day.DayPicker-Day--selected,
    .DayPicker-Day.DayPicker-Day--selected:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white} !important;
    }
    .DayPicker-Day.DayPicker-Day--today {
      color: ${props => props.theme.colors.primary};
    }

    > input {
      border: solid 2px ${props => props.theme.colors.light};
      border-radius: 4px;
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      color: ${props => props.theme.colors.dark};
      padding: 5px 15px;
      width: 145px;
    }
  }

  .dropzone-content-box {
    height: auto !important;
    width: auto !important;
    button {
      margin: 0 !important;
      outline: none;
    }
  }
`;

export const Input = styled(InputStyled)``;

export const Select = styled(ReactSelect)`
  width: 100%;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const FileItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 5px 10px;;
  font-size: 18px;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 0;
    width: 25px;
    height: 25px;
    font-size: 25px;
    color: ${props => props.theme.colors.primaryHex};
    border: 0;
    background-color: transparent;
    outline: none;
    margin-left: 10px;
  }
  &:hover {
    background-color: ${props => props.theme.colors.lighterWhite};
  }
`;

export const FileListContent = styled.div`
  overflow: hidden;
  padding: 3px 0;
`;

export const FileList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 200px;
  overflow-y: auto;
`;

export const FileListItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  font-size: 18px;
  border: 1px solid ${props => props.theme.colors.primaryHex};
  border-top: 0;
  .title-file {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span {
      word-break: break-all;
    }
    button {
      color: ${props => props.theme.colors.primaryHex};
      font-size: 15px;
      border: 0;
      padding: 0;
      margin: 0;
      background-color: transparent;
      width: fit-content;
    }
  }
  .dropzone-content-box {
    width: fit-content !important;
    height: fit-content !important;
    button {
      margin: 0 0 0 5px !important;
      height: 35px;
      width: 160px !important;
    }
  }
  &:first-child {
    border-top: 1px solid ${props => props.theme.colors.primaryHex};
  }
  &:hover {
    background-color: ${props => props.theme.colors.lighterWhite};
  }
`;
