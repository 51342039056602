import styled from 'styled-components';
import ReactSelect from 'react-select';
import { RowStyled } from '~/Components/Styled';

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 0;
`;

export const ButtonCloseModal = styled.div`
  cursor: pointer;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;

  .base-content {
    height: 100%;
    max-height: calc(100vh - 150px);
    overflow-y: auto;
  }
`;

export const Row = styled(RowStyled)``;

export const InfoData = styled.div`
  flex: ${props => (props.flex ? props.flex : 1)};
  display: flex;
  flex-direction: column;

  font-size: 15px;
`;

export const BaseIndicadores = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 15px;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;

  .title {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryHex}30;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 0;

    svg {
      margin-right: 10px;
    }

    span {
      flex: 1;
      text-align: right;
    }
  }

  .content-indicadores {
    padding: 15px;
    min-height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

export const InfoIndicador = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;
  label, p {
    margin-bottom: 0;
  }

  p {
    margin-left: 5px;
  }
`;

export const EditButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
`;

export const EditIndicator = styled.div`
 flex: 1;
  display: flex;
  align-items: center;
  font-size: 16px;

  label, p {
    margin-bottom: 0;
  }

  label {
    margin-right: 10px;
  }

  .button-actions {
    display: flex;
    align-items: center;
    justify-content: center;

    .cancel {
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 2px solid ${props => props.theme.colors.danger2};
      border-radius: 5px;
      margin: 0 5px 0 10px;
      width: 30px;
      height: 30px;
      justify-content: center;
    }

    .confirm {
      cursor: pointer;
      display: flex;
      align-items: center;
      border: 2px solid ${props => props.theme.colors.checked};
      border-radius: 5px;

      width: 30px;
      height: 30px;
      justify-content: center;
    }
  }
`;

export const Select = styled(ReactSelect)`
  width: 180px;
`;
