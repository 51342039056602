import styled from 'styled-components';

import { RowStyled } from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  > label {
    margin-right: 10px;
  }
`;

export const Questions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const ModalWrapBody = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ModalPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: 20px;
`;

export const ModalPreviewTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 580px;
  overflow-y: auto;
  padding: 0 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const AddItemsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 5px;
  padding: 5px;
  span {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    color: ${props => props.theme.colors.white};
    svg {
      font-size: 16px;
      margin-left: 5px;
      margin-right: 10px;
    }
  }
`;

export const GroupButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.white};
  padding: 5px;
`;

export const ButtonInsert = styled.button.attrs({ type: 'button' })`
  border: 0;
  background-color: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white};
  font-size: 20px;
  outline: none;
`;
