import React from 'react';

function ValidatedStatusIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 10.9962C0.0188227 4.94077 4.86756 0 11 0C17.1136 0 22 4.92195 22 11C22 17.0818 17.1136 22.0038 11 22C4.86379 21.9962 0.0150582 17.0517 0 10.9962ZM20.6335 11C20.5808 5.63745 16.3795 1.3783 11.0038 1.3783C5.61294 1.3783 1.38159 5.65252 1.38159 11C1.38159 16.3475 5.60917 20.6217 11 20.6217C16.3758 20.6217 20.5808 16.3625 20.6335 11Z" fill="#67C84F" />
      <path d="M9.64101 12.7812C9.67113 12.7398 9.69372 12.7022 9.72007 12.6758C11.3539 11.0527 12.9877 9.43341 14.6215 7.81034C14.7307 7.70489 14.8511 7.61828 15.0017 7.58062C15.1975 7.53543 15.3669 7.59569 15.5175 7.71243C15.7885 7.92708 15.9429 8.28483 15.653 8.65012C15.6229 8.69154 15.5852 8.7292 15.5513 8.76309C13.7444 10.5707 11.9374 12.3783 10.1266 14.1897C10.0175 14.2989 9.89324 14.3892 9.73889 14.4231C9.4942 14.4759 9.30221 14.3742 9.13657 14.2085C8.65094 13.7302 8.17284 13.2444 7.69098 12.7624C7.27688 12.3482 6.86278 11.9339 6.44868 11.5197C6.40351 11.4745 6.3621 11.4255 6.32445 11.3766C6.15128 11.1431 6.14375 10.9096 6.29057 10.6761C6.45621 10.4125 6.72349 10.2807 6.99077 10.3334C7.13759 10.3636 7.25806 10.4464 7.36346 10.5519C8.09002 11.2674 8.81658 11.9791 9.5469 12.6946C9.56949 12.7172 9.59208 12.7398 9.64101 12.7812Z" fill="#67C84F" />
    </svg>

  );
}

export default ValidatedStatusIcon;
