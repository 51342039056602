/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// import {
//   FaFont, FaQuestionCircle, FaRegCalendarAlt, FaRegCheckSquare, FaRegDotCircle,
// } from 'react-icons/fa';
import uuid4 from 'uuid4';

// import ReactTooltip from 'react-tooltip';
import Modal from '~/Components/Modal';
import Button from '~/Components/Button';


import {
  Row,
  ModalHeader,
  ModalWrapBody,
  // ModalPreview,
  // ModalBody,
  ModalFooter,
  Input,
  ItensRanking,
  Info,
  BaseItem,
} from './styles';

export function ModalRankingSuccess({
  modalOpen, disabledFields,
  content,
  onCancelar, onConfirmar,
}) {
  const [ranking, setRanking] = useState([]);
  const [validForm, setValidForm] = useState(false);
  const [showValidation, setShowValidation] = useState(false);

  useEffect(() => {
    if (modalOpen) {
      if (content && content.ranking) {
        setRanking(content.ranking);
      }
    } else {
      setRanking([]);
    }
  }, [modalOpen]);

  function formIsValid(validRanking) {
    let formValid = true;
    validRanking.forEach((item) => {
      if (!item.title) formValid = false;
      if (!item.description) formValid = false;
    });
    return formValid;
  }

  function newItemRanking() {
    const getLastItemRanking = ranking[ranking.length - 1];

    let minPercentHitpoints = 0;
    if (getLastItemRanking) {
      const minPercentHitpointsPrev = getLastItemRanking.minPercentHitpoints;
      if (minPercentHitpointsPrev < 100) {
        minPercentHitpoints = minPercentHitpointsPrev + 1;
      }
    }

    const newItem = {
      uuid: uuid4(),
      title: '',
      description: '',
      minPercentHitpoints,
    };

    const newRanking = [...ranking, newItem];
    setRanking(newRanking);
    setValidForm(formIsValid(newRanking));
  }

  function removeItemRanking(itemRemove) {
    const { uuid, minPercentHitpoints } = itemRemove;
    let newRanking = ranking.filter(item => item.uuid !== uuid);
    if (newRanking.length && minPercentHitpoints === 0) {
      const [first, ...rest] = newRanking;
      first.minPercentHitpoints = 0;
      newRanking = [first, ...rest];
    }
    setRanking(newRanking);
    setValidForm(formIsValid(newRanking));
  }

  function changeItemRanking(itemChange) {
    const newRanking = ranking.map((item) => {
      if (item.uuid === itemChange.uuid) {
        return itemChange;
      }
      return item;
    });
    setRanking(newRanking);
    setValidForm(formIsValid(newRanking));
  }

  function getPrevMinPercent(uuid) {
    const index = ranking.findIndex(item => item.uuid === uuid);
    if (index === 0) return 0;
    return ranking[index - 1].minPercentHitpoints;
  }

  function getNextMinPercent(uuid) {
    const index = ranking.findIndex(item => item.uuid === uuid);
    if (index === ranking.length - 1) return 100;
    return ranking[index + 1].minPercentHitpoints;
  }

  function renderItem(item, indexItem) {
    const prevMinPercent = getPrevMinPercent(item.uuid);
    const prevMaxPercent = getNextMinPercent(item.uuid);

    return (
      <BaseItem key={item.uuid}>
        <div className="base-item">
          <div className="base-item-content">
            <p>{`${item.minPercentHitpoints}% - ${prevMaxPercent === 100 ? prevMaxPercent : prevMaxPercent - 1}%`}</p>
            <div className="base-item-content-inputs">
              <div className="base-item-content-inputs-label-input">
                <label>Acerto mímino(%)</label>
                <Input
                  type="number"
                  disabled={disabledFields || indexItem === 0}
                  min={prevMinPercent}
                  max={prevMaxPercent}
                  value={item.minPercentHitpoints}
                  onChange={(e) => {
                    let newMinPercentHitpoints = parseInt(e.target.value, 10);
                    if (Number.isNaN(newMinPercentHitpoints)) newMinPercentHitpoints = prevMinPercent + 1;
                    if (newMinPercentHitpoints < prevMinPercent || newMinPercentHitpoints > prevMaxPercent) return;

                    changeItemRanking({ ...item, minPercentHitpoints: newMinPercentHitpoints });
                  }}
                />
              </div>
              <div className="base-item-content-inputs-label-input">
                <label>Título</label>
                <Input
                  type="text"
                  disabled={disabledFields}
                  maxLength={100}
                  width={200}
                  value={item.title}
                  invalid={showValidation && !item.title}
                  onChange={(e) => {
                    changeItemRanking({ ...item, title: e.target.value });
                  }}
                />
              </div>
              <div className="base-item-content-inputs-label-input" style={{ flex: 1 }}>
                <label>Detalhes</label>
                <Input
                  type="text"
                  disabled={disabledFields}
                  maxLength={1000}
                  value={item.description}
                  invalid={showValidation && !item.description}
                  onChange={(e) => {
                    changeItemRanking({ ...item, description: e.target.value });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="base-item-remove">
            <Button
              small
              disabled={disabledFields}
              icon="FaTrashAlt"
              noLabel
              onClick={() => removeItemRanking(item)}
              style={{ width: 35, height: 35 }}
            />
          </div>
        </div>
      </BaseItem>
    );
  }

  function renderItensRanking() {
    const getLastItemRanking = ranking[ranking.length - 1];

    return (
      <ItensRanking>
        {ranking.map((item, index) => renderItem(item, index))}
        <div className="base-buttom-add-item">
          <Button
            disabled={disabledFields || (getLastItemRanking && getLastItemRanking.minPercentHitpoints === 100)}
            onClick={() => newItemRanking()}
            bordered
            small
            icon="FaPlus"
            title="NOVO ITEM"
          />
        </div>
      </ItensRanking>
    );
  }

  return modalOpen ? (
    <Modal width={1200} height={760} padding={0} scroll={false}>
      <ModalHeader>
        <h1>{`${disabledFields ? 'Visualizar' : 'Editar'} ranking da página de sucesso`}</h1>
      </ModalHeader>
      <ModalWrapBody>
        <Row>
          <Info>
            <p>Configure as mensagens de sucesso a partir do ranking de acertos do questionário</p>
          </Info>
        </Row>
        <Row>
          {renderItensRanking()}
        </Row>
      </ModalWrapBody>
      <ModalFooter>
        {disabledFields ? (
          <Button
            type="button"
            bordered
            title="Fechar"
            onClick={onCancelar}
            style={{ margin: 0 }}
          />
        ) : (
          <>
            <Button
              type="button"
              bordered
              title="Cancelar"
              onClick={onCancelar}
              style={{ margin: 0 }}
            />
            <Button
              type="button"
              title="Confirmar"
              disabled={showValidation && !validForm}
              onClick={() => {
                const validRanking = formIsValid(ranking);
                if (!validRanking) {
                  setShowValidation(true);
                } else {
                  onConfirmar(ranking);
                }
                // onConfirmar(formIsValid());
              }}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  ) : null;
}

ModalRankingSuccess.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  content: PropTypes.shape().isRequired,
  formHasError: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCancelar: PropTypes.func.isRequired,
  onConfirmar: PropTypes.func.isRequired,
};
