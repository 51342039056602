import styled, { css } from 'styled-components';

const getPreviewImageBoxHasInvalid = (props) => {
  if (props.invalid) {
    return css`
      border: 1px solid ${props.theme.colors.danger2};
      color: ${props.theme.colors.danger2};
    `;
  }
  return css`
    border: 1px solid ${props.theme.colors.regular};
    color: ${props.theme.colors.regular};
  `;
};

export const Container = styled.div`
  min-width: 500px;
  max-width: 500px;
  min-height: 300px;
  max-height: 300px;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  ${props => getPreviewImageBoxHasInvalid(props)}
  ${props => props.hasImage && css`
    background-color: #000000;
  `}
  > img {
    min-width: 500px;
    max-width: 500px;
    min-height: 300px;
    max-height: 300px;
    object-fit: contain;
    object-position: center;
  }

  ${({ resolution }) => resolution && resolution.id !== 'not-resolution' && css`
    min-width: ${resolution.width}px;
    max-width: ${resolution.width}px;
    min-height: ${resolution.height}px;
    max-height: ${resolution.height}px;
    > img {
      min-width: ${resolution.width}px;
      max-width: ${resolution.width}px;
      min-height: ${resolution.height}px;
      max-height: ${resolution.height}px;
      object-fit: contain;
      object-position: center;
    }
  `}

  .resolution-info {
    font-size: 17px;
    text-decoration: underline;
    color: ${props => props.theme.colors.regular};
  }
`;
