import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  openModal: ['campaignUuid'],
  changeShowModal: ['show'],
  loadExportModalData: ['retargets'],
  generateRetarget: ['campaignUuid', 'retargetConfigUuid'],
  downloadRetarget: ['campaignUuid', 'retargetUuid'],
});

export const AudienceExporterTypes = Types;
export default Creators;


/* Initial State */

const INITIAL_STATE = Immutable({
  show: false,
  retargets: [],
});

/* Reducers */
const changeShowModal = (state, { show }) => ({
  ...state,
  show,
});

const loadExportModalData = (state, { retargets }) => ({
  ...state,
  retargets,
});


export const reducer = createReducer(INITIAL_STATE, {
  [AudienceExporterTypes.CHANGE_SHOW_MODAL]: changeShowModal,
  [AudienceExporterTypes.LOAD_EXPORT_MODAL_DATA]: loadExportModalData,

});
