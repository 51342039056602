import React from 'react';
import PropTypes from 'prop-types';
import { AiFillPlusCircle } from 'react-icons/ai';

import { Container } from './styles';

function ItemAdd({ onClick }) {
  return (
    <Container onClick={onClick} className="media-item-add">
      <AiFillPlusCircle />
      <div className="item-add-separator" />
    </Container>
  );
}

ItemAdd.propTypes = {
  onClick: PropTypes.func.isRequired,
};

export default ItemAdd;
