import React, { useState } from 'react';
import PropTypes from 'prop-types';

import {
  FormGroup, Textarea, Row, ErrorLink,
} from './styles';

import LandingPage from '../../Components/LandingPage';

const SMSMediaMessageForm = ({
  campaign,
  criativoEdit,
  campaignEditCriativoRequestSuccess,
  hasError,
  disabledFields,
}) => {
  const { content } = criativoEdit;
  const [especialCharacterMessage, setEspecialCharacterMessage] = useState('');

  const messageIsInvalid = () => {
    if (!content.channelData) return true;
    if (!content.channelData.message) return true;
    if (content.channelData.message.length > 134) return true;
    return false;
  };
  const verifyEspecialCharecter = (value) => {
    const characterIndex = value.search(/[á|Á|é|É|í|Í|ó|Ó|ú|Ú|à|À|è|È|ì|Ì|ò|Ò|ù|Ù|ã|Ã|õ|Õ|â|Â|ê|Ê|ü|Ü|ç|Ç|]/i);
    if (characterIndex >= 0) {
      const character = value.substr(characterIndex, 1);
      setEspecialCharacterMessage(`O caractere '${character}' não pode ser enviado.`);
    } else {
      setEspecialCharacterMessage('');
    }
  };

  return (
    <>
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Texto SMS
            <span>*</span>
            -
            <small>
              {` ${
                content.channelData && content.channelData.message
                  ? content.channelData.message.length : 0}/134 caracteres`}
            </small>
          </label>
          <Textarea
            disabled={disabledFields}
            placeholder="Texto SMS"
            maxLength={134}
            onChange={(e) => {
              verifyEspecialCharecter(e.target.value);
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    message: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.message || '' : ''}
            rows={3}
            invalid={hasError && messageIsInvalid()}
          />
          <ErrorLink>{`${especialCharacterMessage}`}</ErrorLink>
        </FormGroup>
      </Row>
      <LandingPage
        title="Configuração da Landing Page"
        campaign={campaign}
        criativoEdit={criativoEdit}
        campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
        hasError={hasError}
        disabledFields={disabledFields}
      />
    </>
  );
};

SMSMediaMessageForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

export default SMSMediaMessageForm;
