import styled from 'styled-components';

export const PreviewVastType = styled.div`
  position: absolute;
  top: 73px;
  bottom: 89px;
  left: 31px;
  right: 31px;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.regular};

  font-family: din,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;

  .section-logo {
    display: flex;
    margin-top: 20px;
  }
  .content-preview {
    width: 100%;
    flex: 1;
    align-items: flex-end;
    display: flex;

    .section-content-shadow {
      width: 100%;
      padding: 0 25px 0;
      background: linear-gradient(0deg, rgba(0, 0, 0, 0.8) 76.04%, rgba(0, 0, 0, 0) 100%);

      .section-title {
        width: 100%;
        max-width: 270px;
        margin: 0 auto 20px;
        font-family: "din-alternate";
        font-size: 1.4rem;
        font-weight: normal;
        line-height: 1.6rem;
        text-align: center;
        text-shadow: 4px 4px 10px #000;
        color: #fff;
        text-transform: uppercase;
      }

      .section-content {
        display: flex;
        flex-direction: column;
        align-items: center;

        display: block;
        width: 100%;
        padding: 11px;
        margin: 0 auto 18px;
        border: 0;
        border-radius: 3.42466px;
        background-color: #da291c;
        transition: background-color .3s;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          background-color: #ad2116;
        }

        > p {
          text-align: center;
          margin-bottom: 0;
        }
        .days {
          font-size: 1.6rem;
          font-weight: bold;
          color: #fff;
        }
        .price {
          margin: 0 0 8px;
          font-size: 1rem;
          font-weight: 900;
          color: #000;

          .price-highlight {
            font-size: 1.6rem;
            font-weight: normal;
            color: #ffcf0d;
          }
        }
        .sign {
          margin: 0 0 5px;
          font-family: "din-alternate";
          font-size: 2.1rem;
          font-weight: normal;
          color: #fff;
        }
        .validity {
          margin: 0 0 8px;
          font-size: .9rem;
          font-weight: 900;
          color: #fff;
        }
        .cancel {
          font-size: 1rem;
          font-weight: bold;
          color: #000;
        }
      }
    }
  }
`;

export const BoxLogo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 10px;
  width: 100%;
  img {
    height: 50px;
    width: 100%;
    object-fit: contain;
    object-position: center;
  }
`;
