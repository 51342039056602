import api from './axios';

const list = async () => {
  try {
    const { data } = await api.get('mcare-artemis-audience-manager/demo');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const save = async (demos) => {
  try {
    const { data } = await api.post(
      'mcare-artemis-audience-manager/demo',
      demos,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  list,
  save,
};
