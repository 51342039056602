import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import randomColor from 'random-color';
import {
  FaAngleRight, FaAngleLeft, FaEdit, FaTrash, FaMapMarkerAlt, FaStore, FaTimes, FaHome, FaBuilding,
} from 'react-icons/fa';
import {
  Map,
  TileLayer,
  FeatureGroup,
  // GeoJSON,
  ZoomControl,
  Polygon,
  Circle,
  Tooltip,
} from 'react-leaflet';
import L from 'leaflet';
import { EditControl } from 'react-leaflet-draw';
import 'leaflet/dist/leaflet.css';
import 'leaflet-draw/dist/leaflet.draw.css';
import './css.css';

import Checkbox from '~/Components/Checkbox';
import ColorPicker from '~/Components/ColorPicker';
import Modal from '~/Components/Modal';
import Button from '~/Components/Button';
import MarkerList from '~/Pages/PointsManagement/components/MarkerList';
import ModalRadius from './components/ModalRadius';

import { Creators as AudienceActions } from '~/redux/ducks/audience';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';
import { Creators as PointsManagementActions } from '~/redux/ducks/pointsManagement';

import {
  Container,
  MenuBox,
  ButtonOpenClose,
  MenuBoxHeader,
  MenuBoxBody,
  MenuBoxBodyItem,
  Row,
  ButtonCloseModal,
  RowFooter,
  FormGroup,
  Input,
  ButtonRadius,
  MenuBoxBodyItemIcon,
  InputRadius,
} from './styles';

// eslint-disable-next-line no-underscore-dangle
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-icon.png',
  shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.0.0/images/marker-shadow.png',
});
L.drawLocal = {
  draw: {
    toolbar: {
      actions: {
        title: 'Cancelar desenho',
        text: 'Cancelar',
      },
      finish: {
        title: 'Terminar o desenho',
        text: 'Terminar',
      },
      undo: {
        title: 'Excluir o último ponto desenhado',
        text: 'Excluir o último ponto',
      },
      buttons: {
        polyline: 'Desenhe uma polilinha',
        polygon: 'Desenhe um polígono',
        rectangle: 'Desenhe um retângulo',
        circle: 'Desenhar um círculo',
        marker: 'Desenhe um marcador',
        circlemarker: 'Desenhe um marcador de círculo',
      },
    },
    handlers: {
      circle: {
        tooltip: {
          start: 'Clique e arraste para desenhar o círculo.',
        },
        radius: 'Raio',
      },
      circlemarker: {
        tooltip: {
          start: 'Clique no mapa para colocar o marcador do círculo.',
        },
      },
      marker: {
        tooltip: {
          start: 'Clique no mapa para colocar o marcador.',
        },
      },
      polygon: {
        tooltip: {
          start: 'Clique para começar a desenhar o poligono.',
          cont: 'Clique para continuar desenhando o poligono.',
          end: 'Clique no primeiro ponto para fechar o poligono.',
        },
      },
      polyline: {
        error: '<strong>Erro:</strong> as bordas da forma não podem cruzar!',
        tooltip: {
          start: 'Clique para começar a desenhar a linha.',
          cont: 'Clique para continuar desenhando a linha.',
          end: 'Clique no último ponto para terminar a linha.',
        },
      },
      rectangle: {
        tooltip: {
          start: 'Clique e arraste para desenhar um retângulo.',
        },
      },
      simpleshape: {
        tooltip: {
          end: 'Solte o mouse para terminar de desenhar.',
        },
      },
    },
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: 'Salvar alterações',
          text: 'Salvar',
        },
        cancel: {
          title: 'Cancelar edição, descarta todas as alterações',
          text: 'Cancelar',
        },
        clearAll: {
          title: 'Limpar todas as camadas',
          text: 'Limpar tudo',
        },
      },
      buttons: {
        edit: 'Editar camadas',
        editDisabled: 'Sem camadas para editar',
        remove: 'Excluir camadas',
        removeDisabled: 'Nenhuma camada para excluir',
      },
    },
    handlers: {
      edit: {
        tooltip: {
          text: 'Arraste alças ou marcadores para editar recursos.',
          subtext: 'Clique em cancelar para desfazer as alterações.',
        },
      },
      remove: {
        tooltip: {
          text: 'Clique em um recurso para remover.',
        },
      },
    },
  },
};
class AudienceMap extends Component {
  state = {
    menuIsOpen: false,
    selectedPlot: null,
    isSelectedAll: false,
    referenceForManyItens: [],
    radiusForManyItens: 0,
    hasRadiusForManyItens: false,
    openModalEditManyItens: false,
    filter: ['casa', 'trabalho', 'fim_de_semana'],
  };

  leafletMapRef = null;

  refGeometrylayerGroup = null;

  refPointslayerGroup = null;

  clearLayers = async () => new Promise((resolve) => {
    const { updateGeofenceData } = this.props;
    updateGeofenceData([]);
    this.refGeometrylayerGroup.leafletElement.clearLayers();
    // this.refPointslayerGroup.leafletElement.clearLayers();
    setTimeout(resolve, 200);
  });

  onCreatedRadius = async (items) => {
    const {
      updateGeofenceData,
      geofenceData,
      onChange,
      geopoints: { geoPoints },
    } = this.props;
    const layers = [];
    items.selectedPoints.forEach((point) => {
      const findItem = geoPoints.find(i => i.uuid === point);
      layers.push({
        uuid: uuid4(),
        name: findItem.identificador,
        color: randomColor(0.99, 0.99).hexString(),
        references: ['fim_de_semana', 'casa', 'trabalho'],
        type: 'circle',
        distances: items.radius,
        locations: {
          lat: parseFloat(findItem.latitude),
          lon: parseFloat(findItem.longitude),
        },
      });
    });
    await this.clearLayers();
    updateGeofenceData([...geofenceData, ...layers]);
    onChange();
  };

  onCreated = async ({ layer, layerType }) => {
    const { updateGeofenceData, geofenceData, onChange } = this.props;
    layer.remove();
    const newColor = randomColor(0.99, 0.99).hexString();
    const newLayer = {
      uuid: uuid4(),
      name: `Região ${geofenceData.length + 1}`,
      color: newColor,
      references: ['fim_de_semana', 'casa', 'trabalho'],
    };

    if (layerType === 'circle') {
      const geoJson = layer.toGeoJSON();
      newLayer.type = 'circle';
      newLayer.distances = layer.getRadius();
      newLayer.locations = {
        lat: geoJson.geometry.coordinates[1],
        lon: geoJson.geometry.coordinates[0],
      };
    } else {
      newLayer.type = 'polygon';
      const geoJson = layer.toGeoJSON();
      newLayer.locations = geoJson.geometry.coordinates[0].map(coord => ({
        lat: coord[1],
        lon: coord[0],
      }));
    }
    await this.clearLayers();
    updateGeofenceData([...geofenceData, newLayer]);
    onChange();
  }

  onEdited = async ({ layers }) => {
    const { updateGeofenceData, geofenceData, onChange } = this.props;
    let updatedLayers = [...geofenceData];

    layers.eachLayer((layer) => {
      layer.remove();
      const geoJson = layer.toGeoJSON();
      const dataToReplace = {};
      switch (layer.options.type) {
        case 'circle': {
          dataToReplace.distances = layer.getRadius();
          dataToReplace.locations = {
            lat: geoJson.geometry.coordinates[1],
            lon: geoJson.geometry.coordinates[0],
          };
          break;
        }
        case 'polygon': {
          dataToReplace.locations = geoJson.geometry.coordinates[0].map(coord => ({
            lat: coord[1],
            lon: coord[0],
          }));
          break;
        }
        default:
          break;
      }
      updatedLayers = updatedLayers.map((item) => {
        if (item.uuid === layer.options.uuid) {
          return {
            ...item,
            ...dataToReplace,
          };
        }
        return item;
      });
    });
    await this.clearLayers();
    updateGeofenceData(updatedLayers);
    onChange();
  };

  getCustomIcon = () => {
    const { iconPoint } = this.props;
    const iconPerson = new L.Icon({
      iconUrl: iconPoint,
      iconSize: [40, 40],
      iconAnchor: [20, 40],
      popupAnchor: [0, -40],
      shadowUrl: null,
      shadowSize: null,
      shadowAnchor: null,
    });
    return iconPerson;
  };

  selectItemPoint = (item, selected) => {
    const { geofenceData, updateGeofenceData } = this.props;

    updateGeofenceData([...geofenceData.map((p) => {
      if (p.uuid !== item.uuid) {
        return p;
      }
      return {
        ...item,
        selected,
      };
    })]);
  }

  renderMenu = () => {
    const { menuIsOpen, isSelectedAll, filter } = this.state;
    const {
      geofenceData, confirmModal, existsItemSelected, updateGeofenceData, onChange,
    } = this.props;
    const hasFilteredHouse = filter.find(refe => refe === 'casa');
    const hasFilteredWork = filter.find(refe => refe === 'trabalho');
    const hasFilteredWeekend = filter.find(refe => refe === 'fim_de_semana');

    const getCheckedsReferences = (itemGeo) => {
      const hasHouse = itemGeo.references.find(refe => refe === 'casa');
      const hasWork = itemGeo.references.find(refe => refe === 'trabalho');
      const hasWeekend = itemGeo.references.find(refe => refe === 'fim_de_semana');
      return {
        hasHouse: !!hasHouse,
        hasWork: !!hasWork,
        hasWeekend: !!hasWeekend,
      };
    };
    const geofenceDataFiltered = geofenceData.filter((g) => {
      const hasInFilter = g.references.filter(r => filter.includes(r));
      return hasInFilter.length > 0;
    });
    return (
      <MenuBox menuIsOpen={menuIsOpen}>
        <ButtonOpenClose onClick={() => this.setState({ menuIsOpen: !menuIsOpen })}>
          {menuIsOpen ? <FaAngleLeft /> : <FaAngleRight />}
        </ButtonOpenClose>
        <MenuBoxHeader>
          <div className="header-title">
            <span>Pontos</span>
            <div className="base-filters">
              <span>Filtro:</span>
              <div>
                <button
                  type="button"
                  className={hasFilteredHouse ? 'filter-checked' : ''}
                  data-tip
                  data-for="filter-casa"
                  onClick={() => {
                    if (hasFilteredHouse) {
                      this.setState({
                        filter: filter.filter(f => f !== 'casa'),
                      });
                    } else {
                      this.setState({
                        filter: [...filter, 'casa'],
                      });
                    }
                  }}
                >
                  <FaHome />
                </button>
                <button
                  type="button"
                  className={hasFilteredWork ? 'filter-checked' : ''}
                  data-tip
                  data-for="filter-trabalho"
                  onClick={() => {
                    if (hasFilteredWork) {
                      this.setState({
                        filter: filter.filter(f => f !== 'trabalho'),
                      });
                    } else {
                      this.setState({
                        filter: [...filter, 'trabalho'],
                      });
                    }
                  }}
                >
                  <FaBuilding />
                </button>
                <button
                  type="button"
                  className={hasFilteredWeekend ? 'filter-checked' : ''}
                  data-tip
                  data-for="filter-fim_de_semana"
                  onClick={() => {
                    if (hasFilteredWeekend) {
                      this.setState({
                        filter: filter.filter(f => f !== 'fim_de_semana'),
                      });
                    } else {
                      this.setState({
                        filter: [...filter, 'fim_de_semana'],
                      });
                    }
                  }}
                >
                  <FaStore />
                </button>
                <ReactTooltip effect="solid" id="filter-casa" aria-haspopup="true">
                  Casa
                </ReactTooltip>
                <ReactTooltip effect="solid" id="filter-trabalho" aria-haspopup="true">
                  Trabalho
                </ReactTooltip>
                <ReactTooltip effect="solid" id="filter-fim_de_semana" aria-haspopup="true">
                  Final de Semana
                </ReactTooltip>
              </div>
            </div>
          </div>
          <div className="base-action-all">
            <div className="base-checkbox-items">
              <Checkbox
                label="Selecionar todos"
                value={isSelectedAll}
                onChange={(value) => {
                  this.setState({
                    isSelectedAll: value,
                  });
                  updateGeofenceData([
                    ...geofenceDataFiltered.map(p => ({
                      ...p,
                      selected: value,
                    })),
                  ]);
                }}
              />
            </div>
            <div className={`base-buttons-action-all ${!existsItemSelected ? 'disabled' : ''}`}>
              <button
                type="button"
                disabled={!existsItemSelected}
                onClick={() => {
                  this.setState({ openModalEditManyItens: true });
                }}
              >
                <FaEdit />
              </button>
              <button
                type="button"
                className="remove"
                disabled={!existsItemSelected}
                onClick={() => {
                  const itensSelected = geofenceDataFiltered.filter(item => item.selected);
                  confirmModal(
                    'Deseja remover os pontos?',
                    itensSelected.map(item => item.name).join(' - '),
                    async () => {
                      const newList = [...geofenceDataFiltered];
                      await this.clearLayers();
                      updateGeofenceData(newList.filter(p => !p.selected));
                      onChange();
                    },
                  );
                }}
              >
                <FaTrash />
              </button>
            </div>
          </div>
        </MenuBoxHeader>
        <MenuBoxBody>
          {geofenceDataFiltered.map(item => (
            <MenuBoxBodyItem key={item.uuid} className={item.selected ? 'selected-item' : ''}>
              <div className="header-item">
                <h3>
                  <Checkbox
                    label={item.name}
                    value={item.selected || false}
                    onChange={(value) => {
                      this.selectItemPoint(item, value);
                    }}
                  />
                </h3>
                <MenuBoxBodyItemIcon type={item.type} />
                <button
                  type="button"
                  onClick={() => {
                    this.leafletMapRef.leafletElement.setView(item.center, 11);
                  }}
                >
                  <FaMapMarkerAlt />
                </button>

              </div>
              <div className="base-info-item">
                <div className="item-content">
                  <div className="item-content-checks">
                    <span
                      className={getCheckedsReferences(item).hasHouse ? 'checked' : 'unchecked'}
                      data-tip
                      data-for={`casa-${item.uuid}`}
                    >
                      <FaHome />
                    </span>
                    <span
                      className={getCheckedsReferences(item).hasWork ? 'checked' : 'unchecked'}
                      data-tip
                      data-for={`trabalho-${item.uuid}`}
                    >
                      <FaBuilding />
                    </span>
                    <span
                      className={getCheckedsReferences(item).hasWeekend ? 'checked' : 'unchecked'}
                      data-tip
                      data-for={`fim_de_semana-${item.uuid}`}
                    >
                      <FaStore />
                    </span>
                  </div>
                  <ReactTooltip effect="solid" id={`casa-${item.uuid}`} aria-haspopup="true">Casa</ReactTooltip>
                  <ReactTooltip effect="solid" id={`trabalho-${item.uuid}`} aria-haspopup="true">Trabalho</ReactTooltip>
                  <ReactTooltip effect="solid" id={`fim_de_semana-${item.uuid}`} aria-haspopup="true">Final de Semana</ReactTooltip>
                </div>
                <div className={`base-buttons-header-item ${existsItemSelected ? 'disabled' : ''}`}>
                  <button
                    type="button"
                    disabled={existsItemSelected}
                    onClick={() => {
                      this.setState({
                        selectedPlot: item,
                      });
                    }}
                  >
                    <FaEdit />
                  </button>
                  <button
                    type="button"
                    className="remove"
                    disabled={existsItemSelected}
                    onClick={() => {
                      confirmModal(
                        'Deseja remover o ponto?',
                        `${item.name}`,
                        async () => {
                          const newList = [...geofenceDataFiltered];
                          await this.clearLayers();
                          const { uuid } = item;
                          updateGeofenceData(newList.filter(p => p.uuid !== uuid));
                          this.setState({ selectedPlot: null });
                          onChange();
                        },
                      );
                    }}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div>
            </MenuBoxBodyItem>
          ))}
        </MenuBoxBody>
      </MenuBox>
    );
  };

  renderModal = () => {
    const { selectedPlot } = this.state;
    const {
      geofenceData,
      updateGeofenceData,
      onChange,
      confirmModal,
    } = this.props;
    const hasHouse = selectedPlot.references.find(refe => refe === 'casa');
    const hasWork = selectedPlot.references.find(refe => refe === 'trabalho');
    const hasWeekend = selectedPlot.references.find(refe => refe === 'fim_de_semana');
    return (
      <Modal width={500}>
        <Row>
          <h1>Editar ponto</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              this.setState({ selectedPlot: null });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <Row>
          <FormGroup>
            <label>Nome:</label>
            <Input
              placeholder=""
              value={selectedPlot.name || ''}
              onChange={(e) => {
                this.setState({
                  selectedPlot: {
                    ...selectedPlot,
                    name: e.target.value,
                  },
                });
              }}
            />
          </FormGroup>
          <FormGroup style={{ flex: 0, marginLeft: 10 }}>
            <label>Cor:</label>
            <ColorPicker
              value={selectedPlot.color}
              onChange={(color) => {
                this.setState({
                  selectedPlot: {
                    ...selectedPlot,
                    color,
                  },
                });
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label>Referências:</label>
            <Checkbox
              style={{ marginRight: 10 }}
              label="Casa"
              value={hasHouse}
              onChange={(house) => {
                let newReferences = [...selectedPlot.references];
                if (house) {
                  newReferences.push('casa');
                } else {
                  newReferences = newReferences.filter(refe => refe !== 'casa');
                }
                this.setState({
                  selectedPlot: {
                    ...selectedPlot,
                    references: newReferences,
                  },
                });
              }}
            />
            <Checkbox
              style={{ marginRight: 10 }}
              label="Trabalho"
              value={hasWork}
              onChange={(work) => {
                let newReferences = [...selectedPlot.references];
                if (work) {
                  newReferences.push('trabalho');
                } else {
                  newReferences = newReferences.filter(refe => refe !== 'trabalho');
                }
                this.setState({
                  selectedPlot: {
                    ...selectedPlot,
                    references: newReferences,
                  },
                });
              }}
            />
            <Checkbox
              label="Final de semana"
              value={hasWeekend}
              onChange={(weekend) => {
                let newReferences = [...selectedPlot.references];
                if (weekend) {
                  newReferences.push('fim_de_semana');
                } else {
                  newReferences = newReferences.filter(refe => refe !== 'fim_de_semana');
                }
                this.setState({
                  selectedPlot: {
                    ...selectedPlot,
                    references: newReferences,
                  },
                });
              }}
            />
          </FormGroup>
        </Row>
        <RowFooter>
          <Button
            bordered
            color="danger2"
            title="Remover"
            onClick={() => {
              confirmModal(
                'Deseja remover o ponto?',
                `${selectedPlot.name}`,
                async () => {
                  const newList = [...geofenceData];
                  await this.clearLayers();
                  const { uuid } = selectedPlot;
                  updateGeofenceData(newList.filter(p => p.uuid !== uuid));
                  this.setState({ selectedPlot: null });
                  onChange();
                },
              );
            }}
          />
          <Button
            title="SALVAR"
            onClick={async () => {
              const newList = [...geofenceData];
              await this.clearLayers();
              updateGeofenceData(newList.map((item) => {
                if (item.uuid === selectedPlot.uuid) return selectedPlot;
                return item;
              }));
              this.setState({ selectedPlot: null });
              onChange();
            }}
          />
        </RowFooter>
      </Modal>
    );
  };

  renderModalManyItens = () => {
    const { referenceForManyItens, radiusForManyItens, hasRadiusForManyItens } = this.state;
    const {
      geofenceData,
      updateGeofenceData,
      onChange,
    } = this.props;
    const hasHouse = referenceForManyItens.find(refe => refe === 'casa');
    const hasWork = referenceForManyItens.find(refe => refe === 'trabalho');
    const hasWeekend = referenceForManyItens.find(refe => refe === 'fim_de_semana');
    const selectedItems = geofenceData.filter(i => i.selected);
    const selectedRadiusItems = selectedItems.filter(i => i.type === 'circle');
    const isAllCircle = selectedItems.length === selectedRadiusItems.length;
    return (
      <Modal width={500}>
        <Row>
          <h1>Editar pontos</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              this.setState({ openModalEditManyItens: false });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <Row>
          <FormGroup>
            <label>Referências:</label>
            <Checkbox
              style={{ marginRight: 10 }}
              label="Casa"
              value={hasHouse}
              onChange={(house) => {
                let newReferences = [...referenceForManyItens];
                if (house) {
                  newReferences.push('casa');
                } else {
                  newReferences = newReferences.filter(refe => refe !== 'casa');
                }
                this.setState({ referenceForManyItens: newReferences });
              }}
            />
            <Checkbox
              style={{ marginRight: 10 }}
              label="Trabalho"
              value={hasWork}
              onChange={(work) => {
                let newReferences = [...referenceForManyItens];
                if (work) {
                  newReferences.push('trabalho');
                } else {
                  newReferences = newReferences.filter(refe => refe !== 'trabalho');
                }
                this.setState({ referenceForManyItens: newReferences });
              }}
            />
            <Checkbox
              label="Final de semana"
              value={hasWeekend}
              onChange={(weekend) => {
                let newReferences = [...referenceForManyItens];
                if (weekend) {
                  newReferences.push('fim_de_semana');
                } else {
                  newReferences = newReferences.filter(refe => refe !== 'fim_de_semana');
                }
                this.setState({ referenceForManyItens: newReferences });
              }}
            />
          </FormGroup>
        </Row>
        {isAllCircle && (
          <Row>
            <FormGroup>
              <Checkbox
                label="Alterar tamanho do raio(metros):"
                value={hasRadiusForManyItens}
                onChange={(i) => {
                  this.setState({ hasRadiusForManyItens: i });
                }}
              />
              <InputRadius
                placeholder="Raio"
                type="number"
                value={radiusForManyItens}
                onChange={(e) => {
                  this.setState({ radiusForManyItens: e.target.value });
                }}
                disabled={!hasRadiusForManyItens}
              />
            </FormGroup>
          </Row>
        )}
        <RowFooter style={{ justifyContent: 'flex-end' }}>
          <Button
            title="SALVAR"
            onClick={async () => {
              const newList = [...geofenceData];
              await this.clearLayers();
              updateGeofenceData(newList.map((item) => {
                if (item.selected) {
                  const newAttributes = {
                    references: referenceForManyItens,
                    selected: false,
                  };

                  if (isAllCircle && hasRadiusForManyItens && radiusForManyItens > 0) {
                    newAttributes.distances = parseFloat(radiusForManyItens);
                  }

                  return {
                    ...item,
                    ...newAttributes,
                  };
                }
                return item;
              }));
              this.setState({
                openModalEditManyItens: false,
                isSelectedAll: false,
                referenceForManyItens: [],
                radiusForManyItens: 0,
                hasRadiusForManyItens: false,
              });
              onChange();
            }}
          />
        </RowFooter>
      </Modal>
    );
  };

  render() {
    const { selectedPlot, openModalEditManyItens } = this.state;
    const {
      geofenceData,
      geopoints: geo,
      setStatusModalEditRadius,
      statusModalEditRadius,
    } = this.props;
    const tileConfig = {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      url: 'https://dq0fosatyiphx.cloudfront.net/osm-intl/{z}/{x}/{y}.png',
    };
    return (
      <>
        <Container>
          {this.renderMenu()}
          <Map
            ref={(ref) => {
              this.leafletMapRef = ref;
            }}
            className="custom-map"
            center={[0, 0]}
            zoom={5}
            maxZoom={18}
            minZoom={5}
            zoomControl={false}
            style={{
              height: '100%',
              width: '100%',
            }}
            whenReady={() => {
              const { leafletElement: map } = this.leafletMapRef;
              if (geofenceData.length > 0) {
                const { leafletElement: mapGeometryGroup } = this.refGeometrylayerGroup;
                map.fitBounds(mapGeometryGroup.getBounds());
              } else if (geo && geo.geoPoints.length > 0) {
                const { leafletElement: mapPointsGroup } = this.refPointslayerGroup;
                map.fitBounds(mapPointsGroup.getBounds());
              }
            }}
          >
            <TileLayer attribution={tileConfig.attribution} url={tileConfig.url} />
            <ButtonRadius onClick={() => setStatusModalEditRadius(true)} />
            <ZoomControl position="topleft" />
            <FeatureGroup ref={(ref) => { this.refGeometrylayerGroup = ref; }}>
              <EditControl
                position="topright"
                onCreated={this.onCreated}
                onEdited={this.onEdited}
                draw={{
                  rectangle: false,
                  polyline: false,
                  marker: false,
                  circlemarker: false,
                }}
                edit={{
                  edit: true,
                  remove: false,
                }}
              />
              {/* Render das geometrias */}
              {geofenceData.map((item) => {
                switch (item.type) {
                  case 'circle': return (
                    <Circle
                      key={item.uuid}
                      uuid={item.uuid}
                      type={item.type}
                      layerClearable
                      center={[
                        item.locations.lat,
                        item.locations.lon,
                      ]}
                      radius={item.distances}
                      color={item.color}
                      fillOpacity={0.5}
                      weight={1}
                      onclick={() => {
                        this.setState({
                          selectedPlot: item,
                        });
                      }}
                    >
                      <Tooltip direction="top" opacity={1} offset={[0, 0]} sticky>
                        <span>{item.name}</span>
                      </Tooltip>
                    </Circle>
                  );
                  case 'polygon': return (
                    <Polygon
                      key={item.uuid}
                      uuid={item.uuid}
                      type={item.type}
                      layerClearable
                      positions={item.locations.map(loc => [loc.lat, loc.lon])}
                      color={item.color}
                      fillOpacity={0.5}
                      weight={1}
                      onclick={() => {
                        this.setState({
                          selectedPlot: item,
                        });
                      }}
                    >
                      <Tooltip direction="top" opacity={1} offset={[0, 0]} sticky>
                        <span>{item.name}</span>
                      </Tooltip>
                    </Polygon>
                  );
                  default: return null;
                }
              })}
            </FeatureGroup>
            {!!geo && (
              <FeatureGroup ref={(ref) => { this.refPointslayerGroup = ref; }}>
                {/* Render dos pontos */}
                {<MarkerList icon={this.getCustomIcon()} markers={geo.geoPoints} />}
              </FeatureGroup>
            )}
          </Map>
        </Container>
        {!!selectedPlot && this.renderModal()}
        {!!openModalEditManyItens && this.renderModalManyItens()}
        {!!statusModalEditRadius && <ModalRadius onCreatedRadius={this.onCreatedRadius} />}
      </>
    );
  }
}

AudienceMap.defaultProps = {
  existsItemSelected: false,
  geopoints: null,
  iconPoint: null,
  statusModalEditRadius: false,
};

AudienceMap.propTypes = {
  updateGeofenceData: PropTypes.func.isRequired,
  existsItemSelected: PropTypes.bool,
  geofenceData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  onChange: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  setStatusModalEditRadius: PropTypes.func.isRequired,
  geopoints: PropTypes.shape(),
  iconPoint: PropTypes.string,
  statusModalEditRadius: PropTypes.bool,
};

const mapStateToProps = ({ audience: { geofenceData }, pointsManagement }) => {
  const existsItemSelected = !!geofenceData.find(item => item.selected);

  return {
    geofenceData,
    existsItemSelected,
    geopoints: pointsManagement.geopoints,
    iconPoint: pointsManagement.iconPoint,
    statusModalEditRadius: pointsManagement.statusModalEditRadius,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    updateGeofenceData: AudienceActions.updateGeofenceData,
    confirmModal: ConfirmModalActions.showConfirmModal,
    setStatusModalEditRadius: PointsManagementActions.setStatusModalEditRadius,
  },
  dispatch,
);
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceMap);

// export default withTheme(AudienceMap);
