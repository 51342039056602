import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, ContainerContent } from './styles';

class AudienceList extends Component {
  state= {};

  componentDidMount() {}

  render() {
    const { title, value, icon: Icon } = this.props;
    return (
      <Container>
        <Icon />
        <ContainerContent>
          <h2>{title}</h2>
          <span>{value}</span>
        </ContainerContent>
      </Container>
    );
  }
}

AudienceList.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const mapStateToProps = (/* state */) => ({
  // audiences: state.audience.audiences,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceList);
