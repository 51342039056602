import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { push as pushRouter } from 'connected-react-router';

import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CampaignActions } from '~/redux/ducks/campaign';
import { Creators as HistoryCampaignActions } from '~/redux/ducks/historyCampaign';

import Modal from '~/Components/Modal';
import Button from '~/Components/Button';

import HistoryModal from '../Crud/Components/HistoryModal';

import {
  Container, Row, FormGroup, Textarea, // TitleItemResume,
} from './styles';

import Form from '../Crud/Form';

class ApprovalView extends Component {
  state = {
    showReject: false,
    showCancelCampaign: false,
    showPauseCampaign: false,
    showResumeCampaign: false,
    reason: '',
  };

  static propTypes = {
    loadCampaignEdit: PropTypes.func.isRequired,
    updateCampaign: PropTypes.func.isRequired,
    activeCampaignEdit: PropTypes.func.isRequired,
    saveCampaignEdit: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    campaingInvalid: PropTypes.bool.isRequired,
    campaign: PropTypes.shape(),
    audiences: PropTypes.arrayOf(PropTypes.shape()),
    partners: PropTypes.arrayOf(PropTypes.shape()),
    fortuna: PropTypes.arrayOf(PropTypes.shape()),
    match: PropTypes.shape().isRequired,
    vlrBase: PropTypes.number.isRequired,
    qntMax: PropTypes.number.isRequired,
    cancelCampaign: PropTypes.func.isRequired,
    pauseCampaign: PropTypes.func.isRequired,
    resumeCampaign: PropTypes.func.isRequired,
    transitionState: PropTypes.shape().isRequired,
    setTransitionState: PropTypes.func.isRequired,
    workflowStatus: PropTypes.func.isRequired,
    openHistoryCampaignModal: PropTypes.func.isRequired,
    attributes: PropTypes.arrayOf(PropTypes.shape()),
    fact: PropTypes.arrayOf(PropTypes.shape()),
  };

  static defaultProps = {
    campaign: null,
    audiences: [],
    partners: [],
    fortuna: [],
    attributes: [],
    fact: [],
  };

  componentDidMount() {
    const {
      match: { params },
      loadCampaignEdit,
    } = this.props;
    loadCampaignEdit(params.campaignId);
  }

  normalizeCampaignDatesHours = (campaign) => {
    if (campaign.startDate && campaign.startDate !== '0000-00-00 00:00:00') {
      campaign.startDate = moment(campaign.startDate, 'YYYY-MM-DD').format(
        'DD/MM/YYYY',
      );
    } else {
      campaign.startDate = null;
    }
    if (campaign.endDate && campaign.endDate !== '0000-00-00 00:00:00') {
      campaign.endDate = moment(campaign.endDate, 'YYYY-MM-DD').format(
        'DD/MM/YYYY',
      );
    } else {
      campaign.endDate = null;
    }
  };

  handleRejectReason = () => {
    const {
      campaign: { campaignRejectReason },
    } = this.props;
    this.setState({ showReject: true, reason: campaignRejectReason });
  };

  renderModalReject = () => {
    const { reason } = this.state;
    return (
      <Modal>
        <Row>
          <h1>Justificativa Rejeição</h1>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, marginRight: 0 }}>
            <label>Motivo</label>
            <Textarea
              style={{ marginRight: 0 }}
              placeholder="Digite o motivo de rejeição da campanha"
              value={reason}
              onChange={e => this.setState({
                reason: e.target.value,
              })
              }
            />
          </FormGroup>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
          <Button
            title="FECHAR"
            onClick={() => this.setState({ showReject: false })}
          />
        </Row>
      </Modal>
    );
  };

  renderModalCancelCampaign = () => {
    const { reason } = this.state;
    const { cancelCampaign } = this.props;
    return (
      <Modal>
        <Row>
          <h1>Cancelar Campanha</h1>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, marginRight: 0 }}>
            <label>Motivo</label>
            <Textarea
              style={{ marginRight: 0 }}
              placeholder="Digite o motivo de cancelar a campanha"
              value={reason}
              invalid={!reason}
              onChange={e => this.setState({
                reason: e.target.value,
              })
              }
            />
          </FormGroup>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
          <Button
            style={{ marginLeft: 0 }}
            color="light"
            title="CANCELAR"
            onClick={() => this.setState({ showCancelCampaign: false })}
          />
          <Button
            title="CONFIRMAR"
            disabled={!reason}
            onClick={() => {
              cancelCampaign(reason);
              this.setState({ showCancelCampaign: false });
            }}
          />
        </Row>
      </Modal>
    );
  };

  renderModalPauseCampaign = () => {
    const { reason } = this.state;
    const { pauseCampaign } = this.props;
    return (
      <Modal>
        <Row>
          <h1>Pausar Campanha</h1>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, marginRight: 0 }}>
            <label>Motivo</label>
            <Textarea
              style={{ marginRight: 0 }}
              placeholder="Digite o motivo de pausar a campanha"
              value={reason}
              invalid={!reason}
              onChange={e => this.setState({
                reason: e.target.value,
              })
              }
            />
          </FormGroup>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
          <Button
            style={{ marginLeft: 0 }}
            color="light"
            title="CANCELAR"
            onClick={() => this.setState({ showPauseCampaign: false })}
          />
          <Button
            title="CONFIRMAR"
            disabled={!reason}
            onClick={() => {
              pauseCampaign(reason);
              this.setState({ showPauseCampaign: false });
            }}
          />
        </Row>
      </Modal>
    );
  };

  renderModalResumeCampaign = () => {
    const { resumeCampaign } = this.props;
    return (
      <Modal>
        <Row>
          <h1>Reativar Campanha</h1>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          {/* <TitleItemResume>
            Você confirma a reativação da campanha?
          </TitleItemResume> */}
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
          <Button
            style={{ marginLeft: 0 }}
            color="light"
            title="CANCELAR"
            onClick={() => this.setState({ showResumeCampaign: false })}
          />
          <Button
            title="CONFIRMAR"
            onClick={() => {
              resumeCampaign();
              this.setState({ showResumeCampaign: false });
            }}
          />
        </Row>
      </Modal>
    );
  };

  render() {
    const {
      campaign,
      audiences,
      partners,
      fortuna,
      push,
      updateCampaign,
      campaingInvalid,
      activeCampaignEdit,
      saveCampaignEdit,
      vlrBase,
      qntMax,
      openHistoryCampaignModal,
      transitionState,
      setTransitionState,
      workflowStatus,
      attributes,
      fact,
    } = this.props;

    const {
      showReject,
      showCancelCampaign,
      showPauseCampaign,
      showResumeCampaign,
    } = this.state;

    if (!campaign) {
      return <Container />;
    }

    const { active, campaignStatusName: status } = campaign;
    const disabled = active || (status !== 'CREATED_CAMPAIGN' && status !== 'EDITING');

    return (
      <Container>
        <Form
          mode="APPROVAL"
          campaign={campaign}
          disabled={disabled}
          campaingInvalid={campaingInvalid}
          saveCampaignEdit={saveCampaignEdit}
          updateCampaign={updateCampaign}
          activeCampaignEdit={activeCampaignEdit}
          push={push}
          transitionState={transitionState}
          setTransitionState={setTransitionState}
          workflowStatus={workflowStatus}
          audiences={audiences}
          partners={partners}
          fortuna={fortuna}
          vlrBase={vlrBase}
          qntMax={qntMax}
          attributes={attributes}
          fact={fact}
          openHistoryCampaignModal={() => openHistoryCampaignModal(campaign.uuid)}
          showJustificativa={() => this.handleRejectReason()}
          handlerCancelCampaign={() => this.setState({
            showCancelCampaign: true,
            reason: '',
          })
          }
          handlerPauseCampaign={() => this.setState({
            showPauseCampaign: true,
            reason: '',
          })
          }
          handlerResumeCampaign={() => this.setState({
            showResumeCampaign: true,
          })
          }
        />
        {showReject && this.renderModalReject()}
        {showCancelCampaign && this.renderModalCancelCampaign()}
        {showPauseCampaign && this.renderModalPauseCampaign()}
        {showResumeCampaign && this.renderModalResumeCampaign()}
        <HistoryModal />
      </Container>
    );
  }
}

const validateCampaignInvalid = c => !c || !c.startDate || !c.endDate || !c.startHour || !c.endHour;

const mapStateToProps = (state) => {
  const { campaign } = state.campaign.edit;
  const campaingInvalid = validateCampaignInvalid(campaign);
  return {
    campaign,
    campaingInvalid,
    audiences: state.campaign.edit.audiences,
    partners: state.campaign.edit.partners,
    vlrBase: state.campaign.edit.vlrBase,
    qntMax: state.campaign.edit.qntMax,
    fortuna: state.campaign.edit.fortuna,
    transitionState: state.campaign.transition,
    attributes: state.campaign.edit.attributes,
    fact: state.campaign.edit.fact,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadCampaignEdit: CampaignActions.loadCampaignEdit,
    push: pushRouter,
    updateCampaign: CampaignActions.updateCampaignEdit,
    updateQntMax: CampaignActions.updateQntMax,
    activeCampaignEdit: CampaignActions.activeCampaignEdit,
    saveCampaignEdit: CampaignActions.saveCampaignEdit,
    cancelCampaign: CampaignActions.cancelCampaign,
    pauseCampaign: CampaignActions.pauseCampaign,
    resumeCampaign: CampaignActions.resumeCampaign,
    openHistoryCampaignModal: HistoryCampaignActions.show,
    setTransitionState: CampaignActions.setCampaignTransitionState,
    workflowStatus: CampaignActions.workflowStatus,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ApprovalView);
