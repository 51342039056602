export default {
  SMART_MESSAGE_TEXT: 'SmartMessageText',
  SMART_MESSAGE_TEXT_CLARO: 'SmartMessageTextClaro',
  SMART_MESSAGE_MENU: 'SmartMessageMenu',
  SATPUSH_CARD_TRIPLO: 'SATPUSH_CARD_TRIPLO',
  SMART_MESSAGE_MENU_TIM: 'SmartMessageMenuTim',
  USSD_MESSAGE_TEXT: 'USSDMessageText',
  USSD_MESSAGE_TEXT_CLARO: 'USSDMessageTextClaro',
  VOICE_MESSAGE_TEXT: 'VoiceMessageText',
  SMS_TEXT_MESSAGE: 'SMSTextMessage',
  SMS_MEDIA_MESSAGE: 'SMSMediaMessage',
  PUSH_NOTIFICATION_TEXT_MESSAGE: 'PushNotificationTextMessage',
  PUSH_NOTIFICATION_TEXT_MESSAGE_MINHA_OI: 'PushNotificationTextMessageMinhaOi',
  PUSH_NOTIFICATION_IMAGE_MESSAGE: 'PushNotificationImageMessage',
  PUSH_NOTIFICATION_IMAGE_MESSAGE_MINHA_OI: 'PushNotificationImageMessageMinhaOi',
  MIDIA_NO_CREDIT_AD: 'MidiaNoCreditAD',
  CREATIVE_CUSTOM: 'CreativeCustom',
  NO_CREDIT_BANNER_TEXT: 'NoCreditBannerText',
  NO_DATA_BANNER_TEXT: 'NoDataBannerText',
  SPONSOR_NOTIFY_TEXT: 'SponsorNotifyText',
  SPONSOR_NOTIFY_MEDIA: 'SponsorNotifyMedia',
  CHALLENGE: 'challenge',
  MEDIA_VIDEO: 'mediaVideo',
  RCS_CLARO: 'RCSClaro',
  MIDIA_WEB: 'MidiaWeb',
};
