import queryString from 'query-string';
import api from './axios';

const audienceRequest = async (filter) => {
  try {
    const { data } = await api.get(`mcare-artemis-audience-service/audience?${queryString.stringify(filter)}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getImpactCount = async ({ audienceUuid, zoneUuid, purgeAudienceUuid }) => {
  try {
    let filter = '?1=1';

    if(audienceUuid) {
      filter = filter.concat(`&audienceUuid=${audienceUuid}`);
    }

    if(purgeAudienceUuid) {
      filter = filter.concat(`&purgeAudienceUuid=${purgeAudienceUuid}`);
    }

    const { data } = await api.get(`mcare-artemis-audience-service/query/target/zone/${zoneUuid}/impact${filter}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const contractsRequest = async () => {
  try {
    const { data } = await api.get('contract');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceSave = async (audience) => {
  try {
    const { data } = await api.post(
      'mcare-artemis-audience-service/audience',
      audience,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceUpdate = async (uuid, audience) => {
  try {
    const { data } = await api.put(
      `mcare-artemis-audience-service/audience/${uuid}`,
      audience,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceGet = async (uuid) => {
  try {
    const { data } = await api.get(
      `mcare-artemis-audience-service/audience/${uuid}`,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceDelete = async (uuid) => {
  try {
    const { data } = await api.delete(
      `mcare-artemis-audience-service/audience/${uuid}`,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    const { data } = error.response;
    const errorMessage = {
      message: data.detail || 'Houve um problema ao excluir a audiência!',
      type: data.detail ? 'warning' : 'error',
    };
    throw errorMessage;
  }
};

const executeTestAudience = async (uuid) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-audience-service/audience/test/${uuid}`,
      null,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceCategoriesRequest = async () => {
  try {
    const { data } = await api.get('mcare-artemis-audience-service/category/v2/subcategory');
    return data.map(c => ({
      ...c,
      label: c.key === 'sexo' ? 'Gênero' : c.label,
    }));
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceCategoryDataRequest = async (keyCategory) => {
  try {
    const { data } = await api.get(`mcare-artemis-audience-service/category/data/${keyCategory}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getDataOfSubcategory = async (subcategoryKey, body) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-audience-service/category/v2/data/${subcategoryKey}`,
      { ...body },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getDataItemsOfSubcategory = async (subcategoryItem) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-audience-service/category/v2/data/${subcategoryItem.path[0]}`,
      { ...subcategoryItem.bodyToGetSubcategory },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceCategoryDataCriteriaCount = async (criteria, geolocations) => {
  try {
    const { data } = await api.post(
      'mcare-artemis-audience-service/query/count',
      { criteria, geolocations },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceCategoryDataCriteriaCountByZone = async (zoneId, criteria, geolocations) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-audience-service/query/${zoneId}/count`,
      { criteria, geolocations },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceCategoryDataCriteriaAnalyze = async (preferences, criteria) => {
  try {
    const { data } = await api.post(
      'mcare-artemis-audience-service/query/analyze',
      { preferences, criteria },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceCategoryDataCriteriaAnalyzeByZone = async (zoneId, preferences, criteria) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-audience-service/query/${zoneId}/analyze`,
      { preferences, criteria },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceGetMapMarkers = async (criteria, event) => {
  try {
    const { data } = await api.post(
      'mcare-artemis-audience-service/query/map',
      { event, criteria },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceGetMapMarkersByZone = async (zoneId, criteria, event) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-audience-service/query/${zoneId}/map`,
      { event, criteria },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceZonesOnCreateRequest = async () => {
  try {
    const { data } = await api.get('mcare-artemis-audience-service/zones');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceZonesOnUpdateRequest = async (audienceUuid) => {
  try {
    const { data } = await api.get(`mcare-artemis-audience-service/zones/${audienceUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const audienceCloneRequest = async (uuid, name) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-audience-service/audience/${uuid}/clone`,
      { name },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const generateUrlUploadAudienceExternal = async (audienceUuid) => {
  try {
    const { data } = await api.get(`mcare-artemis-audience-service/audience/external/${audienceUuid}/generatepresignurl`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getUrlDownloadOriginalFileAudienceExternal = async (audienceUuid) => {
  try {
    const { data } = await api.get(`mcare-artemis-audience-service/audience/external/${audienceUuid}/presignurl/original`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getUrlDownloadInvalidationFileAudienceExternal = async (audienceUuid) => {
  try {
    const { data } = await api.get(`mcare-artemis-audience-service/audience/external/${audienceUuid}/presignurl/invalidation`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};


export default {
  getImpactCount,
  audienceRequest,
  contractsRequest,
  audienceSave,
  audienceUpdate,
  audienceGet,
  audienceDelete,
  executeTestAudience,
  audienceCategoriesRequest,
  audienceCategoryDataRequest,
  getDataOfSubcategory,
  getDataItemsOfSubcategory,
  audienceCategoryDataCriteriaCount,
  audienceCategoryDataCriteriaCountByZone,
  audienceCategoryDataCriteriaAnalyze,
  audienceCategoryDataCriteriaAnalyzeByZone,
  audienceGetMapMarkers,
  audienceGetMapMarkersByZone,
  audienceZonesOnCreateRequest,
  audienceZonesOnUpdateRequest,
  audienceCloneRequest,
  generateUrlUploadAudienceExternal,
  getUrlDownloadOriginalFileAudienceExternal,
  getUrlDownloadInvalidationFileAudienceExternal,
};
