import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import termsBg from '~/Assets/Images/terms-bg.png';
import {
  ContainerStyled,
  RowStyled,
  TableStyled,
  FormGroupStyled,
  InputStyled,
  CurrentInputStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)``;

export const Input = styled(InputStyled)``;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const Table = styled(TableStyled)`
  thead {
    tr {
      th {
        white-space: nowrap;
      }
    }
  }
  tbody {
    tr {
      td {
        height: 68px;
        span.nowrap {
          white-space: nowrap;
        }
        span.auctionModified {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          background-color: ${props => props.theme.colors.warning};
          color: ${props => props.theme.colors.darker};
          margin-left: 5px;
          padding: 0 15px;
          height: 30px;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width:100%;
`;

export const ActionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      margin-left: 0;
    }
    svg {
      font-size: 20px;
      margin: 0;
    }
  }
`;

export const TermWrap = styled.div`
  display: flex;
  flex: 1;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const TermHeader = styled.div`
  display: flex;
  padding: 20px;
  min-height: 70px;
  max-height: 70px;
  align-items: center;
  justify-content: space-between;
`;

export const TermContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  min-height: 460px;
  max-height: 460px;
  padding: 20px 100px;
  background-image: url(${termsBg});
  background-repeat: repeat;
  background-size: 100px;
  p {
    text-indent: 40px;
    font-size: 18px;
    color: ${props => props.theme.colors.darker};
    margin-bottom: 10px;
    text-align: justify;
    text-justify: inter-word;
  }
  table {
    font-size: 18px;
    color: ${props => props.theme.colors.darker};
    margin-bottom: 10px;
    tr {
      td:first-child {
        font-weight: bold;
        width: 300px;
      }
    }
  }
`;

export const TermFooter = styled.div`
  display: flex;
  padding: 0 20px;
  min-height: 70px;
  max-height: 70px;
  align-items: center;
  justify-content: space-between;
  > button {
    margin: 0;
  }
  > button.terms-previous {
    color: ${props => props.theme.colors.primary};
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border: 0;
    > svg {
      margin-right: 5px;
    }
  }
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .header-title {
    display: flex;
    flex: 1;
  }
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  button {
    margin: 0;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex: 1;
  margin: 0;
  label {
    small {
      color: ${props => props.theme.colors.danger2};
      margin-left: 5px;
    }
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
`;

export const HeaderForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderFormInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  label {
    font-size: 16px;
    width: 80px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const HeaderStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
`;

export const HeaderStatusTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 30px;
  font-size: 16px;
`;

export const HeaderStatusContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

export const HeaderStatusContentItem = styled.div`
  font-size: 16px;
  span.successDate{
    color: ${props => props.theme.colors.checked};
    letter-spacing: 1px;
  }

  span.dangerDate{
    color: ${props => props.theme.colors.danger};
    letter-spacing: 1px;
  }
`;

export const Select = styled(ReactSelect)`
  width: 300px;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const CurrentInput = styled(CurrentInputStyled)`
  margin-right: 0;
`;
