import styled, { css } from 'styled-components';
import { hex2rgba } from '~/Utils';

export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => hex2rgba(props.theme.colors.dark, 0.5)};
  .value-range {
    align-self: flex-end;
    float: right;
    font-size: 18px;
    font-weight: 200;
    color: ${props => props.theme.colors.regular};
    margin-right: 15px;
  }
`;

export const ModalBox = styled.div`
  position: relative;
  z-index: 2;
  padding: ${props => props.padding}px;
  min-width: 400px;
  min-height: 200px;
  max-height: calc(100vh - 60px);
  background-color: white;
  overflow-y: ${props => (props.scroll ? 'auto' : 'visible')};
  ${props => props.width
    && css`
      width: ${props.width}px;
    `};
  ${props => props.height
    && css`
      max-height: ${props.height}px;
    `};
  ${props => props.minHeight
    && css`
      min-height: ${props.minHeight}px;
    `};
  h1 {
    font-size: 25px;
    color: ${props => props.theme.colors.primary};
    margin: 0;
  }
  box-shadow: 0 5px 20px ${props => hex2rgba(props.theme.colors.dark, 0.5)};
`;
