import styled, { css } from 'styled-components';
import { RowStyled, FormGroupStyled, InputStyled } from '~/Components/Styled';

import iconPolygon from './assets/icon-polygon.svg';

export const Container = styled.div`
  flex: 1;
  height: 100%;
  padding: 0;
  overflow: hidden;
  position: relative;
  .custom-map {
    zoom: 125%;
    .leaflet-draw-section:last-child {
      .leaflet-draw-toolbar {
        margin: -50px 0 0 0 !important;
        box-shadow: none;
      }
      .leaflet-draw-actions-bottom {
        right: 0px !important;
        top: 2px !important;
        li:first-child a {
          border-top-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
        }
        li:last-child a {
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }
      }
    }
    .leaflet-draw-section:first-child {
      .leaflet-draw-actions-top,
      .leaflet-draw-actions-bottom {
        right: 0px !important;
        top: 52px !important;
        li:first-child a {
          border-top-left-radius: 4px !important;
          border-bottom-left-radius: 4px !important;
        }
        li:last-child a {
          border-top-right-radius: 4px !important;
          border-bottom-right-radius: 4px !important;
        }
      }
    }
    .leaflet-draw-section {
      display: flex;
      justify-content: flex-end;
      width: fit-content;
      margin: 0;
      padding: 0;
      float: right;
      clear: both;

      .leaflet-draw-toolbar {
        display: flex;
        width: max-content;
        justify-content: flex-end;
        > a {
          border: 0;
          border-radius: 0;
        }
        .leaflet-draw-draw-polygon {
          width: 50px;
          height: 50px;
          background: url(${iconPolygon}) no-repeat;
          background-position-y: center;
          background-position-x: center;
          background-size: 30px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          border-right: 1px solid #ccc;
          border-bottom-left-radius: 4px;
          border-top-left-radius: 4px;
        }
        .leaflet-draw-draw-circle {
          width: 50px;
          height: 50px;
          background: url('data:image/svg+xml;utf8,<svg stroke="black" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle></svg>') no-repeat;
          background-position-y: center;
          background-position-x: center;
          background-size: 30px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          border-radius: 0px;
          border-right: 1px solid #ccc;
        }
        .leaflet-draw-edit-edit {
          height: 50px;
          width: 50px;
          background: url('data:image/svg+xml;utf8,<svg stroke="black" fill="black" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M79.18 282.94a32.005 32.005 0 0 0-20.24 20.24L0 480l4.69 4.69 92.89-92.89c-.66-2.56-1.57-5.03-1.57-7.8 0-17.67 14.33-32 32-32s32 14.33 32 32-14.33 32-32 32c-2.77 0-5.24-.91-7.8-1.57l-92.89 92.89L32 512l176.82-58.94a31.983 31.983 0 0 0 20.24-20.24l33.07-84.07-98.88-98.88-84.07 33.07zM369.25 28.32L186.14 227.81l97.85 97.85 199.49-183.11C568.4 67.48 443.73-55.94 369.25 28.32z"></path></svg>') no-repeat;
          background-position-y: center;
          background-position-x: center;
          background-size: 25px;
          background-color: #ffffff;
          display: flex;
          align-items: center;
          border-radius: 4px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
    .text-labels {
      /* margin-left: -24px !important; */
      width: auto !important;
      font-size: 20px;
      color: ${props => props.theme.colors.darker};
      font-weight: bold;
      white-space: nowrap;
      pointer-events: none;
      /* text-shadow: 4px 4px 2px rgba(0, 0, 0, 1); */
    }
    /* ajustes cores tooltip */
    .leaflet-draw-tooltip {
      background: ${props => props.theme.colors.dark};
      > * {
        color: ${props => props.theme.colors.white};
      }
    }
    .leaflet-draw-tooltip:before {
      border-right-color: ${props => props.theme.colors.dark};
    }
    /* ajustes cores botões ao editar/desenhar */
    .leaflet-draw-actions a {
      background-color: ${props => props.theme.colors.dark};
      color: ${props => props.theme.colors.white};
    }
    /* ajustes z-index */
    /*
      .leaflet-overlay-pane {
        z-index: 1000 !important;
      }
      .leaflet-marker-pane {
        z-index: unset !important;
      }
      .leaflet-popup-pane {
        z-index: 11000;
      }
    */
    /* ajustes icone ponto edição */
    .leaflet-div-icon {
      border: 1px solid #000 !important;
      opacity: 1 !important;
      width: 10px !important;
      height: 10px !important;
      z-index: 10000 !important;
    }
    .leaflet-draw-toolbar-top {
      box-shadow: 0 1px 5px rgba(0,0,0,0.65);
      padding-right: 100px;
    }
  }

  .__react_component_tooltip {
    z-index: 999999999;
  }
`;

export const MenuBoxBodyItemIcon = styled.div`
  min-width: 25px;
  max-width: 25px;
  min-height: 25px;
  max-height: 25px;
  margin: 0 5px;
  ${props => props.type === 'polygon' && css`
    background: url(${iconPolygon}) no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: 25px;
    background-color: #ffffff;
  `}
  ${props => props.type === 'circle' && css`
    background: url('data:image/svg+xml;utf8,<svg stroke="black" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle></svg>') no-repeat;
    background-position-y: center;
    background-position-x: center;
    background-size: 25px;
    background-color: #ffffff;
  `}
`;

export const MenuBox = styled.div`
  width: 300px;
  min-height: 560px;
  max-height: 560px;
  position: absolute;
  top: 10px;
  bottom: 10px;
  left: ${props => (props.menuIsOpen ? '10px' : '-300px')};
  transition: left 0.5s;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.light};
  border-radius: 4px;
  z-index: 99999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonOpenClose = styled.button.attrs({
  type: 'button',
})`
  width: 40px;
  height: 40px;
  position: absolute;
  right: -41px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.light};
  color: ${props => props.theme.colors.dark};
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  outline: none;
`;

export const MenuBoxHeader = styled.div`
  width: 300px;
  padding: 10px;
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  .header-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    > span {
      font-size: 25px;
    }
    .base-filters {
      display: flex;
      align-items: center;
      > span {
        font-size: 15px;
      }
      > div {
        display: flex;
        button {
          width: 27px;
          height: 27px;
          border: 1px solid ${props => props.theme.colors.primary};
          border-radius: 3px;
          background-color: ${props => props.theme.colors.white};
          margin-left: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 15px;
          color: ${props => props.theme.colors.primary};
          outline: none;
        }
        button.filter-checked {
          background-color: ${props => props.theme.colors.primary};
          color: ${props => props.theme.colors.white};
        }
      }
    }
  }
  padding-left: 10px;
  border-bottom: 1px solid ${props => props.theme.colors.light};
  color: ${props => props.theme.colors.darker};

  .base-action-all {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding-top: 10px;

    .base-checkbox-items {
      display: flex;
      align-items: center;

      > label {
        margin: 0;
      }
    }

    .base-buttons-action-all {
      display: flex;
      > button {
        width: 27px;
        height: 27px;
        border-radius: 4px;
        background-color: ${props => props.theme.colors.primary};
        border: none;
        font-size: 15px;
        color: ${props => props.theme.colors.white};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        outline: none;

        &.remove {
          background-color: ${props => props.theme.colors.danger2};
        }
      }
      &.disabled {
        > button {
          background-color: ${props => props.theme.colors.light};
          color: ${props => props.theme.colors.regular};
        }
      }
    }
  }
`;

export const MenuBoxBody = styled.div`
  width: 300px;
  min-height: 510px;
  max-height: 510px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const MenuBoxBodyItem = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.light};
  background-color: rgba(242, 242, 242, 0.3);
  border-radius: 5px;
  padding: 5px;
  margin-bottom: 10px;

  .header-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;

    > h3 {
      display: flex;
      flex: 1;
      font-size: 20px;
      > label {
        font-weight: normal;
        margin-bottom: 0;
      }
    }
    > button {
      background: white;
      border: 1px solid ${props => props.theme.colors.regular};
      color: ${props => props.theme.colors.primary};
      border-radius: 4px;
      display: flex;
      font-size: 15px;
      padding: 5px;
    }
  }

  &.selected-item {
    border-color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.white};
  }

  .base-info-item {
    display: flex;
    justify-content: space-between;
    padding-right: 2px;
    .item-content {
      display: flex;
      align-items: center;
      flex: 1;
      margin-right: 10px;
      padding-right: 10px;
      border-right: 1px solid #ccc;

      .item-content-checks {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        flex: 1;
        span {
          display: flex;
          align-items: center;
          font-size: 18px;
          height: 30px;
          border-radius: 8px;
          svg {
            font-size: 25px;
          }

          &.checked {
            svg {
              fill: ${props => props.theme.colors.primary}
            }
          }

          &.unchecked {
            svg {
              fill: ${props => props.theme.colors.danger2};
            }
          }
        }
      }
    }
    .base-buttons-header-item {
      display: flex;
      > button {
        width: 27px;
        height: 27px;
        border-radius: 4px;
        background-color: ${props => props.theme.colors.primary};
        border: none;
        font-size: 15px;
        color: ${props => props.theme.colors.white};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-left: 5px;
        outline: none;

        &.remove {
          background-color: ${props => props.theme.colors.danger2};
        }
      }
      &.disabled {
        > button {
          background-color: ${props => props.theme.colors.light};
          color: ${props => props.theme.colors.regular};
        }
      }
    }
  }

`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  width: 100%;
  margin-right: 0;
  > label{
    font-weight: bold;
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Input = styled(InputStyled)`
  width: 100%;
`;

export const ButtonRadius = styled.button.attrs({
  title: 'Criar raios a partir de pontos',
})`
  height: 50px;
  width: 50px;
  color: #000000;
  background: url('data:image/svg+xml;utf8,<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 16 16" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M15 5.5a4.394 4.394 0 0 1-4 4.5 2.955 2.955 0 0 0-.2-1A3.565 3.565 0 0 0 14 5.5a3.507 3.507 0 0 0-7-.3A3.552 3.552 0 0 0 6 5a4.622 4.622 0 0 1 4.5-4A4.481 4.481 0 0 1 15 5.5zM5.5 6a4.5 4.5 0 1 0 0 9.001 4.5 4.5 0 0 0 0-9z"></path></svg>') no-repeat;
  background-position-y: center;
  background-position-x: center;
  background-size: 25px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  border-radius: 4px;
  position: absolute;
  top: 10px;
  right: 60px;
  z-index: 1000;
  border: 0;
  border-radius: 0px;
  border-right: 1px solid ${props => props.theme.colors.darker};
`;

export const InputRadius = styled(InputStyled)`
  width: 100px;
  margin: 0;
`;
