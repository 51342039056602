import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import uuid4 from 'uuid4';
import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import {
  Container,
  Row,
  Column,
  FormGroup,
  Input,
  Textarea,
  Select,
  ErrorMessage,
} from './styles';
import { SUCCESS_AUTO_REDIRECT_TEMPLATE, SUCCESS_BUTTON_ACTION_TEMPLATE } from '~/redux/sagas/campaignEdit';
import UploadImage from '~/Components/UploadImage';
import PreviewImageBox from './components/PreviewImageBox';
import Button from '~/Components/Button';
import UploadService, { baseUrlMedia } from '~/services/UploadService';

class TabSuccess extends Component {
  state = {
    messageError: null,
  };

  componentDidMount() { }

  uploadFileToAWS = async (campaignUuid, file) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;

    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  onChangeDataSuccess = (success) => {
    const {
      changeData,
      criativoEdit,
      content: { mainData },
    } = this.props;

    changeData({
      ...criativoEdit,
      content: {
        ...criativoEdit.content,
        mainData: {
          ...mainData,
          success,
        },
      },
    });
  };

  uploadFileImage = async (files) => {
    const { campaign, content: { mainData: { success } } } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.onChangeDataSuccess({ ...success, image: url });
    }
  };

  renderImageInput = () => {
    const { messageError } = this.state;
    const { content: { mainData }, disabledFields, fileSizeConfigData } = this.props;
    const { success } = mainData;

    const getSuccessThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.success
        && fileSizeConfigData.creative.success.thumbnail
        && fileSizeConfigData.creative.success.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.success.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getSuccessThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.success
        && fileSizeConfigData.creative.success.thumbnail
        && fileSizeConfigData.creative.success.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.success.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    return (
      <>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <div style={{
              display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
            }}
            >
              <UploadImage
                disabled={disabledFields}
                label={success.image ? 'Alterar mídia' : 'Buscar mídia'}
                onUpload={this.uploadFileImage}
                accept="image/jpg,image/jpeg,image/png,image/gif"
                maxSize={getSuccessThumbnailMaxSize()}
                onRejectMaxSize={() => {
                  this.setState({ messageError: `A imagem excedeu o limite de ${getSuccessThumbnailMaxSizeLabel()}MB` });
                }}
                onClick={() => { }}
                styleContainer={{
                  width: 200,
                }}
                style={{
                  width: 200,
                  margin: 0,
                }}
                resolution={null}
              />
              {!!success.image && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => this.onChangeDataSuccess({ ...success, image: '' })}
                />
              )}
            </div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, alignItems: 'center' }}>
            <PreviewImageBox
              resolution={{ width: 300, height: 200 }}
              hasImage={!!success.image}
            >
              {success.image ? (
                <img src={success.image} alt="imagem" />
              ) : (
                <span style={{ textAlign: 'center' }}>Preview da imagem</span>
              )}
            </PreviewImageBox>
          </FormGroup>
        </Row>
        <Row>
          <span className="extensoes-permitidas-image">
            Extensões permitidas: PNG, JPG, JPEG, GIF
          </span>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
      </>
    );
  }

  renderInternalMode = () => {
    const { hasError, content: { mainData }, disabledFields } = this.props;
    const { success } = mainData;
    const buttonAction1 = success.buttonAction1 || SUCCESS_BUTTON_ACTION_TEMPLATE;
    const buttonAction2 = success.buttonAction2 || SUCCESS_BUTTON_ACTION_TEMPLATE;
    const autoRedirectUrl = success.autoRedirectUrl || SUCCESS_AUTO_REDIRECT_TEMPLATE;
    return (
      <>
        <Row>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup>
                <label>
                  Título
                  <span>*</span>
                </label>
                <Input
                  disabled={disabledFields}
                  placeholder="Digite o título"
                  maxLength="35"
                  onChange={(e) => {
                    this.onChangeDataSuccess({
                      ...success, title: e.target.value,
                    });
                  }}
                  value={success && success.title ? success.title : ''}
                  invalid={hasError && !success.title}
                />
              </FormGroup>
            </Row>
            <Row style={{ marginTop: 20 }}>
              <FormGroup>
                <label>
                  Texto livre
                  <span>*</span>
                </label>
                <Textarea
                  disabled={disabledFields}
                  rows="4"
                  maxLength="150"
                  placeholder="Texto livre"
                  onChange={(e) => {
                    this.onChangeDataSuccess({
                      ...success, description: e.target.value,
                    });
                  }}
                  value={success && success.description ? success.description : ''}
                  invalid={hasError && !success.description}
                />
              </FormGroup>
            </Row>
          </Column>
          <Column>
            {this.renderImageInput()}
          </Column>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <h3>Botões</h3>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, marginRight: 10 }}>
            <label>
              Texto -
              <small>
                {` ${
                  buttonAction1.label
                    ? buttonAction1.label.length : 0}/50 caracteres`}
              </small>
            </label>
            <Input
              disabled={disabledFields}
              maxLength={50}
              placeholder="Digite o texto do botão"
              onChange={(e) => {
                this.onChangeDataSuccess({
                  ...success,
                  buttonAction1: {
                    ...buttonAction1,
                    label: e.target.value,
                  },
                });
              }}
              value={buttonAction1.label}
              invalid={false}
            />
          </FormGroup>
          <FormGroup style={{ flex: 2 }}>
            <label>
              Link
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite o endereço do site"
              onChange={(e) => {
                this.onChangeDataSuccess({
                  ...success,
                  buttonAction1: {
                    ...buttonAction1,
                    action: {
                      ...buttonAction1.action,
                      callback: e.target.value,
                    },
                  },
                });
              }}
              value={buttonAction1.action.callback}
              invalid={false}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, marginRight: 10 }}>
            <label>
              Texto -
              <small>
                {` ${
                  buttonAction2.label
                    ? buttonAction2.label.length : 0}/50 caracteres`}
              </small>
            </label>
            <Input
              disabled={disabledFields}
              maxLength={50}
              placeholder="Digite o texto do botão"
              onChange={(e) => {
                this.onChangeDataSuccess({
                  ...success,
                  buttonAction2: {
                    ...buttonAction2,
                    label: e.target.value,
                  },
                });
              }}
              value={buttonAction2.label}
              invalid={false}
            />
          </FormGroup>
          <FormGroup style={{ flex: 2 }}>
            <label>
              Link
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite o endereço do site"
              onChange={(e) => {
                this.onChangeDataSuccess({
                  ...success,
                  buttonAction2: {
                    ...buttonAction2,
                    action: {
                      ...buttonAction2.action,
                      callback: e.target.value,
                    },
                  },
                });
              }}
              value={buttonAction2.action.callback}
              invalid={false}
            />
          </FormGroup>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <h3>URL de redirecionamento automático</h3>
        </Row>
        <Row>
          <FormGroup style={{ flex: 3, marginRight: 10 }}>
            <label>
              Link de redirecionamento
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite o Link de redirecionamento"
              onChange={(e) => {
                this.onChangeDataSuccess({
                  ...success,
                  autoRedirectUrl: {
                    ...autoRedirectUrl,
                    url: e.target.value,
                  },
                });
              }}
              value={autoRedirectUrl.url}
              invalid={false}
            />
          </FormGroup>
          <FormGroup style={{ flex: 1 }}>
            <label>
              Tempo de espera(segundos)
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite o tempo de espera"
              type="number"
              min={0}
              max={999}
              onChange={(e) => {
                this.onChangeDataSuccess({
                  ...success,
                  autoRedirectUrl: {
                    ...autoRedirectUrl,
                    waitSeconds: parseInt(e.target.value),
                  },
                });
              }}
              value={autoRedirectUrl.waitSeconds}
              invalid={false}
            />
          </FormGroup>
        </Row>
      </>
    );
  };

  renderExternalMode = () => {
    const { hasError, content: { mainData }, disabledFields } = this.props;

    const { success } = mainData;

    return (
      <Row>
        <FormGroup>
          <label>
            Link URL externa
            <span>*</span>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite a url"
            onChange={(e) => {
              this.onChangeDataSuccess({
                ...success, url: e.target.value,
              });
            }}
            value={success && success.url ? success.url : ''}
            invalid={hasError && (
              !success.url
              || !success.url.match(
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
              )
            )}
          />
        </FormGroup>
      </Row>
    );
  };

  render() {
    const { hasError, content: { mainData }, disabledFields } = this.props;

    const success = mainData && mainData.success ? mainData.success : { type: '' };

    return (
      <Container>
        <Row style={{ marginBottom: 25 }}>
          <FormGroup>
            <label>Página de Sucesso:</label>
            <Select
              disabled={disabledFields}
              style={{ width: 450 }}
              onChange={e => this.onChangeDataSuccess({
                type: e.target.value,
              })}
              value={success.type}
              invalid={hasError && !success.type}
            >
              <option value="">Selecione...</option>
              <option value="external">Página externa</option>
              <option value="internal">Página interna</option>
            </Select>
          </FormGroup>
        </Row>

        {success.type === 'external' && this.renderExternalMode()}
        {success.type === 'internal' && this.renderInternalMode()}

      </Container>
    );
  }
}

TabSuccess.defaultProps = {
  fileSizeConfigData: null,
};

TabSuccess.propTypes = {
  campaign: PropTypes.shape().isRequired,
  content: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  changeData: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  fileSizeConfigData: PropTypes.shape(),
};

const mapStateToProps = state => ({
  fileSizeConfigData: state.campaignEdit.fileSizeConfigData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TabSuccess);
