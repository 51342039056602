import { call, put } from 'redux-saga/effects';
// import FileSaver from 'file-saver';
// import TermsService from '~/services/TermsService';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as AudienceExporterCreators } from '~/redux/ducks/audienceExporter';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import RetargetService from '~/services/RetargetService';

export function* generateRetarget({ campaignUuid, retargetConfigUuid }) {
  yield put(startRequest());
  try {
    yield call(RetargetService.createRetarget, {
      campaignUuid, retargetConfigUuid,
    });
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Solicitação criada com sucesso.',
    ));
    yield put(AudienceExporterCreators.openModal(campaignUuid));
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao cadastrar a solicitação de geração do arquivo.',
    ));
  }
}

export function* downloadRetarget({ campaignUuid, retargetUuid }) {
  yield put(startRequest());
  try {
    const responseUrlDownload = yield call(RetargetService.getDownloadUrl, {
      campaignUuid, retargetUuid,
    });

    window.open(responseUrlDownload, '_blank');

    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao realizar o download do arquivo.',
    ));
  }
}

export function* openModal({ campaignUuid }) {
  yield put(startRequest());
  try {
    const responseGetConfigs = yield call(RetargetService.getConfigs, campaignUuid);

    const responseGetRetargets = yield call(RetargetService.getRetargets, campaignUuid);

    const exportAudienceList = responseGetConfigs.map((config) => {
      const item = responseGetRetargets.find(retarget => retarget.retargetConfigUuid === config.uuid);

      const status = item ? item.status : 'NOT_RETARGET';
      const timestamp = item ? item.timestamp : null;
      const retargetUuid = item ? item.uuid : null;

      return {
        campaignUuid,
        retargetConfigUuid: config.uuid,
        name: config.name,
        status,
        timestamp,
        retargetUuid,
      };
    });
    yield put(AudienceExporterCreators.loadExportModalData(exportAudienceList));
    yield put(AudienceExporterCreators.changeShowModal(true));
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados para Exportação de Audiência.',
    ));
  }
}
