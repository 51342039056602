/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
/* eslint-disable arrow-parens */
/* eslint-disable import/no-unresolved */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { SketchPicker } from "react-color";
import { Container, Input, ColorBox, Popover, Cover } from "./styles";

const ColorPicker = ({ value, onChange }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const handleClick = () => {
    setDisplayColorPicker(!displayColorPicker);
  };

  const handleClose = () => {
    setDisplayColorPicker(false);
  };

  const handleChange = color => {
    onChange(color.hex.replace("#", ""));
  };

  return (
    <Container>
      <ColorBox color={value} onClick={handleClick} />
      <Input
        value={value}
        placeholder="Cor"
        onChange={e => {
          onChange(e.target.value);
        }}
        onClick={handleClick}
      />
      {displayColorPicker && (
        <Popover>
          <Cover onClick={handleClose} />
          <SketchPicker disableAlpha color={value} onChange={handleChange} />
        </Popover>
      )}
    </Container>
  );
};

ColorPicker.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

export default ColorPicker;
