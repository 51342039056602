import React from 'react';

function ButtonRefreshIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="28"
      fill="none"
      viewBox="0 0 13 14"
    >
      <path
        fill="#00A919"
        d="M12.627 1.414a.472.472 0 00-.56.363l-.344 1.6A6.132 6.132 0 006.431.346 6.141 6.141 0 00.297 6.48a.472.472 0 00.944 0 5.196 5.196 0 015.19-5.19 5.19 5.19 0 014.49 2.583l-1.573-.337a.472.472 0 10-.198.923l2.7.579a.471.471 0 00.56-.363l.58-2.7a.472.472 0 00-.363-.56zM12.152 6.952a.472.472 0 00-.456.488 5.156 5.156 0 01-1.398 3.718 5.157 5.157 0 01-3.791 1.644 5.19 5.19 0 01-4.415-2.461l1.64.44a.472.472 0 10.245-.912l-2.668-.715a.472.472 0 00-.578.334l-.715 2.667a.472.472 0 10.912.245l.402-1.5a6.132 6.132 0 005.38 2.842 6.095 6.095 0 004.277-1.939 6.094 6.094 0 001.652-4.395.472.472 0 00-.487-.456z"
      />
    </svg>
  );
}

export default ButtonRefreshIcon;
