import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import { FormGroupStyled } from '~/Components/Styled';

export const Container = styled.div.attrs({
  className: 'insight-block',
})`
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: ${props => props.maxHeight - 64}px;
  max-height: ${props => props.maxHeight - 64}px;
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryHex}30;
    height: 40px;
    font-size: 16px;
    padding: 10px;
  }
`;

export const Loading = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const IconContainer = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    font-size: 100px;
    color: ${props => props.theme.colors.lighter};
  }
`;

export const ButtonEditPreferences = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  // border: 2px solid ${props => props.theme.colors.primary};
  border: 0;
  color: ${props => props.theme.colors.primary};
  font-size: 20px;
  height: 30px;
  width: 30px;
  margin-right: -5px;
  outline: none;
  border-radius: 4px;
`;

export const ModalContainer = styled.div`
  position: absolute;
  background-color: ${props => props.theme.colors.white};
  top: 40px;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding: 10px;
  overflow-y: auto;
`;

export const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 10px;
  border-top: 1px solid ${props => props.theme.colors.primary};
  min-height: 55px;
  max-height: 55px;
  button {
    margin: 0;
    height: 35px;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  width: 100%;
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)}px;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
  .grouped-types {
    display: flex;
    width: 100%;
    justify-content: space-around;
  }
`;

export const Select = styled(ReactSelect).attrs({
  styles: {
    menuList: provided => ({
      ...provided,
      maxHeight: 200,
    }),
  },
})`
  width: 100%;
  border: 0;
  color: ${props => props.theme.colors.darker};
  [class*="-control"] {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 4px;
    ${props => props.invalid
      && css`
        border-color: ${props.theme.colors.danger2};
      `}
    outline: none;
  }
`;

export const TypeButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background: transparent;
  border: 2px solid ${props => (props.active ? props.theme.colors.primary : props.theme.colors.dark)};
  border-radius: 4px;
  width: 80px;
  height: 80px;
  outline: none;
  > svg.icon-type {
    font-size: 30px;
    color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.dark)};
  }
  > svg.icon-type-check {
    font-size: 15px;
    color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.dark)};
    position: absolute;
    top: 3px;
    right: 3px;
  }
  > span {
    font-size: 18px;
    color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.dark)};
  }
`;
