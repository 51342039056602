/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable no-confusing-arrow */
/* eslint-disable quotes */
import styled from "styled-components";
import { InputStyled } from "~/Components/Styled";


export const Container = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Input = styled(InputStyled)`
  height: 36px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  flex: 1;
  border: solid 1px ${props => props.theme.colors.light};
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  margin-right: 0;
  &::placeholder {
    color: ${props => props.theme.colors.light};
  }
`;

export const ColorBox = styled.div`
  height: 36px;
  width: 36px;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-color: ${props => props.theme.colors.light};
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 0;
  background-color: ${props =>
    props.color ? `${props.color}` : "transparent"};
`;

export const Popover = styled.div`
  position: absolute;
  z-index: 2;
`;
export const Cover = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
