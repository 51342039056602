import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';
import { FaUsers, FaListAlt, FaPaperPlane } from 'react-icons/fa';
import moment from 'moment';
import { json2excel } from 'js2excel';

import Button from '~/Components/Button';
import CardItem from './Components/CardItem';
import ChartBoxItem from './Components/ChartBoxItem';

import { Creators as DashboardActions } from '~/redux/ducks/dashboard';

import {
  Container, Row, CardsBox, ChartsBox, Select, BaseEmptyData, InfoEmptyData,
} from './styles';

class Dashboard extends Component {
  state= {
    filter: {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('M')),
    },
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { dashboardRequest } = this.props;
    const { filter } = this.state;
    dashboardRequest(filter);
  };

  renderEmptyData = () => (
    <BaseEmptyData>
      <InfoEmptyData>Não existem informações para esse período</InfoEmptyData>
    </BaseEmptyData>
  )

  renderNewUsersPerDay = () => {
    const { dashboardResult: { newUsersPerDay } } = this.props;
    if (newUsersPerDay && newUsersPerDay.categories.length > 0) {
      const lineChartData = {
        series: [{
          name: 'Usuários',
          data: newUsersPerDay.data,
        }],
        options: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 3,
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: newUsersPerDay.categories,
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: val => val.toLocaleString('pt-BR'),
            },
          },
        },
      };

      return (
        <Chart
          options={lineChartData.options}
          series={lineChartData.series}
          type="line"
          height={350}
          className="chart-component"
        />
      );
    }
    return this.renderEmptyData();
  };

  renderTotalRegisteredUsers = () => {
    const { dashboardResult: { usersRegisteredPerDay } } = this.props;
    if (usersRegisteredPerDay && usersRegisteredPerDay.categories.length > 0) {
      const lineChartData = {
        series: [{
          name: 'Usuários',
          data: usersRegisteredPerDay.data,
        }],
        options: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 3,
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: usersRegisteredPerDay.categories,
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: val => val.toLocaleString('pt-BR'),
            },
          },
        },
      };

      return (
        <Chart
          options={lineChartData.options}
          series={lineChartData.series}
          type="line"
          height={350}
          className="chart-component"
        />
      );
    }
    return this.renderEmptyData();
  };

  renderTotalCampaignsCreatedXExecutedPerDay = () => {
    const { dashboardResult: { campaignCreatedExecutedPerDay } } = this.props;

    if (campaignCreatedExecutedPerDay && campaignCreatedExecutedPerDay.categories.length > 0) {
      const lineChartData = {
        series: [
          {
            name: 'Campanhas criadas',
            data: campaignCreatedExecutedPerDay.valuesCreated,
          }, {
            name: 'Campanhas executadas por dia',
            data: campaignCreatedExecutedPerDay.valuesExecuted,
          },
        ],
        options: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            // text: 'Product Trends by Month',
            // align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: campaignCreatedExecutedPerDay.categories,
          },
          fill: {
            colors: ['#4285F4', '#EB4335'],
          },
          legend: {
            markers: {
              fillColors: ['#4285F4', '#EB4335'],
            },
            labels: {
              colors: '#929292',
              useSeriesColors: false,
            },
            fontSize: '16px',
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: val => val.toLocaleString('pt-BR'),
            },
          },
        },
      };

      return (
        <Chart
          options={lineChartData.options}
          series={lineChartData.series}
          type="bar"
          height={350}
          className="chart-component"
        />
      );
    }
    return this.renderEmptyData();
  };

  renderTotalContractedMessagesPerDay = () => {
    const { dashboardResult: { messagesHiredPerDay } } = this.props;

    if (messagesHiredPerDay && messagesHiredPerDay.categories.length > 0) {
      const chartData = {
        series: [
          {
            name: 'Mensagens contratadas por dia',
            data: messagesHiredPerDay.data,
          },
        ],
        options: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            // text: 'Product Trends by Month',
            // align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: messagesHiredPerDay.categories,
          },
          fill: {
            colors: ['#4285F4'],
          },
          legend: {
            labels: {
              // colors: undefined,
              useSeriesColors: true,
            },
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: val => val.toLocaleString('pt-BR'),
            },
          },
        },
      };

      return (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
          className="chart-component"
        />
      );
    }
    return this.renderEmptyData();
  };

  renderReimpactDayAbsolute = () => {
    const { dashboardResult: { reimpactDayAbsolute } } = this.props;
    if (reimpactDayAbsolute && reimpactDayAbsolute.categories.length > 0) {
      const chartData = {
        series: [
          {
            name: 'Usuários ignorados',
            data: reimpactDayAbsolute.data,
          },
        ],
        options: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            // text: 'Product Trends by Month',
            // align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: reimpactDayAbsolute.categories,
          },
          fill: {
            colors: ['#4285F4'],
          },
          legend: {
            labels: {
              // colors: undefined,
              useSeriesColors: true,
            },
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: val => val.toLocaleString('pt-BR'),
            },
          },
        },
      };

      return (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
          className="chart-component"
        />
      );
    }
    return this.renderEmptyData();
  };

  renderReimpactDayPercentage = () => {
    const { dashboardResult: { reimpactDayPercentage } } = this.props;
    if (reimpactDayPercentage && reimpactDayPercentage.categories.length > 0) {
      const chartData = {
        series: [
          {
            name: 'Percentual de Usuários ignorados no dia',
            data: reimpactDayPercentage.data,
          },
        ],
        options: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: 'straight',
          },
          title: {
            // text: 'Product Trends by Month',
            // align: 'left',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: reimpactDayPercentage.categories,
          },
          fill: {
            colors: ['#4285F4'],
          },
          legend: {
            labels: {
              // colors: undefined,
              useSeriesColors: true,
            },
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: val => `${val.toLocaleString('pt-BR')}%`,
            },
          },
        },
      };

      return (
        <Chart
          options={chartData.options}
          series={chartData.series}
          type="bar"
          height={350}
          className="chart-component"
        />
      );
    }
    return this.renderEmptyData();
  };

  renderFilter = () => {
    const { filter } = this.state;
    const yearList = [0, 1].map(item => ({
      value: moment().year() - item,
      label: moment().year() - item,
    }));
    const monthListFull = [
      { label: 'Janeiro', value: 1 },
      { label: 'Fevereiro', value: 2 },
      { label: 'Março', value: 3 },
      { label: 'Abril', value: 4 },
      { label: 'Maio', value: 5 },
      { label: 'Junho', value: 6 },
      { label: 'Julho', value: 7 },
      { label: 'Agosto', value: 8 },
      { label: 'Setembro', value: 9 },
      { label: 'Outubro', value: 10 },
      { label: 'Novembro', value: 11 },
      { label: 'Dezembro', value: 12 },
    ];
    let monthList = [...monthListFull];
    if (parseInt(moment().year()) === filter.year) {
      const nowMonth = parseInt(moment().month());
      monthList = monthListFull.filter(i => i.value <= (nowMonth + 1));
    }
    return (
      <Row>
        <div className="box-search">
          <Select
            placeholder="Ano"
            value={yearList.find(item => item.value === filter.year)}
            onChange={(item) => {
              this.setState({
                filter: {
                  ...filter,
                  year: item.value,
                },
              });
            }}
            options={yearList}
          />
          <Select
            placeholder="Mês"
            value={monthList.find(item => item.value === filter.month)}
            onChange={(item) => {
              this.setState({
                filter: {
                  ...filter,
                  month: item.value,
                },
              });
            }}
            options={monthList}
          />
          <Button
            onClick={() => {
              this.loadData();
            }}
            title="Buscar"
            style={{
              height: 35,
              margin: 0,
            }}
          />
        </div>
      </Row>
    );
  };

  renderHeader = () => (
    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h1>Utilização da plataforma</h1>
      {this.renderFilter()}
    </Row>
  );

  handleExportExcel = (data, name = 'download-excel') => {
    json2excel({
      data,
      name,
    });
  };

  renderContent = () => {
    const {
      dashboardResult: {
        totalUsers,
        totalCampaign,
        totalDelivered,
        campaignCreatedExecutedPerDay,
        messagesHiredPerDay,
        reimpactDayAbsolute,
        reimpactDayPercentage,
        newUsersPerDay,
        usersRegisteredPerDay,
      },
      user,
    } = this.props;
    const { contract: { tenant: { extra: { dashboardSettings } } } } = user;
    return (
      <>
        <CardsBox>
          {dashboardSettings.totalUsers.enabled && (
            <CardItem title={dashboardSettings.totalUsers.title} icon={FaUsers} value={(totalUsers || 0).toLocaleString('pt-BR')} />
          )}
          {dashboardSettings.totalCampaign.enabled && (
            <CardItem title={dashboardSettings.totalCampaign.title} icon={FaListAlt} value={(totalCampaign || 0).toLocaleString('pt-BR')} />
          )}
          {dashboardSettings.totalDelivered.enabled && (
            <CardItem title={dashboardSettings.totalDelivered.title} icon={FaPaperPlane} value={(totalDelivered || 0).toLocaleString('pt-BR')} />
          )}
        </CardsBox>
        <ChartsBox>
          {dashboardSettings.newUsersPerDay.enabled && (
            <ChartBoxItem
              title={dashboardSettings.newUsersPerDay.title}
              onExport={
                newUsersPerDay
                && newUsersPerDay.categories.length > 0
                  ? () => {
                    const data = newUsersPerDay.categories.map((item, index) => (
                      {
                        Dia: item,
                        'Novos usuários por dia': newUsersPerDay.data[index] || 0,
                      }
                    ));
                    this.handleExportExcel(data, 'Novos usuários por dia');
                  }
                  : null
              }
            >
              {this.renderNewUsersPerDay()}
            </ChartBoxItem>
          )}
          {dashboardSettings.usersRegisteredPerDay.enabled && (
            <ChartBoxItem
              title={dashboardSettings.usersRegisteredPerDay.title}
              onExport={
                usersRegisteredPerDay
                && usersRegisteredPerDay.categories.length > 0
                  ? () => {
                    const data = usersRegisteredPerDay.categories.map((item, index) => (
                      {
                        Dia: item,
                        'Total de usuários cadastrados': usersRegisteredPerDay.data[index] || 0,
                      }
                    ));
                    this.handleExportExcel(data, 'Total de usuários cadastrados');
                  }
                  : null
              }
            >
              {this.renderTotalRegisteredUsers()}
            </ChartBoxItem>
          )}
        </ChartsBox>
        <ChartsBox>
          {dashboardSettings.campaignCreatedExecutedPerDay.enabled && (
            <ChartBoxItem
              title={dashboardSettings.campaignCreatedExecutedPerDay.title}
              onExport={
                campaignCreatedExecutedPerDay
                && campaignCreatedExecutedPerDay.categories.length > 0
                  ? () => {
                    const data = campaignCreatedExecutedPerDay.categories.map((item, index) => (
                      {
                        Dia: item,
                        'Campanhas criadas': campaignCreatedExecutedPerDay.valuesCreated[index] || 0,
                        'Campanhas executadas por dia': campaignCreatedExecutedPerDay.valuesExecuted[index] || 0,
                      }
                    ));
                    this.handleExportExcel(data, 'Campanhas criadas x executadas');
                  }
                  : null
              }
            >
              {this.renderTotalCampaignsCreatedXExecutedPerDay()}
            </ChartBoxItem>
          )}
          {dashboardSettings.messagesHiredPerDay.enabled && (
            <ChartBoxItem
              title={dashboardSettings.messagesHiredPerDay.title}
              onExport={
                messagesHiredPerDay
                && messagesHiredPerDay.categories.length > 0
                  ? () => {
                    const data = messagesHiredPerDay.categories.map((item, index) => (
                      {
                        Dia: item,
                        'Mensagens contratadas por dia': messagesHiredPerDay.data[index] || 0,
                      }
                    ));
                    this.handleExportExcel(data, 'Mensagens contratadas por dia');
                  }
                  : null
              }
            >
              {this.renderTotalContractedMessagesPerDay()}
            </ChartBoxItem>
          )}
        </ChartsBox>
        {dashboardSettings.reimpactDay.enabled && (
          <ChartsBox>
            <ChartBoxItem
              title={dashboardSettings.reimpactDay.absolute.title}
              onExport={
                reimpactDayAbsolute
                && reimpactDayAbsolute.categories.length > 0
                  ? () => {
                    const data = reimpactDayAbsolute.categories.map((item, index) => (
                      {
                        Dia: item,
                        'Usuários ignorados por dia': reimpactDayAbsolute.data[index] || 0,
                      }
                    ));
                    this.handleExportExcel(data, 'Usuários Ignorados por dia');
                  }
                  : null
              }
            >
              {this.renderReimpactDayAbsolute()}
            </ChartBoxItem>
            <ChartBoxItem
              title={dashboardSettings.reimpactDay.percentage.title}
              onExport={
                reimpactDayPercentage
                && reimpactDayPercentage.categories.length > 0
                  ? () => {
                    const data = reimpactDayPercentage.categories.map((item, index) => (
                      {
                        Dia: item,
                        'Percentual de usuários ignorados por dia': `${reimpactDayPercentage.data[index] || 0}%`,
                      }
                    ));
                    this.handleExportExcel(data, 'Per. Usuários Ignorados por dia');
                  }
                  : null
              }
            >
              {this.renderReimpactDayPercentage()}
            </ChartBoxItem>
          </ChartsBox>
        )}
      </>
    );
  };

  render() {
    const { dashboardResult } = this.props;
    return (
      <Container>
        {this.renderHeader()}
        {!!dashboardResult && this.renderContent()}
      </Container>
    );
  }
}

Dashboard.propTypes = {
  dashboardRequest: PropTypes.func.isRequired,
  dashboardResult: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ dashboard: { dashboardResult }, auth }) => ({
  user: auth.currentUser,
  dashboardResult: dashboardResult && {
    ...dashboardResult,
    newUsersPerDay: dashboardResult.newUsersPerDay ? {
      categories: dashboardResult.newUsersPerDay.map(item => moment(item.key).utc().format('DD/MM')),
      data: dashboardResult.newUsersPerDay.map(item => item.value),
    } : null,
    usersRegisteredPerDay: dashboardResult.usersRegisteredPerDay ? {
      categories: dashboardResult.usersRegisteredPerDay.map(item => moment(item.date).utc().format('DD/MM')),
      data: dashboardResult.usersRegisteredPerDay.map(item => item.value),
    } : null,
    messagesHiredPerDay: dashboardResult.messagesHiredPerDay ? {
      categories: dashboardResult.messagesHiredPerDay.map(item => moment(item.key).utc().format('DD/MM')),
      data: dashboardResult.messagesHiredPerDay.map(item => item.value),
    } : null,
    campaignCreatedExecutedPerDay: dashboardResult.campaignCreatedExecutedPerDay ? {
      categories: dashboardResult.campaignCreatedExecutedPerDay.map(item => moment(item.key).utc().format('DD/MM')),
      valuesCreated: dashboardResult.campaignCreatedExecutedPerDay.map(item => item.valueCreated),
      valuesExecuted: dashboardResult.campaignCreatedExecutedPerDay.map(item => item.valueExecuted),
    } : null,
    reimpactDayAbsolute: dashboardResult.reimpactDay && dashboardResult.reimpactDay.absolute
      ? {
        categories: dashboardResult.reimpactDay.absolute.map(item => moment(item.key).utc().format('DD/MM')),
        data: dashboardResult.reimpactDay.absolute.map(item => parseFloat(item.value)),
      }
      : null,
    reimpactDayPercentage: dashboardResult.reimpactDay && dashboardResult.reimpactDay.percentage
      ? {
        categories: dashboardResult.reimpactDay.percentage.map(item => moment(item.key).utc().format('DD/MM')),
        data: dashboardResult.reimpactDay.percentage.map(item => parseFloat(item.value)),
      }
      : null,
  },
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    dashboardRequest: DashboardActions.dashboardRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Dashboard);
