/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Creators */
export const { Types, Creators } = createActions({
  loadChannelsList: null,
  loadChannelsListSuccess: ["channelsList"]
});

export const ChannelsTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  channelsList: []
});

/* Reducers */
const loadChannelsListSuccess = (state, { channelsList }) => ({
  ...state,
  channelsList
});

export const reducer = createReducer(INITIAL_STATE, {
  [ChannelsTypes.LOAD_CHANNELS_LIST_SUCCESS]: loadChannelsListSuccess
});
