import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function InputCreativeAdvanced({
  valueSuffix, valuePrefix, contentVersion,
  disabled, invalid, style, maxLength, placeholder, value, onChange, type,
}) {
  return (
    <Container style={style} disabled={disabled} invalid={invalid}>
      {contentVersion > 1 && valuePrefix && (<span className="value-prefix">{valuePrefix}</span>)}
      <input
        type={type}
        disabled={disabled}
        maxLength={maxLength}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        invalid={invalid}
      />
      {contentVersion > 1 && valueSuffix && (<span>{valueSuffix}</span>)}
    </Container>
  );
}

InputCreativeAdvanced.defaultProps = {
  disabled: false,
  invalid: false,
  style: null,
  maxLength: null,
  placeholder: null,
  value: null,
  onChange: null,
  type: 'text',
  valueSuffix: null,
  valuePrefix: null,
  contentVersion: 1,
};

InputCreativeAdvanced.propTypes = {
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  style: PropTypes.shape(),
  maxLength: PropTypes.number,
  contentVersion: PropTypes.number,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  valueSuffix: PropTypes.string,
  valuePrefix: PropTypes.string,
};

export default InputCreativeAdvanced;
