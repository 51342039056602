/* eslint-disable no-console */
/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactDOMServer from 'react-dom/server';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';

import { Map, TileLayer, Marker } from 'react-leaflet';
import L from 'leaflet';
import './css.css';
import 'leaflet/dist/leaflet.css';

import iconRetinaUrl from '~/Assets/Images/pin-map.png';
import SVGIconComponent from './Components/SVGIconComponent';

import { Creators as AudienceActions } from '~/redux/ducks/audience';
import { Creators as PointsManagementActions } from '~/redux/ducks/pointsManagement';

import Button from '~/Components/Button';
import Breadcrumb from '~/Components/Breadcrumb';
import AudienceUtil from '~/Utils/AudienceUtil';
import AudienceChart from './Components/AudienceChart';
import FormHeader from './Components/FormHeader';
import ActualData from './Components/ActualData';
import Categories from './Components/Categories';
import Segmentations from './Components/Segmentations';
import ActiveFilter from './Components/ActiveFilter';
import Footer from './Components/Footer';
import ModalCloneAudience from './Components/ModalCloneAudience';
import MapGeofence from './Components/MapGeofence';
import { tileTolonlat } from '~/Utils';

import {
  Container,
  Row,
  // Profile
  ProfileContent,
  // Geofence
  GeofenceContent,
  // Insight
  InsightContent,
  InsightBlock,
  // Tab
  ContainerTabs,
} from './styles';

class AudiencesForm extends Component {
  state = {
    hasError: false,
    changedActivesFilter: false,
    tabSelected: 1,
  };

  leafletMapRef = null;

  intervalToLoadCriteria = null;

  isUpdateMode = false;

  isImpactRegisterMode = false;

  componentDidMount() {
    const {
      match: { params },
      audienceSetupRequest,
      audienceSetNew,
      audienceSetUpdate,
      audienceGetData,
      audienceCategoriesRequest,
      audienceZonesOnCreateRequest,
      audienceZonesOnUpdateRequest,
      updateGeofenceData,
      audienceCategorySetActiveFilter,
      // pontos
      pointsManagementGeoPointsRequestSuccess,
      pointsManagementGeoPointsRequest,
      // icone
      pointsManagementGeoPointsIconRequest,
      pointsManagementGeoPointsIconRequestSuccess,
    } = this.props;
    this.isUpdateMode = !!params.audienceId;
    this.isImpactRegisterMode = !!params.campaignId;


    audienceCategorySetActiveFilter({
      include: [],
      exclude: [],
    });
    updateGeofenceData([]);
    audienceSetNew(null);
    audienceSetUpdate(null);
    audienceSetupRequest();
    audienceCategoriesRequest();
    // pontos
    pointsManagementGeoPointsRequestSuccess(null);
    pointsManagementGeoPointsRequest();
    // icone
    pointsManagementGeoPointsIconRequestSuccess(null);
    pointsManagementGeoPointsIconRequest();

    if (this.isUpdateMode) {
      audienceGetData(params.audienceId);
      audienceZonesOnUpdateRequest(params.audienceId);
      this.setState({
        changedActivesFilter: true,
      });
    } else {
      audienceZonesOnCreateRequest();
      audienceSetNew({
        name: '',
        description: '',
        shared: false,
        contract: null,
        criteria: [],
        preferences: {
          grafico01: { category: { key: 'faixa_etaria', label: 'Faixa Etária' }, type: 'column' },
          grafico02: { category: { key: 'sexo', label: 'Gênero' }, type: 'pie' },
          grafico03: { category: { key: 'plano', label: 'Plano' }, type: 'column' },
          grafico04: { category: { key: 'fabricante_aparelho', label: 'Fabricante do Device' }, type: 'bar' },
        },
      });
    }

    if (this.isImpactRegisterMode) {
      audienceSetNew({
        campaignId: params.campaignId,
      });
    }

    this.setState({
      hasError: false,
      tabSelected: 1,
    });
  }

  componentWillUnmount() {
    const {
      audienceCategoryDataCriteriaCountSuccess,
      audienceCategoryDataCriteriaCountByZoneSuccess,
      audienceCategoryDataCriteriaAnalyzeSuccess,
      audienceCategoryDataCriteriaAnalyzeByZoneSuccess,
      audienceCategorySetActiveFilter,
      audienceSetZoneSelected,
      audienceZonesRequestSuccess,
    } = this.props;
    audienceCategoryDataCriteriaCountSuccess('0');
    audienceCategoryDataCriteriaCountByZoneSuccess('0');
    audienceCategoryDataCriteriaAnalyzeSuccess(null);
    audienceCategoryDataCriteriaAnalyzeByZoneSuccess(null);
    audienceSetZoneSelected(null);
    audienceZonesRequestSuccess([]);
    audienceCategorySetActiveFilter({
      include: [],
      exclude: [],
    });
  }

  handleLoadCriteria = () => {
    this.setState({
      changedActivesFilter: true,
    });
    const {
      audienceCategoryDataCriteriaCountSuccess,
      audienceCategoryDataCriteriaCountByZoneSuccess,
    } = this.props;
    audienceCategoryDataCriteriaCountSuccess('Calculando...');
    audienceCategoryDataCriteriaCountByZoneSuccess('Calculando...');
    if (this.intervalToLoadCriteria) {
      clearTimeout(this.intervalToLoadCriteria);
      this.intervalToLoadCriteria = null;
    }
    this.intervalToLoadCriteria = setTimeout(
      () => {
        const {
          audienceCategoryDataCriteriaCount,
          audienceCategoryDataCriteriaCountByZone,
          categoryFilterActive,
          zoneSelected,
        } = this.props;
        const criteria = AudienceUtil.dataFilterToCriteria(categoryFilterActive);
        audienceCategoryDataCriteriaCount(criteria);
        if (zoneSelected) {
          audienceCategoryDataCriteriaCountByZone(zoneSelected, criteria);
        }
      },
      3000,
    );
  };

  handleSetData = (data) => {
    const {
      audienceSetNew,
      audienceSetUpdate,
      audienceNew,
      audienceUpdate,
    } = this.props;

    if (this.isUpdateMode) {
      audienceSetUpdate({
        ...audienceUpdate,
        ...data,
      });
    } else {
      audienceSetNew({
        ...audienceNew,
        ...data,
      });
    }
  };

  handleFormIsValid = (data) => {
    const {
      user: { hasPermissionGlobalAudience },
    } = this.props;

    if (
      !!data
      && data.name
      && !!data.description
      && (data.contract || (!data.contract && !hasPermissionGlobalAudience))
    ) {
      return true;
    }
    this.setState({
      hasError: true,
    });
    return false;
  };

  handleSaveData = () => {
    const {
      audienceNew,
      audienceUpdate,
      audienceSaveData,
      audienceUpdateData,
      categoryFilterActive,
    } = this.props;
    const criteria = AudienceUtil.dataFilterToCriteria(categoryFilterActive);
    this.handleSetData({ criteria });

    if (this.isUpdateMode) {
      if (this.handleFormIsValid(audienceUpdate)) {
        audienceUpdateData();
      }
    } else if (this.handleFormIsValid(audienceNew)) {
      audienceSaveData();
    }
  };

  handleCloneAudience = () => {
    const { audienceSetClone, audienceUpdate } = this.props;
    audienceSetClone({
      uuid: audienceUpdate.uuid,
      name: `Cópia de ${audienceUpdate.name}`,
    });
  }

  renderHeader = () => {
    const { hasError } = this.state;
    return (
      <>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: 10,
          }}
        >
          <div>
            <Breadcrumb
              items={[
                {
                  route: '/',
                  name: 'Home',
                },
                {
                  route: '/audience',
                  name: 'Audiência',
                },
                {
                  name: 'Cadastro',
                },
              ]}
            />
            <h1>{this.isUpdateMode ? 'EDITAR AUDIÊNCIA' : 'NOVA AUDIÊNCIA'}</h1>
          </div>
          <div className="header-button-box">
            {this.isUpdateMode && <ActualData />}
            {this.isUpdateMode && (
              <Button
                disabled={false}
                bordered
                onClick={() => {
                  this.handleCloneAudience();
                }}
                title="Clonar Audiência"
              />
            )}
            <Button
              disabled={false}
              onClick={() => {
                this.handleSaveData();
              }}
              title="Salvar"
            />
          </div>
        </Row>
        <Row
          style={{
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            marginBottom: 10,
          }}
        >
          <FormHeader
            hasError={hasError}
            handleGetData={this.handleGetData}
            isUpdateMode={this.isUpdateMode}
          />
        </Row>
      </>
    );
  };

  handleGetData = (field) => {
    const {
      audienceNew,
      audienceUpdate,
    } = this.props;

    if (this.isUpdateMode) {
      return audienceUpdate ? audienceUpdate[field] || null : null;
    }
    return audienceNew ? audienceNew[field] || null : null;
  };

  renderGeofenceContent = () => {
    const { tabSelected } = this.state;
    return (
      <GeofenceContent>
        {tabSelected === 2 && (
          <MapGeofence onChange={() => { this.handleLoadCriteria(); }} />
        )}
      </GeofenceContent>
    );
  };

  renderProfileContent = () => {
    const { categoryData } = this.props;
    return (
      <ProfileContent>
        <Categories />
        {!!categoryData && <Segmentations onChange={() => { this.handleLoadCriteria(); }} />}
        <ActiveFilter onChange={() => { this.handleLoadCriteria(); }} />
      </ProfileContent>
    );
  };

  renderGraphic01 = () => {
    const graphicPreferences = this.handleGetData('preferences');
    if (!graphicPreferences) return null;

    const { grafico01 } = graphicPreferences;
    const {
      analyzeLoading,
      analyzeData,
      analyzeByZoneData,
      zoneSelected,
      analyzeByZoneLoading,
    } = this.props;
    const { tabSelected } = this.state;
    const dataLoaded = zoneSelected ? analyzeByZoneData : analyzeData;
    const dataLoading = zoneSelected ? analyzeByZoneLoading : analyzeLoading;

    // eslint-disable-next-line max-len
    const chartCategories = dataLoaded && dataLoaded[grafico01.category.key] && dataLoaded[grafico01.category.key].buckets.length > 0
      ? dataLoaded[grafico01.category.key].buckets.map(item => item.key)
      : [];

    // eslint-disable-next-line max-len
    const chartData = dataLoaded && dataLoaded[grafico01.category.key] && dataLoaded[grafico01.category.key].buckets.length > 0
      ? dataLoaded[grafico01.category.key].buckets.map(item => item.doc_count)
      : [];

    return (
      <AudienceChart
        title={grafico01.category.label}
        type={grafico01.type}
        data={{
          labels: chartCategories,
          data: chartData,
        }}
        loading={dataLoading}
        hasData={!!dataLoaded && chartCategories.length > 0 && chartData.length > 0}
        maxHeight={350}
        preferences={grafico01}
        onSavePreferences={(preferences) => {
          this.handleSetData({
            preferences: {
              ...graphicPreferences,
              grafico01: preferences,
            },
          });
          setTimeout(() => {
            if (tabSelected === 3 && zoneSelected) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyzeByZone(zoneSelected);
            }
            if (tabSelected === 3 && !zoneSelected) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyze();
            }
          }, 500);
        }}
      />
    );
  };

  renderGraphic02 = () => {
    const graphicPreferences = this.handleGetData('preferences');
    if (!graphicPreferences) return null;

    const { grafico02 } = graphicPreferences;
    const {
      analyzeLoading,
      analyzeData,
      analyzeByZoneData,
      zoneSelected,
      analyzeByZoneLoading,
    } = this.props;
    const { tabSelected } = this.state;
    const dataLoaded = zoneSelected ? analyzeByZoneData : analyzeData;
    const dataLoading = zoneSelected ? analyzeByZoneLoading : analyzeLoading;

    // eslint-disable-next-line max-len
    const chartCategories = dataLoaded && dataLoaded[grafico02.category.key] && dataLoaded[grafico02.category.key].buckets.length > 0
      ? dataLoaded[grafico02.category.key].buckets.map(item => item.key)
      : [];

    // eslint-disable-next-line max-len
    const chartData = dataLoaded && dataLoaded[grafico02.category.key] && dataLoaded[grafico02.category.key].buckets.length > 0
      ? dataLoaded[grafico02.category.key].buckets.map(item => item.doc_count)
      : [];

    return (
      <AudienceChart
        title={grafico02.category.label}
        type={grafico02.type}
        data={{
          labels: chartCategories,
          data: chartData,
        }}
        loading={dataLoading}
        hasData={!!dataLoaded && chartCategories.length > 0 && chartData.length > 0}
        maxHeight={350}
        preferences={grafico02}
        onSavePreferences={(preferences) => {
          this.handleSetData({
            preferences: {
              ...graphicPreferences,
              grafico02: preferences,
            },
          });
          setTimeout(() => {
            if (tabSelected === 3 && zoneSelected) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyzeByZone(zoneSelected);
            }
            if (tabSelected === 3 && !zoneSelected) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyze();
            }
          }, 500);
        }}
      />
    );
  };

  renderGraphic03 = () => {
    const graphicPreferences = this.handleGetData('preferences');
    if (!graphicPreferences) return null;

    const { grafico03 } = graphicPreferences;
    const {
      analyzeLoading,
      analyzeData,
      analyzeByZoneData,
      zoneSelected,
      analyzeByZoneLoading,
    } = this.props;
    const { tabSelected } = this.state;
    const dataLoaded = zoneSelected ? analyzeByZoneData : analyzeData;
    const dataLoading = zoneSelected ? analyzeByZoneLoading : analyzeLoading;

    // eslint-disable-next-line max-len
    const chartCategories = dataLoaded && dataLoaded[grafico03.category.key] && dataLoaded[grafico03.category.key].buckets.length > 0
      ? dataLoaded[grafico03.category.key].buckets.map(item => item.key)
      : [];

    // eslint-disable-next-line max-len
    const chartData = dataLoaded && dataLoaded[grafico03.category.key] && dataLoaded[grafico03.category.key].buckets.length > 0
      ? dataLoaded[grafico03.category.key].buckets.map(item => item.doc_count)
      : [];

    return (
      <AudienceChart
        title={grafico03.category.label}
        type={grafico03.type}
        data={{
          labels: chartCategories,
          data: chartData,
        }}
        loading={dataLoading}
        hasData={!!dataLoaded && chartCategories.length > 0 && chartData.length > 0}
        maxHeight={350}
        preferences={grafico03}
        onSavePreferences={(preferences) => {
          this.handleSetData({
            preferences: {
              ...graphicPreferences,
              grafico03: preferences,
            },
          });
          setTimeout(() => {
            if (tabSelected === 3 && zoneSelected) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyzeByZone(zoneSelected);
            }
            if (tabSelected === 3 && !zoneSelected) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyze();
            }
          }, 500);
        }}
      />
    );
  };

  renderGraphic04 = () => {
    const graphicPreferences = this.handleGetData('preferences');
    if (!graphicPreferences) return null;

    const { grafico04 } = graphicPreferences;
    const {
      analyzeLoading,
      analyzeData,
      analyzeByZoneData,
      zoneSelected,
      analyzeByZoneLoading,
    } = this.props;
    const { tabSelected } = this.state;
    const dataLoaded = zoneSelected ? analyzeByZoneData : analyzeData;
    const dataLoading = zoneSelected ? analyzeByZoneLoading : analyzeLoading;

    // eslint-disable-next-line max-len
    const chartCategories = dataLoaded && dataLoaded[grafico04.category.key] && dataLoaded[grafico04.category.key].buckets.length > 0
      ? dataLoaded[grafico04.category.key].buckets.map(item => item.key)
      : [];

    // eslint-disable-next-line max-len
    const chartData = dataLoaded && dataLoaded[grafico04.category.key] && dataLoaded[grafico04.category.key].buckets.length > 0
      ? dataLoaded[grafico04.category.key].buckets.map(item => item.doc_count)
      : [];

    return (
      <AudienceChart
        title={grafico04.category.label}
        type={grafico04.type}
        data={{
          labels: chartCategories,
          data: chartData,
        }}
        loading={dataLoading}
        hasData={!!dataLoaded && chartCategories.length > 0 && chartData.length > 0}
        maxHeight={350}
        preferences={grafico04}
        onSavePreferences={(preferences) => {
          this.handleSetData({
            preferences: {
              ...graphicPreferences,
              grafico04: preferences,
            },
          });
          setTimeout(() => {
            if (tabSelected === 3 && zoneSelected) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyzeByZone(zoneSelected);
            }
            if (tabSelected === 3 && !zoneSelected) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyze();
            }
          }, 500);
        }}
      />
    );
  };

  handleLoadMarkers = () => {
    const {
      audienceGetMapMarkers,
      audienceGetMapMarkersByZone,
      zoneSelected,
      categoryFilterActive,
    } = this.props;
    const criteria = AudienceUtil.dataFilterToCriteria(categoryFilterActive);
    // data map
    const map = this.leafletMapRef.leafletElement;
    const southEast = map.getBounds().getSouthEast();
    const northWest = map.getBounds().getNorthWest();
    const zoom = map.getZoom();
    const markersEvent = {
      zoom,
      top_left: {
        lat: northWest.lat,
        long: northWest.lng,
      },
      bottom_right: {
        lat: southEast.lat,
        long: southEast.lng,
      },
    };

    if (zoneSelected) {
      audienceGetMapMarkersByZone(
        zoneSelected,
        criteria,
        markersEvent,
      );
    } else {
      audienceGetMapMarkers(
        criteria,
        markersEvent,
      );
    }
  };

  getMarkers = () => {
    const {
      zoneSelected,
      mapMarkers,
      mapMarkersByZone,
    } = this.props;
    const mapBuckets = zoneSelected ? mapMarkersByZone : mapMarkers;
    if (this.leafletMapRef && mapBuckets) {
      const markersMap = mapBuckets.nome.buckets.map((item) => {
        const [z, x, y] = item.key.split('/');
        return {
          position: tileTolonlat(z, x, y),
          total: item.doc_count,
        };
      });
      return markersMap;
    }
    return [];
  };

  renderMap = () => {
    const position = [-22.905503, -43.262965];
    const tileOption = {
      attribution: 'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>',
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    };

    // const myIcon = L.icon({
    //   iconUrl: iconRetinaUrl,
    //   iconSize: [50, 82],
    //   // iconAnchor: [32, 64],
    //   // popupAnchor: null,
    //   // shadowUrl: null,
    //   // shadowSize: null,
    //   // shadowAnchor: null,
    // });

    const getCustomIcon = (itemMarker) => {
      if (itemMarker.total === 1) {
        return L.icon({
          iconUrl: iconRetinaUrl,
          iconSize: [50, 50],
        });
      }
      const formatNumber = (number) => {
        let formattedNumber = number;
        if (number > 1000) {
          formattedNumber = `${(number / 1000).toFixed(0)}K`;
        }
        if (number > 1000000) {
          formattedNumber = `${(number / 1000000).toFixed(0)}M`;
        }
        return formattedNumber;
      };
      return L.divIcon({
        className: 'map-custom-icon',
        html: ReactDOMServer.renderToString(<SVGIconComponent total={formatNumber(itemMarker.total)} />),
      });
    };

    return (
      <InsightBlock>
        <Map
          ref={(ref) => {
            this.leafletMapRef = ref;
          }}
          center={position}
          zoom={8}
          maxZoom={18}
          minZoom={5}
          whenReady={() => {
            this.handleLoadMarkers();
          }}
          onzoomend={() => {
            this.handleLoadMarkers();
          }}
          ondragend={() => {
            this.handleLoadMarkers();
          }}
          style={{
            height: '100%',
            width: '100%',
          }}
        >
          <TileLayer
            attribution={tileOption.attribution}
            url={tileOption.url}
          />
          {this.getMarkers().map(item => (
            <Marker icon={getCustomIcon(item)} position={item.position} />
          ))}
        </Map>
      </InsightBlock>
    );
  };

  renderInsightContent = () => (
    <InsightContent>
      <div className="insight-column1">
        {this.renderMap()}
      </div>
      <div className="insight-column2">
        <div className="insight-charts">
          {this.renderGraphic01()}
          {this.renderGraphic02()}
          {this.renderGraphic03()}
          {this.renderGraphic04()}
        </div>
      </div>
    </InsightContent>
  );

  handleLoadAudienceCategoryDataCriteriaAnalyzeByZone = (zoneSelected) => {
    const {
      audienceCategoryDataCriteriaAnalyzeByZone,
      categoryFilterActive,
    } = this.props;
    const {
      grafico01,
      grafico02,
      grafico03,
      grafico04,
    } = this.handleGetData('preferences');
    const criteria = AudienceUtil.dataFilterToCriteria(categoryFilterActive);
    audienceCategoryDataCriteriaAnalyzeByZone(
      zoneSelected,
      {
        analyze_fields: [
          grafico01.category.key,
          grafico02.category.key,
          grafico03.category.key,
          grafico04.category.key,
        ],
      },
      criteria,
    );
  };

  handleLoadAudienceCategoryDataCriteriaAnalyze = () => {
    const {
      audienceCategoryDataCriteriaAnalyze,
      categoryFilterActive,
    } = this.props;
    const {
      grafico01,
      grafico02,
      grafico03,
      grafico04,
    } = this.handleGetData('preferences');
    const criteria = AudienceUtil.dataFilterToCriteria(categoryFilterActive);
    audienceCategoryDataCriteriaAnalyze(
      {
        analyze_fields: [
          grafico01.category.key,
          grafico02.category.key,
          grafico03.category.key,
          grafico04.category.key,
        ],
      },
      criteria,
    );
  };

  handleChangePerfilAudienciaTab = () => {
    const { zoneSelected } = this.props;
    if (zoneSelected) {
      this.handleLoadAudienceCategoryDataCriteriaAnalyzeByZone(zoneSelected);
    } else {
      this.handleLoadAudienceCategoryDataCriteriaAnalyze();
    }
    this.setState({
      changedActivesFilter: false,
    });
  };

  renderTabs = () => {
    const { tabSelected } = this.state;
    const { hideTabGeofenceAudienceInternal } = this.props;
    return (
      <ContainerTabs>
        <Tabs>
          <TabList>
            <Tab
              onClick={() => {
                this.setState({ tabSelected: 1 });
              }}
            >
              Segmentação
            </Tab>
            {!hideTabGeofenceAudienceInternal && (
              <Tab
                onClick={() => {
                  this.setState({ tabSelected: 2 });
                }}
              >
                Geofence
              </Tab>
            )}
            <Tab
              onClick={() => {
                const { changedActivesFilter } = this.state;
                this.setState({ tabSelected: 3 });
                if (changedActivesFilter) {
                  this.handleChangePerfilAudienciaTab();
                }
              }}
            >
              Perfil da audiência
            </Tab>
          </TabList>
          <TabPanel>
            {this.renderProfileContent()}
          </TabPanel>
          {!hideTabGeofenceAudienceInternal && (
            <TabPanel>
              {this.renderGeofenceContent()}
            </TabPanel>
          )}
          <TabPanel>
            {this.renderInsightContent()}
          </TabPanel>
        </Tabs>
        <Footer
          onChange={(zone) => {
            this.handleLoadCriteria();
            if (tabSelected === 3 && zone.value) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyzeByZone(zone.value);
            }
            if (tabSelected === 3 && !zone.value) {
              this.handleLoadAudienceCategoryDataCriteriaAnalyze();
            }
            if (tabSelected === 3) {
              setTimeout(() => {
                this.handleLoadMarkers();
              }, 500);
            }
          }}
        />
      </ContainerTabs>
    );
  };

  render() {
    const { audienceUpdate } = this.props;

    return (!this.isUpdateMode || (this.isUpdateMode && audienceUpdate)) && (
      <Container>
        {this.renderHeader()}
        {this.renderTabs()}
        <ModalCloneAudience />
      </Container>
    );
  }
}

AudiencesForm.defaultProps = {};

AudiencesForm.propTypes = {
  match: PropTypes.shape().isRequired,
  audienceNew: PropTypes.shape().isRequired,
  audienceUpdate: PropTypes.shape().isRequired,
  audienceSetupRequest: PropTypes.func.isRequired,
  audienceSetNew: PropTypes.func.isRequired,
  audienceSetUpdate: PropTypes.func.isRequired,
  audienceSaveData: PropTypes.func.isRequired,
  audienceUpdateData: PropTypes.func.isRequired,
  audienceGetData: PropTypes.func.isRequired,
  audienceCategoriesRequest: PropTypes.func.isRequired,
  categoryData: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  audienceCategorySetActiveFilter: PropTypes.func.isRequired,
  audienceCategoryDataCriteriaCount: PropTypes.func.isRequired,
  audienceCategoryDataCriteriaCountSuccess: PropTypes.func.isRequired,
  audienceCategoryDataCriteriaCountByZone: PropTypes.func.isRequired,
  audienceCategoryDataCriteriaCountByZoneSuccess: PropTypes.func.isRequired,
  audienceCategoryDataCriteriaAnalyzeSuccess: PropTypes.func.isRequired,
  audienceCategoryDataCriteriaAnalyzeByZoneSuccess: PropTypes.func.isRequired,
  audienceGetMapMarkers: PropTypes.func.isRequired,
  audienceGetMapMarkersByZone: PropTypes.func.isRequired,
  audienceCategoryDataCriteriaAnalyze: PropTypes.func.isRequired,
  audienceCategoryDataCriteriaAnalyzeByZone: PropTypes.func.isRequired,
  categoryFilterActive: PropTypes.shape().isRequired,
  analyzeData: PropTypes.shape().isRequired,
  analyzeByZoneData: PropTypes.shape().isRequired,
  analyzeLoading: PropTypes.bool.isRequired,
  analyzeByZoneLoading: PropTypes.bool.isRequired,
  mapMarkers: PropTypes.shape().isRequired,
  mapMarkersByZone: PropTypes.shape().isRequired,
  audienceZonesRequestSuccess: PropTypes.func.isRequired,
  audienceZonesOnCreateRequest: PropTypes.func.isRequired,
  audienceZonesOnUpdateRequest: PropTypes.func.isRequired,
  audienceSetZoneSelected: PropTypes.func.isRequired,
  zoneSelected: PropTypes.string.isRequired,
  audienceSetClone: PropTypes.func.isRequired,
  updateGeofenceData: PropTypes.func.isRequired,
  // pontos
  pointsManagementGeoPointsRequest: PropTypes.func.isRequired,
  pointsManagementGeoPointsRequestSuccess: PropTypes.func.isRequired,
  // icone
  pointsManagementGeoPointsIconRequest: PropTypes.func.isRequired,
  pointsManagementGeoPointsIconRequestSuccess: PropTypes.func.isRequired,

  user: PropTypes.shape().isRequired,
  hideTabGeofenceAudienceInternal: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  user: state.auth.currentUser,
  hideTabGeofenceAudienceInternal: state.auth.hideTabGeofenceAudienceInternal,
  contracts: state.audience.contracts,
  audienceNew: state.audience.audienceNew,
  audienceUpdate: state.audience.audienceUpdate,
  audiences: state.audience.audiences,
  categories: state.audience.categories,
  categoryData: state.audience.categoryData,
  categoryFilterActive: state.audience.categoryFilterActive,
  totalEstimatedAudience: state.audience.totalEstimatedAudience,
  totalEstimatedAudienceByZone: state.audience.totalEstimatedAudienceByZone,
  analyzeData: state.audience.analyzeData,
  analyzeByZoneData: state.audience.analyzeByZoneData,
  analyzeLoading: state.audience.analyzeLoading,
  analyzeByZoneLoading: state.audience.analyzeByZoneLoading,
  mapMarkers: state.audience.mapMarkers,
  mapMarkersByZone: state.audience.mapMarkersByZone,
  zoneSelected: state.audience.zoneSelected,
  audienceToClone: state.audience.audienceToClone,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    audienceSetupRequest: AudienceActions.audienceSetupRequest,
    audienceSetNew: AudienceActions.audienceSetNew,
    audienceSetUpdate: AudienceActions.audienceSetUpdate,
    audienceSaveData: AudienceActions.audienceSaveData,
    audienceUpdateData: AudienceActions.audienceUpdateData,
    audienceGetData: AudienceActions.audienceGetData,
    audienceCategoriesRequest: AudienceActions.audienceCategoriesRequest,
    audienceCategoryDataRequest: AudienceActions.audienceCategoryDataRequest,
    audienceCategorySetActiveFilter: AudienceActions.audienceCategorySetActiveFilter,
    audienceCategoryDataCriteriaCount: AudienceActions.audienceCategoryDataCriteriaCount,
    audienceCategoryDataCriteriaCountSuccess: AudienceActions.audienceCategoryDataCriteriaCountSuccess,
    audienceCategoryDataCriteriaCountByZone: AudienceActions.audienceCategoryDataCriteriaCountByZone,
    audienceCategoryDataCriteriaCountByZoneSuccess: AudienceActions.audienceCategoryDataCriteriaCountByZoneSuccess,
    audienceCategoryDataCriteriaAnalyze: AudienceActions.audienceCategoryDataCriteriaAnalyze,
    audienceCategoryDataCriteriaAnalyzeSuccess: AudienceActions.audienceCategoryDataCriteriaAnalyzeSuccess,
    audienceCategoryDataCriteriaAnalyzeByZoneSuccess: AudienceActions.audienceCategoryDataCriteriaAnalyzeByZoneSuccess,
    audienceCategoryDataCriteriaAnalyzeByZone: AudienceActions.audienceCategoryDataCriteriaAnalyzeByZone,
    audienceGetMapMarkers: AudienceActions.audienceGetMapMarkers,
    audienceGetMapMarkersByZone: AudienceActions.audienceGetMapMarkersByZone,
    audienceZonesOnCreateRequest: AudienceActions.audienceZonesOnCreateRequest,
    audienceZonesOnUpdateRequest: AudienceActions.audienceZonesOnUpdateRequest,
    audienceZonesRequestSuccess: AudienceActions.audienceZonesRequestSuccess,
    audienceSetZoneSelected: AudienceActions.audienceSetZoneSelected,
    audienceSetClone: AudienceActions.audienceSetClone,
    updateGeofenceData: AudienceActions.updateGeofenceData,
    // pontos
    pointsManagementGeoPointsRequest:
      PointsManagementActions.pointsManagementGeoPointsRequest,
    pointsManagementGeoPointsRequestSuccess:
      PointsManagementActions.pointsManagementGeoPointsRequestSuccess,
    // icone
    pointsManagementGeoPointsIconRequest:
      PointsManagementActions.pointsManagementGeoPointsIconRequest,
    pointsManagementGeoPointsIconRequestSuccess:
      PointsManagementActions.pointsManagementGeoPointsIconRequestSuccess,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudiencesForm);
