import React from 'react';
import PropTypes from 'prop-types';
import FileUploadService from '~/services/FileUploadService';

import {
  PreviewVastType,
} from './styles';

class NoCreditBannerTextPreview extends React.Component {
  state = {
    image: null,
  };

  componentDidMount() {
    const { image } = this.state;
    if (!image) this.loadImage();
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  loadImage = async () => {
    const { backgroundImage } = this.props;
    const file = await FileUploadService.getFile(backgroundImage);
    const image = await this.toBase64(file);
    this.setState({ image });
  };

  render() {
    const { image } = this.state;
    return !!image && (
      <PreviewVastType
        style={{
          zIndex: 9,
          '--img': `url(${image})`,
          background: 'linear-gradient(179.08deg, rgba(42, 68, 82, 0.2) -15.36%, rgba(20, 33, 41, 0.4) 42.57%), var(--img)',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    );
  }
}

NoCreditBannerTextPreview.propTypes = {
  backgroundImage: PropTypes.string.isRequired,
};

export default NoCreditBannerTextPreview;
