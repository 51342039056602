/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
import styled, { css } from "styled-components";
import ReactSelect from "react-select";
import {
  ContainerStyled,
  TableStyled,
  RowStyled,
  FormGroupStyled,
  InputStyled,
  TextareaStyled
} from "~/Components/Styled";
import { hex2rgba } from "~/Utils";
import Button from "~/Components/Button";

export const Container = styled(ContainerStyled)``;

export const Table = styled(TableStyled)`
  .empty-table-desc {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    background-color: ${props => hex2rgba(props.theme.colors.primary, 0.05)};
    border-radius: 2px;
    span {
      font-weight: 800;
      font-size: 18px;
      color: ${props => props.theme.colors.primary};
    }
    padding: 10px;
  }
  .nowrap {
    white-space: nowrap;
  }
`;

export const TableButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 20px;

  .ad-table {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 2px;
    padding: 20px;
    width: 100%;
  }
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const RowFooter = styled(Row)`
  justify-content: space-between;
  margin-bottom: 0;
`;

export const FormGroup = styled(FormGroupStyled)`
  flex: 1;
  margin-right: 0;
`;

export const FormGroupColumnLeft = styled(FormGroup)`
  padding-right: 5px;
`;

export const FormGroupColumnRight = styled(FormGroup)`
  padding-left: 5px;
`;

export const ButtonCancel = styled(Button).attrs({
  style: {
    marginLeft: 0
  }
})``;

export const ButtonAddProperty = styled(Button).attrs({
  style: {
    marginLeft: 0,
    width: 180,
    height: 30,
    lineHeight: "normal"
  }
})``;

export const ButtonDeleteProperty = styled(Button).attrs({
  style: {
    marginLeft: 10,
    height: 36
  }
})``;

export const Select = styled(ReactSelect)`
  width: 250px;
  margin-left: 10px;
  ${props =>
    props.invalid &&
    css`
      > div {
        border-color: ${props => props.theme.colors.danger2};
      }
    `}
`;

export const ModalSelect = styled(Select)`
  width: 100%;
  margin-left: 0;
`;

export const Input = styled(InputStyled)`
  height: 36px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.light};
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  margin-right: 0;
  &::placeholder {
    color: ${props => props.theme.colors.light};
  }
`;

export const Textarea = styled(TextareaStyled)`
  width: 100%;
  resize: vertical;
`;
