/* Types */
export const Types = {
  SET_CURRENT_USER: 'auth/SET_CURRENT_USER',
  SET_CURRENT_USER_SUCCESS: 'auth/SET_CURRENT_USER_SUCCESS',
  LOGOUT_USER: 'auth/LOGOUT_USER',
};

const INITIAL_STATE = {
  currentUser: null,
  contract: null,
  contractId: null,
  hasDisabledCloneCampaign: false,
  hasProductDefaultNewCampaign: false,
  hasLinkTermsNewCampaign: false,
  audienceExternalIsShowZonesInfo: false,
  valueMinBudgetTenant: 0,
  auctionAlgorithm: {
    type: 'default',
    algorithms: ['default'],
  },
};

export default function auth(state = INITIAL_STATE, action) {
  let hasProductDefaultNewCampaign = false;
  let hasLinkTermsNewCampaign = false;
  let valueMinBudgetTenant = 0;
  let audienceExternalIsShowZonesInfo = false;
  let hasDisabledCloneCampaign = false;
  let hideTabGeofenceAudienceInternal = false;
  let hasCampaignOfferKey = false

  let ecpmAlgorithmTenant = 'default';
  let ecpmAlgorithmsTenant = [];
  if (action.type === Types.SET_CURRENT_USER_SUCCESS) {
    const { tenantUuid, contract } = action.payload;

    hideTabGeofenceAudienceInternal = !!(tenantUuid === '3740b500-e346-49a4-b1ed-5160ed0aad17');

    hasProductDefaultNewCampaign = !!(tenantUuid === '27e9610a-6d78-4ddd-af88-003eb8cb9b40'
      || tenantUuid === '2563fe93-87c1-494e-983e-509bb03e4ade');

    audienceExternalIsShowZonesInfo = tenantUuid === '27e9610a-6d78-4ddd-af88-003eb8cb9b40';

    hasCampaignOfferKey = tenantUuid === 'd2b07c25-09b0-43ad-81d7-cb0588c566c8';

    hasLinkTermsNewCampaign = !!(contract
      && contract.tenant
      && contract.tenant.extra
      && contract.tenant.extra.conditionalAcceptance
      && tenantUuid !== '10fc469d-4d08-46a9-8b41-43f5adfee9eb');

    valueMinBudgetTenant = contract
      && contract.tenant
      && contract.tenant.extra
      && contract.tenant.extra.pricing
      && contract.tenant.extra.pricing.budget
      && contract.tenant.extra.pricing.budget.min
      ? contract.tenant.extra.pricing.budget.min : 0;

    ecpmAlgorithmTenant = contract
      && contract.tenant
      && contract.tenant.extra
      && contract.tenant.extra.auction
      && contract.tenant.extra.auction.ranking
      && contract.tenant.extra.auction.ranking.ecpm
      ? contract.tenant.extra.auction.ranking.ecpm : 'default';

    ecpmAlgorithmsTenant = contract
      && contract.tenant
      && contract.tenant.extra
      && contract.tenant.extra.auction
      && contract.tenant.extra.auction.ranking
      && contract.tenant.extra.auction.ranking.ecpmAlgorithms
      ? contract.tenant.extra.auction.ranking.ecpmAlgorithms : ['default'];

    hasDisabledCloneCampaign = contract
      && contract.tenant
      && contract.tenant.extra
      && contract.tenant.extra.campaignDisableCloneButton
      ? contract.tenant.extra.campaignDisableCloneButton : false;
  }

  switch (action.type) {
    case Types.LOGOUT_USER:
      return {
        ...state,
        ...INITIAL_STATE,
      };
    case Types.SET_CURRENT_USER_SUCCESS:
      return {
        ...state,
        currentUser: {
          ...action.payload,
          hasPermissionGlobalAudience: action.payload && action.payload.roles && action.payload.roles.find(item => item.name === 'GLOBAL_AUDIENCE_CREATOR'),
        },
        contract: action.payload.contract,
        contractId: action.payload.contract.externalId,
        hasProductDefaultNewCampaign,
        hasLinkTermsNewCampaign,
        hasDisabledCloneCampaign,
        audienceExternalIsShowZonesInfo,
        hasCampaignOfferKey,
        valueMinBudgetTenant,
        hideTabGeofenceAudienceInternal,
        auctionAlgorithm: {
          type: ecpmAlgorithmTenant,
          algorithms: ecpmAlgorithmsTenant,
        },
      };
    default:
      return state;
  }
}

/* Actions Creators */
export const Creators = {
  setCurrentUser: userId => ({
    type: Types.SET_CURRENT_USER,
    payload: userId,
  }),
  setCurrentUserSuccess: user => ({
    type: Types.SET_CURRENT_USER_SUCCESS,
    payload: user,
  }),
  logout: () => ({
    type: Types.LOGOUT_USER,
  }),
};
