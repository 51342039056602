import styled, {css} from 'styled-components';
import { transparentize } from 'polished';
import { hex2rgba } from '~/Utils';
import ReactSelect from 'react-select';

import {
  ContainerStyled,
} from '~/Components/Styled';

export const Container = styled(ContainerStyled)``;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
  margin-right: 25px;
  h1 {
    margin-left: 25px;
    min-width: 200px
  }
`;

export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-width: 400px;
  padding: 25px;
  margin: 25px 0 25px 0;
  gap: 25px;
  background-color: ${props => (props.bordered ? 'transparent' : '#FCF4EE')};
  border: ${props => (props.bordered ? '1px solid #E0E0E0' : '')};
  border-radius: 36px;

  overflow-x: visible;
`;

export const HeaderGroupButton = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  gap: 15px;
`;

export const Button = styled.button`
  height: 50px;
  width: ${props => (props.onlyIcon ? '60px' : '')};
  min-width: max-content;
  padding-left: ${props => (props.onlyIcon ? '' : '25px')};
  padding-right: ${props => (props.onlyIcon ? '' : '25px')};

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 15px;

  background-color: ${props => (props.bordered ? 'transparent' : props.theme.colors.primary)};
  border: ${props => (props.bordered ? `2px solid ${props.theme.colors.primary}` : 'none')};
  border-radius: ${props => (props.onlyIcon ? '20px' : '30px')};

  span {
    color: ${props => (props.bordered ? props.theme.colors.primary : props.theme.colors.white)};
    font-size: 18px;
  }

  &:hover {
    background-color:
    ${props => (props.bordered ? transparentize(0.9, props.theme.colors.primary)
    : transparentize(0.1, props.theme.colors.primary))};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    svg path {
      fill: ${props => props.theme.colors.regular};
    }
    span {
      color: ${props => props.theme.colors.regular};
    }
    box-shadow: 0 5px 10px ${props => hex2rgba(props.theme.colors.dark, 0.3)};
  }
`;

export const FormSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`;

export const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 15px;
`;

export const FormGroup = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  label {
    ${props => props.primary && css`
      color: ${props.theme.colors.primary};
    `}
    margin-left: 15px;
    display: flex;
    align-items: flex-start;
  }
`;

export const Select = styled(ReactSelect)`
  font-size: 20px;
  font-weight: 500;
  min-width: 500px;
  width: 100%;
  .react-select__control {
    border-radius: 30px;
    background-color: transparent;
    padding-left: 15px;
    padding-right: 15px;

    &:hover {
      border-color: ${props => props.theme.colors.primary};
      outline: none;
    }

    &:focus {
      border-color: ${props => props.theme.colors.primary};
      outline: none;
    }
  }
  .react-select__menu {
    border-radius: 30px;
    overflow: hidden;
    padding: 10px 0 10px 0;
  }
  .react-select__option {
    cursor: pointer;
  }
  .react-select__option:hover {
    background-color: ${props => props.theme.colors.primaryHex}30;
    color: ${props => props.theme.colors.dark};
  }
  .react-select__option--is-focused {
    background-color: ${props => props.theme.colors.primaryHex}30;
    color: ${props => props.theme.colors.dark};
  }
  .react-select__option--is-selected {
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
  }
  .react-select__indicator-separator{
    display: none;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;

export const Input = styled.input`
  font-size: 20px;
  font-weight: 500;
  min-width: 500px;
  width: 100%;
  height: 48px;
  background-color: transparent;
  border-color: ${props => props.theme.colors.light};
  outline-color: ${props => transparentize(0.4, props.theme.colors.primary)};
  border-radius: 20px;
  text-align: left;
  padding-left: 15px;
  padding-right: 15px;

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}

  /* Remove as setas up e down no input number */
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    appearance: none;
  }
`;

export const TextArea = styled.textarea`
  font-size: 20px;
  font-weight: 500;
  min-width: 500px;
  width: 100%;
  background-color: transparent;
  border-color: ${props => props.theme.colors.light};
  outline-color: ${props => transparentize(0.4, props.theme.colors.primary)};
  border-radius: 20px;
  text-align: left;
  padding: 15px;
  resize: vertical;

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}
`;

