import { call, put } from 'redux-saga/effects';

import ChannelsService from '~/services/ChannelsService';
import { Creators as ChannelActions } from '~/redux/ducks/channel';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadChannelsList() {
  yield put(startRequest());
  try {
    const channelsList = yield call(ChannelsService.list);
    yield put(ChannelActions.loadChannelsListSuccess(channelsList));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os canais. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}
