import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';

import Upload from '~/Components/Upload';
import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import { Creators as FinancialActions } from '~/redux/ducks/financial';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  FileListContent,
  FileList,
  FileListItem,
  CurrentInput,
} from './styles';

registerLocale('pt-BR', pt);

class ModalUpload extends Component {
  uploadFiles = async (upFiles) => {
    const { setModalInvoiceData, modalInvoiceData } = this.props;
    setModalInvoiceData({
      ...modalInvoiceData,
      files: [
        ...modalInvoiceData.files,
        ...upFiles,
      ],
    });
  };

  getFormIsvalid = () => {
    const { modalInvoiceData } = this.props;
    return !!modalInvoiceData.dueDate
      && !!modalInvoiceData.amount
      && modalInvoiceData.files.length > 0;
  }

  render() {
    const { setModalInvoiceData, modalInvoiceData, financialInvoiceSave } = this.props;
    return (
      <Modal width={700} scroll={false}>
        <Row>
          <h1>{`Lançar cobrança - ${modalInvoiceData.provider.name}`}</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => setModalInvoiceData(null)}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <Row>
          <FormGroup>
            <label>
              <b>Ano:</b>
              {modalInvoiceData.year}
            </label>
          </FormGroup>
          <FormGroup>
            <label>
              <b>Mês:</b>
              {modalInvoiceData.monthLabel}
            </label>
          </FormGroup>
          <FormGroup>
            <label>
              <b>Parceiro:</b>
              {modalInvoiceData.partner.name}
            </label>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <label><b>Data de vencimento:</b></label>
            <DatePicker
              selected={modalInvoiceData.dueDate ? moment(modalInvoiceData.dueDate).toDate() : null}
              onChange={(dueDate) => {
                setModalInvoiceData({
                  ...modalInvoiceData,
                  dueDate,
                });
              }}
              dateFormat="dd/MM/yyyy"
              locale="pt-BR"
              minDate={moment().add(1, 'days').toDate()}
            />
          </FormGroup>
          <FormGroup style={{ flex: 1 }}>
            <label><b>Valor:</b></label>
            <CurrentInput
              placeholder="Valor"
              prefix="R$ "
              decimalSeparator=","
              thousandSeparator="."
              value={modalInvoiceData.amount}
              onChange={(e, value) => {
                setModalInvoiceData({
                  ...modalInvoiceData,
                  amount: value,
                });
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label><b>Arquivos:</b></label>
            <Upload
              multiple
              className="button-file-upload"
              label="Selecione"
              onUpload={this.uploadFiles}
              accept="image/png,image/jpg,image/jpeg,application/pdf"
              onRejectMaxSize={() => {}}
              onClick={() => {}}
            />
          </FormGroup>
        </Row>
        {modalInvoiceData.files.length > 0 && (
          <FileListContent>
            <FileList>
              {modalInvoiceData.files.map(item => (
                <FileListItem>
                  <span>{item.name}</span>
                  <button
                    type="button"
                    onClick={() => {
                      setModalInvoiceData({
                        ...modalInvoiceData,
                        files: modalInvoiceData.files.filter(file => file.name !== item.name),
                      });
                    }}
                  >
                    <FaTimes />
                  </button>
                </FileListItem>
              ))}
            </FileList>
          </FileListContent>
        )}
        <RowFooter>
          <Button
            disabled={!this.getFormIsvalid()}
            title="Confirmar"
            onClick={() => financialInvoiceSave()}
          />
        </RowFooter>
      </Modal>
    );
  }
}

ModalUpload.defaultProps = {
  modalInvoiceData: null,
};

ModalUpload.propTypes = {
  modalInvoiceData: PropTypes.shape(),
  setModalInvoiceData: PropTypes.func.isRequired,
  financialInvoiceSave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modalInvoiceData: state.financial.modalInvoiceData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setModalInvoiceData: FinancialActions.setModalInvoiceData,
    financialInvoiceSave: FinancialActions.financialInvoiceSave,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalUpload);
