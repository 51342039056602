/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable no-console */
import { put, call, select } from 'redux-saga/effects';
import moment from 'moment';

import CampaignScheduling from '~/services/CampaignScheduling';
import { Creators as CampaignSchedulingActions } from '~/redux/ducks/campaignScheduling';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

const INNITIAL_TYPES = [
  {
    uuid: '32850e30-af62-468d-b9dc-425c3da66d2a',
    name: 'POST',
    label: 'Pós',
    icon: 'file',
    createdAt: '2020-05-21T13:00:37.000Z',
    updatedAt: '2020-05-21T13:00:37.000Z',
    deletedAt: null,
    version: 0,
  },
];

export function* campaignScheduleSetup() {
  // Start loading
  yield put(startRequest());
  try {
    const types = INNITIAL_TYPES.map(item => ({
      label: item.label,
      value: item.uuid,
    }));
    yield put(
      CampaignSchedulingActions.campaignScheduleTypesRequestSuccess(types),
    );
    yield put(CampaignSchedulingActions.campaignScheduleSetSchedule({
      type: types[0],
    }));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar o agendamento.',
    ));
  }
}

export function* campaignScheduleCampaignRequest({ position, contractUuid, typeUuid }) {
  const { schedule } = yield select(state => state.campaignScheduling);
  // Start loading
  yield put(startRequest());
  try {
    const campaigns = yield call(
      CampaignScheduling.getCampaigns,
      { contractUuid, typeUuid, date: schedule.date },
    );
    yield put(CampaignSchedulingActions.campaignScheduleSetSchedule({
      partners: schedule.partners.map((item, pos) => {
        if (pos === position) {
          return {
            ...item,
            campaigns: campaigns.content.map(cam => ({
              label: cam.name,
              value: cam.uuid,
            })),
          };
        }
        return item;
      }),
    }));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar as campanhas.',
    ));
  }
}

export function* campaignScheduleRequest({ date, forceSetup }) {
  const { schedule, partners } = yield select(state => state.campaignScheduling);
  // Start loading
  yield put(startRequest());
  try {
    const schedules = yield call(
      CampaignScheduling.getSchedule,
      date,
    );
    if (partners.length <= 0) {
      const response = yield call(CampaignScheduling.partnersRequest);
      yield put(
        CampaignSchedulingActions.campaignSchedulePartnersRequestSuccess(
          response.map(item => ({
            label: item.name,
            value: item.uuid,
          })),
        ),
      );
    }
    yield put(CampaignSchedulingActions.campaignScheduleRequestSuccess({
      date,
      schedules,
    }));
    if (!schedule.date || forceSetup) {
      yield put(CampaignSchedulingActions.campaignScheduleSetupByDate(
        schedule.date || moment().format('YYYY-MM-DD'),
      ));
    }
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar os agendamentos.',
    ));
  }
}

export function* campaignScheduleSetupByDate({ date }) {
  const {
    schedule,
    monthSchedule,
  } = yield select(state => state.campaignScheduling);
  // Start loading
  yield put(startRequest());
  try {
    const dateNow = moment();
    const dateSelected = moment(date);
    const isOpen = dateSelected.isAfter(dateNow);
    const findSchedule = monthSchedule.schedules.find(item => item.date === date);

    if (findSchedule) {
      let loadedPartners = findSchedule.campaigns.map(item => ({
        partner: {
          label: item.contract.name,
          value: item.contract.uuid,
        },
        campaign: {
          label: item.name,
          value: item.uuid,
          isBonus: item.isBonus,
        },
        campaigns: [],
        status: item.CampaignScheduling.status,
      }));

      if (loadedPartners.length < 3) {
        if (loadedPartners.length === 0) {
          loadedPartners = [
            ...loadedPartners,
            {
              partner: null, campaign: null, campaigns: [], status: '',
            },
            {
              partner: null, campaign: null, campaigns: [], status: '',
            },
            {
              partner: null, campaign: null, campaigns: [], status: '',
            },
          ];
        }
      }

      const campaignsToSelectInput = [];

      for (let i = 0; i < loadedPartners.length; i++) {
        if (loadedPartners[i].partner) {
          const response = yield call(
            CampaignScheduling.getCampaigns,
            {
              contractUuid: loadedPartners[i].partner.value,
              typeUuid: schedule.type.value,
              date,
            },
          );
          campaignsToSelectInput.push(response.content);
        } else {
          campaignsToSelectInput.push([]);
        }
      }
      yield put(
        CampaignSchedulingActions.campaignScheduleSetSchedule({
          uuid: findSchedule.uuid,
          date,
          isEnd: !!findSchedule.end,
          isOpen,
          partners: loadedPartners.map((item, pos) => ({
            ...item,
            campaigns: campaignsToSelectInput[pos].map(cam => ({
              label: cam.name,
              value: cam.uuid,
            })),
          })),
        }),
      );
    } else {
      yield put(
        CampaignSchedulingActions.campaignScheduleSetSchedule({
          uuid: null,
          date,
          isEnd: false,
          isOpen,
          partners: [
            {
              partner: null, campaign: null, campaigns: [], status: '',
            },
            {
              partner: null, campaign: null, campaigns: [], status: '',
            },
            {
              partner: null, campaign: null, campaigns: [], status: '',
            },
          ],
        }),
      );
    }
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as campanhas do agendamento.',
    ));
  }
}

export function* campaignScheduleSaveSendRequest({ postType }) {
  const { schedule, monthSchedule } = yield select(state => state.campaignScheduling);
  const isEnd = postType === 'SEND';
  // Start loading
  yield put(startRequest());
  try {
    let objToSave = {
      end: isEnd,
      date: schedule.date,
      campaigns: schedule.partners.map((item, priority) => ({
        uuid: item.campaign.value,
        priority,
        contractUuid: item.partner.value,
        isBonus: item.campaign.isBonus,
      })),
    };
    if (schedule.uuid) {
      objToSave = {
        ...objToSave,
        uuid: schedule.uuid,
      };
    }
    if (isEnd && !schedule.uuid) {
      const response = yield call(
        CampaignScheduling.save,
        {
          ...objToSave,
          end: false,
        },
      );
      objToSave = {
        ...objToSave,
        uuid: response.uuid,
      };
    }
    yield call(
      CampaignScheduling.save,
      objToSave,
    );
    yield put(
      CampaignSchedulingActions.campaignScheduleRequest(monthSchedule.date, true),
    );
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      `O agendamento foi ${isEnd ? 'enviado' : 'cadastrado'} com sucesso.`,
    ));
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      `Houve um problema ao ${isEnd ? 'enviar' : 'salvar'} o agendamento.`,
    ));
  }
}
