import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import {
  Container,
  Row,
  FormGroup,
  // BaseConfirmRemove,
  Input,
  Textarea,
  // LimitCaracteres,
  Select,
} from './styles';

class TabSuccess extends Component {
  componentDidMount() {}

  handleSuccessType = (type) => {
    const {
      campaignEditCriativoRequestSuccess, criativoEdit, content,
    } = this.props;
    // const success = content.mainData && content.mainData.success
    //   ? content.mainData.success
    //   : {};
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        mainData: {
          ...content.mainData,
          success: {
            // ...success,
            type,
          },
        },
      },
    });
  };

  handleSuccessUrl = (url) => {
    const {
      campaignEditCriativoRequestSuccess, criativoEdit, content,
    } = this.props;
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        mainData: {
          ...content.mainData,
          success: {
            ...content.mainData.success,
            url,
          },
        },
      },
    });
  };

  handleSuccessTitle = (title) => {
    const {
      campaignEditCriativoRequestSuccess, criativoEdit, content,
    } = this.props;
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        mainData: {
          ...content.mainData,
          success: {
            ...content.mainData.success,
            title,
          },
        },
      },
    });
  };

  handleSuccessImage = (image) => {
    const {
      campaignEditCriativoRequestSuccess, criativoEdit, content,
    } = this.props;
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        mainData: {
          ...content.mainData,
          success: {
            ...content.mainData.success,
            image,
          },
        },
      },
    });
  };

  uploadFileImage = async (files) => {
    const file = files[0];
    if (file) {
      const { campaign } = this.props;
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.handleSuccessImage(url);
    }
  };

  handleSuccessDescription = (description) => {
    const {
      campaignEditCriativoRequestSuccess, criativoEdit, content,
    } = this.props;
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        mainData: {
          ...content.mainData,
          success: {
            ...content.mainData.success,
            description,
          },
        },
      },
    });
  };

  renderInternalMode = () => {
    const { hasError, content: { mainData }, disabledFields } = this.props;
    const { success } = mainData;
    return (
      <>
        <Row>
          <FormGroup>
            <label>
              Título
              <span>*</span>
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite o título"
              maxLength="35"
              onChange={(e) => {
                this.handleSuccessTitle(e.target.value);
              }}
              value={success && success.title ? success.title : ''}
              invalid={hasError && !success.title}
            />
          </FormGroup>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <FormGroup>
            <label>
              Texto livre
              <span>*</span>
            </label>
            <Textarea
              disabled={disabledFields}
              rows="4"
              maxLength="150"
              placeholder="Texto livre"
              onChange={(e) => {
                this.handleSuccessDescription(e.target.value);
              }}
              value={success && success.description ? success.description : ''}
              invalid={hasError && !success.description}
            />
          </FormGroup>
        </Row>
      </>
    );
  };

  renderExternalMode = () => {
    const { hasError, content: { mainData }, disabledFields } = this.props;
    const { success } = mainData;
    return (
      <Row>
        <FormGroup>
          <label>
            Link URL externa
            <span>*</span>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite a url"
            onChange={(e) => {
              this.handleSuccessUrl(e.target.value);
            }}
            value={success && success.url ? success.url : ''}
            invalid={hasError && (
              !success.url
              || !success.url.match(
                /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
              )
            )}
          />
        </FormGroup>
      </Row>
    );
  };

  render() {
    const { hasError, content: { mainData }, disabledFields } = this.props;
    return (
      <Container>
        <Row style={{ marginBottom: 25 }}>
          <FormGroup>
            <label>Página de Sucesso:</label>
            <Select
              disabled={disabledFields}
              style={{ width: 450 }}
              onChange={e => this.handleSuccessType(e.target.value)}
              value={mainData && mainData.success && mainData.success.type ? mainData.success.type : ''}
              invalid={hasError && (!mainData || !mainData.success || !mainData.success.type)}
            >
              <option value="">Selecione...</option>
              <option value="external">Página externa</option>
              <option value="internal">Página interna</option>
            </Select>
          </FormGroup>
        </Row>

        {mainData && mainData.success && mainData.success.type === 'external' && this.renderExternalMode()}
        {mainData && mainData.success && mainData.success.type === 'internal' && this.renderInternalMode()}

      </Container>
    );
  }
}

TabSuccess.propTypes = {
  campaign: PropTypes.shape().isRequired,
  content: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(TabSuccess);
