/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import FormStep from './formStep';

import {
  Container,
  PreviewImg,
  Preview,
} from './styles';

import previewImg from '~/Assets/Images/preview.png';

class PreviewResearch extends Component {
  componentDidMount() {}

  render() {
    const { data, config, theme } = this.props;
    const configData = config || {
      primaryColor: theme.colors.primaryHex,
      secondaryColor: theme.colors.white,
    };

    return (
      <Container>
        <PreviewImg alt="preview" src={previewImg} />
        <Preview>
          <FormStep data={data} config={configData} />
        </Preview>
      </Container>
    );
  }
}

PreviewResearch.defaultProps = {
  data: null,
  config: null,
};

PreviewResearch.propTypes = {
  data: PropTypes.shape(),
  theme: PropTypes.shape().isRequired,
  config: PropTypes.shape({
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
};

export default withTheme(PreviewResearch);
