import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border-radius: 5px;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
`;

export const ContainerTitle = styled.h2`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  height: 45px;
  font-size: 20px !important;
  padding: 10px 0;
  font-weight: 500;
`;

export const ContainerContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  min-height: 200px;
  .chart-component {
    width: 100%;
  }
  zoom: 122%;
`;
