/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InternalMediaComponentForm } from '../../Components/InternalMediaComponent';

class MediaVideoForm extends Component {
  componentDidMount() {}

  render() {
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      campaign,
      disabledFields,
    } = this.props;
    return (
      <InternalMediaComponentForm
        campaign={campaign}
        criativoEdit={criativoEdit}
        campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
        hasError={hasError}
        disabledFields={disabledFields}
      />
    );
  }
}

MediaVideoForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(MediaVideoForm);
