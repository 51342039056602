import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';
import uuid4 from 'uuid4';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  Input,
} from './styles';
import Upload from '~/Components/Upload';
import UploadService, { baseUrlMedia } from '~/services/UploadService';

function ModalEditCampaignAttribute({
  campaign,
  modalEdit,
  campaignEditChangeModalEditStatus,
  campaignAttributeToEdit,
  campaignEditSetAttributeToEdit,
  campaignEditSaveAttributeInEdit,
  startRequest,
  requestSuccess,
  errorMessage,
  requestError,
  confirmModal,
}) {
  if (!modalEdit.editAttributeStatus || !campaignAttributeToEdit) {
    return null;
  }

  const { attribute } = campaignAttributeToEdit;
  console.log(attribute);

  function renderComponentInput() {
    return (
      <Input
        type="text"
        style={{ marginRight: 0 }}
        placeholder={attribute.label}
        value={attribute.value}
        onChange={(e) => {
          campaignEditSetAttributeToEdit({
            ...campaignAttributeToEdit,
            attribute: {
              ...attribute,
              value: e.target.value,
            },
          });
        }}
      />
    );
  }

  async function uploadFileToAWS(file) {
    const { uuid: campaignUuid } = campaign;

    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const fileKey = `campaigns/${campaignUuid}/attributes/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(fileKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/attributes/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  }

  async function uploadFile(files) {
    const file = files[0];
    if (file) {
      const { url } = await uploadFileToAWS(file);
      campaignEditSetAttributeToEdit({
        ...campaignAttributeToEdit,
        attribute: {
          ...attribute,
          value: url,
        },
      });
    }
  }

  function renderComponenteUpload() {
    return (
      <div style={{
        display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center', flexDirection: 'column',
      }}
      >
        <Upload
          label={attribute.value ? 'Alterar' : 'Buscar'}
          onUpload={uploadFile}
          accept={null}
          onClick={() => { }}
          styleContainer={{
            width: '100%',
          }}
          style={{
            width: '100%',
            margin: 0,
          }}
        />
        {!!attribute.value && (
          <Button
            title="REMOVER"
            color="danger2"
            icon="FaTrash"
            bordered
            small
            style={{ marginLeft: 0 }}
            onClick={() => {
              confirmModal(
                'Remover',
                'Tem certeza que deseja remover o arquivo?',
                () => {
                  campaignEditSetAttributeToEdit({
                    ...campaignAttributeToEdit,
                    attribute: {
                      ...attribute,
                      value: null,
                    },
                  });
                },
              );
            }}
          />
        )}
      </div>
    );
  }

  function renderComponentByType() {
    switch (attribute.fieldtype) {
      case 'STRING':
        return renderComponentInput();
      case 'UPLOAD':
        return renderComponenteUpload();
      default:
        return renderComponentInput();
    }
  }

  return (
    <Modal width={500}>
      <>
        <Row>
          <h1>Editar Atributo da campanha</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              campaignEditChangeModalEditStatus({
                editAttributeStatus: false,
              });
              campaignEditSetAttributeToEdit(null);
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <Row>
          <FormGroup>
            <label>
              {`${attribute.label}:`}
            </label>
            {renderComponentByType()}
          </FormGroup>
        </Row>
        <RowFooter>
          <Button
            title="SALVAR"
            onClick={() => {
              campaignEditSaveAttributeInEdit();
            }}
          />
        </RowFooter>
      </>
    </Modal>
  );
}

ModalEditCampaignAttribute.propTypes = {
  modalEdit: PropTypes.shape().isRequired,
  campaign: PropTypes.shape().isRequired,
  campaignAttributeToEdit: PropTypes.shape().isRequired,
  campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
  campaignEditSetAttributeToEdit: PropTypes.func.isRequired,
  campaignEditSaveAttributeInEdit: PropTypes.func.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const { campaign } = state.campaign.edit;
  return {
    campaign,
    modalEdit: state.campaignEdit.modalEdit,
    campaignAttributeToEdit: state.campaignEdit.campaignAttributeToEdit,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditSetAttributeToEdit: CampaignEditActions.campaignEditSetAttributeToEdit,
    campaignEditSaveAttributeInEdit: CampaignEditActions.campaignEditSaveAttributeInEdit,
    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditCampaignAttribute);
