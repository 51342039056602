import React, { Component } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Container,
  ActualDataItem,
  ActualData,
} from './styles';

class ActualDataComponent extends Component {
  componentDidMount() {}

  render() {
    const { audienceUpdate } = this.props;
    return (
      <Container>
        <ActualData>
          <ActualDataItem>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              width: '100%',
              justifyContent: 'space-around',
            }}
            >
              <div className="baseItem" style={{ display: 'flex' }}>
                {/* <FaUser /> */}
                <div className="desc-box">
                  <p>Autor:</p>
                  <span>
                    {audienceUpdate.user.name}
                  </span>
                </div>
              </div>
              <div className="baseItem" style={{ display: 'flex' }}>
                {/* <FaMousePointer /> */}
                <div className="desc-box">
                  <p>Uso:</p>
                  <span>
                    {audienceUpdate.using || 0}
                  </span>
                </div>
              </div>
              <div className="baseItem" style={{ display: 'flex' }}>
                {/* <FaCalendar /> */}
                <div className="desc-box">
                  <p>Data criação:</p>
                  <span>
                    {moment(audienceUpdate.createdAt).format('DD/MM/YYYY HH:mm')}
                  </span>
                </div>
              </div>
            </div>
          </ActualDataItem>
        </ActualData>
      </Container>
    );
  }
}

ActualDataComponent.defaultProps = {};

ActualDataComponent.propTypes = {
  audienceUpdate: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  audienceUpdate: state.audience.audienceUpdate,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActualDataComponent);
