import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Button from '~/Components/Button';

import { Container, ContainerTitle, ContainerContent } from './styles';

class AudienceList extends Component {
  state= {};

  componentDidMount() {}

  render() {
    const { title, children, onExport } = this.props;
    return (
      <Container>
        <ContainerTitle>
          {title}
          {!!onExport && (
            <Button
              bordered
              icon="FaFileExcel"
              title="Exportar"
              onClick={onExport}
              type="button"
              style={{
                height: 35,
              }}
            />
          )}
        </ContainerTitle>
        <ContainerContent>{children}</ContainerContent>
      </Container>
    );
  }
}

AudienceList.defaultProps = {
  onExport: null,
};

AudienceList.propTypes = {
  title: PropTypes.string.isRequired,
  onExport: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const mapStateToProps = (/* state */) => ({
  // audiences: state.audience.audiences,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceList);
