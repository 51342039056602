import styled from 'styled-components';
import {
  RowStyled,
  FormGroupStyled,
  InputStyled,
  CurrentInputStyled,
} from '~/Components/Styled';


export const Container = styled.div``;

export const Input = styled(InputStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex: 1;
  margin: 0 0 10px 0;
  label {
    > b {
      width: 100px;
      display: inline-block;
    }
    small {
      color: ${props => props.theme.colors.danger2};
    }
  }
`;

export const CurrentInput = styled(CurrentInputStyled)``;
