/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as AudienceActions } from '~/redux/ducks/audience';

import {
  Table,
  ButtonSelectCategory,
} from './styles';

class Categories extends Component {
  componentDidMount() {}

  render() {
    const {
      categories, audienceCategoryDataRequest, categoryData,
    } = this.props;

    return (
      <div className="profile-column1">
        <h2>Categoria</h2>
        <div className="categories-content-column1">
          <Table>
            <tbody>
              {categories.map(item => (
                <tr key={item.key}>
                  <td>
                    <ButtonSelectCategory
                      active={categoryData && categoryData.category === item.key}
                      onClick={() => {
                        audienceCategoryDataRequest(item);
                      }}
                    >
                      {item.label}
                    </ButtonSelectCategory>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    );
  }
}


Categories.defaultProps = {};

Categories.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  categoryData: PropTypes.shape().isRequired,
  audienceCategoryDataRequest: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  categories: state.audience.categories,
  categoryData: state.audience.categoryData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    audienceCategoryDataRequest: AudienceActions.audienceCategoryDataRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Categories);
