import styled from 'styled-components';
import { transparentize } from 'polished';
import ReactSelect from 'react-select';
import { hex2rgba } from '~/Utils';


export const Container = styled.div`
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  z-index: 99999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${props => hex2rgba(props.theme.colors.dark, 0.5)};
  .value-range {
    align-self: flex-end;
    float: right;
    font-size: 18px;
    font-weight: 200;
    color: ${props => props.theme.colors.regular};
    margin-right: 15px;
  }
`;


export const ModalBox = styled.div`
  position: relative;
  z-index: 2;
  padding: 50px 50px 0px 50px;
  width: 60%;
  min-width: 950px;
  height: 80%;
  min-height: 650px;
  max-height: calc(100vh - 60px);
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 5px 20px ${props => hex2rgba(props.theme.colors.dark, 0.5)};

  header{
    text-align: center;
    padding-bottom:5px;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .header-title {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;
      svg {
        width: 40px;
        height: 40px;
        path {
          fill: ${props => props.theme.colors.dark};
        }
      }
      h1{
        font-size: x-large;
        color: ${props => props.theme.colors.primary};
        margin: 0;
      };
    }
  };
  .close-button{
    border: 0px;
    background-color: transparent;
  }
`;


export const Content = styled.div`
width: 100%;
height: 100%;
overflow-y: auto;
padding-bottom: 100px;
::-webkit-scrollbar {
  width: 0px;
}
`;


export const StyledDiv = styled.div`
  width: 100%;
  min-width: 400px;
  padding: 25px;
  margin: 25px 0 25px 0;
  background-color: ${props => (props.bordered ? 'transparent' : '#FCF4EE')};
  border: ${props => (props.bordered ? '1px solid #E0E0E0' : '')};
  border-radius: 36px;

  overflow-x: ${props => (props.bordered ? 'auto' : '')};
  h3 {
    margin-left: 15px;
  };
`;


export const Table = styled.table`
  width: 100%;
  font-size: large;

  thead {
    color: ${props => props.theme.colors.primary};
    th {
      text-align: center;
      padding: 20px 0 15px 0;
    }
    th:first-child {
      padding-left: 15px;
      text-align: left;
    }
  }

  tbody {
    font-size: larger;
    tr{
      border-bottom: 1px solid ${props => props.theme.colors.light};
      .input-div {
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        .brl-symbol {
          margin-right: 10px;
        }
      }
      .delete-button-div {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      td:first-child {
        padding-left: 15px;
      }
    }
    tr:last-child {
      border-bottom: none;
    }
    // remoção de setas dos inputs number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    // remoção de setas dos inputs number - firefox
    input[type=number]{
      -moz-appearance: textfield;
    }
  }
`;


export const TableInput = styled.input`
  width: 70%;
  background-color: transparent;
  border-color: ${props => props.theme.colors.light};
  outline-color: ${props => transparentize(0.4, props.theme.colors.primary)};
  border-radius: 20px;
  text-align: center;
`;


export const ContentBlock = styled.div`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 40px;

  .block-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px 0px 15px;
    >span {
      font-size: larger;
      font-weight: bold;
    }
  }
  .block-content {
    width: 100%;
    padding: 15px;
    margin-top: 15px;

    background-color: ${props => props.theme.colors.lighterWhite};
    border-radius: 30px;
  }
  .block-content-empty {
    width: 100%;
    height: 80px;
    padding: 15px;
    margin-top: 15px;

    background-color: ${props => props.theme.colors.lighterWhite};
    border-radius: 30px;

    display: flex;
    align-items: center;
    justify-content: center;
    span {
      font-size: large;
      font-weight: 500;
    }
  }
`;


export const Select = styled(ReactSelect)`
  font-size: 20px;
  font-weight: 500;
  margin-top: 5px;
  margin-bottom: 5px;
  width: ${props => props.width || 300}px;
  .react-select__control {
    border-radius: 30px;
    background-color: transparent;
    padding-left: 15px;
    padding-right: 15px;
    height: 40px;
  }
  .react-select__menu {
    border-radius: 30px;
  }
  .react-select__indicator-separator{
    display: none;
  }
  svg {
    width: 30px;
    height: 30px;
  }
`;


export const Button = styled.button`
  height: ${props => (props.sm ? '40px' : '50px')};
  width: ${props => (props.sm && props.onlyIcon ? '40px' : '')};
  min-width: max-content;
  padding-left: ${props => (props.onlyIcon ? '' : '25px')};
  padding-right: ${props => (props.onlyIcon ? '' : '25px')};

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 15px;

  background-color: ${props => (props.bordered ? 'transparent' : props.theme.colors.primary)};
  border: ${props => (props.bordered ? `2px solid ${props.theme.colors.primary}` : 'none')};
  border-radius: ${props => (props.onlyIcon ? '10px' : '30px')};

  box-shadow: 0 5px 10px ${props => hex2rgba(props.theme.colors.dark, 0.3)};

  span {
    color: ${props => (props.bordered ? props.theme.colors.primary : props.theme.colors.white)};
    font-size: 18px;
  }

  &:hover {
    background-color:
    ${props => (props.bordered ? transparentize(0.9, props.theme.colors.primary)
    : transparentize(0.1, props.theme.colors.primary))};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    svg path {
      fill: ${props => props.theme.colors.regular};
    }
    span {
      color: ${props => props.theme.colors.regular};
    }
  }
`;


export const ButtonDelete = styled.button`
  height: 40px;
  width: 40px;
  min-width: max-content;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: transparent;
  border: 2px solid #E54B4B;
  border-radius: 10px;

  svg {
    height: 24px;
    path {
      fill: #E54B4B;
    }
  }

  &:hover {
    border-color: ${transparentize(0.4, '#E54B4B')};
    svg path {
      fill: ${transparentize(0.4, '#E54B4B')};
    }
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    svg path {
      fill: ${props => props.theme.colors.regular};
    }
    span {
      color: ${props => props.theme.colors.regular};
    }
  }
`;


export const ModalFooter = styled.div`
    position: absolute;
    bottom: 0px;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100px;
    margin-left: -50px;
    padding-bottom: 20px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;

    background-color: ${props => transparentize(0.4, props.theme.colors.white)};
    backdrop-filter: blur(4px);
    box-shadow: 0 -5px 5px ${props => hex2rgba(props.theme.colors.dark, 0.1)};
`;
