import styled, { css } from 'styled-components';

import { CurrentInputStyled } from '~/Components/Styled';


export const Container = styled.div`
  display: flex;
`;

export const Input = styled.input`
  border-radius: 4px;
  background-color: transparent;
  font-weight: 400;
  padding: 5px;
  border: solid 2px #c7c7c7;
  margin-bottom: 5px;
  color: ${props => props.theme.colors.primary};
  font-weight: bold;
  font-size: ${props => props.fontSize}px;

  ${props => props.maxWidth
    && css`
      max-width: ${props.maxWidth}px;
    `}

  ${props => props.invalid
    && css`
      border-color: ${props => props.theme.colors.danger2};
    `}
`;

export const CurrentInput = styled(CurrentInputStyled)`
  ${props => props.maxWidth
    && css`
      max-width: ${props.maxWidth}px;
    `}
`;

export const Value = styled.label`
  color: ${props => (props.colorLabel ? props.colorLabel : props.theme.colors.primary)};
  font-size: ${props => props.fontSize}px;
`;
export const EditButton = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;

  > svg {
    margin-bottom: 7px;
    margin-left: 7px;
  }
`;
