import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex: 1;
  border: 2px solid ${props => props.theme.colors.lighter};
  border-radius: 5px;
  margin-right: 20px;
  height: 150px;
  &:last-child {
    margin-right: 0;
  }
  > svg {
    font-size: 30px;
    color: ${props => props.theme.colors.primary};
    margin-right: 10px;
  }
`;

export const ContainerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h2 {
    font-weight: bold;
    color: ${props => props.theme.colors.dark} !important;
    font-size: 18px !important;
  }
  span {
    font-size: 30px;
    color: ${props => props.theme.colors.primary};
    font-weight: bold;
  }
`;
