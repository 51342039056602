import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

// import Button from '../Button';
import { Button } from './styles';

export default class UploadImage extends Component {
  static propTypes = {
    accept: PropTypes.string,
    maxSize: PropTypes.number,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onUpload: PropTypes.func.isRequired,
    onRejectMaxSize: PropTypes.func,
    onClick: PropTypes.func,
    multiple: PropTypes.bool,
    bordered: PropTypes.bool,
    invalid: PropTypes.bool,
    style: PropTypes.shape(),
    styleContainer: PropTypes.shape(),
    resolution: PropTypes.shape(),
    onRejectResolution: PropTypes.func,
  };

  static defaultProps = {
    accept: 'image/*',
    maxSize: Infinity,
    disabled: false,
    onRejectMaxSize: null,
    onClick: null,
    multiple: false,
    bordered: true,
    style: null,
    styleContainer: null,
    invalid: false,
    resolution: null,
    onRejectResolution: null,
  };

  renderDragMessage = (open) => {
    const {
      label, onClick, disabled, bordered, style, invalid,
    } = this.props;
    return (
      <Button
        onClick={() => {
          if (onClick) {
            onClick();
          }
          open();
        }}
        disabled={disabled}
        bordered={bordered}
        title={label || 'UPLOAD'}
        style={{
          width: 'fit-content',
          marginLeft: 0,
          marginTop: 15,
          ...style,
        }}
        invalid={invalid}
      />
    );
  };

  validateFile = (file, resolution) => new Promise((resolve, reject) => {
    if (!resolution) {
      resolve();
      return;
    }

    const { fixed, width, height } = resolution;

    const image = new Image();
    image.addEventListener('load', () => {
      if (fixed) {
        if (image.width === width && image.height === height) {
          resolve();
        } else {
          reject();
        }
      } else {
        resolve();
      }
    });
    image.src = URL.createObjectURL(file);
  })

  uploadFiles = async (files) => {
    const { onUpload, resolution, onRejectResolution } = this.props;
    let filesValidate = [];

    if (resolution) {
      await Promise.all(files.map(async (file) => {
        try {
          await this.validateFile(file, resolution);
          filesValidate.push(file);
        } catch {
          if (onRejectResolution) onRejectResolution(file);
        }
      }));
    } else {
      filesValidate = [...files];
    }
    if (filesValidate && filesValidate.length) onUpload(filesValidate);
  }

  render() {
    const {
      disabled, accept, maxSize,
      onRejectMaxSize,
      multiple, styleContainer,
    } = this.props;
    return (
      <div style={{ width: '88px', height: '65px', ...styleContainer }} className="dropzone-content-box">
        <Dropzone
          accept={accept}
          noDrag
          noClick
          onDropAccepted={(files) => {
            this.uploadFiles(files);
          }}
          onDropRejected={(f) => {
            if (f && f[0].size > maxSize) {
              if (onRejectMaxSize) {
                onRejectMaxSize();
              }
            }
          }}
          multiple={multiple}
          disabled={disabled}
          maxSize={maxSize}
        >
          {({
            getRootProps, getInputProps, isDragActive, isDragReject, open,
          }) => (
            <div {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
              <input {...getInputProps()} />
              {this.renderDragMessage(open)}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}
