import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ConnectedRouter } from 'connected-react-router';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as CredentialsActions } from '~/redux/ducks/credentials';

import InitialLoader from './Components/InitialLoader';

import Routes from './routes';
// import Manutencao from './Pages/Manutencao';

class Credentials extends Component {
  static propTypes = {
    getCredentials: PropTypes.func.isRequired,
    credentials: PropTypes.shape(),
    history: PropTypes.shape().isRequired,
  }

  static defaultProps = {
    credentials: null,
  }

  async componentWillMount() {
    const { getCredentials } = this.props;
    getCredentials();
  }

  render() {
    const { credentials, history } = this.props;
    if (!credentials) {
      return <InitialLoader />;
    }
    return (
      <ConnectedRouter history={history}>
        <Routes />
        {/* <Manutencao /> */}
      </ConnectedRouter>
    );
  }
}

const mapStateToProps = state => ({
  credentials: state.credentials.data,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  getCredentials: CredentialsActions.getCredentials,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(Credentials);
