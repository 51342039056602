import { call, put, select } from 'redux-saga/effects';
import AudienceTestService from '~/services/AudienceTestService';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';
import { Creators as AudienceTestActions } from '~/redux/ducks/audienceTest';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';


export function* loadAudienceTest() {
  yield put(startRequest());
  try {
    const audienceUsers = yield call(AudienceTestService.listAllAudienceUsers);
    yield put(requestSuccess());
    yield put(AudienceTestActions.loadAudienceTestList(audienceUsers));
    yield put(AudienceTestActions.backupAudienceData(audienceUsers));
  } catch (e) {
  //  console.error;
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar a lista de audiência de teste. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* loadAudienceUserData(action) {
  const audienceId = action.payload;
  yield put(startRequest());
  try {
    const audienceUserFilter = yield call(AudienceTestService.filterAudienceUser, audienceId);
    const audienceUserData = audienceUserFilter;
    yield put(AudienceTestActions.setAudienceUserData(audienceUserData));
    const channels = audienceUserData.channels;
    // console.log(channels);
    const valueSMS = !!channels.includes('899cb964-0224-4067-8978-de855232ff6a');
    const valuePUSH = !!channels.includes('3b148899-6c48-4f49-92af-5d22b9381d39');
    const valueNODATA = !!channels.includes('fd1bd5dc-4754-11ea-89ec-0677896605d2');
    const valueSPONSORED = !!channels.includes('COLOCAR CODIGO'); // FALTA CODIGO NOTIFICAÇÃO PATROCINADA
    if (valueSMS === true) {
      // console.log("tem sms");
      yield put(AudienceTestActions.isCheckedSms());
    }
    if (valuePUSH === true) {
      // console.log("tem push");
      yield put(AudienceTestActions.isCheckedPush());
    }
    if (valueNODATA === true) {
      // console.log("tem no data");
      yield put(AudienceTestActions.isCheckedNoData());
    }
    if (valueSPONSORED === true) {
      // console.log("tem notificação patrocinada");
      yield put(AudienceTestActions.isCheckedSponsored());
    }

    yield put(AudienceTestActions.showModalAudience());
    yield put(requestSuccess());
  } catch (e) {
    // console.error;
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar os dados. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}


export function* saveModalData(action) {
  const audienceUserNewData = action.payload;
  // console.log(audienceUserNewData);
  const re = new RegExp('[1-9]{2}[9]{0,1}[6-9]{1}[0-9]{3}[0-9]{4}');

  // FALTA OS CODIGOS DOS CANAIS NO DATA E NOTIFICAÇÃO PATROCINADA

  // INCLUINDO OS CANAIS ATIVOS
  const channels = [];
  if (audienceUserNewData.valueSms) {
    channels.push('899cb964-0224-4067-8978-de855232ff6a');
  }
  if (audienceUserNewData.valuePush) {
    channels.push('3b148899-6c48-4f49-92af-5d22b9381d39');
  }
  if (audienceUserNewData.valueNoData) {
    channels.push('fd1bd5dc-4754-11ea-89ec-0677896605d2');
  }
  // if(audienceUserNewData.valueSponsored){
  //   channels.push("COLOCAR CODIGO AQUI");
  // }
  const audienceSave = {
    uuid: audienceUserNewData.idAudienceUser,
    name: audienceUserNewData.loadName,
    identifier: audienceUserNewData.loadIdentifier,
    channels,
  };
  if (re.exec(audienceUserNewData.loadIdentifier) && audienceUserNewData.loadName !== '') {
  // FALTA OS CODIGOS DE NO DATA E NOTIFICAÇÃO PATROCINADA
    // SALVANDO DADOS NA API
    if (audienceSave.uuid) {
      // SALVANDO DADOS DE USUARIO JA EXISTENTE - UPDATE USUARIO DE TESTE
      // console.log("Tem id");
      yield put(startRequest());
      try {
        yield call(AudienceTestService.update, audienceSave);
        // console.log(result);
        yield put(ToastrMessageActions.successMessage(
          'Sucesso!',
          'Usuário de teste alterado com sucesso',
        ));
        yield put(requestSuccess());
        yield put(AudienceTestActions.hideModalAudience());
        yield put(AudienceTestActions.loadAudienceTest());
      } catch (e) {
        yield put(ToastrMessageActions.errorMessage(
          'Atenção!',
          'Houve um problema ao salvar alteracões do cadastro de usuário de teste. Tente novamente mais tarde!',
        ));
        yield put(requestError());
      }
    } else {
      // CRIANDO USUARIO NOVO
      yield put(startRequest());
      try {
        yield call(AudienceTestService.create, audienceSave);
        // console.log(result);
        yield put(ToastrMessageActions.successMessage(
          'Sucesso!',
          'Usuário de teste criado com sucesso',
        ));
        yield put(requestSuccess());

        yield put(AudienceTestActions.hideModalAudience());
        yield put(AudienceTestActions.loadAudienceTest());
      } catch (e) {
        yield put(ToastrMessageActions.errorMessage(
          'Atenção!',
          'Houve um problema ao criar o usuário de audiência. Tente novamente mais tarde!',
        ));
        yield put(requestError());
      }
    }
  } else {
    if (audienceUserNewData.loadIdentifier === '') {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'É necessario um número de celular para realizar o cadastro. Por favor revise os dados inseridos.',
      ));
    }
    if (!re.exec(audienceUserNewData.loadIdentifier) && audienceUserNewData.loadIdentifier !== '') {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Número de celular de fora do padrão. Por favor revise os dados inseridos.',
      ));
    }
    if (audienceUserNewData.loadName === '') {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'É necessario um nome de indentificação para realizar o cadastro. Por favor revise os dados inseridos.',
      ));
    }
  } // validação
}

export function* deleteAudience(action) {
  // console.log("delete no saga");
  const audienceId = action.payload;
  yield put(startRequest());
  try {
    yield call(AudienceTestService.excluir, audienceId);
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Campanha excluida com sucesso.',
    ));
    yield put(requestSuccess());
    yield put(AudienceTestActions.loadAudienceTest());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao excluir a campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* filterAudience(action) {
  const filterData = action.payload;
  const audienceUsersData = yield select(state => state.audienceTest.audienceUsersBackup);
  const regexp = new RegExp(filterData, 'i');
  const filterResult = audienceUsersData.filter(x => regexp.test(x.name) || regexp.test(x.identifier));
  yield put(AudienceTestActions.loadAudienceTestList(filterResult));
  yield put(requestSuccess());
}

export function* resetfilterAudience() {
  const cleanFilter = { name: '' };
  const audienceUsersData = yield select(state => state.audienceTest.audienceUsersBackup);
  yield put(AudienceTestActions.setFilterAudience(cleanFilter));
  yield put(AudienceTestActions.loadAudienceTestList(audienceUsersData));
}
