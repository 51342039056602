import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  Message,
  Input,
} from './styles';

class ModalCloneCampanha extends Component {
  state = {
    hasError: false,
  }

  render() {
    const {
      campaignToClone,
      campaignSetClone,
      campaignCloneRequest,
    } = this.props;
    const { hasError } = this.state;

    return !!campaignToClone && (
      <Modal width={500}>
        <>
          <Row>
            <h1>Clonar Campanha</h1>
            <ButtonCloseModal
              type="button"
              onClick={() => {
                campaignSetClone(null);
              }}
            >
              <FaTimes size={30} />
            </ButtonCloseModal>
          </Row>
          <Message>Deseja realizar uma cópia da Campanha?</Message>
          <FormGroup>
            <label>
              <b>Nome da Campanha: </b>
            </label>
            <Input
              type="text"
              style={{ marginRight: 0 }}
              placeholder="Nome da Campanha"
              value={campaignToClone.name}
              invalid={hasError && !campaignToClone.name}
              onChange={(e) => {
                campaignSetClone({
                  ...campaignToClone,
                  name: e.target.value,
                });
              }}
            />
          </FormGroup>
          <RowFooter>
            <Button
              title="CONFIRMAR"
              onClick={() => {
                if (!!campaignToClone.uuid && !!campaignToClone.name) {
                  campaignCloneRequest();
                } else {
                  this.setState({
                    hasError: true,
                  });
                }
              }}
            />
          </RowFooter>
        </>
      </Modal>
    );
  }
}

ModalCloneCampanha.propTypes = {
  campaignSetClone: PropTypes.func.isRequired,
  campaignCloneRequest: PropTypes.func.isRequired,
  campaignToClone: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  campaignToClone: state.campaignEdit.campaignToClone,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignSetClone: CampaignEditActions.campaignSetClone,
    campaignCloneRequest: CampaignEditActions.campaignCloneRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalCloneCampanha);
