import React from 'react';
import PropTypes from 'prop-types';

import { general } from '~/Styles';
import { Container } from './styles';

const ButtonIcon = ({
  title, name, value, onClick, img, checked,
}) => (
  <Container onClick={onClick}>
    <input type="radio" checked={checked} name={name} value={value} />
    <div className="checkmark">
      <img alt="sms" src={general.image[img]} />
      <span>{title}</span>
    </div>
  </Container>
);

ButtonIcon.propTypes = {
  title: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.shape().isRequired,
  onClick: PropTypes.func.isRequired,
  img: PropTypes.shape().isRequired,
  checked: PropTypes.bool.isRequired,
};

export default ButtonIcon;
