import styled from 'styled-components';
import { RowStyled } from '~/Components/Styled';
import ButtonComponent from '~/Components/Button';

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const TitleItemExcluse = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const Button = styled(ButtonComponent)`
  margin: 0;
`;
