import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import {
  FaRegCheckSquare, FaRegSquare, FaCheck, FaTimes,
} from 'react-icons/fa';

import { Creators as ReportActions } from '~/redux/ducks/report';
import { Creators as ProductActions } from '~/redux/ducks/product';

import {
  Container,
  Row,
  Table,
  TableDisplayRow,
  Select,
  Header,
  HeaderForm,
  HeaderFormInput,
  // box
  BoxTable,
  BoxTableHeader,
  BoxTableHeaderTitle,
  BoxTableContent,
  BoxTableFooter,
  // signature
  TableDisplayColumn,
  ButtonSignatureValidate,
  ButtonSignatureInvalidate,
  Textarea,
  FormGroup,
  RowFooter,
  LabelValidation,
  LabelInvalidation,
  LabelNoResult,
  ActionContainer,
} from './styles';

import { getBudgetLabelString } from '~/Utils';
import Button from '~/Components/Button';
import Modal from '~/Components/Modal';
import ModalResults from './Components/ModalResults';
import ModalSchedule from './Components/ModalSchedule';
import ModalDetailProduct from '~/Pages/Product/ModalDetailProduct';

class ReportGridPreriodList extends Component {
  state = {
    modalResultOpen: false,
    campaignResult: null,
    dailyDistribution: null,
    signatureInvalid: null,
  }

  componentDidMount() {
    const { reportGridPeriodSetup } = this.props;
    reportGridPeriodSetup();
  }

  componentWillUnmount() {
    const {
      reportGridPeriodChannelRequestSuccess,
      reportGridPeriodPeriodRequestSuccess,
      reportGridPeriodRequestSuccess,
      reportGridPeriodSetFilter,
    } = this.props;
    reportGridPeriodChannelRequestSuccess([]);
    reportGridPeriodPeriodRequestSuccess([]);
    reportGridPeriodRequestSuccess([]);
    reportGridPeriodSetFilter({
      channel: null,
      period: null,
      month: null,
      type: null,
    });
  }

  handleOpenModalResult = (gridUuid, campaignUuid, gridPeriodUuid) => {
    const { reportGridPeriodLoadReportData } = this.props;
    reportGridPeriodLoadReportData(
      gridUuid,
      campaignUuid,
      gridPeriodUuid,
    );
  };

  handleOpenModalSchedule = (item) => {
    this.setState({
      dailyDistribution: item.dailyDistribution || [],
    });
  };

  handleChangeSignature = (gridUuid, gridItemUuid, rest) => {
    const { reportGridPeriodRequestSuccess, gridPeriod } = this.props;
    const mapGrid = gridPeriod.map((item) => {
      if (gridUuid === item.uuid) {
        return {
          ...item,
          gridItems: item.gridItems.map((gridItem) => {
            if (
              (gridItem.uuid === gridItemUuid || !gridItemUuid)
              && gridItem.cpa > 0
            ) {
              return {
                ...gridItem,
                ...rest,
              };
            }
            return gridItem;
          }),
        };
      }
      return item;
    });
    reportGridPeriodRequestSuccess(mapGrid);
  };

  handleConfirmSignature = (gridUuid) => {
    const { gridPeriod, reportGridConfirmSignature } = this.props;
    const findGridPeriod = gridPeriod.find(item => item.uuid === gridUuid);
    const filterItemsHasInvalid = findGridPeriod.gridItems.filter(
      item => item.statusSignature === 'INVALIDATION' && !item.signatureConfirmed,
    );
    if (filterItemsHasInvalid.length > 0) {
      this.setState({
        signatureInvalid: {
          ...filterItemsHasInvalid[0],
          gridUuid,
        },
      });
    } else {
      this.setState({
        signatureInvalid: null,
      });
      reportGridConfirmSignature(gridUuid);
    }
  };

  renderHeader = () => {
    const {
      periodList,
      channelList,
      gridPeriodFilter,
      reportGridPeriodSetFilter,
      reportGridPeriodRequest,
    } = this.props;
    return (
      <Header>
        <HeaderForm>
          <HeaderFormInput>
            <label>Semana:</label>
            <Select
              placeholder="Selecione a semana"
              value={gridPeriodFilter.period}
              onChange={(period) => {
                reportGridPeriodSetFilter({ period });
                reportGridPeriodRequest();
              }}
              options={periodList}
            />
          </HeaderFormInput>
          <HeaderFormInput>
            <label>Canal:</label>
            <Select
              placeholder="Selecione o canal"
              value={gridPeriodFilter.channel}
              onChange={(channel) => {
                reportGridPeriodSetFilter({ channel });
                reportGridPeriodRequest();
              }}
              options={channelList}
            />
          </HeaderFormInput>
        </HeaderForm>
      </Header>
    );
  };

  getStatusSignatureLabel = (status) => {
    switch (status) {
      case 'VALIDATION': return (
        <LabelValidation>
          <FaCheck />
          Válido
        </LabelValidation>
      );
      case 'INVALIDATION': return (
        <LabelInvalidation>
          <FaTimes />
          Inválido
        </LabelInvalidation>
      );
      case 'null': return <LabelNoResult>Sem resultado</LabelNoResult>;
      default: return '';
    }
  };

  handleHasSignatureToChange = (gridUuid) => {
    const { gridPeriod } = this.props;
    const findGridPeriod = gridPeriod.find(item => item.uuid === gridUuid);
    const filterItems = findGridPeriod.gridItems.filter(
      item => !item.hasSignature || item.statusLabelSignature === 'INVALIDATION',
    );
    return filterItems.length > 0;
  };

  renderBoxCPA = (grid) => {
    const { showDetailProductSetData } = this.props;
    return grid && grid.gridItems.length > 0 ? (
      <Row>
        <BoxTable>
          <BoxTableHeader>
            <BoxTableHeaderTitle>CPA Fixo</BoxTableHeaderTitle>
            <ButtonSignatureValidate
              onClick={() => {
                this.handleChangeSignature(
                  grid.uuid,
                  null,
                  { statusSignature: 'VALIDATION' },
                );
              }}
            >
              <FaRegCheckSquare />
              Validar todos
            </ButtonSignatureValidate>
          </BoxTableHeader>
          <BoxTableContent>
            <Table>
              <thead>
                <tr>
                  <th>PARCEIRO</th>
                  <th>SENDER</th>
                  <th>NOME</th>
                  <th>KW</th>
                  {/* <th align="center" width="1%">eCPM</th> */}
                  <th align="center" width="1%">BUDGET</th>
                  <th align="center" width="1%">CPA</th>
                  <th align="center" width="1%">VOLUME</th>
                  <th align="center" width="1%">FATURADO</th>
                  <th align="center" width="1%">ASSINATURA</th>
                  <th align="center" width="1%" />
                  <th align="center" width="1%">AÇÕES</th>
                  <th width="1%" align="center">INDICADORES</th>
                </tr>
              </thead>
              <tbody>
                {grid.gridItems.map(item => (
                  <tr key={item.uuid}>
                    <td>
                      <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                    </td>
                    <td>
                      <span>{item.sender}</span>
                    </td>
                    <td>
                      <span>{item.campaign.name}</span>
                    </td>
                    <td>
                      {item.campaign.productOffers.map(po => (
                        <span key={po.CampaignProductOffer.uuid}>
                          {`${po.CampaignProductOffer.keyword}`}
                          <br />
                        </span>
                      ))}
                    </td>
                    {/*
                    <td align="center">
                      <span className="nowrap">{item.ecpm.replace('.', ',')}</span>
                    </td>
                  */}
                    <td align="center">
                      <span className="nowrap">{getBudgetLabelString(item.budget)}</span>
                    </td>
                    <td align="center">
                      <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
                    </td>
                    <td align="center">
                      <span className="nowrap">{item.volume.toLocaleString()}</span>
                    </td>
                    <td align="center">
                      <span className="nowrap">
                        {
                        `R$ ${
                          (
                            item.campaignResult && item.campaignResult.converted
                              ? (item.cpa * item.campaignResult.converted)
                              : 0
                          ).toLocaleString()
                        }`
                      }
                      </span>
                    </td>
                    <td align="center">
                      <span className="nowrap">
                        {item.campaignResult && item.campaignResult.converted ? item.campaignResult.converted : ''}
                      </span>
                    </td>
                    <td>
                      {item.cpa > 0 && item.hasSignature && (
                      <TableDisplayColumn>
                        <span>{this.getStatusSignatureLabel(item.statusLabelSignature)}</span>
                        {item.statusLabelSignature === 'INVALIDATION' && (
                        <ButtonSignatureValidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'VALIDATION' ? 'null' : 'VALIDATION' },
                            );
                          }}
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        >
                          {item.statusSignature === 'VALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Validar
                        </ButtonSignatureValidate>
                        )}
                      </TableDisplayColumn>
                      )}
                      {item.cpa > 0 && !item.hasSignature && (
                      <TableDisplayColumn>
                        <ButtonSignatureValidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'VALIDATION' ? 'null' : 'VALIDATION' },
                            );
                          }}
                          style={{
                            paddingBottom: 5,
                          }}
                        >
                          {item.statusSignature === 'VALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Validar
                        </ButtonSignatureValidate>
                        <ButtonSignatureInvalidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'INVALIDATION' ? 'null' : 'INVALIDATION' },
                            );
                          }}
                          style={{
                            paddingTop: 5,
                          }}
                        >
                          {item.statusSignature === 'INVALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Invalidar
                        </ButtonSignatureInvalidate>
                      </TableDisplayColumn>
                      )}
                    </td>
                    <td align="center">
                      <TableDisplayRow>
                        {item.dailyDistribution && (
                        <>
                          <Button
                            icon="FaCalendarAlt"
                            noLabel
                            onClick={() => {
                              this.handleOpenModalSchedule(item);
                            }}
                            data-tip
                            data-for={`btn-schedule-${item.uuid}`}
                          />
                          <ReactTooltip
                            className="tooltip-report"
                            effect="solid"
                            id={`btn-schedule-${item.uuid}`}
                            aria-haspopup="true"
                          >
                            Cronograma de Disparos
                          </ReactTooltip>
                        </>
                        )}
                        <Button
                          icon="FaChartLine"
                          noLabel
                          onClick={() => {
                            this.handleOpenModalResult(
                              grid.uuid,
                              item.campaign.uuid,
                              grid.gridPeriod.uuid,
                            );
                          }}
                          data-tip
                          data-for={`btn-result-${item.uuid}`}
                        />
                        <ReactTooltip
                          className="tooltip-report"
                          effect="solid"
                          id={`btn-result-${item.uuid}`}
                          aria-haspopup="true"
                        >
                          Relatório
                        </ReactTooltip>
                      </TableDisplayRow>
                    </td>
                    <td>
                      <ActionContainer>
                        <Button
                          icon="FaInfo"
                          noLabel
                          onClick={() => {
                            const productOffer = item.campaign.productOffers[0];
                            if (productOffer && productOffer.product) {
                              showDetailProductSetData(productOffer.product);
                            }
                          }}
                        />
                      </ActionContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </BoxTableContent>
          {this.handleHasSignatureToChange(grid.uuid) && (
          <BoxTableFooter>
            <Button
              onClick={() => {
                this.handleConfirmSignature(grid.uuid);
              }}
              title="Confirmar validação"
              style={{
                height: 35,
              }}
            />
          </BoxTableFooter>
          )}
        </BoxTable>
      </Row>
    ) : null;
  };

  renderBoxTeste = (grid) => {
    const { showDetailProductSetData } = this.props;
    return grid && grid.gridItems.length > 0 ? (
      <Row>
        <BoxTable>
          <BoxTableHeader>
            <BoxTableHeaderTitle>Teste</BoxTableHeaderTitle>
          </BoxTableHeader>
          <BoxTableContent>
            <Table>
              <thead>
                <tr>
                  <th>PARCEIRO</th>
                  <th>SENDER</th>
                  <th>NOME</th>
                  <th>KW</th>
                  <th align="center" width="1%">CPA</th>
                  <th align="center" width="1%">VOLUME</th>
                  <th align="center" width="1%">ASSINATURA</th>
                  <th align="center" width="1%" />
                  <th align="center" width="1%">AÇÕES</th>
                  <th align="center" width="1%">INDICADORES</th>
                </tr>
              </thead>
              <tbody>
                {grid.gridItems.map(item => (
                  <tr key={item.uuid}>
                    <td>
                      <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                    </td>
                    <td>
                      <span>{item.sender}</span>
                    </td>
                    <td>
                      <span>{item.campaign.name}</span>
                    </td>
                    <td>
                      {item.campaign.productOffers.map(po => (
                        <span key={po.CampaignProductOffer.uuid}>
                          {`${po.CampaignProductOffer.keyword}`}
                          <br />
                        </span>
                      ))}
                    </td>
                    <td align="center">
                      <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
                    </td>
                    <td align="center">
                      <span className="nowrap">{item.volume.toLocaleString()}</span>
                    </td>
                    <td align="center">
                      <span className="nowrap">
                        {item.campaignResult && item.campaignResult.converted ? item.campaignResult.converted : ''}
                      </span>
                    </td>
                    <td>
                      {item.cpa > 0 && item.hasSignature && (
                      <TableDisplayColumn>
                        <span>{this.getStatusSignatureLabel(item.statusLabelSignature)}</span>
                        {item.statusLabelSignature === 'INVALIDATION' && (
                        <ButtonSignatureValidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'VALIDATION' ? 'null' : 'VALIDATION' },
                            );
                          }}
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        >
                          {item.statusSignature === 'VALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Validar
                        </ButtonSignatureValidate>
                        )}
                      </TableDisplayColumn>
                      )}
                      {item.cpa > 0 && !item.hasSignature && (
                      <TableDisplayColumn>
                        <ButtonSignatureValidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'VALIDATION' ? 'null' : 'VALIDATION' },
                            );
                          }}
                          style={{
                            paddingBottom: 5,
                          }}
                        >
                          {item.statusSignature === 'VALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Validar
                        </ButtonSignatureValidate>
                        <ButtonSignatureInvalidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'INVALIDATION' ? 'null' : 'INVALIDATION' },
                            );
                          }}
                          style={{
                            paddingTop: 5,
                          }}
                        >
                          {item.statusSignature === 'INVALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Invalidar
                        </ButtonSignatureInvalidate>
                      </TableDisplayColumn>
                      )}
                    </td>
                    <td align="center">
                      <TableDisplayRow>
                        {item.dailyDistribution && (
                        <>
                          <Button
                            icon="FaCalendarAlt"
                            noLabel
                            onClick={() => {
                              this.handleOpenModalSchedule(item);
                            }}
                            data-tip
                            data-for={`btn-schedule-${item.uuid}`}
                          />
                          <ReactTooltip
                            className="tooltip-report"
                            effect="solid"
                            id={`btn-schedule-${item.uuid}`}
                            aria-haspopup="true"
                          >
                            Cronograma de Disparos
                          </ReactTooltip>
                        </>
                        )}
                        <Button
                          icon="FaChartLine"
                          noLabel
                          onClick={() => {
                            this.handleOpenModalResult(
                              grid.uuid,
                              item.campaign.uuid,
                              grid.gridPeriod.uuid,
                            );
                          }}
                          data-tip
                          data-for={`btn-result-${item.uuid}`}
                        />
                        <ReactTooltip
                          className="tooltip-report"
                          effect="solid"
                          id={`btn-result-${item.uuid}`}
                          aria-haspopup="true"
                        >
                          Relatório
                        </ReactTooltip>
                      </TableDisplayRow>
                    </td>
                    <td>
                      <ActionContainer>
                        <Button
                          icon="FaInfo"
                          noLabel
                          onClick={() => {
                            const productOffer = item.campaign.productOffers[0];
                            if (productOffer && productOffer.product) {
                              showDetailProductSetData(productOffer.product);
                            }
                          }}
                        />
                      </ActionContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </BoxTableContent>
          {this.handleHasSignatureToChange(grid.uuid) && (
          <BoxTableFooter>
            <Button
              onClick={() => {
                this.handleConfirmSignature(grid.uuid);
              }}
              title="Confirmar validação"
              style={{
                height: 35,
              }}
            />
          </BoxTableFooter>
          )}
        </BoxTable>
      </Row>
    ) : null;
  };

  renderBoxBonificadas = (grid) => {
    const { showDetailProductSetData } = this.props;
    return grid && grid.gridItems.length > 0 ? (
      <Row>
        <BoxTable>
          <BoxTableHeader>
            <BoxTableHeaderTitle>Bonificadas</BoxTableHeaderTitle>
          </BoxTableHeader>
          <BoxTableContent>
            <Table>
              <thead>
                <tr>
                  <th>PARCEIRO</th>
                  <th>SENDER</th>
                  <th>NOME</th>
                  <th>KW</th>
                  <th align="center" width="1%">VOLUME</th>
                  <th align="center" width="1%">AÇÕES</th>
                  <th align="center" width="1%">INDICADORES</th>
                </tr>
              </thead>
              <tbody>
                {grid.gridItems.map(item => (
                  <tr key={item.uuid}>
                    <td>
                      <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                    </td>
                    <td>
                      <span>{item.sender}</span>
                    </td>
                    <td>
                      <span>{item.campaign.name}</span>
                    </td>
                    <td>
                      {item.campaign.productOffers.map(po => (
                        <span key={po.CampaignProductOffer.uuid}>
                          {`${po.CampaignProductOffer.keyword}`}
                          <br />
                        </span>
                      ))}
                    </td>
                    <td align="center">
                      <span className="nowrap">{item.volume.toLocaleString()}</span>
                    </td>
                    <td align="center">
                      <TableDisplayRow>
                        {item.dailyDistribution && (
                        <>
                          <Button
                            icon="FaCalendarAlt"
                            noLabel
                            onClick={() => {
                              this.handleOpenModalSchedule(item);
                            }}
                            data-tip
                            data-for={`btn-schedule-${item.uuid}`}
                          />
                          <ReactTooltip
                            className="tooltip-report"
                            effect="solid"
                            id={`btn-schedule-${item.uuid}`}
                            aria-haspopup="true"
                          >
                            Cronograma de Disparos
                          </ReactTooltip>
                        </>
                        )}
                        <Button
                          icon="FaChartLine"
                          noLabel
                          onClick={() => {
                            this.handleOpenModalResult(
                              grid.uuid,
                              item.campaign.uuid,
                              grid.gridPeriod.uuid,
                            );
                          }}
                          data-tip
                          data-for={`btn-result-${item.uuid}`}
                        />
                        <ReactTooltip
                          className="tooltip-report"
                          effect="solid"
                          id={`btn-result-${item.uuid}`}
                          aria-haspopup="true"
                        >
                          Relatório
                        </ReactTooltip>
                      </TableDisplayRow>
                    </td>
                    <td>
                      <ActionContainer>
                        <Button
                          icon="FaInfo"
                          noLabel
                          onClick={() => {
                            const productOffer = item.campaign.productOffers[0];
                            if (productOffer && productOffer.product) {
                              showDetailProductSetData(productOffer.product);
                            }
                          }}
                        />
                      </ActionContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </BoxTableContent>
        </BoxTable>
      </Row>
    ) : null;
  };

  renderBoxLeilao = (grid) => {
    const { showDetailProductSetData } = this.props;
    return grid && grid.gridItems.length > 0 ? (
      <Row>
        <BoxTable>
          <BoxTableHeader>
            <BoxTableHeaderTitle>Leilão</BoxTableHeaderTitle>
            <ButtonSignatureValidate
              onClick={() => {
                this.handleChangeSignature(
                  grid.uuid,
                  null,
                  { statusSignature: 'VALIDATION' },
                );
              }}
            >
              <FaRegCheckSquare />
              Validar todos
            </ButtonSignatureValidate>
          </BoxTableHeader>
          <BoxTableContent>
            <Table>
              <thead>
                <tr>
                  <th>POSIÇÃO</th>
                  <th>PARCEIRO</th>
                  <th>SENDER</th>
                  <th>NOME</th>
                  <th>KW</th>
                  {/* <th align="center" width="1%">eCPM</th> */}
                  <th align="center" width="1%">Budget</th>
                  <th align="center" width="1%">CPA</th>
                  <th align="center" width="1%">VOLUME</th>
                  <th align="center" width="1%">FATURADO</th>
                  <th align="center" width="1%">ASSINATURA</th>
                  <th align="center" width="1%" />
                  <th align="center" width="1%">AÇÕES</th>
                  <th align="center" width="1%">INDICADORES</th>
                </tr>
              </thead>
              <tbody>
                {grid.gridItems.map(item => (
                  <tr key={item.uuid}>
                    <td>
                      <span>{item.ranking}</span>
                    </td>
                    <td>
                      <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                    </td>
                    <td>
                      <span>{item.sender}</span>
                    </td>
                    <td>
                      <span>{item.campaign.name}</span>
                    </td>
                    <td>
                      {item.campaign.productOffers.map(po => (
                        <span key={po.CampaignProductOffer.uuid}>
                          {`${po.CampaignProductOffer.keyword}`}
                          <br />
                        </span>
                      ))}
                    </td>
                    {/*
                    <td align="center">
                      <span className="nowrap">{item.ecpm.replace('.', ',')}</span>
                    </td>
                  */}
                    <td align="center">
                      <span className="nowrap">{getBudgetLabelString(item.budget)}</span>
                    </td>
                    <td align="center">
                      <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
                    </td>
                    <td align="center">
                      <span className="nowrap">{item.volume.toLocaleString()}</span>
                    </td>
                    <td align="center">
                      <span className="nowrap">
                        {
                        `R$ ${
                          (
                            item.campaignResult && item.campaignResult.converted
                              ? (item.cpa * item.campaignResult.converted)
                              : 0
                          ).toLocaleString()
                        }`
                      }
                      </span>
                    </td>
                    <td align="center">
                      <span className="nowrap">
                        {item.campaignResult && item.campaignResult.converted ? item.campaignResult.converted : ''}
                      </span>
                    </td>
                    <td>
                      {item.cpa > 0 && item.hasSignature && (
                      <TableDisplayColumn>
                        <span>{this.getStatusSignatureLabel(item.statusLabelSignature)}</span>
                        {item.statusLabelSignature === 'INVALIDATION' && (
                        <ButtonSignatureValidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'VALIDATION' ? 'null' : 'VALIDATION' },
                            );
                          }}
                          style={{
                            width: '100%',
                            justifyContent: 'center',
                          }}
                        >
                          {item.statusSignature === 'VALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Validar
                        </ButtonSignatureValidate>
                        )}
                      </TableDisplayColumn>
                      )}
                      {item.cpa > 0 && !item.hasSignature && (
                      <TableDisplayColumn>
                        <ButtonSignatureValidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'VALIDATION' ? 'null' : 'VALIDATION' },
                            );
                          }}
                          style={{
                            paddingBottom: 5,
                          }}
                        >
                          {item.statusSignature === 'VALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Validar
                        </ButtonSignatureValidate>
                        <ButtonSignatureInvalidate
                          onClick={() => {
                            this.handleChangeSignature(
                              grid.uuid,
                              item.uuid,
                              { statusSignature: item.statusSignature === 'INVALIDATION' ? 'null' : 'INVALIDATION' },
                            );
                          }}
                          style={{
                            paddingTop: 5,
                          }}
                        >
                          {item.statusSignature === 'INVALIDATION' ? <FaRegCheckSquare /> : <FaRegSquare />}
                          Invalidar
                        </ButtonSignatureInvalidate>
                      </TableDisplayColumn>
                      )}
                    </td>
                    <td align="center">
                      <TableDisplayRow>
                        {item.dailyDistribution && (
                        <>
                          <Button
                            icon="FaCalendarAlt"
                            noLabel
                            onClick={() => {
                              this.handleOpenModalSchedule(item);
                            }}
                            data-tip
                            data-for={`btn-schedule-${item.uuid}`}
                          />
                          <ReactTooltip
                            className="tooltip-report"
                            effect="solid"
                            id={`btn-schedule-${item.uuid}`}
                            aria-haspopup="true"
                          >
                            Cronograma de Disparos
                          </ReactTooltip>
                        </>
                        )}
                        <Button
                          icon="FaChartLine"
                          noLabel
                          onClick={() => {
                            this.handleOpenModalResult(
                              grid.uuid,
                              item.campaign.uuid,
                              grid.gridPeriod.uuid,
                            );
                          }}
                          data-tip
                          data-for={`btn-result-${item.uuid}`}
                        />
                        <ReactTooltip
                          className="tooltip-report"
                          effect="solid"
                          id={`btn-result-${item.uuid}`}
                          aria-haspopup="true"
                        >
                          Relatório
                        </ReactTooltip>
                      </TableDisplayRow>
                    </td>
                    <td>
                      <ActionContainer>
                        <Button
                          icon="FaInfo"
                          noLabel
                          onClick={() => {
                            const productOffer = item.campaign.productOffers[0];
                            if (productOffer && productOffer.product) {
                              showDetailProductSetData(productOffer.product);
                            }
                          }}
                        />
                      </ActionContainer>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </BoxTableContent>
          {this.handleHasSignatureToChange(grid.uuid) && (
          <BoxTableFooter>
            <Button
              onClick={() => {
                this.handleConfirmSignature(grid.uuid);
              }}
              title="Confirmar validação"
              style={{
                height: 35,
              }}
            />
          </BoxTableFooter>
          )}
        </BoxTable>
      </Row>
    ) : null;
  };

  renderContent = () => {
    const {
      gridPeriod,
    } = this.props;
    const hasDataCpaFixo = gridPeriod.find(item => item.campaignType.name === 'FIXED_CPA');
    const hasDataTeste = gridPeriod.find(item => item.campaignType.name === 'PROBE');
    const hasDataBonificada = gridPeriod.find(item => item.campaignType.name === 'BONUS');
    const hasDataLeilao = gridPeriod.find(item => item.campaignType.name === 'AUCTION');
    return (
      <>
        {this.renderBoxCPA(hasDataCpaFixo)}
        {this.renderBoxTeste(hasDataTeste)}
        {this.renderBoxBonificadas(hasDataBonificada)}
        {this.renderBoxLeilao(hasDataLeilao)}
        {
          (!hasDataCpaFixo || hasDataCpaFixo.gridItems.length === 0)
          && (!hasDataTeste || hasDataTeste.gridItems.length === 0)
          && (!hasDataBonificada || hasDataBonificada.gridItems.length === 0)
          && (!hasDataLeilao || hasDataLeilao.gridItems.length === 0)
          && (
            <Row style={{ justifyContent: 'center' }}>
              <span className="empty-campaign">
                Não foram localizados dados de campanhas
              </span>
            </Row>
          )
        }
      </>
    );
  };

  renderSignatureConfirm = () => {
    const { signatureInvalid } = this.state;
    return !!signatureInvalid && (
      <Modal width={300}>
        <Row>
          <h1>Invalidar campanha?</h1>
        </Row>
        <Row>
          <b>{`- ${signatureInvalid.campaign.name}`}</b>
        </Row>
        <Row>
          <FormGroup>
            <label>
              Alguma observação
            </label>
            <Textarea
              placeholder="Digite a observação"
              rows="4"
              maxLength="200"
              value={signatureInvalid.signatureObservation}
              onChange={(e) => {
                this.setState({
                  signatureInvalid: {
                    ...signatureInvalid,
                    signatureObservation: e.target.value,
                  },
                });
              }}
            />
          </FormGroup>
        </Row>
        <RowFooter>
          <Button
            bordered
            title="Cancelar"
            onClick={() => {
              this.handleChangeSignature(
                signatureInvalid.gridUuid,
                signatureInvalid.uuid,
                { statusSignature: 'null' },
              );
              this.setState({
                signatureInvalid: null,
              });
              setTimeout(() => {
                this.handleConfirmSignature(signatureInvalid.gridUuid);
              }, 300);
            }}
            type="button"
            style={{
              margin: 0,
            }}
          />
          <Button
            title="Confirmar"
            onClick={() => {
              this.handleChangeSignature(
                signatureInvalid.gridUuid,
                signatureInvalid.uuid,
                {
                  signatureConfirmed: true,
                  signatureObservation: signatureInvalid.signatureObservation,
                },
              );
              this.setState({
                signatureInvalid: null,
              });
              setTimeout(() => {
                this.handleConfirmSignature(signatureInvalid.gridUuid);
              }, 300);
            }}
            type="button"
            style={{
              margin: 0,
            }}
          />
        </RowFooter>
      </Modal>
    );
  }

  render() {
    const { modalResultOpen, campaignResult, dailyDistribution } = this.state;
    return (
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>RESUMO DE FECHAMENTO SEMANAL</h1>
        </Row>
        {this.renderHeader()}
        {this.renderContent()}
        <ModalResults
          visible={modalResultOpen}
          result={campaignResult}
          onClose={() => {
            this.setState({ modalResultOpen: false });
          }}
        />
        <ModalSchedule
          dailyDistribution={dailyDistribution}
          onClose={() => this.setState({ dailyDistribution: null })}
        />
        {this.renderSignatureConfirm()}
        <ModalDetailProduct />
      </Container>
    );
  }
}

ReportGridPreriodList.defaultProps = {
  gridPeriod: [],
};

ReportGridPreriodList.propTypes = {
  gridPeriod: PropTypes.arrayOf(PropTypes.shape()),
  channelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  periodList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gridPeriodFilter: PropTypes.shape().isRequired,
  reportGridPeriodSetup: PropTypes.func.isRequired,
  reportGridPeriodRequest: PropTypes.func.isRequired,
  reportGridPeriodRequestSuccess: PropTypes.func.isRequired,
  reportGridPeriodChannelRequestSuccess: PropTypes.func.isRequired,
  reportGridPeriodPeriodRequestSuccess: PropTypes.func.isRequired,
  reportGridPeriodSetFilter: PropTypes.func.isRequired,
  reportGridConfirmSignature: PropTypes.func.isRequired,
  // report
  reportGridPeriodLoadReportData: PropTypes.func.isRequired,
  showDetailProductSetData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  channelList: state.report.channelList,
  periodList: state.report.periodList,
  gridPeriodFilter: state.report.gridPeriodFilter,
  gridPeriod: state.report.gridPeriod,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    // report actions
    reportGridPeriodSetup: ReportActions.reportGridPeriodSetup,
    reportGridPeriodRequest: ReportActions.reportGridPeriodRequest,
    reportGridPeriodRequestSuccess: ReportActions.reportGridPeriodRequestSuccess,
    reportGridPeriodChannelRequestSuccess: ReportActions.reportGridPeriodChannelRequestSuccess,
    reportGridPeriodPeriodRequestSuccess: ReportActions.reportGridPeriodPeriodRequestSuccess,
    reportGridPeriodSetFilter: ReportActions.reportGridPeriodSetFilter,
    reportGridConfirmSignature: ReportActions.reportGridConfirmSignature,
    // report
    reportGridPeriodLoadReportData: ReportActions.reportGridPeriodLoadReportData,
    showDetailProductSetData: ProductActions.showDetailProductSetData,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportGridPreriodList);
