import { call, put } from 'redux-saga/effects';
import CredentialsService from '~/services/CredentialsService';
import { Creators as CredentialsActions } from '~/redux/ducks/credentials';

export function* getCredentials() {
  try {
    const clientsJson = yield call(CredentialsService.getClientsJson);
    const { hostname } = window.location;
    const client = clientsJson.find(c => c.domain === hostname);
    document.title = client.title_url || 'Platform - Akross';
    localStorage.setItem('api_url', client.api_url);
    yield put(CredentialsActions.getCredentialsSuccess(client));
  } catch (e) {
    console.error(e);
  }
}
