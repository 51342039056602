import React from 'react';
import PropTypes from 'prop-types';
import { Container } from './styles';
import { getFormattedValueByType } from '~/Utils';

const Table = ({ data, channelType, className }) => {
  const total = data && data.length > 0 ? data.reduce((result, item) => ({
    delivered: result.delivered + item.delivered,
    impressions: result.impressions + item.impressions,
    impact: result.impact + item.impact,
    clicks: result.clicks + item.clicks,
    ctrPercent: 0,
    uniqueUsers: result.uniqueUsers + item.uniqueUsers,
    benefits: {
      pre: result.benefits.pre + item.benefits.pre,
      pos: result.benefits.pos + item.benefits.pos,
    },
  }), {
    delivered: 0,
    impressions: 0,
    impact: 0,
    clicks: 0,
    ctrPercent: 0,
    uniqueUsers: 0,
    benefits: {
      pre: 0,
      pos: 0,
    },
  }) : null;
  return (
    <Container channelType={channelType} className={className} showScroll={data && data.length > 7}>
      <thead>

        <tr className="table-header">
          <th>Data</th>
          <th>{channelType === 'PUSH' ? 'Enviado com sucesso' : 'Entregue'}</th>
          {channelType === 'PULL' && (<th>Impressão</th>)}
          {channelType === 'PULL' && (<th>Impacto</th>)}
          <th>Cliques</th>
          <th>CTR</th>
          {channelType === 'PUSH' && (<th>Dismiss</th>)}
          <th>
            Benefícios concedidos
            <div className="column-header-beneficio-concedidos">
              <div>Antecipado</div>
              <div>|</div>
              <div>Final</div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {
          data.length > 0
          && data.map(item => (
            <tr key={item.date} className="table-body">
              <td>{item.date}</td>
              <td>{getFormattedValueByType('decimal', item.delivered)}</td>
              {channelType === 'PULL' && (<td>{getFormattedValueByType('decimal', item.impressions)}</td>)}
              {channelType === 'PULL' && (<td>{getFormattedValueByType('decimal', item.impact)}</td>)}
              <td>{getFormattedValueByType('decimal', item.clicks)}</td>
              <td>{`${getFormattedValueByType('decimal', item.ctrPercent)}%`}</td>
              {channelType === 'PUSH' && (<td>{getFormattedValueByType('decimal', item.dismiss)}</td>)}
              <td>
                <div className="column-item-beneficio-concedidos">
                  <div>{getFormattedValueByType('decimal', item.benefits.pre)}</div>
                  <div>|</div>
                  <div>{getFormattedValueByType('decimal', item.benefits.pos)}</div>
                </div>
              </td>
            </tr>
          ))
        }
        {total && (
          <tr className="table-body total">
            <td>TOTAL</td>
            <td>{getFormattedValueByType('decimal', total.delivered)}</td>
            {channelType === 'PULL' && (<td>{getFormattedValueByType('decimal', total.impressions)}</td>)}
            {channelType === 'PULL' && (<td>{getFormattedValueByType('decimal', total.impact)}</td>)}
            <td>{getFormattedValueByType('decimal', total.clicks)}</td>
            <td>-</td>
            {channelType === 'PUSH' && (<td>{getFormattedValueByType('decimal', total.dismiss)}</td>)}
            <td>
              <div className="column-item-beneficio-concedidos">
                <div>{getFormattedValueByType('decimal', total.benefits.pre)}</div>
                <div>|</div>
                <div>{getFormattedValueByType('decimal', total.benefits.pos)}</div>
              </div>
            </td>
          </tr>
        )}
      </tbody>


    </Container>
  );
};
Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  className: PropTypes.string,
  channelType: PropTypes.string,
};

Table.defaultProps = {
  data: [],
  className: null,
  channelType: '',
};


export default Table;
