import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

import lodash from 'lodash';

/* Types & Creators */
export const { Types, Creators } = createActions({
  listActions: null,
  listActionsSuccess: ['actions'],
  listActionTypes: null,
  listActionTypesSuccess: ['actionTypes'],
  listEventTypes: null,
  listEventTypesSuccess: ['eventTypes'],
  listBehaviors: null,
  listBehaviorsSuccess: ['behaviors'],
  listChannelsCampaign: null,
  listChannelsCampaignSuccess: ['channels'],
  onNewBehaviorModal: null,
  onNewBehaviorModalSuccess: null,
  closeNewBehaviorModal: null,
  setTypeSelectedNewBehaviorModal: ['typeSelected'],
  onUpdateBehaviorNewBehaviorModal: ['behavior'],
  onCreateBehaviorNewBehaviorModal: null,
  onCreateBehaviorNewBehaviorModalInvalidForm: null,
  onCreateBehaviorNewBehaviorModalSuccess: null,
  onUpdateFilterNewBehaviorModal: ['filtersCampaign'],
  listCampaignByFiltersNewBehaviorModal: ['filters'],
  listCampaignByFiltersNewBehaviorModalSuccess: ['campaigns'],
  openCrudBehavior: ['behaviorUuid'],
  openCrudBehaviorSuccess: ['data'],
  onUpdateBehaviorCrud: ['behavior'],
  onSaveBehaviorCrud: null,
  onDeleteBehavior: ['behaviorUuid'],
  onUpdateConfigCrud: ['config'],
  setItemSelectedConfigCrud: ['itemSelectedConfig'],
  addCategoryItemSelectedConfigCrud: ['category'],
  removeCategoryItemSelectedConfigCrud: ['category'],
  changePageBehaviors: ['page'],
  onChangeFilter: ['filter'],
  onCleanFilter: null,
  onSearchByFilter: null,
  cleanCrudBehavior: null,
});

export const BehaviorsTypes = Types;
export default Creators;

const INIT_FILTERS_CAMPAIGN = {
  name: null,
  channel: null,
  creative: 'questions',
};

const INIT_NEW_BEHAVIOR_MODAL = {
  show: false,
  behavior: null,
  invalidForm: false,
  filtersCampaign: INIT_FILTERS_CAMPAIGN,
  campaigns: [],
};

const INIT_CRUD_STATE = {
  behavior: null,
  config: null,
  itemSelectedConfig: null,
};

export const INIT_FILTER = {
  name: '',
  status: null,
};

/* Initial State */
const INITIAL_STATE = Immutable({
  actions: [],
  actionTypes: [],
  eventTypes: [],
  behaviors: [],
  filter: INIT_FILTER,
  paginate: { page: 0, count: 0 },
  channels: [],
  newBehaviorModal: INIT_NEW_BEHAVIOR_MODAL,
  crud: INIT_CRUD_STATE,
});

/* Reducers */
const listActionsSuccess = (state, { actions }) => ({ ...state, actions });
const listActionTypesSuccess = (state, { actionTypes }) => ({ ...state, actionTypes });
const listEventTypesSuccess = (state, { eventTypes }) => ({ ...state, eventTypes });

const listBehaviorsSuccess = (state, { behaviors }) => {
  const behaviorsPaginate = lodash.chunk(behaviors, 10);
  return {
    ...state,
    behaviorsPaginate,
    behaviors: behaviorsPaginate[0],
    paginate: {
      page: 0,
      count: behaviorsPaginate.length,
    },
  };
};

const changePageBehaviors = (state, { page }) => ({
  ...state,
  behaviors: state.behaviorsPaginate[page],
  paginate: {
    ...state.paginate,
    page,
  },
});

const listChannelsCampaignSuccess = (state, { channels }) => ({ ...state, channels });
const onNewBehaviorModalSuccess = state => ({
  ...state,
  newBehaviorModal: {
    ...state.newBehaviorModal,
    show: true,
    behavior: {
      name: '',
      description: '',
      eventTypeUuid: null,
      actions: [],
      properties: {},
      startDate: null,
      endDate: null,
    },
  },
});
const closeNewBehaviorModal = state => ({
  ...state, newBehaviorModal: INIT_NEW_BEHAVIOR_MODAL,
});

const setTypeSelectedNewBehaviorModal = (state, { typeSelected }) => {
  if (!typeSelected) {
    return {
      ...state,
      newBehaviorModal: {
        ...state.newBehaviorModal,
        typeSelected: null,
        behavior: null,
        invalidForm: false,
        filtersCampaign: INIT_FILTERS_CAMPAIGN,
        campaigns: [],
      },
    };
  }

  let filtersCampaign = { ...state.newBehaviorModal.filtersCampaign };
  if (typeSelected.properties && typeSelected.properties.campaignFilter) {
    filtersCampaign = { ...filtersCampaign, ...typeSelected.properties.campaignFilter };
  }

  return {
    ...state,
    newBehaviorModal: {
      ...state.newBehaviorModal,
      typeSelected,
      behavior: {
        ...state.newBehaviorModal.behavior,
        eventTypeUuid: typeSelected ? typeSelected.uuid : null,
      },
      filtersCampaign,
    },
  };
};

const onUpdateBehaviorNewBehaviorModal = (state, { behavior }) => ({
  ...state,
  newBehaviorModal: {
    ...state.newBehaviorModal,
    behavior,
  },
});

const listCampaignByFiltersNewBehaviorModalSuccess = (state, { campaigns }) => ({
  ...state,
  newBehaviorModal: {
    ...state.newBehaviorModal,
    campaigns,
  },
});
const onUpdateFilterNewBehaviorModal = (state, { filtersCampaign }) => ({
  ...state,
  newBehaviorModal: {
    ...state.newBehaviorModal,
    filtersCampaign,
  },
});

const onCreateBehaviorNewBehaviorModalInvalidForm = state => ({
  ...state,
  newBehaviorModal: {
    ...state.newBehaviorModal,
    invalidForm: true,
  },
});

const onCreateBehaviorNewBehaviorModalSuccess = state => ({
  ...state,
  newBehaviorModal: INIT_NEW_BEHAVIOR_MODAL,
});

const openCrudBehaviorSuccess = (state, { data: { behavior, config } }) => ({
  ...state,
  crud: {
    ...state.crud,
    behavior,
    config,
  },
});

const cleanCrudBehavior = state => ({
  ...state,
  crud: INIT_CRUD_STATE,
});

const onUpdateBehaviorCrud = (state, { behavior }) => ({
  ...state,
  crud: {
    ...state.crud,
    behavior,
  },
});

const onUpdateConfigCrud = (state, { config }) => ({
  ...state,
  crud: {
    ...state.crud,
    config,
  },
});

const setItemSelectedConfigCrud = (state, { itemSelectedConfig }) => ({
  ...state,
  crud: {
    ...state.crud,
    itemSelectedConfig,
  },
});

const updateItemRecursiveConfigData = (data, item) => data.map((d) => {
  if (d.id === item.id) {
    return { ...item };
  }
  return {
    ...d,
    data: updateItemRecursiveConfigData(d.data, item),
  };
});

const addCategoryItemSelectedConfigCrud = (state, { category }) => {
  const itemSelectedConfig = {
    ...state.crud.itemSelectedConfig,
    categories: [
      ...state.crud.itemSelectedConfig.categories,
      category,
    ],
  };
  return {
    ...state,
    crud: {
      ...state.crud,
      config: {
        ...state.crud.config,
        data: updateItemRecursiveConfigData(state.crud.config.data, itemSelectedConfig),
      },
      itemSelectedConfig,
    },
  };
};

const removeCategoryItemSelectedConfigCrud = (state, { category }) => {
  const itemSelectedConfig = {
    ...state.crud.itemSelectedConfig,
    categories: [
      ...state.crud.itemSelectedConfig.categories.filter(c => c !== category),
    ],
  };
  return {
    ...state,
    crud: {
      ...state.crud,
      config: {
        ...state.crud.config,
        data: updateItemRecursiveConfigData(state.crud.config.data, itemSelectedConfig),
      },
      itemSelectedConfig,
    },
  };
};

const onChangeFilter = (state, { filter }) => ({
  ...state,
  filter,
});

export const reducer = createReducer(INITIAL_STATE, {
  [BehaviorsTypes.LIST_ACTIONS_SUCCESS]: listActionsSuccess,
  [BehaviorsTypes.LIST_ACTION_TYPES_SUCCESS]: listActionTypesSuccess,
  [BehaviorsTypes.LIST_EVENT_TYPES_SUCCESS]: listEventTypesSuccess,
  [BehaviorsTypes.LIST_BEHAVIORS_SUCCESS]: listBehaviorsSuccess,
  [BehaviorsTypes.LIST_CHANNELS_CAMPAIGN_SUCCESS]: listChannelsCampaignSuccess,
  [BehaviorsTypes.ON_NEW_BEHAVIOR_MODAL_SUCCESS]: onNewBehaviorModalSuccess,
  [BehaviorsTypes.CLOSE_NEW_BEHAVIOR_MODAL]: closeNewBehaviorModal,
  [BehaviorsTypes.SET_TYPE_SELECTED_NEW_BEHAVIOR_MODAL]: setTypeSelectedNewBehaviorModal,
  [BehaviorsTypes.ON_UPDATE_BEHAVIOR_NEW_BEHAVIOR_MODAL]: onUpdateBehaviorNewBehaviorModal,
  [BehaviorsTypes.LIST_CAMPAIGN_BY_FILTERS_NEW_BEHAVIOR_MODAL_SUCCESS]:
    listCampaignByFiltersNewBehaviorModalSuccess,
  [BehaviorsTypes.ON_UPDATE_FILTER_NEW_BEHAVIOR_MODAL]: onUpdateFilterNewBehaviorModal,
  [BehaviorsTypes.ON_CREATE_BEHAVIOR_NEW_BEHAVIOR_MODAL_INVALID_FORM]: onCreateBehaviorNewBehaviorModalInvalidForm,
  [BehaviorsTypes.ON_CREATE_BEHAVIOR_NEW_BEHAVIOR_MODAL_SUCCESS]:
    onCreateBehaviorNewBehaviorModalSuccess,
  [BehaviorsTypes.OPEN_CRUD_BEHAVIOR_SUCCESS]: openCrudBehaviorSuccess,
  [BehaviorsTypes.ON_UPDATE_BEHAVIOR_CRUD]: onUpdateBehaviorCrud,
  [BehaviorsTypes.ON_UPDATE_CONFIG_CRUD]: onUpdateConfigCrud,
  [BehaviorsTypes.SET_ITEM_SELECTED_CONFIG_CRUD]: setItemSelectedConfigCrud,
  [BehaviorsTypes.ADD_CATEGORY_ITEM_SELECTED_CONFIG_CRUD]: addCategoryItemSelectedConfigCrud,
  [BehaviorsTypes.REMOVE_CATEGORY_ITEM_SELECTED_CONFIG_CRUD]: removeCategoryItemSelectedConfigCrud,
  [BehaviorsTypes.CHANGE_PAGE_BEHAVIORS]: changePageBehaviors,
  [BehaviorsTypes.ON_CHANGE_FILTER]: onChangeFilter,
  [BehaviorsTypes.CLEAN_CRUD_BEHAVIOR]: cleanCrudBehavior,
});
