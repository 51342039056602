import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import {
  ContainerStyled, TableStyled, RowStyled, InputStyled,
} from '~/Components/Styled';

import { hex2rgba } from '~/Utils';


export const Container = styled(ContainerStyled)`
  //h1{
  //  margin: 0 0 30px 0;
  //}
`;
export const Table = styled(TableStyled)`
  .empty-table-desc{
  margin-top: 10px;
    width: 100%;
    text-align: center;
    //border: solid 1px ${props => props.theme.colors.primary};
    background-color: ${props => hex2rgba(props.theme.colors.primary, 0.05)};
    border-radius: 2px;
    span{

      font-weight: 800;
      font-size: 18px;
      color:${props => props.theme.colors.primary};
    }

    padding: 10px;
  }
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const Input = styled(InputStyled)`
  height: 36px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.light};
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  margin-right: 15px;
  &::placeholder {
    color: ${props => props.theme.colors.light};
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  flex: 1;
  display: flex;
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
`;

export const TitleItemExclude = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

export const BaseCost = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
`;

export const Cost = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 25px;
    margin-right: 10px;
    opacity: 0.5;
  }

  .desc-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      font-size: 16px;
      margin-bottom: 3px;
      font-weight: bold;
    }
    span {
      font-size: 14px;
    }
  }

  &:nth-child(2) {
    padding: 0 10px;
    margin: 0 10px;
    border-right: solid 1px ${props => props.theme.colors.light};
    border-left: solid 1px ${props => props.theme.colors.light};
  }

  /* border-radius: 2px; */
`;

export const LinkButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border: 1px solid;
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 0;
  padding-left: 10px;
  padding-right: 10px;
  ${props => props.disabled && css`
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  `};
`;

export const ButtonGroup = styled.div`
  display: flex;
  > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      margin: 0;
    }
  }
`;
