import api from './axios';

/* Em produção, é pra chamar o endpoint direto, sem passar pela API Gateway, pra evitar problema de Timeout */
const { hostname } = window.location;
let urlBase = '';
if (hostname === 'admin.oiads.com.br' || hostname === 'artemis.mobicare.com.br' || hostname === 'gdcp-claro-free.akross.com.br') {
  urlBase = 'https://artemis-apigw.mobicare.com.br/';
}

const reportDashboard = async (campaignUuid) => {
  try {
    const { data } = await api.get(
      `${urlBase}mcare-artemis-report-service/dashboard/${campaignUuid}`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  reportDashboard,
};
