import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTheme } from 'styled-components';
import { Creators as HistoryCampaignActions } from '~/redux/ducks/historyCampaign';


import Icone from '~/Components/Icone';
import Modal from '~/Components/Modal';
import { general } from '~/Styles';

import {
  Row, LogBody, LogContainer, CloseModal,
} from './styles';

function HistoryModal({
  show, data, close, theme,
}) {
  if (!show || !data) {
    return null;
  }

  return (
    <Modal width={600} scroll={false}>
      <Row style={{ marginBottom: 30 }}>
        <h1>
          <img style={{ width: 30, marginRight: 15 }} src={general.image['icon-log.png']} alt="log imagem" />
          Histórico de Status
        </h1>
        <CloseModal src={general.image['delete-regular.png']} alt="fechar" onClick={() => close()} />
      </Row>
      <LogBody>
        {data.map(history => (
          <LogContainer key={history.id}>
            <div className="left">
              <div className="icon-circle">
                {/* <IconStatus status={history.status} /> */}
                <Icone
                  icon={history.newStatus.icon}
                  color={theme.colors.white}
                  size="25"
                />
              </div>
              <div className="line" />
            </div>
            <div className="right">
              <div className="status">
                <h2>{history.newStatus.label}</h2>
              </div>
              <div className="content-log">
                <Row className="content-log-info">
                  <div className="col" style={{ flex: 1 }}>
                    <span className="label">Data</span>
                    <span className="desc-bold">
                      {moment(history.createdAt).format('DD/MM/YYYY')}
                    </span>
                  </div>
                  <div className="col" style={{ flex: 1 }}>
                    <span className="label">Hora</span>
                    <span className="desc-bold">
                      {moment(history.createdAt).format('HH:mm:ss')}
                    </span>
                  </div>
                  <div className="col" style={{ flex: 2 }}>
                    <span className="label">Usuário</span>
                    <span className="desc-bold">{history.user.name || 'Sistema'}</span>
                  </div>
                </Row>
                <div className="col content-log-justify">
                  <span className="label">Observações</span>
                  <span className="desc">{history.observation || 'Sem comentário.'}</span>
                </div>
              </div>
            </div>
          </LogContainer>
        ))}
      </LogBody>
    </Modal>
  );
}

HistoryModal.defaultProps = {
  show: false,
  data: null,
};

HistoryModal.propTypes = {
  show: PropTypes.bool,
  data: PropTypes.shape(),
  close: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
};
const mapStateToProps = state => ({
  show: state.historyCampaign.show,
  data: state.historyCampaign.data,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  close: HistoryCampaignActions.close,
}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(HistoryModal));
