/* eslint-disable quotes */
/* eslint-disable comma-dangle */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
import styled, { css } from "styled-components";
import ReactSelect from "react-select";
import {
  ContainerStyled,
  TableStyled,
  RowStyled,
  InputStyled,
  FormGroupStyled
} from "~/Components/Styled";
import { hex2rgba } from "~/Utils";

export const Container = styled(ContainerStyled)``;

export const Table = styled(TableStyled)`
  margin-top: 10px;
  .empty-table-desc {
    margin-top: 10px;
    width: 100%;
    text-align: center;
    background-color: ${props => hex2rgba(props.theme.colors.primary, 0.05)};
    border-radius: 2px;
    span {
      font-weight: 800;
      font-size: 18px;
      color: ${props => props.theme.colors.primary};
    }
    padding: 10px;
  }
  .nowrap {
    white-space: nowrap;
  }
  .ordination {
    display: flex;
    flex-direction: column;
    height: 100%;
    button {
      display: flex;
      flex: 1;
      justify-content: center;
      align-items: center;
      border: 0;
      background-color: transparent;
      padding: 5px 0;
      img {
        height: 10px;
        object-fit: contain;
      }
      &:hover {
        img {
          height: 13px;
        }
      }
    }
  }
`;

export const TableSetupHeader = styled(Table)`
  span {
    font-weight: normal !important;
    font-size: 25px !important;
    color: ${props => props.theme.colors.primary};
  }
  tr > td {
    padding: 5px !important;
  }
`;

export const TableButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;

  .ad-table {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 2px;
    padding: 20px;
    width: 100%;
  }
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .box-activate-edition {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    padding: 10px 0;
    border-bottom: solid 2px ${props => props.theme.colors.light};
  }
  .box-activate-ordination {
    display: flex;
    flex-direction: row-reverse;
    padding: 10px 0;
    border-bottom: solid 2px ${props => props.theme.colors.light};
  }
`;

export const Select = styled(ReactSelect)`
  width: 250px;
  margin-left: 10px;
  ${props =>
    props.invalid &&
    css`
      > div {
        border-color: ${props => props.theme.colors.danger2};
      }
    `}
`;

export const Input = styled(InputStyled)``;

export const FormGroup = styled(FormGroupStyled)`
  margin-right: 0;
`;
