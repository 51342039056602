import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  font-family: "Simplon BP";
  color: ${props => props.theme.colors.dark};

  li a {
    color: ${props => props.theme.colors.dark};
    font-size: 16px;
    border: solid 1px ${props => props.theme.colors.dark};
    &:hover {
      background-color: ${props => props.theme.colors.dark};
      color:${props => props.theme.colors.white};
      border: solid 1px ${props => props.theme.colors.dark};
    }
      margin-right:5px;
  }
  .active a {
    background-color: ${props => props.theme.colors.dark};
    border: solid 1px ${props => props.theme.colors.dark};
    &:hover {
      background-color: ${props => props.theme.colors.dark};
      color:${props => props.theme.colors.white};
      border: solid 1px ${props => props.theme.colors.dark};
    }
  }

  .pagination{
    margin-bottom:0;
    margin-top:30px;
  }
  .pagination > li:last-child > a, .pagination > li:last-child > span {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  .pagination > li:first-child > a, .pagination > li:first-child > span {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }

  .previous,
  .next {
    &.disabled a {
      color: ${props => props.theme.colors.light};
  }
`;
