import React from 'react';
import PropTypes from 'prop-types';


import { Container, LinkButton, Button } from './styles';

function ButtonSearch({ labelSearch, onClickSearch, onClickClean }) {
  return (
    <Container>
      <LinkButton
        onClick={onClickSearch}
      >
        {labelSearch || 'Buscar'}
      </LinkButton>
      <Button
        icon="FaTimes"
        noLabel
        onClick={onClickClean}
      />
    </Container>
  );
}

ButtonSearch.defaultProps = {
  labelSearch: null,
};

ButtonSearch.propTypes = {
  labelSearch: PropTypes.string,
  onClickSearch: PropTypes.func.isRequired,
  onClickClean: PropTypes.func.isRequired,
};

export default ButtonSearch;
