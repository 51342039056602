import { call, put } from 'redux-saga/effects';
import { Creators as CampaignIconActions } from '~/redux/ducks/campaignIcon';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import CampaignService from '~/services/CampaignService';

export function* campaignIconLoadCampaignTypes() {
  yield put(startRequest());
  try {
    const types = yield call(CampaignService.listTypes);
    yield put(requestSuccess());
    yield put(
      CampaignIconActions.campaignIconLoadCampaignTypesSuccess(types),
    );
  } catch (e) {
    yield put(requestError());
  }
}
