import React, { Component } from 'react';
import Chart from 'react-apexcharts';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FaChartPie, FaChartBar, FaBars, FaPencilAlt, FaCheck,
} from 'react-icons/fa';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import { withTheme } from 'styled-components';
import Button from '~/Components/Button';

import {
  Container,
  Loading,
  IconContainer,
  ButtonEditPreferences,
  ModalContainer,
  ModalContent,
  ModalFooter,
  FormGroup,
  Select,
  TypeButton,
} from './styles';

class AudienceChart extends Component {
  state = {
    openModalPreferences: false,
    preferences: {
      category: null,
      type: '',
    },
  };

  componentWillReceiveProps() {
    const { preferences } = this.props;
    if (preferences) {
      this.setState({
        preferences,
      });
    }
  }

  getChartIconEmpty = () => {
    const { type } = this.props;
    switch (type) {
      case 'bar': return (
        <IconContainer>
          <FaChartBar />
        </IconContainer>
      );
      case 'column': return (
        <IconContainer>
          <FaChartBar />
        </IconContainer>
      );
      case 'pie': return (
        <IconContainer>
          <FaChartPie />
        </IconContainer>
      );
      default: return null;
    }
  };

  renderBar = () => {
    const {
      data,
      maxHeight,
    } = this.props;

    const dataChart = {
      options: {
        chart: {
          id: 'chart-faixa-etaria',
          toolbar: {
            show: false,
          },
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: data.labels,
        },
      },
      series: [
        {
          name: 'series-1',
          data: data.data,
        },
      ],
    };

    return (
      <Chart
        options={dataChart.options}
        series={dataChart.series}
        type="bar"
        width="100%"
        height={`${maxHeight - 110}px`}
      />
    );
  };

  renderPie = () => {
    const {
      data,
      maxHeight,
    } = this.props;

    const dataChart = {
      chart: {
        id: 'chart-genero',
        toolbar: {
          show: false,
        },
      },
      series: data.data,
      options: {
        plotOptions: {
          pie: {
            customScale: 0.8,
          },
        },
        // fill: { colors: chartColor },
        // legend: {
        //   markers: { fillColors: chartColor },
        // },
        dataLabels: {
          enabled: false,
        },
        labels: data.labels,
        responsive: [{
          options: {
            chart: {},
            legend: {
              position: 'bottom',
            },
          },
        }],
      },
    };

    return (
      <Chart
        options={dataChart.options}
        series={dataChart.series}
        type="pie"
        width="100%"
        height={`${maxHeight - 110}px`}
      />
    );
  };

  renderColumn = () => {
    const {
      data,
      maxHeight,
    } = this.props;

    const dataChart = {
      options: {
        chart: {
          id: 'chart-faixa-etaria',
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: data.labels,
        },
      },
      series: [
        {
          name: 'series-1',
          data: data.data,
        },
      ],
    };

    return (
      <Chart
        options={dataChart.options}
        series={dataChart.series}
        type="bar"
        width="100%"
        height={`${maxHeight - 110}px`}
      />
    );
  };

  getChart = () => {
    const { type } = this.props;

    switch (type) {
      case 'bar': return this.renderBar();
      case 'pie': return this.renderPie();
      case 'column': return this.renderColumn();
      default: return null;
    }
  };

  handleSavePreferences = () => {
    const { onSavePreferences } = this.props;
    const { preferences } = this.state;
    this.setState({ openModalPreferences: false });
    onSavePreferences(preferences);
  };

  handleCancelPreferences = () => {
    const { preferences } = this.props;
    this.setState({ openModalPreferences: false, preferences });
  };

  getModalPreferences = () => {
    const { categories } = this.props;
    const { preferences } = this.state;
    const categoryList = categories.map(item => ({
      value: item.key,
      label: item.label,
    }));
    return (
      <ModalContainer>
        <ModalContent>
          <FormGroup>
            <label>
              Categoria
              <span>*</span>
            </label>
            <Select
              isDisabled={false}
              invalid={false}
              placeholder="Categoria"
              value={preferences.category
                ? categoryList.find(item => item.value === preferences.category.key)
                : null
              }
              onChange={(category) => {
                this.setState({
                  preferences: {
                    ...preferences,
                    category: {
                      key: category.value,
                      label: category.label,
                    },
                  },
                });
              }}
              options={categoryList}
            />
          </FormGroup>
          <FormGroup style={{ marginTop: 10 }}>
            <label>
              Tipo
              <span>*</span>
            </label>
            <div className="grouped-types">
              <TypeButton
                active={preferences.type === 'column'}
                onClick={() => {
                  this.setState({
                    preferences: {
                      ...preferences,
                      type: 'column',
                    },
                  });
                }}
              >
                <FaChartBar className="icon-type" />
                {preferences.type === 'column' && <FaCheck className="icon-type-check" />}
                <span>Colunas</span>
              </TypeButton>
              <TypeButton
                active={preferences.type === 'bar'}
                onClick={() => {
                  this.setState({
                    preferences: {
                      ...preferences,
                      type: 'bar',
                    },
                  });
                }}
              >
                <FaBars className="icon-type" />
                {preferences.type === 'bar' && <FaCheck className="icon-type-check" />}
                <span>Barras</span>
              </TypeButton>
              <TypeButton
                active={preferences.type === 'pie'}
                onClick={() => {
                  this.setState({
                    preferences: {
                      ...preferences,
                      type: 'pie',
                    },
                  });
                }}
              >
                <FaChartPie className="icon-type" />
                {preferences.type === 'pie' && <FaCheck className="icon-type-check" />}
                <span>Pizza</span>
              </TypeButton>
            </div>
          </FormGroup>
        </ModalContent>
        <ModalFooter>
          <Button
            disabled={false}
            onClick={this.handleCancelPreferences}
            title="Cancelar"
            bordered
          />
          <Button
            disabled={false}
            onClick={this.handleSavePreferences}
            title="Salvar"
          />
        </ModalFooter>
      </ModalContainer>
    );
  };

  render() {
    const {
      loading,
      title,
      hasData,
      maxHeight,
      preferences,
      theme,
    } = this.props;
    const { openModalPreferences } = this.state;

    return (
      <Container maxHeight={maxHeight}>
        <h2>
          {title}
          {!openModalPreferences && (
            <ButtonEditPreferences
              onClick={() => {
                this.setState({
                  preferences,
                  openModalPreferences: true,
                });
              }}
            >
              <FaPencilAlt />
            </ButtonEditPreferences>
          )}
        </h2>
        {!openModalPreferences && hasData && this.getChart()}
        {!openModalPreferences && !hasData && this.getChartIconEmpty()}
        {openModalPreferences && this.getModalPreferences()}
        {loading && (
        <Loading>
          <Loader
            type="TailSpin"
            color={theme.colors.primaryHex}
            height={70}
            width={70}
          />
        </Loading>
        )}
      </Container>
    );
  }
}

AudienceChart.defaultProps = {
  loading: false,
  data: null,
  type: 'bar',
  title: '',
  hasData: true,
  maxHeight: 0,
  preferences: null,
  onSavePreferences: () => {},
};

AudienceChart.propTypes = {
  loading: PropTypes.bool,
  data: PropTypes.shape(),
  type: PropTypes.string,
  title: PropTypes.string,
  hasData: PropTypes.bool,
  maxHeight: PropTypes.number,
  preferences: PropTypes.shape(),
  theme: PropTypes.shape().isRequired,
  onSavePreferences: PropTypes.func,
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  categories: state.audience.categories,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(AudienceChart));
