import api from './axios';

const channelRequest = async () => {
  try {
    const { data } = await api.get('auction/channel');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const channelRequestByPeriod = async (gridPeriodUuid) => {
  try {
    const { data } = await api.get(`auction/channel/${gridPeriodUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const periodRequest = async () => {
  try {
    const { data } = await api.get('auction/period');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const periodInsertRequest = async (period) => {
  try {
    const { data } = await api.post(
      'auction/period',
      period,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const periodUpdateRequest = async (period) => {
  try {
    const { data } = await api.put(
      `auction/period/${period.uuid}`,
      period,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const auctionRequest = async (gridPeriodUui, zoneUuid) => {
  try {
    const { data } = await api.get(`auction/grid/${gridPeriodUui}/${zoneUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const auctionGridUpdate = async ({
  gridUuid,
  gridItemUuid,
  campaignUuid,
  cpa,
  budget,
}) => {
  try {
    const { data } = await api.put(
      `auction/grid/${gridUuid}/${gridItemUuid}`,
      {
        campaignUuid,
        cpa,
        budget,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const auctionRankingUpdate = async (gridUuid) => {
  try {
    const { data } = await api.put(
      `auction/ranking/${gridUuid}`,
      {},
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

// eslint-disable-next-line arrow-body-style
const auctionGetTermPreview = async (gridItemUuid) => {
  try {
    const { data } = await api.get(`terms-service/insertion-request/${gridItemUuid}/preview`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  channelRequest,
  periodRequest,
  channelRequestByPeriod,
  periodInsertRequest,
  periodUpdateRequest,
  auctionRequest,
  auctionGridUpdate,
  auctionRankingUpdate,
  auctionGetTermPreview,
};
