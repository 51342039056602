import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes, FaDatabase } from 'react-icons/fa';
import { FiDatabase } from 'react-icons/fi';

import Modal from '~/Components/Modal';

import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Creators as AudienceActions } from '~/redux/ducks/audience';

import {
  Row,
  ButtonCloseModal,
  ModalContent,
} from './styles';

class ModalSelectAudienceType extends Component {
  componentDidMount() {}

  render() {
    const { navigateToRoute, onClose, audienceSetExternalData } = this.props;
    return (
      <Modal width={500}>
        <Row>
          <h1>Nova Audiência</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              onClose();
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <ModalContent>
          <button
            type="button"
            onClick={() => {
              navigateToRoute('audience/new');
              onClose();
            }}
          >
            <FaDatabase />
            <span>Audiência Interna</span>
          </button>
          <button
            type="button"
            onClick={() => {
              audienceSetExternalData({
                name: '',
                file: null,
                progress: null,
                shared: false,
              });
              onClose();
            }}
          >
            <FiDatabase />
            <span>Audiência Externa</span>
          </button>
        </ModalContent>
      </Modal>
    );
  }
}

ModalSelectAudienceType.propTypes = {
  navigateToRoute: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  audienceSetExternalData: PropTypes.func.isRequired,
};

const mapStateToProps = (/* state */) => ({
  // audiences: state.audience.audiences,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    navigateToRoute: RedirectActions.navigateToRoute,
    audienceSetExternalData: AudienceActions.audienceSetExternalData,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalSelectAudienceType);
