import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { FaTimes } from 'react-icons/fa';
import Modal from '~/Components/Modal';
import Checkbox from '~/Components/Checkbox';

import Button from '~/Components/Button';

import {
  ContentModal,
  Header,
  CloseHeader,
  Body,
  Footer,
  Row,
  FormNew,
  Table,
  Input,
  Select,
  ButtonGroup,
  LinkButton,
} from './styles';

const ModalCampaignFilter = ({ onClose, theme }) => {
  const campaigns = [1, 2, 3, 4, 5, 6].map(item => ({
    uuid: item,
    name: `Campanha 000${item}`,
    type: 'Push',
  }));

  const renderForm = () => (
    <FormNew>
      <Row style={{ marginTop: 10 }}>
        <Input
          placeholder="Procurar por nome"
          value={null}
          onChange={e => e.target.value}
        />
        <Select
          placeholder="Selecione Produto..."
          value={null}
          onChange={product => product}
          options={[]}
          isClearable
        />
        <Select
          placeholder="Selecione Canal..."
          value={null}
          onChange={product => product}
          options={[]}
          isClearable
        />
        <Select
          placeholder="Selecione Tipo..."
          value={null}
          onChange={product => product}
          options={[]}
          isClearable
        />
        <Select
          placeholder="Selecione Status..."
          value={null}
          onChange={product => product}
          options={[]}
          isClearable
        />
        <ButtonGroup>
          <LinkButton
            onClick={() => {}}
          >
            Buscar
          </LinkButton>
          <Button
            icon="FaTimes"
            noLabel
            onClick={() => {}}
          />
        </ButtonGroup>
      </Row>
      <Row>
        <Table>
          <thead>
            <tr>
              <th>NOME</th>
              <th>TIPO</th>
              <th width="1%">ADICIONAR</th>
            </tr>
          </thead>
          <tbody>
            {campaigns.length < 1 ? (
              <tr>
                <td colSpan="8">
                  <div className="empty-table-desc">
                    <span> Não foram localizados registros de campanhas</span>
                  </div>
                </td>
              </tr>
            ) : (
              campaigns.map(campaign => (
                <tr key={campaign.uuid}>
                  <td>
                    <span>
                      {campaign.name}
                    </span>
                  </td>
                  <td>
                    <span>
                      {campaign.type}
                    </span>
                  </td>
                  <td>
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: -12 }}>
                      <Checkbox
                        value
                        onChange={value => value}
                      />
                    </div>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Row>
    </FormNew>
  );

  return (
    <Modal width={1200} padding={0}>
      <ContentModal>
        <Header>
          <h1>Buscar campanha</h1>
          <CloseHeader onClick={() => {
            onClose();
          }}
          >
            <FaTimes color={theme.colors.darker} size={24} />
          </CloseHeader>
        </Header>
        <Body>
          {renderForm()}
        </Body>
        <Footer>
          <Button
            disabled={false}
            onClick={() => {}}
            title="Adicionar"
          />
        </Footer>
      </ContentModal>
    </Modal>
  );
};

ModalCampaignFilter.defaultProps = {
  onClose: () => {},
};

ModalCampaignFilter.propTypes = {
  onClose: PropTypes.func,
  theme: PropTypes.shape().isRequired,
};

export default withTheme(ModalCampaignFilter);
