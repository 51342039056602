import styled from 'styled-components';
import { hex2rgba } from '~/Utils';

export const Container = styled.div`
  /* Customize the label (the container) */

  display: block;
  position: relative;
  /* padding-left: 35px; */
  /* margin-bottom: 12px; */
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    background-color: #fff;

    width: 130px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    box-shadow: 0 0 5px ${props => hex2rgba(props.theme.colors.dark, 0.3)};

    img {
      width: 40px;
    }
    span {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 15px;
      text-align: center;
    }

    &:hover {
      cursor: pointer;
      span {
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  &:hover input ~ .checkmark {
    border: solid 4px ${props => props.theme.colors.primary};
  }
  input:checked ~ .checkmark {
    border: solid 4px ${props => props.theme.colors.primary};
    span {
      color: ${props => props.theme.colors.primary};
    }
  }
`;
