/* eslint-disable max-len */
/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import { Creators as CampaignActions } from '~/redux/ducks/campaign';
import { Creators as RedirectActions } from '~/redux/ducks/redirect';

import CampaignsBoxContent from './Components/CampaignsBoxContent';
import Breadcrumb from '~/Components/Breadcrumb';
import Button from '~/Components/Button';
import PreviewCriativo from '../Components/PreviewCriativo';

import Const from '~/Utils/Const';
import { validateUrl, xmlIsValid } from '~/Utils';

import {
  Wrap,
  Container,
  Header,
  HeaderTitle,
  HeaderButtons,
  Content,
  CampaignData,
} from './styles';

import PushNotificationTextMessageForm from './pages/PushNotificationTextMessageForm';
import PushNotificationImageMessageForm from './pages/PushNotificationImageMessageForm';
import SmartMessageTextForm from './pages/SmartMessageTextForm';
import SmartMessageTextClaroForm from './pages/SmartMessageTextClaroForm';
import SmartMessageMenuForm from './pages/SmartMessageMenuForm';
import SmartMessageMenuFormCardTriplo from './pages/SmartMessageMenuFormCardTriplo';
import SmartMessageMenuTimForm from './pages/SmartMessageMenuTimForm';
import USSDMessageTextForm from './pages/USSDMessageTextForm';
import USSDMessageTextClaroForm from './pages/USSDMessageTextClaroForm';
import VoiceMessageTextForm from './pages/VoiceMessageTextForm';
import SMSTextMessageForm from './pages/SMSTextMessageForm';
import SMSMediaMessageForm from './pages/SMSMediaMessageForm';
import MidiaNoCreditADMessageForm from './pages/MidiaNoCreditADMessageForm';
import NoCreditBannerTextForm from './pages/NoCreditBannerTextForm';
import NoDataBannerTextForm from './pages/NoDataBannerTextForm';
import MidiaWebForm from './pages/MidiaWebForm';
import SponsorNotifyTextMessageForm from './pages/SponsorNotifyTextMessageForm';
import SponsorNotifyMediaMessageForm from './pages/SponsorNotifyMediaMessageForm';
import ChallengeMessageForm from './pages/ChallengeMessageForm';
import MediaVideoForm from './pages/MediaVideoForm';
import { internalMediaComponentFullValidation } from './Components/InternalMediaComponent';

import CreativeCustomForm from './pages/CreativeCustomForm';
import RCSClaroForm from './pages/RCSClaroForm';
import CreativeName from '~/Utils/CreativeName';

class Form extends Component {
  state = {
    hasError: false,
  };

  componentDidMount() {
    const {
      match: { params },
      loadCampaignEdit,
      campaignEditCriativoRequest,
    } = this.props;
    loadCampaignEdit(params.campaignId);
    campaignEditCriativoRequest(params.campaignId);
  }

  getSmartMessageMenuConfig = () => {
    const { criativoEdit: { creativeDefinition: { schema } } } = this.props;
    // const schema = JSON.parse(creativeDefinition.schema);

    const screen1 = schema.screens.find(item => item.id === 'screen1');
    const screen2 = schema.screens.find(item => item.id === 'screen2');
    const option1 = screen2.options.find(item => item.id === 'option1');
    const option2 = screen2.options.find(item => item.id === 'option2');
    const option3 = screen2.options.find(item => item.id === 'option3');
    const screen3 = schema.screens.find(item => item.id === 'screen3');

    return {
      screen1: {
        label: screen1.label || 'Tela 1',
        maxLength: screen1.maxLength || 144,
        minLength: screen1.minLength || 0,
        required: screen1.required || false,
        placeholder: screen1.text || 'Digite seu texto aqui',
        disabled: screen1.disabled || false,
        valuePrefix: screen1.valuePrefix || '',
        valueSuffix: screen1.valueSuffix || '',
        value: screen1.value || '',
      },
      screen2: {
        label: screen2.label || 'Tela 2',
        options: {
          option1: {
            label: option1.label || 'Opção 1',
            maxLength: option1.maxLength || 22,
            minLength: option1.minLength || 0,
            required: option1.required || false,
            placeholder: option1.text || 'Digite seu texto aqui',
            disabled: option1.disabled || false,
            valuePrefix: option1.valuePrefix || '',
            valueSuffix: option1.valueSuffix || '',
            value: option1.value || '',
          },
          option2: {
            label: option2.label || 'Opção 2',
            maxLength: option2.maxLength || 22,
            minLength: option2.minLength || 0,
            required: option2.required || false,
            placeholder: option2.text || 'Digite seu texto aqui',
            disabled: option2.disabled || false,
            valuePrefix: option2.valuePrefix || '',
            valueSuffix: option2.valueSuffix || '',
            value: option2.value || '',
          },
          option3: {
            label: option3.label || 'Opção 3',
            maxLength: option3.maxLength || 22,
            minLength: option3.minLength || 0,
            required: option3.required || false,
            placeholder: option3.text || 'Digite seu texto aqui',
            disabled: option3.disabled || false,
            valuePrefix: option3.valuePrefix || '',
            valueSuffix: option3.valueSuffix || '',
            value: option3.value || '',
          },
        },
      },
      screen3: {
        label: screen3.label || 'Tela 3',
        maxLength: screen3.maxLength || 144,
        minLength: screen3.minLength || 0,
        required: screen3.required || false,
        placeholder: screen3.text || 'Digite seu texto aqui',
        disabled: screen3.disabled || false,
        valuePrefix: screen3.valuePrefix || '',
        valueSuffix: screen3.valueSuffix || '',
        value: screen3.value || '',
      },
    };
  };

  getSmartMessageMenuCardTriploConfig = () => {
    const { criativoEdit: { creativeDefinition: { schema } } } = this.props;

    const screen1 = schema.screens.find(item => item.id === 'screen1');
    const screen2 = schema.screens.find(item => item.id === 'screen2');
    const screen3 = schema.screens.find(item => item.id === 'screen3');

    return {
      screen1: {
        label: screen1.label || 'Tela 1',
        maxLength: screen1.maxLength || 144,
        minLength: screen1.minLength || 0,
        required: screen1.required || false,
        placeholder: screen1.text || 'Digite seu texto aqui',
        disabled: screen1.disabled || false,
        valuePrefix: screen1.valuePrefix || '',
        valueSuffix: screen1.valueSuffix || '',
        value: screen1.value || '',
      },
      screen2: {
        label: screen2.label || 'Tela 2',
        maxLength: screen2.maxLength || 144,
        minLength: screen2.minLength || 0,
        required: screen2.required || false,
        placeholder: screen2.text || 'Digite seu texto aqui',
        disabled: screen2.disabled || false,
        valuePrefix: screen2.valuePrefix || '',
        valueSuffix: screen2.valueSuffix || '',
        value: screen2.value || '',
      },
      screen3: {
        label: screen3.label || 'Tela 3',
        maxLength: screen3.maxLength || 144,
        minLength: screen3.minLength || 0,
        required: screen3.required || false,
        placeholder: screen3.text || 'Digite seu texto aqui',
        disabled: screen3.disabled || false,
        valuePrefix: screen3.valuePrefix || '',
        valueSuffix: screen3.valueSuffix || '',
        value: screen3.value || '',
      },
    };
  };

  getSmartMessageMenuTimConfig = () => {
    const { criativoEdit: { creativeDefinition: { schema } } } = this.props;
    // const schema = JSON.parse(creativeDefinition.schema);

    const screen1 = schema.screens.find(item => item.id === 'screen1');
    const screen2 = schema.screens.find(item => item.id === 'screen2');
    const option1 = screen2.options.find(item => item.id === 'option1');
    const option2 = screen2.options.find(item => item.id === 'option2');
    const option3 = screen2.options.find(item => item.id === 'option3');
    const screen3 = schema.screens.find(item => item.id === 'screen3');

    return {
      screen1: {
        label: screen1.label || 'Tela 1',
        maxLength: screen1.maxLength || 144,
        minLength: screen1.minLength || 0,
        required: screen1.required || false,
        placeholder: screen1.text || 'Digite seu texto aqui',
        disabled: screen1.disabled || false,
        valuePrefix: screen1.valuePrefix || '',
        valueSuffix: screen1.valueSuffix || '',
        value: screen1.value || '',
      },
      screen2: {
        label: screen2.label || 'Tela 2',
        options: {
          option1: {
            label: option1.label || 'Opção 1',
            maxLength: option1.maxLength || 22,
            minLength: option1.minLength || 0,
            required: option1.required || false,
            placeholder: option1.text || 'Digite seu texto aqui',
            disabled: option1.disabled || false,
            valuePrefix: option1.valuePrefix || '',
            valueSuffix: option1.valueSuffix || '',
            value: option1.value || '',
          },
          option2: {
            label: option2.label || 'Opção 2',
            maxLength: option2.maxLength || 22,
            minLength: option2.minLength || 0,
            required: option2.required || false,
            placeholder: option2.text || 'Digite seu texto aqui',
            disabled: option2.disabled || false,
            valuePrefix: option2.valuePrefix || '',
            valueSuffix: option2.valueSuffix || '',
            value: option2.value || '',
          },
          option3: {
            label: option3.label || 'Opção 3',
            maxLength: option3.maxLength || 22,
            minLength: option3.minLength || 0,
            required: option3.required || false,
            placeholder: option3.text || 'Digite seu texto aqui',
            disabled: option3.disabled || false,
            valuePrefix: option3.valuePrefix || '',
            valueSuffix: option3.valueSuffix || '',
            value: option3.value || '',
          },
        },
      },
      screen3: {
        label: screen3.label || 'Tela 3',
        maxLength: screen3.maxLength || 144,
        minLength: screen3.minLength || 0,
        required: screen3.required || false,
        placeholder: screen3.text || 'Digite seu texto aqui',
        disabled: screen3.disabled || false,
        valuePrefix: screen3.valuePrefix || '',
        valueSuffix: screen3.valueSuffix || '',
        value: screen3.value || '',
      },
    };
  };

  getSmartMessageTextConfig = () => {
    const { criativoEdit: { creativeDefinition: { schema } } } = this.props;

    const screen1 = schema.screens.find(item => item.id === 'screen1');
    const screen2 = schema.screens.find(item => item.id === 'screen2');

    return {
      screen1: {
        label: screen1.label || 'Tela 1',
        maxLength: screen1.maxLength || 144,
        minLength: screen1.minLength || 0,
        required: screen1.required || false,
        placeholder: screen1.text || 'Digite seu texto aqui',
        disabled: screen1.disabled || false,
        valuePrefix: screen1.valuePrefix || '',
        valueSuffix: screen1.valueSuffix || '',
        value: screen1.value || '',
      },
      screen2: {
        label: screen2.label || 'Tela 2',
        maxLength: screen2.maxLength || 144,
        minLength: screen2.minLength || 0,
        required: screen2.required || false,
        placeholder: screen2.text || 'Digite seu texto aqui',
        disabled: screen2.disabled || false,
        valuePrefix: screen2.valuePrefix || '',
        valueSuffix: screen2.valueSuffix || '',
        value: screen2.value || '',
      },
    };
  };

  getSmartMessageTextClaroConfig = () => {
    const { criativoEdit: { creativeDefinition: { schema } } } = this.props;

    const screen1 = schema.screens.find(item => item.id === 'screen1');
    const screen2 = schema.screens.find(item => item.id === 'screen2');

    return {
      screen1: {
        label: screen1.label || 'Tela 1',
        maxLength: screen1.maxLength || 144,
        minLength: screen1.minLength || 0,
        required: screen1.required || false,
        placeholder: screen1.text || 'Digite seu texto aqui',
        disabled: screen1.disabled || false,
        valuePrefix: screen1.valuePrefix || '',
        valueSuffix: screen1.valueSuffix || '',
        value: screen1.value || '',
      },
      screen2: screen2 ? {
        label: screen2.label || 'Tela 2',
        maxLength: screen2.maxLength || 144,
        minLength: screen2.minLength || 0,
        required: screen2.required || false,
        placeholder: screen2.text || 'Digite seu texto aqui',
        disabled: screen2.disabled || false,
        valuePrefix: screen2.valuePrefix || '',
        valueSuffix: screen2.valueSuffix || '',
        value: screen2.value || '',
      } : null,
    };
  };

  getUSSDMessageTextConfig = () => {
    const { criativoEdit: { creativeDefinition: { schema } } } = this.props;

    const screen1 = schema.screens.find(item => item.id === 'screen1');
    const screen2 = schema.screens.find(item => item.id === 'screen2');

    return {
      screen1: screen1 ? {
        label: screen1.label || 'Texto Menu',
        maxLength: screen1.maxLength || 15,
        minLength: screen1.minLength || 0,
        required: screen1.required || false,
        placeholder: screen1.text || 'Digite seu texto aqui',
        disabled: screen1.disabled || false,
        valuePrefix: screen1.valuePrefix || '',
        valueSuffix: screen1.valueSuffix || '',
        value: screen1.value || '',
      } : {
        label: 'Texto Menu',
        maxLength: 15,
        minLength: 0,
        required: false,
        placeholder: 'Digite seu texto aqui',
        disabled: false,
        valuePrefix: '',
        valueSuffix: '',
        value: '',
      },
      screen2: screen2 ? {
        label: screen2.label || 'Texto Oferta',
        maxLength: screen2.maxLength || 137,
        minLength: screen2.minLength || 0,
        required: screen2.required || false,
        placeholder: screen2.text || 'Digite seu texto aqui',
        disabled: screen2.disabled || false,
        valuePrefix: screen2.valuePrefix || '',
        valueSuffix: screen2.valueSuffix || '',
        value: screen2.value || '',
      } : {
        label: 'Texto Oferta',
        maxLength: 137,
        minLength: 0,
        required: false,
        placeholder: 'Digite seu texto aqui',
        disabled: false,
        valuePrefix: '',
        valueSuffix: '',
        value: '',
      },
    };
  };

  getUSSDMessageTextClaroConfig = () => {
    const { criativoEdit: { creativeDefinition: { schema } } } = this.props;

    const screen1 = schema.screens.find(item => item.id === 'screen1');
    return {
      screen1: {
        label: screen1.label || 'Texto Menu',
        maxLength: screen1.maxLength || 15,
        minLength: screen1.minLength || 0,
        required: screen1.required || false,
        placeholder: screen1.text || 'Digite seu texto aqui',
        disabled: screen1.disabled || false,
        valuePrefix: screen1.valuePrefix || '',
        valueSuffix: screen1.valueSuffix || '',
        value: screen1.value || '',
      },
    };
  };

  getVoiceMessageTextConfig = () => {
    const { criativoEdit: { creativeDefinition: { schema } } } = this.props;
    const screen1 = schema.screens.find(item => item.id === 'screen1');
    return {
      screen1: {
        label: screen1.label || 'Texto',
        maxLength: screen1.maxLength || 320,
        minLength: screen1.minLength || 0,
        required: screen1.required || false,
        placeholder: screen1.text || 'Digite seu texto aqui',
        disabled: screen1.disabled || false,
        valuePrefix: screen1.valuePrefix || '',
        valueSuffix: screen1.valueSuffix || '',
        value: screen1.value || '',
      },
    };
  };

  renderForm = () => {
    const { criativoEdit, campaign, campaignEditCriativoRequestSuccess } = this.props;
    const { hasError } = this.state;
    const { screenSchema: { creative } } = campaign;

    switch (criativoEdit.creativeDefinition.name) {
      case CreativeName.SMART_MESSAGE_TEXT: return ( // alterado
        <SmartMessageTextForm
          hasError={hasError}
          criativoEdit={criativoEdit}
          formConfig={this.getSmartMessageTextConfig()}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.SMART_MESSAGE_TEXT_CLARO: return ( // alterado
        <SmartMessageTextClaroForm
          hasError={hasError}
          criativoEdit={criativoEdit}
          formConfig={this.getSmartMessageTextClaroConfig()}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.SMART_MESSAGE_MENU: return (
        <SmartMessageMenuForm
          hasError={hasError}
          criativoEdit={criativoEdit}
          formConfig={this.getSmartMessageMenuConfig()}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.SATPUSH_CARD_TRIPLO: return (
        <SmartMessageMenuFormCardTriplo
          hasError={hasError}
          criativoEdit={criativoEdit}
          formConfig={this.getSmartMessageMenuCardTriploConfig()}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.SMART_MESSAGE_MENU_TIM: return (
        <SmartMessageMenuTimForm
          hasError={hasError}
          criativoEdit={criativoEdit}
          formConfig={this.getSmartMessageMenuTimConfig()}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.USSD_MESSAGE_TEXT: return ( // alterado
        <USSDMessageTextForm
          hasError={hasError}
          criativoEdit={criativoEdit}
          formConfig={this.getUSSDMessageTextConfig()}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.USSD_MESSAGE_TEXT_CLARO: return ( // alterado
        <USSDMessageTextClaroForm
          hasError={hasError}
          criativoEdit={criativoEdit}
          formConfig={this.getUSSDMessageTextClaroConfig()}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.VOICE_MESSAGE_TEXT: return ( // alterado
        <VoiceMessageTextForm
          hasError={hasError}
          criativoEdit={criativoEdit}
          formConfig={this.getVoiceMessageTextConfig()}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.RCS_CLARO: return (
        <RCSClaroForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.SMS_TEXT_MESSAGE: return (
        <SMSTextMessageForm
          hasError={hasError}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.SMS_MEDIA_MESSAGE: return (
        <SMSMediaMessageForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE: return (
        <PushNotificationTextMessageForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE_MINHA_OI: return (
        <PushNotificationTextMessageForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE: return (
        <PushNotificationImageMessageForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE_MINHA_OI: return (
        <PushNotificationImageMessageForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.MIDIA_NO_CREDIT_AD: return (
        <MidiaNoCreditADMessageForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.CREATIVE_CUSTOM: return (
        <CreativeCustomForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      // notificação patrocinada
      case CreativeName.SPONSOR_NOTIFY_TEXT: return (
        <SponsorNotifyTextMessageForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.SPONSOR_NOTIFY_MEDIA: return (
        <SponsorNotifyMediaMessageForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.NO_CREDIT_BANNER_TEXT: return (
        <NoCreditBannerTextForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.MIDIA_WEB: return (
        <MidiaWebForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.NO_DATA_BANNER_TEXT: return (
        <NoDataBannerTextForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      case CreativeName.CHALLENGE: return (
        // <ChallengeMessageForm
        //   hasError={hasError}
        //   campaign={campaign}
        //   criativoEdit={criativoEdit}
        //   campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
        //   disabledFields={!creative.editable}
        // />
        <CreativeCustomForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
          modeMidia
        />
      );
      case CreativeName.MEDIA_VIDEO: return (
        <MediaVideoForm
          hasError={hasError}
          campaign={campaign}
          criativoEdit={criativoEdit}
          campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
          disabledFields={!creative.editable}
        />
      );
      default: return null;
    }
  };

  renderPreview = () => {
    const { criativoEdit } = this.props;
    return (
      <PreviewCriativo data={criativoEdit} />
    );
  };

  getSMSMediaMessageValidation = () => {
    const { criativoEdit } = this.props;
    const smsContent = criativoEdit.content;

    // channelData
    if (!smsContent.channelData) return false;
    if (!smsContent.channelData.message) return false;
    if (smsContent.channelData.message.length > 134) return false;

    // special character in message verification
    const characterIndex = smsContent.channelData.message.search(/[á|Á|é|É|í|Í|ó|Ó|ú|Ú|à|À|è|È|ì|Ì|ò|Ò|ù|Ù|ã|Ã|õ|Õ|â|Â|ê|Ê|ü|Ü|ç|Ç|]/i);
    if (characterIndex >= 0) {
      return false;
    }

    // mainData
    if (!smsContent.mainData) return false;

    // mainData Tab Layout
    if (!smsContent.mainData.layout) return false;
    if (!smsContent.mainData.layout.title) return false;
    // if (!smsContent.mainData.layout.primaryInformation) return false;
    // if (!smsContent.mainData.layout.secondaryInformation) return false;
    if (!smsContent.mainData.layout.primaryColor) return false;
    if (!smsContent.mainData.layout.secondaryColor) return false;
    // if (!smsContent.mainData.layout.icon) return false;

    // mainData Tab Media
    if (smsContent.mainData.media) {
      if (smsContent.mainData.media.type === 'file' && !smsContent.mainData.media.url) return false;
      if (smsContent.mainData.media.type === 'vast' && !smsContent.mainData.media.content) return false;
    }

    // mainData Tab Form
    if (smsContent.mainData.form) {
      if (!!smsContent.mainData.form.termPosition && !smsContent.mainData.form.confirmationTerm) return false;
    }

    // mainData Tab Success
    if (!smsContent.mainData.success) return false;
    if (!smsContent.mainData.success.type) return false;
    if (smsContent.mainData.success.type === 'external') {
      if (!smsContent.mainData.success.url) return false;
      if (!smsContent.mainData.success.url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) return false;
    }
    if (smsContent.mainData.success.type === 'internal') {
      if (!smsContent.mainData.success.title) return false;
      if (!smsContent.mainData.success.description) return false;
    }

    return true;
  };

  getPushNotificationImageMessageValidation = () => {
    const { criativoEdit } = this.props;
    const smsContent = criativoEdit.content;

    // channelData
    if (!smsContent.channelData) return false;
    // title validation
    if (!smsContent.channelData.title) {
      return false;
    }

    // subtitle validation
    if (!smsContent.channelData.subtitle) {
      return false;
    }

    // mainData
    if (!smsContent.mainData) return false;

    // mainData Tab Layout
    if (!smsContent.mainData.layout) return false;
    if (!smsContent.mainData.layout.title) return false;
    // if (!smsContent.mainData.layout.primaryInformation) return false;
    // if (!smsContent.mainData.layout.secondaryInformation) return false;
    if (!smsContent.mainData.layout.primaryColor) return false;
    if (!smsContent.mainData.layout.secondaryColor) return false;
    // if (!smsContent.mainData.layout.icon) return false;

    // mainData Tab Media
    if (smsContent.mainData.media) {
      if (smsContent.mainData.media.type === 'file' && !smsContent.mainData.media.url) return false;
      if (smsContent.mainData.media.type === 'vast' && !smsContent.mainData.media.content) return false;
    }

    // mainData Tab Form
    if (smsContent.mainData.form) {
      if (!!smsContent.mainData.form.termPosition && !smsContent.mainData.form.confirmationTerm) return false;
    }

    // mainData Tab Success
    if (!smsContent.mainData.success) return false;
    if (!smsContent.mainData.success.type) return false;
    if (smsContent.mainData.success.type === 'external') {
      if (!smsContent.mainData.success.url) return false;
      if (!smsContent.mainData.success.url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) return false;
    }
    if (smsContent.mainData.success.type === 'internal') {
      if (!smsContent.mainData.success.title) return false;
      if (!smsContent.mainData.success.description) return false;
    }

    return true;
  };

  getPushNotificationTextMessageValidation = () => {
    const { criativoEdit } = this.props;
    const smsContent = criativoEdit.content;

    if (!smsContent.channelData
      || !(smsContent.channelData && smsContent.channelData.title)
    ) {
      return false;
    }

    if (!smsContent.channelData
      || !(smsContent.channelData && smsContent.channelData.subtitle)
    ) {
      return false;
    }

    if (!smsContent.channelData) return false;
    if (!smsContent.channelData.url) return false;
    const hasUrl = smsContent.channelData.url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/);
    const hasDeep = smsContent.channelData.url.match(/^[a-z]+[a-z]:\/\/(.*)$/);
    if (!hasUrl && !hasDeep) return false;

    return true;
  };

  getSMSTextMessageValidation = () => {
    const { criativoEdit } = this.props;
    const smsContent = criativoEdit.content;
    if (smsContent.channelData
      && smsContent.channelData.message
      && smsContent.channelData.message.length > 160
    ) {
      return false;
    }
    if (!smsContent.channelData
      || !(smsContent.channelData && smsContent.channelData.message)
    ) {
      return false;
    }

    const { message, url } = smsContent.channelData;
    if (message) {
      const tag = Const.TAG_LINK_INCLUDE;
      if (message.indexOf(tag) >= 0) {
        if (!url || !validateUrl(url)) {
          return false;
        }
      }
    }
    const characterIndex = message.search(/[á|Á|é|É|í|Í|ó|Ó|ú|Ú|à|À|è|È|ì|Ì|ò|Ò|ù|Ù|ã|Ã|õ|Õ|â|Â|ê|Ê|ü|Ü|ç|Ç|]/i);
    if (characterIndex >= 0) {
      return false;
    }
    return true;
  };

  getSmartMessageTextValidation = () => {
    const { criativoEdit: { content } } = this.props;
    const formConfig = this.getSmartMessageTextConfig();

    // Screen 1 validation
    if (formConfig.screen1.required && !content.screen1) {
      return false;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return false;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return false;
    }
    // Screen 2 validation
    if (formConfig.screen2.required && !content.screen2) {
      return false;
    }
    if ((content.screen2 || '').length < formConfig.screen2.minLength) {
      return false;
    }
    if ((content.screen2 || '').length > formConfig.screen2.maxLength) {
      return false;
    }

    return true;
  };

  getSmartMessageTextClaroValidation = () => {
    const { criativoEdit: { content } } = this.props;
    const formConfig = this.getSmartMessageTextClaroConfig();

    // Screen 1 validation
    if (formConfig.screen1.required && !content.screen1) {
      return false;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return false;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return false;
    }
    // Screen 2 validation
    if (formConfig.screen2) {
      if (formConfig.screen2.required && !content.screen2) {
        return false;
      }
      if ((content.screen2 || '').length < formConfig.screen2.minLength) {
        return false;
      }
      if ((content.screen2 || '').length > formConfig.screen2.maxLength) {
        return false;
      }
    }

    return true;
  };

  getUSSDMessageTextValidation = () => {
    const { criativoEdit: { content } } = this.props;
    const formConfig = this.getUSSDMessageTextConfig();

    // Screen 1 validation
    if (formConfig.screen1.required && !content.screen1) {
      return false;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return false;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return false;
    }
    // Screen 2 validation
    if (formConfig.screen2.required && !content.screen2) {
      return false;
    }
    if ((content.screen2 || '').length < formConfig.screen2.minLength) {
      return false;
    }
    if ((content.screen2 || '').length > formConfig.screen2.maxLength) {
      return false;
    }

    return true;
  };

  getUSSDMessageTextClarValidation = () => {
    const { criativoEdit: { content } } = this.props;
    const formConfig = this.getUSSDMessageTextClaroConfig();

    // Screen 1 validation
    if (formConfig.screen1.required && !content.screen1) {
      return false;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return false;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return false;
    }

    return true;
  };

  getVoiceMessageTextValidation = () => {
    const { criativoEdit: { content } } = this.props;
    const formConfig = this.getVoiceMessageTextConfig();

    // Screen 1 validation
    if (formConfig.screen1.required && !content.screen1) {
      return false;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return false;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return false;
    }

    return true;
  };

  getSmartMessageMenuValidation = () => {
    const { criativoEdit: { content } } = this.props;
    const formConfig = this.getSmartMessageMenuConfig();

    // Screen 1 validation
    if (formConfig.screen1.required && !content.screen1) {
      return false;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return false;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return false;
    }
    // Screen 2 validation
    // option 1
    const option1 = content.screen2 && content.screen2.option1 ? content.screen2.option1 : '';
    if (formConfig.screen2.options.option1.required && !option1) {
      return true;
    }
    if (option1.length < formConfig.screen2.options.option1.minLength) {
      return true;
    }
    if (option1.length > formConfig.screen2.options.option1.maxLength) {
      return true;
    }
    // option 2
    const option2 = content.screen2 && content.screen2.option2 ? content.screen2.option2 : '';
    if (formConfig.screen2.options.option2.required && !option2) {
      return true;
    }
    if (option2.length < formConfig.screen2.options.option2.minLength) {
      return true;
    }
    if (option2.length > formConfig.screen2.options.option2.maxLength) {
      return true;
    }
    // option 3
    const option3 = content.screen2 && content.screen2.option3 ? content.screen2.option3 : '';
    if (formConfig.screen2.options.option3.required && !option3) {
      return true;
    }
    if (option3.length < formConfig.screen2.options.option3.minLength) {
      return true;
    }
    if (option3.length > formConfig.screen2.options.option3.maxLength) {
      return true;
    }
    // Screen 3 validation
    if (formConfig.screen3.required && !content.screen3) {
      return false;
    }
    if ((content.screen3 || '').length < formConfig.screen3.minLength) {
      return false;
    }
    if ((content.screen3 || '').length > formConfig.screen3.maxLength) {
      return false;
    }

    return true;
  };

  getSmartMessageMenuCardTriploValidation = () => {
    const { criativoEdit: { content } } = this.props;
    const formConfig = this.getSmartMessageMenuCardTriploConfig();

    // Screen 1 validation
    if (formConfig.screen1.required && !content.screen1) {
      return false;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return false;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return false;
    }
    // Screen 2 validation
    if (formConfig.screen2.required && !content.screen2) {
      return false;
    }
    if ((content.screen2 || '').length < formConfig.screen2.minLength) {
      return false;
    }
    if ((content.screen2 || '').length > formConfig.screen2.maxLength) {
      return false;
    }
    // Screen 3 validation
    if (formConfig.screen3.required && !content.screen3) {
      return false;
    }
    if ((content.screen3 || '').length < formConfig.screen3.minLength) {
      return false;
    }
    if ((content.screen3 || '').length > formConfig.screen3.maxLength) {
      return false;
    }

    return true;
  };

  getSmartMessageMenuTimValidation = () => {
    const { criativoEdit: { content } } = this.props;
    const formConfig = this.getSmartMessageMenuTimConfig();

    // Screen 1 validation
    if (formConfig.screen1.required && !content.screen1) {
      return false;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return false;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return false;
    }
    // Screen 2 validation
    // option 1
    const option1 = content.screen2 && content.screen2.option1 ? content.screen2.option1 : '';
    if (formConfig.screen2.options.option1.required && !option1) {
      return true;
    }
    if (option1.length < formConfig.screen2.options.option1.minLength) {
      return true;
    }
    if (option1.length > formConfig.screen2.options.option1.maxLength) {
      return true;
    }
    // option 2
    const option2 = content.screen2 && content.screen2.option2 ? content.screen2.option2 : '';
    if (formConfig.screen2.options.option2.required && !option2) {
      return true;
    }
    if (option2.length < formConfig.screen2.options.option2.minLength) {
      return true;
    }
    if (option2.length > formConfig.screen2.options.option2.maxLength) {
      return true;
    }
    // option 3
    const option3 = content.screen2 && content.screen2.option3 ? content.screen2.option3 : '';
    if (formConfig.screen2.options.option3.required && !option3) {
      return true;
    }
    if (option3.length < formConfig.screen2.options.option3.minLength) {
      return true;
    }
    if (option3.length > formConfig.screen2.options.option3.maxLength) {
      return true;
    }
    // Screen 3 validation
    if (formConfig.screen3.required && !content.screen3) {
      return false;
    }
    if ((content.screen3 || '').length < formConfig.screen3.minLength) {
      return false;
    }
    if ((content.screen3 || '').length > formConfig.screen3.maxLength) {
      return false;
    }

    return true;
  };

  getMidiaNoCreditADValidation = () => {
    const { criativoEdit, campaign: { zone } } = this.props;
    const adContent = criativoEdit.content;

    if (!adContent.mainData) return false;

    if (!adContent.mainData.type) return false;

    if (adContent.mainData.type === 'internal') {
      return internalMediaComponentFullValidation(criativoEdit, zone);
    }

    if (adContent.mainData.type === 'provedor') {
      // uuid
      if (
        !adContent.mainData
        || !adContent.mainData.provider
        || !adContent.mainData.provider.uuid
      ) {
        return false;
      }
      // htmlContent && script
      if (
        !adContent.mainData
        || !adContent.mainData.provider
        || !(
          adContent.mainData.provider.script
          || adContent.mainData.provider.htmlContent
        )
      ) {
        return false;
      }
    }
    return true;
  };

  getRCSClaroValidation = () => true


  getMidiaCreativeCustomValidation = () => {
    const { criativoEdit, campaign: { zone } } = this.props;
    const adContent = criativoEdit.content;

    if (!adContent.mainData) return false;

    if (!adContent.mainData.type) return false;

    if (adContent.mainData.type === 'internal') {
      return internalMediaComponentFullValidation(criativoEdit, zone);
    }

    if (adContent.mainData.type === 'provider') {
      // uuid
      if (
        !adContent.mainData
        || !adContent.mainData.provider
        || !adContent.mainData.provider.uuid
      ) {
        return false;
      }
      // htmlContent && script
      if (
        !adContent.mainData
        || !adContent.mainData.provider
        || !(
          adContent.mainData.provider.script
          || adContent.mainData.provider.htmlContent
        )
      ) {
        return false;
      }
    }
    return true;
  };

  getNoCreditBannerTextValidation = () => {
    const { criativoEdit: { content, creativeDefinition: { schema: { screens } } } } = this.props;
    let response = true;

    screens.forEach((field) => {
      switch (field.type) {
        case 'check': {
          if (content[field.id].length === 0 && field.required) response = false;
          break;
        }
        case 'radio': {
          if (!content[field.id] && field.required) response = false;
          break;
        }
        case 'url': {
          if (!content[field.id] && field.required) response = false;
          if (
            !!content[field.id]
            && !content[field.id].match(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            )
          ) response = false;
          break;
        }
        case 'text': {
          if (!content[field.id] && field.required) response = false;
          break;
        }
        case 'image': {
          if (!content[field.id] && field.required) response = false;
          break;
        }
        default: break;
      }
    });

    return response;
  };

  getMidiaWebValidation = () => {
    const { criativoEdit: { content, creativeDefinition: { schema: { screens } } } } = this.props;
    let response = true;

    screens.forEach((field) => {
      switch (field.type) {
        case 'check': {
          if (content[field.id].length === 0 && field.required) response = false;
          break;
        }
        case 'radio': {
          if (!content[field.id] && field.required) response = false;
          break;
        }
        case 'url': {
          if (!content[field.id] && field.required) response = false;
          if (
            !!content[field.id]
            && !content[field.id].match(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            )
          ) response = false;
          break;
        }
        case 'text': {
          if (!content[field.id] && field.required) response = false;
          break;
        }
        case 'multi-images': {
          if (field.required && (
            !content[field.id]
            || content[field.id].length === 0
            || !!content[field.id].find(i => !i.content)
          )) {
            response = false;
          }
          break;
        }
        default: break;
      }
    });

    return response;
  };

  getNoDataBannerTextValidation = () => {
    const { criativoEdit: { content, creativeDefinition: { schema: { screens } } } } = this.props;
    let response = true;

    screens.forEach((field) => {
      switch (field.type) {
        case 'check': {
          if (content[field.id].length === 0 && field.required) response = false;
          break;
        }
        case 'radio': {
          if (!content[field.id] && field.required) response = false;
          break;
        }
        case 'url': {
          if (!content[field.id] && field.required) response = false;
          if (
            !!content[field.id]
            && !content[field.id].match(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            )
          ) response = false;
          break;
        }
        case 'text': {
          if (!content[field.id] && field.required) response = false;
          break;
        }
        case 'image': {
          if (!content[field.id] && field.required) response = false;
          break;
        }
        default: break;
      }
    });

    return response;
  };

  getSponsorNotifyMediaMessageValidation = () => {
    const { criativoEdit } = this.props;
    const smsContent = criativoEdit.content;

    // title validation
    if (!smsContent.channelData
      || !(smsContent.channelData && smsContent.channelData.title)
    ) {
      return false;
    }

    // subtitle validation
    if (!smsContent.channelData
      || !(smsContent.channelData && smsContent.channelData.subtitle)
    ) {
      return false;
    }

    // mainData
    if (!smsContent.mainData) return false;

    // mainData Tab Layout
    if (!smsContent.mainData.layout) return false;
    if (!smsContent.mainData.layout.title) return false;
    // if (!smsContent.mainData.layout.primaryInformation) return false;
    // if (!smsContent.mainData.layout.secondaryInformation) return false;
    if (!smsContent.mainData.layout.primaryColor) return false;
    if (!smsContent.mainData.layout.secondaryColor) return false;
    // if (!smsContent.mainData.layout.icon) return false;

    // mainData Tab Media
    if (smsContent.mainData.media) {
      if (smsContent.mainData.media.type === 'file' && !smsContent.mainData.media.url) return false;
      if (smsContent.mainData.media.type === 'vast' && !smsContent.mainData.media.content) return false;
    }

    // mainData Tab Form
    if (smsContent.mainData.form) {
      if (!!smsContent.mainData.form.termPosition && !smsContent.mainData.form.confirmationTerm) return false;
    }

    // mainData Tab Success
    if (!smsContent.mainData.success) return false;
    if (!smsContent.mainData.success.type) return false;
    if (smsContent.mainData.success.type === 'external') {
      if (!smsContent.mainData.success.url) return false;
      if (!smsContent.mainData.success.url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) return false;
    }
    if (smsContent.mainData.success.type === 'internal') {
      if (!smsContent.mainData.success.title) return false;
      if (!smsContent.mainData.success.description) return false;
    }
    return true;
  };

  getSponsorNotifyTextMessageValidation = () => {
    const { criativoEdit } = this.props;
    const smsContent = criativoEdit.content;

    if (!smsContent.channelData
      || !(smsContent.channelData && smsContent.channelData.title)
    ) {
      return false;
    }

    if (!smsContent.channelData
      || !(smsContent.channelData && smsContent.channelData.subtitle)
    ) {
      return false;
    }

    if (!smsContent.channelData
      || !(
        smsContent.channelData
        && smsContent.channelData.urlClick
        && smsContent.channelData.urlClick.match(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        )
      )
    ) {
      return false;
    }
    return true;
  };

  getChallengeMessageValidation = () => {
    const { criativoEdit: { content } } = this.props;

    if (!content.mainData) return false;
    if (!content.mainData.media) return false;
    if (content.mainData.media.length === 0) return false;

    let result = true;

    content.mainData.media.forEach((i) => {
      if (!i.type) result = false;
      if (!i.title) result = false;
      if (i.type === 'programatica' && !i.config.params[0].value) result = false;
      if (i.type !== 'programatica' && !i.content) result = false;
      if (i.type === 'vast' && !xmlIsValid(i.content)) result = false;
    });

    return result;
  };

  getMediaVideoValidation = () => {
    const { criativoEdit, campaign: { zone } } = this.props;
    return internalMediaComponentFullValidation(criativoEdit, zone);
  }

  getDisabledValidation = () => {
    const { criativoEdit } = this.props;
    switch (criativoEdit.creativeDefinition.name) {
      case CreativeName.SMART_MESSAGE_TEXT: return !this.getSmartMessageTextValidation();
      case CreativeName.SMART_MESSAGE_TEXT_CLARO: return !this.getSmartMessageTextClaroValidation();
      case CreativeName.SMART_MESSAGE_MENU: return !this.getSmartMessageMenuValidation();
      case CreativeName.SATPUSH_CARD_TRIPLO: return !this.getSmartMessageMenuCardTriploValidation();
      case CreativeName.SMART_MESSAGE_MENU_TIM: return !this.getSmartMessageMenuTimValidation();
      case CreativeName.USSD_MESSAGE_TEXT: return !this.getUSSDMessageTextValidation();
      case CreativeName.VOICE_MESSAGE_TEXT: return !this.getVoiceMessageTextValidation();
      case CreativeName.SMS_TEXT_MESSAGE: return !this.getSMSTextMessageValidation();
      case CreativeName.SMS_MEDIA_MESSAGE: return !this.getSMSMediaMessageValidation();
      case CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE: return !this.getPushNotificationTextMessageValidation();
      case CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE_MINHA_OI: return !this.getPushNotificationTextMessageValidation();
      case CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE: return !this.getPushNotificationImageMessageValidation();
      case CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE_MINHA_OI: return !this.getPushNotificationImageMessageValidation();
      case CreativeName.MIDIA_NO_CREDIT_AD: return !this.getMidiaNoCreditADValidation();
      case CreativeName.CREATIVE_CUSTOM: return !this.getMidiaCreativeCustomValidation();
      case CreativeName.RCS_CLARO: return !this.getRCSClaroValidation();
      case CreativeName.NO_CREDIT_BANNER_TEXT: return !this.getNoCreditBannerTextValidation();
      case CreativeName.NO_DATA_BANNER_TEXT: return !this.getNoDataBannerTextValidation();
      case CreativeName.MIDIA_WEB: return !this.getMidiaWebValidation();
      case CreativeName.SPONSOR_NOTIFY_TEXT: return !this.getSponsorNotifyTextMessageValidation();
      case CreativeName.SPONSOR_NOTIFY_MEDIA: return !this.getSponsorNotifyMediaMessageValidation();
      case CreativeName.CHALLENGE: return !this.getChallengeMessageValidation();
      case CreativeName.MEDIA_VIDEO: return !this.getMediaVideoValidation();
      default: return false;
    }
  }

  renderHeader = () => {
    const {
      match: { params },
      campaign,
      navigateToRoute,
      campaignEditCriativoSave,
    } = this.props;
    const { screenSchema: { creative } } = campaign;

    const { hasError } = this.state;
    return (
      <Header>
        <HeaderTitle>
          <Breadcrumb
            items={[
              {
                route: '/',
                name: 'Home',
              },
              {
                route: params.campaignMode === 'approval' ? '/campaigns/approval' : '/campaigns',
                name: params.campaignMode === 'approval' ? 'Aprovação Campanha' : 'Campanhas',
                // route: '/campaigns',
                // name: 'Campanhas',
              },
              {
                route: params.campaignMode === 'approval' ? `/campaigns/approval/view/${campaign.uuid}` : `/campaigns/edit/${campaign.uuid}`,
                name: 'Editar',
                // route: `/campaigns/edit/${campaign.uuid}`,
              },
              {
                name: 'Criativo',
              },
            ]}
          />
          <h1>Editar Campanha - Criativo</h1>
        </HeaderTitle>
        <HeaderButtons>
          <Button
            bordered
            title="Voltar"
            onClick={() => {
              navigateToRoute(
                params.campaignMode === 'approval'
                  ? `/campaigns/approval/view/${campaign.uuid}`
                  : `/campaigns/edit/${campaign.uuid}`,
              );
            }}
            type="button"
          />
          {creative.editable && (
            <Button
              disabled={hasError && this.getDisabledValidation()}
              title="Salvar"
              onClick={() => {
                if (!this.getDisabledValidation()) {
                  campaignEditCriativoSave(
                    params.campaignMode === 'approval'
                      ? 'approval'
                      : '',
                  );
                  this.setState({ hasError: false });
                } else {
                  this.setState({ hasError: true });
                }
              }}
              type="button"
            />
          )}
        </HeaderButtons>
      </Header>
    );
  };

  renderContent = () => {
    const { campaign, criativoEdit } = this.props;
    const { screenSchema } = campaign;

    return (
      <Content>
        <CampaignData>
          {screenSchema.general.visible && (
            <CampaignsBoxContent
              title={criativoEdit.creativeDefinition.description}
              marginRight={15}
            >
              {this.renderForm()}
            </CampaignsBoxContent>
          )}
          {screenSchema.creative.visible && (
            <CampaignsBoxContent title="Preview" width={475}>
              {this.renderPreview()}
            </CampaignsBoxContent>
          )}
        </CampaignData>
      </Content>
    );
  };

  render() {
    const { criativoEdit, campaign } = this.props;
    return criativoEdit && campaign && (
      <Wrap>
        <Container>
          {this.renderHeader()}
          {this.renderContent()}
        </Container>
      </Wrap>
    );
  }
}

Form.defaultProps = {
  campaign: null,
  criativoEdit: null,
};

Form.propTypes = {
  campaign: PropTypes.shape(),
  criativoEdit: PropTypes.shape(),
  match: PropTypes.shape().isRequired,
  loadCampaignEdit: PropTypes.func.isRequired,
  navigateToRoute: PropTypes.func.isRequired,
  campaignEditCriativoRequest: PropTypes.func.isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  campaignEditCriativoSave: PropTypes.func.isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = (state) => {
  const { campaign } = state.campaign.edit;
  return {
    campaign,
    criativoEdit: state.campaignEdit.criativoEdit,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditCriativoRequest: CampaignEditActions.campaignEditCriativoRequest,
    campaignEditCriativoRequestSuccess: CampaignEditActions.campaignEditCriativoRequestSuccess,
    campaignEditCriativoSave: CampaignEditActions.campaignEditCriativoSave,
    loadCampaignEdit: CampaignActions.loadCampaignEdit,
    navigateToRoute: RedirectActions.navigateToRoute,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Form);
