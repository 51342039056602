const wrapChannelKeyValue = (channel) => {
    return channel.zoneUuid ? `${channel.uuid}:${channel.zoneUuid}`: channel.uuid
}

const unwrapChannelKeyValue = (channel) => {
    const [channelUuid, zoneUuid] = channel && channel.value ? channel.value.split(":"): [];
    return { channelUuid, zoneUuid }
}

const mapChannelsForSelect =  (channels) => channels.map(item => ({
    value: wrapChannelKeyValue(item),
    label: item.description
}))

const findChannelByZone = (channels, zone) => channels.find(item => {
    const [channelUuid, zoneUuid] = item.value.split(':')
    const channelFound = zone.channelUuid === channelUuid;

    if (!zoneUuid) return channelFound;
    
    const zoneFound = zone.uuid === zoneUuid  
    return channelFound && zoneFound;
  })

export default {
    wrapChannelKeyValue,
    unwrapChannelKeyValue,
    mapChannelsForSelect,
    findChannelByZone
}