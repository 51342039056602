/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FaFont, FaPencilAlt, FaRegCalendarAlt, FaRegCheckSquare, FaRegDotCircle, FaTrash, FaYoutube, FaInfoCircle,
} from 'react-icons/fa';

import { validateTagGA, validateUrl, xmlIsValid } from '~/Utils';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import UploadService, { baseUrlMedia } from '~/services/UploadService';
import ResearchForm from './components/ResearchForm';
import Upload from '~/Components/Upload';
import UploadImage from '~/Components/UploadImage';
import Button from '~/Components/Button';

import {
  FormGroup,
  FormGroupHorizontal,
  Input,
  Row,
  ColumnVideo,
  Column,
  WrapContent,
  FormMediaWrap,
  FormMedia,
  Select,
  Textarea,
  MidiaVideoPreview,
  LabelXmlValid,
  PreviewVideoBox,
  PreviewThumbnailVideoBox,
  PreviewThumbnailBox,
  ErrorMessage,
  BaseInputsGA,
  BaseCleanSubType,
  BaseExternalUrlConfig,
  BasePerguntaQualificadora,
  BasePerguntaQualificadoraHeader,
  BasePerguntaQualificadoraContent,
  ButtonEditForm,
  PerguntaQualificadoraList,
  PerguntaQualificadoraItem,
  FieldWithLabel,
  InfoLinkExternoExternalRedirect,
  BaseItemsProgramatia,
  ActionButton,
  MediaHeader,
  RowTypeHeader,
  ContainerFallbackNoFill
} from './styles';

import PreviewImageBox from './components/PreviewImageBox';
import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';
import { ModalSurveyEdit } from './components/ModalSurveyEdit';
import Radiobox from '~/Components/Radiobox';
import { TextEditor } from '~/Components/TextEditor';

const propertiesDefaultImage = {
  resolutions: [{
    id: 'not-resolution', label: 'Resolução livre', fixed: false, width: 0, height: 0,
  }],
};

const resolutionThumbnailAppInstall = {
  id: 'app-install-resolution', label: 'Resolução App install', fixed: true, width: 192, height: 192,
};

const resolutionThumbnailExternalRedirect = {
  id: 'app-install-resolution', label: 'Resolução Redirecionamento externo', fixed: true, width: 192, height: 192,
};

const PROXY_VAST_OPTIONS = [
  { label: 'Habilitado', value: true }, { label: 'Desabilitado', value: false },
];

const SURVEY_PAGE_DEFAULT = 'step-page';
const SURVEY_PAGE_LAYOUT_OPTIONS = [
  { label: 'Passo a passo', value: 'step-page' },
  { label: 'Página única', value: 'landing-page' },
];

const SHOW_TITLE_SURVEY_OPTIONS = [
  { label: 'Sim', value: true },
  { label: 'Não', value: false },
];

class MediaNoFill extends Component {
  state = {
    selectedItem: null,
    messageError: null,
    formSurvey: {
      show: false,
      contentCopy: null,
      formHasError: false,
    },
  };

  getZoneProxyVast = () => {
    const {
      campaign: { zone: { extra } },
    } = this.props;
    if (!!extra && !!extra.creatives && !!extra.creatives.internal && !!extra.creatives.internal.medias && !!extra.creatives.internal.medias.vast) {
      const { proxy } = extra.creatives.internal.medias.vast;
      return proxy;
    }
    return null;
  }

  getZoneProxyVpaid = () => {
    const {
      campaign: { zone: { extra } },
    } = this.props;
    if (!!extra && !!extra.creatives && !!extra.creatives.internal && !!extra.creatives.internal.medias && !!extra.creatives.internal.medias.vpaid) {
      const { proxy } = extra.creatives.internal.medias.vpaid;
      return proxy;
    }
    return null;
  }

  getZoneConfigExternalUrl = (typeMidia) => {
    const {
      campaign: { zone: { extra } },
    } = this.props;
    if (!!extra && !!extra.creatives && !!extra.creatives.internal && !!extra.creatives.internal.medias && !!extra.creatives.internal.medias[typeMidia]) {
      const { externalUrl } = extra.creatives.internal.medias[typeMidia];
      return externalUrl;
    }
    return null;
  }

  uploadFileToAWS = async (campaignUuid, file) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;

    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  uploadFileVideo = async (files) => {
    const { campaign, selectedItem, setSelectedData } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, content: url }});
    }
  };

  uploadFileThumbnailVideo = async (files) => {
    const { campaign, selectedItem, setSelectedData } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      setSelectedData({ fallbackNoFill: {...selectedItem.fallbackNoFill, thumbnail: url }});
    }
  };

  uploadFileThumbnailVast = async (files) => {
    const { campaign, selectedItem, setSelectedData } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, thumbnail: url} });
    }
  };

  uploadFileThumbnailProgramatic = async (files) => {
    const { campaign, selectedItem, setSelectedData } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      setSelectedData({ fallbackNoFill: {...selectedItem.fallbackNoFill, thumbnail: url }});
    }
  };

  uploadFileThumbnailAppInstall = async (files) => {
    const { selectedItem, setSelectedData } = this.props;
    const { campaign } = this.props;
    const file = files[0];
    const { content } = selectedItem.fallbackNoFill;
    if (file) {
      const { url: thumbnail } = await this.uploadFileToAWS(campaign.uuid, file);
      setSelectedData({ fallbackNoFill: {...selectedItem.fallbackNoFill, content: { ...content, thumbnail } }});
    }
  };

  uploadFileImage = async (files) => {
    const { campaign, selectedItem, setSelectedData } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      setSelectedData({ fallbackNoFill: {...selectedItem.fallbackNoFill, content: url }});
    }
  };

  renderResolutionImageFormHeader = () => {
    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData
    } = this.props;

    const {
      fallbackNoFill: {
        resolution,
        content,
        properties
      }
    } = selectedItem;
    const { resolutions } = properties || propertiesDefaultImage;

    const resolutionImageList = resolutions.map(r => ({
      value: r.id,
      label: r.label,
      resolution: r,
    }));

    const selectedResolution = resolutionImageList.find(item => item.value === (resolution ? resolution.id : 'not-resolution'));

    return (
      <FormGroup style={{ flex: 1 }}>
        <label>
          Resolução
          <span>*</span>
          {!!content && <span className="info-disabled-resolution-image">Obs: remova a imagem atual para alterar a resolução.</span>}
        </label>
        <Select
          isDisabled={disabledFields || !!content}
          placeholder="Selecione Tipo..."
          value={selectedResolution}
          onChange={(item) => {
            setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, resolution: item.resolution }});
          }}
          options={resolutionImageList}
          invalid={hasError && !selectedResolution}
        />
      </FormGroup>
    );
  }

  renderProxyVastFormHeader = () => {
    const {
      disabledFields,
      selectedItem,
      setSelectedData
    } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    const zoneProxyVast = this.getZoneProxyVast();

    if (!zoneProxyVast) {
      return null;
    }

    if (selectedItem.fallbackNoFill.proxy !== true && selectedItem.fallbackNoFill.proxy !== false) {
      setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, proxy: zoneProxyVast.default }});
    }

    if (!zoneProxyVast.editable) {
      return null;
    }

    const valueItem = selectedItem.fallbackNoFill.proxy !== undefined && selectedItem.fallbackNoFill.proxy !== null ? selectedItem.fallbackNoFill.proxy : zoneProxyVast.default;

    return (
      <FormGroup style={{ flex: 1 }}>
        <label>
          Proxy interno
          <span>*</span>
        </label>
        <Select
          isDisabled={disabledFields}
          placeholder="Selecione a opção..."
          value={PROXY_VAST_OPTIONS.find(p => p.value === valueItem)}
          onChange={(item) => {
            setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, proxy: item.value }});
          }}
          options={PROXY_VAST_OPTIONS}
        />
      </FormGroup>
    );
  }

  renderLayoutTypeSurveyFormHeader = () => {
    const { disabledFields, selectedItem, setSelectedData } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    const layout = selectedItem.fallbackNoFill.content && selectedItem.fallbackNoFill.content.layout ? selectedItem.fallbackNoFill.content.layout : SURVEY_PAGE_DEFAULT;
    const showTitle = selectedItem.fallbackNoFill.content && selectedItem.fallbackNoFill.content.showTitle ? selectedItem.fallbackNoFill.content.showTitle : false;

    return (
      <>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Layout
            <span>*</span>
          </label>
          <Select
            isDisabled={disabledFields}
            placeholder="Selecione a opção..."
            value={SURVEY_PAGE_LAYOUT_OPTIONS.find(p => p.value === layout)}
            onChange={(item) => {
              setSelectedData({
                fallbackNoFill: {
                  ...selectedItem.fallbackNoFill,
                  content: {
                    ...selectedItem.content,
                    layout: item.value,
                  },
                }
              });
            }}
            options={SURVEY_PAGE_LAYOUT_OPTIONS}
          />
        </FormGroup>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Mostrar título
            <span>*</span>
          </label>
          <Select
            isDisabled={disabledFields}
            placeholder="Selecione a opção..."
            value={SHOW_TITLE_SURVEY_OPTIONS.find(p => p.value === showTitle)}
            onChange={(item) => {
              setSelectedData({
                fallbackNoFill: {
                  ...selectedItem.fallbackNoFill,
                  content: {
                    ...selectedItem.content,
                    showTitle: item.value,
                  },
                }
              });
            }}
            options={SHOW_TITLE_SURVEY_OPTIONS}
          />
        </FormGroup>
      </>
    );
  }

  renderProxyVpaidFormHeader = () => {
    // const { disabledFields } = this.props;
    const { selectedItem , setSelectedData } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    if (selectedItem.fallbackNoFill.proxy !== false) {
      setSelectedData({ fallbackNoFill: {...selectedItem.fallbackNoFill, proxy: false }});
    }
    return null;
  }

  renderSubtypeFormHeader = () => {
    const { selectedItem } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) {
      return null;
    }
    const { fallbackNoFill: {type} } = selectedItem;

    if (type === CreativeCustomMidiaType.IMAGE) {
      return this.renderResolutionImageFormHeader();
    } if (type === CreativeCustomMidiaType.VAST || type === CreativeCustomMidiaType.VAST_SURVEY) {
      return this.renderProxyVastFormHeader();
    } if (type === CreativeCustomMidiaType.VPAID || type === CreativeCustomMidiaType.VPAID_SURVEY) {
      return this.renderProxyVpaidFormHeader();
    } if (type === CreativeCustomMidiaType.SURVEY || type === CreativeCustomMidiaType.DATA_SEARCH) {
      return this.renderLayoutTypeSurveyFormHeader();
    }
    return <BaseCleanSubType />;
  }

  renderFormHeader = () => {
    const {
      hasError,
      disabledFields,
      criativoEdit: { creative },
      criativoEdit,
      selectedItem,
      setSelectedData,
      closeMediaNoFill
    } = this.props;

    if(!selectedItem || !selectedItem.fallbackNoFill) return;

    const typeList = [{
      label: 'Sem mídia',
      value: '',
      mainOptions: {
        content: '',
      },
    }];

    if (creative && creative.medias) {
      creative.medias.forEach((media) => {
        switch (media.type) {
          // case CreativeCustomMidiaType.IMAGE:
          //   typeList.push({
          //     label: 'Imagem',
          //     value: CreativeCustomMidiaType.IMAGE,
          //     mainOptions: {
          //       content: '',
          //       qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
          //     },
          //     properties: media.properties,
          //   });
          //   break;
          case CreativeCustomMidiaType.VIDEO:
            typeList.push({
              label: 'Vídeo',
              value: CreativeCustomMidiaType.VIDEO,
              mainOptions: {
                content: '',
                thumbnail: '',
                modeVideo: true,
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          // case CreativeCustomMidiaType.SURVEY:
          //   typeList.push({
          //     label: 'Pesquisa',
          //     value: CreativeCustomMidiaType.DATA_SEARCH,
          //     mainOptions: {
          //       content: {
          //         layout: SURVEY_PAGE_DEFAULT,
          //         rightAnswerRequired: false,
          //         termPosition: '',
          //         confirmationTerm: '',
          //         questions: [],
          //       },
          //       qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
          //     },
          //     properties: media.properties,
          //   });
          //   break;
          // case CreativeCustomMidiaType.PROGRAMMATIC:
          //   typeList.push({
          //     label: 'Programática',
          //     value: CreativeCustomMidiaType.PROGRAMATICA,
          //     mainOptions: {
          //       content: '',
          //       viewed: false,
          //       config: {
          //         type: 'google-admob-rv',
          //         params: [{
          //           key: 'id',
          //           value: '',
          //         }],
          //       },
          //       'fallbacks-config': [],
          //       qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
          //     },
          //     properties: media.properties,
          //   });
          //   break;
          // case CreativeCustomMidiaType.APP_INSTALL:
          //   typeList.push({
          //     label: 'App Install',
          //     value: CreativeCustomMidiaType.APP_INSTALL,
          //     mainOptions: {
          //       content: {
          //         partner: null,
          //         title: '',
          //         description: '',
          //         link: '',
          //         thumbnail: '',
          //         qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
          //       },
          //     },
          //     properties: { ...media.properties },
          //   });
          //   break;
          // case CreativeCustomMidiaType.EXTERNAL_REDIRECT:
          //   typeList.push({
          //     label: 'Redirecionamento externo',
          //     value: CreativeCustomMidiaType.EXTERNAL_REDIRECT,
          //     mainOptions: {
          //       content: {
          //         infoPrimary: '',
          //         infoSecondary: '',
          //         actionButtonLabel: '',
          //         link: '',
          //         thumbnail: '',
          //       },
          //       qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
          //     },
          //     properties: { ...media.properties },
          //   });
          //   break;
          case CreativeCustomMidiaType.VAST:
            typeList.push({
              label: 'Vast',
              value: CreativeCustomMidiaType.VAST,
              mainOptions: {
                content: '',
                thumbnail: '',
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          // case CreativeCustomMidiaType.VAST_SURVEY:
          //   typeList.push({
          //     label: 'Vast com pergunta qualificadora',
          //     value: CreativeCustomMidiaType.VAST_SURVEY,
          //     mainOptions: {
          //       content: '',
          //       thumbnail: '',
          //       dataSearch: {
          //         questions: [],
          //       },
          //       qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
          //     },
          //     properties: media.properties,
          //   });
          //   break;
          case CreativeCustomMidiaType.VPAID:
            typeList.push({
              label: 'Vpaid',
              value: CreativeCustomMidiaType.VPAID,
              mainOptions: {
                content: '',
                thumbnail: '',
                qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
              },
              properties: media.properties,
            });
            break;
          // case CreativeCustomMidiaType.VPAID_SURVEY:
          //   typeList.push({
          //     label: 'Vpaid com pergunta qualificadora',
          //     value: CreativeCustomMidiaType.VPAID_SURVEY,
          //     mainOptions: {
          //       content: '',
          //       thumbnail: '',
          //       dataSearch: {
          //         questions: [],
          //       },
          //       qntReplicas: media.properties && media.properties.replicasEnabled ? 1 : 0,
          //     },
          //     properties: media.properties,
          //   });
          //   break;
          default:
            // eslint-disable-next-line no-console
            console.log(`type-media-not-configured: ${media.type}`);
        }
      });
    } else {
      // typeList.push({
      //   label: 'Imagem',
      //   value: CreativeCustomMidiaType.IMAGE,
      //   mainOptions: {
      //     content: '',
      //   },
      //   properties: { ...propertiesDefaultImage },
      // });
      typeList.push({
        label: 'Vídeo',
        value: CreativeCustomMidiaType.VIDEO,
        mainOptions: {
          content: '',
          thumbnail: '',
          modeVideo: true,
        },
      });
      // typeList.push({
      //   label: 'Pesquisa',
      //   value: CreativeCustomMidiaType.DATA_SEARCH,
      //   mainOptions: {
      //     content: {
      //       layout: SURVEY_PAGE_DEFAULT,
      //       rightAnswerRequired: false,
      //       termPosition: '',
      //       confirmationTerm: '',
      //       questions: [],
      //     },
      //   },
      // });
      // typeList.push({
      //   label: 'Programática',
      //   value: CreativeCustomMidiaType.PROGRAMATICA,
      //   mainOptions: {
      //     content: '',
      //     viewed: false,
      //     config: {
      //       type: 'google-admob-rv',
      //       params: [{
      //         key: 'id',
      //         value: '',
      //       }],
      //     },
      //     'fallbacks-config': [],
      //   },
      // });
      typeList.push({
        label: 'Vast',
        value: CreativeCustomMidiaType.VAST,
        mainOptions: {
          content: '',
          thumbnail: '',
        },
      });
      typeList.push({
        label: 'Vpaid',
        value: CreativeCustomMidiaType.VPAID,
        mainOptions: {
          content: '',
          thumbnail: '',
        },
      });
    }

    const { content } = criativoEdit;

    return (
      <>
        <MediaHeader>
          <FormGroupHorizontal style={{ flex: 0.5, flexDirection: 'row', minWidth: '400px' }}>
            <label>
              Tipo:
              <span>* &nbsp;</span>
            </label>
            <Select
              isDisabled={disabledFields}
              placeholder="Selecione Tipo..."
              value={typeList.find(item => item.value === selectedItem.fallbackNoFill.type)}
              onChange={({ value, mainOptions, properties }) => setSelectedData({
                fallbackNoFill: {
                  uuid: selectedItem.uuid,
                  type: value, ...mainOptions, properties
                }
              })}
              options={typeList}
              invalid={hasError && !selectedItem.fallbackNoFill.type}
            />
          </FormGroupHorizontal>
          <Button
            disabled={disabledFields}
            onClick={() => closeMediaNoFill()}
            bordered
            small
            title="CONCLUÍDO"
          />
        </MediaHeader>
        {!!selectedItem.fallbackNoFill.type && (
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Nome do conteúdo
                <span>*</span>
                -
                <small>{` ${selectedItem.fallbackNoFill.title && selectedItem.fallbackNoFill.title.length || 0}/100 caracteres`}</small>
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite um nome para o conteúdo"
                maxLength={100}
                onChange={e => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, title: e.target.value }})}
                value={selectedItem.fallbackNoFill.title}
                invalid={hasError && !selectedItem.fallbackNoFill.title}
                autoFocus
              />
            </FormGroup>

            {selectedItem.fallbackNoFill.properties && selectedItem.fallbackNoFill.properties.replicasEnabled === true && (
            <FormGroup style={{ marginLeft: '8px' }}>
              <label>
                Réplicas
                <span>*</span>
                <FaInfoCircle
                  style={{ marginBottom: '4px', marginLeft: '2px' }}
                  data-tip="A quantidade de réplicas que essa mídia terá na entrega.
                  Obs: Valor mínimo é 1(um), sendo entregue uma vez."
                  data-for="tooltip-replicas"
                  size={14}
                />
                <ReactTooltip id="tooltip-replicas" place="top" effect="solid" data-html="true" />
              </label>
              <Input
                min={1}
                type="number"
                placeholder="Quantidade de réplicas..."
                onChange={e => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, qntReplicas: e.target.value }})}
                value={selectedItem.fallbackNoFill.qntReplicas}
                invalid={hasError && (!selectedItem.fallbackNoFill.qntReplicas || selectedItem.fallbackNoFill.qntReplicas < 1)}
              />
            </FormGroup>

            )}
            <RowTypeHeader>
              {this.renderSubtypeFormHeader()}
            </RowTypeHeader>
          </Row>
        )}
      </>
    );
  };

  renderVastMode = () => {
    const { messageError } = this.state;
    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData,
      fileSizeConfigData,
    } = this.props;

    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    const getVastThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vast
        && fileSizeConfigData.creative.content.media.vast.thumbnail
        && fileSizeConfigData.creative.content.media.vast.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.vast.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getVastThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vast
        && fileSizeConfigData.creative.content.media.vast.thumbnail
        && fileSizeConfigData.creative.content.media.vast.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.vast.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    return (
      <>
        <Row style={{ justifyContent: 'flex-start' }}>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  XML
                  <span>*</span>
                </label>
                <Textarea
                  disabled={disabledFields}
                  rows="10"
                  placeholder="XML"
                  onChange={e => setSelectedData({ fallbackNoFill: {...selectedItem.fallbackNoFill , content: e.target.value }})}
                  value={selectedItem.fallbackNoFill.content}
                  invalid={hasError && (!selectedItem.fallbackNoFill.content || !xmlIsValid(selectedItem.fallbackNoFill.content))}
                />
                {!!selectedItem.fallbackNoFill.content && (
                  <LabelXmlValid isValid={xmlIsValid(selectedItem.fallbackNoFill.content)}>
                    <span className="label-xml-valid">XML válido</span>
                    <span className="label-xml-invalid">XML inválido</span>
                  </LabelXmlValid>
                )}
              </FormGroup>
            </Row>
          </Column>
          <Column style={{ flex: 1, marginLeft: 25 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.fallbackNoFill.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVast}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVastThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVastThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.fallbackNoFill.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, thumbnail: '' }})}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.fallbackNoFill.thumbnail}
                >
                  {selectedItem.fallbackNoFill.thumbnail ? (
                    <>
                      <img src={selectedItem.fallbackNoFill.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
              </FormGroup>
            </Row>
          </Column>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
        {this.renderExternalUrlConfig()}
      </>
    );
  };

  renderExternalUrlConfig = () => {
    const { selectedItem, setSelectedData } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;
    const {
      hasError,
      disabledFields,
    } = this.props;

    const externalUrlConfig = this.getZoneConfigExternalUrl(selectedItem.fallbackNoFill.type);
    if (!externalUrlConfig || !externalUrlConfig.editable) return null;

    const externalUrl = selectedItem.fallbackNoFill.externalUrl || { url: '' };

    const invalidInputUrl = () => {
      if (hasError) {
        if (!!externalUrl.url && !validateUrl(externalUrl.url)) {
          return true;
        }
      }
      return false;
    };

    return (
      <BaseExternalUrlConfig>
        <p className="title-base-external-url">Redirecionamento por click na mídia</p>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <label>
              URL de redirecionamento
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite a url de redirecionamento"
              onChange={(e) => {
                setSelectedData({ fallbackNoFill: {
                  ...selectedItem.fallbackNoFill,
                  externalUrl: {
                    ...externalUrl,
                    url: e.target.value,
                  },
                }});
              }}
              value={externalUrl.url}
              invalid={invalidInputUrl()}
            />
          </FormGroup>
        </Row>
      </BaseExternalUrlConfig>
    );
  }

  renderVastSurveyMode = () => {
    const { messageError } = this.state;

    const {
      hasError,
      disabledFields,
      selectedItem,
      fileSizeConfigData,
      setSelectedData
    } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    const getVastSurveyThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vastSurvey
        && fileSizeConfigData.creative.content.media.vastSurvey.thumbnail
        && fileSizeConfigData.creative.content.media.vastSurvey.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.vastSurvey.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getVastSurveyThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vastSurvey
        && fileSizeConfigData.creative.content.media.vastSurvey.thumbnail
        && fileSizeConfigData.creative.content.media.vastSurvey.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.vastSurvey.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    return (
      <>
        <Row style={{ justifyContent: 'flex-start' }}>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  XML
                  <span>*</span>
                </label>
                <Textarea
                  disabled={disabledFields}
                  rows="5"
                  placeholder="XML"
                  onChange={e => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, content: e.target.value }})}
                  value={selectedItem.fallbackNoFill.content}
                  invalid={hasError && (!selectedItem.fallbackNoFill.content || !xmlIsValid(selectedItem.fallbackNoFill.content))}
                />
                {!!selectedItem.fallbackNoFill.content && (
                  <LabelXmlValid isValid={xmlIsValid(selectedItem.fallbackNoFill.content)}>
                    <span className="label-xml-valid">XML válido</span>
                    <span className="label-xml-invalid">XML inválido</span>
                  </LabelXmlValid>
                )}
              </FormGroup>
            </Row>
            {this.renderRerguntaQualificadoraItem()}
          </Column>
          <Column style={{ flex: 1, marginLeft: 25 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.fallbackNoFill.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVast}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVastSurveyThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVastSurveyThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.fallbackNoFill.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => setSelectedData({ thumbnail: '' })}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.fallbackNoFill.thumbnail}
                >
                  {selectedItem.fallbackNoFill.thumbnail ? (
                    <>
                      <img src={selectedItem.fallbackNoFill.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
              </FormGroup>
            </Row>
          </Column>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
      </>
    );
  };

  renderVpaidMode = () => {
    const { messageError } = this.state;
    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData,
      fileSizeConfigData,
    } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    const getVpaidThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vpaid
        && fileSizeConfigData.creative.content.media.vpaid.thumbnail
        && fileSizeConfigData.creative.content.media.vpaid.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.vpaid.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getVpaidThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vpaid
        && fileSizeConfigData.creative.content.media.vpaid.thumbnail
        && fileSizeConfigData.creative.content.media.vpaid.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.vpaid.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    return (
      <>
        <Row style={{ alignItems: 'flex-start' }}>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  URL Vpaid
                  <span>*</span>
                </label>
                <Input
                  disabled={disabledFields}
                  placeholder="Digite a url"
                  onChange={e => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, content: e.target.value }})}
                  value={selectedItem.fallbackNoFill.content}
                  invalid={hasError && (!selectedItem.fallbackNoFill.content || !validateUrl(selectedItem.fallbackNoFill.content))}
                  autoFocus
                />
              </FormGroup>
            </Row>
          </Column>
          <Column style={{ flex: 1, marginLeft: 25 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.fallbackNoFill.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVast}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVpaidThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVpaidThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.fallbackNoFill.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => setSelectedData({fallbackNoFill: {...selectedItem.fallbackNoFill, thumbnail: '' }})}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.fallbackNoFill.thumbnail}
                >
                  {selectedItem.fallbackNoFill.thumbnail ? (
                    <>
                      <img src={selectedItem.fallbackNoFill.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
                {messageError && (
                  <Row>
                    <ErrorMessage>{messageError}</ErrorMessage>
                  </Row>
                )}
              </FormGroup>
            </Row>
          </Column>
        </Row>

        {this.renderExternalUrlConfig()}
      </>
    );
  };

  renderRerguntaQualificadoraItem = () => {
    const { formSurvey } = this.state;

    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData
    } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;


    const onChangeForm = (data) => {
      setSelectedData({ fallbackNoFill: {...selectedItem.fallbackNoFill, dataSearch: data }});
    };

    const getIconQuestionType = (questionType) => {
      switch (questionType) {
        case 'check':
          return <FaRegCheckSquare />;
        case 'radio':
          return <FaRegDotCircle />;
        case 'text':
          return <FaFont />;
        case 'date':
          return <FaRegCalendarAlt />;
        default:
          return null;
      }
    };

    const questions = selectedItem.fallbackNoFill.dataSearch && selectedItem.fallbackNoFill.dataSearch.questions ? selectedItem.fallbackNoFill.dataSearch.questions : [];

    return (
      <>
        <Row>
          <FormGroup style={{ flex: 1, marginTop: 10, marginBottom: 10 }}>
            <label>
              Pergunta qualificadora
              <span>*</span>
            </label>
            <BasePerguntaQualificadora invalid={hasError && questions.length === 0}>
              <BasePerguntaQualificadoraHeader invalid={hasError && questions.length === 0}>
                <ButtonEditForm
                  disabled={disabledFields}
                  onClick={() => {
                    this.setState({
                      formSurvey: {
                        show: true,
                        contentCopy: { ...selectedItem.fallbackNoFill.dataSearch },
                        formHasError: false,
                      },
                    });
                  }}
                >
                  Editar perguntas
                  <FaPencilAlt />
                </ButtonEditForm>
              </BasePerguntaQualificadoraHeader>
              <BasePerguntaQualificadoraContent>
                {questions.length === 0 && (<span>Sem perguntas cadastradas</span>)}
                {questions.length > 0 && (
                  <PerguntaQualificadoraList>
                    {questions.map((q, index) => (
                      <PerguntaQualificadoraItem key={q.uuid}>
                        {getIconQuestionType(q.type)}
                        <span>{`${index + 1}#`}</span>
                        <p>{q.title}</p>
                      </PerguntaQualificadoraItem>
                    ))}
                  </PerguntaQualificadoraList>
                )}
              </BasePerguntaQualificadoraContent>
            </BasePerguntaQualificadora>
          </FormGroup>
        </Row>
        <ModalSurveyEdit
          modalOpen={formSurvey.show}
          disabledFields={disabledFields}
          onChangeForm={onChangeForm}
          content={selectedItem.fallbackNoFill.dataSearch}
          formModalOriginal={formSurvey.contentCopy}
          formHasError={formSurvey.formHasError}
          onCloseModal={() => {
            onChangeForm({
              ...selectedItem.fallbackNoFill.dataSearch,
              ...formSurvey.contentCopy,
            });
            this.setState({
              formSurvey: {
                ...formSurvey,
                show: false,
                contentCopy: null,
                formHasError: false,
              },
            });
          }}
          onCancelar={() => {
            onChangeForm({
              ...selectedItem.fallbackNoFill.dataSearch,
              ...formSurvey.contentCopy,
            });
            this.setState({
              formSurvey: {
                ...formSurvey,
                show: false,
                contentCopy: null,
                formHasError: false,
              },
            });
          }}
          onConfirmar={(formIsValid) => {
            if (formIsValid) {
              this.setState({
                formSurvey: {
                  ...formSurvey,
                  show: false,
                  formHasError: false,
                },
              });
            } else {
              this.setState({
                formSurvey: {
                  ...formSurvey,
                  formHasError: true,
                },
              });
            }
          }}
        />
      </>
    );
  }

  renderVpaidSurveyMode = () => {
    const { messageError } = this.state;

    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData,
      fileSizeConfigData,
    } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    const getVpaidSurveyThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vpaidSurvey
        && fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail
        && fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getVpaidSurveyThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.vpaidSurvey
        && fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail
        && fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.vpaidSurvey.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    return (
      <>
        <Row style={{ alignItems: 'flex-start' }}>
          <Column style={{ flex: 2 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  URL Vpaid
                  <span>*</span>
                </label>
                <Input
                  disabled={disabledFields}
                  placeholder="Digite a url"
                  onChange={e => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, content: e.target.value }})}
                  value={selectedItem.fallbackNoFill.content}
                  invalid={hasError && (!selectedItem.fallbackNoFill.content || !validateUrl(selectedItem.fallbackNoFill.content))}
                  autoFocus
                />
              </FormGroup>
            </Row>
            {this.renderRerguntaQualificadoraItem()}
          </Column>
          <Column style={{ flex: 1, marginLeft: 25 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.fallbackNoFill.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVast}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVpaidSurveyThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVpaidSurveyThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.fallbackNoFill.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, thumbnail: '' }})}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.fallbackNoFill.thumbnail}
                >
                  {selectedItem.fallbackNoFill.thumbnail ? (
                    <>
                      <img src={selectedItem.fallbackNoFill.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
              </FormGroup>
            </Row>
          </Column>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
      </>
    );
  };

  renderVideoMode = () => {
    const { messageError } = this.state;
    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData,
      fileSizeConfigData,
    } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    const getVideoContentMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.video
        && fileSizeConfigData.creative.content.media.video.content
        && fileSizeConfigData.creative.content.media.video.content.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.video.content.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getVideoContentMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.video
        && fileSizeConfigData.creative.content.media.video.content
        && fileSizeConfigData.creative.content.media.video.content.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.video.content.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    const getVideoThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.video
        && fileSizeConfigData.creative.content.media.video.thumbnail
        && fileSizeConfigData.creative.content.media.video.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.video.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getVideoThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.video
        && fileSizeConfigData.creative.content.media.video.thumbnail
        && fileSizeConfigData.creative.content.media.video.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.video.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    return (
      <>
        <Row style={{ justifyContent: 'center' }}>
          <ColumnVideo style={{ marginRight: 20 }}>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.fallbackNoFill.content ? 'Alterar mídia' : 'Buscar mídia'}
                    onUpload={this.uploadFileVideo}
                    accept="video/mp4"
                    maxSize={getVideoContentMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `O vídeo excedeu o limite de ${getVideoContentMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.fallbackNoFill.content && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, content: '' }})}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewVideoBox
                  hasVideo={!!selectedItem.fallbackNoFill.content}
                  invalid={hasError && !selectedItem.fallbackNoFill.content}
                >
                  {selectedItem.fallbackNoFill.content ? (
                    <MidiaVideoPreview controls>
                      <source src={selectedItem.fallbackNoFill.content} />
                      Seu browser não suporta o preview de vídeos
                    </MidiaVideoPreview>
                  ) : 'Preview do vídeo'}
                </PreviewVideoBox>
              </FormGroup>
            </Row>
          </ColumnVideo>
          <ColumnVideo>
            <Row>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.fallbackNoFill.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailVideo}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getVideoThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getVideoThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => {
                      this.setState({ messageError: null });
                    }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.fallbackNoFill.thumbnail && !disabledFields && (
                    <Button
                      disabled={disabledFields}
                      title="REMOVER"
                      onClick={() => setSelectedData({ thumbnail: '' })}
                    />
                  )}
                </div>
              </FormGroup>
            </Row>
            <Row>
              <FormGroup style={{ flex: 1, alignItems: 'center' }}>
                <PreviewThumbnailVideoBox
                  hasThumbnail={!!selectedItem.fallbackNoFill.thumbnail}
                >
                  {selectedItem.fallbackNoFill.thumbnail ? (
                    <>
                      <img src={selectedItem.fallbackNoFill.thumbnail} alt="imagem" />
                      <FaYoutube />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailVideoBox>
              </FormGroup>
            </Row>
          </ColumnVideo>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
        {this.renderExternalUrlConfig()}
      </>
    );
  };

  renderImageMode = () => {
    const { messageError } = this.state;

    const {
      hasError,
      selectedItem,
      setSelectedData,
      fileSizeConfigData,
      disabledFields,
    } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    const getImageContentMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.image
        && fileSizeConfigData.creative.content.media.image.content
        && fileSizeConfigData.creative.content.media.image.content.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.image.content.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getImageContentMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.image
        && fileSizeConfigData.creative.content.media.image.content
        && fileSizeConfigData.creative.content.media.image.content.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.image.content.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    const {
      content, resolution, analytics, properties,
    } = selectedItem.fallbackNoFill;

    const autoNext = properties && properties.autoNext ? properties.autoNext : {
      active: false,
      timeoutSeconds: 0,
    };
    const nextButtonLabel = properties ? properties.nextButtonLabel : '';
    const analyticsProps = properties && properties.analytics ? properties.analytics : [];
    const isShowAnalytics = analyticsProps && analyticsProps.length > 0;
    const analyticsData = isShowAnalytics ? (analytics || {}) : null;

    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <FormGroup style={{ flex: 1, marginRight: 15 }}>
            <label>
              Continuar
              <span className="info-btn-continuar">Configuração de sequência para próxima mídia ou sucesso.</span>
            </label>
            <FieldWithLabel>
              <Radiobox
                label="Botão"
                value={autoNext.active === false}
                onChange={() => {
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      properties: {
                        ...properties,
                        autoNext: {
                          ...autoNext,
                          active: false,
                        },
                      },
                    }
                  });
                }}
                style={{ marginRight: 10 }}
                disabled={disabledFields}
              />
              <Radiobox
                label="Automático"
                value={autoNext.active === true}
                onChange={() => {
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      properties: {
                        ...properties,
                        autoNext: {
                          ...autoNext,
                          active: true,
                        },
                      },
                    }
                  });
                }}
                style={{ marginRight: 10 }}
                disabled={disabledFields}
              />
            </FieldWithLabel>
          </FormGroup>
          <FormGroup style={{ flex: 1 }}>
            <label>
              {autoNext.active ? 'Contagem regressiva' : 'Texto botão'}
              <span className="info-btn-continuar">
                {autoNext.active ? 'Valor em segundos' : 'Default: AVANÇAR'}
              </span>
            </label>
            {!autoNext.active && (
              <Input
                style={{ width: '100%' }}
                disabled={disabledFields}
                placeholder="Texto botão"
                value={nextButtonLabel}
                onChange={(e) => {
                  setSelectedData({
                   fallbackNoFill: {
                    ...selectedItem.fallbackNoFill,
                    properties: {
                      ...properties,
                      nextButtonLabel: e.target.value,
                    },
                  }
                });
                }}
              />
            )}
            {autoNext.active && (
              <Input
                style={{ width: '100%' }}
                disabled={disabledFields}
                placeholder="Contagem regressiva"
                type="number"
                value={autoNext.timeoutSeconds}
                onChange={(e) => {
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      properties: {
                        ...properties,
                        autoNext: {
                          ...autoNext,
                          timeoutSeconds: e.target.value,
                        },
                      },
                    }
                  });
                }}
              />
            )}
          </FormGroup>
        </Row>
        {analyticsProps && analyticsProps.length > 0 ? (
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Google Analytics
                <small> - Aceito somente alfanuméricos, underline e hífen(max 100 caracteres por TAG)</small>
              </label>
              <BaseInputsGA>
                {analyticsProps.map(item => (
                  <div key={item.key} className="item-ga">
                    <label>
                      {`${item.label}:`}
                    </label>
                    <Input
                      disabled={disabledFields}
                      placeholder="TAG Google Analytics"
                      maxLength={100}
                      value={analyticsData[item.key]}
                      onChange={(e) => {
                        setSelectedData({
                          fallbackNoFill: {
                            ...selectedItem.fallbackNoFill,
                            analytics: {
                              ...analyticsData,
                              [item.key]: e.target.value,
                            },
                          }
                        });
                      }}
                      invalid={hasError && !validateTagGA(analyticsData[item.key])}
                    />
                  </div>
                ))}
              </BaseInputsGA>
            </FormGroup>
          </Row>
        ) : null}
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <div style={{
              display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
            }}
            >
              <UploadImage
                disabled={disabledFields}
                label={content ? 'Alterar mídia' : 'Buscar mídia'}
                onUpload={this.uploadFileImage}
                accept="image/jpg,image/jpeg,image/png,image/gif"
                maxSize={getImageContentMaxSize()}
                onRejectMaxSize={() => {
                  this.setState({ messageError: `A imagem excedeu o limite de ${getImageContentMaxSizeLabel()}MB` });
                }}
                onClick={() => { }}
                styleContainer={{
                  width: 200,
                }}
                style={{
                  width: 200,
                  margin: 0,
                }}
                resolution={resolution && resolution.id !== 'not-resolution' ? resolution : null}
                onRejectResolution={() => {
                  this.setState({ messageError: `Arquivo não atendeu as dimensões recomendadas de ${resolution.width}x${resolution.height}` });
                }}
              />
              {!!content && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, content: '' }})}
                />
              )}
            </div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, alignItems: 'center' }}>
            <PreviewImageBox
              resolution={resolution}
              hasImage={!!content}
              invalid={hasError && !content}
            >
              {content ? (
                <img src={content} alt="imagem" />
              ) : (
                <>
                  <span style={{ textAlign: 'center' }}>Preview da imagem</span>
                  {resolution && resolution.id !== 'not-resolution' && (
                    <span className="resolution-info">
                      {`${resolution.width}x${resolution.height}`}
                    </span>
                  )}
                </>
              )}
            </PreviewImageBox>
          </FormGroup>
        </Row>
        <Row>
          <span className="extensoes-permitidas-image">
            Extensões permitidas: PNG, JPG, JPEG, GIF
          </span>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
        {this.renderExternalUrlConfig()}
      </>
    );
  };

  renderResearchMode = () => {
    const { selectedItem, setSelectedData, hasError, disabledFields } = this.props;
    return (
      <ResearchForm
        hasError={hasError}
        disabledFields={disabledFields}
        content={selectedItem.fallbackNoFill.content}
        onChangeForm={(content) => {
          setSelectedData({ fallbackNoFill: { ...selectedItem.fallbackNoFill, content }});
        }}
      />
    );
  };

  criarNovoItemFallback = () => {
    const { selectedItem, setSelectedData } = this.props;

    let fallbacksConfigAtual = selectedItem.fallbackNoFill['fallbacks-config'];
    if (!fallbacksConfigAtual) {
      fallbacksConfigAtual = [];
    }

    setSelectedData({
      fallbackNoFill: {
        ...selectedItem.fallbackNoFill,
        'fallbacks-config': [
          ...fallbacksConfigAtual,
          {
            uuid: uuid4(),
            type: 'google-admob-rv',
            params: [
              {
                key: 'id',
                value: '',
              },
            ],
          },
        ],
      }
    });
  }

  changeItemFallback = (item) => {
    const { selectedItem, setSelectedData } = this.props;

    setSelectedData({
      fallbackNoFill: {
        ...selectedItem.fallbackNoFill,
        'fallbacks-config': [
          ...selectedItem.fallbackNoFill['fallbacks-config'].map(i => (i.uuid !== item.uuid ? i : item)),
        ],
      }
    });
  }

  removerItemFallback = (itemId) => {
    const { selectedItem, setSelectedData } = this.props;

    setSelectedData({
      fallbackNoFill: {
        ...selectedItem.fallbackNoFill,
        'fallbacks-config': [
          ...selectedItem.fallbackNoFill['fallbacks-config'].filter(i => i.uuid !== itemId),
        ],
      }
    });
  }

  renderProgramaticaMode = () => {
    const { messageError } = this.state;
    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData,
      fileSizeConfigData,
    } = this.props;
    if(!selectedItem || !selectedItem.fallbackNoFill) return;

    const getProgramaticaThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.programatica
        && fileSizeConfigData.creative.content.media.programatica.thumbnail
        && fileSizeConfigData.creative.content.media.programatica.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.programatica.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getProgramaticaThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.programatica
        && fileSizeConfigData.creative.content.media.programatica.thumbnail
        && fileSizeConfigData.creative.content.media.programatica.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.programatica.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    const typeList = [
      {
        label: 'Google Admob',
        value: 'google-admob-rv',
      },
      {
        label: 'inteliFI',
        value: 'intelifi',
      },
      {
        label: 'Iron Source',
        value: 'iron-source-rv',
      },
      {
        label: 'Tapjoy',
        value: 'tapjoy-rv',
      },
    ];

    return (
          <>
            <Row style={{ marginBottom: 10 }}>
              <FormGroup style={{ flex: 1 }}>
                <label>
                  Integração
                  <span>*</span>
                </label>
                <Select
                  isDisabled={disabledFields}
                  placeholder="Selecione Integração..."
                  value={typeList.find(item => item.value === selectedItem.fallbackNoFill.config.type)}
                  onChange={({ value }) => {
                    setSelectedData({
                      fallbackNoFill: {
                        ...selectedItem.fallbackNoFill,
                        config: {
                          ...selectedItem.config,
                          type: value,
                        },
                      }
                    });
                  }}
                  options={typeList}
                  invalid={hasError && !selectedItem.fallbackNoFill.config.type}
                />
              </FormGroup>
              <FormGroup style={{ flex: 3, marginLeft: 15 }}>
                <label>
                  Chave
                  <span>*</span>
                </label>
                <Input
                  disabled={disabledFields}
                  placeholder="Digite a chave"
                  maxLength={100}
                  onChange={(e) => {
                    const newData = selectedItem.fallbackNoFill.config.params[0];
                    newData.value = e.target.value;
                    setSelectedData({
                      fallbackNoFill: {
                        ...selectedItem.fallbackNoFill,
                        config: {
                          ...selectedItem.fallbackNoFill.config,
                          params: [newData],
                        },
                      }
                    });
                  }}
                  value={selectedItem.fallbackNoFill.config.params[0].value}
                  invalid={hasError && !selectedItem.fallbackNoFill.config.params[0].value}
                  autoFocus
                />
              </FormGroup>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <BaseItemsProgramatia>
                <p className="title-base-items-programatica">Sequência de cascateamento</p>
                {selectedItem.fallbackNoFill['fallbacks-config'] && selectedItem.fallbackNoFill['fallbacks-config'].map(fallbackConfigItem => (
                  <Row key={fallbackConfigItem.uuid} style={{ marginBottom: 10 }}>
                    <FormGroup style={{ flex: 1 }}>
                      <label>
                        Integração
                      </label>
                      <Select
                        isDisabled={disabledFields}
                        placeholder="Selecione Integração..."
                        value={typeList.find(item => item.value === fallbackConfigItem.type)}
                        onChange={({ value }) => {
                          this.changeItemFallback({
                            ...fallbackConfigItem,
                            type: value,
                          });
                        }}
                        options={typeList}
                      />
                    </FormGroup>
                    <FormGroup style={{ flex: 3, marginLeft: 15 }}>
                      <label>
                        Chave
                      </label>
                      <Input
                        disabled={disabledFields}
                        placeholder="Digite a chave"
                        maxLength={100}
                        onChange={(e) => {
                          const newData = fallbackConfigItem.params[0];
                          newData.value = e.target.value;
                          this.changeItemFallback({
                            ...fallbackConfigItem,
                            params: [newData],
                          });
                        }}
                        value={fallbackConfigItem.params[0].value}

                      />
                    </FormGroup>
                    <FormGroup style={{ marginLeft: 5, marginTop: 25, marginRight: 5 }}>
                      <ActionButton
                        onClick={() => { this.removerItemFallback(fallbackConfigItem.uuid); }}
                        data-tip="Excluir item"
                        disabled={disabledFields}
                      >
                        <FaTrash />
                      </ActionButton>
                    </FormGroup>
                  </Row>
                ))}
                <Row>
                  <div className="base-buttom-add-item">
                    <Button
                      disabled={disabledFields}
                      onClick={() => this.criarNovoItemFallback()}
                      bordered
                      small
                      icon="FaPlus"
                      title="NOVO ITEM"
                    />
                  </div>
                </Row>
              </BaseItemsProgramatia>
            </Row>
            <Row style={{ marginBottom: 10 }}>
              <FormGroup style={{ flex: 1 }}>
                <div style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
                >
                  <Upload
                    disabled={disabledFields}
                    label={selectedItem.fallbackNoFill.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                    onUpload={this.uploadFileThumbnailProgramatic}
                    accept="image/jpg,image/jpeg,image/png"
                    maxSize={getProgramaticaThumbnailMaxSize()}
                    onRejectMaxSize={() => {
                      this.setState({ messageError: `A imagem excedeu o limite de ${getProgramaticaThumbnailMaxSizeLabel()}MB` });
                    }}
                    onClick={() => { }}
                    styleContainer={{
                      width: 200,
                    }}
                    style={{
                      width: 200,
                      margin: 0,
                    }}
                  />
                  {!!selectedItem.fallbackNoFill.thumbnail && !disabledFields && (
                  <Button
                    disabled={disabledFields}
                    title="REMOVER"
                    onClick={() => setSelectedData({ fallbackNoFill: {...selectedItem.fallbackNoFill, thumbnail: '' }})}
                  />
                  )}
                </div>
                <PreviewThumbnailBox
                  hasThumbnail={!!selectedItem.fallbackNoFill.thumbnail}
                  style={{ alignSelf: 'center' }}
                >
                  {selectedItem.fallbackNoFill.thumbnail ? (
                    <>
                      <img src={selectedItem.fallbackNoFill.thumbnail} alt="imagem" />
                    </>
                  ) : 'Preview da thumbnail'}
                </PreviewThumbnailBox>
                {messageError && (
                  <Row>
                    <ErrorMessage>{messageError}</ErrorMessage>
                  </Row>
                )}
              </FormGroup>
            </Row>
          </>
    );
  };

  renderAppInstallMode = () => {
    const { messageError } = this.state;
    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData,
      fileSizeConfigData,
    } = this.props;

    const getAppInstallThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.app_install
        && fileSizeConfigData.creative.content.media.app_install.thumbnail
        && fileSizeConfigData.creative.content.media.app_install.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.app_install.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getAppInstallThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.app_install
        && fileSizeConfigData.creative.content.media.app_install.thumbnail
        && fileSizeConfigData.creative.content.media.app_install.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.app_install.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    const { properties: { partners } } = selectedItem.fallbackNoFill;

    const partnersList = partners.map(partner => ({
      value: partner.id,
      label: partner.name,
      partner,
    }));

    return (
      <Row style={{ justifyContent: 'flex-start' }}>
        <Column style={{ flex: 2 }}>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Parceiro
                <span>*</span>
              </label>
              <Select
                isDisabled={disabledFields}
                placeholder="Selecione Integração..."
                value={selectedItem.fallbackNoFill.content.partner ? partnersList.find(item => item.value === selectedItem.fallbackNoFill.content.partner.id) : null}
                onChange={({ partner }) => {
                  const { content } = selectedItem.fallbackNoFill;
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      content: {
                        ...content,
                        partner,
                      },
                    }
                  });
                }}
                options={partnersList}
                invalid={hasError && !selectedItem.fallbackNoFill.content.partner}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Título
                <span>*</span>
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite um título"
                maxLength={50}
                onChange={(e) => {
                  const { content } = selectedItem.fallbackNoFill;
                  const title = e.target.value;
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      content: {
                        ...content,
                        title,
                      },
                    }
                  });
                }}
                value={selectedItem.fallbackNoFill.content.title}
                invalid={hasError && !selectedItem.fallbackNoFill.content.title}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Descrição
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite uma descrição"
                maxLength={100}
                onChange={(e) => {
                  const { content } = selectedItem.fallbackNoFill;
                  const description = e.target.value;
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      content: {
                        ...content,
                        description,
                      },
                    }
                  });
                }}
                value={selectedItem.fallbackNoFill.content.description}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Link
                <span>*</span>
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite um link"
                onChange={(e) => {
                  const { content } = selectedItem.fallbackNoFill;
                  const link = e.target.value;
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      content: {
                        ...content,
                        link,
                      },
                    }
                  });
                }}
                value={selectedItem.fallbackNoFill.content.link}
                invalid={hasError && (!selectedItem.fallbackNoFill.content.link || (selectedItem.fallbackNoFill.content.link && !validateUrl(selectedItem.fallbackNoFill.content.link)))}
              />
            </FormGroup>
          </Row>
        </Column>
        <Column style={{ flex: 1 }}>
          <Row>
            <FormGroup style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
              >
                <UploadImage
                  disabled={disabledFields}
                  label={selectedItem.fallbackNoFill.content.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                  onUpload={this.uploadFileThumbnailAppInstall}
                  accept="image/jpg,image/jpeg,image/png"
                  maxSize={getAppInstallThumbnailMaxSize()}
                  onRejectMaxSize={() => {
                    this.setState({ messageError: `A imagem excedeu o limite de ${getAppInstallThumbnailMaxSizeLabel()}MB` });
                  }}
                  onClick={() => { }}
                  styleContainer={{
                    width: 200,
                  }}
                  style={{
                    width: 200,
                    margin: 0,
                  }}
                  resolution={resolutionThumbnailAppInstall}
                  onRejectResolution={() => {
                    this.setState({ messageError: `Arquivo não atendeu as dimensões recomendadas de ${192}x${192}` });
                  }}
                />
                {!!selectedItem.fallbackNoFill.content.thumbnail && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    const { content } = selectedItem.fallbackNoFill;
                    setSelectedData({
                      fallbackNoFill: {
                        ...selectedItem.fallbackNoFill,
                        content: {
                          ...content,
                          thumbnail: '',
                        },
                      }
                    });
                  }}
                />
                )}
              </div>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup style={{ flex: 1, alignItems: 'center' }}>
              <PreviewImageBox
                resolution={resolutionThumbnailAppInstall}
                hasImage={!!selectedItem.fallbackNoFill.content.thumbnail}
              >
                {selectedItem.fallbackNoFill.content.thumbnail ? (
                  <img src={selectedItem.fallbackNoFill.content.thumbnail} alt="imagem" />
                ) : (
                  <>
                    <span style={{ textAlign: 'center' }}>Preview da thumbnail</span>
                    <span className="resolution-info">
                      192x192
                    </span>
                  </>
                )}
              </PreviewImageBox>
            </FormGroup>
          </Row>
          {messageError && (
            <Row>
              <ErrorMessage>{messageError}</ErrorMessage>
            </Row>
          )}
        </Column>
      </Row>
    );
  };

  renderExternalRedirectMode = () => {
    const { messageError } = this.state;
    const {
      hasError,
      disabledFields,
      selectedItem,
      setSelectedData,
      fileSizeConfigData,
    } = this.props;

    const getExternalRedirectThumbnailMaxSize = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.external_redirect
        && fileSizeConfigData.creative.content.media.external_redirect.thumbnail
        && fileSizeConfigData.creative.content.media.external_redirect.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.external_redirect.thumbnail.maxFileSizeMB * 1000000;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
        }
        return 2000000;
    }

    const getExternalRedirectThumbnailMaxSizeLabel = () => {
      if(
        fileSizeConfigData
        && fileSizeConfigData.creative
        && fileSizeConfigData.creative.content
        && fileSizeConfigData.creative.content.media
        && fileSizeConfigData.creative.content.media.external_redirect
        && fileSizeConfigData.creative.content.media.external_redirect.thumbnail
        && fileSizeConfigData.creative.content.media.external_redirect.thumbnail.maxFileSizeMB
      ) {
          return fileSizeConfigData.creative.content.media.external_redirect.thumbnail.maxFileSizeMB;
        }
        if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
          return fileSizeConfigData.defaultMaxFileSizeMB;
        }
        return 2;
    }

    return (
      <Row style={{ justifyContent: 'flex-start' }}>
        <Column style={{ flex: 2 }}>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Texto explicativo (opcional)
              </label>
              <TextEditor
                disabled={disabledFields}
                value={selectedItem.fallbackNoFill.content.infoPrimary}
                onChange={(infoPrimary) => {
                  const { content } = selectedItem.fallbackNoFill;
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      content: {
                        ...content,
                        infoPrimary,
                      },
                    }
                  });
                }}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Texto complementar (opcional)
              </label>
              <TextEditor
                disabled={disabledFields}
                value={selectedItem.fallbackNoFill.content.infoSecondary}
                onChange={(infoSecondary) => {
                  const { content } = selectedItem.fallbackNoFill;
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      content: {
                        ...content,
                        infoSecondary,
                      },
                    }
                  });
                }}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Texto botão
                <span>*</span>
              </label>
              <Input
                style={{ width: '100%' }}
                disabled={disabledFields}
                placeholder="Texto botão"
                onChange={(e) => {
                  const { content } = selectedItem.fallbackNoFill;
                  const actionButtonLabel = e.target.value;
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      content: {
                        ...content,
                        actionButtonLabel,
                      },
                    }
                  });
                }}
                value={selectedItem.fallbackNoFill.content.actionButtonLabel}
                invalid={hasError && !selectedItem.fallbackNoFill.content.actionButtonLabel}
              />
            </FormGroup>
          </Row>
          <Row style={{ marginBottom: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Link externo
                <span>*</span>
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite um link"
                onChange={(e) => {
                  const { content } = selectedItem.fallbackNoFill;
                  const link = e.target.value;
                  setSelectedData({
                    fallbackNoFill: {
                      ...selectedItem.fallbackNoFill,
                      content: {
                        ...content,
                        link,
                      },
                    }
                  });
                }}
                value={selectedItem.fallbackNoFill.content.link}
                invalid={
                  hasError
                  && (
                    !selectedItem.fallbackNoFill.content.link
                    || (
                      selectedItem.fallbackNoFill.content.link
                      && !validateUrl(selectedItem.fallbackNoFill.content.link)
                    )
                  )
                }
              />
              <InfoLinkExternoExternalRedirect>
                <p>Variáveis de enriquecimento de URL disponíveis:</p>
                <div className="options-info">
                  <span>(campaignuuid)</span>
                  <span>(reqid)</span>
                  <span>(userid)</span>
                  <span>(midiauuid)</span>
                  <span>(clickid)</span>
                </div>
              </InfoLinkExternoExternalRedirect>
            </FormGroup>
          </Row>
        </Column>
        <Column style={{ flex: 1 }}>
          <Row>
            <FormGroup style={{ flex: 1 }}>
              <div
                style={{
                  display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
                }}
              >
                <UploadImage
                  disabled={disabledFields}
                  label={selectedItem.fallbackNoFill.content.thumbnail ? 'Alterar thumbnail' : 'Buscar thumbnail'}
                  onUpload={this.uploadFileThumbnailAppInstall}
                  accept="image/jpg,image/jpeg,image/png"
                  maxSize={getExternalRedirectThumbnailMaxSize()}
                  onRejectMaxSize={() => {
                    this.setState({ messageError: `A imagem excedeu o limite de ${getExternalRedirectThumbnailMaxSizeLabel()}MB` });
                  }}
                  onClick={() => { }}
                  styleContainer={{
                    width: 200,
                  }}
                  style={{
                    width: 200,
                    margin: 0,
                  }}
                  resolution={resolutionThumbnailExternalRedirect}
                  onRejectResolution={() => {
                    this.setState({ messageError: `Arquivo não atendeu as dimensões recomendadas de ${192}x${192}` });
                  }}
                />
                {!!selectedItem.fallbackNoFill.content.thumbnail && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    const { content } = selectedItem.fallbackNoFill;
                    setSelectedData({
                      fallbackNoFill: {
                        ...selectedItem.fallbackNoFill,
                        content: {
                          ...content,
                          thumbnail: '',
                        },
                      }
                    });
                  }}
                />
                )}
              </div>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup style={{ flex: 1, alignItems: 'center' }}>
              <PreviewImageBox
                resolution={resolutionThumbnailExternalRedirect}
                hasImage={!!selectedItem.fallbackNoFill.content.thumbnail}
              >
                {selectedItem.fallbackNoFill.content.thumbnail ? (
                  <img src={selectedItem.fallbackNoFill.content.thumbnail} alt="imagem" />
                ) : (
                  <>
                    <span style={{ textAlign: 'center' }}>Preview da thumbnail</span>
                    <span className="resolution-info">
                      192x192
                    </span>
                  </>
                )}
              </PreviewImageBox>
            </FormGroup>
          </Row>
          {messageError && (
            <Row>
              <ErrorMessage>{messageError}</ErrorMessage>
            </Row>
          )}
        </Column>
      </Row>
    );
  };

  renderForm = () => {
    const { selectedItem } = this.props;
    if (!selectedItem || !selectedItem.fallbackNoFill) return null;

    return (
      <FormMediaWrap>
        <FormMedia>
          {this.renderFormHeader()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.VIDEO && this.renderVideoMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.IMAGE && this.renderImageMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.VAST && this.renderVastMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.VAST_SURVEY && this.renderVastSurveyMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.VPAID && this.renderVpaidMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.VPAID_SURVEY && this.renderVpaidSurveyMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.DATA_SEARCH && this.renderResearchMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.PROGRAMATICA && this.renderProgramaticaMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.APP_INSTALL && this.renderAppInstallMode()}
          {selectedItem.fallbackNoFill.type === CreativeCustomMidiaType.EXTERNAL_REDIRECT && this.renderExternalRedirectMode()}
        </FormMedia>
      </FormMediaWrap>
    );
  };

  render() {
    return (
      <WrapContent>
        {this.renderForm()}
      </WrapContent>
    );
  }
}

MediaNoFill.defaultProps = {
  fileSizeConfigData: null,
};

MediaNoFill.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  selectedItem: PropTypes.shape().isRequired,
  setSelectedData: PropTypes.func.isRequired,
  closeMediaNofill: PropTypes.func.isRequired,
  changeData: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  confirmModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  fileSizeConfigData: state.campaignEdit.fileSizeConfigData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaNoFill);
