import React from 'react';
import PropTypes from 'prop-types';
import InputLink from './components/InputLink';

import Const from '~/Utils/Const';
import { validateUrl } from '~/Utils';

let linkIncluido = false;

const SMSTextMessageForm = ({
  criativoEdit, campaignEditCriativoRequestSuccess, hasError, disabledFields,
}) => {
  const { content } = criativoEdit;
  const getValueMessage = () => {
    if (content.channelData && content.channelData.message) {
      return content.channelData.message;
    }
    return '';
  };

  const getValueUrl = () => {
    if (content.channelData && content.channelData.url) {
      return content.channelData.url;
    }
    return '';
  };

  const setValueMessage = (value) => {
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        channelData: {
          message: value,
          url: getValueUrl(),
        },
      },
    });
  };

  const setValueUrl = (value) => {
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        channelData: {
          message: getValueMessage(),
          url: value,
        },
      },
    });
  };

  const setLinkIncluido = (value) => {
    linkIncluido = value;
  };

  const getValidateLink = () => {
    if (linkIncluido && content.channelData) {
      if (!content.channelData.url || !validateUrl(content.channelData.url)) {
        return false;
      }
    }
    return true;
  };

  return (
    <InputLink
      disabled={disabledFields}
      tagLinkInclude={Const.TAG_LINK_INCLUDE}
      onChange={(value) => {
        setValueMessage(value.trim());
      }}
      onChangeLink={(value) => {
        setValueUrl(value.trim());
      }}
      onChangeLinkIncluido={(value) => {
        setLinkIncluido(value);
      }}
      value={getValueMessage()}
      linkValue={getValueUrl()}
      linkInvalid={hasError && !getValidateLink()}
      isValidateInputs={hasError && !getValueMessage()}
      maxLength={160}
    />
  );
};

SMSTextMessageForm.propTypes = {
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

export default SMSTextMessageForm;
