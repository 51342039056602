import styled from 'styled-components';

import { InputStyled } from '~/Components/Styled';
import ButtonStyled from '~/Components/Button';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.div`
  margin: 10px 5px;
  border: 2px solid ${props => props.theme.colors.primaryHex};
  background-color: ${props => props.theme.colors.primaryHex}50;
  padding: 5px;
  font-size: 14px;
  text-align: center;
  font-style: italic;
`;

export const Input = styled(InputStyled)``;

export const Button = styled(ButtonStyled)``;

export const Body = styled.div``;

export const BaseInclude = styled.div`
  display: flex;
  align-items: center;
  padding: 0 5px;

  > input {
    width: 100%;
    margin-right: 0;
  }
`;

export const Categories = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 415px;
  overflow: auto;
  margin-top: 10px;
  border-top: 1px solid #ccc;
`;

export const Category = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;

`;

export const Name = styled.h4``;

export const Remove = styled.div`
  cursor: pointer;
`;
