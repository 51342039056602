import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Icone from '~/Components/Icone';

import {
  Container, ChildrenData, Item, ButtonShowData, NoShowData, BaseIcon, Title, Subtitle, BaseInfo,
} from './styles';

function ItemTree({ item, itemSelectedId, onClick }) {
  const [openChildren, setOpenChildren] = useState(false);

  const handleClickOpenChildren = () => {
    setOpenChildren(!openChildren);
  };

  if (!item.show) {
    return null;
  }

  const showButton = item.data && item.data.length && item.data.find(d => d.show);

  return (
    <Container key={item.id}>
      <Item selected={item.id === itemSelectedId}>
        {showButton ? (
          <ButtonShowData onClick={handleClickOpenChildren}>
            <Icone icon={openChildren ? 'caret-down' : 'caret-right'} color="darker" size={40} />
          </ButtonShowData>
        ) : <NoShowData />}
        <BaseInfo className="base-info" onClick={() => onClick(item)}>
          <div className="base-info-title">
            {item.icon && (
              <BaseIcon>
                <Icone icon={item.icon} color="darker" size={30} />
              </BaseIcon>
            )}
            <Title>{item.title}</Title>
          </div>
          {item.first && (<Subtitle>{item.subtitle}</Subtitle>)}
        </BaseInfo>
      </Item>
      {openChildren && (
        <ChildrenData>
          {item.data.map(i => <ItemTree item={i} itemSelectedId={itemSelectedId} onClick={onClick} />)}
        </ChildrenData>
      )}
    </Container>
  );
}

ItemTree.propTypes = {
  item: PropTypes.shape().isRequired,
};

export default ItemTree;
