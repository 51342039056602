import api from './axios';

const uploadFile = async (body, referenceKey = '') => {
  try {
    const { data } = await api.post(
      `image-files?referenceKey=${referenceKey}`,
      body,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const getFile = async (uuid) => {
  try {
    const { data } = await api.get(
      `image-files/${uuid}`,
      {
        responseType: 'blob',
      },
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  uploadFile,
  getFile,
};
