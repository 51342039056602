import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FaFilePdf, FaFile, FaFileImage, FaTrash,
} from 'react-icons/fa';

import Button from '~/Components/Button';

import {
  Container, DeleteFile, InvoiceFile, ReceiptFile,
} from './styles';

export default class FileItem extends Component {
  componentDidMount() {}

  getIconFromFileType = (fileName) => {
    const extFile = fileName.split('.').pop().toUpperCase();
    if (['PDF'].includes(extFile)) return <FaFilePdf />;
    if (['JPG', 'PNG', 'JPEG', 'GIF', 'SVG', 'JFIF', 'BMP', 'PSD', 'TIFF', 'EXIF'].includes(extFile)) return <FaFileImage />;
    return <FaFile />;
  };

  render() {
    const {
      file, onDownloadInvoice, onDownloadReceipt, hasDeleteButton, onDelete,
    } = this.props;
    return (
      <Container>
        {file.invoice && (
          <InvoiceFile>
            <div className="file-item-icon">
              {this.getIconFromFileType(file.invoice.name)}
            </div>
            <div className="file-item-name">
              {file.invoice.name}
            </div>
            <Button
              icon="FaCloudDownloadAlt"
              title="Baixar nota"
              bordered
              onClick={onDownloadInvoice}
              style={{
                margin: 0,
                width: 150,
                height: 30,
                fontSize: 14,
                alignSelf: 'center',
              }}
            />
          </InvoiceFile>
        )}
        {!!file.receipt && (
          <ReceiptFile>
            <div className="file-item-icon">
              {this.getIconFromFileType(file.receipt.name)}
            </div>
            <div className="file-item-name">
              {file.receipt.name}
            </div>
            <Button
              icon="FaCloudDownloadAlt"
              title="Baixar recibo"
              bordered
              onClick={onDownloadReceipt}
              style={{
                margin: 0,
                width: 170,
                height: 30,
                fontSize: 14,
                alignSelf: 'center',
              }}
            />
          </ReceiptFile>
        )}
        {hasDeleteButton && (
          <DeleteFile onClick={onDelete}>
            <FaTrash />
          </DeleteFile>
        )}
      </Container>
    );
  }
}

FileItem.defaultProps = {
  file: null,
  onDownloadInvoice: () => {},
  onDownloadReceipt: () => {},
  onDelete: () => {},
  hasDeleteButton: false,
};

FileItem.propTypes = {
  file: PropTypes.shape(),
  onDownloadInvoice: PropTypes.func,
  onDownloadReceipt: PropTypes.func,
  onDelete: PropTypes.func,
  hasDeleteButton: PropTypes.bool,
};
