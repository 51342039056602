import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  width: 100%;
  margin-right: ${props => props.marginRight}px;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 15px;
  min-height: 118px;
  .benefit-box-items {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    .benefit-box-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      .benefit-box-item-content {
        display: flex;
        .benefit-box-item-separator {
            display: flex;
            align-items: center;
            justify-content: center;
            min-height: 35px;
            height: 35px;
            min-width: 20px;
            width: 20px;
            font-size: 25px;
        }
        .benefit-box-item-wrap {
            display: flex;
            min-height: 35px;
            height: 35px;
          .benefit-box-item-wrap-type {
            display: flex;
            align-items: center;
            border: 3px solid ${props => props.theme.colors.primaryHex};
            background-color: ${props => props.theme.colors.primaryHex}50;
            font-weight: bold;
            padding: 0 5px;
          }
          .benefit-box-item-wrap-value {
            display: flex;
            align-items: center;
            padding: 0 5px;
            border: 3px solid ${props => props.theme.colors.lighter};
          }
        }
      }
      span {
        min-height: 22px;
      }
    }
  }
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;
`;

export const BoxContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

export const BoxContentItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  flex: ${props => props.flex};
  span {
    min-height: 22px;
  }
`;
