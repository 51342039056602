import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  background: ${props => props.theme.colors.light};
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 0;
  display: flex;

  > input, > span {
    flex: 1;
  }

  &:focus-within {
    outline: -webkit-focus-ring-color auto 1px;
    outline-offset: 0px;
  }

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}

  > input {
    padding: 5px;
    flex: 1;
    width: 100%;
    background: white;
    border: 0;
    &:hover, &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${props => props.theme.colors.light};
    }
  }

  span {
    background: transparent;
    padding: 0 5px;
    height: 95%;
    margin: 1px;
    margin-bottom: 2px;
    color: ${props => props.theme.colors.dark};
    font-style: italic;
    font-size: 14px;
    display: flex;
    align-items: center;

    &.value-prefix {
      justify-content: flex-end;
    }
  }

  ${props => props.disabled && css`
    opacity: 0.6;
    > input {
      opacity: 0.6;
    }
  `}
`;
