import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';
import moment from 'moment';

import { Creators as ReportActions } from '~/redux/ducks/report';
import {
  Container,
  Row,
  Table,
  TableDisplayRow,
  Select,
  Header,
  HeaderForm,
  HeaderFormInput,
  // box
  BoxTable,
  BoxTableHeader,
  BoxTableHeaderTitle,
  BoxTableContent,
} from './styles';

import { getBudgetLabelString } from '~/Utils';

import Button from '~/Components/Button';
import ModalResults from './Components/ModalResults';

class ReportGridPreriodList extends Component {
  state = {
    modalResultOpen: false,
    campaignResult: null,
  }

  componentDidMount() {
    const { reportMonthlyGridPeriodSetup } = this.props;
    reportMonthlyGridPeriodSetup();
  }

  componentWillUnmount() {
    const {
      reportGridPeriodChannelRequestSuccess,
      reportMonthlyGridPeriodRequestSuccess,
      reportGridPeriodSetFilter,
    } = this.props;
    reportGridPeriodChannelRequestSuccess([]);
    reportMonthlyGridPeriodRequestSuccess([]);
    reportGridPeriodSetFilter({
      channel: null,
      period: null,
      month: null,
      type: null,
    });
  }

  handleOpenModalResult = (gridUuid, campaignUuid, gridPeriodUuid) => {
    const { reportGridPeriodLoadReportData } = this.props;
    reportGridPeriodLoadReportData(
      gridUuid,
      campaignUuid,
      gridPeriodUuid,
    );
  };

  renderHeader = () => {
    const {
      channelList,
      typeList,
      gridPeriodFilter,
      reportGridPeriodSetFilter,
      reportMonthlyGridPeriodRequest,
      monthList,
    } = this.props;

    const types = [...typeList];
    const months = [...monthList];

    return (
      <Header>
        <HeaderForm>
          <HeaderFormInput>
            <label>Mês:</label>
            <Select
              placeholder="Selecione o mês"
              value={gridPeriodFilter.month}
              onChange={(month) => {
                reportGridPeriodSetFilter({ month });
                reportMonthlyGridPeriodRequest();
              }}
              options={months}
            />
          </HeaderFormInput>
          <HeaderFormInput>
            <label>Canal:</label>
            <Select
              placeholder="Selecione o canal"
              value={gridPeriodFilter.channel}
              onChange={(channel) => {
                reportGridPeriodSetFilter({ channel });
                reportMonthlyGridPeriodRequest();
              }}
              options={channelList}
            />
          </HeaderFormInput>
          <HeaderFormInput>
            <label>Tipo:</label>
            <Select
              placeholder="Selecione o tipo"
              value={gridPeriodFilter.type}
              onChange={(type) => {
                reportGridPeriodSetFilter({ type });
                reportMonthlyGridPeriodRequest();
              }}
              options={types}
            />
          </HeaderFormInput>
        </HeaderForm>
      </Header>
    );
  };

  renderMonthlyGridItem = item => (
    <Row key={item.campaignType.uuid}>
      <BoxTable>
        <BoxTableHeader>
          <BoxTableHeaderTitle>{item.campaignType.label || item.campaignType.name || ''}</BoxTableHeaderTitle>
        </BoxTableHeader>
        <BoxTableContent>
          <Table>
            <thead>
              <tr>
                <th>DATA</th>
                <th>NOME</th>
                <th>PRODUTO - OFERTA</th>
                <th align="center" width="1%">BUDGET</th>
                <th align="center" width="1%">CPA</th>
                <th align="center" width="1%">VOLUME</th>
                <th align="center" width="1%">AÇÕES</th>
              </tr>
            </thead>
            <tbody>
              {item.campaignSchedulings.map(grid => (
                <tr key={grid.campaign.uuid}>
                  <td>
                    <span>{moment(grid.scheduling.date).format('DD/MM/YYYY')}</span>
                  </td>
                  <td>
                    <span>{grid.campaign.name}</span>
                  </td>
                  <td>
                    {grid.campaign.productOffers.map(po => (
                      <span key={po.uuid}>
                        {`${po.product.name} - ${po.name}`}
                        <br />
                      </span>
                    ))}
                  </td>
                  <td align="center">
                    <span className="nowrap">{getBudgetLabelString(grid.budget)}</span>
                  </td>
                  <td align="center">
                    <span className="nowrap">{`R$ ${grid.cpa.replace('.', ',')}`}</span>
                  </td>
                  <td align="center">
                    <span className="nowrap">{grid.volume.toLocaleString()}</span>
                  </td>
                  <td align="center">
                    <TableDisplayRow>
                      <Button
                        disabled
                        icon="FaChartLine"
                        noLabel
                        onClick={() => {
                          this.handleOpenModalResult(
                            grid.uuid,
                            grid.campaign.uuid,
                            grid.gridPeriod.uuid,
                          );
                        }}
                        data-tip
                        data-for={`btn-result-${item.uuid}`}
                      />
                      <ReactTooltip
                        className="tooltip-report"
                        effect="solid"
                        id={`btn-result-${item.uuid}`}
                        aria-haspopup="true"
                      >
                        Relatório
                      </ReactTooltip>
                    </TableDisplayRow>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </BoxTableContent>
      </BoxTable>
    </Row>
  );

  renderContent = () => {
    const {
      monthlyGridPeriod,
    } = this.props;
    return monthlyGridPeriod.length > 0 ? (
      monthlyGridPeriod.map(item => this.renderMonthlyGridItem(item))
    ) : (
      <Row style={{ justifyContent: 'center' }}>
        <span className="empty-campaign">
          Não foram localizados dados de campanhas
        </span>
      </Row>
    );
  };

  render() {
    const { modalResultOpen, campaignResult } = this.state;
    return (
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>RESUMO DE FECHAMENTO MENSAL</h1>
        </Row>
        {this.renderHeader()}
        {this.renderContent()}
        <ModalResults
          visible={modalResultOpen}
          result={campaignResult}
          onClose={() => {
            this.setState({ modalResultOpen: false });
          }}
        />
      </Container>
    );
  }
}

ReportGridPreriodList.defaultProps = {
  monthlyGridPeriod: [],
  monthList: [],
  typeList: [],
};

ReportGridPreriodList.propTypes = {
  monthList: PropTypes.arrayOf(PropTypes.shape()),
  typeList: PropTypes.arrayOf(PropTypes.shape()),
  monthlyGridPeriod: PropTypes.arrayOf(PropTypes.shape()),
  channelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gridPeriodFilter: PropTypes.shape().isRequired,
  reportMonthlyGridPeriodSetup: PropTypes.func.isRequired,
  reportMonthlyGridPeriodRequest: PropTypes.func.isRequired,
  reportMonthlyGridPeriodRequestSuccess: PropTypes.func.isRequired,
  reportGridPeriodChannelRequestSuccess: PropTypes.func.isRequired,
  reportGridPeriodSetFilter: PropTypes.func.isRequired,
  // report
  reportGridPeriodLoadReportData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  channelList: state.report.channelList,
  gridPeriodFilter: state.report.gridPeriodFilter,
  monthlyGridPeriod: state.report.monthlyGridPeriod,
  monthList: state.report.monthList,
  typeList: state.report.typeList,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    // report actions
    reportMonthlyGridPeriodSetup: ReportActions.reportMonthlyGridPeriodSetup,
    reportMonthlyGridPeriodRequest: ReportActions.reportMonthlyGridPeriodRequest,
    reportMonthlyGridPeriodRequestSuccess: ReportActions.reportMonthlyGridPeriodRequestSuccess,
    reportGridPeriodChannelRequestSuccess: ReportActions.reportGridPeriodChannelRequestSuccess,
    reportGridPeriodSetFilter: ReportActions.reportGridPeriodSetFilter,
    // report
    reportGridPeriodLoadReportData: ReportActions.reportGridPeriodLoadReportData,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportGridPreriodList);
