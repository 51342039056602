import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  getCredentials: null,
  getCredentialsSuccess: ['data'],
});

export const CredentialsTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  data: null,
});

/* Reducers */
const getCredentialsSuccess = (state, { data }) => ({
  ...state,
  data,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CredentialsTypes.GET_CREDENTIALS_SUCCESS]: getCredentialsSuccess,
});
