import api from './axios';

const gridPeriodRequest = async (gridPeriodUui, zoneUuid) => {
  try {
    const { data } = await api.get(`gridperiod/${gridPeriodUui}/${zoneUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const channelRequest = async () => {
  try {
    const { data } = await api.get('auction/channel');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const periodRequest = async () => {
  try {
    const { data } = await api.get('auction/period');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const senderRequest = async () => {
  try {
    const { data } = await api.get('griditem/senders');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getCampaignByFilter = async (filter) => {
  const {
    page,
    type,
    zone,
    minCPA,
    maxCPA,
    contract,
    product,
  } = filter;
  let url = `workflow/campaign?page=${page}`;
  url += '&statusName=ACTIVE';
  if (type) {
    url += `&typeUuid=${type}`;
  }
  if (zone) {
    url += `&zoneUuid=${zone}`;
  }
  if (minCPA) {
    url += `&minCPA=${minCPA}`;
  }
  if (maxCPA) {
    url += `&maxCPA=${maxCPA}`;
  }
  if (contract) {
    url += `&contractUuid=${contract.value}`;
  }
  if (product) {
    url += `&productUuid=${product.value}`;
  }
  try {
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const saveGridFinal = async (gridPeriodUuid) => {
  try {
    const { data } = await api.post(
      `gridperiod/${gridPeriodUuid}/schedule`,
      {},
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const saveGridDraft = async ({ gridPeriodUuid, zoneUuid, grid }) => {
  try {
    const { data } = await api.post(
      `gridperiod/${gridPeriodUuid}/${zoneUuid}`,
      grid,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listAllProducts = async () => {
  try {
    const { data } = await api.get('workflow/product');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listContracts = async () => {
  try {
    const { data } = await api.get('contract');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  gridPeriodRequest,
  channelRequest,
  periodRequest,
  getCampaignByFilter,
  saveGridFinal,
  saveGridDraft,
  listAllProducts,
  listContracts,
  senderRequest,
};
