import React, {useState} from 'react';
import Button from '~/Components/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export function ButtonCopyClipboard(props) {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    setCopied(true);
    setTimeout(() => setCopied(false), 2500);
  };

  return(
    <CopyToClipboard text={props.text} onCopy={handleCopy}>
    <Button
      icon={copied ? 'FaCheck' : 'FaClone'}
      title={copied ? 'Copiado com sucesso' : 'Copiar'}
      style={{ marginLeft: 0 }}
      small
      bordered={copied ? false : true}
      onClick={() => {}}
    />
    </CopyToClipboard>
  )
}
