/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
} from './styles';

class HeaderRichCreative extends Component {
  componentDidMount() {}

  render() {
    const {
      image,
      label1,
      label2,
      style,
    } = this.props;
    return (
      <Container style={style}>
        <div className="header-rich">
          <div className="header-rich-icon">
            {!!image && (<img src={image} alt="icon" />)}
          </div>
          <div className="header-rich-content">
            <span className="header-rich-content-label1">{label1}</span>
            <span className="header-rich-content-label2">{label2}</span>
          </div>
        </div>
        <hr />
      </Container>
    );
  }
}

HeaderRichCreative.defaultProps = {
  image: '',
  label1: '',
  label2: '',
  style: null,
};

HeaderRichCreative.propTypes = {
  image: PropTypes.string,
  label1: PropTypes.string,
  label2: PropTypes.string,
  style: PropTypes.shape(),
};

export default HeaderRichCreative;
