/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable operator-linebreak */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
import styled from "styled-components";
import { RowStyled, FormGroupStyled, InputStyled } from "~/Components/Styled";

import Button from "~/Components/Button";

export const Row = styled(RowStyled)`
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 20px;

  .ad-table {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 2px;
    padding: 20px;
    width: 100%;
  }
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  flex: 1;
  margin-right: 0;
`;

export const FormGroupColumnLeft = styled(FormGroup)`
  padding-right: 5px;
`;

export const FormGroupColumnRight = styled(FormGroup)`
  padding-left: 5px;
`;

export const Input = styled(InputStyled)`
  height: 36px;
  border-radius: 4px;
  border: solid 1px ${props => props.theme.colors.light};
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  margin-right: 0;
  &::placeholder {
    color: ${props => props.theme.colors.light};
  }
`;

export const ButtonAddProperty = styled(Button).attrs({
  style: {
    marginLeft: 0,
    width: 180,
    height: 30,
    lineHeight: "normal"
  }
})``;

export const ButtonDeleteProperty = styled(Button).attrs({
  style: {
    marginLeft: 10,
    height: 36
  }
})``;

export const RowFooter = styled(Row)`
  justify-content: space-between;
  margin-bottom: 0;
`;

export const ButtonCancel = styled(Button).attrs({
  style: {
    marginLeft: 0
  }
})``;
