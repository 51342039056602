/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import { withTheme } from 'styled-components';
import RichTextEditor from 'react-rte';
import {
  FaQuestionCircle,
  FaRegCheckSquare,
  FaRegDotCircle,
  FaFont,
  // FaSortNumericUp,
  // FaRegStar,
  FaRegCalendarAlt,
  FaPencilAlt,
  FaTrash,
  FaEye,
} from 'react-icons/fa';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactTooltip from 'react-tooltip';

import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

// import Checkbox from '~/Components/Checkbox';
import Modal from '~/Components/Modal';
import Button from '~/Components/Button';
// import Checkbox from '~/Components/Checkbox';
// import Radiobox from '~/Components/Radiobox';
import Item from './components/Item';
import PreviewResearch from '../../../../../Components/PreviewResearch';

import {
  Container,
  Row,
  Column,
  Questions,
  // LabelQuestion,
  // NewItem,
  // AddNewItem,
  // modal
  ModalHeader,
  ModalWrapBody,
  ModalPreview,
  ModalBody,
  ModalFooter,
  AddItemsWrap,
  GroupButtons,
  ButtonInsert,
  FormGroup,
  MessageForm,
  ButtonEditForm,
  ButtonViewForm,
  ButtonDeteleForm,
  GroupActionsButton,
  SelectPosition,
} from './styles';

class TabValidate extends Component {
  state = {
    modalResearchOpen: false,
    editorValue: RichTextEditor.createEmptyValue(),
    formModalCopy: null,
    formHasError: false,
  };

  refModalBody = null;

  componentDidMount() {
    const {
      content: { mainData },
    } = this.props;

    const form = mainData && mainData.form ? mainData.form : null;
    this.setState({
      editorValue: RichTextEditor.createValueFromString(
        form && form.confirmationTerm ? form.confirmationTerm : '',
        'html',
      ),
    });
  }

  handleScrollToBottom = () => {
    this.refModalBody.scrollTop = this.refModalBody.scrollHeight - this.refModalBody.clientHeight;
  };

  handleNewQuestion = (item) => {
    const {
      campaignEditCriativoRequestSuccess,
      criativoEdit,
      content: { mainData },
    } = this.props;
    const form = mainData && mainData.form ? mainData.form : null;
    const questionList = [...form.questions];

    questionList.push(item);
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...criativoEdit.content,
        mainData: {
          ...mainData,
          form: {
            ...form,
            questions: questionList,
          },
        },
      },
    });
    setTimeout(this.handleScrollToBottom, 300);
  };

  renderQuestions = () => {
    const {
      campaignEditCriativoRequestSuccess,
      criativoEdit,
      content: { mainData },
      disabledFields,
    } = this.props;
    const { formHasError } = this.state;
    const form = mainData && mainData.form ? mainData.form : null;
    const questionList = [...form.questions];

    return (
      <>
        <Row>
          <Questions>
            {questionList.map((question, index) => (
              <Item
                key={index}
                index={index + 1}
                question={question}
                hasError={formHasError}
                disableUp={index === 0}
                disableDown={index === questionList.length - 1}
                changePositionUp={() => {
                  const item1 = questionList[index - 1];
                  const item2 = questionList[index];
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...mainData,
                        form: {
                          ...form,
                          questions: questionList.map((item, itemIndex) => {
                            if (index === itemIndex) {
                              return item1;
                            }
                            if (index - 1 === itemIndex) {
                              return item2;
                            }
                            return item;
                          }),
                        },
                      },
                    },
                  });
                }}
                changePositionDown={() => {
                  const item1 = questionList[index + 1];
                  const item2 = questionList[index];
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...mainData,
                        form: {
                          ...form,
                          questions: questionList.map((item, itemIndex) => {
                            if (index === itemIndex) {
                              return item1;
                            }
                            if (index + 1 === itemIndex) {
                              return item2;
                            }
                            return item;
                          }),
                        },
                      },
                    },
                  });
                }}
                copyItem={() => {
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...mainData,
                        form: {
                          ...form,
                          questions: [
                            ...questionList,
                            {
                              ...questionList[index],
                              uuid: uuid4(),
                            },
                          ],
                        },
                      },
                    },
                  });
                  setTimeout(this.handleScrollToBottom, 300);
                }}
                onChange={(questionChange) => {
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...mainData,
                        form: {
                          ...form,
                          questions: questionList.map(
                            (item, i) => {
                              if (index === i) {
                                return questionChange;
                              }
                              return item;
                            },
                          ),
                        },
                      },
                    },
                  });
                }}
                onRemove={() => {
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...mainData,
                        form: {
                          ...form,
                          questions: questionList.filter(
                            (item, i) => !(index === i),
                          ),
                        },
                      },
                    },
                  });
                }}
                disabledFields={disabledFields}
              />
            ))}
          </Questions>
        </Row>
      </>
    );
  };

  renderGroupButtons = () => (
    <GroupButtons>
      <ButtonInsert
        data-tip="Inserir campo de CheckBox (Seleção de múltiplos itens)"
        onClick={() => {
          this.handleNewQuestion({
            uuid: uuid4(),
            title: '',
            type: 'check',
            required: false,
            rightAnswerRequired: false,
            options: [
              {
                uuid: uuid4(),
                description: '',
                isAnswer: false,
              },
            ],
          });
        }}
      >
        <FaRegCheckSquare />
      </ButtonInsert>
      <ButtonInsert
        data-tip="Inserir campo de Rádio (Seleção de um único item)"
        onClick={() => {
          this.handleNewQuestion({
            uuid: uuid4(),
            title: '',
            type: 'radio',
            required: false,
            rightAnswerRequired: false,
            options: [
              {
                uuid: uuid4(),
                description: '',
                isAnswer: false,
              },
            ],
          });
        }}
      >
        <FaRegDotCircle />
      </ButtonInsert>
      <ButtonInsert
        data-tip="Inserir campo de texto"
        onClick={() => {
          this.handleNewQuestion({
            uuid: uuid4(),
            title: '',
            type: 'text',
            required: false,
            fieldType: 'text',
            validateMax: false,
            validateMin: false,
            max: 0,
            min: 0,
          });
        }}
      >
        <FaFont />
      </ButtonInsert>
      {/*
        <ButtonInsert
          data-tip="Inserir campo somente números"
          onClick={() => {
            this.handleNewQuestion('number');
          }}
        >
          <FaSortNumericUp />
        </ButtonInsert>
      */}
      {/*
        <ButtonInsert
          data-tip="Inserir campo de nota de 1 a 5"
          onClick={() => {
            this.handleNewQuestion('rate');
          }}
        >
          <FaRegStar />
        </ButtonInsert>
      */}
      <ButtonInsert
        data-tip="Inserir campo de data"
        onClick={() => {
          this.handleNewQuestion({
            uuid: uuid4(),
            title: '',
            type: 'date',
            required: false,
            acceptHour: false,
            dateFormat: '',
            dateMax: '',
            dateMaxType: '',
            dateMin: '',
            dateMinType: '',
          });
        }}
      >
        <FaRegCalendarAlt />
      </ButtonInsert>
      <ReactTooltip place="bottom" effect="solid" />
    </GroupButtons>
  );

  renderModalResearch = () => {
    const { formModalCopy } = this.state;
    const {
      campaignEditCriativoRequestSuccess,
      criativoEdit,
      content: { mainData },
      disabledFields,
    } = this.props;
    const { formHasError } = this.state;
    const form = mainData && mainData.form ? mainData.form : null;
    const layout = mainData && mainData.layout ? mainData.layout : null;
    const { modalResearchOpen } = this.state;

    const formIsValid = () => {
      let formValid = true;
      form.questions.forEach((item) => {
        if (!item.title) formValid = false;
        if (item.type === 'check') {
          const optionNull = item.options.filter(option => !option.description);
          if (optionNull.length > 0) formValid = false;
        }

        if (item.type === 'radio') {
          const optionNull = item.options.filter(option => !option.description);
          if (optionNull.length > 0) formValid = false;
        }

        // if (item.type === 'text') {}

        // if (item.type === 'date') {}
      });
      return formValid;
    };

    return modalResearchOpen && (
      <Modal width={1200} height={760} padding={0} scroll={false}>
        <ModalHeader>
          <h1>{disabledFields ? 'Visualizar pesquisa' : 'Editar pesquisa'}</h1>
          {!disabledFields && (
            <AddItemsWrap>
              <span>
                Inserir item:
                <FaQuestionCircle />
              </span>
              {this.renderGroupButtons()}
            </AddItemsWrap>
          )}
        </ModalHeader>
        <ModalWrapBody>
          <ModalPreview>
            <PreviewResearch
              data={form}
              config={
                layout
                  ? ({
                    primaryColor: layout.primaryColor,
                    secondaryColor: layout.secondaryColor,
                  })
                  : null
              }
            />
          </ModalPreview>
          <ModalBody ref={(ref) => { this.refModalBody = ref; }}>
            {this.renderQuestions()}
          </ModalBody>
        </ModalWrapBody>
        <ModalFooter>
          {disabledFields ? (
            <Button
              type="button"
              bordered
              title="Fechar"
              onClick={() => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...criativoEdit.content,
                    mainData: {
                      ...mainData,
                      form: formModalCopy,
                    },
                  },
                });
                this.setState({
                  modalResearchOpen: false,
                  formModalCopy: null,
                  formHasError: false,
                });
              }}
              style={{ margin: 0 }}
            />
          ) : (
            <>
              <Button
                type="button"
                bordered
                title="Cancelar"
                onClick={() => {
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...mainData,
                        form: formModalCopy,
                      },
                    },
                  });
                  this.setState({
                    modalResearchOpen: false,
                    formModalCopy: null,
                    formHasError: false,
                  });
                }}
                style={{ margin: 0 }}
              />
              <Button
                type="button"
                title="Confirmar"
                disabled={formHasError && !formIsValid()}
                onClick={() => {
                  if (formIsValid()) {
                    this.setState({
                      modalResearchOpen: false,
                      formHasError: false,
                    });
                  } else {
                    this.setState({
                      formHasError: true,
                    });
                  }
                }}
              />
            </>
          )}
        </ModalFooter>
      </Modal>
    );
  };

  render() {
    const { editorValue } = this.state;
    const {
      // theme,
      confirmModal,
      campaignEditCriativoRequestSuccess,
      criativoEdit,
      content: { mainData },
      hasError,
      disabledFields,
    } = this.props;

    const form = mainData && mainData.form ? mainData.form : null;

    const typeList = [
      { label: 'Antes da pesquisa', value: 'before' },
      { label: 'Depois da pesquisa', value: 'after' },
      { label: 'Não se aplica', value: '' },
    ];

    return (
      <Container>
        {!form ? (
          <Row style={{ justifyContent: 'center' }}>
            <Button
              type="button"
              icon="FaFileAlt"
              bordered
              title="Criar um formulário de pesquisa"
              onClick={() => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...criativoEdit.content,
                    mainData: {
                      ...mainData,
                      form: {
                        type: 'data_search',
                        rightAnswerRequired: false,
                        termPosition: 'before',
                        confirmationTerm: '',
                        questions: [],
                      },
                    },
                  },
                });
              }}
            />
          </Row>
        ) : (
          <>
            <Row>
              <MessageForm>
                {
                  form.questions.length > 0
                    ? `Formulário configurado para a campanha com ${form.questions.length} questões.`
                    : 'Nenhum formulário configurado para esta campanha'
                }
              </MessageForm>
              <GroupActionsButton>
                {disabledFields ? (
                  <ButtonViewForm
                    onClick={() => {
                      this.setState({
                        modalResearchOpen: true,
                        formModalCopy: form,
                        formHasError: false,
                      });
                    }}
                  >
                    Visualizar formulário
                    <FaEye />
                  </ButtonViewForm>
                ) : (
                  <>
                    <ButtonEditForm
                      onClick={() => {
                        this.setState({
                          modalResearchOpen: true,
                          formModalCopy: form,
                          formHasError: false,
                        });
                      }}
                    >
                      Editar formulário
                      <FaPencilAlt />
                    </ButtonEditForm>
                    <ButtonDeteleForm
                      onClick={() => {
                        confirmModal(
                          'Atenção!',
                          'Deseja excluir o formulário?',
                          () => {
                            campaignEditCriativoRequestSuccess({
                              ...criativoEdit,
                              content: {
                                ...criativoEdit.content,
                                mainData: {
                                  ...mainData,
                                  form: null,
                                },
                              },
                            });
                          },
                        );
                      }}
                    >
                      <FaTrash />
                    </ButtonDeteleForm>
                  </>
                )}
              </GroupActionsButton>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <h4>Configurações</h4>
            </Row>
            <Row style={{ justifyContent: 'flex-start' }}>
              <Column>
                <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
                  <label style={{ margin: 0 }}>Posição do termo</label>
                  <SelectPosition
                    isDisabled={disabledFields}
                    menuPlacement="top"
                    placeholder="Selecione a posição"
                    value={typeList.find(item => item.value === (form && form.termPosition ? form.termPosition : ''))}
                    onChange={(position) => {
                      campaignEditCriativoRequestSuccess({
                        ...criativoEdit,
                        content: {
                          ...criativoEdit.content,
                          mainData: {
                            ...mainData,
                            form: {
                              ...form,
                              termPosition: position.value,
                              ...(!position.value ? { confirmationTerm: '' } : {}),
                            },
                          },
                        },
                      });
                      if (!position.value) {
                        this.setState({ editorValue: RichTextEditor.createValueFromString('', 'html') });
                      }
                    }}
                    options={typeList}
                  />
                </FormGroup>
              </Column>
            </Row>
            <Row style={{ marginTop: 10 }}>
              <FormGroup>
                <label>
                  Termo de confirmação
                  {!!form.termPosition && (
                    <span>*</span>
                  )}
                </label>
                <RichTextEditor
                  disabled={disabledFields || !form.termPosition}
                  className={`editor-rich ${hasError && (!form.confirmationTerm && form.termPosition) ? 'invalid-editor' : ''}`}
                  value={editorValue}
                  toolbarConfig={{
                    display: [
                      'INLINE_STYLE_BUTTONS',
                    ],
                    INLINE_STYLE_BUTTONS: [
                      { label: 'Negrito', style: 'BOLD' },
                      { label: 'Itálico', style: 'ITALIC' },
                      { label: 'Sublinhado', style: 'UNDERLINE' },
                    ],
                  }}
                  onChange={(val) => {
                    this.setState({ editorValue: val });
                    campaignEditCriativoRequestSuccess({
                      ...criativoEdit,
                      content: {
                        ...criativoEdit.content,
                        mainData: {
                          ...mainData,
                          form: {
                            ...form,
                            confirmationTerm: val.toString('html'),
                          },
                        },
                      },
                    });
                  }}
                />
              </FormGroup>
            </Row>
          </>
        )}
        {this.renderModalResearch()}
      </Container>
    );
  }
}

TabValidate.propTypes = {
  content: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  // theme: PropTypes.shape().isRequired,
  confirmModal: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(withTheme(TabValidate));
