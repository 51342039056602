import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as ProductActions } from '~/redux/ducks/product';

import Button from '~/Components/Button';
import Pagination from '~/Components/Pagination';

import {
  Container,
  Row,
  Input,
  ButtonGroup,
  LinkButton,
  Table,
  LinkStyled,
  Select,
} from './styles';
import StatusInfo from '~/Components/StatusInfo';
import ModalDetailProduct from '../ModalDetailProduct';
import StatusWarningInfo from '~/Components/StatusWarningInfo';

const statusList = [
  { value: 'active', label: 'Ativo' },
  { value: 'inactive', label: 'Inativo' },
];
function List({
  products, paginate, goSearch, filter, updateFilter,
  cleanFilter, showDetailProduct, changePage,
}) {
  useEffect(() => {
    goSearch(0);
  }, []);


  function renderRowProduct(product) {
    return (
      <tr key={product.uuid}>
        <td className="link">
          <LinkStyled
            href="/"
            onClick={(e) => {
              e.preventDefault();
              showDetailProduct(product.uuid);
            }}
          >
            {product.name}
          </LinkStyled>
        </td>
        <td>
          <span>
            {product.contract.name}
          </span>
        </td>
        <td>
          <StatusWarningInfo status={product.indicatorStatus && product.indicatorStatus.toUpperCase() === 'OK'} labelTrue="Normal" labelFalse="Com pendência" />
        </td>
        <td>
          <StatusInfo status={product.status === 'active'} labelTrue="Ativo" labelFalse="Inativo" />
        </td>
      </tr>
    );
  }

  function renderProducts() {
    return (
      (
        <Table>
          <thead>
            <tr>
              <th style={{ width: 440 }}>NOME</th>
              <th style={{ width: 440 }}>PARCEIRO</th>
              <th style={{ width: 120 }}>INDICADOR</th>
              <th style={{ width: 120 }}>STATUS</th>
            </tr>
          </thead>
          <tbody>
            {products.length < 1 && (
              <tr>
                <td colSpan="4">
                  <div className="empty-table-desc">
                    <span> Não foram localizados registros de produto</span>
                  </div>
                </td>
              </tr>
            )}
            {products.length > 0
              && products.map(product => renderRowProduct(product))}
          </tbody>
        </Table>
      )
    );
  }

  return (
    <>
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>PRODUTOS</h1>
        </Row>
        <Row>
          <div className="box-search">
            <Input
              placeholder="Procurar por nome"
              value={filter.productName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  goSearch(0);
                }
              }}
              onChange={(e) => {
                updateFilter({ ...filter, productName: e.target.value });
              }}
            />
            <Input
              placeholder="Procurar por parceiro"
              value={filter.contractName}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  goSearch(0);
                }
              }}
              onChange={(e) => {
                updateFilter({ ...filter, contractName: e.target.value });
              }}
            />
            <Select
              placeholder="Selecione status..."
              value={filter.status ? statusList.find(s => s.value === filter.status) : null}
              onChange={(item) => {
                updateFilter({ ...filter, status: item ? item.value : null });
              }}
              options={statusList}
            />
            <ButtonGroup>
              <LinkButton
                onClick={() => {
                  goSearch(0);
                }}
              >
                Buscar
              </LinkButton>
              <Button
                icon="FaTimes"
                noLabel
                onClick={() => {
                  cleanFilter();
                }}
              />
            </ButtonGroup>
          </div>
        </Row>
        {renderProducts()}
        <Row>
          <Pagination
            page={paginate.page}
            pageCount={paginate.pageCount}
            pageChange={(e) => {
              changePage(parseInt(e.selected));
            }}
          />
        </Row>
      </Container>
      <ModalDetailProduct modeEdit />
    </>
  );
}

List.defaultProps = {
  products: null,
};

List.propTypes = {
  filter: PropTypes.shape({
    productName: PropTypes.string,
    contractName: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
  products: PropTypes.arrayOf(PropTypes.shape()),
  paginate: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
  }).isRequired,
  goSearch: PropTypes.func.isRequired,
  updateFilter: PropTypes.func.isRequired,
  cleanFilter: PropTypes.func.isRequired,
  showDetailProduct: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ product: { data, filter, paginate } }) => ({
  products: data, filter, paginate,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    goSearch: ProductActions.goSearch,
    updateFilter: ProductActions.updateFilter,
    cleanFilter: ProductActions.cleanFilter,
    showDetailProduct: ProductActions.showDetailProduct,
    changePage: ProductActions.changePage,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(List);
