import React, { Component } from 'react';
import PropTypes from 'prop-types';

import uuid4 from 'uuid4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import UploadService, { baseUrlMedia } from '~/services/UploadService';
import {
  startRequest as request,
  requestSuccess as success,
  requestError as error,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import Upload from '~/Components/Upload';

import {
  Container,
  Row,
  FormGroup,
  BaseMidiaButton,
  MediaUpload,
  ErrorMessage,
  MessageMediaRequired,
  Textarea,
  Select,
} from './styles';

class TabInitialContent extends Component {
  state = {
    showMessageErrorMaxSizeFile: false,
  };

  uploadMedia = async (campaignUuid, file) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
      successMessage,
    } = this.props;
    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      successMessage(
        'Sucesso!', 'Mídia enviada com sucesso.',
      );
      return {
        urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  handleMidiaUpload = async (files) => {
    const file = files[0];
    if (file) {
      const {
        campaign, campaignEditCriativoRequestSuccess, criativoEdit, content,
      } = this.props;
      const {
        urlMedia: url,
        file: { type, size },
        extFile: format,
      } = await this.uploadMedia(campaign.uuid, file);
      const media = {
        url, mimeType: type, format, size, type: 'file',
      };

      campaignEditCriativoRequestSuccess({
        ...criativoEdit,
        content: {
          ...content,
          mainData: {
            ...content.mainData,
            media,
          },
        },
      });
    }
  };

  handleChangeContentVast = (contentValue) => {
    const {
      campaignEditCriativoRequestSuccess, criativoEdit, content,
    } = this.props;
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        mainData: {
          ...content.mainData,
          media: {
            type: 'vast',
            content: contentValue,
          },
        },
      },
    });
  };

  handleChangeType = (type) => {
    const {
      campaignEditCriativoRequestSuccess, criativoEdit, content,
    } = this.props;
    if (type === 'vast') {
      campaignEditCriativoRequestSuccess({
        ...criativoEdit,
        content: {
          ...content,
          mainData: {
            ...content.mainData,
            media: {
              type: 'vast',
              content: '',
            },
          },
        },
      });
    } else if (type === 'file') {
      campaignEditCriativoRequestSuccess({
        ...criativoEdit,
        content: {
          ...content,
          mainData: {
            ...content.mainData,
            media: {
              type: 'file',
            },
          },
        },
      });
    } else {
      campaignEditCriativoRequestSuccess({
        ...criativoEdit,
        content: {
          ...content,
          mainData: {
            ...content.mainData,
            media: null,
          },
        },
      });
    }
  }

  render() {
    const { showMessageErrorMaxSizeFile } = this.state;
    const { hasError, content: { mainData }, disabledFields } = this.props;

    const typeList = [
      { label: 'Sem mídia', value: 'no-midia' },
      { label: 'Imagem ou Vídeo', value: 'file' },
      { label: 'Vast', value: 'vast' },
    ];
    const typeValue = mainData && mainData.media && mainData.media.type
      ? mainData.media.type
      : 'no-midia';

    return (
      <Container>
        <Row>
          <FormGroup>
            <label>
              Tipo
            </label>
            <Select
              isDisabled={disabledFields}
              placeholder="Selecione Tipo..."
              value={typeList.find(item => item.value === typeValue)}
              onChange={({ value }) => {
                this.handleChangeType(value);
              }}
              options={typeList}
            />
          </FormGroup>
        </Row>
        {typeValue === 'file' && (
          <Row
            style={{
              marginBottom: 20,
              alignItems: 'flex-start',
            }}
          >
            <FormGroup>
              <Row>
                <BaseMidiaButton>
                  <MediaUpload>
                    <Upload
                      disabled={disabledFields}
                      label={mainData && mainData.media && mainData.media.url ? 'ALTERAR' : 'ADICIONAR'}
                      onUpload={this.handleMidiaUpload}
                      accept="image/png,image/jpg,image/jpeg,video/mp4"
                      maxSize={2000000}
                      onRejectMaxSize={() => this.setState({ showMessageErrorMaxSizeFile: true })}
                      onClick={() => this.setState({ showMessageErrorMaxSizeFile: false })}
                    />
                    {hasError && !(mainData && mainData.media && mainData.media.url) && (
                      <MessageMediaRequired>
                        É necessário selecionar uma mídia para salvar!
                      </MessageMediaRequired>
                    )}
                  </MediaUpload>
                  <label>Formatos: PNG, JPG, MP4(Max: 2MB)</label>
                  {showMessageErrorMaxSizeFile && (
                    <ErrorMessage>
                      Arquivo excedeu o limite de 2MB.
                    </ErrorMessage>
                  )}
                </BaseMidiaButton>
              </Row>
            </FormGroup>
          </Row>
        )}
        {typeValue === 'vast' && (
          <Row style={{ width: '100%', marginTop: 10 }}>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Conteúdo
                <span>*</span>
              </label>
              <Textarea
                disabled={disabledFields}
                rows="6"
                placeholder="Conteúdo"
                onChange={(e) => {
                  this.handleChangeContentVast(e.target.value);
                }}
                value={mainData.media.content}
                invalid={hasError && !mainData.media.content}
              />
            </FormGroup>
          </Row>
        )}
      </Container>
    );
  }
}

TabInitialContent.propTypes = {
  campaign: PropTypes.shape().isRequired,
  content: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  successMessage: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: request,
    requestSuccess: success,
    requestError: error,
    errorMessage: ToastrMessageActions.errorMessage,
    successMessage: ToastrMessageActions.successMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(TabInitialContent);
