import React from 'react';
import {
  GoGift,
} from 'react-icons/go';

import {
  Container,
  Header,
  StyledDiv,
  LinkStyled,
} from './styles';

export function PlatformSettingsDashboard() {
  return (
    <Container>
      <Header>
        <h1>CONFIGURAÇÕES DA PLATAFORMA</h1>
      </Header>
      <StyledDiv bordered>
        <LinkStyled to="/platform-settings/carrot">
          <GoGift size={36} />
          Pacote cenoura
        </LinkStyled>
      </StyledDiv>
    </Container>
  )
}
