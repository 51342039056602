import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as PeriodActions } from '~/redux/ducks/period';

import Modal from '~/Components/Modal';

import {
  Container,
  Table,
  Row,
  RowFooter,
  FormGroup,
  Input,
  ButtonCancel,
  InputMask,
  GroupDates,
  BaseInfoBetweenDates,
} from './styles';
import Button from '~/Components/Button';

class PeriodList extends Component {
  componentDidMount() {
    const { loadPeriodList } = this.props;
    loadPeriodList();
  }

  updatePeriod = () => {
    const { periodUpdate } = this.props;
    periodUpdate();
  };

  renderRowPeriod = (period) => {
    const {
      changeModalEditPeriod,
      changePeriodEdit,
    } = this.props;
    return (
      <tr key={period.uuid}>
        <td>
          <span>
            {period.label}
          </span>
        </td>
        <td>
          <BaseInfoBetweenDates>
            <div>
              <label>Inicio</label>
              <span>{period.startDate}</span>
            </div>
            <div>
              <label>Fim</label>
              <span>{period.endDate}</span>
            </div>
          </BaseInfoBetweenDates>
        </td>
        <td>
          <BaseInfoBetweenDates>
            <div>
              <label>Inicio</label>
              <span>{period.bidOpenDate}</span>
            </div>
            <div>
              <label>Fim</label>
              <span>{period.bidCloseDate}</span>
            </div>
          </BaseInfoBetweenDates>
        </td>
        <td>
          <BaseInfoBetweenDates>
            <div>
              <label>Inicio</label>
              <span>{period.gridOpenDate}</span>
            </div>
            <div>
              <label>Fim</label>
              <span>{period.gridCloseDate}</span>
            </div>
          </BaseInfoBetweenDates>
        </td>
        <td>
          <div>
            <Button
              bordered
              title="Alterar"
              onClick={() => {
                changePeriodEdit(period);
                changeModalEditPeriod(true);
              }}
            />
          </div>
        </td>
      </tr>
    );
  };

  renderTablePeriods = () => {
    const { periodList } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th>NOME</th>
            <th width="240">ENVIO</th>
            <th width="240">LEILÃO</th>
            <th width="240">AJUSTE DOS GRIDS</th>
            <th width="1%">AÇÕES</th>
          </tr>
        </thead>
        <tbody>
          {periodList.length < 1 ? (
            <tr>
              <td colSpan="5">
                <div className="empty-table-desc">
                  <span>Não foram localizados registros de períodos</span>
                </div>
              </td>
            </tr>
          ) : (
            periodList.map(period => this.renderRowPeriod(period))
          )}
        </tbody>
      </Table>
    );
  };

  renderAlterModal = () => {
    const {
      changeModalEditPeriod,
      changePeriodEdit,
      periodEdit,
    } = this.props;
    return (
      <Modal width={650}>
        <>
          <Row>
            <h1>Edição Período</h1>
          </Row>
          <Row>
            <FormGroup>
              <label>Nome</label>
              <Input
                value={periodEdit.label}
                onChange={e => changePeriodEdit({
                  label: e.target.value,
                })}
              />
            </FormGroup>
          </Row>
          <GroupDates>
            <h5>Período de envio</h5>
            <Row>
              <FormGroup style={{ marginRight: 5 }}>
                <label>Início</label>
                <InputMask
                  mask="99/99/9999 99:99"
                  value={periodEdit.startDate}
                  onChange={e => changePeriodEdit({
                    startDate: e.target.value,
                  })}
                />
              </FormGroup>
              <FormGroup style={{ marginLeft: 5 }}>
                <label>Fim</label>
                <InputMask
                  mask="99/99/9999 99:99"
                  value={periodEdit.endDate}
                  onChange={e => changePeriodEdit({
                    endDate: e.target.value,
                  })}
                />
              </FormGroup>
            </Row>
          </GroupDates>
          <GroupDates>
            <h5>Período de leilão</h5>
            <Row>
              <FormGroup style={{ marginRight: 5 }}>
                <label>Início</label>
                <InputMask
                  mask="99/99/9999 99:99"
                  value={periodEdit.bidOpenDate}
                  onChange={e => changePeriodEdit({
                    bidOpenDate: e.target.value,
                  })}
                />
              </FormGroup>
              <FormGroup style={{ marginLeft: 5 }}>
                <label>Fim</label>
                <InputMask
                  mask="99/99/9999 99:99"
                  value={periodEdit.bidCloseDate}
                  onChange={e => changePeriodEdit({
                    bidCloseDate: e.target.value,
                  })}
                />
              </FormGroup>
            </Row>
          </GroupDates>
          <GroupDates>
            <h5>Período de ajuste dos grids</h5>
            <Row>
              <FormGroup style={{ marginRight: 5 }}>
                <label>Início</label>
                <InputMask
                  mask="99/99/9999 99:99"
                  value={periodEdit.gridOpenDate}
                  onChange={e => changePeriodEdit({
                    gridOpenDate: e.target.value,
                  })}
                />
              </FormGroup>
              <FormGroup style={{ marginLeft: 5 }}>
                <label>Fim</label>
                <InputMask
                  mask="99/99/9999 99:99"
                  value={periodEdit.gridCloseDate}
                  onChange={e => changePeriodEdit({
                    gridCloseDate: e.target.value,
                  })}
                />
              </FormGroup>
            </Row>
          </GroupDates>
          <RowFooter>
            <ButtonCancel
              color="light"
              title="CANCELAR"
              onClick={() => changeModalEditPeriod(false)}
            />
            <Button
              title="CONFIRMAR"
              onClick={() => {
                this.updatePeriod();
              }}
            />
          </RowFooter>
        </>
      </Modal>
    );
  };

  render() {
    const {
      showModalEditPeriod,
    } = this.props;
    return (
      <Container>
        <Row>
          <h1>Lista de Períodos</h1>
        </Row>
        {this.renderTablePeriods()}
        {showModalEditPeriod && this.renderAlterModal()}
      </Container>
    );
  }
}

PeriodList.propTypes = {
  showModalEditPeriod: PropTypes.bool.isRequired,
  periodList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  periodEdit: PropTypes.shape().isRequired,
  loadPeriodList: PropTypes.func.isRequired,
  periodUpdate: PropTypes.func.isRequired,
  changeModalEditPeriod: PropTypes.func.isRequired,
  changePeriodEdit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  showModalEditPeriod: state.period.showModalEditPeriod,
  periodList: state.period.periodList,
  periodFilterList: state.period.periodFilterList,
  periodEdit: state.period.periodEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changeModalEditPeriod: PeriodActions.changeModalEditPeriod,
    loadPeriodList: PeriodActions.loadPeriodList,
    filterPeriodList: PeriodActions.filterPeriodList,
    periodUpdate: PeriodActions.periodUpdate,
    changePeriodEdit: PeriodActions.changePeriodEdit,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PeriodList);
