import { call, put, select } from 'redux-saga/effects';

import lodash from 'lodash';

import moment from 'moment';
import { Creators as CockpitAnalyzeActions } from '~/redux/ducks/cockpitAnalyze';
import CockpitV2Service from '~/services/CockpitV2Service';

import CampaignService from '~/services/CampaignService';
// import AudienceService from '~/services/AudienceService';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';


import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import ReportService from '~/services/ReportService';
import ChannelUtil from '~/Utils/ChannelUtil';

function validateFilter(filter) {
  if (filter) {
    const {
      campaignTypeUuid,
      channelUuid,
      campaignStatusUuid,
      contractUuid,
      productUuid,
      productOfferUuid,
      campaignName,
      campaignKeyword,
    } = filter;
    if (
      !!campaignTypeUuid
      || !!channelUuid
      || !!campaignStatusUuid
      || !!contractUuid
      || !!productUuid
      || !!productOfferUuid
      || !!campaignName
      || !!campaignKeyword
    ) {
      return true;
    }
  }
  return false;
}

export function* initStateView() {
  // Start loading
  yield put(startRequest());
  try {
    const campaignTypes = yield call(CampaignService.listTypes);
    const channels = yield call(CampaignService.listChannels);
    const campaignStatus = yield call(CampaignService.listStatus);
    const contracts = yield call(CampaignService.listContracts);
    const products = yield call(CampaignService.listAllProducts);
    // const audiences = yield call(AudienceService.audienceRequest);

    yield put(
      CockpitAnalyzeActions.databaseFilters({
        campaignTypes,
        channels,
        campaignStatus,
        contracts,
        products,
      }),
    );
    yield put(requestSuccess());
  } catch (e) {
    console.error(e);
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar os dados do cockpit.',
    ));
  }
}


export function* cockpitAnalyzeRequest() {
  try {
    const tenantUuid = localStorage.getItem('uuidTenant');
    const { filter } = yield select(state => state.cockpitAnalyze);    
    const { channelUuid } = ChannelUtil.unwrapChannelKeyValue({ value: filter.channelUuid });
    
    const resultValidate = validateFilter(filter);
    
    if (resultValidate) {
      yield put(startRequest());
      const result = yield call(CockpitV2Service.cockpitRequest, Object.assign(
        {},
        filter,
        {
          channelUuid,
          tenantUuid
        }
      ));

      const originalData = lodash.chunk(result, 20);

      const dataAnalyze = {
        originalData,
        data: originalData.length ? originalData[0] : [],
        paginate: {
          page: 0,
          pageCount: originalData.length,
        },
      };

      yield put(
        CockpitAnalyzeActions.cockpitAnalyzeRequestSuccess(dataAnalyze),
      );

      yield put(requestSuccess());
    } else {
      yield put(ToastrMessageActions.warningMessage(
        'Atenção!',
        'É necessário preencher ao menos um campo do filtro.',
      ));
    }
  } catch (e) {
    console.error(e);
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar os dados de acompanhamento.',
    ));
  }
}

function makeGridPeriodReport(result) {
  const totals = {
    volume: 0,
    sentQty: 0,
    clickQty: 0,
    costVlr: 0,
  };

  const accumulated = [];

  if (result.data && result.data.length > 0) {
    result.data = result.data.map((d) => {
      totals.volume += d.volume || 0;
      totals.sentQty += d.sentQty || 0;
      totals.clickQty += d.clickQty || 0;
      totals.costVlr += d.costVlr || 0;

      accumulated.push({
        dateEvent: d.dateEvent,
        volume: totals.volume,
        sentQty: totals.sentQty,
        clickQty: totals.clickQty,
        costVlr: totals.costVlr,
      });

      return {
        ...d,
        dateEvent: moment(d.dateEvent, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      };
    });
  }
  return {
    ...result,
    totals,
    accumulated,
  };
}

//   showModalResultsWeekly: ['campaignUuid'],
export function* showModalResultsWeeklyCockpitAnalyzeRequest({ campaignUuid }) {
  // Start loading
  yield put(startRequest());
  try {
    const periodsResult = yield call(ReportService.periodRequest);
    const periods = periodsResult.map(item => ({
      data: { ...item },
      value: item.uuid,
      label: item.label,
    }));
    const periodSelected = periods[0];

    const campaign = yield call(CampaignService.get, campaignUuid);

    const { zone: { uuid: zoneUuid } } = campaign;

    const gridPeriodList = yield call(
      ReportService.reportGridPeriodRequest,
      periodSelected.data.uuid,
      zoneUuid,
    );

    const grids = gridPeriodList.map(g => ({
      data: { ...g },
      value: g.uuid,
      label: g.campaignType.label,
    }));

    const gridSelected = grids.find(g => g.data.campaignType.name === 'AUCTION') || grids[0];

    let gridPeriodReport;
    try {
      const result = yield call(
        ReportService.gridPeriodReport,
        gridSelected.data.uuid,
        campaignUuid,
        periodSelected.data.uuid,
      );
      gridPeriodReport = makeGridPeriodReport(result);
    } catch (e) {
      gridPeriodReport = null;
    }

    yield put(
      CockpitAnalyzeActions.showModalResultsWeeklySuccess(campaignUuid, zoneUuid, periodSelected, gridSelected, periods, grids, gridPeriodReport),
    );

    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados da campanha. Tente novamente!',
    ));
    yield put(requestError());
  }
}

// changePeriodSelectedModalResultsWeekly: ['periodSelected'],
export function* changePeriodSelectedModalResultsWeeklyCockpitAnalyzeRequest({ periodSelected }) {
  const { periodSelected: periodSelectedState, campaignUuid, zoneUuid } = yield select(state => state.cockpitAnalyze.modalResultsWeekly);
  if (!periodSelectedState || periodSelectedState.value !== periodSelected.value) {
    // Start loading
    yield put(startRequest());
    try {
      const gridPeriodList = yield call(
        ReportService.reportGridPeriodRequest,
        periodSelected.data.uuid,
        zoneUuid,
      );

      const grids = gridPeriodList.map(g => ({
        data: { ...g },
        value: g.uuid,
        label: g.campaignType.label,
      }));

      const gridSelected = grids.find(g => g.data.campaignType.name === 'AUCTION') || grids[0];

      let gridPeriodReport;
      try {
        const result = yield call(
          ReportService.gridPeriodReport,
          gridSelected.data.uuid,
          campaignUuid,
          periodSelected.data.uuid,
        );
        gridPeriodReport = makeGridPeriodReport(result);
      } catch (e) {
        gridPeriodReport = null;
      }

      yield put(
        CockpitAnalyzeActions.changeGridPeriodReportModalResultsWeeklySuccess(periodSelected, grids, gridSelected, gridPeriodReport),
      );
      // Stop loading
      yield put(requestSuccess());
    } catch (e) {
      console.error(e);
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um problema ao buscar os dados da campanha. Tente novamente!',
      ));
      yield put(requestError());
    }
  }
}

export function* changeGridSelectedModalResultsWeeklyCockpitAnalyzeRequest({ gridSelected }) {
  const {
    periodSelected, grids, gridSelected: gridSelectedState, campaignUuid,
  } = yield select(state => state.cockpitAnalyze.modalResultsWeekly);
  if (!gridSelectedState || gridSelectedState.value !== gridSelected.value) {
    // Start loading
    yield put(startRequest());
    try {
      let gridPeriodReport;
      try {
        const result = yield call(
          ReportService.gridPeriodReport,
          gridSelected.data.uuid,
          campaignUuid,
          periodSelected.data.uuid,
        );
        gridPeriodReport = makeGridPeriodReport(result);
      } catch (e) {
        gridPeriodReport = null;
      }

      yield put(
        CockpitAnalyzeActions.changeGridPeriodReportModalResultsWeeklySuccess(periodSelected, grids, gridSelected, gridPeriodReport),
      );

      // Stop loading
      yield put(requestSuccess());
    } catch (e) {
      console.error(e);
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um problema ao buscar os dados da campanha. Tente novamente!',
      ));
      yield put(requestError());
    }
  }
}
