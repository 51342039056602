import styled from 'styled-components';

export const Container = styled.div`
  font-weight: normal !important;
  font-size: 25px !important;
  color: ${props => props.theme.colors.primary};
`;

export const Status = styled.span`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
  background-color: ${props => (props.enabled ? props.theme.colors.checked : props.theme.colors.danger2)};
`;
