/* eslint-disable no-plusplus */
import { call, put } from 'redux-saga/effects';

import { replace } from 'connected-react-router';

import PlatformSettingsService from '~/services/PlatformSettingsService';
import { Creators as PlatformSettingsActions } from '~/redux/ducks/platformSettings';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadSettings() {
  try {
    yield put(startRequest());

    let result = yield call(
      PlatformSettingsService.getTenantSettings
    );

    yield put(
      PlatformSettingsActions.loadSettingsSuccess(result.extra.benefit),
    );

    yield put(requestSuccess());
  } catch (e) {
    console.error(e);
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados de configuração. Tente novamente!',
    ));
    yield put(replace(`/platform-settings`));
  }
}

export function* updateSettings(settings) {
  try {
    yield put(startRequest());

    yield call(
      PlatformSettingsService.updateTenantSettings,
      settings
    );
    yield put(replace(`/platform-settings`));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Configuração salva com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    console.error(e);
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar os dados de configuração. Tente novamente!',
    ));
  }
}
