import styled from 'styled-components';


export const Container = styled.div`
  height: 40px;
  display: flex;
  align-items: center;

  .toggle-component {
    .react-toggle-track{
      height: 30px !important;
      width: 56px !important;
    }
    .react-toggle-thumb {
      height: 28px !important;
      width: 28px !important;
      box-shadow: none !important;
    }
    &.react-toggle--checked {
      .react-toggle-track {
        background-color: ${props => props.theme.colors.primary} !important;
      }
      .react-toggle-thumb {
        border-color: ${props => props.theme.colors.primary} !important;
      }
    }
  }

`;
