import styled from 'styled-components';

export const Container = styled.table`

    /* display:block; */

    width:100%;


    tbody{
        display: block;
        width:100%;
        max-height: 350px;
        overflow-y:auto;
    }

    .table-header{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        width: 100%;

        background-color: ${props => props.theme.colors.primary};
        border-radius: 28px;


        /* height:30px; */


        th{
            font-size: 14pt;
            color:${props => props.theme.colors.white};
            padding:10px 10px;
            font-weight: 700;
            text-align: center;
        }

        th:first-child{
                border-radius:14px 0 0 14px;
                padding:10px 20px;
            }

        th:last-child{
            border-radius: 0 14px 14px 0;
            padding:10px 20px;
        }

    }

    .table-body{
        border-bottom: 1px solid ${props => props.theme.colors.light};

        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        td{
          font-size: 14pt;
          color:c;
          padding:10px 10px;
          font-weight: 500;
          text-align: center;
        }

        td:first-child{
            padding:10px 20px;
        }

        td:last-child{
            padding:10px 20px;
        }

        &.total {
          background: #ccc;
          td {
            font-weight: 600;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
    }
    transition: .4s all;
    tr.table-body:hover{
            transition: .4s all;
            background-color: ${props => props.theme.colors.lighter};
        }
        .table-body:last-child{
            border-bottom: none;
        }


`;
