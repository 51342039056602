import api from './axios';


const cockpitRequest = async (
  {
    campaignName,
    campaignUuid,
    tenantUuid,
    zoneUuid,
    campaignTypeUuid,
    channelUuid,
    campaignStatusUuid,
    audienceUuid,
    contractUuid,
    productUuid,
    productOfferUuid,
    campaignKeyword,
  },
) => {
  try {
    let filter = `tenantUuid=${tenantUuid}&pageSize=9999`;

    if (campaignUuid) {
      filter += `&campaignUuid=${campaignUuid}`;
    }

    if (campaignName) {
      filter += `&campaignName=${campaignName}`;
    }

    if (zoneUuid) {
      filter += `&zoneUuid=${zoneUuid}`;
    }

    if (campaignTypeUuid) {
      filter += `&campaignTypeUuid=${campaignTypeUuid}`;
    }

    if (channelUuid) {
      filter += `&channelUuid=${channelUuid}`;
    }

    if (campaignStatusUuid) {
      filter += `&campaignStatusUuid=${campaignStatusUuid}`;
    }

    if (audienceUuid) {
      filter += `&audienceUuid=${audienceUuid}`;
    }

    if (contractUuid) {
      filter += `&contractUuid=${contractUuid}`;
    }

    if (productUuid) {
      filter += `&productUuid=${productUuid}`;
    }

    if (productOfferUuid) {
      filter += `&productOfferUuid=${productOfferUuid}`;
    }

    if (campaignKeyword) {
      filter += `&campaignKeyword=${campaignKeyword}`;
    }

    const { data } = await api.get(`mcare-artemis-report-service/cockpit?${filter}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  cockpitRequest,
};
