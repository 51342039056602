import { call, put, select } from 'redux-saga/effects';

import { replace } from 'connected-react-router';

import ContractService from '~/services/ContractService';

import { Creators as ContractActions } from '~/redux/ducks/contract';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadContractList() {
  yield put(startRequest());
  try {
    const contracts = yield call(ContractService.list);
    yield put(ContractActions.loadContractListSuccess(contracts));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os contratos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* filterContractList() {
  yield put(startRequest());
  try {
    const { name } = yield select(state => state.contract.filter);
    let contracts = yield call(ContractService.list);
    if (name) {
      contracts = contracts.filter(c => c.name.toUpperCase().indexOf(name.toUpperCase()) > -1);
    }
    yield put(ContractActions.loadContractListSuccess(contracts));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os contratos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* loadContractEdit(action) {
  const contractId = action.payload;
  yield put(startRequest());
  try {
    const contract = yield call(ContractService.get, contractId);
    if (contract.data) {
      contract.data = {
        ...contract.data,
        notify: parseFloat(contract.data.notify) || 0.0,
        push: parseFloat(contract.data.push) || 0.0,
        sms: parseFloat(contract.data.sms) || 0.0,
      };
    }
    yield put(ContractActions.loadContractEditSuccess(contract));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar o contrato. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* saveContractEdit() {
  const contract = yield select(state => state.contract.edit.contract);
  try {
    yield put(startRequest());
    yield call(ContractService.update, contract);
    yield put(replace('/contracts'));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Contrato salvo com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      e.message,
    ));
    yield put(requestError());
  }
}
