import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  ContainerStyled,
  RowStyled,
  TableStyled,
  FormGroupStyled,
  InputStyled,
  CurrentInputStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)``;

export const TabContainer = styled.div`
  display: flex;
  width: 100%;

  .react-tabs {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    /* margin-top: 15px; */
    /* font-size: 24px; */
    color: ${props => props.theme.colors.primary};
  }

  .react-tabs__tab-list {
    margin-bottom: 0;
    border-bottom: none;
  }

  .react-tabs__tab {
    border: solid 2px ${props => props.theme.colors.primary};
    border-right: none;
    font-size: 24px;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.white};
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
      border-right: solid 2px ${props => props.theme.colors.primary};
    }
  }

  .react-tabs__tab.react-tabs__tab--selected {
    border-radius: 0;
    background-color: ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.white};
    &:first-child {
      border-top-left-radius: 4px;
    }
    &:last-child {
      border-top-right-radius: 4px;
    }
  }

  .react-tabs__tab-panel--selected {
    padding: 10px;
    display: flex;
    flex-direction: column;
    width: 100%;
    border-radius: 0 8px 8px 8px;
    border: solid 1px ${props => props.theme.colors.light};
    align-items: flex-start;
    justify-content: flex-start;
    .tab-body {
      padding: 15px;
      flex: 1;
      display: flex;
      flex-direction: column;
      width: calc(100% - 30px);
      flex-shrink: 0;
      background-color: ${props => props.theme.colors.bgBody};
    }
  }
`;

export const Input = styled(InputStyled)``;

export const CurrentInput = styled(CurrentInputStyled)`
  margin: 0;
  width: 130px;
`;

export const InputTable = styled(InputStyled)`
  width: 150px;
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const TableDaily = styled(TableStyled)`
  border-collapse: unset;
  thead {
    tr {
      th {
        white-space: nowrap;
      }
      th[align="center"] {
        text-align: center !important;
      }
    }
  }
  tbody {
    .grid-separator {
      td {
        padding: 0;
        height: 7px;
      }
    }
    .grid-form {
      td {
        /* padding: 5px 5px 5px 7.5px; */
        padding: 0;
        border-bottom: 1px solid ${props => props.theme.colors.primary};
        border-left: 1px solid ${props => props.theme.colors.primary};
        border-right: 1px solid ${props => props.theme.colors.primary};
        border-bottom-left-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
      }
    }
    .grid-content {
      td {
        height: 50px;
        padding: 10px 5px 0px 7.5px;
        border-top: 1px solid ${props => props.theme.colors.primary};
        &:first-child {
          border-left: 1px solid ${props => props.theme.colors.primary};
          border-top-left-radius: 4px;
        }
        &:last-child {
          border-right: 1px solid ${props => props.theme.colors.primary};
          border-top-right-radius: 4px;
        }
      }
    }

    tr {
      td {
        height: 68px;
        span.nowrap {
          white-space: nowrap;
        }
        span.auctionModified {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          background-color: ${props => props.theme.colors.warning};
          color: ${props => props.theme.colors.darker};
          margin-left: 5px;
          padding: 0 15px;
          height: 30px;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
`;

export const Table = styled(TableStyled)`
  thead {
    tr {
      th {
        white-space: nowrap;
      }
      th[align="center"] {
        text-align: center !important;
      }
    }
  }
  tbody {
    tr {
      td {
        height: 68px;
        span.nowrap {
          white-space: nowrap;
        }
        span.auctionModified {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          background-color: ${props => props.theme.colors.warning};
          color: ${props => props.theme.colors.darker};
          margin-left: 5px;
          padding: 0 15px;
          height: 30px;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
`;

export const TableFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width:100%;
`;

export const TableButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  button {
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    &:first-child {
      margin-left: 0;
    }
    svg {
      font-size: 20px;
      margin: 0;
    }
  }
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowTabHeader = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  button {
    margin: 0;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex: 1;
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 15px;
`;

export const HeaderForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderFormInput = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  label {
    font-size: 16px;
    width: 80px;
  }
  &:last-child {
    margin-bottom: 0;
  }
`;

export const HeaderStatus = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
`;

export const HeaderStatusTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 30px;
  font-size: 16px;
`;

export const HeaderStatusContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

export const HeaderStatusContentItem = styled.div`
  font-size: 16px;
  span.successDate{
    color: ${props => props.theme.colors.checked};
    letter-spacing: 1px;
  }

  span.dangerDate{
    color: ${props => props.theme.colors.danger};
    letter-spacing: 1px;
  }
`;

export const Select = styled(ReactSelect)`
  width: 300px;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const SelectSender = styled(ReactSelect)`
  width: 120px;
  margin: 0;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const BoxDailyVolumes = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
`;

export const BoxDailyVolumesItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;
  ${props => props.separator && css`
    border-right: 1px solid ${props.theme.colors.primary};
    margin: 10px 0;
  `}
  label {
    display: flex;
    align-items: center;
  }
`;

export const BoxTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const BoxTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 30px;
`;

export const BoxTableHeaderTitle = styled.span`
  display: flex;
  height: 30px;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  margin-left: 10px;
`;

export const BoxTableContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

export const InfoWeek = styled.span`
  cursor: pointer;
  margin-left: 5px;
  svg {
    color: ${props => props.theme.colors.primary};
    font-size: 14px;
  }
`;
