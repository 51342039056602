import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';
import moment from 'moment';
import 'moment/locale/pt-br';
import { formatNumber, formatNumberDailyLimit } from '../../Utils/index';

import Button from '~/Components/Button';
import ChartBoxItem from './Components/ChartBoxItem';

import { Creators as CockpitActions } from '~/redux/ducks/cockpit';

import {
  Container,
  Row,
  ChartsBox,
  Select,
  BaseEmptyData,
  InfoEmptyData,
  Table,
  TableBox,
  // FilterBox,
  Separator,
} from './styles';

class Cockpit extends Component {
  state= {
    filter: {
      year: parseInt(moment().format('YYYY')),
      month: parseInt(moment().format('M')),
    },
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { cockpitRequest } = this.props;
    const { filter } = this.state;
    cockpitRequest(filter);
  };

  renderEmptyData = () => (
    <BaseEmptyData>
      <InfoEmptyData>Não existem informações para esse período</InfoEmptyData>
    </BaseEmptyData>
  )

  renderFillNoFillPerDayNocredit = () => {
    const { cockpitResult: { fillNoFillPerDayNocredit } } = this.props;
    if (fillNoFillPerDayNocredit && fillNoFillPerDayNocredit.categories.length > 0) {
      const lineChartData = {
        series: [
          {
            name: 'FILL',
            data: fillNoFillPerDayNocredit.valueFill,
          },
          {
            name: 'NO FILL',
            data: fillNoFillPerDayNocredit.valueNoFill,
          },
        ],
        options: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 3,
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: fillNoFillPerDayNocredit.categories,
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: val => val.toLocaleString('pt-BR'),
            },
          },
          colors: ['#4285F4', '#EB4335'],
          legend: {
            markers: {
              fillColors: ['#4285F4', '#EB4335'],
            },
            labels: {
              colors: '#929292',
              useSeriesColors: false,
            },
            fontSize: '16px',
          },
        },
      };

      return (
        <Chart
          options={lineChartData.options}
          series={lineChartData.series}
          type="line"
          height={350}
          className="chart-component"
        />
      );
    }
    return this.renderEmptyData();
  };

  renderFillNoFillPerDayNotify = () => {
    const { cockpitResult: { fillNoFillPerDayNotify } } = this.props;
    if (fillNoFillPerDayNotify && fillNoFillPerDayNotify.categories.length > 0) {
      const lineChartData = {
        series: [
          {
            name: 'FILL',
            data: fillNoFillPerDayNotify.valueFill,
          },
          {
            name: 'NO FILL',
            data: fillNoFillPerDayNotify.valueNoFill,
          },
        ],
        options: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            width: 3,
            curve: 'straight',
          },
          grid: {
            row: {
              colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: fillNoFillPerDayNotify.categories,
          },
          tooltip: {
            marker: {
              show: false,
            },
            style: {
              fontSize: '16px',
            },
            y: {
              formatter: val => val.toLocaleString('pt-BR'),
            },
          },
          colors: ['#4285F4', '#EB4335'],
          legend: {
            markers: {
              fillColors: ['#4285F4', '#EB4335'],
            },
            labels: {
              colors: '#929292',
              useSeriesColors: false,
            },
            fontSize: '16px',
          },
        },
      };

      return (
        <Chart
          options={lineChartData.options}
          series={lineChartData.series}
          type="line"
          height={350}
          className="chart-component"
        />
      );
    }
    return this.renderEmptyData();
  };

  renderFilter = () => {
    const { filter } = this.state;
    const yearList = [0, 1].map(item => ({
      value: moment().year() - item,
      label: moment().year() - item,
    }));
    const monthListFull = [
      { label: 'Janeiro', value: 1 },
      { label: 'Fevereiro', value: 2 },
      { label: 'Março', value: 3 },
      { label: 'Abril', value: 4 },
      { label: 'Maio', value: 5 },
      { label: 'Junho', value: 6 },
      { label: 'Julho', value: 7 },
      { label: 'Agosto', value: 8 },
      { label: 'Setembro', value: 9 },
      { label: 'Outubro', value: 10 },
      { label: 'Novembro', value: 11 },
      { label: 'Dezembro', value: 12 },
    ];
    let monthList = [...monthListFull];
    if (parseInt(moment().year()) === filter.year) {
      const nowMonth = parseInt(moment().month());
      monthList = monthListFull.filter(i => i.value <= (nowMonth + 1));
    }
    return (
      <Row>
        <div className="box-search">
          <Select
            placeholder="Ano"
            value={yearList.find(item => item.value === filter.year)}
            onChange={(item) => {
              this.setState({
                filter: {
                  ...filter,
                  year: item.value,
                },
              });
            }}
            options={yearList}
          />
          <Select
            placeholder="Mês"
            value={monthList.find(item => item.value === filter.month)}
            onChange={(item) => {
              this.setState({
                filter: {
                  ...filter,
                  month: item.value,
                },
              });
            }}
            options={monthList}
          />
          <Button
            onClick={() => {
              this.loadData();
            }}
            title="Buscar"
            style={{
              height: 35,
              margin: 0,
            }}
          />
        </div>
      </Row>
    );
  };

  renderHeader = () => (
    <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
      <h1>Cockpit de Campanhas</h1>
      {this.renderFilter()}
    </Row>
  );

  renderContent = () => {
    const { cockpitResult, user } = this.props;
    const { contract: { tenant: { extra: { dashboardSettings } } } } = user;
    const dayList = [7, 6, 5, 4, 3, 2, 1].map(
      item => moment().subtract(item, 'days').format('DD/MMM'),
    );
    return (
      <>
        {dashboardSettings.fillNoFillPerDay.enabled && (
          <ChartsBox>
            <ChartBoxItem title={dashboardSettings.fillNoFillPerDay.nocredit.title}>
              {this.renderFillNoFillPerDayNocredit()}
            </ChartBoxItem>
            <ChartBoxItem title={dashboardSettings.fillNoFillPerDay.notify.title}>
              {this.renderFillNoFillPerDayNotify()}
            </ChartBoxItem>
          </ChartsBox>
        )}
        <Separator />
        <TableBox>
          <Table>
            <thead>
              <tr>
                <th rowSpan="2" align="center">CONTRATO</th>
                <th rowSpan="2" align="center">CAMPANHA</th>
                <th rowSpan="2" align="center">INÍCIO</th>
                <th rowSpan="2" align="center">FIM</th>
                <th rowSpan="2" align="center">VOLUME</th>
                <th rowSpan="2" align="center">ENTREGUES</th>
                <th colSpan="4" align="center">RESUMO</th>
                <th colSpan="7" align="center">ÚLTIMOS 7 DIAS</th>
              </tr>
              <tr>
                <th align="center">STATUS DA ENTREGA</th>
                <th align="center">DIFERENÇA NA ENTREGA</th>
                <th align="center">LIMITE DE ENTREGAS DIÁRIO</th>
                <th align="center">DIAS RESTANTES</th>
                {dayList.map(item => (
                  <th align="center" key={item}>{item}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {cockpitResult.impressedReport.length === 0 ? (
                <tr>
                  <td colSpan="17">
                    <div className="empty-table-desc">
                      <span> Não foram localizados registros de cockpit</span>
                    </div>
                  </td>
                </tr>
              ) : cockpitResult.impressedReport.map(impress => (
                <tr key={impress.uuid}>
                  <td align="center">
                    {impress.contractName}
                  </td>
                  <td align="center">
                    {impress.campaignName}
                  </td>
                  <td align="center">
                    {moment(impress.startDate).utc().format('DD/MM/YYYY')}
                  </td>
                  <td align="center">
                    {moment(impress.endDate).utc().format('DD/MM/YYYY')}
                  </td>
                  <td align="center">
                    {formatNumber(impress.contractedVolume)}
                  </td>
                  <td align="center">
                    {formatNumber(impress.impressed)}
                  </td>
                  <td align="center">
                    {impress.impressedStatus}
                  </td>
                  <td align="center">
                    {formatNumber(impress.impressedDifference)}
                  </td>
                  <td align="center">
                    {formatNumberDailyLimit(impress.dailyLimitImpressed)}
                  </td>
                  <td align="center">
                    {impress.remainingDays}
                  </td>
                  <td align="center">
                    {
                      impress.lastSevenDays[6]
                        ? formatNumber(impress.lastSevenDays[6].impressedDifference) || ''
                        : ''
                    }
                  </td>
                  <td align="center">
                    {
                      impress.lastSevenDays[5]
                        ? formatNumber(impress.lastSevenDays[5].impressedDifference) || ''
                        : ''
                    }
                  </td>
                  <td align="center">
                    {
                      impress.lastSevenDays[4]
                        ? formatNumber(impress.lastSevenDays[4].impressedDifference) || ''
                        : ''
                    }
                  </td>
                  <td align="center">
                    {
                      impress.lastSevenDays[3]
                        ? formatNumber(impress.lastSevenDays[3].impressedDifference) || ''
                        : ''
                    }
                  </td>
                  <td align="center">
                    {
                      impress.lastSevenDays[2]
                        ? formatNumber(impress.lastSevenDays[2].impressedDifference) || ''
                        : ''
                    }
                  </td>
                  <td align="center">
                    {
                      impress.lastSevenDays[1]
                        ? formatNumber(impress.lastSevenDays[1].impressedDifference) || ''
                        : ''
                    }
                  </td>
                  <td align="center">
                    {
                      impress.lastSevenDays[0]
                        ? formatNumber(impress.lastSevenDays[0].impressedDifference) || ''
                        : ''
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </TableBox>
      </>
    );
  };

  render() {
    const { cockpitResult } = this.props;
    return (
      <Container>
        {this.renderHeader()}
        {!!cockpitResult && this.renderContent()}
      </Container>
    );
  }
}

Cockpit.propTypes = {
  cockpitRequest: PropTypes.func.isRequired,
  cockpitResult: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = ({ cockpit: { cockpitResult }, auth }) => ({
  user: auth.currentUser,
  cockpitResult: cockpitResult && {
    ...cockpitResult,
    reimpactDayAbsolute: cockpitResult.reimpactDay && cockpitResult.reimpactDay.absolute
      ? {
        categories: cockpitResult.reimpactDay.absolute.map(item => moment(item.key).utc().format('DD/MM')),
        data: cockpitResult.reimpactDay.absolute.map(item => parseFloat(item.value)),
      }
      : null,
    reimpactDayPercentage: cockpitResult.reimpactDay && cockpitResult.reimpactDay.percentage
      ? {
        categories: cockpitResult.reimpactDay.percentage.map(item => moment(item.key).utc().format('DD/MM')),
        data: cockpitResult.reimpactDay.percentage.map(item => parseFloat(item.value)),
      }
      : null,
    fillNoFillPerDayNocredit: cockpitResult.fillNoFillPerDay
      && cockpitResult.fillNoFillPerDay.nocredit
      ? {
        categories: cockpitResult.fillNoFillPerDay.nocredit.map(item => moment(item.key).utc().format('DD/MM')),
        valueFill: cockpitResult.fillNoFillPerDay.nocredit.map(item => parseInt(item.valueFill)),
        valueNoFill: cockpitResult.fillNoFillPerDay.nocredit.map(
          item => parseInt(item.valueNoFill),
        ),
      }
      : null,
    fillNoFillPerDayNotify: cockpitResult.fillNoFillPerDay
      && cockpitResult.fillNoFillPerDay.notify
      ? {
        categories: cockpitResult.fillNoFillPerDay.notify.map(item => moment(item.key).utc().format('DD/MM')),
        valueFill: cockpitResult.fillNoFillPerDay.notify.map(item => parseInt(item.valueFill)),
        valueNoFill: cockpitResult.fillNoFillPerDay.notify.map(
          item => parseInt(item.valueNoFill),
        ),
      }
      : null,
    impressedReport: cockpitResult.impressedReport || [],
  },
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    cockpitRequest: CockpitActions.cockpitRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Cockpit);
