export default {
  IMAGE: 'image',
  VIDEO: 'video',
  VAST: 'vast',
  VPAID: 'vpaid',
  VIDEO_SURVEY: 'videoSurvey',
  VAST_SURVEY: 'vastSurvey',
  VPAID_SURVEY: 'vpaidSurvey',
  SURVEY: 'survey',
  DATA_SEARCH: 'data_search',
  PROGRAMMATIC: 'programmatic',
  PROGRAMATICA: 'programatica',
  APP_INSTALL: 'app_install',
  EXTERNAL_REDIRECT: 'external_redirect',
};
