import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 10px;
  .accordeon-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    > h2 {
      line-height: 1;
      padding: 0 20px;
      height: 60px;
      display: flex;
      align-items: center;
      flex: 1;
      cursor: pointer;
      color: ${props => props.theme.colors.primary};
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 60px;
      height: 60px;
      border: 0;
      background-color: transparent;
      outline: none;
      cursor: pointer;
      > svg {
        font-size: 30px;
        color: ${props => props.theme.colors.primary};
      }
    }
  }
  .accordeon-content {
    display: flex;
    flex-direction: column;
  }
  ${props => props.hasError && css`
    border: 1px solid ${props.theme.colors.danger2};
    .accordeon-title {
      > h2 {
        color: ${props.theme.colors.danger2};
      }
      > button {
        > svg {
          color: ${props.theme.colors.danger2};
        }
      }
    }
    .accordeon-content {
      display: flex;
      flex-direction: column;
    }
  `}
`;
