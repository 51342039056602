import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ReactTooltip from 'react-tooltip';
import {
  FaTimes,
  FaPlus,
} from 'react-icons/fa';

import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import {
  startRequest as request,
  requestSuccess as success,
  requestError as error,
} from '~/redux/ducks/loading';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import { normalizeDecimal } from '~/Utils';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  ModalHeader,
  FormGroupHeader,
  Select,
  FormGroup,
  CurrentInput,
  GroupedSelect,
  ButtonPlus,
  ErrorMessage,
} from './styles';
import AudienceService from '~/services/AudienceService';

class ModalEditEstimativaImpacto extends Component {
  static propTypes = {
    modalEdit: PropTypes.shape().isRequired,
    audiences: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    impactSettings: PropTypes.shape().isRequired,
    campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
    campaignEditLoadAudiencesRequest: PropTypes.func.isRequired,
    campaignEditSetImpactSettings: PropTypes.func.isRequired,
    campaignEditSaveImpactSettings: PropTypes.func.isRequired,
    navigateToRoute: PropTypes.func.isRequired,
    campaignId: PropTypes.string.isRequired,
    zone: PropTypes.shape().isRequired,
    startRequest: PropTypes.func.isRequired,
    requestSuccess: PropTypes.func.isRequired,
    requestError: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      campaignEditLoadAudiencesRequest,
    } = this.props;
    campaignEditLoadAudiencesRequest({
      shared: true,
      processingStatus: 'PROCESSED,N/A,INVALID_FILE_PROCESSED',
    });
  }

  buscarValorImpacto = async ({
    audienceUuid, zoneUuid, purgeAudienceUuid,
  }) => {
    const { startRequest, requestSuccess, requestError } = this.props;
    startRequest();
    try {
      const { impact } = await AudienceService.getImpactCount({
        audienceUuid, zoneUuid, purgeAudienceUuid,
      });
      requestSuccess();
      return impact || 0;
    } catch (e) {
      requestError();
    }
    return 0;
  }

  changeAudience = async (item) => {
    const { impactSettings, campaignEditSetImpactSettings, zone } = this.props;
    const total = await this.buscarValorImpacto({
      audienceUuid: item ? item.value : null,
      zoneUuid: zone.uuid,
      purgeAudienceUuid: impactSettings.purgeAudience ? impactSettings.purgeAudience.audienceUuid : null,
    });
    campaignEditSetImpactSettings({
      ...impactSettings,
      audienceUuid: item ? item.value : null,
      estimatedAudience: total || 0,
      volume: total || 0,
    });
  }

  changeAudiencePurge = async (item) => {
    const { impactSettings, campaignEditSetImpactSettings, zone } = this.props;
    const total = await this.buscarValorImpacto({
      audienceUuid: impactSettings.audienceUuid,
      zoneUuid: zone.uuid,
      purgeAudienceUuid: item ? item.value : null,
    });
    if (item) {
      campaignEditSetImpactSettings({
        ...impactSettings,
        estimatedAudience: total || 0,
        volume: total || 0,
        purgeAudience: {
          volume: total,
          audienceUuid: item.value,
          cpm: '0.00',
        },
      });
    } else {
      campaignEditSetImpactSettings({
        ...impactSettings,
        estimatedAudience: total || 0,
        volume: total || 0,
        purgeAudience: null,
      });
    }
  }

  renderHeader = (isAudienceRequired) => {
    const {
      navigateToRoute,
      audiences,
      impactSettings,
      campaignId,
    } = this.props;

    const audienceList = audiences.map(item => ({
      label: item.name,
      value: item.uuid,
      data: item,
    }));

    let selectedAudience = null;
    let selectedPurgeAudience = null;

    if (impactSettings && impactSettings.audienceUuid) {
      selectedAudience = impactSettings.audienceUuid;
      if (impactSettings.purgeAudience && impactSettings.purgeAudience.audienceUuid) {
        selectedPurgeAudience = impactSettings.purgeAudience.audienceUuid;
      }
    }

    const validatePurgeAudience = selectedAudience !== null
      && selectedAudience === selectedPurgeAudience;

    return (
      <ModalHeader>
        <FormGroupHeader>
          <label>Utilizar a audiência</label>
          <GroupedSelect>
            <Select
              placeholder="Selecione audiência..."
              value={
                impactSettings && impactSettings.audienceUuid
                  ? audienceList.find(item => item.value === impactSettings.audienceUuid)
                  : null
              }
              onChange={this.changeAudience}
              options={audienceList}
              isClearable={!isAudienceRequired}
              styles={{
                menuList: provided => ({
                  ...provided,
                  maxHeight: 200,
                }),
              }}
            />
            <ButtonPlus
              data-tip={`
                Cadastrar nova audiência
              `}
              onClick={() => {
                navigateToRoute(`/audience/new/${campaignId}`);
              }}
            >
              <FaPlus />
            </ButtonPlus>
            <ReactTooltip
              place="bottom"
              effect="solid"
            />
          </GroupedSelect>

          <label>Excluir a audiência</label>
          <GroupedSelect>
            <Select
              placeholder="Selecione audiência..."
              value={
                      impactSettings
                      && impactSettings.purgeAudience
                      && impactSettings.purgeAudience.audienceUuid
                        ? audienceList
                          .find(
                            item => item.value === impactSettings.purgeAudience.audienceUuid,
                          ) : null
                  }
              onChange={this.changeAudiencePurge}
              options={audienceList}
              isClearable
              styles={{
                menuList: provided => ({
                  ...provided,
                  maxHeight: 200,
                }),
              }}
            />
            <ButtonPlus
              data-tip={`
                Cadastrar nova audiência
              `}
              onClick={() => {
                navigateToRoute(`/audience/new/${campaignId}`);
              }}
            >
              <FaPlus />
            </ButtonPlus>
            <ReactTooltip
              place="bottom"
              effect="solid"
            />
          </GroupedSelect>
          {validatePurgeAudience
            && (
            <ErrorMessage>
              A audiência excluída deve ser diferente da utilizada
            </ErrorMessage>
            )
          }
        </FormGroupHeader>
      </ModalHeader>
    );
  };

  getEstimatedImpact = () => {
    const {
      impactSettings,
    } = this.props;
    if (impactSettings && impactSettings.estimatedAudience) {
      return impactSettings.estimatedAudience;
    }
    return 0;
  };

  renderInputImpactoContratado = (isAudienceRequired) => {
    const {
      campaignEditSetImpactSettings,
      impactSettings,
      zone,
    } = this.props;

    if (!impactSettings) {
      return null;
    }

    // const isPermitionChangeValue = this.getEstimatedImpact() > 0
    //   || (!isAudienceRequired && impactSettings && !impactSettings.audienceUuid);

    const max = zone.channel.channelType.type === 'PULL' || !impactSettings.audienceUuid ? null : impactSettings.estimatedAudience;

    // return isPermitionChangeValue ? (
    return (
      <CurrentInput
        placeholder="Impacto Contratado"
        precision={0}
        prefix=""
        thousandSeparator="."
        max={max}
        value={
          impactSettings && impactSettings.volume
            ? impactSettings.volume
            : 0
        }
        onChange={(e, volume) => {
          const volumeChange = volume <= (impactSettings.estimatedAudience || 0)
          || zone.channel.channelType.type === 'PULL'
          || !impactSettings.audienceUuid
            ? volume
            : impactSettings.volume;

          campaignEditSetImpactSettings({
            ...impactSettings,
            volume: volumeChange,
          });
        }}
      />
    );
    // ) : (
    //   <span>Ilimitado</span>
    // );
  }

  render() {
    const {
      modalEdit,
      campaignEditChangeModalEditStatus,
      campaignEditSetImpactSettings,
      impactSettings,
      campaignEditSaveImpactSettings,
      zone,
    } = this.props;

    const getEstimatedCost = () => {
      if (
        impactSettings
        && impactSettings.volume
        && impactSettings.cpm
      ) {
        return parseFloat(impactSettings.cpm * impactSettings.volume) / 1000;
      }
      return 0;
    };

    const isAudienceRequired = zone.channel.channelType.type === 'PUSH';

    let selectedAudience = null;
    let selectedPurgeAudience = null;
    if (impactSettings && impactSettings.audienceUuid) {
      selectedAudience = impactSettings.audienceUuid;
      if (impactSettings.purgeAudience && impactSettings.purgeAudience.audienceUuid) {
        selectedPurgeAudience = impactSettings.purgeAudience.audienceUuid;
      }
    }
    const validatePurgeAudience = selectedAudience !== null
      && selectedAudience === selectedPurgeAudience;

    const disabledButton = validatePurgeAudience || !impactSettings
      || (isAudienceRequired && !impactSettings.audienceUuid);

    return modalEdit.editImpactEstimateStatus && (
      <Modal width={500}>
        <Row>
          <h1>Configurações de impacto</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              campaignEditSetImpactSettings(null);
              campaignEditChangeModalEditStatus({
                editImpactEstimateStatus: false,
              });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        {this.renderHeader(isAudienceRequired)}
        <FormGroup>
          <label>
            <b>Impacto Estimado:</b>
            {this.getEstimatedImpact() > 0 ? (
              <span>{this.getEstimatedImpact().toLocaleString()}</span>
            ) : (
              <span>Ilimitado</span>
            )}
          </label>
        </FormGroup>
        <FormGroup>
          <label>
            <b>Impacto Contratado:</b>
            {this.renderInputImpactoContratado(isAudienceRequired)}
          </label>
        </FormGroup>
        <FormGroup>
          <label>
            <b>Custo por envio:</b>
            <CurrentInput
              placeholder="Custo por envio"
              precision={2}
              prefix="R$ "
              thousandSeparator="."
              decimalSeparator=","
              value={parseFloat(impactSettings && impactSettings.cpm ? impactSettings.cpm : 0)}
              onChange={(e, cpm) => {
                campaignEditSetImpactSettings({
                  ...impactSettings,
                  cpm: cpm || 0,
                });
              }}
            />
          </label>
        </FormGroup>
        <FormGroup>
          <label>
            <b>Custo total estimado:</b>
            <span>
              {`R$ ${normalizeDecimal(getEstimatedCost())}`}
            </span>
          </label>
        </FormGroup>
        <RowFooter>
          <Button
            title="SALVAR"
            disabled={disabledButton}
            onClick={() => {
              campaignEditSaveImpactSettings();
            }}
          />
        </RowFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modalEdit: state.campaignEdit.modalEdit,
  audiences: state.campaignEdit.audiences,
  impactSettings: state.campaignEdit.impactSettings,
  criativoEdit: state.campaignEdit.criativoEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    navigateToRoute: RedirectActions.navigateToRoute,
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditLoadAudiencesRequest: CampaignEditActions.campaignEditLoadAudiencesRequest,
    campaignEditSetImpactSettings: CampaignEditActions.campaignEditSetImpactSettings,
    campaignEditSaveImpactSettings: CampaignEditActions.campaignEditSaveImpactSettings,
    startRequest: request,
    requestSuccess: success,
    requestError: error,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditEstimativaImpacto);
