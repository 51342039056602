import styled, { css } from 'styled-components';

import ReactSelect from 'react-select';

import { transparentize } from 'polished';

import { InputStyled } from '~/Components/Styled';
import ButtonStyled from '~/Components/Button';

export const Container = styled.div`
  height: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  padding: 15px;
  height: 100%;

  .button-form {
    display: flex;
    justify-content: center;
    button {
      width: 350px;
    }
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  > input {
    margin-right: 0;
  }
`;

export const Input = styled(InputStyled)``;

export const Button = styled(ButtonStyled)``;

export const BodyTableCampaign = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;

  .body-table {
    flex: 1;
    border: solid 2px rgba(151,151,151,0.35);
    border-radius: 4px;

    .body-search {
      display: flex;
      align-items: center;
      padding: 5px;

      > label {
        color: ${props => props.theme.colors.regular};
        font-weight: normal;
        margin-right: 2px;
        margin-bottom: 0;
      }

      > input {
        flex: 1;
      }

      .filter-type-campaign {
        width: 150px;
      }
    }
  }

  ${props => props.invalid && css`
    .body-table {
      border-color: ${props.theme.colors.danger2};
    }
  `}
`;

export const Select = styled(ReactSelect)``;

export const Table = styled.div`
  display: flex;
  flex-direction: column;
  margin: -1px;
`;

export const HeaderTable = styled.div`
  display: flex;
  align-items: center;

  padding: 10px 5px;
  background: ${props => props.theme.colors.light};

  > div {
    font-size: 18px;
    font-weight: bold;
  }
`;

export const BodyTable = styled.div`
  max-height: 195px;
  overflow-y: auto;
`;

export const ItemTable = styled.div`
  display: flex;
  padding: 7px 5px;
  > div {
    font-size: 18px;
  }

  &:hover {
    background-color: ${props => transparentize(0.9, props.theme.colors.primary)};
  }

  ${props => props.selected && css`
    background-color: ${transparentize(0.4, props.theme.colors.primary)};
    &:hover {
      background-color: ${transparentize(0.4, props.theme.colors.primary)};
    }
  `}
`;

export const ItemTableEmpty = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 15px 5px;
  font-size: 18px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`;

export const Value = styled.div`
  flex: ${props => props.flex || 1};
  display: flex;
  align-items: center;
  padding-left: ${props => (props.paddingLeft ? props.paddingLeft : 0)}px;
`;

export const SelectCampaign = styled.div`
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;

  text-decoration: underline;
  color: ${props => props.theme.colors.primary};
  cursor: pointer;

  ${props => props.selected && css`
    cursor: default;
    text-decoration: none;
    color: ${props => props.theme.colors.dark};
    font-weight: bold;
  `}
`;
