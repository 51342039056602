import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { SketchPicker } from 'react-color';
import uuid4 from 'uuid4';
import { withTheme } from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import UploadService, { baseUrlMedia } from '~/services/UploadService';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import {
  Container,
  Row,
  Column,
  FormGroup,
  Input,
  Imagem,
  BannerEmpty,
  ErrorMessage,
  ButtonSelectColor,
  ButtonColor,
  Popover,
  Cover,
} from './styles';

import Upload from '~/Components/Upload';
import Image from '~/Components/Image';
import Button from '~/Components/Button';

class TabLayout extends Component {
  state = {
    showMessageErrorMaxSizeFileIcon: false,
    displayColorPicker1: false,
    displayColorPicker2: false,
  };

  componentDidMount() {
    const {
      campaignEditCriativoRequestSuccess,
      criativoEdit,
      theme,
      content: { mainData },
    } = this.props;
    const layout = mainData && mainData.layout ? mainData.layout : {};
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...criativoEdit.content,
        mainData: {
          ...mainData,
          layout: {
            ...layout,
            primaryColor: layout && layout.primaryColor
              ? layout.primaryColor
              : theme.colors.primaryHex,
            secondaryColor: layout && layout.secondaryColor
              ? layout.secondaryColor
              : theme.colors.white,
          },
        },
      },
    });
  }

  uploadFileToAWS = async (campaignUuid, file) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;
    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  uploadFileIcon = async (files) => {
    const file = files[0];
    if (file) {
      const {
        campaign,
        campaignEditCriativoRequestSuccess,
        criativoEdit,
        content: { mainData },
      } = this.props;
      const layout = mainData && mainData.layout ? mainData.layout : {};

      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      campaignEditCriativoRequestSuccess({
        ...criativoEdit,
        content: {
          ...criativoEdit.content,
          mainData: {
            ...mainData,
            layout: {
              ...layout,
              icon: url,
            },
          },
        },
      });
    }
  };

  renderForm = () => {
    const {
      campaignEditCriativoRequestSuccess,
      criativoEdit,
      content: { mainData },
      hasError,
      disabledFields,
    } = this.props;
    const layout = mainData && mainData.layout ? mainData.layout : {};
    const {
      displayColorPicker1,
      displayColorPicker2,
      showMessageErrorMaxSizeFileIcon,
    } = this.state;
    return (
      <>
        <Row>
          <FormGroup>
            <label>
              Título da página
              <span>*</span>
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite o título da página"
              maxLength="50"
              onChange={(e) => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...criativoEdit.content,
                    mainData: {
                      ...mainData,
                      layout: {
                        ...layout,
                        title: e.target.value,
                      },
                    },
                  },
                });
              }}
              value={layout && layout.title ? layout.title : ''}
              invalid={hasError && !layout.title}
            />
          </FormGroup>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Column style={{ flex: 1, marginRight: 10 }}>
            <FormGroup>
              <label>
                Informação primária
                {/* <span>*</span> */}
              </label>
              <Input
                disabled={disabledFields}
                maxLength="30"
                placeholder="Informação primária"
                onChange={(e) => {
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...mainData,
                        layout: {
                          ...layout,
                          primaryInformation: e.target.value,
                        },
                      },
                    },
                  });
                }}
                value={layout && layout.primaryInformation ? layout.primaryInformation : ''}
                invalid={false /* hasError && !layout.primaryInformation */}
              />
            </FormGroup>
          </Column>
          <Column style={{ flex: 1 }}>
            <FormGroup>
              <label>
                Informação secundária
                {/* <span>*</span> */}
              </label>
              <Input
                disabled={disabledFields}
                maxLength="55"
                placeholder="Informação secundária"
                onChange={(e) => {
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...mainData,
                        layout: {
                          ...layout,
                          secondaryInformation: e.target.value,
                        },
                      },
                    },
                  });
                }}
                value={layout && layout.secondaryInformation ? layout.secondaryInformation : ''}
                invalid={false /* hasError && !layout.secondaryInformation */}
              />
            </FormGroup>
          </Column>
        </Row>
        <Row
          style={{
            marginTop: 10,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Column style={{ width: 200 }}>
            <FormGroup>
              <label>
                Cor Primária
                <span>*</span>
              </label>
              <div>
                <ButtonSelectColor
                  disabled={disabledFields}
                  onClick={() => {
                    this.setState({ displayColorPicker1: true });
                  }}
                  invalid={hasError && !layout.primaryColor}
                >
                  <ButtonColor
                    color={layout && layout.primaryColor ? layout.primaryColor : ''}
                  />
                </ButtonSelectColor>
                {displayColorPicker1 ? (
                  <Popover>
                    <Cover onClick={() => {
                      this.setState({ displayColorPicker1: false });
                    }}
                    />
                    <SketchPicker
                      color={layout && layout.primaryColor ? layout.primaryColor : ''}
                      onChange={(color) => {
                        campaignEditCriativoRequestSuccess({
                          ...criativoEdit,
                          content: {
                            ...criativoEdit.content,
                            mainData: {
                              ...mainData,
                              layout: {
                                ...layout,
                                primaryColor: color.hex,
                              },
                            },
                          },
                        });
                      }}
                    />
                  </Popover>
                ) : null }
              </div>
            </FormGroup>
            <FormGroup>
              <label>
                Cor Secundária
                <span>*</span>
              </label>
              <div>
                <ButtonSelectColor
                  disabled={disabledFields}
                  onClick={() => {
                    this.setState({ displayColorPicker2: true });
                  }}
                  invalid={hasError && !layout.secondaryColor}
                >
                  <ButtonColor
                    color={layout && layout.secondaryColor ? layout.secondaryColor : ''}
                  />
                </ButtonSelectColor>
                {displayColorPicker2 ? (
                  <Popover>
                    <Cover onClick={() => {
                      this.setState({ displayColorPicker2: false });
                    }}
                    />
                    <SketchPicker
                      color={layout && layout.secondaryColor ? layout.secondaryColor : ''}
                      onChange={(color) => {
                        campaignEditCriativoRequestSuccess({
                          ...criativoEdit,
                          content: {
                            ...criativoEdit.content,
                            mainData: {
                              ...mainData,
                              layout: {
                                ...layout,
                                secondaryColor: color.hex,
                              },
                            },
                          },
                        });
                      }}
                    />
                  </Popover>
                ) : null }
              </div>
            </FormGroup>
          </Column>
          <Column>
            <FormGroup>
              <label>
                Ícone
                {/* <span>*</span> */}
              </label>
              <Row style={{ alignItems: 'flex-start' }}>
                <Imagem invalid={false /* hasError && !layout.icon */}>
                  {layout && layout.icon ? (
                    <Image
                      className="success-img"
                      src={layout.icon}
                      alt="imagem"
                    />
                  ) : (
                    <BannerEmpty
                      invalid={false /* hasError && !layout.icon */}
                    >
                      Sem ícone de visualização
                    </BannerEmpty>
                  )}
                </Imagem>
                <FormGroup style={{ marginLeft: '20px' }}>
                  <label>Formatos: PNG, JPG</label>
                  <label>Dimensão recomendada: 192x192.</label>
                  {showMessageErrorMaxSizeFileIcon && (
                    <ErrorMessage>
                      Arquivo excedeu o limite de 2MB.
                    </ErrorMessage>
                  )}
                  <div style={{ display: 'flex' }}>
                    <Upload
                      disabled={disabledFields}
                      label={layout && layout.icon ? 'ALTERAR' : 'ADICIONAR'}
                      onUpload={this.uploadFileIcon}
                      accept="image/png,image/jpg,image/jpeg"
                      maxSize={2000000}
                      onRejectMaxSize={() => {
                        this.setState({ showMessageErrorMaxSizeFileIcon: true });
                      }}
                      onClick={() => {
                        this.setState({ showMessageErrorMaxSizeFileIcon: false });
                      }}
                      style={{ margin: 0 }}
                    />
                    {layout && layout.icon && (
                      <Button
                        disabled={disabledFields}
                        style={{ marginLeft: 20 }}
                        title="REMOVER"
                        onClick={() => {
                          campaignEditCriativoRequestSuccess({
                            ...criativoEdit,
                            content: {
                              ...criativoEdit.content,
                              mainData: {
                                ...mainData,
                                layout: {
                                  ...layout,
                                  icon: '',
                                },
                              },
                            },
                          });
                        }}
                      />
                    )}
                  </div>
                </FormGroup>
              </Row>
            </FormGroup>
          </Column>
        </Row>
      </>
    );
  };

  render() {
    return (
      <Container>
        {this.renderForm()}
      </Container>
    );
  }
}

TabLayout.propTypes = {
  campaign: PropTypes.shape().isRequired,
  content: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(withTheme(TabLayout));
