import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaFileInvoice, FaDollarSign } from 'react-icons/fa';
import moment from 'moment';

import { convertFloatReal } from '~/Utils';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import Button from '~/Components/Button';
import ModalUpload from './Components/ModalUpload';

import { Creators as FinancialActions, INITIAL_STATUS_FILTER } from '~/redux/ducks/financial';

import {
  Container,
  Table,
  Row,
  Select,
  ButtonGroup,
  LinkButton,
  ButtonOpenModal,
  RemoveReceipt,
  Column,
  LinkStyled,
  LabelStatus,
} from './styles';

class FinancialList extends Component {
  componentDidMount() {
    this.loadDocuments();
  }

  loadDocuments = () => {
    const { financialSetup } = this.props;
    financialSetup();
  };

  getStatusLabel = (document) => {
    const { confirmModal, financialModalReceiptDataRequest, theme } = this.props;
    switch (document.documentStatus.name) {
      case 'AWAITING_RECEIPT': return (
        <LabelStatus color={theme.colors.dark}>
          <FaFileInvoice />
          Aguardando recibo
        </LabelStatus>
      );
      case 'RECEIPT_SENT': return (
        <Column>
          <LabelStatus color={theme.colors.dark}>
            <FaFileInvoice />
            Recibo enviado
          </LabelStatus>
          <RemoveReceipt
            onClick={() => {
              confirmModal(
                'Remover recibo',
                `Tem certeza que quer remover o recipo do provedor ${document.provider.name}?`,
                () => {},
              );
            }}
          >
            Remover recibo
          </RemoveReceipt>
        </Column>
      );
      case 'PENDING': return (
        <Column>
          <LabelStatus color={theme.colors.dark}>
            <FaFileInvoice />
            {document.documentStatus.label}
          </LabelStatus>
          <ButtonOpenModal
            onClick={() => {
              financialModalReceiptDataRequest(document.uuid, document.provider);
            }}
          >
            Lançar recibo
          </ButtonOpenModal>
        </Column>
      );
      case 'WITHOUT_INVOICE': return (
        <LabelStatus color={theme.colors.dark}>
          <FaFileInvoice />
          {document.documentStatus.label}
        </LabelStatus>
      );
      case 'LATE': return (
        <Column>
          <LabelStatus color={theme.colors.dark}>
            {document.documentStatus.label}
          </LabelStatus>
          <ButtonOpenModal
            onClick={() => {
              financialModalReceiptDataRequest(document.uuid, document.provider);
            }}
          >
            Lançar recibo
          </ButtonOpenModal>
        </Column>
      );
      case 'PAID': return (
        <LabelStatus color={theme.colors.dark}>
          <FaDollarSign />
          {document.documentStatus.label}
        </LabelStatus>
      );
      default: return <span>{document.documentStatus.label}</span>;
    }
  };

  renderFinancial = () => {
    const { providers, documents } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th style={{ width: 80 }}>ANO</th>
            <th style={{ width: 80 }}>MÊS</th>
            <th className="table-column-center" style={{ width: 150 }}>ARQUIVO</th>
            {providers.map(item => (
              <>
                <th key={`${item.uuid}-status`} className="table-column-center left-border-primary">
                  {item.name.toUpperCase()}
                </th>
                <th key={`${item.uuid}-name`} className="table-column-center">STATUS</th>
              </>
            ))}
          </tr>
        </thead>
        <tbody>
          {documents && documents.content.length > 0 ? (
            documents.content.map(content => (
              <tr key={content.key}>
                <td>
                  <span>{content.year}</span>
                </td>
                <td>
                  <span>{content.monthLabel}</span>
                </td>
                <td align="center">
                  <LinkStyled
                    to={`/financial/partner/files/${content.year}/${content.month}/${content.partnerUuid}`}
                  >
                    Visualizar
                  </LinkStyled>
                </td>
                {content.documents.map(document => (
                  <>
                    <td key={`${document.uuid}-amount`} align="center" className="left-border-primary">
                      <span>
                        {`R$ ${convertFloatReal(document.amount || 0)}`}
                      </span>
                    </td>
                    <td key={`${document.uuid}-status`} align="center">
                      {document.documentStatus && this.getStatusLabel({
                        ...document,
                        month: content.month,
                        monthLabel: content.monthLabel,
                        partner: content.partner,
                        year: content.year,
                      })}
                    </td>
                  </>
                ))}
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="8">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros do financeiro</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    );
  };

  renderFilter = () => {
    const { setFinancialFilter, filter } = this.props;
    const yearList = [0, 1, 2, 3, 4].map(item => ({
      value: moment().year() - item,
      label: moment().year() - item,
    }));
    const monthList = [
      { label: 'Janeiro', value: 0 },
      { label: 'Fevereiro', value: 1 },
      { label: 'Março', value: 2 },
      { label: 'Abril', value: 3 },
      { label: 'Maio', value: 4 },
      { label: 'Junho', value: 5 },
      { label: 'Julho', value: 6 },
      { label: 'Agosto', value: 7 },
      { label: 'Setembro', value: 8 },
      { label: 'Outubro', value: 9 },
      { label: 'Novembro', value: 10 },
      { label: 'Dezembro', value: 11 },
    ];
    return (
      <Row>
        <div className="box-search">
          <Select
            placeholder="Ano"
            value={filter.year}
            onChange={(year) => {
              setFinancialFilter({
                ...filter,
                year,
              });
            }}
            options={yearList}
            isClearable
          />
          <Select
            placeholder="Mês"
            value={filter.month}
            onChange={(month) => {
              setFinancialFilter({
                ...filter,
                month,
              });
            }}
            options={monthList}
            isClearable
          />
          <ButtonGroup>
            <LinkButton
              onClick={() => {
                this.loadDocuments();
              }}
            >
              Buscar
            </LinkButton>
            <Button
              icon="FaTimes"
              noLabel
              onClick={() => {
                setFinancialFilter({
                  ...filter,
                  ...INITIAL_STATUS_FILTER,
                });
                this.loadDocuments();
              }}
            />
          </ButtonGroup>
        </div>
      </Row>
    );
  };

  onResetFilter = () => {};

  render() {
    const { modalInvoiceData } = this.props;
    return (
      <>
        <Container>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>FINANCEIRO</h1>
          </Row>
          {this.renderFilter()}
          {this.renderFinancial()}
        </Container>
        {modalInvoiceData && <ModalUpload />}
      </>
    );
  }
}

FinancialList.propTypes = {
  confirmModal: PropTypes.func.isRequired,
  financialSetup: PropTypes.func.isRequired,
  documents: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  setFinancialFilter: PropTypes.func.isRequired,
  filter: PropTypes.shape().isRequired,
  financialModalReceiptDataRequest: PropTypes.func.isRequired,
  modalInvoiceData: PropTypes.shape().isRequired,
  theme: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  documents: state.financial.documents,
  providers: state.financial.providers,
  filter: state.financial.filter,
  modalInvoiceData: state.financial.modalInvoiceData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    confirmModal: ConfirmModalActions.showConfirmModal,
    financialSetup: FinancialActions.financialSetup,
    setFinancialFilter: FinancialActions.setFinancialFilter,
    financialModalReceiptDataRequest: FinancialActions.financialModalReceiptDataRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(FinancialList));
