import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTheme } from 'styled-components';

import Icone from '~/Components/Icone';
import { Container } from './styles';

const CampaignIcon = ({ name, types, theme }) => {
  const type = types.find(item => item.name === name);
  return types.length > 0 ? (
    <Container data-tip={type ? type.label : name}>
      <Icone
        icon={type ? type.icon : 'circle'}
        color={theme.colors.primary}
        size="28"
      />
      <ReactTooltip place="right" effect="solid" />
    </Container>
  ) : null;
};

CampaignIcon.propTypes = {
  name: PropTypes.string.isRequired,
  types: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  theme: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  types: state.campaignIcon.types,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(CampaignIcon));
