import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

import {
  RowStyled,
  FormGroupStyled,
  InputStyled,
  TableStyled,
} from '~/Components/Styled';

export const ContentModal = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.regular};
`;

export const Body = styled.div`
  overflow-y: auto;
`;

export const Footer = styled.div`
  height: 70px;
  border-top: 1px solid ${props => props.theme.colors.regular};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 0 10px;
`;

export const CloseHeader = styled.div`
  cursor: pointer;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const FormGroup = styled(FormGroupStyled)`
  width: 100%;
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)};
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const FormNew = styled.div`
  padding: 10px;
`;

export const Input = styled(InputStyled)`
  width: 350px;
`;

export const Table = styled(TableStyled)``;

export const Select = styled(ReactSelect)`
  width: 350px;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const LinkButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border: 1px solid;
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 0;
  padding-left: 10px;
  padding-right: 10px;
  ${props => props.disabled && css`
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  `};
`;

export const ButtonGroup = styled.div`
  display: flex;
  > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      margin: 0;
    }
  }
`;
