import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FaTimes,
} from 'react-icons/fa';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';
import Checkbox from '~/Components/Checkbox';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  Select,
  // CreateAudiencia,
  FormGroup,
  CurrentInput,
} from './styles';

const strategyList = [
  { value: 1, label: 'Uniformemente' },
  { value: 2, label: 'O quanto antes' },
];

const frequencyList = [
  { value: 1, label: 'Hora' },
  { value: 2, label: 'A cada 24 horas' },
  { value: 5, label: 'Diário' },
  { value: 3, label: 'Semana' },
  { value: 4, label: 'Mês' },
  { value: 6, label: 'Sempre' },
];

class ModalEditEstrategiaEntrega extends Component {
  componentDidMount() {}

  render() {
    const {
      modalEdit,
      campaignEditChangeModalEditStatus,
      campaignEditSetEstrategiaEntregaToEdit,
      campaignEditSaveEstrategiaEntrega,
      campaignEstrategiaEntregaToEdit,
    } = this.props;

    if (!modalEdit.editEstrategiaEntregaStatus) {
      return null;
    }

    const isDisabledButton = campaignEstrategiaEntregaToEdit.hasFrequency && (
      !campaignEstrategiaEntregaToEdit.frequencyPerUser
      || !campaignEstrategiaEntregaToEdit.frequencyPerUserPeriod
    );

    return modalEdit.editEstrategiaEntregaStatus && (
      <Modal width={500}>
        <Row>
          <h1>Estratégia de Entrega</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              campaignEditSetEstrategiaEntregaToEdit(null);
              campaignEditChangeModalEditStatus({
                editEstrategiaEntregaStatus: false,
              });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <FormGroup>
          <label>Prioridade:</label>
          <CurrentInput
            placeholder="Prioridade"
            precision={0}
            prefix=""
            thousandSeparator=""
            decimalSeparator=""
            value={parseInt(campaignEstrategiaEntregaToEdit.priority)}
            onChange={(e, priority) => {
              campaignEditSetEstrategiaEntregaToEdit({
                ...campaignEstrategiaEntregaToEdit,
                priority: parseInt(priority),
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label>Estratégia:</label>
          <Select
            placeholder="Selecione estratégia..."
            value={strategyList.find(
              item => item.value === campaignEstrategiaEntregaToEdit.deliveryStrategy,
            )}
            onChange={({ value }) => {
              campaignEditSetEstrategiaEntregaToEdit({
                ...campaignEstrategiaEntregaToEdit,
                deliveryStrategy: value,
              });
            }}
            options={strategyList}
            styles={{
              menuList: provided => ({
                ...provided,
                maxHeight: 200,
              }),
            }}
          />
        </FormGroup>
        <Row style={{ justifyContent: 'flex-start', marginBottom: 0, marginTop: 20 }}>
          <Checkbox
            label="Personalizar Frequência?"
            value={campaignEstrategiaEntregaToEdit.hasFrequency}
            onChange={(value) => {
              campaignEditSetEstrategiaEntregaToEdit({
                ...campaignEstrategiaEntregaToEdit,
                hasFrequency: value,
              });
            }}
          />
        </Row>
        <FormGroup>
          <label className={campaignEstrategiaEntregaToEdit.hasFrequency && 'required'}>Limite de Utilização por Usuário:</label>
          <CurrentInput
            placeholder="Limite de Utilização por Usuário"
            precision={0}
            prefix=""
            thousandSeparator=""
            decimalSeparator=""
            disabled={!campaignEstrategiaEntregaToEdit.hasFrequency}
            value={parseInt(campaignEstrategiaEntregaToEdit.frequencyPerUser)}
            onChange={(e, frequencyPerUser) => {
              campaignEditSetEstrategiaEntregaToEdit({
                ...campaignEstrategiaEntregaToEdit,
                frequencyPerUser: parseInt(frequencyPerUser),
              });
            }}
          />
        </FormGroup>
        <FormGroup>
          <label className={campaignEstrategiaEntregaToEdit.hasFrequency && 'required'}>Período:</label>
          <Select
            placeholder="Selecione período..."
            value={frequencyList.find(
              item => item.value === campaignEstrategiaEntregaToEdit.frequencyPerUserPeriod,
            )}
            isDisabled={!campaignEstrategiaEntregaToEdit.hasFrequency}
            onChange={({ value }) => {
              campaignEditSetEstrategiaEntregaToEdit({
                ...campaignEstrategiaEntregaToEdit,
                frequencyPerUserPeriod: value,
              });
            }}
            options={frequencyList}
            menuPlacement="top"
            styles={{
              menuList: provided => ({
                ...provided,
                maxHeight: 200,
              }),
            }}
          />
        </FormGroup>
        <RowFooter>
          <Button
            title="SALVAR"
            disabled={isDisabledButton}
            onClick={() => {
              campaignEditSaveEstrategiaEntrega();
            }}
          />
        </RowFooter>
      </Modal>
    );
  }
}

ModalEditEstrategiaEntrega.propTypes = {
  modalEdit: PropTypes.shape().isRequired,
  campaignEstrategiaEntregaToEdit: PropTypes.shape().isRequired,
  campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
  campaignEditSetEstrategiaEntregaToEdit: PropTypes.func.isRequired,
  campaignEditSaveEstrategiaEntrega: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modalEdit: state.campaignEdit.modalEdit,
  campaignEstrategiaEntregaToEdit: state.campaignEdit.campaignEstrategiaEntregaToEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditSetEstrategiaEntregaToEdit:
      CampaignEditActions.campaignEditSetEstrategiaEntregaToEdit,
    campaignEditSaveEstrategiaEntrega: CampaignEditActions.campaignEditSaveEstrategiaEntrega,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditEstrategiaEntrega);
