/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import smsImg from '~/Assets/Images/sms.png';
import pushImg from '~/Assets/Images/push-notification.png';
import paidNotificationImg from '~/Assets/Images/notificacao-patrocinada.png';

import moment from 'moment';

import Button from '~/Components/Button';
import {
  Container, Row, Input, FormGroup, Card, CurrentInput,
} from './styles';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ContractActions } from '~/redux/ducks/contract';

class ContractEdit extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    loadContractEdit: PropTypes.func.isRequired,
    contract: PropTypes.shape().isRequired,
    updateContract: PropTypes.func.isRequired,
    saveContractEdit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const {
      match: { params },
      loadContractEdit,
    } = this.props;

    loadContractEdit(params.contractId);
  }

  render() {
    const { contract, updateContract, saveContractEdit } = this.props;

    if (!contract) {
      return null;
    }

    return (
      <>
        <Container>
          <div className="campaign">
            <Row>
              <div className="campaign-desc">
                {/* <BaseTitle>
                      <DinamicStaticEditInput fontSize={25} value={"Contrato 01"} />
                    </BaseTitle> */}

                <h1>{contract.name}</h1>
              </div>
            </Row>
            <Row style={{ justifyContent: 'flex-start' }}>
              <FormGroup>
                <label>ID de Contrato:</label>
                <span>{contract.id}</span>
              </FormGroup>
              <FormGroup>
                <label> Data de Criação:</label>
                <span>{contract.creation_date && moment(contract.creation_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
              </FormGroup>
              <FormGroup>
                <label> Código de Faturamento:</label>
                <Input
                  placeholder="Código"
                  value={contract.tariff_code}
                  onChange={e => updateContract({
                    ...contract,
                    tariff_code: e.target.value,
                  })
                  }
                />
              </FormGroup>
            </Row>
            <h2>Modos de Envio</h2>
            <Row style={{ justifyContent: 'flex-start' }}>
              <Card>
                <img alt="SMS" src={smsImg} />

                <FormGroup>
                  <span> SMS</span>
                  <label> Custo por Mensagem:</label>
                  <CurrentInput
                    placeholder="Custo em R$0,00"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    value={contract.data.sms}
                    onChange={(e, sms) => updateContract({
                      ...contract,
                      data: {
                        ...contract.data,
                        sms,
                      },
                    })
                    }
                  />
                </FormGroup>
              </Card>
              <Card>
                <img alt="Push" src={pushImg} />
                <FormGroup>
                  <span>Push Notification</span>
                  <label> Custo por Mensagem:</label>
                  <CurrentInput
                    placeholder="Custo em R$0,00"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    value={contract.data.push}
                    onChange={(e, push) => updateContract({
                      ...contract,
                      data: {
                        ...contract.data,
                        push,
                      },
                    })
                    }
                  />
                </FormGroup>
              </Card>
              <Card>
                <img alt="Paid Notification" src={paidNotificationImg} />
                <FormGroup>
                  <span>Notificação Patrocinada</span>
                  <label> Custo por Mensagem:</label>
                  <CurrentInput
                    placeholder="Custo em R$0,00"
                    prefix="R$"
                    decimalSeparator=","
                    thousandSeparator="."
                    value={contract.data.notify}
                    onChange={(e, notify) => updateContract({
                      ...contract,
                      data: {
                        ...contract.data,
                        notify,
                      },
                    })
                    }
                  />
                </FormGroup>
              </Card>
            </Row>

            <Row style={{ justifyContent: 'flex-end', marginBottom: 0 }}>
              <Button bsStyle="primary" title="Salvar Alterações" onClick={saveContractEdit} />
            </Row>
          </div>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  contract: state.contract.edit.contract,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadContractEdit: ContractActions.loadContractEdit,
    updateContract: ContractActions.updateContract,
    saveContractEdit: ContractActions.saveContractEdit,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContractEdit);
