import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  gridPeriodSetup: null,
  gridPeriodRequest: null,
  gridPeriodRequestSuccess: ['gridPeriod'],
  gridPeriodChannelRequestSuccess: ['channelList'],
  gridPeriodPeriodRequestSuccess: ['periodList'],
  gridPeriodSetFilter: ['filter'],
  gridPeriodChangeModalStatus: ['modalStatus'],
  gridPeriodCampaignByFilterRequest: null,
  gridPeriodCampaignByFilterRequestSuccess: ['campaignList'],
  gridPeriodSetFilterModalCampaign: ['campaignListFilter'],
  gridPeriodResetFilterModalCampaign: null,
  gridPeriodSetCampaignToAdd: ['campaignListUuid'],
  gridPeriodInsertCampaignsSelected: null,
  gridPeriodSetItemToEdit: ['gridItemToEdit'],
  gridPeriodSaveItemInEdit: null,
  gridPeriodSaveGrid: ['saveType'],
  gridPeriodDeleteItemGrid: ['gridType', 'gridItemUuid'],
  gridPeriodLoadRemainingImpressions: null,
  gridPeriodSetRemainingImpressions: ['remainingImpressions'],
  gridPeriodChangeLeilaoGridItem: ['gridItemUuid', 'value'],
  gridPeriodChangeBonificadaGridItem: ['gridItemUuid', 'value'],
  gridPeriodChangeTesteGridItem: ['gridItemUuid', 'value'],
  gridPeriodChangeTesteCpa: ['gridItemUuid', 'value'],
  gridPeriodChangeSender: ['gridItemUuid', 'value'],
  gridPeriodChangeCpaGridItem: ['gridItemUuid', 'value'],
  gridPeriodChangeCpa: ['gridItemUuid', 'value'],
  gridPeriodAutomaticDistribution: null,
  gridPeriodProductRequestSuccess: ['productList'],
  gridPeriodContractRequestSuccess: ['contractList'],
  gridPeriodSendersRequestSuccess: ['senderList'],
});

export const GridPeriodTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_FILTER_GRID_PERIOD = {
  channel: null,
  period: null,
};
const INITIAL_MODALS_STATUS = {
  modalBonificadas: false,
  modalBonificadasEdit: false,
  modalCpaFixo: false,
  modalTeste: false,
  modalCloseAuction: false,
};
const INITIAL_MODALS_CAMPAIGN_FILTER = {
  page: null,
  type: null,
  typeName: null,
};
const INITIAL_STATE = Immutable({
  channelList: [],
  periodList: [],
  productList: [],
  contractList: [],
  senderList: [],
  gridPeriodFilter: INITIAL_FILTER_GRID_PERIOD,
  modal: INITIAL_MODALS_STATUS,
  gridPeriod: null,
  campaignList: null,
  campaignListFilter: INITIAL_MODALS_CAMPAIGN_FILTER,
  campaignToAdd: [],
  gridItemToEdit: null,
  remainingImpressions: 0,
});

/* Reducers */
const gridPeriodRequestSuccess = (state, { gridPeriod }) => ({
  ...state,
  gridPeriod,
});
const gridPeriodChannelRequestSuccess = (state, { channelList }) => ({
  ...state,
  channelList,
});
const gridPeriodPeriodRequestSuccess = (state, { periodList }) => ({
  ...state,
  periodList,
});
const gridPeriodProductRequestSuccess = (state, { productList }) => ({
  ...state,
  productList,
});
const gridPeriodContractRequestSuccess = (state, { contractList }) => ({
  ...state,
  contractList,
});
const gridPeriodSendersRequestSuccess = (state, { senderList }) => ({
  ...state,
  senderList,
});
const gridPeriodCampaignByFilterRequestSuccess = (state, { campaignList }) => ({
  ...state,
  campaignList,
});

const gridPeriodSetFilterModalCampaign = (state, { campaignListFilter }) => ({
  ...state,
  campaignListFilter: {
    ...state.campaignListFilter,
    ...campaignListFilter,
  },
});

const gridPeriodResetFilterModalCampaign = state => ({
  ...state,
  campaignListFilter: null,
});
const gridPeriodSetFilter = (state, { filter }) => ({
  ...state,
  gridPeriodFilter: {
    ...state.gridPeriodFilter,
    ...filter,
  },
});
const gridPeriodChangeModalStatus = (state, { modalStatus }) => ({
  ...state,
  modal: {
    ...state.modal,
    ...modalStatus,
  },
});
const gridPeriodSetCampaignToAdd = (state, { campaignListUuid }) => ({
  ...state,
  campaignToAdd: campaignListUuid,
});
const gridPeriodSetItemToEdit = (state, { gridItemToEdit }) => ({
  ...state,
  gridItemToEdit,
});
const gridPeriodSetRemainingImpressions = (state, { remainingImpressions }) => ({
  ...state,
  remainingImpressions,
});

export const reducer = createReducer(INITIAL_STATE, {
  [GridPeriodTypes.GRID_PERIOD_REQUEST_SUCCESS]: gridPeriodRequestSuccess,
  [GridPeriodTypes.GRID_PERIOD_CHANNEL_REQUEST_SUCCESS]: gridPeriodChannelRequestSuccess,
  [GridPeriodTypes.GRID_PERIOD_PERIOD_REQUEST_SUCCESS]: gridPeriodPeriodRequestSuccess,
  [GridPeriodTypes.GRID_PERIOD_SET_FILTER]: gridPeriodSetFilter,
  [GridPeriodTypes.GRID_PERIOD_CHANGE_MODAL_STATUS]: gridPeriodChangeModalStatus,
  [GridPeriodTypes.GRID_PERIOD_CAMPAIGN_BY_FILTER_REQUEST_SUCCESS]:
    gridPeriodCampaignByFilterRequestSuccess,
  [GridPeriodTypes.GRID_PERIOD_SET_FILTER_MODAL_CAMPAIGN]:
    gridPeriodSetFilterModalCampaign,
  [GridPeriodTypes.GRID_PERIOD_RESET_FILTER_MODAL_CAMPAIGN]:
    gridPeriodResetFilterModalCampaign,
  [GridPeriodTypes.GRID_PERIOD_SET_CAMPAIGN_TO_ADD]: gridPeriodSetCampaignToAdd,
  [GridPeriodTypes.GRID_PERIOD_SET_ITEM_TO_EDIT]: gridPeriodSetItemToEdit,
  [GridPeriodTypes.GRID_PERIOD_SET_REMAINING_IMPRESSIONS]: gridPeriodSetRemainingImpressions,
  [GridPeriodTypes.GRID_PERIOD_PRODUCT_REQUEST_SUCCESS]: gridPeriodProductRequestSuccess,
  [GridPeriodTypes.GRID_PERIOD_CONTRACT_REQUEST_SUCCESS]: gridPeriodContractRequestSuccess,
  [GridPeriodTypes.GRID_PERIOD_SENDERS_REQUEST_SUCCESS]: gridPeriodSendersRequestSuccess,
});
