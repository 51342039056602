import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';
import { getFormattedValueByType } from '~/Utils';

const Table = ({ data }) => {
  const total = data && data.length > 0 ? data.reduce((result, item) => ({
    impressions: result.impressions + item.impressions,
    clicks: result.clicks + item.clicks,
    ctrPercent: 0,
  }), {
    impressions: 0,
    clicks: 0,
    ctrPercent: 0,
  }) : null;
  return (
    <Container>
      <thead>
        <tr className="table-header">
          <th>Data</th>
          <th>Impressão</th>
          <th>Cliques</th>
          <th>Convertido</th>
        </tr>
      </thead>
      <tbody>
        {data.map(d => (
          <tr key={d.date} className="table-body">
            <td>{d.date}</td>
            <td>{getFormattedValueByType('decimal', d.impressions)}</td>
            <td>{getFormattedValueByType('decimal', d.clicks)}</td>
            <td>{`${d.ctrPercent}%`}</td>
          </tr>
        ))}
        {total && (
          <tr className="table-body total">
            <td>TOTAL</td>
            <td>{getFormattedValueByType('decimal', total.impressions)}</td>
            <td>{getFormattedValueByType('decimal', total.clicks)}</td>
            <td>-</td>
          </tr>
        )}
      </tbody>
    </Container>
  );
};

Table.defaultProps = {
  data: [],
};

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default Table;
