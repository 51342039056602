import React from 'react';

function ButtonGridPeriodIcon() {
  return (
    <svg width="25" height="21" viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.79321 2.42158C6.40899 2.70315 6.0453 2.97005 5.68124 3.23695C4.0967 4.39878 2.51031 5.55878 0.928333 6.72428C0.538612 7.01134 0.0788659 6.76424 0.0121404 6.41778C-0.0395535 6.14941 0.0781327 5.95327 0.283442 5.80295C0.967562 5.30141 1.65461 4.80427 2.34093 4.30529C3.71541 3.30588 5.08988 2.30609 6.46435 1.30668C6.77452 1.0812 7.01355 1.0988 7.28486 1.3679C8.55521 2.62726 9.82556 3.88661 11.0952 5.14669C11.2162 5.26695 11.3324 5.39197 11.457 5.52065C11.4992 5.48399 11.5293 5.45906 11.5579 5.43303C13.4566 3.69633 15.3557 1.96 17.2537 0.222571C17.4029 0.0861865 17.5635 -0.011702 17.7758 0.00112982C18.1571 0.0242271 18.4174 0.442911 18.2561 0.788638C18.2121 0.88286 18.1454 0.974149 18.0687 1.04417C16.4244 2.5433 14.7772 4.03949 13.1307 5.53605C12.7127 5.91587 12.2948 6.29569 11.8772 6.67552C11.6073 6.92079 11.2682 6.91455 11.0112 6.65792C9.62941 5.27685 8.24798 3.89541 6.8669 2.5136C6.84124 2.48794 6.82108 2.45641 6.79395 2.42158H6.79321Z" fill="white" />
      <path d="M8.56764 11.4288C8.56764 12.3688 8.57021 13.3092 8.56654 14.2492C8.56508 14.6367 8.45949 14.9957 8.23402 15.315C7.93852 15.7333 7.5202 15.9548 7.02379 16.0031C6.10467 16.093 5.24713 15.4833 5.14851 14.4197C5.14081 14.3379 5.13788 14.2555 5.13788 14.173C5.13715 12.3292 5.13751 10.4858 5.13751 8.64208C5.13751 7.76988 5.67645 7.07183 6.52848 6.88192C7.13671 6.74626 7.65145 6.96074 8.08187 7.39079C8.4166 7.72515 8.56361 8.142 8.56618 8.60835C8.57131 9.54837 8.56764 10.4888 8.56764 11.4288V11.4288ZM6.27808 11.4306C6.27808 12.3585 6.27808 13.2865 6.27808 14.2144C6.27808 14.2602 6.27808 14.306 6.28175 14.3515C6.30558 14.6661 6.50465 14.8582 6.82875 14.8805C7.11728 14.9003 7.34495 14.7277 7.40948 14.434C7.42414 14.3662 7.42561 14.2947 7.42561 14.225C7.42634 12.3629 7.42634 10.5009 7.42598 8.63914C7.42598 8.59331 7.42598 8.54749 7.42195 8.50203C7.39078 8.15337 7.06155 7.91836 6.72279 8.00232C6.43316 8.07381 6.27845 8.28058 6.27808 8.60101C6.27735 9.54434 6.27808 10.4873 6.27808 11.4306V11.4306Z" fill="white" />
      <path d="M17.7252 11.4215C17.7252 12.3615 17.7274 13.3019 17.7241 14.2419C17.7226 14.6426 17.6137 15.0129 17.3743 15.3385C17.0737 15.7476 16.6557 15.9625 16.1615 16.0046C15.2428 16.0831 14.4028 15.4697 14.306 14.4179C14.2994 14.3449 14.2954 14.2716 14.2954 14.1986C14.2947 12.3457 14.295 10.4932 14.295 8.64025C14.295 7.76988 14.8387 7.06853 15.6878 6.88119C16.2891 6.74847 16.7995 6.95745 17.228 7.3787C17.5705 7.71526 17.7215 8.13651 17.7237 8.60982C17.7281 9.54691 17.7252 10.484 17.7252 11.4211V11.4215ZM16.5846 11.4365C16.5846 10.5031 16.5846 9.56964 16.5846 8.63585C16.5846 8.59332 16.5846 8.55043 16.5813 8.5079C16.5531 8.15557 16.222 7.91763 15.8789 8.00232C15.5889 8.07418 15.4363 8.28279 15.436 8.61165C15.436 10.491 15.436 12.3699 15.436 14.2492C15.436 14.2888 15.4378 14.3284 15.4415 14.368C15.4701 14.6719 15.6662 14.856 15.9848 14.8798C16.2693 14.9011 16.5007 14.7299 16.5644 14.445C16.5795 14.3775 16.5835 14.3061 16.5835 14.2364C16.5846 13.303 16.5842 12.3695 16.5842 11.4358L16.5846 11.4365Z" fill="white" />
      <path d="M13.1464 12.5965C13.1464 13.149 13.1501 13.7015 13.1453 14.2536C13.1417 14.661 13.0273 15.036 12.7776 15.3627C12.4784 15.7546 12.0693 15.9617 11.5894 16.0043C10.664 16.0857 9.82261 15.4675 9.72729 14.4153C9.72069 14.3424 9.71666 14.269 9.71666 14.1961C9.71592 13.1127 9.71372 12.029 9.71739 10.9456C9.71922 10.4671 9.87101 10.0393 10.1991 9.6855C10.4877 9.37424 10.847 9.18982 11.2693 9.14363C12.0781 9.05527 12.903 9.62281 13.0944 10.5137C13.1263 10.6618 13.1424 10.8154 13.1439 10.9668C13.1497 11.5102 13.1461 12.0535 13.1461 12.5969L13.1464 12.5965ZM12.0055 12.5972C12.0055 12.0418 12.0077 11.4864 12.0033 10.9313C12.0026 10.8327 11.9872 10.7293 11.9549 10.6362C11.8709 10.3942 11.6561 10.2732 11.4332 10.2721C11.211 10.271 10.9907 10.3912 10.9056 10.6409C10.873 10.737 10.8594 10.8437 10.8591 10.9456C10.8558 12.044 10.8569 13.1428 10.8572 14.2412C10.8572 14.287 10.8583 14.3328 10.8631 14.3783C10.8884 14.6203 11.038 14.8025 11.2532 14.8564C11.516 14.9224 11.7694 14.8446 11.9006 14.6544C11.9813 14.537 12.0059 14.4036 12.0055 14.2635C12.0048 13.7081 12.0055 13.1527 12.0055 12.5976V12.5972Z" fill="white" />
      <path d="M0.571255 13.1596C0.571255 12.7325 0.549257 12.3039 0.575654 11.8786C0.632114 10.9683 1.47682 10.2222 2.35928 10.2783C3.15302 10.3286 3.82724 10.9478 3.95593 11.7496C3.97646 11.8786 3.98672 12.0103 3.98745 12.1408C3.99039 12.8458 3.99259 13.5508 3.98745 14.2558C3.98452 14.6496 3.8749 15.014 3.64026 15.3352C3.34256 15.7421 2.92828 15.9584 2.43847 16.0035C1.50761 16.0893 0.653012 15.4679 0.573821 14.4131C0.542658 13.9974 0.568688 13.5776 0.568688 13.1596H0.571255V13.1596ZM2.84799 13.1721H2.84579C2.84579 12.7783 2.85715 12.3842 2.84286 11.9912C2.82489 11.4937 2.34975 11.3628 2.02528 11.4999C1.78075 11.6037 1.70192 11.809 1.70046 12.0543C1.69789 12.4755 1.69936 12.8964 1.69972 13.3176C1.69972 13.6564 1.69019 13.9955 1.70412 14.3339C1.72575 14.8655 2.23536 14.9616 2.53526 14.8245C2.76953 14.717 2.84469 14.5169 2.84725 14.2797C2.85092 13.9105 2.84835 13.5413 2.84835 13.1717L2.84799 13.1721Z" fill="white" />
    </svg>

  );
}

export default ButtonGridPeriodIcon;
