/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as AudienceActions } from '~/redux/ducks/audience';

import {
  Row,
  FormGroup,
  Input,
  FormGroupHeader,
  Toggle,
  Select,
} from './styles';

class FormHeader extends Component {
  componentDidMount() {}

  handleSetData = (data) => {
    const {
      audienceSetNew,
      audienceSetUpdate,
      audienceNew,
      audienceUpdate,
      isUpdateMode,
    } = this.props;

    if (isUpdateMode) {
      audienceSetUpdate({
        ...audienceUpdate,
        ...data,
      });
    } else {
      audienceSetNew({
        ...audienceNew,
        ...data,
      });
    }
  };

  render() {
    const {
      contracts, hasError, handleGetData, user: { hasPermissionGlobalAudience },
    } = this.props;

    const contractList = contracts.map(item => ({
      label: item.name,
      value: item.uuid,
    }));
    const contractSelected = contractList.find((item) => {
      const hasContract = handleGetData('contract');
      return hasContract && hasContract.uuid === item.value;
    });

    return (
      <Row className="header-form-box">
        <FormGroup spaceRight={70}>
          <label>Nome</label>
          <Input
            placeholder="Nome"
            defaultValue={handleGetData('name')}
            onChange={(e) => {
              this.handleSetData({
                name: e.target.value,
                description: e.target.value,
              });
            }}
            invalid={hasError && !handleGetData('name')}
          />
        </FormGroup>
        {hasPermissionGlobalAudience && (
          <FormGroupHeader style={{ flex: 1 }}>
            <FormGroup style={{ alignItems: 'center' }} spaceRight={10}>
              <label>Audiência global</label>
              <Toggle
                value={handleGetData('shared')}
                style={{ justifyContent: 'center' }}
                onChange={(e) => {
                  const isGlobal = e.target.checked;
                  this.handleSetData({
                    shared: isGlobal,
                    contract: isGlobal ? contracts[0] : handleGetData('contract'),
                  });
                }}
              />
            </FormGroup>

            <FormGroup>
              <label>Contrato</label>
              <Select
                isDisabled={handleGetData('shared')}
                placeholder="Contrato"
                value={contractSelected || null}
                onChange={(contract) => {
                  this.handleSetData({
                    contract: contracts.find(
                      item => item.uuid === (contract ? contract.value : null),
                    ),
                  });
                }}
                options={contractList}
                invalid={hasError && !handleGetData('contract')}
              />
            </FormGroup>
          </FormGroupHeader>
        )}
      </Row>
    );
  }
}


FormHeader.defaultProps = {
  hasError: false,
};

FormHeader.propTypes = {
  hasError: PropTypes.bool,
  isUpdateMode: PropTypes.bool.isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  audienceNew: PropTypes.shape().isRequired,
  audienceUpdate: PropTypes.shape().isRequired,
  user: PropTypes.shape().isRequired,
  audienceSetNew: PropTypes.func.isRequired,
  audienceSetUpdate: PropTypes.func.isRequired,
  handleGetData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  contracts: state.audience.contracts,
  audienceNew: state.audience.audienceNew,
  audienceUpdate: state.audience.audienceUpdate,
  user: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    audienceSetNew: AudienceActions.audienceSetNew,
    audienceSetUpdate: AudienceActions.audienceSetUpdate,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormHeader);
