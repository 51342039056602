import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  updateFilter: ['filter'],
  cleanFilter: null,
  goSearch: ['page'],
  goSearchResult: ['result'],
  showDetailProduct: ['productId'],
  showDetailProductSetData: ['product'],
  showDetailProductResult: ['product', 'indicator', 'evaluation'],
  closeDetailProduct: null,
  changePage: ['page'],
  changeStrategy: ['strategy'],
  changeStrategyIndicator: ['strategy'],
});

export const ProductTypes = Types;
export default Creators;

const INIT_FILTER = {
  productName: '',
  contractName: '',
  status: 'active',
};

const INIT_MODAL_DETAIL = {
  show: false,
  product: null,
  indicator: null,
  evaluation: null,
};

/* Initial State */
const INITIAL_STATE = Immutable({
  filter: INIT_FILTER,
  data: [],
  paginate: {
    page: 0,
    pageCount: 0,
  },
  modalDetail: INIT_MODAL_DETAIL,
});

/* Reducers */
const updateFilter = (state, { filter }) => ({
  ...state,
  filter,
});

const cleanFilter = state => ({
  ...state,
  filter: INIT_FILTER,
});

const goSearchResult = (state, { result: { data, paginate } }) => ({
  ...state,
  data,
  paginate,
});

const showDetailProductResult = (state, { product, indicator, evaluation }) => ({
  ...state,
  modalDetail: {
    show: true,
    product,
    indicator,
    evaluation,
  },
});

const changeStrategyIndicator = (state, { strategy }) => ({
  ...state,
  modalDetail: {
    ...state.modalDetail,
    indicator: {
      ...state.modalDetail.indicator,
      strategy,
    },
  },
});

const closeDetailProduct = state => ({
  ...state,
  modalDetail: INIT_MODAL_DETAIL,
});

export const reducer = createReducer(INITIAL_STATE, {
  [ProductTypes.UPDATE_FILTER]: updateFilter,
  [ProductTypes.CLEAN_FILTER]: cleanFilter,
  [ProductTypes.GO_SEARCH_RESULT]: goSearchResult,
  [ProductTypes.SHOW_DETAIL_PRODUCT_RESULT]: showDetailProductResult,
  [ProductTypes.CLOSE_DETAIL_PRODUCT]: closeDetailProduct,
  [ProductTypes.CHANGE_STRATEGY_INDICATOR]: changeStrategyIndicator,
});
