import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  showAlertModal: ['title', 'message'],
  hideAlertModal: null,
});

export const AlertModal = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  title: '',
  message: '',
  modalStatus: false,
});

/* Reducers */
const open = (state, { title, message }) => ({
  ...state,
  title,
  message,
  modalStatus: true,
});

const close = state => ({
  ...state,
  modalStatus: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [AlertModal.SHOW_ALERT_MODAL]: open,
  [AlertModal.HIDE_ALERT_MODAL]: close,
});
