import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  RowStyled,
  FormGroupStyled,
  InputStyled,
  InputMaskStyled,
  CurrentInputStyled,
} from '~/Components/Styled';


export const Container = styled.div``;

export const Input = styled(InputStyled)``;

export const InputMask = styled(InputMaskStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  height: 50px;
  label {
    display: flex;
    align-items: center;
    flex: 1;
    > b {
      display: flex;
      flex: 1;
    }
  }
`;

export const ClearButton = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  padding: 0 5px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const FormGroupHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  label {
    color: ${props => props.theme.colors.regular};
    font-size: 18px;
    font-weight: bold;
  }
`;

export const Select = styled(ReactSelect)`
  width: 100%;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props => props.theme.colors.danger2};
      }
    `}
`;

export const CreateAudiencia = styled.button.attrs({
  type: 'button',
})`
  padding: 0;
  background: transparent;
  display: flex;
  align-items: center;
  height: 30px;
  border: 0;
  color: ${props => props.theme.colors.primary};
  width: 180px;
  margin-bottom: 20px;
  > svg {
    margin-right: 5px;
  }
`;

export const CurrentInput = styled(CurrentInputStyled)`
  margin: 0;
  width: 170px;
`;

export const GroupedSelect = styled.div`
  display: flex;
  margin-bottom: 15px;
  [class*='-control'] {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
`;

export const ButtonPlus = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  width: 38px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  &:disabled {
    background-color: ${props => props.theme.colors.light};
    border: 1px solid ${props => props.theme.colors.lighter};
  }
`;

export const ErrorMessage = styled.span`
  margin-top: -15px;
  text-align: center;
  color: ${props => props.theme.colors.danger2};
  font-style: italic;
`;
