import { call, put, select } from 'redux-saga/effects';

import uuidv4 from 'uuid/v4';

import AuctionService from '~/services/AuctionService';
import { Creators as AuctionActions } from '~/redux/ducks/auction';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* auctionSetup() {
  const { auctionFilter } = yield select(state => state.auction);
  // Start loading
  yield put(startRequest());
  try {
    let periodSelected = null;
    if (!auctionFilter.period) {
      // GET Periods
      let periodList = yield call(AuctionService.periodRequest);
      periodList = periodList.map(item => ({
        value: item.uuid,
        label: `${item.label} ${item.bidOpen ? '(aberta)' : ''}`,
      }));
      yield put(AuctionActions.auctionPeriodRequestSuccess(periodList));
      yield put(AuctionActions.auctionSetFilter({
        period: periodList[0],
      }));
      periodSelected = periodList[0].value;
    } else {
      periodSelected = auctionFilter.period.value;
    }
    // GET Channels
    const channelsByPeriod = yield call(
      AuctionService.channelRequestByPeriod, periodSelected,
    );
    const channelZonesList = [];
    channelsByPeriod.forEach((channel) => {
      channel.zones.forEach((zone) => {
        channelZonesList.push({
          value: zone.uuid,
          label: `${channel.name} (${zone.name})`,
        });
      });
    });
    yield put(AuctionActions.auctionChannelRequestSuccess(channelZonesList));
    yield put(AuctionActions.auctionSetFilter({
      channel: channelZonesList[0],
    }));

    // Stop loading
    yield put(requestSuccess());
    // Load Auctions
    yield put(AuctionActions.auctionRequest());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}
export function* auctionRequest() {
  const { auctionFilter } = yield select(state => state.auction);
  try {
    if (auctionFilter.period && auctionFilter.channel) {
      // Start loading
      yield put(startRequest());
      // GET Auctions
      const auction = yield call(
        AuctionService.auctionRequest,
        auctionFilter.period.value,
        auctionFilter.channel.value,
      );
      // SET Auctions
      if (auction.gridItems && auction.gridItems.length > 0) {
        auction.gridItems.forEach((i) => {
          i.key = uuidv4();
        });
      }

      yield put(AuctionActions.auctionRequestSuccess(auction));
      // Stop Loading
      yield put(requestSuccess());
    }
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os leilões. Tente novamente!',
    ));
  }
}

export function* auctionGridUpdate() {
  const { dataGridUpdate } = yield select(state => state.auction);
  yield put(startRequest());
  try {
    if (!dataGridUpdate.budget || Number.isNaN(dataGridUpdate.budget)) {
      dataGridUpdate.budget = parseFloat('0');
    }
    yield call(AuctionService.auctionGridUpdate, dataGridUpdate);
    yield put(requestSuccess());
    yield put(AuctionActions.auctionSetDataGridUpdate(null));
    yield put(AuctionActions.auctionRequest());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Os dados foram atualizados!',
    ));
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar os dados. Tente novamente!',
    ));
  }
}

export function* auctionRankingUpdate() {
  const { auction } = yield select(state => state.auction);
  yield put(startRequest());
  try {
    yield call(AuctionService.auctionRankingUpdate, auction.uuid);
    yield put(requestSuccess());
    yield put(AuctionActions.auctionSetDataGridUpdate(null));
    yield put(AuctionActions.auctionRequest());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar o ranking. Tente novamente!',
    ));
  }
}

export function* auctionGetTermPreview() {
  const { dataGridUpdate } = yield select(state => state.auction);
  yield put(startRequest());
  try {
    const terms = yield call(AuctionService.auctionGetTermPreview, dataGridUpdate.gridItemUuid);
    yield put(requestSuccess());
    yield put(AuctionActions.auctionGetTermPreviewSuccess(terms));
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao carregar os dados do termo de aceite. Tente novamente!',
    ));
  }
}
