import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FileSaver from 'file-saver';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import UploadImage from '~/Components/UploadImage';
import Button from '~/Components/Button';
import ImageFromService from '~/Components/ImageFromService';

import {
  FormBox,
  FieldItem,
  Row,
  Input,
  FormGroup,
  BannerEmpty,
  ErrorMessage,
  Checkbox,
  Radiobox,
  RadioContainer,
  CheckContainer,
} from './styles';

import FileUploadService from '~/services/FileUploadService';

class NoCreditBannerTextForm extends Component {
  state = {
    errorImageSize: {},
    errorImageResolution: {},
  };

  componentDidMount() {
    this.handleInitData();
  }

  handleInitData = () => {
    const { criativoEdit: { creativeDefinition: { schema: { screens } } } } = this.props;
    const errorImageSize = {};
    const errorImageResolution = {};

    screens.forEach((item) => {
      switch (item.type) {
        case 'image': {
          errorImageSize[item.id] = false;
          errorImageResolution[item.id] = false;
          break;
        }
        default: break;
      }
    });

    this.setState({ errorImageSize, errorImageResolution });
  };

  uploadFileImage = async (field, file) => {
    const {
      campaign,
      campaignEditCriativoRequestSuccess,
      criativoEdit,
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    const referenceKey = `${campaign.uuid}-${field.id}`;

    if (file) {
      try {
        startRequest();
        const formData = new FormData();
        formData.append('file', file);
        const { uuid } = await FileUploadService.uploadFile(formData, referenceKey);
        requestSuccess();
        contentData[field.id] = uuid;
        campaignEditCriativoRequestSuccess({
          ...criativoEdit,
          content: contentData,
        });
      } catch (error) {
        errorMessage(
          'Atenção!',
          'Houve um problema ao enviar a imagem. Tente novamente mais tarde!',
        );
        requestError();
      }
    }
  };

  downloadFileImage = async (fileUuid) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;
    try {
      startRequest();
      const fileData = await FileUploadService.getFile(fileUuid);
      FileSaver.saveAs(fileData, `${fileUuid}.${fileData.type.split('/').pop()}`);
      requestSuccess();
    } catch (error) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao baixar a imagem. Tente novamente mais tarde!',
      );
      requestError();
    }
  };

  renderCheckField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    return (
      <Row>
        <CheckContainer invalid={hasError && field.required && contentData[field.id].length === 0}>
          {field.values.map(item => (
            <Checkbox
              disabled={disabledFields}
              label={item.label}
              value={!!contentData[field.id].find(val => val === item.value)}
              onChange={(value) => {
                if (value) {
                  contentData[field.id].push(item.value);
                } else {
                  contentData[field.id] = contentData[field.id].filter(val => val !== item.value);
                }
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: contentData,
                });
              }}
              style={{ marginRight: 20 }}
            />
          ))}
        </CheckContainer>
      </Row>
    );
  };

  renderRadioField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    return (
      <Row>
        <RadioContainer invalid={hasError && field.required && !content[field.id]}>
          {field.values.map(item => (
            <Radiobox
              disabled={disabledFields}
              label={item.label}
              value={content[field.id] === item.value}
              onChange={() => {
                contentData[field.id] = item.value;
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: contentData,
                });
              }}
              style={{ marginRight: 20 }}
            />
          ))}
        </RadioContainer>
      </Row>
    );
  };

  renderUrlField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    return (
      <Row>
        <Input
          disabled={disabledFields}
          placeholder="Digite a url"
          onChange={(e) => {
            contentData[field.id] = e.target.value;
            campaignEditCriativoRequestSuccess({
              ...criativoEdit,
              content: contentData,
            });
          }}
          value={contentData[field.id]}
          invalid={hasError && field.required && (
            !contentData[field.id]
            || !contentData[field.id].match(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            )
          )}
        />
      </Row>
    );
  };

  renderTextField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    return (
      <Row>
        <Input
          disabled={disabledFields}
          placeholder="Digite o texto"
          onChange={(e) => {
            contentData[field.id] = e.target.value;
            campaignEditCriativoRequestSuccess({
              ...criativoEdit,
              content: contentData,
            });
          }}
          value={contentData[field.id]}
          invalid={hasError && field.required && !contentData[field.id]}
          maxLength={field.maxLength ? field.maxLength : false}
        />
      </Row>
    );
  };

  renderImageField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      hasError,
      campaignEditCriativoRequestSuccess,
    } = this.props;
    const { content, creativeDefinition: { schema: { fields } } } = criativoEdit;
    const { errorImageSize, errorImageResolution } = this.state;
    const imageAccept = field.format.map(item => `image/${item}`);
    const contentData = {
      ...content,
    };

    const fieldValidate = fields.find(f => f.name === field.id && f.valueType === 'image') || null;
    const constraints = fieldValidate ? fieldValidate.constraints : [];
    const validate = constraints.length > 0 ? constraints[0] : null;

    let maxSize = field.size;
    if (validate && validate.maxFileSize) {
      const { unit, value } = validate.maxFileSize;
      if (unit === 'KB') {
        maxSize = value * 1000;
      } else {
        maxSize = value;
      }
    }

    return (
      <Row>
        <FormGroup>
          <div style={{ display: 'flex' }}>
            <UploadImage
              disabled={disabledFields}
              label={content[field.id] ? 'ALTERAR' : 'ADICIONAR'}
              onUpload={(files) => {
                contentData[field.id] = '';
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: contentData,
                });
                this.uploadFileImage(field, files[0]);
              }}
              accept={imageAccept.join(',')}
              maxSize={maxSize}
              onRejectMaxSize={() => {
                const errorData = { ...errorImageSize };
                errorData[field.id] = true;
                this.setState({ errorImageSize: errorData });
              }}
              onClick={() => {
                const errorData = { ...errorImageSize };
                errorData[field.id] = false;
                const errorDataResolutionUpdate = { ...errorImageResolution };
                errorDataResolutionUpdate[field.id] = false;
                this.setState({
                  errorImageSize: errorData,
                  errorImageResolution: errorDataResolutionUpdate,
                });
              }}
              style={{ marginTop: 0 }}
              styleContainer={{ height: 'auto' }}
              resolution={validate ? validate.resolution : null}
              onRejectResolution={() => {
                const errorData = { ...errorImageResolution };
                errorData[field.id] = true;
                this.setState({ errorImageResolution: errorData });
              }}
            />
            {content[field.id] && (
              <>
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    contentData[field.id] = '';
                    campaignEditCriativoRequestSuccess({
                      ...criativoEdit,
                      content: contentData,
                    });
                  }}
                />
                <Button
                  disabled={disabledFields}
                  title="DOWNLOAD"
                  icon="FaCloudDownloadAlt"
                  onClick={() => this.downloadFileImage(content[field.id])}
                  bordered
                  style={{ marginLeft: 7 }}
                />
              </>
            )}
          </div>
          {content[field.id] ? (
            <ImageFromService
              imageUuid={content[field.id]}
              width={field.width}
              height={field.height}
              fill={field.id === 'banner' ? 'contain' : 'cover'}
              style={{
                marginTop: 5,
                backgroundColor: 'rgba(42, 68, 82, 0.3)',
              }}
            />
          ) : (
            <BannerEmpty invalid={hasError && field.required && !content[field.id]}>
              Sem imagem de visualização
            </BannerEmpty>
          )}
        </FormGroup>
        <FormGroup style={{ marginLeft: '20px' }}>
          <label>
            {`Formatos: ${field.format.map(item => item.toUpperCase()).join(', ')}`}
          </label>
          {validate && validate.resolution && (
            <label>{`Dimensão recomendada:  ${validate.resolution.width}x${validate.resolution.height}`}</label>
          )}
          {errorImageSize[field.id] && (
            <ErrorMessage>
              {`Arquivo excedeu o limite de ${Math.ceil(maxSize / 1000)}kb.`}
            </ErrorMessage>
          )}
          {errorImageResolution[field.id] && validate && validate.resolution && (
            <ErrorMessage>
              {`Arquivo não atendeu as dimensões recomendadas de ${validate.resolution.width}x${validate.resolution.height}`}
            </ErrorMessage>
          )}
        </FormGroup>
      </Row>
    );
  };

  renderField = (field) => {
    const { criativoEdit } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };

    const getFieldByType = () => {
      switch (field.type) {
        case 'check': return this.renderCheckField(field);
        case 'radio': return this.renderRadioField(field);
        case 'url': return this.renderUrlField(field);
        case 'text': return this.renderTextField(field);
        case 'image': return this.renderImageField(field);
        default: return null;
      }
    };
    return (
      <FieldItem>
        <label>
          {field.label}
          {field.required && (<span>*</span>)}
          {!!field.maxLength && (
            <>
              -
              <small>
                {` ${contentData[field.id].length}/${field.maxLength} caracteres`}
              </small>
            </>
          )}
        </label>
        {getFieldByType()}
      </FieldItem>
    );
  };

  renderForm = () => {
    const { criativoEdit: { creativeDefinition: { schema: { screens } } } } = this.props;
    return (
      <FormBox>
        {screens.map(field => this.renderField(field))}
      </FormBox>
    );
  };

  render() {
    const { criativoEdit: { content, creativeDefinition: { schema } } } = this.props;
    return !!schema && !!Object.keys(content).length > 0 && this.renderForm();
  }
}

NoCreditBannerTextForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(NoCreditBannerTextForm);
