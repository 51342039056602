import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: fit-content;
  border: 1px solid ${props => props.theme.colors.primaryHex};
  margin-right: 20px;
  border-radius: 5px;
  padding: 10px;
  position: relative;

  .file-item-icon {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 10px;
    svg {
      font-size: 40px;
      color: ${props => props.theme.colors.primaryHex};
    }
  }

  .file-item-name {
    display: flex;
    flex-direction: column;
    flex: 1;
    text-align: center;
    color: ${props => props.theme.colors.darker};
    font-size: 18px;
    margin-bottom: 10px;
  }

  &:hover {}
`;

export const InvoiceFile = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 180px;
  width: 180px;
`;

export const ReceiptFile = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 180px;
  width: 180px;
`;

export const DeleteFile = styled.button.attrs({ type: 'button' })`
  position: absolute;
  top: 10px;
  right: 10px;
  color: ${props => props.theme.colors.danger2};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border: 1px solid ${props => props.theme.colors.danger2};
  border-radius: 3px;
  background-color: ${props => props.theme.colors.white};
  outline: none;
  svg {
    font-size: 20px !important;
  }
`;
