import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  RowStyled,
  FormGroupStyled,
  CurrentInputStyled,
} from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  label {
    display: flex;
    align-items: center;
    flex: 1;
    font-weight: bold;
  }
  margin-bottom: 10px;
`;

export const Select = styled(ReactSelect)`
  width: 100%;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props => props.theme.colors.danger2};
      }
    `}
`;

export const CurrentInput = styled(CurrentInputStyled)`
  margin: 0;
  width: 100%;
`;
