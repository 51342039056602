import styled from 'styled-components';
import {
  ContainerStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)`
  flex: 1;
  height: 100%;
  padding: 0;
  .custom-map {
    zoom: 125%;
    .leaflet-draw-toolbar {
      .leaflet-draw-draw-polygon {
        width: 140px;
        height: 50px;
        background: url('data:image/svg+xml;utf8,<svg stroke="red" fill="red" stroke-width="0" viewBox="0 0 448 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M384 352c-.35 0-.67.1-1.02.1l-39.2-65.32c5.07-9.17 8.22-19.56 8.22-30.78s-3.14-21.61-8.22-30.78l39.2-65.32c.35.01.67.1 1.02.1 35.35 0 64-28.65 64-64s-28.65-64-64-64c-23.63 0-44.04 12.95-55.12 32H119.12C108.04 44.95 87.63 32 64 32 28.65 32 0 60.65 0 96c0 23.63 12.95 44.04 32 55.12v209.75C12.95 371.96 0 392.37 0 416c0 35.35 28.65 64 64 64 23.63 0 44.04-12.95 55.12-32h209.75c11.09 19.05 31.49 32 55.12 32 35.35 0 64-28.65 64-64 .01-35.35-28.64-64-63.99-64zm-288 8.88V151.12A63.825 63.825 0 0 0 119.12 128h208.36l-38.46 64.1c-.35-.01-.67-.1-1.02-.1-35.35 0-64 28.65-64 64s28.65 64 64 64c.35 0 .67-.1 1.02-.1l38.46 64.1H119.12A63.748 63.748 0 0 0 96 360.88zM272 256c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zM400 96c0 8.82-7.18 16-16 16s-16-7.18-16-16 7.18-16 16-16 16 7.18 16 16zM64 80c8.82 0 16 7.18 16 16s-7.18 16-16 16-16-7.18-16-16 7.18-16 16-16zM48 416c0-8.82 7.18-16 16-16s16 7.18 16 16-7.18 16-16 16-16-7.18-16-16zm336 16c-8.82 0-16-7.18-16-16s7.18-16 16-16 16 7.18 16 16-7.18 16-16 16z"></path></svg>') no-repeat;
        background-position-y: center;
        background-position-x: 10px;
        background-size: 30px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
      }
      .leaflet-draw-draw-polygon:before {
        content: 'Poligono';
        text-indent: 50px;
        font-size: 18px;
      }
      .leaflet-draw-draw-circle {
        width: 140px;
        height: 50px;
        background: url('data:image/svg+xml;utf8,<svg stroke="blue" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><circle cx="12" cy="12" r="6"></circle><circle cx="12" cy="12" r="2"></circle></svg>') no-repeat;
        background-position-y: center;
        background-position-x: 10px;
        background-size: 30px;
        background-color: #ffffff;
        display: flex;
        align-items: center;
      }
      .leaflet-draw-draw-circle:before {
        content: 'Circulo';
        text-indent: 50px;
        font-size: 18px;
      }
    }
    .text-labels {
      font-size: 20px;
      color: #ffffff;
      white-space: nowrap;
      text-shadow: 4px 4px 2px rgba(0, 0, 0, 1);
    }
  }
`;

export const BoxActionMap = styled.div`
  width: 220px;
  height: 60px;
  z-index: 999999999;
  position: absolute;
  bottom: 30px;
  left: 30px;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
`;
