/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as ProviderActions } from '~/redux/ducks/provider';
import { Box, BoxTitle, BoxContentItem } from '~/Components/Box/styles';

import {
  Row,
  FormGroup,
  Textarea,
  Select,
} from './styles';

class TypeProvedor extends Component {
  componentDidMount() {
    const { loadProviderList } = this.props;
    loadProviderList();
  }

  render() {
    const {
      hasError,
      content,
      campaignEditCriativoRequestSuccess,
      criativoEdit,
      providers,
      disabledFields,
    } = this.props;

    const { mainData } = content;
    const { provider } = mainData;
    const providerHtml = provider && provider.htmlContent ? provider.htmlContent : '';
    const providerScript = provider && provider.script ? provider.script : '';
    const providerUuid = provider && provider.uuid ? provider.uuid : '';
    const providerList = providers.map(item => ({
      label: item.name,
      value: item.uuid,
      htmlContent: item.htmlContent,
      script: item.script,
    }));

    return (
      <>
        <Row>
          <FormGroup style={{ marginBottom: 10 }}>
            <label>
              Provedor
              <span>*</span>
            </label>
            <Select
              isDisabled={disabledFields}
              placeholder="Selecione Provedor..."
              value={providerList.find(item => item.value === providerUuid)}
              onChange={({ value, htmlContent, script }) => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...content,
                    mainData: {
                      ...content.mainData,
                      provider: {
                        ...content.mainData.provider,
                        uuid: value,
                        htmlContent,
                        script,
                      },
                    },
                  },
                });
              }}
              options={providerList}
              invalid={hasError && !providerUuid}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ marginBottom: 10 }}>
            <label>
              HTML
              {!providerScript && <span>*</span>}
            </label>
            <Textarea
              disabled={disabledFields}
              rows="8"
              placeholder="HTML"
              onChange={(e) => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...content,
                    mainData: {
                      ...content.mainData,
                      provider: {
                        ...content.mainData.provider,
                        htmlContent: e.target.value,
                      },
                    },
                  },
                });
              }}
              value={providerHtml}
              invalid={hasError && !(providerHtml || providerScript)}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label>
              SCRIPT
              {!providerHtml && <span>*</span>}
            </label>
            <Textarea
              disabled={disabledFields}
              rows="8"
              placeholder="SCRIPT"
              onChange={(e) => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...content,
                    mainData: {
                      ...content.mainData,
                      provider: {
                        ...content.mainData.provider,
                        script: e.target.value,
                      },
                    },
                  },
                });
              }}
              value={providerScript}
              invalid={hasError && !(providerHtml || providerScript)}
            />
          </FormGroup>
        </Row>
        <Box>
          <BoxTitle>
            <span>Tag Especial</span>
          </BoxTitle>
          <BoxContentItem>
            <p>
              [ATR_UUID]: Caso queira injetar o identificador da campanha,
              coloque esse código no <b>HTML</b> ou <b>SCRIPT</b>
            </p>
            <p>{'Ex: <h1>[ATR_UUID]</h1> irá resultar em <h1>b01c7f5a-5a74-4403-8d78-539fc6d4f896</h1>'}</p>
          </BoxContentItem>
        </Box>
        <Row />
      </>
    );
  }
}

TypeProvedor.defaultProps = {
  providers: [],
};

TypeProvedor.propTypes = {
  content: PropTypes.shape().isRequired,
  hasError: PropTypes.bool.isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  loadProviderList: PropTypes.func.isRequired,
  providers: PropTypes.arrayOf(PropTypes.shape()),
  disabledFields: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  providers: state.provider.providers,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadProviderList: ProviderActions.loadProviderList,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(TypeProvedor);
