import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  CurrentInput,
} from './styles';

import { convertFloatReal } from '~/Utils';

class ModalEditOrcamento extends Component {
  static propTypes = {
    modalEdit: PropTypes.shape().isRequired,
    campaignOrcamentoToEdit: PropTypes.shape().isRequired,
    campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
    campaignEditSetOrcamentoToEdit: PropTypes.func.isRequired,
    campaignEditSaveOrcamentoInEdit: PropTypes.func.isRequired,
    campaign: PropTypes.shape().isRequired,
  };

  componentDidMount() { }

  render() {
    const {
      modalEdit,
      campaignEditChangeModalEditStatus,
      campaignOrcamentoToEdit,
      campaignEditSetOrcamentoToEdit,
      campaignEditSaveOrcamentoInEdit,
      campaign,
    } = this.props;
    let pricing = null;
    let hasCpa = null;
    let hasBudget = null;
    let hasVolume = null;

    if (modalEdit.editOrcamentoStatus && campaignOrcamentoToEdit) {
      pricing = campaignOrcamentoToEdit.extra
        ? campaignOrcamentoToEdit.extra.pricing
        : null;
      hasCpa = ['FIXED_CPA', 'AUCTION', 'PROBE'].includes(campaign.campaignType.name);
      hasBudget = ['AUCTION'].includes(campaign.campaignType.name);
      hasVolume = ['FIXED_CPA', 'PROBE'].includes(campaign.campaignType.name);
    }

    return modalEdit.editOrcamentoStatus && campaignOrcamentoToEdit && (
      <Modal width={500}>
        <>
          <Row>
            <h1>Alterar Orçamento</h1>
            <ButtonCloseModal
              type="button"
              onClick={() => {
                campaignEditChangeModalEditStatus({
                  editOrcamentoStatus: false,
                });
                campaignEditSetOrcamentoToEdit(null);
              }}
            >
              <FaTimes size={30} />
            </ButtonCloseModal>
          </Row>
          {hasCpa && (
            <FormGroup>
              <label>
                <b>CPA: </b>
                <CurrentInput
                  placeholder="CPA"
                  prefix="R$ "
                  decimalSeparator=","
                  thousandSeparator="."
                  value={campaignOrcamentoToEdit.cpa}
                  invalid={campaignOrcamentoToEdit.cpa === ''}
                  onChange={(e, cpa) => {
                    campaignEditSetOrcamentoToEdit({ ...campaignOrcamentoToEdit, cpa });
                  }}
                />
                {(
                  pricing
                  && pricing.cpa
                  && campaignOrcamentoToEdit.cpa < pricing.cpa.min
                ) && (
                  <small>{`Mínimo: R$${convertFloatReal(pricing.cpa.min)}`}</small>
                )}
              </label>
            </FormGroup>
          )}
          {hasBudget && (
            <FormGroup>
              <label>
                <b>Budget: </b>
                <CurrentInput
                  placeholder="Budget"
                  prefix="R$ "
                  decimalSeparator=","
                  thousandSeparator="."
                  value={campaignOrcamentoToEdit.budget}
                  invalid={campaignOrcamentoToEdit.budget === ''}
                  onChange={(e, budget) => {
                    campaignEditSetOrcamentoToEdit({ ...campaignOrcamentoToEdit, budget });
                  }}
                />
              </label>
            </FormGroup>
          )}
          {hasVolume && (
            <FormGroup>
              <label>
                <b>Volume: </b>
                <CurrentInput
                  placeholder="Volume"
                  prefix=""
                  precision="0"
                  thousandSeparator="."
                  value={campaignOrcamentoToEdit.volume}
                  invalid={campaignOrcamentoToEdit.volume === ''}
                  onChange={(e, volume) => {
                    campaignEditSetOrcamentoToEdit({ ...campaignOrcamentoToEdit, volume });
                  }
                  }
                />
              </label>
            </FormGroup>
          )}
          <RowFooter>
            <Button
              disabled={
                hasCpa
                && (
                  campaignOrcamentoToEdit.cpa <= 0
                  || (
                    pricing
                    && pricing.cpa
                    && campaignOrcamentoToEdit.cpa < pricing.cpa.min
                  )
                )
              }
              title="SALVAR"
              onClick={() => {
                campaignEditSaveOrcamentoInEdit();
              }}
            />
          </RowFooter>
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { campaign } = state.campaign.edit;
  return {
    campaign,
    modalEdit: state.campaignEdit.modalEdit,
    campaignOrcamentoToEdit: state.campaignEdit.campaignOrcamentoToEdit,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditSetOrcamentoToEdit: CampaignEditActions.campaignEditSetOrcamentoToEdit,
    campaignEditSaveOrcamentoInEdit: CampaignEditActions.campaignEditSaveOrcamentoInEdit,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditOrcamento);
