import styled, { css } from 'styled-components';


export const Container = styled.label`
  display: block;
  position: relative;
  padding-left: 25px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border-radius: 3px;
    border: 2px solid ${props => props.theme.colors.primary};
  }

  &:hover input ~ .checkmark {
    background-color: #fff;
  }

  input:checked ~ .checkmark {
    background-color: ${props => (props.colorChecked ? props.colorChecked : props.theme.colors.primary)};
    border-color: ${props => (props.colorChecked ? props.colorChecked : props.theme.colors.primary)};
  }

  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  input:checked ~ .checkmark:after {
    display: block;
  }

  .checkmark:after {
    left: 6px;
    top: 2px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  ${props => props.disabled
    && css`
      opacity: 0.7;
    `}
`;
