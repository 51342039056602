import axios from 'axios';

const api = axios.create({
  baseURL:
  process.env.NODE_ENV === 'development'
    ? 'http://admin-dev.oiads.com.br:3000/api'
    : localStorage.getItem('api_url'),
});

api.interceptors.request.use((config) => {
  // const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJrUTFSalF6TURreE9EUkRNakl3UkRaQk5qUkJOVGsyTkVaRFJEaEdPVUZCTmtVeE16QXdSQSJ9.eyJodHRwczovL2FydGVtaXMubW9iaWNhcmUuY29tLmJyL3VzZXJVdWlkIjoiNDE2YjlmMmUtMzY1NS00NDc2LThiZGUtYTQ2ZTFlNmEwMDMwIiwiaHR0cHM6Ly9hcnRlbWlzLm1vYmljYXJlLmNvbS5ici9jb250cmFjdFV1aWQiOiI0ZmZjYTYyZS00ZWYzLTQyMTEtOTA3Yi05ODM1MGMzMTc0NTciLCJodHRwczovL2FydGVtaXMubW9iaWNhcmUuY29tLmJyL3RlbmFudFV1aWQiOiIyN2U5NjEwYS02ZDc4LTRkZGQtYWY4OC0wMDNlYjhjYjliNDAiLCJodHRwczovL2FydGVtaXMubW9iaWNhcmUuY29tLmJyL2NsaWVudE5hbWUiOiJPSSIsImh0dHBzOi8vYXJ0ZW1pcy5tb2JpY2FyZS5jb20uYnIvdXNlclJvbGVzIjpbIkFVQ1RJT05fQURNSU4iLCJXT1JLRkxPV19BRE1JTiIsIkdMT0JBTF9BVURJRU5DRV9DUkVBVE9SIiwiTE9DQUxfQVVESUVOQ0VfQ1JFQVRPUiIsIkFVRElFTkNFX1RFU1RfRVhFQ1VUSU9OIl0sImh0dHBzOi8vYXJ0ZW1pcy5tb2JpY2FyZS5jb20uYnIvaXNTb2NpYWwiOmZhbHNlLCJodHRwczovL2FydGVtaXMubW9iaWNhcmUuY29tLmJyL2NyZWF0ZWRBdCI6IjIwMjAtMDItMDVUMTU6NTM6MTkuMjUzWiIsIm5pY2tuYW1lIjoicWEiLCJuYW1lIjoicWFAbW9iaWNhcmUuY29tLmJyIiwicGljdHVyZSI6Imh0dHBzOi8vcy5ncmF2YXRhci5jb20vYXZhdGFyL2VlNWViMmFkOGMwNGZjMjZiN2UwNzE3ZDY1NmY3Y2I4P3M9NDgwJnI9cGcmZD1odHRwcyUzQSUyRiUyRmNkbi5hdXRoMC5jb20lMkZhdmF0YXJzJTJGcWEucG5nIiwidXBkYXRlZF9hdCI6IjIwMjEtMDctMjZUMTM6Mzk6MjQuMjI0WiIsImlzcyI6Imh0dHBzOi8vbW9iaWNhcmUuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDVlM2FlNGVmMTE1ZGRmMGU4YTBhM2M3ZiIsImF1ZCI6InZVWGlCOHFMUVZ6U2QwbUJkc0hTNzB1c05PdXcycENmIiwiaWF0IjoxNjI3MzEwNzMzLCJleHAiOjE2MjczNDY3MzMsImF0X2hhc2giOiJHTW1ETzRtSDY2VWlHUWYzVmFJU1VBIiwibm9uY2UiOiJGdy1-TG9oU1o2MkNHa0VqMFRzWk5MTn5CUDBTYlZpUSJ9.kUa6IZHyg1Az4-gkbxTqQDwMah6lGzFrTsYqWGvI7NLvcdRxygJ3faG4lpo3ttYGtbJYkMoGBJPRJqM9WTOkEFiNP2qDFBFNVFsorR60tisrPCf2WZo76Mk9Sn7KM8BDF2MA5l40jBzhoCpNAKKvj8lWDoxSlodfJypYB016QpqK2Cqtxq8b8QAy9EEEacI55wJuvBgndKoze3N6x_qKAci9xzI5fDsfAelQJdCAtEA0wu7So-NY4QWP-WiKPp2Mh5MMlY7Ga_0FkWfj0qdHYPqMDWZuUKTIrazKEMoVOSzmaKbqf6nB9VqHZF3WFA9X0niiwgm7s19IpbamwlF21A';
  // const token = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImxNekM0NjU5S1lLeUdzYm1BLXhPTyJ9.eyJodHRwczovL2FydGVtaXMubW9iaWNhcmUuY29tLmJyL3VzZXJVdWlkIjoiOGNkZjNjNmMtODcyYy00NGQ4LWJiZDQtNjUzNTc3MWU3YTBkIiwiaHR0cHM6Ly9hcnRlbWlzLm1vYmljYXJlLmNvbS5ici9jb250cmFjdFV1aWQiOiJiYzgzYWI5Yy0wMzJlLTQxNTEtOTdlNy0zYjAyZDViZWVkNGIiLCJodHRwczovL2FydGVtaXMubW9iaWNhcmUuY29tLmJyL3RlbmFudFV1aWQiOiJkMmIwN2MyNS0wOWIwLTQzYWQtODFkNy1jYjA1ODhjNTY2YzgiLCJodHRwczovL2FydGVtaXMubW9iaWNhcmUuY29tLmJyL2NsaWVudE5hbWUiOiJDTEFSTyIsImh0dHBzOi8vYXJ0ZW1pcy5tb2JpY2FyZS5jb20uYnIvdXNlclJvbGVzIjpbIlJFUE9SVF9WSUVXRVIiLCJBVUNUSU9OX1VTRVIiLCJHUklEUEVSSU9EX0FETUlOIiwiUFJPRFVDVFMiLCJDQU1QQUlHTl9VU0VSIiwiV09SS0ZMT1dfQURNSU4iLCJBVUNUSU9OX0FETUlOIiwiRklOQU5DRV9WSUVXRVIiLCJGSU5BTkNFX0FETUlOIl0sImh0dHBzOi8vYXJ0ZW1pcy5tb2JpY2FyZS5jb20uYnIvaXNTb2NpYWwiOmZhbHNlLCJodHRwczovL2FydGVtaXMubW9iaWNhcmUuY29tLmJyL2NyZWF0ZWRBdCI6IjIwMjItMTItMDhUMTc6MzY6MTUuODI4WiIsIm5pY2tuYW1lIjoiYWRtaW4iLCJuYW1lIjoiYWRtaW5AZ2RjLmNvbS5iciIsInBpY3R1cmUiOiJodHRwczovL3MuZ3JhdmF0YXIuY29tL2F2YXRhci9mZDdlYjkwODlmN2MyYzI5ZTIxZDUxMjY3MWNmYTE4Zj9zPTQ4MCZyPXBnJmQ9aHR0cHMlM0ElMkYlMkZjZG4uYXV0aDAuY29tJTJGYXZhdGFycyUyRmFkLnBuZyIsInVwZGF0ZWRfYXQiOiIyMDIzLTA5LTI2VDEyOjU2OjI2LjIzOFoiLCJpc3MiOiJodHRwczovL2Frcm9zcy10ZW5hbnQudXMuYXV0aDAuY29tLyIsImF1ZCI6IkthZTROOTdJRm5kcTczV2t4cTU0VkRKcklFN3draWRnIiwiaWF0IjoxNjk1NzMyOTg2LCJleHAiOjE2OTU3Njg5ODYsInN1YiI6ImF1dGgwfDYzOTIyMDhmZjQ0NDQ4Y2JmOTEyNjZkMCIsImF0X2hhc2giOiJWdkpRNlNoLXMtd0pURjg3Q3NiMTdRIiwic2lkIjoibGFrY185d1M3ajJRN3FJUDVHNXVGMUphRHZVX3RzeU8iLCJub25jZSI6IjBQU3ltdHdMd2d0a2J6X2ltUHdqUHJJOWY2RjdLdXdoIn0.hX7cSPl8hmFdKtsOipBHQBQeTz3cA6v896t2Mm_dFCz1-VWZEazFc8FTlUqrWgH_diHxZ0gWaZKf0A7ubl_ooJQWflQy1-_02F-gKqGFI3n9_3W6N-g6aJMMikVwoLevgswX-pL_bTiq-f8vxaCdM_st-ufItbmAV-umAjkqz6fg4B5IlzzO5CNz71YiM1lQOajSoExkSSxihfRkS9_6HmS12YIHEt81xWdFXlNcFMXf7YC1722GaYlqb2yrwEWsd-SsnnbFiKVjCy4asxxLsSm_mjx3BaRHY7qdzhIrvLisTqff6U2eo6nFEx1l50E15bIZVB7deHJi5xOsZDQPqQ';
  const token = localStorage.getItem('token');
  const headers = { ...config.headers };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  return { ...config, headers };
});

export default api;
