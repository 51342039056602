import CreativeName from '~/Utils/CreativeName';

const getReportTemplateByCreative = (creative, campaign) => {
  const getTemplateDefault = () => {
    const template = {
      reportTotal: {
        visible: true,
        title: 'Total Efetuado',
        data: [
          {
            label: 'Audiência total',
            tooltip: 'Quantidade de usuários que estão habilitados a receber campanhas',
            icon: 'database',
            field: 'audienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'OptIn Atualizado',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'user-plus',
            field: 'processedAudienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Envios contratados',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'money-bill-wave',
            field: 'volume',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Mensagens enviadas',
            tooltip: 'Quantidade de mensagens enviadas com sucesso pela aplicação',
            icon: 'check',
            field: 'deliveredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Reimpacto ignorado',
            tooltip: 'Valor total de reimpacto ignorado',
            icon: 'user-minus',
            field: 'reimpactIgnoredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
        ],
      },
      mediaVast: {
        visible: false,
        title: 'Informações media VAST',
      },
      chartAccumulated: {
        visible: true,
        title: 'Acumulado',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      chartEvents: {
        visible: true,
        title: 'Eventos',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      dataTable: {
        visible: true,
        title: 'Dados',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'CTR', field: 'ctr' },
          { label: 'Impressões Efetivas', field: 'impressedQty' },
          { label: 'Interação', field: 'interactedQty' },
          { label: 'Conversão', field: 'convertedQty' },
          { label: 'Benefícios Concedidos', field: 'benefitQty' },
          { label: 'Megas Concedidos', field: 'benefitUnitQty' },
        ],
      },
      question: {
        visible: false,
        title: 'Questões',
      },
    };
    return template;
  };

  const getTemplateSMSTextMessage = () => {
    const hasBenefit = campaign.benefitOffers && campaign.benefitOffers.length > 0;
    const template = {
      reportTotal: {
        visible: true,
        title: 'Total Efetuado',
        data: [
          {
            label: 'Audiência total',
            tooltip: 'Quantidade de usuários que estão habilitados a receber campanhas',
            icon: 'database',
            field: 'audienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'OptIn Atualizado',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'user-plus',
            field: 'processedAudienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Envios contratados',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'money-bill-wave',
            field: 'volume',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Mensagens enviadas',
            tooltip: 'Quantidade de mensagens enviadas com sucesso pela aplicação',
            icon: 'check',
            field: 'deliveredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Reimpacto ignorado',
            tooltip: 'Valor total de reimpacto ignorado',
            icon: 'user-minus',
            field: 'reimpactIgnoredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Benefícios concedidos',
            tooltip: 'Benefícios concedidos',
            icon: 'signal',
            field: 'benefitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Megas concedidos',
            tooltip: 'Valor total de benefícios concedidos em megas.',
            icon: 'cloud-download-alt',
            field: 'benefitUnitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
        ],
      },
      mediaVast: {
        visible: false,
        title: 'Informações media VAST',
      },
      chartAccumulated: {
        visible: true,
        title: 'Acumulado',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      chartEvents: {
        visible: true,
        title: 'Eventos',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      dataTable: {
        visible: true,
        title: 'Dados',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'CTR', field: 'ctr' },
          { label: 'Impressões Efetivas', field: 'impressedQty' },
          { label: 'Interação', field: 'interactedQty' },
          { label: 'Conversão', field: 'convertedQty' },
          { label: 'Benefícios Concedidos', field: 'benefitQty' },
          { label: 'Megas Concedidos', field: 'benefitUnitQty' },
        ],
      },
      question: {
        visible: false,
        title: 'Questões',
      },
    };
    return template;
  };

  const getTemplateSMSMediaMessage = () => {
    const isVast = creative.content
      && creative.content.mainData
      && creative.content.mainData.media
      && creative.content.mainData.media.type
      && creative.content.mainData.media.type === 'vast';
    const hasBenefit = campaign.benefitOffers && campaign.benefitOffers.length > 0;

    const template = {
      reportTotal: {
        visible: true,
        title: 'Total Efetuado',
        data: [
          {
            label: 'Audiência total',
            tooltip: 'Quantidade de usuários que estão habilitados a receber campanhas',
            icon: 'database',
            field: 'audienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'OptIn Atualizado',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'user-plus',
            field: 'processedAudienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Envios contratados',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'money-bill-wave',
            field: 'volume',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Mensagens enviadas',
            tooltip: 'Quantidade de mensagens enviadas com sucesso pela aplicação',
            icon: 'check',
            field: 'deliveredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Cliques',
            tooltip: 'Quantidade de usuários que clicaram no corpo da mensagem e foram redirecionados',
            icon: 'mouse-pointer',
            field: 'clickedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'CTR',
            tooltip: 'Número de cliques recebidos pelo seu anúncio dividido pelo número de vezes que ele foi exibido ( cliques / mensagens enviadas = CTR )',
            icon: '',
            field: 'ctr',
            type: 'percentage',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Impressões efetivas',
            tooltip: 'O criativo foi efetivamente exibido na tela do usuário',
            icon: 'user-friends',
            field: 'impressedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Interação',
            tooltip: 'Interação',
            icon: 'user-cog',
            field: 'interactedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Conversão',
            tooltip: 'Conversão',
            icon: 'chart-pie',
            field: 'convertedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Benefícios concedidos',
            tooltip: 'Benefícios concedidos',
            icon: 'signal',
            field: 'benefitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Megas concedidos',
            tooltip: 'Valor total de benefícios concedidos em megas.',
            icon: 'cloud-download-alt',
            field: 'benefitUnitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Reimpacto ignorado',
            tooltip: 'Valor total de reimpacto ignorado',
            icon: 'user-minus',
            field: 'reimpactIgnoredQty',
            type: 'decimal',
            visible: !isVast,
            ifHasData: null,
          },
        ],
      },
      mediaVast: {
        visible: isVast,
        title: 'Informações media VAST',
      },
      chartAccumulated: {
        visible: true,
        title: 'Acumulado',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      chartEvents: {
        visible: true,
        title: 'Eventos',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      dataTable: {
        visible: true,
        title: 'Dados',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'CTR', field: 'ctr' },
          { label: 'Impressões Efetivas', field: 'impressedQty' },
          { label: 'Interação', field: 'interactedQty' },
          { label: 'Conversão', field: 'convertedQty' },
          { label: 'Benefícios Concedidos', field: 'benefitQty' },
          { label: 'Megas Concedidos', field: 'benefitUnitQty' },
          ...(!isVast ? [] : [
            { label: 'Início', field: 'startQty' },
            { label: 'Primeiro quartil', field: 'firstQuartileQty' },
            { label: 'Segundo quartil', field: 'midpointQty' },
            { label: 'Terceiro quartil', field: 'thirdQuartileQty' },
            { label: 'Completo', field: 'completeQty' },
          ]),
        ],
      },
      question: {
        visible: true,
        title: 'Questões',
      },
    };
    return template;
  };

  const getTemplatePushNotificationTextMessage = () => {
    const hasButton1 = creative.content
      && creative.content.channelData
      && !!creative.content.channelData.button_text_1;
    const hasButton2 = creative.content
      && creative.content.channelData
      && !!creative.content.channelData.button_text_2;
    const hasBenefit = campaign.benefitOffers && campaign.benefitOffers.length > 0;

    const template = {
      reportTotal: {
        visible: true,
        title: 'Total Efetuado',
        data: [
          {
            label: 'Audiência total',
            tooltip: 'Quantidade de usuários que estão habilitados a receber campanhas',
            icon: 'database',
            field: 'audienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'OptIn Atualizado',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'user-plus',
            field: 'processedAudienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Envios contratados',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'money-bill-wave',
            field: 'volume',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Mensagens enviadas',
            tooltip: 'Quantidade de mensagens enviadas com sucesso pela aplicação',
            icon: 'check',
            field: 'deliveredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Cliques',
            tooltip: 'Quantidade de usuários que clicaram no corpo da mensagem e foram redirecionados',
            icon: 'mouse-pointer',
            field: 'clickedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'CTR',
            tooltip: 'Número de cliques recebidos pelo seu anúncio dividido pelo número de vezes que ele foi exibido ( cliques / mensagens enviadas = CTR )',
            icon: '',
            field: 'ctr',
            type: 'percentage',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Dismiss',
            tooltip: 'Quantidade de usuários que optaram por dispensar a campanha.',
            icon: 'trash',
            field: 'dismissQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Botão 1',
            tooltip: 'Quantidade de usuários que clicaram no botão 1 com sua url especifica',
            icon: 'mouse-pointer',
            field: 'clickPushBtn1Qty',
            type: 'decimal',
            visible: hasButton1,
            ifHasData: null,
          },
          {
            label: 'Botão 2',
            tooltip: 'Quantidade de usuários que clicaram no botão 2 com sua url especifica',
            icon: 'mouse-pointer',
            field: 'clickPushBtn2Qty',
            type: 'decimal',
            visible: hasButton2,
            ifHasData: null,
          },
          {
            label: 'Reimpacto ignorado',
            tooltip: 'Valor total de reimpacto ignorado',
            icon: 'user-minus',
            field: 'reimpactIgnoredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Benefícios concedidos',
            tooltip: 'Benefícios concedidos',
            icon: 'signal',
            field: 'benefitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Megas concedidos',
            tooltip: 'Valor total de benefícios concedidos em megas.',
            icon: 'cloud-download-alt',
            field: 'benefitUnitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
        ],
      },
      mediaVast: {
        visible: false,
        title: 'Informações media VAST',
      },
      chartAccumulated: {
        visible: true,
        title: 'Acumulado',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      chartEvents: {
        visible: true,
        title: 'Eventos',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      dataTable: {
        visible: true,
        title: 'Dados',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'CTR', field: 'ctr' },
          { label: 'Impressões Efetivas', field: 'impressedQty' },
          { label: 'Interação', field: 'interactedQty' },
          { label: 'Conversão', field: 'convertedQty' },
          { label: 'Benefícios Concedidos', field: 'benefitQty' },
          { label: 'Megas Concedidos', field: 'benefitUnitQty' },
          { label: 'Dismiss', field: 'dismissQty' },
          { label: 'Click Botão 1', field: 'clickPushBtn1Qty' },
          { label: 'Click Botão 2', field: 'clickPushBtn2Qty' },
        ],
      },
      question: {
        visible: false,
        title: 'Questões',
      },
    };
    return template;
  };

  const getTemplatePushNotificationImageMessage = () => {
    const isVast = creative.content
      && creative.content.mainData
      && creative.content.mainData.media
      && creative.content.mainData.media.type
      && creative.content.mainData.media.type === 'vast';
    const hasButton1 = creative.content
      && creative.content.channelData
      && !!creative.content.channelData.button_text_1;
    const hasButton2 = creative.content
      && creative.content.channelData
      && !!creative.content.channelData.button_text_2;
    const hasBenefit = campaign.benefitOffers && campaign.benefitOffers.length > 0;

    const template = {
      reportTotal: {
        visible: true,
        title: 'Total Efetuado',
        data: [
          {
            label: 'Audiência total',
            tooltip: 'Quantidade de usuários que estão habilitados a receber campanhas',
            icon: 'database',
            field: 'audienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'OptIn Atualizado',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'user-plus',
            field: 'processedAudienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Envios contratados',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'money-bill-wave',
            field: 'volume',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Mensagens enviadas',
            tooltip: 'Quantidade de mensagens enviadas com sucesso pela aplicação',
            icon: 'check',
            field: 'deliveredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Cliques',
            tooltip: 'Quantidade de usuários que clicaram no corpo da mensagem e foram redirecionados',
            icon: 'mouse-pointer',
            field: 'clickedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'CTR',
            tooltip: 'Número de cliques recebidos pelo seu anúncio dividido pelo número de vezes que ele foi exibido ( cliques / mensagens enviadas = CTR )',
            icon: '',
            field: 'ctr',
            type: 'percentage',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Impressões efetivas',
            tooltip: 'O criativo foi efetivamente exibido na tela do usuário',
            icon: 'user-friends',
            field: 'impressedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Interação',
            tooltip: 'Interação',
            icon: 'user-cog',
            field: 'interactedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Conversão',
            tooltip: 'Conversão',
            icon: 'chart-pie',
            field: 'convertedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Benefícios concedidos',
            tooltip: 'Benefícios concedidos',
            icon: 'signal',
            field: 'benefitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Megas concedidos',
            tooltip: 'Valor total de benefícios concedidos em megas.',
            icon: 'cloud-download-alt',
            field: 'benefitUnitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Dismiss',
            tooltip: 'Quantidade de usuários que optaram por dispensar a campanha.',
            icon: 'trash',
            field: 'dismissQty',
            type: 'decimal',
            visible: !isVast,
            ifHasData: null,
          },
          {
            label: 'Botão 1',
            tooltip: 'Quantidade de usuários que clicaram no botão 1 com sua url especifica',
            icon: 'mouse-pointer',
            field: 'clickPushBtn1Qty',
            type: 'decimal',
            visible: hasButton1,
            ifHasData: null,
          },
          {
            label: 'Botão 2',
            tooltip: 'Quantidade de usuários que clicaram no botão 2 com sua url especifica',
            icon: 'mouse-pointer',
            field: 'clickPushBtn2Qty',
            type: 'decimal',
            visible: hasButton2,
            ifHasData: null,
          },
          {
            label: 'Reimpacto ignorado',
            tooltip: 'Valor total de reimpacto ignorado',
            icon: 'user-minus',
            field: 'reimpactIgnoredQty',
            type: 'decimal',
            visible: !isVast,
            ifHasData: null,
          },
        ],
      },
      mediaVast: {
        visible: isVast,
        title: 'Informações media VAST',
      },
      chartAccumulated: {
        visible: true,
        title: 'Acumulado',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      chartEvents: {
        visible: true,
        title: 'Eventos',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      dataTable: {
        visible: true,
        title: 'Dados',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'CTR', field: 'ctr' },
          { label: 'Impressões Efetivas', field: 'impressedQty' },
          { label: 'Interação', field: 'interactedQty' },
          { label: 'Conversão', field: 'convertedQty' },
          { label: 'Benefícios Concedidos', field: 'benefitQty' },
          { label: 'Megas Concedidos', field: 'benefitUnitQty' },
          { label: 'Dismiss', field: 'dismissQty' },
          { label: 'Click Botão 1', field: 'clickPushBtn1Qty' },
          { label: 'Click Botão 2', field: 'clickPushBtn2Qty' },
          ...(!isVast ? [] : [
            { label: 'Início', field: 'startQty' },
            { label: 'Primeiro quartil', field: 'firstQuartileQty' },
            { label: 'Segundo quartil', field: 'midpointQty' },
            { label: 'Terceiro quartil', field: 'thirdQuartileQty' },
            { label: 'Completo', field: 'completeQty' },
          ]),
        ],
      },
      question: {
        visible: true,
        title: 'Questões',
      },
    };
    return template;
  };

  const getTemplateMidiaNoCreditAD = () => {
    const isVast = creative.content
      && creative.content.mainData
      && creative.content.mainData.media
      && creative.content.mainData.media.type
      && creative.content.mainData.media.type === 'vast';
    const isInternal = creative.content
      && creative.content.mainData
      && creative.content.mainData.type
      && creative.content.mainData.type === 'internal';
    const hasBenefit = campaign.benefitOffers && campaign.benefitOffers.length > 0;
    const template = {
      reportTotal: {
        visible: true,
        title: 'Total Efetuado',
        data: [
          {
            label: 'Audiência total',
            tooltip: 'Quantidade de usuários que estão habilitados a receber campanhas',
            icon: 'database',
            field: 'audienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'OptIn Atualizado',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'user-plus',
            field: 'processedAudienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Impressões Contratadas',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'money-bill-wave',
            field: 'volume',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Campanhas Entregues',
            tooltip: 'Quantidade de mensagens enviadas com sucesso pela aplicação',
            icon: 'check',
            field: 'deliveredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Impressões Realizadas',
            tooltip: 'O criativo foi efetivamente exibido na tela do usuário',
            icon: 'user-friends',
            field: 'impressedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Interação',
            tooltip: 'Interação',
            icon: 'user-cog',
            field: 'interactedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Conversão',
            tooltip: 'Conversão',
            icon: 'chart-pie',
            field: 'convertedQty',
            type: 'decimal',
            visible: isInternal,
            ifHasData: null,
          },
          {
            label: 'Benefícios concedidos',
            tooltip: 'Benefícios concedidos',
            icon: 'signal',
            field: 'benefitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Megas concedidos',
            tooltip: 'Valor total de benefícios concedidos em megas.',
            icon: 'cloud-download-alt',
            field: 'benefitUnitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
        ],
      },
      mediaVast: {
        visible: isVast,
        title: 'Informações media VAST',
      },
      chartAccumulated: {
        visible: true,
        title: 'Acumulado',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      chartEvents: {
        visible: true,
        title: 'Eventos',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      dataTable: {
        visible: true,
        title: 'Dados',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'CTR', field: 'ctr' },
          { label: 'Impressões Efetivas', field: 'impressedQty' },
          { label: 'Interação', field: 'interactedQty' },
          { label: 'Conversão', field: 'convertedQty' },
          { label: 'Benefícios Concedidos', field: 'benefitQty' },
          { label: 'Megas Concedidos', field: 'benefitUnitQty' },
          ...(!isVast ? [] : [
            { label: 'Início', field: 'startQty' },
            { label: 'Primeiro quartil', field: 'firstQuartileQty' },
            { label: 'Segundo quartil', field: 'midpointQty' },
            { label: 'Terceiro quartil', field: 'thirdQuartileQty' },
            { label: 'Completo', field: 'completeQty' },
          ]),
        ],
      },
      question: {
        visible: isInternal,
        title: 'Questões',
      },
    };
    return template;
  };

  const getTemplateSponsorNotifyText = () => {
    const hasBenefit = campaign.benefitOffers && campaign.benefitOffers.length > 0;
    const template = {
      reportTotal: {
        visible: true,
        title: 'Total Efetuado',
        data: [
          {
            label: 'Audiência total',
            tooltip: 'Quantidade de usuários que estão habilitados a receber campanhas',
            icon: 'database',
            field: 'audienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'OptIn atualizado',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'user-plus',
            field: 'processedAudienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Impressões contratadas',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'money-bill-wave',
            field: 'volume',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Campanhas entregues',
            tooltip: 'Quantidade de mensagens enviadas com sucesso pela aplicação',
            icon: 'check',
            field: 'deliveredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Cliques',
            tooltip: 'Quantidade de usuários que clicaram no corpo da mensagem e foram redirecionados',
            icon: 'mouse-pointer',
            field: 'clickedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'CTR',
            tooltip: 'Número de cliques recebidos pelo seu anúncio dividido pelo número de vezes que ele foi exibido ( cliques / mensagens enviadas = CTR )',
            icon: '',
            field: 'ctr',
            type: 'percentage',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Impressões realizadas',
            tooltip: 'O criativo foi efetivamente exibido na tela do usuário',
            icon: 'user-friends',
            field: 'impressedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Benefícios concedidos',
            tooltip: 'Benefícios concedidos',
            icon: 'signal',
            field: 'benefitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Megas concedidos',
            tooltip: 'Valor total de benefícios concedidos em megas.',
            icon: 'cloud-download-alt',
            field: 'benefitUnitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
        ],
      },
      mediaVast: {
        visible: false,
        title: 'Informações media VAST',
      },
      chartAccumulated: {
        visible: true,
        title: 'Acumulado',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      chartEvents: {
        visible: true,
        title: 'Eventos',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      dataTable: {
        visible: true,
        title: 'Dados',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'CTR', field: 'ctr' },
          { label: 'Impressões Efetivas', field: 'impressedQty' },
          { label: 'Interação', field: 'interactedQty' },
          { label: 'Conversão', field: 'convertedQty' },
          { label: 'Benefícios Concedidos', field: 'benefitQty' },
          { label: 'Megas Concedidos', field: 'benefitUnitQty' },
        ],
      },
      question: {
        visible: false,
        title: 'Questões',
      },
    };
    return template;
  };

  const getTemplateSponsorNotifyMedia = () => {
    const isVast = creative.content
      && creative.content.mainData
      && creative.content.mainData.media
      && creative.content.mainData.media.type
      && creative.content.mainData.media.type === 'vast';
    const hasBenefit = campaign.benefitOffers && campaign.benefitOffers.length > 0;

    const template = {
      reportTotal: {
        visible: true,
        title: 'Total Efetuado',
        data: [
          {
            label: 'Audiência total',
            tooltip: 'Quantidade de usuários que estão habilitados a receber campanhas',
            icon: 'database',
            field: 'audienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'OptIn Atualizado',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'user-plus',
            field: 'processedAudienceQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Impressões Contratadas',
            tooltip: 'Quantidade atualizada de usuários que estão habilitados a receber campanhas',
            icon: 'money-bill-wave',
            field: 'volume',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Campanhas Entregues',
            tooltip: 'Quantidade de mensagens enviadas com sucesso pela aplicação',
            icon: 'check',
            field: 'deliveredQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Cliques',
            tooltip: 'Quantidade de usuários que clicaram no corpo da mensagem e foram redirecionados',
            icon: 'mouse-pointer',
            field: 'clickedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'CTR',
            tooltip: 'Número de cliques recebidos pelo seu anúncio dividido pelo número de vezes que ele foi exibido ( cliques / mensagens enviadas = CTR )',
            icon: '',
            field: 'ctr',
            type: 'percentage',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Impressões Realizadas',
            tooltip: 'O criativo foi efetivamente exibido na tela do usuário',
            icon: 'user-friends',
            field: 'impressedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Interação',
            tooltip: 'Interação',
            icon: 'user-cog',
            field: 'interactedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Conversão',
            tooltip: 'Conversão',
            icon: 'chart-pie',
            field: 'convertedQty',
            type: 'decimal',
            visible: true,
            ifHasData: null,
          },
          {
            label: 'Benefícios concedidos',
            tooltip: 'Benefícios concedidos',
            icon: 'signal',
            field: 'benefitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
          {
            label: 'Megas concedidos',
            tooltip: 'Valor total de benefícios concedidos em megas.',
            icon: 'cloud-download-alt',
            field: 'benefitUnitQty',
            type: 'decimal',
            visible: hasBenefit,
            ifHasData: null,
          },
        ],
      },
      mediaVast: {
        visible: isVast,
        title: 'Informações media VAST',
      },
      chartAccumulated: {
        visible: true,
        title: 'Acumulado',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      chartEvents: {
        visible: true,
        title: 'Eventos',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'Conversão', field: 'convertedQty' },
        ],
      },
      dataTable: {
        visible: true,
        title: 'Dados',
        data: [
          { label: 'Mensagens Enviadas', field: 'deliveredQty' },
          { label: 'Cliques', field: 'clickedQty' },
          { label: 'CTR', field: 'ctr' },
          { label: 'Impressões Efetivas', field: 'impressedQty' },
          { label: 'Interação', field: 'interactedQty' },
          { label: 'Conversão', field: 'convertedQty' },
          { label: 'Benefícios Concedidos', field: 'benefitQty' },
          { label: 'Megas Concedidos', field: 'benefitUnitQty' },
          ...(!isVast ? [] : [
            { label: 'Início', field: 'startQty' },
            { label: 'Primeiro quartil', field: 'firstQuartileQty' },
            { label: 'Segundo quartil', field: 'midpointQty' },
            { label: 'Terceiro quartil', field: 'thirdQuartileQty' },
            { label: 'Completo', field: 'completeQty' },
          ]),
        ],
      },
      question: {
        visible: true,
        title: 'Questões',
      },
    };
    return template;
  };

  switch (creative.creativeDefinition.name) {
    // SMS
    case CreativeName.SMS_TEXT_MESSAGE: return getTemplateSMSTextMessage();
    case CreativeName.SMS_MEDIA_MESSAGE: return getTemplateSMSMediaMessage();
    // Push
    case CreativeName.PUSH_NOTIFICATION_TEXT_MESSAGE: return getTemplatePushNotificationTextMessage();
    case CreativeName.PUSH_NOTIFICATION_IMAGE_MESSAGE: return getTemplatePushNotificationImageMessage();
    // No credit
    case CreativeName.MIDIA_NO_CREDIT_AD: return getTemplateMidiaNoCreditAD();
    // Sponsor
    case CreativeName.SPONSOR_NOTIFY_TEXT: return getTemplateSponsorNotifyText();
    case CreativeName.SPONSOR_NOTIFY_MEDIA: return getTemplateSponsorNotifyMedia();
    default: return getTemplateDefault();
  }
};

export default {
  getReportTemplateByCreative,
};
