/* eslint-disable max-len */
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  audienceRequest: ['filter'],
  audienceRequestSuccess: ['audiences'],
  audienceSetupRequest: null,
  audienceSetupRequestSuccess: ['setup'],
  audienceSetNew: ['audienceNew'],
  audienceSetUpdate: ['audienceUpdate'],
  audienceSaveData: null,
  audienceUpdateData: null,
  audienceGetData: ['uuid'],
  audienceDeleteData: ['uuid'],
  audienceCategoriesRequest: null,
  audienceCategoriesRequestSuccess: ['categories'],
  audienceCategoryDataRequest: ['subCategory'],
  audienceCategoryDataRequestSuccess: ['categoryData'],
  audienceCategorySetActiveFilter: ['itemFilter'],
  audienceCategoryDataCriteriaCount: ['criteria'],
  audienceCategoryDataCriteriaCountSuccess: ['totalEstimatedAudience'],
  audienceCategoryDataCriteriaCountByZone: ['zoneId', 'criteria'],
  audienceCategoryDataCriteriaCountByZoneSuccess: ['totalEstimatedAudienceByZone'],
  audienceCategoryDataCriteriaAnalyze: ['preferences', 'criteria'],
  audienceCategoryDataCriteriaAnalyzeSuccess: ['analyzeData'],
  audienceCategoryDataCriteriaAnalyzeLoading: ['analyzeLoading'],
  audienceCategoryDataCriteriaAnalyzeByZone: ['zoneId', 'preferences', 'criteria'],
  audienceCategoryDataCriteriaAnalyzeByZoneSuccess: ['analyzeByZoneData'],
  audienceCategoryDataCriteriaAnalyzeByZoneLoading: ['analyzeByZoneLoading'],
  audienceGetMapMarkers: ['criteria', 'event'],
  audienceGetMapMarkersSuccess: ['mapMarkers'],
  audienceGetMapMarkersByZone: ['zoneId', 'criteria', 'event'],
  audienceGetMapMarkersByZoneSuccess: ['mapMarkersByZone'],
  audienceZonesOnCreateRequest: null,
  audienceZonesOnUpdateRequest: ['audienceUuid'],
  audienceZonesRequestSuccess: ['zones'],
  audienceSetZoneSelected: ['zoneSelected'],
  // Clone audience
  audienceSetClone: ['audienceToClone'],
  audienceCloneRequest: null,
  audienceSetExternalData: ['audienceExternalData'],
  saveExternalAudienceRequest: null,
  uploadFileExternalAudience: ['audienceExternalUploadData'],
  problemUploadFileAudienceExternal: null,
  cleanResetAudienceExternalData: null,
  updateGeofenceData: ['geofenceData'],
  downloadOriginalFileAudienceExternal: null,
  downloadInvalidationFileAudienceExternal: null,
});

export const AudienceTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  audiences: [],
  contracts: [],
  audienceNew: null,
  audienceUpdate: null,
  categories: [],
  categoryData: null,
  categoryFilterActive: {
    include: [],
    exclude: [],
  },
  geofenceData: [],
  totalEstimatedAudience: '0',
  totalEstimatedAudienceByZone: '0',
  analyzeData: null,
  analyzeByZoneData: null,
  analyzeLoading: false,
  analyzeByZoneLoading: false,
  mapMarkers: null,
  mapMarkersByZone: null,
  zones: [],
  zoneSelected: null,
  audienceToClone: null,
  audienceExternalData: null,
  audienceExternalUploadData: null,
});

/* Reducers */
const audienceRequestSuccess = (state, { audiences }) => ({
  ...state,
  audiences,
});
const audienceSetupRequestSuccess = (state, { setup }) => ({
  ...state,
  contracts: setup.contracts,
});
const audienceSetNew = (state, { audienceNew }) => ({
  ...state,
  audienceNew,
});
const audienceSetUpdate = (state, { audienceUpdate }) => ({
  ...state,
  audienceUpdate,
});
const audienceCategoriesRequestSuccess = (state, { categories }) => ({
  ...state,
  categories,
});
const audienceCategoryDataRequestSuccess = (state, { categoryData }) => ({
  ...state,
  categoryData,
});
const audienceCategorySetActiveFilter = (state, { itemFilter }) => ({
  ...state,
  categoryFilterActive: {
    ...state.categoryFilterActive,
    ...itemFilter,
  },
});
const audienceCategoryDataCriteriaCountSuccess = (state, { totalEstimatedAudience }) => ({
  ...state,
  totalEstimatedAudience,
});
const audienceCategoryDataCriteriaCountByZoneSuccess = (state, { totalEstimatedAudienceByZone }) => ({
  ...state,
  totalEstimatedAudienceByZone,
});
const audienceCategoryDataCriteriaAnalyzeSuccess = (state, { analyzeData }) => ({
  ...state,
  analyzeData,
});
const audienceCategoryDataCriteriaAnalyzeLoading = (state, { analyzeLoading }) => ({
  ...state,
  analyzeLoading,
});
const audienceCategoryDataCriteriaAnalyzeByZoneSuccess = (state, { analyzeByZoneData }) => ({
  ...state,
  analyzeByZoneData,
});
const audienceCategoryDataCriteriaAnalyzeByZoneLoading = (state, { analyzeByZoneLoading }) => ({
  ...state,
  analyzeByZoneLoading,
});
const audienceGetMapMarkersSuccess = (state, { mapMarkers }) => ({
  ...state,
  mapMarkers,
});
const audienceGetMapMarkersByZoneSuccess = (state, { mapMarkersByZone }) => ({
  ...state,
  mapMarkersByZone,
});
const audienceZonesRequestSuccess = (state, { zones }) => ({
  ...state,
  zones,
});
const audienceSetZoneSelected = (state, { zoneSelected }) => ({
  ...state,
  zoneSelected,
});
const audienceSetClone = (state, { audienceToClone }) => ({
  ...state,
  audienceToClone,
});
const audienceSetExternalData = (state, { audienceExternalData }) => ({
  ...state,
  audienceExternalData,
});
const uploadFileExternalAudienceData = (state, { audienceExternalUploadData }) => ({
  ...state,
  audienceExternalData: null,
  audienceExternalUploadData,
});
const cleanResetAudienceExternalData = state => ({
  ...state,
  audienceExternalData: null,
  audienceExternalUploadData: null,
});
const updateGeofenceData = (state, { geofenceData }) => ({
  ...state,
  geofenceData,
});

export const reducer = createReducer(INITIAL_STATE, {
  [AudienceTypes.AUDIENCE_REQUEST_SUCCESS]: audienceRequestSuccess,
  [AudienceTypes.AUDIENCE_SETUP_REQUEST_SUCCESS]: audienceSetupRequestSuccess,
  [AudienceTypes.AUDIENCE_SET_NEW]: audienceSetNew,
  [AudienceTypes.AUDIENCE_SET_UPDATE]: audienceSetUpdate,
  [AudienceTypes.AUDIENCE_CATEGORIES_REQUEST_SUCCESS]: audienceCategoriesRequestSuccess,
  [AudienceTypes.AUDIENCE_CATEGORY_DATA_REQUEST_SUCCESS]: audienceCategoryDataRequestSuccess,
  [AudienceTypes.AUDIENCE_CATEGORY_SET_ACTIVE_FILTER]: audienceCategorySetActiveFilter,
  [AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_COUNT_SUCCESS]: audienceCategoryDataCriteriaCountSuccess,
  [AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_COUNT_BY_ZONE_SUCCESS]: audienceCategoryDataCriteriaCountByZoneSuccess,
  [AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_ANALYZE_SUCCESS]: audienceCategoryDataCriteriaAnalyzeSuccess,
  [AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_ANALYZE_LOADING]: audienceCategoryDataCriteriaAnalyzeLoading,
  [AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_ANALYZE_BY_ZONE_SUCCESS]: audienceCategoryDataCriteriaAnalyzeByZoneSuccess,
  [AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_ANALYZE_BY_ZONE_LOADING]: audienceCategoryDataCriteriaAnalyzeByZoneLoading,
  [AudienceTypes.AUDIENCE_GET_MAP_MARKERS_SUCCESS]: audienceGetMapMarkersSuccess,
  [AudienceTypes.AUDIENCE_GET_MAP_MARKERS_BY_ZONE_SUCCESS]: audienceGetMapMarkersByZoneSuccess,
  [AudienceTypes.AUDIENCE_ZONES_REQUEST_SUCCESS]: audienceZonesRequestSuccess,
  [AudienceTypes.AUDIENCE_SET_ZONE_SELECTED]: audienceSetZoneSelected,
  [AudienceTypes.AUDIENCE_SET_CLONE]: audienceSetClone,
  [AudienceTypes.AUDIENCE_SET_EXTERNAL_DATA]: audienceSetExternalData,
  [AudienceTypes.UPLOAD_FILE_EXTERNAL_AUDIENCE]: uploadFileExternalAudienceData,
  [AudienceTypes.CLEAN_RESET_AUDIENCE_EXTERNAL_DATA]: cleanResetAudienceExternalData,
  [AudienceTypes.UPDATE_GEOFENCE_DATA]: updateGeofenceData,
});
