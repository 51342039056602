import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  Input,
} from './styles';

class ModalEditBriefing extends Component {
  static propTypes = {
    modalEdit: PropTypes.shape().isRequired,
    campaignBriefingToEdit: PropTypes.shape().isRequired,
    campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
    campaignEditSetBriefingToEdit: PropTypes.func.isRequired,
    campaignEditSaveBriefingInEdit: PropTypes.func.isRequired,
    // campaign: PropTypes.shape().isRequired,
  };

  componentDidMount() {}

  render() {
    const {
      modalEdit,
      campaignEditChangeModalEditStatus,
      campaignBriefingToEdit,
      campaignEditSetBriefingToEdit,
      campaignEditSaveBriefingInEdit,
      // campaign,
    } = this.props;

    return modalEdit.editBriefingStatus && campaignBriefingToEdit && (
      <Modal width={500}>
        <>
          <Row>
            <h1>Alterar Template</h1>
            <ButtonCloseModal
              type="button"
              onClick={() => {
                campaignEditChangeModalEditStatus({
                  editBriefingStatus: false,
                });
                campaignEditSetBriefingToEdit(null);
              }}
            >
              <FaTimes size={30} />
            </ButtonCloseModal>
          </Row>
          <FormGroup>
            <label>
              <b>Template Id: </b>
              <Input
                maxLength={32}
                type="text"
                style={{ marginRight: 0 }}
                placeholder="Template Id"
                value={campaignBriefingToEdit.templateId || ''}
                onChange={(e) => {
                  campaignEditSetBriefingToEdit({
                    ...campaignBriefingToEdit,
                    templateId: e.target.value,
                  });
                }}
              />
            </label>
          </FormGroup>
          <RowFooter>
            <Button
              title="SALVAR"
              onClick={() => {
                campaignEditSaveBriefingInEdit();
              }}
            />
          </RowFooter>
        </>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  const { campaign } = state.campaign.edit;
  return {
    campaign,
    modalEdit: state.campaignEdit.modalEdit,
    campaignBriefingToEdit: state.campaignEdit.campaignBriefingToEdit,
  };
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditSetBriefingToEdit: CampaignEditActions.campaignEditSetBriefingToEdit,
    campaignEditSaveBriefingInEdit: CampaignEditActions.campaignEditSaveBriefingInEdit,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditBriefing);
