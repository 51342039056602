import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 92px;
  max-height: 92px;
  overflow: hidden;

  .header-rich {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;

    .header-rich-icon {
      display: flex;
      min-height: 70px;
      max-height: 70px;
      min-width: 70px;
      max-width: 70px;
      border-radius: 4px;
      border: 1px solid ${props => props.theme.colors.lighter};
      overflow: hidden;
      img {
        min-height: 70px;
        max-height: 70px;
        min-width: 70px;
        max-width: 70px;
        object-fit: contain;
      }
    }
    .header-rich-content {
      display: flex;
      flex-direction: column;
      padding-left: 5px;

      .header-rich-content-label1 {
        font-weight: bold;
        font-size: 15px;
        color: ${props => props.theme.colors.dark};
      }
      .header-rich-content-label2 {
        font-weight: normal;
        font-size: 14px;
        color: ${props => props.theme.colors.dark};
      }
    }
  }
  hr {
    width: 100%;
    border: 0;
    border-top: 1px solid ${props => props.theme.colors.lighter};
    margin: 0;
    padding: 0;
    margin-bottom: 10px;
  }
`;
