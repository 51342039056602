/* eslint-disable max-len */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import uuid4 from 'uuid4';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import PreviewImageBox from './components/PreviewImageBox';
import UploadImage from '~/Components/UploadImage';
import Button from '~/Components/Button';

import {
  Container,
  Card,
  Header,
  Body,
  ImagemItem,
  ImagemItemHeader,
  ImagemEdit,
  ImagemEditInfo,
  Separator,
  Input,
  ErrorMessage,
  BaseContentInput,
  SuccessInfoCard4,
} from './styles';
import FileUploadService from '~/services/FileUploadService';
import ImageFromService from '~/Components/ImageFromService';
import InputCreativeAdvanced from '../../Components/InputCreativeAdvanced';
import { formatBytes } from '~/Utils';


const RCSClaroForm = ({
  campaign,
  criativoEdit,
  campaignEditCriativoRequestSuccess,
  hasError,
  disabledFields,
  startRequest,
  requestSuccess,
  requestError,
  errorMessage,
}) => {
  const [statusError, setStatusError] = useState({
    messageErrorImageCampaign: '',
    messageErrorImageInfo1: '',
    messageErrorImageInfo2: '',
    messageErrorImageInfo3: '',
  });
  const { content, creativeDefinition: { schema } } = criativoEdit;
  const {
    cards: {
      card1, card2, card3, card4,
    },
  } = schema;

  async function uploadFile(file, field) {
    const { uuid: campaignUuid } = campaign;

    const referenceKey = `${campaignUuid}-${field}-${uuid4()}`;

    try {
      startRequest();
      const formData = new FormData();
      formData.append('file', file);
      const { uuid } = await FileUploadService.uploadFile(formData, referenceKey);
      requestSuccess();
      return uuid;
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a imagem. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  }

  function changeContent(data) {
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...data,
      },
    });
  }

  async function uploadImageCampaign(files) {
    const file = files[0];
    if (file) {
      const fileUuid = await uploadFile(file, 'imageCampaign');
      changeContent({
        ...content,
        imageCampaign: fileUuid,
      });
    }
  }

  async function uploadImageInfo1(files) {
    const file = files[0];
    if (file) {
      const fileUuid = await uploadFile(file, 'imageInfo1');
      changeContent({
        ...content,
        imageInfo1: fileUuid,
      });
    }
  }
  async function uploadImageInfo2(files) {
    const file = files[0];
    if (file) {
      const fileUuid = await uploadFile(file, 'imageInfo2');
      changeContent({
        ...content,
        imageInfo2: fileUuid,
      });
    }
  }
  async function uploadImageInfo3(files) {
    const file = files[0];
    if (file) {
      const fileUuid = await uploadFile(file, 'imageInfo3');
      changeContent({
        ...content,
        imageInfo3: fileUuid,
      });
    }
  }

  function renderCard1(card) {
    const { title, config: { imageCampaign: imageCampaignConfig, textCampaign: textCampaignConfig } } = card;
    return (
      <Card>
        <Header>
          {title}
        </Header>
        <Body>
          <ImagemItem>
            <ImagemItemHeader>
              <UploadImage
                disabled={disabledFields}
                label={content.imageCampaign ? 'Alterar imagem' : 'Buscar imagem'}
                onUpload={uploadImageCampaign}
                accept={imageCampaignConfig.accept}
                onClick={() => {
                  setStatusError({ ...statusError, messageErrorImageCampaign: '' });
                }}
                maxSize={imageCampaignConfig.maxSize}
                onRejectMaxSize={() => {
                  setStatusError({ ...statusError, messageErrorImageCampaign: `A imagem excedeu o limite de ${formatBytes(imageCampaignConfig.maxSize)}` });
                }}
                styleContainer={{
                  width: 200,
                }}
                style={{
                  width: 200,
                  margin: 0,
                }}
                resolution={imageCampaignConfig.resolution}
                onRejectResolution={() => {
                  setStatusError({ ...statusError, messageErrorImageCampaign: `Arquivo não atendeu as dimensões recomendadas de ${imageCampaignConfig.resolution.width}x${imageCampaignConfig.resolution.height}` });
                }}
              />
              {!!content.imageCampaign && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    changeContent({
                      ...content,
                      imageCampaign: null,
                    });
                  }}
                />
              )}
            </ImagemItemHeader>
            <ImagemEdit>
              <PreviewImageBox
                resolution={imageCampaignConfig.resolution}
                hasImage={!!content.imageCampaign}
              >
                {content.imageCampaign ? (
                  <ImageFromService
                    imageUuid={content.imageCampaign}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    styleImage={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <>
                    <span style={{ textAlign: 'center' }}>Preview da imagem</span>
                    <span className="resolution-info">
                      {`${imageCampaignConfig.resolution.width}x${imageCampaignConfig.resolution.height}`}
                    </span>
                  </>
                )}
              </PreviewImageBox>
            </ImagemEdit>
            {statusError.messageErrorImageCampaign && <ErrorMessage>{statusError.messageErrorImageCampaign}</ErrorMessage>}
            <ImagemEditInfo>
              <label>
                {textCampaignConfig.label}
              </label>
              <InputCreativeAdvanced
                disabled={disabledFields}
                maxLength={textCampaignConfig.maxLength}
                minLength={textCampaignConfig.minLength}
                type="text"
                style={{ width: '100%' }}
                contentVersion={2}
                placeholder={textCampaignConfig.placeholder}
                value={content.textCampaign}
                valueSuffix={textCampaignConfig.valueSuffix}
                valuePrefix={textCampaignConfig.valuePrefix}
                onChange={(e) => {
                  const textCampaign = e.target.value;
                  changeContent({
                    ...content,
                    textCampaign,
                  });
                }}
              />
            </ImagemEditInfo>
          </ImagemItem>
        </Body>
      </Card>
    );
  }

  function renderCard2(card) {
    const {
      title, config: {
        imageInfo1: imageInfo1Config, textInfo1: textInfo1Config,
        imageInfo2: imageInfo2Config, textInfo2: textInfo2Config,
        imageInfo3: imageInfo3Config, textInfo3: textInfo3Config,
      },
    } = card;

    return (
      <Card>
        <Header>
          {title}
        </Header>
        <Body>
          <ImagemItem>
            <ImagemItemHeader>
              <UploadImage
                disabled={disabledFields}
                label={content.imageInfo1 ? 'Alterar imagem' : 'Buscar imagem'}
                onUpload={uploadImageInfo1}
                onClick={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo1: '' });
                }}
                accept={imageInfo1Config.accept}
                maxSize={imageInfo1Config.maxSize}
                onRejectMaxSize={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo1: `A imagem excedeu o limite de ${formatBytes(imageInfo1Config.maxSize)}` });
                }}
                styleContainer={{
                  width: 200,
                }}
                style={{
                  width: 200,
                  margin: 0,
                }}
                resolution={imageInfo1Config.resolution}
                onRejectResolution={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo1: `Arquivo não atendeu as dimensões recomendadas de ${imageInfo1Config.resolution.width}x${imageInfo1Config.resolution.height}` });
                }}
              />
              {!!content.imageInfo1 && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    changeContent({
                      ...content,
                      imageInfo1: null,
                    });
                  }}
                />
              )}
            </ImagemItemHeader>
            <ImagemEdit>
              <PreviewImageBox
                resolution={imageInfo1Config.resolution}
                maxSizePx={300}
                hasImage={!!content.imageInfo1}
              >
                {content.imageInfo1 ? (
                  <ImageFromService
                    imageUuid={content.imageInfo1}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    styleImage={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <>
                    <span style={{ textAlign: 'center' }}>Preview da imagem</span>
                    <span className="resolution-info">
                      {`${imageInfo1Config.resolution.width}x${imageInfo1Config.resolution.height}`}
                    </span>
                  </>
                )}
              </PreviewImageBox>
            </ImagemEdit>
            {statusError.messageErrorImageInfo1 && <ErrorMessage>{statusError.messageErrorImageInfo1}</ErrorMessage>}
            <ImagemEditInfo>
              <label>
                {textInfo1Config.label}
              </label>
              <InputCreativeAdvanced
                disabled={disabledFields}
                maxLength={textInfo1Config.maxLength}
                minLength={textInfo1Config.minLength}
                type="text"
                contentVersion={2}
                placeholder={textInfo1Config.placeholder}
                value={content.textInfo1}
                valueSuffix={textInfo1Config.valueSuffix}
                valuePrefix={textInfo1Config.valuePrefix}
                onChange={(e) => {
                  const textInfo1 = e.target.value;
                  changeContent({
                    ...content,
                    textInfo1,
                  });
                }}
              />
            </ImagemEditInfo>
          </ImagemItem>
          <Separator />
          <ImagemItem>
            <ImagemItemHeader>
              <UploadImage
                disabled={disabledFields}
                label={content.imageInfo2 ? 'Alterar imagem' : 'Buscar imagem'}
                onUpload={uploadImageInfo2}
                onClick={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo2: '' });
                }}
                accept={imageInfo2Config.accept}
                maxSize={imageInfo2Config.maxSize}
                onRejectMaxSize={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo2: `A imagem excedeu o limite de ${formatBytes(imageInfo2Config.maxSize)}` });
                }}
                styleContainer={{
                  width: 200,
                }}
                style={{
                  width: 200,
                  margin: 0,
                }}
                resolution={imageInfo2Config.resolution}
                onRejectResolution={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo2: `Arquivo não atendeu as dimensões recomendadas de ${imageInfo2Config.resolution.width}x${imageInfo2Config.resolution.height}` });
                }}
              />
              {!!content.imageInfo2 && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    changeContent({
                      ...content,
                      imageInfo2: null,
                    });
                  }}
                />
              )}
            </ImagemItemHeader>
            <ImagemEdit>
              <PreviewImageBox
                resolution={imageInfo2Config.resolution}
                maxSizePx={300}
                hasImage={!!content.imageInfo2}
              >
                {content.imageInfo2 ? (
                  <ImageFromService
                    imageUuid={content.imageInfo2}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    styleImage={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <>
                    <span style={{ textAlign: 'center' }}>Preview da imagem</span>
                    <span className="resolution-info">
                      {`${imageInfo2Config.resolution.width}x${imageInfo2Config.resolution.height}`}
                    </span>
                  </>
                )}
              </PreviewImageBox>
            </ImagemEdit>
            {statusError.messageErrorImageInfo2 && <ErrorMessage>{statusError.messageErrorImageInfo2}</ErrorMessage>}
            <ImagemEditInfo>
              <label>
                {textInfo2Config.label}
              </label>
              <InputCreativeAdvanced
                disabled={disabledFields}
                maxLength={textInfo2Config.maxLength}
                minLength={textInfo2Config.minLength}
                type="text"
                contentVersion={2}
                placeholder={textInfo2Config.placeholder}
                value={content.textInfo2}
                valueSuffix={textInfo2Config.valueSuffix}
                valuePrefix={textInfo2Config.valuePrefix}
                onChange={(e) => {
                  const textInfo2 = e.target.value;
                  changeContent({
                    ...content,
                    textInfo2,
                  });
                }}
              />
            </ImagemEditInfo>
          </ImagemItem>
          <Separator />
          <ImagemItem>
            <ImagemItemHeader>
              <UploadImage
                disabled={disabledFields}
                label={content.imageInfo3 ? 'Alterar imagem' : 'Buscar imagem'}
                onUpload={uploadImageInfo3}
                onClick={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo3: '' });
                }}
                accept={imageInfo3Config.accept}
                maxSize={imageInfo3Config.maxSize}
                onRejectMaxSize={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo3: `A imagem excedeu o limite de ${formatBytes(imageInfo3Config.maxSize)}` });
                }}
                styleContainer={{
                  width: 200,
                }}
                style={{
                  width: 200,
                  margin: 0,
                }}
                resolution={imageInfo3Config.resolution}
                onRejectResolution={() => {
                  setStatusError({ ...statusError, messageErrorImageInfo3: `Arquivo não atendeu as dimensões recomendadas de ${imageInfo3Config.resolution.width}x${imageInfo3Config.resolution.height}` });
                }}
              />
              {!!content.imageInfo3 && !disabledFields && (
                <Button
                  disabled={disabledFields}
                  title="REMOVER"
                  onClick={() => {
                    changeContent({
                      ...content,
                      imageInfo3: null,
                    });
                  }}
                />
              )}
            </ImagemItemHeader>
            <ImagemEdit>
              <PreviewImageBox
                resolution={imageInfo3Config.resolution}
                maxSizePx={300}
                hasImage={!!content.imageInfo3}
              >
                {content.imageInfo3 ? (
                  <ImageFromService
                    imageUuid={content.imageInfo3}
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    styleImage={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'contain',
                    }}
                  />
                ) : (
                  <>
                    <span style={{ textAlign: 'center' }}>Preview da imagem</span>
                    <span className="resolution-info">
                      {`${imageInfo3Config.resolution.width}x${imageInfo3Config.resolution.height}`}
                    </span>
                  </>
                )}
              </PreviewImageBox>
            </ImagemEdit>
            {statusError.messageErrorImageInfo3 && <ErrorMessage>{statusError.messageErrorImageInfo3}</ErrorMessage>}
            <ImagemEditInfo>
              <label>
                {textInfo3Config.label}
              </label>
              <InputCreativeAdvanced
                disabled={disabledFields}
                maxLength={textInfo3Config.maxLength}
                minLength={textInfo3Config.minLength}
                type="text"
                contentVersion={2}
                placeholder={textInfo3Config.placeholder}
                value={content.textInfo3}
                valueSuffix={textInfo3Config.valueSuffix}
                valuePrefix={textInfo3Config.valuePrefix}
                onChange={(e) => {
                  const textInfo3 = e.target.value;
                  changeContent({
                    ...content,
                    textInfo3,
                  });
                }}
              />
            </ImagemEditInfo>
          </ImagemItem>
        </Body>
      </Card>
    );
  }

  function renderCard3(card) {
    const { title, config: { textDuploOptin: textDuploOptinConfig } } = card;
    return (
      <Card>
        <Header>
          {title}
        </Header>
        <Body>
          <BaseContentInput>
            <label>
              {textDuploOptinConfig.label}
            </label>
            <InputCreativeAdvanced
              disabled={disabledFields}
              maxLength={textDuploOptinConfig.maxLength}
              minLength={textDuploOptinConfig.minLength}
              type="text"
              style={{ width: '100%' }}
              contentVersion={2}
              placeholder={textDuploOptinConfig.placeholder}
              value={content.textDuploOptin}
              valueSuffix={textDuploOptinConfig.valueSuffix}
              valuePrefix={textDuploOptinConfig.valuePrefix}
              onChange={(e) => {
                const textDuploOptin = e.target.value;
                changeContent({
                  ...content,
                  textDuploOptin,
                });
              }}
            />
          </BaseContentInput>
        </Body>
      </Card>
    );
  }

  function renderCard4(card) {
    const { title, config: { successInfo } } = card;
    return (
      <Card>
        <Header>
          {title}
        </Header>
        <Body>
          <SuccessInfoCard4>{successInfo}</SuccessInfoCard4>
        </Body>
      </Card>
    );
  }

  return (
    <Container>
      {renderCard1(card1)}
      {renderCard2(card2)}
      {renderCard3(card3)}
      {renderCard4(card4)}
    </Container>
  );
};

RCSClaroForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,

  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(RCSClaroForm);
