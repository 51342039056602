import styled, { css } from 'styled-components';

import { transparentize } from 'polished';

import ModalStyled from '~/Components/Modal';

export const Modal = styled(ModalStyled)`
  transition: all 0.8s;
`;

export const ContentModal = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  transition: all 0.8s;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 60px;
  max-height: 60px;
  padding: 0 15px;
`;

export const Body = styled.div`
  width: 100%;
  min-height: ${props => props.height - 60}px;
  max-height: ${props => props.height - 60}px;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: all 0.8s;
`;

export const CloseHeader = styled.div`
  cursor: pointer;
  display: flex;
`;

export const ContainerItensType = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: ${props => props.height - 60}px;
  max-height: ${props => props.height - 60}px;
  transition: all 0.8s;

  padding: 0 0 0 10px;

  .base-itens {
    width: 100%;
    height: 100%;
    max-width: 100%;
    max-height: 450px;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;

    overflow: auto;
    padding-top: 60px;
  }

  ${props => props.itemsLength <= 2 && css`
    padding-right: 10px;

    .base-itens {
      padding-top: 0;
    }
  `}
`;

export const ItemType = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 150px;
  min-height: 150px;
  margin-bottom: 15px;
  padding: 15px;
  border: 2px solid ${props => props.theme.colors.regular};
  border-radius: 4px;

  cursor: pointer;

  > * {
    pointer-events: none;
  }

  h1 {
    text-align: center;
    color: ${props => props.theme.colors.dark};
  }

  img {
    height: 100px;
  }

  &:hover {
    background-color: ${props => transparentize(0.3, props.theme.colors.primary)};
    border-color: ${props => props.theme.colors.primary};
  }
`;

export const ItemSelected = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  padding: 0 25px 25px 25px;
  position: relative;
  width: 100%;
  min-height: ${props => props.height - 60}px;
  max-height: ${props => props.height - 60}px;
  transition: all 0.8s;
`;

export const Left = styled.div`
  min-width: 300px;
  min-height: 150px;
  position: absolute;
  overflow: hidden;
  transition: all 0.8s;

  background-color: ${props => transparentize(0.3, props.theme.colors.primary)};
  border: 4px solid ${props => props.theme.colors.primary};

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding: 10px;

  .body {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    h1 {
      text-align: center;
      margin-bottom: 20px;
      color: ${props => props.theme.colors.dark};
    }

    img {
      height: 200px;
    }
  }
`;

export const CloseItemSelected = styled.div`
  cursor: pointer;
`;

export const Right = styled.div`
  transition: all 0.8s;
  flex: 1;
  min-height: 500px;
  max-height: 500px;
  min-width: 615px;
  max-width: 615px;
  border: 3px solid #ccc;
  border-left: 0;
`;
