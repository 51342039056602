import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const MAX_SIZE_PX = 900;

function PreviewImageBox({
  resolution, hasImage, invalid, children,
}) {
  let resolutionContainer = null;
  if (resolution) {
    const { width, height } = resolution;
    resolutionContainer = {
      width, height,
    };
    while (resolutionContainer.width > MAX_SIZE_PX || resolutionContainer.height > MAX_SIZE_PX) {
      resolutionContainer.width -= ((resolutionContainer.width / 100) * 10);
      resolutionContainer.height -= ((resolutionContainer.height / 100) * 10);
    }
  }

  return (
    <Container
      resolution={resolutionContainer}
      hasImage={hasImage}
      invalid={invalid}
    >
      {children}
    </Container>
  );
}

PreviewImageBox.defaultProps = {
  resolution: null,
  hasImage: false,
  invalid: false,
};

PreviewImageBox.propTypes = {
  resolution: PropTypes.shape(),
  hasImage: PropTypes.bool,
  invalid: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default PreviewImageBox;
