import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  changeModalEditAdvertisingPartner: ['showModalEditAdvertisingPartner'],
  changeAdvertisingPartnerEdit: ['advertisingPartnerEdit'],
  loadAdvertisingPartnerList: null,
  loadAdvertisingPartnerListSuccess: ['advertisingPartnerList'],
  filterAdvertisingPartnerList: ['advertisingPartnerId'],
  loadFilterListSuccess: ['advertisingPartnerFilterList'],
  advertisingPartnerUpdate: null,
});

export const AdvertisingPartnerTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  showModalEditAdvertisingPartner: false,
  advertisingPartnerEdit: {
    id: null,
    code: '',
    name: '',
    segmentationKey: '',
  },
  advertisingPartnerList: [],
  advertisingPartnerFilterList: [],
});

/* Reducers */
const loadAdvertisingPartnerListSuccess = (state, { advertisingPartnerList }) => ({
  ...state,
  advertisingPartnerList,
});
const loadFilterListSuccess = (state, { advertisingPartnerFilterList }) => ({
  ...state,
  advertisingPartnerFilterList,
});
const changeModalEditAdvertisingPartner = (state, { showModalEditAdvertisingPartner }) => ({
  ...state,
  showModalEditAdvertisingPartner,
});
const changeAdvertisingPartnerEdit = (state, { advertisingPartnerEdit }) => ({
  ...state,
  advertisingPartnerEdit: {
    ...state.advertisingPartnerEdit,
    ...advertisingPartnerEdit,
  },
});

export const reducer = createReducer(INITIAL_STATE, {
  [AdvertisingPartnerTypes.LOAD_ADVERTISING_PARTNER_LIST_SUCCESS]: loadAdvertisingPartnerListSuccess,
  [AdvertisingPartnerTypes.LOAD_FILTER_LIST_SUCCESS]: loadFilterListSuccess,
  [AdvertisingPartnerTypes.CHANGE_MODAL_EDIT_ADVERTISING_PARTNER]: changeModalEditAdvertisingPartner,
  [AdvertisingPartnerTypes.CHANGE_ADVERTISING_PARTNER_EDIT]: changeAdvertisingPartnerEdit,
});
