import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ChannelUtil from '~/Utils/ChannelUtil';

import { withTheme } from 'styled-components';


import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Button from '~/Components/Button';
import Pagination from '~/Components/Pagination';

import { Creators as CockpitAnalyzeActions } from '~/redux/ducks/cockpitAnalyze';

import {
  Container, Row, Select, Input, ButtonGroup, LinkButton, Table, LinkStyled,
} from './styles';
import ModalResultsWeekly from './components/ModalResultsWeekly';

function CockpitAcompanhamento({
  initStateView, dataFilters, filter,
  updateFilter, cleanFilter, goSearch,
  paginate, changePage, data,
  showModalResultsWeekly,
}) {
  const [modeInitView, setModeInitView] = useState(true);
  const [types, setTypes] = useState([]);
  const [channels, setChannels] = useState([]);
  const [status, setStatus] = useState([]);
  const [contracts, setContracts] = useState([]);
  const [products, setProducts] = useState([]);

  const uuidTenant = localStorage.getItem('uuidTenant');

  function handleDisplayKeyword() {
    return uuidTenant === '46a8b5fc-6636-40c8-821f-15b34b739519'
      || uuidTenant === 'd2b07c25-09b0-43ad-81d7-cb0588c566c8'
      || uuidTenant === '263cbc92-3c29-42e5-b59f-f7141eefb56d';
  }

  function handleReportWeekly() {
    return uuidTenant === '46a8b5fc-6636-40c8-821f-15b34b739519'
      || uuidTenant === 'd2b07c25-09b0-43ad-81d7-cb0588c566c8'
      || uuidTenant === '263cbc92-3c29-42e5-b59f-f7141eefb56d';
  }

  function handleDisplayProductOffer() {
    return uuidTenant !== '27e9610a-6d78-4ddd-af88-003eb8cb9b40'
    && uuidTenant !== '2563fe93-87c1-494e-983e-509bb03e4ade';
  }

  function renderRowCampaign(campaign) {
    // const channel = channels.find(item => item.value === campaign.zone.channelUuid);
    return (
      <tr key={campaign.uuid}>
        <td className="link">
          <LinkStyled to={`campaigns/edit/${campaign.uuid}`}>
            {campaign.name}
          </LinkStyled>
        </td>
        {handleDisplayProductOffer() && (
          <td>
            {campaign.product ? campaign.product.name : ''}
          </td>
        )}
        {handleDisplayKeyword() && (
          <td>
            {campaign.keyword}
          </td>
        )}
        <td>
          <span>
            {campaign.channel ? campaign.channel.name : ''}
          </span>
        </td>
        <td>
          <span>{moment(campaign.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
        </td>
        <td>
          <span>{campaign.status}</span>
        </td>
        <td style={{ textAlign: 'center', paddingRight: 15 }}>
          <div className="base-buttons-actions">
            {handleReportWeekly() && (
              <>
                <Button
                  icon="FaChartBar"
                  noLabel
                  onClick={() => {
                    showModalResultsWeekly(campaign.uuid);
                  }}
                  data-tip
                  data-for={`btn-result-weekly-${campaign.uuid}`}
                />
                <ReactTooltip
                  className="tooltip-report"
                  effect="solid"
                  id={`btn-result-weekly-${campaign.uuid}`}
                  aria-haspopup="true"
                >
                  Relatório Semanal
                </ReactTooltip>
              </>
            )}

            {!handleReportWeekly() && (
              <>
                <Button
                  icon="FaChartPie"
                  noLabel
                  onClick={() => {
                    const { origin } = window.location;
                    const urlRedirect = `${origin}/#/campaigns/edit/${campaign.uuid}/report`;
                    window.open(urlRedirect, '_blank');
                  }}
                  data-tip
                  data-for={`btn-result-${campaign.uuid}`}
                />
                <ReactTooltip
                  className="tooltip-report"
                  effect="solid"
                  id={`btn-result-${campaign.uuid}`}
                  aria-haspopup="true"
                >
                  Relatório Geral
                </ReactTooltip>
              </>
            )}
          </div>
        </td>
      </tr>
    );
  }

  function renderCampaigns() {
    return (
      <Table>
        <thead>
          <tr>
            <th>NOME</th>
            {handleDisplayProductOffer() && (
              <th style={{ width: 200 }}>PRODUTO - OFERTA</th>
            )}
            {handleDisplayKeyword() && (
              <th style={{ width: 180 }}>KEYWORD</th>
            )}
            <th style={{ width: 200 }}>CANAL</th>
            <th style={{ width: 200 }}>ÚLTIMA ALTERAÇÃO</th>
            <th style={{ width: 180 }}>STATUS</th>
            <th style={{ width: 110, textAlign: 'center' }}>RESULTADO</th>
          </tr>
        </thead>
        <tbody>
          {data.length < 1 && (
            <tr>
              <td colSpan="8">
                <div className="empty-table-desc">
                  {modeInitView ? (
                    <span>Preencha algum filtro para realizar a busca de registros de campanhas</span>
                  ) : (
                    <span>Não foram localizados registros de campanhas</span>
                  )}
                </div>
              </td>
            </tr>
          )}
          {data.length > 0
            && data.map(({ campaign }) => renderRowCampaign(campaign))}
        </tbody>
      </Table>
    );
  }

  useEffect(() => {
    function makeDatasFilters() {
      setTypes(dataFilters.campaignTypes.map(type => ({
        value: type.uuid,
        label: type.label,
      })));
      setChannels(ChannelUtil.mapChannelsForSelect(dataFilters.channels));
      setStatus(dataFilters.campaignStatus.map(type => ({
        value: type.uuid,
        label: type.label,
      })));
      setContracts(dataFilters.contracts.map(type => ({
        value: type.uuid,
        label: type.name,
      })));
      setProducts(dataFilters.products.map(type => ({
        value: type.uuid,
        label: type.name,
      })));
    }
    makeDatasFilters();
  }, [dataFilters]);

  useEffect(() => {
    if (modeInitView && data && data.length > 0) {
      setModeInitView(false);
    }
  }, [data]);

  useEffect(() => {
    initStateView();
  }, []);

  return (
    <Container>
      <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1>COCKPIT DE ACOMPANHAMENTO</h1>
      </Row>
      <Row>
        <div className="box-search">
          <Input
            placeholder="Nome"
            value={filter.campaignName}
            onChange={(e) => {
              updateFilter(
                {
                  ...filter,
                  campaignName: e.target.value,
                },
              );
            }}
          />
          {handleDisplayKeyword() && (
            <Input
              placeholder="Keyword"
              value={filter.campaignKeyword}
              onChange={(e) => {
                updateFilter(
                  {
                    ...filter,
                    campaignKeyword: e.target.value,
                  },
                );
              }}
            />
          )}
          <Select
            placeholder="Selecione tipo..."
            value={types.find(t => t.value === filter.campaignTypeUuid)}
            onChange={(item) => {
              updateFilter(
                {
                  ...filter,
                  campaignTypeUuid: item ? item.value : null,
                },
              );
            }}
            options={types}
            isClearable
          />
          <Select
            placeholder="Selecione canal..."
            value={channels.find(t => t.value === filter.channelUuid)}
            onChange={(item) => {
              updateFilter(
                {
                  ...filter,
                  channelUuid: item ? item.value : null,
                },
              );
            }}
            options={channels}
            isClearable
          />
          <Select
            placeholder="Selecione status..."
            value={status.find(t => t.value === filter.campaignStatusUuid)}
            onChange={(item) => {
              updateFilter(
                {
                  ...filter,
                  campaignStatusUuid: item ? item.value : null,
                },
              );
            }}
            options={status}
            isClearable
          />
          <Select
            placeholder="Selecione contrato..."
            value={contracts.find(t => t.value === filter.contractUuid)}
            onChange={(item) => {
              updateFilter(
                {
                  ...filter,
                  contractUuid: item ? item.value : null,
                },
              );
            }}
            options={contracts}
            isClearable
          />
          <Select
            placeholder="Selecione produto..."
            value={products.find(t => t.value === filter.productUuid)}
            onChange={(item) => {
              updateFilter(
                {
                  ...filter,
                  productUuid: item ? item.value : null,
                },
              );
            }}
            options={products}
            isClearable
          />
          <ButtonGroup>
            <LinkButton
              onClick={() => {
                goSearch();
              }}
            >
              Buscar
            </LinkButton>
            <Button
              icon="FaTimes"
              noLabel
              onClick={() => {
                cleanFilter();
              }}
            />
          </ButtonGroup>
        </div>
      </Row>
      {renderCampaigns()}
      <Row>
        <Pagination
          page={paginate.page}
          pageCount={paginate.pageCount}
          pageChange={(e) => {
            changePage(parseInt(e.selected));
          }}
        />
      </Row>
      <ModalResultsWeekly />
    </Container>
  );
}

CockpitAcompanhamento.propTypes = {
  initStateView: PropTypes.func.isRequired,
  dataFilters: PropTypes.shape().isRequired,
  filter: PropTypes.shape().isRequired,
  paginate: PropTypes.shape().isRequired,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  updateFilter: PropTypes.func.isRequired,
  cleanFilter: PropTypes.func.isRequired,
  goSearch: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
  showModalResultsWeekly: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  cockpitAnalyze: {
    dataFilters,
    filter,
    paginate,
    data,
  },
}) => ({
  dataFilters,
  filter,
  paginate,
  data,
});

const mapDispatchToProps = dispatch => bindActionCreators({
  initStateView: CockpitAnalyzeActions.initStateView,
  updateFilter: CockpitAnalyzeActions.updateFilter,
  cleanFilter: CockpitAnalyzeActions.cleanFilter,
  changePage: CockpitAnalyzeActions.changePage,
  goSearch: CockpitAnalyzeActions.cockpitAnalyzeRequest,
  showModalResultsWeekly: CockpitAnalyzeActions.showModalResultsWeekly,
}, dispatch);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(CockpitAcompanhamento));
