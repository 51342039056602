import { call, put } from 'redux-saga/effects';

import HistoryCampaignService from '~/services/HistoryCampaignService';

import { Creators as HistoryCampaignActions } from '~/redux/ducks/historyCampaign';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadHistoryCampaign({ payload: campaignId }) {
  yield put(startRequest());
  try {
    const history = yield call(HistoryCampaignService.historyByCampaignId, campaignId);
    yield put(HistoryCampaignActions.showSuccess(history));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar o Histório de Status da campanha. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}
