import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${props => props.width}px;
  height: ${props => props.height}px;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  overflow: hidden;
  img {
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    object-fit: ${props => props.fill};
  }
`;
