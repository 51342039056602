/* eslint-disable react/jsx-one-expression-per-line */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Formik } from 'formik';
import * as Yup from 'yup';

import _ from 'lodash';

import { Container, Row, RowEditor } from './styles';

import { Box, BoxTitle, BoxContentItem } from '~/Components/Box/styles';
import Button from '~/Components/Button';
import DinamicStaticEditInput from '~/Components/DinamicStaticEditInput';

class Form extends Component {
  static propTypes = {
    provider: PropTypes.shape().isRequired,
    saveProviderEdit: PropTypes.func.isRequired,
  };

  render() {
    const { provider, saveProviderEdit } = this.props;

    const validationSchema = Yup.object().shape({
      name: Yup.string().max(150).required(),
      htmlContent: Yup.string().required(),
      script: Yup.string(),
    });

    return (
      <Formik
        ref={this.form}
        initialValues={{ ...provider }}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          saveProviderEdit({ ...values });
        }}
        render={({
          errors,
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
        }) => (
          <form style={{ width: '100%' }} onSubmit={handleSubmit}>
            <Container>
              <div className="provider">
                <Row>
                  <DinamicStaticEditInput
                    fontSize={25}
                    maxLength="150"
                    name="name"
                    onChange={handleChange}
                    value={values.name}
                    onBlur={handleBlur}
                    invalid={errors.name}
                  />
                </Row>
                <div className="text-wrapper">
                  <div className="text-box" style={{ marginRight: 20 }}>
                    <h2>HTML</h2>
                    <RowEditor invalid={errors.htmlContent}>
                      <textarea
                        name="htmlContent"
                        value={values.htmlContent}
                        onChange={e => setFieldValue('htmlContent', e.target.value)}
                      />
                    </RowEditor>
                  </div>
                  <div className="text-box">
                    <h2>SCRIPT</h2>
                    <RowEditor invalid={errors.script}>
                      <textarea
                        name="script"
                        value={values.script}
                        onChange={e => setFieldValue('script', e.target.value)}
                      />
                    </RowEditor>
                  </div>
                </div>
                <Box>
                  <BoxTitle>
                    <span>Tag Especial</span>
                  </BoxTitle>
                  <BoxContentItem>
                    <p>
                      [ATR_UUID]: Caso queira injetar o identificador da campanha,
                       coloque esse código no <b>HTML</b> ou <b>SCRIPT</b>
                    </p>
                    <p>{'Ex: <h1>[ATR_UUID]</h1> irá resultar em <h1>b01c7f5a-5a74-4403-8d78-539fc6d4f896</h1>'}</p>
                  </BoxContentItem>
                </Box>
                <Row style={{ justifyContent: 'flex-end', marginBottom: 0 }}>
                  <Button
                    bsStyle="primary"
                    title="Salvar Alterações"
                    disabled={_.values(errors).length}
                    type="submit"
                  />
                </Row>
              </div>
            </Container>
          </form>
        )}
      />
    );
  }
}

export default Form;
