import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { withTheme } from 'styled-components';
import { getFormattedValueByType } from '~/Utils';

const LineChart = ({
  values, labels, theme, mode, height,
}) => (
  <div className="zoom-chart">


    <Chart
      height={height || '300px'}
      width="100%"
      options={{
        fontFamily: 'Simplon BP',
        chart: {

          type: mode || 'line',

          toolbar: {
            show: false,
          },
          zoom: { enabled: false },
        },

        dataLabels: {
          enabled: false,
        },

        fill: {
          colors: theme.colors.chart,
          opacity: 1,
          type: 'solid',
        },
        stroke: {
          width: [3, 3, 3],
          curve: 'straight',
        },
        colors: theme.colors.chart,

        grid: {

          xaxis: {
            lines: {
              show: false,
            },

          },
          yaxis: {
            lines: {
              show: false,
            },
          },


        },

        legend: {
          show: false,
        },
        labels: labels || [],
        yaxis: {
          show: false,
        },
        tooltip: {
          style: {
            fontSize: '10pt',
            fontFamily: 'Simplon BP',
          },
          x: {
            show: false,
          },
          y: {
            formatter: value => getFormattedValueByType('decimal', value),
          },
        },


      }


                }
      series={values || []}
      type={mode || 'line'}
    />
  </div>
);

export default withTheme(LineChart);
