import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  white-space: nowrap;
  button {
    border: 0;
    background-color: transparent;
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    font-size: 18px;
    &:hover {
      color: ${props => props.theme.colors.primaryHex}80;
    }
  }
  svg {
    color: ${props => props.theme.colors.primary};
    font-size: 15px;
    margin: 0 5px;
  }
  span {
    color: ${props => props.theme.colors.dark};
    font-size: 18px;
  }
`;
