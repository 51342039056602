import React, { useState } from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import Upload from '~/Components/Upload';
import Image from '~/Components/Image';
import Button from '~/Components/Button';

import UploadService, { baseUrlMedia } from '~/services/UploadService';

import {
  FormGroup,
  Input,
  Row,
  Imagem,
  BannerEmpty,
  ErrorMessage,
} from './styles';

const SMSMediaMessageForm = ({
  campaign,
  criativoEdit,
  campaignEditCriativoRequestSuccess,
  hasError,
  startRequest,
  requestSuccess,
  requestError,
  errorMessage,
  disabledFields,
}) => {
  const [showMessageErrorMaxSizeFileIcon, setShowMessageErrorMaxSizeFileIcon] = useState(false);
  const [showMessageErrorMaxSizeFileImage, setShowMessageErrorMaxSizeFileImage] = useState(false);

  const { content } = criativoEdit;

  const getTitleIsInvalid = () => {
    if (content.channelData) {
      if (content.channelData.title) {
        return false;
      }
    }
    return true;
  };

  const getSubtitleIsInvalid = () => {
    if (content.channelData) {
      if (content.channelData.subtitle) {
        return false;
      }
    }
    return true;
  };

  const getUrlIsInvalid = () => {
    if (content.channelData) {
      if (
        content.channelData.url
        && (
          content.channelData.url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)
          || content.channelData.url.match(/^[a-z]+[a-z]:\/\/(.*)$/)
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const uploadFileToAWS = async (campaignUuid, file) => {
    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  const handleSuccessIcon = (icon) => {
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        channelData: {
          ...content.channelData,
          icon,
        },
      },
    });
  };

  const handleSuccessImage = (image) => {
    let subtitleValidate = content.channelData ? content.channelData.subtitle : null;
    if (image && subtitleValidate) {
      if (subtitleValidate.length > 37) {
        subtitleValidate = subtitleValidate.substr(0, 37);
      }
    }
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        channelData: {
          ...content.channelData,
          subtitle: subtitleValidate,
          image,
        },
      },
    });
  };

  const uploadFileIcon = async (files) => {
    const file = files[0];
    if (file) {
      const { url } = await uploadFileToAWS(campaign.uuid, file);
      handleSuccessIcon(url);
    }
  };

  const uploadFileImage = async (files) => {
    const file = files[0];
    if (file) {
      const { url } = await uploadFileToAWS(campaign.uuid, file);
      handleSuccessImage(url);
    }
  };

  const maxLengthSubtitle = content && content.channelData && content.channelData.image ? 37 : 230;

  return (
    <>
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Título
            <span>*</span>
            -
            <small>
              {` ${
                content.channelData && content.channelData.title
                  ? content.channelData.title.length : 0}/37 caracteres`}
            </small>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite o título"
            maxLength={37}
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    title: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.title || '' : ''}
            invalid={hasError && getTitleIsInvalid()}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Subtítulo
            <span>*</span>
            -
            <small>
              {` ${
                content.channelData && content.channelData.subtitle
                  ? content.channelData.subtitle.length : 0}/${maxLengthSubtitle} caracteres`}
            </small>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Subtítulo"
            maxLength={maxLengthSubtitle}
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    subtitle: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.subtitle || '' : ''}
            invalid={hasError && getSubtitleIsInvalid()}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <label>
            Ícone
          </label>
          <Row>
            <Imagem invalid={false /* hasError && getIconIsInvalid() */}>
              {content.channelData && content.channelData.icon ? (
                <Image
                  className="success-img"
                  src={content.channelData.icon}
                  alt="imagem"
                />
              ) : (
                <BannerEmpty>Sem ícone de visualização</BannerEmpty>
              )}
            </Imagem>
            <FormGroup style={{ marginLeft: '20px' }}>
              <label>Formatos: PNG, JPG</label>
              <label>Dimensão recomendada: 192x192.</label>
              {showMessageErrorMaxSizeFileIcon && (
                <ErrorMessage>
                  Arquivo excedeu o limite de 2MB.
                </ErrorMessage>
              )}
              <div style={{ display: 'flex' }}>
                <Upload
                  disabled={disabledFields}
                  label={content.channelData && content.channelData.icon ? 'ALTERAR' : 'ADICIONAR'}
                  onUpload={uploadFileIcon}
                  accept="image/png,image/jpg,image/jpeg"
                  maxSize={2000000}
                  onRejectMaxSize={() => {
                    setShowMessageErrorMaxSizeFileIcon(true);
                  }}
                  onClick={() => {
                    setShowMessageErrorMaxSizeFileIcon(false);
                  }}
                />
                {content.channelData && content.channelData.icon && (
                  <Button
                    disabled={disabledFields}
                    style={{ marginTop: '15px' }}
                    title="REMOVER"
                    onClick={() => {
                      handleSuccessIcon('');
                    }}
                  />
                )}
              </div>
            </FormGroup>
          </Row>
        </FormGroup>
      </Row>
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Url ou Deep link
            <span>*</span>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite a url"
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    url: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.url || '' : ''}
            invalid={hasError && getUrlIsInvalid()}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <label>
            Imagem
          </label>
          <Row>
            <Imagem invalid={false /* hasError && getImageIsInvalid() */}>
              {content.channelData && content.channelData.image ? (
                <Image
                  className="success-img"
                  src={content.channelData.image}
                  alt="imagem"
                />
              ) : (
                <BannerEmpty>Sem imagem de visualização</BannerEmpty>
              )}
            </Imagem>
            <FormGroup style={{ marginLeft: '20px' }}>
              <label>Formatos: PNG, JPG</label>
              <label>Dimensão recomendada: 1024x512.</label>
              {showMessageErrorMaxSizeFileImage && (
                <ErrorMessage>
                  Arquivo excedeu o limite de 2MB.
                </ErrorMessage>
              )}
              <div style={{ display: 'flex' }}>
                <Upload
                  disabled={disabledFields}
                  label={content.channelData && content.channelData.image ? 'ALTERAR' : 'ADICIONAR'}
                  onUpload={uploadFileImage}
                  accept="image/png,image/jpg,image/jpeg"
                  maxSize={2000000}
                  onRejectMaxSize={() => {
                    setShowMessageErrorMaxSizeFileImage(true);
                  }}
                  onClick={() => {
                    setShowMessageErrorMaxSizeFileImage(false);
                  }}
                />
                {content.channelData && content.channelData.image && (
                  <Button
                    disabled={disabledFields}
                    style={{ marginTop: '15px' }}
                    title="REMOVER"
                    onClick={() => {
                      handleSuccessImage('');
                    }}
                  />
                )}
              </div>
            </FormGroup>
          </Row>
        </FormGroup>
      </Row>
      <Row>
        <h3>Botões</h3>
      </Row>
      <Row>
        <FormGroup style={{ flex: 1, marginRight: 10 }}>
          <label>
            Texto
            -
            <small>
              {` ${
                content.channelData && content.channelData.button_text_1
                  ? content.channelData.button_text_1.length : 0}/50 caracteres`}
            </small>
          </label>
          <Input
            disabled={disabledFields}
            maxLength={50}
            placeholder="Digite o texto do botão"
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    button_text_1: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.button_text_1 || '' : ''}
            invalid={false /* hasError && getButtonText1IsInvalid() */}
          />
        </FormGroup>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Link
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite o endereço do site"
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    button_url_1: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.button_url_1 || '' : ''}
            invalid={false /* hasError && getButtonUrl1IsInvalid() */}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup style={{ flex: 1, marginRight: 10 }}>
          <label>
            Texto
            -
            <small>
              {` ${
                content.channelData && content.channelData.button_text_2
                  ? content.channelData.button_text_2.length : 0}/50 caracteres`}
            </small>
          </label>
          <Input
            disabled={disabledFields}
            maxLength={50}
            placeholder="Digite o texto do botão"
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    button_text_2: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.button_text_2 || '' : ''}
            invalid={false /* hasError && getButtonText2IsInvalid() */}
          />
        </FormGroup>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Link
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite o endereço do site"
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    button_url_2: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.button_url_2 || '' : ''}
            invalid={false /* hasError && getButtonUrl2IsInvalid() */}
          />
        </FormGroup>
      </Row>
    </>
  );
};

SMSMediaMessageForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(SMSMediaMessageForm);
