import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import moment from 'moment';
import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  InputMask,
  ClearButton,
} from './styles';

class ModalEditPeriodo extends Component {
  static propTypes = {
    modalEdit: PropTypes.shape().isRequired,
    campaignPeriodoToEdit: PropTypes.shape().isRequired,
    campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
    campaignEditSetPeriodoToEdit: PropTypes.func.isRequired,
    campaignEditSavePeriodoInEdit: PropTypes.func.isRequired,
  };

  render() {
    const {
      modalEdit,
      campaignEditChangeModalEditStatus,
      campaignEditSetPeriodoToEdit,
      campaignEditSavePeriodoInEdit,
      campaignPeriodoToEdit,
    } = this.props;

    if (!modalEdit.editPeriodoStatus || !campaignPeriodoToEdit) {
      return null;
    }

    return modalEdit.editPeriodoStatus && campaignPeriodoToEdit && (
      <Modal width={500} scroll={false}>
        <Row>
          <h1>Alterar Período</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              campaignEditChangeModalEditStatus({
                editPeriodoStatus: false,
              });
              campaignEditSetPeriodoToEdit(null);
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <FormGroup>
          <label>
            <b>Inicial:</b>
            <DayPickerInput
              ref={(ref) => {
                if (ref && !campaignPeriodoToEdit.startDate) {
                  ref.input.value = campaignPeriodoToEdit.startDate;
                }
              }}
              inputProps={{ disabled: modalEdit.modeDisabledStartDate }}
              formatDate={formatDate}
              parseDate={parseDate}
              format="DD/MM/YYYY"
              dayPickerProps={{
                localeUtils: MomentLocaleUtils,
                locale: 'pt-br',
              }}
              placeholder="__/__/____"
              value={campaignPeriodoToEdit.startDate ? moment(
                campaignPeriodoToEdit.startDate,
                'DD/MM/YYYY',
              ).toDate() : null
              }
              onDayChange={(day) => {
                if (day) {
                  const initialData = moment(day);
                  const startHour = moment(initialData.format('YYYY-MM-DD'))
                    .isSameOrBefore(moment().format('YYYY-MM-DD'))
                    ? moment().add(1, 'minutes').format('HH:mm')
                    : '00:00';
                  campaignEditSetPeriodoToEdit({
                    ...campaignPeriodoToEdit,
                    startDate: initialData.format('DD/MM/YYYY'),
                    startHour: !campaignPeriodoToEdit.startHour
                      ? startHour
                      : campaignPeriodoToEdit.startHour,
                  });
                }
              }}
            />
            <InputMask
              className="field-hour"
              mask="99:99"
              disabled={modalEdit.modeDisabledStartDate}
              style={{ marginRight: 0 }}
              placeholder="Hora"
              value={campaignPeriodoToEdit.startHour}
              onChange={(e) => {
                campaignEditSetPeriodoToEdit({
                  ...campaignPeriodoToEdit,
                  startHour: e.target.value,
                });
              }}
            />
          </label>
          {campaignPeriodoToEdit.startDate && !modalEdit.modeDisabledStartDate && (
            <ClearButton onClick={() => {
              campaignEditSetPeriodoToEdit({
                ...campaignPeriodoToEdit,
                startDate: '',
                startHour: '',
              });
            }}
            >
              <FaTimes size={22} />
            </ClearButton>
          )}
        </FormGroup>
        <FormGroup>
          <label>
            <b>Final:</b>
            <DayPickerInput
              ref={(ref) => {
                if (ref && !campaignPeriodoToEdit.endDate) {
                  ref.input.value = campaignPeriodoToEdit.endDate;
                }
              }}
              formatDate={formatDate}
              parseDate={parseDate}
              format="DD/MM/YYYY"
              dayPickerProps={{
                localeUtils: MomentLocaleUtils,
                locale: 'pt-br',
              }}
              placeholder="__/__/____"
              value={campaignPeriodoToEdit.endDate ? moment(
                campaignPeriodoToEdit.endDate,
                'DD/MM/YYYY',
              ).toDate() : null
              }
              onDayChange={(day) => {
                if (day) {
                  campaignEditSetPeriodoToEdit({
                    ...campaignPeriodoToEdit,
                    endDate: moment(day).format('DD/MM/YYYY'),
                    endHour: !campaignPeriodoToEdit.endHour ? '23:59' : campaignPeriodoToEdit.endHour,
                  });
                }
              }}
            />
            <InputMask
              className="field-hour"
              mask="99:99"
              style={{ marginRight: 0 }}
              placeholder="Hora"
              value={campaignPeriodoToEdit.endHour}
              onChange={(e) => {
                campaignEditSetPeriodoToEdit({
                  ...campaignPeriodoToEdit,
                  endHour: e.target.value,
                });
              }}
            />
          </label>
          {campaignPeriodoToEdit.endDate && (
            <ClearButton onClick={() => {
              campaignEditSetPeriodoToEdit({
                ...campaignPeriodoToEdit,
                endDate: '',
                endHour: '',
              });
            }}
            >
              <FaTimes size={22} />
            </ClearButton>
          )}
        </FormGroup>

        <RowFooter>
          <Button
            title="SALVAR"
            onClick={() => {
              campaignEditSavePeriodoInEdit();
            }}
          />
        </RowFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modalEdit: state.campaignEdit.modalEdit,
  campaignPeriodoToEdit: state.campaignEdit.campaignPeriodoToEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditSetPeriodoToEdit: CampaignEditActions.campaignEditSetPeriodoToEdit,
    campaignEditSavePeriodoInEdit: CampaignEditActions.campaignEditSavePeriodoInEdit,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditPeriodo);
