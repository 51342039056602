import styled from 'styled-components';
import {
  TableStyled,
} from '~/Components/Styled';
import Button from '~/Components/Button';

export const Table = styled(TableStyled)`
  margin-top: 5px;
  tbody tr:hover {
    background-color: ${props => props.theme.colors.lighterWhite}
  }
  tbody tr td {
    padding: 5px 5px 5px 15px;
    span {
      font-size: 14px;
      color: ${props => props.theme.colors.dark};
      font-weight: 500;
      font-family: 'Simplon Oi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
    }
  }
`;

export const ButtonSelectSegment = styled.button.attrs({
  type: 'button',
})`
  background-color: transparent;
  font-size: 14px;
  border: 0;
  color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.dark)};
  outline: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0;
  svg {
    font-size: 18px;
    margin-left: -5px;
  }
`;

export const SegmentButton = styled(Button)`
  height: 25px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  line-height: 25px;
  padding: 0 7px;
`;
