/* Types */
export const Types = {
  LOAD_PROVIDER_NEW: 'provider/LOAD_PROVIDER_NEW',
  LOAD_PROVIDER_EDIT: 'provider/LOAD_PROVIDER_EDIT',
  LOAD_PROVIDER_EDIT_SUCCESS: 'provider/LOAD_PROVIDER_EDIT_SUCCESS',
  UPDATE_PROVIDER: 'provider/UPDATE_PROVIDER',
  SAVE_PROVIDER_EDIT: 'provider/SAVE_PROVIDER_EDIT',
  LOAD_PROVIDER_LIST: 'provider/LOAD_PROVIDER_LIST',
  LOAD_PROVIDER_LIST_SUCCESS: 'provider/LOAD_PROVIDER_LIST_SUCCESS',
  UPDATE_FILTER: 'provider/UPDATE_FILTER',
  FILTER_PROVIDER_LIST: 'provider/FILTER_PROVIDER_LIST',
  DELETE_PROVIDER: 'provider/DELETE_PROVIDER',
  BACKUP_PROVIDER_LIST: 'provider/BACKUP_PROVIDER_LIST',
  RESET_SEARCH_PROVIDER: 'provider/RESET_SEARCH_PROVIDER',
};

const INIT_NEW_PROVIDER = {
  uuid: null,
  name: 'Nome',
  htmlContent: '',
  script: '',
  contractUuid: '',
};

const INITIAL_STATE = {
  providers: [],
  filter: {
    name: null,
  },
  edit: {
    provider: null,
  },
};

export default function provider(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD_PROVIDER_EDIT_SUCCESS:
      return {
        ...state,
        edit: {
          ...state.edit,
          provider: action.payload,
        },
      };
    case Types.LOAD_PROVIDER_LIST_SUCCESS:
      return {
        ...state,
        providers: action.payload,
      };
    case Types.LOAD_PROVIDER_NEW:
      return {
        ...state,
        edit: {
          ...state.edit,
          provider: INIT_NEW_PROVIDER,
        },
      };
    case Types.LOAD_PROVIDER_EDIT:
      return {
        ...state,
        edit: {
          ...state.edit,
          provider: null,
        },
      };
    case Types.UPDATE_PROVIDER:
      return {
        ...state,
        edit: {
          ...state.edit,
          provider: action.payload,
        },
      };
    case Types.UPDATE_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    case Types.BACKUP_PROVIDER_LIST:
        return {
          ...state,
          backupProviderList: action.payload,
        };
    default:
      return state;
  }
}

/* Actions Creators */
export const Creators = {
  filterProviderList: () => ({
    type: Types.FILTER_PROVIDER_LIST,
  }),
  loadProviderList: () => ({
    type: Types.LOAD_PROVIDER_LIST,
  }),
  loadProviderListSuccess: providers => ({
    type: Types.LOAD_PROVIDER_LIST_SUCCESS,
    payload: providers,
  }),
  loadProviderNew: () => ({
    type: Types.LOAD_PROVIDER_NEW,
  }),
  loadProviderEdit: providerId => ({
    type: Types.LOAD_PROVIDER_EDIT,
    payload: providerId,
  }),
  loadProviderEditSuccess: providerEdit => ({
    type: Types.LOAD_PROVIDER_EDIT_SUCCESS,
    payload: providerEdit,
  }),
  updateProvider: providerUpdate => ({
    type: Types.UPDATE_PROVIDER,
    payload: providerUpdate,
  }),
  saveProviderEdit: providerUpdate => ({
    type: Types.SAVE_PROVIDER_EDIT,
    payload: providerUpdate,
  }),
  updateFilter: filter => ({
    type: Types.UPDATE_FILTER,
    payload: filter,
  }),
  deleteProvider: providerId => ({
    type: Types.DELETE_PROVIDER,
    payload: providerId,
  }),
  backupProviderList: (backupProviderList) => ({
    type: Types.BACKUP_PROVIDER_LIST,
    payload: backupProviderList,
  }),
  resetSearchProvider: (backupProviderList) => ({
    type: Types.RESET_SEARCH_PROVIDER,
    payload: backupProviderList,
  }),
};
