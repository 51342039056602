import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Container,
  BaseNotification,
} from './styles';

import Image from '~/Components/Image';

class SponsorNotifyTextPreview extends Component {
  getValueMessage = () => {
    const { data: { content } } = this.props;
    if (content.channelData && content.channelData.message) {
      return content.channelData.message;
    }
    return '';
  };

  renderSmsScreen = () => {
    const { data: { content } } = this.props;
    const url = content.channelData && content.channelData.url;
    const titleContent = content.channelData && content.channelData.title
      ? content.channelData.title
      : ''; // 'Título da Mensagem';
    const subtitleContent = content.channelData && content.channelData.subtitle
      ? content.channelData.subtitle
      : ''; // 'Subtitulo da mensagem descrito com texto largo para teste de campo';
    const imageContent = content.channelData && content.channelData.image
      ? content.channelData.image
      : null;

    return (
      <Container>
        <BaseNotification onClick={() => {
          window.open(url.indexOf('http') === 0 ? url : `http://${url}`, '_blank');
        }}
        >
          <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            <label className="title-app">App Genérico</label>
            <span className="dot-now-app">
              <span className="dot-app">&#183;</span>
              {' '}
            52min
            </span>
          </Row>
          <Row>
            <div className="title-subtitle">
              <label className="title">{titleContent}</label>
              <p>{subtitleContent}</p>
            </div>
          </Row>
          {imageContent && (
          <Row className="action-box-banner">
            <Image className="img-banner" src={imageContent} alt="banner" />
          </Row>
          )}
        </BaseNotification>
      </Container>
    );
  };

  render() {
    return this.renderSmsScreen();
  }
}

SponsorNotifyTextPreview.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape().isRequired,
  }).isRequired,
};

export default SponsorNotifyTextPreview;
