/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable arrow-parens */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as AdActions } from "~/redux/ducks/ad";
import { Creators as EventActions } from "~/redux/ducks/event";
import { Creators as ChannelActions } from "~/redux/ducks/channel";
import { Creators as AdvertisingPartnerActions } from "~/redux/ducks/advertisingPartner";

import {
  Container,
  Table,
  TableSetupHeader,
  Row,
  TableButtons,
  Input,
  FormGroup
} from "./styles";
import Button from "~/Components/Button";
import AdColor from "../../components/AdColor";
import AdStatus from "../../components/AdStatus";
import SetupStatus from "../../components/SetupStatus";

import arrowTop from "~/Assets/Images/arrow-top.png";
import arrowBottom from "~/Assets/Images/arrow-bottom.png";

import ModalEdit from "../../components/ModalEdit";

class PlacementSetting extends Component {
  static propTypes = {
    loadAdList: PropTypes.func.isRequired,
    showModalEditAd: PropTypes.bool.isRequired,
    adList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    setupEditing: PropTypes.number.isRequired,
    setupOrdering: PropTypes.number.isRequired,
    loadEditAd: PropTypes.func.isRequired,
    changeDetailPositionInSetup: PropTypes.func.isRequired,
    loadEventsList: PropTypes.func.isRequired,
    loadChannelsList: PropTypes.func.isRequired,
    loadAdvertisingPartnerList: PropTypes.func.isRequired,
    setupEnableDisable: PropTypes.func.isRequired,
    setupEnableToEdit: PropTypes.func.isRequired,
    setupDisableToEdit: PropTypes.func.isRequired,
    setupEnableOrdering: PropTypes.func.isRequired,
    setupSaveOrdering: PropTypes.func.isRequired,
    setupDisableOrdering: PropTypes.func.isRequired,
    changeStateEditingSetup: PropTypes.func.isRequired,
    setupSaveEditing: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      loadAdList,
      loadEventsList,
      loadChannelsList,
      loadAdvertisingPartnerList
    } = this.props;
    loadAdList();
    loadEventsList();
    loadChannelsList();
    loadAdvertisingPartnerList();
  }

  renderTableAds = group => {
    const {
      loadEditAd,
      changeDetailPositionInSetup,
      setupOrdering
    } = this.props;
    let adItemsRows = [];
    group.details.forEach(detail => {
      adItemsRows.push({
        id: detail.id,
        setupId: group.id,
        enabled: detail.enabled,
        label: detail.label,
        color: detail.color,
        position: detail.position
      });
    });
    adItemsRows = _.orderBy(adItemsRows, ["position"], ["asc"]);
    return (
      <Table>
        <thead>
          <tr>
            {setupOrdering === group.id && <th width="1%">ORDEM</th>}
            <th width="1%">HABILITADO</th>
            <th width="1%">COR</th>
            <th>DESCRIÇÃO</th>
            <th width="1%">AÇÕES</th>
          </tr>
        </thead>
        <tbody>
          {adItemsRows.length < 1 ? (
            <tr>
              <td colSpan="5">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de fornecedores</span>
                </div>
              </td>
            </tr>
          ) : (
            adItemsRows.map(ad => (
              <tr key={ad.id}>
                {setupOrdering === group.id && (
                  <td>
                    <div className="ordination">
                      {ad.position > 0 && (
                        <button
                          onClick={() => {
                            changeDetailPositionInSetup(
                              ad.setupId,
                              ad.id,
                              ad.position - 1
                            );
                          }}
                          type="button"
                        >
                          <img src={arrowTop} alt="ordination" />
                        </button>
                      )}
                      {ad.position < adItemsRows.length - 1 && (
                        <button
                          onClick={() => {
                            changeDetailPositionInSetup(
                              ad.setupId,
                              ad.id,
                              ad.position + 1
                            );
                          }}
                          type="button"
                        >
                          <img src={arrowBottom} alt="ordination" />
                        </button>
                      )}
                    </div>
                  </td>
                )}
                <td>
                  <span>
                    <AdStatus enabled={ad.enabled} />
                  </span>
                </td>
                <td>
                  <span>
                    <AdColor color={ad.color} />
                  </span>
                </td>
                <td>
                  <span>{ad.label}</span>
                </td>
                <td>
                  <TableButtons>
                    <Button
                      bordered
                      title="Alterar"
                      onClick={() => {
                        loadEditAd(ad.setupId, ad.id);
                      }}
                    />
                  </TableButtons>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    );
  };

  render() {
    const {
      adList,
      showModalEditAd,
      setupEnableDisable,
      setupEnableToEdit,
      setupDisableToEdit,
      setupEditing,
      changeStateEditingSetup,
      setupSaveEditing,
      setupEnableOrdering,
      setupDisableOrdering,
      setupSaveOrdering,
      setupOrdering
    } = this.props;

    return (
      <Container>
        <Row>
          <h1>Configuração de Veiculação</h1>
        </Row>
        {adList.map(ad => (
          <Row key={ad.id}>
            <div className="ad-table">
              <TableSetupHeader>
                <tbody>
                  <tr>
                    <td>
                      <span>
                        <b>Entrega: </b>
                      </span>
                      <span>{ad.event}</span>
                    </td>
                    <td>
                      <span>
                        <b>Canal: </b>
                      </span>
                      <span>{ad.channel}</span>
                    </td>
                    <td>
                      <SetupStatus enabled={ad.enabled} />
                    </td>
                    <td width="1%">
                      <Button
                        bordered
                        title={ad.enabled ? "Desativar" : "Ativar"}
                        onClick={() => {
                          setupEnableDisable(ad.id);
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </TableSetupHeader>
              <div className="box-activate-edition">
                <FormGroup>
                  <label>Audiência(%)</label>
                  <Input
                    placeholder="Audiência(%)"
                    value={
                      setupEditing.id !== ad.id
                        ? ad.audience
                        : setupEditing.audience
                    }
                    onChange={e => {
                      changeStateEditingSetup({
                        audience: e.target.value
                      });
                    }}
                    disabled={setupEditing.id !== ad.id}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Impressão(segs)</label>
                  <Input
                    placeholder="Impressão(segs)"
                    value={
                      setupEditing.id !== ad.id
                        ? ad.printingSecs
                        : setupEditing.printingSecs
                    }
                    onChange={e => {
                      changeStateEditingSetup({
                        printingSecs: e.target.value
                      });
                    }}
                    disabled={setupEditing.id !== ad.id}
                  />
                </FormGroup>
                <FormGroup>
                  <label>Retentiva(segs)</label>
                  <Input
                    placeholder="Retentiva(segs)"
                    value={
                      setupEditing.id !== ad.id
                        ? ad.retrySecs
                        : setupEditing.retrySecs
                    }
                    onChange={e => {
                      changeStateEditingSetup({
                        retrySecs: e.target.value
                      });
                    }}
                    disabled={setupEditing.id !== ad.id}
                  />
                </FormGroup>
                {setupEditing.id === ad.id ? (
                  <>
                    <Button
                      bordered
                      title="Cancelar"
                      onClick={() => {
                        setupDisableToEdit();
                      }}
                    />
                    <Button
                      bordered
                      title="Salvar"
                      onClick={() => {
                        setupSaveEditing();
                      }}
                    />
                  </>
                ) : (
                  <Button
                    bordered
                    title="Ativar edição"
                    onClick={() => {
                      setupEnableToEdit(ad.id);
                    }}
                  />
                )}
              </div>
              <div className="box-activate-ordination">
                {setupOrdering === ad.id ? (
                  <>
                    <Button
                      bordered
                      title="Cancelar"
                      onClick={() => {
                        setupDisableOrdering();
                      }}
                    />
                    <Button
                      bordered
                      title="Salvar ordenação"
                      onClick={() => {
                        setupSaveOrdering();
                      }}
                    />
                  </>
                ) : (
                  <Button
                    bordered
                    title="Ativar ordenação"
                    onClick={() => {
                      setupEnableOrdering(ad.id);
                    }}
                  />
                )}
              </div>
              {this.renderTableAds(ad)}
            </div>
          </Row>
        ))}
        {showModalEditAd && <ModalEdit /> /* this.renderModalEditAd() */}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  adList: state.ad.adList,
  setupEditing: state.ad.setupEditing,
  showModalEditAd: state.ad.showModalEditAd,
  setupOrdering: state.ad.setupOrdering
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadAdList: AdActions.loadAdList,
      setupEnableDisable: AdActions.setupEnableDisable,
      setupEnableToEdit: AdActions.setupEnableToEdit,
      setupDisableToEdit: AdActions.setupDisableToEdit,
      setupEnableOrdering: AdActions.setupEnableOrdering,
      setupSaveOrdering: AdActions.setupSaveOrdering,
      setupDisableOrdering: AdActions.setupDisableOrdering,
      changeStateEditingSetup: AdActions.changeStateEditingSetup,
      setupSaveEditing: AdActions.setupSaveEditing,
      loadEditAd: AdActions.loadEditAd,
      changeDetailPositionInSetup: AdActions.changeDetailPositionInSetup,
      loadEventsList: EventActions.loadEventsList,
      loadChannelsList: ChannelActions.loadChannelsList,
      loadAdvertisingPartnerList:
        AdvertisingPartnerActions.loadAdvertisingPartnerList
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlacementSetting);
