/* eslint-disable quotes */
import styled from "styled-components";


export const Container = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  height: 100vh;
  width: 100%;
  justify-content: center;
  align-items: center;
`;
