import React, { Component } from 'react';

import {
  Container,
  Table,
  Row,
  LinkStyled,
} from './styles';

class ReportList extends Component {
  uuidTenant = localStorage.getItem('uuidTenant');

  renderReports = () => (
    <Table>
      <thead>
        <tr>
          <th>CLIQUE NO RELATÓRIO PARA ACESSO</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td className="link">
            <LinkStyled to="reports/weekly-gridperiod-extract">
              Resumo de Fechamento Semanal
            </LinkStyled>
          </td>
        </tr>
        {/* {isShowResumoFechamentoMensal && (
          <tr>
            <td className="link">
              <LinkStyled to="reports/monthly-gridperiod-extract">
                Resumo de Fechamento Mensal
              </LinkStyled>
            </td>
          </tr>
          )} */}
      </tbody>
    </Table>
  )
  ;

  render() {
    return (
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>Relatórios</h1>
        </Row>
        {this.renderReports()}
      </Container>
    );
  }
}

export default ReportList;
