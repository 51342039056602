import React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'react-loaders';
import { withTheme } from 'styled-components';

import { Container } from './styles';

const InitialLoader = ({ theme }) => (
  <Container>
    <Loader active type="ball-grid-beat" color={theme.colors.primary} />
  </Container>
);

InitialLoader.propTypes = {
  theme: PropTypes.shape().isRequired,
};

export default withTheme(InitialLoader);
