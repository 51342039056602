import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import { Link } from 'react-router-dom';

import {
  ContainerStyled,
  TableStyled,
  RowStyled,
  InputStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)``;

export const Select = styled(ReactSelect)`
  width: 180px;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const Table = styled(TableStyled)``;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
`;

export const BaseTable = styled.div`
  width: 100%;
`;

export const Input = styled(InputStyled)``;
