import React from 'react';
import PropTypes from 'prop-types';

import { Container, Status } from './styles';

const AdStatus = ({ enabled }) => (
  <Container>
    <Status enabled={enabled} />
    {enabled ? 'Sim' : 'Não'}
  </Container>
);

AdStatus.propTypes = {
  enabled: PropTypes.bool.isRequired,
};

export default AdStatus;
