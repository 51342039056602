import React from 'react';
import PropTypes from 'prop-types';

import ReactTooltip from 'react-tooltip';

import typeCampaignImg from '../../assets/type-campaign.png';
import typeCallServiceImg from '../../assets/type-call-service.png';

import { Container } from './styles';

function BehaviorIconType({
  showTooltip, width, height,
  eventType: { name, description },
}) {
  let typeImg = null;
  if (name === 'QUIZ') {
    typeImg = typeCampaignImg;
  } else if (name === 'BENEFIT') {
    typeImg = typeCallServiceImg;
  }

  if (!typeImg) {
    return null;
  }

  return (
    <Container data-tip={showTooltip ? description : null} width={width} height={height}>
      <img src={typeImg} alt={description} />
      <ReactTooltip place="right" effect="solid" />
    </Container>
  );
}

BehaviorIconType.defaultProps = {
  showTooltip: true,
  width: null,
  height: 30,
};

BehaviorIconType.propTypes = {
  eventType: PropTypes.shape().isRequired,
  showTooltip: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default BehaviorIconType;
