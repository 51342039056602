/* eslint-disable operator-linebreak */
/* eslint-disable function-paren-newline */
/* eslint-disable react/no-array-index-key */
/* eslint-disable object-curly-newline */
/* eslint-disable arrow-parens */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
import React, { Component } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Creators as AdActions } from "~/redux/ducks/ad";
import { Creators as EventActions } from "~/redux/ducks/event";
import { Creators as ChannelActions } from "~/redux/ducks/channel";
import { Creators as AdvertisingPartnerActions } from "~/redux/ducks/advertisingPartner";

import Modal from "~/Components/Modal";

import { Container, Table, Row, TableButtons, Select } from "./styles";
import Button from "~/Components/Button";
import AdColor from "../../components/AdColor";
import AdStatus from "../../components/AdStatus";

import ModalEdit from "../../components/ModalEdit";
import ModalNew from "../../components/ModalNew";

class AdManagement extends Component {
  state = {
    showDeleteAd: false,
    deleteDetailId: null,
    deleteSetupId: null
  };

  static propTypes = {
    // State
    adList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    eventFilter: PropTypes.string.isRequired,
    channelFilter: PropTypes.string.isRequired,
    partnerFilter: PropTypes.string.isRequired,
    showModalNewAd: PropTypes.bool.isRequired,
    showModalEditAd: PropTypes.bool.isRequired,
    channelsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    eventsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    advertisingPartnerList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    // Functions
    loadAdList: PropTypes.func.isRequired,
    deleteAd: PropTypes.func.isRequired,
    loadEditAd: PropTypes.func.isRequired,
    setEventFilter: PropTypes.func.isRequired,
    setChannelFilter: PropTypes.func.isRequired,
    setPartnerFilter: PropTypes.func.isRequired,
    changeModalNewAd: PropTypes.func.isRequired,
    loadEventsList: PropTypes.func.isRequired,
    loadChannelsList: PropTypes.func.isRequired,
    loadAdvertisingPartnerList: PropTypes.func.isRequired
  };

  componentDidMount() {
    const {
      loadAdList,
      loadEventsList,
      loadChannelsList,
      loadAdvertisingPartnerList
    } = this.props;
    loadAdList();
    loadEventsList();
    loadChannelsList();
    loadAdvertisingPartnerList();
  }

  renderRowAd = ad => {
    const { loadEditAd } = this.props;
    return (
      <tr key={ad.id}>
        <td>
          <span>
            <AdStatus enabled={ad.enabled} />
          </span>
        </td>
        <td>
          <span>
            <AdColor color={ad.color} />
          </span>
        </td>
        <td className="nowrap">
          <span>{ad.event}</span>
        </td>
        <td>
          <span>{ad.channel}</span>
        </td>
        <td>
          <span>{ad.partner}</span>
        </td>
        <td>
          <span>{ad.label}</span>
        </td>
        <td>
          <TableButtons>
            <Button
              bordered
              title="Alterar"
              onClick={() => {
                loadEditAd(ad.setupId, ad.id);
              }}
            />
            <Button
              bordered
              title="Excluir"
              onClick={() => {
                this.setState({
                  showDeleteAd: true,
                  deleteDetailId: ad.id,
                  deleteSetupId: ad.setupId
                });
              }}
            />
          </TableButtons>
        </td>
      </tr>
    );
  };

  renderTablePartners = () => {
    const { adList, eventFilter, channelFilter, partnerFilter } = this.props;
    let adItemsRows = [];
    adList.forEach(ad => {
      ad.details.forEach(detail => {
        adItemsRows.push({
          id: detail.id,
          setupId: ad.id,
          enabled: detail.enabled,
          label: detail.label,
          partner: detail.partner.name,
          color: detail.color,
          channel: ad.channel,
          event: ad.event
        });
      });
    });
    adItemsRows = adItemsRows.filter(item => {
      const eventHasFilter = item.event === eventFilter || !eventFilter;
      const channelHasFilter = item.channel === channelFilter || !channelFilter;
      const partnerHasFilter = item.partner === partnerFilter || !partnerFilter;
      return eventHasFilter && channelHasFilter && partnerHasFilter;
    });
    return (
      <Table>
        <thead>
          <tr>
            <th width="1%">HABILITADO</th>
            <th width="1%">COR</th>
            <th>EVENTO</th>
            <th>CANAL</th>
            <th>FORNECEDOR</th>
            <th>DESCRIÇÃO</th>
            <th width="1%">AÇÕES</th>
          </tr>
        </thead>
        <tbody>
          {adList.length < 1 ? (
            <tr>
              <td colSpan="5">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de fornecedores</span>
                </div>
              </td>
            </tr>
          ) : (
            adItemsRows.map(ad => this.renderRowAd(ad))
          )}
        </tbody>
      </Table>
    );
  };

  renderEventSelect = () => {
    const { eventsList, eventFilter, setEventFilter } = this.props;
    return (
      <Select
        placeholder="Evento..."
        defaultValue={eventFilter}
        onChange={e => {
          setEventFilter(e.value);
        }}
        options={[
          {
            value: null,
            label: "Evento..."
          },
          ...eventsList.map(item => ({
            value: item.name,
            label: item.name
          }))
        ]}
      />
    );
  };

  renderChannelsSelect = () => {
    const { channelsList, channelFilter, setChannelFilter } = this.props;
    return (
      <Select
        placeholder="Canal..."
        defaultValue={channelFilter}
        onChange={e => {
          setChannelFilter(e.value);
        }}
        options={[
          {
            value: null,
            label: "Canal..."
          },
          ...channelsList.map(item => ({
            value: item.code,
            label: item.name
          }))
        ]}
      />
    );
  };

  renderPartnersSelect = () => {
    const {
      advertisingPartnerList,
      partnerFilter,
      setPartnerFilter
    } = this.props;
    return (
      <Select
        placeholder="Fornecedor..."
        defaultValue={partnerFilter}
        onChange={e => {
          setPartnerFilter(e.value);
        }}
        options={[
          {
            value: null,
            label: "Fornecedor..."
          },
          ...advertisingPartnerList.map(item => ({
            value: item.name,
            label: item.name
          }))
        ]}
      />
    );
  };

  renderDeleteAd = () => {
    const { deleteAd } = this.props;
    const { deleteDetailId, deleteSetupId } = this.state;
    return (
      <Modal width={350}>
        <Row>
          <h1>Confirmar a exclusão do anúncio?</h1>
        </Row>
        <Row style={{ justifyContent: "space-between", marginBottom: "0px" }}>
          <Button
            style={{ marginLeft: 0 }}
            color="light"
            title="CANCELAR"
            onClick={() => {
              this.setState({
                showDeleteAd: false,
                deleteDetailId: null,
                deleteSetupId: null
              });
            }}
          />
          <Button
            title="CONFIRMAR EXCLUSÃO"
            onClick={() => {
              deleteAd(deleteSetupId, deleteDetailId);
              this.setState({
                showDeleteAd: false,
                deleteDetailId: null,
                deleteSetupId: null
              });
            }}
          />
        </Row>
      </Modal>
    );
  };

  render() {
    const { showModalNewAd, showModalEditAd, changeModalNewAd } = this.props;
    const { showDeleteAd } = this.state;
    return (
      <Container>
        <Row>
          <h1>Gestão de Anúncios</h1>
          <div className="box-search">
            {this.renderPartnersSelect()}
            {this.renderEventSelect()}
            {this.renderChannelsSelect()}
            <Button
              bordered
              title="Adicionar"
              onClick={() => changeModalNewAd(true)}
            />
          </div>
        </Row>
        {this.renderTablePartners()}
        {showModalNewAd && <ModalNew />}
        {showModalEditAd && <ModalEdit />}
        {showDeleteAd && this.renderDeleteAd()}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  adList: state.ad.adList,
  eventFilter: state.ad.eventFilter,
  channelFilter: state.ad.channelFilter,
  partnerFilter: state.ad.partnerFilter,
  showModalNewAd: state.ad.showModalNewAd,
  showModalEditAd: state.ad.showModalEditAd,
  channelsList: state.channel.channelsList,
  eventsList: state.event.eventsList,
  advertisingPartnerList: state.advertisingPartner.advertisingPartnerList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadAdList: AdActions.loadAdList,
      deleteAd: AdActions.deleteAd,
      loadEditAd: AdActions.loadEditAd,
      setEventFilter: AdActions.setEventFilter,
      setChannelFilter: AdActions.setChannelFilter,
      setPartnerFilter: AdActions.setPartnerFilter,
      changeModalNewAd: AdActions.changeModalNewAd,
      loadEventsList: EventActions.loadEventsList,
      loadChannelsList: ChannelActions.loadChannelsList,
      loadAdvertisingPartnerList:
        AdvertisingPartnerActions.loadAdvertisingPartnerList
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AdManagement);
