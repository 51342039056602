/* eslint-disable no-plusplus */
import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';

import ReportService from '~/services/ReportService';
import { Creators as ReportGridPeriodActions } from '~/redux/ducks/report';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

const TYPE_ITEM_DEFAULT = { label: 'Todos', value: 'ALL' };

export function* reportMonthlyGridPeriodSetup() {
  const { monthList } = yield select(state => state.report);
  // pega o mês atual como mes default
  const monthSelected = monthList[0];
  // pega o primeiro tipo como default
  const typeSelected = TYPE_ITEM_DEFAULT;
  // Start loading
  yield put(startRequest());
  try {
    // GET Channels
    const channelList = yield call(ReportService.channelRequest, 'POST');
    const channelZonesList = [];
    channelList.forEach((channel) => {
      channel.zones.forEach((zone) => {
        channelZonesList.push({
          value: zone.uuid,
          label: `${channel.name} (${zone.name})`,
        });
      });
    });
    yield put(ReportGridPeriodActions.reportGridPeriodChannelRequestSuccess(channelZonesList));
    // GET Types
    const typeList = yield call(ReportService.getTypes);
    yield put(ReportGridPeriodActions.reportGridPeriodTypesRequestSuccess([
      TYPE_ITEM_DEFAULT,
      ...typeList.map(item => ({
        label: item.label, value: item.uuid,
      })),
    ]));


    // SET mes selecionado
    yield put(
      ReportGridPeriodActions.reportGridPeriodSetFilter({
        month: monthSelected,
        type: typeSelected,
        channel: channelZonesList[0],
      }),
    );
    yield put(ReportGridPeriodActions.reportMonthlyGridPeriodRequest());
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os filtros. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* reportGridPeriodSetup() {
  // Start loading
  yield put(startRequest());
  try {
    // GET Channels
    const channelList = yield call(ReportService.channelRequest);
    const channelZonesList = [];
    channelList.forEach((channel) => {
      channel.zones.forEach((zone) => {
        channelZonesList.push({
          value: zone.uuid,
          label: `${channel.name} (${zone.name})`,
        });
      });
    });
    yield put(ReportGridPeriodActions.reportGridPeriodChannelRequestSuccess(channelZonesList));
    yield put(ReportGridPeriodActions.reportGridPeriodSetFilter({
      channel: channelZonesList[0],
    }));
    // GET Periods
    let periodList = yield call(ReportService.periodRequest);
    periodList = periodList.map(item => ({
      data: {
        ...item,
        // gridOpen: false,
      },
      value: item.uuid,
      label: `${item.label} ${item.gridOpen ? '(aberta)' : ''}`,
    }));
    yield put(ReportGridPeriodActions.reportGridPeriodPeriodRequestSuccess(periodList));
    yield put(ReportGridPeriodActions.reportGridPeriodSetFilter({
      period: periodList[0],
    }));
    // Stop loading
    yield put(requestSuccess());
    // Load Auctions
    yield put(ReportGridPeriodActions.reportGridPeriodRequest());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os filtros. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* reportGridPeriodRequest() {
  const { gridPeriodFilter } = yield select(state => state.report);
  try {
    if (gridPeriodFilter.period && gridPeriodFilter.channel) {
      // Start loading
      yield put(startRequest());
      // GET Auctions
      const gridPeriodList = yield call(
        ReportService.reportGridPeriodRequest,
        gridPeriodFilter.period.value,
        gridPeriodFilter.channel.value,
      );
      // Set grid period success
      const mapGrid = gridPeriodList.map(gridPeriod => ({
        ...gridPeriod,
        gridItems: gridPeriod.gridItems.map((gridItem) => {
          const { campaignResult } = gridItem;
          return {
            ...gridItem,
            statusSignature: campaignResult.typeValidation
              ? campaignResult.typeValidation
              : 'null', // Status da assinatura
            statusLabelSignature: campaignResult.typeValidation
              ? campaignResult.typeValidation
              : 'null', // Status da assinatura
            hasSignature:
              (campaignResult.typeValidation && campaignResult.typeValidation !== 'null')
              || !campaignResult.typeValidation, // Caso já tenha vindo assinado
            signatureObservation: '', // Observação caso assinatura invalidada
            signatureConfirmed:
              (campaignResult.typeValidation && campaignResult.typeValidation !== 'null')
              || !campaignResult.typeValidation, // Caso já tenha vindo assinado
          };
        }),
      }));
      yield put(
        ReportGridPeriodActions.reportGridPeriodRequestSuccess(mapGrid),
      );
      // Stop Loading
      yield put(requestSuccess());
    }
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados de campanha. Tente novamente!',
    ));
  }
}

export function* reportMonthlyGridPeriodRequest() {
  const { gridPeriodFilter } = yield select(state => state.report);
  try {
    // Start loading
    yield put(startRequest());
    // GET Auctions
    const gridPeriodList = yield call(
      ReportService.reportMonthlyGridPeriodRequest,
      gridPeriodFilter.month.value,
      gridPeriodFilter.channel.value, // '0cd196ec-cc2c-42de-914b-3819b3f8db90'
      gridPeriodFilter.type.value,
    );
    // Set grid period success
    yield put(
      ReportGridPeriodActions.reportMonthlyGridPeriodRequestSuccess(gridPeriodList),
    );
    // Stop Loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados de campanha. Tente novamente!',
    ));
  }
}

export function* reportGridPeriodLoadReportData({ gridUuid, campaignUuid, gridPeriodUuid }) {
  yield put(startRequest());
  try {
    const result = yield call(
      ReportService.gridPeriodReport,
      gridUuid,
      campaignUuid,
      gridPeriodUuid,
    );

    const totals = {
      volume: 0,
      sentQty: 0,
      clickQty: 0,
      costVlr: 0,
    };

    const accumulated = [];

    if (result.data && result.data.length > 0) {
      result.data = result.data.map(d => ({
        ...d,
        dateEvent: moment(d.dateEvent, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      }));

      for (let i = 0; i < result.data.length; i++) {
        const d = result.data[i];

        totals.volume += d.volume || 0;
        totals.sentQty += d.sentQty || 0;
        totals.clickQty += d.clickQty || 0;
        totals.costVlr += d.costVlr || 0;

        accumulated.push({
          dateEvent: d.dateEvent,
          volume: totals.volume,
          sentQty: totals.sentQty,
          clickQty: totals.clickQty,
          costVlr: totals.costVlr,
        });
      }
    }

    yield put(
      ReportGridPeriodActions.reportGridPeriodLoadReportDataSuccess({
        ...result,
        totals,
        accumulated,
      }),
    );
    yield put(requestSuccess());
  } catch (error) {
    yield put(ToastrMessageActions.warningMessage(
      'Atenção!',
      error,
    ));
    yield put(requestError());
  }
}

export function* reportGridConfirmSignature({ gridUuid }) {
  const { gridPeriod } = yield select(state => state.report);

  yield put(startRequest());
  try {
    const findGrid = gridPeriod.find(item => item.uuid === gridUuid);

    const filterGridItems = findGrid.gridItems.filter(
      item => (
        !!item.statusSignature
        && item.statusSignature !== 'null'
        && (
          !item.hasSignature
          || (
            item.statusLabelSignature === 'INVALIDATION'
            && item.statusSignature !== 'INVALIDATION'
          )
        )
      ),
    );

    if (filterGridItems.length > 0) {
      yield call(
        ReportService.confirmSignature,
        {
          campaignsResults: filterGridItems.map(item => ({
            type: item.statusSignature,
            uuid: item.campaignResult.uuid,
            descriptionUser: item.signatureObservation,
          })),
        },
      );
      yield put(ToastrMessageActions.successMessage(
        'Sucesso!',
        'Validação confirmada com sucesso!',
      ));
      yield put(ReportGridPeriodActions.reportGridPeriodRequest());
    } else {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Não há campanhas para confirmar validação!',
      ));
    }
    yield put(requestSuccess());
  } catch (error) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao confirmar validação!',
    ));
    yield put(requestError());
  }
}
