import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as AudienceActions } from '~/redux/ducks/audience';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  Input,
  Message,
} from './styles';

class ModalCloneAudience extends Component {
  state= {
    hasError: false,
  };

  componentDidMount() {
    this.setState({
      hasError: false,
    });
  }

  render() {
    const {
      audienceToClone,
      audienceSetClone,
      audienceCloneRequest,
    } = this.props;
    const { hasError } = this.state;

    return !!audienceToClone && (
      <Modal width={500}>
        <>
          <Row>
            <h1>Clonar Audiência</h1>
            <ButtonCloseModal
              type="button"
              onClick={() => {
                audienceSetClone(null);
              }}
            >
              <FaTimes size={30} />
            </ButtonCloseModal>
          </Row>
          <Message>Deseja realizar uma cópia da Audiência?</Message>
          <FormGroup>
            <label>
              <b>Nome da Audiência: </b>
            </label>
            <Input
              type="text"
              style={{ marginRight: 0 }}
              placeholder="Nome da Audiência"
              value={audienceToClone.name}
              invalid={hasError && !audienceToClone.name}
              onChange={(e) => {
                audienceSetClone({
                  ...audienceToClone,
                  name: e.target.value,
                });
              }}
            />
          </FormGroup>
          <RowFooter>
            <Button
              title="CONFIRMAR"
              onClick={() => {
                if (!!audienceToClone.uuid && !!audienceToClone.name) {
                  audienceCloneRequest();
                } else {
                  this.setState({
                    hasError: true,
                  });
                }
              }}
            />
          </RowFooter>
        </>
      </Modal>
    );
  }
}

ModalCloneAudience.propTypes = {
  audienceSetClone: PropTypes.func.isRequired,
  audienceCloneRequest: PropTypes.func.isRequired,
  audienceToClone: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  audienceToClone: state.audience.audienceToClone,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    audienceSetClone: AudienceActions.audienceSetClone,
    audienceCloneRequest: AudienceActions.audienceCloneRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalCloneAudience);
