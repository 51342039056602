/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  Container, CloseModal, Row, FormGroup, Textarea, RowFooter,
} from './styles';
import Button from '~/Components/Button';
import Modal from '~/Components/Modal';
import { general } from '~/Styles';

import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';
import CreativeName from '~/Utils/CreativeName';

const CampaignActions = (props) => {
  const {
    campaign,
    onConfirm,
    transitionState,
    setTransitionState,
    criativoEdit,
    confirmModal,
  } = props;
  const { transitions } = campaign.campaignStatus;

  const validateSmartMessageTextForm = () => {
    const { content } = criativoEdit;
    return !!content.screen1 && !!content.screen2;
  };
  const validateSmartMessageTextClaroForm = () => {
    const { content } = criativoEdit;
    return !!content.screen1 && !!content.screen2;
  };
  const validateSmartMessageMenuForm = () => {
    const { content } = criativoEdit;
    return !!content.screen1 && !!content.screen2 && !!content.screen3;
  };
  const validateSmartMessageMenuTimForm = () => {
    const { content } = criativoEdit;
    return !!content.screen1 && !!content.screen2 && !!content.screen3;
  };
  const validateUSSDMessageTextForm = () => {
    const { content } = criativoEdit;
    return !!content.screen1 && !!content.screen2;
  };
  const validateUSSDMessageTextClaroForm = () => {
    const { content } = criativoEdit;
    return !!content.screen1 && !!content.screen2;
  };
  const validateVoiceMessageTextForm = () => {
    const { content } = criativoEdit;
    return !!content.screen1;
  };

  const creativeIsValid = () => {
    switch (criativoEdit.creativeDefinition.name) {
      case CreativeName.SMART_MESSAGE_TEXT: return validateSmartMessageTextForm();
      case CreativeName.SMART_MESSAGE_TEXT_CLARO: return validateSmartMessageTextClaroForm();
      case CreativeName.SMART_MESSAGE_MENU: return validateSmartMessageMenuForm();
      case CreativeName.SMART_MESSAGE_MENU_TIM: return validateSmartMessageMenuTimForm();
      case CreativeName.USSD_MESSAGE_TEXT: return validateUSSDMessageTextForm();
      case CreativeName.USSD_MESSAGE_TEXT_CLARO: return validateUSSDMessageTextClaroForm();
      case CreativeName.VOICE_MESSAGE_TEXT: return validateVoiceMessageTextForm();
      default: return false;
    }
  };

  const handleSubmitTransiction = () => {
    onConfirm();
  };

  const handleOpenTransition = (transition) => {
    setTransitionState({
      name: transition.name,
      campaignUuid: campaign.uuid,
      transitionStateUuid: transition.uuid,
      observation: '',
    });
  };

  return (
    <>
      <Container>
        {transitions.map(transition => (
          <Button
            key={transition.uuid}
            bordered
            title={transition.name}
            onClick={() => {
              const { screenSchema } = transition;
              if (
                screenSchema
                && screenSchema.creative
                && screenSchema.creative.required
              ) {
                if (creativeIsValid()) {
                  handleOpenTransition(transition);
                } else {
                  confirmModal(
                    'Enviar para aprovação!',
                    'O criatvo da campanha não está preenchido, deseja prosseguir mesmo assim?',
                    () => {
                      handleOpenTransition(transition);
                    },
                    {
                      titleConfirm: 'Prosseguir',
                    },
                  );
                }
              } else {
                handleOpenTransition(transition);
              }
            }}
            type="button"
          />
        ))}
      </Container>
      {transitionState && (
        <Modal width={300}>
          <Row>
            <h1>{transitionState.name}</h1>
            <CloseModal
              src={general.image['delete-regular.png']}
              alt="fechar"
              onClick={() => {
                setTransitionState(null);
              }}
            />
          </Row>
          <Row>
            <FormGroup>
              <label>
                Alguma observação
              </label>
              <Textarea
                placeholder="Digite a observação"
                rows="4"
                maxLength="200"
                value={transitionState.observation}
                onChange={(e) => {
                  setTransitionState({ ...transitionState, observation: e.target.value });
                }}
              />
            </FormGroup>
          </Row>
          <RowFooter>
            <Button
              bordered
              title="Confirmar"
              onClick={handleSubmitTransiction}
              type="button"
            />
          </RowFooter>
        </Modal>
      )}
    </>
  );
};

CampaignActions.defaultProps = {
  criativoEdit: null,
};

CampaignActions.propTypes = {
  campaign: PropTypes.any.isRequired,
  onConfirm: PropTypes.func.isRequired,
  transitionState: PropTypes.shape.isRequired,
  setTransitionState: PropTypes.func.isRequired,
  criativoEdit: PropTypes.shape(),
  confirmModal: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  criativoEdit: state.campaignEdit.criativoEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampaignActions);
