import styled from 'styled-components';

import Button from '~/Components/Button';
import { RowStyled, TableStyled } from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  > label {
    margin-right: 10px;
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const ModalWrapBody = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 600px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 580px;
  overflow-y: auto;
  padding: 0 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const Info = styled.div`
  width: 100%;
  background: #ecebeb;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid #ccc;

  p {
    margin-bottom: 0;
    font-weight: 600;
    font-style: italic;
  }
`;

export const QuestionsItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 17px;
  gap: 10px;
  margin-left: 20px;

  svg {
    color: ${props => props.theme.colors.primary};
  }

  p {
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 0;
    flex: 1;
  }
`;

export const BaseFormBenefits = styled.div`
  width: 100%;
  padding: 15px 0px;

  .header-form-benefits {
    display: flex;
    align-items: center;
    gap: 10px;

    .form-group-benefits {
      flex: 1;
      display: flex;
      flex-direction: column;
      label {
        color: ${props => props.theme.colors.regular};
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
`;

export const AddBeneficio = styled(Button)`
  align-self: flex-end;
  margin: 0;
`;

export const RemoveBeneficio = styled(Button)`
  margin: 0;
`;

export const Table = styled(TableStyled)`
  margin-top: 20px;
`;
