/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FaCaretRight,
  FaCaretDown,
} from 'react-icons/fa';

import { Creators as AudienceActions } from '~/redux/ducks/audience';
import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
} from '~/redux/ducks/loading';

import AudienceService from '~/services/AudienceService';
import {
  Table,
  ButtonSelectSegment,
  SegmentButton,
} from './styles';

const Segmentations = ({
  categoryData,
  audienceCategoryDataRequestSuccess,
  audienceCategorySetActiveFilter,
  categoryFilterActive,
  onChange,
  startRequest,
  requestSuccess,
}) => {
  const toggleSegment = (segmentToToggle, segments = categoryData.data) => segments.map((item) => {
    if (item.key === segmentToToggle.key && item.value === segmentToToggle.value) {
      return {
        ...item,
        collapsed: !item.collapsed,
      };
    }
    if (item.data && item.data.length > 0) {
      return {
        ...item,
        data: toggleSegment(segmentToToggle, item.data),
      };
    }
    return item;
  });

  const loadSegmentData = async (item, level) => {
    startRequest();
    const subItems = await AudienceService.getDataItemsOfSubcategory(item);
    requestSuccess();
    const mapData = subItems.map(subItem => ({
      key: item.subCategory.pathItems[level + 1],
      value: subItem.key,
      breadcrumbs: [...item.breadcrumbs, item.value],
      subCategory: item.subCategory,
      path: [...item.path, item.subCategory.pathItems[level + 1]],
      teste: [item.subCategory.pathItems.length, level],
      bodyToGetSubcategory: item.subCategory.pathItems[item.subCategory.pathItems.length - 1] !== item.subCategory.pathItems[level + 1] ? {
        criterias: [
          ...item.bodyToGetSubcategory.criterias,
          {
            key: item.subCategory.pathItems[level + 1],
            value: subItem.key,
          },
        ],
        subcategoryKey: item.subCategory.pathItems[level + 2],
      } : null,
    }));
    const changeData = (segmentToChange, segments = categoryData.data) => segments.map((segment) => {
      if (segment.key === segmentToChange.key && segment.value === segmentToChange.value) {
        return {
          ...segment,
          data: mapData,
          collapsed: true,
        };
      }
      if (segment.data && segment.data.length > 0) {
        return {
          ...segment,
          data: changeData(segmentToChange, segment.data),
        };
      }
      return segment;
    });
    audienceCategoryDataRequestSuccess({
      ...categoryData,
      data: changeData(item),
    });
  };

  const renderSegmentRow = (segment, level) => {
    const haveBrothersInInclude = categoryFilterActive.include.find(
      item => item.path[0] === segment.path[0] && item.breadcrumbs.join('-') === segment.breadcrumbs.join('-'),
    );
    const haveBrothersInExclude = categoryFilterActive.exclude.find(
      item => item.path[0] === segment.path[0] && item.breadcrumbs.join('-') === segment.breadcrumbs.join('-'),
    );
    const hasInInclude = categoryFilterActive.include.find(
      item => item.path[0] === segment.path[0] && item.key === `${segment.key}-${segment.value}`,
    );
    const hasInExclude = categoryFilterActive.exclude.find(
      item => item.path[0] === segment.path[0] && item.key === `${segment.key}-${segment.value}`,
    );
    return (
      <tr key={`${segment.key}-${segment.value}`}>
        <td style={{ paddingLeft: level > 0 ? level * 30 : 10 }}>
          {(!segment.subCategory.isUnique && segment.subCategory.pathItems.length - 1 > level) ? (
            <ButtonSelectSegment
              active={segment.collapsed}
              onClick={() => {
                if (segment.collapsed) {
                  audienceCategoryDataRequestSuccess({
                    ...categoryData,
                    data: toggleSegment(segment),
                  });
                } else {
                  loadSegmentData(segment, level);
                }
              }}
            >
              {segment.collapsed ? <FaCaretDown /> : <FaCaretRight />}
              {segment.value}
            </ButtonSelectSegment>
          ) : (
            <span>{segment.value}</span>
          )}
        </td>
        <td width="1%">
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <SegmentButton
              disabled={!!haveBrothersInExclude}
              bordered={!hasInInclude}
              title={!hasInInclude ? 'Adicionar' : 'Adicionado'}
              onClick={() => {
                if (!hasInInclude) {
                  audienceCategorySetActiveFilter({
                    include: [
                      ...categoryFilterActive.include,
                      {
                        key: `${segment.key}-${segment.value}`,
                        value: segment.value,
                        breadcrumbs: segment.breadcrumbs,
                        path: segment.path,
                        source: segment.subCategory.source,
                      },
                    ],
                  });
                  onChange();
                }
              }}
            />
            <SegmentButton
              disabled={!!haveBrothersInInclude}
              bordered={!hasInExclude}
              title="EXCLUIR"
              onClick={() => {
                if (!hasInExclude) {
                  audienceCategorySetActiveFilter({
                    exclude: [
                      ...categoryFilterActive.exclude,
                      {
                        key: `${segment.key}-${segment.value}`,
                        value: segment.value,
                        breadcrumbs: segment.breadcrumbs,
                        path: segment.path,
                        source: segment.subCategory.source,
                      },
                    ],
                  });
                  onChange();
                }
              }}
            />
          </div>
        </td>
      </tr>
    );
  };

  const renderSegments = (segments, level) => segments.map((item) => {
    if (item.data && item.data.length > 0) {
      return (
        <>
          {renderSegmentRow(item, level)}
          {item.collapsed && renderSegments(item.data, level + 1)}
        </>
      );
    }
    return renderSegmentRow(item, level);
  });

  return (
    <div className="profile-column2">
      <h2>Segmentação</h2>
      <div className="segments-content-column2">
        <Table>
          <tbody>
            {renderSegments(categoryData.data, 0)}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

Segmentations.defaultProps = {};

Segmentations.propTypes = {
  onChange: PropTypes.func.isRequired,
  categoryData: PropTypes.shape().isRequired,
  audienceCategoryDataRequestSuccess: PropTypes.func.isRequired,
  audienceCategorySetActiveFilter: PropTypes.func.isRequired,
  categoryFilterActive: PropTypes.shape().isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  categories: state.audience.categories,
  categoryData: state.audience.categoryData,
  categoryFilterActive: state.audience.categoryFilterActive,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    audienceCategoryDataRequestSuccess: AudienceActions.audienceCategoryDataRequestSuccess,
    audienceCategorySetActiveFilter: AudienceActions.audienceCategorySetActiveFilter,
    startRequest: requestLoading,
    requestSuccess: successLoading,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Segmentations);
