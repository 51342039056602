import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Modal from '~/Components/Modal';

import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Creators as AudienceActions } from '~/redux/ducks/audience';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import {
  startRequest as loadingStartRequest,
  requestSuccess as loadingRequestSuccess,
  requestError as loadingRequestError,
} from '~/redux/ducks/loading';

import {
  Row,
  ModalContent,
  RowFooter,
  ProgressBar,
  Progress,
  ProgressLabel,
} from './styles';

class ModalUploadProgress extends Component {
  state = {
    progress: 0,
  }

  componentDidMount() {
    this.uploadFile();
  }

  uploadFile = async () => {
    const {
      audienceExternalUploadData: { file, urlUpload, audience },
      uploadFileExternalAudience,
      // errorMessage,
      problemUploadFileAudienceExternal,
      successMessage,
      startRequest,
      requestSuccess,
      requestError,
      navigateToRoute,
    } = this.props;
    const ajax = new XMLHttpRequest();
    const updateStatusProgress = (event) => {
      const percent = (event.loaded / event.total) * 100;
      this.setState({ progress: Math.round(percent) });
    };
    ajax.upload.addEventListener('progress', updateStatusProgress, false);
    ajax.upload.addEventListener('loadend', () => startRequest(), false);
    ajax.addEventListener('load', (e) => {
      const { status } = e.currentTarget;
      if (status >= 200 && status < 300) {
        requestSuccess();
        successMessage('Sucesso!', 'Audiência cadastrada com sucesso.');
        navigateToRoute(`audience/${audience.uuid}/details`);
        uploadFileExternalAudience(null);
      } else {
        requestError();
        problemUploadFileAudienceExternal();
      }
    }, false);
    ajax.addEventListener('error', () => {
      requestError();
      problemUploadFileAudienceExternal();
    }, false);
    ajax.open('PUT', urlUpload);
    ajax.setRequestHeader('content-type', 'text/csv');
    await ajax.send(file);
  };

  render() {
    const { progress } = this.state;
    return (
      <Modal width={500}>
        <Row>
          <h1>Cadastro de audiência externa</h1>
        </Row>
        <ModalContent>
          <ProgressBar>
            <Progress progress={progress} />
          </ProgressBar>
          <ProgressLabel>
            {`${progress}%`}
          </ProgressLabel>
          <RowFooter>
            {/* <Button
              title="Cancelar"
              onClick={() => {
                audienceSetExternalData({
                  ...audienceExternalData,
                  progress: null,
                });
              }}
            /> */}
          </RowFooter>
        </ModalContent>
      </Modal>
    );
  }
}

ModalUploadProgress.propTypes = {
  audienceExternalUploadData: PropTypes.shape().isRequired,
  uploadFileExternalAudience: PropTypes.func.isRequired,
  // errorMessage: PropTypes.func.isRequired,
  successMessage: PropTypes.func.isRequired,
  problemUploadFileAudienceExternal: PropTypes.func.isRequired,
  // audienceRequest: PropTypes.func.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  navigateToRoute: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  audienceExternalUploadData: state.audience.audienceExternalUploadData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    navigateToRoute: RedirectActions.navigateToRoute,
    uploadFileExternalAudience: AudienceActions.uploadFileExternalAudience,
    problemUploadFileAudienceExternal: AudienceActions.problemUploadFileAudienceExternal,
    errorMessage: ToastrMessageActions.errorMessage,
    successMessage: ToastrMessageActions.successMessage,
    startRequest: loadingStartRequest,
    requestSuccess: loadingRequestSuccess,
    requestError: loadingRequestError,
    audienceRequest: AudienceActions.audienceRequest,
    // navigateToRoute: RedirectActions.navigateToRoute,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalUploadProgress);
