import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';


import {
  FaBuffer, FaChartLine, FaCheck, FaExclamationTriangle, FaPenSquare, FaTimes,
} from 'react-icons/fa';
import { withTheme } from 'styled-components';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Modal from '~/Components/Modal';
import StatusInfo from '~/Components/StatusInfo';

import { Creators as ProductActions } from '~/redux/ducks/product';

import {
  ModalHeader, ModalContent, ButtonCloseModal, Row,
  InfoData, BaseIndicadores, InfoIndicador, EditButton,
  EditIndicator, Select,
} from './styles';
import IncludeHasPermission from '~/Components/IncludeHasPermission';

function getDescriptionEstrategy(strategy) {
  switch (strategy) {
    case 'ALTO':
      return 'Alto';
    case 'MEDIO':
      return 'Médio';
    case 'BAIXO':
      return 'Baixo';
    default:
      return '-';
  }
}

const strategyOptions = [
  { value: 'ALTO', label: getDescriptionEstrategy('ALTO') },
  { value: 'MEDIO', label: getDescriptionEstrategy('MEDIO') },
  { value: 'BAIXO', label: getDescriptionEstrategy('BAIXO') },
];

function ModalDetailProduct({
  modeEdit, theme, close, show, product,
  indicator, evaluation, auctionAlgorithm, changeStrategy,
}) {
  const [modoEditStrategy, setModoEditStrategy] = useState(false);
  const [strategyEdit, setStrategyEdit] = useState(null);

  useEffect(() => {
    if (!show && modoEditStrategy) {
      setModoEditStrategy(false);
    }
  }, [show]);

  if (!show) {
    return null;
  }

  const { type: algorithmType } = auctionAlgorithm;

  function convertValuePercent(value) {
    return String(value).replaceAll('.', ',');
  }

  function renderIndicadorDefault() {
    return (
      <Row>
        <InfoIndicador>
          <label>Fator:</label>
          <p>1</p>
        </InfoIndicador>
      </Row>
    );
  }


  function renderIndicadorBCG() {
    if (!modoEditStrategy) {
      const {
        bcg, billingRate, instantChurn, strategy,
      } = indicator;
      return (
        <Row>
          <IncludeHasPermission roles={['AUCTION_ADMIN']}>
            <InfoIndicador>
              <label>Estratégico:</label>
              <p>{strategy ? getDescriptionEstrategy(strategy) : '-'}</p>
              {modeEdit && (
                <EditButton onClick={() => {
                  const sEdit = strategy ? strategyOptions.find(s => s.value === strategy) : null;
                  setStrategyEdit(sEdit);
                  setModoEditStrategy(true);
                }}
                >
                  <FaPenSquare size={20} color={theme.colors.primary} />
                </EditButton>
              )}
            </InfoIndicador>
          </IncludeHasPermission>
          <IncludeHasPermission roles={['AUCTION_ADMIN']}>
            <InfoIndicador>
              <label>BCG:</label>
              <p>{bcg || '-'}</p>
            </InfoIndicador>
          </IncludeHasPermission>
          <InfoIndicador>
            <label>Instant Churn(%):</label>
            <p>{instantChurn ? convertValuePercent(instantChurn) : '-'}</p>
          </InfoIndicador>
          <InfoIndicador>
            <label>Billing rate(%):</label>
            <p>{billingRate ? convertValuePercent(billingRate) : '-'}</p>
          </InfoIndicador>
        </Row>
      );
    }

    return (
      <Row>
        <EditIndicator>
          <label>Estratégico:</label>
          <Select
            placeholder="Selecione..."
            value={strategyEdit}
            onChange={(item) => {
              setStrategyEdit(item);
            }}
            options={strategyOptions}
          />

          <div className="button-actions">
            <div className="cancel" onClick={() => setModoEditStrategy(false)}>
              <FaTimes size={20} color={theme.colors.danger2} />
            </div>
            <div
              className="confirm"
              onClick={() => {
                if (strategyEdit) {
                  changeStrategy(strategyEdit.value);
                  setModoEditStrategy(false);
                }
              }}
            >
              <FaCheck size={20} color={theme.colors.checked} />
            </div>
          </div>
        </EditIndicator>
      </Row>
    );
  }

  function renderAvaliacaoBCG() {
    const instantChurn = evaluation ? evaluation.instantChurn : null;
    const billingRate = evaluation ? evaluation.billingRate : null;
    const strategy = evaluation ? evaluation.strategy : null;

    return (
      <Row>
        <IncludeHasPermission roles={['AUCTION_ADMIN']}>
          <InfoIndicador>
            <label>Estratégico(%):</label>
            <p>{strategy ? convertValuePercent(strategy) : '-'}</p>
          </InfoIndicador>
        </IncludeHasPermission>
        <InfoIndicador>
          <label>Instant Churn(%):</label>
          <p>{instantChurn ? convertValuePercent(instantChurn) : '-'}</p>
        </InfoIndicador>
        <InfoIndicador>
          <label>Billing rate(%):</label>
          <p>{billingRate ? convertValuePercent(billingRate) : '-'}</p>
        </InfoIndicador>
      </Row>
    );
  }

  return (
    <Modal width={1000} maxHeight={600} scroll={false} padding={0}>
      <ModalHeader>
        <h1>Produto</h1>
        <ButtonCloseModal
          type="button"
          onClick={() => {
            close();
          }}
        >
          <FaTimes size={30} />
        </ButtonCloseModal>
      </ModalHeader>
      <ModalContent>
        <div className="base-content">
          <Row>
            <InfoData flex={2}>
              <label>Nome</label>
              <p>{product.name}</p>
            </InfoData>
            <InfoData flex={2}>
              <label>Parceiro</label>
              <p>{product.contract ? product.contract.name : ''}</p>
            </InfoData>
            <InfoData>
              <label>Status</label>
              <p><StatusInfo status={product.status === 'active'} labelTrue="Ativo" labelFalse="Inativo" /></p>
            </InfoData>
            <InfoData>
              <label>Data última alteração</label>
              <p>{moment(product.updatedAt).format('DD/MM/YYYY HH:mm')}</p>
            </InfoData>
          </Row>
          <Row>
            <InfoData>
              <label>Descrição</label>
              <p>{product.description}</p>
            </InfoData>
          </Row>
          <BaseIndicadores>
            <p className="title">
              <FaBuffer size={15} color={theme.colors.primary} />
              {(product.indicatorStatus && product.indicatorStatus.toUpperCase() !== 'OK') && (
                <FaExclamationTriangle
                  data-tip
                  data-for="btn-product-detail"
                  color="#ffc107"
                  size={20}
                />
              )}
              Indicadores
              {algorithmType === 'default' && (<span>Algoritmo ECPM: Default</span>)}
              {algorithmType === 'bcg' && (<span>Algoritmo ECPM: BCG</span>)}
            </p>
            <div className="content-indicadores">
              {algorithmType === 'default' && renderIndicadorDefault()}
              {algorithmType === 'bcg' && renderIndicadorBCG()}
            </div>
            <ReactTooltip
              className="tooltip-report"
              effect="solid"
              id="btn-product-detail"
              aria-haspopup="true"
            >
              Com pendência
            </ReactTooltip>
          </BaseIndicadores>
          {algorithmType === 'bcg' && (
          <BaseIndicadores>
            <p className="title">
              <FaChartLine size={15} color={theme.colors.primary} />
              Avaliação
            </p>
            <div className="content-indicadores">
              {renderAvaliacaoBCG()}
            </div>
          </BaseIndicadores>
          )}
        </div>
      </ModalContent>
    </Modal>
  );
}

ModalDetailProduct.defaultProps = {
  modeEdit: false,
  product: null,
  indicator: null,
  evaluation: null,
  show: false,
};
ModalDetailProduct.propTypes = {
  modeEdit: PropTypes.bool,
  theme: PropTypes.shape().isRequired,
  close: PropTypes.func.isRequired,
  changeStrategy: PropTypes.func.isRequired,
  show: PropTypes.bool,
  product: PropTypes.shape(),
  indicator: PropTypes.shape(),
  evaluation: PropTypes.shape(),
  auctionAlgorithm: PropTypes.shape().isRequired,
};

const mapStateToProps = ({
  auth: { auctionAlgorithm },
  product: {
    modalDetail: {
      show, product, indicator, evaluation,
    },
  },
}) => ({
  show, product, indicator, evaluation, auctionAlgorithm,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    close: ProductActions.closeDetailProduct,
    changeStrategy: ProductActions.changeStrategy,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(ModalDetailProduct));
