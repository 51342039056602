import api from './axios';

const products = [
  {
    uuid: 1, name: 'Product 01', contractName: 'Contrato 01', status: 'ACTIVE',
  },
  {
    uuid: 2, name: 'Product 02', contractName: 'Contrato 01', status: 'INACTIVE',
  },
  {
    uuid: 3, name: 'Product 03', contractName: 'Contrato 01', status: 'ACTIVE',
  },
];

const list = async (search) => {
  const {
    page, productName, contractName, status,
  } = search;

  let filter = '';

  if (productName) {
    filter += `&productName=${productName}`;
  }
  if (contractName) {
    filter += `&contractName=${contractName}`;
  }
  if (status) {
    filter += `&status=${status}`;
  }

  try {
    const { data } = await api.get(`product-service/product?page=${page || 0}&pageSize=30${filter}`);
    return data;
  } catch (error) {
    if (!error.response) throw error;
    throw error.response.data ? error.response.data : error.response;
  }
};

const get = async (productId) => {
  try {
    const { data } = await api.get(`product-service/product/${productId}`);
    return data;
  } catch (error) {
    if (!error.response) throw error;
    throw error.response.data ? error.response.data : error.response;
  }
};

const getIndicator = async ({ productId, typeIndicator }) => {
  try {
    const { data } = await api.get(`product-service/product/${productId}/indicator?type=${typeIndicator}`);
    if (data && data.length > 0) {
      const item = data[0];
      return item.indicators;
    }
    return null;
  } catch (error) {
    if (!error.response) throw error;
    throw error.response.data ? error.response.data : error.response;
  }
};

const updateStrategy = async ({ productId, typeIndicator, strategy }) => {
  try {
    await api.post(`product-service/product/${productId}/indicator`, {
      type: typeIndicator,
      indicators: {
        indicator: {
          strategy,
        },
      },
    });
  } catch (error) {
    if (!error.response) throw error;
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  list,
  get,
  getIndicator,
  updateStrategy,
};
