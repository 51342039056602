import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  ContainerStyled,
  RowStyled,
  InputStyled,
  TableStyled,
  FormGroupStyled,
  InputMaskStyled,
} from '~/Components/Styled';

import Button from '~/Components/Button';
import ToggleComponent from '~/Components/Toggle';

export const Container = styled(ContainerStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .header-form-box {
    flex-wrap: wrap;
    width: 100%;
  }
  .header-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1148px) {
      flex: 1;
    }
  }
  .header-button-box {
    display: flex;
    > button {
      align-self: flex-end;
    }
  }
`;

export const ActualData = styled.div`
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 2px;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 400px;
`;

export const ActualDataItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h4 {
    padding-left: 5px;
    margin-bottom: 5px;
  }

  svg {
    font-size: 25px;
    color: ${props => props.theme.colors.primary};
    margin-right: 10px;
  }

  .desc-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px 0 0;
    p {
      font-size: 13px;
      margin-bottom: -5px;
      font-weight: bold;
    }
    span {
      font-size: 18px;
      min-height: 25px;
    }
  }
`;

export const ProfileResult = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  border: 1px solid ${props => props.theme.colors.primary};
  padding: 0 10px;
  border-radius: 5px;
  max-height: 80px;
  min-height: 80px;
  margin-top: 10px;
  .item-result-channel {
    display: flex;
    align-items: center;
    h3 {
      font-size: 18px;
      color: ${props => props.theme.colors.primary};
    }
    h4 {
      font-size: 20px;
      min-width: 100px;
      text-align: right;
      padding: 0;
      margin: 0;
      padding-left: 10px;
    }
  }
  .item-result-total {
    display: flex;
    align-items: center;
    margin-top: 5px;
    h3 {
      font-size: 14px;
      color: ${props => props.theme.colors.primary};
    }
    h4 {
      font-size: 14px;
      min-width: 100px;
      text-align: right;
      padding: 0;
      margin: 0;
      padding-left: 10px;
    }
  }
`;

export const SelectChannel = styled(ReactSelect)`
  width: 150px;
  border: 0;
  margin: 0 3px 0 5px;
  [class*="-control"] {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 4px;
    ${props => props.invalid
      && css`
        border-color: ${props.theme.colors.danger2};
      `}
    outline: none;
    /* background-color: transparent; */
  }
`;

export const RowTabHeader = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 10px;
`;

export const Select = styled(ReactSelect)`
  width: 300px;
  margin-bottom: 10px;
  margin-right: 15px;
  border: 0;
  [class*="-control"] {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 4px;
    ${props => props.invalid
      && css`
        border-color: ${props.theme.colors.danger2};
      `}
    outline: none;
    /* background-color: transparent; */
  }
`;

export const Input = styled(InputStyled)`
  width: 400px;
  margin-bottom: 10px;
  height: 40px;
`;

export const Toggle = styled(ToggleComponent).attrs({
  style: {
    marginBottom: 10,
    height: 40,
    width: 150,
  },
})``;

export const InputMask = styled(InputMaskStyled)``;

export const Table = styled(TableStyled)`
  margin-top: 5px;
  tbody tr:hover {
    background-color: ${props => props.theme.colors.lighterWhite}
  }
  tbody tr td {
    padding: 5px 5px 5px 15px;
    span {
      font-size: 14px;
      color: ${props => props.theme.colors.dark};
      font-weight: 500;
      font-family: 'Simplon Oi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
    }
  }
`;

export const ProfileBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  > h1 {
    width: 100%;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    margin: 0 0 20px 0;
  }
`;

export const ButtonSelectCategory = styled.button.attrs({
  type: 'button',
})`
  background-color: transparent;
  font-size: 14px;
  border: 0;
  color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.dark)};
  outline: none;
  font-weight: 500;
`;

export const ButtonSelectSegment = styled.button.attrs({
  type: 'button',
})`
  background-color: transparent;
  font-size: 14px;
  border: 0;
  color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.dark)};
  outline: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0;
  svg {
    font-size: 18px;
    margin-left: -5px;
  }
`;

export const GeofenceContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 10px;
  height: 600px;
  overflow: hidden;
  position: relative;
`;

export const ProfileContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 10px;
  height: 600px;
  max-height: 600px;
  overflow: hidden;
  [class*='profile-'] {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    overflow: hidden;
    > h2 {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: bold;
      color: ${props => props.theme.colors.primary};
      border-bottom: 1px solid ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.primaryHex}30;
      height: 40px;
      font-size: 16px;
      padding: 10px;
    }
    h3 {
      margin: 10px 10px 0px 10px;
      width: calc(100% - 20px);
      border-bottom: 1px solid ${props => props.theme.colors.primary};
    }
  }
  .profile-column1 {
    max-width: 400px;
    .categories-content-column1 {
      display: flex;
      flex-direction: column;
      height: calc(100% - 40px);
      overflow-y: auto;
    }
  }
  .profile-column2 {
    margin: 0 10px;
    .segments-content-column2 {
      display: flex;
      flex-direction: column;
      height: calc(100% - 40px);
      overflow-y: auto;
    }
  }
  .profile-column3 {
    .filter-content-column3 {
      display: flex;
      flex-direction: column;
      height: calc(100% - 40px);
      .filter-included, .filter-excluded {
        display: flex;
        flex: 1;
        flex-direction: column;
        overflow-y: auto;
      }
    }
  }
`;

export const InsightBox = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  overflow: hidden;
  > h1 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryHex}30;
    height: 40px;
    font-size: 16px;
    padding: 10px;
    span {
      font-size: 16px;
      color: ${props => props.theme.colors.primary};
      font-weight: 500;
      font-style: italic;
    }
  }
`;

export const InsightBoxLoader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.7);
  z-index: 99999999999999;
  color: ${props => props.theme.colors.primary};
  font-size: 20px;
`;

export const InsightCalc = styled.div`
  padding: 20px 0 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .insight-calc-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    color: ${props => props.theme.colors.primary};
    width: 180px;
    height: 120px;
    h2 {
      font-size: 30px;
      display: flex;
      align-items: center;
      > svg {
        margin-right: 10px;
      }
    }
    span {
      margin-top: 5px;
      font-size: 20px;
      font-weight: 600;
    }
  }
  > svg {
    margin: 0 10px;
    font-size: 25px;
    color: ${props => props.theme.colors.primary};
  }
`;

export const InsightContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 10px 10px 0 10px;
  .insight-column1 {
    display: flex;
    flex: 1;
    .insight-block {
      width: 100%;
      margin-bottom: 10px;
      padding: 10px;
    }
  }
  .insight-column2 {
    display: flex;
    flex: 1;
    .insight-charts {
      width: 100%;
      height: fit-content;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      .insight-block {
        margin-left: 10px;
        margin-bottom: 10px;
      }
      .gender-chart {
        .apexcharts-legend {
          display: flex;
          justify-content: center;
          .apexcharts-legend-series {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 30px !important;
            &:last-child {
              margin-bottom: 0 !important;
            }
            .apexcharts-legend-marker {
              height: 22px !important;
              width: 22px !important;
              margin-right: 5px;
            }
            .apexcharts-legend-text {
              font-size: 15px !important;
            }
          }
        }
      }
    }
  }
`;

export const InsightBlock = styled.div.attrs({
  className: 'insight-block',
})`
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  overflow: hidden;
  h2 {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: bold;
    color: ${props => props.theme.colors.primary};
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryHex}30;
    height: 40px;
    font-size: 16px;
    padding: 10px;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)}px;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const FormGroupHeader = styled.div`
  display: flex;
  align-items: flex-end;
  // border: 1px solid ${props => props.theme.colors.primary};
  padding: 5px 10px;
  border-radius: 5px;
`;

export const SegmentButton = styled(Button)`
  height: 25px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  line-height: 25px;
  padding: 0 7px;
`;

export const ButtonDeleteSegment = styled.button.attrs({
  type: 'button',
})`
  margin-right: 10px;
  color: ${props => props.theme.colors.danger2};
  border: 1px solid ${props => props.theme.colors.danger2};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
`;

export const TabContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
`;

export const ContainerTabs = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;

  .react-tabs__tab {
    border-color: ${props => props.theme.colors.light};
    font-size: 22px;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.lighterWhite};
    padding: 5px 20px;
    border-right-width: 0;
    outline: none;
    &:last-child {
      border-right-width: 1px;
    }

    &.react-tabs__tab--selected {
      border-radius: 0;
      border-top: 1px solid ${props => props.theme.colors.primary};
      background-color: ${props => props.theme.colors.white};
    }
  }

  .tab-has-error {
    color: red;
    margin-left: 5px;
    font-size: 12px;
  }

  .react-tabs {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    color: ${props => props.theme.colors.primary};
    min-height: 100%;
    max-height: 100%;
    overflow: hidden;
    .react-tabs__tab-list {
      display: flex;
      min-height: 44px;
      max-height: 44px;
      margin-bottom: 0;
      border-bottom: none;
    }
    .react-tabs__tab-panel.react-tabs__tab-panel--selected{
      display: flex;
      flex: 1;
      min-height: 100%;
      max-height: 100%;
      width: 100%;
      border-radius: 0 8px 8px 8px;
      border: solid 1px ${props => props.theme.colors.light};
      align-items: flex-start;
      justify-content: flex-start;
      .tab-body {
        padding: 15px;
        flex: 1;
        display: flex;
        flex-direction: column;
        width: calc(100% - 30px);
        flex-shrink: 0;
        background-color: ${props => props.theme.colors.bgBody};
      }
    }
  }
`;
