import ChannelUtil from '~/Utils/ChannelUtil'

/* Types */
export const Types = {
  LOAD_CAMPAIGN_LIST: 'approval/LOAD_CAMPAIGN_LIST',
  LOAD_CAMPAIGN_LIST_VIEW_SUCCESS: 'approval/LOAD_CAMPAIGN_LIST_VIEW_SUCCESS',
  FILTER_CAMPAIGN: 'approval/FILTER_CAMPAIGN',
  EXPORT_FILTER_CAMPAIGN: 'approval/EXPORT_FILTER_CAMPAIGN',
  FILTER_CAMPAIGN_SUCCESS: 'approval/FILTER_CAMPAIGN_SUCCESS',
  FILTER_CAMPAIGN_ERROR: 'approval/FILTER_CAMPAIGN_ERROR',
  UPDATE_DATA_SEARCH: 'approval/UPDATE_DATA_SEARCH',
  LOAD_CAMPAIGN_APPROVAL: 'approval/LOAD_CAMPAIGN_APPROVAL',
  LOAD_CAMPAIGN_APPROVAL_SUCCESS: 'approval/LOAD_CAMPAIGN_APPROVAL_SUCCESS',
  APPROVED_CAMPAIGN: 'approval/APPROVED_CAMPAIGN',
  DEMONSTRATION_CAMPAIGN: 'approval/DEMONSTRATION_CAMPAIGN',
  RUN_DEMONSTRATION_CAMPAIGN: 'approval/RUN_DEMONSTRATION_CAMPAIGN',
  REJECT_CAMPAIGN: 'approval/REJECT_CAMPAIGN',
  SHOW_MODAL_APPROVED_CAMPAIGN: 'approval/SHOW_MODAL_APPROVED_CAMPAIGN',
  CLOSE_MODAL_APPROVED_CAMPAIGN: 'approval/CLOSE_MODAL_APPROVED_CAMPAIGN',
  SET_CAMPAIGN_TRANSITION_STATE: 'approval/SET_CAMPAIGN_TRANSITION_STATE',
  WORKFLOW_STATUS: 'approval/WORKFLOW_STATUS',
};


const INITIAL_STATE = {
  data: {
    content: [],
    page: 0,
    pageSize: 0,
    totalElements: 0,
  },
  search: {
    product: null,
    channel: null,
    type: null,
    contract: null,
    name: '',
    keyword: '',
    status: null,
  },
  status: [
    { id: '0', name: 'Aguardando aprovação' },
    { id: '1', name: 'Aprovado' },
    { id: '2', name: 'Rejeitado' },
  ],
  types: [],
  view: {
    campaign: null,
    action: null,
    showModalApprovedCampaign: false,
  },
  transition: null,
};

export default function approval(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.FILTER_CAMPAIGN_SUCCESS:
      return { ...state, data: action.payload };
    case Types.LOAD_CAMPAIGN_LIST_VIEW_SUCCESS:
      return {
        ...state,
        products: action.payload.products ? action.payload.products.map(item => ({
          value: item.uuid,
          label: item.name,
        })) : [],
        productsRaw: action.payload.products,
        channels: ChannelUtil.mapChannelsForSelect(action.payload.channels),
        contracts: action.payload.contracts.map(item => ({
          value: item.uuid,
          label: item.name,
        })),
        types: action.payload.types.map(item => ({
          value: item.uuid,
          label: item.label,
        })),
        statusList: action.payload.statusList.map(item => ({
          value: item.uuid,
          label: item.label,
          name: item.name,
        })),
        status: action.payload.status,
      };
    case Types.FILTER_CAMPAIGN_ERROR:
      return {
        ...state,
        data: [],
        error: action.payload,
      };
    case Types.UPDATE_DATA_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          ...action.payload,
        },
      };
    case Types.LOAD_CAMPAIGN_APPROVAL:
      return {
        ...state,
        view: {
          campaign: null,
          action: null,
        },
      };
    case Types.LOAD_CAMPAIGN_APPROVAL_SUCCESS:
      return {
        ...state,
        view: {
          campaign: action.payload.campaign,
          action: action.payload.action,
          showModalApprovedCampaign: false,
        },
      };
    case Types.SHOW_MODAL_APPROVED_CAMPAIGN:
      return {
        ...state,
        view: {
          ...state.view,
          showModalApprovedCampaign: true,
        },
      };
    case Types.CLOSE_MODAL_APPROVED_CAMPAIGN:
      return {
        ...state,
        view: {
          ...state.view,
          showModalApprovedCampaign: false,
        },
      };
    case Types.SET_CAMPAIGN_TRANSITION_STATE:
      return {
        ...state,
        transition: action.payload,
      };
    default:
      return state;
  }
}

/* Actions Creators */
export const Creators = {
  loadCampaignList: () => ({
    type: Types.LOAD_CAMPAIGN_LIST,
  }),
  loadCampaignListViewSuccess: result => ({
    type: Types.LOAD_CAMPAIGN_LIST_VIEW_SUCCESS,
    payload: result,
  }),
  filterCampaign: () => ({
    type: Types.FILTER_CAMPAIGN,
  }),
  exportFilterCampaign: () => ({
    type: Types.EXPORT_FILTER_CAMPAIGN,
  }),
  filterCampaignSuccess: result => ({
    type: Types.FILTER_CAMPAIGN_SUCCESS,
    payload: result,
  }),
  filterCampaignError: error => ({
    type: Types.FILTER_CAMPAIGN_ERROR,
    payload: error,
  }),
  updateDataSearch: search => ({
    type: Types.UPDATE_DATA_SEARCH,
    payload: search,
  }),
  loadCampaignApproval: (campaignId, action) => ({
    type: Types.LOAD_CAMPAIGN_APPROVAL,
    payload: {
      campaignId,
      action,
    },
  }),
  loadCampaignApprovalSuccess: result => ({
    type: Types.LOAD_CAMPAIGN_APPROVAL_SUCCESS,
    payload: result,
  }),
  approvedCampaign: () => ({
    type: Types.APPROVED_CAMPAIGN,
  }),
  demonstrationCampaign: () => ({
    type: Types.DEMONSTRATION_CAMPAIGN,
  }),
  runDemonstrationCampaign: () => ({
    type: Types.RUN_DEMONSTRATION_CAMPAIGN,
  }),
  rejectCampaign: reason => ({
    type: Types.REJECT_CAMPAIGN,
    payload: reason,
  }),
  showModalApprovedCampaign: () => ({
    type: Types.SHOW_MODAL_APPROVED_CAMPAIGN,
  }),
  closeModalApprovedCampaign: () => ({
    type: Types.CLOSE_MODAL_APPROVED_CAMPAIGN,
  }),
  setCampaignTransitionState: transition => ({
    type: Types.SET_CAMPAIGN_TRANSITION_STATE,
    payload: transition,
  }),
  workflowStatus: () => ({
    type: Types.WORKFLOW_STATUS,
  }),
};
