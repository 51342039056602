import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import DatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt-BR';

import Switch from 'react-switch';

import { Creators as BehaviorActions } from '~/redux/ducks/behavior';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import Breadcrumb from '~/Components/Breadcrumb';

import ConfigsBase from './components/ConfigsBase';
import EditConfig from './components/EditConfig';

import {
  Container, Row, Button, Title, FormHeader, FormItem, Input,
  HeaderConfig, ButtonModeView, Config,
} from './styles';

registerLocale('pt-BR', pt);

function Crud({
  match: { params }, openCrudBehavior, behavior,
  config, onUpdateBehavior, onSaveBehavior,
  onUpdateConfig, itemSelectedConfig, setItemSelectedConfig,
  addCategoryItemSelectedConfig, removeCategoryItemSelectedConfig,
  errorMessage,
}) {
  // const [config, setConfig] = useState(data);
  const [modeView, setModeView] = useState('first');
  const [dataTree, setDataTree] = useState([]);

  useEffect(() => {
    setDataTree(config ? config.data : []);
  }, [config]);

  useEffect(() => {
    const { behaviorUuid } = params;
    openCrudBehavior(behaviorUuid);
  }, []);

  const validData = (d, item, valid = false) => {
    d.forEach((i) => {
      if (!valid && i.id === item.id) {
        i.show = !i.show;
        valid = true;
      } else if (!valid && i.data && i.data.length) {
        validData(i.data, item, valid);
      }
    });
    return d;
  };

  const changeShowItem = (item) => {
    const configUpdate = {
      ...config,
      data: [...validData(config.data, item)],
    };
    onUpdateConfig(configUpdate);
  };

  if (!behavior) {
    return null;
  }

  return (
    <Container>
      <Row>
        <Breadcrumb
          items={[
            {
              route: '/',
              name: 'Home',
            },
            {
              route: '/behaviors',
              name: 'Comportamentos',
            },
            {
              name: 'Cadastro',
            },
          ]}
        />
      </Row>
      <Row>
        <Title>EDITAR COMPORTAMENTO</Title>
        <Button title="Salvar" onClick={() => onSaveBehavior()} />
      </Row>
      <Row>
        <FormHeader>
          <Row>
            <FormItem flex={5}>
              <label>Nome:</label>
              <Input
                value={behavior.name}
                placeholder="Nome do comportamento"
                onChange={e => onUpdateBehavior({
                  ...behavior, name: e.target.value,
                })}
              />
            </FormItem>
            <FormItem flex={4}>
              <label>Período:</label>
              <div className="base-periodo">
                <span>Inicial:</span>
                <DatePicker
                  selected={behavior.startDate ? moment(behavior.startDate, 'YYYY-MM-DD').toDate() : null}
                  onChange={startDateUpdate => onUpdateBehavior({
                    ...behavior, startDate: startDateUpdate ? moment(startDateUpdate).format('YYYY-MM-DD') : null,
                  })}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                  maxDate={behavior.endDate ? moment(behavior.endDate, 'YYYY-MM-DD').toDate() : null}
                />
                <span>Final:</span>
                <DatePicker
                  selected={behavior.endDate ? moment(behavior.endDate, 'YYYY-MM-DD').toDate() : null}
                  onChange={endDateUpdate => onUpdateBehavior({
                    ...behavior, endDate: endDateUpdate ? moment(endDateUpdate).format('YYYY-MM-DD') : null,
                  })}
                  dateFormat="dd/MM/yyyy"
                  locale="pt-BR"
                  minDate={behavior.startDate ? moment(behavior.startDate, 'YYYY-MM-DD').toDate() : null}
                />
              </div>
            </FormItem>
            <FormItem flex={1}>
              <label>Habilitado?</label>
              <div className="base-item">
                <Switch
                  checked={behavior.enabled}
                  onChange={() => onUpdateBehavior({
                    ...behavior, enabled: !behavior.enabled,
                  })}
                />
              </div>
            </FormItem>
          </Row>
          <Row>
            <FormItem>
              <label>Descrição:</label>
              <Input
                value={behavior.description}
                placeholder="Descrição"
                onChange={e => onUpdateBehavior({
                  ...behavior, description: e.target.value,
                })}
              />
            </FormItem>
          </Row>
        </FormHeader>
      </Row>
      <Row>
        <HeaderConfig>
          <h1>Configuração do Comportamento</h1>
          <div className="base-mode-buttons-view">
            <ButtonModeView
              selected={modeView === 'first'}
              onClick={() => {
                setModeView('first');
              }}
            >
              <div className="item-first" />
              <div className="item-second" />
            </ButtonModeView>
            <ButtonModeView
              selected={modeView === 'second'}
              onClick={() => {
                setItemSelectedConfig(null);
                setModeView('second');
              }}
            >
              <div className="item-first" />
            </ButtonModeView>
          </div>
        </HeaderConfig>
      </Row>
      <Row>
        <Config>
          <ConfigsBase data={dataTree} itemSelectedId={itemSelectedConfig ? itemSelectedConfig.id : null} setItemSelected={i => setItemSelectedConfig(i)} />
          <EditConfig
            modeView={modeView}
            closeConfig={() => setItemSelectedConfig(null)}
            addCategory={categoryValue => addCategoryItemSelectedConfig(categoryValue)}
            removeCategory={category => removeCategoryItemSelectedConfig(category)}
            data={itemSelectedConfig}
            changeShowItem={changeShowItem}
            errorMessage={errorMessage}
          />
        </Config>
      </Row>
    </Container>
  );
}

Crud.defaultProps = {
  behavior: null,
  config: null,
  itemSelectedConfig: null,
};

Crud.propTypes = {
  behavior: PropTypes.shape(),
  itemSelectedConfig: PropTypes.shape(),
  config: PropTypes.shape(),
  match: PropTypes.shape().isRequired,
  openCrudBehavior: PropTypes.func.isRequired,
  onUpdateBehavior: PropTypes.func.isRequired,
  onSaveBehavior: PropTypes.func.isRequired,
  onUpdateConfig: PropTypes.func.isRequired,
  setItemSelectedConfig: PropTypes.func.isRequired,
  addCategoryItemSelectedConfig: PropTypes.func.isRequired,
  removeCategoryItemSelectedConfig: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
};

const mapStateToProps = ({ behavior: { crud: { behavior, config, itemSelectedConfig } } }) => ({
  behavior,
  config,
  itemSelectedConfig,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    openCrudBehavior: BehaviorActions.openCrudBehavior,
    onUpdateBehavior: BehaviorActions.onUpdateBehaviorCrud,
    onSaveBehavior: BehaviorActions.onSaveBehaviorCrud,
    onUpdateConfig: BehaviorActions.onUpdateConfigCrud,
    setItemSelectedConfig: BehaviorActions.setItemSelectedConfigCrud,
    addCategoryItemSelectedConfig: BehaviorActions.addCategoryItemSelectedConfigCrud,
    removeCategoryItemSelectedConfig: BehaviorActions.removeCategoryItemSelectedConfigCrud,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(Crud);
