import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup } from '../../styles';

import InputCreativeAdvanced from '../../Components/InputCreativeAdvanced';

const USSDMessageTextForm = ({
  criativoEdit,
  campaignEditCriativoRequestSuccess,
  formConfig,
  hasError,
  disabledFields,
}) => {
  const { content, contentVersion } = criativoEdit;
  const getScreen1Validation = () => {
    if (formConfig.screen1.required && !content.screen1) {
      return true;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return true;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return true;
    }
    return false;
  };
  const getScreen2Validation = () => {
    if (formConfig.screen2.required && !content.screen2) {
      return true;
    }
    if ((content.screen2 || '').length < formConfig.screen2.minLength) {
      return true;
    }
    if ((content.screen2 || '').length > formConfig.screen2.maxLength) {
      return true;
    }
    return false;
  };

  const getMaxLengthInput = (screenId) => {
    const { maxLength, valueSuffix, valuePrefix } = formConfig[screenId];
    if (contentVersion > 1) {
      return maxLength
        - ((valueSuffix ? valueSuffix.length : 0) + (valuePrefix ? valuePrefix.length : 0));
    }
    return maxLength;
  };

  const getLengthValue = (screenId) => {
    const value = content[screenId];
    if (contentVersion > 1) {
      const { valueSuffix, valuePrefix } = formConfig[screenId];
      return (
        (value ? value.length : 0)
        + (valueSuffix ? valueSuffix.length : 0)
        + (valuePrefix ? valuePrefix.length : 0)
      );
    }
    return value ? value.length : 0;
  };

  return (
    <>
      <FormGroup>
        <label>
          {formConfig.screen1.label}
          {' - '}
          <small>
            {`
              ${getLengthValue('screen1')}
              /
              ${formConfig.screen1.maxLength} caracteres
            `}
          </small>
        </label>
        <InputCreativeAdvanced
          disabled={disabledFields || formConfig.screen1.disabled}
          maxLength={getMaxLengthInput('screen1')}
          type="text"
          style={{ marginRight: 0 }}
          contentVersion={contentVersion}
          placeholder={formConfig.screen1.placeholder}
          valueSuffix={formConfig.screen1.valueSuffix}
          valuePrefix={formConfig.screen1.valuePrefix}
          value={formConfig.screen1.disabled ? formConfig.screen1.value : content.screen1}
          onChange={(e) => {
            campaignEditCriativoRequestSuccess({
              ...criativoEdit,
              content: {
                ...criativoEdit.content,
                screen1: e.target.value,
              },
            });
          }}
          invalid={hasError && getScreen1Validation()}
        />
      </FormGroup>
      <FormGroup>
        <label>
          {formConfig.screen2.label}
          {' - '}
          <small>
            {`
              ${getLengthValue('screen2')}
              /
              ${formConfig.screen2.maxLength} caracteres
            `}
          </small>
        </label>
        <InputCreativeAdvanced
          disabled={disabledFields || formConfig.screen2.disabled}
          maxLength={getMaxLengthInput('screen2')}
          type="text"
          style={{ marginRight: 0 }}
          contentVersion={contentVersion}
          placeholder={formConfig.screen2.placeholder}
          valueSuffix={formConfig.screen2.valueSuffix}
          valuePrefix={formConfig.screen2.valuePrefix}
          value={formConfig.screen2.disabled ? formConfig.screen2.value : content.screen2}
          onChange={(e) => {
            campaignEditCriativoRequestSuccess({
              ...criativoEdit,
              content: {
                ...criativoEdit.content,
                screen2: e.target.value,
              },
            });
          }}
          invalid={hasError && getScreen2Validation()}
        />
      </FormGroup>
    </>
  );
};

USSDMessageTextForm.propTypes = {
  criativoEdit: PropTypes.shape().isRequired,
  formConfig: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

export default USSDMessageTextForm;
