import React from 'react';
import PropTypes from 'prop-types';

import * as FaIcon from 'react-icons/fa';

import { Container } from './styles';

const Button = ({
  title,
  bordered,
  color,
  small,
  style,
  onClick,
  disabled,
  icon,
  noLabel,
  className,
  iconSize,
  ...rest
}) => {
  const Icon = icon ? FaIcon[icon] : null;
  return (
    <Container
      small={small}
      onClick={onClick}
      bordered={bordered}
      color={color}
      style={style}
      disabled={disabled}
      noLabel={noLabel}
      className={className}
      {...rest}
    >
      {title}
      {Icon && <Icon size={iconSize} style={title.length === 0 ? { margin: '0 0 -3px 0' } : {}} />}
    </Container>
  );
};

Button.defaultProps = {
  title: '',
  style: null,
  disabled: false,
  color: null,
  bordered: false,
  small: false,
  onClick: null,
  icon: null,
  noLabel: false,
  className: null,
  iconSize: null,
};

Button.propTypes = {
  title: PropTypes.string,
  bordered: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.shape(),
  disabled: PropTypes.bool,
  small: PropTypes.bool,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  noLabel: PropTypes.bool,
  className: PropTypes.string,
  iconSize: PropTypes.number,
};

export default Button;
