import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  financialSetup: null,
  financialDocumentsRequestSuccess: ['documents'],
  financialProvidersRequestSuccess: ['providers'],
  financialPartnersRequestSuccess: ['partners'],
  financialArchivesRequest: null,
  financialArchivesRequestSuccess: ['archives'],
  financialArchiveDownload: ['file'],
  setModalInvoiceData: ['modalInvoiceData'],
  financialInvoiceSave: null,
  setFinancialFilter: ['filter'],
  setFinancialFilterArchives: ['filterArchives'],
  financialDocumentArchivesDelete: ['uuid'],
  financialDocumentArchiveDelete: ['uuid'],
  financialModalReceiptDataRequest: ['uuid', 'provider'],
  financialInvoiceReceiptSave: null,
});

export const FinancialTypes = Types;
export default Creators;

export const INITIAL_STATUS_FILTER = {
  year: null,
  month: null,
  partnerUuid: null,
  page: 0,
};
export const INITIAL_STATUS_FILTER_ARCHIVES = {
  year: null,
  month: null,
  partnerUuid: null,
};

const INITIAL_STATE = Immutable({
  documents: null,
  providers: [],
  partners: [],
  archives: [],
  filter: INITIAL_STATUS_FILTER,
  filterArchives: INITIAL_STATUS_FILTER_ARCHIVES,
  modalInvoiceData: null,
});

/* Reducers */
const financialDocumentsRequestSuccess = (state, { documents }) => ({
  ...state,
  documents,
});
const financialProvidersRequestSuccess = (state, { providers }) => ({
  ...state,
  providers,
});
const financialPartnersRequestSuccess = (state, { partners }) => ({
  ...state,
  partners,
});
const financialArchivesRequestSuccess = (state, { archives }) => ({
  ...state,
  archives,
});
const setModalInvoiceData = (state, { modalInvoiceData }) => ({
  ...state,
  modalInvoiceData,
});
const setFinancialFilter = (state, { filter }) => ({
  ...state,
  filter,
});
const setFinancialFilterArchives = (state, { filterArchives }) => ({
  ...state,
  filterArchives,
});

export const reducer = createReducer(INITIAL_STATE, {
  [FinancialTypes.FINANCIAL_DOCUMENTS_REQUEST_SUCCESS]: financialDocumentsRequestSuccess,
  [FinancialTypes.FINANCIAL_PROVIDERS_REQUEST_SUCCESS]: financialProvidersRequestSuccess,
  [FinancialTypes.FINANCIAL_PARTNERS_REQUEST_SUCCESS]: financialPartnersRequestSuccess,
  [FinancialTypes.FINANCIAL_ARCHIVES_REQUEST_SUCCESS]: financialArchivesRequestSuccess,
  [FinancialTypes.SET_MODAL_INVOICE_DATA]: setModalInvoiceData,
  [FinancialTypes.SET_FINANCIAL_FILTER]: setFinancialFilter,
  [FinancialTypes.SET_FINANCIAL_FILTER_ARCHIVES]: setFinancialFilterArchives,
});
