/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable comma-dangle */
import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Creators */
export const { Types, Creators } = createActions({
  loadAdList: null,
  createAd: null,
  editAd: null,
  deleteAd: ["setupId", "detailId"],
  loadEditAd: ["setupId", "detailId"],
  loadEditAdSuccess: ["detail"],
  loadAdListSuccess: ["adList"],
  setEventFilter: ["eventFilter"],
  setChannelFilter: ["channelFilter"],
  setPartnerFilter: ["partnerFilter"],
  changeModalNewAd: ["status"],
  changeModalEditAd: ["status"],
  changeStateNewAd: ["adState"],
  changeStateEditAd: ["adState"],
  addNewFieldPropertyOnNewAd: null,
  addNewFieldPropertyOnEditAd: null,
  deleteFieldPropertyOnNewAd: ["pos"],
  deleteFieldPropertyOnEditAd: ["pos"],
  changePropertyStateNewAd: ["pos", "propertyState"],
  changePropertyStateEditAd: ["pos", "propertyState"],
  // placement-setting
  setupEnableDisable: ["setupId"],
  setupEnableToEdit: ["setupId"],
  setupDisableToEdit: null,
  setupSaveEditing: null,
  setupEnableToEditSuccess: ["id", "audience", "printingSecs", "retrySecs"],
  changeStateEditingSetup: ["setupState"],
  changeDetailPositionInSetup: ["setupId", "detailId", "newPosition"],
  setupEnableOrdering: ["setupId"],
  setupDisableOrdering: null,
  setupSaveOrdering: null
});

export const AdTypes = Types;
export default Creators;

const INITIAL_STATE_AD_NEW = {
  color: "",
  enabled: false,
  label: "",
  partner: null,
  event: null,
  channel: null,
  properties: []
};
const INITIAL_STATE_SETUP_EDITING = {
  id: null,
  audience: null,
  printingSecs: null,
  retrySecs: null
};

/* Initial State */
const INITIAL_STATE = Immutable({
  adList: [],
  adNew: INITIAL_STATE_AD_NEW,
  adEdit: null,
  eventFilter: null,
  channelFilter: null,
  partnerFilter: null,
  showModalNewAd: false,
  showModalEditAd: false,
  setupEditing: INITIAL_STATE_SETUP_EDITING,
  setupOrdering: null
});

/* Reducers */
const loadAdListSuccess = (state, { adList }) => ({
  ...state,
  adList
});
const setupEnableToEditSuccess = (
  state,
  { id, audience, printingSecs, retrySecs }
) => ({
  ...state,
  setupEditing: {
    id,
    audience,
    printingSecs,
    retrySecs
  }
});
const setupEnableOrdering = (state, { setupId }) => ({
  ...state,
  setupOrdering: setupId
});
const setupDisableOrdering = state => ({
  ...state,
  setupOrdering: null
});
const changeStateEditingSetup = (state, { setupState }) => ({
  ...state,
  setupEditing: {
    ...state.setupEditing,
    ...setupState
  }
});
const setupDisableToEdit = state => ({
  ...state,
  setupEditing: INITIAL_STATE_SETUP_EDITING
});
const loadEditAdSuccess = (state, { detail }) => ({
  ...state,
  showModalEditAd: true,
  adEdit: detail
});
const setEventFilter = (state, { eventFilter }) => ({
  ...state,
  eventFilter
});
const setChannelFilter = (state, { channelFilter }) => ({
  ...state,
  channelFilter
});
const setPartnerFilter = (state, { partnerFilter }) => ({
  ...state,
  partnerFilter
});
const changeModalNewAd = (state, { status }) => ({
  ...state,
  showModalNewAd: status,
  adNew: INITIAL_STATE_AD_NEW
});
const changeModalEditAd = (state, { status }) => ({
  ...state,
  showModalEditAd: status,
  adEdit: !status ? null : state.adEdit
});
const changeStateNewAd = (state, { adState }) => ({
  ...state,
  adNew: {
    ...state.adNew,
    ...adState
  }
});
const changeStateEditAd = (state, { adState }) => ({
  ...state,
  adEdit: {
    ...state.adEdit,
    ...adState
  }
});
const addNewFieldPropertyOnNewAd = state => ({
  ...state,
  adNew: {
    ...state.adNew,
    properties: [
      ...state.adNew.properties,
      {
        key: "",
        value: ""
      }
    ]
  }
});
const addNewFieldPropertyOnEditAd = state => ({
  ...state,
  adEdit: {
    ...state.adEdit,
    properties: [
      ...state.adEdit.properties,
      {
        key: "",
        value: ""
      }
    ]
  }
});
const deleteFieldPropertyOnNewAd = (state, { pos }) => ({
  ...state,
  adNew: {
    ...state.adNew,
    properties: state.adNew.properties.filter((item, index) => index !== pos)
  }
});
const deleteFieldPropertyOnEditAd = (state, { pos }) => ({
  ...state,
  adEdit: {
    ...state.adEdit,
    properties: state.adEdit.properties.filter((item, index) => index !== pos)
  }
});
const changePropertyStateNewAd = (state, { pos, propertyState }) => ({
  ...state,
  adNew: {
    ...state.adNew,
    properties: state.adNew.properties.map((item, index) => {
      if (index === pos) {
        return {
          ...item,
          ...propertyState
        };
      }
      return item;
    })
  }
});
const changePropertyStateEditAd = (state, { pos, propertyState }) => ({
  ...state,
  adEdit: {
    ...state.adEdit,
    properties: state.adEdit.properties.map((item, index) => {
      if (index === pos) {
        return {
          ...item,
          ...propertyState
        };
      }
      return item;
    })
  }
});

export const reducer = createReducer(INITIAL_STATE, {
  [AdTypes.LOAD_AD_LIST_SUCCESS]: loadAdListSuccess,
  [AdTypes.SETUP_ENABLE_TO_EDIT_SUCCESS]: setupEnableToEditSuccess,
  [AdTypes.SETUP_ENABLE_ORDERING]: setupEnableOrdering,
  [AdTypes.SETUP_DISABLE_ORDERING]: setupDisableOrdering,
  [AdTypes.CHANGE_STATE_EDITING_SETUP]: changeStateEditingSetup,
  [AdTypes.SETUP_DISABLE_TO_EDIT]: setupDisableToEdit,
  [AdTypes.LOAD_EDIT_AD_SUCCESS]: loadEditAdSuccess,
  [AdTypes.SET_EVENT_FILTER]: setEventFilter,
  [AdTypes.SET_CHANNEL_FILTER]: setChannelFilter,
  [AdTypes.SET_PARTNER_FILTER]: setPartnerFilter,
  [AdTypes.CHANGE_MODAL_NEW_AD]: changeModalNewAd,
  [AdTypes.CHANGE_MODAL_EDIT_AD]: changeModalEditAd,
  [AdTypes.CHANGE_STATE_NEW_AD]: changeStateNewAd,
  [AdTypes.CHANGE_STATE_EDIT_AD]: changeStateEditAd,
  [AdTypes.ADD_NEW_FIELD_PROPERTY_ON_NEW_AD]: addNewFieldPropertyOnNewAd,
  [AdTypes.ADD_NEW_FIELD_PROPERTY_ON_EDIT_AD]: addNewFieldPropertyOnEditAd,
  [AdTypes.DELETE_FIELD_PROPERTY_ON_NEW_AD]: deleteFieldPropertyOnNewAd,
  [AdTypes.DELETE_FIELD_PROPERTY_ON_EDIT_AD]: deleteFieldPropertyOnEditAd,
  [AdTypes.CHANGE_PROPERTY_STATE_NEW_AD]: changePropertyStateNewAd,
  [AdTypes.CHANGE_PROPERTY_STATE_EDIT_AD]: changePropertyStateEditAd
});
