import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import Pagination from '~/Components/Pagination';
import Modal from '~/Components/Modal';
import Button from '~/Components/Button';
import ButtonIcon from '~/Components/ButtonIcon';
import CampaignIcon from '~/Components/CampaignIcon';
import ChannelUtil from '~/Utils/ChannelUtil';

import { Creators as CampaignActions } from '~/redux/ducks/campaign';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';
import { Creators as CampaignIconActions } from '~/redux/ducks/campaignIcon';

import NewCampaign from '../New';

import {
  Container,
  Table,
  Row,
  Input,
  LinkStyled,
  FormGroup,
  Select,
  RowTypesCampaign,
  ButtonGroup,
  LinkButton,
} from './styles';

const INIT_NEW_CAMPAIGN = {
  name: '',
  campaignTypeId: null,
};

class CampanhaList extends Component {
  state = {
    newCampaign: INIT_NEW_CAMPAIGN,
    validateForm: false,
    showModal: false,
  };

  uuidTenant = localStorage.getItem('uuidTenant');

  static propTypes = {
    loadCampaignList: PropTypes.func.isRequired,
    createCampaign: PropTypes.func.isRequired,
    onFilter: PropTypes.func.isRequired,
    deleteCampaign: PropTypes.func.isRequired,
    cloneCampaign: PropTypes.func.isRequired,
    setFilterCampaign: PropTypes.func.isRequired,
    onExportFilter: PropTypes.func.isRequired,
    campaigns: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    products: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    channels: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    types: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    status: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    campaignFilter: PropTypes.shape().isRequired,
    totalPages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    contractId: PropTypes.string.isRequired,
    openModalNewCampaign: PropTypes.func.isRequired,
    confirmModal: PropTypes.func.isRequired,
    campaignIconLoadCampaignTypes: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadCampaignList, campaignIconLoadCampaignTypes } = this.props;
    campaignIconLoadCampaignTypes();
    loadCampaignList();
  }

  novaCampanha = () => {
    const { newCampaign } = this.state;
    const { createCampaign, contractId } = this.props;
    createCampaign({
      ...newCampaign,
      contractId,
    });
    this.setState({ showModal: false });
  };

  handleExcluirCampanha = (campaign) => {
    const { confirmModal, deleteCampaign } = this.props;
    confirmModal(
      'Deseja excluir a campanha?',
      `${campaign.name}`,
      () => {
        deleteCampaign(campaign.uuid);
      },
    );
  };

  formatDateTime = (date, hour) => {
    if (!date || !hour) {
      return '';
    }
    const value = `${date.split('T')[0]} ${hour}`;
    return value;
  };

  onChangePage = (page) => {
    const { setFilterCampaign, onFilter } = this.props;
    setFilterCampaign({ page });
    onFilter();
  };

  renderModal = () => {
    const { newCampaign, validateForm } = this.state;
    return (
      <Modal width={750}>
        <Row>
          <h1>Nova Campanha</h1>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, marginRight: 0 }}>
            <label>Nome da Campanha</label>
            <Input
              maxLength="255"
              style={{ marginRight: 0 }}
              placeholder="Digite o nome da campanha"
              value={newCampaign.name}
              invalid={validateForm && !newCampaign.name}
              onChange={e => this.setState({
                newCampaign: { ...newCampaign, name: e.target.value },
              })
              }
            />
          </FormGroup>
        </Row>
        <RowTypesCampaign invalid={validateForm && !newCampaign.campaignTypeId}>
          <ButtonIcon
            title="SMS"
            name="rdCampaign"
            img="sms.png"
            checked={newCampaign.campaignTypeId === 3}
            onClick={() => this.setState({
              newCampaign: { ...newCampaign, campaignTypeId: 3 },
            })
            }
          />
          <ButtonIcon
            title="Notificação Patrocinada"
            checked={newCampaign.campaignTypeId === 2}
            name="rdCampaign"
            img="notificacao-patrocinada.png"
            onClick={() => this.setState({
              newCampaign: { ...newCampaign, campaignTypeId: 2 },
            })
            }
          />
          <ButtonIcon
            title="Notificação Push"
            name="rdCampaign"
            checked={newCampaign.campaignTypeId === 1}
            img="push-notification.png"
            onClick={() => this.setState({
              newCampaign: { ...newCampaign, campaignTypeId: 1 },
            })
            }
          />
          <ButtonIcon
            title="Rewarded"
            name="rdCampaign"
            checked={newCampaign.campaignTypeId === 4}
            img="rewarded.png"
            onClick={() => this.setState({
              newCampaign: { ...newCampaign, campaignTypeId: 4 },
            })
            }
          />
          <ButtonIcon
            title="Survey"
            name="rdCampaign"
            checked={newCampaign.campaignTypeId === 5}
            img="survey.png"
            onClick={() => this.setState({
              newCampaign: { ...newCampaign, campaignTypeId: 5 },
            })
            }
          />
        </RowTypesCampaign>
        <Row style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
          <Button
            style={{ marginLeft: 0 }}
            color="light"
            title="CANCELAR"
            onClick={() => this.setState({ showModal: false })}
          />
          <Button
            title="CRIAR CAMPANHA"
            onClick={() => {
              if (!newCampaign.name || !newCampaign.campaignTypeId) {
                this.setState({ validateForm: true });
              } else {
                this.novaCampanha();
              }
            }}
          />
        </Row>
      </Modal>
    );
  };

  renderRowCampaign = (campaign) => {
    const { channels } = this.props;
    const channel = ChannelUtil.findChannelByZone(channels, campaign.zone);

    return (
      <tr key={campaign.uuid}>
        <td>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CampaignIcon
              name={campaign.campaignType.name}
            />
          </div>
        </td>
        <td className="link">
          <LinkStyled to={`campaigns/edit/${campaign.uuid}`}>
            {campaign.name}
          </LinkStyled>
        </td>
        {this.handleDisplayProductOffer() && (
          <td>
            {campaign.productOffers.map(item => (
              <span key={item.uuid}>
                {`${item.product.name} - ${item.name}`}
                <br />
              </span>
            ))}
          </td>
        )}
        {this.handleDisplayKeyword() && (
          <td>
            {campaign.productOffers.map(item => (
              <span key={item.CampaignProductOffer.uuid}>
                {`${item.CampaignProductOffer.keyword}`}
                <br />
              </span>
            ))}
          </td>
        )}
        <td>
          <span>
            {channel ? channel.label : ''}
          </span>
        </td>
        <td>
          <span>{moment(campaign.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
        </td>
        <td>
          <span>{campaign.campaignStatus.label}</span>
        </td>
        <td>

          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              bordered
              title="EXCLUIR"
              onClick={() => this.handleExcluirCampanha(campaign)}
            />
          </div>
        </td>
      </tr>
    );
  };

  renderCampaigns = () => {
    const { campaigns } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th style={{ width: 60 }}>TIPO</th>
            <th>NOME</th>
            {this.handleDisplayProductOffer() && (
              <th style={{ width: 200 }}>PRODUTO - OFERTA</th>
            )}
            {this.handleDisplayKeyword() && (
              <th style={{ width: 180 }}>KEYWORD</th>
            )}
            <th style={{ width: 200 }}>CANAL</th>
            <th style={{ width: 200 }}>ÚLTIMA ALTERAÇÃO</th>
            <th style={{ width: 180 }}>STATUS</th>
            <th style={{ width: 250 }} />
          </tr>
        </thead>
        <tbody>
          {campaigns.length < 1 && (
            <tr>
              <td colSpan="8">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de campanhas</span>
                </div>
              </td>
            </tr>
          )}
          {campaigns.length > 0
            && campaigns.map(campaign => this.renderRowCampaign(campaign))}
        </tbody>
      </Table>
    );
  };

  onResetFilter = () => {
    const { setFilterCampaign } = this.props;
    setFilterCampaign({
      product: null,
      channel: null,
      type: null,
      status: null,
      name: '',
    });
    this.onChangePage(0);
  };

  handleDisplayKeyword() {
    return this.uuidTenant === '46a8b5fc-6636-40c8-821f-15b34b739519'
      || this.uuidTenant === 'd2b07c25-09b0-43ad-81d7-cb0588c566c8'
      || this.uuidTenant === '263cbc92-3c29-42e5-b59f-f7141eefb56d';
  }

  handleDisplayProductOffer() {
    return this.uuidTenant !== '27e9610a-6d78-4ddd-af88-003eb8cb9b40'
    && this.uuidTenant !== '2563fe93-87c1-494e-983e-509bb03e4ade';
  }

  render() {
    const { showModal } = this.state;
    const {
      onExportFilter,
      products,
      channels,
      types,
      status,
      totalPages,
      page,
      setFilterCampaign,
      campaignFilter,
      openModalNewCampaign,
    } = this.props;
    return (
      <>
        <Container>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>CAMPANHAS</h1>


            <Button
              bordered
              onClick={() => openModalNewCampaign()}
              title="NOVA CAMPANHA"
            />

          </Row>
          <Row>
            <div className="box-search">
              <Input
                placeholder="Procurar por nome"
                value={campaignFilter.name}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    this.onChangePage(0);
                  }
                }}
                onChange={(e) => {
                  setFilterCampaign({ name: e.target.value });
                }}
              />
              {this.handleDisplayKeyword() && (
                <Input
                  placeholder="Keyword"
                  value={campaignFilter.keyword}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') {
                      this.onChangePage(0);
                    }
                  }}
                  onChange={(e) => {
                    setFilterCampaign({ keyword: e.target.value });
                  }}
                />
              )}
              {this.handleDisplayProductOffer() && (
                <Select
                  placeholder="Selecione Produto..."
                  value={campaignFilter.product}
                  onChange={(product) => {
                    setFilterCampaign({ product });
                  }}
                  options={products}
                  isClearable
                />
              )}
              <Select
                placeholder="Selecione Canal..."
                value={campaignFilter.channel}
                onChange={(channel) => {
                  setFilterCampaign({ channel });
                }}
                options={channels}
                isClearable
              />
              <Select
                placeholder="Selecione Tipo..."
                // defaultValue={campaignFilter.type}
                value={campaignFilter.type}
                onChange={(type) => {
                  setFilterCampaign({ type });
                }}
                options={types}
                isClearable
              />
              <Select
                placeholder="Selecione Status..."
                defaultValue={campaignFilter.status}
                onChange={(e) => {
                  setFilterCampaign({ status: e });
                }}
                options={status}
                isClearable
              />
              <ButtonGroup>
                <LinkButton
                  data-tip="Buscar dados"
                  data-for="tooltip-search"
                  onClick={() => {
                    this.onChangePage(0);
                  }}
                >
                  Buscar
                </LinkButton>
                <Button
                  icon="FaTimes"
                  data-tip="Limpar filtro"
                  data-for="tooltip-search"
                  noLabel
                  onClick={() => {
                    this.onResetFilter();
                  }}
                />
              </ButtonGroup>
              <Button
                onClick={() => onExportFilter()}
                data-tip="Exportar dados"
                data-for="tooltip-search"
                noLabel
                bordered
                icon="FaDownload"
              />
              <ReactTooltip id="tooltip-search" place="left" effect="solid" />
            </div>
          </Row>
          {this.renderCampaigns()}
          <Row>
            <Pagination
              page={parseInt(page, 10)}
              pageCount={totalPages}
              pageChange={e => this.onChangePage(e.selected)}
            />
          </Row>
        </Container>
        {showModal && this.renderModal()}
        <NewCampaign />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    content, page, totalElements,
    pageSize,
  } = state.campaign.data;
  const totalPages = Math.ceil(totalElements / pageSize);
  // const totalPages = Math.ceil(totalElements / 10);

  const pageResult = totalPages ? parseInt(page) : 0;

  const result = {
    page: pageResult,
    totalPages,
    campaigns: content,
    products: state.campaign.products,
    channels: state.campaign.channels,
    status: state.campaign.status,
    types: state.campaign.types,
    contractId: state.auth.contractId,
    campaignFilter: state.campaign.filter,
  };
  return result;
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadCampaignList: CampaignActions.loadCampaignList,
    onFilter: CampaignActions.filterCampaign,
    onExportFilter: CampaignActions.exportFilterCampaign,
    createCampaign: CampaignActions.createCampaign,
    deleteCampaign: CampaignActions.deleteCampaign,
    setFilterCampaign: CampaignActions.setFilterCampaign,
    openModalNewCampaign: CampaignActions.openModalNewCampaign,
    confirmModal: ConfirmModalActions.showConfirmModal,
    campaignIconLoadCampaignTypes: CampaignIconActions.campaignIconLoadCampaignTypes,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CampanhaList);
