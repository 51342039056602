import styled, { css } from 'styled-components';

import ButtonStyled from '~/Components/Button';

export const Container = styled.div`
  display: flex;
  > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      margin: 0;
    }
  }
`;

export const LinkButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border: 1px solid;
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 0;
  padding-left: 10px;
  padding-right: 10px;
  ${props => props.disabled && css`
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  `};
`;

export const Button = styled(ButtonStyled)``;
