import { call, put, select } from 'redux-saga/effects';

import moment from 'moment';
import AuctionService from '~/services/AuctionService';


import { Creators as PeriodActions } from '~/redux/ducks/period';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadPeriodList() {
  yield put(startRequest());
  try {
    let periods = yield call(AuctionService.periodRequest);
    periods = periods.map(period => ({
      ...period,
      startDate: moment(period.startDate).utc().format('DD/MM/YYYY HH:mm'),
      endDate: moment(period.endDate).utc().format('DD/MM/YYYY HH:mm'),
      bidOpenDate: moment(period.bidOpenDate).utc().format('DD/MM/YYYY HH:mm'),
      bidCloseDate: moment(period.bidCloseDate).utc().format('DD/MM/YYYY HH:mm'),
      gridOpenDate: moment(period.gridOpenDate).utc().format('DD/MM/YYYY HH:mm'),
      gridCloseDate: moment(period.gridCloseDate).utc().format('DD/MM/YYYY HH:mm'),
    }));
    yield put(PeriodActions.loadPeriodListSuccess(periods));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os períodos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* periodUpdate() {
  const { periodEdit } = yield select(state => state.period);

  const startDate = moment(periodEdit.startDate, 'DD/MM/YYYY HH:ss').format('YYYY-MM-DDTHH:mm:00');
  const endDate = moment(periodEdit.endDate, 'DD/MM/YYYY HH:ss').format('YYYY-MM-DDTHH:mm:00');
  const bidOpenDate = moment(periodEdit.bidOpenDate, 'DD/MM/YYYY HH:ss').format('YYYY-MM-DDTHH:mm:00');
  const bidCloseDate = moment(periodEdit.bidCloseDate, 'DD/MM/YYYY HH:ss').format('YYYY-MM-DDTHH:mm:00');
  const gridOpenDate = moment(periodEdit.gridOpenDate, 'DD/MM/YYYY HH:ss').format('YYYY-MM-DDTHH:mm:00');
  const gridCloseDate = moment(periodEdit.gridCloseDate, 'DD/MM/YYYY HH:ss').format('YYYY-MM-DDTHH:mm:00');
  yield put(startRequest());
  try {
    yield call(AuctionService.periodUpdateRequest, {
      ...periodEdit,
      startDate,
      endDate,
      bidOpenDate,
      bidCloseDate,
      gridOpenDate,
      gridCloseDate,
    });
    yield put(PeriodActions.changeModalEditPeriod(false));
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Período salvo com sucesso.',
    ));
    yield put(PeriodActions.loadPeriodList());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      e.message || 'Houve um problema ao atualizar o período.',
    ));
    yield put(requestError());
  }
}
