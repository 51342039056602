import styled from 'styled-components';
import ReactSelect from 'react-select';
import PerfectScrollbar from '@opuscapita/react-perfect-scrollbar';
import {
  InputStyled,
} from '~/Components/Styled';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: ${props => props.width}px;
  max-width: ${props => props.width}px;
  min-height: ${props => props.height}px;
  max-height: ${props => props.height}px;
`;

export const ModalTitleBox = styled.div`
  display: flex;
  height: 60px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  border-bottom: 1px solid ${props => props.theme.colors.lighter};
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const ModalAlertMessage = styled.div`
  width: 100%;
  border: 2px solid ${props => props.theme.colors.primaryHex};
  background-color: ${props => props.theme.colors.primaryHex}50;
  padding: 5px;
  font-size: 15px;
  text-align: center;
  margin: 10px auto;
  width: 700px;
  color: ${props => props.theme.colors.dark};
  display: flex;
`;

export const ModalFormBox = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 40px);
  height: 575px;
  margin: 0 auto;
  border: 1px solid ${props => props.theme.colors.lighter};
  padding: 10px 10px 0 10px;
`;

export const ModalFooterBox = styled.div`
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
  justify-content: flex-end;
  > b {
    color: ${props => props.theme.colors.dark};
    font-size: 16px;
    margin-right: 20px;
  }
`;

export const FooterRadius = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: max-content;
  > b {
    color: ${props => props.theme.colors.dark};
    font-size: 16px;
    margin-right: 5px;
  }
  > span {
    color: ${props => props.theme.colors.dark};
    font-size: 16px;
    margin-left: 5px;
  }
`;

export const InputFooter = styled(InputStyled)`
  width: 100px;
  margin: 0;
`;

export const FormHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const InputHeader = styled(InputStyled)`
  width: 180px;
  margin: 0;
`;

export const SelectFilterGrouping = styled(ReactSelect)`
  width: 215px;
  margin: 0 0 0 10px;
`;

export const SelectFilterUf = styled(ReactSelect)`
  width: 140px;
  margin: 0 0 0 10px;
`;

export const SelectFilterCity = styled(ReactSelect)`
  width: 165px;
  margin: 0 0 0 10px;
`;

export const SelectFilterNeighborhood = styled(ReactSelect)`
  width: 165px;
  margin: 0 0 0 10px;
`;

export const FormTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  .checkmark-box {
    margin-top: -10px;
  }
`;

export const FormTableHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 938px;
  height: 40px;
  border: 1px solid ${props => props.theme.colors.primaryHex}80;
  background-color: ${props => props.theme.colors.primaryHex}30;
  color: ${props => props.theme.colors.dark};
  font-size: 16px;
  .table-header-check {
    width: 50px;
    display: flex;
    justify-content: center;
  }
  .table-header-name {
    width: 240px;
    padding-left: 10px;
  }
  .table-header-uf {
    width: 50px;
    padding-left: 10px;
  }
  .table-header-city {
    width: 180px;
    padding-left: 10px;
  }
  .table-header-neighborhood {
    width: 300px;
    padding-left: 10px;
  }
  .table-header-grouping {
    width: 118px;
    padding-left: 10px;
  }
`;

export const FormTableBody = styled(PerfectScrollbar)`
  display: flex;
  flex-direction: column;
  width: 938px;
  min-height: 462px !important;
  max-height: 462px !important;
`;

export const FormTablePointItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 938px;
  padding: 15px 0;
  color: ${props => props.theme.colors.dark};
  font-size: 16px;
  &:hover {
    background-color: ${props => props.theme.colors.lighterWhite};
  }
  .table-item-check {
    width: 50px;
    display: flex;
    justify-content: center;
  }
  .table-item-name {
    width: 240px;
    padding-left: 10px;
  }
  .table-item-uf {
    width: 50px;
    padding-left: 10px;
  }
  .table-item-city {
    width: 180px;
    padding-left: 10px;
  }
  .table-item-neighborhood {
    width: 300px;
    padding-left: 10px;
  }
  .table-item-grouping {
    width: 118px;
    padding-left: 10px;
  }
`;
