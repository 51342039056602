import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

const SVGIconComponent = ({ total, theme }) => {
  const perc = total || 0;
  return (
    <svg width="70px" height="70px" viewBox="0 0 42 42" className="donut" aria-labelledby="beers-title beers-desc" role="img">
      <circle className="donut-hole" cx="21" cy="21" r="15.91549430918954" fill="white" role="presentation" />
      <circle className="donut-ring" cx="21" cy="21" r="15.91549430918954" fill="transparent" stroke={theme.colors.primary} strokeWidth="3" role="presentation" />
      <g className="chart-text">
        <text className="chart-number" x="50%" y="53%" dominantBaseline="middle" textAnchor="middle">
          {perc}
        </text>
      </g>
    </svg>
  );
};

SVGIconComponent.defaultProps = {
  total: 0,
};

SVGIconComponent.propTypes = {
  total: PropTypes.number,
  theme: PropTypes.shape().isRequired,
};

export default withTheme(SVGIconComponent);
