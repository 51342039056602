import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  ContainerStyled,
  TableStyled,
  RowStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)``;

export const Table = styled(TableStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
  }
`;
