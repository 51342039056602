import styled, { css } from 'styled-components';
import { InputStyled } from '~/Components/Styled';

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 30px;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  /* background-color: ${props => props.theme.colors.primaryHex}30; */
  height: 50px;
  font-size: 16px;
  padding: 5px 10px;
`;

export const Body = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ImagemItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const ImagemItemHeader = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
`;

export const ImagemEdit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.danger2};
  font-style: italic;
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding-top: 5px;
`;

export const ImagemEditInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;

  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const BaseContentInput = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 15px;

  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Separator = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${props => props.theme.colors.primary};
`;

export const Input = styled(InputStyled)`
  margin-right: 0;
`;

export const SuccessInfoCard4 = styled.div`
  text-align: center;
  flex: 1;
  padding: 15px;
  font-size: 16px;
  font-weight: bold;
`;
