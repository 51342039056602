import styled from 'styled-components';
import ReactSelect from 'react-select';

import { transparentize } from 'polished';
import { hex2rgba } from '~/Utils';


import {
  ContainerStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)``;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  h1 {
    margin-left: 25px;
  }
`;


export const StyledDiv = styled.div`
  width: 100%;
  min-width: 400px;
  padding: 25px;
  margin: 25px 0 25px 0;
  background-color: ${props => (props.bordered ? 'transparent' : '#FCF4EE')};
  border: ${props => (props.bordered ? '1px solid #E0E0E0' : '')};
  border-radius: 36px;

  overflow-x: ${props => (props.bordered ? 'auto' : '')};
`;


export const GroupElements = styled.div`
  width: 100%;
  display: flex;
  gap: 15px;
  justify-content: space-between;

  @media(max-width: 860px) {
    flex-wrap: wrap;
    justify-content: center;

  }
`;

export const GroupTitleList = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 25px;
`;


export const Select = styled(ReactSelect)`
  font-size: 20px;
  font-weight: 500;
  min-width: 500px;
  max-width: 600px;

  .react-select__control {
    border-radius: 30px;
    background-color: transparent;
    padding-left: 15px;
    padding-right: 15px;
  }

  .react-select__menu {
    border-radius: 30px;
  }

  .react-select__indicator-separator{
    display: none;
  }

  svg {
    width: 30px;
    height: 30px;
  }
`;


export const Button = styled.button`
  height: ${props => (props.onlyIcon ? '60px' : props.bordered ? '40px' : '50px')};
  width: ${props => (props.onlyIcon ? '60px' : '')};
  min-width: max-content;
  padding-left: ${props => (props.onlyIcon ? '' : '25px')};
  padding-right: ${props => (props.onlyIcon ? '' : '25px')};

  display: flex;
  align-items: center;
  justify-content: center;

  gap: 15px;

  background-color: ${props => (props.bordered ? 'transparent' : props.theme.colors.primary)};
  border: ${props => (props.bordered ? `2px solid ${props.theme.colors.primary}` : 'none')};
  border-radius: ${props => (props.onlyIcon ? '20px' : '30px')};

  span {
    color: ${props => (props.bordered ? props.theme.colors.primary : props.theme.colors.white)};
    font-size: 18px;
  }

  &:hover {
    background-color:
    ${props => (props.bordered ? transparentize(0.9, props.theme.colors.primary)
    : transparentize(0.1, props.theme.colors.primary))};
  }

  &:disabled {
    background-color: ${props => props.theme.colors.light};
    svg path {
      fill: ${props => props.theme.colors.regular};
    }
    span {
      color: ${props => props.theme.colors.regular};
    }
    box-shadow: 0 5px 10px ${props => hex2rgba(props.theme.colors.dark, 0.3)};
  }
`;


export const Table = styled.table`
  width: 100%;
  min-width: 1000px;
  text-align: center;
  font-weight: 600;
  border-collapse: separate;
  border-spacing: 0 15px;

  tr {
    height: 90px;
  }

  td {
    background-color: ${props => props.theme.colors.lighterWhite};
    >div {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      align-items: center;
      border-left: 2px solid ${props => transparentize(0.2, props.theme.colors.light)};
      height: 60px;
    }

    .channel-situation {
      display: flex;
      align-items: center;
      padding: 0 10px 0 10px;
      .span-awaiting {
        margin-left: 15px;
        font-size: 12px;
        color: #EEBA00;
      }
      .span-analysis {
        margin-left: 15px;
        font-size: 12px;
        color: #4F7FC8;
      }
      .span-validated {
        margin-left: 15px;
        font-size: 12px;
        color: #67C84F;
      }
    }
  }

  td:first-child {
    text-align: left;
    font-size: 20px;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    padding-left: 28px;
    >div {
      border-left: none;
    }
  }

  td:last-child {
    text-align: right;
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
    padding-right: 14px;
    >div {
      border-left: none;
    }
  }
`;
