import React from 'react';
import PropTypes from 'prop-types';

import { withTheme } from 'styled-components';

import moment from 'moment';
import { Container, BarComponent } from './styles';
import { getFormattedValueByType } from '~/Utils';
import DataEmpty from '../DataEmpty';

const BarChart = ({ data, remainingStatus }) => {
  const dataChart = [...data];
  const { time: { days }, impact: { daily } } = remainingStatus;

  if (days > 0) {
    const metaValue = daily;

    let dataInitMoment;
    const dataInit = dataChart.length > 0 ? dataChart[dataChart.length - 1].date : null;
    if (!dataInit) {
      dataInitMoment = moment();
    } else {
      dataInitMoment = moment(dataInit, 'DD/MM');
    }

    dataInitMoment.add(1, 'day');

    let index = 0;
    while (index < days) {
      dataChart.push({
        date: dataInitMoment.format('DD/MM'),
        impact: metaValue,
        type: 'META',
      });
      dataInitMoment.add(1, 'day');
      index += 1;
    }
  }
  const entregaRealizada = Math.max.apply(null, dataChart.map(d => d.impact));

  if (data && data.length) {
    setTimeout(() => {
      const scrollPosition = (data.length > 5 ? data.length - 5 : data.length - 1) * 95; // 95 = width(60) + margin-right(35)
      const element = document.getElementById('base-chart-cronograma-entregas');
      if (element) {
        element.scrollLeft = scrollPosition;
      }
    }, 500);
  }

  return (
    <Container id="base-chart-cronograma-entregas">
      {
        dataChart && dataChart.length > 1
          ? dataChart.map(item => (
            <BarComponent id={`item-${item.date}`} key={item.date} barHeight={(item.impact * 100 / entregaRealizada)}>
              <span className={`bar-total ${item.type === 'IMPACT' ? 'secundary' : 'third'}`}>{getFormattedValueByType('decimal', item.impact)}</span>
              <div className="line" />
              <div className={`bar-color ${item.type === 'IMPACT' ? 'secundary' : 'third'}`} />
              <span className={`bar-day ${item.type === 'IMPACT' ? 'primary' : ''}`}>{item.date}</span>
            </BarComponent>
          )) : (
            <DataEmpty label="Gráfico disponível a partir de dois dias de dados." />
          )
      }
    </Container>

  );
};


BarChart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
  remainingStatus: PropTypes.shape().isRequired,
};

BarChart.defaultProps = {
  data: [],
};


export default withTheme(BarChart);
