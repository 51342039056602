import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';
import Dropzone from 'react-dropzone';

import Modal from '~/Components/Modal';
import Button from '~/Components/Button';

import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Creators as AudienceActions } from '~/redux/ducks/audience';

import {
  Row,
  ButtonCloseModal,
  ModalContent,
  FormGroup,
  Input,
  RowFooter,
  Select,
  Toggle,
  UploadArea,
} from './styles';

class ModalExternalAudience extends Component {
  componentDidMount() {
    const { audienceSetupRequest } = this.props;
    audienceSetupRequest();
  }

  render() {
    const {
      audienceSetExternalData, audienceExternalData,
      contracts, onSaveExternalAudience,
      user: { hasPermissionGlobalAudience },
    } = this.props;

    const contractList = contracts.map(item => ({
      label: item.name,
      value: item.uuid,
      uuid: item.uuid,
    }));
    return (
      <Modal width={500}>
        <Row>
          <h1>Audiência Externa</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              audienceSetExternalData(null);
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <ModalContent>
          <FormGroup>
            <label>
              <b>Nome</b>
            </label>
            <Input
              type="text"
              style={{ marginRight: 0 }}
              placeholder="Nome"
              value={audienceExternalData.name}
              onChange={(e) => {
                audienceSetExternalData({
                  ...audienceExternalData,
                  name: e.target.value,
                });
              }}
            />
          </FormGroup>
          {hasPermissionGlobalAudience && (
            <Row>
              <FormGroup style={{ maxWidth: 150 }}>
                <label>Audiência global</label>
                <Toggle
                  value={audienceExternalData.shared}
                  style={{ justifyContent: 'center' }}
                  onChange={(e) => {
                    const shared = e.target.checked;
                    audienceSetExternalData({
                      ...audienceExternalData,
                      shared,
                      contract: shared ? contractList[0] : audienceExternalData.contract,
                    });
                  }}
                />
              </FormGroup>
              <FormGroup>
                <label>Contrato</label>
                <Select
                  isDisabled={!!audienceExternalData.shared}
                  placeholder="Contrato"
                  value={audienceExternalData.contract}
                  onChange={(contract) => {
                    audienceSetExternalData({
                      ...audienceExternalData,
                      contract,
                    });
                  }}
                  options={contractList}
                />
              </FormGroup>
            </Row>
          )}
          <FormGroup>
            <label>
              <b>Arquivo</b>
              <a
                href="https://s3.amazonaws.com/static.akross.com.br/template.csv"
                target="_blank"
                rel="noopener noreferrer"
              >
                Faça o download do arquivo de exemplo
              </a>
            </label>
            <UploadArea
              dropFile={!!audienceExternalData.file}
            >
              <Dropzone
                accept=".csv"
                multiple={false}
                onDrop={(files) => {
                  audienceSetExternalData({
                    ...audienceExternalData,
                    file: files[0],
                  });
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} style={{ outline: 'none' }}>
                      <input {...getInputProps()} />
                      {
                        audienceExternalData.file
                        && <p>{audienceExternalData.file.name}</p>
                      }
                      <p>
                        {!audienceExternalData.file
                          ? 'Clique aqui ou arraste o CSV'
                          : 'Clique aqui ou arraste para alterar'}
                      </p>
                    </div>
                  </section>
                )}
              </Dropzone>
            </UploadArea>
          </FormGroup>
          <RowFooter>
            <Button
              disabled={
                !audienceExternalData.name
                || (hasPermissionGlobalAudience && !audienceExternalData.contract)
                || !audienceExternalData.file
              }
              title="SALVAR"
              onClick={() => onSaveExternalAudience()}
            />
          </RowFooter>
        </ModalContent>
      </Modal>
    );
  }
}

ModalExternalAudience.propTypes = {
  audienceExternalData: PropTypes.shape().isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  audienceSetExternalData: PropTypes.func.isRequired,
  audienceSetupRequest: PropTypes.func.isRequired,
  onSaveExternalAudience: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  audienceExternalData: state.audience.audienceExternalData,
  audienceExternalUploadData: state.audience.audienceExternalUploadData,
  contracts: state.audience.contracts,
  user: state.auth.currentUser,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    navigateToRoute: RedirectActions.navigateToRoute,
    audienceSetExternalData: AudienceActions.audienceSetExternalData,
    audienceSetupRequest: AudienceActions.audienceSetupRequest,
    onSaveExternalAudience: AudienceActions.saveExternalAudienceRequest,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalExternalAudience);
