import styled from 'styled-components';
import {
  RowStyled,
  TableStyled,
  InputStyled,
} from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const BoxTable = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
`;

export const BoxTableHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 30px;
`;

export const BoxTableHeaderTitle = styled.span`
  display: flex;
  height: 30px;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  font-size: 16px;
  margin-left: 10px;
`;

export const BoxTableHeaderSubtitle = styled.span`
  color: ${props => props.theme.colors.dark};
  font-size: 16px;
  display: flex;
  height: 30px;
  align-items: center;
  margin-right: 10px;
`;

export const BoxTableContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

export const BoxTableContentItem = styled.div`
  font-size: 16px;
  span.successDate{
    color: ${props => props.theme.colors.checked};
    letter-spacing: 1px;
  }

  span.dangerDate{
    color: ${props => props.theme.colors.danger};
    letter-spacing: 1px;
  }
`;

export const Table = styled(TableStyled)`
  thead {
    tr {
      th {
        white-space: nowrap;
      }
      th[align="center"] {
        text-align: center !important;
      }
    }
  }
  tbody {
    tr {
      td {
        padding: 0 5px;
        height: 40px;
        span.nowrap {
          white-space: nowrap;
        }
        span.auctionModified {
          display: flex;
          flex-direction: row;
          white-space: nowrap;
          background-color: ${props => props.theme.colors.warning};
          color: ${props => props.theme.colors.darker};
          margin-left: 5px;
          padding: 0 15px;
          height: 30px;
          border-radius: 15px;
          justify-content: center;
          align-items: center;
          font-size: 14px;
        }
      }
    }
  }
`;

export const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 0 20px;
`;

export const RowContent = styled.div`
  display: inline;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 20px;
  h2 {
    color: ${props => props.theme.colors.dark};
    margin-bottom: 10px;
  }
  .title{
    color: ${props => props.theme.colors.dark};
    font-weight: bold;
    font-size: 18px;
    margin-top: 30px;
      &:after{
      content:' ';
      display:block;
      border: 1px solid #d0d0d0;
      margin-bottom: 30px;
      }
  }
  .input-wrapper{
    display: inline-flex;
    position: relative;
    svg#icon {
      position: absolute;
      top: 10px;
      right: 30px;
    }
  }

`;


export const RowFooter = styled(RowStyled)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  padding: 20px;
  p {
    font-size: 16px;
    font-style: italic;
    color: ${props => props.theme.colors.danger2};
    margin: 0 10px 0 0;
  }
  button {
    margin: 0;
  }
`;

export const Input = styled(InputStyled)`
  width: 290px;
  /* &:after {
        font-family: 'FontAwesome';
        content: '\f274';
        position: absolute;
        right: 6px;
    } */
`;
