/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { FaVolumeUp } from 'react-icons/fa';
import { Loader } from 'react-loaders';

import { PreviewMessageBox, ContentVoiceMessage, PreviewMessageBoxFooter } from './styles';

const getMessageScreen = (contentVersion, content, schema, propContent) => {
  const value = content[propContent];
  if (contentVersion === 2) {
    const screen = schema.screens.find(s => s.id === propContent);
    if (screen) {
      const {
        disabled, valueSuffix, valuePrefix, value: valueScreen,
      } = screen;
      if (disabled) {
        return `${valuePrefix || ''} ${valueScreen || ''} ${valueSuffix || ''}`;
      }
      return `${valuePrefix || ''} ${value || ''} ${valueSuffix || ''}`;
    }
  }
  return value || '';
};

const VoiceMessageTextPreview = ({
  currentScreen, changeCurrentScreen, theme,
  data: { content, contentVersion, creativeDefinition: { schema } },
}) => (
  <>
    {currentScreen === 'screen1' && (
      <PreviewMessageBox>
        <ContentVoiceMessage>
          <div
            className="buttonPlay"
            onClick={() => {
              changeCurrentScreen('screen2');
            }}
          >
            <FaVolumeUp size={40} />
          </div>
        </ContentVoiceMessage>
      </PreviewMessageBox>
    )}
    {currentScreen === 'screen2' && (
      <PreviewMessageBox>
        <ContentVoiceMessage>
          <div className="contentPlaying">
            <Loader active type="line-scale-pulse-out" color={theme.colors.white} />
            <div>
              <label>Transcrição audio</label>
              <p>{getMessageScreen(contentVersion, content, schema, 'screen1')}</p>
            </div>
            <PreviewMessageBoxFooter style={{ width: '100%' }}>
              <button
                type="button"
                onClick={() => {
                  changeCurrentScreen('screen1');
                }}
              >
                Cancelar
              </button>
              <button
                type="button"
                onClick={() => {
                  changeCurrentScreen('screen1');
                }}
              >
                OK
              </button>
            </PreviewMessageBoxFooter>
          </div>
        </ContentVoiceMessage>
      </PreviewMessageBox>
    )}
  </>
);

VoiceMessageTextPreview.propTypes = {
  currentScreen: PropTypes.string.isRequired,
  changeCurrentScreen: PropTypes.func.isRequired,
  data: PropTypes.shape({
    content: PropTypes.shape().isRequired,
    contentVersion: PropTypes.number,
    creativeDefinition: PropTypes.shape({
      schema: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
  }).isRequired,
  theme: PropTypes.shape().isRequired,
};

export default withTheme(VoiceMessageTextPreview);
