export const Types = {
  START_REQUEST: 'START_REQUEST',
  REQUEST_SUCCESS: 'REQUEST_SUCCESS',
  REQUEST_ERROR: 'REQUEST_ERROR',
};

export default function loading(state = {}, action) {
  return state;
}

export const startRequest = () => ({ type: Types.START_REQUEST });
export const requestSuccess = () => ({ type: Types.REQUEST_SUCCESS });
export const requestError = () => ({ type: Types.REQUEST_ERROR });
