/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import uuid4 from 'uuid4';
import { withTheme } from 'styled-components';
import RichTextEditor from 'react-rte';
import {
  // FaQuestionCircle,
  // FaRegCheckSquare,
  // FaRegDotCircle,
  // FaFont,
  // FaSortNumericUp,
  // FaRegStar,
  // FaRegCalendarAlt,
  FaPencilAlt,
  FaEye,
} from 'react-icons/fa';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
// import ReactTooltip from 'react-tooltip';

// import Checkbox from '~/Components/Checkbox';
// import Modal from '~/Components/Modal';
// import Button from '~/Components/Button';
// import Checkbox from '~/Components/Checkbox';
// import Radiobox from '~/Components/Radiobox';
// import Item from './components/Item';
// import PreviewResearch from '../../../../../../../../Components/PreviewResearch';

import {
  Container,
  Row,
  Column,
  // Questions,
  // LabelQuestion,
  // NewItem,
  // AddNewItem,
  // modal
  // ModalHeader,
  // ModalWrapBody,
  // ModalPreview,
  // ModalBody,
  // ModalFooter,
  // AddItemsWrap,
  // GroupButtons,
  // ButtonInsert,
  FormGroup,
  ButtonEditForm,
  ButtonViewForm,
  GroupActionsButton,
  SelectPosition,
} from './styles';
import { ModalSurveyEdit } from '../ModalSurveyEdit';

class ResearchForm extends Component {
  state = {
    modalResearchOpen: false,
    editorValue: RichTextEditor.createEmptyValue(),
    formModalCopy: null,
    formHasError: false,
  };

  refModalBody = null;

  editorRef = null;

  componentDidMount() {
    const { content } = this.props;
    this.setState({
      editorValue: RichTextEditor.createValueFromString(
        content.confirmationTerm ? content.confirmationTerm : '',
        'html',
      ),
    });
  }

  renderModalResearch = () => {
    const { formModalCopy } = this.state;
    const {
      onChangeForm,
      content,
      disabledFields,
    } = this.props;
    const { formHasError } = this.state;
    const { modalResearchOpen } = this.state;

    return (
      <ModalSurveyEdit
        modalOpen={modalResearchOpen}
        disabledFields={disabledFields}
        onChangeForm={onChangeForm}
        content={content}
        formModalOriginal={formModalCopy}
        formHasError={formHasError}
        onCloseModal={() => {
          onChangeForm({
            ...content,
            ...formModalCopy,
          });
          this.setState({
            modalResearchOpen: false,
            formModalCopy: null,
            formHasError: false,
          });
        }}
        onCancelar={() => {
          onChangeForm({
            ...content,
            ...formModalCopy,
          });
          this.setState({
            modalResearchOpen: false,
            formModalCopy: null,
            formHasError: false,
          });
        }}
        onConfirmar={(formIsValid) => {
          if (formIsValid) {
            this.setState({
              modalResearchOpen: false,
              formHasError: false,
            });
          } else {
            this.setState({
              formHasError: true,
            });
          }
        }}
      />
    );
  };

  render() {
    const { editorValue } = this.state;
    const {
      onChangeForm,
      content,
      hasError,
      disabledFields,
    } = this.props;

    const typeList = [
      { label: 'Antes da pesquisa', value: 'before' },
      { label: 'Depois da pesquisa', value: 'after' },
      { label: 'Não se aplica', value: '' },
    ];

    return (
      <Container>
        <>
          <Row style={{ justifyContent: 'center' }}>
            <GroupActionsButton>
              {disabledFields ? (
                <ButtonViewForm
                  onClick={() => {
                    this.setState({
                      modalResearchOpen: true,
                      formModalCopy: content,
                      formHasError: false,
                    });
                  }}
                >
                  Visualizar formulário
                  <FaEye />
                </ButtonViewForm>
              ) : (
                <>
                  <ButtonEditForm
                    onClick={() => {
                      this.setState({
                        modalResearchOpen: true,
                        formModalCopy: content,
                        formHasError: false,
                      });
                    }}
                  >
                    Editar formulário
                    <FaPencilAlt />
                  </ButtonEditForm>
                </>
              )}
            </GroupActionsButton>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <h4>Configurações</h4>
          </Row>
          <Row style={{ justifyContent: 'flex-start' }}>
            <Column>
              <FormGroup style={{ flexDirection: 'row', alignItems: 'center' }}>
                <label style={{ margin: 0 }}>Posição do termo</label>
                <SelectPosition
                  isDisabled={disabledFields}
                  menuPlacement="top"
                  placeholder="Selecione a posição"
                  value={typeList.find(item => item.value === (content.termPosition ? content.termPosition : ''))}
                  onChange={(position) => {
                    onChangeForm({
                      ...content,
                      termPosition: position.value,
                      ...(!position.value ? { confirmationTerm: '' } : {}),
                    });
                    if (!position.value) {
                      this.setState({ editorValue: RichTextEditor.createValueFromString('', 'html') });
                    }
                  }}
                  options={typeList}
                />
              </FormGroup>
            </Column>
          </Row>
          <Row style={{ marginTop: 10 }}>
            <FormGroup>
              <label>
                Termo de confirmação
                {!!content.termPosition && (
                  <span>*</span>
                )}
              </label>
              <RichTextEditor
                disabled={disabledFields || !content.termPosition}
                className={`editor-rich ${hasError && (!content.confirmationTerm && content.termPosition) ? 'invalid-editor' : ''}`}
                value={editorValue}
                ref={(ref) => { this.editorRef = ref; }}
                toolbarConfig={{
                  display: [
                    'INLINE_STYLE_BUTTONS',
                  ],
                  INLINE_STYLE_BUTTONS: [
                    { label: 'Negrito', style: 'BOLD' },
                    { label: 'Itálico', style: 'ITALIC' },
                    { label: 'Sublinhado', style: 'UNDERLINE' },
                  ],
                }}
                onChange={(val) => {
                  this.setState({ editorValue: val });
                  onChangeForm({
                    ...content,
                    confirmationTerm: val.toString('html'),
                  });
                }}
              />
            </FormGroup>
          </Row>
        </>
        {this.renderModalResearch()}
      </Container>
    );
  }
}

ResearchForm.propTypes = {
  content: PropTypes.shape().isRequired,
  onChangeForm: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(withTheme(ResearchForm));
