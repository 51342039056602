/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import {
  PreviewMessageBox,
  PreviewMessageBoxFooter,
  PreviewMessageBoxContent,
} from './styles';

const getMessageScreen = (contentVersion, content, schema, propContent) => {
  const value = content[propContent];
  if (contentVersion === 2) {
    const screen = schema.screens.find(s => s.id === propContent);
    if (screen) {
      const {
        disabled, valueSuffix, valuePrefix, value: valueScreen,
      } = screen;
      if (disabled) {
        return `${valuePrefix || ''} ${valueScreen || ''} ${valueSuffix || ''}`;
      }
      return `${valuePrefix || ''} ${value || ''} ${valueSuffix || ''}`;
    }
  }
  return value || '';
};

const SmartMessageTextClaroPreview = ({
  currentScreen, changeCurrentScreen,
  data: { content, contentVersion, creativeDefinition: { schema } },
}) => (
  <>
    {currentScreen === 'screen1' && (
    <PreviewMessageBox opacity={currentScreen === 'screen2'}>
      <PreviewMessageBoxContent>
        {getMessageScreen(contentVersion, content, schema, 'screen1')}
      </PreviewMessageBoxContent>
      <PreviewMessageBoxFooter>
        <button
          type="button"
          onClick={() => {
            changeCurrentScreen('');
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          onClick={() => {
            changeCurrentScreen('screen2');
          }}
        >
          OK
        </button>
      </PreviewMessageBoxFooter>
    </PreviewMessageBox>
    )}
    {currentScreen === 'screen2' && (
    <PreviewMessageBox>
      <PreviewMessageBoxContent>
        {getMessageScreen(contentVersion, content, schema, 'screen2')}
      </PreviewMessageBoxContent>
      <PreviewMessageBoxFooter>
        <button
          type="button"
          onClick={() => {
            changeCurrentScreen('');
          }}
        >
          Cancelar
        </button>
        <button
          type="button"
          onClick={() => {
            changeCurrentScreen('');
          }}
        >
          OK
        </button>
      </PreviewMessageBoxFooter>
    </PreviewMessageBox>
    )}
  </>
);

SmartMessageTextClaroPreview.propTypes = {
  currentScreen: PropTypes.string.isRequired,
  changeCurrentScreen: PropTypes.func.isRequired,
  data: PropTypes.shape({
    content: PropTypes.shape().isRequired,
    contentVersion: PropTypes.number,
    creativeDefinition: PropTypes.shape({
      schema: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
  }).isRequired,
};

export default SmartMessageTextClaroPreview;
