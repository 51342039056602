import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// import {
//   FaFont, FaQuestionCircle, FaRegCalendarAlt, FaRegCheckSquare, FaRegDotCircle,
// } from 'react-icons/fa';
// import uuid4 from 'uuid4';

// import ReactTooltip from 'react-tooltip';
import {
  FaFont, FaRegCalendarAlt, FaRegCheckSquare, FaRegDotCircle,
} from 'react-icons/fa';
import { toastr } from 'react-redux-toastr';
import Modal from '~/Components/Modal';
import Button from '~/Components/Button';


import {
  Row,
  ModalHeader,
  ModalWrapBody,
  ModalBody,
  ModalFooter,
  Info,
  QuestionsItem,
  BaseFormBenefits,
  AddBeneficio,
  RemoveBeneficio,
  Table,
} from './styles';
import Select from '~/Components/Select';
import BenefitService from '~/services/BenefitService';

export function ModalBenefitQuestion({
  modalOpen, disabledFields,
  question,
  onCancelar, onConfirmar,
}) {
  const [benefits, setBenefits] = useState([]);

  const [benefitType, setBenefitType] = useState(null);
  const [benefitOffer, setBenefitOffer] = useState(null);
  const [benefitOptions, setBenefitOptions] = useState([]);
  const [benefitOffersOptions, setBenefitOffersOptions] = useState([]);
  async function loadBenefit() {
    try {
      const result = await BenefitService.benefitRequest();
      setBenefitOptions(result.map(item => ({ label: item.name, value: item.uuid, benefitOffers: item.benefitOffers })));
    } catch (e) {
      console.log('error', e);
    }
  }

  function changeBenefitType(type) {
    setBenefitType(type);
    setBenefitOffer(null);
    if (type) {
      setBenefitOffersOptions(type.benefitOffers.map(item => ({ label: item.name, value: item.uuid })));
    } else {
      setBenefitOffersOptions([]);
    }
  }

  function handleAddBenefit() {
    setBenefits([...benefits, {
      benefit: {
        uuid: benefitType.value,
        name: benefitType.label,
      },
      benefitOffer: {
        uuid: benefitOffer.value,
        name: benefitOffer.label,
      },
    }]);

    setBenefitType(null);
    setBenefitOffer(null);
  }

  function removeBenefit(index) {
    const newBenefits = benefits.filter((item, i) => i !== index);
    setBenefits(newBenefits);
  }

  useEffect(() => {
    if (modalOpen) {
      loadBenefit();

      if (question.benefits) {
        setBenefits(question.benefits);
      } else {
        setBenefits([]);
      }
    }
  }, [modalOpen]);

  function getIconQuestionType(questionType) {
    switch (questionType) {
      case 'check':
        return <FaRegCheckSquare size={25} />;
      case 'radio':
        return <FaRegDotCircle size={25} />;
      case 'text':
        return <FaFont size={25} />;
      case 'date':
        return <FaRegCalendarAlt size={25} />;
      default:
        return null;
    }
  }

  function renderFormBenefits() {
    return (
      <BaseFormBenefits>
        <div className="header-form-benefits">
          <div className="form-group-benefits">
            <label>Tipo de benefício</label>
            <Select
              placeholder="Tipo de benefício..."
              value={benefitType ? benefitOptions.find(item => benefitType.value === item.value) : null}
              onChange={(type) => {
                changeBenefitType(type);
              }}
              options={benefitOptions}
              isClearable
            />
          </div>
          <div className="form-group-benefits">
            <label>Benefício</label>
            <Select
              placeholder="Benefício..."
              value={
              benefitOffer
                ? benefitOffersOptions.find(item => benefitOffer.value === item.value)
                : null
            }
              onChange={(offer) => {
                setBenefitOffer(offer);
              }}
              options={benefitOffersOptions}
              isClearable
            />
          </div>
          <AddBeneficio
            disabled={!benefitType || !benefitOffer}
            title="ADICIONAR"
            onClick={handleAddBenefit}
          />
        </div>
        <div>
          <Table>
            <thead>
              <tr>
                <th width="1%">#</th>
                <th>Tipo de benefício</th>
                <th>Benefício</th>
                <th width="1%">Ação</th>
              </tr>
            </thead>
            <tbody>
              {benefits.length < 1 ? (
                <tr>
                  <td colSpan="4">
                    <div className="empty-table-desc">
                      <span>Não foram localizados registros de benefícios</span>
                    </div>
                  </td>
                </tr>
              ) : (
                benefits.map((item, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <tr key={`${item.benefitOffer.uuid}-${index}`}>
                    <td>
                      <span>{index + 1}</span>
                    </td>
                    <td>
                      <span>{item.benefit.name}</span>
                    </td>
                    <td>
                      <span>{item.benefitOffer.name}</span>
                    </td>
                    <td>
                      <RemoveBeneficio
                        title="REMOVER"
                        bordered
                        onClick={() => removeBenefit(index)}
                      />
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </div>
      </BaseFormBenefits>
    );
  }

  return modalOpen ? (
    <Modal width={1000} height={760} padding={0} scroll={false}>
      <ModalHeader>
        <h1>{`${disabledFields ? 'Visualizar' : 'Editar'} benefícios da pergunta`}</h1>
        <QuestionsItem>
          {getIconQuestionType(question.type)}
          <p>{question.title}</p>
        </QuestionsItem>
      </ModalHeader>
      <ModalWrapBody>
        <ModalBody>
          <Row>
            <Info>
              <p>Configure os benefícios concedidos ao usuário que responder a pergunta</p>
            </Info>
          </Row>
          <Row>
            {renderFormBenefits()}
          </Row>
        </ModalBody>
      </ModalWrapBody>
      <ModalFooter>
        {disabledFields ? (
          <Button
            type="button"
            bordered
            title="Fechar"
            onClick={onCancelar}
            style={{ margin: 0 }}
          />
        ) : (
          <>
            <Button
              type="button"
              bordered
              title="Cancelar"
              onClick={onCancelar}
              style={{ margin: 0 }}
            />
            <Button
              type="button"
              title="Confirmar"
              onClick={() => {
                onConfirmar(benefits);
              }}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  ) : null;
}

ModalBenefitQuestion.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  // onChangeForm: PropTypes.func.isRequired,
  question: PropTypes.shape().isRequired,
  // formHasError: PropTypes.bool.isRequired,
  // onCloseModal: PropTypes.func.isRequired,
  onCancelar: PropTypes.func.isRequired,
  onConfirmar: PropTypes.func.isRequired,
};
