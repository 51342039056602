import { call, put, select } from 'redux-saga/effects';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import ProductService from '~/services/ProductService';

import { Creators as ProductActions } from '~/redux/ducks/product';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* goSearch({ page }) {
  const { filter } = yield select(state => state.product);
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(ProductService.list, { ...filter, page });
    if (response) {
      const { page: pageResult, countElements, products: data } = response;
      const pageCount = Math.ceil(countElements / 30);
      yield put(ProductActions.goSearchResult({ paginate: { page: parseInt(pageResult), pageCount }, data }));
    }
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os Produtos.',
    ));
  }
}

export function* changeStrategy({ strategy }) {
  const { type: typeIndicator } = yield select(state => state.auth.auctionAlgorithm);
  const {
    modalDetail: {
      product: { uuid: productId },
      indicator: { strategy: strategyCurrent },
    },
  } = yield select(state => state.product);

  yield put(ProductActions.changeStrategyIndicator(strategy));

  yield put(startRequest());
  try {
    yield call(ProductService.updateStrategy, {
      productId,
      typeIndicator,
      strategy,
    });

    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Estratégia atualizada com sucesso!',
    ));
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar a estratégia do Produto.',
    ));
    yield put(ProductActions.changeStrategyIndicator(strategyCurrent));
  }
}

export function* showDetailProduct({ productId }) {
  const { type: typeIndicator } = yield select(state => state.auth.auctionAlgorithm);
  yield put(startRequest());
  try {
    const product = yield call(ProductService.get, productId);
    if (product) {
      const indicatorResult = yield call(ProductService.getIndicator, { productId, typeIndicator });
      let indicator;
      switch (typeIndicator) {
        case 'bcg':
          indicator = {
            bcg: null,
            billingRate: null,
            instantChurn: null,
            strategy: null,
          };
          break;
        default:
          indicator = { fator: 1 };
      }

      if (indicatorResult && indicatorResult.indicator) {
        indicator = { ...indicator, ...indicatorResult.indicator };
      }

      const evaluation = indicatorResult && indicatorResult.evaluation ? indicatorResult.evaluation : null;

      yield put(ProductActions.showDetailProductResult(product, indicator, evaluation));
      yield put(requestSuccess());
    } else {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Produto não encontrado.',
      ));
      yield put(requestError());
    }
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar o Produto.',
    ));
  }
}

export function* showDetailProductSetData({ product }) {
  const { type: typeIndicator } = yield select(state => state.auth.auctionAlgorithm);

  if (product) {
    const { productIndicator } = product;
    const indicatorsDataProduct = productIndicator && productIndicator.indicators ? productIndicator.indicators : null;
    let indicator;
    switch (typeIndicator) {
      case 'bcg':
        indicator = {
          bcg: null,
          billingRate: null,
          instantChurn: null,
          strategy: null,
        };
        break;
      default:
        indicator = { fator: 1 };
    }

    if (indicatorsDataProduct && indicatorsDataProduct.indicator) {
      indicator = { ...indicator, ...indicatorsDataProduct.indicator };
    }

    const evaluation = indicatorsDataProduct && indicatorsDataProduct.evaluation ? indicatorsDataProduct.evaluation : null;
    yield put(ProductActions.showDetailProductResult(product, indicator, evaluation));
  }
}
