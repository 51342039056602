import styled from 'styled-components';
import { RowStyled, FormGroupStyled, InputStyled } from '~/Components/Styled';

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0 20px 0;
  label {
    display: flex;
    flex: 1;
    align-items: center;
    > b {
      margin-right: 10px;
    }
    input {
      display: flex;
      flex: 1;
    }
    a {
      color: ${props => props.theme.colors.primary};
      margin-left: 10px;
    }
  }
`;

export const Input = styled(InputStyled)``;

export const RowFooter = styled(RowStyled)`
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const ProgressBar = styled.div`
  width: 100%;
  height: 35px;
  border: 1px solid ${props => props.theme.colors.primary};
`;

export const Progress = styled.div`
  width: ${props => props.progress}%;
  background-color: ${props => props.theme.colors.primary};
  height: 100%;
`;

export const ProgressLabel = styled.div`
  font-size: 20px;
  color: ${props => props.theme.colors.primary};
  text-align: center;
`;
