import React from 'react';
import PropTypes from 'prop-types';
import {
  FaBezierCurve, FaListAlt, FaImage, FaYoutube, FaMobileAlt, FaCaretSquareUp,
} from 'react-icons/fa';
import { sortableHandle } from 'react-sortable-hoc';

import { Container } from './styles';
import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';

function ItemMedia({
  data, selected, onClick, hasError, type, editable,
}) {
  const DragHandle = sortableHandle(() => <span className="item-media-move">::</span>);

  const getIconByType = () => {
    switch (type) {
      case CreativeCustomMidiaType.VIDEO: return <FaYoutube />;
      case CreativeCustomMidiaType.IMAGE: return <FaImage />;
      case CreativeCustomMidiaType.DATA_SEARCH: return <FaListAlt />;
      case CreativeCustomMidiaType.PROGRAMATICA: return <FaBezierCurve />;
      case CreativeCustomMidiaType.APP_INSTALL: return <FaMobileAlt />;
      case CreativeCustomMidiaType.EXTERNAL_REDIRECT: return <FaCaretSquareUp />;
      case CreativeCustomMidiaType.VAST: return <FaYoutube />;
      default: return <FaYoutube />;
    }
  };

  return (
    <Container selected={selected} onClick={onClick} hasError={hasError} className="media-item-button">
      {editable && <DragHandle />}
      {getIconByType()}
      {!!data.title && <span className="item-media-title">{data.title}</span>}
      {!data.title && <span className="item-media-placeholder">Mídia</span>}
      {data.properties && data.properties.replicasEnabled && (
      <div className="container-qnt-replicas">
        <span className="item-qnt-replicas">{data.qntReplicas}</span>
      </div>
      )}
    </Container>
  );
}

ItemMedia.defaultProps = {
  editable: true,
  selected: false,
  hasError: false,
  type: '',
};

ItemMedia.propTypes = {
  data: PropTypes.shape().isRequired,
  editable: PropTypes.bool,
  selected: PropTypes.bool,
  hasError: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  type: PropTypes.string,
};

export default ItemMedia;
