import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Container,
} from './styles';
import ItemTree from './components/ItemTree';

function TreeView({ data, itemSelectedId, setItemSelected }) {
  const [tree, setTree] = useState(null);

  useEffect(() => {
    setTree(data);
  }, [data]);

  if (!tree) {
    return null;
  }

  return (
    <Container>
      {tree.map(item => <ItemTree item={item} itemSelectedId={itemSelectedId} onClick={i => setItemSelected(i)} />)}
    </Container>
  );
}

TreeView.defaultProps = {
  data: null,
};

TreeView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

export default TreeView;
