/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FaTimes,
} from 'react-icons/fa';

import { Creators as AudienceActions } from '~/redux/ducks/audience';

import {
  Table,
  ButtonDeleteSegment,
} from './styles';

const ActiveFilter = ({
  audienceCategorySetActiveFilter,
  categoryFilterActive,
  onChange,
}) => {
  const itensIncluded = categoryFilterActive.include;
  const itensExcluded = categoryFilterActive.exclude;

  return (
    <div className="profile-column3">
      <h2>Filtros ativos</h2>
      <div className="filter-content-column3">
        {itensIncluded.length > 0 && (
          <>
            <h3>Incluídos</h3>
            <div className="filter-included">
              <Table>
                <tbody>
                  {itensIncluded.map((item, index) => (
                    <tr key={item.key}>
                      <td>
                        <span>
                          <b>{item.value}</b>
                          {` - ${item.breadcrumbs.map((val, i, array) => array[array.length - 1 - i]).join(' - ')}`}
                        </span>
                      </td>
                      <td width="1%">
                        <ButtonDeleteSegment
                          onClick={() => {
                            itensIncluded.splice(index, 1);
                            audienceCategorySetActiveFilter({
                              // include: itensIncluded.filter(inc => inc.key !== item.key),
                              include: [...itensIncluded],
                            });
                            onChange();
                          }}
                        >
                          <FaTimes />
                        </ButtonDeleteSegment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
        {itensExcluded.length > 0 && (
          <>
            <h3>Excluídos</h3>
            <div className="filter-excluded">
              <Table>
                <tbody>
                  {itensExcluded.map((item, index) => (
                    <tr key={item.key}>
                      <td>
                        <span>
                          <b>{item.value}</b>
                          {` - ${item.breadcrumbs.map((val, i, array) => array[array.length - 1 - i]).join(' - ')}`}
                        </span>
                      </td>
                      <td width="1%">
                        <ButtonDeleteSegment
                          onClick={() => {
                            itensExcluded.splice(index, 1);
                            audienceCategorySetActiveFilter({
                              // exclude: itensExcluded.filter(exc => exc.key !== item.key),
                              exclude: [...itensExcluded],
                            });
                            onChange();
                          }}
                        >
                          <FaTimes />
                        </ButtonDeleteSegment>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

ActiveFilter.defaultProps = {};

ActiveFilter.propTypes = {
  onChange: PropTypes.func.isRequired,
  audienceCategorySetActiveFilter: PropTypes.func.isRequired,
  categoryFilterActive: PropTypes.shape().isRequired,
};

const mapStateToProps = state => ({
  categories: state.audience.categories,
  categoryData: state.audience.categoryData,
  categoryFilterActive: state.audience.categoryFilterActive,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    audienceCategorySetActiveFilter: AudienceActions.audienceCategorySetActiveFilter,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ActiveFilter);
