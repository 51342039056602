import styled, { css } from 'styled-components';

export const Container = styled.button`
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
  border: none;

  color: ${props => props.theme.colors.white};
  padding: 5px 30px;

  background-color: ${props => (props.bordered ? 'transparent' : props.theme.colors[props.color ? props.color : 'primary'])};

  border: ${props => (props.bordered ? `solid 1px ${props.theme.colors[props.color ? props.color : 'primary']}` : 'none')};
  color: ${props => (props.bordered ? props.theme.colors[props.color ? props.color : 'primary'] : props.theme.colors.white)};
  margin-left: 15px;
  padding: 5px 15px;

  :disabled {
    opacity: 0.7;
  }

  ${props => props.small && css`
    font-size: 12px;
    padding: 5px;
    height: 30px;
  `};

  svg {
    font-size: ${props => (props.small ? 12 : 16)}px;
    color: ${props => (props.bordered ? props.theme.colors[props.color ? props.color : 'primary'] : props.theme.colors.white)};
    margin-left: ${props => (props.noLabel ? 0 : 10)}px;
    margin-bottom: -2px;
  }
`;
