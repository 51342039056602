/* eslint-disable no-console */
/* eslint-disable no-constant-condition */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import {
  FaPlus, FaBan, FaStopwatch, FaPowerOff, FaFunnelDollar, FaCoins,
} from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from 'react-day-picker/moment';
import 'react-day-picker/lib/style.css';
import 'moment/locale/pt-br';

import Button from '~/Components/Button';
import Breadcrumb from '~/Components/Breadcrumb';
// import GroupedButtons from './Components/GroupedButtons';

import ModalAdvertiser from './Components/ModalAdvertiser';
import ModalAgency from './Components/ModalAgency';
import ModalCampaignFilter from './Components/ModalCampaignFilter';

import {
  Container,
  Row,
  GroupedRows,
  Table,
  FormGroup,
  GroupedSelect,
  ButtonPlus,
  Select,
  Textarea,
  Input,
  CurrentInput,
  GroupedInput,
  LogBody,
  LogContainer,
} from './styles';

const statusLog = [
  {
    uuid: 1,
    observation: '',
    createdAt: '2020-01-10T13:01:11.000Z',
    label: 'Não Aprovado',
    type: 'not-approved',
    user: {
      name: 'Luis Leal',
    },
    icon: 'check-circle',
  },
  {
    uuid: 2,
    observation: '',
    createdAt: '2020-01-10T13:01:11.000Z',
    label: 'Aguardando Faturamento',
    type: 'awaiting-billing',
    user: {
      name: 'Luis Leal',
    },
    icon: 'check-circle',
  },
  {
    uuid: 3,
    observation: '',
    createdAt: '2020-01-10T13:01:11.000Z',
    label: 'Cancelado',
    type: 'canceled',
    user: {
      name: 'Luis Leal',
    },
    icon: 'check-circle',
  },
  {
    uuid: 4,
    observation: '',
    createdAt: '2020-01-10T13:01:11.000Z',
    label: 'Faturado Parcialmente',
    type: 'partially-billed',
    user: {
      name: 'Luis Leal',
    },
    icon: 'check-circle',
    value: 1550.00,
  },
  {
    uuid: 5,
    observation: '',
    createdAt: '2020-01-10T13:01:11.000Z',
    label: 'Faturado',
    type: 'billed',
    user: {
      name: 'Luis Leal',
    },
    icon: 'check-circle',
    value: 1550.00,
  },
];

class PiManagementForm extends Component {
  state = {
    // hasError: false,
    modalAdvertiser: false,
    modalAgency: false,
    modalCampaignFilter: false,
    // buttonMonth: 5,
    statusValue: null,
  };

  isUpdateMode = false;

  componentDidMount() {
    const {
      match: { params },
    } = this.props;

    this.isUpdateMode = !!params.piId;

    // this.setState({
    //   hasError: false,
    // });
  }

  getIconByStatusType = (type) => {
    switch (type) {
      case 'not-approved': return <FaBan />;
      case 'awaiting-billing': return <FaStopwatch />;
      case 'canceled': return <FaPowerOff />;
      case 'partially-billed': return <FaFunnelDollar />;
      case 'billed': return <FaCoins />;
      default: return null;
    }
  };

  renderHeader = () => (
    <>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginBottom: 10,
        }}
      >
        <div>
          <Breadcrumb
            items={[
              {
                route: '/',
                name: 'Home',
              },
              {
                route: '/pi-management',
                name: 'Gestão de PI',
              },
              {
                name: 'Cadastro',
              },
            ]}
          />
          <h1>{this.isUpdateMode ? 'EDITAR GESTÃO DE PI' : 'NOVA GESTÃO DE PI'}</h1>
        </div>
        <div className="header-button-box">
          <Button
            disabled={false}
            onClick={() => {}}
            title="Salvar"
          />
          <span>
            <b>Criação:</b>
            {' '}
            {moment().format('DD/MM/YYYY [-] HH:mm')}
          </span>
          <span>
            <b>Última atualização:</b>
            {' '}
            {moment().format('DD/MM/YYYY [-] HH:mm')}
          </span>
        </div>
      </Row>
    </>
  );

  renderForm = () => {
    const { /* buttonMonth, */ statusValue } = this.state;
    const campaigns = [1, 2, 3, 4, 5, 6].map(item => ({
      uuid: item,
      name: `Campanha 000${item}`,
      type: 'Push',
      status_approval: 'STATUS',
      status_send: 'EXECUTANDO',
      planned_budget: 'R$550,00',
      realized_budget: 'R$250,00',
    }));
    return (
      <>
        <Row>
          <FormGroup spaceRight={10}>
            <label>
              Número do PI
              <span>*</span>
            </label>
            <Input
              invalid={false}
              maxLength="60"
              placeholder="Digite o nome"
              value={null}
              onChange={e => e.target.value}
            />
          </FormGroup>
          <FormGroup>
            <label>
              Autor
            </label>
            <div className="author-label">Autor 1</div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label>
              Descrição
              <span>*</span>
            </label>
            <Textarea
              placeholder="Digite a descrição"
              rows="4"
              maxLength="200"
              value={null}
              onChange={e => e.target.value}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup spaceRight={10}>
            <label>
              Anunciante
              <span>*</span>
            </label>
            <GroupedSelect>
              <Select
                placeholder="Selecione..."
                value={null}
                onChange={(/* type */) => {}}
                options={[]}
                styles={{
                  menuList: provided => ({
                    ...provided,
                    maxHeight: 200,
                  }),
                }}
              />
              <ButtonPlus
                data-tip="Cadastrar novo anunciante"
                onClick={() => {
                  this.setState({ modalAdvertiser: true });
                }}
              >
                <FaPlus />
              </ButtonPlus>
              <ReactTooltip
                place="bottom"
                effect="solid"
              />
            </GroupedSelect>
          </FormGroup>
          <FormGroup>
            <label>
              Agência
            </label>
            <GroupedSelect>
              <Select
                placeholder="Selecione..."
                value={null}
                onChange={(/* type */) => {}}
                options={[]}
                styles={{
                  menuList: provided => ({
                    ...provided,
                    maxHeight: 200,
                  }),
                }}
              />
              <ButtonPlus
                data-tip="Cadastrar nova agência"
                onClick={() => {
                  this.setState({ modalAgency: true });
                }}
              >
                <FaPlus />
              </ButtonPlus>
              <ReactTooltip
                place="bottom"
                effect="solid"
              />
            </GroupedSelect>
          </FormGroup>
        </Row>
        {/*
          <Row style={{ alignItems: 'flex-end' }}>
            <FormGroup>
              <h1>Competência</h1>
              <GroupedButtons
                buttons={[
                  { label: 'Janeiro', value: 1 },
                  { label: 'Fevereiro', value: 2 },
                  { label: 'Março', value: 3 },
                  { label: 'Abril', value: 4 },
                  { label: 'Maio', value: 5 },
                  { label: 'Junho', value: 6 },
                  { label: 'Julho', value: 7 },
                  { label: 'Agosto', value: 8 },
                  { label: 'Setembro', value: 9 },
                  { label: 'Outubro', value: 10 },
                  { label: 'Novembro', value: 11 },
                  { label: 'Dezembro', value: 12 },
                ]}
                value={buttonMonth}
                onChange={(value) => {
                  this.setState({ buttonMonth: value });
                }}
              />
            </FormGroup>
          </Row>
        */}
        <GroupedRows>

          <Row style={{ alignItems: 'flex-end' }}>
            <h1>Status de faturamento</h1>
          </Row>
          <Row style={{ justifyContent: 'flex-start' }}>
            <FormGroup spaceRight={10} style={{ width: 400 }}>
              <label>
                Status
                <span>*</span>
              </label>
              <Select
                placeholder="Selecione..."
                value={statusValue}
                onChange={(status) => {
                  this.setState({
                    statusValue: status,
                  });
                }}
                options={[
                  { value: 1, label: 'Não Aprovado' },
                  { value: 2, label: 'Aguardando Faturamento' },
                  { value: 3, label: 'Cancelado' },
                  { value: 4, label: 'Faturado Parcialmente' },
                  { value: 5, label: 'Faturado' },
                ]}
                styles={{
                  menuList: provided => ({
                    ...provided,
                    maxHeight: 200,
                  }),
                }}
              />
            </FormGroup>
            <FormGroup spaceRight={10} style={{ width: 200 }}>
              <label>
                Data
              </label>
              <DayPickerInput
                ref={(/* ref */) => {
                  // if (ref && !campaignPeriodoToEdit.startDate) {
                  //   ref.input.value = campaignPeriodoToEdit.startDate;
                  // }
                }}
                formatDate={formatDate}
                parseDate={parseDate}
                format="DD/MM/YYYY"
                dayPickerProps={{
                  localeUtils: MomentLocaleUtils,
                  locale: 'pt-br',
                }}
                placeholder="__/__/____"
                value={moment().toDate()}
                onDayChange={(/* day */) => {
                  // if (day) {
                  //   const initialData = moment(day);
                  //   const startHour = moment(initialData.format('YYYY-MM-DD'))
                  //     .isSameOrBefore(moment().format('YYYY-MM-DD'))
                  //     ? moment().add(1, 'minutes').format('HH:mm')
                  //     : '00:00';
                  //   campaignEditSetPeriodoToEdit({
                  //     ...campaignPeriodoToEdit,
                  //     startDate: initialData.format('DD/MM/YYYY'),
                  //     startHour: !campaignPeriodoToEdit.startHour
                  //       ? startHour
                  //       : campaignPeriodoToEdit.startHour,
                  //   });
                  // }
                }}
              />
            </FormGroup>
            {statusValue && (statusValue.value === 4 || statusValue.value === 5) && (
              <FormGroup style={{ width: 200 }}>
                <label>
                  Valor
                </label>
                <CurrentInput
                  placeholder="R$"
                  prefix="R$ "
                  decimalSeparator=","
                  thousandSeparator="."
                  value={null}
                  invalid={false}
                  onChange={(/* e, cpa */) => {
                    // campaignEditSetOrcamentoToEdit({ ...campaignOrcamentoToEdit, cpa });
                  }}
                />
              </FormGroup>
            )}
          </Row>
          <Row>
            <FormGroup>
              <label>
                Observações
                <span>*</span>
              </label>
              <GroupedInput>
                <Input
                  invalid={false}
                  maxLength="60"
                  placeholder="Digite a observação"
                  value={null}
                  onChange={e => e.target.value}
                />
                <Button
                  disabled={false}
                  onClick={() => {}}
                  title="Adicionar"
                  icon="FaPlus"
                />
              </GroupedInput>
            </FormGroup>
          </Row>
          <Row bordered>
            <LogBody>
              {statusLog.map(history => (
                <LogContainer key={history.id}>
                  <div className="left">
                    <div className="icon-circle">
                      {this.getIconByStatusType(history.type)}
                    </div>
                    <div className="line" />
                  </div>
                  <div className="right">
                    <div className="status">
                      <h2>{history.label}</h2>
                    </div>
                    <div className="content-log">
                      <Row className="content-log-info">
                        <div className="col" style={{ flex: 1 }}>
                          <span className="label">Data</span>
                          <span className="desc-bold">
                            {moment(history.createdAt).format('DD/MM/YYYY')}
                          </span>
                        </div>
                        {!!history.value && (
                          <div className="col" style={{ flex: 1 }}>
                            <span className="label">Valor</span>
                            <span className="desc-bold">
                              {`R$ ${history.value.toLocaleString()}`}
                            </span>
                          </div>
                        )}
                        <div className="col" style={{ flex: 2 }}>
                          <span className="label">Autor</span>
                          <span className="desc-bold">{history.user.name || 'Sistema'}</span>
                        </div>
                      </Row>
                      <div className="col content-log-justify">
                        <span className="label">Observações</span>
                        <span className="desc">{history.observation || 'Sem observação.'}</span>
                      </div>
                    </div>
                  </div>
                </LogContainer>
              ))}
            </LogBody>
          </Row>
        </GroupedRows>
        <Row>
          <FormGroup borderLeft style={{ whiteSpace: 'nowrap' }} spaceRight={10}>
            <label>Orçamento planejado</label>
            <div className="billing-value">
              <b>R$ 1.555.302,00</b>
            </div>
          </FormGroup>
          <FormGroup borderLeft style={{ whiteSpace: 'nowrap' }} spaceRight={10}>
            <label>Orçamento realizado</label>
            <div className="billing-value">
              <b>R$ 1.000.000,00</b>
            </div>
          </FormGroup>
          <FormGroup borderLeft style={{ whiteSpace: 'nowrap' }} spaceRight={10}>
            <label>Faturamento realizado</label>
            <div className="billing-value">
              <b>R$ 1.555.302,00</b>
            </div>
          </FormGroup>
          <FormGroup borderLeft style={{ whiteSpace: 'nowrap' }}>
            <label>Vigência</label>
            <div className="billing-value">
              <b>Início:</b>
              <span>{moment().format('DD/MM/YYYY [-] HH:mm')}</span>
              <b>Fim:</b>
              <span>{moment().format('DD/MM/YYYY [-] HH:mm')}</span>
            </div>
          </FormGroup>
        </Row>
        <Row style={{ alignItems: 'flex-end' }}>
          <h1>Campanhas</h1>
          <Button
            disabled={false}
            onClick={() => {
              this.setState({ modalCampaignFilter: true });
            }}
            title="Adicionar"
            icon="FaPlus"
          />
        </Row>
        <Row>
          <Table>
            <thead>
              <tr>
                <th>NOME</th>
                <th>TIPO</th>
                <th>STATUS DE APROVAÇÃO</th>
                <th>STATUS DE ENVIO</th>
                <th>ORÇAMENTO PLANEJADO</th>
                <th>ORÇAMENTO REALIZADO</th>
                <th />
              </tr>
            </thead>
            <tbody>
              {campaigns.length < 1 ? (
                <tr>
                  <td colSpan="8">
                    <div className="empty-table-desc">
                      <span> Não foram localizados registros de campanhas</span>
                    </div>
                  </td>
                </tr>
              ) : (
                campaigns.map(campaign => (
                  <tr key={campaign.uuid}>
                    <td>
                      <span>
                        {campaign.name}
                      </span>
                    </td>
                    <td>
                      <span>
                        {campaign.type}
                      </span>
                    </td>
                    <td>
                      <span>
                        {campaign.status_approval}
                      </span>
                    </td>
                    <td>
                      <span>
                        {campaign.status_send}
                      </span>
                    </td>
                    <td>
                      <span>
                        {campaign.planned_budget}
                      </span>
                    </td>
                    <td>
                      <span>
                        {campaign.realized_budget}
                      </span>
                    </td>
                    <td>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <Button
                          bordered
                          title="EXCLUIR"
                          onClick={() => {}}
                        />
                      </div>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </Table>
        </Row>
      </>
    );
  };

  render() {
    const { modalAdvertiser, modalAgency, modalCampaignFilter } = this.state;
    return (
      <Container>
        {this.renderHeader()}
        {this.renderForm()}
        {modalAdvertiser && (
          <ModalAdvertiser
            onClose={() => { this.setState({ modalAdvertiser: false }); }}
          />
        )}
        {modalAgency && (
          <ModalAgency
            onClose={() => { this.setState({ modalAgency: false }); }}
          />
        )}
        {modalCampaignFilter && (
          <ModalCampaignFilter
            onClose={() => { this.setState({ modalCampaignFilter: false }); }}
          />
        )}
      </Container>
    );
  }
}

PiManagementForm.defaultProps = {};

PiManagementForm.propTypes = {
  match: PropTypes.shape().isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PiManagementForm);
