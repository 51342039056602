import React from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  BoxTitle,
  BoxContent,
} from './styles';

const CampaignsBoxContent = ({
  children,
  title,
  marginRight,
  width,
}) => (
  <Container marginRight={marginRight} width={width}>
    <Box>
      <BoxTitle>
        <span>{title}</span>
      </BoxTitle>
      <BoxContent>
        {children}
      </BoxContent>
    </Box>
  </Container>
);

CampaignsBoxContent.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  marginRight: PropTypes.number,
  width: PropTypes.number,
};

CampaignsBoxContent.defaultProps = {
  children: null,
  marginRight: 0,
  width: 0,
};

export default CampaignsBoxContent;
