import styled from 'styled-components';
import { RowStyled } from '~/Components/Styled';

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
  }
`;

export const ButtonCloseModal = styled.div`
  cursor: pointer;
`;

export const InfoDeleteCampaign = styled.p`
  padding-top: 20px;
  text-align: center;
  font-size: 20px;
`;
