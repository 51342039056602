import styled, { css } from 'styled-components';
import DatePicker from 'react-datepicker';

import { InputMaskStyled, InputStyled } from '~/Components/Styled';

export const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
`;

export const PreviewImg = styled.img`
  width: 100%;
`;

export const Preview = styled.div`
  position: absolute;
  top: 66px;
  left: 30px;
  right: 30px;
  bottom: 81px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
`;

export const PreviewContent = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
`;

export const PreviewHeader = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
`;

export const PreviewForm = styled.div`
  display: flex;
  flex: 1;
`;

export const PreviewFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px;
`;

export const Stepsbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StepItem = styled.div`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 1px solid ${props => props.color};
  background-color: ${props => (props.active ? props.color : props.theme.colors.white)};
  margin-right: 10px;
  &:last-child {
    margin-right: 0;
  }
`;

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  padding: 0 10px;
  .radio-box {
    .radio {
      border: 1px solid ${props => props.theme.colors.dark};
    }
    .radio:after {
      top: 5px;
      left: 5px;
    }
  }
  .checkmark-box {
    .checkmark {
      border-radius: 0px;
      border: 1px solid ${props => props.theme.colors.dark};
    }
    .checkmark:after {
      top: 3px;
    }
  }
`;

export const FormTitle = styled.span`
  color: ${props => props.theme.colors.dark};
  font-size: 18px;
  margin-bottom: 5px;
`;

export const InputMask = styled(InputMaskStyled)`
  margin: 0;
  outline: none;
  height: 40px;
`;

export const EditText = styled(InputStyled)`
  margin: 0;
  outline: none;
  height: 40px;
`;

export const EditDatePicker = styled(DatePicker)`
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  background-color: transparent;
  font-size: 16px;
  font-weight: 400;
  color: ${props => props.theme.colors.dark};
  padding: 5px 15px;
  height: 40px;
  width: 100%;
  outline: none;

  ${props => props.disabled
    && css`
      opacity: 0.6;
    `}

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}

  &::placeholder {
    color: ${props => props.theme.colors.light};
  }
`;

export const WrapBoxDate = styled.div`
  position: relative;
  width: 100%;
  height: 40px;
  .react-datepicker-wrapper {
    width: 100%;
    z-index: 9;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    .react-datepicker__input-container {
      width: 100%;
    }
  }
  .react-datepicker__tab-loop{
    zoom: 145%;
    .react-datepicker-popper {
      top: -14px !important;
    }
  }
  .icon-calendar {
    position: absolute;
    z-index: 0;
    top: 0;
    right: 0;
    cursor: pointer;
    border-left: 2px solid ${props => props.theme.colors.light};
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    svg {
      color: ${props => props.theme.colors.regular};
      font-size: 18px;
    }
  }
`;
