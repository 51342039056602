import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import { RowStyled, FormGroupStyled, InputStyled } from '~/Components/Styled';

import ToggleComponent from '~/Components/Toggle';

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0 20px 0;
  label {
    display: flex;
    flex: 1;
    align-items: center;
    > b {
      margin-right: 10px;
    }
    input {
      display: flex;
      flex: 1;
    }
    a {
      color: ${props => props.theme.colors.primary};
      margin-left: 10px;
    }
  }
`;

export const Input = styled(InputStyled)``;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const Select = styled(ReactSelect)`
  width: 100%;
  margin-bottom: 10px;
  margin-right: 15px;
  border: 0;
  [class*="-control"] {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 4px;
    outline: none;
  }
`;

export const Toggle = styled(ToggleComponent).attrs({
  style: {
    marginBottom: 10,
    height: 40,
    width: 130,
  },
})``;

export const UploadArea = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  section {
    border: 3px dashed ${props => props.theme.colors.light};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100px;
    font-size: 18px;
    cursor: pointer;
    outline: none;
    > div {
      padding: 20px;
      outline: none;
      flex: 1;
      height: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        margin: 0;
        outline: none;
      }
    }

  }

  ${props => props.dropFile
    && css`
      section {
        border-color: ${props.theme.colors.checked};
      }
    `}
`;
