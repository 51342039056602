/* eslint-disable max-len */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { sortableContainer, sortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import {
  FaFont, FaPencilAlt, FaRegCalendarAlt, FaRegCheckSquare, FaRegDotCircle,
} from 'react-icons/fa';
import { validateUrl, xmlIsValid } from '~/Utils';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import UploadService, { baseUrlMedia } from '~/services/UploadService';
import MediaItemAdd from './components/ItemAdd';
import MediaItemMedia from './components/ItemMedia';
import Upload from '~/Components/Upload';
import Button from '~/Components/Button';

import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';
import { ModalSurveyEdit } from '../../Components/InternalMediaComponent/Form/components/TabContent/components/ModalSurveyEdit';

import {
  FormGroup,
  Input,
  Row,
  //
  WrapContent,
  ListMedia,
  ListMediaItem,
  FormMediaWrap,
  FormMedia,
  Select,
  Textarea,
  MidiaVideoPreview,
  LabelXmlValid,
  PreviewVideoBox,
  ErrorMessage,
  RowTypeHeader,
  BasePerguntaQualificadora,
  BasePerguntaQualificadoraHeader,
  BasePerguntaQualificadoraContent,
  ButtonEditForm,
  PerguntaQualificadoraList,
  PerguntaQualificadoraItem,
} from './styles';

const PROXY_VAST_OPTIONS = [
  { label: 'Habilitado', value: true }, { label: 'Desabilitado', value: false },
];

const TYPE_LIST = [
  { label: 'Sem mídia', value: '' },
  {
    label: 'Vídeo',
    value: CreativeCustomMidiaType.VIDEO,
    mainOptions: {
      content: '',
    },
  },
  {
    label: 'Vast',
    value: CreativeCustomMidiaType.VAST,
    mainOptions: {
      content: '',
    },
  },
  {
    label: 'Vast com pergunta qualificadora',
    value: CreativeCustomMidiaType.VAST_SURVEY,
    mainOptions: {
      content: '',
      thumbnail: '',
      dataSearch: {
        questions: [],
      },
    },
  },
  {
    label: 'Vpaid',
    value: CreativeCustomMidiaType.VPAID,
    mainOptions: {
      content: '',
    },
  },
  {
    label: 'Vpaid com pergunta qualificadora',
    value: CreativeCustomMidiaType.VPAID_SURVEY,
    mainOptions: {
      content: '',
      thumbnail: '',
      dataSearch: {
        questions: [],
      },
    },
  },
  {
    label: 'Programática',
    value: CreativeCustomMidiaType.PROGRAMATICA,
    mainOptions: {
      content: '',
      viewed: false,
      config: {
        type: 'google-admob-rv',
        params: [{
          key: 'id',
          value: '',
        }],
      },
    },
  },
];

class SMSMediaMessageForm extends Component {
  state = {
    selectedItem: null,
    messageError: false,
    formSurvey: {
      show: false,
      contentCopy: null,
      formHasError: false,
    },
  };

  uploadFileToAWS = async (campaignUuid, file) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;

    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  uploadFileVideo = async (files) => {
    const { campaign } = this.props;
    const file = files[0];
    if (file) {
      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.setSelectedData({ content: url });
    }
  };

  setSelectedData = (data) => {
    const { selectedItem } = this.state;
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
    } = this.props;

    if (data.type === 'programatica' && !data.config) {
      data = {
        ...data,
        config: {
          type: 'google-admob-rv',
          params: [{
            key: 'id',
            value: '',
          }],
        },
      };
    }
    const item = {
      ...selectedItem,
      ...data,
    };
    this.setState({ selectedItem: item }, () => {
      campaignEditCriativoRequestSuccess({
        ...criativoEdit,
        content: {
          ...criativoEdit.content,
          mainData: {
            ...criativoEdit.content.mainData,
            media: criativoEdit.content.mainData.media.map((i) => {
              if (i.uuid === item.uuid) {
                return item;
              }
              return i;
            }),
          },
        },
      });
    });
  };

  renderFormProgramatica = () => {
    const { selectedItem } = this.state;
    const { hasError, disabledFields } = this.props;

    const typeList = [
      {
        label: 'Google Admob',
        value: 'google-admob-rv',
      },
      {
        label: 'inteliFI',
        value: 'intelifi',
      },
    ];

    return (
      <>
        <Row style={{ marginBottom: 10 }}>
          <FormGroup style={{ flex: 1 }}>
            <label>
              Integração
              <span>*</span>
            </label>
            <Select
              isDisabled={disabledFields}
              placeholder="Selecione Integração..."
              value={typeList.find(item => item.value === selectedItem.config.type)}
              onChange={({ value }) => {
                this.setSelectedData({
                  config: {
                    ...selectedItem.config,
                    type: value,
                  },
                });
              }}
              options={typeList}
              invalid={hasError && !selectedItem.config.type}
            />
          </FormGroup>
        </Row>
        <Row style={{ marginBottom: 10 }}>
          <FormGroup style={{ flex: 1 }}>
            <label>
              Chave
              <span>*</span>
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite a chave"
              maxLength={100}
              onChange={(e) => {
                const newData = selectedItem.config.params[0];
                newData.value = e.target.value;
                this.setSelectedData({
                  config: {
                    ...selectedItem.config,
                    params: [newData],
                  },
                });
              }}
              value={selectedItem.config.params[0].value}
              invalid={hasError && !selectedItem.config.params[0].value}
              autoFocus
            />
          </FormGroup>
        </Row>
      </>
    );
  }

  renderRerguntaQualificadoraItem = () => {
    const { selectedItem, formSurvey } = this.state;
    if (!selectedItem) return null;

    const {
      hasError,
      disabledFields,
    } = this.props;


    const onChangeForm = (data) => {
      this.setSelectedData({ dataSearch: data });
    };

    const getIconQuestionType = (questionType) => {
      switch (questionType) {
        case 'check':
          return <FaRegCheckSquare />;
        case 'radio':
          return <FaRegDotCircle />;
        case 'text':
          return <FaFont />;
        case 'date':
          return <FaRegCalendarAlt />;
        default:
          return null;
      }
    };

    const questions = selectedItem.dataSearch && selectedItem.dataSearch.questions ? selectedItem.dataSearch.questions : [];

    return (
      <>
        <Row>
          <FormGroup style={{ flex: 1, marginTop: 10, marginBottom: 10 }}>
            <label>
              Pergunta qualificadora
              <span>*</span>
            </label>
            <BasePerguntaQualificadora invalid={hasError && questions.length === 0}>
              <BasePerguntaQualificadoraHeader invalid={hasError && questions.length === 0}>
                <ButtonEditForm
                  disabled={disabledFields}
                  onClick={() => {
                    this.setState({
                      formSurvey: {
                        show: true,
                        contentCopy: { ...selectedItem.dataSearch },
                        formHasError: false,
                      },
                    });
                  }}
                >
                  Editar perguntas
                  <FaPencilAlt />
                </ButtonEditForm>
              </BasePerguntaQualificadoraHeader>
              <BasePerguntaQualificadoraContent>
                {questions.length === 0 && (<span>Sem perguntas cadastradas</span>)}
                {questions.length > 0 && (
                  <PerguntaQualificadoraList>
                    {questions.map((q, index) => (
                      <PerguntaQualificadoraItem key={q.uuid}>
                        {getIconQuestionType(q.type)}
                        <span>{`${index + 1}#`}</span>
                        <p>{q.title}</p>
                      </PerguntaQualificadoraItem>
                    ))}
                  </PerguntaQualificadoraList>
                )}
              </BasePerguntaQualificadoraContent>
            </BasePerguntaQualificadora>
          </FormGroup>
        </Row>
        <ModalSurveyEdit
          modalOpen={formSurvey.show}
          disabledFields={disabledFields}
          onChangeForm={onChangeForm}
          content={selectedItem.dataSearch}
          formModalOriginal={formSurvey.contentCopy}
          formHasError={formSurvey.formHasError}
          onCloseModal={() => {
            onChangeForm({
              ...selectedItem.dataSearch,
              ...formSurvey.contentCopy,
            });
            this.setState({
              formSurvey: {
                ...formSurvey,
                show: false,
                contentCopy: null,
                formHasError: false,
              },
            });
          }}
          onCancelar={() => {
            onChangeForm({
              ...selectedItem.dataSearch,
              ...formSurvey.contentCopy,
            });
            this.setState({
              formSurvey: {
                ...formSurvey,
                show: false,
                contentCopy: null,
                formHasError: false,
              },
            });
          }}
          onConfirmar={(formIsValid) => {
            if (formIsValid) {
              this.setState({
                formSurvey: {
                  ...formSurvey,
                  show: false,
                  formHasError: false,
                },
              });
            } else {
              this.setState({
                formSurvey: {
                  ...formSurvey,
                  formHasError: true,
                },
              });
            }
          }}
        />
      </>
    );
  }

  renderFormVast = () => {
    const { selectedItem } = this.state;
    const { hasError, disabledFields } = this.props;
    return (
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            XML
            <span>*</span>
          </label>
          <Textarea
            disabled={disabledFields}
            rows="10"
            placeholder="XML"
            onChange={e => this.setSelectedData({ content: e.target.value })}
            value={selectedItem.content}
            invalid={hasError && (!selectedItem.content || !xmlIsValid(selectedItem.content))}
          />
          {!!selectedItem.content && (
          <LabelXmlValid isValid={xmlIsValid(selectedItem.content)}>
            <span className="label-xml-valid">XML válido</span>
            <span className="label-xml-invalid">XML inválido</span>
          </LabelXmlValid>
          )}
        </FormGroup>
      </Row>
    );
  }

  renderFormVastSurvey = () => {
    const { selectedItem } = this.state;
    const { hasError, disabledFields } = this.props;
    return (
      <>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <label>
              XML
              <span>*</span>
            </label>
            <Textarea
              disabled={disabledFields}
              rows="10"
              placeholder="XML"
              onChange={e => this.setSelectedData({ content: e.target.value })}
              value={selectedItem.content}
              invalid={hasError && (!selectedItem.content || !xmlIsValid(selectedItem.content))}
            />
            {!!selectedItem.content && (
            <LabelXmlValid isValid={xmlIsValid(selectedItem.content)}>
              <span className="label-xml-valid">XML válido</span>
              <span className="label-xml-invalid">XML inválido</span>
            </LabelXmlValid>
            )}
          </FormGroup>
        </Row>
        {this.renderRerguntaQualificadoraItem()}
      </>
    );
  }

  renderFormVpaid = () => {
    const { selectedItem } = this.state;
    const { hasError, disabledFields } = this.props;
    return (
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            URL
            <span>*</span>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite a url"
            onChange={e => this.setSelectedData({ content: e.target.value })}
            value={selectedItem.content}
            invalid={hasError && (!selectedItem.content || !validateUrl(selectedItem.content))}
            autoFocus
          />
        </FormGroup>
      </Row>
    );
  };

  renderFormVpaidSurvey = () => {
    const { selectedItem } = this.state;
    const { hasError, disabledFields } = this.props;
    return (
      <>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <label>
              URL
              <span>*</span>
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite a url"
              onChange={e => this.setSelectedData({ content: e.target.value })}
              value={selectedItem.content}
              invalid={hasError && (!selectedItem.content || !validateUrl(selectedItem.content))}
              autoFocus
            />
          </FormGroup>
        </Row>
        {this.renderRerguntaQualificadoraItem()}
      </>
    );
  };

  renderFormVideo = () => {
    const { selectedItem, messageError } = this.state;
    const { hasError, disabledFields } = this.props;

    return (
      <>
        <Row>
          <FormGroup style={{ flex: 1 }}>
            <div style={{
              display: 'flex', flex: 1, marginTop: 10, justifyContent: 'center',
            }}
            >
              <Upload
                disabled={disabledFields}
                label={selectedItem.content ? 'Alterar mídia' : 'Buscar mídia'}
                onUpload={this.uploadFileVideo}
                accept="video/mp4"
                maxSize={2000000}
                onRejectMaxSize={() => {
                  this.setState({ messageError: 'A imagem excedeu o limite de 2MB' });
                }}
                onClick={() => {
                  this.setState({ messageError: null });
                }}
                styleContainer={{
                  width: 200,
                }}
                style={{
                  width: 200,
                  margin: 0,
                }}
              />
              {!!selectedItem.content && !disabledFields && (
              <Button
                disabled={disabledFields}
                title="REMOVER"
                onClick={() => this.setSelectedData({ content: '' })}
              />
              )}
            </div>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup style={{ flex: 1, alignItems: 'center' }}>
            <PreviewVideoBox
              hasVideo={!!selectedItem.content}
              invalid={hasError && !selectedItem.content}
            >
              {selectedItem.content ? (
                <MidiaVideoPreview controls>
                  <source src={selectedItem.content} />
                  Seu browser não suporta o preview de vídeos
                </MidiaVideoPreview>
              ) : 'Preview do vídeo'}
            </PreviewVideoBox>
          </FormGroup>
        </Row>
        {messageError && (
          <Row>
            <ErrorMessage>{messageError}</ErrorMessage>
          </Row>
        )}
      </>
    );
  }

  getZoneProxyVast = () => {
    const {
      campaign: { zone: { extra } },
    } = this.props;
    console.log(extra);
    if (!!extra && !!extra.creatives && !!extra.creatives.internal && !!extra.creatives.internal.medias && !!extra.creatives.internal.medias.vast) {
      const { proxy } = extra.creatives.internal.medias.vast;
      return proxy;
    }
    return null;
  }

  getZoneProxyVpaid = () => {
    const {
      campaign: { zone: { extra } },
    } = this.props;
    if (!!extra && !!extra.creatives && !!extra.creatives.internal && !!extra.creatives.internal.medias && !!extra.creatives.internal.medias.vpaid) {
      const { proxy } = extra.creatives.internal.medias.vpaid;
      return proxy;
    }
    return null;
  }

  renderProxyVastFormHeader = () => {
    const { disabledFields } = this.props;
    const { selectedItem } = this.state;
    if (!selectedItem) return null;

    const zoneProxyVast = this.getZoneProxyVast();

    if (!zoneProxyVast) {
      return null;
    }

    if (selectedItem.proxy !== true && selectedItem.proxy !== false) {
      this.setSelectedData({ proxy: zoneProxyVast.default });
    }

    if (!zoneProxyVast.editable) {
      return null;
    }

    const valueItem = selectedItem.proxy !== undefined && selectedItem.proxy !== null ? selectedItem.proxy : zoneProxyVast.default;

    return (
      <FormGroup>
        <label>
          Proxy interno
          <span>*</span>
        </label>
        <Select
          isDisabled={disabledFields}
          placeholder="Selecione a opção..."
          value={PROXY_VAST_OPTIONS.find(p => p.value === valueItem)}
          onChange={(item) => {
            this.setSelectedData({ proxy: item.value });
          }}
          options={PROXY_VAST_OPTIONS}
        />
      </FormGroup>
    );
  }

  renderProxyVpaidFormHeader = () => {
    // const { disabledFields } = this.props;
    const { selectedItem } = this.state;
    if (!selectedItem) return null;

    if (selectedItem.proxy !== false) {
      this.setSelectedData({ proxy: false });
    }
    return null;

    // const zoneProxyVpaid = this.getZoneProxyVpaid();

    // if (!zoneProxyVpaid) {
    //   return null;
    // }

    // if (selectedItem.proxy !== true && selectedItem.proxy !== false) {
    //   this.setSelectedData({ proxy: zoneProxyVpaid.default });
    // }

    // if (!zoneProxyVpaid.editable) {
    //   return null;
    // }

    // const valueItem = selectedItem.proxy !== undefined && selectedItem.proxy !== null ? selectedItem.proxy : zoneProxyVpaid.default;

    // return (
    //   <FormGroup>
    //     <label>
    //       Proxy interno
    //       <span>*</span>
    //     </label>
    //     <Select
    //       isDisabled={disabledFields}
    //       placeholder="Selecione a opção..."
    //       value={PROXY_VAST_OPTIONS.find(p => p.value === valueItem)}
    //       onChange={(item) => {
    //         this.setSelectedData({ proxy: item.value });
    //       }}
    //       options={PROXY_VAST_OPTIONS}
    //     />
    //   </FormGroup>
    // );
  }

  renderSubtypeFormHeader = () => {
    const { selectedItem } = this.state;

    if (!selectedItem) {
      return null;
    }

    const { type } = selectedItem;

    if (type === CreativeCustomMidiaType.VAST || type === CreativeCustomMidiaType.VAST_SURVEY) {
      return this.renderProxyVastFormHeader();
    } if (type === CreativeCustomMidiaType.VPAID || type === CreativeCustomMidiaType.VPAID_SURVEY) {
      return this.renderProxyVpaidFormHeader();
    }
    return null;
  }

  renderForm = () => {
    const { selectedItem } = this.state;
    if (!selectedItem) return null;

    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      // campaign,
      disabledFields,
      confirmModal,
    } = this.props;

    return (
      <FormMediaWrap>
        <FormMedia>
          <Row>
            <FormGroup style={{ flex: 1 }}>
              <label>
                Título
                <span>*</span>
                -
                <small>{` ${selectedItem.title.length || 0}/100 caracteres`}</small>
              </label>
              <Input
                disabled={disabledFields}
                placeholder="Digite o título"
                maxLength={100}
                onChange={e => this.setSelectedData({ title: e.target.value })}
                value={selectedItem.title}
                invalid={hasError && !selectedItem.title}
                autoFocus
              />
            </FormGroup>
          </Row>
          <RowTypeHeader>
            <FormGroup>
              <label>
                Tipo
                <span>*</span>
              </label>
              <Select
                isDisabled={disabledFields}
                placeholder="Selecione Tipo..."
                value={TYPE_LIST.find(item => item.value === selectedItem.type)}
                onChange={({ value, mainOptions }) => {
                  this.setSelectedData({ type: value, ...mainOptions });
                }}
                options={TYPE_LIST}
                invalid={hasError && !selectedItem.type}
              />
            </FormGroup>
            {this.renderSubtypeFormHeader()}
          </RowTypeHeader>
          {selectedItem.type === CreativeCustomMidiaType.VAST && this.renderFormVast()}
          {selectedItem.type === CreativeCustomMidiaType.VAST_SURVEY && this.renderFormVastSurvey()}
          {selectedItem.type === CreativeCustomMidiaType.VPAID && this.renderFormVpaid()}
          {selectedItem.type === CreativeCustomMidiaType.VPAID_SURVEY && this.renderFormVpaidSurvey()}
          {selectedItem.type === CreativeCustomMidiaType.VIDEO && this.renderFormVideo()}
          {selectedItem.type === CreativeCustomMidiaType.PROGRAMATICA && this.renderFormProgramatica()}
        </FormMedia>
        <button
          type="button"
          onClick={() => {
            confirmModal(
              'Excluir',
              'Tem certeza que deseja excluir esta mídia?',
              () => {
                this.setState({ selectedItem: null }, () => {
                  campaignEditCriativoRequestSuccess({
                    ...criativoEdit,
                    content: {
                      ...criativoEdit.content,
                      mainData: {
                        ...criativoEdit.content.mainData,
                        media: criativoEdit.content.mainData.media.filter(i => i.uuid !== selectedItem.uuid),
                      },
                    },
                  });
                });
              },
            );
          }}
        >
          Excluir mídia
        </button>
      </FormMediaWrap>
    );
  };

  renderMediaList = () => {
    const { selectedItem } = this.state;
    const {
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      // campaign,
      // disabledFields,
    } = this.props;

    const { content } = criativoEdit;
    const listMedia = content.mainData && content.mainData.media ? content.mainData.media : [];

    const addNewItemAfter = (uuid) => {
      this.setState({ selectedItem: null }, () => {
        const newListMedia = [];
        const newItem = {
          uuid: uuid4(),
          type: '',
          title: '',
          content: '',
        };
        listMedia.forEach((i) => {
          newListMedia.push(i);
          if (i.uuid === uuid) {
            newListMedia.push(newItem);
          }
        });
        this.setState({ selectedItem: newItem }, () => {
          campaignEditCriativoRequestSuccess({
            ...criativoEdit,
            content: {
              ...criativoEdit.content,
              mainData: {
                ...criativoEdit.content.mainData,
                media: newListMedia,
              },
            },
          });
        });
      });
    };

    const SortableItem = sortableElement((element) => {
      const item = element.value;
      return (
        <ListMediaItem>
          <MediaItemMedia
            onClick={() => {
              this.setState({ selectedItem: null }, () => {
                this.setState({ selectedItem: item });
              });
            }}
            selected={!!selectedItem && selectedItem.uuid === item.uuid}
            hasError={
                hasError
                && (
                  !item.type
                  || !item.title
                  || !item.content
                  || (item.type === CreativeCustomMidiaType.VAST && !xmlIsValid(item.content))
                  || (
                    item.type === CreativeCustomMidiaType.VAST_SURVEY
                    && (
                      !xmlIsValid(item.content)
                      || !item.dataSearch
                      || !item.dataSearch.questions
                      || item.dataSearch.questions.length <= 0
                    )
                  )
                  || (item.type === CreativeCustomMidiaType.VPAID && !validateUrl(item.content))
                  || (
                    item.type === CreativeCustomMidiaType.VPAID_SURVEY
                    && (
                      !validateUrl(item.content)
                      || !item.dataSearch
                      || !item.dataSearch.questions
                      || item.dataSearch.questions.length <= 0
                    )
                  )
                )
              }
            data={item}
          />
          <MediaItemAdd onClick={() => addNewItemAfter(item.uuid)} />
        </ListMediaItem>
      );
    });

    const SortableContainer = sortableContainer(({ children }) => <ListMedia>{children}</ListMedia>);

    const onSortEnd = ({ oldIndex, newIndex }) => {
      campaignEditCriativoRequestSuccess({
        ...criativoEdit,
        content: {
          ...criativoEdit.content,
          mainData: {
            ...criativoEdit.content.mainData,
            media: arrayMove(criativoEdit.content.mainData.media, oldIndex, newIndex),
          },
        },
      });
    };

    return (
      <SortableContainer
        helperClass="sortable-item-move"
        onSortEnd={onSortEnd}
      >
        {listMedia.length > 0 ? listMedia.map((item, index) => (
          <SortableItem
            key={item.uuid}
            index={index}
            sortIndex={index}
            value={item}
          />
        )) : (
          <MediaItemAdd
            onClick={() => {
              const newItem = {
                uuid: uuid4(),
                type: '',
                title: '',
                content: '',
              };
              this.setState({ selectedItem: newItem }, () => {
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: {
                    ...criativoEdit.content,
                    mainData: {
                      ...criativoEdit.content.mainData,
                      media: [newItem],
                    },
                  },
                });
              });
            }}
          />
        )}
      </SortableContainer>
    );
  };

  render() {
    return (
      <WrapContent>
        {this.renderMediaList()}
        {this.renderForm()}
      </WrapContent>
    );
  }
}

SMSMediaMessageForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  confirmModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(SMSMediaMessageForm);
