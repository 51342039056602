/* eslint-disable no-console */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import { FaRegCalendarAlt } from 'react-icons/fa';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import pt from 'date-fns/locale/pt-BR';

import Checkbox from '~/Components/Checkbox';
import Radiobox from '~/Components/Radiobox';
import Button from '~/Components/Button';

import {
  PreviewContent,
  PreviewHeader,
  PreviewForm,
  PreviewFooter,
  Stepsbox,
  StepItem,
  FormItem,
  FormTitle,
  InputMask,
  EditText,
  WrapBoxDate,
  EditDatePicker,
} from './styles';

registerLocale('pt-BR', pt);

class PreviewResearch extends Component {
  state = {
    step: 0,
    dateState: [],
    checkeds: [],
  };

  componentDidMount() {}

  renderCheckItem = (item) => {
    const { checkeds } = this.state;
    return (
      <FormItem>
        <FormTitle>{item.title}</FormTitle>
        {item.options.map(answer => (
          <Checkbox
            key={answer.uuid}
            label={answer.description}
            colorChecked="#4332ff"
            value={checkeds.includes(answer.uuid)}
            onChange={(checked) => {
              if (checked) {
                this.setState({
                  checkeds: [
                    ...checkeds,
                    answer.uuid,
                  ],
                });
              } else {
                this.setState({
                  checkeds: checkeds.filter(ck => ck !== answer.uuid),
                });
              }
            }}
          />
        ))}
      </FormItem>
    );
  };

  renderRadioItem = item => (
    <FormItem>
      <FormTitle>{item.title}</FormTitle>
      {item.options.map(answer => (
        <Radiobox
          key={answer.uuid}
          label={answer.description}
          colorChecked="#4332ff"
          onChange={() => {}}
        />
      ))}
    </FormItem>
  );

  renderTextItem = (item) => {
    const { theme } = this.props;
    return (
      <FormItem>
        <FormTitle>{item.title}</FormTitle>
        {item.fieldType === 'text' && (
          <EditText
            placeholder=""
            color={theme.colors.dark}
          />
        )}
        {item.fieldType === 'cpf' && (
          <InputMask
            className="field-date"
            mask="999.999.999-99"
            placeholder="___.___.___-__"
          />
        )}
        {item.fieldType === 'phone' && (
          <InputMask
            className="field-date"
            mask="(99) 99999-9999"
            placeholder="(__) _____-____"
          />
        )}
        {item.fieldType === 'cep' && (
          <InputMask
            className="field-date"
            mask="99999-999"
            placeholder="_____-___"
          />
        )}
      </FormItem>
    );
  };

  renderNumberItem = () => null;

  renderRateItem = () => null;

  renderDateItem = (item) => {
    const { dateState } = this.state;
    const dateMinType = item.dateMinType || '';
    const dateMaxType = item.dateMaxType || '';
    const pickerParams = {};
    const hasDate = dateState.find(date => date.uuid === item.uuid);

    const changeState = (date) => {
      if (!hasDate) {
        this.setState({
          dateState: [
            ...dateState,
            { uuid: item.uuid, date },
          ],
        });
      } else {
        this.setState({
          dateState: dateState.map((dateItem) => {
            if (dateItem.uuid === item.uuid) {
              return {
                ...dateItem,
                date,
              };
            }
            return dateItem;
          }),
        });
      }
    };

    switch (dateMinType) {
      case 'no-limit': {
        // pickerParams.minDate = '';
        break;
      }
      case 'now': {
        pickerParams.minDate = moment().toDate();
        break;
      }
      case 'yesterday': {
        pickerParams.minDate = moment().subtract(1, 'days').toDate();
        break;
      }
      case 'tomorrow': {
        pickerParams.minDate = moment().add(1, 'days').toDate();
        break;
      }
      case 'fixed': {
        try {
          pickerParams.minDate = moment(item.dateMin, 'DD/MM/YYYY').toDate();
        } catch (error) {
          //
        }
        break;
      }
      default:
        break;
    }

    switch (dateMaxType) {
      case 'no-limit': {
        // pickerParams.maxDate = '';
        break;
      }
      case 'now': {
        pickerParams.maxDate = moment().toDate();
        break;
      }
      case 'yesterday': {
        pickerParams.maxDate = moment().subtract(1, 'days').toDate();
        break;
      }
      case 'tomorrow': {
        pickerParams.maxDate = moment().add(1, 'days').toDate();
        break;
      }
      case 'fixed': {
        try {
          pickerParams.maxDate = moment(item.dateMax, 'DD/MM/YYYY').toDate();
        } catch (error) {
          //
        }
        break;
      }
      default:
        break;
    }

    return (
      <FormItem>
        <FormTitle>{item.title}</FormTitle>
        <WrapBoxDate>
          {!item.acceptHour && (
            <EditDatePicker
              selected={hasDate ? hasDate.date : null}
              onChange={changeState}
              placeholderText="__/__/____"
              dateFormat="dd/MM/yyyy"
              locale="pt-BR"
              customInput={(
                <ReactInputMask
                  mask="99/99/9999"
                  value={hasDate ? moment(hasDate.date).format('DD/MM/YYYY') : null}
                />
              )}
              {...pickerParams}
            />
          )}
          {item.acceptHour && (
            <EditDatePicker
              selected={hasDate ? hasDate.date : null}
              onChange={changeState}
              placeholderText="__/__/____ __:__"
              timeInputLabel="Hora:"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeInput
              locale="pt-BR"
              customInput={(
                <ReactInputMask
                  mask="99/99/9999 99:99"
                  value={hasDate ? moment(hasDate.date).format('DD/MM/YYYY HH:mm') : null}
                />
              )}
              {...pickerParams}
            />
          )}
          <div className="icon-calendar">
            <FaRegCalendarAlt />
          </div>
        </WrapBoxDate>
      </FormItem>
    );
  };

  renderQuestion = (question) => {
    switch (question.type) {
      case 'check': return this.renderCheckItem(question);
      case 'radio': return this.renderRadioItem(question);
      case 'text': return this.renderTextItem(question);
      case 'number': return this.renderNumberItem(question);
      case 'rate': return this.renderRateItem(question);
      case 'date': return this.renderDateItem(question);
      default: return null;
    }
  };

  renderMessage = () => {
    const {
      data, config, theme, style,
    } = this.props;
    const { step } = this.state;
    const configData = config || {
      primaryColor: theme.colors.primaryHex,
      secondaryColor: theme.colors.white,
    };
    return (
      <PreviewContent style={style}>
        <PreviewHeader>
          <Stepsbox>
            {data.questions.map((item, index) => (
              <StepItem color={configData.primaryColor} active={step === index} />
            ))}
          </Stepsbox>
        </PreviewHeader>
        <PreviewForm>
          {data.questions.length > 0 && this.renderQuestion(data.questions[step])}
        </PreviewForm>
        <PreviewFooter>
          <Button
            disabled={step === 0}
            type="button"
            bordered
            title="Anterior"
            onClick={() => {
              this.setState({ step: step - 1 });
            }}
            style={{
              margin: 0,
              height: 35,
              fontSize: 16,
              backgroundColor: 'transparent',
              color: configData.primaryColor,
              borderColor: configData.primaryColor,
            }}
          />
          <Button
            disabled={data.questions.length === 0 || step === (data.questions.length - 1)}
            type="button"
            title="Próximo"
            onClick={() => {
              this.setState({ step: step + 1 });
            }}
            style={{
              margin: 0,
              height: 35,
              fontSize: 16,
              backgroundColor: configData.primaryColor,
              color: configData.secondaryColor,
            }}
          />
        </PreviewFooter>
      </PreviewContent>
    );
  };

  render() {
    return this.renderMessage();
  }
}

PreviewResearch.defaultProps = {
  data: null,
  config: null,
  style: null,
};

PreviewResearch.propTypes = {
  data: PropTypes.shape(),
  theme: PropTypes.shape().isRequired,
  config: PropTypes.shape({
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
  }),
  style: PropTypes.shape(),
};

export default withTheme(PreviewResearch);
