import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import { FaTimes } from 'react-icons/fa';
import Modal from '~/Components/Modal';

import Button from '~/Components/Button';

import {
  ContentModal,
  Header,
  CloseHeader,
  Body,
  Footer,
  Row,
  FormNew,
  FormGroup,
  Input,
} from './styles';

const ModalAdvertiser = ({ onClose, theme }) => {
  const renderForm = () => (
    <FormNew>
      <Row>
        <FormGroup>
          <label>
            Razão Social:
            <span>*</span>
          </label>
          <Input
            invalid={false}
            maxLength="60"
            placeholder="Razão Social"
            value={null}
            onChange={e => e.target.value}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup spaceRight={20}>
          <label>
            CNPJ:
            <span>*</span>
          </label>
          <Input
            invalid={false}
            maxLength="60"
            placeholder="CNPJ"
            value={null}
            onChange={e => e.target.value}
          />
        </FormGroup>
        <FormGroup>
          <label>
            Inscrição Estadual:
            <span>*</span>
          </label>
          <Input
            invalid={false}
            maxLength="60"
            placeholder="Inscrição Estadual"
            value={null}
            onChange={e => e.target.value}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <label>
            Endereço:
            <span>*</span>
          </label>
          <Input
            invalid={false}
            maxLength="60"
            placeholder="Endereço"
            value={null}
            onChange={e => e.target.value}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup spaceRight={20}>
          <label>
            CEP:
            <span>*</span>
          </label>
          <Input
            invalid={false}
            maxLength="60"
            placeholder="CEP"
            value={null}
            onChange={e => e.target.value}
          />
        </FormGroup>
        <FormGroup>
          <label>
            Município/UF:
            <span>*</span>
          </label>
          <Input
            invalid={false}
            maxLength="60"
            placeholder="Município/UF"
            value={null}
            onChange={e => e.target.value}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <label>
            Contato:
            <span>*</span>
          </label>
          <Input
            invalid={false}
            maxLength="60"
            placeholder="Contato"
            value={null}
            onChange={e => e.target.value}
          />
        </FormGroup>
      </Row>
    </FormNew>
  );

  return (
    <Modal width={750} padding={0}>
      <ContentModal>
        <Header>
          <h1>Cadastro de Anunciante</h1>
          <CloseHeader onClick={() => {
            onClose();
          }}
          >
            <FaTimes color={theme.colors.darker} size={24} />
          </CloseHeader>
        </Header>
        <Body>
          {renderForm()}
        </Body>
        <Footer>
          <Button
            disabled={false}
            onClick={() => {}}
            title="Salvar"
          />
        </Footer>
      </ContentModal>
    </Modal>
  );
};

ModalAdvertiser.defaultProps = {
  onClose: () => {},
};

ModalAdvertiser.propTypes = {
  onClose: PropTypes.func,
  theme: PropTypes.shape().isRequired,
};

export default withTheme(ModalAdvertiser);
