import React from 'react';
import PropTypes from 'prop-types';
import FileUploadService from '~/services/FileUploadService';

import {
  BoxLogo,
} from './styles';

class LogoPreview extends React.Component {
  state = {
    image: null,
  };

  componentDidMount() {
    const { image } = this.state;
    if (!image) this.loadImage();
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  loadImage = async () => {
    const { logo } = this.props;
    const file = await FileUploadService.getFile(logo);
    const image = await this.toBase64(file);
    this.setState({ image });
  };

  render() {
    const { image } = this.state;
    return !!image && (
      <BoxLogo>
        <img src={image} alt="logo" />
      </BoxLogo>
    );
  }
}

LogoPreview.propTypes = {
  logo: PropTypes.string.isRequired,
};

export default LogoPreview;
