import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as NewGridPeriodCreators } from '~/redux/ducks/newGridPeriod';

import { general } from '~/Styles';
import {
  ButtonGridPeriodIcon,
  PlanSheetIcon,
} from '../../../Components';

import {
  Container,
  ModalBox,
  Content,
  StyledDiv,
  Table,
  TableInput,
  ModalFooter,
  Button,
  Select,
} from './styles';

const optionsYesNo = [
  { value: true, label: 'Sim' },
  { value: false, label: 'Não' },
];

function ModalInitCloseWeekly({
  showModalInitCloseWeekly, closeModalInitCloseWeekly,
  geral, auction, probe, fixedCpa, bonus,
  updateConfigsGeral, updateConfigsAuction,
  updateConfigsProbe, updateConfigsFixedCpa,
  updateConfigsBonus,
  confirmStartGridPeriod,
}) {
  if (!showModalInitCloseWeekly) {
    return null;
  }

  function onCloseModalInitCloseWeekly() {
    closeModalInitCloseWeekly();
  }

  function updateConfigGeral(zoneItem) {
    updateConfigsGeral({
      ...geral,
      zones: [
        ...geral.zones.map(z => (z.zone.uuid === zoneItem.zone.uuid ? zoneItem : z)),
      ],
    });
  }

  function updateConfigZoneAuction(zoneItem) {
    updateConfigsAuction({
      ...auction,
      zones: [
        ...auction.zones.map(z => (z.zone.uuid === zoneItem.zone.uuid ? zoneItem : z)),
      ],
    });
  }

  function updateConfigZoneProbe(zoneItem) {
    updateConfigsProbe({
      ...probe,
      zones: [
        ...probe.zones.map(z => (z.zone.uuid === zoneItem.zone.uuid ? zoneItem : z)),
      ],
    });
  }

  function updateConfigZoneFixedCpa(zoneItem) {
    updateConfigsFixedCpa({
      ...fixedCpa,
      zones: [
        ...fixedCpa.zones.map(z => (z.zone.uuid === zoneItem.zone.uuid ? zoneItem : z)),
      ],
    });
  }

  function updateConfigZoneBonus(zoneItem) {
    updateConfigsBonus({
      ...bonus,
      zones: [
        ...bonus.zones.map(z => (z.zone.uuid === zoneItem.zone.uuid ? zoneItem : z)),
      ],
    });
  }

  function renderGeralConfigs() {
    if (!geral) {
      return null;
    }

    return (
      <StyledDiv bordered>
        <h3>
          GERAL
        </h3>
        <Table>
          <thead>
            <tr>
              <th>Canal</th>
              <th>Ativo</th>
              <th>Modo vazão semanal</th>
              <th>Modo validação IVR</th>
            </tr>
          </thead>
          <tbody>
            {geral.zones.map(item => (
              <tr key={item.zone.uuid}>
                <td>{`${item.zone.channel.name} (${item.zone.name})`}</td>
                <td style={{ width: 180 }}>
                  <div className="input-div">
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      width="150"
                      options={optionsYesNo}
                      value={optionsYesNo.find(i => i.value === item.zoneActive)}
                      onChange={(e) => {
                        updateConfigGeral({ ...item, zoneActive: e.value });
                      }}
                    />
                  </div>
                </td>
                <td style={{ width: 180 }}>
                  <div className="input-div">
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      width="150"
                      options={optionsYesNo}
                      value={optionsYesNo.find(i => i.value === item.modeVolumeWeekly)}
                      onChange={(e) => {
                        updateConfigGeral({ ...item, modeVolumeWeekly: e.value });
                      }}
                    />
                  </div>
                </td>
                <td style={{ width: 180 }}>
                  <div className="input-div">
                    <Select
                      className="react-select-container"
                      classNamePrefix="react-select"
                      width="150"
                      options={optionsYesNo}
                      value={optionsYesNo.find(i => i.value === item.modeValidateIVR)}
                      onChange={(e) => {
                        updateConfigGeral({ ...item, modeValidateIVR: e.value });
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </StyledDiv>
    );
  }

  function renderAuctionConfigs() {
    if (!auction) {
      return null;
    }

    return (
      <StyledDiv bordered>
        <h3>
          LEILÃO
        </h3>
        <Table>
          <thead>
            <tr>
              <th>Canal</th>
              <th>Nº de ganhadores</th>
              <th>Nº de reservas</th>
            </tr>
          </thead>
          <tbody>
            {auction.zones.map(item => (
              <tr key={item.zone.uuid}>
                <td>{`${item.zone.channel.name} (${item.zone.name})`}</td>
                <td style={{ width: 210 }}>
                  <div className="input-div">
                    <TableInput
                      type="number"
                      id="ganhadores"
                      value={item.countWinners}
                      onChange={(e) => {
                        updateConfigZoneAuction({ ...item, countWinners: parseFloat(e.target.value) });
                      }}
                    />
                  </div>
                </td>
                <td style={{ width: 170 }}>
                  <div className="input-div">
                    <TableInput
                      type="number"
                      id="reservas"
                      value={item.countReservations}
                      onChange={(e) => {
                        updateConfigZoneAuction({ ...item, countReservations: parseFloat(e.target.value) });
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </StyledDiv>
    );
  }

  function renderProbeConfigs() {
    if (!probe) {
      return null;
    }

    return (
      <StyledDiv bordered>
        <h3>
          TESTE
        </h3>
        <Table>
          <thead>
            <tr>
              <th>Canal</th>
              <th>Valor teste normal</th>
            </tr>
          </thead>
          <tbody>
            {probe.zones.map(item => (
              <tr key={item.zone.uuid}>
                <td>{`${item.zone.channel.name} (${item.zone.name})`}</td>
                <td style={{ width: 200 }}>
                  <div className="input-div">
                    <span className="brl-symbol">R$</span>
                    <TableInput
                      type="number"
                      id="valor-teste-normal"
                      value={item.cpa}
                      onChange={(e) => {
                        updateConfigZoneProbe({ ...item, cpa: parseFloat(e.target.value) });
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </StyledDiv>
    );
  }

  function renderFixedCpaConfigs() {
    if (!fixedCpa) {
      return null;
    }

    return (
      <StyledDiv bordered>
        <h3>
          CPA FIXO
        </h3>
        <Table>
          <thead>
            <tr>
              <th>Canal</th>
              <th>Valor teste normal</th>
            </tr>
          </thead>
          <tbody>
            {fixedCpa.zones.map(item => (
              <tr key={item.zone.uuid}>
                <td>{`${item.zone.channel.name} (${item.zone.name})`}</td>
                <td style={{ width: 200 }}>
                  <div className="input-div">
                    <span className="brl-symbol">R$</span>
                    <TableInput
                      type="number"
                      id="valor-teste-normal"
                      value={item.cpa}
                      onChange={(e) => {
                        updateConfigZoneFixedCpa({ ...item, cpa: parseFloat(e.target.value) });
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </StyledDiv>
    );
  }

  function renderBonusConfigs() {
    if (!bonus) {
      return null;
    }

    return (
      <StyledDiv bordered>
        <h3>
          BONIFICADA
        </h3>
        <Table>
          <thead>
            <tr>
              <th>Canal</th>
              <th>Valor vazão fixa</th>
            </tr>
          </thead>
          <tbody>
            {bonus.zones.map(item => (
              <tr key={item.zone.uuid}>
                <td>{`${item.zone.channel.name} (${item.zone.name})`}</td>
                <td style={{ width: 200 }}>
                  <div className="input-div">
                    <TableInput
                      type="number"
                      id="valor-teste-normal"
                      value={item.fixedVolume}
                      onChange={(e) => {
                        updateConfigZoneBonus({ ...item, fixedVolume: parseFloat(e.target.value) });
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </StyledDiv>
    );
  }

  return (
    <Container>
      <ModalBox>
        <Content>
          <header>
            <div className="header-title">
              <ButtonGridPeriodIcon className="grid-period-icon" />
              <h1>
                INICIAR ANÁLISE DE FECHAMENTO SEMANAL
              </h1>
            </div>
            <button type="button" onClick={() => onCloseModalInitCloseWeekly()} className="close-button">
              <img src={general.imageCampaign['icon-close-modal.png']} alt="close-icon" />
            </button>
          </header>
          {renderGeralConfigs()}
          {renderAuctionConfigs()}
          {renderProbeConfigs()}
          {renderFixedCpaConfigs()}
          {renderBonusConfigs()}
        </Content>
        <ModalFooter>
          <Button onClick={() => confirmStartGridPeriod()}>
            <PlanSheetIcon />
            <span>Confirmar dados e iniciar análise de Fechamento Semanal</span>
          </Button>
        </ModalFooter>
      </ModalBox>
    </Container>
  );
}

const mapStateToProps = ({
  newGridPeriod: {
    showModalInitCloseWeekly,
    configs: {
      geral,
      auction,
      probe,
      fixedCpa,
      bonus,
    },
  },
}) => ({
  showModalInitCloseWeekly,
  geral,
  auction,
  probe,
  fixedCpa,
  bonus,
});


const mapDispatchToProps = dispatch => bindActionCreators(
  {
    closeModalInitCloseWeekly: NewGridPeriodCreators.closeModalInitCloseWeekly,
    updateConfigsGeral: NewGridPeriodCreators.updateConfigsGeral,
    updateConfigsAuction: NewGridPeriodCreators.updateConfigsAuction,
    updateConfigsProbe: NewGridPeriodCreators.updateConfigsProbe,
    updateConfigsFixedCpa: NewGridPeriodCreators.updateConfigsFixedCpa,
    updateConfigsBonus: NewGridPeriodCreators.updateConfigsBonus,
    confirmStartGridPeriod: NewGridPeriodCreators.confirmStartGridPeriod,
  },
  dispatch,
);

ModalInitCloseWeekly.defaultProps = {
  geral: null,
  auction: null,
  probe: null,
  fixedCpa: null,
  bonus: null,
};

ModalInitCloseWeekly.propTypes = {
  showModalInitCloseWeekly: PropTypes.bool.isRequired,
  geral: PropTypes.shape(),
  auction: PropTypes.shape(),
  probe: PropTypes.shape(),
  fixedCpa: PropTypes.shape(),
  bonus: PropTypes.shape(),
  closeModalInitCloseWeekly: PropTypes.func.isRequired,
  updateConfigsGeral: PropTypes.func.isRequired,
  updateConfigsAuction: PropTypes.func.isRequired,
  updateConfigsProbe: PropTypes.func.isRequired,
  updateConfigsFixedCpa: PropTypes.func.isRequired,
  updateConfigsBonus: PropTypes.func.isRequired,
  confirmStartGridPeriod: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalInitCloseWeekly);
