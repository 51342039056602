/* eslint-disable no-console */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import lodash from 'lodash';
import uuid4 from 'uuid4';

import Pagination from '~/Components/Pagination';
import Button from '~/Components/Button';

import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import {
  Container,
  Table,
  Row,
  Input,
  LinkStyled,
  Select,
  ButtonGroup,
  LinkButton,
} from './styles';

const piManagements = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
].map(item => ({
  uuid: uuid4(),
  name: `Gestão de PI ${item}`,
  advertiser: { name: `Anunciante ${item}` },
  agency: { name: `Agencia ${item}` },
  user: { name: `Usuário ${item}` },
}));

class PiManagement extends Component {
  state= {
    page: 0,
  };

  handleDeletePiManagement = (pi) => {
    const { confirmModal } = this.props;
    confirmModal(
      'Deseja excluir a Gestão?',
      pi.name,
      () => {},
    );
  };

  renderRowPiManagement = pi => (
    <tr key={pi.uuid}>
      <td className="link">
        <LinkStyled to={`/pi-management/${pi.uuid}/edit`}>
          {pi.name}
        </LinkStyled>
      </td>
      <td>
        <span>{pi.advertiser.name}</span>
      </td>
      <td>
        <span>{pi.agency.name}</span>
      </td>
      <td>
        <span>{pi.user.name}</span>
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            bordered
            title="EXCLUIR"
            onClick={() => this.handleDeletePiManagement(pi)}
          />
        </div>
      </td>
    </tr>
  );

  renderPiManagement = () => {
    const piManagementList = lodash.chunk(piManagements, 10 /* 20 */);
    const { page } = this.state;
    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>NOME</th>
              <th>ANUNCIANTE</th>
              <th>AGÊNCIA</th>
              <th>AUTOR</th>
              <th style={{ width: 250 }} />
            </tr>
          </thead>
          <tbody>
            {piManagementList.length < 1 ? (
              <tr>
                <td colSpan="9">
                  <div className="empty-table-desc">
                    <span> Não foram localizados registros de audiências.</span>
                  </div>
                </td>
              </tr>
            ) : (
              piManagementList[page].map(pi => this.renderRowPiManagement(pi))
            )}
          </tbody>
        </Table>
        <Row>
          <Pagination
            page={parseInt(page)}
            pageCount={piManagementList.length}
            pageChange={(e) => {
              this.setState({
                page: parseInt(e.selected),
              });
            }}
          />
        </Row>
      </>
    );
  };

  renderAdvertiserSelect = () => (
    <Select
      placeholder="Selecione Anunciante..."
      value={null}
      onChange={(item) => {}}
      options={[]}
      isClearable
    />
  );

  renderAgencySelect = () => (
    <Select
      placeholder="Selecione Agência..."
      value={null}
      onChange={(item) => {}}
      options={[]}
      isClearable
    />
  );

  renderContractName = () => (
    <Input
      placeholder="Procurar por nome"
      value={null}
      onChange={(e) => {}}
    />
  );

  render() {
    const { navigateToRoute } = this.props;
    return (
      <>
        <Container>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>Gestão de PI</h1>
            <Button
              bordered
              onClick={() => {
                navigateToRoute('pi-management/new');
              }}
              title="NOVA GESTÃO"
            />
          </Row>
          <Row>
            <div className="box-search">
              {this.renderContractName()}
              {this.renderAdvertiserSelect()}
              {this.renderAgencySelect()}
              <ButtonGroup>
                <LinkButton
                  onClick={() => {
                    this.loadData();
                  }}
                >
                  Buscar
                </LinkButton>
                <Button
                  icon="FaTimes"
                  noLabel
                  onClick={() => {}}
                />
              </ButtonGroup>
            </div>
          </Row>
          {this.renderPiManagement()}
        </Container>
      </>
    );
  }
}

PiManagement.propTypes = {
  confirmModal: PropTypes.func.isRequired,
  navigateToRoute: PropTypes.func.isRequired,
};

const mapStateToProps = (/* state */) => ({
  // audiences: state.audience.audiences,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    confirmModal: ConfirmModalActions.showConfirmModal,
    navigateToRoute: RedirectActions.navigateToRoute,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PiManagement);
