import api from './axios';

const get = async (userId) => {
  try {
    const { data } = await api.get(`user/${encodeURI(userId)}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  get,
};
