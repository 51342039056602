import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Dropzone from 'react-dropzone';

// import Button from '../Button';
import { Button } from './styles';

export default class Upload extends Component {
  static propTypes = {
    accept: PropTypes.string,
    maxSize: PropTypes.number,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onUpload: PropTypes.func.isRequired,
    onRejectMaxSize: PropTypes.func,
    onClick: PropTypes.func,
    multiple: PropTypes.bool,
    bordered: PropTypes.bool,
    invalid: PropTypes.bool,
    style: PropTypes.shape(),
    styleContainer: PropTypes.shape(),
  };

  static defaultProps = {
    accept: 'image/*',
    maxSize: Infinity,
    disabled: false,
    onRejectMaxSize: null,
    onClick: null,
    multiple: false,
    bordered: true,
    style: null,
    styleContainer: null,
    invalid: false,
  };

  renderDragMessage = (open) => {
    const {
      label, onClick, disabled, bordered, style, invalid,
    } = this.props;
    return (
      <Button
        onClick={() => {
          if (onClick) {
            onClick();
          }
          open();
        }}
        disabled={disabled}
        bordered={bordered}
        title={label || 'UPLOAD'}
        style={{
          width: 'fit-content',
          marginLeft: 0,
          marginTop: 15,
          ...style,
        }}
        invalid={invalid}
      />
    );
  };

  render() {
    const {
      onUpload, disabled, accept, maxSize, onRejectMaxSize, multiple, styleContainer,
    } = this.props;
    return (
      <div style={{ width: '88px', height: '65px', ...styleContainer }} className="dropzone-content-box">
        <Dropzone
          accept={accept}
          noDrag
          noClick
          onDropAccepted={onUpload}
          onDropRejected={(f) => {
            if (f && f[0].size > maxSize) {
              if (onRejectMaxSize) {
                onRejectMaxSize();
              }
            }
          }}
          multiple={multiple}
          disabled={disabled}
          maxSize={maxSize}
        >
          {({
            getRootProps, getInputProps, isDragActive, isDragReject, open,
          }) => (
            <div {...getRootProps()} isDragActive={isDragActive} isDragReject={isDragReject}>
              <input {...getInputProps()} />
              {this.renderDragMessage(open)}
            </div>
          )}
        </Dropzone>
      </div>
    );
  }
}
