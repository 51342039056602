import styled from 'styled-components';

import {
  RowStyled,
  FormGroupStyled,
  InputStyled,
} from '~/Components/Styled';

export const ContentModal = styled.div``;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.regular};
`;

export const Body = styled.div`
  overflow-y: auto;
`;

export const Footer = styled.div`
  height: 70px;
  border-top: 1px solid ${props => props.theme.colors.regular};
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  padding: 0 10px;
`;

export const CloseHeader = styled.div`
  cursor: pointer;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const FormGroup = styled(FormGroupStyled)`
  width: 100%;
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)};
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const FormNew = styled.div`
  padding: 10px;
`;

export const Input = styled(InputStyled)`
  width: 100%;
`;
