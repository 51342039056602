/* eslint-disable import/no-cycle */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Column } from './styles';
import TabSuccess from './components/TabSuccess';
import TabLayout from './components/TabLayout';
import TabContent from './components/TabContent';
import AccordeonItem from './components/Accordeon';
import {
  internalMediaComponentLayoutIsValid,
  internalMediaComponentContentIsValid,
  internalMediaComponentSuccessIsValid,
} from '..';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withTheme } from 'styled-components';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';

class InternalMediaComponentForm extends Component {
  state = {
    tabActive: 'content',
  };

  changeTabActive = (tabActiveChange) => {
    const { tabActive } = this.state;
    this.setState({ tabActive: tabActive === tabActiveChange ? '' : tabActiveChange });
  }

  hasErrorTabActive = (tabActiveProps) => {
    const {
      criativoEdit: { content },
      hasError,
    } = this.props;

    let isValid;
    if (tabActiveProps === 'layout') {
      isValid = internalMediaComponentLayoutIsValid;
    } else if (tabActiveProps === 'media') {
      isValid = internalMediaComponentContentIsValid;
    } else if (tabActiveProps === 'success') {
      isValid = internalMediaComponentSuccessIsValid;
    }

    return hasError
      && !isValid(
        content
          && content.mainData
          && content.mainData[tabActiveProps]
          ? content.mainData[tabActiveProps]
          : null,
      );
  }

  componentDidMount() {
    const {
      campaignFileSizeConfigRequest,
    } = this.props;
    campaignFileSizeConfigRequest();
  }

  render() {
    const { tabActive } = this.state;

    const {
      campaign,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
      disabledFields,
      modeMidia,
    } = this.props;

    const { content } = criativoEdit;

    if (modeMidia) {
      return (
        <TabContent
          hasError={hasError}
          campaign={campaign}
          content={content}
          criativoEdit={criativoEdit}
          changeData={campaignEditCriativoRequestSuccess}
          disabledFields={disabledFields}
        />
      );
    }

    return (
      <Column>
        <AccordeonItem
          isActive={tabActive === 'layout'}
          onAccordion={() => this.changeTabActive('layout')}
          title="Layout"
          hasError={this.hasErrorTabActive('layout')}
        >
          <TabLayout
            hasError={hasError}
            campaign={campaign}
            content={content}
            criativoEdit={criativoEdit}
            changeData={campaignEditCriativoRequestSuccess}
            disabledFields={disabledFields}
          />
        </AccordeonItem>
        <AccordeonItem
          isActive={tabActive === 'content'}
          onAccordion={() => this.changeTabActive('content')}
          title="Conteúdo"
          hasError={this.hasErrorTabActive('media')}
        >
          <TabContent
            hasError={hasError}
            campaign={campaign}
            content={content}
            criativoEdit={criativoEdit}
            changeData={campaignEditCriativoRequestSuccess}
            disabledFields={disabledFields}
          />
        </AccordeonItem>
        <AccordeonItem
          isActive={tabActive === 'success'}
          onAccordion={() => this.changeTabActive('success')}
          title="Sucesso"
          hasError={this.hasErrorTabActive('success')}
        >
          <TabSuccess
            hasError={hasError}
            content={content}
            campaign={campaign}
            criativoEdit={criativoEdit}
            changeData={campaignEditCriativoRequestSuccess}
            disabledFields={disabledFields}
          />
        </AccordeonItem>
      </Column>
    );
  }
}

InternalMediaComponentForm.defaultProps = {
  modeMidia: false,
};

InternalMediaComponentForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  modeMidia: PropTypes.bool,
  campaignFileSizeConfigRequest: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignFileSizeConfigRequest: CampaignEditActions.campaignFileSizeConfigRequest,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(withTheme(InternalMediaComponentForm));
