import api from './axios';

const reportGridPeriodRequest = async (gridPeriodUui, zoneUuid) => {
  try {
    const { data } = await api.get(
      `report/${gridPeriodUui}/${zoneUuid}`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const reportMonthlyGridPeriodRequest = async (date, gridChannelUuid, campaignTypeUuid) => {
  try {
    let queryString = '';
    if (campaignTypeUuid !== 'ALL') {
      queryString = `?campaignTypeUuid=${campaignTypeUuid}`;
    }

    const { data } = await api.get(
      `sheduling/${date}/${gridChannelUuid}${queryString}`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const channelRequest = async (campaignTypes = '') => {
  try {
    let queryString = '';
    if (campaignTypes) {
      queryString = `?campaignTypes=${campaignTypes}`;
    }
    const { data } = await api.get(
      `auction/channel${queryString}`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const periodRequest = async () => {
  try {
    const { data } = await api.get('auction/period');
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const gridPeriodReport = async (gridUuid, campaignUuid, gridPeriodUuid) => {
  try {
    const { data } = await api.get(
      `mcare-artemis-report-manager/campaign/result/${gridUuid}/${campaignUuid}/${gridPeriodUuid}`,
    );
    return data;
  } catch (error) {
    const { data } = error.response;
    throw (
      data.msg || 'Houve um problema ao tentar buscar os dados!'
    );
  }
};

const partnerList = async () => {
  try {
    const { data } = await api.get('mcare-artemis-partner/partner');
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const consultaFortuna = async () => {
  try {
    const { data } = await api.get('mcare-artemis-campaign-manager/fortuna/consulta');
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const getTypes = async (type = 'MONTHLY') => {
  try {
    const { data } = await api.get(`type?schedulingType=${type}`);
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const confirmSignature = async (body) => {
  try {
    const { data } = await api.post('campaign/result/validate', body);
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  reportGridPeriodRequest,
  reportMonthlyGridPeriodRequest,
  channelRequest,
  periodRequest,
  gridPeriodReport,
  partnerList,
  consultaFortuna,
  getTypes,
  confirmSignature,
};
