
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import moment from 'moment';

import Breadcrumb from '~/Components/Breadcrumb';
import FileItem from './Components/FileItem';
import Button from '~/Components/Button';
import { convertFloatReal } from '~/Utils';

import { Creators as FinancialActions } from '~/redux/ducks/financial';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import {
  Container,
  Row,
  FilesBox,
} from './styles';

class FinancialFiles extends Component {
  state = {};

  componentDidMount() {
    const {
      match: { params },
      setFinancialFilterArchives,
      financialArchivesRequest,
    } = this.props;
    setFinancialFilterArchives({
      year: params.year,
      month: params.month,
      partnerUuid: params.partnerUuid,
    });
    financialArchivesRequest();
  }

  renderHeader = () => {
    const {
      match: { params },
    } = this.props;
    let path = '/';

    if (params.type === 'partner') path = '/financial/partner';
    if (params.type === 'provider') path = '/financial/provider';

    return (
      <Row
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          marginBottom: 10,
        }}
      >
        <div>
          <Breadcrumb
            items={[
              {
                route: '/',
                name: 'Home',
              },
              {
                route: path,
                name: 'Financeiro',
              },
              {
                name: 'Arquivos',
              },
            ]}
          />
          <h1>Financeiro</h1>
        </div>
      </Row>
    );
  };

  renderFilesBox = () => {
    const {
      archives,
      financialArchiveDownload,
      confirmModal,
      financialDocumentArchivesDelete,
      financialDocumentArchiveDelete,
      match: { params },
    } = this.props;

    const archivesList = archives.filter(item => item.archives.length > 0);
    const generalFiles = archivesList.filter(item => !item.document);
    const providerFiles = archivesList.filter(item => !!item.document);

    const getHasDeleteIcon = (file) => {
      const hasDeleteIcon = params.type === 'partner' && !!file.receipt;
      return hasDeleteIcon;
    };

    const getButtonRemoveIsVisible = (item) => {
      const findFileWithReceipt = item.archives.filter(file => !!file.receipt);
      return params.type === 'provider'
        && item.document.documentStatus.name !== 'PAID'
        && (item.archives.length > 0 && findFileWithReceipt.length === 0);
    };

    return (
      <>
        {generalFiles.map(item => (
          <FilesBox key={item.uuid}>
            <div className="files-box-header">
              <h2>Arquivos gerais</h2>
            </div>
            <div className="files-box-content">
              <div className="files-box-content-files">
                {item.archives.map(file => (
                  <FileItem
                    key={file.uuid}
                    file={file}
                    onDownload={() => {
                      financialArchiveDownload(file.invoice);
                    }}
                  />
                ))}
              </div>
            </div>
          </FilesBox>
        ))}
        {providerFiles.map(item => (
          <FilesBox key={item.uuid}>
            <div className="files-box-header">
              <h2>{item.document.provider.name}</h2>
              <div className="files-box-subtitle">
                <span>
                  <b>Valor: </b>
                  {`R$ ${convertFloatReal(item.document.amount || 0)}`}
                </span>
                <span>
                  <b>Vencimento: </b>
                  {moment(item.document.dueDate).utc().format('DD/MM/YYYY')}
                </span>
              </div>
              <div>
                {getButtonRemoveIsVisible(item) && (
                  <Button
                    icon="FaTrash"
                    title="Remover lançamento"
                    bordered
                    onClick={() => {
                      confirmModal(
                        'Remover lançamento',
                        `Tem certeza que quer remover o lançamento do provedor ${item.document.provider.name}?`,
                        () => {
                          financialDocumentArchivesDelete(item.document.uuid);
                        },
                      );
                    }}
                    style={{
                      margin: 0,
                    }}
                  />
                )}
              </div>
            </div>
            <div className="files-box-content">
              <div className="files-box-content-files">
                {item.archives.map(file => (
                  <FileItem
                    key={file.uuid}
                    file={file}
                    onDelete={() => {
                      confirmModal(
                        'Remover recibo',
                        'Tem certeza que quer remover este recibo?',
                        () => {
                          financialDocumentArchiveDelete(file.invoice.uuid);
                        },
                      );
                    }}
                    hasDeleteButton={getHasDeleteIcon(file)}
                    onDownloadInvoice={() => {
                      financialArchiveDownload(file.invoice);
                    }}
                    onDownloadReceipt={() => {
                      financialArchiveDownload(file.receipt);
                    }}
                  />
                ))}
              </div>
            </div>
          </FilesBox>
        ))}
      </>
    );
  };

  render() {
    return (
      <Container>
        {this.renderHeader()}
        {this.renderFilesBox()}
      </Container>
    );
  }
}

FinancialFiles.defaultProps = {};

FinancialFiles.propTypes = {
  match: PropTypes.shape().isRequired,
  confirmModal: PropTypes.func.isRequired,
  financialArchivesRequest: PropTypes.func.isRequired,
  financialArchiveDownload: PropTypes.func.isRequired,
  setFinancialFilterArchives: PropTypes.func.isRequired,
  financialDocumentArchivesDelete: PropTypes.func.isRequired,
  financialDocumentArchiveDelete: PropTypes.func.isRequired,
  archives: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  archives: state.financial.archives,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    confirmModal: ConfirmModalActions.showConfirmModal,
    financialArchivesRequest: FinancialActions.financialArchivesRequest,
    financialArchiveDownload: FinancialActions.financialArchiveDownload,
    setFinancialFilterArchives: FinancialActions.setFinancialFilterArchives,
    financialDocumentArchivesDelete: FinancialActions.financialDocumentArchivesDelete,
    financialDocumentArchiveDelete: FinancialActions.financialDocumentArchiveDelete,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FinancialFiles);
