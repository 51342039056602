import styled from 'styled-components';
import { Link } from 'react-router-dom';

import {
  ContainerStyled,
} from '~/Components/Styled';

export const Container = styled(ContainerStyled)``;

export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-right: 25px;
  h1 {
    margin-left: 25px;
  }
`;

export const StyledDiv = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  min-width: 400px;
  padding: 25px;
  margin: 25px 0 25px 0;
  gap: 25px;
  background-color: ${props => (props.bordered ? 'transparent' : '#FCF4EE')};
  border: ${props => (props.bordered ? '1px solid #E0E0E0' : '')};
  border-radius: 36px;

  overflow-x: ${props => (props.bordered ? 'auto' : '')};
`;

export const LinkStyled = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 240px;
  height: 160px;
  justify-content: center;
  align-items: center;

  border: 2px solid ${props => props.theme.colors.lighter};
  border-radius: 36px;
  text-decoration: none;
  color: ${props => props.theme.colors.dark};
  font-size: 24px;
  transition: color 0.2s ease, border-color 0.2s ease;

  &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
  }

  &:focus {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    border-color: ${props => props.theme.colors.primary};
    outline: none;
  }
`;
