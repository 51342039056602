import React from 'react';
import PropTypes from 'prop-types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icone = ({ icon, color, size }) => <FontAwesomeIcon icon={icon} color={color} style={{ fontSize: `${size}px` }} />;

Icone.defaultProps = {
  color: null,
  size: 12,
};

Icone.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.string,
  color: PropTypes.string,
};

export default Icone;
