import React from 'react';

function AwaitingStatusIcon() {
  return (
    <svg width="25" height="3" viewBox="0 0 20 2" fill="none" xmlns="http://www.w3.org/2000/svg">
      <line x1="1" y1="1" x2="19" y2="1" stroke="#EEBA00" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}

export default AwaitingStatusIcon;
