import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
// import { FaTimes } from 'react-icons/fa';

import Modal from '~/Components/Modal';
import {
  Row,
  TitleItemExcluse,
  // ButtonCloseModal,
  Button,
} from './styles';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

const ConfirmModal = ({
  title,
  message,
  modalStatus,
  confirmAction,
  hide,
  titleCancel,
  titleConfirm,
}) => (modalStatus ? (
  <Modal>
    <Row>
      <h1>{title}</h1>
      {/*
        <ButtonCloseModal
          type="button"
          onClick={() => {
            hide();
          }}
        >
          <FaTimes size={30} />
        </ButtonCloseModal>
      */}
    </Row>
    <Row>
      <TitleItemExcluse>{message}</TitleItemExcluse>
    </Row>
    <Row style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
      <Button
        title={titleCancel}
        bordered
        onClick={() => {
          hide();
        }}
      />
      <Button
        title={titleConfirm}
        onClick={() => {
          hide();
          confirmAction();
        }}
      />
    </Row>
  </Modal>
) : null);

const mapStateToProps = state => ({
  title: state.confirmModal.title,
  message: state.confirmModal.message,
  modalStatus: state.confirmModal.modalStatus,
  confirmAction: state.confirmModal.confirmAction,
  titleCancel: state.confirmModal.titleCancel,
  titleConfirm: state.confirmModal.titleConfirm,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    hide: ConfirmModalActions.hideConfirmModal,
  },
  dispatch,
);

ConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  modalStatus: PropTypes.bool.isRequired,
  confirmAction: PropTypes.func.isRequired,
  hide: PropTypes.func.isRequired,
  titleCancel: PropTypes.string.isRequired,
  titleConfirm: PropTypes.string.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmModal);
