

import React from 'react';

function SaveIcon({width = 20, height = 20}) {
  return (
    <svg width={width} height={height} viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_611_79)">
    <path d="M0 7.5C0 5.90619 0 4.31717 0 2.72336C0 1.99107 0.234525 1.34014 0.741863 0.804084C1.09126 0.440332 1.52202 0.210593 2.01021 0.0909381C2.26388 0.0287173 2.51755 0 2.77601 0C5.10689 0 7.44257 0 9.77345 0C10.5632 0 11.2285 0.272814 11.7837 0.832802C12.583 1.63689 13.3823 2.4314 14.1816 3.2307C14.6362 3.6806 14.9091 4.22144 14.9856 4.85801C14.9952 4.95852 15.0048 5.05903 15.0048 5.16433C15.0048 7.5335 15.0048 9.90268 15.0048 12.2719C15.0048 13.0089 14.775 13.6694 14.2581 14.2055C13.9135 14.5645 13.4828 14.7942 12.9994 14.9138C12.7505 14.9761 12.4968 15.0048 12.2431 15.0048C9.07945 15.0048 5.91576 15.0048 2.74729 15.0048C2.0485 15.0048 1.41193 14.799 0.880664 14.3251C0.459477 13.9518 0.196235 13.478 0.0861519 12.9276C0.0430759 12.7074 0.0143586 12.4777 0.0143586 12.2479C0.00957243 10.6637 0.0143586 9.08424 0.0143586 7.5H0ZM10.7116 1.33057V1.44065C10.7116 2.38832 10.7116 3.34078 10.7116 4.28845C10.7116 4.5134 10.6589 4.724 10.5297 4.91066C10.3047 5.23133 9.98405 5.35578 9.60594 5.35578C7.48564 5.35578 5.37013 5.35578 3.24984 5.35578C3.11583 5.35578 2.98181 5.33663 2.85258 5.30313C2.42661 5.17869 2.14422 4.77664 2.14422 4.31238C2.14422 3.2977 2.14422 2.28302 2.14422 1.26835V1.17262C2.12029 1.1822 2.10115 1.18698 2.08679 1.19177C1.42629 1.46937 1.07211 2.015 1.07211 2.72336C1.06733 5.91098 1.07211 9.0986 1.07211 12.2862C1.07211 12.3915 1.08168 12.5016 1.10562 12.6021C1.17262 12.9515 1.321 13.2578 1.58424 13.4971C1.9097 13.7891 2.29738 13.9279 2.73293 13.9279C5.91576 13.9279 9.0986 13.9279 12.2814 13.9279C12.4154 13.9279 12.5495 13.9087 12.6787 13.88C12.9898 13.813 13.2674 13.6694 13.4876 13.4349C13.7939 13.1047 13.9375 12.7026 13.9375 12.2575C13.9375 9.90268 13.9375 7.54786 13.9375 5.19783C13.9375 4.75271 13.7843 4.36981 13.4684 4.05393C12.626 3.21155 11.7837 2.36439 10.9365 1.52202C10.8743 1.4598 10.8025 1.40236 10.7163 1.33057H10.7116ZM9.63944 4.28366V1.07211H3.21634V4.28366H9.63944Z" fill="white"/>
    <path d="M4.82446 9.63944C4.86754 8.09828 5.93965 6.9783 7.49996 6.9783C9.05069 6.9783 10.1707 8.08392 10.1707 9.64422C10.1707 11.2045 9.05069 12.3149 7.49996 12.3101C5.93487 12.3101 4.86754 11.1806 4.82446 9.63944ZM5.89179 9.63944C5.93487 10.5775 6.57143 11.238 7.49996 11.238C8.41412 11.238 9.09855 10.5919 9.09855 9.64422C9.09855 8.69655 8.41891 8.04084 7.49996 8.04084C6.57622 8.04084 5.93965 8.70134 5.89179 9.63944Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_611_79">
    <rect width="15" height="15" fill="white"/>
    </clipPath>
    </defs>
    </svg>
  );
}

export default SaveIcon;
