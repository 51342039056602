import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';


export const ProfileResult = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  flex: 1;
  border: 1px solid ${props => props.theme.colors.primary};
  padding: 0 10px;
  border-radius: 5px;
  max-height: 80px;
  min-height: 80px;
  margin-top: 10px;
  .item-result-zone {
    display: flex;
    align-items: center;
    h3 {
      font-size: 18px;
      color: ${props => props.theme.colors.primary};
    }
    h4 {
      font-size: 20px;
      min-width: 100px;
      text-align: right;
      padding: 0;
      margin: 0;
      padding-left: 10px;
    }
  }
  .item-result-total {
    display: flex;
    align-items: center;
    margin-top: 5px;
    h3 {
      font-size: 14px;
      color: ${props => props.theme.colors.primary};
    }
    h4 {
      font-size: 14px;
      min-width: 100px;
      text-align: right;
      padding: 0;
      margin: 0;
      padding-left: 10px;
    }
  }
`;

export const SelectZone = styled(ReactSelect)`
  width: 150px;
  border: 0;
  margin: 0 3px 0 5px;
  [class*="-control"] {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 4px;
    ${props => props.invalid
      && css`
        border-color: ${props => props.theme.colors.danger2};
      `}
    outline: none;
    /* background-color: transparent; */
  }
`;
