import styled from 'styled-components';
import { Popup as PopupComponent } from 'react-leaflet';

export const Popup = styled(PopupComponent)`
  .leaflet-popup-content {
    margin: 0;
    padding: 10px;
    font-size: 15px;
    width: max-content !important;
    line-height: 1.6;
  }
`;
