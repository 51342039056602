import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import BackgroundPreview from './BackgroundPreview';
import LogoPreview from './LogoPreview';
import {
  PreviewVastType,
} from './styles';

const NoCreditBannerTextPreview = ({ data: { content }, noDataPreviewData }) => (
  <>
    {!!content.backgroundImage && <BackgroundPreview backgroundImage={content.backgroundImage} />}
    <PreviewVastType
      style={{
        zIndex: 10,
        ...(content.backgroundImage ? { backgroundColor: 'transparent' } : null),
      }}
    >
      <section className="section-logo">
        {!!content.logo && <LogoPreview logo={content.logo} />}
      </section>
      <div className="content-preview">
        <section className="section-content-shadow">
          <section className="section-title">
            {content.offerScreen || ''}
          </section>
          <a href={content.urlHome || '#'} target="_blank" rel="noreferrer" className="section-content">
            <p className="days">
              {noDataPreviewData
                ? `Experimente ${noDataPreviewData.trialPeriod} ${parseInt(noDataPreviewData.trialPeriod) > 1 ? 'dias' : 'dia'} grátis*`
                : ''
            }
            </p>
            <p className="price">
              {noDataPreviewData && (
              <>
                {'após '}
                <span className="price-highlight">{`R$${noDataPreviewData.price}`}</span>
                {`/${noDataPreviewData.period}`}
              </>
              )}
            </p>
            <p className="sign">ASSINE AGORA</p>
            <p className="validity">*Promoção válida apenas na primeira assinatura</p>
            <p className="cancel">
              {`Para cancelar, envie SAIR para ${noDataPreviewData ? noDataPreviewData.productLa : ''}`}
            </p>
          </a>
        </section>
      </div>
    </PreviewVastType>
  </>
);

NoCreditBannerTextPreview.propTypes = {
  data: PropTypes.shape().isRequired,
  noDataPreviewData: PropTypes.shape().isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  noDataPreviewData: state.campaignEdit.noDataPreviewData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoCreditBannerTextPreview);
