import styled from 'styled-components';

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;
`;

export const BoxContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

export const BoxContentItem = styled.div`
  display: flex;
  align-items:center;
  flex-direction: column;
  font-size: 16px;
  flex: ${props => props.flex};
  span {
    min-height: 22px;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 99%;
  margin-left: 0;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 15px;
  min-height: 118px;
`;
