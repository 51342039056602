import styled from 'styled-components';
import { ContainerStyled } from '~/Components/Styled';


export const Wrap = styled(ContainerStyled)`
  width: 1605px;
`;

export const Container = styled.div`

  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 30px;
  margin-top:15px;
  margin-bottom:30px;

  .zoom-chart{
    zoom:120%;
    width:100%;
  }


  //TEXT
  h1{
    font-size:22pt ;
  }
  h2{
    margin-top:5px;
    font-size:18pt ;
  }

  span{
    font-size: 16pt;
    color:${props => props.theme.colors.dark};
    font-weight:600;
  }

  span.bigger{
    font-size:17pt;
    font-weight:bold;
    color:${props => props.theme.colors.dark};
  }

  span.primary{
    color:${props => props.theme.colors.primary};
  }

  span.secundary{
    color:${props => props.theme.colors.chart[1]};
  }
  span.third{
    color:${props => props.theme.colors.chart[2]};
  }

  span.fourth{
    color:${props => props.theme.colors.chart[3]};
  }

  span.smaller{
        font-size:10pt;
        font-weight:500;
        color:${props => props.theme.colors.regular}
  }

  label{
    font-weight:600 ;
    color: ${props => props.theme.colors.regular} ;
    font-size:12pt ;
  }

  //ESTRUCTUTE

  .action{
    margin-top:-10px;
    button{
      margin-left: 15px;
    }
  }



  .box{
      border: 1px solid ${props => props.theme.colors.lighter};
      border-radius:28px;
      padding:30px;
      display:flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      img{
        width:30px;
        margin-right:15px;
      }





      .type-box{

        background-color: ${props => props.theme.colors.lighterWhite};
        border-radius: 14px;
        padding:15px;
        margin-right: 10px;


        img{
          width:30px;
          margin-right: 0;
        }
      }



    }


  .grid{




    .btn-anchor{

        margin-left:15px;

      }


    .box.tipo{
      grid-area: gridTipo;
    }

    .box.audiencia{
      grid-area: gridAudiencia;

      .base-info-audiencia {
        width: 100%;

        > div {
          width: 100%;
        }

        .info-secondary-audiencia {
          > div {
            flex: 1;
          }
        }
      }
    }


    .box.impacto-contratado{
      grid-area: gridImpacto;
    }

    .box.ctr{
      grid-area: gridCtr;
      padding-bottom: 0;

      .base-values-chart {
        height: 100%;
        flex-direction: column;
        justify-content: center;

        .values{
          min-width: 165px;
          margin-bottom: 20px;
          justify-content: center;
          align-items: center;
        }

        .chart{
          min-width:185px;
        }
      }


    }

    .box.estimado-realizado{
      grid-area: gridEstimado;
      align-items: center;
      justify-content: flex-start;

      .space:first-child{
        align-self: flex-start;
      }

      h2{
        margin-bottom: 15px;
      }

      span.bigger.primary{
        align-self: flex-start;
        margin-left: 15px;
      }
      .subtitles{
        align-self: flex-start;
        margin-top: 15px;
        align-items: center;
        .square{

          width:20px;
          height:20px;
          background-color: ${props => props.theme.colors.lighterWhite};
          border-radius: 8px;
          margin-top:-5px;
          margin-right:10px;
        }
        .square.primary{
            margin-left:25px;
            background-color: ${props => props.theme.colors.primary};
        }
      }


      .estimado{
        align-items: flex-end;
        div{
        align-items: flex-end;
        }
        h2{
          color:${props => props.theme.colors.dark};
        }
      }

      .realizado{
        span.bigger{
          color:${props => props.theme.colors.primary}
        }
      }

      .maths{
        height: -webkit-fill-available;
        height: fill-available;
        margin:0 10px;
        padding-top: 70px;
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        img{
          width:30px;
          margin-right:0;
        }
        .line{
          height:100%;
          width: 3px;
          border-radius: 3px;
          background-color: ${props => props.theme.colors.lighterWhite};
        }
        width:50px;
      }

    }

    .box.beneficios{
      grid-area: gridBeneficios;

      .header{
        justify-content: space-between !important;
        width:100% !important;
        margin-bottom:15px;
      }

      .items{
        width: 100%;
        margin-top:30px;
      }




    }

    .box.dados{
      grid-area: gridDados;
      background-color: ${props => props.theme.colors.lighterWhite};
      border:none;
      .header{
        justify-content: space-between !important;
        width:100% !important;
        margin-bottom:30px;
      }

      .data-empty {
        height: 100px;
      }

    }

    .box.cronograma-entrega{
      grid-area: gridCronograma;

      display: flex;
      align-items: center;
      flex-direction:column;
      justify-content: flex-start;

      div:first-child{
        width: 100%;
      }


      .grid-cronograma-entrega{


        .grid-dados{
          grid-area: gridDados;
          display:flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: center;
          margin-right:30px;

          .dados-cronograma{

            .chart{
              min-width:120px;
              max-width:120px;
              width:120px;
            }

            width:100%;

            div:first-child{
              align-items: flex-end;
            }
            img{
              width:40px;
              align-self:flex-end;
              margin:0 10px;
              margin-bottom: 14px;
            }

          }

          .dados-entrega{

            .chart{
              min-width:120px;
              max-width:120px;
              width:120px;
            }

            width:100%;
            div:first-child{
              align-items: flex-end;
            }
            img{
              width:40px;
              align-self:flex-end;
              margin:0 10px;
              margin-bottom: 14px;
            }

            .chart-color{
              color:${props => props.theme.colors.chart[1] || props.theme.colors.primary};
            }

          }

          .dados-meta{

            background-color:  ${props => props.theme.colors.lighterWhite};
            border-radius: 14px;
            padding:10px 14px;


            width:100%;
            div:first-child{
              align-items: flex-end;
            }
            img{
              width:40px;
              align-self:flex-end;
              margin:0 10px;
              margin-bottom: 14px;
            }

            .chart-color{
              color:${props => props.theme.colors.chart[2] || props.theme.colors.primary};
            }

            .bar-subtitle{
              width: 25px;
              height: 60px;
              border-radius: 15px;
              margin-left:30px;
              background-color:${props => props.theme.colors.chart[2] || props.theme.colors.primary};
            }

          }

        }

        .grid-chart{
          position: relative;
          display: flex;
          align-items:center;
          justify-content: center;


          .time-left{

            position:absolute;
            display: flex;
            flex-direction: column;
            align-items: center !important;
            justify-content: center !important;

          }


          grid-area: gridChart;
          display:flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;


        }

        .grid-bars{
          grid-area: gridBars;
        }



        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 15px;
        grid-row-gap: 15px;
        grid-template-areas:
          "gridDados gridChart "
          "gridBars gridBars "
        ;
      }

      .chart-box{
          width: 100%;
        }


    }

    .box.eventos-chart{

      grid-area: gridEventosChart;

      .chart-compare{
        width:100%;
        display:flex;
        justify-content: space-between;

        .chart-line{
          display: block;
          width:100%;

          .data-empty {
            margin: 25px 0;
            height: 250px;
          }
        }
      }
    }

    display: grid;
    grid-template-columns: 1fr 1fr 5fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-areas:
      "gridTipo gridCtr gridCronograma"
      "gridImpacto gridCtr gridCronograma"
      "gridAudiencia gridAudiencia gridCronograma"
      "gridEstimado gridEstimado gridBeneficios"
      "gridEventosChart gridEventosChart gridEventosChart"
      "gridDados gridDados gridDados"
    ;


  }


  .subtitle-line-chart{
    .line{
      width:50px;
      height: 6px;
      margin-right:10px;
      margin-left:30px;
      border-radius:3px;
      margin-top:-5px;

    }

    .line:first-child{
      margin-left:0;
    }
    .line.primary{
      background-color: ${props => props.theme.colors.chart[0]};
    }
    .line.secundary{
      background-color: ${props => props.theme.colors.chart[1]};
    }
    .line.third{
      background-color: ${props => props.theme.colors.chart[2]};
    }
  }


  .erros{

    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 30px 0;

    .erros-title{
      margin-bottom: 15px;
    }

  }

  .tabs{

    margin-top: 15px;

  }

  .media-questionario{
    margin-top:30px;
    margin-bottom:30px;
    .box{
      margin-right: 15px;
    }
    .box:last-child{
      margin-right: 0px
    }
  }

  .media-imagem{
    margin: 30px 0;
    .media-imagem-grid{

      .media-imagem-preview{
        grid-area: gridImagemPreview;

      }

      .media-imagem-download{
        grid-area: gridImagemDownload;
        width:490px;

        > button {
          width: 100%;
          margin-top: 15px;
        }
      }

      .media-imagem-ctr{
        grid-area: gridImagemCtr;
        width:490px;

        .title-ctr{
          margin-bottom:30px;
        }

        .ctr-space{
          justify-content: space-between;
          width:100%;


          .chart {
            max-width: 150px;
          }
        }
      }

      .box-empty2{
        grid-area: gridEmpty2;
      }
      .box-empty{
        grid-area: gridEmpty;
      }

      .media-imagem-dados{
        grid-area: gridImagemDados;
        background-color: ${props => props.theme.colors.lighterWhite};
        border:none;
        margin-top: 15px;
        .header{
          justify-content: space-between !important;
          width:100% !important;
          margin-bottom:30px;
        }
      }


      .media-imagem-eventos{
        grid-area: gridImagemEventos;

      .space{
        img{
          margin-right:30px;
        }
      }

        .chart-compare{
          width:100%;
          display:flex;
          justify-content: space-between;
          .chart-line{
            display: block;
            width:100%;
          }
          .result-day{
            margin:0 30px;
            h2{margin-bottom:15px;}
          }
        }
      }

      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      grid-template-areas:
        "gridImagemPreview gridImagemDownload gridEmpty"
        "gridImagemPreview gridImagemCtr gridEmpty"
        "gridImagemEventos gridImagemEventos  gridImagemEventos"
        "gridImagemDados gridImagemDados  gridImagemDados"
      ;


    }
  }



  .media-video{
    margin: 30px 0;
    .media-video-grid{
      .media-video-preview{
        grid-area: gridVideoPreview;

        .title-action{
          img{
            height:45px;
          }
        }
      }



      .box-emptyw{
        grid-area: gridEmptyw;
      }

      .media-video-dados{
        grid-area: gridVideoDados;
        background-color: ${props => props.theme.colors.lighterWhite};
        border:none;
        margin-top: 15px;
        .header{
          justify-content: space-between !important;
          width:100% !important;
          margin-bottom:30px;
        }
      }


      .media-video-eventos{
        grid-area: gridVideoEventos;

        .space{
          img{
            margin-right:30px;
          }
        }



        .chart-compare{
          width:100%;
          display:flex;
          justify-content: space-between;
          .chart-line{
            display: block;
            width:100%;
          }
          .result-day{
            margin:0 30px;
            h2{margin-bottom:15px;}
          }
          .data-empty {
            width: 100%;
            height: 100px;
            margin: 25px 0 0;
          }
        }
      }


      .media-video-reproducao{
        min-width: 1000px;
        min-height: 310px;
        grid-area: gridReproducao;

        .header{
          width:100%;
          justify-content: space-between;
        }

        .box.iteraction{
          border:none;
          background-color: ${props => props.theme.colors.lighterWhite};
          margin-right: 30px;
          justify-content: center;
          align-items: center;
          img{
           margin-right: 0;
            width:60px;
            margin-bottom:15px;
          }







        }
        .reproducao-data{
            justify-content: space-around;
            width:100%;
          .chart-line{
            display: block;
            width:100%;
          }

        }

        .iteraction-chart{
            align-items: center !important;
            width:100px;

            span, label{
              text-align: center;
            }
          }

          img.arrow{
            align-self:flex-end;
            margin: 0 15px 30px 15px;
            width:40px;

          }



      }

      display: grid;
      grid-template-columns: 1fr 2fr;
      grid-column-gap: 15px;
      grid-row-gap: 15px;
      grid-template-areas:
        "gridVideoPreview gridReproducao"
        "gridVideoEventos gridVideoEventos "
        "gridVideoDados gridVideoDados"
      ;
    }
  }

`;

export const GuideLine = styled.div`

  background-color: ${props => (props.color ? props.color : props.theme.colors.lighterWhite)};
  height:100%;
  width:5px;
  position: absolute;
  left:0px;
  top:0;
  border-radius:3px;
`;


export const Row = styled.div`
  display:flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;



  &&.title-campaign{
        justify-content: space-between;
        margin-bottom:30px;
  }

  &&.space{
    margin-bottom:5px;
  }

`;

export const Column = styled.div`

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  &&.space{
    margin-top:15px;
    label{
      margin-bottom: 0;
    }
  }

`;

export const ColumnBenefit = styled(Column)`
  flex: 1;

  .data-empty {
    margin-top: 25px;
  }
`;

export const ItemBenefit = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  grid-column-gap: 15px;
  justify-content: space-between;

  width:100%;

  border-bottom:2px solid  ${props => props.theme.colors.lighterWhite};
  padding:3px 15px;
  margin-top:15px;


  span:last-child{
    text-align: right;
  }

  span.total{
    color: ${props => props.theme.colors.primary}
  }
`;


export const SingleHorizontalChart = styled.div`
  width:100%;
  height:34px;
  background-color: ${props => props.theme.colors.lighterWhite} ;
  border-radius: 16px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: flex-start;
  padding:0 4px;


`;

export const SingleHorizontalChartTotal = styled.div`
  width:${props => (props.value ? `${props.value}%` : '100%')};
  height:28px;
  background-color: ${props => props.theme.colors.primary} ;
  border-radius: 14px;



`;


export const CheckButton = styled.div`
  cursor:pointer;
  padding:10px 20px;
  margin-left:15px;
  height:40px;
  border-radius:20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: ${props => (props.checked ? props.theme.colors.primary : props.theme.colors.lighterWhite)};
  span{
    color: ${props => (props.checked ? props.theme.colors.white : props.theme.colors.dark)};

  }

  &&:hover{
    filter:${props => (props.checked ? 'saturate(1.3)' : 'brightness(0.97)')} ;

  }


`;

export const Divider = styled.div`
  width: 5px;
  border-radius:3px;
  height:120px;

  background-color: ${props => props.theme.colors.lighterWhite};
  margin: 0 10px;

`;

export const BaseInfoMedias = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width:120px;
  margin-right: 5px;

  .line {
    width:100%;
    height: 3px;
    background-color: ${props => props.theme.colors.lighterWhite};
    margin: 15px 0;
    border-radius:3px;
  }

  .circle {
    width:10px;
    height: 10px;
    background-color: ${props => props.theme.colors.lighterWhite};
    margin: 15px 0;
    border-radius:50%;
  }
`;


export const ButtonMedia = styled.div`
  min-width: 160px;
  height: 160px;
  background-color: ${props => (props.color ? props.color : props.theme.colors.lighterWhite)};
  padding:15px 15px 25px 15px;
  border-radius:28px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  margin-right:15px;
  cursor: pointer;

  span{
    font-family: 'Simplon OI';

    font-size: 14pt;
    color: ${props => (props.color ? props.theme.colors.white : props.theme.colors.dark)};

  }

  &&:hover{
    filter: brightness(0.97);
  }
`;


export const MediaImagemPreview = styled.div`
  border-radius: 28px;
  display:flex;
  flex-direction:column;

  justify-content: flex-end;
  align-items:center;

  max-width: 470px;
  background-image: url(${props => props.image});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: ${props => props.theme.colors.lighterWhite} ;

  .preview-no-content {
    flex: 1;
    display: flex;
    align-items: center;
  }

 .title-action{
    width:100%;
    padding:15px 30px;
    justify-content: space-between;
    border-radius: 0 0 28px 28px;
    background-color: ${props => props.theme.colors.primaryHex}90;

    img{
      height:30px;
      filter: drop-shadow( 0 5px 8px  ${props => props.theme.colors.black}30);

    }
    span{
      color:${props => props.theme.colors.white};
    }
  }
`;


export const Select = styled.select`

  border-radius: 50px;
  padding: 5px 20px;
min-width:250px;
  outline:none;

  font-size: 14pt;
  color:${props => props.theme.colors.dark};
  font-weight:600;

  border:1px solid ${props => props.theme.colors.regular};

  background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat;
        background-position: right 10px top; /* Change the px value here to change the distance */
        -moz-appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;


`;


export const ButtonExpand = styled.div`
  cursor: pointer;
  border-radius: 14px;
  padding:10px;
  background-color: transparent;
  &&:hover{
    background-color:  ${props => (props.color ? props.color : props.theme.colors.white)}25;
  }
`;
