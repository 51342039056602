import { call, put } from 'redux-saga/effects';

import { Creators as BenefitActions } from '~/redux/ducks/benefit';
import BenefitService from '~/services/BenefitService';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* benefitRequest() {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(BenefitService.benefitRequest);
    yield put(
      BenefitActions.benefitRequestSuccess(response),
    );
    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema carregar os benefícios.',
    ));
  }
}
