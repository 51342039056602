import api from './axios';

const getTenantSettings = async () => {
  try {
    const tenantUuid = localStorage.getItem('uuidTenant');
    const { data } = await api.get(`/tenant/${tenantUuid}`);

    if(data && data.extra && data.extra.benefit) {
      return data;
    }
    const defaultData = {
      extra: {
        benefit: {
          packageLimits: {
            carrot: {
              max: 0,
              period: "day",
              periodNumber: 0,
              limitReachedMessage: ''
            },
            carrotWithoutConversion: {
              max: 0,
              period: "day",
              periodNumber: 0,
              limitReachedMessage: ''
            },
            global: {
              max: 0,
              period: "day",
              periodNumber: 0,
              limitReachedMessage: ''
            },
            periodOptions: [
              "hour",
              "day",
              "week",
              "month"
            ]
          }
        }
      }
    }

    return defaultData;

  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const updateTenantSettings = async ({type, settings}) => {
  try {
    const tenantUuid = localStorage.getItem('uuidTenant');
    const config = {
      configColumn: "extra",
      configPath: "benefit",
      configValue: settings,
    }

    await api.patch(`/tenant/config?tenantUuid=${tenantUuid}`, config);

  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  getTenantSettings,
  updateTenantSettings,
};
