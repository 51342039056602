import React from 'react';

function MoreIcon() {
  return (
    <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.46131 5.54174C6.50327 5.54174 6.53649 5.54174 6.57001 5.54174C8.20517 5.54174 9.84063 5.54057 11.4758 5.54437C11.5769 5.54437 11.6847 5.56712 11.7777 5.60679C11.9386 5.67592 12.0231 5.87514 11.9951 6.06824C11.9666 6.26718 11.8418 6.40661 11.6574 6.43957C11.5932 6.45094 11.5274 6.45678 11.4621 6.45678C9.82926 6.45765 8.19643 6.45736 6.5636 6.45765C6.4956 6.45765 6.4616 6.49149 6.4616 6.55916C6.4616 8.19818 6.4616 9.83749 6.46102 11.4765C6.46102 11.5369 6.45694 11.5984 6.44411 11.6571C6.38903 11.907 6.20282 12.0051 5.98658 11.9998C5.73421 11.9937 5.57247 11.8385 5.54858 11.5993C5.54391 11.5535 5.54333 11.5071 5.54333 11.461C5.54333 9.83165 5.54333 8.20226 5.54333 6.57287C5.54333 6.53729 5.54333 6.5017 5.54333 6.45765H5.43142C3.79626 6.45765 2.1608 6.45882 0.525634 6.45503C0.424511 6.45503 0.316685 6.43198 0.224013 6.39231C0.0681026 6.32523 -0.0207809 6.12367 0.00486419 5.94486C0.035172 5.73513 0.152323 5.59775 0.338542 5.56128C0.402363 5.54874 0.468516 5.54262 0.533503 5.54262C2.16138 5.54174 3.78956 5.54203 5.41744 5.54145C5.55732 5.54145 5.54275 5.56187 5.54275 5.41923C5.54304 3.78984 5.54275 2.16045 5.54333 0.531057C5.54333 0.47301 5.54391 0.413797 5.55499 0.357209C5.60569 0.0932274 5.80269 -0.0100315 6.02184 0.000761098C6.29403 0.0138872 6.4479 0.182193 6.46072 0.457259C6.46189 0.483803 6.46102 0.510639 6.46102 0.537474C6.46102 2.17182 6.46102 3.80588 6.46102 5.44023V5.54174H6.46131Z" fill="white" />
    </svg>
  );
}

export default MoreIcon;
