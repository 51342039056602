import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

import {
  ContainerStyled,
  RowStyled,
  TableStyled,
  FormGroupStyled,
  TextareaStyled,
  InputStyled,
  CurrentInputStyled,
} from '~/Components/Styled';

export const Container = styled(ContainerStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .header-form-box {
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
  }
  .header-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1148px) {
      flex: 1;
    }
  }
  .header-button-box {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    button {
      margin-bottom: 5px;
    }
    span {
      font-style: italic;
    }
  }
`;

export const GroupedRows = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
  border: 2px solid ${props => props.theme.colors.primary};
  margin-bottom: 40px;
`;

export const Table = styled(TableStyled)``;

export const FormGroup = styled(FormGroupStyled)`
  width: 100%;
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)};
  ${props => props.borderLeft
    && css`
      border-left: 2px solid ${props => props.theme.colors.primary};
      padding-left: 10px;
    `}
  > label {
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
  .billing-value {
    font-size: 18px;
    span {
      margin: 0 10px 0 5px;
    }
  }
  .author-label {
    font-weight: bold;
    font-size: 18px;
    height: 36px;
    display: flex;
    align-items: center;
  }
  .DayPickerInput {
    .DayPicker-wrapper {
      zoom: 160%;
    }

    .DayPicker-Day.DayPicker-Day--selected,
    .DayPicker-Day.DayPicker-Day--selected:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white} !important;
    }
    .DayPicker-Day.DayPicker-Day--today {
      color: ${props => props.theme.colors.primary};
    }

    > input {
      border: solid 2px ${props => props.theme.colors.light};
      border-radius: 4px;
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      color: ${props => props.theme.colors.dark};
      padding: 5px 15px;
      width: 100%;
      height: 40px;
    }
  }
`;

export const GroupedSelect = styled.div`
  display: flex;
  [class*='-control'] {
    border: solid 2px ${props => props.theme.colors.light} !important;
    border-right: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
`;

export const ButtonPlus = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border: 1px solid ${props => props.theme.colors.primary};
  color: ${props => props.theme.colors.white};
  width: 38px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
`;

export const Select = styled(ReactSelect)`
  width: 100%;
  [class*='-control'] {
    border: solid 2px ${props => props.theme.colors.light};
  }
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props => props.theme.colors.danger2};
      }
    `}
`;

export const Textarea = styled(TextareaStyled)`
  min-width: 100%;
  max-width: 100%;
`;

export const Input = styled(InputStyled)`
  width: 100%;
`;

export const CurrentInput = styled(CurrentInputStyled)``;

export const GroupedInput = styled.div`
  display: flex;
  input {
    margin: 0;
  }
  button {
    display: flex;
    white-space: nowrap;
    margin: 0;
    height: 40px;
    margin-left: 10px;
  }
`;

export const LogBody = styled.div`
  width: 100%;
  max-height: 400px;
  overflow-y: scroll;
  padding-right: 20px;
`;

export const LogContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;

  .left {
    width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .icon-circle {
      align-items: center;
      justify-content: center;
      display: flex;
      width: 60px;
      height: 60px;
      margin-bottom: 10px;
      border-radius: 50%;
      background-color: ${props => props.theme.colors.primary};

      svg {
        color: ${props => props.theme.colors.white};
        font-size: 25px;
      }
    }

    .line {
      width: 5px;
      background-color: ${props => props.theme.colors.primary};
      border-radius: 4px;
      flex: 1;
      display: flex;
    }
  }
  .right {
    flex: 1;
    .content-log {
      background-color: ${props => props.theme.colors.lighterWhite};
      border-radius: 2px;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 15px;
      margin-left: -15px;
    }
    .status {
      height: 60px;
      margin-bottom: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .col {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      span {
        &.label {
          font-size: 14px;
          color: ${props => props.theme.colors.dark};
          padding: 0;
        }
        &.desc {
          font-weight: 200;
          font-size: 18px;
          color: ${props => props.theme.colors.darker};
        }
        &.desc-bold {
          font-weight: bold;
          font-size: 18px;
          color: ${props => props.theme.colors.darker};
        }
      }
    }

    .content-log-info {
      margin-bottom: 0;
    }

    .content-log-justify {
      margin-top: 20px;
      min-height: 35px;
    }
  }
`;
