import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Row,
  Container,
  BaseNotification,
} from './styles';

import Image from '~/Components/Image';

class SMSMediaMessagePreview extends Component {
  getValueMessage = () => {
    const { data: { content } } = this.props;
    if (content.channelData && content.channelData.message) {
      return content.channelData.message;
    }
    return '';
  };

  renderSmsScreen = () => {
    const { data: { content } } = this.props;
    const url = content.channelData && content.channelData.url;
    const titleContent = content.channelData && content.channelData.title
      ? content.channelData.title
      : ''; // 'Título da Mensagem';
    const subtitleContent = content.channelData && content.channelData.subtitle
      ? content.channelData.subtitle
      : ''; // 'Subtitulo da mensagem descrito com texto largo para teste de campo';
    const iconContent = content.channelData && content.channelData.icon
      ? content.channelData.icon
      : null;
    const imageContent = content.channelData && content.channelData.image
      ? content.channelData.image
      : null;
    const buttonText1Content = content.channelData && content.channelData.button_text_1
      ? content.channelData.button_text_1
      : null;
    const buttonUrl1Content = content.channelData && content.channelData.button_url_1
      ? content.channelData.button_url_1
      : '#';
    const buttonText2Content = content.channelData && content.channelData.button_text_2
      ? content.channelData.button_text_2
      : null;
    const buttonUrl2Content = content.channelData && content.channelData.button_url_2
      ? content.channelData.button_url_2
      : '#';

    return (
      <Container>
        <BaseNotification onClick={() => {
          window.open(url.indexOf('http') === 0 ? url : `http://${url}`, '_blank');
        }}
        >
          <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
            <label className="title-app">App Genérico</label>
            <span className="dot-now-app">
              <span className="dot-app">&#183;</span>
              {' '}
              52min
            </span>
          </Row>
          <Row>
            <div className="title-subtitle">
              <label className="title">{titleContent}</label>
              <p>{subtitleContent}</p>
            </div>
            {iconContent && (
            <div className="icon-box">
              <Image className="img-subtitle" src={iconContent} alt="Icone" />
            </div>
            )}
          </Row>
          {imageContent && (
          <Row className="action-box-banner">
            <Image className="img-banner" src={imageContent} alt="banner" />
          </Row>
          )}
        </BaseNotification>
        {(buttonText1Content || buttonText2Content) && (
          <Row className="action-box">
            {buttonText1Content && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                disabled={!buttonUrl1Content}
                href={buttonUrl1Content.indexOf('http') === 0 ? buttonUrl1Content : `http://${buttonUrl1Content}`}
              >
                {buttonText1Content || 'BOTÃO 1'}
              </a>
            )}
            {buttonText2Content && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                disabled={!buttonUrl2Content}
                href={buttonUrl2Content.indexOf('http') === 0 ? buttonUrl2Content : `http://${buttonUrl2Content}`}
              >
                {buttonText2Content || 'BOTÃO 2'}
              </a>
            )}
          </Row>
        )}
      </Container>
    );
  };

  render() {
    return this.renderSmsScreen();
  }
}

SMSMediaMessagePreview.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape().isRequired,
  }).isRequired,
};

export default SMSMediaMessagePreview;
