import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as BehaviorActions } from '~/redux/ducks/behavior';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import Pagination from '~/Components/Pagination';
import Button from '~/Components/Button';

import NewModal from '../NewModal';

import {
  Container, Row, Table, LinkStyled, BaseTable, Input, Select,
} from './styles';
import BehaviorIconType from '../components/BehaviorIconType';
import ButtonSearch from '~/Components/ButtonSearch';
import StatusInfo from '~/Components/StatusInfo';

const List = ({
  listBehaviors, onNewBehaviorModal, confirmModal,
  onDeleteBehavior, behaviors, changePageBehaviors, paginate,
  filter, onChangeFilter, onCleanFilter, onSearchByFilter,
}) => {
  useEffect(() => {
    listBehaviors();
  }, []);

  const handleExcluirBehavior = (behavior) => {
    confirmModal(
      'Deseja excluir o comportamento?',
      `${behavior.name}`,
      () => onDeleteBehavior(behavior.uuid),
    );
  };

  const renderRowBehavior = behavior => (
    <tr key={behavior.uuid}>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <BehaviorIconType eventType={behavior.eventType} />
        </div>
      </td>
      <td className="link">
        <LinkStyled to={`behaviors/crud/${behavior.uuid}`}>
          {behavior.name}
        </LinkStyled>
      </td>
      <td>
        {behavior.owner}
      </td>
      <td>
        {behavior.startDate ? moment(behavior.startDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'Sem informação'}
      </td>
      <td>
        {behavior.endDate ? moment(behavior.endDate, 'YYYY-MM-DD').format('DD/MM/YYYY') : 'Sem informação'}
      </td>
      <td>
        {behavior.updatedAt ? moment(behavior.updatedAt).format('DD/MM/YYYY HH:mm') : 'Sem informação'}
      </td>
      <td>
        <StatusInfo status={behavior.enabled} labelTrue="Ativo" labelFalse="Inativo" />
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            bordered
            title="EXCLUIR"
            onClick={() => handleExcluirBehavior(behavior)}
          />
        </div>
      </td>
    </tr>
  );

  const renderBehaviors = () => (
    <BaseTable>
      <Table>
        <thead>
          <tr>
            <th style={{ width: 60 }}>TIPO</th>
            <th>NOME</th>
            <th style={{ width: 200 }}>RESPONSÁVEL</th>
            <th style={{ width: 150 }}>INÍCIO</th>
            <th style={{ width: 150 }}>FINAL</th>
            <th style={{ width: 150 }}>ÚLTIMA ATUALIZAÇÃO</th>
            <th style={{ width: 100 }}>STATUS</th>
            <th style={{ width: 150 }} />
          </tr>
        </thead>
        <tbody>
          {behaviors.length === 0 && (
          <tr>
            <td colSpan="8">
              <div className="empty-table-desc">
                <span> Não foram localizados registros de campanhas</span>
              </div>
            </td>
          </tr>
          )}
          {behaviors.length > 0 && behaviors.map(behavior => renderRowBehavior(behavior))}
        </tbody>
      </Table>
      <Row>
        <Pagination
          page={paginate.page}
          pageCount={paginate.count}
          pageChange={(e) => {
            if (paginate.page !== parseInt(e.selected)) {
              changePageBehaviors(parseInt(e.selected));
            }
          }}
        />
      </Row>
    </BaseTable>
  );

  const statusList = [
    { value: true, label: 'Ativo' },
    { value: false, label: 'Inativo' },
  ];

  return (
    <>
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>COMPORTAMENTOS</h1>

          <Button
            bordered
            onClick={() => onNewBehaviorModal()}
            title="NOVO COMPORTAMENTO"
          />
        </Row>
        <Row>
          <div className="box-search">
            <Input
              placeholder="Procurar por nome"
              value={filter.name}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  onSearchByFilter();
                }
              }}
              onChange={(e) => {
                onChangeFilter({
                  ...filter,
                  name: e.target.value,
                });
              }}
            />
            <Select
              placeholder="Procurar por status"
              value={filter.status}
              onChange={(item) => {
                onChangeFilter({
                  ...filter,
                  status: item,
                });
              }}
              options={statusList}
              isClearable
            />
            <ButtonSearch
              labelSearch="Buscar"
              onClickSearch={() => onSearchByFilter()}
              onClickClean={() => onCleanFilter()}
            />

          </div>
        </Row>
        <Row>{renderBehaviors()}</Row>
      </Container>
      <NewModal />
    </>
  );
};
List.defaultProps = {
  behaviors: [],
};

List.propTypes = {
  behaviors: PropTypes.arrayOf(PropTypes.shape()),
  listBehaviors: PropTypes.func.isRequired,
  onNewBehaviorModal: PropTypes.func.isRequired,
  onDeleteBehavior: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  changePageBehaviors: PropTypes.func.isRequired,
  paginate: PropTypes.shape().isRequired,
  filter: PropTypes.shape().isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  onCleanFilter: PropTypes.func.isRequired,
  onSearchByFilter: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  behaviors: state.behavior.behaviors,
  paginate: state.behavior.paginate,
  filter: state.behavior.filter,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    listBehaviors: BehaviorActions.listBehaviors,
    onNewBehaviorModal: BehaviorActions.onNewBehaviorModal,
    onDeleteBehavior: BehaviorActions.onDeleteBehavior,
    confirmModal: ConfirmModalActions.showConfirmModal,
    changePageBehaviors: BehaviorActions.changePageBehaviors,
    onChangeFilter: BehaviorActions.onChangeFilter,
    onCleanFilter: BehaviorActions.onCleanFilter,
    onSearchByFilter: BehaviorActions.onSearchByFilter,
  },
  dispatch,
);

export default connect(mapStateToProps, mapDispatchToProps)(List);
