import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  FaCheckCircle, FaTimesCircle, FaSpinner, FaCircleNotch, FaExclamationTriangle,
} from 'react-icons/fa';

import Breadcrumb from '~/Components/Breadcrumb';
import Button from '~/Components/Button';

import ActualData from './Components/ActualData';

import { Creators as AudienceActions } from '~/redux/ducks/audience';

import {
  Container,
  Row,
  FormGroup,
  Input,
  FormGroupHeader,
  Toggle,
  Select,
  Box,
  BoxTitle,
  BoxContent,
  BoxContentItem,
  StatusLabel,
  StatusErrorDescription,
  StatusWarningDescription,
  StatusDoneItems,
  BaseResumeDataFileUpload,
} from './styles';
import { formatBytes, getFormattedValueByType } from '~/Utils';

class ExternalAudience extends Component {
  state= {};

  componentDidMount() {
    const {
      match: { params },
      audienceSetupRequest,
      audienceGetData,
      audienceCategorySetActiveFilter,
    } = this.props;
    audienceCategorySetActiveFilter({
      include: [],
      exclude: [],
    });
    audienceGetData(params.audienceId);
    audienceSetupRequest();
  }

  renderHeader = () => {
    const {
      audienceSetUpdate,
      audienceUpdate,
      contracts,
      audienceUpdateData,
      user: { hasPermissionGlobalAudience },
    } = this.props;
    const contractList = contracts.map(item => ({
      label: item.name,
      value: item.uuid,
    }));
    const contractSelected = contractList.find((item) => {
      const hasContract = audienceUpdate.contract;
      return hasContract && hasContract.uuid === item.value;
    });

    return (
      <>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
            marginBottom: 10,
          }}
        >
          <div>
            <Breadcrumb
              items={[
                {
                  route: '/',
                  name: 'Home',
                },
                {
                  route: '/audience',
                  name: 'Audiência',
                },
                {
                  name: 'Cadastro',
                },
              ]}
            />
            <h1>EDITAR AUDIÊNCIA</h1>
          </div>
          <div className="header-button-box">
            <ActualData />
            <Button
              onClick={() => {
                this.updateDadosAudienciaExterna();
              }}
              bordered
              icon="FaSync"
              title="Atualizar dados"
            />
            <Button
              disabled={
                !audienceUpdate.name
                || !audienceUpdate.contract
              }
              onClick={() => {
                audienceUpdateData();
              }}
              title="Salvar"
            />
          </div>
        </Row>
        <Row
          style={{
            alignItems: 'flex-start',
            flexWrap: 'wrap',
            marginBottom: 10,
          }}
        >
          <Row className="header-form-box">
            <FormGroup spaceRight={70}>
              <label>Nome</label>
              <Input
                placeholder="Nome"
                defaultValue={audienceUpdate.name}
                onChange={(e) => {
                  audienceSetUpdate({
                    ...audienceUpdate,
                    name: e.target.value,
                    description: e.target.value,
                  });
                }}
                invalid={false}
              />
            </FormGroup>
            {hasPermissionGlobalAudience && (
              <FormGroupHeader style={{ flex: 1 }}>
                <FormGroup style={{ alignItems: 'center' }} spaceRight={10}>
                  <label>Audiência global</label>
                  <Toggle
                    value={audienceUpdate.shared}
                    style={{ justifyContent: 'center' }}
                    onChange={(e) => {
                      const isGlobal = e.target.checked;
                      audienceSetUpdate({
                        ...audienceUpdate,
                        shared: isGlobal,
                        contract: isGlobal ? contracts[0] : audienceUpdate.contract,
                      });
                    }}
                  />
                </FormGroup>

                <FormGroup>
                  <label>Contrato</label>
                  <Select
                    isDisabled={audienceUpdate.shared}
                    placeholder="Contrato"
                    value={contractSelected || null}
                    onChange={(contract) => {
                      audienceSetUpdate({
                        ...audienceUpdate,
                        contract: contracts.find(
                          item => item.uuid === (contract ? contract.value : null),
                        ),
                      });
                    }}
                    options={contractList}
                    invalid={false}
                  />
                </FormGroup>
              </FormGroupHeader>
            )}
          </Row>
        </Row>
      </>
    );
  };

  getStatusBox = () => {
    const { audienceUpdate } = this.props;
    switch (audienceUpdate.processingStatus) {
      case 'PENDING_SYNC': return (
        <StatusLabel>
          <FaCircleNotch className="status-unprocessed" />
          Aguardando sincronização...
        </StatusLabel>
      );
      case 'UNPROCESSED': return (
        <StatusLabel>
          <FaCircleNotch className="status-unprocessed" />
          Aguardando validação...
        </StatusLabel>
      );
      case 'VALIDATING': return (
        <StatusLabel>
          <FaSpinner className="status-processing" />
          Arquivo em validação...
        </StatusLabel>
      );
      case 'VALIDATED': return (
        <StatusLabel>
          <FaSpinner className="status-processing" />
          Arquivo validado, aguardando processamento...
        </StatusLabel>
      );
      case 'PROCESSING': return (
        <StatusLabel>
          <FaSpinner className="status-processing" />
          Em processamento...
        </StatusLabel>
      );
      case 'SYNC_FINISHED': return (
        <StatusLabel>
          <FaCheckCircle className="status-processed" />
          Sincronia efetuada com sucesso.
        </StatusLabel>
      );
      case 'PROCESSED': return (
        <StatusLabel>
          <FaCheckCircle className="status-processed" />
          Processado.
        </StatusLabel>
      );
      case 'PROCESSING_ERROR': return (
        <StatusLabel>
          <FaTimesCircle className="status-processing-error" />
          Erro no processamento.
        </StatusLabel>
      );
      case 'PROCESSING_ERROR_HEADER': return (
        <StatusLabel>
          <FaTimesCircle className="status-processing-error" />
          Erro no processamento.
        </StatusLabel>
      );
      case 'INVALID_FILE':
      case 'INVALID_FILE_PROCESSED':
        return (
          <StatusLabel>
            <FaExclamationTriangle className="status-processing-warning" />
            Arquivo processado com erro.
          </StatusLabel>
        );
      default: return null;
    }
  };

  renderResumeFile = () => {
    const { audienceUpdate } = this.props;
    const { file } = audienceUpdate.countMap;

    if (!file) {
      return null;
    }

    return (
      <BaseResumeDataFileUpload>
        <div className="header">Resumo arquivo</div>
        <div className="content">
          <div className="item">
            <p>Total de linhas do arquivo:</p>
            <span>{getFormattedValueByType('decimal', file.totalLines || 0)}</span>
          </div>
          <div className="item">
            <p>Total de linhas importadas:</p>
            <span>{getFormattedValueByType('decimal', file.totalLinesImported || 0)}</span>
          </div>
          <div className="item">
            <p>Total de linhas válidas:</p>
            <span>{getFormattedValueByType('decimal', file.totalLinesOk || 0)}</span>
          </div>
          <div className="item">
            <p>Total de linhas inválidas:</p>
            <span>{getFormattedValueByType('decimal', file.totalLinesNotOk || 0)}</span>
          </div>
          <div className="item">
            <p>Total de linhas com falhas na importação:</p>
            <span>{getFormattedValueByType('decimal', file.totalLinesLost || 0)}</span>
          </div>
        </div>
      </BaseResumeDataFileUpload>
    );
  }

  getStatusContent = () => {
    const { audienceUpdate, audienceExternalIsShowZonesInfo } = this.props;

    switch (audienceUpdate.processingStatus) {
      case 'UNPROCESSED': return null;
      case 'PENDING_SYNC': return null;
      case 'SYNC_FINISHED': return null;
      case 'VALIDATING': return null;
      case 'VALIDATED': return null;
      case 'PROCESSING': return null;
      case 'PROCESSING_ERROR': return (
        <StatusErrorDescription>
          {audienceUpdate.processingMessage || 'Houve um problema no processamento do arquivo.'}
        </StatusErrorDescription>
      );
      case 'PROCESSING_ERROR_HEADER': return (
        <StatusErrorDescription>
          {audienceUpdate.processingMessage || 'Arquivo com o cabeçalho inválido.'}
        </StatusErrorDescription>
      );
      case 'INVALID_FILE':
      case 'INVALID_FILE_PROCESSED':
      case 'PROCESSED': return (
        <>
          {audienceExternalIsShowZonesInfo && (
          <StatusDoneItems>
            <div className="status-done-content">
              <div className="status-done-content-item">
                <b>SMS</b>
                <span>{getFormattedValueByType('decimal', audienceUpdate.countMap.totalOptinSms || 0)}</span>
              </div>
              <div className="status-done-content-item">
                <b>PUSH</b>
                <span>{getFormattedValueByType('decimal', audienceUpdate.countMap.totalOptinPush || 0)}</span>
              </div>
              <div className="status-done-content-item" style={{ minWidth: 250 }}>
                <b>NOTIFICAÇÃO PATROCINADA</b>
                <span>{getFormattedValueByType('decimal', audienceUpdate.countMap.totalSponsorNotification || 0)}</span>
              </div>
              <div className="status-done-content-item" style={{ border: 0 }}>
                <b>NO-CREDIT</b>
                <span>{getFormattedValueByType('decimal', audienceUpdate.countMap.totalOptinRewarded || 0)}</span>
              </div>
            </div>
            <div className="status-done-footer">
              <b>TOTAL TERMINAIS</b>
              <span>{audienceUpdate.countMap.totalImpact}</span>
            </div>
          </StatusDoneItems>
          )}
          {(audienceUpdate.processingStatus === 'INVALID_FILE_PROCESSED' || audienceUpdate.processingStatus === 'INVALID_FILE') && (
            <StatusWarningDescription>
              {audienceUpdate.processingMessage || 'Houve um problema no processamento do arquivo.'}
            </StatusWarningDescription>
          )}
        </>
      );
      default: return null;
    }
  };

  updateDadosAudienciaExterna = () => {
    const {
      match: { params },
      audienceSetupRequest,
      audienceGetData,
    } = this.props;
    audienceGetData(params.audienceId);
    audienceSetupRequest();
  }

  renderContent = () => {
    const { audienceUpdate, downloadOriginalFileAudienceExternal, downloadInvalidationFileAudienceExternal } = this.props;
    const {
      isInvalidationFileDownloadable,
      isOriginalFileDownloadable,
      sizeBytes,
    } = audienceUpdate;

    return (
      <Box>
        <BoxTitle>
          <span>Base de dados</span>
          <div className="base-buttons-download">
            {isOriginalFileDownloadable && (
              <Button
                icon="FaCloudDownloadAlt"
                title="Download arquivo original"
                bordered
                small
                onClick={() => downloadOriginalFileAudienceExternal()}
              />
            )}
            {isInvalidationFileDownloadable && (
              <Button
                icon="FaCloudDownloadAlt"
                title="Download arquivo processado"
                bordered
                small
                onClick={() => downloadInvalidationFileAudienceExternal()}
              />
            )}
          </div>
        </BoxTitle>
        <div className="box-databate-content">
          <div className="box-databate-content-labels">
            <BoxContent>
              <BoxContentItem>
                <b>Status</b>
                {this.getStatusBox()}
              </BoxContentItem>
            </BoxContent>
            <BoxContent>
              <BoxContentItem>
                <b>Tamanho do arquivo</b>
                <span>{sizeBytes ? formatBytes(sizeBytes) : 'Calculando...'}</span>
              </BoxContentItem>
            </BoxContent>
          </div>
          <div className="box-databate-content-result">
            {audienceUpdate.isCountMapVisible && this.renderResumeFile()}
            {this.getStatusContent()}
          </div>
        </div>
      </Box>
    );
  };

  render() {
    const { audienceUpdate } = this.props;
    return (
      <>
        <Container>
          {!!audienceUpdate && this.renderHeader()}
          {!!audienceUpdate && this.renderContent()}
        </Container>
      </>
    );
  }
}

ExternalAudience.defaultProps = {
  // audienceExternalData: null,
};

ExternalAudience.propTypes = {
  match: PropTypes.shape().isRequired,
  audienceExternalIsShowZonesInfo: PropTypes.bool.isRequired,
  audienceSetupRequest: PropTypes.func.isRequired,
  audienceGetData: PropTypes.func.isRequired,
  audienceCategorySetActiveFilter: PropTypes.func.isRequired,
  audienceSetUpdate: PropTypes.func.isRequired,
  audienceUpdateData: PropTypes.func.isRequired,
  audienceUpdate: PropTypes.shape().isRequired,
  downloadOriginalFileAudienceExternal: PropTypes.func.isRequired,
  downloadInvalidationFileAudienceExternal: PropTypes.func.isRequired,
  user: PropTypes.shape().isRequired,
  contracts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const mapStateToProps = state => ({
  audienceUpdate: state.audience.audienceUpdate,
  contracts: state.audience.contracts,
  user: state.auth.currentUser,
  audienceExternalIsShowZonesInfo: state.auth.audienceExternalIsShowZonesInfo,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    audienceSetupRequest: AudienceActions.audienceSetupRequest,
    audienceGetData: AudienceActions.audienceGetData,
    audienceCategorySetActiveFilter: AudienceActions.audienceCategorySetActiveFilter,
    audienceSetUpdate: AudienceActions.audienceSetUpdate,
    audienceUpdateData: AudienceActions.audienceUpdateData,
    downloadOriginalFileAudienceExternal: AudienceActions.downloadOriginalFileAudienceExternal,
    downloadInvalidationFileAudienceExternal: AudienceActions.downloadInvalidationFileAudienceExternal,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExternalAudience);
