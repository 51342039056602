import styled, { css } from 'styled-components';
import ContentEditable from 'react-contenteditable';
import { general } from '~/Styles';

import { InputStyled } from '~/Components/Styled';

export const Container = styled.div`
  .input-hidden {
    display: none;
  }
  .footer {
    height: 48px;
    border-radius: 0 0 4px 4px;
    border: solid 2px ${props => (props.error === 'error' ? 'red' : props.theme.colors.light)};
    border-top: none !important;
    padding: 5px 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    button {
      height: 30px;
    }
  }

  ${props => props.errorlink && css`
    > div {
      border-color: ${props => props.theme.colors.danger2} !important;
    }
  `}

`;

export const Editable = styled(ContentEditable)`
  min-height: 32px;
  border-radius: 4px 4px 0 0;
  font-size: 16px;
  color: ${props => props.theme.colors.white};
  padding: 5px 10px;
  border: solid 2px ${props => props.theme.colors.light};
  color: ${props => props.theme.colors.dark};
  margin-bottom: 0;
  min-height: 150px;
  display: block;
  overflow-wrap: break-word;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  /* flex-direction: row; */
  /* justify-content: flex-start; */
  /* align-items: flex-start; */

  &:focus {
    outline: none;
    /* box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); */
  }
  :disabled {
    opacity: 0.7;
  }

  &.error {
    border-color: red;
  }

  .text-editable {
    display: inline-flex;
    vertical-align: inherit;
  }

  .input-link {
    border-radius: 4px 0 0 4px;
    font-size: 16px;
    color: ${props => props.theme.colors.white};
    margin-left: 5px;
    border: solid 2px ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.dark};
    width: 150px;
    &:focus {
      outline: none;
    }
  }

  .button-link {
    background: url(${general.image['delete.png']}) no-repeat center;
    background-size: 14px 14px;
    border-radius: 0 4px 4px 0;
    border: solid 2px ${props => props.theme.colors.light};
    border-left: none;
    height: 28px;
    width: 28px;

    margin-right: 5px;
    font-weight: bold;
  }

  .group-link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: fit-content;
  }

  #GROUPLINK {
    border: 1px solid ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    padding: 0 5px;
    border-radius: 5px;
    margin: 0 5px;
    font-style: italic;
    cursor: default;
  }
`;

export const ErrorLink = styled.span`
  font-style: italic;
  color: ${props => props.theme.colors.danger2};
`;

export const LimitCaracteres = styled.span`
  font-style: italic;
  color: #a7a7a7;

  &.error {
    color: ${props => props.theme.colors.danger2};
  }
`;

export const BaseInputLink = styled.div`
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  width: fit-content;

  > input {
    border-radius: 4px 0 0 4px;
    font-size: 16px;
    color: ${props => props.theme.colors.white};
    margin: 0;
    margin-left: 5px;
    border: solid 2px ${props => props.theme.colors.light};
    color: ${props => props.theme.colors.dark};
    width: 350px;
    height: 36px;
    &:focus {
      outline: none;
    }
  }

  > button {
    background: url(${general.image['delete.png']}) no-repeat center;
    background-size: 14px 14px;
    border-radius: 0 4px 4px 0;
    border: solid 2px ${props => props.theme.colors.light};
    border-left: none;
    height: 36px !important;
    width: 28px;

    margin-right: 5px;
    font-weight: bold;
  }

  ${props => props.invalid && css`
      input, button {
        border-color: ${props => props.theme.colors.danger2};
      }
  `}
`;

export const Input = styled(InputStyled)``;

export const ContentLink = styled.div`
  label {
    font-size: 15px;
  }
`;
