import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';

import {
  Row,
  Container,
  ControlPreview,
  ControlButton,
  ControlTitle,
  ContentPreview,
  MidiaVideoPreview,
  MidiaImagePreview,
  IconBox,
  PreviewVastType,
  Box,
  BoxTitle,
  BoxContent,
  PreviewForm,
} from './styles';

import Image from '~/Components/Image';

import FormStep from '../../../PreviewResearch/formStep';
import HeaderRichCreative from '../../components/HeaderRichCreative';

class SMSMediaMessagePreview extends Component {
  state = {
    currentScreen: 'sms',
    screenList: [
      { key: 'sms', value: 'Notificação' },
      { key: 'layout', value: 'Layout' },
      { key: 'media', value: 'Mídia inicial' },
      { key: 'form', value: 'Pesquisa' },
      { key: 'success', value: 'Mensagem de sucesso' },
    ],
  };

  componentDidMount() {
    const { screenList } = this.state;
    this.setState({
      currentScreen: screenList[0],
    });
  }

  renderTipoVast = () => (
    <PreviewVastType>
      <Box>
        <BoxTitle>
          <span>Sem Preview</span>
        </BoxTitle>
        <BoxContent>
          <p>Esse tipo de Criativo não tem visualização de preview.</p>
          <div>
            <b>Tipo: </b>
            Vast
          </div>
        </BoxContent>
      </Box>
    </PreviewVastType>
  );

  getValueMessage = () => {
    const { data: { content } } = this.props;
    if (content.channelData && content.channelData.message) {
      return content.channelData.message;
    }
    return '';
  };

  renderHeader = (customStyles = null) => {
    const { data: { content } } = this.props;
    let headerimage = '';
    let headerLabel1 = '';
    let headerLabel2 = '';


    if (content.mainData && content.mainData.layout) {
      headerimage = content.mainData.layout.icon || '';
      headerLabel1 = content.mainData.layout.primaryInformation || '';
      headerLabel2 = content.mainData.layout.secondaryInformation || '';
    }
    return (
      <HeaderRichCreative
        image={headerimage}
        label1={headerLabel1}
        label2={headerLabel2}
        style={customStyles}
      />
    );
  };

  renderSmsScreen = () => {
    const { data: { content } } = this.props;
    const titleContent = content.channelData && content.channelData.title
      ? content.channelData.title
      : ''; // 'Título da Mensagem';
    const subtitleContent = content.channelData && content.channelData.subtitle
      ? content.channelData.subtitle
      : ''; // 'Subtitulo da mensagem descrito com texto largo para teste de campo';
    const iconContent = content.channelData && content.channelData.icon
      ? content.channelData.icon
      : null;
    const imageContent = content.channelData && content.channelData.image
      ? content.channelData.image
      : null;
    const buttonText1Content = content.channelData && content.channelData.button_text_1
      ? content.channelData.button_text_1
      : null;
    const buttonUrl1Content = content.channelData && content.channelData.button_url_1
      ? content.channelData.button_url_1
      : '#';
    const buttonText2Content = content.channelData && content.channelData.button_text_2
      ? content.channelData.button_text_2
      : null;
    const buttonUrl2Content = content.channelData && content.channelData.button_url_2
      ? content.channelData.button_url_2
      : '#';

    return (
      <Container>
        <Row style={{ alignItems: 'center', justifyContent: 'flex-start' }}>
          <label className="title-app">App Genérico</label>
          <span className="dot-now-app">
            <span className="dot-app">&#183;</span>
            {' '}
            52min
          </span>
        </Row>
        <Row>
          <div className="title-subtitle">
            <label className="title">{titleContent}</label>
            <p>{subtitleContent}</p>
          </div>
          {iconContent && (
            <div className="icon-box">
              <Image className="img-subtitle" src={iconContent} alt="Icone" />
            </div>
          )}
        </Row>
        {imageContent && (
          <Row className="action-box-banner">
            <Image className="img-banner" src={imageContent} alt="banner" />
          </Row>
        )}
        {(buttonText1Content || buttonText2Content) && (
          <Row className="action-box">
            {buttonText1Content && (<a target="_blank" rel="noreferrer" href={buttonUrl1Content}>{buttonText1Content || 'BOTÃO 1'}</a>)}
            {buttonText2Content && (<a target="_blank" rel="noreferrer" href={buttonUrl2Content}>{buttonText2Content || 'BOTÃO 2'}</a>)}
          </Row>
        )}
      </Container>
    );
  };

  renderLayoutScreen = () => {
    const { data: { content } } = this.props;

    if (content.mainData && content.mainData.layout) {
      return (
        <ContentPreview>
          <Row style={{ alignItems: 'center' }}>
            {content.mainData.layout.icon && (
              <IconBox>
                <Image className="img-title" src={content.mainData.layout.icon} alt="Icone" />
              </IconBox>
            )}
            <label className="success-title">
              {content.mainData.layout.title || 'Título da página'}
            </label>
          </Row>
          <Row>
            <p className="success-subtitle">
              {content.mainData.layout.primaryInformation || 'Informação primária'}
            </p>
          </Row>
          <Row>
            <p className="success-subtitle">
              {content.mainData.layout.secondaryInformation || 'Informação secundária'}
            </p>
          </Row>
        </ContentPreview>
      );
    }
    return null;
  };

  renderMediaScreen = () => {
    const { data: { content } } = this.props;

    if (content.mainData && content.mainData.media) {
      if (content.mainData.media.type === 'vast') {
        return (
          <PreviewVastType>
            <Box>
              <BoxTitle>
                <span>Sem Preview</span>
              </BoxTitle>
              <BoxContent>
                <p>Esse tipo de Criativo não tem visualização de preview.</p>
                <div>
                  <b>Tipo: </b>
                  Vast
                </div>
              </BoxContent>
            </Box>
          </PreviewVastType>
        );
      }
      const textImage = new RegExp('(.*?).(png|PNG|jpg|JPG|jpeg|JPEG)$');
      if (content && content.mainData && content.mainData.media && content.mainData.media.url) {
        let isVideo = false;
        const urlSplit = content.mainData.media.url.split('/');
        const filename = urlSplit.pop();
        if (!textImage.test(filename)) {
          isVideo = true;
        }
        return (
          <ContentPreview>
            {this.renderHeader({ marginLeft: 10, marginRight: 10, marginTop: 10 })}
            {isVideo ? (
              <MidiaVideoPreview controls>
                <source src={content.mainData.media.url} type="video/mp4" />
                Seu browser não suporta o preview de vídeos
              </MidiaVideoPreview>
            ) : (
              <MidiaImagePreview src={content.mainData.media.url} />
            )}
          </ContentPreview>
        );
      }
      return null;
    }

    return null;
  };

  renderFormScreen = () => {
    const {
      data: { content: { mainData } },
    } = this.props;
    const form = mainData && mainData.form ? mainData.form : null;
    const layout = mainData && mainData.layout ? mainData.layout : null;
    if (form) {
      return (
        <PreviewForm>
          {this.renderHeader({ marginLeft: 10, marginRight: 10, marginTop: 10 })}
          <FormStep
            data={form}
            style={{ top: 92 }}
            config={
                layout
                  ? ({
                    primaryColor: layout.primaryColor,
                    secondaryColor: layout.secondaryColor,
                  })
                  : null
              }
          />
        </PreviewForm>
      );
    }
    return null;
  };

  renderSuccessScreen = () => {
    const { data: { content } } = this.props;

    if (content.mainData && content.mainData.success) {
      if (content.mainData.success.type === 'internal') {
        return (
          <ContentPreview>
            {this.renderHeader({ marginLeft: 10, marginRight: 10, marginTop: 10 })}
            <Row style={{ alignItems: 'center' }}>
              <label className="success-title">
                {content.mainData.success.title || 'Título da Mensagem'}
              </label>
            </Row>
            <Row>
              <p className="success-subtitle">
                {content.mainData.success.description || 'Subtitulo da mensagem descrito com texto largo para teste de campo'}
              </p>
            </Row>
          </ContentPreview>
        );
      }
      return (
        <ContentPreview>
          {this.renderHeader({ marginLeft: 10, marginRight: 10, marginTop: 10 })}
          <a className="external-link" href={content.mainData.success.url} target="_blank" rel="noopener noreferrer">
            Acessar link externo
          </a>
        </ContentPreview>
      );
    }
    return null;
  };

  renderScreens = () => {
    const { currentScreen } = this.state;
    switch (currentScreen.key) {
      case 'sms': return this.renderSmsScreen();
      case 'layout': return this.renderLayoutScreen();
      case 'media': return this.renderMediaScreen();
      case 'form': return this.renderFormScreen();
      case 'success': return this.renderSuccessScreen();
      default: return null;
    }
  };

  render() {
    const { currentScreen, screenList } = this.state;

    return (
      <>
        {currentScreen && (
          <ControlPreview>
            <ControlButton
              disabled={screenList.indexOf(currentScreen) === 0}
              onClick={() => {
                this.setState({
                  currentScreen: screenList[screenList.indexOf(currentScreen) - 1],
                });
              }}
            >
              <FaChevronLeft />
            </ControlButton>
            <ControlTitle>
              {currentScreen.value}
            </ControlTitle>
            <ControlButton
              disabled={screenList.indexOf(currentScreen) === screenList.length - 1}
              onClick={() => {
                this.setState({
                  currentScreen: screenList[screenList.indexOf(currentScreen) + 1],
                });
              }}
            >
              <FaChevronRight />
            </ControlButton>
          </ControlPreview>
        )}
        {this.renderScreens()}
      </>
    );
  }
}

SMSMediaMessagePreview.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape().isRequired,
  }).isRequired,
};

export default SMSMediaMessagePreview;
