/* eslint-disable import/no-cycle */
import Form from './Form';
import Preview from './Preview';
import { validateTagGA, validateUrl, xmlIsValid } from '~/Utils';
import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';

export const internalMediaComponentLayoutIsValid = (layout) => {
  if (!layout) return false;
  if (!layout.title) return false;
  if (!layout.primaryColor) return false;
  if (!layout.secondaryColor) return false;
  return true;
};

export const internalMediaComponentSuccessIsValid = (success) => {
  if (!success) return false;
  if (!success.type) return false;
  if (success.type === 'external') {
    if (!success.url) return false;
    if (!validateUrl(success.url)) return false;
  }
  if (success.type === 'internal') {
    if (!success.title) return false;
    if (!success.description) return false;
  }
  return true;
};

export const internalMediaComponentContentIsValid = (media, zone) => {
  if (!media || media.length === 0) return false;

  const getZoneConfigExternalUrl = (typeMidia) => {
    if (zone) {
      const {
        extra,
      } = zone;
      if (!!extra
        && !!extra.creatives
        && !!extra.creatives.internal
        && !!extra.creatives.internal.medias
        && !!extra.creatives.internal.medias[typeMidia]) {
        const { externalUrl } = extra.creatives.internal.medias[typeMidia];
        return externalUrl;
      }
    }
    return null;
  };

  const validateExternalUrl = (mediaItem) => {
    const externalUrlConfig = getZoneConfigExternalUrl(mediaItem.type);
    if (externalUrlConfig && externalUrlConfig.editable) {
      const { externalUrl } = mediaItem;
      if (externalUrl) {
        if (!!externalUrl.url && !validateUrl(externalUrl.url)) {
          return false;
        }
      }
    }
    return true;
  };

  let result = true;
  media.forEach((i) => {
    if (!i.title) result = false;
    if (!i.type) result = false;

    if (i.properties && i.properties.replicasEnabled) {
      if (!i.qntReplicas || (i.qntReplicas && i.qntReplicas < 1)) {
        result = false;
      }
    }

    if (i.type === CreativeCustomMidiaType.VIDEO) {
      if (!i.content) {
        result = false;
      }
      if (result) {
        if (!validateExternalUrl(i)) {
          result = false;
        }
      }
    }
    if (i.type === CreativeCustomMidiaType.IMAGE) {
      if (!i.content) {
        result = false;
      }
      if (result) {
        if (!validateExternalUrl(i)) {
          result = false;
        }
      }
      if (i.analytics) {
        const analyticsData = i.analytics;
        const { properties: { analytics } } = i;
        let validateAnalytics = true;
        analytics.forEach((item) => {
          if (validateAnalytics) {
            const value = analyticsData[item.key];
            if (!validateTagGA(value)) {
              validateAnalytics = false;
            }
          }
        });
        if (!validateAnalytics) {
          result = false;
        }
      }
    }
    if (i.type === CreativeCustomMidiaType.DATA_SEARCH && (!!i.content.termPosition && !i.content.confirmationTerm)) result = false;
    if (i.type === CreativeCustomMidiaType.PROGRAMATICA && !i.config.params[0].value) result = false;
    if (i.type === CreativeCustomMidiaType.APP_INSTALL) {
      const { partner, title, link } = i.content;
      if (!partner || !title || !link || (link && !validateUrl(link))) {
        result = false;
      }
    }
    if (i.type === CreativeCustomMidiaType.EXTERNAL_REDIRECT) {
      const { actionButtonLabel, link } = i.content;
      if (!actionButtonLabel || !link || (link && !validateUrl(link))) {
        result = false;
      }
    }
    if (i.type === CreativeCustomMidiaType.VAST) {
      if (!xmlIsValid(i.content)) {
        result = false;
      }
      if (result) {
        if (!validateExternalUrl(i)) {
          result = false;
        }
      }
    }
    if (i.type === CreativeCustomMidiaType.VAST_SURVEY) {
      if (
        !xmlIsValid(i.content)
        || !i.dataSearch
        || !i.dataSearch.questions
        || i.dataSearch.questions.length <= 0
      ) {
        result = false;
      }
      if (result) {
        if (!validateExternalUrl(i)) {
          result = false;
        }
      }
    }
    if (i.type === CreativeCustomMidiaType.VPAID) {
      if (!validateUrl(i.content)) {
        result = false;
      }
      if (result) {
        if (!validateExternalUrl(i)) {
          result = false;
        }
      }
    }
    if (i.type === CreativeCustomMidiaType.VPAID_SURVEY) {
      if (
        !validateUrl(i.content)
        || !i.dataSearch
        || !i.dataSearch.questions
        || i.dataSearch.questions.length <= 0
      ) {
        result = false;
      }
      if (result) {
        if (!validateExternalUrl(i)) {
          result = false;
        }
      }
    }

    if (i.fallbackNoFill) {
      if (!internalMediaComponentContentIsValid([i.fallbackNoFill] || null, zone)) result = false;
    }
  });

  return result;
};

export const internalMediaComponentFullValidation = ({ content }, zone) => {
  if (!content.mainData) return false;
  if (!internalMediaComponentLayoutIsValid(content.mainData.layout || null)) return false;
  if (!internalMediaComponentSuccessIsValid(content.mainData.success || null)) return false;
  if (!internalMediaComponentContentIsValid(content.mainData.media || null, zone)) return false;
  return true;
};

export const InternalMediaComponentForm = Form;

export const InternalMediaComponentPreview = Preview;
