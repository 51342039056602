/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { Marker, Tooltip } from 'react-leaflet';

import { Popup } from './styles';

function MarkerList({ markers, icon }) {
  return (
    <>
      {markers.map((item, index) => (
        <Marker
          key={`${index}(${item.latitude},${item.longitude})`}
          position={[item.latitude, item.longitude]}
          icon={icon}
          zIndexOffset={1}
        >
          <Popup>
            <b>Nome: </b>
            {item.identificador}
            <br />
            <b>Latitude: </b>
            {item.latitude}
            <br />
            <b>Longitude: </b>
            {item.longitude}
            <br />
            <b>Uf: </b>
            {item.uf}
            <br />
            <b>Cidade: </b>
            {item.cidade}
            <br />
            <b>Bairro: </b>
            {item.bairro}
          </Popup>
          <Tooltip direction="top" offset={[0, -42]} opacity={1}>
            <span>{item.identificador}</span>
          </Tooltip>
        </Marker>
      )) }
    </>
  );
}

MarkerList.propTypes = {
  markers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.object.isRequired,
};

export default MarkerList;
