/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import PropTypes from 'prop-types';

import {
  PreviewMessageBox,
  PreviewMessageBoxFooter,
  PreviewMessageBoxContentMenu,
  PreviewMessageBoxContent,
} from './styles';

const getMessageScreen = (contentVersion, content, schema, propContent) => {
  const value = content[propContent];
  if (contentVersion === 2) {
    const screen = schema.screens.find(s => s.id === propContent);
    if (screen) {
      const {
        disabled, valueSuffix, valuePrefix, value: valueScreen,
      } = screen;
      if (disabled) {
        return `${valuePrefix || ''} ${valueScreen || ''} ${valueSuffix || ''}`;
      }
      return `${valuePrefix || ''} ${value || ''} ${valueSuffix || ''}`;
    }
  }
  return value || '';
};

const getMessageScreenOption = (contentVersion, content, schema, propContent, propOption) => {
  let value = '';
  if (content[propContent] && content[propContent][propOption]) {
    value = content[propContent][propOption];
  }

  if (contentVersion === 1) {
    const screen = schema.screens.find(s => s.id === propContent);
    const option = screen.options.find(o => o.id === propOption);
    if (option) {
      const { disabled, value: valueOption } = option;
      if (disabled) {
        return `${valueOption || ''}`;
      }
    }
  } else if (contentVersion === 2) {
    const screen = schema.screens.find(s => s.id === propContent);
    const option = screen.options.find(o => o.id === propOption);
    if (option) {
      const {
        disabled, valueSuffix, valuePrefix, value: valueOption,
      } = option;
      if (disabled) {
        return `${valuePrefix || ''} ${valueOption || ''} ${valueSuffix || ''}`;
      }
      return `${valuePrefix || ''} ${value || ''} ${valueSuffix || ''}`;
    }
  }
  return value || '';
};

const SmartMessageMenuTimPreview = ({
  currentScreen, changeCurrentScreen,
  data: { content, contentVersion, creativeDefinition: { schema } },
}) => (
  <>
    {(currentScreen === 'screen1' || currentScreen === 'screen2') && (
      <PreviewMessageBox main={currentScreen === 'screen2'}>
        <PreviewMessageBoxContent>
          {getMessageScreen(contentVersion, content, schema, 'screen1')}
        </PreviewMessageBoxContent>
        <PreviewMessageBoxFooter>
          <button
            type="button"
            onClick={() => {
              changeCurrentScreen('');
            }}
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={() => {
              changeCurrentScreen('screen2');
            }}
          >
            OK
          </button>
        </PreviewMessageBoxFooter>
      </PreviewMessageBox>
    )}
    {currentScreen === 'screen2' && (
      <PreviewMessageBox child>
        <PreviewMessageBoxContentMenu>
          <button
            type="button"
            onClick={() => {
              changeCurrentScreen('screen3');
            }}
          >
            {getMessageScreenOption(contentVersion, content, schema, 'screen2', 'option1')}
          </button>
          <button
            type="button"
            onClick={() => { }}
          >
            {getMessageScreenOption(contentVersion, content, schema, 'screen2', 'option2')}
          </button>
          <button
            type="button"
            onClick={() => {
              changeCurrentScreen('');
            }}
          >
            {getMessageScreenOption(contentVersion, content, schema, 'screen2', 'option3')}
          </button>
        </PreviewMessageBoxContentMenu>
      </PreviewMessageBox>
    )}
    {currentScreen === 'screen3' && (
      <PreviewMessageBox>
        <PreviewMessageBoxContent>
          {getMessageScreen(contentVersion, content, schema, 'screen3')}
        </PreviewMessageBoxContent>
        <PreviewMessageBoxFooter>
          <button
            type="button"
            onClick={() => {
              changeCurrentScreen('');
            }}
          >
            Cancelar
          </button>
          <button
            type="button"
            onClick={() => {
              changeCurrentScreen('');
            }}
          >
            OK
          </button>
        </PreviewMessageBoxFooter>
      </PreviewMessageBox>
    )}
  </>
);

SmartMessageMenuTimPreview.propTypes = {
  currentScreen: PropTypes.string.isRequired,
  changeCurrentScreen: PropTypes.func.isRequired,
  data: PropTypes.shape({
    content: PropTypes.shape().isRequired,
    contentVersion: PropTypes.number,
    creativeDefinition: PropTypes.shape({
      schema: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    }).isRequired,
  }).isRequired,
};

export default SmartMessageMenuTimPreview;
