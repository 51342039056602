import queryString from 'query-string';
import api from './axios';

const search = async ({
  contractUuid,
  page,
  channel,
  zone,
  type,
  status,
  product,
  name,
  waitingApprovalCampaign,
  approvedCampaign,
  rejectedCampaign,
  keyword,
  creative,
}) => {
  const url = (`campaign?${
    queryString.stringify({
      page: page || 0,
      contractUuid,
      channelUuid: channel,
      zoneUuid: zone,
      productUuid: product,
      typeUuid: type,
      statusUuid: status,
      name,
      waitingApprovalCampaign,
      approvedCampaign,
      rejectedCampaign,
      keyword,
      creative,
    }, {
      skipNull: true,
      skipEmptyString: true,
    })}
  `);
  try {
    const { data } = await api.get(url);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};


const get = async (campaignId) => {
  try {
    const { data } = await api.get(`campaign/${campaignId}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const reportDownload = async (
  {
    contractUuid,
    channel,
    type,
    status,
    product,
    name,
    waitingApprovalCampaign,
    approvedCampaign,
    rejectedCampaign,
    keyword,
    creative,
  },
) => {
  let url = 'mcare-artemis-report-manager/campaign/report?page=0';

  if (contractUuid) {
    url += `&contractUuid=${contractUuid}`;
  }
  if (channel) {
    url += `&channelUuid=${channel}`;
  }
  if (product) {
    url += `&productUuid=${product}`;
  }
  if (type) {
    url += `&typeUuid=${type}`;
  }
  if (status) {
    url += `&statusUuid=${status}`;
  }
  if (name) {
    url += `&name=${name}`;
  }
  if (waitingApprovalCampaign) {
    url += `&waitingApprovalCampaign=${waitingApprovalCampaign}`;
  }
  if (approvedCampaign) {
    url += `&approvedCampaign=${approvedCampaign}`;
  }
  if (rejectedCampaign) {
    url += `&rejectedCampaign=${rejectedCampaign}`;
  }
  if (keyword) {
    url += `&keyword=${keyword}`;
  }
  if (creative) {
    url += `&creative=${creative}`;
  }
  try {
    const { data } = await api.get(encodeURI(url), { responseType: 'blob' });
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const resultQuiz = async (campaignId) => {
  try {
    const { data } = await api.get(`mcare-artemis-report-manager/campaign/results/quiz/${campaignId}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listProducts = async (filter) => {
  try {
    const { data } = await api.get(`product${filter && filter.zoneUuid ? `?zoneUuid=${filter.zoneUuid}` : ''}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listAllProducts = async () => {
  try {
    const { data } = await api.get('workflow/product');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listChannels = async () => {
  try {
    const { data } = await api.get('channel');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listTypes = async () => {
  try {
    const { data } = await api.get('type');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listContracts = async () => {
  try {
    const { data } = await api.get('contract');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listStatus = async () => {
  try {
    const { data } = await api.get('status');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listRecipes = async () => {
  try {
    const { data } = await api.get('recipe');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const consultaFortuna = async () => {
  try {
    const { data } = await api.get('mcare-artemis-campaign-manager/fortuna/consulta');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const create = async (campaign) => {
  try {
    const { data } = await api.post(
      'campaign',
      campaign,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const update = async ({ campaign }) => {
  try {
    const { data } = await api.put(
      `mcare-artemis-campaign-manager/campaign/${campaign.uuid}`,
      campaign,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const excluir = async (campaignId) => {
  try {
    await api.delete(`campaign/${campaignId}`);
    return true;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const campaignCloneRequest = async ({ uuid, name }) => {
  try {
    const { data } = await api.post(
      `campaign/${uuid}/clone`,
      {
        newName: name,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const listAudience = async () => {
  try {
    const { data } = await api.get('mcare-artemis-audiences-get');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const activeCampaign = async ({ campaignId, userId }) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-campaign-manager/approval/${campaignId}`,
      {
        status: 'WAITING_APPROVAL',
        user: userId,
        reason: '',
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const rejectCampaign = async ({ campaignId, userId, reason }) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-campaign-manager/approval/${campaignId}`,
      {
        status: 'EDITING',
        user: userId,
        reason,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const demonstrationCampaign = async ({ campaignId, userId }) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-campaign-manager/approval/${campaignId}`,
      {
        status: 'DEMONSTRATION',
        user: userId,
        reason: '',
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const runDemonstrationCampaign = async (campaignId) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-campaign-manager/campaign/run-demo/${campaignId}`,
      null,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const approvateCampaign = async (campaignId) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-campaign-manager/campaign/activate/${campaignId}`,
      null,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const cancelCampaign = async ({ campaignId, reason, userId }) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-campaign-manager/campaign/close/${campaignId}`,
      {
        userId,
        reason,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const pauseResumeCampaign = async ({ campaignId, reason, userId }) => {
  try {
    const { data } = await api.post(
      `mcare-artemis-campaign-manager/campaign/pause/${campaignId}`,
      {
        userId,
        reason,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const downloadReport = async (campaignId) => {
  try {
    const { data } = await api.get(
      `api/prod/mcare-artemis-campaign-manager/campaign/report/download/${campaignId}`,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const workflowStatus = async (transition) => {
  try {
    const { data } = await api.post(
      `workflow/${transition.campaignUuid}/status`,
      {
        transitionStateUuid: transition.transitionStateUuid,
        observation: transition.observation,
      },
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const verifyIncludeProbeGrid = async (campaignId) => {
  try {
    const { data } = await api.get(`/campaign/${campaignId}/verify-include-probe-grid`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const includeToProbeGrid = async (campaignId) => {
  try {
    const { data } = await api.put(`/campaign/${campaignId}/include-to-probe-grid`, {});
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getAttributesCampaign = async (campaignId) => {
  try {
    const { data } = await api.get(`/campaign/${campaignId}/attribute`, {});
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getFactCampaign = async (campaignId) => {
  try {
    const { data } = await api.get(`/campaign/fact/${campaignId}`, {});
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getCampaignShare = async (campaignId) => {
  try {
    const { data } = await api.get(`/v1/campaign/${campaignId}/share`, {});
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const updateValueAttributeCampaign = async ({ campaignUuid, attributeUuid, value }) => {
  try {
    await api.put(`/campaign/${campaignUuid}/attribute/${attributeUuid}/value`, { value });
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  search,
  listProducts,
  listAllProducts,
  listChannels,
  listTypes,
  listContracts,
  listStatus,
  listAudience,
  create,
  update,
  get,
  reportDownload,
  excluir,
  campaignCloneRequest,
  activeCampaign,
  approvateCampaign,
  demonstrationCampaign,
  runDemonstrationCampaign,
  rejectCampaign,
  cancelCampaign,
  pauseResumeCampaign,
  consultaFortuna,
  downloadReport,
  listRecipes,
  workflowStatus,
  resultQuiz,
  verifyIncludeProbeGrid,
  includeToProbeGrid,
  getAttributesCampaign,
  getFactCampaign,
  getCampaignShare,
  updateValueAttributeCampaign,
};
