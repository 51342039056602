import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

import { RowStyled, FormGroupStyled, TextareaStyled } from '~/Components/Styled';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 20px;
  width: 100%;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-right: 0 !important;
`;

export const FormGroup = styled(FormGroupStyled)`
  flex: 1;
  margin: 0;
`;

export const BaseMidiaButton = styled(FormGroupStyled)`
  flex: 1;
`;

export const MediaUpload = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.danger2};
  font-style: italic;
`;

export const MessageMediaRequired = styled.p`
    color: red;
    font-style: italic;
    margin-bottom: 0px;
    margin-left: 35px;
`;

export const Select = styled(ReactSelect)`
  width: 450px;
  > div {
    border-width: 2px !important;
  }
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const Textarea = styled(TextareaStyled)`
  width: 100%;
  resize: vertical;
`;
