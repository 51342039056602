import styled from 'styled-components';
import Button from '~/Components/Button';
import ReactSelect from '~/Components/Select';
import {
  RowStyled,
  FormGroupStyled,
  TableStyled,
} from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 0 10px 0;
  label {
    > b {
      width: 100px;
      display: inline-block;
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const FormGroupHeader = styled.div`
  display: flex;
  flex-direction: column;
  label {
    color: ${props => props.theme.colors.regular};
    font-size: 18px;
    font-weight: bold;
  }
`;

export const AddBeneficio = styled(Button)`
  align-self: flex-end;
  margin: 0;
`;

export const RemoveBeneficio = styled(Button)`
  margin: 0;
`;

export const Select = styled(ReactSelect).attrs({
  width: '100%',
  marginRight: 10,
})``;

export const Table = styled(TableStyled)`
  margin-top: 20px;
`;
