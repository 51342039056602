import React from 'react';
import PropTypes from 'prop-types';
import { FaCircle } from 'react-icons/fa';

import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';

import {
  ContainerTabs, Row,
} from './styles';
import TabInitialContent from './components/TabInitialContent';
import TabResearch from './components/TabResearch';
import TabSuccess from './components/TabSuccess';
import TabLayout from './components/TabLayout';

const SMSMediaMessageForm = ({
  campaign,
  criativoEdit,
  campaignEditCriativoRequestSuccess,
  hasError,
  disabledFields,
  title,
}) => {
  const { content } = criativoEdit;

  const tabLayoutIsInvalid = () => {
    if (!content.mainData) return true;
    if (!content.mainData.layout) return true;
    if (!content.mainData.layout.title) return true;
    // if (!content.mainData.layout.primaryInformation) return true;
    // if (!content.mainData.layout.secondaryInformation) return true;
    if (!content.mainData.layout.primaryColor) return true;
    if (!content.mainData.layout.secondaryColor) return true;
    // if (!content.mainData.layout.icon) return true;
    return false;
  };

  const tabMediaIsInvalid = () => {
    if (content.mainData && content.mainData.media) {
      if (content.mainData.media.type === 'file' && !content.mainData.media.url) return true;
      if (content.mainData.media.type === 'vast' && !content.mainData.media.content) return true;
    }
    return false;
  };

  const tabFormIsInvalid = () => {
    if (content.mainData && content.mainData.form) {
      if (!!content.mainData.form.termPosition && !content.mainData.form.confirmationTerm) return true;
    }
    return false;
  };

  const tabSuccessIsInvalid = () => {
    if (!content.mainData) return true;
    if (!content.mainData.success) return true;
    if (!content.mainData.success.type) return true;
    if (content.mainData.success.type === 'external') {
      if (!content.mainData.success.url) return true;
      if (!content.mainData.success.url.match(/^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/)) return true;
    }
    if (content.mainData.success.type === 'internal') {
      if (!content.mainData.success.title) return true;
      if (!content.mainData.success.description) return true;
    }
    return false;
  };

  return (
    <>
      {!!title && (
        <Row>
          <h1 className="title-separator">{title}</h1>
        </Row>
      )}
      {!!content && (
        <Row>
          <ContainerTabs style={{ flex: 1 }}>
            <Tabs>
              <TabList>
                <Tab>
                  Layout
                  {hasError && tabLayoutIsInvalid() && (
                    <FaCircle className="tab-has-error" />
                  )}
                </Tab>
                <Tab>
                  Mídia Inicial
                  {hasError && tabMediaIsInvalid() && (
                    <FaCircle className="tab-has-error" />
                  )}
                </Tab>
                <Tab>
                  Pesquisa
                  {hasError && tabFormIsInvalid() && (
                    <FaCircle className="tab-has-error" />
                  )}
                </Tab>
                <Tab>
                  Mensagem de Sucesso
                  {hasError && tabSuccessIsInvalid() && (
                    <FaCircle className="tab-has-error" />
                  )}
                </Tab>
              </TabList>
              <TabPanel>
                <TabLayout
                  hasError={hasError}
                  campaign={campaign}
                  content={content}
                  criativoEdit={criativoEdit}
                  campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
                  disabledFields={disabledFields}
                />
              </TabPanel>
              <TabPanel>
                <TabInitialContent
                  hasError={hasError}
                  campaign={campaign}
                  content={content}
                  criativoEdit={criativoEdit}
                  campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
                  disabledFields={disabledFields}
                />
              </TabPanel>
              <TabPanel>
                <TabResearch
                  hasError={hasError}
                  campaign={campaign}
                  content={content}
                  criativoEdit={criativoEdit}
                  campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
                  disabledFields={disabledFields}
                />
              </TabPanel>
              <TabPanel>
                <TabSuccess
                  hasError={hasError}
                  campaign={campaign}
                  content={content}
                  criativoEdit={criativoEdit}
                  campaignEditCriativoRequestSuccess={campaignEditCriativoRequestSuccess}
                  disabledFields={disabledFields}
                />
              </TabPanel>
            </Tabs>
          </ContainerTabs>
        </Row>
      )}
    </>
  );
};

SMSMediaMessageForm.defaultProps = {
  title: '',
};

SMSMediaMessageForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

export default SMSMediaMessageForm;
