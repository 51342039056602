import styled from 'styled-components';
import {
  RowStyled,
  FormGroupStyled,
  InputStyled,
} from '~/Components/Styled';


export const Container = styled.div``;

export const Input = styled(InputStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 0 10px 0;
  label {
    display: flex;
    flex: 1;
    align-items: center;
    > b {
      margin-right: 10px;
    }
    input {
      display: flex;
      flex: 1;
    }
  }
`;

export const ClearButton = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  padding: 0 5px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
`;

export const Message = styled.div`
  text-align: center;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
`;
