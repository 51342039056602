import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';
import { Creators as ToastrActions } from '~/redux/ducks/toastrMessage';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  ModalHeader,
  AddBeneficio,
  RemoveBeneficio,
  FormGroupHeader,
  Select,
  Table,
  Row,
  RowFooter,
  ButtonCloseModal,
} from './styles';

class ModalEditBeneficio extends Component {
  state = {
    benefitType: null,
    benefitOffer: null,
  };

  static propTypes = {
    modalEdit: PropTypes.shape().isRequired,
    campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
    campaignEditBenefitSave: PropTypes.func.isRequired,
    benefits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    benefitList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    confirmModal: PropTypes.func.isRequired,
    campaignEditBenefitSetList: PropTypes.func.isRequired,
    warningMessage: PropTypes.func.isRequired,
    mediaEventList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    mediaEventUuid: PropTypes.string.isRequired,
    campaignEditBenefitSetMediaEventUuid: PropTypes.string.isRequired,
  };

  componentDidMount() {}

  handleAddBenefit = () => {
    const { benefitOffer, benefitType } = this.state;
    const {
      campaignEditBenefitSetList,
      benefitList,
      warningMessage,
      mediaEventList,
      mediaEventUuid,
      campaignEditBenefitSetMediaEventUuid,
    } = this.props;

    const hasBenefitInList = benefitList.find(item => item.uuid === benefitOffer.uuid);
    const mediaEvent = mediaEventList.find(
      mediaEventItem => mediaEventItem.uuid === mediaEventUuid,
    );

    if (!hasBenefitInList) {
      campaignEditBenefitSetList([
        ...benefitList,
        {
          ...benefitOffer,
          benefit: benefitType,
          CampaignBenefitOffer: {
            mediaEvent: {
              uuid: mediaEvent !== null ? mediaEvent.uuid : '',
              description: mediaEvent !== null ? mediaEvent.description : '',
            },
          },
        },
      ]);
      this.setState({
        benefitType: null,
        benefitOffer: null,
      });
      campaignEditBenefitSetMediaEventUuid(mediaEventList[0].uuid);
    } else {
      warningMessage('Atenção', 'Este benefício já foi inserido na lista!');
    }
  };

  handleRemoveBenefit = (uuid) => {
    const { campaignEditBenefitSetList, benefitList, confirmModal } = this.props;

    const benefitToRemove = benefitList.find(item => item.uuid === uuid);

    confirmModal(
      'Deseja remover o benefício?',
      `${benefitToRemove.benefit.name}(${benefitToRemove.name})`,
      () => {
        campaignEditBenefitSetList(benefitList.filter(item => !(item.uuid === uuid)));
      },
    );
  };

  handleSave = () => {
    const { campaignEditBenefitSave } = this.props;
    campaignEditBenefitSave();
  };

  renderHeader = () => {
    const { benefitType, benefitOffer } = this.state;
    const {
      benefits,
      mediaEventList,
      mediaEventUuid,
      campaignEditBenefitSetMediaEventUuid,
    } = this.props;

    const benefitList = benefits.map(benefit => ({
      ...benefit,
      label: benefit.name,
      value: benefit.uuid,
      benefitOffers: benefit.benefitOffers.map(offer => ({
        ...offer,
        label: offer.name,
        value: offer.uuid,
      })),
    }));

    const mediaEventListItems = mediaEventList.map(mediaEvent => ({
      label: mediaEvent.description,
      value: mediaEvent.uuid,
    }));

    return (
      <ModalHeader>
        <FormGroupHeader style={{ width: 250 }}>
          <label>Tipo de benefício</label>
          <Select
            placeholder="Tipo de benefício..."
            value={benefitType ? benefitList.find(item => benefitType.value === item.value) : null}
            onChange={(type) => {
              this.setState({
                benefitType: type,
                benefitOffer: null,
              });
            }}
            options={benefitList}
            isClearable
          />
        </FormGroupHeader>
        <FormGroupHeader style={{ width: 250 }}>
          <label>Benefício</label>
          <Select
            placeholder="Benefício..."
            value={
              benefitOffer
                ? benefitType.benefitOffers.find(item => benefitOffer.value === item.value)
                : null
            }
            onChange={(offer) => {
              this.setState({
                benefitOffer: offer,
              });
            }}
            options={benefitType ? benefitType.benefitOffers : []}
            isClearable
          />
        </FormGroupHeader>
        <FormGroupHeader style={{ width: 250 }}>
          <label>Momento do concessão</label>
          <Select
            placeholder="Momento do concessão..."
            value={
              mediaEventListItems.length > 0
                ? mediaEventListItems.find(mediaEvent => mediaEvent.value === mediaEventUuid)
                : null
            }
            onChange={(type) => {
              campaignEditBenefitSetMediaEventUuid(type.value);
            }}
            options={mediaEventListItems}
            isClearable={false}
          />
        </FormGroupHeader>
        <AddBeneficio
          disabled={!benefitType || !benefitOffer}
          title="ADICIONAR"
          onClick={this.handleAddBenefit}
        />
      </ModalHeader>
    );
  };

  renderTable = () => {
    const { benefitList, mediaEventList } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th width="1%">#</th>
            <th>Tipo de benefício</th>
            <th>Benefício</th>
            <th>Momento da concessão</th>
            <th width="1%">Ação</th>
          </tr>
        </thead>
        <tbody>
          {benefitList.length < 1 ? (
            <tr>
              <td colSpan="4">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de ofertas</span>
                </div>
              </td>
            </tr>
          ) : (
            benefitList.map((benefitOffer, index) => (
              <tr key={benefitOffer.uuid}>
                <td>
                  <span>{index + 1}</span>
                </td>
                <td>
                  <span>{benefitOffer.benefit.name}</span>
                </td>
                <td>
                  <span>{benefitOffer.name}</span>
                </td>
                <td>
                  <span>
                    {
                      !!benefitOffer.CampaignBenefitOffer
                      && !!benefitOffer.CampaignBenefitOffer.mediaEvent
                      && !!benefitOffer.CampaignBenefitOffer.mediaEvent.description
                      && mediaEventList.length > 0
                      && (mediaEventList.find(mediaEvent => mediaEvent.uuid === benefitOffer.CampaignBenefitOffer.mediaEvent.uuid).description || '')
                    }
                  </span>
                </td>
                <td>
                  <RemoveBeneficio
                    title="REMOVER"
                    bordered
                    onClick={() => this.handleRemoveBenefit(benefitOffer.uuid)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    );
  };

  render() {
    const {
      modalEdit,
      campaignEditChangeModalEditStatus,
    } = this.props;

    return modalEdit.editBenefitStatus && (
      <Modal width={1000}>
        <Row>
          <h1>Alterar Benefício</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              campaignEditChangeModalEditStatus({
                editBenefitStatus: false,
              });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        {this.renderHeader()}
        <Row>
          {this.renderTable()}
        </Row>
        <RowFooter>
          <Button
            title="SALVAR"
            onClick={() => this.handleSave()}
          />
        </RowFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modalEdit: state.campaignEdit.modalEdit,
  benefitList: state.campaignEdit.benefitList,
  benefits: state.benefit.benefits,
  mediaEventList: state.campaignEdit.mediaEventList,
  mediaEventUuid: state.campaignEdit.mediaEventUuid,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditBenefitSave: CampaignEditActions.campaignEditBenefitSave,
    confirmModal: ConfirmModalActions.showConfirmModal,
    campaignEditBenefitSetList: CampaignEditActions.campaignEditBenefitSetList,
    warningMessage: ToastrActions.warningMessage,
    campaignEditBenefitSetMediaEventUuid: CampaignEditActions.campaignEditBenefitSetMediaEventUuid,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditBeneficio);
