import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  RowStyled,
  InputStyled,
  TextareaStyled,
  FormGroupStyled,
} from '~/Components/Styled';


export const ContentModal = styled.div`
  margin: -20px;
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 10px 5px 10px;
  border-bottom: 1px solid ${props => props.theme.colors.regular};
`;

export const Body = styled.div`
  overflow-y: auto;
`;

export const Footer = styled.div`
  height: 70px;
  border-top: 1px solid ${props => props.theme.colors.regular};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  > button {
    position: absolute;
    color: ${props => props.theme.colors.primary};
    font-size: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
  }

  > button.recipe-previous {
    border: 0;
    left: 10px;
    > svg {
      margin-right: 5px;
    }
  }

  > button.recipe-next {
    border: 0;
    right: 10px;
    > svg {
      margin-left: 5px;
    }
  }

  > button.recipe-next[disabled] {
    opacity: 0.8;
  }

  > button.recipe-register {
    right: 15px;
    height: 48px;
    border-radius: 4px;
    font-size: 20px;
    padding: 5px 30px;
    background-color: transparent;
    border: solid 1px ${props => props.theme.colors.primary};
    color: ${props => props.theme.colors.primary};
    margin-left: 15px;
    padding: 5px 15px;
  }

  > button.recipe-register[disabled] {
    opacity: 0.8;
  }
`;

export const CloseHeader = styled.div`
  cursor: pointer;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  padding: 15px 15px 0 15px;
`;

export const ListRecipes = styled.ul`
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0 10px;
`;

export const Recipe = styled.li`
  width: 100%;
  display: flex;
  margin: 10px 0;
  border: ${props => (props.active ? `2px solid ${props.theme.colors.primary}` : `1px solid ${props.theme.colors.dark}`)};
  background-color: ${props => (props.active ? props.theme.colors.lighterWhite : props.theme.colors.white)};
  border-radius: 5px;
  overflow: hidden;
`;

export const RecipeAction = styled.button`
  display: flex;
  width: 100%;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
`;

export const ImageRecipe = styled.div`
  width: 125px;
  height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    padding: 5px;
    max-width: 100%;
    object-fit: cover;
  }
`;

export const InfoRecipe = styled.div`
  flex: 1;
  padding: 10px;
  display: flex;
  flex-direction: column;
`;

export const Description = styled.div`
  font-weight: bold;
  font-size: 16px;
  text-align: left;
`;

export const Details = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
`;

export const ButtonSelectRecipe = styled.div`
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;

  > span {
    color: ${props => props.theme.colors.white};
    background: ${props => props.theme.colors.primary};
    padding: 5px;
    border-radius: 5px;
    width: 100px;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
  }
`;

export const Helper = styled.div`
  font-weight: bold;
  > a {
    color: ${props => props.theme.colors.primary};
    font-weight: normal;
    cursor: pointer;
  }
`;

export const FormNewCampaign = styled.div`
  padding-bottom: 20px;
  button.product-offer-add {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    background-color: transparent;
    color: ${props => props.theme.colors.primary};
    border: 0;
  }
  button.product-offer-remove {
    align-self: flex-end;
    cursor: pointer;
    display: flex;
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${props => props.theme.colors.danger2};
    color: ${props => props.theme.colors.danger2};
    border-radius: 5px;
    background-color: transparent;
    margin-left: 10px;
    margin-bottom: 6px;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  width: 100%;
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)};
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Space = styled.div`
  width: 100%;
`;

export const Input = styled(InputStyled)`
  width: 100%;
`;

export const Textarea = styled(TextareaStyled)`
  width: 100%;
`;

export const Select = styled(ReactSelect)`
  width: 100%;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  ${props => props.invalid
    && css`
      border-color: ${props => props.theme.colors.danger2};
    `}
  [class*="-control"] {
    border: 0;
    outline: none;
    /* background-color: transparent; */
  }
`;

export const FooterForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px 15px 15px 15px;

  > button {
    margin-left: 0 !important;
  }
`;

export const QuestionLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.colors.dark};
  font-size: 20px;
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    color: ${props => props.theme.colors.dark};
  }
`;

export const FooterSteps = styled.div`
  display: flex;
`;

export const Step = styled.div`
  width: 20px;
  height: 20px;
  border: 3px solid ${props => props.theme.colors.primary};
  background-color: ${props => (props.active ? props.theme.colors.primary : props.theme.colors.white)};
  border-radius: 50%;
  margin-right: 5px;
`;

export const LinkTerms = styled.div`
  display: flex;
  width: 100%;
  padding: 21px 15px 0 15px;
  font-size: 18px;
  > a {
    color: ${props => props.theme.colors.primary};
    margin-left: 5px;
  }
`;
