import React, { Component } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Loader } from 'react-loaders';
import FileUploadService from '~/services/FileUploadService';
import { Container } from './styles';

class ImageFromService extends Component {
  state = {
    loading: true,
    image: null,
  };

  componentDidMount() {
    const { image } = this.state;
    if (!image) this.loadImage();
  }

  toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });

  loadImage = async () => {
    const { imageUuid } = this.props;
    const file = await FileUploadService.getFile(imageUuid);
    const image = await this.toBase64(file);
    this.setState({ image, loading: false });
  };

  render() {
    const {
      style,
      theme,
      width,
      height,
      styleImage,
      fill
    } = this.props;
    const { loading, image } = this.state;
    return (
      <Container style={style} width={width} height={height} fill={fill ? fill : 'cover'}>
        {loading ? (
          <Loader active type="line-spin-fade-loader" color={theme.colors.primary} />
        ) : (
          <img style={styleImage} src={image} alt="Imagem" />
        )}
      </Container>
    );
  }
}

ImageFromService.defaultProps = {
  style: null,
  styleImage: null,
};

ImageFromService.propTypes = {
  imageUuid: PropTypes.string.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  style: PropTypes.shape(),
  styleImage: PropTypes.shape(),
  fill: PropTypes.string,
  theme: PropTypes.shape().isRequired,
};

export default withTheme(ImageFromService);
