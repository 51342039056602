/* eslint-disable max-len */
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  cockpitRequest: ['filter'],
  cockpitRequestSuccess: ['cockpitResult'],
});

export const CockpitTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  cockpitResult: null,
});

/* Reducers */
const cockpitRequestSuccess = (state, { cockpitResult }) => ({
  ...state,
  cockpitResult,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CockpitTypes.COCKPIT_REQUEST_SUCCESS]: cockpitRequestSuccess,
});
