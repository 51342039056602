import styled from 'styled-components';


export const Container = styled.div`
  width: 100%;
  height: 100%;
  min-width: 50px;
  min-height: 50px;
  position: relative;
`;

export const BaseLoader = styled.div`
  position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${props => props.theme.colors.light};
`;
