/* eslint-disable react/button-has-type */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Button from '~/Components/Button';
import {
  Container, Editable, LimitCaracteres, ErrorLink,
  BaseInputLink, Input, ContentLink,
} from './styles';

import { replaceAll } from '~/Utils';

const KEY_VALUE_TAG_LINK = 'VAR_VALUE';
const TEMPLATE_TAG_LINK = `<section id="GROUPLINK" class="group-link" contentEditable="false"> ${KEY_VALUE_TAG_LINK} </section>`;
const TEMPLATE_DIV_EMPTY = '<font class="text-editable"></font>';

export default class InputLink extends Component {
  state = {
    isDisabledButton: false,
    html: '',
    tagLinkInclude: '',
    valueLength: 0,
    especialCharacterMessage: '',
  };

  componentDidMount() {
    const {
      tagLinkInclude, value, onChangeLinkIncluido,
    } = this.props;

    let html = `${TEMPLATE_DIV_EMPTY}`;

    let isDisabledButton = false;
    if (value) {
      const link = TEMPLATE_TAG_LINK.replace(KEY_VALUE_TAG_LINK, tagLinkInclude);
      html = replaceAll(value, tagLinkInclude, link);
    }
    if (value.indexOf(tagLinkInclude) >= 0) {
      isDisabledButton = true;
    }

    if (onChangeLinkIncluido) {
      onChangeLinkIncluido(isDisabledButton);
    }

    this.setState({
      tagLinkInclude, isDisabledButton, html, valueLength: value.length,
    });
  }

  onChangeInputUrlLink = (e) => {
    const { value } = e.target;
    this.onChangeLinkUrl(value);
  };

  onClickButtonRemoveLink = (e) => {
    e.preventDefault();
    this.handleRemoveLinkInput();
  };

  onChangeValue = (html) => {
    const { tagLinkInclude } = this.state;
    const { onChange } = this.props;

    let value = html;
    const indexInitLink = value.indexOf('<section id="GROUPLINK"');
    const indexEndLink = value.indexOf('section>');
    let includeLink = false;
    if (indexInitLink >= 0 && indexEndLink > 0) {
      includeLink = true;
    }

    if (includeLink) {
      value = `${value.substring(0, indexInitLink)} ${tagLinkInclude} ${value.substring(
        indexEndLink + 8,
        value.length,
      )}`;
    }

    // Remoção do html nos textos
    value = this.removeHtml(value);
    onChange(value);

    this.verifyEspecialCharecter(value);

    this.setState({ valueLength: value.trim().length });
  };

  verifyEspecialCharecter = (value) => {
    const characterIndex = value.search(/[á|Á|é|É|í|Í|ó|Ó|ú|Ú|à|À|è|È|ì|Ì|ò|Ò|ù|Ù|ã|Ã|õ|Õ|â|Â|ê|Ê|ü|Ü|ç|Ç|]/i);
    if (characterIndex >= 0) {
      const character = value.substr(characterIndex, 1);
      this.setState({ especialCharacterMessage: `O caractere '${character}' não pode ser enviado.` });
    } else {
      this.setState({ especialCharacterMessage: '' });
    }
  }

  removeHtml = (value) => {
    const tmp = document.createElement('DIV');
    tmp.innerHTML = value;
    return tmp.textContent || tmp.innerText;
  }

  handleChange = (evt) => {
    const { onChangeLinkIncluido } = this.props;
    const html = evt.target.value;

    const indexInit = html.indexOf('<section id="GROUPLINK"');

    const isDisabledButton = indexInit >= 0;

    if (onChangeLinkIncluido) {
      onChangeLinkIncluido(isDisabledButton);
    }

    this.setState({ html, isDisabledButton });
    this.onChangeValue(html);
  };

  handleRemoveLinkInput = () => {
    const { html } = this.state;
    const { onChangeLinkIncluido } = this.props;

    const indexInit = html.indexOf('<section id="GROUPLINK"');
    const indexEnd = html.indexOf('section>');

    const newHtml = html.substring(0, indexInit) + html.substring(indexEnd + 8, html.length);

    this.onChangeLinkUrl('');
    if (onChangeLinkIncluido) {
      onChangeLinkIncluido(false);
    }

    this.setState({ html: newHtml, isDisabledButton: false });
    this.onChangeValue(newHtml);
  };

  onChangeLinkUrl = (value) => {
    const { onChangeLink } = this.props;
    onChangeLink(value);
  };

  handleIncludeLinkInput = (e) => {
    e.preventDefault();
    const { html } = this.state;
    const { onChangeLinkIncluido, tagLinkInclude } = this.props;
    const link = TEMPLATE_TAG_LINK.replace(KEY_VALUE_TAG_LINK, tagLinkInclude) + TEMPLATE_DIV_EMPTY;

    let newHtml = html + link;
    newHtml = newHtml.replace('&nbsp;<font class="text-editable"></font><section', '<font class="text-editable"></font><section');
    newHtml = newHtml.replace(' <font class="text-editable"></font><section', '<font class="text-editable"></font><section');

    if (onChangeLinkIncluido) {
      onChangeLinkIncluido(true);
    }

    this.setState({ html: newHtml, isDisabledButton: true }, () => {
      this.inputLink.focus();
    });
    this.onChangeValue(newHtml);
  };

  render() {
    const {
      disabled, className, linkInvalid, isValidateInputs, maxLength, onChangeLink, linkValue,
    } = this.props;
    const {
      isDisabledButton, html, valueLength, especialCharacterMessage,
    } = this.state;

    return (
      <Container
        error={className}
        errorlink={
          isValidateInputs
          || (valueLength || 0) > maxLength
        }
      >
        <Editable
          id="editableLinkInput"
          name="editableLinkInput"
          className={`editableLinkInput ${className}`}
          html={html}
          disabled={disabled}
          onChange={(evt) => {
            this.handleChange(evt);
          }}
        />
        <div className="footer">
          <LimitCaracteres className={className}>
            {`Caracteres: ${valueLength || 0} de ${maxLength}`}
          </LimitCaracteres>
          {(especialCharacterMessage.length > 0) && (
          <ErrorLink>{`${especialCharacterMessage}`}</ErrorLink>
          )}
          {(valueLength || 0) > maxLength && especialCharacterMessage.length <= 0
            ? (
              <ErrorLink>{`O limite de caracteres é ${maxLength}`}</ErrorLink>
            ) : (
              isDisabledButton && linkInvalid && (
                <>
                  {!linkValue && (<ErrorLink>O link é obrigatório!</ErrorLink>)}
                  {linkValue && (<ErrorLink>Formáto inválido: iniciar com http(s)://</ErrorLink>)}
                </>
              )
            )
          }
          {isDisabledButton ? (
            <ContentLink>
              <label>Informar link:</label>
              <BaseInputLink invalid={linkInvalid}>
                <Input
                  disabled={disabled}
                  ref={(input) => { this.inputLink = input; }}
                  type="text"
                  placeholder="A url é obrigatória"
                  value={linkValue}
                  onChange={(e) => {
                    onChangeLink(e.target.value);
                  }}
                />
                <button disabled={disabled} type="button" className="button-link" onClick={() => this.handleRemoveLinkInput()} />
              </BaseInputLink>
            </ContentLink>
          ) : (
            <Button
              disabled={disabled}
              title="Adicionar link"
              onClick={this.handleIncludeLinkInput}
            />
          )}
        </div>
      </Container>
    );
  }
}

InputLink.defaultProps = {
  disabled: false,
  className: null,
  value: null,
  linkValue: null,
  linkInvalid: false,
  onChangeLinkIncluido: null,
  isValidateInputs: true,
  maxLength: 160,
};

InputLink.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onChangeLink: PropTypes.func.isRequired,
  className: PropTypes.string,
  tagLinkInclude: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linkValue: PropTypes.string,
  linkInvalid: PropTypes.bool,
  isValidateInputs: PropTypes.bool,
  onChangeLinkIncluido: PropTypes.func,
  maxLength: PropTypes.number,
};
