import { call, put, select } from 'redux-saga/effects';
import { push as pushRouter, replace as replaceRouter } from 'connected-react-router';

import UserService from '~/services/UserService';
import Auth from '../../Auth/Auth';

import { Creators as AuthActions } from '~/redux/ducks/auth';
import { Creators as WarningActions } from '~/redux/ducks/warning';

export function* setCurrentUser(action) {
  const userId = action.payload;
  // yield put(startRequest());
  try {
    const user = yield call(UserService.get, userId);
    yield put(AuthActions.setCurrentUserSuccess(user));
    yield put(
      WarningActions.warningSetupRequest(
        user.contract && user.contract.warnings ? user.contract.warnings : [],
      ),
    );
    // yield put(requestSuccess());
    // const isDevelopment = process.env.NODE_ENV === 'development';
    // const devRedirect = process.env.REACT_APP_URL_DEV_TEST;
    let pathReturn = sessionStorage.getItem('@ArtemisAdmin:path_return');
    if (pathReturn === '/' || pathReturn === '/callback') {
      pathReturn = '';
    }
    const uriToRedirect = pathReturn || user.modules[0].url;
    if (uriToRedirect) {
      yield put(replaceRouter(uriToRedirect));
    }
  } catch (e) {
    const credentials = yield select(state => state.credentials.data);
    // yield put(requestError());
    const auth = new Auth(pushRouter, credentials);
    auth.logout();
  }
}
