/* eslint-disable max-len */
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  warningSetupRequest: ['warnings'],
  warningSetupRequestSuccess: ['warnings'],
  warningAcceptRequest: null,
  warningRefuseRequest: null,
});

export const WarningTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  warnings: [],
});

/* Reducers */
const warningSetupRequestSuccess = (state, { warnings }) => ({
  ...state,
  warnings,
});

export const reducer = createReducer(INITIAL_STATE, {
  [WarningTypes.WARNING_SETUP_REQUEST_SUCCESS]: warningSetupRequestSuccess,
});
