import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';
import moment from 'moment';

const MONTHS = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

/* Types & Creators */
export const { Types, Creators } = createActions({
  reportMonthlyGridPeriodSetup: null,
  reportMonthlyGridPeriodRequest: null,
  reportMonthlyGridPeriodRequestSuccess: ['monthlyGridPeriod'],
  reportGridPeriodTypesRequestSuccess: ['typeList'],
  reportGridPeriodSetup: null,
  reportGridPeriodRequest: null,
  reportGridPeriodRequestSuccess: ['gridPeriod'],
  reportGridPeriodChannelRequestSuccess: ['channelList'],
  reportGridPeriodPeriodRequestSuccess: ['periodList'],
  reportGridPeriodSetFilter: ['filter'],
  reportGridPeriodLoadReportData: ['gridUuid', 'campaignUuid', 'gridPeriodUuid'],
  reportGridPeriodLoadReportDataSuccess: ['gridPeriodReport'],
  reportGridSetMonthList: ['monthList'],
  reportGridConfirmSignature: ['gridUuid'],
});

export const ReportTypes = Types;
export default Creators;


/* Initial State */
const INITIAL_FILTER_GRID_PERIOD = {
  channel: null,
  period: null,
  month: null,
  type: null,
};
const INITIAL_STATE = Immutable({
  channelList: [],
  periodList: [],
  productList: [],
  contractList: [],
  gridPeriodFilter: INITIAL_FILTER_GRID_PERIOD,
  gridPeriod: [],
  monthlyGridPeriod: [],
  gridPeriodReport: null,
  monthList: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11].map((item) => {
    const monthItem = moment().subtract(item, 'months');
    return {
      label: `${MONTHS[monthItem.month()]}/${monthItem.format('YYYY')}`,
      value: monthItem.format('YYYY-MM-01'),
    };
  }),
  typeList: [],
});

/* Reducers */
const gridPeriodRequestSuccess = (state, { gridPeriod }) => ({
  ...state,
  gridPeriod,
});
const monthlyGridPeriodRequestSuccess = (state, { monthlyGridPeriod }) => ({
  ...state,
  monthlyGridPeriod,
});
const gridPeriodChannelRequestSuccess = (state, { channelList }) => ({
  ...state,
  channelList,
});
const gridPeriodPeriodRequestSuccess = (state, { periodList }) => ({
  ...state,
  periodList,
});
const reportGridPeriodLoadReportDataSuccess = (state, { gridPeriodReport }) => ({
  ...state,
  gridPeriodReport,
});
const gridPeriodSetFilter = (state, { filter }) => ({
  ...state,
  gridPeriodFilter: {
    ...state.gridPeriodFilter,
    ...filter,
  },
});
const reportGridPeriodTypesRequestSuccess = (state, { typeList }) => ({
  ...state,
  typeList,
});

export const reducer = createReducer(INITIAL_STATE, {
  [ReportTypes.REPORT_GRID_PERIOD_REQUEST_SUCCESS]: gridPeriodRequestSuccess,
  [ReportTypes.REPORT_MONTHLY_GRID_PERIOD_REQUEST_SUCCESS]: monthlyGridPeriodRequestSuccess,
  [ReportTypes.REPORT_GRID_PERIOD_CHANNEL_REQUEST_SUCCESS]: gridPeriodChannelRequestSuccess,
  [ReportTypes.REPORT_GRID_PERIOD_PERIOD_REQUEST_SUCCESS]: gridPeriodPeriodRequestSuccess,
  [ReportTypes.REPORT_GRID_PERIOD_SET_FILTER]: gridPeriodSetFilter,
  [ReportTypes.REPORT_GRID_PERIOD_LOAD_REPORT_DATA_SUCCESS]: reportGridPeriodLoadReportDataSuccess,
  [ReportTypes.REPORT_GRID_PERIOD_TYPES_REQUEST_SUCCESS]: reportGridPeriodTypesRequestSuccess,
});
