import api from './axios';

const getGridConfigPeriod = async (gridPeriodUuid) => {
  try {
    const { data } = await api.get(`gridconfig/${gridPeriodUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getGridManagerPeriod = async (gridPeriodUuid) => {
  try {
    const { data } = await api.get(`gridmanager/${gridPeriodUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const getGridManagerPeriodItems = async (gridPeriodUuid, zoneUuid) => {
  try {
    const { data } = await api.get(`gridmanager/${gridPeriodUuid}/${zoneUuid}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const startGridManager = async (gridPeriodUuid, gridConfig) => {
  try {
    await api.post(`gridmanager/${gridPeriodUuid}/start`, gridConfig);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const closeGridManagerZone = async (gridPeriodUuid, zoneUuid) => {
  try {
    await api.post(`gridmanager/${gridPeriodUuid}/${zoneUuid}/close`, null);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const closeGridManager = async (gridPeriodUuid) => {
  try {
    await api.post(`gridmanager/${gridPeriodUuid}/close`, null);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const updateStatusCampaignType = async (gridPeriodUuid, zoneUuid, campaignTypeUuid, status) => {
  try {
    await api.put(`gridmanager/${gridPeriodUuid}/${zoneUuid}/${campaignTypeUuid}/update-status-campaign-type`, { status });
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const updateItemCampaignType = async (
  gridPeriodUuid, zoneUuid, campaignTypeUuid, campaignUuid, item,
) => {
  try {
    await api.put(`gridmanager/${gridPeriodUuid}/${zoneUuid}/${campaignTypeUuid}/${campaignUuid}/update-campaign`, item);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const addCampaignsCampaignType = async (
  gridPeriodUuid, zoneUuid, campaignTypeUuid, items,
) => {
  try {
    await api.post(`gridmanager/${gridPeriodUuid}/${zoneUuid}/${campaignTypeUuid}/add-campaign`, items);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const removeGridItem = async (
  gridPeriodUuid, zoneUuid, campaignTypeUuid, campaignUuid,
) => {
  try {
    await api.delete(`gridmanager/${gridPeriodUuid}/${zoneUuid}/${campaignTypeUuid}/${campaignUuid}`);
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  getGridConfigPeriod,
  getGridManagerPeriod,
  getGridManagerPeriodItems,
  startGridManager,
  closeGridManagerZone,
  closeGridManager,
  updateStatusCampaignType,
  updateItemCampaignType,
  addCampaignsCampaignType,
  removeGridItem,
};
