import React from 'react';
import PropTypes from 'prop-types';

import { SelectComponent } from './styles';

const Select = ({
  placeholder,
  value,
  onChange,
  options,
  isClearable,
  style,
  ...rest
}) => (
  <SelectComponent
    placeholder={placeholder}
    value={value}
    onChange={onChange}
    options={options}
    isClearable={isClearable}
    noOptionsMessage={() => 'Sem registros'}
    {...rest}
  />
);

Select.defaultProps = {
  isClearable: true,
  style: null,
  placeholder: '',
};

Select.propTypes = {
  style: PropTypes.shape(),
  placeholder: PropTypes.string,
  value: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isClearable: PropTypes.bool,
};

export default Select;
