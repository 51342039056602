import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Container, ContainerTitle, ContainerContent } from './styles';

class AudienceList extends Component {
  state= {};

  componentDidMount() {}

  render() {
    const { title, children } = this.props;
    return (
      <Container>
        <ContainerTitle>{title}</ContainerTitle>
        <ContainerContent>{children}</ContainerContent>
      </Container>
    );
  }
}

AudienceList.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

const mapStateToProps = (/* state */) => ({
  // audiences: state.audience.audiences,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceList);
