import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  RowStyled,
  InputStyled,
  FormGroupStyled,
} from '~/Components/Styled';


import ToggleComponent from '~/Components/Toggle';

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .header-form-box {
    flex-wrap: wrap;
    width: 100%;
  }
  .header-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1148px) {
      flex: 1;
    }
  }
  .header-button-box {
    display: flex;
    > button {
      align-self: flex-end;
    }
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)}px;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Input = styled(InputStyled)`
  width: 400px;
  margin-bottom: 10px;
  height: 40px;
`;

export const FormGroupHeader = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 5px 10px;
  border-radius: 5px;
`;

export const Toggle = styled(ToggleComponent).attrs({
  style: {
    marginBottom: 10,
    height: 40,
    width: 150,
  },
})``;

export const Select = styled(ReactSelect)`
  width: 300px;
  margin-bottom: 10px;
  margin-right: 15px;
  border: 0;
  [class*="-control"] {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 4px;
    ${props => props.invalid
      && css`
        border-color: ${props.theme.colors.danger2};
      `}
    outline: none;
  }
`;
