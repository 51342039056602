/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';
// import moment from 'moment';

import Modal from '~/Components/Modal';

import {
  Row,
  ModalWrap,
  RowHeader,
  RowContent,
  ButtonCloseModal,
  Table,
} from './styles';

const ModalSchedule = ({
  dailyDistribution,
  onClose,
}) => dailyDistribution && (
  <Modal width={400} height={400} scroll={false} padding={0}>
    <ModalWrap>
      <RowHeader>
        <Row>
          <h1>Cronograma de disparos</h1>
          <ButtonCloseModal
            type="button"
            onClick={onClose}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
      </RowHeader>
      <RowContent>
        <Table>
          <thead>
            <tr>
              <th width="1%" />
              <th>Data</th>
              <th>Volume</th>
            </tr>
          </thead>
          <tbody>
            {dailyDistribution && dailyDistribution.length > 0 && Array.isArray(dailyDistribution)
              ? dailyDistribution.map((item, index) => (
                <tr key={index}>
                  <td>
                    {`#${index + 1}`}
                  </td>
                  <td>{item.date}</td>
                  <td>{item.volume || 0}</td>
                </tr>
              )) : (
                <tr>
                  <td colSpan="3">
                    <div className="empty-table-desc">
                      <span> Não foram localizados registros de disparos.</span>
                    </div>
                  </td>
                </tr>
              )}
          </tbody>
        </Table>
      </RowContent>
    </ModalWrap>
  </Modal>
);

ModalSchedule.defaultProps = {
  dailyDistribution: null,
  onClose: () => {},
};

ModalSchedule.propTypes = {
  dailyDistribution: PropTypes.arrayOf(PropTypes.shape()),
  onClose: PropTypes.func,
};

export default ModalSchedule;
