/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ProviderActions } from '~/redux/ducks/provider';

import { Container } from './styles';
import Form from './components/Form';

class ProviderEdit extends Component {
  static propTypes = {
    match: PropTypes.shape().isRequired,
    loadProviderNew: PropTypes.func.isRequired,
    loadProviderEdit: PropTypes.func.isRequired,
    provider: PropTypes.shape().isRequired,
    saveProviderEdit: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { match, loadProviderNew, loadProviderEdit } = this.props;

    if (/new/.test(match.path)) {
      loadProviderNew();
    } else {
      const { params } = match;
      loadProviderEdit(params.providerId);
    }
  }

  render() {
    const { provider, saveProviderEdit } = this.props;

    if (!provider) {
      return <Container />;
    }

    return (
      <Form provider={provider} saveProviderEdit={saveProviderEdit} />
    );
  }
}

const mapStateToProps = state => ({
  provider: state.provider.edit.provider,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadProviderNew: ProviderActions.loadProviderNew,
    loadProviderEdit: ProviderActions.loadProviderEdit,
    saveProviderEdit: ProviderActions.saveProviderEdit,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderEdit);
