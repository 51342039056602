import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as GridPeriodActions } from '~/redux/ducks/gridPeriod';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  Input,
} from './styles';

class ModalBonificadasEdit extends Component {
  componentDidMount() {}

  render() {
    const {
      modal,
      gridPeriodChangeModalStatus,
      gridItemToEdit,
      gridPeriodSetItemToEdit,
      gridPeriodSaveItemInEdit,
    } = this.props;

    return modal.modalBonificadasEdit && gridItemToEdit && (
      <Modal width={500}>
        <>
          <Row>
            <h1>Alterar Campanha Bonificada</h1>
            <ButtonCloseModal
              type="button"
              onClick={() => {
                gridPeriodChangeModalStatus({
                  modalBonificadasEdit: false,
                });
                gridPeriodSetItemToEdit(null);
              }}
            >
              <FaTimes size={30} />
            </ButtonCloseModal>
          </Row>
          <Row>
            <FormGroup>
              <label>
                <b>Parceiro: </b>
                {gridItemToEdit.campaign.contract.name}
              </label>
            </FormGroup>
          </Row>
          <Row>
            <FormGroup>
              <label>
                <b>Produto - Oferta: </b>
                {gridItemToEdit.campaign.productOffers.map(po => (
                  <span key={po.uuid}>
                    {`${po.product.name} - ${po.name}`}
                    <br />
                  </span>
                ))}
              </label>
            </FormGroup>
          </Row>
          <FormGroup>
            <label>
              <b>Volume: </b>
              <Input
                type="number"
                style={{ marginRight: 0 }}
                placeholder="Volume"
                value={gridItemToEdit.volume}
                invalid={!gridItemToEdit.volume}
                onChange={(e) => {
                  gridPeriodSetItemToEdit({
                    ...gridItemToEdit,
                    volume: e.target.value,
                  });
                }}
              />
            </label>
          </FormGroup>
          <RowFooter>
            <Button
              title="SALVAR"
              onClick={() => {
                gridPeriodSaveItemInEdit();
              }}
            />
          </RowFooter>
        </>
      </Modal>
    );
  }
}

ModalBonificadasEdit.defaultProps = {
  gridItemToEdit: null,
};

ModalBonificadasEdit.propTypes = {
  modal: PropTypes.shape().isRequired,
  gridItemToEdit: PropTypes.shape(),
  gridPeriodChangeModalStatus: PropTypes.func.isRequired,
  gridPeriodSetItemToEdit: PropTypes.func.isRequired,
  gridPeriodSaveItemInEdit: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modal: state.gridPeriod.modal,
  gridItemToEdit: state.gridPeriod.gridItemToEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    gridPeriodChangeModalStatus: GridPeriodActions.gridPeriodChangeModalStatus,
    gridPeriodSetItemToEdit: GridPeriodActions.gridPeriodSetItemToEdit,
    gridPeriodSaveItemInEdit: GridPeriodActions.gridPeriodSaveItemInEdit,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalBonificadasEdit);
