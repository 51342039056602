import React from 'react';
// import { hot } from 'react-hot-loader/root';

import '~/App.css';

import { ThemeProvider } from 'styled-components';

import { routerMiddleware } from 'connected-react-router';

import createSagaMiddleware from 'redux-saga';
import reduxMiddleware from 'react-block-ui/lib/reduxMiddleware';

import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';

import ReduxToastr from 'react-redux-toastr';
import history from '~/navigation/history';

import sagas from '~/redux/sagas';
import rootReducer from '~/redux/ducks';

import AppCredentials from './AppCredentials';

import ConfirmModal from '~/Components/ConfirmModal';
import AlertModal from '~/Components/AlertModal';
import WarningAction from '~/Components/WarningAction';

import ThemeStyles from '~/Styles/theme';

// eslint-disable-next-line no-underscore-dangle
const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();

const sagaMiddleware = createSagaMiddleware();

const midlewares = [reduxMiddleware, sagaMiddleware, routerMiddleware(history)];

const store = applyMiddleware(...midlewares)(createStore)(rootReducer(history), devTools);

sagaMiddleware.run(sagas);

const App = () => {
  const theme = ThemeStyles.getThemeByTenant();
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <AppCredentials history={history} />
        <ReduxToastr
          timeOut={4000}
          showCloseButton
          preventDuplicates
          position="top-right"
          transitionIn="fadeIn"
          transitionOut="fadeOut"
          progressBar
          closeOnToastrClick
        />
        <ConfirmModal />
        <AlertModal />
        <WarningAction />
      </ThemeProvider>
    </Provider>
  );
};

// export default process.env.NODE_ENV === 'development' ? hot(App) : App;
export default App;
