import React from 'react';

function PlanSheetIcon() {
  return (
    <svg width="18" height="22" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.9965 9.55998C12.9965 11.317 13.0071 13.0741 12.9929 14.8312C12.9858 15.7636 12.5247 16.4317 11.6628 16.8213C11.3861 16.9466 11.0846 16.9953 10.7795 16.9953C7.92415 16.9953 5.07231 16.9953 2.21692 16.9953C1.09959 16.9953 0.195089 16.2333 0.0248295 15.1582C0.00709413 15.0538 0 14.946 0 14.8381C0 11.317 0 7.79248 0 4.2714C0 3.22412 0.730696 2.35429 1.77353 2.15597C1.92606 2.12813 2.08922 2.11422 2.24529 2.12813C2.57872 2.15597 2.70286 2.4204 2.69577 2.66047C2.68868 2.94578 2.50068 3.13018 2.20273 3.16497C2.04666 3.18237 1.88349 3.19281 1.73806 3.245C1.3337 3.38765 1.08186 3.75994 1.07476 4.1879C1.07476 4.23661 1.07476 4.28532 1.07476 4.33055C1.07476 7.84119 1.07476 11.3518 1.07476 14.8625C1.07476 15.3183 1.31596 15.701 1.72742 15.8506C1.88349 15.9063 2.06085 15.9272 2.22756 15.9272C4.68568 15.9306 7.14025 15.9272 9.59836 15.9272C10.0063 15.9272 10.4106 15.9272 10.8186 15.9272C11.1945 15.9272 11.5067 15.8019 11.7301 15.4992C11.8649 15.3148 11.9217 15.1095 11.9252 14.8868C11.9288 14.5598 11.9252 14.2292 11.9252 13.9022C11.9252 10.6838 11.9252 7.46542 11.9252 4.24357C11.9252 3.8678 11.7869 3.56162 11.4677 3.34242C11.3187 3.24152 11.1484 3.19281 10.9675 3.17193C10.8292 3.15454 10.6838 3.14062 10.5596 3.08843C10.3752 3.01536 10.2723 2.77181 10.3042 2.56653C10.3468 2.31602 10.4887 2.15945 10.7192 2.12813C11.0456 2.0829 11.3613 2.14901 11.6521 2.27775C12.5211 2.66047 12.9858 3.33198 12.9965 4.26792C13.0106 6.02846 13 7.79248 13 9.5565L12.9965 9.55998Z" fill="white" />
      <path d="M8.36419 3.17889C8.59475 3.17889 8.81112 3.17889 9.02394 3.17889C9.12326 3.17889 9.22258 3.17889 9.31835 3.19629C9.61985 3.25196 9.7972 3.51987 9.72981 3.81561C9.66596 4.09744 9.48151 4.23661 9.15873 4.23661C8.30034 4.23661 7.44195 4.23661 6.58001 4.23661C5.66842 4.23661 4.75682 4.23661 3.84523 4.23661C3.74946 4.23661 3.64659 4.22269 3.55791 4.1879C3.33445 4.10092 3.21385 3.8678 3.25287 3.62773C3.29543 3.37026 3.45505 3.21021 3.71753 3.19281C3.98356 3.17541 4.24604 3.18585 4.51207 3.18237C4.544 3.18237 4.57947 3.18237 4.62913 3.18237C4.27797 2.60133 4.24604 2.00984 4.46242 1.40096C4.62203 0.955603 4.91644 0.61115 5.31016 0.346721C6.06214 -0.157782 7.08724 -0.102113 7.82149 0.4511C8.49898 0.962562 8.99557 2.14553 8.35709 3.18237L8.36419 3.17889ZM6.49843 3.17889C7.14045 3.17889 7.58383 2.73702 7.58029 2.10726C7.58029 1.48446 7.12981 1.04607 6.49134 1.04607C5.84932 1.04607 5.40594 1.48794 5.40948 2.1177C5.40948 2.7405 5.85996 3.17889 6.49843 3.17542V3.17889Z" fill="white" />
      <path d="M3.838 8.30395C3.85574 8.27612 3.86993 8.24828 3.89121 8.22741C4.43391 7.67419 4.97306 7.12098 5.51577 6.57125C5.6612 6.42163 5.83855 6.32769 6.05847 6.37292C6.48766 6.45991 6.65083 6.96093 6.34578 7.27407C5.65055 7.98733 4.94823 8.69364 4.24592 9.39994C4.04728 9.59826 3.73514 9.6087 3.51877 9.42082C3.12504 9.07288 2.73132 8.72495 2.34469 8.36658C2.17443 8.21001 2.10349 8.00821 2.19216 7.77857C2.33759 7.40976 2.80226 7.30886 3.09312 7.58721C3.31304 7.79945 3.52941 8.01517 3.74578 8.22741C3.76706 8.24828 3.78125 8.2796 3.79898 8.30395H3.83446H3.838Z" fill="white" />
      <path d="M8.11577 12.2112C7.417 12.2112 6.72177 12.2112 6.023 12.2112C5.92013 12.2112 5.81372 12.1938 5.7215 12.1556C5.51222 12.0721 5.39162 11.8424 5.42354 11.6232C5.46256 11.3588 5.61154 11.2022 5.85983 11.1674C5.92368 11.157 5.99107 11.1535 6.05847 11.1535C7.44537 11.1535 8.83227 11.1535 10.2192 11.1535C10.4072 11.1535 10.5916 11.1814 10.7158 11.3449C10.8506 11.5188 10.8647 11.7137 10.7832 11.9155C10.7087 12.0964 10.5597 12.1834 10.3682 12.2008C10.3079 12.2078 10.2476 12.2147 10.1873 12.2147C9.49912 12.2147 8.80745 12.2147 8.11931 12.2147L8.11577 12.2112Z" fill="white" />
      <path d="M8.6618 9.02068C8.14392 9.02068 7.62605 9.02068 7.10463 9.02068C7.00886 9.02068 6.906 9.00677 6.81732 8.97197C6.59031 8.88847 6.47326 8.66579 6.50518 8.41528C6.5371 8.17173 6.70027 8.0082 6.94502 7.97341C7.00177 7.96645 7.05852 7.96297 7.11882 7.95949C8.14747 7.95949 9.17612 7.95949 10.2048 7.95949C10.3218 7.95949 10.446 7.98384 10.5559 8.02907C10.7439 8.10562 10.8539 8.34221 10.822 8.54402C10.7794 8.80496 10.6375 8.96153 10.4034 8.99633C10.3254 9.00677 10.2473 9.01372 10.1728 9.01372C9.66916 9.01372 9.16548 9.01372 8.6618 9.01372V9.02068Z" fill="white" />
      <path d="M4.05412 11.6754C4.02574 12.1521 3.73488 12.4617 3.24893 12.4617C2.77717 12.4617 2.45439 12.1695 2.4473 11.6858C2.44375 11.2057 2.75944 10.896 3.24184 10.8926C3.72424 10.8926 4.01865 11.1918 4.05412 11.6719V11.6754Z" fill="white" />
    </svg>

  );
}

export default PlanSheetIcon;
