import api from './axios';

const urlBase = window.location.hostname === 'admin.oiads.com.br' ? 'https://artemis-apigw.mobicare.com.br/' : '';
const getConfigs = async (campaignUuid) => {
  try {
    const { data } = await api.get(
      `${urlBase}mcare-artemis-report-service/campaign/${campaignUuid}/retargetConfig`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};
const getRetargets = async (campaignUuid) => {
  try {
    const { data } = await api.get(
      `${urlBase}mcare-artemis-report-service/campaign/${campaignUuid}/retarget`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const getDownloadUrl = async ({ campaignUuid, retargetUuid }) => {
  try {
    const { data } = await api.get(
      `${urlBase}mcare-artemis-report-service/campaign/${campaignUuid}/retarget/${retargetUuid}/download`,
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const createRetarget = async ({ campaignUuid, retargetConfigUuid }) => {
  try {
    const { data } = await api.post(
      `${urlBase}mcare-artemis-report-service/campaign/${campaignUuid}/retarget`,
      { retargetConfigUuid },
    );
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  getConfigs,
  getRetargets,
  getDownloadUrl,
  createRetarget,
};
