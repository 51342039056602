import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';
import PropTypes from 'prop-types';
import moment from 'moment';

import { convertFloatReal } from '~/Utils';
import Upload from '~/Components/Upload';
import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import { Creators as FinancialActions } from '~/redux/ducks/financial';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  FileListContent,
  FileList,
  FileListItem,
} from './styles';

class ModalUpload extends Component {
  componentDidMount() {}

  setReceiptInFile = async (uuid, receipt) => {
    const { setModalInvoiceData, modalInvoiceData } = this.props;
    setModalInvoiceData({
      ...modalInvoiceData,
      files: modalInvoiceData.files.map((item) => {
        if (item.uuid === uuid) {
          return {
            ...item,
            receipt,
          };
        }
        return item;
      }),
    });
  };

  render() {
    const {
      setModalInvoiceData, modalInvoiceData, financialArchiveDownload, financialInvoiceReceiptSave,
    } = this.props;
    return (
      <Modal width={700} scroll={false}>
        <Row>
          <h1>{`Lançar recibo - ${modalInvoiceData.provider.name}`}</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => setModalInvoiceData(null)}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <Row style={{ justifyContent: 'flex-start' }}>
          <FormGroup style={{ marginRight: 50 }}>
            <label>
              <b>Valor:</b>
              {`R$ ${convertFloatReal(modalInvoiceData.amount)}`}
            </label>
          </FormGroup>
          <FormGroup>
            <label>
              <b>Vencimento:</b>
              {moment(modalInvoiceData.dueDate).utc().format('DD/MM/YYYY')}
            </label>
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label><b>Arquivos:</b></label>
          </FormGroup>
        </Row>
        {modalInvoiceData.files.length > 0 && (
          <FileListContent>
            <FileList>
              {modalInvoiceData.files.map(item => (
                <FileListItem>
                  <div className="title-file">
                    <span>
                      {item.name}
                    </span>
                    <button
                      type="button"
                      onClick={() => {
                        financialArchiveDownload(item);
                      }}
                    >
                      Download
                    </button>
                  </div>
                  <Upload
                    className="button-file-upload"
                    label={item.receipt ? 'Alterar recibo' : 'Incluir recibo'}
                    onUpload={(files) => {
                      this.setReceiptInFile(item.uuid, files[0]);
                    }}
                    accept="image/png,image/jpg,image/jpeg,application/pdf"
                    onRejectMaxSize={() => {}}
                    onClick={() => {}}
                    bordered={!!item.receipt}
                  />
                </FileListItem>
              ))}
            </FileList>
          </FileListContent>
        )}
        <RowFooter>
          <Button
            disabled={!modalInvoiceData.files.find(item => !!item.receipt)}
            title="Confirmar"
            onClick={() => {
              financialInvoiceReceiptSave();
            }}
          />
        </RowFooter>
      </Modal>
    );
  }
}

ModalUpload.defaultProps = {
  modalInvoiceData: null,
};

ModalUpload.propTypes = {
  modalInvoiceData: PropTypes.shape(),
  setModalInvoiceData: PropTypes.func.isRequired,
  financialArchiveDownload: PropTypes.func.isRequired,
  financialInvoiceReceiptSave: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modalInvoiceData: state.financial.modalInvoiceData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    setModalInvoiceData: FinancialActions.setModalInvoiceData,
    financialArchiveDownload: FinancialActions.financialArchiveDownload,
    financialInvoiceReceiptSave: FinancialActions.financialInvoiceReceiptSave,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalUpload);
