import { put, call } from 'redux-saga/effects';
import uuid4 from 'uuid4';
import lodash from 'lodash';

import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';
import { Creators as PointsManagementActions } from '~/redux/ducks/pointsManagement';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import PointsManagementService from '~/services/PointsManagementService';
import iconMarkerDefault from '~/Assets/Images/pin-map.png';

export function* pointsManagementGeoPointsRequest() {
  yield put(startRequest());
  try {
    const ufList = [];
    const cityList = [];
    const neighborhoodList = [];
    const groupingList = [];
    let geopoints = yield call(PointsManagementService.pointsManagementGeoPointsRequest);
    if (geopoints) {
      geopoints = {
        ...geopoints,
        geoPoints: geopoints.geoPoints.map(item => ({
          ...item,
          uuid: uuid4(),
        })),
      };
    }

    // inicio mock de dados
    // if (geopoints) {
    //   geopoints = {
    //     ...geopoints,
    //     geoPoints: geopoints.geoPoints.length < 10
    //       ? geopoints.geoPoints
    //       : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(item => geopoints.geoPoints[item]),
    //   };
    // }
    // fim mock de dados

    if (geopoints) {
      geopoints.geoPoints.forEach((item) => {
        ufList.push({
          key: `${item.uf.toUpperCase()}`,
          label: item.uf.toUpperCase(),
          value: item.uf.toUpperCase(),
        });
        cityList.push({
          key: `${item.uf.toUpperCase()}-${item.cidade.toUpperCase()}`,
          label: item.cidade.toUpperCase(),
          value: item.cidade.toUpperCase(),
          uf: item.uf.toUpperCase(),
        });
        neighborhoodList.push({
          key: `${item.uf.toUpperCase()}-${item.cidade.toUpperCase()}-${item.bairro.toUpperCase()}`,
          label: item.bairro.toUpperCase(),
          value: item.bairro.toUpperCase(),
          uf: item.uf.toUpperCase(),
          city: item.cidade.toUpperCase(),
        });
        groupingList.push(item.agrupador.toUpperCase());
      });
    }
    yield put(PointsManagementActions.pointsManagementGeoPointsRequestSuccess(geopoints));
    yield put(
      PointsManagementActions.setFilterLists({
        ufList: lodash.uniqBy(ufList, 'key'),
        cityList: lodash.uniqBy(cityList, 'key'),
        neighborhoodList: lodash.uniqBy(neighborhoodList, 'key'),
        groupingList: lodash.uniq(groupingList),
      }),
    );
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os pontos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* pointsManagementGeoPointsIconRequest() {
  yield put(startRequest());
  try {
    const icon = yield call(PointsManagementService.pointsManagementGeoPointsIconRequest);
    yield put(
      PointsManagementActions.pointsManagementGeoPointsIconRequestSuccess(
        icon || iconMarkerDefault,
      ),
    );
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar o ícone. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* pointsManagementGeoPointsIconSave({ iconPoint }) {
  yield put(startRequest());
  try {
    yield call(PointsManagementService.pointsManagementGeoPointsIconSave, iconPoint);
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Sucesso ao enviar o ícone!',
    ));
    yield put(PointsManagementActions.pointsManagementGeoPointsIconRequest());
  } catch (error) {
    if (error.detail) {
      yield put(ToastrMessageActions.warningMessage(
        'Atenção!',
        error.detail,
      ));
    } else {
      yield put(ToastrMessageActions.errorMessage(
        'Atenção!',
        'Houve um problema ao enviar o ícone. Tente novamente mais tarde!',
      ));
    }
    yield put(requestError());
  }
}
