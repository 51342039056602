/* eslint-disable object-curly-newline */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import { all, takeLatest } from "redux-saga/effects";

import { Types as AuthTypes } from "~/redux/ducks/auth";
import { Types as CampaignTypes } from "~/redux/ducks/campaign";
import { Types as ApprovalTypes } from "~/redux/ducks/approval";
import { Types as AudienceTestTypes } from "~/redux/ducks/audienceTest";
import { Types as ContractTypes } from "~/redux/ducks/contract";
import { Types as ProviderTypes } from "~/redux/ducks/provider";
import { Types as AdvertisingPartnerTypes } from "~/redux/ducks/advertisingPartner";
import { Types as PeriodTypes } from "~/redux/ducks/period";
import { Types as AdTypes } from "~/redux/ducks/ad";
import { Types as DemoTypes } from "~/redux/ducks/demo";
import { Types as HistoryCampaignTypes } from "~/redux/ducks/historyCampaign";
import { Types as ChannelTypes } from "~/redux/ducks/channel";
import { Types as EventTypes } from "~/redux/ducks/event";
import { Types as ToastrMessageTypes } from "~/redux/ducks/toastrMessage";
import { Types as CredentialsTypes } from "~/redux/ducks/credentials";
import { Types as BehaviorTypes } from "~/redux/ducks/behavior";
import { Types as AudienceExporterTypes } from "~/redux/ducks/audienceExporter";

import { setCurrentUser } from "./auth";
import {
  loadCampaignListView,
  loadCampaignEdit,
  filterCampaign,
  exportFilterCampaign,
  createCampaign,
  deleteCampaign,
  cloneCampaign,
  activeCampaignEdit,
  saveCampaignEdit,
  cancelCampaign,
  pauseCampaign,
  resumeCampaign,
  downloadReport,
  openModalNewCampaign,
  confirmCreateCampaign,
  updateProductsListByZoneNewCampaign,
  setDefaultProductOffersNewCampaign,
  workflowStatus,
  includeToProbeGrid,
} from "./campaign";

import { loadHistoryCampaign } from "./historyCampaign";

import {
  openModal as openModalAudienceExporter,
  downloadRetarget,
  generateRetarget
} from './audienceExporter';

import {
  listActions,
  listActionType,
  listEventType,
  listBehaviors,
  listChannelsCampaign,
  onNewBehaviorModal,
  listCampaignByFiltersNewBehaviorModal,
  onCreateBehaviorNewBehaviorModal,
  openCrudBehavior,
  onSaveBehaviorCrud,
  deleteBehavior,
  onSearchByFilter,
  onCleanFilter,
} from "./behavior";

import {
  loadCampaignList as loadCampaignListApproval,
  filterCampaign as filterCampaignApproval,
  exportFilterCampaign as exportFilterCampaignApproval,
  loadCampaignApproval,
  approvedCampaign,
  demonstrationCampaign,
  runDemonstrationCampaign,
  rejectCampaign,
  workflowStatus as workflowStatusApproval
} from "./approval";

import {
  loadContractEdit,
  saveContractEdit,
  loadContractList,
  filterContractList
} from "./contract";

import {
  loadProviderEdit,
  saveProviderEdit,
  loadProviderList,
  filterProviderList,
  deleteProvider,
  resetSearchProvider,
} from "./provider";

import {
  loadAudienceTest,
  loadAudienceUserData,
  saveModalData,
  deleteAudience,
  filterAudience,
  resetfilterAudience,
} from "./audienceTest";

import {
  loadAdvertisingPartnerList,
  filterAdvertisingPartnerList,
  advertisingPartnerUpdate
} from "./advertisingPartner";

import {
  loadPeriodList,
  periodUpdate
} from "./period";

import {
  loadAdList,
  createAd,
  editAd,
  deleteAd,
  loadEditAd,
  setupEnableDisable,
  setupEnableToEdit,
  setupSaveEditing,
  changeDetailPositionInSetup,
  setupDisableOrdering,
  setupSaveOrdering
} from "./ad";

import { Types as AuctionTypes } from "~/redux/ducks/auction";
import {
  auctionRequest,
  auctionSetup,
  auctionGridUpdate,
  auctionRankingUpdate,
  auctionGetTermPreview,
} from "./auction";

import { Types as ReportTypes } from "~/redux/ducks/report";
import {
  reportGridPeriodRequest,
  reportGridPeriodSetup,
  reportGridPeriodLoadReportData,
  reportMonthlyGridPeriodSetup,
  reportMonthlyGridPeriodRequest,
  reportGridConfirmSignature,
} from "./report";

import { Types as ReportV2Types } from "~/redux/ducks/reportV2";
import {
  loadDataReportDashboard,
} from "./reportV2";

import { Types as NewGridPeriodTypes } from "~/redux/ducks/newGridPeriod";
import {
  gridPeriodSetup as newGridPeriodSetup,
  changePeriodSelected,
  openModalInitCloseWeekly,
  confirmStartGridPeriod,
  confirmCloseGridPeriod,
  openModalChannel as openModalChannelGridPeriod,
  changeStatusAuction as changeStatusAuctionGridPeriod,
  changeStatusProbe as changeStatusProbeGridPeriod,
  changeStatusFixedCpa as changeStatusFixedCpaGridPeriod,
  changeStatusBonus as changeStatusBonusGridPeriod,
  closeModalChannel as closeModalChannelGridPeriod,
  updateItemAuction as updateItemAuctionGridPeriod,
  updateItemProbe as updateItemProbeGridPeriod,
  updateItemFixedCpa as updateItemFixedCpaGridPeriod,
  updateItemBonus as updateItemBonusGridPeriod,
  recalculateRemainingImpressions,
  removeCampaignProbe as removeCampaignProbeGridPeriod,
  removeCampaignFixedCpa as removeCampaignFixedCpaGridPeriod,
  removeCampaignBonus as removeCampaignBonusGridPeriod,
  gridPeriodCampaignByFilterRequest as newGridPeriodCampaignByFilterRequest,
  gridPeriodInsertCampaignsSelected as newGridPeriodInsertCampaignsSelected
} from "./newGridPeriod";

import { Types as GridPeriodTypes } from "~/redux/ducks/gridPeriod";

import {
  gridPeriodSetup,
  gridPeriodRequest,
  gridPeriodLoadRemainingImpressions,
  gridPeriodCampaignByFilterRequest,
  gridPeriodInsertCampaignsSelected,
  gridPeriodSaveItemInEdit,
  gridPeriodSaveGrid,
  gridPeriodDeleteItemGrid,
  gridPeriodChangeLeilaoGridItem,
  gridPeriodChangeBonificadaGridItem,
  gridPeriodChangeCpaGridItem,
  gridPeriodChangeTesteGridItem,
  gridPeriodChangeTesteCpa,
  gridPeriodChangeSender,
  gridPeriodChangeCpa,
  gridPeriodAutomaticDistribution,
} from "./gridPeriod";

import { Types as CampaignEditTypes } from "~/redux/ducks/campaignEdit";
import {
  campaignEditSaveOrcamentoInEdit,
  campaignEditSavePeriodoInEdit,
  campaignEditSaveBriefingInEdit,
  campaignEditSaveAttributeInEdit,
  campaignEditBriefingDownload,
  campaignEditCriativoRequest,
  campaignEditCriativoSave,
  campaignEditBenefitSave,
  campaignEditCarrotPackageSave,
  campaignEditLoadMediaEventList,
  campaignEditLoadReport,
  campaignEditLoadReportTemplate,
  campaignEditLoadAudiencesRequest,
  campaignEditSaveImpactSettings,
  campaignEditSaveEstrategiaEntrega,
  executeTestAudience,
  campaignEditNoCreditPreviewRequest,
  campaignEditNoDataPreviewRequest,
  campaignCloneRequest,
  campaignFileSizeConfigRequest
} from "./campaignEdit";

import { loadChannelsList } from "./channel";

import { loadEventsList } from "./event";

import { errorMessage, successMessage, warningMessage } from "./toastrMessage";

import {
  loadDemoList,
  addNewTerminalDemo,
  removeTerminalDemo,
  saveDemoList
} from "./demo";

import { getCredentials } from './credentials';

import { Types as RedirectTypes } from '~/redux/ducks/redirect';
import { navigateToRoute } from './redirect';

import { Types as CampaignIconTypes } from '~/redux/ducks/campaignIcon';
import { campaignIconLoadCampaignTypes } from './campaignIcon';

import { Types as BenefitTypes } from '~/redux/ducks/benefit';
import { benefitRequest } from './benefit';

import { Types as TermsTypes } from '~/redux/ducks/terms';
import { termsRequest } from './terms';

import { Types as FinancialTypes } from '~/redux/ducks/financial';
import {
  financialSetup,
  financialArchivesRequest,
  financialArchiveDownload,
  financialInvoiceSave,
  financialDocumentArchivesDelete,
  financialDocumentArchiveDelete,
  financialModalReceiptDataRequest,
  financialInvoiceReceiptSave,
} from './financial';

import { Types as AudienceTypes } from '~/redux/ducks/audience';
import {
  audienceRequest,
  audienceSetupRequest,
  audienceSaveData,
  audienceUpdateData,
  audienceGetData,
  audienceDeleteData,
  audienceCategoriesRequest,
  audienceCategoryDataRequest,
  audienceCategoryDataCriteriaCount,
  audienceCategoryDataCriteriaCountByZone,
  audienceCategoryDataCriteriaAnalyze,
  audienceCategoryDataCriteriaAnalyzeByZone,
  audienceGetMapMarkers,
  audienceGetMapMarkersByZone,
  audienceZonesOnCreateRequest,
  audienceZonesOnUpdateRequest,
  audienceCloneRequest,
  saveExternalAudience,
  problemUploadFileAudienceExternal,
  downloadOriginalFileAudienceExternal,
  downloadInvalidationFileAudienceExternal
} from './audience';

import { Types as DashboardTypes } from '~/redux/ducks/dashboard';
import { dashboardRequest } from './dashboard';

import { Types as CockpitTypes } from '~/redux/ducks/cockpit';
import { cockpitRequest } from './cockpit';

import { Types as CockpitAnalyzeTypes } from '~/redux/ducks/cockpitAnalyze';
import {
  initStateView,
  cockpitAnalyzeRequest,
  showModalResultsWeeklyCockpitAnalyzeRequest,
  changePeriodSelectedModalResultsWeeklyCockpitAnalyzeRequest,
  changeGridSelectedModalResultsWeeklyCockpitAnalyzeRequest,

} from './cockpitAnalyze';

import { Types as CampaignSchedulingTypes } from '~/redux/ducks/campaignScheduling';
import {
  campaignScheduleSetup,
  campaignScheduleCampaignRequest,
  campaignScheduleRequest,
  campaignScheduleSetupByDate,
  campaignScheduleSaveSendRequest,
} from './campaignScheduling';

import { Types as WarningTypes } from '~/redux/ducks/warning';
import {
  warningSetupRequest,
  warningAcceptRequest,
  warningRefuseRequest,
} from './warning';

import { Types as PointsManagementTypes } from '~/redux/ducks/pointsManagement';
import {
  pointsManagementGeoPointsRequest,
  pointsManagementGeoPointsIconRequest,
  pointsManagementGeoPointsIconSave
} from './pointsManagement';

import { Types as ProductTypes } from '~/redux/ducks/product';
import { goSearch as goSearchProduct, showDetailProduct, showDetailProductSetData, changeStrategy } from './product';

import { Types as PlatformSettingsTypes } from "~/redux/ducks/platformSettings";
import { loadSettings, updateSettings } from './platformSettings';

export default function* root() {
  return yield all([

    // Product
    takeLatest(
      ProductTypes.GO_SEARCH,
      goSearchProduct
    ),
    takeLatest(
      ProductTypes.CLEAN_FILTER,
      goSearchProduct
    ),
    takeLatest(
      ProductTypes.CHANGE_PAGE,
      goSearchProduct
    ),
    takeLatest(
      ProductTypes.SHOW_DETAIL_PRODUCT,
      showDetailProduct
    ),
    takeLatest(
      ProductTypes.SHOW_DETAIL_PRODUCT_SET_DATA,
      showDetailProductSetData
    ),
    takeLatest(
      ProductTypes.CHANGE_STRATEGY,
      changeStrategy
    ),

    // PointsManagementTypes
    takeLatest(
      PointsManagementTypes.POINTS_MANAGEMENT_GEO_POINTS_REQUEST,
      pointsManagementGeoPointsRequest
    ),
    takeLatest(
      PointsManagementTypes.POINTS_MANAGEMENT_GEO_POINTS_ICON_REQUEST,
      pointsManagementGeoPointsIconRequest
    ),
    takeLatest(
      PointsManagementTypes.POINTS_MANAGEMENT_GEO_POINTS_ICON_SAVE,
      pointsManagementGeoPointsIconSave
    ),

    // WarningTypes
    takeLatest(
      WarningTypes.WARNING_SETUP_REQUEST,
      warningSetupRequest
    ),
    takeLatest(
      WarningTypes.WARNING_ACCEPT_REQUEST,
      warningAcceptRequest
    ),
    takeLatest(
      WarningTypes.WARNING_REFUSE_REQUEST,
      warningRefuseRequest
    ),

    takeLatest(
      CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_SETUP,
      campaignScheduleSetup
    ),
    takeLatest(
      CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_CAMPAIGN_REQUEST,
      campaignScheduleCampaignRequest
    ),
    takeLatest(
      CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_REQUEST,
      campaignScheduleRequest
    ),
    takeLatest(
      CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_SETUP_BY_DATE,
      campaignScheduleSetupByDate
    ),
    takeLatest(
      CampaignSchedulingTypes.CAMPAIGN_SCHEDULE_SAVE_SEND_REQUEST,
      campaignScheduleSaveSendRequest
    ),

    takeLatest(
      DashboardTypes.DASHBOARD_REQUEST,
      dashboardRequest
    ),

    takeLatest(
      CockpitTypes.COCKPIT_REQUEST,
      cockpitRequest
    ),

    takeLatest(
      CockpitAnalyzeTypes.INIT_STATE_VIEW,
      initStateView
    ),
    takeLatest(
      CockpitAnalyzeTypes.COCKPIT_ANALYZE_REQUEST,
      cockpitAnalyzeRequest
    ),
    takeLatest(
      CockpitAnalyzeTypes.SHOW_MODAL_RESULTS_WEEKLY,
      showModalResultsWeeklyCockpitAnalyzeRequest,
    ),
    takeLatest(
      CockpitAnalyzeTypes.CHANGE_PERIOD_SELECTED_MODAL_RESULTS_WEEKLY,
      changePeriodSelectedModalResultsWeeklyCockpitAnalyzeRequest
    ),
    takeLatest(
      CockpitAnalyzeTypes.CHANGE_GRID_SELECTED_MODAL_RESULTS_WEEKLY,
      changeGridSelectedModalResultsWeeklyCockpitAnalyzeRequest
    ),

    takeLatest(
      CampaignIconTypes.CAMPAIGN_ICON_LOAD_CAMPAIGN_TYPES,
      campaignIconLoadCampaignTypes
    ),

    takeLatest(RedirectTypes.NAVIGATE_TO_ROUTE, navigateToRoute),

    takeLatest(
      BenefitTypes.BENEFIT_REQUEST,
      benefitRequest
    ),

    takeLatest(
      FinancialTypes.FINANCIAL_SETUP,
      financialSetup
    ),
    takeLatest(
      FinancialTypes.FINANCIAL_ARCHIVES_REQUEST,
      financialArchivesRequest
    ),
    takeLatest(
      FinancialTypes.FINANCIAL_INVOICE_SAVE,
      financialInvoiceSave
    ),
    takeLatest(
      FinancialTypes.FINANCIAL_ARCHIVE_DOWNLOAD,
      financialArchiveDownload
    ),
    takeLatest(
      FinancialTypes.FINANCIAL_DOCUMENT_ARCHIVES_DELETE,
      financialDocumentArchivesDelete
    ),
    takeLatest(
      FinancialTypes.FINANCIAL_DOCUMENT_ARCHIVE_DELETE,
      financialDocumentArchiveDelete
    ),
    takeLatest(
      FinancialTypes.FINANCIAL_MODAL_RECEIPT_DATA_REQUEST,
      financialModalReceiptDataRequest
    ),
    takeLatest(
      FinancialTypes.FINANCIAL_INVOICE_RECEIPT_SAVE,
      financialInvoiceReceiptSave
    ),

    takeLatest(
      TermsTypes.TERMS_REQUEST,
      termsRequest
    ),
    takeLatest(
      AudienceTypes.PROBLEM_UPLOAD_FILE_AUDIENCE_EXTERNAL,
      problemUploadFileAudienceExternal,
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_REQUEST,
      audienceRequest
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_SETUP_REQUEST,
      audienceSetupRequest
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_SAVE_DATA,
      audienceSaveData
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_UPDATE_DATA,
      audienceUpdateData
    ),
    takeLatest(
      AudienceTypes.DOWNLOAD_ORIGINAL_FILE_AUDIENCE_EXTERNAL,
      downloadOriginalFileAudienceExternal
    ),
    takeLatest(
      AudienceTypes.DOWNLOAD_INVALIDATION_FILE_AUDIENCE_EXTERNAL,
      downloadInvalidationFileAudienceExternal
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_GET_DATA,
      audienceGetData
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_DELETE_DATA,
      audienceDeleteData
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_CATEGORIES_REQUEST,
      audienceCategoriesRequest
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_CATEGORY_DATA_REQUEST,
      audienceCategoryDataRequest
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_COUNT,
      audienceCategoryDataCriteriaCount
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_COUNT_BY_ZONE,
      audienceCategoryDataCriteriaCountByZone
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_ANALYZE,
      audienceCategoryDataCriteriaAnalyze
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_CATEGORY_DATA_CRITERIA_ANALYZE_BY_ZONE,
      audienceCategoryDataCriteriaAnalyzeByZone
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_GET_MAP_MARKERS,
      audienceGetMapMarkers
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_GET_MAP_MARKERS_BY_ZONE,
      audienceGetMapMarkersByZone
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_ZONES_ON_CREATE_REQUEST,
      audienceZonesOnCreateRequest
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_ZONES_ON_UPDATE_REQUEST,
      audienceZonesOnUpdateRequest
    ),
    takeLatest(
      AudienceTypes.AUDIENCE_CLONE_REQUEST,
      audienceCloneRequest
    ),
    takeLatest(AudienceTypes.SAVE_EXTERNAL_AUDIENCE_REQUEST, saveExternalAudience),

    takeLatest(AuthTypes.SET_CURRENT_USER, setCurrentUser),

    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_SAVE_ORCAMENTO_IN_EDIT,
      campaignEditSaveOrcamentoInEdit
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_SAVE_PERIODO_IN_EDIT,
      campaignEditSavePeriodoInEdit
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_SAVE_BRIEFING_IN_EDIT,
      campaignEditSaveBriefingInEdit
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_SAVE_ATTRIBUTE_IN_EDIT,
      campaignEditSaveAttributeInEdit
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_BRIEFING_DOWNLOAD,
      campaignEditBriefingDownload
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_CRIATIVO_REQUEST,
      campaignEditCriativoRequest
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_CRIATIVO_SAVE,
      campaignEditCriativoSave
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_BENEFIT_SAVE,
      campaignEditBenefitSave
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_CARROT_PACKAGE_SAVE,
      campaignEditCarrotPackageSave
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_LOAD_MEDIA_EVENT_LIST,
      campaignEditLoadMediaEventList
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_LOAD_REPORT,
      campaignEditLoadReport
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_CRIATIVO_REQUEST_SUCCESS,
      campaignEditLoadReportTemplate
    ),
    takeLatest(
      CampaignTypes.LOAD_CAMPAIGN_EDIT_SUCCESS,
      campaignEditLoadReportTemplate
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_LOAD_AUDIENCES_REQUEST,
      campaignEditLoadAudiencesRequest
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_SAVE_IMPACT_SETTINGS,
      campaignEditSaveImpactSettings
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_SAVE_ESTRATEGIA_ENTREGA,
      campaignEditSaveEstrategiaEntrega
    ),
    takeLatest(
      CampaignEditTypes.EXECUTE_TEST_AUDIENCE,
      executeTestAudience
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_NO_CREDIT_PREVIEW_REQUEST,
      campaignEditNoCreditPreviewRequest
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_EDIT_NO_DATA_PREVIEW_REQUEST,
      campaignEditNoDataPreviewRequest
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_CLONE_REQUEST,
      campaignCloneRequest
    ),
    takeLatest(
      CampaignEditTypes.CAMPAIGN_FILE_SIZE_CONFIG_REQUEST,
      campaignFileSizeConfigRequest
    ),

    takeLatest(ApprovalTypes.LOAD_CAMPAIGN_LIST, loadCampaignListApproval),
    takeLatest(ApprovalTypes.FILTER_CAMPAIGN, filterCampaignApproval),
    takeLatest(ApprovalTypes.EXPORT_FILTER_CAMPAIGN, exportFilterCampaignApproval),
    takeLatest(ApprovalTypes.LOAD_CAMPAIGN_APPROVAL, loadCampaignApproval),
    takeLatest(ApprovalTypes.APPROVED_CAMPAIGN, approvedCampaign),
    takeLatest(ApprovalTypes.DEMONSTRATION_CAMPAIGN, demonstrationCampaign),
    takeLatest(
      ApprovalTypes.RUN_DEMONSTRATION_CAMPAIGN,
      runDemonstrationCampaign
    ),
    takeLatest(ApprovalTypes.REJECT_CAMPAIGN, rejectCampaign),
    takeLatest(ApprovalTypes.WORKFLOW_STATUS, workflowStatusApproval),

    takeLatest(CampaignTypes.LOAD_CAMPAIGN_LIST, loadCampaignListView),
    takeLatest(CampaignTypes.LOAD_CAMPAIGN_EDIT, loadCampaignEdit),
    takeLatest(CampaignTypes.FILTER_CAMPAIGN, filterCampaign),
    takeLatest(CampaignTypes.EXPORT_FILTER_CAMPAIGN, exportFilterCampaign),
    takeLatest(CampaignTypes.CREATE_CAMPAIGN, createCampaign),
    takeLatest(CampaignTypes.DELETE_CAMPAIGN, deleteCampaign),
    takeLatest(CampaignTypes.CLONE_CAMPAIGN, cloneCampaign),
    takeLatest(CampaignTypes.ACTIVE_CAMPAIGN_EDIT, activeCampaignEdit),
    takeLatest(CampaignTypes.SAVE_CAMPAIGN_EDIT, saveCampaignEdit),
    takeLatest(CampaignTypes.CANCEL_CAMPAIGN, cancelCampaign),
    takeLatest(CampaignTypes.PAUSE_CAMPAIGN, pauseCampaign),
    takeLatest(CampaignTypes.RESUME_CAMPAIGN, resumeCampaign),
    takeLatest(CampaignTypes.DOWNLOAD_REPORT, downloadReport),

    takeLatest(CampaignTypes.OPEN_MODAL_NEW_CAMPAIGN, openModalNewCampaign),
    takeLatest(CampaignTypes.CONFIRM_CREATE_CAMPAIGN, confirmCreateCampaign),
    takeLatest(
      CampaignTypes.UPDATE_PRODUCTS_LIST_BY_ZONE_NEW_CAMPAIGN, updateProductsListByZoneNewCampaign
    ),
    takeLatest(
      CampaignTypes.SET_DEFAULT_PRODUCT_OFFERS_NEW_CAMPAIGN, setDefaultProductOffersNewCampaign
    ),
    takeLatest(CampaignTypes.WORKFLOW_STATUS, workflowStatus),
    takeLatest(CampaignTypes.INCLUDE_TO_PROBE_GRID, includeToProbeGrid),

    takeLatest(ContractTypes.LOAD_CONTRACT_EDIT, loadContractEdit),
    takeLatest(ContractTypes.SAVE_CONTRACT_EDIT, saveContractEdit),
    takeLatest(ContractTypes.LOAD_CONTRACT_LIST, loadContractList),
    takeLatest(ContractTypes.FILTER_CONTRACT_LIST, filterContractList),

    takeLatest(AudienceTestTypes.LOAD_AUDIENCE_TEST, loadAudienceTest),
    takeLatest(AudienceTestTypes.SAVE_MODAL_DATA, saveModalData),
    takeLatest(AudienceTestTypes.LOAD_AUDIENCE_USER_DATA, loadAudienceUserData),
    takeLatest(AudienceTestTypes.DELETE_AUDIENCE, deleteAudience),
    takeLatest(AudienceTestTypes.FILTER_AUDIENCE, filterAudience),
    takeLatest(AudienceTestTypes.RESET_FILTER_AUDIENCE, resetfilterAudience),


    takeLatest(
      AdvertisingPartnerTypes.LOAD_ADVERTISING_PARTNER_LIST,
      loadAdvertisingPartnerList
    ),
    takeLatest(
      AdvertisingPartnerTypes.ADVERTISING_PARTNER_UPDATE,
      advertisingPartnerUpdate
    ),
    takeLatest(
      AdvertisingPartnerTypes.FILTER_ADVERTISING_PARTNER_LIST,
      filterAdvertisingPartnerList
    ),

    takeLatest(PeriodTypes.LOAD_PERIOD_LIST, loadPeriodList),
    takeLatest(PeriodTypes.PERIOD_UPDATE, periodUpdate),

    takeLatest(AuctionTypes.AUCTION_REQUEST, auctionRequest),
    takeLatest(AuctionTypes.AUCTION_SETUP, auctionSetup),
    takeLatest(AuctionTypes.AUCTION_GRID_UPDATE, auctionGridUpdate),
    takeLatest(AuctionTypes.AUCTION_RANKING_UPDATE, auctionRankingUpdate),
    takeLatest(AuctionTypes.AUCTION_GET_TERM_PREVIEW, auctionGetTermPreview),

    takeLatest(ReportTypes.REPORT_GRID_PERIOD_REQUEST, reportGridPeriodRequest),
    takeLatest(ReportTypes.REPORT_GRID_PERIOD_SETUP, reportGridPeriodSetup),
    takeLatest(ReportTypes.REPORT_MONTHLY_GRID_PERIOD_SETUP, reportMonthlyGridPeriodSetup),
    takeLatest(ReportTypes.REPORT_GRID_PERIOD_LOAD_REPORT_DATA, reportGridPeriodLoadReportData),
    takeLatest(ReportTypes.REPORT_MONTHLY_GRID_PERIOD_REQUEST, reportMonthlyGridPeriodRequest),
    takeLatest(ReportTypes.REPORT_GRID_CONFIRM_SIGNATURE, reportGridConfirmSignature),

    takeLatest(ReportV2Types.LOAD_DATA_REPORT_DASHBOARD, loadDataReportDashboard),

    takeLatest(NewGridPeriodTypes.GRID_PERIOD_SETUP, newGridPeriodSetup),
    takeLatest(NewGridPeriodTypes.CHANGE_PERIOD_SELECTED, changePeriodSelected),
    takeLatest(NewGridPeriodTypes.OPEN_MODAL_INIT_CLOSE_WEEKLY, openModalInitCloseWeekly),
    takeLatest(NewGridPeriodTypes.CONFIRM_START_GRID_PERIOD, confirmStartGridPeriod),
    takeLatest(NewGridPeriodTypes.CONFIRM_CLOSE_GRID_PERIOD, confirmCloseGridPeriod),
    takeLatest(NewGridPeriodTypes.OPEN_MODAL_CHANNEL, openModalChannelGridPeriod),
    takeLatest(NewGridPeriodTypes.CHANGE_STATUS_AUCTION, changeStatusAuctionGridPeriod),
    takeLatest(NewGridPeriodTypes.CHANGE_STATUS_PROBE, changeStatusProbeGridPeriod),
    takeLatest(NewGridPeriodTypes.CHANGE_STATUS_FIXED_CPA, changeStatusFixedCpaGridPeriod),
    takeLatest(NewGridPeriodTypes.CHANGE_STATUS_BONUS, changeStatusBonusGridPeriod),
    takeLatest(NewGridPeriodTypes.CLOSE_MODAL_CHANNEL, closeModalChannelGridPeriod),
    takeLatest(NewGridPeriodTypes.UPDATE_ITEM_AUCTION, updateItemAuctionGridPeriod),
    takeLatest(NewGridPeriodTypes.UPDATE_ITEM_PROBE, updateItemProbeGridPeriod),
    takeLatest(NewGridPeriodTypes.REMOVE_CAMPAIGN_PROBE, removeCampaignProbeGridPeriod),
    takeLatest(NewGridPeriodTypes.REMOVE_CAMPAIGN_FIXED_CPA, removeCampaignFixedCpaGridPeriod),
    takeLatest(NewGridPeriodTypes.REMOVE_CAMPAIGN_BONUS, removeCampaignBonusGridPeriod),
    takeLatest(NewGridPeriodTypes.UPDATE_ITEM_FIXED_CPA, updateItemFixedCpaGridPeriod),
    takeLatest(NewGridPeriodTypes.UPDATE_ITEM_BONUS, updateItemBonusGridPeriod),
    takeLatest(NewGridPeriodTypes.RECALCULATE_REMAINING_IMPRESSIONS, recalculateRemainingImpressions),
    takeLatest(
      NewGridPeriodTypes.GRID_PERIOD_CAMPAIGN_BY_FILTER_REQUEST,
      newGridPeriodCampaignByFilterRequest
    ),
    takeLatest(
      NewGridPeriodTypes.GRID_PERIOD_INSERT_CAMPAIGNS_SELECTED,
      newGridPeriodInsertCampaignsSelected
    ),

    takeLatest(GridPeriodTypes.GRID_PERIOD_SETUP, gridPeriodSetup),
    takeLatest(GridPeriodTypes.GRID_PERIOD_REQUEST, gridPeriodRequest),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_CAMPAIGN_BY_FILTER_REQUEST,
      gridPeriodCampaignByFilterRequest
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_INSERT_CAMPAIGNS_SELECTED,
      gridPeriodInsertCampaignsSelected
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_SAVE_ITEM_IN_EDIT,
      gridPeriodSaveItemInEdit
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_SAVE_GRID,
      gridPeriodSaveGrid
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_DELETE_ITEM_GRID,
      gridPeriodDeleteItemGrid
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_LOAD_REMAINING_IMPRESSIONS,
      gridPeriodLoadRemainingImpressions
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_CHANGE_LEILAO_GRID_ITEM,
      gridPeriodChangeLeilaoGridItem
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_CHANGE_BONIFICADA_GRID_ITEM,
      gridPeriodChangeBonificadaGridItem
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_CHANGE_CPA_GRID_ITEM,
      gridPeriodChangeCpaGridItem
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_CHANGE_TESTE_GRID_ITEM,
      gridPeriodChangeTesteGridItem
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_CHANGE_TESTE_CPA,
      gridPeriodChangeTesteCpa
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_CHANGE_SENDER,
      gridPeriodChangeSender
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_CHANGE_CPA,
      gridPeriodChangeCpa
    ),
    takeLatest(
      GridPeriodTypes.GRID_PERIOD_AUTOMATIC_DISTRIBUTION,
      gridPeriodAutomaticDistribution
    ),

    takeLatest(AdTypes.LOAD_AD_LIST, loadAdList),
    takeLatest(AdTypes.CREATE_AD, createAd),
    takeLatest(AdTypes.EDIT_AD, editAd),
    takeLatest(AdTypes.DELETE_AD, deleteAd),
    takeLatest(AdTypes.LOAD_EDIT_AD, loadEditAd),
    takeLatest(AdTypes.SETUP_ENABLE_DISABLE, setupEnableDisable),
    takeLatest(AdTypes.SETUP_ENABLE_TO_EDIT, setupEnableToEdit),
    takeLatest(AdTypes.SETUP_SAVE_EDITING, setupSaveEditing),
    takeLatest(
      AdTypes.CHANGE_DETAIL_POSITION_IN_SETUP,
      changeDetailPositionInSetup
    ),
    takeLatest(AdTypes.SETUP_DISABLE_ORDERING, setupDisableOrdering),
    takeLatest(AdTypes.SETUP_SAVE_ORDERING, setupSaveOrdering),
    // toastr messages
    takeLatest(ToastrMessageTypes.ERROR_MESSAGE, errorMessage),
    takeLatest(ToastrMessageTypes.SUCCESS_MESSAGE, successMessage),
    takeLatest(ToastrMessageTypes.WARNING_MESSAGE, warningMessage),

    takeLatest(ChannelTypes.LOAD_CHANNELS_LIST, loadChannelsList),

    takeLatest(CredentialsTypes.GET_CREDENTIALS, getCredentials),

    takeLatest(EventTypes.LOAD_EVENTS_LIST, loadEventsList),

    takeLatest(ProviderTypes.LOAD_PROVIDER_EDIT, loadProviderEdit),
    takeLatest(ProviderTypes.SAVE_PROVIDER_EDIT, saveProviderEdit),
    takeLatest(ProviderTypes.LOAD_PROVIDER_LIST, loadProviderList),
    takeLatest(ProviderTypes.FILTER_PROVIDER_LIST, filterProviderList),
    takeLatest(ProviderTypes.DELETE_PROVIDER, deleteProvider),
    takeLatest(ProviderTypes.RESET_SEARCH_PROVIDER, resetSearchProvider),

    takeLatest(DemoTypes.LOAD_DEMO_LIST, loadDemoList),
    takeLatest(DemoTypes.ADD_NEW_TERMINAL_DEMO, addNewTerminalDemo),
    takeLatest(DemoTypes.REMOVE_TERMINAL_DEMO, removeTerminalDemo),
    takeLatest(DemoTypes.SAVE_DEMO_LIST, saveDemoList),

    takeLatest(HistoryCampaignTypes.SHOW, loadHistoryCampaign),

    takeLatest(BehaviorTypes.LIST_ACTIONS, listActions),
    takeLatest(BehaviorTypes.LIST_ACTION_TYPES, listActionType),
    takeLatest(BehaviorTypes.LIST_EVENT_TYPES, listEventType),
    takeLatest(BehaviorTypes.LIST_BEHAVIORS, listBehaviors),
    takeLatest(BehaviorTypes.OPEN_CRUD_BEHAVIOR, openCrudBehavior),
    takeLatest(BehaviorTypes.LIST_CHANNELS_CAMPAIGN, listChannelsCampaign),
    takeLatest(BehaviorTypes.ON_NEW_BEHAVIOR_MODAL, onNewBehaviorModal),
    takeLatest(BehaviorTypes.LIST_CAMPAIGN_BY_FILTERS_NEW_BEHAVIOR_MODAL,
      listCampaignByFiltersNewBehaviorModal),
    takeLatest(BehaviorTypes.ON_CREATE_BEHAVIOR_NEW_BEHAVIOR_MODAL,
      onCreateBehaviorNewBehaviorModal),
    takeLatest(BehaviorTypes.ON_SAVE_BEHAVIOR_CRUD, onSaveBehaviorCrud),
    takeLatest(BehaviorTypes.ON_DELETE_BEHAVIOR, deleteBehavior),
    takeLatest(BehaviorTypes.ON_SEARCH_BY_FILTER, onSearchByFilter),
    takeLatest(BehaviorTypes.ON_CLEAN_FILTER, onCleanFilter),

    takeLatest(AudienceExporterTypes.OPEN_MODAL, openModalAudienceExporter),
    takeLatest(AudienceExporterTypes.DOWNLOAD_RETARGET, downloadRetarget),
    takeLatest(AudienceExporterTypes.GENERATE_RETARGET, generateRetarget),

    takeLatest(PlatformSettingsTypes.LOAD_SETTINGS, loadSettings),
    takeLatest(PlatformSettingsTypes.UPDATE_SETTINGS, updateSettings),
  ]);
}
