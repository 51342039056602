import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as NewGridPeriodCreators } from '~/redux/ducks/newGridPeriod';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';


import {
  ButtonGridPeriodIcon,
  ChannelsIcon,
  InspectChannelIcon,
  AwaitingStatusIcon,
  ProcessingStatusIcon,
  ValidatedStatusIcon,
} from '../Components';

import {
  Container,
  Header,
  StyledDiv,
  GroupElements,
  GroupTitleList,
  Select,
  Button,
  Table,
} from './styles';
import { ModalInitCloseWeekly, ModalChannel } from './Components';

function NewGridPeriodList({
  periods, periodSelected, zones, gridPeriodSetup, openModalInitCloseWeekly, openModalChannel,
  changePeriodSelected, confirmModal, confirmCloseGridPeriod,
}) {
  const [gridOpen, setGridOpen] = useState(false);
  const [modeInit, setModeInit] = useState(false);
  const [disabledConfirmCloseWeekly, setDisabledConfirmCloseWeekly] = useState(true);

  function handleConfirmCloseWeekly() {
    confirmModal(
      'Você confirma a conclusão do fechamento semanal?',
      periodSelected.label,
      async () => {
        confirmCloseGridPeriod();
      },
    );
  }

  useEffect(() => {
    if (periodSelected) {
      setGridOpen(periodSelected.gridOpen);
      if (periodSelected.gridOpen) {
        const isExistZoneNotStatus = zones.find(z => z.status === 'NOT_STATUS');
        if (isExistZoneNotStatus || !zones.length) {
          setModeInit(true);
        } else {
          setModeInit(false);
        }
        const isNotDoneZones = zones.find(z => z.status !== 'DONE');
        if (!isNotDoneZones) {
          setDisabledConfirmCloseWeekly(false);
        }
      }
    } else {
      setModeInit(false);
      setGridOpen(false);
    }
  }, [periodSelected, zones]);

  useEffect(() => {
    gridPeriodSetup();
  }, []);

  function getStatusIcon(status) {
    switch (status) {
      case 'NOT_STATUS':
        return <AwaitingStatusIcon />;
      case 'ANALYZING':
        return <ProcessingStatusIcon />;
      case 'DONE':
        return <ValidatedStatusIcon />;
      default:
        return <AwaitingStatusIcon />;
    }
  }

  function getStatusDescription(status) {
    switch (status) {
      case 'NOT_STATUS':
        return <span className="span-awaiting">Aguardando abertura análise</span>;
      case 'ANALYZING':
        return <span className="span-analysis">Em análise</span>;
      case 'DONE':
        return <span className="span-validated">Validado</span>;
      default:
        return <span className="span-awaiting">Aguardando abertura análise</span>;
    }
  }

  return (
    <Container>
      <Header>
        <h1>FECHAMENTO SEMANAL</h1>
        {/* {gridOpen && !modeInit && (
          <Button bordered>
            <ButtonConfigIcon />
            <span>Configurações de Fechamento</span>
          </Button>
        )} */}
      </Header>
      <StyledDiv>
        <GroupElements>
          <Select
            id="period"
            name="period"
            className="react-select-container"
            classNamePrefix="react-select"
            options={periods}
            value={periodSelected ? periods.find(p => p.value === periodSelected.uuid) : null}
            onChange={e => changePeriodSelected(e.item)}
          />
          {gridOpen && modeInit && (
            <Button onClick={() => openModalInitCloseWeekly()}>
              <ButtonGridPeriodIcon />
              <span>Iniciar análise de fechamento semanal</span>
            </Button>
          )}
          {gridOpen && !modeInit && (
            <Button disabled={disabledConfirmCloseWeekly} onClick={handleConfirmCloseWeekly}>
              <ButtonGridPeriodIcon />
              <span>Confirmar fechamento semanal</span>
            </Button>
          )}
        </GroupElements>
      </StyledDiv>
      <StyledDiv bordered>
        <GroupTitleList>
          <ChannelsIcon />
          <h3>CANAIS</h3>
        </GroupTitleList>
        <Table>
          <tbody>
            {zones.map((zone) => {
              const auction = zone.types.find(t => t.name === 'AUCTION');
              const fixedCpa = zone.types.find(t => t.name === 'FIXED_CPA');
              const probe = zone.types.find(t => t.name === 'PROBE');
              const bonus = zone.types.find(t => t.name === 'BONUS');
              return (
                <tr key={zone.uuid}>
                  <td style={{ minWidth: 250 }}>{`${zone.channel.name} (${zone.name})`}</td>
                  <td style={{ width: 180 }}>
                    <div>
                      <span>Leilão</span>
                      {getStatusIcon(auction.status)}
                    </div>
                  </td>
                  <td style={{ width: 180 }}>
                    <div>
                      <span>Teste</span>
                      {getStatusIcon(probe.status)}
                    </div>
                  </td>
                  <td style={{ width: 180 }}>
                    <div>
                      <span>CPA Fixo</span>
                      {getStatusIcon(fixedCpa.status)}
                    </div>
                  </td>
                  <td style={{ width: 180 }}>
                    <div>
                      <span>Bonificada</span>
                      {getStatusIcon(bonus.status)}
                    </div>
                  </td>
                  <td style={{ width: 250 }}>
                    <div>
                      <span>Situação Canal</span>
                      <div className="channel-situation">
                        {getStatusIcon(zone.status)}
                        {getStatusDescription(zone.status)}
                      </div>
                    </div>
                  </td>
                  <td style={{ width: 80 }}>
                    <div>
                      <Button onlyIcon onClick={() => openModalChannel(zone)}>
                        <InspectChannelIcon />
                      </Button>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </StyledDiv>
      <ModalInitCloseWeekly />
      <ModalChannel />
    </Container>
  );
}

const mapStateToProps = ({
  newGridPeriod: {
    showModalInitCloseWeekly,
    periods,
    periodSelected,
    zones,
  },
}) => ({
  showModalInitCloseWeekly,
  periods: periods.map(p => ({
    value: p.uuid,
    label: p.label,
    item: p,
  })),
  periodSelected,
  zones,
});


const mapDispatchToProps = dispatch => bindActionCreators(
  {
    gridPeriodSetup: NewGridPeriodCreators.gridPeriodSetup,
    changePeriodSelected: NewGridPeriodCreators.changePeriodSelected,
    openModalInitCloseWeekly: NewGridPeriodCreators.openModalInitCloseWeekly,
    openModalChannel: NewGridPeriodCreators.openModalChannel,
    confirmCloseGridPeriod: NewGridPeriodCreators.confirmCloseGridPeriod,

    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

NewGridPeriodList.defaultProps = {
  periods: [],
  periodSelected: null,
  zones: [],
};

NewGridPeriodList.propTypes = {
  confirmModal: PropTypes.func.isRequired,
  gridPeriodSetup: PropTypes.func.isRequired,
  openModalInitCloseWeekly: PropTypes.func.isRequired,
  openModalChannel: PropTypes.func.isRequired,
  changePeriodSelected: PropTypes.func.isRequired,
  confirmCloseGridPeriod: PropTypes.func.isRequired,
  periods: PropTypes.arrayOf(PropTypes.shape()),
  periodSelected: PropTypes.shape(),
  zones: PropTypes.arrayOf(PropTypes.shape()),
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewGridPeriodList);
