import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as GridPeriodActions } from '~/redux/ducks/gridPeriod';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import { getBudgetLabelString } from '~/Utils';

import {
  Row,
  ModalWrap,
  RowHeader,
  RowContent,
  RowFooter,
  ButtonCloseModal,
  BoxTable,
  BoxTableHeader,
  BoxTableHeaderTitle,
  // BoxTableHeaderSubtitle,
  BoxTableContent,
  TableDaily,
  BoxDailyVolumes,
  BoxDailyVolumesItem,
  Section,
} from './styles';

class ModalCloseAuction extends Component {
  renderTableCPAIsAllChannels = grid => (
    <TableDaily>
      <thead>
        <tr>
          <th>PARCEIRO</th>
          <th>NOME</th>
          <th>PRODUTO - OFERTA</th>
          <th align="center" width="1%">eCPM</th>
          <th align="center" width="1%">BUDGET</th>
          <th align="center" width="1%">CPA</th>
          <th align="center" width="1%">VOLUME</th>
        </tr>
      </thead>
      <tbody>
        {grid && grid.gridItems && grid.gridItems.length > 0 ? grid.gridItems.map(item => (
          <>
            <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
              <td colSpan={7} />
            </tr>
            <tr key={`grid-content-${item.uuid}`} className="grid-content">
              <td>
                <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
              </td>
              <td>
                <span>{item.campaign.name}</span>
              </td>
              <td>
                {item.campaign.productOffers.map(po => (
                  <span key={po.uuid}>
                    {`${po.product.name} - ${po.name}`}
                    <br />
                  </span>
                ))}
              </td>
              <td align="center">
                <span className="nowrap">{item.ecpm.replace('.', ',')}</span>
              </td>
              <td align="center">
                <span className="nowrap">{getBudgetLabelString(item.budget)}</span>
              </td>
              <td align="center">
                <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
              </td>
              <td align="center">
                <span className="nowrap">{item.volume.toLocaleString()}</span>
              </td>
            </tr>
            <tr key={`grid-form-${item.uuid}`} className="grid-form">
              <td colSpan={8}>
                <BoxDailyVolumes>
                  <BoxDailyVolumesItem separator>
                    <label>Volume Total</label>
                    <span>{item.volume}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>Dom</label>
                    <span>{item.dailyDistributionBefore[0] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEG</label>
                    <span>{item.dailyDistributionBefore[1] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>TER</label>
                    <span>{item.dailyDistributionBefore[2] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUA</label>
                    <span>{item.dailyDistributionBefore[3] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUI</label>
                    <span>{item.dailyDistributionBefore[4] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEX</label>
                    <span>{item.dailyDistributionBefore[5] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SAB</label>
                    <span>{item.dailyDistributionBefore[6] || 0}</span>
                  </BoxDailyVolumesItem>
                </BoxDailyVolumes>
              </td>
            </tr>
          </>
        )) : (
          <tr>
            <td colSpan={11}>
              <div className="empty-table-desc">
                <span>Nenhuma campanha adicionada.</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </TableDaily>
  );

  renderTableTesteIsAllChannels = grid => (
    <TableDaily>
      <thead>
        <tr>
          <th>PARCEIRO</th>
          <th>NOME</th>
          <th>PRODUTO - OFERTA</th>
          <th align="center" width="1%">CPA</th>
          <th align="center" width="1%">VOLUME</th>
        </tr>
      </thead>
      <tbody>
        {grid && grid.gridItems && grid.gridItems.length > 0 ? grid.gridItems.map(item => (
          <>
            <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
              <td colSpan={8} />
            </tr>
            <tr key={`grid-content-${item.uuid}`} className="grid-content">
              <td>
                <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
              </td>
              <td>
                <span>{item.campaign.name}</span>
              </td>
              <td>
                {item.campaign.productOffers.map(po => (
                  <span key={po.uuid}>
                    {`${po.product.name} - ${po.name}`}
                    <br />
                  </span>
                ))}
              </td>
              <td align="center">
                <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
              </td>
              <td align="center">
                <span className="nowrap">{item.volume.toLocaleString()}</span>
              </td>
            </tr>
            <tr key={`grid-form-${item.uuid}`} className="grid-form">
              <td colSpan={8}>
                <BoxDailyVolumes>
                  <BoxDailyVolumesItem separator>
                    <label>Volume Total</label>
                    <span>{item.volume}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>Dom</label>
                    <span>{item.dailyDistributionBefore[0] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEG</label>
                    <span>{item.dailyDistributionBefore[1] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>TER</label>
                    <span>{item.dailyDistributionBefore[2] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUA</label>
                    <span>{item.dailyDistributionBefore[3] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUI</label>
                    <span>{item.dailyDistributionBefore[4] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEX</label>
                    <span>{item.dailyDistributionBefore[5] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SAB</label>
                    <span>{item.dailyDistributionBefore[6] || 0}</span>
                  </BoxDailyVolumesItem>
                </BoxDailyVolumes>
              </td>
            </tr>
          </>
        )) : (
          <tr>
            <td colSpan={11}>
              <div className="empty-table-desc">
                <span>Nenhuma campanha adicionada.</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </TableDaily>
  );

  renderTableBonificadaIsAllChannels = grid => (
    <TableDaily>
      <thead>
        <tr>
          <th>PARCEIRO</th>
          <th>NOME</th>
          <th>PRODUTO - OFERTA</th>
          <th align="center" width="1%">VOLUME</th>
        </tr>
      </thead>
      <tbody>
        {grid && grid.gridItems && grid.gridItems.length > 0 ? grid.gridItems.map(item => (
          <>
            <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
              <td colSpan={8} />
            </tr>
            <tr key={`grid-content-${item.uuid}`} className="grid-content">
              <td>
                <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
              </td>
              <td>
                <span>{item.campaign.name}</span>
              </td>
              <td>
                {item.campaign.productOffers.map(po => (
                  <span key={po.uuid}>
                    {`${po.product.name} - ${po.name}`}
                    <br />
                  </span>
                ))}
              </td>
              <td align="center">
                <span className="nowrap">{item.volume.toLocaleString()}</span>
              </td>
            </tr>
            <tr key={`grid-form-${item.uuid}`} className="grid-form">
              <td colSpan={8}>
                <BoxDailyVolumes>
                  <BoxDailyVolumesItem separator>
                    <label>Volume Total</label>
                    <span>{item.volume}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>Dom</label>
                    <span>{item.dailyDistributionBefore[0] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEG</label>
                    <span>{item.dailyDistributionBefore[1] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>TER</label>
                    <span>{item.dailyDistributionBefore[2] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUA</label>
                    <span>{item.dailyDistributionBefore[3] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUI</label>
                    <span>{item.dailyDistributionBefore[4] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEX</label>
                    <span>{item.dailyDistributionBefore[5] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SAB</label>
                    <span>{item.dailyDistributionBefore[6] || 0}</span>
                  </BoxDailyVolumesItem>
                </BoxDailyVolumes>
              </td>
            </tr>
          </>
        )) : (
          <tr>
            <td colSpan={11}>
              <div className="empty-table-desc">
                <span>Nenhuma campanha adicionada.</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </TableDaily>
  );

  renderTableLeilaoIsAllChannels = grid => (
    <TableDaily>
      <thead>
        <tr>
          <th>POSIÇÃO</th>
          <th>PARCEIRO</th>
          <th>NOME</th>
          <th>PRODUTO - OFERTA</th>
          <th align="center" width="1%">eCPM</th>
          <th align="center" width="1%">Budget</th>
          <th align="center" width="1%">CPA</th>
          <th align="center" width="1%">VOLUME</th>
        </tr>
      </thead>
      <tbody>
        {grid && grid.gridItems && grid.gridItems.length > 0 ? grid.gridItems.map(item => (
          <>
            <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
              <td colSpan={8} />
            </tr>
            <tr key={`grid-content-${item.uuid}`} className="grid-content">
              <td>
                <span>{item.ranking}</span>
              </td>
              <td>
                <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
              </td>
              <td>
                <span>{item.campaign.name}</span>
              </td>
              <td>
                {item.campaign.productOffers.map(po => (
                  <span key={po.uuid}>
                    {`${po.product.name} - ${po.name}`}
                    <br />
                  </span>
                ))}
              </td>
              <td align="center">
                <span className="nowrap">{item.ecpm.replace('.', ',')}</span>
              </td>
              <td align="center">
                <span className="nowrap">{getBudgetLabelString(item.budget)}</span>
              </td>
              <td align="center">
                <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
              </td>
              <td align="center">
                <span className="nowrap">{item.volume.toLocaleString()}</span>
              </td>
            </tr>
            <tr key={`grid-form-${item.uuid}`} className="grid-form">
              <td colSpan={8}>
                <BoxDailyVolumes>
                  <BoxDailyVolumesItem separator>
                    <label>Volume Total</label>
                    <span>{item.volume}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>Dom</label>
                    <span>{item.dailyDistributionBefore[0] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEG</label>
                    <span>{item.dailyDistributionBefore[1] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>TER</label>
                    <span>{item.dailyDistributionBefore[2] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUA</label>
                    <span>{item.dailyDistributionBefore[3] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUI</label>
                    <span>{item.dailyDistributionBefore[4] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEX</label>
                    <span>{item.dailyDistributionBefore[5] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SAB</label>
                    <span>{item.dailyDistributionBefore[6] || 0}</span>
                  </BoxDailyVolumesItem>
                </BoxDailyVolumes>
              </td>
            </tr>
          </>
        )) : (
          <tr>
            <td colSpan={11}>
              <div className="empty-table-desc">
                <span>Nenhuma campanha adicionada.</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </TableDaily>
  );

  renderContent = () => {
    const {
      gridPeriod,
    } = this.props;
    const hasDataCpaFixo = gridPeriod.map(item => ({
      zone: item.zone,
      grid: item.grids.find(grid => grid.campaignType.name === 'FIXED_CPA'),
    }));
    const hasDataTeste = gridPeriod.map(item => ({
      zone: item.zone,
      grid: item.grids.find(grid => grid.campaignType.name === 'PROBE'),
    }));
    const hasDataBonificada = gridPeriod.map(item => ({
      zone: item.zone,
      grid: item.grids.find(grid => grid.campaignType.name === 'BONUS'),
    }));
    const hasDataLeilao = gridPeriod.map(item => ({
      zone: item.zone,
      grid: item.grids.find(grid => grid.campaignType.name === 'AUCTION'),
    }));

    return (
      <>
        <Section>
          <legend>CPA Fixo</legend>
          <div className="section-content">
            {hasDataCpaFixo && hasDataCpaFixo.map(item => (
              <BoxTable key={item.zone.uuid}>
                <BoxTableHeader>
                  <BoxTableHeaderTitle>
                    {`${item.zone.channel.name} (${item.zone.name})`}
                  </BoxTableHeaderTitle>
                </BoxTableHeader>
                <BoxTableContent>
                  {this.renderTableCPAIsAllChannels(item.grid)}
                </BoxTableContent>
              </BoxTable>
            ))}
          </div>
        </Section>
        <Section>
          <legend>Teste</legend>
          <div className="section-content">
            {hasDataTeste && hasDataTeste.map(item => (
              <BoxTable key={item.zone.uuid}>
                <BoxTableHeader>
                  <BoxTableHeaderTitle>
                    {`${item.zone.channel.name} (${item.zone.name})`}
                  </BoxTableHeaderTitle>
                </BoxTableHeader>
                <BoxTableContent>
                  {this.renderTableTesteIsAllChannels(item.grid)}
                </BoxTableContent>
              </BoxTable>
            ))}
          </div>
        </Section>
        <Section>
          <legend>Bonificada</legend>
          <div className="section-content">
            {hasDataBonificada && hasDataBonificada.map(item => (
              <BoxTable key={item.zone.uuid}>
                <BoxTableHeader>
                  <BoxTableHeaderTitle>
                    {`${item.zone.channel.name} (${item.zone.name})`}
                  </BoxTableHeaderTitle>
                </BoxTableHeader>
                <BoxTableContent>
                  {this.renderTableBonificadaIsAllChannels(item.grid)}
                </BoxTableContent>
              </BoxTable>
            ))}
          </div>
        </Section>
        <Section>
          <legend>Leilão</legend>
          <div className="section-content">
            {hasDataLeilao && hasDataLeilao.map(item => (
              <BoxTable key={item.zone.uuid}>
                <BoxTableHeader>
                  <BoxTableHeaderTitle>
                    {`${item.zone.channel.name} (${item.zone.name})`}
                  </BoxTableHeaderTitle>
                </BoxTableHeader>
                <BoxTableContent>
                  {this.renderTableLeilaoIsAllChannels(item.grid)}
                </BoxTableContent>
              </BoxTable>
            ))}
          </div>
        </Section>
      </>
    );
  };

  render() {
    const {
      modal,
      gridPeriodChangeModalStatus,
      gridPeriodSaveGrid,
      // remainingImpressions,
    } = this.props;

    const remainingImpressions = 0;

    return modal.modalCloseAuction && (
      <Modal width={1000} height={800} padding={0} scroll={false}>
        <ModalWrap>
          <RowHeader>
            <Row>
              <h1>Confirmar fechamento do leilão</h1>
              <ButtonCloseModal
                type="button"
                onClick={() => {
                  gridPeriodChangeModalStatus({
                    modalCloseAuction: false,
                  });
                }}
              >
                <FaTimes size={30} />
              </ButtonCloseModal>
            </Row>
          </RowHeader>
          <RowContent>
            {this.renderContent()}
          </RowContent>
          <RowFooter>
            {remainingImpressions < 0 && (
              <p>
                O número de disparos restantes não pode ser negativo para o fechamento do leilão.
              </p>
            )}
            <Button
              disabled={remainingImpressions < 0}
              title="Confirmar"
              onClick={() => {
                gridPeriodSaveGrid('FINAL');
              }}
            />
          </RowFooter>
        </ModalWrap>
      </Modal>
    );
  }
}

ModalCloseAuction.defaultProps = {
  gridPeriod: null,
};

ModalCloseAuction.propTypes = {
  // remainingImpressions: PropTypes.number.isRequired,
  modal: PropTypes.shape().isRequired,
  gridPeriod: PropTypes.shape(),
  gridPeriodChangeModalStatus: PropTypes.func.isRequired,
  gridPeriodSaveGrid: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modal: state.gridPeriod.modal,
  gridPeriod: state.gridPeriod.gridPeriod,
  remainingImpressions: state.gridPeriod.remainingImpressions,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    gridPeriodChangeModalStatus: GridPeriodActions.gridPeriodChangeModalStatus,
    gridPeriodSaveGrid: GridPeriodActions.gridPeriodSaveGrid,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalCloseAuction);
