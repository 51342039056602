import React from 'react';
import PropTypes from 'prop-types';
import { AiFillPlusCircle } from 'react-icons/ai';

import { Container } from './styles';

function ItemAdd({ onClick, disabledFields }) {
  return (
    <Container onClick={onClick} className="media-item-add" disabled={disabledFields}>
      <AiFillPlusCircle />
      <div className="item-add-separator" />
    </Container>
  );
}

ItemAdd.defaultProps = {
  disabledFields: false,
};

ItemAdd.propTypes = {
  disabledFields: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ItemAdd;
