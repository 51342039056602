import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import Image from '~/Components/Image';

import HeaderRichCreative from '../../../../Components/PreviewCriativo/components/HeaderRichCreative';
import FormStep from '../../../../Components/PreviewResearch/formStep';

import {
  Row,
  ControlPreview,
  ControlButton,
  ControlTitle,
  ContentPreview,
  IconBox,
  SmartphoneToolbar,
  MidiaImagePreview,
  MidiaVideoPreview,
  MidiaVastPreview,
  MidiaProgramaticaPreview,
  MidiaAppInstallPreview,
} from './styles';
import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';

const DEFAULT_SCREENS = [
  { key: 'layout', value: 'Layout' },
  { key: 'media', value: 'Conteúdo' },
  { key: 'success', value: 'Sucesso' },
];

class Preview extends Component {
  state = {
    currentScreen: DEFAULT_SCREENS[0],
    screenList: DEFAULT_SCREENS,
    page: 0,
  };

  componentDidMount() {}

  renderLayoutScreen = () => {
    const { data: { content } } = this.props;

    if (content.mainData && content.mainData.layout) {
      return (
        <ContentPreview>
          <Row style={{ alignItems: 'center' }}>
            {content.mainData.layout.icon && (
              <IconBox>
                <Image className="img-title" src={content.mainData.layout.icon} alt="Icone" />
              </IconBox>
            )}
            <label className="success-title">
              {content.mainData.layout.title || 'Título da página'}
            </label>
          </Row>
          <Row>
            <p className="success-subtitle">
              {content.mainData.layout.primaryInformation || 'Informação primária'}
            </p>
          </Row>
          <Row>
            <p className="success-subtitle">
              {content.mainData.layout.secondaryInformation || 'Informação secundária'}
            </p>
          </Row>
        </ContentPreview>
      );
    }
    return null;
  };

  renderVideo = (media) => {
    if (!media.content) return null;
    return (
      <MidiaVideoPreview controls>
        <source src={media.content} type="video/mp4" />
        Seu browser não suporta o preview de vídeos
      </MidiaVideoPreview>
    );
  };

  renderImage = (media) => {
    if (!media.content) return null;
    return <MidiaImagePreview src={media.content} />;
  };

  renderDataSearch = (media) => {
    if (!media.content) return null;
    const {
      data: { content: { mainData } },
    } = this.props;
    const layout = mainData && mainData.layout ? mainData.layout : null;
    return (
      <FormStep
        data={media.content}
        style={{ top: 33 }}
        config={
            layout
              ? ({
                primaryColor: layout.primaryColor,
                secondaryColor: layout.secondaryColor,
              })
              : null
          }
      />
    );
  };

  renderProgramatica = (media) => {
    if (!media.config.params[0].value) return null;
    return (
      <MidiaProgramaticaPreview>
        <div className="midia-programatica-preview-box">
          <div className="midia-programatica-preview-box-title">
            <span>Sem Preview</span>
          </div>
          <div className="midia-programatica-preview-box-contet">
            <p>Esse tipo de Criativo não tem visualização de preview.</p>
            <div>
              <b>Tipo: </b>
              Programática
            </div>
          </div>
        </div>
      </MidiaProgramaticaPreview>
    );
  };

  renderAppInstall = (media) => {
    if (!media.content) return null;
    return (
      <MidiaAppInstallPreview>
        <div className="midia-app-install-preview-box">
          <div className="midia-app-install-preview-box-title">
            <span>Sem Preview</span>
          </div>
          <div className="midia-app-install-preview-box-contet">
            <p>Esse tipo de Criativo não tem visualização de preview.</p>
            <div>
              <b>Tipo: </b>
              App Install
            </div>
          </div>
        </div>
      </MidiaAppInstallPreview>
    );
  };

  renderExternalRedirect = (media) => {
    if (!media.content) return null;
    return (
      <MidiaAppInstallPreview>
        <div className="midia-app-install-preview-box">
          <div className="midia-app-install-preview-box-title">
            <span>Sem Preview</span>
          </div>
          <div className="midia-app-install-preview-box-contet">
            <p>Esse tipo de Criativo não tem visualização de preview.</p>
            <div>
              <b>Tipo: </b>
              Redirecionamento externo
            </div>
          </div>
        </div>
      </MidiaAppInstallPreview>
    );
  };

  renderVast = (media) => {
    if (!media.content) return null;
    return (
      <MidiaVastPreview>
        <div className="midia-vast-preview-box">
          <div className="midia-vast-preview-box-title">
            <span>Sem Preview</span>
          </div>
          <div className="midia-vast-preview-box-contet">
            <p>Esse tipo de Criativo não tem visualização de preview.</p>
            <div>
              <b>Tipo: </b>
              Vast
            </div>
          </div>
        </div>
      </MidiaVastPreview>
    );
  };

  renderVastSurvey = (media) => {
    if (!media.content) return null;
    return (
      <MidiaVastPreview>
        <div className="midia-vast-preview-box">
          <div className="midia-vast-preview-box-title">
            <span>Sem Preview</span>
          </div>
          <div className="midia-vast-preview-box-contet">
            <p>Esse tipo de Criativo não tem visualização de preview.</p>
            <div>
              <b>Tipo: </b>
              Vast com pergunta qualificadora
            </div>
          </div>
        </div>
      </MidiaVastPreview>
    );
  };

  renderVpaid = (media) => {
    if (!media.content) return null;
    return (
      <MidiaVastPreview>
        <div className="midia-vast-preview-box">
          <div className="midia-vast-preview-box-title">
            <span>Sem Preview</span>
          </div>
          <div className="midia-vast-preview-box-contet">
            <p>Esse tipo de Criativo não tem visualização de preview.</p>
            <div>
              <b>Tipo: </b>
              Vpaid
            </div>
          </div>
        </div>
      </MidiaVastPreview>
    );
  };

  renderVpaidSurvey = (media) => {
    if (!media.content) return null;
    return (
      <MidiaVastPreview>
        <div className="midia-vast-preview-box">
          <div className="midia-vast-preview-box-title">
            <span>Sem Preview</span>
          </div>
          <div className="midia-vast-preview-box-contet">
            <p>Esse tipo de Criativo não tem visualização de preview.</p>
            <div>
              <b>Tipo: </b>
              Vpaid com pergunta qualificadora
            </div>
          </div>
        </div>
      </MidiaVastPreview>
    );
  };

  renderMediaScreen = () => {
    const { data: { content } } = this.props;
    const listMedia = content.mainData.media;
    const { page } = this.state;

    const nextPage = () => {
      this.setState({ page: page + 1 });
    };

    const previousPage = () => {
      let newPage = page - 1;
      if (page >= listMedia.length - 1) {
        newPage = (listMedia.length - 1) - 1;
      }
      this.setState({ page: newPage });
    };

    const media = listMedia ? (listMedia[page] || listMedia[listMedia.length - 1]) : null;

    if (!media) {
      return null;
    }

    const renderPageFromMedia = () => {
      switch (media.type) {
        case CreativeCustomMidiaType.VIDEO: return this.renderVideo(media);
        case CreativeCustomMidiaType.IMAGE: return this.renderImage(media);
        case CreativeCustomMidiaType.DATA_SEARCH: return this.renderDataSearch(media);
        case CreativeCustomMidiaType.PROGRAMATICA: return this.renderProgramatica(media);
        case CreativeCustomMidiaType.APP_INSTALL: return this.renderAppInstall(media);
        case CreativeCustomMidiaType.EXTERNAL_REDIRECT: return this.renderExternalRedirect(media);
        case CreativeCustomMidiaType.VAST: return this.renderVast(media);
        case CreativeCustomMidiaType.VAST_SURVEY: return this.renderVastSurvey(media);
        case CreativeCustomMidiaType.VPAID: return this.renderVpaid(media);
        case CreativeCustomMidiaType.VPAID_SURVEY: return this.renderVpaidSurvey(media);
        default: return null;
      }
    };

    return (
      <ContentPreview>
        <SmartphoneToolbar>
          <button
            type="button"
            disabled={listMedia.length <= 1 || page === 0}
            onClick={() => previousPage()}
          >
            <FaChevronLeft />
          </button>
          <div className="smartphone-toolbar-title"><span>{media ? media.title : ''}</span></div>
          <button
            type="button"
            disabled={page >= listMedia.length - 1}
            onClick={() => nextPage()}
          >
            <FaChevronRight />
          </button>
        </SmartphoneToolbar>
        {renderPageFromMedia()}
      </ContentPreview>
    );
  };

  renderSuccessScreen = () => {
    const { data: { content } } = this.props;

    const renderHeader = (customStyles = null) => {
      let headerimage = '';
      let headerLabel1 = '';
      let headerLabel2 = '';

      if (content.mainData && content.mainData.layout) {
        headerimage = content.mainData.layout.icon || '';
        headerLabel1 = content.mainData.layout.primaryInformation || '';
        headerLabel2 = content.mainData.layout.secondaryInformation || '';
      }
      return (
        <HeaderRichCreative
          image={headerimage}
          label1={headerLabel1}
          label2={headerLabel2}
          style={customStyles}
        />
      );
    };

    if (content.mainData && content.mainData.success) {
      if (content.mainData.success.type === 'internal') {
        return (
          <ContentPreview>
            {renderHeader({ marginLeft: 10, marginRight: 10, marginTop: 10 })}
            <Row style={{ alignItems: 'center' }}>
              <label className="success-title">
                {content.mainData.success.title || 'Título da Mensagem'}
              </label>
            </Row>
            <Row>
              <p className="success-subtitle">
                {content.mainData.success.description || 'Subtitulo da mensagem descrito com texto largo para teste de campo'}
              </p>
            </Row>
          </ContentPreview>
        );
      }
      return (
        <ContentPreview>
          {renderHeader({ marginLeft: 10, marginRight: 10, marginTop: 10 })}
          <a className="external-link" href={content.mainData.success.url} target="_blank" rel="noopener noreferrer">
            Acessar link externo
          </a>
        </ContentPreview>
      );
    }
    return null;
  };

  renderScreens = () => {
    const { currentScreen } = this.state;
    switch (currentScreen.key) {
      case 'layout': return this.renderLayoutScreen();
      case 'success': return this.renderSuccessScreen();
      case 'media': return this.renderMediaScreen();
      default: return null;
    }
  };

  renderButtonsNavigators = () => {
    const { currentScreen, screenList } = this.state;
    return (
      <ControlPreview>
        <ControlButton
          disabled={screenList.indexOf(currentScreen) === 0}
          onClick={() => {
            this.setState({
              currentScreen: screenList[screenList.indexOf(currentScreen) - 1],
            });
          }}
        >
          <FaChevronLeft />
        </ControlButton>
        <ControlTitle>
          {currentScreen.value}
        </ControlTitle>
        <ControlButton
          disabled={screenList.indexOf(currentScreen) === screenList.length - 1}
          onClick={() => {
            this.setState({
              currentScreen: screenList[screenList.indexOf(currentScreen) + 1],
            });
          }}
        >
          <FaChevronRight />
        </ControlButton>
      </ControlPreview>
    );
  };

  render() {
    return (
      <>
        {this.renderButtonsNavigators()}
        {this.renderScreens()}
      </>
    );
  }
}

Preview.propTypes = {
  data: PropTypes.shape({
    content: PropTypes.shape().isRequired,
  }).isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Preview);
