import React, { Component } from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import { withTheme } from 'styled-components';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import UploadService, { baseUrlMedia } from '~/services/UploadService';
import ColorPicker from '~/Components/ColorPicker';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import {
  Container,
  Row,
  Column,
  FormGroup,
  Input,
  Imagem,
  SuccessHeaderImagem,
  BannerEmpty,
  ErrorMessage,
} from './styles';

import Upload from '~/Components/Upload';
import Image from '~/Components/Image';
import Button from '~/Components/Button';
import UploadImage from '~/Components/UploadImage';

const BANNER_RESOLUTION_DEFAULT = {
  width: 500,
  height: 300,
  fixed: false,
};

class TabLayout extends Component {
  state = {
    showMessageErrorMaxSizeFileIcon: false,
    showMessageErrorMaxSizeFileHeaderImage: false,
    showMessageErrorResolutionBannerInvalid: false,
  };

  componentDidMount() {
    const {
      changeData,
      criativoEdit,
      theme,
      content: { mainData },
    } = this.props;

    const layout = mainData && mainData.layout ? mainData.layout : {};
    changeData({
      ...criativoEdit,
      content: {
        ...criativoEdit.content,
        mainData: {
          ...mainData,
          layout: {
            ...layout,
            primaryColor: layout && layout.primaryColor
              ? layout.primaryColor
              : theme.colors.primaryHex,
            secondaryColor: layout && layout.secondaryColor
              ? layout.secondaryColor
              : theme.colors.white,
          },
        },
      },
    });
  }

  uploadFileToAWS = async (campaignUuid, file) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;
    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  uploadFileIcon = async (files) => {
    const file = files[0];
    if (file) {
      const {
        campaign,
        content: { mainData },
      } = this.props;

      const layout = mainData && mainData.layout ? mainData.layout : {};

      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.onChangeDataLayout({
        ...layout,
        icon: url,
      });
    }
  };

  uploadFileHeaderImage = async (files) => {
    const file = files[0];
    if (file) {
      const {
        campaign,
        content: { mainData },
      } = this.props;

      let layout = mainData && mainData.layout ? mainData.layout : { header: {} };
      if (!layout.header) {
        layout = {
          ...layout,
          header: {
            image: '',
          },
        };
      }

      const { url } = await this.uploadFileToAWS(campaign.uuid, file);
      this.onChangeDataLayout({
        ...layout,
        header: {
          ...layout.header,
          image: url,
        },
      });
    }
  };

  onChangeDataLayout = (layout) => {
    const {
      changeData,
      criativoEdit,
      content: { mainData },
    } = this.props;

    changeData({
      ...criativoEdit,
      content: {
        ...criativoEdit.content,
        mainData: {
          ...mainData,
          layout,
        },
      },
    });
  };

  getIconMaxSize = () => {
    const {
      fileSizeConfigData,
    } = this.props;
    if(fileSizeConfigData
      && fileSizeConfigData.creative
      && fileSizeConfigData.creative.layout
      && fileSizeConfigData.creative.layout.icon
      && fileSizeConfigData.creative.layout.icon.maxFileSizeMB) {
        return fileSizeConfigData.creative.layout.icon.maxFileSizeMB * 1000000;
      }
      if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
  }

  getBannerMaxSize = () => {
    const {
      fileSizeConfigData,
    } = this.props;
    if(fileSizeConfigData
      && fileSizeConfigData.creative
      && fileSizeConfigData.creative.layout
      && fileSizeConfigData.creative.layout.banner
      && fileSizeConfigData.creative.layout.banner.maxFileSizeMB) {
        return fileSizeConfigData.creative.layout.banner.maxFileSizeMB * 1000000;
      }
      if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB * 1000000;
      }
      return 2000000;
  }

  getIconMaxSizeLabel = () => {
    const {
      fileSizeConfigData,
    } = this.props;
    if(fileSizeConfigData
      && fileSizeConfigData.creative
      && fileSizeConfigData.creative.layout
      && fileSizeConfigData.creative.layout.icon
      && fileSizeConfigData.creative.layout.icon.maxFileSizeMB) {
        return fileSizeConfigData.creative.layout.icon.maxFileSizeMB;
      }
      if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
  }

  getBannerMaxSizeLabel = () => {
    const {
      fileSizeConfigData,
    } = this.props;
    if(fileSizeConfigData
      && fileSizeConfigData.creative
      && fileSizeConfigData.creative.layout
      && fileSizeConfigData.creative.layout.banner
      && fileSizeConfigData.creative.layout.banner.maxFileSizeMB) {
        return fileSizeConfigData.creative.layout.banner.maxFileSizeMB;
      }
      if(fileSizeConfigData && fileSizeConfigData.defaultMaxFileSizeMB) {
        return fileSizeConfigData.defaultMaxFileSizeMB;
      }
      return 2;
  }

  renderForm = () => {
    const {
      content: { mainData },
      hasError,
      disabledFields,
      campaign,
      fileSizeConfigData,
    } = this.props;

    console.log(this.getIconMaxSize());

    let bannerResolution = BANNER_RESOLUTION_DEFAULT;
    if (campaign.zone.extra && campaign.zone.extra) {
      const { creatives } = campaign.zone.extra;
      if (creatives
        && creatives.internal
        && creatives.internal.layout
        && creatives.internal.layout.banner
      ) {
        const { resolution } = creatives.internal.layout.banner;
        if (resolution) {
          bannerResolution = resolution;
        }
      }
    }

    let layout = mainData && mainData.layout ? mainData.layout : { header: {} };
    if (!layout.header) {
      layout = {
        ...layout,
        header: {},
      };
    }

    const {
      showMessageErrorMaxSizeFileIcon,
      showMessageErrorMaxSizeFileHeaderImage,
      showMessageErrorResolutionBannerInvalid
    } = this.state;
    return (
      <>
        <Row>
          <FormGroup style={{ flex: 4, marginRight: 10 }}>
            <label>
              Título da página
              <span>*</span>
            </label>
            <Input
              disabled={disabledFields}
              placeholder="Digite o título da página"
              maxLength="50"
              onChange={(e) => {
                this.onChangeDataLayout({
                  ...layout,
                  title: e.target.value,
                });
              }}
              value={layout && layout.title ? layout.title : ''}
              invalid={hasError && !layout.title}
            />
          </FormGroup>
          <FormGroup>
            <label>
              Cor Primária
              <span>*</span>
            </label>
            <ColorPicker
              value={layout && layout.primaryColor ? layout.primaryColor : ''}
              disabled={disabledFields}
              onChange={(color) => {
                this.onChangeDataLayout({
                  ...layout,
                  primaryColor: color,
                });
              }}
            />
          </FormGroup>
          <FormGroup>
            <label>
              Cor Secundária
              <span>*</span>
            </label>
            <ColorPicker
              disabled={disabledFields}
              value={layout && layout.secondaryColor ? layout.secondaryColor : ''}
              onChange={(color) => {
                this.onChangeDataLayout({
                  ...layout,
                  secondaryColor: color,
                });
              }}
            />
          </FormGroup>
        </Row>
        <Row style={{ marginTop: 10 }}>
          <Column style={{ flex: 1, marginRight: 10 }}>
            <FormGroup>
              <label>
                Informação primária
              </label>
              <Input
                disabled={disabledFields}
                maxLength="30"
                placeholder="Informação primária"
                onChange={(e) => {
                  this.onChangeDataLayout({
                    ...layout,
                    primaryInformation: e.target.value,
                  });
                }}
                value={layout && layout.primaryInformation ? layout.primaryInformation : ''}
                invalid={false}
              />
            </FormGroup>
          </Column>
          <Column style={{ flex: 1 }}>
            <FormGroup>
              <label>
                Informação secundária
              </label>
              <Input
                disabled={disabledFields}
                maxLength="55"
                placeholder="Informação secundária"
                onChange={(e) => {
                  this.onChangeDataLayout({
                    ...layout,
                    secondaryInformation: e.target.value,
                  });
                }}
                value={layout && layout.secondaryInformation ? layout.secondaryInformation : ''}
                invalid={false}
              />
            </FormGroup>
          </Column>
        </Row>
        <Row
          style={{
            marginTop: 10,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
          }}
        >
          <Column style={{ marginRight: 20 }}>
            <FormGroup>
              <label>
                Ícone
              </label>
              <Row style={{ alignItems: 'flex-start' }}>
                <Imagem invalid={false}>
                  {layout && layout.icon ? (
                    <Image
                      className="success-img"
                      src={layout.icon}
                      alt="imagem"
                    />
                  ) : (
                    <BannerEmpty
                      invalid={false}
                    >
                      Sem ícone de visualização
                    </BannerEmpty>
                  )}
                </Imagem>
                <FormGroup style={{ marginLeft: '20px' }}>
                  <label>Formatos: PNG, JPG</label>
                  <label>Dimensão recomendada: 192x192.</label>
                  {showMessageErrorMaxSizeFileIcon && (
                    <ErrorMessage>
                      Arquivo excedeu o limite de {this.getIconMaxSizeLabel()}MB.
                    </ErrorMessage>
                  )}
                  <div style={{ display: 'flex' }}>
                    <Upload
                      disabled={disabledFields}
                      label={layout && layout.icon ? 'ALTERAR' : 'ADICIONAR'}
                      onUpload={this.uploadFileIcon}
                      accept="image/png,image/jpg,image/jpeg"
                      maxSize={this.getIconMaxSize()}
                      onRejectMaxSize={() => {
                        this.setState({ showMessageErrorMaxSizeFileIcon: true });
                      }}
                      onClick={() => {
                        this.setState({ showMessageErrorMaxSizeFileIcon: false });
                      }}
                      style={{ margin: 0 }}
                    />
                    {layout && layout.icon && (
                      <Button
                        disabled={disabledFields}
                        style={{ marginLeft: 20 }}
                        title="REMOVER"
                        onClick={() => {
                          this.onChangeDataLayout({
                            ...layout,
                            icon: '',
                          });
                        }}
                      />
                    )}
                  </div>
                </FormGroup>
              </Row>
            </FormGroup>
          </Column>
          <Column>
            <FormGroup>
              <label>
                Banner
              </label>
              <Row style={{ alignItems: 'flex-start' }}>
                <SuccessHeaderImagem invalid={false}>
                  {layout.header && layout.header.image ? (
                    <Image
                      className="success-img"
                      src={layout.header.image}
                      alt="imagem"
                    />
                  ) : (
                    <BannerEmpty
                      invalid={false}
                    >
                      Sem banner de visualização
                    </BannerEmpty>
                  )}
                </SuccessHeaderImagem>
                <FormGroup style={{ marginLeft: '20px' }}>
                  <label>Formatos: PNG, JPG</label>
                  <label>{`Dimensão recomendada: ${bannerResolution.width}x${bannerResolution.height}`}</label>
                  {showMessageErrorMaxSizeFileHeaderImage && (
                    <ErrorMessage>
                      Arquivo excedeu o limite de {this.getBannerMaxSizeLabel()}MB.
                    </ErrorMessage>
                  )}
                  {showMessageErrorResolutionBannerInvalid && (
                    <ErrorMessage>
                      {`Arquivo não atendeu as dimensões recomendadas de ${bannerResolution.width}x${bannerResolution.height}`}
                    </ErrorMessage>
                  )}
                  <div style={{ display: 'flex' }}>
                    <UploadImage
                      disabled={disabledFields}
                      label={layout.header && layout.header.image ? 'ALTERAR' : 'ADICIONAR'}
                      onUpload={this.uploadFileHeaderImage}
                      accept="image/png,image/jpg,image/jpeg"
                      maxSize={this.getBannerMaxSize()}
                      resolution={bannerResolution}
                      onRejectResolution={() => {
                        this.setState({ showMessageErrorResolutionBannerInvalid: true });
                      }}
                      onRejectMaxSize={() => {
                        this.setState({ showMessageErrorMaxSizeFileHeaderImage: true });
                      }}
                      onClick={() => {
                        this.setState({ showMessageErrorMaxSizeFileHeaderImage: false, showMessageErrorResolutionBannerInvalid: false });
                      }}
                      style={{ margin: 0 }}
                    />
                    {layout.header && layout.header.image && (
                      <Button
                        disabled={disabledFields}
                        style={{ marginLeft: 20 }}
                        title="REMOVER"
                        onClick={() => {
                          this.onChangeDataLayout({
                            ...layout,
                            header: {
                              ...layout.header,
                              image: '',
                            },
                          });
                        }}
                      />
                    )}
                  </div>
                </FormGroup>
              </Row>
            </FormGroup>
          </Column>
        </Row>
      </>
    );
  };

  render() {
    return (
      <Container>
        {this.renderForm()}
      </Container>
    );
  }
}

TabLayout.defaultProps = {
  fileSizeConfigData: null,
};

TabLayout.propTypes = {
  campaign: PropTypes.shape().isRequired,
  content: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  changeData: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
  fileSizeConfigData: PropTypes.shape(),
};

const mapStateToProps = state => ({
  fileSizeConfigData: state.campaignEdit.fileSizeConfigData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(TabLayout));
