import React from 'react';
import PropTypes from 'prop-types';

import { FormGroup, Table } from '../../styles';

import InputCreativeAdvanced from '../../Components/InputCreativeAdvanced';

const SmartMessageMenuTimForm = ({
  criativoEdit,
  campaignEditCriativoRequestSuccess,
  formConfig,
  hasError,
  disabledFields,
}) => {
  const { content, contentVersion } = criativoEdit;

  const getScreen1Validation = () => {
    if (formConfig.screen1.required && !content.screen1) {
      return true;
    }
    if ((content.screen1 || '').length < formConfig.screen1.minLength) {
      return true;
    }
    if ((content.screen1 || '').length > formConfig.screen1.maxLength) {
      return true;
    }
    return false;
  };

  const getScreen2Option1Validation = () => {
    const option1 = content.screen2 && content.screen2.option1 ? content.screen2.option1 : '';
    if (formConfig.screen2.options.option1.required && !option1) {
      return true;
    }
    if (option1.length < formConfig.screen2.options.option1.minLength) {
      return true;
    }
    if (option1.length > formConfig.screen2.options.option1.maxLength) {
      return true;
    }
    return false;
  };

  const getScreen2Option2Validation = () => {
    const option2 = content.screen2 && content.screen2.option2 ? content.screen2.option2 : '';
    if (formConfig.screen2.options.option2.required && !option2) {
      return true;
    }
    if (option2.length < formConfig.screen2.options.option2.minLength) {
      return true;
    }
    if (option2.length > formConfig.screen2.options.option2.maxLength) {
      return true;
    }
    return false;
  };

  const getScreen2Option3Validation = () => {
    const option3 = content.screen2 && content.screen2.option3 ? content.screen2.option3 : '';
    if (formConfig.screen2.options.option3.required && !option3) {
      return true;
    }
    if (option3.length < formConfig.screen2.options.option3.minLength) {
      return true;
    }
    if (option3.length > formConfig.screen2.options.option3.maxLength) {
      return true;
    }
    return false;
  };

  const getScreen3Validation = () => {
    if (formConfig.screen3.required && !content.screen3) {
      return true;
    }
    if ((content.screen3 || '').length < formConfig.screen3.minLength) {
      return true;
    }
    if ((content.screen3 || '').length > formConfig.screen3.maxLength) {
      return true;
    }
    return false;
  };

  const getValueOption = (config, c, screen, option) => {
    if (config[screen].options[option].disabled) {
      return config[screen].options[option].value;
    }
    return c[screen][option] || '';
  };

  const getMaxLengthInput = (screenId) => {
    const { maxLength, valueSuffix, valuePrefix } = formConfig[screenId];
    if (contentVersion > 1) {
      return maxLength
        - ((valueSuffix ? valueSuffix.length : 0) + (valuePrefix ? valuePrefix.length : 0));
    }
    return maxLength;
  };

  const getLengthValue = (screenId) => {
    const value = content[screenId];
    if (contentVersion > 1) {
      const { valueSuffix, valuePrefix } = formConfig[screenId];
      return (
        (value ? value.length : 0)
        + (valueSuffix ? valueSuffix.length : 0)
        + (valuePrefix ? valuePrefix.length : 0)
      );
    }
    return value ? value.length : 0;
  };

  return (
    <>
      <FormGroup>
        <label>
          {formConfig.screen1.label}
          {' - '}
          <small>
            {`
              ${getLengthValue('screen1')}
              /
              ${formConfig.screen1.maxLength} caracteres
            `}
          </small>
        </label>
        <InputCreativeAdvanced
          disabled={disabledFields || formConfig.screen1.disabled}
          maxLength={getMaxLengthInput('screen1')}
          style={{ marginRight: 0 }}
          placeholder={formConfig.screen1.placeholder}
          valueSuffix={formConfig.screen1.valueSuffix}
          valuePrefix={formConfig.screen1.valuePrefix}
          contentVersion={contentVersion}
          value={formConfig.screen1.disabled ? formConfig.screen1.value : content.screen1}
          onChange={(e) => {
            campaignEditCriativoRequestSuccess({
              ...criativoEdit,
              content: {
                ...criativoEdit.content,
                screen1: e.target.value,
              },
            });
          }}
          invalid={hasError && getScreen1Validation()}
        />
      </FormGroup>
      <FormGroup>
        <label>
          {formConfig.screen2.label}
          {' - '}
        </label>
        <Table>
          <tbody>
            <tr>
              <td width="20%" align="center">
                <span>{formConfig.screen2.options.option1.label}</span>
              </td>
              <td>
                <InputCreativeAdvanced
                  disabled={disabledFields || formConfig.screen2.options.option1.disabled}
                  maxLength={formConfig.screen2.options.option1.maxLength}
                  type="text"
                  style={{ width: '100%' }}
                  contentVersion={contentVersion}
                  placeholder={formConfig.screen2.options.option1.placeholder}
                  value={getValueOption(formConfig, content, 'screen2', 'option1')}
                  valueSuffix={formConfig.screen2.options.option1.valueSuffix}
                  valuePrefix={formConfig.screen2.options.option1.valuePrefix}
                  onChange={(e) => {
                    campaignEditCriativoRequestSuccess({
                      ...criativoEdit,
                      content: {
                        ...criativoEdit.content,
                        screen2: {
                          ...criativoEdit.content.screen2,
                          option1: e.target.value,
                        },
                      },
                    });
                  }}
                  invalid={hasError && getScreen2Option1Validation()}
                />
              </td>
              <td width="200" align="center">
                <span>
                  {`
                    ${content.screen2 && content.screen2.option1 ? content.screen2.option1.length : 0}
                    /
                    ${formConfig.screen2.options.option1.maxLength} caracteres
                  `}
                </span>
              </td>
            </tr>
            <tr>
              <td width="20%" align="center">
                <span>{formConfig.screen2.options.option2.label}</span>
              </td>
              <td>
                <InputCreativeAdvanced
                  disabled={disabledFields || formConfig.screen2.options.option2.disabled}
                  maxLength={formConfig.screen2.options.option2.maxLength}
                  type="text"
                  style={{ width: '100%' }}
                  contentVersion={contentVersion}
                  placeholder={formConfig.screen2.options.option2.placeholder}
                  value={getValueOption(formConfig, content, 'screen2', 'option2')}
                  valueSuffix={formConfig.screen2.options.option2.valueSuffix}
                  valuePrefix={formConfig.screen2.options.option2.valuePrefix}
                  onChange={(e) => {
                    campaignEditCriativoRequestSuccess({
                      ...criativoEdit,
                      content: {
                        ...criativoEdit.content,
                        screen2: {
                          ...criativoEdit.content.screen2,
                          option2: e.target.value,
                        },
                      },
                    });
                  }}
                  invalid={hasError && getScreen2Option2Validation()}
                />
              </td>
              <td width="200" align="center">
                <span>
                  {`
                    ${content.screen2 && content.screen2.option2 ? content.screen2.option2.length : 0}
                    /
                    ${formConfig.screen2.options.option2.maxLength} caracteres
                  `}
                </span>
              </td>
            </tr>
            <tr>
              <td width="20%" align="center">
                <span>{formConfig.screen2.options.option3.label}</span>
              </td>
              <td>
                <InputCreativeAdvanced
                  disabled={disabledFields || formConfig.screen2.options.option3.disabled}
                  maxLength={formConfig.screen2.options.option3.maxLength}
                  type="text"
                  style={{ width: '100%' }}
                  contentVersion={contentVersion}
                  placeholder={formConfig.screen2.options.option3.placeholder}
                  value={getValueOption(formConfig, content, 'screen2', 'option3')}
                  valueSuffix={formConfig.screen2.options.option3.valueSuffix}
                  valuePrefix={formConfig.screen2.options.option3.valuePrefix}
                  onChange={(e) => {
                    campaignEditCriativoRequestSuccess({
                      ...criativoEdit,
                      content: {
                        ...criativoEdit.content,
                        screen2: {
                          ...criativoEdit.content.screen2,
                          option3: e.target.value,
                        },
                      },
                    });
                  }}
                  invalid={hasError && getScreen2Option3Validation()}
                />
              </td>
              <td width="200" align="center">
                <span>
                  {`
                    ${content.screen2 && content.screen2.option3 ? content.screen2.option3.length : 0}
                    /
                    ${formConfig.screen2.options.option3.maxLength} caracteres
                  `}
                </span>
              </td>
            </tr>
          </tbody>
        </Table>
      </FormGroup>
      <FormGroup>
        <label>
          {formConfig.screen3.label || 'Tela 3'}
          {' - '}
          <small>
            {`
              ${getLengthValue('screen3')}
              /
              ${formConfig.screen3.maxLength} caracteres
            `}
          </small>
        </label>
        <InputCreativeAdvanced
          disabled={disabledFields || formConfig.screen3.disabled}
          maxLength={getMaxLengthInput('screen3')}
          type="text"
          style={{ marginRight: 0 }}
          contentVersion={contentVersion}
          placeholder={formConfig.screen3.placeholder}
          value={formConfig.screen3.disabled ? formConfig.screen3.value : content.screen3}
          valueSuffix={formConfig.screen3.valueSuffix}
          valuePrefix={formConfig.screen3.valuePrefix}
          onChange={(e) => {
            campaignEditCriativoRequestSuccess({
              ...criativoEdit,
              content: {
                ...criativoEdit.content,
                screen3: e.target.value,
              },
            });
          }}
          invalid={hasError && getScreen3Validation()}
        />
      </FormGroup>
    </>
  );
};

SmartMessageMenuTimForm.propTypes = {
  criativoEdit: PropTypes.shape().isRequired,
  formConfig: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

export default SmartMessageMenuTimForm;
