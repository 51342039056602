import styled, { css } from 'styled-components';

import { transparentize } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ChildrenData = styled.div`
  margin-left: 40px;
`;

export const Item = styled.div`
  display: flex;
  margin: 2px 0;
  height: 50px;

  .base-info {
    flex: 1;
    padding: 0 10px;
    border: 2px solid #ccc;
    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;

    .base-info-title {
      display: flex;
      align-items: center;
    }

    &:hover {
      background-color: ${props => transparentize(0.8, props.theme.colors.primary)};
      border-color: ${props => props.theme.colors.primary};
    }

  }
  ${props => props.selected && css`
    .base-info {
      background-color: ${transparentize(0.6, props.theme.colors.primary)};
      border-color: ${props.theme.colors.primary};
    }
  `}
`;

export const ButtonShowData = styled.div`
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
`;

export const NoShowData = styled.div`
  width: 40px;
`;

export const BaseIcon = styled.div`
  margin-right: 20px;
`;

export const Title = styled.p`
  font-size: 20px;
  margin-bottom: 0;
  /* font-weight: bold; */
  color: ${props => props.theme.colors.dark};
`;

export const Subtitle = styled.p`
  margin-bottom: 0;
  color: ${props => props.theme.colors.regular};
`;

export const BaseInfo = styled.div``;
