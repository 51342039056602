import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

import { RowStyled, FormGroupStyled } from '~/Components/Styled';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 20px;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  > label {
    margin-right: 10px;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormGroup = styled(FormGroupStyled)`
  flex: 1;
  margin: 0;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
  .editor-rich {
    border: 2px solid ${props => props.theme.colors.light};
  }
  .invalid-editor {
    border: 2px solid ${props => props.theme.colors.danger2};
  }
  .DraftEditor-editorContainer {
    .public-DraftEditor-content {
      max-height: 109px;
      height: 109px;
    }
  }
`;

export const Questions = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const LabelQuestion = styled.div`
  font-weight: bold;
  color: ${props => props.theme.colors.dark};
  margin-top: 10px;
  margin-bottom: 5px;
`;

export const NewItem = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const AddNewItem = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;

  ${props => props.disabled && css`
    opacity: 0.7;
  `}

  > a {
    display: flex;
    color: ${props => props.theme.colors.primary};
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
    border: 2px solid ${props => props.theme.colors.primary};
    border-radius: 5px;
    text-decoration: none;
    width: 280px;
    justify-content: center;

    > span {
      margin-top: 0;
      padding-left: 5px;
      color: ${props => props.theme.colors.primary};
    }
  }
`;

export const ModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const ModalWrapBody = styled.div`
  display: flex;
  flex-direction: row;
`;

export const ModalPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  margin-left: 20px;
`;

export const ModalPreviewTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;
`;

export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 580px;
  overflow-y: auto;
  padding: 0 20px;
`;

export const ModalFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
`;

export const AddItemsWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  border-radius: 5px;
  padding: 5px;
  span {
    font-size: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 5px;
    color: ${props => props.theme.colors.white};
    svg {
      font-size: 16px;
      margin-left: 5px;
      margin-right: 10px;
    }
  }
`;

export const GroupButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 1px solid ${props => props.theme.colors.white};
  padding: 5px;
`;

export const ButtonInsert = styled.button.attrs({ type: 'button' })`
  border: 0;
  background-color: transparent;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.white};
  font-size: 20px;
  outline: none;
`;

export const MessageForm = styled.b`
  color: ${props => props.theme.colors.dark};
  font-size: 18px;
`;

export const ButtonEditForm = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.primary};
  border-right: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 330px;
  height: 65px;
  font-size: 20px;
  svg {
    margin-left: 10px;
  }
  margin: 0;
`;

export const ButtonViewForm = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.primary};
  width: 330px;
  height: 65px;
  font-size: 20px;
  svg {
    margin-left: 10px;
  }
  margin: 0;
`;

export const ButtonDeteleForm = styled.button.attrs({ type: 'button' })`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.danger2};
  color: ${props => props.theme.colors.danger2};
  border-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  width: 65px;
  height: 65px;
  font-size: 20px;
  margin: 0;
`;

export const GroupActionsButton = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Select = styled(ReactSelect)`
  width: 450px;
  > div {
    border-width: 2px !important;
  }
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const SelectPosition = styled(ReactSelect)`
  width: 200px;
  margin-left: 10px;
  > div {
    border-width: 2px !important;
  }
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;
