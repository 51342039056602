import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  FaTimes,
} from 'react-icons/fa';

import { withTheme } from 'styled-components';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Creators as BehaviorActions } from '~/redux/ducks/behavior';

import {
  Modal, ContentModal, Header, Body, CloseHeader, ContainerItensType, ItemType, CloseItemSelected,
  ItemSelected, Left, Right,
} from './styles';

import FormTypeCampaign from './components/FormTypeCampaign';

import BehaviorIconType from '../components/BehaviorIconType';

const INNITIAL_DATA_STYLE = {
  left: 0,
  top: 0,
  width: 0,
  height: 0,
};

class NewModal extends Component {
  state = {
    elementAnimation: INNITIAL_DATA_STYLE,
    originalStyle: INNITIAL_DATA_STYLE,
    modalWidth: 750,
    modalHeight: 510,
    selectedItem: null,
    showForm: false,
  };

  componentDidMount() {
    this.handleResetState();
  }

  componentWillUnmount() {
    this.handleResetState();
  }

  handleResetState = () => {
    this.setState({
      elementAnimation: INNITIAL_DATA_STYLE,
      originalStyle: INNITIAL_DATA_STYLE,
      modalWidth: 750,
      modalHeight: 510,
      selectedItem: null,
      showForm: false,
    });
  };

  renderHeader = () => {
    const { theme, closeNewBehaviorModal } = this.props;
    return (
      <Header>
        <h1>Novo comportamento</h1>
        <CloseHeader onClick={() => {
          this.handleResetState();
          closeNewBehaviorModal();
        }}
        >
          <FaTimes color={theme.colors.darker} size={24} />
        </CloseHeader>
      </Header>
    );
  };

  handleAnimate = () => {
    this.setState({
      modalWidth: 1000,
      modalHeight: 586,
      elementAnimation: {
        left: 25,
        top: 0,
        width: 335,
        height: 500,
      },
    }, () => setTimeout(() => {
      this.setState({
        showForm: true,
      });
    }, 800));
  };

  renderContent = () => {
    const { eventTypes, setTypeSelected, theme } = this.props;
    const {
      modalHeight,
      elementAnimation,
      selectedItem,
      showForm,
      originalStyle,
    } = this.state;
    return (
      <Body height={modalHeight}>
        {!selectedItem && (
          <ContainerItensType itemsLength={eventTypes.length} height={modalHeight}>
            <div className="base-itens">
              {eventTypes.map(type => (
                <ItemType
                  key={type.uuid}
                  onClick={async (e) => {
                    const boundsButton = e.target.getBoundingClientRect();
                    const dataStyle = {
                      left: e.target.offsetLeft,
                      top: e.target.offsetTop,
                      width: boundsButton.width,
                      height: 150,
                    };
                    this.setState({
                      selectedItem: type,
                      elementAnimation: dataStyle,
                      originalStyle: dataStyle,
                    }, () => {
                      setTypeSelected(type);
                      setTimeout(this.handleAnimate, 50);
                    });
                  }
            }
                >
                  <h1>{type.description}</h1>
                  <BehaviorIconType eventType={type} showTooltip={false} height={100} />
                </ItemType>
              ))}
            </div>
          </ContainerItensType>
        )}
        {!!selectedItem && (
          <ItemSelected height={modalHeight}>
            <Left
              style={{
                ...elementAnimation,
              }}
            >
              <CloseItemSelected
                onClick={() => {
                  this.setState({
                    modalWidth: 750,
                    modalHeight: 510,
                    elementAnimation: {
                      left: originalStyle.left,
                      top: originalStyle.top,
                      width: originalStyle.width,
                      height: originalStyle.height,
                    },
                    showForm: false,
                  }, () => setTimeout(() => {
                    setTypeSelected(null);
                    this.handleResetState();
                  }, 800));
                }}
              >
                <FaTimes color={theme.colors.darker} size={24} />
              </CloseItemSelected>

              <div className="body">
                <h1>{selectedItem.description}</h1>
                <BehaviorIconType eventType={selectedItem} showTooltip={false} height={200} />
              </div>
            </Left>
            {!!showForm && (
              <Right>
                {selectedItem.name === 'QUIZ' && <FormTypeCampaign />}
                {/* {typeSelected.name === 'CONVERSION' && null} */}
              </Right>
            )}
          </ItemSelected>
        )}
      </Body>
    );
  }

  render() {
    const { newBehaviorModal: { show } } = this.props;
    const {
      modalWidth,
      modalHeight,
    } = this.state;
    if (!show) return null;
    return (
      <Modal
        width={modalWidth}
        minHeight={modalHeight}
        height={modalHeight}
        padding={0}
        scroll={false}
      >
        <ContentModal height={modalHeight} width={modalWidth}>
          {this.renderHeader()}
          {this.renderContent()}
        </ContentModal>
      </Modal>
    );
  }
}

NewModal.defaultProps = {
  eventTypes: [],
};

NewModal.propTypes = {
  theme: PropTypes.shape().isRequired,
  newBehaviorModal: PropTypes.shape().isRequired,
  eventTypes: PropTypes.arrayOf(PropTypes.shape()),
  closeNewBehaviorModal: PropTypes.func.isRequired,
  setTypeSelected: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  eventTypes: state.behavior.eventTypes,
  newBehaviorModal: state.behavior.newBehaviorModal,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    navigateToRoute: RedirectActions.navigateToRoute,
    closeNewBehaviorModal: BehaviorActions.closeNewBehaviorModal,
    setTypeSelected: BehaviorActions.setTypeSelectedNewBehaviorModal,
    onUpdateBehavior: BehaviorActions.onUpdateBehaviorNewBehaviorModal,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(NewModal));
