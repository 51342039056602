import api from './axios';

const termsRequest = async () => {
  try {
    const { data } = await api.get('terms-service/terms-and-conditions');
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

const termsRequestv2 = async () => {
  try {
    const { data } = await api.get('terms-service/terms-and-conditions', {
      responseType: 'blob'
    });
    return data;
  } catch (error) {
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  termsRequest,
  termsRequestv2
};
