import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FaChevronRight, FaChevronLeft } from 'react-icons/fa';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  // Container,
  ControlPreview,
  ControlButton,
  ControlTitle,
  ContentPreview,
  MidiaVideoPreview,
  PreviewVastType,
  Box,
  BoxTitle,
  BoxContent,
  ItemPaginator,
} from './styles';

class MidiaNoCreditADPreview extends Component {
  state = {
    page: 0,
  };

  componentDidMount() {}

  renderControlPreview = () => {
    const { data: { content } } = this.props;
    const listMedia = content.mainData.media;
    const { page } = this.state;

    const nextPage = () => {
      this.setState({ page: page + 1 });
    };

    const previousPage = () => {
      let newPage = page - 1;
      if (page >= listMedia.length - 1) {
        newPage = (listMedia.length - 1) - 1;
      }
      this.setState({ page: newPage });
    };

    const media = listMedia[page] || listMedia[listMedia.length - 1];

    return (
      <ControlPreview>
        <ControlButton
          disabled={listMedia.length <= 1 || page === 0}
          onClick={() => previousPage()}
        >
          <FaChevronLeft />
        </ControlButton>
        <ControlTitle>
          {listMedia.map(item => (
            <ItemPaginator
              key={item.uuid}
              active={media.uuid === item.uuid}
            />
          ))}
        </ControlTitle>
        <ControlButton disabled={page >= listMedia.length - 1} onClick={() => nextPage()}>
          <FaChevronRight />
        </ControlButton>
      </ControlPreview>
    );
  };

  renderVideo = media => (
    <ContentPreview>
      <p>{media.title || 'Mídia'}</p>
      {!!media.content && (
        <MidiaVideoPreview controls>
          <source src={media.content} type="video/mp4" />
          Seu browser não suporta o preview de vídeos
        </MidiaVideoPreview>
      )}
    </ContentPreview>
  );

  renderVast = media => (
    <PreviewVastType>
      <p>{media.title || 'Mídia'}</p>
      <Box>
        <BoxTitle>
          <span>Sem Preview</span>
        </BoxTitle>
        <BoxContent>
          <p>Esse tipo de Criativo não tem visualização de preview.</p>
          <div>
            <b>Tipo: </b>
            Vast
          </div>
        </BoxContent>
      </Box>
    </PreviewVastType>
  );

  renderProgramatica = media => (
    <PreviewVastType>
      <p>{media.title || 'Mídia'}</p>
      <Box>
        <BoxTitle>
          <span>Sem Preview</span>
        </BoxTitle>
        <BoxContent>
          <p>Esse tipo de Criativo não tem visualização de preview.</p>
          <div>
            <b>Tipo: </b>
            Programática
          </div>
        </BoxContent>
      </Box>
    </PreviewVastType>
  );

  renderPageFromMedia = (media) => {
    switch (media.type) {
      case 'video': return this.renderVideo(media);
      case 'vast': return this.renderVast(media);
      case 'programatica': return this.renderProgramatica(media);
      default: return null;
    }
  };

  render() {
    const { data: { content } } = this.props;
    const { page } = this.state;

    if (
      !content
      || !content.mainData
      || !content.mainData.media
      || content.mainData.media.length === 0
    ) return null;
    const listMedia = content.mainData.media;

    const media = listMedia[page] || listMedia[listMedia.length - 1];

    return (
      <>
        {this.renderControlPreview()}
        {this.renderPageFromMedia(media)}
      </>
    );
  }
}

MidiaNoCreditADPreview.propTypes = {
  data: PropTypes.shape().isRequired,
};

const mapStateToProps = (/* state */) => ({});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MidiaNoCreditADPreview);
