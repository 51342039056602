import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

import Modal from '~/Components/Modal';
import {
  Row,
  TitleItemExcluse,
  ButtonCloseModal,
  Button,
} from './styles';
import { Creators as WarningActions } from '~/redux/ducks/warning';

const WarningAction = ({
  warnings,
  warningAcceptRequest,
  warningRefuseRequest,
  warningSetupRequestSuccess,
}) => (warnings.length > 0 ? (
  <Modal width={450}>
    <Row>
      <h1>{warnings[0].title}</h1>
      <ButtonCloseModal
        type="button"
        onClick={() => {
          warningSetupRequestSuccess(
            warnings.filter((item, index) => index > 0),
          );
        }}
      >
        <FaTimes size={30} />
      </ButtonCloseModal>
    </Row>
    <Row>
      <TitleItemExcluse>{warnings[0].body}</TitleItemExcluse>
    </Row>
    <Row style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
      <Button
        title="Rejeitar"
        bordered
        onClick={() => {
          warningRefuseRequest();
        }}
      />
      <Button
        title="Aceitar"
        onClick={() => {
          warningAcceptRequest();
        }}
      />
    </Row>
  </Modal>
) : null);

const mapStateToProps = state => ({
  warnings: state.warning.warnings,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    warningAcceptRequest: WarningActions.warningAcceptRequest,
    warningRefuseRequest: WarningActions.warningRefuseRequest,
    warningSetupRequestSuccess: WarningActions.warningSetupRequestSuccess,
  },
  dispatch,
);

WarningAction.propTypes = {
  warnings: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  warningAcceptRequest: PropTypes.func.isRequired,
  warningRefuseRequest: PropTypes.func.isRequired,
  warningSetupRequestSuccess: PropTypes.func.isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WarningAction);
