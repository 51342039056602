import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  Row,
  RowFooter,
  ButtonCloseModal,
  FormGroup,
  Textarea,
} from './styles';

class ModalEditCriativo extends Component {
  static propTypes = {
    modalEdit: PropTypes.shape().isRequired,
    campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
  };

  componentDidMount() {}

  render() {
    const {
      modalEdit,
      campaignEditChangeModalEditStatus,
    } = this.props;

    return modalEdit.editCriativoStatus && (
      <Modal width={500}>
        <Row>
          <h1>Alterar Criativo</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              campaignEditChangeModalEditStatus({
                editCriativoStatus: false,
              });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        <FormGroup>
          <label>
            <b>Frase Principal: </b>
          </label>
          <Textarea rows="5" placeholder="Frase Principal" />
        </FormGroup>
        <RowFooter>
          <Button
            title="SALVAR"
            onClick={() => {}}
          />
        </RowFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modalEdit: state.campaignEdit.modalEdit,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditCriativo);
