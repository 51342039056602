import styled from 'styled-components';


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  @media (max-width: 1148px) {
    flex: 1;
  }
`;

export const ActualData = styled.div`
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 2px;
  padding: 5px;
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  min-width: 400px;
`;

export const ActualDataItem = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  h4 {
    padding-left: 5px;
    margin-bottom: 5px;
  }

  svg {
    font-size: 25px;
    color: ${props => props.theme.colors.primary};
    margin-right: 10px;
  }

  .desc-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 10px 0 0;
    p {
      font-size: 13px;
      margin-bottom: -5px;
      font-weight: bold;
    }
    span {
      font-size: 18px;
      min-height: 25px;
    }
  }
`;
