import styled from 'styled-components';
import {
  RowStyled,
  TableStyled,
  FormGroupStyled,
} from '~/Components/Styled';


export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .header-campaign {
    display: flex;
    flex: 1;
    align-items: center;
    height: 50px;
    h2 {
      margin: 0;
      padding: 0;
    }
  }
  .header-chanel {
    display: flex;
    align-items: center;
    height: 50px;
    h2 {
      margin: 0;
      padding: 0;
    }
  }
  .header-action {
    display: flex;
    align-items: center;
    height: 50px;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const ModalWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 800px;
`;

export const RowHeader = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 15px 0 15px;
  border-bottom: 1px solid ${props => props.theme.colors.lighter};
  h2 {
    color: ${props => props.theme.colors.dark};
    margin-bottom: 10px;
  }
  > div {
    margin-bottom: 5px;
  }
`;

export const RowContent = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow-y: auto;
  padding: 0 20px 20px 20px;
  h2 {
    color: ${props => props.theme.colors.dark};
    margin-bottom: 10px;
  }
  .campaign {
    margin-top: 10px;
  }
`;

export const BaseCost = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 10px;
  border: solid 2px ${props => props.theme.colors.light};
  &.full {
    padding: 0px;
    border: none;
    flex: 1;
  }
`;

export const Cost = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 25px;
    margin-right: 10px;
  }

  .desc-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    p {
      font-size: 13px;
      margin-bottom: -5px;
      font-weight: bold;
    }
    span {
      font-size: 18px;
    }
  }

  padding: 0 10px;

  border-right: solid 1px ${props => props.theme.colors.light};

  &:last-child {
    border-right: none;
  }

  &.full {
    flex: 1;
    justify-content: center;

    img {
      height: 25px;
      margin-right: 10px;
    }
  }
`;

export const Img = styled.img`
  &.push {
    width: 35px;
    margin-left: 15px;
  }
  &.alvo {
    width: 20px;
    margin-right: 15px;
  }
`;

export const Card = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: solid 2px ${props => props.theme.colors.light};
  padding: 10px 10px;
  border-radius: 2px;
  margin-right: 15px;

  input {
    margin-right: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  .card-value {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
    color: ${props => props.theme.colors.primary};
  }
  label {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 0;
    color: ${props => props.theme.colors.primary};
  }
  span {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
  }
  .center {
    align-items: center;
  }
  img {
    height: 30px;
    margin-right: 10px;
  }

  > svg {
    color: ${props => props.theme.colors.primary};
    font-size: 30px;
    margin-right: 10px;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  > label {
    text-align: center;
  }
`;

export const Table = styled(TableStyled)`
  thead tr th {
    font-size: 16px;
  }
  tbody tr {
    border-bottom: solid 1px ${props => props.theme.colors.light};
    &:last-child {
      border-bottom: none;
    }
  }
`;
