import { call, put, select } from 'redux-saga/effects';
import lodash from 'lodash';

import GridPeriodService from '~/services/GridPeriodService';
import { Creators as GridPeriodActions } from '~/redux/ducks/gridPeriod';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* gridPeriodSetup() {
  // Start loading
  yield put(startRequest());
  try {
    // GET Channels
    const channelList = yield call(GridPeriodService.channelRequest);
    const channelZonesList = [];
    channelList.forEach((channel) => {
      channel.zones.forEach((zone) => {
        channelZonesList.push({
          value: zone.uuid,
          label: `${channel.name} (${zone.name})`,
          chooseSender: zone.chooseSender,
        });
      });
    });
    yield put(GridPeriodActions.gridPeriodChannelRequestSuccess([
      {
        value: null,
        label: 'Todos',
      },
      ...channelZonesList,
    ]));
    yield put(GridPeriodActions.gridPeriodSetFilter({
      channel: channelZonesList[0],
    }));
    // GET Senders
    const senderList = yield call(GridPeriodService.senderRequest);
    yield put(GridPeriodActions.gridPeriodSendersRequestSuccess(senderList));

    // GET Periods
    let periodList = yield call(GridPeriodService.periodRequest);
    periodList = periodList.map(item => ({
      data: {
        ...item,
        // gridOpen: false,
      },
      value: item.uuid,
      label: `${item.label} ${item.gridOpen ? '(aberta)' : ''}`,
    }));
    yield put(GridPeriodActions.gridPeriodPeriodRequestSuccess(periodList));
    yield put(GridPeriodActions.gridPeriodSetFilter({
      period: periodList[0],
    }));
    // Stop loading
    yield put(requestSuccess());
    // Load Auctions
    yield put(GridPeriodActions.gridPeriodRequest());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* gridPeriodRequest() {
  const { gridPeriodFilter } = yield select(state => state.gridPeriod);
  try {
    if (gridPeriodFilter.period /* && gridPeriodFilter.channel */) {
      // Start loading
      yield put(startRequest());
      // GET Auctions
      const gridPeriodList = yield call(
        GridPeriodService.gridPeriodRequest,
        gridPeriodFilter.period.value || '',
        gridPeriodFilter.channel.value || '',
      );
      // Set grid period success
      if (Array.isArray(gridPeriodList)) {
        yield put(
          GridPeriodActions.gridPeriodRequestSuccess(gridPeriodList.map(channel => ({
            ...channel,
            grids: channel.grids.map(grid => ({
              ...grid,
              gridItems: grid.gridItems.map((gridItem) => {
                const dailyDistributionBefore = [
                  0,
                  (typeof (gridItem.dailyDistributionBefore[1]) !== 'undefined' && gridItem.dailyDistributionBefore[1] !== null) ? gridItem.dailyDistributionBefore[1] : 0,
                  (typeof (gridItem.dailyDistributionBefore[2]) !== 'undefined' && gridItem.dailyDistributionBefore[2] !== null) ? gridItem.dailyDistributionBefore[2] : 0,
                  (typeof (gridItem.dailyDistributionBefore[3]) !== 'undefined' && gridItem.dailyDistributionBefore[3] !== null) ? gridItem.dailyDistributionBefore[3] : 0,
                  (typeof (gridItem.dailyDistributionBefore[4]) !== 'undefined' && gridItem.dailyDistributionBefore[4] !== null) ? gridItem.dailyDistributionBefore[4] : 0,
                  (typeof (gridItem.dailyDistributionBefore[5]) !== 'undefined' && gridItem.dailyDistributionBefore[5] !== null) ? gridItem.dailyDistributionBefore[5] : 0,
                  (typeof (gridItem.dailyDistributionBefore[6]) !== 'undefined' && gridItem.dailyDistributionBefore[6] !== null) ? gridItem.dailyDistributionBefore[6] : 0,
                ];
                return {
                  ...gridItem,
                  volume: gridItem.volume
                    || gridItem.dailyDistributionBefore.reduce(
                      (accumulator, currentValue) => accumulator + currentValue, 0,
                    ),
                  dailyDistributionBefore,
                };
              }),
            })),
          }))),
        );
      } else {
        yield put(
          GridPeriodActions.gridPeriodRequestSuccess({
            ...gridPeriodList,
            grids: gridPeriodList.grids.map(grid => ({
              ...grid,
              gridItems: grid.gridItems.map((gridItem) => {
                const dailyDistributionBefore = [
                  0,
                  (typeof (gridItem.dailyDistributionBefore[1]) !== 'undefined' && gridItem.dailyDistributionBefore[1] !== null) ? gridItem.dailyDistributionBefore[1] : 0,
                  (typeof (gridItem.dailyDistributionBefore[2]) !== 'undefined' && gridItem.dailyDistributionBefore[2] !== null) ? gridItem.dailyDistributionBefore[2] : 0,
                  (typeof (gridItem.dailyDistributionBefore[3]) !== 'undefined' && gridItem.dailyDistributionBefore[3] !== null) ? gridItem.dailyDistributionBefore[3] : 0,
                  (typeof (gridItem.dailyDistributionBefore[4]) !== 'undefined' && gridItem.dailyDistributionBefore[4] !== null) ? gridItem.dailyDistributionBefore[4] : 0,
                  (typeof (gridItem.dailyDistributionBefore[5]) !== 'undefined' && gridItem.dailyDistributionBefore[5] !== null) ? gridItem.dailyDistributionBefore[5] : 0,
                  (typeof (gridItem.dailyDistributionBefore[6]) !== 'undefined' && gridItem.dailyDistributionBefore[6] !== null) ? gridItem.dailyDistributionBefore[6] : 0,
                ];
                return {
                  ...gridItem,
                  volume: gridItem.volume
                    || gridItem.dailyDistributionBefore.reduce(
                      (accumulator, currentValue) => accumulator + currentValue, 0,
                    ),
                  dailyDistributionBefore,
                };
              }),
            })),
          }),
        );
        yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
      }
      // Stop Loading
      yield put(requestSuccess());
    }
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* gridPeriodLoadRemainingImpressions() {
  const { gridPeriod } = yield select(state => state.gridPeriod);
  const hasDataCpaFixo = gridPeriod.grids.find(item => item.campaignType.name === 'FIXED_CPA');
  const hasDataTeste = gridPeriod.grids.find(item => item.campaignType.name === 'PROBE');
  const hasDataBonificada = gridPeriod.grids.find(item => item.campaignType.name === 'BONUS');
  const hasDataLeilao = gridPeriod.grids.find(item => item.campaignType.name === 'AUCTION');

  let remainingImpressions = 0;

  if (hasDataCpaFixo) {
    remainingImpressions += hasDataCpaFixo.gridItems.reduce(
      (sumItems, current) => sumItems + (parseFloat(current.volume) || 0), 0,
    );
  }

  if (hasDataTeste) {
    remainingImpressions += hasDataTeste.gridItems.reduce(
      (sumItems, current) => sumItems + (parseFloat(current.volume) || 0), 0,
    );
  }

  if (hasDataBonificada) {
    remainingImpressions += hasDataBonificada.gridItems.reduce(
      (sumItems, current) => sumItems + (parseFloat(current.volume) || 0), 0,
    );
  }

  if (hasDataLeilao) {
    remainingImpressions += hasDataLeilao.gridItems.reduce(
      (sumItems, current) => {
        if (current.dailyDistributionBefore.length > 0) {
          const sumDailyDistributionBefore = current.dailyDistributionBefore.reduce(
            (accumulator, currentValue) => accumulator + currentValue, 0,
          );
          return sumItems + (parseFloat(sumDailyDistributionBefore) || 0);
        }
        return sumItems + (parseFloat(current.volume) || 0);
      }, 0,
    );
  }

  yield put(
    GridPeriodActions.gridPeriodSetRemainingImpressions(
      gridPeriod.zone.totalImpressions - remainingImpressions,
    ),
  );
}

export function* gridPeriodCampaignByFilterRequest() {
  const {
    campaignListFilter,
    productList,
    contractList,
  } = yield select(state => state.gridPeriod);
  yield put(startRequest());
  try {
    if (productList.length <= 0) {
      const products = yield call(GridPeriodService.listAllProducts);
      yield put(GridPeriodActions.gridPeriodProductRequestSuccess(products));
    }
    if (contractList.length <= 0) {
      const contracts = yield call(GridPeriodService.listContracts);
      yield put(GridPeriodActions.gridPeriodContractRequestSuccess(contracts));
    }

    const campaignList = yield call(GridPeriodService.getCampaignByFilter, campaignListFilter);
    yield put(
      GridPeriodActions.gridPeriodCampaignByFilterRequestSuccess(campaignList),
    );
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

function makeDailyDistributionBeforeByFieldConfigurations(volume, fieldConfigurations) {
  let dividerBy = 0;
  fieldConfigurations.forEach((item) => {
    dividerBy += item.distributable ? 1 : 0;
  });


  const div = parseInt(volume / dividerBy);
  let remaining = volume - (div * dividerBy);
  const dailyDistributionBefore = fieldConfigurations.map(
    (field) => {
      if (!field.distributable || dividerBy === 0) return 0;

      const res = (div + remaining);
      remaining = 0;
      return res;
    },
  );
  return dailyDistributionBefore;
}

export function* gridPeriodInsertCampaignsSelected() {
  const {
    campaignToAdd,
    campaignList,
    campaignListFilter,
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const { fieldConfigurations, zone } = gridPeriod;

  const campaingNewItems = campaignToAdd.map((item) => {
    const itemFind = campaignList.content.find(
      contentItem => contentItem.uuid === item,
    );
    const newVolume = campaignListFilter.typeName === 'BONUS' ? 500000 : itemFind.volume;

    const dailyDistributionBefore = makeDailyDistributionBeforeByFieldConfigurations(
      newVolume,
      fieldConfigurations,
    );

    let cpa = itemFind.cpa || '';
    if (campaignListFilter.typeName === 'PROBE') {
      cpa = parseFloat(cpa || 0) > zone.extra.pricing.cpa.min
        ? cpa
        : `${zone.extra.pricing.cpa.min}`;
    }

    return {
      uuid: itemFind.uuid,
      budget: itemFind.budget,
      cpa,
      ecpm: '0.0', // Falta este item do endpoint
      volume: newVolume,
      dailyDistributionBefore,
      campaign: {
        description: itemFind.name,
        name: itemFind.name,
        productOffers: itemFind.productOffers || [],
        uuid: itemFind.uuid,
        contract: itemFind.contract,
      },
    };
  });

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === campaignListFilter.typeName) {
        return {
          ...grid,
          gridItems: [
            ...grid.gridItems,
            ...campaingNewItems,
          ],
        };
      }

      return grid;
    }),
  };

  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
  yield put(GridPeriodActions.gridPeriodChangeModalStatus({
    modalBonificadas: false,
    modalBonificadasEdit: false,
    modalCpaFixo: false,
    modalTeste: false,
    modalCloseAuction: false,
  }));
  yield put(GridPeriodActions.gridPeriodCampaignByFilterRequestSuccess(null));
  yield put(GridPeriodActions.gridPeriodResetFilterModalCampaign(null));
  yield put(GridPeriodActions.gridPeriodSetCampaignToAdd([]));
}

export function* gridPeriodSaveItemInEdit() {
  const {
    gridPeriod,
    gridItemToEdit,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === 'BONUS') {
        return {
          ...grid,
          gridItems: grid.gridItems.map(
            gridItem => (gridItem.uuid === gridItemToEdit.uuid ? gridItemToEdit : gridItem),
          ),
        };
      }
      return grid;
    }),
  };

  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
  yield put(GridPeriodActions.gridPeriodChangeModalStatus({
    modalBonificadasEdit: false,
  }));
  yield put(GridPeriodActions.gridPeriodSetItemToEdit(null));
}

export function* gridPeriodDeleteItemGrid({ gridType, gridItemUuid }) {
  const {
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === gridType) {
        return {
          ...grid,
          gridItems: grid.gridItems.filter(gridItem => gridItem.uuid !== gridItemUuid),
        };
      }

      return grid;
    }),
  };
  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
}

export function* gridPeriodSaveGrid({ saveType }) {
  const {
    gridPeriod,
    gridPeriodFilter,
  } = yield select(state => state.gridPeriod);
  const saveMode = saveType || 'DRAFT';

  yield put(startRequest());
  try {
    if (saveMode === 'DRAFT') {
      const gridPeriodToSave = {
        status: saveMode,
        grids: gridPeriod.grids.map(grid => ({
          uuid: grid.uuid,
          gridItems: grid.gridItems.map(item => ({
            uuid: item.uuid,
            volume: item.volume,
            sender: item.sender,
            cpa: `${item.cpa || 0}`,
            dailyDistributionBefore: item.dailyDistributionBefore,
            campaign: {
              uuid: item.campaign.uuid,
            },
          })),
        })),
      };
      yield call(GridPeriodService.saveGridDraft, {
        gridPeriodUuid: gridPeriod.gridPeriod.uuid,
        zoneUuid: gridPeriod.zone.uuid,
        grid: gridPeriodToSave,
      });
    }
    if (saveMode === 'FINAL') {
      yield call(GridPeriodService.saveGridFinal, gridPeriodFilter.period.value);
    }
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Os dados foram cadastrados com sucesso!',
    ));
    yield put(GridPeriodActions.gridPeriodRequest());
    yield put(GridPeriodActions.gridPeriodChangeModalStatus({
      modalBonificadas: false,
      modalBonificadasEdit: false,
      modalCpaFixo: false,
      modalTeste: false,
      modalCloseAuction: false,
    }));
    yield put(GridPeriodActions.gridPeriodSetup());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao salvar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* gridPeriodChangeLeilaoGridItem({ gridItemUuid, value }) {
  const {
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === 'AUCTION') {
        return {
          ...grid,
          gridItems: grid.gridItems.map(
            gridItem => (gridItem.uuid === gridItemUuid
              ? {
                ...gridItem,
                dailyDistributionBefore: value,
                volume: value.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
              }
              : gridItem
            ),
          ),
        };
      }
      return grid;
    }),
  };
  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
}

export function* gridPeriodChangeBonificadaGridItem({ gridItemUuid, value }) {
  const {
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === 'BONUS') {
        return {
          ...grid,
          gridItems: grid.gridItems.map(
            gridItem => (gridItem.uuid === gridItemUuid
              ? {
                ...gridItem,
                dailyDistributionBefore: value,
                volume: value.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
              }
              : gridItem
            ),
          ),
        };
      }
      return grid;
    }),
  };
  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
}

export function* gridPeriodChangeCpaGridItem({ gridItemUuid, value }) {
  const {
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === 'FIXED_CPA') {
        return {
          ...grid,
          gridItems: grid.gridItems.map(
            gridItem => (gridItem.uuid === gridItemUuid
              ? {
                ...gridItem,
                dailyDistributionBefore: value,
                volume: value.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
              }
              : gridItem
            ),
          ),
        };
      }
      return grid;
    }),
  };
  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
}

export function* gridPeriodChangeTesteGridItem({ gridItemUuid, value }) {
  const {
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === 'PROBE') {
        return {
          ...grid,
          gridItems: grid.gridItems.map(
            gridItem => (gridItem.uuid === gridItemUuid
              ? {
                ...gridItem,
                dailyDistributionBefore: value,
                volume: value.reduce((accumulator, currentValue) => accumulator + currentValue, 0),
              }
              : gridItem
            ),
          ),
        };
      }
      return grid;
    }),
  };
  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
}

export function* gridPeriodChangeTesteCpa({ gridItemUuid, value }) {
  const {
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === 'PROBE') {
        return {
          ...grid,
          gridItems: grid.gridItems.map(
            gridItem => (gridItem.uuid === gridItemUuid
              ? {
                ...gridItem,
                cpa: value,
              }
              : gridItem
            ),
          ),
        };
      }
      return grid;
    }),
  };
  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
}

export function* gridPeriodChangeSender({ gridItemUuid, value }) {
  const {
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map(grid => ({
      ...grid,
      gridItems: grid.gridItems.map(
        gridItem => (gridItem.uuid === gridItemUuid
          ? {
            ...gridItem,
            sender: value,
          }
          : gridItem
        ),
      ),
    })),
  };
  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
}

export function* gridPeriodChangeCpa({ gridItemUuid, value }) {
  const {
    gridPeriod,
  } = yield select(state => state.gridPeriod);

  const gridPeriodToReplace = {
    ...gridPeriod,
    grids: gridPeriod.grids.map((grid) => {
      if (grid.campaignType.name === 'FIXED_CPA') {
        return {
          ...grid,
          gridItems: grid.gridItems.map(
            gridItem => (gridItem.uuid === gridItemUuid
              ? {
                ...gridItem,
                cpa: value,
              }
              : gridItem
            ),
          ),
        };
      }
      return grid;
    }),
  };
  yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
  yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
}

export function* gridPeriodAutomaticDistribution() {
  const { gridPeriod } = yield select(state => state.gridPeriod);
  const { fieldConfigurations } = gridPeriod;

  try {
    const hasDataCpaFixo = gridPeriod.grids.find(item => item.campaignType.name === 'FIXED_CPA');
    const hasDataTeste = gridPeriod.grids.find(item => item.campaignType.name === 'PROBE');
    const hasDataBonificada = gridPeriod.grids.find(item => item.campaignType.name === 'BONUS');

    let remainingPartialImpressions = 0;
    if (hasDataCpaFixo) {
      remainingPartialImpressions += hasDataCpaFixo.gridItems.reduce(
        (sumItems, current) => sumItems + (parseFloat(current.volume) || 0), 0,
      );
    }

    if (hasDataTeste) {
      remainingPartialImpressions += hasDataTeste.gridItems.reduce(
        (sumItems, current) => sumItems + (parseFloat(current.volume) || 0), 0,
      );
    }

    if (hasDataBonificada) {
      remainingPartialImpressions += hasDataBonificada.gridItems.reduce(
        (sumItems, current) => sumItems + (parseFloat(current.volume) || 0), 0,
      );
    }

    const remainingImpressions = gridPeriod.zone.totalImpressions - remainingPartialImpressions;

    let accumulatedGridItems = {};

    const updateAccumulatedGridItems = (accumulatedVolume) => {
      const gridItemsKeys = Object.keys(accumulatedGridItems); let
        total = accumulatedVolume;
      const volumePerGridItem = parseInt(accumulatedVolume / gridItemsKeys.length);
      for (const uuid of gridItemsKeys) {
        accumulatedGridItems[uuid] += volumePerGridItem;
        total -= volumePerGridItem;
      }
      if (total > 0 && gridItemsKeys.length > 0) { accumulatedGridItems[gridItemsKeys[0]] += total; }
    };

    const getGridItem = (gridItem, volume) => {
      const {
        budget, ecpm, cpa, conversionRate,
      } = gridItem;

      if (budget > 0 && ecpm > 0) {
        const volBudget = Math.ceil(budget / ((cpa * conversionRate) / 100));
        if (volBudget <= volume) {
          let accumulatedVolume = volume - volBudget;
          accumulatedVolume += accumulatedGridItems[gridItem.uuid];
          delete accumulatedGridItems[gridItem.uuid];
          updateAccumulatedGridItems(accumulatedVolume);
          volume = volBudget;
        } else {
          let diff = volBudget - volume;
          if (accumulatedGridItems[gridItem.uuid] > diff) {
            const accumulatedVolume = accumulatedGridItems[gridItem.uuid] - diff;
            delete accumulatedGridItems[gridItem.uuid];
            updateAccumulatedGridItems(accumulatedVolume);
          } else {
            diff = accumulatedGridItems[gridItem.uuid];
            accumulatedGridItems[gridItem.uuid] = 0;
          }
          volume += diff;
        }
      } else {
        volume += accumulatedGridItems[gridItem.uuid];
        accumulatedGridItems[gridItem.uuid] = 0;
      }

      const dailyDistributionBefore = makeDailyDistributionBeforeByFieldConfigurations(volume, fieldConfigurations);

      return {
        ...gridItem,
        volume: lodash.sum(dailyDistributionBefore),
        dailyDistributionBefore,
      };
    };

    const gridItemsMap = (gridItems) => {
      accumulatedGridItems = {};

      for (const gridItem of gridItems) {
        if (gridItem.percentage > 0) accumulatedGridItems[gridItem.uuid] = 0;
      }

      const result = {};
      let hasAccumulatedGridItems = accumulatedGridItems;
      while (lodash.size(hasAccumulatedGridItems) > 0) {
        for (const gridItemUuid in hasAccumulatedGridItems) {
          let volume = 0; let
            gridItem = result[gridItemUuid];
          if (gridItem) {
            volume = gridItem.volume;
          } else {
            gridItem = lodash.find(gridItems, { uuid: gridItemUuid });
            volume = Math.ceil((remainingImpressions * gridItem.percentage) / 100);
          }

          const gridItemMapped = getGridItem(gridItem, volume);
          result[gridItemUuid] = gridItemMapped;
        }

        const hasAccumulated = {};
        for (const gridItemUuid in accumulatedGridItems) {
          if (accumulatedGridItems[gridItemUuid] > 0) { hasAccumulated[gridItemUuid] = accumulatedGridItems[gridItemUuid]; }
        }
        hasAccumulatedGridItems = hasAccumulated;
      }

      return Object.values(result);
    };

    const gridPeriodToReplace = {
      ...gridPeriod,
      grids: gridPeriod.grids.map((grid) => {
        if (grid.campaignType.name === 'AUCTION') {
          // countGridItems = grid.gridItems.length;
          return {
            ...grid,
            gridItems: gridItemsMap(grid.gridItems).sort((a, b) => b.ecpm - a.ecpm),
          };
        }
        return grid;
      }),
    };
    yield put(GridPeriodActions.gridPeriodRequestSuccess(gridPeriodToReplace));
    yield put(GridPeriodActions.gridPeriodLoadRemainingImpressions());
  } catch (error) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema na distribuição automática. Tente novamente!',
    ));
  }
}
