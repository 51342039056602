import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import { ContainerStyled, RowStyled, TableStyled } from '~/Components/Styled';

export const Container = styled(ContainerStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  h1 {
    white-space: nowrap;
  }
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const FormSchedule = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
`;

export const FormScheduleLeftBox = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
  margin-right: 20px;
  .DayPicker {
    * {
      outline: none;
    }
    .DayPicker-NavButton {
      right: 0;
    }
    .DayPicker-wrapper {
      zoom: 190%;
    }
    .DayPicker-Day--selected {
      background-color: ${props => props.theme.colors.primary} !important;
      color: ${props => props.theme.colors.white} !important;
    }
    .DayPicker-Week {}
    .DayPicker-Day {
      outline: none;
    }
  }
`;

export const FormScheduleRightBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 15px;
`;

export const FormScheduleRightBoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;
`;

export const FormScheduleRightBoxContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
`;

export const FormScheduleRightBoxFotter = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
`;

export const Select = styled(ReactSelect)`
  width: 100%;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const Table = styled(TableStyled)`
  span {
    white-space: nowrap;
  }
`;
