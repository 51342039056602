/* eslint-disable no-console */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import {
  Tab, Tabs, TabList, TabPanel,
} from 'react-tabs';

import { Creators as GridPeriodActions } from '~/redux/ducks/gridPeriod';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';
import { Creators as ProductActions } from '~/redux/ducks/product';
import Button from '~/Components/Button';
import ModalCpaFixo from './Components/ModalCpaFixo';
import ModalBonificadas from './Components/ModalBonificadas';
import ModalBonificadasEdit from './Components/ModalBonificadasEdit';
import ModalTeste from './Components/ModalTeste';
import ModalCloseAuction from './Components/ModalCloseAuction';
import {
  Container,
  TabContainer,
  RowTabHeader,
  Row,
  TableDaily,
  TableButtonsContainer,
  Select,
  CurrentInput,
  InputTable,
  Header,
  HeaderForm,
  HeaderFormInput,
  HeaderStatus,
  HeaderStatusTitle,
  HeaderStatusContent,
  HeaderStatusContentItem,
  BoxDailyVolumes,
  BoxDailyVolumesItem,
  BoxTable,
  BoxTableHeader,
  BoxTableHeaderTitle,
  BoxTableContent,
  InfoWeek,
  SelectSender,
} from './styles';

import { convertFloatReal, getBudgetLabelString } from '~/Utils';
import ModalDetailProduct from '~/Pages/Product/ModalDetailProduct';

class GridPreriodList extends Component {
  componentDidMount() {
    const { gridPeriodSetup } = this.props;
    gridPeriodSetup();
  }

  componentWillUnmount() {
    this.resetPageData();
  }

  getPeriodSelected = () => {
    const {
      periodList,
      gridPeriodFilter,
    } = this.props;
    const period = periodList.find(
      item => gridPeriodFilter.period && item.data.uuid === gridPeriodFilter.period.value,
    );
    if (period) {
      return period.data;
    }
    return null;
  };

  resetPageData = () => {
    const {
      gridPeriodChannelRequestSuccess,
      gridPeriodPeriodRequestSuccess,
      gridPeriodRequestSuccess,
      gridPeriodSetFilter,
    } = this.props;
    gridPeriodChannelRequestSuccess([]);
    gridPeriodPeriodRequestSuccess([]);
    gridPeriodRequestSuccess(null);
    gridPeriodSetFilter({
      period: null,
      channel: null,
    });
  };

  renderDisparos = () => {
    const { gridPeriod, remainingImpressions } = this.props;
    return (
      <HeaderStatus>
        <HeaderStatusTitle>
          DISPAROS
        </HeaderStatusTitle>
        <HeaderStatusContent>
          <HeaderStatusContentItem>
            <b>Quantidade de Disparos: </b>
            {gridPeriod.zone.totalImpressions.toLocaleString()}
          </HeaderStatusContentItem>
          <HeaderStatusContentItem>
            <b>Disparos Restantes: </b>
            {remainingImpressions.toLocaleString()}
          </HeaderStatusContentItem>
        </HeaderStatusContent>
      </HeaderStatus>
    );
  };

  renderHeader = () => {
    const {
      periodList,
      channelList,
      gridPeriodFilter,
      gridPeriodSetFilter,
      gridPeriodRequest,
      gridPeriod,
    } = this.props;
    return (
      <Header>
        <HeaderForm>
          <HeaderFormInput>
            <label>Semana:</label>
            <Select
              placeholder="Selecione a semana"
              value={gridPeriodFilter.period}
              onChange={(period) => {
                gridPeriodSetFilter({ period });
                gridPeriodRequest();
              }}
              options={periodList}
            />
          </HeaderFormInput>
          <HeaderFormInput>
            <label>Canal:</label>
            <Select
              placeholder="Selecione o canal"
              value={gridPeriodFilter.channel}
              onChange={(channel) => {
                gridPeriodSetFilter({ channel });
                gridPeriodRequest();
              }}
              options={channelList}
            />
          </HeaderFormInput>
        </HeaderForm>
        {gridPeriod
          && !Array.isArray(gridPeriod)
          && this.renderDisparos()
        }
      </Header>
    );
  };

  handleDeleteCampaign = (gridType, gridItemUuid) => {
    const { confirmModal, gridPeriodDeleteItemGrid } = this.props;
    confirmModal('Campanha', 'Deseja realmente excluir a campanha?', () => {
      gridPeriodDeleteItemGrid(gridType, gridItemUuid);
    });
  };

  renderTableCPA = (grid) => {
    const {
      gridPeriod,
      gridPeriodChangeModalStatus,
      gridPeriodCampaignByFilterRequest,
      gridPeriodSetFilterModalCampaign,
      gridPeriodSetCampaignToAdd,
      gridPeriodChangeCpaGridItem,
      gridPeriodChangeCpa,
      gridPeriod: { fieldConfigurations },
      senderList,
      gridPeriodChangeSender,
      gridPeriodFilter,
    } = this.props;
    const period = this.getPeriodSelected();
    const listSenders = senderList.map(i => ({
      label: i,
      value: i,
    }));
    return (
      <>
        <RowTabHeader>
          <Button
            disabled={period ? !period.gridOpen : true}
            bordered
            onClick={() => {
              gridPeriodSetFilterModalCampaign({
                page: 0,
                type: grid.campaignType.uuid,
                typeName: grid.campaignType.name,
                zone: gridPeriod.zone.uuid,
              });
              gridPeriodCampaignByFilterRequest();
              gridPeriodChangeModalStatus({
                modalCpaFixo: true,
              });
              gridPeriodSetCampaignToAdd([]);
            }}
            title="ADICIONAR "
          />
        </RowTabHeader>
        <TableDaily>
          <thead>
            <tr>
              <th>PARCEIRO</th>
              <th align="left">DISPARADOR</th>
              <th>NOME</th>
              <th>PRODUTO - OFERTA</th>
              <th align="center" width="1%">CONVERSÃO</th>
              <th align="center" width="1%">eCPM</th>
              <th align="center" width="1%">BUDGET</th>
              <th align="center" width="1%">CPA</th>
              <th align="center" width="1%">VOLUME</th>
              <th align="center" width="1%">AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            {grid.gridItems.length > 0 ? grid.gridItems.map(item => (
              <Fragment key={item.uuid}>
                <tr className="grid-separator">
                  <td colSpan={12} />
                </tr>
                <tr className="grid-content">
                  <td>
                    <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                  </td>
                  <td>
                    {gridPeriodFilter.channel.chooseSender ? (
                      <SelectSender
                        placeholder="Disparador"
                        value={listSenders.find(i => i.value === item.sender) || null}
                        onChange={(sender) => {
                          gridPeriodChangeSender(item.uuid, sender ? sender.value : '');
                        }}
                        options={listSenders}
                      />
                    ) : (
                      <span>{item.campaign.zone ? item.campaign.zone.sender : ''}</span>
                    )}
                  </td>
                  <td>
                    <span>{item.campaign.name}</span>
                  </td>
                  <td>
                    {item.campaign.productOffers.map(po => (
                      <span key={po.uuid}>
                        {`${po.product.name} - ${po.name}`}
                        <br />
                      </span>
                    ))}
                  </td>
                  <td>
                    <span className="nowrap">{item.conversionRate || ''}</span>
                  </td>
                  <td align="center">
                    <span className="nowrap">{item.ecpm.replace('.', ',')}</span>
                  </td>
                  <td>
                    <span className="nowrap">{getBudgetLabelString(item.budget)}</span>
                  </td>
                  <td>
                    <CurrentInput
                      disabled={period ? !period.gridOpen : true}
                      placeholder="CPA"
                      prefix="R$ "
                      decimalSeparator=","
                      thousandSeparator="."
                      value={parseFloat(item.cpa)}
                      onChange={(e, cpa) => {
                        gridPeriodChangeCpa(item.uuid, cpa);
                      }}
                    />
                  </td>
                  <td>
                    <span className="nowrap">{item.volume.toLocaleString()}</span>
                  </td>
                  <td>
                    <TableButtonsContainer>
                      <Button
                        disabled={period ? !period.gridOpen : true}
                        icon="FaTrashAlt"
                        noLabel
                        onClick={() => {
                          this.handleDeleteCampaign(grid.campaignType.name, item.uuid);
                        }}
                      />
                    </TableButtonsContainer>
                  </td>
                </tr>
                <tr className="grid-form">
                  <td colSpan={11}>
                    <BoxDailyVolumes>
                      <ReactTooltip
                        place="bottom"
                        effect="solid"
                      />
                      <BoxDailyVolumesItem separator>
                        <label>Volume Total</label>
                        <InputTable
                          disabled
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="Volume"
                          value={item.volume}
                          onChange={(/* e */) => { }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          Dom
                          {!fieldConfigurations[0].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[0].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[0].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="DOM"
                          value={item.dailyDistributionBefore[0]}
                          onChange={(e) => {
                            gridPeriodChangeCpaGridItem(
                              item.uuid,
                              [
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SEG
                          {!fieldConfigurations[1].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[1].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[1].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SEG"
                          value={item.dailyDistributionBefore[1]}
                          onChange={(e) => {
                            gridPeriodChangeCpaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          TER
                          {!fieldConfigurations[2].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[2].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[2].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="TER"
                          value={item.dailyDistributionBefore[2]}
                          onChange={(e) => {
                            gridPeriodChangeCpaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          QUA
                          {!fieldConfigurations[3].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[3].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[3].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="QUA"
                          value={item.dailyDistributionBefore[3]}
                          onChange={(e) => {
                            gridPeriodChangeCpaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          QUI
                          {!fieldConfigurations[4].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[4].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[4].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="QUI"
                          value={item.dailyDistributionBefore[4]}
                          onChange={(e) => {
                            gridPeriodChangeCpaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SEX
                          {!fieldConfigurations[5].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[5].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[5].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SEX"
                          value={item.dailyDistributionBefore[5]}
                          onChange={(e) => {
                            gridPeriodChangeCpaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SAB
                          {!fieldConfigurations[6].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[6].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[6].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SAB"
                          value={item.dailyDistributionBefore[6]}
                          onChange={(e) => {
                            gridPeriodChangeCpaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                    </BoxDailyVolumes>
                  </td>
                </tr>
              </Fragment>
            )) : (
              <tr>
                <td colSpan={10}>
                  <div className="empty-table-desc">
                    <span>Nenhuma campanha adicionada.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </TableDaily>
        <ModalCpaFixo />
      </>
    );
  };

  renderTableTeste = (grid) => {
    const {
      gridPeriod,
      gridPeriodChangeModalStatus,
      gridPeriodCampaignByFilterRequest,
      gridPeriodSetFilterModalCampaign,
      gridPeriodSetCampaignToAdd,
      gridPeriodChangeTesteGridItem,
      gridPeriodChangeTesteCpa,
      gridPeriod: { fieldConfigurations },
      senderList,
      gridPeriodChangeSender,
      gridPeriodFilter,
    } = this.props;
    const period = this.getPeriodSelected();
    const listSenders = senderList.map(i => ({
      label: i,
      value: i,
    }));
    return (
      <>
        <RowTabHeader>
          <Button
            disabled={period ? !period.gridOpen : true}
            bordered
            onClick={() => {
              gridPeriodSetFilterModalCampaign({
                page: 0,
                type: grid.campaignType.uuid,
                typeName: grid.campaignType.name,
                zone: gridPeriod.zone.uuid,
              });
              gridPeriodCampaignByFilterRequest();
              gridPeriodChangeModalStatus({
                modalTeste: true,
              });
              gridPeriodSetCampaignToAdd([]);
            }}
            title="ADICIONAR "
          />
        </RowTabHeader>
        <TableDaily>
          <thead>
            <tr>
              <th>PARCEIRO</th>
              <th align="left">DISPARADOR</th>
              <th>NOME</th>
              <th>PRODUTO - OFERTA</th>
              <th align="center" width="1%">CONVERSÃO</th>
              <th align="center" width="1%">CPA</th>
              <th align="center" width="1%">VOLUME</th>
              <th align="center" width="1%">AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            {grid.gridItems.length > 0 ? grid.gridItems.map(item => (
              <>
                <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
                  <td colSpan={12} />
                </tr>
                <tr key={`grid-content-${item.uuid}`} className="grid-content">
                  <td>
                    <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                  </td>
                  <td>
                    {gridPeriodFilter.channel.chooseSender ? (
                      <SelectSender
                        placeholder="Disparador"
                        value={listSenders.find(i => i.value === item.sender) || null}
                        onChange={(sender) => {
                          gridPeriodChangeSender(item.uuid, sender ? sender.value : '');
                        }}
                        options={listSenders}
                      />
                    ) : (
                      <span>{item.campaign.zone ? item.campaign.zone.sender : ''}</span>
                    )}
                  </td>
                  <td>
                    <span>{item.campaign.name}</span>
                  </td>
                  <td>
                    {item.campaign.productOffers.map(po => (
                      <span key={po.uuid}>
                        {`${po.product.name} - ${po.name}`}
                        <br />
                      </span>
                    ))}
                  </td>
                  <td>
                    <span className="nowrap">{item.conversionRate || ''}</span>
                  </td>
                  <td>
                    <CurrentInput
                      disabled={period ? !period.gridOpen : true}
                      placeholder="CPA"
                      prefix="R$ "
                      decimalSeparator=","
                      thousandSeparator="."
                      value={parseFloat(item.cpa)}
                      onChange={(e, cpa) => {
                        gridPeriodChangeTesteCpa(item.uuid, cpa);
                      }}
                    />
                  </td>
                  <td>
                    <span className="nowrap">{item.volume.toLocaleString()}</span>
                  </td>
                  <td>
                    <TableButtonsContainer>
                      <Button
                        disabled={period ? !period.gridOpen : true}
                        icon="FaTrashAlt"
                        noLabel
                        onClick={() => {
                          this.handleDeleteCampaign(grid.campaignType.name, item.uuid);
                        }}
                      />
                    </TableButtonsContainer>
                  </td>
                </tr>
                <tr key={`grid-form-${item.uuid}`} className="grid-form">
                  <td colSpan={11}>
                    <BoxDailyVolumes>
                      <ReactTooltip
                        place="bottom"
                        effect="solid"
                      />
                      <BoxDailyVolumesItem separator>
                        <label>Volume Total</label>
                        <InputTable
                          disabled
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="Volume"
                          value={item.volume}
                          onChange={(/* e */) => { }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          Dom
                          {!fieldConfigurations[0].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[0].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[0].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="DOM"
                          value={item.dailyDistributionBefore[0]}
                          onChange={(e) => {
                            gridPeriodChangeTesteGridItem(
                              item.uuid,
                              [
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SEG
                          {!fieldConfigurations[1].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[1].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[1].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SEG"
                          value={item.dailyDistributionBefore[1]}
                          onChange={(e) => {
                            gridPeriodChangeTesteGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          TER
                          {!fieldConfigurations[2].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[2].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[2].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="TER"
                          value={item.dailyDistributionBefore[2]}
                          onChange={(e) => {
                            gridPeriodChangeTesteGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          QUA
                          {!fieldConfigurations[3].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[3].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[3].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="QUA"
                          value={item.dailyDistributionBefore[3]}
                          onChange={(e) => {
                            gridPeriodChangeTesteGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          QUI
                          {!fieldConfigurations[4].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[4].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[4].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="QUI"
                          value={item.dailyDistributionBefore[4]}
                          onChange={(e) => {
                            gridPeriodChangeTesteGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SEX
                          {!fieldConfigurations[5].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[5].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[5].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SEX"
                          value={item.dailyDistributionBefore[5]}
                          onChange={(e) => {
                            gridPeriodChangeTesteGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SAB
                          {!fieldConfigurations[6].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[6].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[6].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SAB"
                          value={item.dailyDistributionBefore[6]}
                          onChange={(e) => {
                            gridPeriodChangeTesteGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                    </BoxDailyVolumes>
                  </td>
                </tr>
              </>
            )) : (
              <tr>
                <td colSpan={8}>
                  <div className="empty-table-desc">
                    <span>Nenhuma campanha adicionada.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </TableDaily>
        <ModalTeste />
      </>
    );
  };

  renderTableBonificada = (grid, gridUuid) => {
    const {
      gridPeriod,
      gridPeriodChangeModalStatus,
      gridPeriodCampaignByFilterRequest,
      gridPeriodSetFilterModalCampaign,
      gridPeriodSetCampaignToAdd,
      // gridPeriodSetItemToEdit,
      gridPeriodChangeBonificadaGridItem,
      gridPeriod: { fieldConfigurations },
      senderList,
      gridPeriodChangeSender,
      gridPeriodFilter,
    } = this.props;
    const period = this.getPeriodSelected();
    const listSenders = senderList.map(i => ({
      label: i,
      value: i,
    }));
    return (
      <>
        <RowTabHeader>
          <Button
            disabled={period ? !period.gridOpen : true}
            bordered
            onClick={() => {
              gridPeriodSetFilterModalCampaign({
                page: 0,
                type: gridUuid,
                typeName: grid.campaignType.name,
                zone: gridPeriod.zone.uuid,
              });
              gridPeriodCampaignByFilterRequest();
              gridPeriodChangeModalStatus({
                modalBonificadas: true,
              });
              gridPeriodSetCampaignToAdd([]);
            }}
            title="ADICIONAR"
          />
        </RowTabHeader>
        <TableDaily>
          <thead>
            <tr>
              <th>PARCEIRO</th>
              <th align="left">DISPARADOR</th>
              <th>NOME</th>
              <th>PRODUTO - OFERTA</th>
              <th align="center" width="1%">CONVERSÃO</th>
              <th align="center" width="1%">VOLUME</th>
              <th align="center" width="1%">AÇÕES</th>
            </tr>
          </thead>
          <tbody>
            {grid.gridItems.length > 0 ? grid.gridItems.map(item => (
              <>
                <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
                  <td colSpan={12} />
                </tr>
                <tr key={`grid-content-${item.uuid}`} className="grid-content">
                  <td>
                    <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                  </td>
                  <td>
                    {gridPeriodFilter.channel.chooseSender ? (
                      <SelectSender
                        placeholder="Disparador"
                        value={listSenders.find(i => i.value === item.sender) || null}
                        onChange={(sender) => {
                          gridPeriodChangeSender(item.uuid, sender ? sender.value : '');
                        }}
                        options={listSenders}
                      />
                    ) : (
                      <span>{item.campaign.zone ? item.campaign.zone.sender : ''}</span>
                    )}
                  </td>
                  <td>
                    <span>{item.campaign.name}</span>
                  </td>
                  <td>
                    {item.campaign.productOffers.map(po => (
                      <span key={po.uuid}>
                        {`${po.product.name} - ${po.name}`}
                        <br />
                      </span>
                    ))}
                  </td>
                  <td>
                    <span className="nowrap">{item.conversionRate || ''}</span>
                  </td>
                  <td>
                    <span className="nowrap">{parseInt(item.volume, 10).toLocaleString()}</span>
                  </td>
                  <td align="center">
                    <TableButtonsContainer>
                      {/*
                        <Button
                          disabled={period ? !period.gridOpen : true}
                          bordered
                          icon="FaPencilAlt"
                          noLabel
                          onClick={() => {
                            gridPeriodChangeModalStatus({
                              modalBonificadasEdit: true,
                            });
                            gridPeriodSetItemToEdit(item);
                          }}
                        />
                      */}
                      <Button
                        disabled={period ? !period.gridOpen : true}
                        icon="FaTrashAlt"
                        noLabel
                        onClick={() => {
                          this.handleDeleteCampaign(grid.campaignType.name, item.uuid);
                        }}
                      />
                    </TableButtonsContainer>
                  </td>
                </tr>
                <tr key={`grid-form-${item.uuid}`} className="grid-form">
                  <td colSpan={11}>
                    <BoxDailyVolumes>
                      <ReactTooltip
                        place="bottom"
                        effect="solid"
                      />
                      <BoxDailyVolumesItem separator>
                        <label>Volume Total</label>
                        <InputTable
                          disabled
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="Volume"
                          value={item.volume}
                          onChange={(/* e */) => { }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          Dom
                          {!fieldConfigurations[0].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[0].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[0].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="DOM"
                          value={item.dailyDistributionBefore[0]}
                          onChange={(e) => {
                            gridPeriodChangeBonificadaGridItem(
                              item.uuid,
                              [
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SEG
                          {!fieldConfigurations[1].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[1].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[1].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SEG"
                          value={item.dailyDistributionBefore[1]}
                          onChange={(e) => {
                            gridPeriodChangeBonificadaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          TER
                          {!fieldConfigurations[2].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[2].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[2].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="TER"
                          value={item.dailyDistributionBefore[2]}
                          onChange={(e) => {
                            gridPeriodChangeBonificadaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          QUA
                          {!fieldConfigurations[3].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[3].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[3].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="QUA"
                          value={item.dailyDistributionBefore[3]}
                          onChange={(e) => {
                            gridPeriodChangeBonificadaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          QUI
                          {!fieldConfigurations[4].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[4].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[4].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="QUI"
                          value={item.dailyDistributionBefore[4]}
                          onChange={(e) => {
                            gridPeriodChangeBonificadaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SEX
                          {!fieldConfigurations[5].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[5].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[5].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SEX"
                          value={item.dailyDistributionBefore[5]}
                          onChange={(e) => {
                            gridPeriodChangeBonificadaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SAB
                          {!fieldConfigurations[6].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[6].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[6].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SAB"
                          value={item.dailyDistributionBefore[6]}
                          onChange={(e) => {
                            gridPeriodChangeBonificadaGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                    </BoxDailyVolumes>
                  </td>
                </tr>
              </>
            )) : (
              <tr>
                <td colSpan={7}>
                  <div className="empty-table-desc">
                    <span>Nenhuma campanha adicionada.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </TableDaily>
        <ModalBonificadas />
        <ModalBonificadasEdit />
      </>
    );
  };

  renderTableAuctionLeilao = (grid) => {
    const {
      gridPeriodChangeLeilaoGridItem,
      gridPeriodAutomaticDistribution,
      remainingImpressions,
      gridPeriod: { fieldConfigurations },
      senderList,
      gridPeriodChangeSender,
      gridPeriodFilter,
      showDetailProductSetData,
    } = this.props;
    const period = this.getPeriodSelected();
    const getBudget = (item) => {
      const budgetItem = parseFloat(item.budget);
      const cpaItem = parseFloat((parseFloat(item.cpa) * parseFloat(item.conversionRate)) / 100);
      try {
        const calc = Math.round(budgetItem / cpaItem);
        return calc > 0 ? calc : 'Ilimitado';
      } catch (error) {
        return 0;
      }
    };
    const listSenders = senderList.map(i => ({
      label: i,
      value: i,
    }));
    return (
      <>
        <RowTabHeader>
          <Button
            disabled={period ? !period.gridOpen : true}
            bordered
            onClick={() => {
              gridPeriodAutomaticDistribution();
            }}
            title="DISTRIBUIÇÃO AUTOMÁTICA"
          />
        </RowTabHeader>
        <TableDaily>
          <thead>
            <tr>
              <th width="1%">POS</th>
              <th>PARCEIRO</th>
              <th align="left">DISPARADOR</th>
              <th>NOME</th>
              <th>PRODUTO - OFERTA</th>
              <th align="center" width="1%">CONVERSÃO</th>
              <th align="center" width="1%">eCPM</th>
              <th align="center" width="1%">BUDGET</th>
              <th align="center" width="1%">CPA</th>
              <th align="center" width="1%">PERCENTUAL</th>
              <th align="center" width="1%">VOL. LEILÃO</th>
              <th align="center" width="1%">VOL. BUDGET</th>
              <th align="center" width="1%">INDICADORES</th>
            </tr>
          </thead>
          <tbody>
            {grid.gridItems.length > 0 ? grid.gridItems.map(item => (
              <>
                <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
                  <td colSpan={12} />
                </tr>
                <tr key={`grid-content-${item.uuid}`} className="grid-content">
                  <td>
                    <span>{item.ranking}</span>
                  </td>
                  <td>
                    <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                  </td>
                  <td>
                    {gridPeriodFilter.channel.chooseSender ? (
                      <SelectSender
                        placeholder="Disparador"
                        value={listSenders.find(i => i.value === item.sender) || null}
                        onChange={(sender) => {
                          gridPeriodChangeSender(item.uuid, sender ? sender.value : '');
                        }}
                        options={listSenders}
                      />
                    ) : (
                      <span>{item.campaign.zone ? item.campaign.zone.sender : ''}</span>
                    )}
                  </td>
                  <td>
                    <span>{item.campaign.name}</span>
                  </td>
                  <td>
                    {item.campaign.productOffers.map(po => (
                      <span key={po.uuid}>
                        {`${po.product.name} - ${po.name}`}
                        <br />
                      </span>
                    ))}
                  </td>
                  <td>
                    <span className="nowrap">{item.conversionRate || ''}</span>
                  </td>
                  <td align="center">
                    <span className="nowrap">{convertFloatReal(item.ecpm)}</span>
                  </td>
                  <td>
                    <span className="nowrap">{getBudgetLabelString(item.budget)}</span>
                  </td>
                  <td>
                    <span className="nowrap">{`R$ ${convertFloatReal(item.cpa)}`}</span>
                  </td>
                  <td align="center">
                    <span className="nowrap">{`${item.percentage.toLocaleString()}%`}</span>
                  </td>
                  <td align="center">
                    <span className="nowrap">{Math.ceil((remainingImpressions * item.percentage) / 100).toLocaleString()}</span>
                  </td>
                  <td align="center">
                    <span className="nowrap">{getBudget(item)}</span>
                  </td>
                  <td align="center">
                    <Button
                      icon="FaInfo"
                      noLabel
                      onClick={() => {
                        const productOffer = item.campaign.productOffers[0];
                        if (productOffer && productOffer.product) {
                          showDetailProductSetData(productOffer.product);
                        }
                      }}
                    />
                  </td>
                </tr>
                <tr key={`grid-form-${item.uuid}`} className="grid-form">
                  <td colSpan={13}>
                    <BoxDailyVolumes>
                      <ReactTooltip
                        place="bottom"
                        effect="solid"
                      />
                      <BoxDailyVolumesItem separator>
                        <label>Volume Total</label>
                        <InputTable
                          disabled
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="Volume"
                          value={item.volume}
                          onChange={(/* e */) => { }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          Dom
                          {!fieldConfigurations[0].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[0].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[0].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="DOM"
                          value={item.dailyDistributionBefore[0]}
                          onChange={(e) => {
                            gridPeriodChangeLeilaoGridItem(
                              item.uuid,
                              [
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SEG
                          {!fieldConfigurations[1].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[1].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[1].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SEG"
                          value={item.dailyDistributionBefore[1]}
                          onChange={(e) => {
                            gridPeriodChangeLeilaoGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          TER
                          {!fieldConfigurations[2].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[2].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[2].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="TER"
                          value={item.dailyDistributionBefore[2]}
                          onChange={(e) => {
                            gridPeriodChangeLeilaoGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          QUA
                          {!fieldConfigurations[3].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[3].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[3].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="QUA"
                          value={item.dailyDistributionBefore[3]}
                          onChange={(e) => {
                            gridPeriodChangeLeilaoGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          QUI
                          {!fieldConfigurations[4].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[4].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[4].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="QUI"
                          value={item.dailyDistributionBefore[4]}
                          onChange={(e) => {
                            gridPeriodChangeLeilaoGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[5],
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SEX
                          {!fieldConfigurations[5].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[5].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[5].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SEX"
                          value={item.dailyDistributionBefore[5]}
                          onChange={(e) => {
                            gridPeriodChangeLeilaoGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                item.dailyDistributionBefore[6],
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                      <BoxDailyVolumesItem>
                        <label>
                          SAB
                          {!fieldConfigurations[6].enabled && (
                            <InfoWeek data-tip={fieldConfigurations[6].message}>
                              <FaInfoCircle />
                            </InfoWeek>
                          )}
                        </label>
                        <InputTable
                          disabled={!fieldConfigurations[6].enabled || !period.gridOpen}
                          type="number"
                          style={{ marginRight: 0 }}
                          placeholder="SAB"
                          value={item.dailyDistributionBefore[6]}
                          onChange={(e) => {
                            gridPeriodChangeLeilaoGridItem(
                              item.uuid,
                              [
                                item.dailyDistributionBefore[0],
                                item.dailyDistributionBefore[1],
                                item.dailyDistributionBefore[2],
                                item.dailyDistributionBefore[3],
                                item.dailyDistributionBefore[4],
                                item.dailyDistributionBefore[5],
                                parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                              ],
                            );
                          }}
                        />
                      </BoxDailyVolumesItem>
                    </BoxDailyVolumes>
                  </td>
                </tr>
              </>
            )) : (
              <tr>
                <td colSpan={12}>
                  <div className="empty-table-desc">
                    <span>Nenhuma campanha adicionada.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </TableDaily>
      </>
    );
  };

  renderTableCPAIsAllChannels = grid => (
    <TableDaily>
      <thead>
        <tr>
          <th>PARCEIRO</th>
          <th>NOME</th>
          <th>PRODUTO - OFERTA</th>
          <th align="center" width="1%">eCPM</th>
          <th align="center" width="1%">BUDGET</th>
          <th align="center" width="1%">CPA</th>
          <th align="center" width="1%">VOLUME</th>
        </tr>
      </thead>
      <tbody>
        {grid && grid.gridItems && grid.gridItems.length > 0 ? grid.gridItems.map(item => (
          <>
            <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
              <td colSpan={7} />
            </tr>
            <tr key={`grid-content-${item.uuid}`} className="grid-content">
              <td>
                <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
              </td>
              <td>
                <span>{item.campaign.name}</span>
              </td>
              <td>
                {item.campaign.productOffers.map(po => (
                  <span key={po.uuid}>
                    {`${po.product.name} - ${po.name}`}
                    <br />
                  </span>
                ))}
              </td>
              <td align="center">
                <span className="nowrap">{item.ecpm.replace('.', ',')}</span>
              </td>
              <td align="center">
                <span className="nowrap">{getBudgetLabelString(item.budget)}</span>
              </td>
              <td align="center">
                <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
              </td>
              <td align="center">
                <span className="nowrap">{item.volume.toLocaleString()}</span>
              </td>
            </tr>
            <tr key={`grid-form-${item.uuid}`} className="grid-form">
              <td colSpan={8}>
                <BoxDailyVolumes>
                  <BoxDailyVolumesItem separator>
                    <label>Volume Total</label>
                    <span>{item.volume}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>Dom</label>
                    <span>{item.dailyDistributionBefore[0] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEG</label>
                    <span>{item.dailyDistributionBefore[1] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>TER</label>
                    <span>{item.dailyDistributionBefore[2] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUA</label>
                    <span>{item.dailyDistributionBefore[3] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUI</label>
                    <span>{item.dailyDistributionBefore[4] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEX</label>
                    <span>{item.dailyDistributionBefore[5] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SAB</label>
                    <span>{item.dailyDistributionBefore[6] || 0}</span>
                  </BoxDailyVolumesItem>
                </BoxDailyVolumes>
              </td>
            </tr>
          </>
        )) : (
          <tr>
            <td colSpan={11}>
              <div className="empty-table-desc">
                <span>Nenhuma campanha adicionada.</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </TableDaily>
  );

  renderTableTesteIsAllChannels = grid => (
    <TableDaily>
      <thead>
        <tr>
          <th>PARCEIRO</th>
          <th>NOME</th>
          <th>PRODUTO - OFERTA</th>
          <th align="center" width="1%">CPA</th>
          <th align="center" width="1%">VOLUME</th>
        </tr>
      </thead>
      <tbody>
        {grid && grid.gridItems && grid.gridItems.length > 0 ? grid.gridItems.map(item => (
          <>
            <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
              <td colSpan={8} />
            </tr>
            <tr key={`grid-content-${item.uuid}`} className="grid-content">
              <td>
                <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
              </td>
              <td>
                <span>{item.campaign.name}</span>
              </td>
              <td>
                {item.campaign.productOffers.map(po => (
                  <span key={po.uuid}>
                    {`${po.product.name} - ${po.name}`}
                    <br />
                  </span>
                ))}
              </td>
              <td align="center">
                <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
              </td>
              <td align="center">
                <span className="nowrap">{item.volume.toLocaleString()}</span>
              </td>
            </tr>
            <tr key={`grid-form-${item.uuid}`} className="grid-form">
              <td colSpan={8}>
                <BoxDailyVolumes>
                  <BoxDailyVolumesItem separator>
                    <label>Volume Total</label>
                    <span>{item.volume}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>Dom</label>
                    <span>{item.dailyDistributionBefore[0] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEG</label>
                    <span>{item.dailyDistributionBefore[1] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>TER</label>
                    <span>{item.dailyDistributionBefore[2] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUA</label>
                    <span>{item.dailyDistributionBefore[3] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUI</label>
                    <span>{item.dailyDistributionBefore[4] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEX</label>
                    <span>{item.dailyDistributionBefore[5] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SAB</label>
                    <span>{item.dailyDistributionBefore[6] || 0}</span>
                  </BoxDailyVolumesItem>
                </BoxDailyVolumes>
              </td>
            </tr>
          </>
        )) : (
          <tr>
            <td colSpan={11}>
              <div className="empty-table-desc">
                <span>Nenhuma campanha adicionada.</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </TableDaily>
  );

  renderTableBonificadaIsAllChannels = grid => (
    <TableDaily>
      <thead>
        <tr>
          <th>PARCEIRO</th>
          <th>NOME</th>
          <th>PRODUTO - OFERTA</th>
          <th align="center" width="1%">VOLUME</th>
        </tr>
      </thead>
      <tbody>
        {grid && grid.gridItems && grid.gridItems.length > 0 ? grid.gridItems.map(item => (
          <>
            <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
              <td colSpan={8} />
            </tr>
            <tr key={`grid-content-${item.uuid}`} className="grid-content">
              <td>
                <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
              </td>
              <td>
                <span>{item.campaign.name}</span>
              </td>
              <td>
                {item.campaign.productOffers.map(po => (
                  <span key={po.uuid}>
                    {`${po.product.name} - ${po.name}`}
                    <br />
                  </span>
                ))}
              </td>
              <td align="center">
                <span className="nowrap">{item.volume.toLocaleString()}</span>
              </td>
            </tr>
            <tr key={`grid-form-${item.uuid}`} className="grid-form">
              <td colSpan={8}>
                <BoxDailyVolumes>
                  <BoxDailyVolumesItem separator>
                    <label>Volume Total</label>
                    <span>{item.volume}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>Dom</label>
                    <span>{item.dailyDistributionBefore[0] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEG</label>
                    <span>{item.dailyDistributionBefore[1] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>TER</label>
                    <span>{item.dailyDistributionBefore[2] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUA</label>
                    <span>{item.dailyDistributionBefore[3] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>QUI</label>
                    <span>{item.dailyDistributionBefore[4] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SEX</label>
                    <span>{item.dailyDistributionBefore[5] || 0}</span>
                  </BoxDailyVolumesItem>
                  <BoxDailyVolumesItem>
                    <label>SAB</label>
                    <span>{item.dailyDistributionBefore[6] || 0}</span>
                  </BoxDailyVolumesItem>
                </BoxDailyVolumes>
              </td>
            </tr>
          </>
        )) : (
          <tr>
            <td colSpan={11}>
              <div className="empty-table-desc">
                <span>Nenhuma campanha adicionada.</span>
              </div>
            </td>
          </tr>
        )}
      </tbody>
    </TableDaily>
  );

  renderTableLeilaoIsAllChannels = (grid) => {
    const { showDetailProductSetData } = this.props;
    return (
      <TableDaily>
        <thead>
          <tr>
            <th>POSIÇÃO</th>
            <th>PARCEIRO</th>
            <th>NOME</th>
            <th>PRODUTO - OFERTA</th>
            <th align="center" width="1%">eCPM</th>
            <th align="center" width="1%">Budget</th>
            <th align="center" width="1%">CPA</th>
            <th align="center" width="1%">VOLUME</th>
            <th align="center" width="1%">INDICADORES</th>
          </tr>
        </thead>
        <tbody>
          {grid && grid.gridItems && grid.gridItems.length > 0 ? grid.gridItems.map(item => (
            <>
              <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
                <td colSpan={8} />
              </tr>
              <tr key={`grid-content-${item.uuid}`} className="grid-content">
                <td>
                  <span>{item.ranking}</span>
                </td>
                <td>
                  <span>{item.campaign.contract ? item.campaign.contract.name : ''}</span>
                </td>
                <td>
                  <span>{item.campaign.name}</span>
                </td>
                <td>
                  {item.campaign.productOffers.map(po => (
                    <span key={po.uuid}>
                      {`${po.product.name} - ${po.name}`}
                      <br />
                    </span>
                  ))}
                </td>
                <td align="center">
                  <span className="nowrap">{item.ecpm.replace('.', ',')}</span>
                </td>
                <td align="center">
                  <span className="nowrap">{getBudgetLabelString(item.budget)}</span>
                </td>
                <td align="center">
                  <span className="nowrap">{`R$ ${item.cpa.replace('.', ',')}`}</span>
                </td>
                <td align="center">
                  <span className="nowrap">{item.volume.toLocaleString()}</span>
                </td>
                <td align="center">
                  <Button
                    icon="FaInfo"
                    noLabel
                    onClick={() => {
                      const productOffer = item.campaign.productOffers[0];
                      if (productOffer && productOffer.product) {
                        showDetailProductSetData(productOffer.product);
                      }
                    }}
                  />
                </td>
              </tr>
              <tr key={`grid-form-${item.uuid}`} className="grid-form">
                <td colSpan={9}>
                  <BoxDailyVolumes>
                    <BoxDailyVolumesItem separator>
                      <label>Volume Total</label>
                      <span>{item.volume}</span>
                    </BoxDailyVolumesItem>
                    <BoxDailyVolumesItem>
                      <label>Dom</label>
                      <span>{item.dailyDistributionBefore[0] || 0}</span>
                    </BoxDailyVolumesItem>
                    <BoxDailyVolumesItem>
                      <label>SEG</label>
                      <span>{item.dailyDistributionBefore[1] || 0}</span>
                    </BoxDailyVolumesItem>
                    <BoxDailyVolumesItem>
                      <label>TER</label>
                      <span>{item.dailyDistributionBefore[2] || 0}</span>
                    </BoxDailyVolumesItem>
                    <BoxDailyVolumesItem>
                      <label>QUA</label>
                      <span>{item.dailyDistributionBefore[3] || 0}</span>
                    </BoxDailyVolumesItem>
                    <BoxDailyVolumesItem>
                      <label>QUI</label>
                      <span>{item.dailyDistributionBefore[4] || 0}</span>
                    </BoxDailyVolumesItem>
                    <BoxDailyVolumesItem>
                      <label>SEX</label>
                      <span>{item.dailyDistributionBefore[5] || 0}</span>
                    </BoxDailyVolumesItem>
                    <BoxDailyVolumesItem>
                      <label>SAB</label>
                      <span>{item.dailyDistributionBefore[6] || 0}</span>
                    </BoxDailyVolumesItem>
                  </BoxDailyVolumes>
                </td>
              </tr>
            </>
          )) : (
            <tr>
              <td colSpan={11}>
                <div className="empty-table-desc">
                  <span>Nenhuma campanha adicionada.</span>
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </TableDaily>
    );
  };

  renderTabs = () => {
    const { gridPeriod } = this.props;
    const isAllChannels = Array.isArray(gridPeriod);
    let hasDataCpaFixo = null;
    let hasDataTeste = null;
    let hasDataBonificada = null;
    let hasDataLeilao = null;

    if (isAllChannels) {
      hasDataCpaFixo = gridPeriod.map(item => ({
        zone: item.zone,
        grid: item.grids.find(grid => grid.campaignType.name === 'FIXED_CPA'),
      }));
      hasDataTeste = gridPeriod.map(item => ({
        zone: item.zone,
        grid: item.grids.find(grid => grid.campaignType.name === 'PROBE'),
      }));
      hasDataBonificada = gridPeriod.map(item => ({
        zone: item.zone,
        grid: item.grids.find(grid => grid.campaignType.name === 'BONUS'),
      }));
      hasDataLeilao = gridPeriod.map(item => ({
        zone: item.zone,
        grid: item.grids.find(grid => grid.campaignType.name === 'AUCTION'),
      }));

      return (
        <TabContainer>
          <Tabs defaultIndex={0}>
            <TabList>
              <Tab>CPA Fixo</Tab>
              <Tab>Teste</Tab>
              <Tab>Bonificada</Tab>
              <Tab>Leilão</Tab>
            </TabList>
            <TabPanel>
              {hasDataCpaFixo && hasDataCpaFixo.map(item => (
                <BoxTable key={item.zone.uuid}>
                  <BoxTableHeader>
                    <BoxTableHeaderTitle>
                      {`${item.zone.channel.name} (${item.zone.name})`}
                    </BoxTableHeaderTitle>
                  </BoxTableHeader>
                  <BoxTableContent>
                    {this.renderTableCPAIsAllChannels(item.grid)}
                  </BoxTableContent>
                </BoxTable>
              ))}
            </TabPanel>
            <TabPanel>
              {hasDataTeste && hasDataTeste.map(item => (
                <BoxTable key={item.zone.uuid}>
                  <BoxTableHeader>
                    <BoxTableHeaderTitle>
                      {`${item.zone.channel.name} (${item.zone.name})`}
                    </BoxTableHeaderTitle>
                  </BoxTableHeader>
                  <BoxTableContent>
                    {this.renderTableTesteIsAllChannels(item.grid)}
                  </BoxTableContent>
                </BoxTable>
              ))}
            </TabPanel>
            <TabPanel>
              {hasDataBonificada && hasDataBonificada.map(item => (
                <BoxTable key={item.zone.uuid}>
                  <BoxTableHeader>
                    <BoxTableHeaderTitle>
                      {`${item.zone.channel.name} (${item.zone.name})`}
                    </BoxTableHeaderTitle>
                  </BoxTableHeader>
                  <BoxTableContent>
                    {this.renderTableBonificadaIsAllChannels(item.grid)}
                  </BoxTableContent>
                </BoxTable>
              ))}
            </TabPanel>
            <TabPanel>
              {hasDataLeilao && hasDataLeilao.map(item => (
                <BoxTable key={item.zone.uuid}>
                  <BoxTableHeader>
                    <BoxTableHeaderTitle>
                      {`${item.zone.channel.name} (${item.zone.name})`}
                    </BoxTableHeaderTitle>
                  </BoxTableHeader>
                  <BoxTableContent>
                    {this.renderTableLeilaoIsAllChannels(item.grid)}
                  </BoxTableContent>
                </BoxTable>
              ))}
            </TabPanel>
          </Tabs>
        </TabContainer>
      );
    }

    hasDataCpaFixo = gridPeriod.grids.find(item => item.campaignType.name === 'FIXED_CPA');
    hasDataTeste = gridPeriod.grids.find(item => item.campaignType.name === 'PROBE');
    hasDataBonificada = gridPeriod.grids.find(item => item.campaignType.name === 'BONUS');
    hasDataLeilao = gridPeriod.grids.find(item => item.campaignType.name === 'AUCTION');

    return gridPeriod.grids.length > 0 ? (
      <TabContainer>
        <Tabs defaultIndex={0}>
          <TabList>
            {hasDataCpaFixo && (<Tab>CPA Fixo</Tab>)}
            {hasDataTeste && (<Tab>Teste</Tab>)}
            {hasDataBonificada && (<Tab>Bonificada</Tab>)}
            {hasDataLeilao && (<Tab>Leilão</Tab>)}
          </TabList>
          {hasDataCpaFixo && (
            <TabPanel>
              {this.renderTableCPA(hasDataCpaFixo)}
            </TabPanel>
          )}
          {hasDataTeste && (
            <TabPanel>
              {this.renderTableTeste(hasDataTeste)}
            </TabPanel>
          )}
          {hasDataBonificada && (
            <TabPanel>
              {this.renderTableBonificada(
                hasDataBonificada,
                `${hasDataBonificada.campaignType.uuid}${hasDataLeilao ? `,${hasDataLeilao.campaignType.uuid}` : ''}${hasDataTeste ? `,${hasDataTeste.campaignType.uuid}` : ''}`,
              )}
            </TabPanel>
          )}
          {hasDataLeilao && (
            <TabPanel>
              {this.renderTableAuctionLeilao(hasDataLeilao)}
            </TabPanel>
          )}
        </Tabs>
      </TabContainer>
    ) : null;
  };

  render() {
    const {
      gridPeriodChangeModalStatus,
      gridPeriod,
      gridPeriodSaveGrid,
    } = this.props;
    const period = this.getPeriodSelected();

    return (
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>FECHAMENTO SEMANAL</h1>
          {gridPeriod && (
            <div>
              {!Array.isArray(gridPeriod) && (
                <Button
                  disabled={period ? !period.gridOpen : true}
                  bordered
                  onClick={() => {
                    gridPeriodSaveGrid('DRAFT');
                  }}
                  title="SALVAR "
                />
              )}
              {Array.isArray(gridPeriod) && (
                <Button
                  disabled={period ? !period.gridOpen : true}
                  onClick={() => {
                    gridPeriodChangeModalStatus({
                      modalCloseAuction: true,
                    });
                  }}
                  title="FINALIZAR"
                />
              )}
            </div>
          )}
        </Row>
        {this.renderHeader()}
        {gridPeriod && this.renderTabs()}
        <ModalCloseAuction />
        <ModalDetailProduct />
      </Container>
    );
  }
}

GridPreriodList.defaultProps = {
  gridPeriod: null,
};

GridPreriodList.propTypes = {
  gridPeriod: PropTypes.oneOfType([PropTypes.shape(), PropTypes.arrayOf(PropTypes.shape())]),
  remainingImpressions: PropTypes.number.isRequired,
  channelList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  periodList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  senderList: PropTypes.arrayOf(PropTypes.string).isRequired,
  gridPeriodFilter: PropTypes.shape().isRequired,
  gridPeriodSetup: PropTypes.func.isRequired,
  gridPeriodRequest: PropTypes.func.isRequired,
  gridPeriodSetFilter: PropTypes.func.isRequired,
  gridPeriodRequestSuccess: PropTypes.func.isRequired,
  gridPeriodChannelRequestSuccess: PropTypes.func.isRequired,
  gridPeriodPeriodRequestSuccess: PropTypes.func.isRequired,
  gridPeriodChangeModalStatus: PropTypes.func.isRequired,
  gridPeriodCampaignByFilterRequest: PropTypes.func.isRequired,
  gridPeriodSetFilterModalCampaign: PropTypes.func.isRequired,
  gridPeriodSetCampaignToAdd: PropTypes.func.isRequired,
  // gridPeriodSetItemToEdit: PropTypes.func.isRequired,
  gridPeriodSaveGrid: PropTypes.func.isRequired,
  gridPeriodDeleteItemGrid: PropTypes.func.isRequired,
  gridPeriodChangeLeilaoGridItem: PropTypes.func.isRequired,
  gridPeriodChangeBonificadaGridItem: PropTypes.func.isRequired,
  gridPeriodChangeCpaGridItem: PropTypes.func.isRequired,
  gridPeriodChangeTesteGridItem: PropTypes.func.isRequired,
  gridPeriodChangeTesteCpa: PropTypes.func.isRequired,
  gridPeriodChangeSender: PropTypes.func.isRequired,
  gridPeriodChangeCpa: PropTypes.func.isRequired,
  gridPeriodAutomaticDistribution: PropTypes.func.isRequired,
  confirmModal: PropTypes.func.isRequired,
  showDetailProductSetData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  channelList: state.gridPeriod.channelList,
  periodList: state.gridPeriod.periodList,
  senderList: state.gridPeriod.senderList,
  gridPeriodFilter: state.gridPeriod.gridPeriodFilter,
  gridPeriod: state.gridPeriod.gridPeriod,
  remainingImpressions: state.gridPeriod.remainingImpressions,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    gridPeriodSetup: GridPeriodActions.gridPeriodSetup,
    gridPeriodRequest: GridPeriodActions.gridPeriodRequest,
    gridPeriodRequestSuccess: GridPeriodActions.gridPeriodRequestSuccess,
    gridPeriodChannelRequestSuccess: GridPeriodActions.gridPeriodChannelRequestSuccess,
    gridPeriodPeriodRequestSuccess: GridPeriodActions.gridPeriodPeriodRequestSuccess,
    gridPeriodSetFilter: GridPeriodActions.gridPeriodSetFilter,
    gridPeriodChangeModalStatus: GridPeriodActions.gridPeriodChangeModalStatus,
    gridPeriodCampaignByFilterRequest: GridPeriodActions.gridPeriodCampaignByFilterRequest,
    gridPeriodSetFilterModalCampaign: GridPeriodActions.gridPeriodSetFilterModalCampaign,
    gridPeriodSetCampaignToAdd: GridPeriodActions.gridPeriodSetCampaignToAdd,
    gridPeriodSaveGrid: GridPeriodActions.gridPeriodSaveGrid,
    gridPeriodDeleteItemGrid: GridPeriodActions.gridPeriodDeleteItemGrid,
    gridPeriodChangeLeilaoGridItem: GridPeriodActions.gridPeriodChangeLeilaoGridItem,
    gridPeriodChangeBonificadaGridItem: GridPeriodActions.gridPeriodChangeBonificadaGridItem,
    gridPeriodChangeCpaGridItem: GridPeriodActions.gridPeriodChangeCpaGridItem,
    gridPeriodChangeTesteGridItem: GridPeriodActions.gridPeriodChangeTesteGridItem,
    gridPeriodChangeTesteCpa: GridPeriodActions.gridPeriodChangeTesteCpa,
    gridPeriodChangeSender: GridPeriodActions.gridPeriodChangeSender,
    gridPeriodChangeCpa: GridPeriodActions.gridPeriodChangeCpa,
    gridPeriodAutomaticDistribution: GridPeriodActions.gridPeriodAutomaticDistribution,
    confirmModal: ConfirmModalActions.showConfirmModal,
    showDetailProductSetData: ProductActions.showDetailProductSetData,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(GridPreriodList);
