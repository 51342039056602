import styled, { css } from 'styled-components';

import {
  RowStyled, FormGroupStyled, InputStyled, TextareaStyled, SelectStyled,
} from '~/Components/Styled';


export const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FormGroup = styled(FormGroupStyled)`
  flex: 1;
  margin: 0;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const BaseConfirmRemove = styled.div`
  border: 2px solid #dbdbdb;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 250px;

  p {
    margin-bottom: 1px;
  }
`;

export const Input = styled(InputStyled)`
  margin-right: 0;
`;

export const Textarea = styled(TextareaStyled)`
  width: 100%;
  margin: 0;

  &.error {
    border-color: red;
  }
`;

export const Imagem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  position: relative;
  height: 150px;
  width: 180px;

  .success-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: ${props => props.theme.colors.white};
  }

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;

export const SuccessHeaderImagem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2px;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  position: relative;
  height: 150px;
  width: 230px;

  .success-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: ${props => props.theme.colors.white};;
  }

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;

export const BannerEmpty = styled.div`
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  text-align: center;
  ${props => props.invalid
    && css`
      color: ${props.theme.colors.danger2};
    `}
`;

export const LimitCaracteres = styled.span`
  font-style: italic;
  color: #a7a7a7;

  &.error {
    color: red;
  }
`;

export const Select = styled(SelectStyled)``;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.danger2};
  font-style: italic;
`;
