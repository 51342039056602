import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;

  > svg {
    margin-right: 5px;
  }
`;
