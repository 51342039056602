import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  ContainerStyled,
  RowStyled,
  FormGroupStyled,
  InputStyled,
} from '~/Components/Styled';
import ToggleComponent from '~/Components/Toggle';

export const Container = styled(ContainerStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
  .header-button-box {
    display: flex;
    > button {
      align-self: flex-end;
    }
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)}px;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Input = styled(InputStyled)`
  width: 400px;
  margin-bottom: 10px;
  height: 40px;
`;

export const FormGroupHeader = styled.div`
  display: flex;
  align-items: flex-end;
  padding: 5px 10px;
  border-radius: 5px;
`;

export const Toggle = styled(ToggleComponent).attrs({
  style: {
    marginBottom: 10,
    height: 40,
    width: 150,
  },
})``;

export const Select = styled(ReactSelect)`
  width: 300px;
  margin-bottom: 10px;
  margin-right: 15px;
  border: 0;
  [class*="-control"] {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 4px;
    ${props => props.invalid
      && css`
        border-color: ${props.theme.colors.danger2};
      `}
    outline: none;
  }
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 15px;
  min-height: 118px;
  .box-databate-content {
    display: flex;
    flex-direction: row;
    width: 100%;
    .box-databate-content-labels {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .box-databate-content-result {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 2;
      padding: 10px;
    }
  }
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;

  .base-buttons-download {
    display: flex;

    button {
      background-color: white;
      padding-left: 15px;
      padding-right: 15px;
    }
  }
`;

export const BoxContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
`;

export const BoxContentItem = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 16px;
  flex: ${props => props.flex};
`;

export const StatusLabel = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  svg {
    font-size: 20px;
    margin-right: 10px;
  }
  svg.status-unprocessed {
    color: ${props => props.theme.colors.dark} !important;
  }
  svg.status-processing-error {
    color: ${props => props.theme.colors.danger2} !important;
  }
  svg.status-processing-warning {
    color: ${props => props.theme.colors.warning2} !important;
  }
  svg.status-processing {
    color: ${props => props.theme.colors.dark} !important;
  }
  svg.status-processed {
    color: ${props => props.theme.colors.checked} !important;
  }
`;

export const StatusErrorDescription = styled.div`
  font-size: 16px;
  margin: 10px 10px 0 10px;
  border: 1px solid ${props => props.theme.colors.danger2};
  padding: 10px;
  border-radius: 6px;
`;

export const StatusWarningDescription = styled.div`
  font-size: 16px;
  margin: 10px 0;
  border: 1px solid ${props => props.theme.colors.warning2};
  padding: 10px;
  border-radius: 6px;
`;

export const StatusDoneItems = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 6px;
  .status-done-content {
    display: flex;
    flex-direction: row;
    .status-done-content-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      padding: 10px 20px;
      font-size: 16px;
      border-right: 1px solid ${props => props.theme.colors.primary};
      &:last-child {
        border-right: 0;
      }
    }
  }
  .status-done-footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 30px;
    border-top: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryHex}30;
    font-size: 16px;
    b {
      margin-right: 5px;
    }
  }
`;

export const BaseResumeDataFileUpload = styled.div`
  display: flex;
  flex-direction: column;

  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;

  .header {
    display: flex;
    justify-content: center;
    height: 30px;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryHex}30;
    font-size: 16px;
    font-weight: bold;
    align-items: center;
  }

  .content {
    display: table;
    clear: both;
    .item {
      float: left;
      width: 50%;
      padding: 5px 15px;
      p {
        font-weight: bold;
        margin-bottom: 0;
      }

      span {

      }
    }
  }
`;
