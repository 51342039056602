import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';

import {
  TextareaStyled, InputStyled, RowStyled, FormGroupStyled,
} from '~/Components/Styled';

export const Row = styled(RowStyled)`
  justify-content: space-between;
  .title-separator {
    margin-top: 20px;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    width: 100%;
  }
`;

export const RowTypeHeader = styled(Row)`
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 10px;

  > div {
    margin-right: 15px;

    > div {
      flex: 1;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`;

export const MediaHeader = styled.div`
  margin-bottom: 15px;
  margin-right: 15px;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.colors.light};
`;

export const HeaderButtonGroup = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

export const FormGroupHorizontal = styled(FormGroupStyled)`
  > label{
    padding-top: 5px;
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const ColumnVideo = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: fit-content;
`;

export const WrapContent = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  padding: 10px 10px 10px 10px;
`;

export const ListMedia = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 250px;
  max-width: 250px;
  border-right: 1px solid ${props => props.theme.colors.primary};
  padding-right: 10px;
  margin-right: 10px;
  overflow-y: auto;
`;

export const ListMediaItem = styled.div`
  display: flex;
  flex-direction: column;
  height: fit-content;
  background-color: ${props => props.theme.colors.white};
`;

export const FormMediaWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  overflow-y: auto;

  > button {
    color: ${props => props.theme.colors.danger2};
    border: 1px solid ${props => props.theme.colors.danger2};
    background-color: ${props => props.theme.colors.danger2Hex}30;
    height: 35px;
    border-radius: 3px;
  }

  .extensoes-permitidas-image {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    font-style: italic;
  }
`;

export const FormMedia = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const Select = styled(ReactSelect)`
  width: 100%;

  > div {
    border-width: 2px !important;
  }
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const Textarea = styled(TextareaStyled)`
  min-width: 100%;
  resize: none;

  &.error {
    border-color: red;
  }
`;

const getPreviewVideoBoxHasInvalid = (props) => {
  if (props.invalid) {
    return css`
      border: 1px solid ${props.theme.colors.danger2};
      color: ${props.theme.colors.danger2};
    `;
  }
  return css`
    border: 1px solid ${props.theme.colors.regular};
    color: ${props.theme.colors.regular};
  `;
};

export const PreviewVideoBox = styled.div`
  min-width: 320px;
  max-width: 320px;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  ${props => getPreviewVideoBoxHasInvalid(props)}
  ${props => props.hasVideo && css`
    background-color: #000000;
  `}
`;

export const PreviewThumbnailVideoBox = styled.div`
  min-width: 320px;
  max-width: 320px;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  border: 1px solid ${props => props.theme.colors.regular};
  color: ${props => props.theme.colors.regular};
  position: relative;
  ${props => props.hasThumbnail && css`
    background-color: #000000;
  `}
  > img {
    min-width: 320px;
    max-width: 320px;
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }
  > svg {
    position: absolute;
    color: ${props => props.theme.colors.primaryHex}90;
    font-size: 60px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.primaryHex};
    }
  }
`;

export const PreviewThumbnailBox = styled.div`
  min-width: 320px;
  max-width: 320px;
  min-height: 200px;
  max-height: 200px;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 25px;
  border: 1px solid ${props => props.theme.colors.regular};
  color: ${props => props.theme.colors.regular};
  position: relative;
  ${props => props.hasThumbnail && css`
    background-color: #000000;
  `}
  > img {
    min-width: 320px;
    max-width: 320px;
    min-height: 200px;
    max-height: 200px;
    object-fit: cover;
    object-position: center;
    z-index: 0;
  }
  > svg {
    position: absolute;
    color: ${props => props.theme.colors.primaryHex}90;
    font-size: 60px;
    z-index: 1;
    cursor: pointer;
    &:hover {
      color: ${props => props.theme.colors.primaryHex};
    }
  }

  .resolution-info {
    font-size: 17px;
    text-decoration: underline;
    color: ${props => props.theme.colors.regular};
  }
`;

export const MidiaVideoPreview = styled.video`
  min-width: 300px;
  max-width: 300px;
  min-height: 200px;
  max-height: 200px;
  outline: none;
  background-color: #000000;
`;

export const LabelXmlValid = styled.div`
  ${props => (props.isValid ? css`
    border: 1px solid ${props.theme.colors.checked};
    background-color: ${props.theme.colors.checked}30;
    span {
      color: ${props.theme.colors.checked};
    }
    .label-xml-invalid {
      display: none;
    }
  ` : css`
    border: 1px solid ${props.theme.colors.danger2Hex};
    background-color: ${props.theme.colors.danger2Hex}30;
    span {
      color: ${props.theme.colors.danger2Hex};
    }
    .label-xml-valid {
      display: none;
    }
  `)}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 30px;
  width: 100%;
  border-radius: 3px;
  margin-top: 5px;
  span {
    font-size: 15px;
  }
`;

export const FormGroup = styled(FormGroupStyled)`
  margin: 0;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }

    .info-disabled-resolution-image {
      color: ${props => props.theme.colors.darker};
      font-size: 14px;
      font-style: italic;
    }
    .info-btn-continuar {
      color: ${props => props.theme.colors.darker};
      font-size: 14px;
      font-style: italic;
      margin-left: 10px;
    }
  }
`;

export const Input = styled(InputStyled)`
  margin-right: 0;
`;

export const ContainerTabs = styled.div`
  display: flex;
  justify-content: space-between;

  .tab-has-error {
    color: red;
    margin-left: 5px;
    font-size: 12px;
  }

  .react-tabs {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    color: ${props => props.theme.colors.primary};
  }

  .react-tabs__tab-list {
    margin-bottom: 0;
    border-bottom: none;
  }

  .react-tabs__tab {
    border-color: ${props => props.theme.colors.light};
    border-bottom: 3px solid ${props => props.theme.colors.light};
    font-size: 24px;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.lighterWhite};
  }

  .react-tabs__tab.react-tabs__tab--selected {
    border-radius: 0;
    border-bottom: 3px solid #fff;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }

  .react-tabs__tab-panel--selected {
    display: flex;
    width: 100%;
    border-radius: 0 8px 8px 8px;
    border: solid 1px ${props => props.theme.colors.light};
    align-items: flex-start;
    justify-content: flex-start;
    .tab-body {
      padding: 15px;
      flex: 1;
      display: flex;
      flex-direction: column;
      width: calc(100% - 30px);
      flex-shrink: 0;
      background-color: ${props => props.theme.colors.bgBody};
    }
  }
`;

export const Imagem = styled.div`
  padding: 20px;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  position: relative;

  .success-img {
    max-width: 150px;
    position: relative;
  }

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;

export const BannerEmpty = styled.div`
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.danger2};
  font-style: italic;
  font-size: 15px;
  text-align: center;
  width: 100%;
  padding-top: 5px;
`;

export const BaseInputsGA = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  .item-ga {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    label {
      width: 150px;
      text-align: right;
      margin-right: 15px;
      font-size: 16px;
    }
    input {
      flex: 1;
    }
  }
`;

export const BaseCleanSubType = styled.div`
  flex: 1;
`;

export const BaseExternalUrlConfig = styled.div`
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 5px;

  .title-base-external-url {
    color: ${props => props.theme.colors.primary};
    margin-bottom: 0;
  }
`;

export const BaseItemsProgramatia = styled.div`
  flex: 1;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  margin-bottom: 15px;
  margin-top: 15px;
  padding: 5px;

  .title-base-items-programatica {
    color: ${props => props.theme.colors.primary};
    margin-bottom: 0;
  }

  .base-buttom-add-item {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${props => props.color};
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.danger2};
  width: 30px;
  height: 30px;
  border-radius: 5px;
  margin-left: 5px;
  outline: none;
  opacity: ${props => (props.disabled ? 0.5 : 1)};
  svg {
    font-size: 14px;
    color: ${props => props.color};
  }
`;

export const BasePerguntaQualificadora = styled.div`
  width: 100%;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;

export const BasePerguntaQualificadoraHeader = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  border-bottom: solid 1px ${props => props.theme.colors.light};
  ${props => props.invalid
    && css`
      border-bottom-color: ${props.theme.colors.danger2};
    `}
`;

export const BasePerguntaQualificadoraContent = styled.div`
  padding: 15px;
  display: flex;
  justify-content: center;
`;

export const ButtonEditForm = styled.button.attrs({ type: 'button' })`
  padding: 5px;
  height: 35px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.primary};
  border-radius: 4px;
  border: 1px solid ${props => props.theme.colors.primary};
  font-size: 16px;
  margin: 0px;
  svg {
    margin-left: 10px;
  }
`;

export const PerguntaQualificadoraList = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const PerguntaQualificadoraItem = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 0;
  font-size: 17px;

  span {
    color: ${props => props.theme.colors.primary};
    padding-top: 3px;
    margin-right: 5px;
    font-weight: bold;
  }

  svg {
    color: ${props => props.theme.colors.primary};
    margin-right: 5px;
    margin-left: 5px;
  }

  p {
    margin-bottom: 0;
  }
`;

export const FieldWithLabel = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  label {
    margin: 0;
  }
  input {
    width: 100%;
  }
`;

export const InfoLinkExternoExternalRedirect = styled.div`
    background-color: ${props => props.theme.colors.lighterWhite};
    margin-top: 5px;
    border-radius: 4px;
    padding: 5px;
    border: 1px solid ${props => props.theme.colors.regular};

    p {
      width: 100%;
      text-align: center;
      margin-bottom: 5px;
      font-size: 15px;
      text-decoration: underline;
    }

    .options-info {
      display: flex;
      justify-content: space-around;
      font-weight: bold;
    }
`;
