import React from 'react';
import PropTypes from 'prop-types';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';

import { Container } from './styles';

const ToggleComponent = ({ style, value, onChange }) => (
  <Container style={style}>
    <Toggle
      id="toggle"
      className="toggle-component"
      defaultChecked={value}
      onChange={onChange}
    />
  </Container>
);


ToggleComponent.defaultProps = {
  style: null,
  value: false,
  onChange: () => {},
};

ToggleComponent.propTypes = {
  style: PropTypes.shape(),
  value: PropTypes.bool,
  onChange: PropTypes.func,
};

export default ToggleComponent;
