import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Creators as platformSettingsActions } from '~/redux/ducks/platformSettings';

import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';

import {
  Container,
  Header,
  StyledDiv,
  HeaderGroupButton,
  Button,
  FormSection,
  FormGroup,
  Row,
  Select,
  Input,
  TextArea,
} from './styles';

import {
  SaveIcon,
} from '../../Components'

function CarrotSettings({
  navigateToRoute,
  loadSettings,
  settings,
  periodOptions,
  onChangeSettings,
  updateSettings
}) {

  function handleUpdateSettings() {
    updateSettings(settings);
  }

  useEffect(() => {
    loadSettings();
  }, []);

  return (
    <Container>
      <Header>
        <h1>Configuração de Benefícios</h1>
        <HeaderGroupButton>
          <Button onClick={() => navigateToRoute('/platform-settings')} bordered>
            <span>Voltar</span>
          </Button>
          <Button onClick={() => handleUpdateSettings()}>
            <SaveIcon/>
            <span>Salvar Alterações</span>
          </Button>
        </HeaderGroupButton>
      </Header>
      {!!settings && (
        <StyledDiv bordered>
          <FormSection>
            <h2>Limite de concessões no período</h2>
            <Row>
              <FormGroup>
                <label for="benefitsPeriod">Período de liberação:</label>
                <Select
                  id="benefitsPeriod"
                  name="benefitsPeriod"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={periodOptions}
                  value={periodOptions.find(({value}) => value === settings.packageLimits.global.period)}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      global: {
                        ...settings.packageLimits.global,
                        period: e.value
                      }
                    }
                  })}
                />
              </FormGroup>
              <FormGroup>
                <label for="benefitsConcession">Quantidade de período:</label>
                <Input
                  id="benefitsConcession"
                  name="benefitsConcession"
                  type="number"
                  style={{ marginRight: 0 }}
                  placeholder="Quantidade..."
                  value={
                    settings.packageLimits.global.periodNumber
                    ? settings.packageLimits.global.periodNumber.toString()
                    : '0'
                  }
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      global: {
                        ...settings.packageLimits.global,
                        periodNumber: Number(e.target.value)
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
            <Row style={{width: '50%'}}>
              <FormGroup>
                <label for="benefitsConcession">Quantidade máxima de concessões no período:</label>
                <Input
                  id="benefitsConcession"
                  name="benefitsConcession"
                  type="number"
                  style={{ marginRight: 0 }}
                  placeholder="Quantidade..."
                  value={settings.packageLimits.global.max.toString()}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      global: {
                        ...settings.packageLimits.global,
                        max: Number(e.target.value)
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <label for="benefitsExceeded">Mensagem de limite excedido:</label>
                <TextArea
                  id="benefitsExceeded"
                  name="benefitsExceeded"
                  rows="3"
                  value={settings.packageLimits.global.limitReachedMessage}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      global: {
                        ...settings.packageLimits.global,
                        limitReachedMessage: e.target.value
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
          </FormSection>
          <FormSection>
            <h2>Limites de pacote cenoura</h2>
            <Row>
              <FormGroup>
                <label for="carrotPeriod">Período de liberação do pacote cenoura:</label>
                <Select
                  id="carrotPeriod"
                  name="carrotPeriod"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={periodOptions}
                  value={periodOptions.find(({value}) => value === settings.packageLimits.carrot.period)}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      carrot: {
                        ...settings.packageLimits.carrot,
                        period: e.value
                      }
                    }
                  })}
                />
              </FormGroup>
              <FormGroup>
                <label for="benefitsConcession">Quantidade de período:</label>
                <Input
                  id="benefitsConcession"
                  name="benefitsConcession"
                  type="number"
                  style={{ marginRight: 0 }}
                  placeholder="Quantidade..."
                  value={
                    settings.packageLimits.carrot.periodNumber
                    ? settings.packageLimits.carrot.periodNumber.toString()
                    : '0'
                  }
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      carrot: {
                        ...settings.packageLimits.carrot,
                        periodNumber: Number(e.target.value)
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
            <Row style={{width: '50%'}}>
              <FormGroup>
                <label for="carrotConcession">Quantidade máxima de concessões no período:</label>
                <Input
                  id="carrotConcession"
                  name="carrotConcession"
                  type="number"
                  style={{ marginRight: 0 }}
                  placeholder="Quantidade..."
                  value={settings.packageLimits.carrot.max.toString()}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      carrot: {
                        ...settings.packageLimits.carrot,
                        max: Number(e.target.value)
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <label for="carrotExceeded">Mensagem de limite de concessões excedido no período:</label>
                <TextArea
                  id="carrotExceeded"
                  name="carrotExceeded"
                  rows="3"
                  value={settings.packageLimits.carrot.limitReachedMessage}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      carrot: {
                        ...settings.packageLimits.carrot,
                        limitReachedMessage: e.target.value
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
          </FormSection>
          <FormSection>
            <h2>Configurações de lista de bloqueio de pacote cenoura</h2>
            <Row>
              <FormGroup>
                <label for="carrotWithoutConversionPeriod">Tempo de permanência na lista de bloqueio:</label>
                <Select
                  id="carrotWithoutConversionPeriod"
                  name="carrotWithoutConversionPeriod"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={periodOptions}
                  value={periodOptions.find(({value}) => value === settings.packageLimits.carrotWithoutConversion.period)}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      carrotWithoutConversion: {
                        ...settings.packageLimits.carrotWithoutConversion,
                        period: e.value
                      }
                    }
                  })}
                />
              </FormGroup>
              <FormGroup>
                <label for="benefitsConcession">Quantidade de período:</label>
                <Input
                  id="benefitsConcession"
                  name="benefitsConcession"
                  type="number"
                  style={{ marginRight: 0 }}
                  placeholder="Quantidade..."
                  value={
                    settings.packageLimits.carrotWithoutConversion.periodNumber
                    ? settings.packageLimits.carrotWithoutConversion.periodNumber.toString()
                    : '0'
                  }
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      carrotWithoutConversion: {
                        ...settings.packageLimits.carrotWithoutConversion,
                        periodNumber: Number(e.target.value)
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
            <Row style={{width: '50%'}}>
              <FormGroup>
                <label for="carrotWithoutConversionConcession">Quantidade máxima de concessões sem conversão:</label>
                <Input
                  id="carrotWithoutConversionConcession"
                  name="carrotWithoutConversionConcession"
                  type="number"
                  style={{ marginRight: 0 }}
                  placeholder="Quantidade..."
                  value={settings.packageLimits.carrotWithoutConversion.max.toString()}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      carrotWithoutConversion: {
                        ...settings.packageLimits.carrotWithoutConversion,
                        max: Number(e.target.value)
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
            <Row>
              <FormGroup>
                <label for="carrotWithoutConversionExceeded">Mensagem de limite excedido:</label>
                <TextArea
                  id="carrotWithoutConversionExceeded"
                  name="carrotWithoutConversionExceeded"
                  rows="3"
                  value={settings.packageLimits.carrotWithoutConversion.limitReachedMessage}
                  onChange={e => onChangeSettings({
                    ...settings,
                    packageLimits: {
                      ...settings.packageLimits,
                      carrotWithoutConversion: {
                        ...settings.packageLimits.carrotWithoutConversion,
                        limitReachedMessage: e.target.value
                      }
                    }
                  })}
                />
              </FormGroup>
            </Row>
          </FormSection>
        </StyledDiv>
      )}
    </Container>
  )
}

CarrotSettings.defaultProps = {
  settings: null,
  periodOptions: [],
};

CarrotSettings.propTypes = {
  navigateToRoute: PropTypes.func.isRequired,
  loadSettings: PropTypes.func.isRequired,
  onChangeSettings: PropTypes.func.isRequired,
  updateSettings: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  settings: state.platformSettings.settings,
  periodOptions: state.platformSettings.periodOptions,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    navigateToRoute: RedirectActions.navigateToRoute,
    loadSettings: platformSettingsActions.loadSettings,
    onChangeSettings: platformSettingsActions.onChangeSettings,
    updateSettings: platformSettingsActions.updateSettings,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CarrotSettings);
