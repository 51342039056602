import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import lodash from 'lodash';
import moment from 'moment';

import Pagination from '~/Components/Pagination';
import Button from '~/Components/Button';
import ModalSelectAudienceType from './Components/ModalSelectAudienceType';
import ModalExternalAudience from './Components/ModalExternalAudience';
import ModalUploadProgress from './Components/ModalUploadProgress';

import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';
import { Creators as AudienceActions } from '~/redux/ducks/audience';

import {
  Container,
  Table,
  Row,
  Input,
  LinkStyled,
  Select,
  ButtonGroup,
  LinkButton,
} from './styles';

class AudienceList extends Component {
  state= {
    headerFields: {
      contract: null,
      name: '',
      type: null,
    },
    page: 0,
    modalNew: false,
  };

  componentDidMount() {
    this.loadData();
  }

  loadData = () => {
    const { audienceRequest } = this.props;
    const { headerFields } = this.state;
    audienceRequest({
      name: headerFields.name,
      contractUuid: headerFields.contract ? headerFields.contract.value : '',
      type: headerFields.type ? headerFields.type.value : '',
    });
  };

  handleDeleteAudience = (audience) => {
    const { confirmModal, audienceDeleteData } = this.props;
    confirmModal(
      'Deseja excluir a audiência?',
      audience.name,
      () => {
        audienceDeleteData(audience.uuid);
      },
    );
  };

  getTypeLabel = (type) => {
    switch (type) {
      case 'INTERNAL': return 'Interna';
      case 'EXTERNAL': return 'Externa';
      default: return '';
    }
  };

  renderRowAudience = audience => (
    <tr key={audience.uuid}>
      <td className="link">
        {audience.type === 'INTERNAL' ? (
          <LinkStyled to={`audience/${audience.uuid}/edit`}>
            {audience.name}
          </LinkStyled>
        ) : (
          <LinkStyled to={`audience/${audience.uuid}/details`}>
            {audience.name}
          </LinkStyled>
        )}
      </td>
      <td>
        <span>{audience.contract.name}</span>
      </td>
      <td>
        <span>{audience.countMap.totalImpact || 0}</span>
      </td>
      <td>
        <span>{audience.totalFilterActive || 0}</span>
      </td>
      <td>
        <span>{audience.countMap.totalUse || 0}</span>
      </td>
      <td>
        <span>{audience.user.name}</span>
      </td>
      <td>
        <span>{moment(audience.createdAt).format('DD/MM/YYYY HH:mm')}</span>
      </td>
      <td>
        <span>{moment(audience.updatedAt).format('DD/MM/YYYY HH:mm')}</span>
      </td>
      <td>
        <span>{this.getTypeLabel(audience.type)}</span>
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            bordered
            title="EXCLUIR"
            onClick={() => this.handleDeleteAudience(audience)}
          />
        </div>
      </td>
    </tr>
  );

  renderAudiences = () => {
    const { audiences } = this.props;
    const { page } = this.state;
    const audienceList = lodash.chunk(audiences, 20);

    return (
      <>
        <Table>
          <thead>
            <tr>
              <th>NOME</th>
              <th>CONTRATO</th>
              <th>TOTAL IMPACTO</th>
              <th>FILTROS ATIVOS</th>
              <th>USO</th>
              <th>AUTOR</th>
              <th>CRIAÇÃO</th>
              <th>ÚLTIMA ALTERAÇÃO</th>
              <th>TIPO</th>
              <th style={{ width: 250 }} />
            </tr>
          </thead>
          <tbody>
            {audienceList.length < 1 ? (
              <tr>
                <td colSpan="10">
                  <div className="empty-table-desc">
                    <span> Não foram localizados registros de audiências.</span>
                  </div>
                </td>
              </tr>
            ) : (
              audienceList[page].map(audience => this.renderRowAudience(audience))
            )}
          </tbody>
        </Table>
        <Row>
          <Pagination
            page={parseInt(page)}
            pageCount={audienceList.length}
            pageChange={(e) => {
              this.setState({
                page: parseInt(e.selected),
              });
            }}
          />
        </Row>
      </>
    );
  };

  renderContractSelect = () => {
    const { audiences } = this.props;
    const { headerFields } = this.state;
    const contractList = [];

    audiences.forEach((item) => {
      if (!contractList.find(contractItem => contractItem.value === item.contract.uuid)) {
        contractList.push({
          value: item.contract.uuid,
          label: item.contract.name,
        });
      }
    });

    return (
      <Select
        placeholder="Selecione Contrato..."
        value={headerFields.contract}
        onChange={(item) => {
          this.setState({
            headerFields: {
              ...headerFields,
              contract: item,
            },
          });
        }}
        options={contractList}
        isClearable
      />
    );
  };

  renderTypeSelect = () => {
    const { headerFields } = this.state;
    const typeList = [
      { value: 'INTERNAL', label: 'Interna' },
      { value: 'EXTERNAL', label: 'Externa' },
    ];
    return (
      <Select
        placeholder="Selecione Tipo..."
        value={headerFields.type}
        onChange={(item) => {
          this.setState({
            headerFields: {
              ...headerFields,
              type: item,
            },
          });
        }}
        options={typeList}
        isClearable
      />
    );
  };

  renderContractName = () => {
    const { headerFields } = this.state;
    return (
      <Input
        placeholder="Procurar por nome"
        value={headerFields.name}
        onKeyPress={(e) => {
          if (e.key === 'Enter') {
            this.loadData();
          }
        }}
        onChange={(e) => {
          this.setState({
            headerFields: {
              ...headerFields,
              name: e.target.value,
            },
          });
        }}
      />
    );
  };

  render() {
    const { modalNew } = this.state;
    const {
      audienceExternalData,
      audienceExternalUploadData,
      user,
      navigateToRoute,
      audienceSetExternalData,
    } = this.props;
    const hasPermissionExternalAudiente = user.roles.find(item => item.name === 'EXTERNAL_TYPE_CREATOR');
    const hasPermissionInternalAudiente = user.roles.find(item => item.name === 'INTERNAL_TYPE_CREATOR');

    return (
      <>
        <Container>
          <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
            <h1>AUDIÊNCIAS</h1>
            <Button
              bordered
              onClick={() => {
                if (hasPermissionExternalAudiente && hasPermissionInternalAudiente) {
                  this.setState({
                    modalNew: true,
                  });
                } else if (hasPermissionExternalAudiente) {
                  audienceSetExternalData({
                    name: '',
                    file: null,
                    progress: null,
                  });
                } else if (hasPermissionInternalAudiente) {
                  navigateToRoute('audience/new');
                }
              }}
              title="NOVA AUDIÊNCIA"
            />
          </Row>
          <Row>
            <div className="box-search">
              {this.renderContractName()}
              {this.renderContractSelect()}
              {this.renderTypeSelect()}
              <ButtonGroup>
                <LinkButton
                  onClick={() => {
                    this.loadData();
                  }}
                >
                  Buscar
                </LinkButton>
                <Button
                  icon="FaTimes"
                  noLabel
                  onClick={() => {
                    this.setState({
                      headerFields: {
                        contract: null,
                        name: '',
                      },
                      page: 0,
                    }, () => {
                      this.loadData();
                    });
                  }}
                />
              </ButtonGroup>
            </div>
          </Row>
          {this.renderAudiences()}
          {modalNew && (
            <ModalSelectAudienceType
              onClose={() => {
                this.setState({
                  modalNew: false,
                });
              }}
            />
          )}
          {audienceExternalData && <ModalExternalAudience />}
          {audienceExternalUploadData && <ModalUploadProgress />}
        </Container>
      </>
    );
  }
}

AudienceList.defaultProps = {
  audienceExternalData: null,
  audienceExternalUploadData: null,
};

AudienceList.propTypes = {
  confirmModal: PropTypes.func.isRequired,
  audienceRequest: PropTypes.func.isRequired,
  audienceDeleteData: PropTypes.func.isRequired,
  navigateToRoute: PropTypes.func.isRequired,
  audiences: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  audienceExternalData: PropTypes.shape(),
  audienceExternalUploadData: PropTypes.shape(),
  user: PropTypes.shape().isRequired,
  audienceSetExternalData: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  audiences: state.audience.audiences,
  user: state.auth.currentUser,
  audienceExternalData: state.audience.audienceExternalData,
  audienceExternalUploadData: state.audience.audienceExternalUploadData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    confirmModal: ConfirmModalActions.showConfirmModal,
    navigateToRoute: RedirectActions.navigateToRoute,
    audienceRequest: AudienceActions.audienceRequest,
    audienceDeleteData: AudienceActions.audienceDeleteData,
    audienceSetExternalData: AudienceActions.audienceSetExternalData,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AudienceList);
