import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  auctionSetup: null,
  auctionRequest: null,
  auctionRequestSuccess: ['auction'],
  auctionChannelRequestSuccess: ['channelList'],
  auctionPeriodRequestSuccess: ['periodList'],
  auctionSetFilter: ['filter'],
  auctionSetDataGridUpdate: ['data'],
  auctionGridUpdate: null,
  auctionRankingUpdate: null,
  auctionGetTermPreview: ['gridItemUuid'],
  auctionGetTermPreviewSuccess: ['termPreview'],
});

export const AuctionTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_FILTER_AUCTION = {
  channel: null,
  period: null,
};
const INITIAL_STATE = Immutable({
  channelList: [],
  periodList: [],
  auction: null,
  auctionFilter: INITIAL_FILTER_AUCTION,
  dataGridUpdate: null,
  termPreview: null,
});

/* Reducers */
const auctionRequestSuccess = (state, { auction }) => ({
  ...state,
  auction,
});
const auctionChannelRequestSuccess = (state, { channelList }) => ({
  ...state,
  channelList,
});
const auctionPeriodRequestSuccess = (state, { periodList }) => ({
  ...state,
  periodList,
});
const auctionSetFilter = (state, { filter }) => ({
  ...state,
  auctionFilter: {
    ...state.auctionFilter,
    ...filter,
  },
});
const auctionSetDataGridUpdate = (state, { data }) => ({
  ...state,
  dataGridUpdate: data,
});
const auctionGetTermPreviewSuccess = (state, { termPreview }) => ({
  ...state,
  termPreview,
});

export const reducer = createReducer(INITIAL_STATE, {
  [AuctionTypes.AUCTION_REQUEST_SUCCESS]: auctionRequestSuccess,
  [AuctionTypes.AUCTION_CHANNEL_REQUEST_SUCCESS]: auctionChannelRequestSuccess,
  [AuctionTypes.AUCTION_PERIOD_REQUEST_SUCCESS]: auctionPeriodRequestSuccess,
  [AuctionTypes.AUCTION_SET_FILTER]: auctionSetFilter,
  [AuctionTypes.AUCTION_SET_DATA_GRID_UPDATE]: auctionSetDataGridUpdate,
  [AuctionTypes.AUCTION_GET_TERM_PREVIEW_SUCCESS]: auctionGetTermPreviewSuccess,
});
