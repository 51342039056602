import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '~/Components/Modal';

import {
  Container,
  Table,
  Row,
  Input,
  LinkStyled,
  ButtonGroup,
  LinkButton,
  TitleItemExclude,
} from './styles';

import Button from '~/Components/Button';

import { push as pushRouter } from 'connected-react-router';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ProviderActions } from '~/redux/ducks/provider';

class ProviderList extends Component {
  state = {
    showDeleteProvider: false,
    providerDelete: null,
  };

  static propTypes = {
    providers: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    filter: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    loadProviderList: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
    filterProviderList: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    deleteProvider: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadProviderList } = this.props;
    loadProviderList();
  }

  onHandleExcluirProvider = (provider) => {
    this.setState({ showDeleteProvider: true, providerDelete: provider });
  };

  renderDeleteProvider = () => {
    const { providerDelete } = this.state;
    const { deleteProvider } = this.props;
    return (
      <Modal>
        <Row>
          <h1>Deseja excluir o provedor?</h1>
        </Row>
        <Row style={{ justifyContent: 'center' }}>
          <TitleItemExclude>{providerDelete.name}</TitleItemExclude>
        </Row>
        <Row style={{ justifyContent: 'space-between', marginBottom: '0px' }}>
          <Button
            style={{ marginLeft: 0 }}
            color="light"
            title="CANCELAR"
            onClick={() => this.setState({ showDeleteProvider: false, providerDelete: null })}
          />
          <Button
            title="CONFIRMAR EXCLUSÃO"
            onClick={() => {
              deleteProvider(providerDelete.uuid);
              this.setState({ showDeleteProvider: false, providerDelete: null });
            }}
          />
        </Row>
      </Modal>
    );
  };

  renderRowProvider = provider => (
    <tr key={provider.uuid}>
      <td className="link">
        <LinkStyled to={`providers/edit/${provider.uuid}`}>{provider.name}</LinkStyled>
      </td>
      <td>
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Button
            bordered
            title="EXCLUIR"
            onClick={() => this.onHandleExcluirProvider(provider)}
          />
        </div>
      </td>
    </tr>
  );

  renderTableProviders = () => {
    const { providers } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th>NOME DO PROVEDOR</th>
            <th style={{ width: 125 }} />
          </tr>
        </thead>
        <tbody>
          {providers.length < 1 && (
            <tr>
              <td colSpan="5">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de provedores</span>
                </div>
              </td>
            </tr>
          )}
          {providers.length > 0 && providers.map(contract => this.renderRowProvider(contract))}
        </tbody>
      </Table>
    );
  };

  render() {
    const { filter, updateFilter, filterProviderList, push, onResetSearch  } = this.props;
    const { showDeleteProvider } = this.state;
    return (
      <>
      <Container>
        <Row style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h1>Lista de Provedores</h1>
          <Button
              bordered
              onClick={() => {
                push('providers/new');
              }}
              title="NOVO PROVEDOR"
            />
             </Row>
            <Row>
            <div className="box-search">
            <Input
              placeholder="Procurar por nome"
              value={filter.name}
              onChange={e => updateFilter({
                ...filter,
                name: e.target.value,
              })
              }
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  filterProviderList(filter.name)
                }
              }}
            />
             <ButtonGroup>
            {/* <Button title="BUSCAR" onClick={() => filterProviderList()}/> */}
             <LinkButton
                  onClick={() => {
                    filterProviderList()
                  }}
                >
                  Buscar
                </LinkButton>
                <Button
                  icon="FaTimes"
                  noLabel
                  onClick={() => {
                    onResetSearch();
                  }}
                />
            </ButtonGroup>
          </div>
        </Row>
        {this.renderTableProviders()}
        {showDeleteProvider && this.renderDeleteProvider()}
      </Container>
       </>
    );
  }
}

const mapStateToProps = state => ({
  providers: state.provider.providers,
  filter: state.provider.filter,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadProviderList: ProviderActions.loadProviderList,
    updateFilter: ProviderActions.updateFilter,
    filterProviderList: ProviderActions.filterProviderList,
    deleteProvider: ProviderActions.deleteProvider,
    push: pushRouter,
    onResetSearch: ProviderActions.resetSearchProvider,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ProviderList);
