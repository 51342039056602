import styled from 'styled-components';
import {
  PreviewMessageBox as PreviewMessageBoxStyled,
  PreviewMessageBoxFooter as PreviewMessageBoxFooterStyled,
  PreviewMessageBoxContentMenu as PreviewMessageBoxContentMenuStyled,
  PreviewMessageBoxContent as PreviewMessageBoxContentStyled,
} from '../../styles';

export const PreviewMessageBox = styled(PreviewMessageBoxStyled)``;
export const PreviewMessageBoxFooter = styled(PreviewMessageBoxFooterStyled)``;

export const PreviewMessageBoxContentMenu = styled(PreviewMessageBoxContentMenuStyled)``;
export const PreviewMessageBoxContent = styled(PreviewMessageBoxContentStyled)``;
