import styled, { css } from 'styled-components';

import { transparentize } from 'polished';

import { ContainerStyled, InputStyled } from '~/Components/Styled';
import ButtonStyled from '~/Components/Button';

export const Container = styled(ContainerStyled)`
`;

export const Row = styled.div`
  width: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
`;

export const Title = styled.h1`
  flex: 1;
`;

export const Input = styled(InputStyled)``;

export const Button = styled(ButtonStyled)``;

export const FormHeader = styled.div`
  width: 100%;
`;

export const FormItem = styled.div`
  flex: ${props => props.flex || 1};
  display: flex;
  flex-direction: column;

  & + div {
    margin-left: 15px;
  }

  > input {
    margin-right: 0;
  }

  .base-periodo {
    display: flex;
    align-items: center;

    > span {
      margin-right: 5px;

      div + & {
        margin-left: 5px;
      }
    }

    .react-datepicker-popper {
      > div {
        zoom: 180% !important;
      }
    }
    .react-datepicker__input-container {
      input {
        border: solid 2px ${props => props.theme.colors.light};
        border-radius: 4px;
        background-color: transparent;
        font-size: 16px;
        font-weight: 400;
        color: ${props => props.theme.colors.dark};
        padding: 5px 15px;
      }
    }
    .react-datepicker__day--selected {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white};
      font-weight: bold;
    }

    > input {
      border: solid 2px ${props => props.theme.colors.light};
      border-radius: 4px;
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      color: ${props => props.theme.colors.dark};
      padding: 5px 15px;
      width: 145px;
    }
    }
  }
`;

export const HeaderConfig = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 1px solid ${props => props.theme.colors.dark};

  padding-bottom: 5px;

  .base-mode-buttons-view {
    display: flex;
    margin-right: 5px;
  }
`;

export const ButtonModeView = styled.div`
  width: 80px;
  height: 50px;

  padding: 3px;

  border: 3px solid ${props => props.theme.colors.regular};

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;

  div + & {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  display: flex;

  cursor: pointer;

  .item-first {
    background-color: ${props => props.theme.colors.lighter};
    border: 3px solid ${props => props.theme.colors.regular};
    height: 100%;
    flex: 2;
  }

  .item-second{
    background-color: ${props => props.theme.colors.lighter};
    border: 3px solid ${props => props.theme.colors.regular};
    height: 100%;
    flex: 1;
    margin-left: 3px;
  }

  ${props => props.selected && css`
    background-color: ${transparentize(0.7, props.theme.colors.primary)};
    border-color: ${props.theme.colors.primary};
  `}
`;

export const Config = styled.div`
  flex: 1;
  display: flex;
`;
