import styled from 'styled-components';
import {
  RowStyled,
  ContainerStyled,
  FormGroupStyled,
  InputStyled,
  TableStyled,
} from '~/Components/Styled';


export const Wrap = styled(ContainerStyled)`

  .campaign {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 2px;
    padding: 20px;
    width: 100%;
  }

  .campaign-desc {
    h1 {
      margin-bottom: 5px;
    }
    span {
      font-size: 18px;
      font-weight: 200;
      color: ${props => props.theme.colors.regular};
    }
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const BaseLinkHistoryCampaign = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const LinkHistoryCampaign = styled.a`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  &:hover, &:focus {
    color: ${props => props.theme.colors.primary};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CampaignData = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CampaignDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  flex: 1;
`;

export const CampaignDataPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  margin: 0 0 10px 0;
  label {
    > b {
      display: inline-block;
    }
  }
`;

export const Input = styled(InputStyled)``;

export const Table = styled(TableStyled)``;
