import styled from 'styled-components';
import {
  RowStyled,
  TextareaStyled,
  FormGroupStyled,
} from '~/Components/Styled';


export const Container = styled.div`
  display: inline;
`;

export const CloseModal = styled.img`
  cursor: pointer;
  width: 20px;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
`;

export const FormGroup = styled(FormGroupStyled)`
  width: 100%;
  margin-right: ${props => (props.spaceRight ? props.spaceRight : 0)};
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Textarea = styled(TextareaStyled)`
  width: 100%;
`;
