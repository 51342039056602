import AWS from 'aws-sdk';

// const albumBucketName = 'portal-artemis-static.wifioi.com.br';
// const bucketRegion = 'us-east-1';
// const IdentityPoolId = 'us-east-1:6513bc72-9578-4438-81d4-716b8713f62c';

// const albumBucketName = 'artemis-hml-files-static.mobicare.com.br';
// const bucketRegion = 'us-west-2';
// const IdentityPoolId = 'us-west-2:3fedd0da-7e26-4c9d-8d85-f53feabdd518';

const albumBucketName = 'artemis-prd-files-static.mobicare.com.br';
const bucketRegion = 'us-east-1';
const IdentityPoolId = 'us-east-1:9dbf926a-0d1a-40b6-8931-7620a77d13aa';
export const baseUrlMedia = 'https://s3.amazonaws.com/artemis-prd-files-static.mobicare.com.br';

AWS.config.update({
  region: bucketRegion,
  credentials: new AWS.CognitoIdentityCredentials({
    IdentityPoolId,
  }),
});

const s3 = new AWS.S3({
  apiVersion: '2006-03-01',
  params: { Bucket: albumBucketName },
});

const upload = (photoKey, file) => new Promise((resolve, reject) => {
  s3.upload(
    {
      Key: photoKey,
      Body: file,
      ACL: 'public-read',
    },
    (err, data) => {
      if (err) {
        reject(err);
      } else {
        resolve(data.Location);
      }
    },
  );
});

export default {
  upload,
};
