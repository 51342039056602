import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import { ContainerStyled, RowStyled } from '~/Components/Styled';

export const Container = styled(ContainerStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  h1 {
    white-space: nowrap;
  }
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const CardsBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`;

export const ChartsBox = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 20px;
`;

export const Select = styled(ReactSelect)`
  width: 180px;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const BaseEmptyData = styled.div`
  flex: 1;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px dashed ${props => props.theme.colors.primary};
  border-radius: 15px;
`;
export const InfoEmptyData = styled.p`
  font-size: 18px;
  font-style: italic;
`;
