/* eslint-disable no-plusplus */
import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';
import moment from 'moment';
import FileSaver from 'file-saver';

import { Creators as CampaignActions } from '~/redux/ducks/campaign';
import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import CampaignService from '~/services/CampaignService';
import ReportTemplateService from '~/services/ReportTemplateService';
import CampaignEditService from '~/services/CampaignEditService';
import AudienceService from '~/services/AudienceService';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import CreativeName from '~/Utils/CreativeName';
import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';
import FileSizeConfigService from '~/services/FileSizeConfigService';
import BenefitService from '~/services/BenefitService';

const TAG_URL_VIDEO_TEMPLATE_XML_VAST = '{{VIDEO_URL}}';
const TAG_URL_LINK_CLICK_XML_VAST = '{{LINK_URL}}';
const TAG_TEMPLATE_CLICK_URL_VAST = '{{TEMPLATE_CLICK_URL}}';

const TEMPLATE_XML_VAST = `<VAST version="2.0"><Ad id="123123"><InLine><AdSystem version="1.0">Vast-Media-Artemis</AdSystem><AdTitle></AdTitle><Impression>http://artemis.event.vast.example?track=impression</Impression><Creatives><Creative><Linear><TrackingEvents><Tracking event="start">http://artemis.event.vast.example?track=start</Tracking><Tracking event="firstQuartile">http://artemis.event.vast.example?track=firstQuartile</Tracking><Tracking event="midpoint">http://artemis.event.vast.example?track=midpoint</Tracking><Tracking event="thirdQuartile">http://artemis.event.vast.example?track=thirdQuartile</Tracking><Tracking event="complete">http://artemis.event.vast.example?track=complete</Tracking></TrackingEvents>${TAG_TEMPLATE_CLICK_URL_VAST}<MediaFiles><MediaFile delivery="progressive" height="480" type="video/mp4" width="720"><![CDATA[${TAG_URL_VIDEO_TEMPLATE_XML_VAST}]]></MediaFile></MediaFiles></Linear></Creative></Creatives></InLine></Ad></VAST>`;
const TEMPLATE_CLICK_EVENT = `<VideoClicks><ClickThrough><![CDATA[${TAG_URL_LINK_CLICK_XML_VAST}]]></ClickThrough></VideoClicks>`;

function makeContentVastMediaVideo(contentUrlVideo, externalUrl) {
  let vast = TEMPLATE_XML_VAST.replace(TAG_URL_VIDEO_TEMPLATE_XML_VAST, contentUrlVideo);
  if (externalUrl && externalUrl.url) {
    const clickEventTag = TEMPLATE_CLICK_EVENT.replace(TAG_URL_LINK_CLICK_XML_VAST, externalUrl.url);
    vast = vast.replace(TAG_TEMPLATE_CLICK_URL_VAST, clickEventTag);
  } else {
    vast = vast.replace(TAG_TEMPLATE_CLICK_URL_VAST, '');
  }
  return vast;
}

const isVast = (content) => {
  if (
    content
    && content.mainData
    && content.mainData.media
    && content.mainData.media.type === CreativeCustomMidiaType.VAST
  ) {
    return true;
  }
  return false;
};

export function* campaignEditSaveOrcamentoInEdit() {
  const { campaignOrcamentoToEdit } = yield select(state => state.campaignEdit);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      CampaignEditService.campaignEditSaveOrcamento,
      campaignOrcamentoToEdit,
    );

    yield put(CampaignActions.loadCampaignEdit(campaignOrcamentoToEdit.uuid));
    yield put(CampaignEditActions.campaignEditChangeModalEditStatus({
      editOrcamentoStatus: false,
    }));
    yield put(CampaignEditActions.campaignEditSetOrcamentoToEdit(null));

    // Stop loading
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'O orçamento foi atualizado com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao salvar o orçamento. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditSavePeriodoInEdit() {
  const { campaignPeriodoToEdit, modalEdit: { modeDisabledStartDate } } = yield select(
    state => state.campaignEdit,
  );

  const startDate = moment(
    `${campaignPeriodoToEdit.startDate} ${campaignPeriodoToEdit.startHour || '00:00'}`,
    'DD/MM/YYYY HH:mm',
  );
  if (campaignPeriodoToEdit.startDate && !startDate.isValid()) {
    yield put(ToastrMessageActions.warningMessage(
      'Atenção!',
      'Data inicial inválida!',
    ));
    return;
  }
  const endDate = moment(
    `${campaignPeriodoToEdit.endDate} ${campaignPeriodoToEdit.endHour || '00:00'}`,
    'DD/MM/YYYY HH:mm',
  );
  if (campaignPeriodoToEdit.endDate && !endDate.isValid()) {
    yield put(ToastrMessageActions.warningMessage(
      'Atenção!',
      'Data final inválida!',
    ));
    return;
  }

  if (!modeDisabledStartDate) {
    const now = moment();
    if (startDate.isBefore(now)) {
      yield put(ToastrMessageActions.warningMessage(
        'Atenção!',
        'Data inicial precisa ser maior que a data atual!',
      ));
      return;
    }
  }
  if (endDate.isBefore(startDate)) {
    yield put(ToastrMessageActions.warningMessage(
      'Atenção!',
      'Data inicial precisa ser menor que a Data final!',
    ));
    return;
  }

  // Start loading
  yield put(startRequest());
  try {
    if (modeDisabledStartDate) {
      yield call(
        CampaignEditService.campaignEditSavePeriodoEndDate,
        campaignPeriodoToEdit,
      );
    } else {
      yield call(
        CampaignEditService.campaignEditSavePeriodo,
        campaignPeriodoToEdit,
      );
    }

    yield put(CampaignActions.loadCampaignEdit(campaignPeriodoToEdit.uuid));
    yield put(CampaignEditActions.campaignEditChangeModalEditStatus({
      editPeriodoStatus: false,
    }));
    yield put(CampaignEditActions.campaignEditSetPeriodoToEdit(null));

    // Stop loading
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'O período foi atualizado com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao salvar o período. Tente novamente!',
    ));
  }
}

export function* campaignEditSaveBriefingInEdit() {
  const { campaignBriefingToEdit } = yield select(state => state.campaignEdit);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      CampaignEditService.campaignEditSaveBriefing,
      campaignBriefingToEdit,
    );

    yield put(CampaignActions.loadCampaignEdit(campaignBriefingToEdit.uuid));
    yield put(CampaignEditActions.campaignEditChangeModalEditStatus({
      editBriefingStatus: false,
    }));
    yield put(CampaignEditActions.campaignEditSetBriefingToEdit(null));

    // Stop loading
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'O briefing foi atualizado com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao salvar o briefing. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditSaveAttributeInEdit() {
  const { campaignAttributeToEdit } = yield select(state => state.campaignEdit);

  const { campaignUuid, attribute: { uuid: attributeUuid, value } } = campaignAttributeToEdit;
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      CampaignService.updateValueAttributeCampaign,
      {
        campaignUuid,
        attributeUuid,
        value,
      },
    );

    yield put(CampaignActions.loadCampaignEdit(campaignUuid));
    yield put(CampaignEditActions.campaignEditChangeModalEditStatus({
      editAttributeStatus: false,
    }));
    yield put(CampaignEditActions.campaignEditSetAttributeToEdit(null));

    // Stop loading
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'O atributo foi atualizado com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao salvar o atributo. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditBriefingDownload({ uuid }) {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(
      CampaignEditService.campaignEditDownloadBriefing,
      uuid,
    );

    // Download file
    const blob = new Blob(
      [response],
      {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      },
    );
    FileSaver.saveAs(blob, `${uuid}.xlsx`);

    // Stop loading
    yield put(requestSuccess());
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao baixar o aquivo.',
    ));
    yield put(requestError());
  }
}

export const SUCCESS_BUTTON_ACTION_TEMPLATE = {
  label: '',
  action: {
    type: 'link',
    callback: '',
  },
};
export const SUCCESS_AUTO_REDIRECT_TEMPLATE = {
  url: '',
  waitSeconds: 0,
};

function internalSuccessAjustEdit(content) {
  if (content && content.mainData && content.mainData.type === 'internal' && content.mainData.success) {
    const { success } = content.mainData;
    // BUTTON ACTIONS ---------------------------------------------
    let buttonAction1 = SUCCESS_BUTTON_ACTION_TEMPLATE;
    let buttonAction2 = SUCCESS_BUTTON_ACTION_TEMPLATE;
    const buttons = success.buttons || [];
    if (buttons && buttons.length > 0) {
      if (buttons.length >= 1) {
        // eslint-disable-next-line prefer-destructuring
        buttonAction1 = buttons[0];
      }
      if (buttons.length >= 2) {
        // eslint-disable-next-line prefer-destructuring
        buttonAction2 = buttons[1];
      }
    }
    delete success.buttons;

    // BUTTON ACTIONS ---------------------------------------------
    // AUTO REDIRECT URL ------------------------------------------
    const autoRedirectUrl = success.autoRedirectUrl || SUCCESS_AUTO_REDIRECT_TEMPLATE;
    // AUTO REDIRECT URL ------------------------------------------
    content.mainData.success = {
      ...success,
      buttonAction1,
      buttonAction2,
      autoRedirectUrl,
    };
  }
  return content;
}

function internalSuccessAjustSave(content) {
  if (content && content.mainData && content.mainData.type === 'internal' && content.mainData.success) {
    const { success } = content.mainData;
    // BUTTON ACTIONS ---------------------------------------------
    const { buttonAction1, buttonAction2 } = success;
    const buttons = [];
    if (buttonAction1 && buttonAction1.label) {
      buttons.push({ ...buttonAction1 });
    }
    if (buttonAction2 && buttonAction2.label) {
      buttons.push({ ...buttonAction2 });
    }
    delete success.buttonAction1;
    delete success.buttonAction2;
    success.buttons = buttons;
    // BUTTON ACTIONS ---------------------------------------------
    // AUTO REDIRECT URL ------------------------------------------
    if (success.autoRedirectUrl && !success.autoRedirectUrl.url) {
      delete success.autoRedirectUrl;
    }
    // AUTO REDIRECT URL ------------------------------------------
  }
  return content;
}

export function* campaignEditCriativoRequest({ uuid }) {
  // Start loading
  yield put(startRequest());
  try {
    yield put(CampaignEditActions.campaignEditCriativoRequestSuccess(null));
    const {
      content, contentVersion, creativeDefinition, ...rest
    } = yield call(
      CampaignEditService.campaignEditCriativoRequest,
      uuid,
    );

    let responseContent = content;
    if (typeof content === 'string' || content instanceof String) {
      responseContent = JSON.parse(content);
    }

    if (creativeDefinition.name === CreativeName.CREATIVE_CUSTOM || creativeDefinition.name === CreativeName.CHALLENGE) {
      let config = creativeDefinition.schema;
      let creative;

      if (typeof config === 'string' || config instanceof String) {
        config = JSON.parse(config);
      }

      const { creatives } = config;

      if (responseContent.mainData && responseContent.mainData.type) {
        creative = creatives.find(c => c.type === responseContent.mainData.type);
      }

      if (!creative && creatives.length === 1) {
        if (!responseContent.mainData) {
          responseContent.mainData = { type: '' };
        }
        // eslint-disable-next-line prefer-destructuring
        creative = creatives[0];
        responseContent.mainData.type = creative.type;
      }

      const questionsGetBenefits = [];

      if (responseContent.mainData && responseContent.mainData.media && responseContent.mainData.media.length > 0) {
        responseContent.mainData.media.forEach((media) => {
          if (media.type === CreativeCustomMidiaType.APP_INSTALL || media.type === CreativeCustomMidiaType.EXTERNAL_REDIRECT) {
            if (typeof media.content === 'string' || media.content instanceof String) {
              try {
                media.content = JSON.parse(media.content);
              } catch {}
            }
          } else if (media.type === CreativeCustomMidiaType.VAST && media.modeVideo) {
            media.type = CreativeCustomMidiaType.VIDEO;
            media.content = media.originalContent;
          }
          if (!!media.fallbackNoFill && media.fallbackNoFill.type === CreativeCustomMidiaType.VAST && media.fallbackNoFill.modeVideo) {
            media.fallbackNoFill.type = CreativeCustomMidiaType.VIDEO;
            media.fallbackNoFill.content = media.fallbackNoFill.originalContent;
          }

          if (media.type === CreativeCustomMidiaType.DATA_SEARCH) {
            const { questions } = media.content;
            if (questions && questions.length > 0) {
              const hasBenefitsQuestions = questions.find(q => q.hasBenefit);
              if (hasBenefitsQuestions) {
                questions.forEach((question) => {
                  if (question.hasBenefit) {
                    questionsGetBenefits.push({
                      mediaUuid: media.uuid,
                      questionUuid: question.uuid,
                    });
                  }
                });
              }
            }
          }
        });
      }

      if (questionsGetBenefits && questionsGetBenefits.length) {
        const benefitsResponse = yield call(BenefitService.benefitRequest);
        for (let iQuestion = 0; iQuestion < questionsGetBenefits.length; iQuestion++) {
          const { mediaUuid, questionUuid } = questionsGetBenefits[iQuestion];

          const benefitsResult = yield call(CampaignEditService.campaignGetBenefitQuiz, uuid, mediaUuid, questionUuid);
          if (benefitsResult && benefitsResult.rows && benefitsResult.rows.length) {
            const { rows: benefitsQuiz } = benefitsResult;

            const benefits = benefitsQuiz.map((bq) => {
              const benefitsFilter = benefitsResponse.filter(b => b.benefitOffers.find(bo => bo.uuid === bq.benefitOfferUuid));

              const benefit = benefitsFilter[0];
              const benefitOffer = benefit.benefitOffers.find(bo => bo.uuid === bq.benefitOfferUuid);

              return {
                benefit: {
                  uuid: benefit.uuid,
                  name: benefit.name,
                },
                benefitOffer: {
                  uuid: benefitOffer.uuid,
                  name: benefitOffer.name,
                },
              };
            });

            const questionUpdate = responseContent.mainData.media.find(m => m.uuid === mediaUuid).content.questions.find(q => q.uuid === questionUuid);
            questionUpdate.benefits = benefits;
          }
        }
      }

      responseContent = internalSuccessAjustEdit(responseContent);

      yield put(requestSuccess());
      yield put(CampaignEditActions.campaignEditCriativoRequestSuccess({
        ...rest,
        content: responseContent,
        contentVersion,
        creativeDefinition,

        config,
        creative,
      }));
      yield put(requestSuccess());
    } else {
      if (creativeDefinition.name !== CreativeName.CREATIVE_CUSTOM && isVast(responseContent)) {
        let contentMediaData = '';
        try {
          contentMediaData = atob(responseContent.mainData.media.content);
        } catch (error) {
          contentMediaData = responseContent.mainData.media.content;
        }
        responseContent.mainData.media.content = contentMediaData;
      }

      let responseSchema;

      if (creativeDefinition.name === CreativeName.RCS_CLARO) {
        const config = creativeDefinition.schema;

        if (typeof config === 'string' || config instanceof String) {
          creativeDefinition.schema = JSON.parse(config);
        }
        responseSchema = creativeDefinition.schema;
      }


      if (creativeDefinition.name !== CreativeName.CREATIVE_CUSTOM) {
        if (responseContent.mainData && responseContent.mainData.media && responseContent.mainData.media.length > 0) {
          responseContent.mainData.media.forEach((media) => {
            if (media.type === CreativeCustomMidiaType.VAST && media.modeVideo) {
              media.type = CreativeCustomMidiaType.VIDEO;
              media.content = media.originalContent;
            }
            if (!!media.fallbackNoFill && media.fallbackNoFill.type === CreativeCustomMidiaType.VAST && media.fallbackNoFill.modeVideo) {
              media.fallbackNoFill.type = CreativeCustomMidiaType.VIDEO;
              media.fallbackNoFill.content = media.fallbackNoFill.originalContent;
            }
          });
        }
      }

      if (creativeDefinition.name === CreativeName.NO_CREDIT_BANNER_TEXT) {
        yield put(CampaignEditActions.campaignEditNoCreditPreviewRequestSuccess(null));
        yield put(CampaignEditActions.campaignEditNoCreditPreviewRequest(uuid));
      }
      if (creativeDefinition.name === CreativeName.NO_DATA_BANNER_TEXT) {
        yield put(CampaignEditActions.campaignEditNoDataPreviewRequestSuccess(null));
        yield put(CampaignEditActions.campaignEditNoDataPreviewRequest(uuid));
      }
      if (creativeDefinition.name === CreativeName.MIDIA_WEB) {
        yield put(CampaignEditActions.campaignEditNoCreditPreviewRequestSuccess(null));
        yield put(CampaignEditActions.campaignEditNoCreditPreviewRequest(uuid));
      }

      if (
        creativeDefinition.name === CreativeName.SMART_MESSAGE_TEXT
        || creativeDefinition.name === CreativeName.SMART_MESSAGE_TEXT_CLARO
        || creativeDefinition.name === CreativeName.SMART_MESSAGE_MENU
        || creativeDefinition.name === CreativeName.SMART_MESSAGE_MENU_TIM
        || creativeDefinition.name === CreativeName.USSD_MESSAGE_TEXT
        || creativeDefinition.name === CreativeName.USSD_MESSAGE_TEXT_CLARO
        || creativeDefinition.name === CreativeName.VOICE_MESSAGE_TEXT
        || creativeDefinition.name === CreativeName.SATPUSH_CARD_TRIPLO
      ) {
        if (
          typeof creativeDefinition.schema === 'string'
          || creativeDefinition.schema instanceof String
        ) {
          try {
            responseSchema = JSON.parse(creativeDefinition.schema);
            if (contentVersion === 2) {
              const newContent = {};
              try {
                responseSchema.screens.forEach((scSchema) => {
                  const config = responseContent[scSchema.id];
                  if (scSchema.options && scSchema.options.length > 0) {
                    const optionsConfig = {};
                    scSchema.options.forEach((o) => {
                      const oConfig = config ? config[o.id] : null;
                      if (oConfig) {
                        optionsConfig[o.id] = oConfig.value;
                      } else if (o.disabled) {
                        optionsConfig[o.id] = o.value;
                      } else {
                        optionsConfig[o.id] = '';
                      }
                    });
                    newContent[scSchema.id] = optionsConfig;
                  } else {
                    newContent[scSchema.id] = config ? config.value : '';
                  }
                });
                responseContent = newContent;
              } catch (e) {
                console.error('erro', e);
              }
            }
          } catch (error) {
            responseSchema = null;
          }
        }
      }

      if (
        creativeDefinition.name === CreativeName.SATPUSH_CARD_TRIPLO
      ) {
        if (
          typeof creativeDefinition.schema === 'string'
          || creativeDefinition.schema instanceof String
        ) {
          try {
            responseSchema = JSON.parse(creativeDefinition.schema);
          } catch (error) {
            responseSchema = null;
          }
        }
      }

      if (
        creativeDefinition.name === CreativeName.NO_CREDIT_BANNER_TEXT
        || creativeDefinition.name === CreativeName.NO_DATA_BANNER_TEXT
        || creativeDefinition.name === CreativeName.MIDIA_WEB
      ) {
        if (
          typeof creativeDefinition.schema === 'string'
          || creativeDefinition.schema instanceof String
        ) {
          try {
            responseSchema = JSON.parse(creativeDefinition.schema);
          } catch (error) {
            responseSchema = null;
          }
        }
        if (responseSchema) {
          if (Object.keys(responseContent).length === 0) {
            const { screens } = responseSchema;
            const contentData = {};
            screens.forEach((item) => {
              contentData[item.id] = '';
              switch (item.type) {
                case 'check': {
                  contentData[item.id] = [];
                  break;
                }
                default: break;
              }
            });
            responseContent = contentData;
          }
        }
      }

      responseContent = internalSuccessAjustEdit(responseContent);

      yield put(requestSuccess());
      yield put(CampaignEditActions.campaignEditCriativoRequestSuccess({
        ...rest,
        contentVersion,
        content: responseContent,
        creativeDefinition: {
          ...creativeDefinition,
          schema: responseSchema,
        },
      }));
      yield put(requestSuccess());
    }
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

function convertContentCriativoV2(content, schema) {
  const newContentV2 = {};
  schema.screens.forEach((screenSchema) => {
    let data = null;
    if (screenSchema.options && screenSchema.options.length) {
      data = {};
      screenSchema.options.forEach((o) => {
        data[o.id] = {
          value: o.disabled ? o.value : content[screenSchema.id][o.id],
        };
        if (o.valuePrefix) {
          data[o.id].valuePrefix = o.valuePrefix;
        }
        if (o.valueSuffix) {
          data[o.id].valueSuffix = o.valueSuffix;
        }
      });
    } else {
      data = {
        value: screenSchema.disabled ? screenSchema.value : content[screenSchema.id],
      };
      if (screenSchema.valuePrefix) {
        data.valuePrefix = screenSchema.valuePrefix;
      }
      if (screenSchema.valueSuffix) {
        data.valueSuffix = screenSchema.valueSuffix;
      }
    }

    newContentV2[screenSchema.id] = data;
  });
  return newContentV2;
}

function getProxyValidate(mediaProxy, mediaType, campaignZone) {
  if (mediaType === CreativeCustomMidiaType.VAST || mediaType === CreativeCustomMidiaType.VAST_SURVEY) {
    const { extra } = campaignZone;
    if (extra && extra.creatives && extra.creatives.internal && extra.creatives.internal.medias && extra.creatives.internal.medias.vast && extra.creatives.internal.medias.vast.proxy) {
      const { default: defaultProxy, editable } = extra.creatives.internal.medias.vast.proxy;
      if (!editable) {
        return defaultProxy;
      }
    }
  } else if (mediaType === CreativeCustomMidiaType.VPAID || mediaType === CreativeCustomMidiaType.VPAID_SURVEY) {
    const { extra } = campaignZone;
    if (extra && extra.creatives && extra.creatives.internal && extra.creatives.internal.medias && extra.creatives.internal.medias.vpaid && extra.creatives.internal.medias.vpaid.proxy) {
      const { default: defaultProxy, editable } = extra.creatives.internal.medias.vpaid.proxy;
      if (!editable) {
        return defaultProxy;
      }
    }
  } else if (mediaType === CreativeCustomMidiaType.VIDEO || mediaType === CreativeCustomMidiaType.VIDEO_SURVEY) {
    const { extra } = campaignZone;
    if (extra && extra.creatives && extra.creatives.internal && extra.creatives.internal.medias && extra.creatives.internal.medias.video && extra.creatives.internal.medias.video.proxy) {
      const { default: defaultProxy, editable } = extra.creatives.internal.medias.video.proxy;
      if (!editable) {
        return defaultProxy;
      }
    }
  }
  return mediaProxy;
}

export function* campaignEditCriativoSave({ mode }) {
  const { criativoEdit } = yield select(state => state.campaignEdit);
  const { campaign } = yield select(state => state.campaign.edit);
  // Start loading
  yield put(startRequest());
  try {
    let contentSave = criativoEdit.content;
    if (
      criativoEdit.creativeDefinition.name === CreativeName.SMART_MESSAGE_TEXT
      || criativoEdit.creativeDefinition.name === CreativeName.SMART_MESSAGE_TEXT_CLARO
      || criativoEdit.creativeDefinition.name === CreativeName.SMART_MESSAGE_MENU
      || criativoEdit.creativeDefinition.name === CreativeName.SMART_MESSAGE_MENU_TIM
      || criativoEdit.creativeDefinition.name === CreativeName.USSD_MESSAGE_TEXT
      || criativoEdit.creativeDefinition.name === CreativeName.USSD_MESSAGE_TEXT_CLARO
      || criativoEdit.creativeDefinition.name === CreativeName.VOICE_MESSAGE_TEXT
      || criativoEdit.creativeDefinition.name === CreativeName.SATPUSH_CARD_TRIPLO
    ) {
      if (criativoEdit.contentVersion === 2) {
        try {
          contentSave = convertContentCriativoV2(
            criativoEdit.content,
            criativoEdit.creativeDefinition.schema,
          );
        } catch (e) {
          console.error('erro', e);
        }
      }
    }

    if (criativoEdit.creativeDefinition.name !== CreativeName.MIDIA_NO_CREDIT_AD && isVast(criativoEdit.content)) {
      criativoEdit.content.mainData.media.content = btoa(
        criativoEdit.content.mainData.media.content,
      );
      criativoEdit.content.mainData.media.isBase64 = true;
    }

    const questionsBenefitsUpdate = [];
    if (
      criativoEdit.creativeDefinition.name === CreativeName.CREATIVE_CUSTOM
      || criativoEdit.creativeDefinition.name === CreativeName.CHALLENGE
      || criativoEdit.creativeDefinition.name === CreativeName.MIDIA_NO_CREDIT_AD
    ) {
      if (criativoEdit.content.mainData && criativoEdit.content.mainData.media && criativoEdit.content.mainData.media.length > 0) {
        criativoEdit.content.mainData.media.forEach((media) => {
          if (media.type === CreativeCustomMidiaType.APP_INSTALL || media.type === CreativeCustomMidiaType.EXTERNAL_REDIRECT) {
            media.content = JSON.stringify(media.content);
          } else if (media.type === CreativeCustomMidiaType.VIDEO && media.modeVideo) {
            media.proxy = getProxyValidate(media.proxy, media.type, campaign.zone);
            media.type = CreativeCustomMidiaType.VAST;
            media.originalContent = media.content;
            media.content = makeContentVastMediaVideo(media.content, media.externalUrl);
          } else if (media.type === CreativeCustomMidiaType.DATA_SEARCH) {
            const { questions } = media.content;
            if (questions && questions.length > 0) {
              const hasBenefitsQuestions = questions.find(q => q.benefits);
              if (hasBenefitsQuestions) {
                questions.forEach((question) => {
                  if (question.benefits) {
                    questionsBenefitsUpdate.push({
                      mediaUuid: media.uuid,
                      questionUuid: question.uuid,
                      benefits: question.benefits,
                    });
                    question.hasBenefit = question.benefits && question.benefits.length > 0;
                    delete question.benefits;
                  }
                });
              }
            }
          } else {
            media.proxy = getProxyValidate(media.proxy, media.type, campaign.zone);
          }

          if (media.fallbackNoFill) {
            media.fallbackNoFill.proxy = getProxyValidate(media.fallbackNoFill.proxy, media.fallbackNoFill.type, campaign.zone);
            if (media.fallbackNoFill.type && media.fallbackNoFill.type === CreativeCustomMidiaType.VIDEO && media.fallbackNoFill.modeVideo) {
              media.fallbackNoFill.type = CreativeCustomMidiaType.VAST;
              media.fallbackNoFill.originalContent = media.fallbackNoFill.content;
              media.fallbackNoFill.content = makeContentVastMediaVideo(media.fallbackNoFill.content, media.fallbackNoFill.externalUrl);
            }
          }
        });
      }
      criativoEdit.content = internalSuccessAjustSave(criativoEdit.content);
    }

    if (questionsBenefitsUpdate && questionsBenefitsUpdate.length) {
      const channelUuid = campaign.zone.channel.uuid;
      for (let iQuestion = 0; iQuestion < questionsBenefitsUpdate.length; iQuestion++) {
        const { mediaUuid, questionUuid, benefits } = questionsBenefitsUpdate[iQuestion];
        if (benefits && benefits.length) {
          const benefitsUpdate = benefits.map(b => ({
            channelUuid,
            mediaUuid,
            questionUuid,
            benefitOfferUuid: b.benefitOffer.uuid,
          }));
          yield call(CampaignEditService.campaignSaveAllBenefitQuiz, campaign.uuid, benefitsUpdate);
        } else {
          yield call(CampaignEditService.campaignDeleteAllBenefitQuiz, campaign.uuid, mediaUuid, questionUuid);
        }
      }
    }

    yield call(
      CampaignEditService.campaignEditCriativoSave,
      campaign.uuid,
      {
        ...criativoEdit,
        content: contentSave,
      },
    );
    yield put(CampaignEditActions.campaignEditCriativoRequestSuccess(null));
    if (mode && mode === 'approval') {
      yield put(replace(`/campaigns/approval/view/${campaign.uuid}`));
    } else {
      yield put(replace(`/campaigns/edit/${campaign.uuid}`));
    }
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Os dados do criativo foram atualizados com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar os dados. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditBenefitSave() {
  const { campaign } = yield select(state => state.campaign.edit);
  const benefitList = yield select(state => state.campaignEdit.benefitList);

  // Start loading
  yield put(startRequest());
  try {
    yield call(
      CampaignEditService.campaignEditBenefitSave,
      campaign.uuid,
      benefitList.map(item => ({ uuid: item.uuid, mediaEventUuid: item.CampaignBenefitOffer.mediaEvent.uuid })),
    );
    yield put(CampaignEditActions.campaignEditChangeModalEditStatus({
      editBenefitStatus: false,
    }));
    yield put(CampaignActions.loadCampaignEdit(campaign.uuid));
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Os dados de benefícios foram atualizados com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar os dados de benefícios. Tente novamente!',
    ));
    yield put(requestError());
  }
}


export function* campaignEditCarrotPackageSave() {
  const { campaign } = yield select(state => state.campaign.edit);
  const carrotPackageList = yield select(state => state.campaignEdit.carrotPackageList);
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      CampaignEditService.campaignEditCarrotPackageSave,
      campaign.uuid,
      carrotPackageList.map(item => ({ uuid: item.uuid })),
    );
    yield put(CampaignEditActions.campaignEditChangeModalEditStatus({
      editCarrotPackageStatus: false,
    }));
    yield put(CampaignActions.loadCampaignEdit(campaign.uuid));
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Os dados de benefícios foram atualizados com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar os dados de benefícios. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditLoadMediaEventList() {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(
      CampaignEditService.campaignEditLoadMediaEventList,
    );
    yield put(requestSuccess());
    yield put(CampaignEditActions.campaignEditLoadMediaEventListSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados do Momento do Evento. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditLoadReport({ uuid }) {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(
      CampaignEditService.campaignEditReportRequest,
      uuid,
    );
    const reportQuiz = yield call(CampaignService.resultQuiz, uuid);

    const totals = {
      costVlr: 0,
      volume: 0,
      deliveredQty: 0,
      clickedQty: 0,
      ctr: 0,
      impressedQty: 0,
      interactedQty: 0,
      convertedQty: 0,
      benefitQty: 0,
      benefitUnitQty: 0,
      dismissQty: 0,
      clickPushBtn1Qty: 0,
      clickPushBtn2Qty: 0,
    };
    const accumulated = [];

    if (response.data && response.data.length > 0) {
      response.data = response.data.map(d => ({
        ...d,
        dateEvent: moment(d.dateEvent, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      }));

      for (let i = 0; i < response.data.length; i++) {
        const d = response.data[i];

        totals.costVlr += d.costVlr || 0;
        totals.volume += d.volume || 0;
        totals.deliveredQty += d.deliveredQty || 0;
        totals.clickedQty += d.clickedQty || 0;
        totals.ctr += d.ctr || 0;
        totals.impressedQty += d.impressedQty || 0;
        totals.interactedQty += d.interactedQty || 0;
        totals.convertedQty += d.convertedQty || 0;
        totals.benefitQty += d.benefitQty || 0;
        totals.benefitUnitQty += d.benefitUnitQty || 0;
        totals.dismissQty += d.dismissQty || 0;
        totals.clickPushBtn1Qty += d.clickPushBtn1Qty || 0;
        totals.clickPushBtn2Qty += d.clickPushBtn2Qty || 0;

        accumulated.push({
          dateEvent: totals.dateEvent,
          costVlr: totals.costVlr,
          volume: totals.volume,
          deliveredQty: totals.deliveredQty,
          clickedQty: totals.clickedQty,
          ctr: totals.ctr,
          impressedQty: totals.impressedQty,
          interactedQty: totals.interactedQty,
          convertedQty: totals.convertedQty,
          benefitQty: totals.benefitQty,
          benefitUnitQty: totals.benefitUnitQty,
          dismissQty: totals.dismissQty,
          clickPushBtn1Qty: totals.clickPushBtn1Qty,
          clickPushBtn2Qty: totals.clickPushBtn2Qty,
        });
      }
    }

    yield put(requestSuccess());
    yield put(CampaignEditActions.campaignEditLoadReportSuccess({
      ...response,
      totals,
      accumulated,
      reportQuiz,
    }));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os dados do relatório. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditLoadReportTemplate() {
  const { criativoEdit } = yield select(state => state.campaignEdit);
  const { edit: { campaign } } = yield select(state => state.campaign);
  if (criativoEdit && campaign) {
    yield put(CampaignEditActions.campaignEditSetTemplateReport(
      ReportTemplateService.getReportTemplateByCreative(criativoEdit, campaign),
    ));
  }
}

export function* campaignEditLoadAudiencesRequest({ filter }) {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(CampaignEditService.campaignEditAudienceRequest, filter);

    yield put(requestSuccess());
    yield put(CampaignEditActions.campaignEditLoadAudiencesRequestSuccess(response));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as audiências. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* executeTestAudience({ uuid }) {
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      AudienceService.executeTestAudience,
      uuid,
    );

    yield put(CampaignActions.loadCampaignEdit(uuid));
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Execução de testes realizado com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao executar o teste da campanha. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditSaveImpactSettings() {
  const { impactSettings } = yield select(state => state.campaignEdit);
  const { uuid, ...impact } = impactSettings;
  // Start loading
  yield put(startRequest());
  try {
    yield call(
      CampaignEditService.campaignEditSaveImpactSettings,
      uuid,
      impact,
    );

    yield put(requestSuccess());
    yield put(CampaignEditActions.campaignEditSetImpactSettings(null));
    yield put(CampaignEditActions.campaignEditChangeModalEditStatus({
      editImpactEstimateStatus: false,
    }));
    yield put(CampaignActions.loadCampaignEdit(uuid));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Configurações de impacto salvas com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as audiências. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditSaveEstrategiaEntrega() {
  const { campaignEstrategiaEntregaToEdit } = yield select(state => state.campaignEdit);
  // Start loading
  yield put(startRequest());
  try {
    if (!campaignEstrategiaEntregaToEdit.hasFrequency) {
      campaignEstrategiaEntregaToEdit.frequencyPerUser = null;
      campaignEstrategiaEntregaToEdit.frequencyPerUserPeriod = null;
    }
    yield call(
      CampaignEditService.campaignEditSaveEstrategiaEntrega,
      campaignEstrategiaEntregaToEdit,
    );

    yield put(
      CampaignActions.loadCampaignEdit(
        campaignEstrategiaEntregaToEdit.uuid,
      ),
    );
    yield put(CampaignEditActions.campaignEditChangeModalEditStatus({
      editEstrategiaEntregaStatus: false,
    }));
    yield put(CampaignEditActions.campaignEditSetEstrategiaEntregaToEdit(null));

    // Stop loading
    yield put(requestSuccess());
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'A Estratégia de Entrega foi atualizada com sucesso!',
    ));
  } catch (e) {
    console.error(e);
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar a Estratégia de Entrega. Tente novamente!',
    ));
    yield put(requestError());
  }
}

export function* campaignEditNoCreditPreviewRequest({ uuid }) {
  // Start loading
  yield put(startRequest());
  try {
    const data = yield call(
      CampaignEditService.campaignEditNoCreditPreviewRequest,
      uuid,
    );
    yield put(CampaignEditActions.campaignEditNoCreditPreviewRequestSuccess(data));
    yield put(requestSuccess());
    // yield put(ToastrMessageActions.successMessage(
    //   'Sucesso!',
    //   'Execução de testes realizado com sucesso!',
    // ));
  } catch (e) {
    console.error(e);
    // yield put(ToastrMessageActions.errorMessage(
    //   'Atenção!',
    //   'Houve um problema ao executar o teste da campanha. Tente novamente!',
    // ));
    yield put(requestError());
  }
}

export function* campaignEditNoDataPreviewRequest({ uuid }) {
  // Start loading
  yield put(startRequest());
  try {
    const data = yield call(
      CampaignEditService.campaignEditNoDataPreviewRequest,
      uuid,
    );
    yield put(CampaignEditActions.campaignEditNoDataPreviewRequestSuccess(data));
    yield put(requestSuccess());
    // yield put(ToastrMessageActions.successMessage(
    //   'Sucesso!',
    //   'Execução de testes realizado com sucesso!',
    // ));
  } catch (e) {
    console.error(e);
    // yield put(ToastrMessageActions.errorMessage(
    //   'Atenção!',
    //   'Houve um problema ao executar o teste da campanha. Tente novamente!',
    // ));
    yield put(requestError());
  }
}

export function* campaignCloneRequest() {
  const { campaignToClone } = yield select(state => state.campaignEdit);
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(
      CampaignService.campaignCloneRequest,
      campaignToClone,
    );
    // Stop loading
    yield put(requestSuccess());

    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'A cópia da campanha foi realizada com sucesso.',
    ));

    yield put(CampaignEditActions.campaignSetClone(null));

    const { origin } = window.location;
    const urlRedirect = `${origin}/#/campaigns/edit/${response.uuid}`;
    window.open(urlRedirect, '_blank');
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao clonar a campanha.',
    ));
  }
}

export function* campaignFileSizeConfigRequest() {
  // Start loading
  yield put(startRequest());
  try {
    const data = yield call(
      FileSizeConfigService.getFileSizeConfig,
      { moduleName: 'campaign' },
    );
    yield put(CampaignEditActions.campaignFileSizeConfigRequestSuccess(data));
    yield put(requestSuccess());
    // yield put(ToastrMessageActions.successMessage(
    //   'Sucesso!',
    //   'Execução de testes realizado com sucesso!',
    // ));
  } catch (e) {
    console.error(e);
    // yield put(ToastrMessageActions.errorMessage(
    //   'Atenção!',
    //   'Houve um problema ao executar o teste da campanha. Tente novamente!',
    // ));
    yield put(requestError());
  }
}
