import api from './axios';

const list = async () => {
  try {
    const { data } = await api.get('provider');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const get = async (providerId) => {
  try {
    const { data } = await api.get(`provider/${providerId}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const create = async (providerNew) => {
  try {
    const { data } = await api.post(
      'provider',
      providerNew,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const update = async (provider) => {
  try {
    const { data } = await api.put(
      `provider/${provider.uuid}`,
      provider,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    const { status } = error.response;
    const message = status === 400
      ? 'Verifique os campos obrigatórios'
      : 'Houve um problema ao salvar o Provedor';
    throw new Error(message);
  }
};

const excluir = async (providerId) => {
  try {
    const { data } = await api.delete(`provider/${providerId}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  list,
  get,
  update,
  create,
  excluir,
};
