import React from 'react';

function InfoIcon() {
  return (
    <svg viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.80354 12.0933H3.92878C4.4526 12.0933 4.97642 12.0914 5.50024 12.0951C5.58423 12.0958 5.67338 12.1062 5.7511 12.136C5.92203 12.2012 6.02369 12.3968 5.99496 12.5762C5.95702 12.8134 5.82809 12.9368 5.59491 12.9589C5.56139 12.9623 5.5275 12.9615 5.49398 12.9615C4.07576 12.9615 2.65717 12.9623 1.23895 12.96C1.15791 12.96 1.07208 12.9501 0.996565 12.9228C0.813118 12.8565 0.718815 12.6723 0.746443 12.4613C0.772966 12.2579 0.905947 12.1261 1.11444 12.0999C1.1627 12.0936 1.21243 12.094 1.26105 12.094C1.77861 12.0936 2.29654 12.094 2.8141 12.094H2.92755V5.05448C2.88961 5.05448 2.85388 5.05448 2.81815 5.05448C2.57318 5.05448 2.32785 5.05706 2.08289 5.05374C1.82724 5.05043 1.67473 4.93623 1.63016 4.72184C1.56975 4.43194 1.74509 4.19471 2.04347 4.18844C2.48735 4.1796 2.93198 4.18108 3.37586 4.18807C3.63372 4.19213 3.77996 4.34389 3.80059 4.60212C3.80427 4.6478 3.80354 4.69385 3.80354 4.73989C3.80354 7.14055 3.80354 9.54158 3.80354 11.9422V12.0933Z" fill="#4F7FC8" />
      <path d="M4.24108 1.54651C4.24071 2.06259 3.8856 2.41807 3.36988 2.41881C2.85233 2.41917 2.49206 2.05817 2.49317 1.54025C2.49464 1.03042 2.85785 0.670895 3.37173 0.671632C3.88523 0.672369 4.24145 1.03079 4.24108 1.54651Z" fill="#4F7FC8" />
    </svg>
  );
}

export default InfoIcon;
