import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { FaTimes } from 'react-icons/fa';

import { withTheme } from 'styled-components';

import Modal from '~/Components/Modal';
import {
  Row,
  ButtonCloseModal,
  InfoDeleteCampaign,
} from './styles';
import { Creators as AlertModalActions } from '~/redux/ducks/alertModal';

const AlertModal = ({
  title,
  message,
  modalStatus,
  hide,
  theme,
}) => (modalStatus ? (
  <Modal width={500}>
    <Row>
      <h1>{title}</h1>
      <ButtonCloseModal onClick={() => {
        hide();
      }}
      >
        <FaTimes color={theme.colors.darker} size={24} />
      </ButtonCloseModal>
    </Row>
    <Row>
      <InfoDeleteCampaign>{message}</InfoDeleteCampaign>
    </Row>
  </Modal>
) : null);

const mapStateToProps = state => ({
  title: state.alertModal.title,
  message: state.alertModal.message,
  modalStatus: state.alertModal.modalStatus,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    hide: AlertModalActions.hideAlertModal,
  },
  dispatch,
);

AlertModal.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  modalStatus: PropTypes.bool.isRequired,
  hide: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withTheme(AlertModal));
