import styled from 'styled-components';

export const Container = styled.div`
    width: 100%;
    height: 100%;

    background: ${props => props.theme.colors.white};
    border: 3px dashed #D8D8D8;
    border-radius: 14px;
    padding: 10px 14px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin-bottom: 0;
      font-weight: 600;
      color: ${props => props.theme.colors.primary};
      font-size: 12pt;
    }
`;
