import { call, put, select } from 'redux-saga/effects';
import { replace } from 'connected-react-router';

import DemoService from '~/services/DemoService';

import { Creators as DemoActions } from '~/redux/ducks/demo';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadDemoList() {
  yield put(startRequest());
  try {
    const demos = yield call(DemoService.list);
    yield put(DemoActions.loadDemoListSuccess(demos));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar as Audiências de Teste. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* addNewTerminalDemo() {
  const demos = yield select(state => state.demo.data);
  yield put(DemoActions.loadDemoListSuccess([
    ...demos,
    { id: new Date().getTime(), name: '', terminal: '' },
  ]));
}

export function* removeTerminalDemo({ payload: idItem }) {
  const demos = yield select(state => state.demo.data);
  yield put(DemoActions.loadDemoListSuccess(demos.filter(item => item.id !== idItem)));
}

export function* saveDemoList() {
  yield put(startRequest());
  try {
    const demos = yield select(state => state.demo.data);
    yield call(DemoService.save, demos);
    yield put(replace('/campaigns/approval'));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Audiencia de teste atualizada com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar a Audiência de Teste. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}
