import { call, put } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import TermsService from '~/services/TermsService';

import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* termsRequest() {
  // Start loading
  yield put(startRequest());
  try {
    const response = yield call(TermsService.termsRequestv2);
    // Download file
    FileSaver.saveAs(response, 'terms-and-conditions.pdf');

    yield put(requestSuccess());
  } catch (e) {
    yield put(requestError());
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema baixar os Termos e Condições de Uso.',
    ));
  }
}
