import React, { Component } from 'react';
import PropTypes from 'prop-types';

import {
  Container, Table, Row, Input, LinkStyled, BaseCost, Cost,
} from './styles';
import Button from '~/Components/Button';
// import Pagination from '~/Components/Pagination';
import smsImg from '~/Assets/Images/sms-dark.png';
import pushImg from '~/Assets/Images/push-notification-dark.png';
import paidNotificationImg from '~/Assets/Images/notificacao-patrocinada-dark.png';

import moment from 'moment';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Creators as ContractActions } from '~/redux/ducks/contract';

// import ContractService from '~/services/ContractService';
// import LoadingComponent from '~/Components/Loading';

import { convertFloatReal } from '~/Utils';

class ContratoList extends Component {
  static propTypes = {
    contracts: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    filter: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
    loadContractList: PropTypes.func.isRequired,
    updateFilter: PropTypes.func.isRequired,
    filterContractList: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { loadContractList } = this.props;
    loadContractList();
  }

  renderRowContract = contract => (
    <tr key={contract.id}>
      <td className="link">
        <LinkStyled to={`contracts/edit/${contract.external_contract_Id}`}>
          {contract.name}
        </LinkStyled>
      </td>
      <td>
        <BaseCost>
          <Cost>
            <img alt="SMS" src={smsImg} />
            <div className="desc-box">
              <p>SMS</p>
              <span>{`R$${convertFloatReal(contract.data.sms || 0.0)}`}</span>
            </div>
          </Cost>
          <Cost>
            <img alt="Push Notification" src={pushImg} />
            <div className="desc-box">
              <p>Push</p>
              <span>{`R$${convertFloatReal(contract.data.push || 0.0)}`}</span>
            </div>
          </Cost>
          <Cost>
            <img alt="Notificação Patrocinada" src={paidNotificationImg} />
            <div className="desc-box">
              <p>Notif.</p>
              <span>{`R$${convertFloatReal(contract.data.notify || 0.0)}`}</span>
            </div>
          </Cost>
        </BaseCost>
      </td>
      <td>
        <span>{contract.creation_date && moment(contract.creation_date, 'YYYY-MM-DD').format('DD/MM/YYYY')}</span>
      </td>
      <td>
        <span>{contract.tariff_code}</span>
      </td>
    </tr>
  );

  renderTableContratos = () => {
    const { contracts } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th>CONTRATOS</th>
            <th>CUSTO POR CANAL</th>
            <th>DATA DE CADASTRO</th>
            <th>CÓDIGO DE FATURAMENTO</th>
          </tr>
        </thead>
        <tbody>
          {contracts.length < 1 && (
            <tr>
              <td colSpan="5">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de contratos</span>
                </div>
              </td>
            </tr>
          )}
          {contracts.length > 0 && contracts.map(contract => this.renderRowContract(contract))}
        </tbody>
      </Table>
    );
  };

  render() {
    const { filter, updateFilter, filterContractList } = this.props;
    return (
      <Container>
        <Row>
          <h1>Lista de Contratos</h1>
          <div className="box-search">
            <Input
              placeholder="Procurar por nome"
              value={filter.name}
              onChange={e => updateFilter({
                ...filter,
                name: e.target.value,
              })
              }
            />
            <Button title="BUSCAR" onClick={() => filterContractList()} />
          </div>
        </Row>
        {this.renderTableContratos()}
        {/* <Row>
          <Pagination />
        </Row> */}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  contracts: state.contract.contracts,
  filter: state.contract.filter,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    loadContractList: ContractActions.loadContractList,
    updateFilter: ContractActions.updateFilter,
    filterContractList: ContractActions.filterContractList,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContratoList);
