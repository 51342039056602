import styled from 'styled-components';

export const Container = styled.button`
  display: flex;
  width: 100%;
  min-height: 30px;
  max-height: 30px;
  border: 0;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: transparent;
  outline: none;
  * {
    pointer-events: none;
  }
  svg {
    color: ${props => props.theme.colors.light};
    width: 30px;
    height: 30px;
    background-color: ${props => props.theme.colors.white};
    border-radius: 50%;
    padding: 0;
    z-index: 1;
  }
  .item-add-separator {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: ${props => props.theme.colors.light};
    z-index: 0;
  }

  &:hover {
    svg {
      color: ${props => props.theme.colors.dark};
    }
  }
  &:disabled {
    display: none;
  }
`;
