import React from 'react';

function ProcessingStatusIcon() {
  return (
    <svg width="26" height="26" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.6187 5.4075V5.28693C20.6187 3.80997 20.6187 2.333 20.6187 0.862065C20.6187 0.765611 20.6307 0.663127 20.6549 0.566672C20.7573 0.138654 21.0829 0 21.4144 0C21.8244 0 22.1077 0.247166 22.174 0.632985C22.1861 0.711355 22.1921 0.795753 22.1921 0.874122C22.1921 2.93585 22.1921 4.99154 22.1921 7.05326C22.1921 7.14369 22.1861 7.24014 22.162 7.32454C22.0715 7.71639 21.7882 7.92136 21.3421 7.92136C20.3474 7.92136 19.3527 7.92136 18.358 7.92136C17.291 7.92136 16.224 7.92136 15.1509 7.92136C15.0364 7.92136 14.9218 7.9093 14.8133 7.87916C14.4275 7.77667 14.2587 7.45717 14.2707 7.09546C14.2828 6.68553 14.542 6.40822 14.9158 6.36602C15.0183 6.35397 15.1268 6.34794 15.2353 6.34794C16.5435 6.34794 17.8577 6.34794 19.1658 6.34794H19.3165C19.2924 6.30574 19.2804 6.2756 19.2623 6.24545C17.8818 4.02096 15.9466 2.544 13.4147 1.86881C12.5165 1.62768 11.5941 1.55533 10.6657 1.60356C9.07424 1.69399 7.57316 2.09789 6.21074 2.92379C3.85362 4.35253 2.37665 6.42631 1.79793 9.13307C1.64721 9.83237 1.55679 10.5377 1.5809 11.2491C1.67736 13.9498 2.64793 16.2707 4.64334 18.1215C6.0118 19.3874 7.63947 20.1591 9.48417 20.4725C10.3583 20.6172 11.2324 20.6715 12.1186 20.575C14.108 20.364 15.8623 19.6045 17.3573 18.2722C17.7974 17.8803 18.2073 17.4523 18.569 16.9821C18.8463 16.6204 19.3105 16.542 19.6903 16.7892C19.9435 16.9519 20.0822 17.4282 19.9616 17.7417C19.9254 17.8381 19.8712 17.9346 19.8048 18.019C18.768 19.2849 17.5201 20.2917 16.0491 21.0211C15.1569 21.4612 14.2225 21.8048 13.2459 21.9977C12.408 22.1605 11.564 22.2268 10.7079 22.1907C9.4058 22.1364 8.13983 21.9013 6.94018 21.3889C3.67277 19.9963 1.4965 17.597 0.453585 14.197C0.134079 13.1299 -0.016632 12.0508 0.00145328 10.9537C0.0858513 7.05929 2.12346 3.54472 5.39087 1.59151C6.66287 0.831923 8.03132 0.331564 9.50226 0.120569C13.692 -0.470218 18.0867 1.24788 20.5343 5.31707C20.5524 5.34722 20.5705 5.37736 20.5946 5.4075H20.6187Z" fill="#4F7FC8" />
    </svg>
  );
}

export default ProcessingStatusIcon;
