import styled from 'styled-components';


export const Container = styled.div`
  position: absolute;
  width: 80.5%;
  min-height: 60px;
  top: 75px;
  padding: 15px 20px;

  span {
    color: ${props => props.theme.colors.regular};
  }
  .dot-now {
    margin-left: 15px;
    font-size: 14px;
  }
  .dot {
    font-size: 28px;
    line-height: 0.1;
    position: absolute;
    margin-top: 10px;
    margin-left: -7px;
  }
  background-color: #f9f8f8;
  label {
    font-size: 16px;
  }
  p {
    line-height: 1.3;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  a {
    text-decoration: underline;
  }
  .base-button {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    border-top: 1px solid #ccc;
    padding-top: 10px;
  }
`;
