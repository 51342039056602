/* eslint-disable react/no-array-index-key */
import React from 'react';
// import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaChevronRight } from 'react-icons/fa';

import { Creators as RedirectActions } from '~/redux/ducks/redirect';
import { Container } from './styles';

const Breadcrumb = ({ items, navigateToRoute }) => (
  <Container>
    {items.map((item, index) => (
      <span key={index}>
        {index < items.length - 1 ? (
          <>
            <button
              type="button"
              onClick={() => {
                if(item.handleClick) {
                  item.handleClick();
                } else {
                  navigateToRoute(item.route);
                }
              }}
            >
              {item.name}
            </button>
            <FaChevronRight />
          </>
        ) : (
          <span>{item.name}</span>
        )}
      </span>
    ))}
  </Container>
);

Breadcrumb.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  navigateToRoute: PropTypes.func.isRequired,
};

Breadcrumb.defaultProps = {
  items: [],
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    navigateToRoute: RedirectActions.navigateToRoute,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(Breadcrumb);
