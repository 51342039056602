/* eslint-disable react/no-array-index-key */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';
import uuid4 from 'uuid4';

import {
  FaTrash,
  FaPlus,
  FaChevronUp,
  FaChevronDown,
  FaRegCopy,
  FaRegStar,
} from 'react-icons/fa';

import { isValid, parse } from 'date-fns';

import Checkbox from '~/Components/Checkbox';
import Radiobox from '~/Components/Radiobox';

import {
  Container,
  AnswerHeader,
  EditText,
  Answers,
  Answer,
  AnswerCheckCorrect,
  AddAnswer,
  Row,
  FieldWithLabel,
  Header,
  HeaderActions,
  WrapActionButton,
  ActionButton,
  FormGroup,
  StarItem,
  Select,
  InputMask,
} from './styles';


function validateDateRequired(value) {
  if (!value || !isValid(parse(value, 'dd/MM/yyyy', new Date()))) {
    return false;
  }
  return true;
}

class Item extends Component {
  handleNewAnswer = () => {
    const { question, onChange } = this.props;
    onChange({
      ...question,
      options: [
        ...question.options,
        {
          uuid: uuid4(),
          description: '',
          isAnswer: false,
        },
      ],
    });
  };

  handleChangeIsAnswer = (index) => {
    const { question, onChange } = this.props;
    onChange({
      ...question,
      options: [
        ...question.options.map((a, i) => (i === index
          ? { ...a, isAnswer: question.type === 'radio' ? true : !a.isAnswer }
          : { ...a, isAnswer: question.type === 'radio' ? false : a.isAnswer })),
      ],
    });
  };

  handleChangeQuestion = (question) => {
    const { onChange } = this.props;
    onChange(question);
  };

  handleChangeAnswer = (index, answer) => {
    const { question, onChange } = this.props;
    onChange({
      ...question,
      options: [...question.options.map((a, i) => (i === index ? answer : a))],
    });
  };

  handleRemoveAnswer = (index) => {
    const { question, onChange } = this.props;
    onChange({
      ...question,
      options: [...question.options.filter((a, i) => i !== index)],
    });
  };

  renderActionButtons = () => {
    const {
      changePositionUp,
      changePositionDown,
      disableUp,
      disableDown,
      copyItem,
      onRemove,
      theme,
      disabledFields,
    } = this.props;
    return (
      <WrapActionButton>
        <ActionButton
          color={theme.colors.primary}
          onClick={() => { changePositionUp(); }}
          disabled={disabledFields || disableUp}
          data-tip="Enviar este item para cima"
        >
          <FaChevronUp />
        </ActionButton>
        <ActionButton
          color={theme.colors.primary}
          onClick={() => { changePositionDown(); }}
          disabled={disabledFields || disableDown}
          data-tip="Enviar este item para baixo"
        >
          <FaChevronDown />
        </ActionButton>
        <ActionButton
          color={theme.colors.primary}
          onClick={() => { copyItem(); }}
          data-tip="Fazer uma cópia deste item"
          disabled={disabledFields}
        >
          <FaRegCopy />
        </ActionButton>
        <ActionButton
          color={theme.colors.danger2}
          onClick={() => { onRemove(); }}
          data-tip="Excluir item"
          disabled={disabledFields}
        >
          <FaTrash />
        </ActionButton>
        <ReactTooltip place="bottom" effect="solid" />
      </WrapActionButton>
    );
  };

  renderHeader = (title) => {
    const {
      index, theme, question, disabledFields,
    } = this.props;
    return (
      <Header>
        <h2>{`${index} - ${title}`}</h2>
        <HeaderActions>
          {(question.type === 'check' || question.type === 'radio') && (
            <Checkbox
              label="Acerto mandatório?"
              colorChecked={theme.colors.primary}
              value={question.rightAnswerRequired}
              onChange={check => this.handleChangeQuestion({
                ...question,
                rightAnswerRequired: check,
                required: check || question.required,
              })}
              style={{ marginRight: 20 }}
              disabled={disabledFields}
            />
          )}
          <Checkbox
            label="Reposta Obrigatória?"
            disabled={disabledFields || (question.rightAnswerRequired || false)}
            colorChecked={theme.colors.primary}
            value={question.required}
            onChange={check => this.handleChangeQuestion({
              ...question,
              required: check,
            })}
          />
          {this.renderActionButtons()}
        </HeaderActions>
      </Header>
    );
  };

  renderCheckItem = () => {
    const {
      question, theme, hasError, disabledFields,
    } = this.props;
    return (
      <Container>
        {this.renderHeader('Check box')}
        <Row style={{ marginRight: 0 }}>
          <FormGroup>
            <label>Questão</label>
            <EditText
              value={question.title}
              placeholder="Questão"
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                title: e.target.value,
              })}
              invalid={hasError && !question.title}
              disabled={disabledFields}
            />
          </FormGroup>
        </Row>
        <Answers>
          <AnswerHeader>
            <span>Correta?</span>
            <span>Resposta</span>
          </AnswerHeader>
          {question.options.map((answer, i) => (
            answer && (
              <Answer key={i}>
                <AnswerCheckCorrect>
                  <Checkbox
                    label=""
                    colorChecked={theme.colors.primary}
                    value={answer.isAnswer}
                    onChange={() => this.handleChangeIsAnswer(i)}
                    disabled={disabledFields}
                  />
                </AnswerCheckCorrect>
                <EditText
                  autoFocus={!!question.title || question.options.length > 1}
                  value={answer.description}
                  placeholder="Digite resposta"
                  color={theme.colors.dark}
                  onChange={e => this.handleChangeAnswer(i, {
                    ...answer,
                    description: e.target.value,
                  })}
                  invalid={hasError && !answer.description}
                  disabled={disabledFields}
                />
                <ActionButton
                  color={theme.colors.danger2}
                  onClick={() => {
                    this.handleRemoveAnswer(i);
                  }}
                  style={{ margin: 0 }}
                  disabled={disabledFields}
                >
                  <FaTrash />
                </ActionButton>
              </Answer>
            )
          ))}
          {!disabledFields && (
            <AddAnswer>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleNewAnswer();
                }}
              >
                <FaPlus color={theme.colors.primary} size={12} />
                <span>Nova resposta</span>
              </a>
            </AddAnswer>
          )}
        </Answers>
      </Container>
    );
  };

  renderRadioItem = () => {
    const {
      question, theme, hasError, disabledFields,
    } = this.props;
    return (
      <Container>
        {this.renderHeader('Rádio')}
        <Row style={{ marginRight: 0 }}>
          <FormGroup>
            <label>Questão</label>
            <EditText
              value={question.title}
              placeholder="Questão"
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                title: e.target.value,
              })}
              invalid={hasError && !question.title}
              disabled={disabledFields}
            />
          </FormGroup>
        </Row>
        <Answers>
          <AnswerHeader>
            <span>Correta?</span>
            <span>Resposta</span>
          </AnswerHeader>
          {question.options.map((answer, i) => (
            answer && (
              <Answer key={i}>
                <AnswerCheckCorrect>
                  <Radiobox
                    label=""
                    colorChecked={theme.colors.primary}
                    value={answer.isAnswer}
                    onChange={() => this.handleChangeIsAnswer(i)}
                    disabled={disabledFields}
                  />
                </AnswerCheckCorrect>
                <EditText
                  autoFocus={!!question.title || question.options.length > 1}
                  value={answer.description}
                  placeholder="Digite resposta"
                  invalid={hasError && !answer.description}
                  color={theme.colors.dark}
                  onChange={e => this.handleChangeAnswer(i, {
                    ...answer,
                    description: e.target.value,
                  })}
                  disabled={disabledFields}
                />
                <ActionButton
                  color={theme.colors.danger2}
                  onClick={() => {
                    this.handleRemoveAnswer(i);
                  }}
                  style={{ margin: 0 }}
                  disabled={disabledFields}
                >
                  <FaTrash />
                </ActionButton>
              </Answer>
            )
          ))}
          {!disabledFields && (
            <AddAnswer>
              <a
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  this.handleNewAnswer();
                }}
              >
                <FaPlus color={theme.colors.primary} size={12} />
                <span>Nova resposta</span>
              </a>
            </AddAnswer>
          )}
        </Answers>
      </Container>
    );
  };

  renderTextItem = () => {
    const {
      question, theme, hasError, disabledFields,
    } = this.props;

    const typeList = [
      { label: 'Texto livre', value: 'text' },
      { label: 'CPF', value: 'cpf' },
      { label: 'Telefone', value: 'phone' },
      { label: 'CEP', value: 'cep' },
      { label: 'E-mail', value: 'email' },
    ];

    return (
      <Container>
        {this.renderHeader('Texto')}
        <Row style={{ marginRight: 0 }}>
          <FormGroup>
            <label>Questão</label>
            <EditText
              value={question.title}
              placeholder="Questão"
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                title: e.target.value,
              })}
              invalid={hasError && !question.title}
              disabled={disabledFields}
            />
          </FormGroup>
        </Row>
        <AnswerHeader style={{ marginTop: 10 }}>
          <span style={{ width: 200 }}>Configuração resposta</span>
        </AnswerHeader>
        <Row>
          <FormGroup style={{ maxWidth: 250 }}>
            <label>Tipo</label>
            <FieldWithLabel style={{ width: 200 }}>
              <Select
                menuPlacement="top"
                placeholder="Selecione o tipo"
                value={typeList.find(item => (item.value === question.fieldType || null))}
                onChange={type => this.handleChangeQuestion({
                  ...question,
                  fieldType: type.value,
                })}
                options={typeList}
                isDisabled={disabledFields}
              />
            </FieldWithLabel>
          </FormGroup>
          {question.fieldType && question.fieldType === 'text' && (
            <>
              <FormGroup style={{ maxWidth: 250 }}>
                <label>Mínimo de caracteres</label>
                <FieldWithLabel style={{ width: 200 }}>
                  <Checkbox
                    label="Validar"
                    colorChecked={theme.colors.primary}
                    value={question.validateMin}
                    onChange={validateMin => this.handleChangeQuestion({
                      ...question,
                      validateMin,
                    })}
                    style={{ margin: 0 }}
                    disabled={disabledFields}
                  />
                  <EditText
                    value={question.min}
                    placeholder="Mínimo"
                    color={theme.colors.dark}
                    type="number"
                    onChange={e => this.handleChangeQuestion({
                      ...question,
                      min: parseInt(e.target.value || 0),
                    })}
                    style={{ marginLeft: 10 }}
                    disabled={disabledFields || !question.validateMin}
                  />
                </FieldWithLabel>
              </FormGroup>
              <FormGroup style={{ maxWidth: 250 }}>
                <label>Máximo de caracteres</label>
                <FieldWithLabel style={{ width: 200 }}>
                  <Checkbox
                    label="Validar"
                    colorChecked={theme.colors.primary}
                    value={question.validateMax}
                    onChange={validateMax => this.handleChangeQuestion({
                      ...question,
                      validateMax,
                    })}
                    style={{ margin: 0 }}
                    disabled={disabledFields}
                  />
                  <EditText
                    value={question.max}
                    placeholder="Máximo"
                    color={theme.colors.dark}
                    type="number"
                    onChange={e => this.handleChangeQuestion({
                      ...question,
                      max: parseInt(e.target.value || 0),
                    })}
                    style={{ marginLeft: 10 }}
                    disabled={disabledFields || !question.validateMax}
                  />
                </FieldWithLabel>
              </FormGroup>
            </>
          )}
        </Row>
      </Container>
    );
  };

  renderNumberItem = () => {
    const { question, theme, hasError } = this.props;
    return (
      <Container>
        {this.renderHeader('Numérico')}
        <Row style={{ marginRight: 0 }}>
          <FormGroup>
            <label>Questão</label>
            <EditText
              value={question.title}
              placeholder="Questão"
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                title: e.target.value,
              })}
              invalid={hasError && !question.title}
            />
          </FormGroup>
        </Row>
        <AnswerHeader style={{ marginTop: 10 }}>
          <span style={{ width: 200 }}>Configuração resposta</span>
        </AnswerHeader>
        <Row>
          <FormGroup style={{ maxWidth: 250 }}>
            <label>Valor mínimo</label>
            <FieldWithLabel style={{ width: 200 }}>
              <Checkbox
                label="Validar"
                colorChecked={theme.colors.primary}
                value={question.validMin}
                onChange={validMin => this.handleChangeQuestion({
                  ...question,
                  validMin,
                })}
                style={{ margin: 0 }}
              />
              <EditText
                value={question.min}
                placeholder="Min"
                disabled={!question.validMin}
                color={theme.colors.dark}
                type="number"
                onChange={e => this.handleChangeQuestion({
                  ...question,
                  min: parseInt(e.target.value || 0),
                })}
                style={{ marginLeft: 10 }}
              />
            </FieldWithLabel>
          </FormGroup>
          <FormGroup style={{ maxWidth: 250 }}>
            <label>Valor máximo</label>
            <FieldWithLabel style={{ width: 200 }}>
              <Checkbox
                label="Validar"
                colorChecked={theme.colors.primary}
                value={question.validMax}
                onChange={validMax => this.handleChangeQuestion({
                  ...question,
                  validMax,
                })}
                style={{ margin: 0 }}
              />
              <EditText
                value={question.max}
                placeholder="Max"
                disabled={!question.validMax}
                color={theme.colors.dark}
                type="number"
                onChange={e => this.handleChangeQuestion({
                  ...question,
                  max: parseInt(e.target.value || 0),
                })}
                style={{ marginLeft: 10 }}
              />
            </FieldWithLabel>
          </FormGroup>
        </Row>
      </Container>
    );
  };

  renderStarItem = () => {
    const { question, theme, hasError } = this.props;
    return (
      <Container>
        {this.renderHeader('Ranking')}
        <Row style={{ marginRight: 0 }}>
          <FormGroup>
            <label>Questão</label>
            <EditText
              value={question.title}
              placeholder="Questão"
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                title: e.target.value,
              })}
              invalid={hasError && !question.title}
            />
          </FormGroup>
        </Row>
        <AnswerHeader style={{ marginTop: 10 }}>
          <span style={{ width: 200 }}>Valores</span>
        </AnswerHeader>
        <Row style={{ justifyContent: 'center' }}>
          <StarItem>
            <FaRegStar />
            <EditText
              value={question.rate1}
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                rate1: parseInt(e.target.value || 0),
              })}
            />
          </StarItem>
          <StarItem>
            <FaRegStar />
            <EditText
              value={question.rate2}
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                rate2: parseInt(e.target.value || 0),
              })}
            />
          </StarItem>
          <StarItem>
            <FaRegStar />
            <EditText
              value={question.rate3}
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                rate3: parseInt(e.target.value || 0),
              })}
            />
          </StarItem>
          <StarItem>
            <FaRegStar />
            <EditText
              value={question.rate4}
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                rate4: parseInt(e.target.value || 0),
              })}
            />
          </StarItem>
          <StarItem>
            <FaRegStar />
            <EditText
              value={question.rate5}
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                rate5: parseInt(e.target.value || 0),
              })}
            />
          </StarItem>
        </Row>
      </Container>
    );
  };

  renderDateItem = () => {
    const {
      question, theme, hasError, disabledFields,
    } = this.props;

    const typeList = [
      { label: 'Sem limite', value: 'no-limit' },
      { label: 'Data atual', value: 'now' },
      { label: 'Data de ontem', value: 'yesterday' },
      { label: 'Data de amanhã', value: 'tomorrow' },
      { label: 'Data fixa', value: 'fixed' },
    ];
    return (
      <Container>
        {this.renderHeader('Data')}
        <Row style={{ marginRight: 0 }}>
          <FormGroup>
            <label>Questão</label>
            <EditText
              value={question.title}
              placeholder="Questão"
              color={theme.colors.dark}
              onChange={e => this.handleChangeQuestion({
                ...question,
                title: e.target.value,
              })}
              invalid={hasError && !question.title}
              disabled={disabledFields}
            />
          </FormGroup>
        </Row>
        <AnswerHeader style={{ marginTop: 10 }}>
          <span style={{ width: 200 }}>Configuração resposta</span>
        </AnswerHeader>
        <Row>
          <FormGroup style={{ maxWidth: 200 }}>
            <label>Hora</label>
            <Checkbox
              label="Aceitar hora"
              colorChecked={theme.colors.primary}
              value={question.acceptHour}
              onChange={acceptHour => this.handleChangeQuestion({
                ...question,
                acceptHour,
              })}
              style={{ margin: 0 }}
              disabled={disabledFields}
            />
          </FormGroup>
          <FormGroup style={{ maxWidth: 400 }}>
            <label>Formato da data</label>
            <FieldWithLabel style={{ width: 400 }}>
              <Radiobox
                label={`DD/MM/YYYY ${question.acceptHour ? 'HH:MM' : ''}`}
                colorChecked={theme.colors.primary}
                value={question.dateFormat === 'DD/MM/YYYY'}
                onChange={() => this.handleChangeQuestion({
                  ...question,
                  dateFormat: 'DD/MM/YYYY',
                })}
                style={{ marginRight: 10 }}
                disabled={disabledFields}
              />
              <Radiobox
                label={`MM/DD/YYYY ${question.acceptHour ? 'HH:MM' : ''}`}
                colorChecked={theme.colors.primary}
                value={question.dateFormat === 'MM/DD/YYYY'}
                onChange={() => this.handleChangeQuestion({
                  ...question,
                  dateFormat: 'MM/DD/YYYY',
                })}
                disabled={disabledFields}
              />
            </FieldWithLabel>
          </FormGroup>
        </Row>
        <Row>
          <h4 style={{ margin: 0 }}>Data limite</h4>
        </Row>
        <Row style={{ marginTop: 0 }}>
          <FormGroup style={{ flex: 0, marginRight: 10 }}>
            <label>
              Mínimo
              <span>*</span>
            </label>
            <FieldWithLabel style={{ width: 200 }}>
              <Select
                menuPlacement="top"
                placeholder="Selecione o mínimo"
                value={typeList.find(item => (item.value === question.dateMinType || null))}
                onChange={type => this.handleChangeQuestion({
                  ...question,
                  dateMinType: type.value,
                  dateMin: '',
                })}
                options={typeList}
                isDisabled={disabledFields}
              />
            </FieldWithLabel>
          </FormGroup>
          {question.dateMinType && question.dateMinType === 'fixed' && (
            <FormGroup style={{ flex: 0, marginRight: 10 }}>
              <label>
                Data mínima
                <span>*</span>
              </label>
              <FieldWithLabel style={{ width: 150 }}>
                <InputMask
                  className="field-date"
                  mask="99/99/9999"
                  placeholder="__/__/____"
                  value={question.dateMin || ''}
                  onChange={e => this.handleChangeQuestion({
                    ...question,
                    dateMin: e.target.value || '',
                  })}
                  disabled={disabledFields}
                  invalid={hasError && !validateDateRequired(question.dateMin)}
                />
              </FieldWithLabel>
            </FormGroup>
          )}
          <FormGroup style={{ flex: 0, marginRight: 10 }}>
            <label>
              Máximo
              <span>*</span>
            </label>
            <FieldWithLabel style={{ width: 200 }}>
              <Select
                placeholder="Selecione o máximo"
                menuPlacement="top"
                value={typeList.find(item => (item.value === question.dateMaxType || null))}
                onChange={type => this.handleChangeQuestion({
                  ...question,
                  dateMaxType: type.value,
                  dateMax: '',
                })}
                options={typeList}
                isDisabled={disabledFields}
              />
            </FieldWithLabel>
          </FormGroup>
          {question.dateMaxType && question.dateMaxType === 'fixed' && (
            <FormGroup style={{ flex: 0 }}>
              <label>
                Data máxima
                <span>*</span>
              </label>
              <FieldWithLabel style={{ width: 150 }}>
                <InputMask
                  className="field-date"
                  mask="99/99/9999"
                  placeholder="__/__/____"
                  value={question.dateMax || ''}
                  onChange={e => this.handleChangeQuestion({
                    ...question,
                    dateMax: e.target.value || '',
                  })}
                  disabled={disabledFields}
                  invalid={hasError && !validateDateRequired(question.dateMax)}
                />
              </FieldWithLabel>
            </FormGroup>
          )}
        </Row>
      </Container>
    );
  };

  renderQuestion = () => {
    const { question } = this.props;
    switch (question.type) {
      case 'check': return this.renderCheckItem();
      case 'radio': return this.renderRadioItem();
      case 'text': return this.renderTextItem();
      case 'number': return this.renderNumberItem();
      case 'rate': return this.renderStarItem();
      case 'date': return this.renderDateItem();
      default: return null;
    }
  };

  render() {
    return this.renderQuestion();
  }
}

Item.propTypes = {
  index: PropTypes.number.isRequired,
  question: PropTypes.shape().isRequired,
  onChange: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
  changePositionUp: PropTypes.func.isRequired,
  changePositionDown: PropTypes.func.isRequired,
  copyItem: PropTypes.func.isRequired,
  disableUp: PropTypes.bool.isRequired,
  disableDown: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

Item.defaultProps = {};

export default withTheme(Item);
