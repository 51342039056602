import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  termsRequest: null,
});

export const TermsTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  // benefits: [],
});

/* Reducers */
// const benefitRequestSuccess = (state, { benefits }) => ({
//   ...state,
//   benefits,
// });

export const reducer = createReducer(INITIAL_STATE, {
  // [TermsTypes.BENEFIT_REQUEST_SUCCESS]: benefitRequestSuccess,
});
