import React from 'react';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';
import { withTheme } from 'styled-components';

const RadialChart = ({
  values, labels, size, theme, textSize, padding, color, showDataLabels,
}) => (
  <Chart
    height={size}

    options={{
      chart: {
        type: 'radialBar',

      },
      grid: {
        show: true,
        padding,
      },
      colors: color || [theme.colors.primary],
      plotOptions: {

        radialBar: {
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: showDataLabels || false,
              fontSize: textSize || '30pt',
              fontFamily: 'Simplon Oi',
            },

          },
        },
      },
      stroke: {
        lineCap: 'round',
      },
      labels: labels || [],
    }}
    series={values || []}
    type="radialBar"
  />
);

RadialChart.defaultProps = {
  values: [],
  labels: [],
  color: null,
  textSize: '30pt',
  showDataLabels: false,
};

RadialChart.propTypes = {
  values: PropTypes.arrayOf(PropTypes.number),
  labels: PropTypes.arrayOf(PropTypes.string),
  size: PropTypes.number.isRequired,
  theme: PropTypes.shape().isRequired,
  textSize: PropTypes.string,
  padding: PropTypes.shape().isRequired,
  color: PropTypes.arrayOf(PropTypes.string),
  showDataLabels: PropTypes.bool,
};

export default withTheme(RadialChart);
