import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  campaignEditChangeModalEditStatus: ['modalStatus'],
  campaignEditSetOrcamentoToEdit: ['campaignOrcamentoToEdit'],
  campaignEditSetPeriodoToEdit: ['campaignPeriodoToEdit'],
  campaignEditSetCriativoToEdit: ['campaignCriativoToEdit'],
  campaignEditSetBriefingToEdit: ['campaignBriefingToEdit'],
  campaignEditSetAttributeToEdit: ['campaignAttributeToEdit'],
  campaignEditSetEstrategiaEntregaToEdit: ['campaignEstrategiaEntregaToEdit'],
  campaignEditSaveOrcamentoInEdit: null,
  campaignEditSavePeriodoInEdit: null,
  campaignEditSaveBriefingInEdit: null,
  campaignEditSaveAttributeInEdit: null,
  campaignEditBriefingDownload: ['uuid'],
  campaignEditCriativoRequest: ['uuid'],
  campaignEditCriativoRequestSuccess: ['criativoEdit'],
  campaignEditCriativoSave: ['mode'],
  campaignEditBenefitSave: null,
  campaignEditCarrotPackageSave: null,
  campaignEditBenefitSetList: ['benefitList'],
  campaignEditCarrotPackageSetList: ['carrotPackageList'],
  campaignEditBenefitSetMediaEventUuid: ['mediaEventUuid'],
  campaignEditLoadMediaEventList: null,
  campaignEditLoadMediaEventListSuccess: ['mediaEventList'],
  campaignEditLoadReport: ['uuid'],
  campaignEditLoadReportSuccess: ['report'],
  campaignEditLoadAudiencesRequest: ['filter'],
  campaignEditLoadAudiencesRequestSuccess: ['audiences'],
  campaignEditSetImpactSettings: ['impactSettings'],
  campaignEditSaveImpactSettings: null,
  campaignEditSaveEstrategiaEntrega: null,
  executeTestAudience: ['uuid'],
  cleanDataReport: null,
  campaignEditSetTemplateReport: ['campaignEditTemplateReport'],
  campaignEditNoCreditPreviewRequest: ['uuid'],
  campaignEditNoCreditPreviewRequestSuccess: ['noCreditPreviewData'],
  campaignEditNoDataPreviewRequest: ['uuid'],
  campaignEditNoDataPreviewRequestSuccess: ['noDataPreviewData'],
  campaignSetClone: ['campaignToClone'],
  campaignCloneRequest: null,
  campaignFileSizeConfigRequest: null,
  campaignFileSizeConfigRequestSuccess: ['fileSizeConfigData'],
});

export const CampaignEditTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_MODALS_EDIT_STATUS = {
  editOrcamentoStatus: false,
  editPeriodoStatus: false,
  editCriativoStatus: false,
  editBriefingStatus: false,
  editAttributeStatus: false,
  editBenefitStatus: false,
  editImpactEstimateStatus: false,
  editEstrategiaEntregaStatus: false,
  editCarrotPackageStatus: false,
};

const INITIAL_STATE = Immutable({
  modalEdit: INITIAL_MODALS_EDIT_STATUS,
  campaignOrcamentoToEdit: null,
  campaignPeriodoToEdit: null,
  campaignCriativoToEdit: null,
  campaignBriefingToEdit: null,
  campaignAttibuteToEdit: null,
  campaignEstrategiaEntregaToEdit: null,
  criativoEdit: null,
  benefitList: [],
  carrotPackageList: [],
  mediaEventUuid: null,
  mediaEventList: [],
  report: null,
  audiences: [],
  impactSettings: null,
  campaignEditTemplateReport: null,
  noCreditPreviewData: null,
  noDataPreviewData: null,
  campaignToClone: null,
  fileSizeConfigData: null,
});

/* Reducers */
const campaignEditChangeModalEditStatus = (state, { modalStatus }) => ({
  ...state,
  modalEdit: {
    ...state.modalEdit,
    ...modalStatus,
  },
});
const campaignEditSetOrcamentoToEdit = (state, { campaignOrcamentoToEdit }) => ({
  ...state,
  campaignOrcamentoToEdit,
});
const campaignEditSetPeriodoToEdit = (state, { campaignPeriodoToEdit }) => ({
  ...state,
  campaignPeriodoToEdit,
});
const campaignEditSetEstrategiaEntregaToEdit = (state, { campaignEstrategiaEntregaToEdit }) => ({
  ...state,
  campaignEstrategiaEntregaToEdit,
});
const campaignEditSetCriativoToEdit = (state, { campaignCriativoToEdit }) => ({
  ...state,
  campaignCriativoToEdit,
});
const campaignEditSetBriefingToEdit = (state, { campaignBriefingToEdit }) => ({
  ...state,
  campaignBriefingToEdit,
});
const campaignEditSetAttributeToEdit = (state, { campaignAttributeToEdit }) => ({
  ...state,
  campaignAttributeToEdit,
});
const campaignEditCriativoRequestSuccess = (state, { criativoEdit }) => ({
  ...state,
  criativoEdit,
});
const campaignEditBenefitSetList = (state, { benefitList }) => ({
  ...state,
  benefitList,
});
const campaignEditCarrotPackageSetList = (state, { carrotPackageList }) => ({
  ...state,
  carrotPackageList,
});
const campaignEditBenefitSetMediaEventUuid = (state, { mediaEventUuid }) => ({
  ...state,
  mediaEventUuid,
});
const campaignEditLoadMediaEventListSuccess = (state, { mediaEventList }) => ({
  ...state,
  mediaEventList,
  mediaEventUuid: mediaEventList[0].uuid,
});
const campaignEditLoadReportSuccess = (state, { report }) => ({
  ...state,
  report,
});
const campaignEditLoadAudiencesRequestSuccess = (state, { audiences }) => ({
  ...state,
  audiences,
});
const campaignEditSetImpactSettings = (state, { impactSettings }) => ({
  ...state,
  impactSettings,
});

const cleanDataReport = state => ({
  ...state,
  report: null,
});

const campaignEditSetTemplateReport = (state, { campaignEditTemplateReport }) => ({
  ...state,
  campaignEditTemplateReport,
});

const campaignEditNoCreditPreviewRequestSuccess = (state, { noCreditPreviewData }) => ({
  ...state,
  noCreditPreviewData,
});

const campaignEditNoDataPreviewRequestSuccess = (state, { noDataPreviewData }) => ({
  ...state,
  noDataPreviewData,
});

const campaignSetClone = (state, { campaignToClone }) => ({
  ...state,
  campaignToClone,
});

const campaignFileSizeConfigRequestSuccess = (state, { fileSizeConfigData }) => ({
  ...state,
  fileSizeConfigData,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CampaignEditTypes.CAMPAIGN_EDIT_CHANGE_MODAL_EDIT_STATUS]: campaignEditChangeModalEditStatus,
  [CampaignEditTypes.CAMPAIGN_EDIT_SET_ORCAMENTO_TO_EDIT]: campaignEditSetOrcamentoToEdit,
  [CampaignEditTypes.CAMPAIGN_EDIT_SET_PERIODO_TO_EDIT]: campaignEditSetPeriodoToEdit,
  [CampaignEditTypes.CAMPAIGN_EDIT_SET_CRIATIVO_TO_EDIT]: campaignEditSetCriativoToEdit,
  [CampaignEditTypes.CAMPAIGN_EDIT_SET_BRIEFING_TO_EDIT]: campaignEditSetBriefingToEdit,
  [CampaignEditTypes.CAMPAIGN_EDIT_SET_ATTRIBUTE_TO_EDIT]: campaignEditSetAttributeToEdit,
  [CampaignEditTypes.CAMPAIGN_EDIT_SET_ESTRATEGIA_ENTREGA_TO_EDIT]:
    campaignEditSetEstrategiaEntregaToEdit,
  [CampaignEditTypes.CAMPAIGN_EDIT_CRIATIVO_REQUEST_SUCCESS]: campaignEditCriativoRequestSuccess,
  [CampaignEditTypes.CAMPAIGN_EDIT_BENEFIT_SET_LIST]: campaignEditBenefitSetList,
  [CampaignEditTypes.CAMPAIGN_EDIT_CARROT_PACKAGE_SET_LIST]: campaignEditCarrotPackageSetList,
  [CampaignEditTypes.CAMPAIGN_EDIT_BENEFIT_SET_MEDIA_EVENT_UUID]:
  campaignEditBenefitSetMediaEventUuid,
  [CampaignEditTypes.CAMPAIGN_EDIT_LOAD_MEDIA_EVENT_LIST_SUCCESS]:
  campaignEditLoadMediaEventListSuccess,
  [CampaignEditTypes.CAMPAIGN_EDIT_LOAD_REPORT_SUCCESS]: campaignEditLoadReportSuccess,
  [CampaignEditTypes.CAMPAIGN_EDIT_LOAD_AUDIENCES_REQUEST_SUCCESS]:
    campaignEditLoadAudiencesRequestSuccess,
  [CampaignEditTypes.CAMPAIGN_EDIT_SET_IMPACT_SETTINGS]:
    campaignEditSetImpactSettings,
  [CampaignEditTypes.CLEAN_DATA_REPORT]: cleanDataReport,
  [CampaignEditTypes.CAMPAIGN_EDIT_SET_TEMPLATE_REPORT]: campaignEditSetTemplateReport,
  [CampaignEditTypes.CAMPAIGN_EDIT_NO_CREDIT_PREVIEW_REQUEST_SUCCESS]:
    campaignEditNoCreditPreviewRequestSuccess,
  [CampaignEditTypes.CAMPAIGN_EDIT_NO_DATA_PREVIEW_REQUEST_SUCCESS]:
    campaignEditNoDataPreviewRequestSuccess,
  [CampaignEditTypes.CAMPAIGN_SET_CLONE]: campaignSetClone,
  [CampaignEditTypes.CAMPAIGN_FILE_SIZE_CONFIG_REQUEST_SUCCESS]:
  campaignFileSizeConfigRequestSuccess,
});
