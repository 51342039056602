import styled, { css } from 'styled-components';

export const Container = styled.button`
  display: flex;
  align-items: center;
  padding: 5px;
  border: 1px solid transparent;
  background-color: transparent;
  width: 100%;
  min-height: 53px;
  max-height: 53px;
  overflow: hidden;
  border-radius: 5px;
  margin: 10px 0;
  outline: none;
  &:first-child {
    margin-top: 0;
  }
  span.item-media-move {
    cursor: move;
  }
  svg {
    min-width: 40px;
    max-width: 40px;
    height: auto;
    color: ${props => props.theme.colors.dark};
    pointer-events: none;
  }
  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 5px;
    font-size: 18px;
    line-height: 1;
  }
  span.item-media-title {
    color: ${props => props.theme.colors.dark};
    pointer-events: none;
  }
  span.item-media-placeholder {
    color: ${props => props.theme.colors.dark}70;
    pointer-events: none;
  }

  ${props => props.selected && css`
    border: 1px solid ${props.theme.colors.primary};
    svg {
      color: ${props.theme.colors.primary};
    }
    span.item-media-title {
      color: ${props.theme.colors.primary};
    }
    span.item-media-placeholder {
      color: ${props.theme.colors.primaryHex}70;
    }
  `}

  ${props => props.hasError && css`
    border: 1px solid ${props.theme.colors.danger2};
    svg {
      color: ${props.theme.colors.danger2};
    }
    span.item-media-title {
      color: ${props.theme.colors.danger2};
    }
    span.item-media-placeholder {
      color: ${props.theme.colors.danger2Hex}70;
    }
  `}
`;
