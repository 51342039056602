import styled from 'styled-components';

export const Container = styled.button`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    padding:8px ${props => (props.iconAlone ? '16px' : '20px')};
    border-radius: 10px;
    min-height:46px;
    outline: none;

    filter: drop-shadow( 0 5px 8px  ${props => props.theme.colors.black}30);
    transition: .3s all;

    background-color: ${props => (props.bordered ? 'transparent' : props.theme.colors[props.color ? props.color : 'primary'])};
    border: ${props => (props.bordered ? `solid 1px ${props.theme.colors[props.color ? props.color : 'primary']}` : 'none')};
    color: ${props => (props.bordered ? props.theme.colors[props.color ? props.color : 'primary'] : props.theme.colors.white)};

    &&:hover{
        filter: drop-shadow( 0 5px 8px  ${props => props.theme.colors.black}30) saturate(1.3);
        transition: .3s all;

        img.update{
            transform: rotate(180deg);
            transition: .8s all;
        }
    }

    img{
        width:22px;
        margin-right: ${props => (props.iconAlone ? '0px' : '10px')};
        margin-top:${props => (props.iconAlone ? '0px' : '-3px')};
    }

    &&.btn-anchor{
        background-color: transparent !important;
        filter: drop-shadow( 0 0px 0px  ${props => props.theme.colors.black}0);


        img{
          margin-right: 0;

        }
    }
    &&.btn-anchor.big{

        img{
            width:35px;

        }

    }

    &&:disabled{
      background: #ccc;
    }

`;

export const Title = styled.span`
    /* color: ${props => props.theme.colors.white} !important; */
    color: ${props => (props.bordered ? props.theme.colors[props.color ? props.color : 'primary'] : props.theme.colors.white)} !important;
    font-size: 14pt;
    font-weight: 700;
    margin-left:${props => (props.space ? '10px' : 0)};

`;
