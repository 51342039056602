import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import FileSaver from 'file-saver';

import { FaPlusCircle } from 'react-icons/fa';
import { withTheme } from 'styled-components';
import uuid4 from 'uuid4';
import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import UploadImage from '~/Components/UploadImage';
import Button from '~/Components/Button';
import ImageFromService from '~/Components/ImageFromService';

import {
  FormBox,
  FieldItem,
  Row,
  Input,
  BannerEmpty,
  Checkbox,
  Radiobox,
  RadioContainer,
  CheckContainer,
  BaseMultiUploads,
  ListItemsImage,
  ItemImageSelected,
  BaseChangeImage,
  AddImageMultiUploads,
} from './styles';

import FileUploadService from '~/services/FileUploadService';

class MidiaWebForm extends Component {
  state = {
    multiImages: {
      itemSelected: null,
    },
  };

  uploadFileImage = async (field, item, file) => {
    const {
      campaign,
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
      campaignEditCriativoRequestSuccess,
      criativoEdit,
    } = this.props;
    const { multiImages } = this.state;
    const items = criativoEdit.content[field.id];
    const referenceKey = `${campaign.uuid}-${field.id}-${item.uuid}`;

    if (file) {
      try {
        startRequest();
        const formData = new FormData();
        formData.append('file', file);
        const { uuid } = await FileUploadService.uploadFile(formData, referenceKey);
        requestSuccess();

        const itemUpdated = { ...item, content: uuid };

        campaignEditCriativoRequestSuccess({
          ...criativoEdit,
          content: {
            ...criativoEdit.content,
            [field.id]: [...items.map(i => (i.uuid !== itemUpdated.uuid ? i : itemUpdated))],
          },
        });

        this.setState({ multiImages: { ...multiImages, itemSelected: { ...itemUpdated, content: null } } }, () => {
          this.setState({ multiImages: { ...multiImages, itemSelected: { ...itemUpdated } } });
        });
      } catch (error) {
        errorMessage(
          'Atenção!',
          'Houve um problema ao enviar a imagem. Tente novamente mais tarde!',
        );
        requestError();
      }
    }
  };

  downloadFileImage = async (fileUuid) => {
    const {
      startRequest,
      requestSuccess,
      requestError,
      errorMessage,
    } = this.props;
    try {
      startRequest();
      const fileData = await FileUploadService.getFile(fileUuid);
      FileSaver.saveAs(fileData, `${fileUuid}.${fileData.type.split('/').pop()}`);
      requestSuccess();
    } catch (error) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao baixar a imagem. Tente novamente mais tarde!',
      );
      requestError();
    }
  };

  renderCheckField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    return (
      <Row>
        <CheckContainer invalid={hasError && field.required && contentData[field.id].length === 0}>
          {field.values.map(item => (
            <Checkbox
              disabled={disabledFields}
              label={item.label}
              value={!!contentData[field.id].find(val => val === item.value)}
              onChange={(value) => {
                if (value) {
                  contentData[field.id].push(item.value);
                } else {
                  contentData[field.id] = contentData[field.id].filter(val => val !== item.value);
                }
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: contentData,
                });
              }}
              style={{ marginRight: 20 }}
            />
          ))}
        </CheckContainer>
      </Row>
    );
  };

  renderRadioField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    return (
      <Row>
        <RadioContainer invalid={hasError && field.required && !content[field.id]}>
          {field.values.map(item => (
            <Radiobox
              disabled={disabledFields}
              label={item.label}
              value={content[field.id] === item.value}
              onChange={() => {
                contentData[field.id] = item.value;
                campaignEditCriativoRequestSuccess({
                  ...criativoEdit,
                  content: contentData,
                });
              }}
              style={{ marginRight: 20 }}
            />
          ))}
        </RadioContainer>
      </Row>
    );
  };

  renderUrlField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    return (
      <Row>
        <Input
          disabled={disabledFields}
          placeholder="Digite a url"
          onChange={(e) => {
            contentData[field.id] = e.target.value;
            campaignEditCriativoRequestSuccess({
              ...criativoEdit,
              content: contentData,
            });
          }}
          value={contentData[field.id]}
          invalid={hasError && field.required && (
            !contentData[field.id]
            || !contentData[field.id].match(
              /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
            )
          )}
        />
      </Row>
    );
  };

  renderTextField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      campaignEditCriativoRequestSuccess,
      hasError,
    } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };
    return (
      <Row>
        <Input
          disabled={disabledFields}
          placeholder="Digite o texto"
          onChange={(e) => {
            contentData[field.id] = e.target.value;
            campaignEditCriativoRequestSuccess({
              ...criativoEdit,
              content: contentData,
            });
          }}
          value={contentData[field.id]}
          invalid={hasError && field.required && !contentData[field.id]}
          maxLength={field.maxLength ? field.maxLength : false}
        />
      </Row>
    );
  };

  addNewImageField = (field) => {
    const { campaignEditCriativoRequestSuccess, criativoEdit } = this.props;

    const items = criativoEdit.content[field.id] || [];
    const newItem = { uuid: uuid4(), label: `Imagem ${items.length + 1}`, content: null };

    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...criativoEdit.content,
        [field.id]: [...items, newItem],
      },
    });

    this.setState({ multiImages: { itemSelected: newItem } });
  }

  renderMultiImagesField = (field) => {
    const {
      disabledFields,
      criativoEdit,
      hasError,
      campaignEditCriativoRequestSuccess,
      theme,
      confirmModal,
    } = this.props;

    const { multiImages } = this.state;
    const { itemSelected } = multiImages;

    const { content } = criativoEdit;
    const imageAccept = field.format.map(item => `image/${item}`);

    const items = content[field.id] || [];

    const invalid = hasError && field.required && items.length === 0;

    return (
      <BaseMultiUploads invalid={invalid}>
        <ListItemsImage>
          {items.map(i => (
            <ItemImageSelected
              key={i.uuid}
              selected={itemSelected && itemSelected.uuid === i.uuid}
              invalid={hasError && field.required && !i.content}
              onClick={() => {
                this.setState({ multiImages: { ...multiImages, itemSelected: null } }, () => {
                  this.setState({ multiImages: { ...multiImages, itemSelected: i } });
                });
              }}
            >
              {i.label}
            </ItemImageSelected>
          ))}
          <AddImageMultiUploads>
            {!disabledFields && (
              <div className="baseBtnAdd" onClick={() => this.addNewImageField(field)}>
                <FaPlusCircle color={theme.colors.primary} size={20} />
                {' Novo'}
              </div>
            )}
          </AddImageMultiUploads>
        </ListItemsImage>
        {itemSelected && (
          <BaseChangeImage>
            <div className="base-buttons-upload-image">
              <UploadImage
                disabled={disabledFields}
                label={itemSelected.content ? 'ALTERAR' : 'ADICIONAR'}
                onUpload={(files) => {
                  this.uploadFileImage(field, itemSelected, files[0]);
                }}
                accept={imageAccept.join(',')}
                style={{ marginTop: 0, width: itemSelected.content ? 'auto' : 250 }}
                styleContainer={{ width: 'auto', height: 'auto' }}
              />
              {itemSelected.content && (
                <Button
                  title="DOWNLOAD"
                  icon="FaCloudDownloadAlt"
                  onClick={() => this.downloadFileImage(itemSelected.content)}
                  bordered
                />
              )}
            </div>
            {itemSelected.content ? (
              <ImageFromService
                imageUuid={itemSelected.content}
                width={field.width}
                height={field.height}
                style={{
                  width: '100%',
                  height: '100%',
                  marginTop: 5,
                  backgroundColor: 'rgba(42, 68, 82, 0.3)',
                }}
                styleImage={{
                  width: '100%',
                  height: '100%',
                  objectFit: 'contain',
                }}
              />
            ) : (
              <BannerEmpty invalid={hasError && field.required && !itemSelected.content}>
                Sem imagem de visualização
              </BannerEmpty>
            )}
            {!disabledFields && (
              <button
                className="btn-remover-imagem"
                type="button"
                onClick={() => {
                  confirmModal(
                    'Excluir',
                    'Tem certeza que deseja excluir esta mídia?',
                    () => {
                      campaignEditCriativoRequestSuccess({
                        ...criativoEdit,
                        content: {
                          ...criativoEdit.content,
                          [field.id]: [...items.filter(i => i.uuid !== itemSelected.uuid)],
                        },
                      });
                      this.setState({ multiImages: { ...multiImages, itemSelected: null } });
                    },
                  );
                }}
              >
                Excluir item
              </button>
            )}
          </BaseChangeImage>
        )}
      </BaseMultiUploads>
    );
  };

  renderField = (field) => {
    const { criativoEdit } = this.props;
    const { content } = criativoEdit;
    const contentData = {
      ...content,
    };

    const getFieldByType = () => {
      switch (field.type) {
        case 'check': return this.renderCheckField(field);
        case 'radio': return this.renderRadioField(field);
        case 'url': return this.renderUrlField(field);
        case 'text': return this.renderTextField(field);
        case 'multi-images': return this.renderMultiImagesField(field);
        default: return null;
      }
    };
    return (
      <FieldItem>
        <label>
          {field.label}
          {field.required && (<span>*</span>)}
          {!!field.maxLength && (
            <>
              -
              <small>
                {` ${contentData[field.id].length}/${field.maxLength} caracteres`}
              </small>
            </>
          )}
        </label>
        {getFieldByType()}
      </FieldItem>
    );
  };

  renderForm = () => {
    const { criativoEdit: { creativeDefinition: { schema: { screens } } } } = this.props;
    return (
      <FormBox>
        {screens.map(field => this.renderField(field))}
      </FormBox>
    );
  };

  render() {
    const { criativoEdit: { content, creativeDefinition: { schema } } } = this.props;
    return !!schema && !!Object.keys(content).length > 0 && this.renderForm();
  }
}

MidiaWebForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  theme: PropTypes.shape().isRequired,
  confirmModal: PropTypes.func.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(withTheme(MidiaWebForm));
