import api from './axios';

const listAllAudienceUsers = async () => {
  try {
    const { data } = await api.get('audienceuser');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const filterAudienceUser = async (audienceId) => {
  try {
    const { data } = await api.get(`audienceuser/${audienceId}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const create = async (audienceNew) => {
  try {
    const { data } = await api.post(
      'audienceuser',
      audienceNew,
    );
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

const update = async (audience) => {
  try {
    const { data } = await api.put(
      `audienceuser/${audience.uuid}`,
      audience,
    );
    return data;
  } catch (error) {
    const { status } = error.response;
    const message = status === 412
      ? 'Verifique os campos obrigatórios'
      : 'Houve um problema ao salvar as alterações no usuário de teste';
    throw new Error(message);
  }
};

const excluir = async (audienceId) => {
  try {
    const { data } = await api.delete(`audienceuser/${audienceId}`);
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  listAllAudienceUsers,
  excluir,
  create,
  filterAudienceUser,
  update,
};
