/* eslint-disable operator-linebreak */
/* eslint-disable react/no-array-index-key */
/* eslint-disable arrow-parens */
/* eslint-disable function-paren-newline */
/* eslint-disable implicit-arrow-linebreak */
/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import PropTypes from "prop-types";

import { Creators as AdActions } from "~/redux/ducks/ad";

import Checkbox from "~/Components/Checkbox";
import Modal from "~/Components/Modal";
import ColorPicker from "../ColorPicker";
import { SelectStyled } from "~/Components/Styled";
import Button from "~/Components/Button";

import {
  Row,
  FormGroupColumnLeft,
  FormGroupColumnRight,
  FormGroup,
  Input,
  ButtonAddProperty,
  ButtonDeleteProperty,
  RowFooter,
  ButtonCancel
} from "./styles";

const ModalEdit = props => {
  const {
    adList,
    editAd,
    changeModalEditAd,
    advertisingPartnerList,
    eventsList,
    channelsList,
    changeStateEditAd,
    changePropertyStateEditAd,
    deleteFieldPropertyOnEditAd,
    addNewFieldPropertyOnEditAd,
    adEdit
  } = props;
  let channels = adList.filter(
    item => parseInt(item.eventId) === parseInt(adEdit.event)
  );
  channels = channels.map(item =>
    channelsList.find(channel => channel.code === item.channel)
  );
  return (
    <Modal width={650}>
      <>
        <Row>
          <h1>Editar anúncio</h1>
        </Row>
        <Row>
          <FormGroupColumnLeft>
            <Checkbox
              label="Habilitado"
              value={adEdit.enabled}
              onChange={value => {
                changeStateEditAd({
                  enabled: value
                });
              }}
            />
          </FormGroupColumnLeft>
          <FormGroupColumnRight>
            <label>Cor</label>
            <ColorPicker
              value={adEdit.color}
              onChange={value => {
                changeStateEditAd({
                  color: value
                });
              }}
            />
          </FormGroupColumnRight>
        </Row>
        <Row>
          <FormGroup>
            <label>Descrição</label>
            <Input
              value={adEdit.label}
              placeholder="Descrição"
              onChange={e => {
                changeStateEditAd({
                  label: e.target.value
                });
              }}
            />
          </FormGroup>
        </Row>
        <Row>
          <FormGroup>
            <label>Fornecedor</label>
            <SelectStyled
              defaultValue={adEdit.partner}
              onChange={e => {
                changeStateEditAd({
                  partner: e.target.value
                });
              }}
            >
              <option value="">Fornecedor...</option>
              {advertisingPartnerList.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </SelectStyled>
          </FormGroup>
        </Row>
        <Row>
          <FormGroupColumnLeft>
            <label>Evento</label>
            <SelectStyled
              defaultValue={adEdit.event}
              onChange={e => {
                changeStateEditAd({
                  event: e.target.value
                });
              }}
            >
              <option value="">Evento...</option>
              {eventsList.map(item => (
                <option key={item.id} value={item.id}>
                  {item.name}
                </option>
              ))}
            </SelectStyled>
          </FormGroupColumnLeft>
          <FormGroupColumnRight>
            <label>Canal</label>
            <SelectStyled
              defaultValue={adEdit.channel}
              onChange={e => {
                changeStateEditAd({
                  channel: e.target.value
                });
              }}
            >
              <option value="">Canal...</option>
              {channels.map(item => (
                <option key={item.id} value={item.code}>
                  {item.name}
                </option>
              ))}
            </SelectStyled>
          </FormGroupColumnRight>
        </Row>
        <Row>
          <FormGroup>
            <label>Propriedades</label>
          </FormGroup>
          <ButtonAddProperty
            title="Nova Propriedade"
            bordered
            onClick={() => {
              addNewFieldPropertyOnEditAd();
            }}
          />
        </Row>
        <div>
          {adEdit.properties.map((property, index) => (
            <Row key={index}>
              <FormGroupColumnLeft>
                <label>Chave</label>
                <Input
                  value={property.key}
                  placeholder="Chave"
                  onChange={e => {
                    changePropertyStateEditAd(index, {
                      key: e.target.value
                    });
                  }}
                />
              </FormGroupColumnLeft>
              <FormGroupColumnRight>
                <label>Valor</label>
                <Input
                  value={property.value}
                  placeholder="Valor"
                  onChange={e => {
                    changePropertyStateEditAd(index, {
                      value: e.target.value
                    });
                  }}
                />
              </FormGroupColumnRight>
              <ButtonDeleteProperty
                bordered
                title="Excluir"
                onClick={() => {
                  deleteFieldPropertyOnEditAd(index);
                }}
              />
            </Row>
          ))}
        </div>
        <RowFooter>
          <ButtonCancel
            color="light"
            title="CANCELAR"
            onClick={() => changeModalEditAd(false)}
          />
          <Button
            title="CONFIRMAR"
            disabled={
              !adEdit.label ||
              !adEdit.partner ||
              !adEdit.event ||
              !adEdit.channel
            }
            onClick={() => {
              editAd();
            }}
          />
        </RowFooter>
      </>
    </Modal>
  );
};

ModalEdit.propTypes = {
  // State
  adList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  channelsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  eventsList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  advertisingPartnerList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  adEdit: PropTypes.shape().isRequired,
  // Functions
  changeStateEditAd: PropTypes.func.isRequired,
  addNewFieldPropertyOnEditAd: PropTypes.func.isRequired,
  deleteFieldPropertyOnEditAd: PropTypes.func.isRequired,
  changePropertyStateEditAd: PropTypes.func.isRequired,
  editAd: PropTypes.func.isRequired,
  changeModalEditAd: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  adList: state.ad.adList,
  adEdit: state.ad.adEdit,
  channelsList: state.channel.channelsList,
  eventsList: state.event.eventsList,
  advertisingPartnerList: state.advertisingPartner.advertisingPartnerList
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      editAd: AdActions.editAd,
      changeStateEditAd: AdActions.changeStateEditAd,
      addNewFieldPropertyOnEditAd: AdActions.addNewFieldPropertyOnEditAd,
      deleteFieldPropertyOnEditAd: AdActions.deleteFieldPropertyOnEditAd,
      changePropertyStateEditAd: AdActions.changePropertyStateEditAd,
      changeModalEditAd: AdActions.changeModalEditAd
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalEdit);
