import React from 'react';
import PropTypes from 'prop-types';
import { AiFillPlaySquare } from 'react-icons/ai';
import { sortableHandle } from 'react-sortable-hoc';

import { Container } from './styles';

function ItemMedia({
  data, selected, onClick, hasError,
}) {
  const DragHandle = sortableHandle(() => <span className="item-media-move">::</span>);

  return (
    <Container selected={selected} onClick={onClick} hasError={hasError} className="media-item-button">
      <DragHandle />
      <AiFillPlaySquare />
      {!!data.title && <span className="item-media-title">{data.title}</span>}
      {!data.title && <span className="item-media-placeholder">Mídia</span>}
    </Container>
  );
}

ItemMedia.defaultProps = {
  selected: false,
  hasError: false,
};

ItemMedia.propTypes = {
  data: PropTypes.shape().isRequired,
  selected: PropTypes.bool,
  hasError: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

export default ItemMedia;
