import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  showConfirmModal: ['title', 'message', 'confirmAction', 'options'],
  hideConfirmModal: null,
});

export const ConfirmModal = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  title: '',
  message: '',
  modalStatus: false,
  confirmAction: () => {},
  titleCancel: 'CANCELAR',
  titleConfirm: 'CONFIRMAR',
  // cancelAction: () => {},
});

/* Reducers */
const open = (state, {
  title, message, confirmAction, options = {},
}) => ({
  ...state,
  title,
  message,
  confirmAction,
  modalStatus: true,
  ...options,
});

const close = state => ({
  ...state,
  modalStatus: false,
});

export const reducer = createReducer(INITIAL_STATE, {
  [ConfirmModal.SHOW_CONFIRM_MODAL]: open,
  [ConfirmModal.HIDE_CONFIRM_MODAL]: close,
});
