import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  campaignIconLoadCampaignTypes: null,
  campaignIconLoadCampaignTypesSuccess: ['types'],
});

export const CampaignIconTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  types: [],
});

/* Reducers */
const campaignIconLoadCampaignTypesSuccess = (state, { types }) => ({
  ...state,
  types,
});

export const reducer = createReducer(INITIAL_STATE, {
  [CampaignIconTypes.CAMPAIGN_ICON_LOAD_CAMPAIGN_TYPES_SUCCESS]:
    campaignIconLoadCampaignTypesSuccess,
});
