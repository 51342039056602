/* eslint-disable max-len */
import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  initStateView: null,
  cleanFilter: null,
  databaseFilters: ['result'],
  cockpitAnalyzeRequest: null,
  cockpitAnalyzeRequestSuccess: ['result'],
  updateFilter: ['filter'],
  changePage: ['page'],
  showModalResultsWeekly: ['campaignUuid'],
  showModalResultsWeeklySuccess: ['campaignUuid', 'zoneUuid', 'periodSelected', 'gridSelected', 'periods', 'grids', 'gridPeriodReport'],
  changePeriodSelectedModalResultsWeekly: ['periodSelected'],
  changeGridSelectedModalResultsWeekly: ['gridSelected'],
  changeGridPeriodReportModalResultsWeeklySuccess: ['periodSelected', 'grids', 'gridSelected', 'gridPeriodReport'],
  closeModalResultsWeekly: null,
});

export const CockpitAnalyzeTypes = Types;
export default Creators;

const INIT_FILTERS = {
  zoneUuid: null,
  campaignTypeUuid: null,
  channelUuid: null,
  campaignStatusUuid: null,
  // audienceUuid: null,
  contractUuid: null,
  productUuid: null,
  productOfferUuid: null,
  campaignKeyword: '',
};

const INIT_MODAL_RESULTS_WEEKLY = {
  show: false,
  periods: [],
  grids: [],
  periodSelected: null,
  gridSelected: null,
  gridPeriodReport: null,
  campaignUuid: null,
  zoneUuid: null,
};

/* Initial State */
const INITIAL_STATE = Immutable({
  dataFilters: {
    zones: [],
    campaignTypes: [],
    channels: [],
    campaignStatus: [],
    // audiences: [],
    contracts: [],
    products: [],
    productOffers: [],
  },
  filter: INIT_FILTERS,
  originalData: [],
  data: [],
  paginate: {
    page: 0, pageCount: 0,
  },
  modalResultsWeekly: INIT_MODAL_RESULTS_WEEKLY,
});

/* Reducers */
const cleanFilter = state => ({
  ...state,
  filter: INIT_FILTERS,
});

const databaseFilters = (state, { result }) => ({
  ...state,
  dataFilters: {
    ...result,
  },
});

const cockpitAnalyzeRequestSuccess = (state, {
  result: {
    originalData,
    data,
    paginate,
  },
}) => ({
  ...state,
  originalData,
  data,
  paginate,
});

const changePage = (state, { page }) => ({
  ...state,
  data: state.originalData.length > 0 ? state.originalData[page] : [],
  paginate: {
    ...state.paginate,
    page,
  },
});

const updateFilter = (state, { filter }) => ({
  ...state,
  filter: {
    ...filter,
  },
});

const showModalResultsWeeklySuccess = (state, {
  campaignUuid, zoneUuid, periodSelected, periods, gridPeriodReport, grids, gridSelected,
}) => ({
  ...state,
  modalResultsWeekly: {
    ...state.modalResultsWeekly,
    show: true,
    campaignUuid,
    zoneUuid,
    periodSelected,
    periods,
    gridPeriodReport,
    grids,
    gridSelected,
  },
});

const changeGridPeriodReportModalResultsWeeklySuccess = (state, {
  grids, gridPeriodReport, periodSelected, gridSelected,
}) => ({
  ...state,
  modalResultsWeekly: {
    ...state.modalResultsWeekly,
    grids,
    gridPeriodReport,
    periodSelected,
    gridSelected,
  },
});

const closeModalResultsWeekly = state => ({
  ...state,
  modalResultsWeekly: {
    ...INIT_MODAL_RESULTS_WEEKLY,
  },
});

export const reducer = createReducer(INITIAL_STATE, {
  [CockpitAnalyzeTypes.CLEAN_FILTER]: cleanFilter,
  [CockpitAnalyzeTypes.DATABASE_FILTERS]: databaseFilters,
  [CockpitAnalyzeTypes.COCKPIT_ANALYZE_REQUEST_SUCCESS]: cockpitAnalyzeRequestSuccess,
  [CockpitAnalyzeTypes.UPDATE_FILTER]: updateFilter,
  [CockpitAnalyzeTypes.CHANGE_PAGE]: changePage,
  [CockpitAnalyzeTypes.SHOW_MODAL_RESULTS_WEEKLY_SUCCESS]: showModalResultsWeeklySuccess,
  [CockpitAnalyzeTypes.CHANGE_GRID_PERIOD_REPORT_MODAL_RESULTS_WEEKLY_SUCCESS]: changeGridPeriodReportModalResultsWeeklySuccess,
  [CockpitAnalyzeTypes.CLOSE_MODAL_RESULTS_WEEKLY]: closeModalResultsWeekly,
});
