import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Button from '~/Components/Button';
import {
  Container,
  Box,
  BoxTitle,
  BoxContent,
  BoxContentItem,
} from './styles';

const CampaignsBoxContent = ({
  children,
  title,
  items,
  items2,
  items3,
  hasAction,
  actionTitle,
  action,
  marginRight,
  theme,
}) => {
  const itensRow1 = items.filter(item => item.visible);
  const itensRow2 = items2.filter(item => item.visible);
  const itensRow3 = items3.filter(item => item.visible);

  return (
    <Container marginRight={marginRight}>
      <Box>
        <BoxTitle>
          <span>{title}</span>
          {hasAction && (
          <Button
            small
            bordered
            title={actionTitle}
            onClick={action}
            type="button"
            style={{
              backgroundColor: theme.colors.white,
            }}
          />
          )}
        </BoxTitle>
        {children && (
        <BoxContent>
          {children}
        </BoxContent>
        )}
        {itensRow1.length > 0 && (
        <BoxContent>
          {itensRow1.map(item => (
            <BoxContentItem flex={item.flex || 'none'}>
              <b>{item.title}</b>
              <span>{item.value}</span>
            </BoxContentItem>
          ))}
        </BoxContent>
        )}
        {itensRow2.length > 0 && (
        <BoxContent>
          {itensRow2.map(item => (
            <BoxContentItem flex={item.flex || 'none'}>
              <b>{item.title}</b>
              <span>{item.value}</span>
            </BoxContentItem>
          ))}
        </BoxContent>
        )}
        {itensRow3.length > 0 && (
        <BoxContent>
          {itensRow3.map(item => (
            <BoxContentItem flex={item.flex || 'none'}>
              <b>{item.title}</b>
              <span>{item.value}</span>
            </BoxContentItem>
          ))}
        </BoxContent>
        )}
      </Box>
    </Container>
  );
};

CampaignsBoxContent.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.shape()),
  items2: PropTypes.arrayOf(PropTypes.shape()),
  items3: PropTypes.arrayOf(PropTypes.shape()),
  hasAction: PropTypes.bool,
  actionTitle: PropTypes.string,
  action: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  marginRight: PropTypes.number,
  theme: PropTypes.shape().isRequired,
};

CampaignsBoxContent.defaultProps = {
  hasAction: false,
  actionTitle: 'Editar',
  action: () => {},
  items: [],
  items2: [],
  items3: [],
  children: null,
  marginRight: 0,
};

export default withTheme(CampaignsBoxContent);
