/* eslint-disable comma-dangle */
/* eslint-disable quotes */
import { createReducer, createActions } from "reduxsauce";
import Immutable from "seamless-immutable";

/* Types & Creators */
export const { Types, Creators } = createActions({
  successMessage: ["title", "message"],
  errorMessage: ["title", "message"],
  warningMessage: ["title", "message"],
});

export const ToastrMessageTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({});

/* Reducers */
// const changeAdvertisingPartnerEdit = (state) => ({
//   ...state,
// });

export const reducer = createReducer(INITIAL_STATE, {
  // [ToastrMessageTypes.MESSAGE_SUCCESS]: reducer,
});
