/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import uuid4 from 'uuid4';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  startRequest as requestLoading,
  requestSuccess as successLoading,
  requestError as errorLoading,
} from '~/redux/ducks/loading';
import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

import Upload from '~/Components/Upload';
import Image from '~/Components/Image';
import Button from '~/Components/Button';

import UploadService, { baseUrlMedia } from '~/services/UploadService';

import {
  FormGroup,
  Input,
  Row,
  Imagem,
  BannerEmpty,
} from './styles';

const SponsorNotifyTextMessageForm = ({
  campaign,
  criativoEdit,
  campaignEditCriativoRequestSuccess,
  hasError,
  startRequest,
  requestSuccess,
  requestError,
  errorMessage,
  disabledFields,
}) => {
  const { content } = criativoEdit;

  const getTitleIsInvalid = () => {
    if (content.channelData) {
      if (content.channelData.title) {
        return false;
      }
    }
    return true;
  };

  const getSubtitleIsInvalid = () => {
    if (content.channelData) {
      if (content.channelData.subtitle) {
        return false;
      }
    }
    return true;
  };

  const getUrlIsInvalid = () => {
    if (content.channelData) {
      if (
        content.channelData.urlClick
        && content.channelData.urlClick.match(
          /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
        )
      ) {
        return false;
      }
    }
    return true;
  };

  const uploadFileToAWS = async (campaignUuid, file) => {
    startRequest();
    const filename = file.name;

    const extFile = filename.substr(
      filename.lastIndexOf('.') + 1,
      filename.length,
    );

    const randomFilename = uuid4();
    const photoKey = `campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
    try {
      await UploadService.upload(photoKey, file);
      const urlMedia = `${baseUrlMedia}/campaigns/${campaignUuid}/${randomFilename}.${extFile}`;
      requestSuccess();
      return {
        url: urlMedia,
        file,
        extFile,
      };
    } catch (e) {
      errorMessage(
        'Atenção!',
        'Houve um problema ao enviar a mídia. Tente novamente mais tarde!',
      );
      requestError();
    }
    return null;
  };

  const handleSuccessImage = (image) => {
    campaignEditCriativoRequestSuccess({
      ...criativoEdit,
      content: {
        ...content,
        channelData: {
          ...content.channelData,
          image,
        },
      },
    });
  };

  const uploadFileImage = async (files) => {
    const file = files[0];
    if (file) {
      const { url } = await uploadFileToAWS(campaign.uuid, file);
      handleSuccessImage(url);
    }
  };

  return (
    <>
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Título
            <span>*</span>
            -
            <small>
              {` ${
                content.channelData && content.channelData.title
                  ? content.channelData.title.length : 0}/37 caracteres`}
            </small>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite o título"
            maxLength={37}
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    title: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.title || '' : ''}
            invalid={hasError && getTitleIsInvalid()}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Subtítulo
            <span>*</span>
            -
            <small>
              {` ${
                content.channelData && content.channelData.subtitle
                  ? content.channelData.subtitle.length : 0}/37 caracteres`}
            </small>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Subtítulo"
            maxLength={37}
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    subtitle: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.subtitle || '' : ''}
            invalid={hasError && getSubtitleIsInvalid()}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup style={{ flex: 1 }}>
          <label>
            Url de destino
            <span>*</span>
          </label>
          <Input
            disabled={disabledFields}
            placeholder="Digite a url"
            onChange={(e) => {
              campaignEditCriativoRequestSuccess({
                ...criativoEdit,
                content: {
                  ...criativoEdit.content,
                  channelData: {
                    ...criativoEdit.content.channelData,
                    urlClick: e.target.value,
                  },
                },
              });
            }}
            value={content.channelData ? content.channelData.urlClick || '' : ''}
            invalid={hasError && getUrlIsInvalid()}
          />
        </FormGroup>
      </Row>
      <Row>
        <FormGroup>
          <label>
            Banner
          </label>
          <Row>
            <Imagem invalid={false /* hasError && getImageIsInvalid() */}>
              {content.channelData && content.channelData.image ? (
                <Image
                  className="success-img"
                  src={content.channelData.image}
                  alt="Banner"
                />
              ) : (
                <BannerEmpty>Sem imagem de visualização</BannerEmpty>
              )}
            </Imagem>
            <FormGroup style={{ marginLeft: '20px' }}>
              <label>Formatos: PNG, JPG</label>
              <label>Dimensão recomendada: 1024x512.</label>
              <div style={{ display: 'flex' }}>
                <Upload
                  disabled={disabledFields}
                  label={content.channelData && content.channelData.image ? 'ALTERAR' : 'ADICIONAR'}
                  onUpload={uploadFileImage}
                  accept="image/png,image/jpg,image/jpeg"
                />
                {content.channelData && content.channelData.image && (
                  <Button
                    disabled={disabledFields}
                    style={{ marginTop: '15px' }}
                    title="REMOVER"
                    onClick={() => {
                      handleSuccessImage('');
                    }}
                  />
                )}
              </div>
            </FormGroup>
          </Row>
        </FormGroup>
      </Row>
    </>
  );
};

SponsorNotifyTextMessageForm.propTypes = {
  campaign: PropTypes.shape().isRequired,
  criativoEdit: PropTypes.shape().isRequired,
  campaignEditCriativoRequestSuccess: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  startRequest: PropTypes.func.isRequired,
  requestSuccess: PropTypes.func.isRequired,
  requestError: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
  disabledFields: PropTypes.bool.isRequired,
};

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    startRequest: requestLoading,
    requestSuccess: successLoading,
    requestError: errorLoading,
    errorMessage: ToastrMessageActions.errorMessage,
  },
  dispatch,
);

export default connect(
  null,
  mapDispatchToProps,
)(SponsorNotifyTextMessageForm);
