import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  pointsManagementGeoPointsRequest: null,
  pointsManagementGeoPointsRequestSuccess: ['geopoints'],
  pointsManagementGeoPointsIconRequest: null,
  pointsManagementGeoPointsIconRequestSuccess: ['iconPoint'],
  pointsManagementGeoPointsIconSave: ['iconPoint'],
  setStatusModalEditRadius: ['statusModalEditRadius'],
  setFilterLists: ['filterLists'],
});

export const PointsManagementTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({
  geopoints: null,
  iconPoint: null,
  statusModalEditRadius: false,
  filterLists: {
    ufList: [],
    cityList: [],
    neighborhoodList: [],
    groupingList: [],
  },
});

/* Reducers */
const setStatusModalEditRadius = (state, { statusModalEditRadius }) => ({
  ...state,
  statusModalEditRadius,
});
const pointsManagementGeoPointsRequestSuccess = (state, { geopoints }) => ({
  ...state,
  geopoints,
});
const pointsManagementGeoPointsIconRequestSuccess = (state, { iconPoint }) => ({
  ...state,
  iconPoint,
});
const setFilterLists = (state, { filterLists }) => ({
  ...state,
  filterLists,
});

export const reducer = createReducer(INITIAL_STATE, {
  [PointsManagementTypes.POINTS_MANAGEMENT_GEO_POINTS_REQUEST_SUCCESS]:
    pointsManagementGeoPointsRequestSuccess,
  [PointsManagementTypes.POINTS_MANAGEMENT_GEO_POINTS_ICON_REQUEST_SUCCESS]:
    pointsManagementGeoPointsIconRequestSuccess,
  [PointsManagementTypes.SET_STATUS_MODAL_EDIT_RADIUS]:
    setStatusModalEditRadius,
  [PointsManagementTypes.SET_FILTER_LISTS]:
    setFilterLists,
});
