/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

const Radiobox = ({
  label, value, onChange, colorChecked, disabled, style,
}) => (
  <Container colorChecked={colorChecked} disabled={disabled} style={style} className="radio-box">
    {label}
    <input
      type="radio"
      checked={value}
      disabled={disabled}
      onClick={() => {
        if (!disabled) {
          onChange(!value);
        }
      }}
    />
    <span className="radio" />
  </Container>
);

Radiobox.defaultProps = {
  colorChecked: null,
  disabled: false,
  style: null,
};

Radiobox.propTypes = {
  colorChecked: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.shape(),
};

export default Radiobox;
