import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as BehaviorActions } from '~/redux/ducks/behavior';

import {
  Container, Form, FormItem, Input, Button, BodyTableCampaign, Select,
  Table, HeaderTable, BodyTable, ItemTable, Value, SelectCampaign, ItemTableEmpty,
} from './styles';

function FormTypeCampaign({
  behavior, onUpdateBehavior, onUpdateFilter, onCreateBehavior,
  channels, filtersCampaign, listCampaignByFilters,
  listChannelsCampaign, campaigns, invalidForm,
}) {
  const { name, properties } = behavior;
  const campaignUuid = properties ? properties.campaignUuid : null;

  function loadCampaign() {
    listCampaignByFilters(filtersCampaign);
  }

  useEffect(() => {
    listChannelsCampaign();
    loadCampaign();
  }, []);

  const handleCreateBehavior = (e) => {
    e.preventDefault();
    onCreateBehavior();
  };

  const renderCampaignsEmpty = () => (
    <ItemTableEmpty>Busque campanhas com o filtro acima</ItemTableEmpty>
  );

  const channelsSelect = channels.map(c => ({
    value: c.uuid, label: c.description,
  }));

  return (
    <Container>
      <Form onSubmit={handleCreateBehavior}>
        <FormItem>
          <label>Nome do comportamento:</label>
          <Input
            value={behavior.name}
            invalid={invalidForm && !behavior.name}
            placeholder="Preencha um nome"
            onChange={e => onUpdateBehavior({
              ...behavior,
              name: e.target.value,
            })}
          />
        </FormItem>
        <BodyTableCampaign invalid={invalidForm && !campaignUuid}>
          <label>Selecione uma campanha:</label>
          <div className="body-table">
            <div className="body-search">
              <label>Nome:</label>
              <Input
                value={filtersCampaign.name}
                placeholder="Nome..."
                onChange={e => onUpdateFilter({
                  ...filtersCampaign, name: e.target.value,
                })}
              />
              <label>Tipo:</label>
              <Select
                placeholder="Tipo..."
                className="filter-type-campaign"
                value={filtersCampaign.channel}
                onChange={item => onUpdateFilter({
                  ...filtersCampaign, channel: item,
                })}
                isClearable
                options={channelsSelect}
              />
              <Button
                type="button"
                title="Buscar"
                onClick={() => loadCampaign()}
              />
            </div>

            <Table>
              <HeaderTable>
                <Value flex={5}>Nome</Value>
                <Value flex={5} paddingLeft={10}>Canal</Value>
              </HeaderTable>
              <BodyTable>
                {campaigns && campaigns.map(campaign => (
                  <ItemTable
                    key={campaign.uuid}
                    selected={campaignUuid === campaign.uuid}
                  >
                    <Value flex={5}>{campaign.name}</Value>
                    <Value flex={3} paddingLeft={10}>{campaign.zone.channel.description}</Value>
                    <SelectCampaign
                      selected={campaignUuid === campaign.uuid}
                      onClick={() => onUpdateBehavior({
                        ...behavior,
                        properties: {
                          ...behavior.properties,
                          campaignUuid: campaign.uuid,
                        },
                      })}
                    >
                      {(campaignUuid === campaign.uuid) ? 'Selecionado' : 'Selecionar'}
                    </SelectCampaign>
                  </ItemTable>
                ))}
                {campaigns && campaigns.length === 0 && renderCampaignsEmpty()}
              </BodyTable>
            </Table>
          </div>
        </BodyTableCampaign>

        <div className="button-form">
          <Button
            type="submit"
            disabled={invalidForm && (!name || !campaignUuid)}
            title="Criar comportamento"
          />
        </div>
      </Form>
    </Container>
  );
}

FormTypeCampaign.defaultProps = {
  channels: [],
  campaigns: [],
  invalidForm: false,
};

FormTypeCampaign.propTypes = {
  behavior: PropTypes.shape().isRequired,
  channels: PropTypes.arrayOf(PropTypes.shape()),
  campaigns: PropTypes.arrayOf(PropTypes.shape()),
  invalidForm: PropTypes.bool,
  filtersCampaign: PropTypes.shape().isRequired,
  onUpdateBehavior: PropTypes.func.isRequired,
  listCampaignByFilters: PropTypes.func.isRequired,
  listChannelsCampaign: PropTypes.func.isRequired,
  onUpdateFilter: PropTypes.func.isRequired,
  onCreateBehavior: PropTypes.func.isRequired,
};

const mapStateToProps = ({
  behavior: {
    channels, newBehaviorModal: {
      behavior, invalidForm, filtersCampaign, campaigns,
    },
  },
}) => ({
  behavior, invalidForm, filtersCampaign, channels, campaigns,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    onUpdateBehavior: BehaviorActions.onUpdateBehaviorNewBehaviorModal,
    listCampaignByFilters: BehaviorActions.listCampaignByFiltersNewBehaviorModal,
    listChannelsCampaign: BehaviorActions.listChannelsCampaign,
    onUpdateFilter: BehaviorActions.onUpdateFilterNewBehaviorModal,
    onCreateBehavior: BehaviorActions.onCreateBehaviorNewBehaviorModal,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FormTypeCampaign);
