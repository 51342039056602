import React from 'react';
import PropTypes from 'prop-types';

import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { Container } from './styles';

function StatusInfo({ status, labelTrue, labelFalse }) {
  return (
    <Container>
      {status ? <FaCheckCircle color="#00d318" size={20} /> : <FaTimesCircle color="#ff3b2d" size={20} />}
      {status ? labelTrue : labelFalse}
    </Container>
  );
}

StatusInfo.propTypes = {
  status: PropTypes.bool.isRequired,
  labelTrue: PropTypes.string.isRequired,
  labelFalse: PropTypes.string.isRequired,
};

export default StatusInfo;
