import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  loadDataReportDashboard: ['campaignUuid'],
  loadDataReportDashboardSuccess: ['data'],
  cleanDataReportDashboard: [],
});

export const ReportV2Types = Types;
export default Creators;


/* Initial State */

const INITIAL_STATE = Immutable({
  campaign: null,
  campaignResult: null,
  benefit: [],
  periodResults: [],
  campaignMedias: [],
});

/* Reducers */
const loadDataReportDashboard = state => ({
  ...state,
  ...INITIAL_STATE,
});

const cleanDataReportDashboard = state => ({
  ...state,
  ...INITIAL_STATE,
});

const loadDataReportDashboardSuccess = (state, {
  data: {
    campaign,
    benefit,
    campaignResult,
    periodResults,
    campaignMedias,
  },
}) => ({
  ...state,
  campaign,
  benefit,
  campaignResult,
  periodResults,
  campaignMedias,
});

export const reducer = createReducer(INITIAL_STATE, {
  [ReportV2Types.LOAD_DATA_REPORT_DASHBOARD]: loadDataReportDashboard,
  [ReportV2Types.LOAD_DATA_REPORT_DASHBOARD_SUCCESS]: loadDataReportDashboardSuccess,
  [ReportV2Types.CLEAN_DATA_REPORT_DASHBOARD]: cleanDataReportDashboard,
});
