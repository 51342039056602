import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import { Creators as CampaignEditActions } from '~/redux/ducks/campaignEdit';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';
import { Creators as ToastrActions } from '~/redux/ducks/toastrMessage';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  ModalHeader,
  AddBeneficio,
  RemoveBeneficio,
  FormGroupHeader,
  Select,
  Table,
  Row,
  RowFooter,
  ButtonCloseModal,
} from './styles';

class ModalEditCarrotPackage extends Component {
  state = {
    benefitType: null,
    benefitOffer: null,
  };

  static propTypes = {
    modalEdit: PropTypes.shape().isRequired,
    campaignEditChangeModalEditStatus: PropTypes.func.isRequired,
    campaignEditCarrotPackageSave: PropTypes.func.isRequired,
    benefits: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    carrotPackageList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    confirmModal: PropTypes.func.isRequired,
    campaignEditCarrotPackageSetList: PropTypes.func.isRequired,
    warningMessage: PropTypes.func.isRequired,
    mediaEventList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    mediaEventUuid: PropTypes.string.isRequired,
  };

  componentDidMount() {}

  handleAddBenefit = () => {
    const { benefitOffer, benefitType } = this.state;
    const {
      campaignEditCarrotPackageSetList,
      carrotPackageList,
      warningMessage,
    } = this.props;
    const hasBenefitInList = carrotPackageList.find(item => item.uuid === benefitOffer.uuid);

    if (!hasBenefitInList) {
      campaignEditCarrotPackageSetList([
        ...carrotPackageList,
        {
          ...benefitOffer,
          benefit: benefitType,
        },
      ]);
      this.setState({
        benefitType: null,
        benefitOffer: null,
      });
    } else {
      warningMessage('Atenção', 'Este benefício já foi inserido na lista!');
    }
  };

  handleRemoveBenefit = (uuid) => {
    const { campaignEditCarrotPackageSetList, carrotPackageList, confirmModal } = this.props;

    const benefitToRemove = carrotPackageList.find(item => item.uuid === uuid);

    confirmModal(
      'Deseja remover o benefício?',
      `${benefitToRemove.benefit.name}(${benefitToRemove.name})`,
      () => {
        campaignEditCarrotPackageSetList(carrotPackageList.filter(item => !(item.uuid === uuid)));
      },
    );
  };

  handleSave = () => {
    const { campaignEditCarrotPackageSave } = this.props;
    campaignEditCarrotPackageSave();
  };

  renderHeader = () => {
    const { benefitType, benefitOffer } = this.state;
    const {
      benefits,
    } = this.props;

    const carrotPackageList = benefits.map(benefit => ({
      ...benefit,
      label: benefit.name,
      value: benefit.uuid,
      benefitOffers: benefit.benefitOffers.map(offer => ({
        ...offer,
        label: offer.name,
        value: offer.uuid,
      })),
    }));

    return (
      <ModalHeader>
        <FormGroupHeader style={{ width: 250 }}>
          <label>Tipo de benefício</label>
          <Select
            placeholder="Tipo de benefício..."
            value={benefitType ? carrotPackageList.find(item => benefitType.value === item.value) : null}
            onChange={(type) => {
              this.setState({
                benefitType: type,
                benefitOffer: null,
              });
            }}
            options={carrotPackageList}
            isClearable
          />
        </FormGroupHeader>
        <FormGroupHeader style={{ width: 250 }}>
          <label>Benefício</label>
          <Select
            placeholder="Benefício..."
            value={
              benefitOffer
                ? benefitType.benefitOffers.find(item => benefitOffer.value === item.value)
                : null
            }
            onChange={(offer) => {
              this.setState({
                benefitOffer: offer,
              });
            }}
            options={benefitType ? benefitType.benefitOffers : []}
            isClearable
          />
        </FormGroupHeader>
        <AddBeneficio
          disabled={!benefitType || !benefitOffer}
          title="ADICIONAR"
          onClick={this.handleAddBenefit}
        />
      </ModalHeader>
    );
  };

  renderTable = () => {
    const { carrotPackageList } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th width="1%">#</th>
            <th>Tipo de benefício</th>
            <th>Benefício</th>
            <th width="1%">Ação</th>
          </tr>
        </thead>
        <tbody>
          {carrotPackageList.length < 1 ? (
            <tr>
              <td colSpan="4">
                <div className="empty-table-desc">
                  <span> Não foram localizados registros de ofertas</span>
                </div>
              </td>
            </tr>
          ) : (
            carrotPackageList.map((benefitOffer, index) => (
              <tr key={benefitOffer.uuid}>
                <td>
                  <span>{index + 1}</span>
                </td>
                <td>
                  <span>{benefitOffer.benefit.name}</span>
                </td>
                <td>
                  <span>{benefitOffer.name}</span>
                </td>
                <td>
                  <RemoveBeneficio
                    title="REMOVER"
                    bordered
                    onClick={() => this.handleRemoveBenefit(benefitOffer.uuid)}
                  />
                </td>
              </tr>
            ))
          )}
        </tbody>
      </Table>
    );
  };

  render() {
    const {
      modalEdit,
      campaignEditChangeModalEditStatus,
    } = this.props;

    return modalEdit.editCarrotPackageStatus && (
      <Modal width={1000}>
        <Row>
          <h1>Alterar Benefício Pacote Cenoura</h1>
          <ButtonCloseModal
            type="button"
            onClick={() => {
              campaignEditChangeModalEditStatus({
                editCarrotPackageStatus: false,
              });
            }}
          >
            <FaTimes size={30} />
          </ButtonCloseModal>
        </Row>
        {this.renderHeader()}
        <Row>
          {this.renderTable()}
        </Row>
        <RowFooter>
          <Button
            title="SALVAR"
            onClick={() => this.handleSave()}
          />
        </RowFooter>
      </Modal>
    );
  }
}

const mapStateToProps = state => ({
  modalEdit: state.campaignEdit.modalEdit,
  carrotPackageList: state.campaignEdit.carrotPackageList,
  benefits: state.benefit.benefits,
  mediaEventList: state.campaignEdit.mediaEventList,
  mediaEventUuid: state.campaignEdit.mediaEventUuid,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    campaignEditChangeModalEditStatus: CampaignEditActions.campaignEditChangeModalEditStatus,
    campaignEditCarrotPackageSave: CampaignEditActions.campaignEditCarrotPackageSave,
    confirmModal: ConfirmModalActions.showConfirmModal,
    campaignEditCarrotPackageSetList: CampaignEditActions.campaignEditCarrotPackageSetList,
    warningMessage: ToastrActions.warningMessage,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalEditCarrotPackage);
