import { put } from 'redux-saga/effects';
import { actions as toastrActions } from 'react-redux-toastr';

export function* successMessage({ title, message }) {
  yield put(
    toastrActions.add({
      type: 'success',
      title,
      message,
      options: {
        showCloseButton: true,
        progressBar: true,
        timeOut: 4000,
        removeOnHover: true,
        removeOnHoverTimeOut: 4000,
      },
    }),
  );
}

export function* errorMessage({ title, message }) {
  yield put(
    toastrActions.add({
      type: 'error',
      title,
      message,
      options: {
        showCloseButton: true,
        progressBar: true,
        timeOut: 4000,
        removeOnHover: true,
        removeOnHoverTimeOut: 4000,
      },
    }),
  );
}

export function* warningMessage({ title, message }) {
  yield put(
    toastrActions.add({
      type: 'warning',
      title,
      message,
      options: {
        showCloseButton: true,
        progressBar: true,
        timeOut: 4000,
        removeOnHover: true,
        removeOnHoverTimeOut: 4000,
      },
    }),
  );
}
