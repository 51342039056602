import { call, put, select } from 'redux-saga/effects';

import { replace } from 'connected-react-router';

import ProviderService from '~/services/ProviderService';

import { Creators as ProviderActions } from '~/redux/ducks/provider';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadProviderList() {
  yield put(startRequest());
  try {
    const provider = yield call(ProviderService.list);
    yield put(ProviderActions.loadProviderListSuccess(provider));
    yield put(ProviderActions.backupProviderList(provider));
    yield put(ProviderActions.loadProviderNew());
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os provedores. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* filterProviderList() {
  yield put(startRequest());
  try {
    const { name } = yield select(state => state.provider.filter);
    let provider = yield call(ProviderService.list);
    if (name) {
      provider = provider.filter(c => c.name.toUpperCase().indexOf(name.toUpperCase()) > -1);
    }
    yield put(ProviderActions.loadProviderListSuccess(provider));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os provedores. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* loadProviderEdit(action) {
  const providerId = action.payload;
  yield put(startRequest());
  try {
    const provider = yield call(ProviderService.get, providerId);
    yield put(ProviderActions.loadProviderEditSuccess(provider));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar o provedor. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* saveProviderEdit(action) {
  const provider = action.payload;
  yield put(startRequest());
  try {
    if (provider.uuid) {
      yield call(ProviderService.update, provider);
    } else {
      yield call(ProviderService.create, provider);
    }
    yield put(ProviderActions.loadProviderNew());
    yield put(replace('/providers'));
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Provedor salvo com sucesso.',
    ));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      e.message,
    ));
    yield put(requestError());
  }
}

export function* deleteProvider(action) {
  const providerId = action.payload;
  yield put(startRequest());
  try {
    yield call(ProviderService.excluir, providerId);
    yield put(ToastrMessageActions.successMessage(
      'Sucesso!',
      'Provedor excluido com sucesso.',
    ));

    yield put(ProviderActions.loadProviderList());

    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao excluir o provedor. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* resetSearchProvider() {
  const filter = { name: ''};
  const audienceUsersData = yield select(state => state.provider.backupProviderList);
  yield put(ProviderActions.updateFilter(filter));
  yield put(ProviderActions.loadProviderListSuccess(audienceUsersData));
}
