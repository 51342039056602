import '@babel/polyfill';
import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';

import React from 'react';
import * as Sentry from '@sentry/react';
import ReactDOM from 'react-dom';

import './config/dependencies';

import App from './App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({ dsn: 'https://1219db336ece47a4a24e2b0067f164f6@o417298.ingest.sentry.io/5316453' });
}

const pathReturn = sessionStorage.getItem('@ArtemisAdmin:path_return');
if (!pathReturn) {
  const { pathname } = window.location;
  sessionStorage.setItem('@ArtemisAdmin:path_return', pathname);
}

ReactDOM.render(<App />, document.getElementById('root'));
