
import React from 'react';

function ChevronDownIcon() {
  return (
    <svg width="13" height="8" viewBox="0 0 13 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.49898 6.23582C6.64351 6.09618 6.78621 5.95874 6.92839 5.82103C8.67348 4.13158 10.4186 2.44213 12.1631 0.752126C12.2468 0.671152 12.3393 0.610558 12.4517 0.587974C12.6242 0.55327 12.7695 0.61827 12.879 0.750474C12.9961 0.892042 13.0403 1.06308 12.9598 1.24238C12.9226 1.32528 12.8709 1.40791 12.8074 1.46988C11.4695 2.77567 10.129 4.07842 8.789 5.38173C8.14449 6.00859 7.49998 6.63518 6.85573 7.26205C6.63881 7.4733 6.35785 7.4744 6.1404 7.26315C4.15747 5.33491 2.17481 3.40639 0.191883 1.47787C0.104328 1.39276 0.0261817 1.3005 0.00553439 1.17132C-0.0310558 0.942444 0.117657 0.69952 0.337198 0.619372C0.513615 0.555198 0.669123 0.596787 0.802416 0.72541C1.88653 1.77312 2.96934 2.82221 4.05267 3.87103C4.85399 4.64662 5.65505 5.42249 6.45638 6.19809C6.46892 6.2102 6.48251 6.22122 6.49898 6.23609V6.23582Z" fill="#515151" />
    </svg>
  );
}

export default ChevronDownIcon;
