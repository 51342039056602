import { createReducer, createActions } from 'reduxsauce';
import Immutable from 'seamless-immutable';

/* Types & Creators */
export const { Types, Creators } = createActions({
  navigateToRoute: ['route'],
});

export const RedirectTypes = Types;
export default Creators;

/* Initial State */
const INITIAL_STATE = Immutable({});

/* Reducers */
// const relatorioMenuTreeRequestSuccess = (state, { menuTree }) => ({
//   ...state,
//   menuTree,
// });

export const reducer = createReducer(INITIAL_STATE, {});
