import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  MidiaWebreview,
} from './styles';

const RCSClaroPreview = () => (
  <MidiaWebreview>
    <div className="midia-web-preview-box">
      <div className="midia-web-preview-box-title">
        <span>Sem Preview</span>
      </div>
      <div className="midia-web-preview-box-contet">
        <p>Esse tipo de Criativo não tem visualização de preview.</p>
        <div>
          <b>Tipo: </b>
          RCS Claro
        </div>
      </div>
    </div>
  </MidiaWebreview>
);

RCSClaroPreview.propTypes = {
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(RCSClaroPreview);
