import styled from 'styled-components';
import {
  RowStyled,
  FormGroupStyled,
  InputStyled,
  InputMaskStyled,
} from '~/Components/Styled';


export const Container = styled.div``;

export const Input = styled(InputStyled)``;

export const InputMask = styled(InputMaskStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const RowFooter = styled(RowStyled)`
  justify-content: flex-end;
  width: 100%;
  margin-top: 20px;
  button {
    margin: 0;
  }
`;

export const ButtonCloseModal = styled.button`
  display: flex;
  padding: 0;
  border: 0;
  background-color: transparent;
  outline: none;
`;

export const FormGroup = styled(FormGroupStyled)`
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0 0 10px 0;
  label {
    > b {
      width: 60px;
      display: inline-block;
    }

    .field-date {
      width: 110px;
    }

    .field-hour {
      width: 75px;
      margin-left: 10px;
    }
  }
  .DayPickerInput {
    .DayPicker-wrapper {
      zoom: 160%;
    }

    .DayPicker-Day.DayPicker-Day--selected,
    .DayPicker-Day.DayPicker-Day--selected:hover {
      background-color: ${props => props.theme.colors.primary};
      color: ${props => props.theme.colors.white} !important;
    }
    .DayPicker-Day.DayPicker-Day--today {
      color: ${props => props.theme.colors.primary};
    }

    > input {
      border: solid 2px ${props => props.theme.colors.light};
      border-radius: 4px;
      background-color: transparent;
      font-size: 16px;
      font-weight: 400;
      color: ${props => props.theme.colors.dark};
      padding: 5px 15px;
      width: 110px;

      &:disabled {
        opacity: 0.6;
      }
    }
  }
`;

export const ClearButton = styled.button.attrs({
  type: 'button',
})`
  border: 0;
  background-color: transparent;
  color: ${props => props.theme.colors.primary};
  padding: 0 5px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
`;
