import styled, { css } from 'styled-components';

import {
  RowStyled, FormGroupStyled, InputStyled, TextareaStyled, SelectStyled,
} from '~/Components/Styled';


export const Container = styled.div`
  width: 100%;
  padding: 20px;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;

  .extensoes-permitidas-image {
    width: 100%;
    text-align: center;
    margin-top: 5px;
    font-style: italic;
  }
`;

export const Column = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const FormGroup = styled(FormGroupStyled)`
  flex: 1;
  margin: 0;
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const BaseConfirmRemove = styled.div`
  border: 2px solid #dbdbdb;
  border-radius: 5px;
  padding: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 250px;

  p {
    margin-bottom: 1px;
  }
`;

export const Input = styled(InputStyled)`
  margin-right: 0;
`;

export const Textarea = styled(TextareaStyled)`
  width: 100%;
  margin-top: 0;

  &.error {
    border-color: red;
  }
`;

export const Imagem = styled.div`
  padding: 20px;
  border: solid 2px ${props => props.theme.colors.light};
  border-radius: 4px;
  position: relative;

  .success-img {
    max-width: 150px;
    position: relative;
  }

  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;
export const BannerEmpty = styled.div`
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
`;

export const LimitCaracteres = styled.span`
  font-style: italic;
  color: #a7a7a7;

  &.error {
    color: red;
  }
`;

export const Select = styled(SelectStyled)``;

export const ErrorMessage = styled.span`
  color: ${props => props.theme.colors.danger2};
  font-style: italic;
`;
