import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import {
  Container, Header, Input, Button, Body, BaseInclude, Categories, Category, Name, Remove,
} from './styles';
import Icone from '~/Components/Icone';

function SegmentationActionConfig({
  categories, addCategory, removeCategory, errorMessage,
}) {
  const [categoryValue, setCategoryValue] = useState('');
  const inputRef = useRef(null);
  if (!categories) {
    return null;
  }

  const validateNewValue = (value) => {
    const exists = !!categories.find(c => c.toUpperCase() === value);
    if (exists) {
      errorMessage('Atenção!', 'Já existe uma categoria com este nome!');
    }
    return !exists;
  };

  const handleAddCategory = () => {
    if (categoryValue && categoryValue.replace(/\s/g, '')) {
      const categoryValueNew = categoryValue;

      if (!validateNewValue(categoryValueNew)) {
        return;
      }

      setCategoryValue('');
      inputRef.current.focus();
      addCategory(categoryValueNew);
    }
  };

  return (
    <Container>
      <Header>
        Abaixo você pode incluir as categorias que deseja incluir ao usuário quando o mesmo marcar essa resposta.
      </Header>
      <Body>
        <BaseInclude>
          <Input
            ref={inputRef}
            value={categoryValue}
            placeholder="Nova categoria"
            onChange={(e) => {
              const newValue = e.target.value || '';
              setCategoryValue(newValue.toUpperCase());
            }}
            onKeyPress={(e) => {
              if (e.key === 'Enter') {
                handleAddCategory();
              }
            }}
          />
          <Button small title="Adicionar" onClick={handleAddCategory} />
        </BaseInclude>
        <Categories>
          {categories.map(category => (
            <Category key={category}>
              <Name>{category}</Name>
              <Remove onClick={() => removeCategory(category)}>
                <Icone icon="trash-alt" color="#ff3b2d" size={25} />
              </Remove>
            </Category>
          ))}
        </Categories>
      </Body>
    </Container>
  );
}

SegmentationActionConfig.defaultProps = {
  categories: [],
};

SegmentationActionConfig.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape()),
  addCategory: PropTypes.func.isRequired,
  removeCategory: PropTypes.func.isRequired,
  errorMessage: PropTypes.func.isRequired,
};

export default SegmentationActionConfig;
