import styled from 'styled-components';

import { TextareaStyled, RowStyled } from '~/Components/Styled';
import { FormGroup as FormGroupStyled } from '../../styles';


export const FormGroup = styled(FormGroupStyled)`
  > label{
    > span {
      color: ${props => props.theme.colors.danger2};
      font-weight: normal;
      font-size: 20px;
    }
  }
`;

export const Textarea = styled(TextareaStyled)`
  width: 100%;
  resize: vertical;
`;

export const ContainerTabs = styled.div`
  display: flex;
  justify-content: space-between;

  .tab-has-error {
    color: red;
    margin-left: 5px;
    font-size: 12px;
  }

  .react-tabs {
    display: flex;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    flex-direction: column;
    color: ${props => props.theme.colors.primary};
  }

  .react-tabs__tab-list {
    margin-bottom: 0;
    border-bottom: none;
  }

  .react-tabs__tab {
    border-color: ${props => props.theme.colors.light};
    border-bottom: 3px solid ${props => props.theme.colors.light};
    font-size: 24px;
    color: ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.lighterWhite};
  }

  .react-tabs__tab.react-tabs__tab--selected {
    border-radius: 0;
    border-bottom: 3px solid #fff;
    color: ${props => props.theme.colors.white};
    background-color: ${props => props.theme.colors.primary};
  }

  .react-tabs__tab-panel--selected {
    display: flex;
    width: 100%;
    border-radius: 0 8px 8px 8px;
    border: solid 1px ${props => props.theme.colors.light};
    align-items: flex-start;
    justify-content: flex-start;
    .tab-body {
      padding: 15px;
      flex: 1;
      display: flex;
      flex-direction: column;
      width: calc(100% - 30px);
      flex-shrink: 0;
      background-color: ${props => props.theme.colors.bgBody};
    }
  }
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  .title-separator {
    margin-top: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    width: 100%;
  }
`;

export const ErrorLink = styled.span`
  font-style: italic;
  color: ${props => props.theme.colors.danger2};
  padding-top: 5px;
`;
