/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaInfoCircle } from 'react-icons/fa';
import ReactTooltip from 'react-tooltip';
import { Creators as NewGridPeriodCreators } from '~/redux/ducks/newGridPeriod';
import { Creators as ProductActions } from '~/redux/ducks/product';
import { Creators as ConfirmModalActions } from '~/redux/ducks/confirmModal';

import { general } from '~/Styles';
import {
  ButtonGridPeriodIcon,
  InfoIcon,
  TrashIcon,
  MoreIcon,
  ChevronDownIcon,
} from '../../../Components';

import {
  Container,
  ModalBox,
  StyledDiv,
  ModalContent,
  ContentBlock,
  ContentBlockSummaryCard,
  Select,
  ButtonShow,
  ButtonInfo,
  ButtonDelete,
  ButtonAdd,
  Table,
  TableInput,
  BoxDailyVolumes,
  BoxDailyVolumesItem,
  InfoWeek,
} from './styles';
import { convertFloatReal, getBudgetLabelString } from '~/Utils';
import ModalDetailProduct from '~/Pages/Product/ModalDetailProduct';
import ModalTeste from './components/ModalTeste';
import ModalCpaFixo from './components/ModalCpaFixo';
import ModalBonificadas from './components/ModalBonificadas';

const statusTypes = [
  { value: 'ANALYZING', label: 'Em análise' },
  { value: 'DONE', label: 'Validado' },
];

const typesAuctions = [
  { value: 'WINNER', label: 'Vencedor' },
  { value: 'RESERVATION', label: 'Reserva' },
  { value: 'IGNORED', label: 'Ignorado' },
];

const getBudget = (item) => {
  const budgetItem = parseFloat(item.budget);
  const cpaItem = parseFloat((item.cpa * item.conversionRate) / 100);
  try {
    const calc = Math.round(budgetItem / cpaItem);
    return calc > 0 ? calc : 'Ilimitado';
  } catch (error) {
    return 0;
  }
};

function ModalChannel({
  showModalChannel,
  closeModalChannel,
  periodSelected,

  zone,
  channel,
  configZone,
  fieldConfigurations,

  changeStatusAuction,
  changeStatusProbe,
  changeStatusFixedCpa,
  changeStatusBonus,
  showDetailProductSetData,
  updateItemAuction,
  updateItemProbe,
  updateItemFixedCpa,
  updateItemBonus,

  removeCampaignProbe,
  removeCampaignFixedCpa,
  removeCampaignBonus,

  gridPeriodSetFilterModalCampaign,
  gridPeriodCampaignByFilterRequest,
  gridPeriodChangeModalStatus,
  gridPeriodSetCampaignToAdd,

  confirmModal,

  disparosSatpush,
}) {
  if (!showModalChannel) {
    return null;
  }

  const disabledAll = !periodSelected.gridOpen;

  const [showLeilaoData, setShowLeilaoData] = useState(true);
  const [showTesteData, setShowTesteData] = useState(true);
  const [showCpaFixoData, setShowCpaFixoData] = useState(true);
  const [showBonificacaoData, setShowBonificacaoData] = useState(true);

  function handleRemoveCampaignProbe(item) {
    confirmModal(
      'Deseja remover a campanha?',
      item.campaign.name,
      async () => {
        removeCampaignProbe(item);
      },
    );
  }
  function handleAddCampaignProbe() {
    const { probe } = channel;
    gridPeriodSetFilterModalCampaign({
      page: 0,
      type: probe.uuid,
      typeName: probe.name,
      zone: zone.uuid,
    });
    gridPeriodCampaignByFilterRequest();
    gridPeriodChangeModalStatus({
      modalTeste: true,
    });
    gridPeriodSetCampaignToAdd([]);
  }

  function handleRemoveCampaignFixedCpa(item) {
    confirmModal(
      'Deseja remover a campanha?',
      item.campaign.name,
      async () => {
        removeCampaignFixedCpa(item);
      },
    );
  }
  function handleAddCampaignFixedCpa() {
    const { fixedCpa } = channel;
    gridPeriodSetFilterModalCampaign({
      page: 0,
      type: fixedCpa.uuid,
      typeName: fixedCpa.name,
      zone: zone.uuid,
    });
    gridPeriodCampaignByFilterRequest();
    gridPeriodChangeModalStatus({
      modalCpaFixo: true,
    });
    gridPeriodSetCampaignToAdd([]);
  }

  function handleRemoveCampaignBonus(item) {
    confirmModal(
      'Deseja remover a campanha?',
      item.campaign.name,
      async () => {
        removeCampaignBonus(item);
      },
    );
  }
  function handleAddCampaignBonus() {
    const { bonus } = channel;
    gridPeriodSetFilterModalCampaign({
      page: 0,
      type: bonus.uuid,
      typeName: bonus.name,
      zone: zone.uuid,
    });
    gridPeriodCampaignByFilterRequest();
    gridPeriodChangeModalStatus({
      modalBonificadas: true,
    });
    gridPeriodSetCampaignToAdd([]);
  }

  function onCloseModalChannel() {
    closeModalChannel();
  }

  function onShowLeilaoData(show) {
    setShowLeilaoData(show);
  }
  function onShowTesteData(show) {
    setShowTesteData(show);
  }
  function onShowCpaFixoData(show) {
    setShowCpaFixoData(show);
  }
  function onShowBonificacaoData(show) {
    setShowBonificacaoData(show);
  }

  function getDescriptionType(type) {
    switch (type) {
      case 'TEST':
        return 'Teste';
      case 'TEST_AB':
        return 'Teste A/B';
      case 'IGNORED':
        return 'Ignorado';
      default:
        return type;
    }
  }

  function renderAuction() {
    if (!channel || !channel.auction || !configZone) {
      return null;
    }
    const { config, items, status } = channel.auction;

    const { modeVolumeWeekly } = configZone;

    const disabled = disabledAll || status === 'DONE';

    return (
      <StyledDiv bordered>
        <h3>
          LEILÃO
        </h3>
        <ContentBlock>
          <div className="block-summary">
            <div className="content-block-summary">
              <ContentBlockSummaryCard>
                <span>Status</span>
                <Select
                  id="period"
                  name="period"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statusTypes}
                  isDisabled={disabledAll}
                  value={status ? statusTypes.find(s => s.value === status) : 'NOT_STATUS'}
                  onChange={e => changeStatusAuction(e.value)}
                />
              </ContentBlockSummaryCard>
              {/* <ContentBlockSummaryCard>
                    <span>Campanhas à validar</span>
                    <span className="card-text-info">02</span>
                  </ContentBlockSummaryCard>
                  <ContentBlockSummaryCard>
                    <span>Campanhas já validadas</span>
                    <span className="card-text-success">10</span>
                  </ContentBlockSummaryCard> */}
              <ContentBlockSummaryCard gray>
                <span>Configurações</span>
                <div className="div-transform-to-columns">
                  <div>
                    <span>Nº de Ganhadores:</span>
                    <span className="text-bold">{config.countWinners}</span>
                  </div>
                  <div>
                    <span>Nº de Reservas:</span>
                    <span className="text-bold">{config.countReservations}</span>
                  </div>
                </div>
              </ContentBlockSummaryCard>
            </div>
            {!showLeilaoData && (
            <ButtonShow onClick={() => onShowLeilaoData(true)}>
              <ChevronDownIcon />
            </ButtonShow>
            )}
            {showLeilaoData && (
            <ButtonShow onClick={() => onShowLeilaoData(false)} inverseIcon>
              <ChevronDownIcon />
            </ButtonShow>
            )}
          </div>
          {showLeilaoData && (
            <div className="block-content">
              <Table>
                <thead>
                  <tr>
                    <th>Pos</th>
                    <th>Parceiro</th>
                    <th>Disparador</th>
                    <th>Nome</th>
                    <th>Keyword</th>
                    <th>Produto - Oferta</th>
                    <th>Conversão</th>
                    <th>eCPM</th>
                    <th>Budget</th>
                    <th>CPA</th>
                    <th>%</th>
                    <th>Vol. Budget</th>
                    <th>Situação</th>
                    <th>Indicad.</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, indexItem) => (
                    <>
                      {indexItem > 0 && (
                        <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
                          <td colSpan={14} />
                        </tr>
                      )}
                      <tr key={`grid-item-${item.uuid}`} className="grid-content">
                        <td>{item.ranking}</td>
                        <td>{item.campaign.contract ? item.campaign.contract.name : ''}</td>
                        <td>{item.campaign.zone ? item.campaign.zone.sender : ''}</td>
                        <td>{item.campaign.name}</td>
                        <td>
                          {item.campaign.productOffers.map(po => (
                            <span key={po.CampaignProductOffer.uuid}>
                              {`${po.CampaignProductOffer.keyword}`}
                              <br />
                            </span>
                          ))}
                        </td>
                        <td>
                          {item.campaign.productOffers.map(po => (
                            <span key={po.uuid}>
                              {`${po.product.name} - ${po.name}`}
                              <br />
                            </span>
                          ))}

                        </td>
                        <td>{item.conversionRate || ''}</td>
                        <td>{convertFloatReal(item.ecpm)}</td>
                        <td>{getBudgetLabelString(item.budget)}</td>
                        <td>{`R$ ${convertFloatReal(item.cpa)}`}</td>
                        <td>{`${item.percentage.toLocaleString()}%`}</td>
                        <td>{getBudget(item)}</td>
                        <td>
                          <Select
                            id="period"
                            name="period"
                            className="react-select-container"
                            classNamePrefix="react-select"
                            width="185"
                            isDisabled={disabled}
                            options={typesAuctions}
                            value={item.type ? typesAuctions.find(t => t.value === item.type) : 'IGNORED'}
                            onChange={(e) => {
                              updateItemAuction({ ...item, type: e.value }, true);
                            }}
                          />
                        </td>
                        <td>
                          <div className="info-button-div">
                            <ButtonInfo onClick={() => {
                              const productOffer = item.campaign.productOffers[0];
                              if (productOffer && productOffer.product) {
                                showDetailProductSetData(productOffer.product);
                              }
                            }}
                            >
                              <InfoIcon />
                            </ButtonInfo>
                          </div>
                        </td>
                      </tr>
                      {modeVolumeWeekly && (
                        <tr key={`grid-form-weekly-${item.uuid}`} className="grid-form">
                          <td colSpan={14}>

                            <BoxDailyVolumes>
                              <ReactTooltip
                                place="bottom"
                                effect="solid"
                              />
                              {/* <BoxDailyVolumesItem separator>
                                <label>Volume Total</label>
                                <TableInput
                                  disabled
                                  type="number"
                                  style={{ marginRight: 0 }}
                                  placeholder="Volume"
                                  value={item.volume}
                                  onChange={(e) => { }}
                                />
                              </BoxDailyVolumesItem> */}
                              <BoxDailyVolumesItem>
                                <label>
                                  Dom
                                  {!fieldConfigurations[0].enabled && (
                                  <InfoWeek data-tip={fieldConfigurations[0].message}>
                                    <FaInfoCircle />
                                  </InfoWeek>
                                  )}
                                </label>
                                <TableInput
                                  disabled={!fieldConfigurations[0].enabled || disabled || item.type === 'IGNORED'}
                                  type="number"
                                  style={{ marginRight: 0 }}
                                  placeholder="DOM"
                                  value={item.dailyDistributionBefore[0]}
                                  onChange={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, false);
                                  }}
                                  onBlur={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, true);
                                  }}
                                />
                              </BoxDailyVolumesItem>
                              <BoxDailyVolumesItem>
                                <label>
                                  SEG
                                  {!fieldConfigurations[1].enabled && (
                                  <InfoWeek data-tip={fieldConfigurations[1].message}>
                                    <FaInfoCircle />
                                  </InfoWeek>
                                  )}
                                </label>
                                <TableInput
                                  disabled={!fieldConfigurations[1].enabled || disabled || item.type === 'IGNORED'}
                                  type="number"
                                  style={{ marginRight: 0 }}
                                  placeholder="SEG"
                                  value={item.dailyDistributionBefore[1]}
                                  onChange={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, false);
                                  }}
                                  onBlur={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, true);
                                  }}
                                />
                              </BoxDailyVolumesItem>
                              <BoxDailyVolumesItem>
                                <label>
                                  TER
                                  {!fieldConfigurations[2].enabled && (
                                  <InfoWeek data-tip={fieldConfigurations[2].message}>
                                    <FaInfoCircle />
                                  </InfoWeek>
                                  )}
                                </label>
                                <TableInput
                                  disabled={!fieldConfigurations[2].enabled || disabled || item.type === 'IGNORED'}
                                  type="number"
                                  style={{ marginRight: 0 }}
                                  placeholder="TER"
                                  value={item.dailyDistributionBefore[2]}
                                  onChange={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, false);
                                  }}
                                  onBlur={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, true);
                                  }}
                                />
                              </BoxDailyVolumesItem>
                              <BoxDailyVolumesItem>
                                <label>
                                  QUA
                                  {!fieldConfigurations[3].enabled && (
                                  <InfoWeek data-tip={fieldConfigurations[3].message}>
                                    <FaInfoCircle />
                                  </InfoWeek>
                                  )}
                                </label>
                                <TableInput
                                  disabled={!fieldConfigurations[3].enabled || disabled || item.type === 'IGNORED'}
                                  type="number"
                                  style={{ marginRight: 0 }}
                                  placeholder="QUA"
                                  value={item.dailyDistributionBefore[3]}
                                  onChange={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, false);
                                  }}
                                  onBlur={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, true);
                                  }}
                                />
                              </BoxDailyVolumesItem>
                              <BoxDailyVolumesItem>
                                <label>
                                  QUI
                                  {!fieldConfigurations[4].enabled && (
                                  <InfoWeek data-tip={fieldConfigurations[4].message}>
                                    <FaInfoCircle />
                                  </InfoWeek>
                                  )}
                                </label>
                                <TableInput
                                  disabled={!fieldConfigurations[4].enabled || disabled || item.type === 'IGNORED'}
                                  type="number"
                                  style={{ marginRight: 0 }}
                                  placeholder="QUI"
                                  value={item.dailyDistributionBefore[4]}
                                  onChange={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, false);
                                  }}
                                  onBlur={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[5],
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, true);
                                  }}
                                />
                              </BoxDailyVolumesItem>
                              <BoxDailyVolumesItem>
                                <label>
                                  SEX
                                  {!fieldConfigurations[5].enabled && (
                                  <InfoWeek data-tip={fieldConfigurations[5].message}>
                                    <FaInfoCircle />
                                  </InfoWeek>
                                  )}
                                </label>
                                <TableInput
                                  disabled={!fieldConfigurations[5].enabled || disabled || item.type === 'IGNORED'}
                                  type="number"
                                  style={{ marginRight: 0 }}
                                  placeholder="SEX"
                                  value={item.dailyDistributionBefore[5]}
                                  onChange={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, false);
                                  }}
                                  onBlur={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                        item.dailyDistributionBefore[6],
                                      ],
                                    }, true);
                                  }}
                                />
                              </BoxDailyVolumesItem>
                              <BoxDailyVolumesItem>
                                <label>
                                  SAB
                                  {!fieldConfigurations[6].enabled && (
                                  <InfoWeek data-tip={fieldConfigurations[6].message}>
                                    <FaInfoCircle />
                                  </InfoWeek>
                                  )}
                                </label>
                                <TableInput
                                  disabled={!fieldConfigurations[6].enabled || disabled || item.type === 'IGNORED'}
                                  type="number"
                                  style={{ marginRight: 0 }}
                                  placeholder="SAB"
                                  value={item.dailyDistributionBefore[6]}
                                  onChange={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      ],
                                    }, false);
                                  }}
                                  onBlur={(e) => {
                                    updateItemAuction({
                                      ...item,
                                      dailyDistributionBefore: [
                                        item.dailyDistributionBefore[0],
                                        item.dailyDistributionBefore[1],
                                        item.dailyDistributionBefore[2],
                                        item.dailyDistributionBefore[3],
                                        item.dailyDistributionBefore[4],
                                        item.dailyDistributionBefore[5],
                                        parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      ],
                                    }, true);
                                  }}
                                />
                              </BoxDailyVolumesItem>
                            </BoxDailyVolumes>
                          </td>
                        </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </ContentBlock>
      </StyledDiv>
    );
  }

  function renderProbe() {
    if (!channel || !channel.probe) {
      return null;
    }
    const { config, items, status } = channel.probe;

    const { modeVolumeWeekly } = configZone;

    const disabled = disabledAll || status === 'DONE';

    return (
      <StyledDiv bordered>
        <h3>
          TESTE
        </h3>
        <ContentBlock>
          <div className="block-summary">
            <div className="content-block-summary">
              <ContentBlockSummaryCard>
                <span>Status</span>
                <Select
                  id="period"
                  name="period"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statusTypes}
                  isDisabled={disabledAll}
                  value={status ? statusTypes.find(s => s.value === status) : 'NOT_STATUS'}
                  onChange={e => changeStatusProbe(e.value)}
                />
              </ContentBlockSummaryCard>
              {/* <ContentBlockSummaryCard>
                    <span>Campanhas à validar</span>
                    <span className="card-text-info">02</span>
                  </ContentBlockSummaryCard>
                  <ContentBlockSummaryCard>
                    <span>Campanhas já validadas</span>
                    <span className="card-text-success">10</span>
                  </ContentBlockSummaryCard> */}
              <ContentBlockSummaryCard gray>
                <span>Configurações</span>
                <div className="div-transform-to-columns">
                  <div>
                    <span>Valor teste normal:</span>
                    <span className="text-bold">{`R$ ${convertFloatReal(config.cpa)}`}</span>
                  </div>
                </div>
              </ContentBlockSummaryCard>
              <ContentBlockSummaryCard>
                <ButtonAdd disabled={disabled} onClick={handleAddCampaignProbe}>
                  <MoreIcon />
                  <span>Adicionar Campanhas</span>
                </ButtonAdd>
              </ContentBlockSummaryCard>
            </div>
            {!showTesteData && (
            <ButtonShow onClick={() => onShowTesteData(true)}>
              <ChevronDownIcon />
            </ButtonShow>
            )}
            {showTesteData && (
            <ButtonShow onClick={() => onShowTesteData(false)} inverseIcon>
              <ChevronDownIcon />
            </ButtonShow>
            )}
          </div>
          {showTesteData && (
          <div className="block-content">
            <Table>
              <thead>
                <tr>
                  <th>Parceiro</th>
                  <th>Disparador</th>
                  <th>Nome</th>
                  <th>Keyword</th>
                  <th>Produto - Oferta</th>
                  <th>Tipo</th>
                  <th>CPA</th>
                  <th>Remover</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, indexItem) => (
                  <>
                    {indexItem > 0 && (
                      <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
                        <td colSpan={14} />
                      </tr>
                    )}
                    <tr key={`grid-item-${item.uuid}`} className="grid-content">
                      <td>{item.campaign.contract ? item.campaign.contract.name : ''}</td>
                      <td>{item.campaign.zone ? item.campaign.zone.sender : ''}</td>
                      <td>{item.campaign.name}</td>
                      <td>
                        {item.campaign.productOffers.map(po => (
                          <span key={po.CampaignProductOffer.uuid}>
                            {`${po.CampaignProductOffer.keyword}`}
                            <br />
                          </span>
                        ))}
                      </td>
                      <td>
                        {item.campaign.productOffers.map(po => (
                          <span key={po.uuid}>
                            {`${po.product.name} - ${po.name}`}
                            <br />
                          </span>
                        ))}
                      </td>
                      <td>{getDescriptionType(item.type)}</td>
                      <td>
                        {item.type === 'TEST_AB' || item.type === 'IGNORED' || disabled
                          ? item.type === 'IGNORED' ? '-' : `R$ ${convertFloatReal(item.cpa)}`
                          : (
                            <TableInput
                              type="number"
                              value={item.cpa}
                              onChange={(e) => {
                                updateItemProbe({ ...item, cpa: e.target.value }, false);
                              }}
                              onBlur={(e) => {
                                updateItemProbe({ ...item, cpa: e.target.value }, true);
                              }}
                            />
                          )}
                      </td>
                      <td>
                        <div className="delete-button-div">
                          <ButtonDelete disabled={disabled} onClick={() => handleRemoveCampaignProbe(item)}>
                            <TrashIcon />
                          </ButtonDelete>
                        </div>
                      </td>
                    </tr>
                    {modeVolumeWeekly && (
                    <tr key={`grid-form-weekly-${item.uuid}`} className="grid-form">
                      <td colSpan={14}>

                        <BoxDailyVolumes>
                          <ReactTooltip
                            place="bottom"
                            effect="solid"
                          />
                          {/* <BoxDailyVolumesItem separator>
                            <label>Volume Total</label>
                            <TableInput
                              disabled
                              type="number"
                              style={{ marginRight: 0 }}
                              placeholder="Volume"
                              value={item.volume}
                              onChange={(e) => { }}
                            />
                          </BoxDailyVolumesItem> */}
                          <BoxDailyVolumesItem>
                            <label>
                              Dom
                              {!fieldConfigurations[0].enabled && (
                              <InfoWeek data-tip={fieldConfigurations[0].message}>
                                <FaInfoCircle />
                              </InfoWeek>
                              )}
                            </label>
                            <TableInput
                              disabled={!fieldConfigurations[0].enabled || disabled || item.type === 'IGNORED'}
                              type="number"
                              style={{ marginRight: 0 }}
                              placeholder="DOM"
                              value={item.dailyDistributionBefore[0]}
                              onChange={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, false);
                              }}
                              onBlur={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, true);
                              }}
                            />
                          </BoxDailyVolumesItem>
                          <BoxDailyVolumesItem>
                            <label>
                              SEG
                              {!fieldConfigurations[1].enabled && (
                              <InfoWeek data-tip={fieldConfigurations[1].message}>
                                <FaInfoCircle />
                              </InfoWeek>
                              )}
                            </label>
                            <TableInput
                              disabled={!fieldConfigurations[1].enabled || disabled || item.type === 'IGNORED'}
                              type="number"
                              style={{ marginRight: 0 }}
                              placeholder="SEG"
                              value={item.dailyDistributionBefore[1]}
                              onChange={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, false);
                              }}
                              onBlur={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, true);
                              }}
                            />
                          </BoxDailyVolumesItem>
                          <BoxDailyVolumesItem>
                            <label>
                              TER
                              {!fieldConfigurations[2].enabled && (
                              <InfoWeek data-tip={fieldConfigurations[2].message}>
                                <FaInfoCircle />
                              </InfoWeek>
                              )}
                            </label>
                            <TableInput
                              disabled={!fieldConfigurations[2].enabled || disabled || item.type === 'IGNORED'}
                              type="number"
                              style={{ marginRight: 0 }}
                              placeholder="TER"
                              value={item.dailyDistributionBefore[2]}
                              onChange={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, false);
                              }}
                              onBlur={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, true);
                              }}
                            />
                          </BoxDailyVolumesItem>
                          <BoxDailyVolumesItem>
                            <label>
                              QUA
                              {!fieldConfigurations[3].enabled && (
                              <InfoWeek data-tip={fieldConfigurations[3].message}>
                                <FaInfoCircle />
                              </InfoWeek>
                              )}
                            </label>
                            <TableInput
                              disabled={!fieldConfigurations[3].enabled || disabled || item.type === 'IGNORED'}
                              type="number"
                              style={{ marginRight: 0 }}
                              placeholder="QUA"
                              value={item.dailyDistributionBefore[3]}
                              onChange={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, false);
                              }}
                              onBlur={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, true);
                              }}
                            />
                          </BoxDailyVolumesItem>
                          <BoxDailyVolumesItem>
                            <label>
                              QUI
                              {!fieldConfigurations[4].enabled && (
                              <InfoWeek data-tip={fieldConfigurations[4].message}>
                                <FaInfoCircle />
                              </InfoWeek>
                              )}
                            </label>
                            <TableInput
                              disabled={!fieldConfigurations[4].enabled || disabled || item.type === 'IGNORED'}
                              type="number"
                              style={{ marginRight: 0 }}
                              placeholder="QUI"
                              value={item.dailyDistributionBefore[4]}
                              onChange={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, false);
                              }}
                              onBlur={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[5],
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, true);
                              }}
                            />
                          </BoxDailyVolumesItem>
                          <BoxDailyVolumesItem>
                            <label>
                              SEX
                              {!fieldConfigurations[5].enabled && (
                              <InfoWeek data-tip={fieldConfigurations[5].message}>
                                <FaInfoCircle />
                              </InfoWeek>
                              )}
                            </label>
                            <TableInput
                              disabled={!fieldConfigurations[5].enabled || disabled || item.type === 'IGNORED'}
                              type="number"
                              style={{ marginRight: 0 }}
                              placeholder="SEX"
                              value={item.dailyDistributionBefore[5]}
                              onChange={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, false);
                              }}
                              onBlur={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    item.dailyDistributionBefore[6],
                                  ],
                                }, true);
                              }}
                            />
                          </BoxDailyVolumesItem>
                          <BoxDailyVolumesItem>
                            <label>
                              SAB
                              {!fieldConfigurations[6].enabled && (
                              <InfoWeek data-tip={fieldConfigurations[6].message}>
                                <FaInfoCircle />
                              </InfoWeek>
                              )}
                            </label>
                            <TableInput
                              disabled={!fieldConfigurations[6].enabled || disabled || item.type === 'IGNORED'}
                              type="number"
                              style={{ marginRight: 0 }}
                              placeholder="SAB"
                              value={item.dailyDistributionBefore[6]}
                              onChange={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                  ],
                                }, false);
                              }}
                              onBlur={(e) => {
                                updateItemProbe({
                                  ...item,
                                  dailyDistributionBefore: [
                                    item.dailyDistributionBefore[0],
                                    item.dailyDistributionBefore[1],
                                    item.dailyDistributionBefore[2],
                                    item.dailyDistributionBefore[3],
                                    item.dailyDistributionBefore[4],
                                    item.dailyDistributionBefore[5],
                                    parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                  ],
                                }, true);
                              }}
                            />
                          </BoxDailyVolumesItem>
                        </BoxDailyVolumes>
                      </td>
                    </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
          )}
        </ContentBlock>
      </StyledDiv>
    );
  }

  function renderFixedCpa() {
    if (!channel || !channel.fixedCpa) {
      return null;
    }
    const { config, items, status } = channel.fixedCpa;

    const { modeVolumeWeekly } = configZone;

    const disabled = disabledAll || status === 'DONE';

    return (
      <StyledDiv bordered>
        <h3>
          CPA FIXO
        </h3>
        <ContentBlock>
          <div className="block-summary">
            <div className="content-block-summary">
              <ContentBlockSummaryCard>
                <span>Status</span>
                <Select
                  id="period"
                  name="period"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statusTypes}
                  isDisabled={disabledAll}
                  value={status ? statusTypes.find(s => s.value === status) : 'NOT_STATUS'}
                  onChange={e => changeStatusFixedCpa(e.value)}
                />
              </ContentBlockSummaryCard>
              {/* <ContentBlockSummaryCard>
                    <span>Campanhas à validar</span>
                    <span className="card-text-info">02</span>
                  </ContentBlockSummaryCard>
                  <ContentBlockSummaryCard>
                    <span>Campanhas já validadas</span>
                    <span className="card-text-success">10</span>
                  </ContentBlockSummaryCard> */}
              <ContentBlockSummaryCard gray>
                <span>Configurações</span>
                <div className="div-transform-to-columns">
                  <div>
                    <span>Valor normal:</span>
                    <span className="text-bold">{`R$ ${convertFloatReal(config.cpa)}`}</span>
                  </div>
                </div>
              </ContentBlockSummaryCard>
              <ContentBlockSummaryCard>
                <ButtonAdd disabled={disabled} onClick={handleAddCampaignFixedCpa}>
                  <MoreIcon />
                  <span>Adicionar Campanhas</span>
                </ButtonAdd>
              </ContentBlockSummaryCard>
            </div>
            {!showCpaFixoData && (
            <ButtonShow onClick={() => onShowCpaFixoData(true)}>
              <ChevronDownIcon />
            </ButtonShow>
            )}
            {showCpaFixoData && (
            <ButtonShow onClick={() => onShowCpaFixoData(false)} inverseIcon>
              <ChevronDownIcon />
            </ButtonShow>
            )}
          </div>
          {showCpaFixoData && (
            <div className="block-content">
              <Table>
                <thead>
                  <tr>
                    <th>Parceiro</th>
                    <th>Disparador</th>
                    <th>Nome</th>
                    <th>Keyword</th>
                    <th>Produto - Oferta</th>
                    <th>Conversão</th>
                    <th>eCPM</th>
                    <th>Budget</th>
                    <th>CPA</th>
                    <th>Volume</th>
                    <th>Remover</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item, indexItem) => (
                    <>
                      {indexItem > 0 && (
                        <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
                          <td colSpan={14} />
                        </tr>
                      )}
                      <tr key={`grid-item-${item.uuid}`} className="grid-content">
                        <td>{item.campaign.contract ? item.campaign.contract.name : ''}</td>
                        <td>{item.campaign.zone ? item.campaign.zone.sender : ''}</td>
                        <td>{item.campaign.name}</td>
                        <td>
                          {item.campaign.productOffers.map(po => (
                            <span key={po.CampaignProductOffer.uuid}>
                              {`${po.CampaignProductOffer.keyword}`}
                              <br />
                            </span>
                          ))}
                        </td>
                        <td>
                          {item.campaign.productOffers.map(po => (
                            <span key={po.uuid}>
                              {`${po.product.name} - ${po.name}`}
                              <br />
                            </span>
                          ))}
                        </td>
                        <td>{item.conversionRate || ''}</td>
                        <td>{item.ecpm.replace('.', ',')}</td>
                        <td>{getBudgetLabelString(item.budget)}</td>
                        <td>
                          {disabled ? (
                            `R$ ${convertFloatReal(item.cpa)}`
                          ) : (
                            <TableInput
                              type="number"
                              value={item.cpa}
                              onChange={(e) => {
                                updateItemFixedCpa({ ...item, cpa: e.target.value }, false);
                              }}
                              onBlur={(e) => {
                                updateItemFixedCpa({ ...item, cpa: e.target.value }, true);
                              }}
                            />
                          )}
                        </td>
                        <td>
                          {(disabled || modeVolumeWeekly) ? (
                            parseInt(item.volume, 10).toLocaleString()
                          ) : (
                            <TableInput
                              type="number"
                              value={item.volume}
                              onChange={(e) => {
                                updateItemFixedCpa({ ...item, volume: e.target.value }, false);
                              }}
                              onBlur={(e) => {
                                updateItemFixedCpa({ ...item, volume: e.target.value }, true);
                              }}
                            />
                          )}
                        </td>
                        <td>
                          <div className="delete-button-div">
                            <ButtonDelete disabled={disabled} onClick={() => handleRemoveCampaignFixedCpa(item)}>
                              <TrashIcon />
                            </ButtonDelete>
                          </div>
                        </td>
                      </tr>
                      {modeVolumeWeekly && (
                      <tr key={`grid-form-weekly-${item.uuid}`} className="grid-form">
                        <td colSpan={14}>

                          <BoxDailyVolumes>
                            <ReactTooltip
                              place="bottom"
                              effect="solid"
                            />
                            {/* <BoxDailyVolumesItem separator>
                              <label>Volume Total</label>
                              <TableInput
                                disabled
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="Volume"
                                value={item.volume}
                                onChange={(e) => { }}
                              />
                            </BoxDailyVolumesItem> */}
                            <BoxDailyVolumesItem>
                              <label>
                                Dom
                                {!fieldConfigurations[0].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[0].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[0].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="DOM"
                                value={item.dailyDistributionBefore[0]}
                                onChange={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                SEG
                                {!fieldConfigurations[1].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[1].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[1].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="SEG"
                                value={item.dailyDistributionBefore[1]}
                                onChange={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                TER
                                {!fieldConfigurations[2].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[2].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[2].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="TER"
                                value={item.dailyDistributionBefore[2]}
                                onChange={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                QUA
                                {!fieldConfigurations[3].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[3].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[3].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="QUA"
                                value={item.dailyDistributionBefore[3]}
                                onChange={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                QUI
                                {!fieldConfigurations[4].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[4].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[4].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="QUI"
                                value={item.dailyDistributionBefore[4]}
                                onChange={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                SEX
                                {!fieldConfigurations[5].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[5].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[5].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="SEX"
                                value={item.dailyDistributionBefore[5]}
                                onChange={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                SAB
                                {!fieldConfigurations[6].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[6].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[6].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="SAB"
                                value={item.dailyDistributionBefore[6]}
                                onChange={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemFixedCpa({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                          </BoxDailyVolumes>
                        </td>
                      </tr>
                      )}
                    </>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </ContentBlock>
      </StyledDiv>
    );
  }

  function renderBonus() {
    if (!channel || !channel.bonus) {
      return null;
    }
    const { config, items, status } = channel.bonus;

    const { modeVolumeWeekly } = configZone;

    const disabled = disabledAll || status === 'DONE';

    return (
      <StyledDiv bordered>
        <h3>
          BONIFICAÇÃO
        </h3>
        <ContentBlock>
          <div className="block-summary">
            <div className="content-block-summary">
              <ContentBlockSummaryCard>
                <span>Status</span>
                <Select
                  id="period"
                  name="period"
                  className="react-select-container"
                  classNamePrefix="react-select"
                  options={statusTypes}
                  isDisabled={disabledAll}
                  value={status ? statusTypes.find(s => s.value === status) : 'NOT_STATUS'}
                  onChange={e => changeStatusBonus(e.value)}
                />
              </ContentBlockSummaryCard>
              {/* <ContentBlockSummaryCard>
                    <span>Campanhas à validar</span>
                    <span className="card-text-info">02</span>
                  </ContentBlockSummaryCard>
                  <ContentBlockSummaryCard>
                    <span>Campanhas já validadas</span>
                    <span className="card-text-success">10</span>
                  </ContentBlockSummaryCard> */}
              <ContentBlockSummaryCard gray>
                <span>Configurações</span>
                <div className="div-transform-to-columns">
                  <div>
                    <span>Volume fixo:</span>
                    <span className="text-bold">{config.fixedVolume}</span>
                  </div>
                </div>
              </ContentBlockSummaryCard>
              <ContentBlockSummaryCard>
                <ButtonAdd disabled={disabled} onClick={handleAddCampaignBonus}>
                  <MoreIcon />
                  <span>Adicionar Campanhas</span>
                </ButtonAdd>
              </ContentBlockSummaryCard>
            </div>
            {!showBonificacaoData && (
            <ButtonShow onClick={() => onShowBonificacaoData(true)}>
              <ChevronDownIcon />
            </ButtonShow>
            )}
            {showBonificacaoData && (
            <ButtonShow onClick={() => onShowBonificacaoData(false)} inverseIcon>
              <ChevronDownIcon />
            </ButtonShow>
            )}
          </div>
          {showBonificacaoData && (
          <div className="block-content">
            <Table>
              <thead>
                <tr>
                  <th>Parceiro</th>
                  <th>Disparador</th>
                  <th>Nome</th>
                  <th>Keyword</th>
                  <th>Produto - Oferta</th>
                  {/* <th>Conversão</th> */}
                  <th>Volume</th>
                  <th>Remover</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, indexItem) => (
                  <>
                    {indexItem > 0 && (
                      <tr key={`grid-separator-${item.uuid}`} className="grid-separator">
                        <td colSpan={14} />
                      </tr>
                    )}
                    <tr key={`grid-item-${item.uuid}`} className="grid-content">
                      <td>{item.campaign.contract ? item.campaign.contract.name : ''}</td>
                      <td>{item.campaign.zone ? item.campaign.zone.sender : ''}</td>
                      <td>{item.campaign.name}</td>
                      <td>
                        {item.campaign.productOffers.map(po => (
                          <span key={po.CampaignProductOffer.uuid}>
                            {`${po.CampaignProductOffer.keyword}`}
                            <br />
                          </span>
                        ))}
                      </td>
                      <td>
                        {item.campaign.productOffers.map(po => (
                          <span key={po.uuid}>
                            {`${po.product.name} - ${po.name}`}
                            <br />
                          </span>
                        ))}
                      </td>
                      <td>
                        {disabled || modeVolumeWeekly ? (
                          parseInt(item.volume, 10).toLocaleString()
                        ) : (
                          <TableInput
                            type="number"
                            value={item.volume}
                            onChange={(e) => {
                              updateItemBonus({ ...item, volume: e.target.value }, false);
                            }}
                            onBlur={(e) => {
                              updateItemBonus({ ...item, volume: e.target.value }, true);
                            }}
                          />
                        )}
                      </td>
                      <td>
                        <div className="delete-button-div">
                          <ButtonDelete disabled={disabled} onClick={() => handleRemoveCampaignBonus(item)}>
                            <TrashIcon />
                          </ButtonDelete>
                        </div>
                      </td>
                    </tr>
                    {modeVolumeWeekly && (
                      <tr key={`grid-form-weekly-${item.uuid}`} className="grid-form">
                        <td colSpan={14}>

                          <BoxDailyVolumes>
                            <ReactTooltip
                              place="bottom"
                              effect="solid"
                            />
                            {/* <BoxDailyVolumesIte* separator>
                              <label>Volume Total</label>
                              <TableInput
                                disabled
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="Volume"
                                value={item.volume}
                                onChange={(e) => { }}
                              />
                          BoxDailyVolumesIte */}
                            <BoxDailyVolumesItem>
                              <label>
                                Dom
                                {!fieldConfigurations[0].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[0].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[0].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="DOM"
                                value={item.dailyDistributionBefore[0]}
                                onChange={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                SEG
                                {!fieldConfigurations[1].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[1].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[1].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="SEG"
                                value={item.dailyDistributionBefore[1]}
                                onChange={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                TER
                                {!fieldConfigurations[2].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[2].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[2].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="TER"
                                value={item.dailyDistributionBefore[2]}
                                onChange={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                QUA
                                {!fieldConfigurations[3].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[3].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[3].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="QUA"
                                value={item.dailyDistributionBefore[3]}
                                onChange={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                QUI
                                {!fieldConfigurations[4].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[4].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[4].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="QUI"
                                value={item.dailyDistributionBefore[4]}
                                onChange={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[5],
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                SEX
                                {!fieldConfigurations[5].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[5].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[5].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="SEX"
                                value={item.dailyDistributionBefore[5]}
                                onChange={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                      item.dailyDistributionBefore[6],
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                            <BoxDailyVolumesItem>
                              <label>
                                SAB
                                {!fieldConfigurations[6].enabled && (
                                <InfoWeek data-tip={fieldConfigurations[6].message}>
                                  <FaInfoCircle />
                                </InfoWeek>
                                )}
                              </label>
                              <TableInput
                                disabled={!fieldConfigurations[6].enabled || disabled || item.type === 'IGNORED'}
                                type="number"
                                style={{ marginRight: 0 }}
                                placeholder="SAB"
                                value={item.dailyDistributionBefore[6]}
                                onChange={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    ],
                                  }, false);
                                }}
                                onBlur={(e) => {
                                  updateItemBonus({
                                    ...item,
                                    dailyDistributionBefore: [
                                      item.dailyDistributionBefore[0],
                                      item.dailyDistributionBefore[1],
                                      item.dailyDistributionBefore[2],
                                      item.dailyDistributionBefore[3],
                                      item.dailyDistributionBefore[4],
                                      item.dailyDistributionBefore[5],
                                      parseInt(e.target.value) ? parseInt(e.target.value) : 0,
                                    ],
                                  }, true);
                                }}
                              />
                            </BoxDailyVolumesItem>
                          </BoxDailyVolumes>
                        </td>
                      </tr>
                    )}
                  </>
                ))}
              </tbody>
            </Table>
          </div>
          )}
        </ContentBlock>
      </StyledDiv>
    );
  }


  return (
    <Container>
      <ModalBox>
        <header>
          <div className="header-title">
            <ButtonGridPeriodIcon className="grid-period-icon" />
            <div className="header-title-with-subtitle">
              <h1>
                {`${zone.channel.name} (${zone.name})`}
              </h1>
              <span>{`Semana - ${periodSelected.label}`}</span>
            </div>
          </div>
          {disparosSatpush.show && (
            <div className="header-disparos">
              <h2>Disparos</h2>
              <span><strong>Quantidade total:</strong> {disparosSatpush.totalImpressions.toLocaleString()}   |   <strong>Quantidade restante:</strong> {disparosSatpush.remainingImpressions.toLocaleString()}</span>
            </div>
          )}
          <button type="button" onClick={() => onCloseModalChannel()} className="close-button">
            <img src={general.imageCampaign['icon-close-modal.png']} alt="close-icon" />
          </button>
        </header>
        <ModalContent>
          {renderAuction()}
          {renderProbe()}
          {renderFixedCpa()}
          {renderBonus()}
        </ModalContent>
      </ModalBox>
      <ModalDetailProduct />
      <ModalTeste />
      <ModalCpaFixo />
      <ModalBonificadas />
    </Container>
  );
}

const mapStateToProps = ({
  newGridPeriod: {
    periodSelected,
    showModalChannel,
    channelEdit: {
      zone, channel, config, fieldConfigurations, disparosSatpush,
    },
  },
}) => ({
  showModalChannel,
  periodSelected,
  zone,
  channel,
  configZone: config,
  fieldConfigurations,
  disparosSatpush,
});


const mapDispatchToProps = dispatch => bindActionCreators(
  {
    closeModalChannel: NewGridPeriodCreators.closeModalChannel,
    changeStatusAuction: NewGridPeriodCreators.changeStatusAuction,
    changeStatusProbe: NewGridPeriodCreators.changeStatusProbe,
    changeStatusFixedCpa: NewGridPeriodCreators.changeStatusFixedCpa,
    changeStatusBonus: NewGridPeriodCreators.changeStatusBonus,
    updateItemAuction: NewGridPeriodCreators.updateItemAuction,
    updateItemProbe: NewGridPeriodCreators.updateItemProbe,
    updateItemFixedCpa: NewGridPeriodCreators.updateItemFixedCpa,
    updateItemBonus: NewGridPeriodCreators.updateItemBonus,
    showDetailProductSetData: ProductActions.showDetailProductSetData,

    removeCampaignProbe: NewGridPeriodCreators.removeCampaignProbe,
    removeCampaignFixedCpa: NewGridPeriodCreators.removeCampaignFixedCpa,
    removeCampaignBonus: NewGridPeriodCreators.removeCampaignBonus,

    gridPeriodSetFilterModalCampaign: NewGridPeriodCreators.gridPeriodSetFilterModalCampaign,
    gridPeriodCampaignByFilterRequest: NewGridPeriodCreators.gridPeriodCampaignByFilterRequest,
    gridPeriodChangeModalStatus: NewGridPeriodCreators.gridPeriodChangeModalStatus,
    gridPeriodSetCampaignToAdd: NewGridPeriodCreators.gridPeriodSetCampaignToAdd,

    confirmModal: ConfirmModalActions.showConfirmModal,
  },
  dispatch,
);

ModalChannel.defaultProps = {
  zone: null,
  channel: null,
  configZone: null,
  fieldConfigurations: [],
};

ModalChannel.propTypes = {
  showModalChannel: PropTypes.bool.isRequired,
  periodSelected: PropTypes.shape().isRequired,
  zone: PropTypes.shape(),
  channel: PropTypes.shape(),
  configZone: PropTypes.shape(),
  fieldConfigurations: PropTypes.arrayOf(PropTypes.shape()),
  closeModalChannel: PropTypes.func.isRequired,
  changeStatusAuction: PropTypes.func.isRequired,
  changeStatusProbe: PropTypes.func.isRequired,
  changeStatusFixedCpa: PropTypes.func.isRequired,
  changeStatusBonus: PropTypes.func.isRequired,
  showDetailProductSetData: PropTypes.func.isRequired,
  updateItemAuction: PropTypes.func.isRequired,
  updateItemProbe: PropTypes.func.isRequired,
  updateItemFixedCpa: PropTypes.func.isRequired,
  updateItemBonus: PropTypes.func.isRequired,

  removeCampaignProbe: PropTypes.func.isRequired,
  removeCampaignFixedCpa: PropTypes.func.isRequired,
  removeCampaignBonus: PropTypes.func.isRequired,

  gridPeriodSetFilterModalCampaign: PropTypes.func.isRequired,
  gridPeriodCampaignByFilterRequest: PropTypes.func.isRequired,
  gridPeriodChangeModalStatus: PropTypes.func.isRequired,
  gridPeriodSetCampaignToAdd: PropTypes.func.isRequired,

  confirmModal: PropTypes.func.isRequired,

  disparosSatpush: PropTypes.shape().isRequired,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalChannel);
