import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Modal from '~/Components/Modal';

import {
  Row,
  ModalContent,
  ProgressBar,
  Progress,
  ProgressLabel,
} from './styles';

class ModalProgress extends Component {
  intervalProgress = null;

  componentDidMount() {}

  render() {
    const { progress } = this.props;
    return (
      <Modal width={500} minHeight={100}>
        <Row>
          <h1>Enviando arquivo</h1>
        </Row>
        <ModalContent>
          <ProgressBar>
            <Progress progress={progress} />
          </ProgressBar>
          <ProgressLabel>
            {`${progress}%`}
          </ProgressLabel>
        </ModalContent>
      </Modal>
    );
  }
}

ModalProgress.propTypes = {
  progress: PropTypes.number.isRequired,
};

export default ModalProgress;
