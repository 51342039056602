/* eslint-disable react/no-array-index-key */
import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import {
  FaFont, FaQuestionCircle, FaRegCalendarAlt, FaRegCheckSquare, FaRegDotCircle,
} from 'react-icons/fa';
import uuid4 from 'uuid4';
import { parse, isValid } from 'date-fns';

import ReactTooltip from 'react-tooltip';
import Modal from '~/Components/Modal';
import Button from '~/Components/Button';
import PreviewResearch from '../../../../../../../../../../Components/PreviewResearch';

import Item from './components/Item';

import {
  Row,
  Questions,
  ModalHeader,
  ModalWrapBody,
  ModalPreview,
  ModalBody,
  ModalFooter,
  AddItemsWrap,
  GroupButtons,
  ButtonInsert,
} from './styles';

export function ModalSurveyEdit({
  modalOpen, disabledFields, onChangeForm,
  content, formHasError, onCloseModal,
  onCancelar, onConfirmar,
}) {
  const refModalBody = useRef(null);

  function handleScrollToBottom() {
    refModalBody.current.scrollTop = refModalBody.current.scrollHeight - refModalBody.current.clientHeight;
  }

  function validateDateRequired(value) {
    if (!value || !isValid(parse(value, 'dd/MM/yyyy', new Date()))) {
      return false;
    }
    return true;
  }

  function formIsValid() {
    let formValid = true;
    content.questions.forEach((item) => {
      if (!item.title) formValid = false;
      if (item.type === 'check') {
        const optionNull = item.options.filter(option => !option.description);
        if (optionNull.length > 0) formValid = false;
      }

      if (item.type === 'radio') {
        const optionNull = item.options.filter(option => !option.description);
        if (optionNull.length > 0) formValid = false;
      }

      if (item.type === 'date') {
        if (item.dateMinType === 'fixed') {
          if (!validateDateRequired(item.dateMin)) {
            formValid = false;
          }
        }
        if (item.dateMaxType === 'fixed') {
          if (!validateDateRequired(item.dateMax)) {
            formValid = false;
          }
        }
      }
    });
    return formValid;
  }

  function handleNewQuestion(item) {
    const questionList = [...content.questions];
    questionList.push(item);

    onChangeForm({
      ...content,
      questions: questionList,
    });
    setTimeout(handleScrollToBottom, 300);
  }

  function renderQuestions() {
    const questionList = [...content.questions];

    return (
      <>
        <Row>
          <Questions>
            {questionList.map((question, index) => (
              <Item
                key={index}
                index={index + 1}
                question={question}
                hasError={formHasError}
                disableUp={index === 0}
                disableDown={index === questionList.length - 1}
                changePositionUp={() => {
                  const item1 = questionList[index - 1];
                  const item2 = questionList[index];
                  onChangeForm({
                    ...content,
                    questions: questionList.map((item, itemIndex) => {
                      if (index === itemIndex) {
                        return item1;
                      }
                      if (index - 1 === itemIndex) {
                        return item2;
                      }
                      return item;
                    }),
                  });
                }}
                changePositionDown={() => {
                  const item1 = questionList[index + 1];
                  const item2 = questionList[index];
                  onChangeForm({
                    ...content,
                    questions: questionList.map((item, itemIndex) => {
                      if (index === itemIndex) {
                        return item1;
                      }
                      if (index + 1 === itemIndex) {
                        return item2;
                      }
                      return item;
                    }),
                  });
                }}
                copyItem={() => {
                  onChangeForm({
                    ...content,
                    questions: [
                      ...questionList,
                      {
                        ...questionList[index],
                        uuid: uuid4(),
                      },
                    ],
                  });
                  setTimeout(handleScrollToBottom, 300);
                }}
                onChange={(questionChange) => {
                  onChangeForm({
                    ...content,
                    questions: questionList.map(
                      (item, i) => {
                        if (index === i) {
                          return questionChange;
                        }
                        return item;
                      },
                    ),
                  });
                }}
                onRemove={() => {
                  onChangeForm({
                    ...content,
                    questions: questionList.filter(
                      (item, i) => !(index === i),
                    ),
                  });
                }}
                disabledFields={disabledFields}
              />
            ))}
          </Questions>
        </Row>
      </>
    );
  }


  function renderGroupButtons() {
    return (
      <GroupButtons>
        <ButtonInsert
          data-tip="Inserir campo de CheckBox (Seleção de múltiplos itens)"
          onClick={() => {
            handleNewQuestion({
              uuid: uuid4(),
              title: '',
              type: 'check',
              required: false,
              rightAnswerRequired: false,
              options: [
                {
                  uuid: uuid4(),
                  description: '',
                  isAnswer: false,
                },
              ],
            });
          }}
        >
          <FaRegCheckSquare />
        </ButtonInsert>
        <ButtonInsert
          data-tip="Inserir campo de Rádio (Seleção de um único item)"
          onClick={() => {
            handleNewQuestion({
              uuid: uuid4(),
              title: '',
              type: 'radio',
              required: false,
              rightAnswerRequired: false,
              options: [
                {
                  uuid: uuid4(),
                  description: '',
                  isAnswer: false,
                },
              ],
            });
          }}
        >
          <FaRegDotCircle />
        </ButtonInsert>
        <ButtonInsert
          data-tip="Inserir campo de texto"
          onClick={() => {
            handleNewQuestion({
              uuid: uuid4(),
              title: '',
              type: 'text',
              required: false,
              fieldType: 'text',
              validateMax: false,
              validateMin: false,
              max: 0,
              min: 0,
            });
          }}
        >
          <FaFont />
        </ButtonInsert>
        <ButtonInsert
          data-tip="Inserir campo de data"
          onClick={() => {
            handleNewQuestion({
              uuid: uuid4(),
              title: '',
              type: 'date',
              required: false,
              acceptHour: false,
              dateFormat: '',
              dateMax: '',
              dateMaxType: '',
              dateMin: '',
              dateMinType: '',
            });
          }}
        >
          <FaRegCalendarAlt />
        </ButtonInsert>
        <ReactTooltip place="bottom" effect="solid" />
      </GroupButtons>
    );
  }

  return modalOpen ? (
    <Modal width={1200} height={760} padding={0} scroll={false}>
      <ModalHeader>
        <h1>{disabledFields ? 'Visualizar pesquisa' : 'Editar pesquisa'}</h1>
        {!disabledFields && (
          <AddItemsWrap>
            <span>
              Inserir item:
              <FaQuestionCircle />
            </span>
            {renderGroupButtons()}
          </AddItemsWrap>
        )}
      </ModalHeader>
      <ModalWrapBody>
        <ModalPreview>
          <PreviewResearch
            data={content}
            config={null}
          />
        </ModalPreview>
        <ModalBody ref={refModalBody}>
          {renderQuestions()}
        </ModalBody>
      </ModalWrapBody>
      <ModalFooter>
        {disabledFields ? (
          <Button
            type="button"
            bordered
            title="Fechar"
            onClick={onCloseModal}
            style={{ margin: 0 }}
          />
        ) : (
          <>
            <Button
              type="button"
              bordered
              title="Cancelar"
              onClick={onCancelar}
              style={{ margin: 0 }}
            />
            <Button
              type="button"
              title="Confirmar"
              disabled={formHasError && !formIsValid()}
              onClick={() => {
                onConfirmar(formIsValid());
              }}
            />
          </>
        )}
      </ModalFooter>
    </Modal>
  ) : null;
}

ModalSurveyEdit.propTypes = {
  modalOpen: PropTypes.bool.isRequired,
  disabledFields: PropTypes.bool.isRequired,
  onChangeForm: PropTypes.func.isRequired,
  content: PropTypes.shape().isRequired,
  formHasError: PropTypes.bool.isRequired,
  onCloseModal: PropTypes.func.isRequired,
  onCancelar: PropTypes.func.isRequired,
  onConfirmar: PropTypes.func.isRequired,
};
