import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { FaTimes } from 'react-icons/fa';

import Pagination from '~/Components/Pagination';
import { Creators as GridPeriodActions } from '~/redux/ducks/gridPeriod';

import Button from '~/Components/Button';
import Modal from '~/Components/Modal';

import {
  ModalWrap,
  Row,
  RowHeader,
  RowContent,
  RowFooter,
  RowPagination,
  ButtonCloseModal,
  Table,
  Select,
  Header,
  HeaderForm,
  HeaderFormInput,
  HeaderFormGroup,
  InputFilter,
  LinkButton,
  ButtonGroup,
  Checkbox,
} from './styles';

class ModalTeste extends Component {
  componentDidMount() {}

  renderHeader = () => {
    const {
      gridPeriodCampaignByFilterRequest,
      gridPeriodSetFilterModalCampaign,
      campaignListFilter,
      productList,
      contractList,
    } = this.props;
    return (
      <Header>
        <HeaderForm>
          <HeaderFormInput>
            <label>Contrato:</label>
            <Select
              placeholder="Selecione o Contrato"
              value={campaignListFilter.contract || ''}
              onChange={(e) => {
                gridPeriodSetFilterModalCampaign({
                  contract: e,
                  product: null,
                });
              }}
              options={contractList.map(item => ({
                value: item.uuid,
                label: item.name,
              }))}
              isClearable
            />
          </HeaderFormInput>
          <HeaderFormInput>
            <label>Produto:</label>
            <Select
              placeholder="Selecione o Produto"
              value={campaignListFilter.product || ''}
              onChange={(e) => {
                gridPeriodSetFilterModalCampaign({
                  product: e,
                });
              }}
              options={
              productList.filter((item) => {
                if (!campaignListFilter.contract) return true;
                return item.contract.uuid === campaignListFilter.contract.value;
              }).map(item => ({
                value: item.uuid,
                label: item.name,
              }))
            }
              isClearable
            />
          </HeaderFormInput>
          <HeaderFormInput>
            <label>CPA:</label>
            <HeaderFormGroup>
              <InputFilter
                type="number"
                placeholder="De"
                value={campaignListFilter.minCPA || ''}
                onChange={(e) => {
                  gridPeriodSetFilterModalCampaign({
                    minCPA: e.target.value,
                  });
                }}
              />
              <span>até</span>
              <InputFilter
                type="number"
                placeholder="Até"
                value={campaignListFilter.maxCPA || ''}
                onChange={(e) => {
                  gridPeriodSetFilterModalCampaign({
                    maxCPA: e.target.value,
                  });
                }}
              />
            </HeaderFormGroup>
          </HeaderFormInput>
          <HeaderFormInput>
            <ButtonGroup>
              <LinkButton
                onClick={() => {
                  gridPeriodSetFilterModalCampaign({
                    page: 0,
                  });
                  gridPeriodCampaignByFilterRequest();
                }}
              >
            Buscar
              </LinkButton>
              <Button
                icon="FaTimes"
                noLabel
                onClick={() => {
                  gridPeriodSetFilterModalCampaign({
                    maxCPA: null,
                    minCPA: null,
                    contract: null,
                    product: null,
                  });
                  gridPeriodCampaignByFilterRequest();
                }}
              />
            </ButtonGroup>
          </HeaderFormInput>
        </HeaderForm>
      </Header>
    );
  };

  onChangePage = (page) => {
    const {
      campaignList,
      gridPeriodSetFilterModalCampaign,
      gridPeriodCampaignByFilterRequest,
    } = this.props;
    if (parseInt(campaignList.page, 10) - 1 !== page) {
      gridPeriodSetFilterModalCampaign({
        page,
      });
      gridPeriodCampaignByFilterRequest();
    }
  };

  handleChangeItemsSelected = (campaignUuid, isSelected) => {
    const { gridPeriodSetCampaignToAdd, campaignToAdd } = this.props;
    if (isSelected) {
      gridPeriodSetCampaignToAdd([
        ...campaignToAdd,
        campaignUuid,
      ]);
    } else {
      gridPeriodSetCampaignToAdd(campaignToAdd.filter(item => item !== campaignUuid));
    }
  };

  renderTable = () => {
    const {
      campaignList,
      campaignToAdd,
      campaignListFilter,
      gridPeriod,
    } = this.props;
    const tabSelected = gridPeriod.grids.find(
      item => item.campaignType.name === campaignListFilter.typeName,
    );
    return campaignList && (
      <>
        <Table>
          <thead>
            <tr>
              <th width={166}>CONTRATO</th>
              <th width={166}>NOME</th>
              <th width={332}>PRODUTO - OFERTA</th>
              <th width={166} align="center">VOLUME</th>
              <th width={166} align="center">SELECIONAR</th>
            </tr>
          </thead>
          <tbody>
            {campaignList.content.length <= 0 ? (
              <tr>
                <td width={1160} colSpan="7" align="center">
                  Nenhuma campanha encontrada
                </td>
              </tr>
            ) : campaignList.content.map(campaign => (
              <tr key={campaign.uuid}>
                <td width={166}>
                  <span>{campaign.contract.name}</span>
                </td>
                <td width={166}>
                  <span>{campaign.name}</span>
                </td>
                <td width={332}>
                  {campaign.productOffers.map(item => (
                    <span key={item.uuid}>
                      {`${item.product.name} - ${item.name}`}
                      <br />
                    </span>
                  ))}
                </td>
                <td width={166} align="center">
                  <span className="nowrap">{campaign.volume.toLocaleString()}</span>
                </td>
                <td width={166} align="center">
                  {!tabSelected.gridItems.find(
                    gridItem => gridItem.campaign.uuid === campaign.uuid,
                  ) && (
                    <Checkbox
                      label=""
                      value={!!campaignToAdd.find(item => item === campaign.uuid)}
                      onChange={(value) => {
                        this.handleChangeItemsSelected(campaign.uuid, value);
                      }}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <RowPagination>
          <Pagination
            page={parseInt(campaignList.page, 10)}
            pageCount={Math.ceil(campaignList.totalElements / campaignList.pageSize)}
            pageChange={(e) => {
              if (campaignList.page !== e.selected) {
                this.onChangePage(e.selected);
              }
            }}
          />
        </RowPagination>
      </>
    );
  };

  render() {
    const {
      modal,
      gridPeriodChangeModalStatus,
      gridPeriodCampaignByFilterRequestSuccess,
      gridPeriodResetFilterModalCampaign,
      gridPeriodInsertCampaignsSelected,
      campaignToAdd,
    } = this.props;
    return modal.modalTeste && (
      <Modal width={1200} height={800} padding={0} scroll={false}>
        <ModalWrap>
          <RowHeader>
            <Row>
              <h1>Buscar Campanhas - Teste</h1>
              <ButtonCloseModal
                type="button"
                onClick={() => {
                  gridPeriodChangeModalStatus({
                    modalTeste: false,
                  });
                  gridPeriodCampaignByFilterRequestSuccess(null);
                  gridPeriodResetFilterModalCampaign(null);
                }}
              >
                <FaTimes size={30} />
              </ButtonCloseModal>
            </Row>
            <Row>
              {this.renderHeader()}
            </Row>
          </RowHeader>
          <RowContent>
            {this.renderTable()}
          </RowContent>
          <RowFooter>
            <Button
              title="SELECIONAR"
              onClick={() => {
                gridPeriodInsertCampaignsSelected();
              }}
              disabled={campaignToAdd.length <= 0}
            />
          </RowFooter>
        </ModalWrap>
      </Modal>
    );
  }
}

ModalTeste.defaultProps = {
  campaignList: null,
};

ModalTeste.propTypes = {
  gridPeriod: PropTypes.shape().isRequired,
  campaignListFilter: PropTypes.shape().isRequired,
  campaignToAdd: PropTypes.arrayOf(PropTypes.string).isRequired,
  modal: PropTypes.shape().isRequired,
  campaignList: PropTypes.shape(),
  gridPeriodChangeModalStatus: PropTypes.func.isRequired,
  gridPeriodCampaignByFilterRequestSuccess: PropTypes.func.isRequired,
  gridPeriodSetFilterModalCampaign: PropTypes.func.isRequired,
  gridPeriodCampaignByFilterRequest: PropTypes.func.isRequired,
  gridPeriodSetCampaignToAdd: PropTypes.func.isRequired,
  gridPeriodInsertCampaignsSelected: PropTypes.func.isRequired,
  productList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  contractList: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  gridPeriodResetFilterModalCampaign: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modal: state.gridPeriod.modal,
  campaignList: state.gridPeriod.campaignList,
  campaignToAdd: state.gridPeriod.campaignToAdd,
  gridPeriod: state.gridPeriod.gridPeriod,
  campaignListFilter: state.gridPeriod.campaignListFilter,
  productList: state.gridPeriod.productList,
  contractList: state.gridPeriod.contractList,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    gridPeriodChangeModalStatus: GridPeriodActions.gridPeriodChangeModalStatus,
    gridPeriodCampaignByFilterRequestSuccess:
      GridPeriodActions.gridPeriodCampaignByFilterRequestSuccess,
    gridPeriodSetFilterModalCampaign: GridPeriodActions.gridPeriodSetFilterModalCampaign,
    gridPeriodCampaignByFilterRequest: GridPeriodActions.gridPeriodCampaignByFilterRequest,
    gridPeriodSetCampaignToAdd: GridPeriodActions.gridPeriodSetCampaignToAdd,
    gridPeriodInsertCampaignsSelected: GridPeriodActions.gridPeriodInsertCampaignsSelected,
    gridPeriodResetFilterModalCampaign: GridPeriodActions.gridPeriodResetFilterModalCampaign,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalTeste);
