import { call, put } from 'redux-saga/effects';

import EventsService from '~/services/EventsService';
import { Creators as EventActions } from '~/redux/ducks/event';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadEventsList() {
  yield put(startRequest());
  try {
    const eventsList = yield call(EventsService.list);
    yield put(EventActions.loadEventsListSuccess(eventsList));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os eventos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}
