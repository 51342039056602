/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import PropTypes from 'prop-types';
import {
  FaChevronUp,
  FaChevronDown,
} from 'react-icons/fa';

import { Container } from './styles';

function Accordeon({
  children, isActive, onAccordion, title, hasError,
}) {
  return (
    <Container hasError={hasError}>
      <div className="accordeon-title">
        <h2 onClick={onAccordion}>{title}</h2>
        <button type="button" onClick={onAccordion}>
          {!!isActive && <FaChevronUp />}
          {!isActive && <FaChevronDown />}
        </button>
      </div>
      {!!isActive && (
        <div className="accordeon-content">
          {children}
        </div>
      )}
    </Container>
  );
}

Accordeon.defaultProps = {
  isActive: false,
  hasError: false,
};

Accordeon.propTypes = {
  children: PropTypes.shape().isRequired,
  isActive: PropTypes.bool,
  hasError: PropTypes.bool,
  onAccordion: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default Accordeon;
