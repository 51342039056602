import styled from 'styled-components';
import { RowStyled } from '~/Components/Styled';

export const Row = styled(RowStyled)`
  justify-content: space-between;
`;

export const Container = styled.div`
  position: absolute;
  width: 80.5%;
  min-height: 60px;
  top: 75px;
  padding: 15px 20px;

  span {
    color: ${props => props.theme.colors.regular};
  }
  .dot-now {
    margin-left: 15px;
    font-size: 14px;
  }
  .dot {
    font-size: 28px;
    line-height: 0.1;
    position: absolute;
    margin-top: 10px;
    margin-left: -7px;
  }
  background-color: #f9f8f8;
  label {
    font-size: 16px;
  }
  p {
    line-height: 1.3;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
  .sms-link {
    color: #1A0DAB;
  }
`;

export const ControlPreview = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  top: -7px;
  right: 0;
  left: 0;
  align-items: center;
`;

export const ControlButton = styled.button.attrs({
  type: 'button',
})`
  background-color: transparent;
  color: ${props => (props.disabled ? `${props.theme.colors.primaryHex}50` : props.theme.colors.primary)};
  font-size: 25px;
  display: flex;
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
`;

export const ControlTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  color: ${props => props.theme.colors.primary};
  font-size: 20px;
  font-weight: bold;
`;

export const IconBox = styled.div`
  display: flex;
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  img {
    min-width: 60px;
    max-width: 60px;
    min-height: 60px;
    max-height: 60px;
    object-fit: contain;
    background-color: ${props => props.theme.colors.white};
  }
`;

export const ContentPreview = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 80.5%;
  top: 75px;
  min-height: 60px;
  background-color: ${props => props.theme.colors.white};
  > div {
    justify-content: flex-start;
  }

  .external-link {
    display: flex;
    text-align: center;
    color: ${props => props.theme.colors.primary};
    align-self: center;
    margin-top: 20px;
    margin-bottom: 20px;
    font-size: 15px;
  }

  .success-title {
    font-size: 16px;
    font-weight: normal;
    color: ${props => props.theme.colors.dark};
    padding-left: 10px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
    display: flex;
    flex: 1;
  }

  .success-subtitle {
    width: 100%;
    margin: 10px;
    font-size: 16px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    hyphens: auto;
  }
`;

export const MidiaVideoPreview = styled.video`
  width: 100%;
  max-height: 565px;
  outline: none;
`;

export const MidiaImagePreview = styled.img`
  width: 100%;
`;

export const PreviewVastType = styled.div`
  background-color: ${props => props.theme.colors.white}80;
  position: absolute;
  top: 75px;
  bottom: 90px;
  left: 35px;
  right: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  width: 75%;
  height: 60%;
  border: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.white};
  border-radius: 5px;
`;

export const BoxTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border-bottom: 1px solid ${props => props.theme.colors.primary};
  background-color: ${props => props.theme.colors.primaryHex}30;
  height: 50px;
  font-size: 16px;
  padding: 10px;
`;

export const BoxContent = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
`;

export const PreviewForm = styled.div`
  position: absolute;
  top: 74px;
  left: 33px;
  right: 33px;
  bottom: 90px;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
`;
