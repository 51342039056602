import axios from 'axios';

const getClientsJson = async () => {
  try {
    const { data } = await axios.get('https://auth0.apolowifi.com/clients.json');
    return data;
  } catch (error) {
    if (!error.response) {
      throw error;
    }
    throw error.response.data ? error.response.data : error.response;
  }
};

export default {
  getClientsJson,
};
