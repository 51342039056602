/* eslint-disable no-plusplus */
import { call, put, select } from 'redux-saga/effects';
import moment from 'moment';
import lodash from 'lodash';
import FileSaver from 'file-saver';

import FinancialService from '~/services/FinancialService';

import { Creators as FinancialActions } from '~/redux/ducks/financial';
import { startRequest, requestSuccess, requestError } from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

const monthList = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export function* financialSetup() {
  const filter = yield select(state => state.financial.filter);
  yield put(startRequest());
  try {
    const documents = yield call(FinancialService.getDocuments, filter);
    let providers = yield call(FinancialService.getProviders);
    const partners = yield call(FinancialService.getPartners);

    providers = providers.filter(provider => provider.uuid !== '2f0b83ec-587b-4c6c-8c89-c4c152d0b772');
    providers = lodash.orderBy(providers, 'name', 'asc');

    yield put(FinancialActions.financialDocumentsRequestSuccess({
      ...documents,
      content: documents.content.map(content => ({
        ...content,
        key: `${content.partnerUuid}-${content.year}-${content.month}`,
        monthLabel: monthList[content.month - 1],
        documents: providers.map((provider) => {
          const findProvider = content.documents.find(
            document => document.provider.uuid === provider.uuid,
          );
          if (findProvider) return findProvider;
          return {
            amount: null,
            documentStatus: null,
            dueDate: null,
            provider,
            uuid: '',
          };
        }),
      })),
    }));
    yield put(FinancialActions.financialProvidersRequestSuccess(providers));
    yield put(FinancialActions.financialPartnersRequestSuccess(partners));

    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os documentos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* financialArchivesRequest() {
  const filter = yield select(state => state.financial.filterArchives);
  yield put(startRequest());
  try {
    const archives = yield call(FinancialService.getArchives, filter);
    yield put(FinancialActions.financialArchivesRequestSuccess(archives));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os arquivos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* financialInvoiceSave() {
  yield put(startRequest());
  const modalInvoiceData = yield select(state => state.financial.modalInvoiceData);
  try {
    yield call(FinancialService.saveInvoice, {
      uuid: modalInvoiceData.uuid,
      dueDate: moment(modalInvoiceData.dueDate).format('YYYY-MM-DD'),
      amount: modalInvoiceData.amount,
    });
    yield call(FinancialService.saveInvoiceFiles, {
      uuid: modalInvoiceData.uuid,
      files: modalInvoiceData.files,
    });
    yield put(requestSuccess());
    yield put(FinancialActions.setModalInvoiceData(null));
    yield put(FinancialActions.financialSetup());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao enviar os arquivos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* financialArchiveDownload({ file }) {
  yield put(startRequest());
  try {
    const response = yield call(FinancialService.getArchive, file.uuid);
    // Download file
    FileSaver.saveAs(response, file.name);

    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os arquivos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* financialDocumentArchivesDelete({ uuid }) {
  yield put(startRequest());
  try {
    yield call(FinancialService.deleteDocumentArchives, uuid);
    yield put(requestSuccess());
    yield put(FinancialActions.financialArchivesRequest());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao excluir os arquivos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* financialDocumentArchiveDelete({ uuid }) {
  yield put(startRequest());
  try {
    yield call(FinancialService.deleteDocumentArchive, uuid);
    yield put(requestSuccess());
    yield put(FinancialActions.financialArchivesRequest());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao excluir o arquivo. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* financialModalReceiptDataRequest({ uuid, provider }) {
  yield put(startRequest());
  try {
    const document = yield call(FinancialService.getDocument, uuid);
    // const files = yield call(FinancialService.getArchivesFromDocument, uuid);
    const archives = yield call(FinancialService.getArchives, {
      year: document.year,
      month: document.month,
      partnerUuid: document.partnerUuid,
    });
    const findDocument = archives.find(item => item.document && item.document.uuid === document.uuid);
    const files = findDocument.archives.filter(item => !item.receipt).map(item => item.invoice);

    yield put(requestSuccess());
    yield put(FinancialActions.setModalInvoiceData({
      provider,
      ...document,
      files,
    }));
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os arquivos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* financialInvoiceReceiptSave() {
  const modalInvoiceData = yield select(state => state.financial.modalInvoiceData);
  yield put(startRequest());
  try {
    const files = modalInvoiceData.files.filter(item => !!item.receipt);
    for (let i = 0; i < files.length; i++) {
      yield call(FinancialService.saveReceiptFiles, {
        archiveUuid: files[i].uuid,
        documentUuid: modalInvoiceData.uuid,
        file: files[i].receipt,
      });
    }
    yield put(requestSuccess());
    yield put(FinancialActions.setModalInvoiceData(null));
    yield put(FinancialActions.financialSetup());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao enviar os recibos. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}
