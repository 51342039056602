import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import moment from 'moment';
import FileSaver from 'file-saver';
import { jsonToCSV } from 'react-papaparse';
import {
  Button, RadialChart, LineChart,
} from '../../Components';

import Table from './Components/Table';
import { general } from '~/Styles';
import {
  Row,
  Column,
  CheckButton,
  MediaImagemPreview,
  Select,
} from '../../styles';
import { getFormattedValueByType } from '~/Utils';
import DataEmpty from '../../Components/DataEmpty';
import CreativeCustomMidiaType from '~/Utils/CreativeCustomMidiaType';

const MODE_INDIVIDUAL = 'I';
const MODE_FUNIL = 'F';

const MODE_DAILY_EVENTOS = 'events';
const MODE_DAILY_ACUMULADO = 'accumuled';

const downloadCsv = (data) => {
  const dataConvert = [
    ...data.map(item => [
      item.date,
      getFormattedValueByType('decimal', item.impressions),
      getFormattedValueByType('decimal', item.start),
      getFormattedValueByType('decimal', item.firstQuartile),
      getFormattedValueByType('decimal', item.midpoint),
      getFormattedValueByType('decimal', item.thirdQuartile),
      getFormattedValueByType('decimal', item.complete),
      `${getFormattedValueByType('decimal', item.vtrPercent)}%`,
    ]),
  ];
  const fields = ['Data', 'Impressão', 'Play', '1º Quartil', '2º Quartil', '3º Quartil', 'Completo', 'VTR'];
  const csvContent = new Blob(['\ufeff', jsonToCSV({ fields, data: dataConvert })], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(csvContent, 'dados.csv');
};

function MediaVideo({ media }) {
  const [modeKPI, setModeKPI] = useState(MODE_INDIVIDUAL);

  const [propNameDataDaily, setPropNameDataDaily] = useState('clicks');
  const [modeDataDaily, setModeDataDaily] = useState(MODE_DAILY_EVENTOS);
  const [dataDaily, setDataDaily] = useState(null);

  const [dataTable, setDataTable] = useState([]);

  const {
    mediaKPI, type: typeMedia, title, thumb, thumbnail, dailyMediaKPI, modeVideo,
  } = media;
  let labelTypeMedia = '';
  switch (typeMedia) {
    case CreativeCustomMidiaType.VAST:
      if (modeVideo) {
        labelTypeMedia = 'Vídeo';
      } else {
        labelTypeMedia = 'Vast';
      }
      break;
    case CreativeCustomMidiaType.VPAID:
      if (modeVideo) {
        labelTypeMedia = 'Vídeo';
      } else {
        labelTypeMedia = 'Vpaid';
      }
      break;
    case CreativeCustomMidiaType.VIDEO:
      labelTypeMedia = 'Vídeo';
      break;
    default:
  }

  const titleData = title || `Mídia ${labelTypeMedia}`;
  const thumbData = thumb || thumbnail || null;

  function getNameByProps(propName) {
    switch (propName) {
      case 'clicks':
        return 'Cliques';
      case 'start':
        return 'Play';
      case 'firstQuartile':
        return '1º Quartil';
      case 'midpoint':
        return '2º Quartil';
      case 'thirdQuartile':
        return '3º Quartil';
      case 'complete':
        return 'Completo';
      default:
        return '';
    }
  }

  useEffect(() => {
    function makeDataChartDaily(data) {
      const dataDailyUpdate = {
        labels: [],
        values: {
          events: [],
          accumuled: [],
        },
      };
      setDataDaily(dataDailyUpdate);

      data.forEach((d) => {
        const value = d[propNameDataDaily];

        dataDailyUpdate.labels.push(moment(d.date, 'YYYY-MM-DD').format('DD/MM'));

        dataDailyUpdate.values.events.push(value);

        const lastAccumuled = dataDailyUpdate.values.accumuled[dataDailyUpdate.values.accumuled.length - 1] || 0;
        dataDailyUpdate.values.accumuled.push(lastAccumuled + value);
      });
      setDataDaily(dataDailyUpdate);
    }
    makeDataChartDaily(dailyMediaKPI);
  }, [dailyMediaKPI, propNameDataDaily]);

  useEffect(() => {
    function makeDataTable(data) {
      setDataTable(data.map(d => ({
        ...d,
        date: moment(d.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      })));
    }
    makeDataTable(dailyMediaKPI);
  }, [dailyMediaKPI]);

  return (
    <>
      <div className="media-video">
        <div className="media-video-grid">

          <MediaImagemPreview className="media-video-preview" image={thumbData}>
            {!thumbData && <span className="preview-no-content">Sem preview disponível</span>}
            <Row className="title-action">
              <span className="bigger">{titleData}</span>
              {/* <ButtonExpand onClick={() => { }}>
                <img src={general.imageCampaign['icon-play-white.png']} alt="Expandir Visualização" />
              </ButtonExpand> */}
            </Row>
          </MediaImagemPreview>

          <div className="box media-video-dados">
            <Row className="header">
              <Row>
                <h2>Dados</h2>
                {/* <Button onClick={() => { }} className="btn-anchor">
                  <img src={general.imageCampaign['icon-anchor-link.png']} alt="" />
                </Button> */}
              </Row>

              <Button
                disabled={!(dataTable && dataTable.length > 0)}
                tooltip={!(dataTable && dataTable.length > 0) ? 'Não existe arquivo para download no momento' : null}
                title="Download Dados"
                onClick={() => downloadCsv(dataTable)}
              >
                <img src={general.imageCampaign['icon-download-white.png']} alt="" />
              </Button>
            </Row>

            <Table data={dataTable} />
          </div>

          <div className="box media-video-eventos">
            <Row className="space">
              <img src={general.imageCampaign['icon-money.png']} alt="" />

              <Select value={propNameDataDaily} onChange={e => setPropNameDataDaily(e.target.value)}>
                <option value="clicks">{getNameByProps('clicks')}</option>
                <option value="start">{getNameByProps('start')}</option>
                <option value="firstQuartile">{getNameByProps('firstQuartile')}</option>
                <option value="midpoint">{getNameByProps('midpoint')}</option>
                <option value="thirdQuartile">{getNameByProps('thirdQuartile')}</option>
                <option value="complete">{getNameByProps('complete')}</option>
              </Select>

              <CheckButton onClick={() => setModeDataDaily(MODE_DAILY_EVENTOS)} checked={modeDataDaily === MODE_DAILY_EVENTOS}>
                <span>Eventos</span>
              </CheckButton>
              <CheckButton onClick={() => setModeDataDaily(MODE_DAILY_ACUMULADO)} checked={modeDataDaily === MODE_DAILY_ACUMULADO}>
                <span>Acumulado</span>
              </CheckButton>

            </Row>
            <Row className="chart-compare">
              {dataDaily && dataDaily.labels && dataDaily.labels.length > 1 ? (
                <Column className="chart-line">
                  <LineChart
                    height={150}
                    mode="area"
                    values={[
                      {
                        name: getNameByProps(propNameDataDaily),
                        data: dataDaily.values[modeDataDaily],
                      },
                    ]}

                    labels={dataDaily.labels}
                  />
                </Column>
              ) : (
                <DataEmpty />
              )}
            </Row>
          </div>
          <div className="box media-video-reproducao">
            <Row className="header space">
              <Row>
                <img src={general.imageCampaign['icon-play.png']} alt="" />
                <h2>Reprodução</h2>

              </Row>
              <Row>
                <CheckButton onClick={() => setModeKPI(MODE_INDIVIDUAL)} checked={modeKPI === MODE_INDIVIDUAL}><span>Individual</span></CheckButton>
                <CheckButton onClick={() => setModeKPI(MODE_FUNIL)} checked={modeKPI === MODE_FUNIL}><span>Funil</span></CheckButton>
              </Row>
            </Row>

            <Row className="reproducao-data">
              <Column className="box iteraction">
                <img src={general.imageCampaign['icon-iteraction.png']} alt="Impressões" />
                <label>Impressões</label>
                <span className="bigger primary">{getFormattedValueByType('decimal', mediaKPI.impressions)}</span>
              </Column>
              {modeKPI === MODE_INDIVIDUAL ? (
                <>
                  <Column className="iteraction-chart">
                    <RadialChart
                      size={160}
                      values={[mediaKPI.start.percent]}
                      showDataLabels
                      padding={{
                        top: 0,
                        bottom: -10,
                      }}
                      textSize="18pt"
                    />
                    <label>Play</label>
                    <span className="bigger primary">{getFormattedValueByType('decimal', mediaKPI.start.value)}</span>
                  </Column>
                  <img src={general.imageCampaign['icon-arrow.png']} className="arrow" alt="Iterações" />
                  <Column className="iteraction-chart">
                    <RadialChart
                      size={160}
                      values={[mediaKPI.firstQuartile.percent]}
                      showDataLabels
                      padding={{
                        top: 0,
                        bottom: -10,
                      }}
                      textSize="18pt"
                    />
                    <label>1º Quartil</label>
                    <span className="bigger primary">{getFormattedValueByType('decimal', mediaKPI.firstQuartile.value)}</span>
                  </Column>
                  <img src={general.imageCampaign['icon-arrow.png']} className="arrow" alt="Iterações" />
                  <Column className="iteraction-chart">
                    <RadialChart
                      size={160}
                      values={[mediaKPI.midpoint.percent]}
                      showDataLabels
                      padding={{
                        top: 0,
                        bottom: -10,
                      }}
                      textSize="18pt"
                    />
                    <label>2º Quartil</label>
                    <span className="bigger primary">{getFormattedValueByType('decimal', mediaKPI.midpoint.value)}</span>
                  </Column>
                  <img src={general.imageCampaign['icon-arrow.png']} className="arrow" alt="Iterações" />
                  <Column className="iteraction-chart">
                    <RadialChart
                      size={160}
                      values={[mediaKPI.thirdQuartile.percent]}
                      showDataLabels
                      padding={{
                        top: 0,
                        bottom: -10,
                      }}
                      textSize="18pt"
                    />
                    <label>3º Quartil</label>
                    <span className="bigger primary">{getFormattedValueByType('decimal', mediaKPI.thirdQuartile.value)}</span>
                  </Column>
                  <img src={general.imageCampaign['icon-arrow.png']} className="arrow" alt="Iterações" />
                  <Column className="iteraction-chart">
                    <RadialChart
                      size={160}
                      values={[mediaKPI.complete.percent]}
                      showDataLabels
                      padding={{
                        top: 0,
                        bottom: -10,
                      }}
                      textSize="18pt"
                    />
                    <label>Completo</label>
                    <span className="bigger primary">{getFormattedValueByType('decimal', mediaKPI.complete.value)}</span>
                  </Column>
                </>
              ) : (
                <Column className="chart-line">
                  <LineChart
                    height={150}
                    mode="area"
                    values={[
                      {
                        name: 'Iterações',
                        data: [mediaKPI.start.value, mediaKPI.firstQuartile.value, mediaKPI.midpoint.value, mediaKPI.thirdQuartile.value, mediaKPI.complete.value],
                      },
                    ]}

                    labels={['Play', '1º Quartil', '2º Quartil', '3º Quartil', 'Completo']}
                  />
                </Column>
              )}
            </Row>
          </div>
        </div>
      </div>
    </>
  );
}

MediaVideo.propTypes = {
  media: PropTypes.shape().isRequired,
};


export default withTheme(MediaVideo);
