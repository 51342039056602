import styled, { css } from 'styled-components';
import ReactSelect from 'react-select';
import {
  ContainerStyled,
  TableStyled,
  RowStyled,
  InputStyled,
  FormGroupStyled,
} from '~/Components/Styled';


export const Space = styled.span`
  padding-right: ${props => props.padding}px;
`;

export const TitleItemExcluse = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

export const Container = styled(ContainerStyled)`
  //h1{
  //  margin: 0 0 30px 0;
  //}
`;
export const Table = styled(TableStyled)`
  .title-status{
    text-align:center;
  }
  .center-status{
    display: flex;
    justify-content: center;
  }
  .identification{
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    font-size: 18px;
    &:hover {
    text-decoration: none;
    color: ${props => props.theme.colors.primary};
    cursor: pointer;
  }

  }
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .box-search {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    justify-content: flex-end;
  }
`;

export const Input = styled(InputStyled)`
  width: 290px;
`;

export const RowTypesCampaign = styled(Row)`
  padding: 5px;
  border: 2px solid transparent;
  border-radius: 5px;
  ${props => props.invalid
    && css`
      border-color: ${props.theme.colors.danger2};
    `}
`;


export const FormGroup = styled(FormGroupStyled)``;

export const Select = styled(ReactSelect)`
  width: 180px;
  margin-right: 10px;
  ${props => props.invalid
    && css`
      > div {
        border-color: ${props.theme.colors.danger2};
      }
    `}
`;

export const ButtonCloseModal = styled.div`
  cursor: pointer;
`;

export const InfoDeleteCampaign = styled.p`
  padding-top: 20px;
  text-align: center;
  font-size: 20px;
`;

export const LinkButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  border: 1px solid;
  border-right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  margin-left: 0;
  padding-left: 10px;
  padding-right: 10px;
  ${props => props.disabled && css`
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  `};
`;

export const ButtonGroup = styled.div`
  display: flex;
  > button {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin: 0;
    padding: 0 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    > svg {
      margin: 0;
    }
  }
`;
