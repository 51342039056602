/* Types */
export const Types = {
  SHOW: 'historyCampaign/SHOW',
  CLOSE: 'historyCampaign/CLOSE',
  SHOW_SUCCESS: 'historyCampaign/SHOW_SUCCESS',
};

const INITIAL_STATE = {
  show: false,
  data: null,
};

export default function historyCampaign(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.SHOW_SUCCESS:
      return {
        ...state,
        show: true,
        data: action.payload,
      };
    case Types.CLOSE:
      return {
        ...state,
        show: false,
        data: null,
      };
    default:
      return state;
  }
}

/* Actions Creators */
export const Creators = {
  show: campaignId => ({
    type: Types.SHOW,
    payload: campaignId,
  }),
  showSuccess: data => ({
    type: Types.SHOW_SUCCESS,
    payload: data,
  }),
  close: () => ({
    type: Types.CLOSE,
  }),
};
