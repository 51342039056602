import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  FaTimes,
  FaEye, FaUserCheck, FaPercent,
} from 'react-icons/fa';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { json2excel } from 'js2excel';

import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';

import Modal from '~/Components/Modal';
import Button from '~/Components/Button';

import calendarImg from '~/Assets/Images/calendar.png';
import costTotalImg from '~/Assets/Images/cost-total.png';
import sendMessageImg from '~/Assets/Images/send-message.png';
import coinImg from '~/Assets/Images/coin.png';

import {
  Row,
  ModalWrap,
  RowHeader,
  RowContent,
  ButtonCloseModal,
  BaseCost,
  Cost,
  Img,
  BasePeriodo,
  Card,
  FormGroup,
  Table,
} from './styles';
import Select from '~/Components/Select';

import { Creators as CockpitAnalyzeActions } from '~/redux/ducks/cockpitAnalyze';
import { convertFloatReal } from '~/Utils';

class ModalResultsWeekly extends Component {
  renderAudiencia = () => {
    const {
      modalResultsWeekly: {
        gridPeriodReport: { campaign },
      },
    } = this.props;

    return (
      <div className="audience">
        <Row style={{ marginBottom: 0 }}>
          <BaseCost className="full">
            <Cost className="full">
              <Img alt="Moeda" src={coinImg} />
              <div className="desc-box">
                <p>Custo Acordado(R$):</p>
                <span>
                  {convertFloatReal(campaign.costVlr)}
                </span>
              </div>
            </Cost>
          </BaseCost>
        </Row>
      </div>
    );
  };

  renderEfetuado = () => {
    const {
      modalResultsWeekly: {
        gridPeriodReport: { campaign },
      },
    } = this.props;

    return (
      <>
        <Row style={{ marginRight: -20 }}>
          <Card>
            <Img alt="Bolsa Dinheiro" src={costTotalImg} />
            <FormGroup>
              <span>
                Investimento(R$)
              </span>
              <label className="card-value">{campaign.investiment.toLocaleString('pt-BR')}</label>
            </FormGroup>
          </Card>
          <Card>
            <Img alt="Avião Papel" src={sendMessageImg} />
            <FormGroup>
              <span>
                Disparos
              </span>
              <label className="card-value">{campaign.volume.toLocaleString('pt-BR')}</label>
            </FormGroup>
          </Card>
          <Card>
            <FaEye />
            <FormGroup>
              <span>
                Impressões
              </span>
              <label className="card-value">{campaign.delivered.toLocaleString('pt-BR')}</label>
            </FormGroup>
          </Card>
          <Card>
            <FaUserCheck />
            <FormGroup>
              <span>
                Conversão
              </span>
              <label className="card-value">{campaign.total.toLocaleString('pt-BR')}</label>
            </FormGroup>
          </Card>
          <Card>
            <FaPercent />
            <FormGroup>
              <span>
                % Conversão
              </span>
              <label className="card-value">
                {parseFloat(campaign.conversionRate).toFixed(2).toLocaleString('pt-BR')}
              </label>
            </FormGroup>
          </Card>
        </Row>
      </>
    );
  };

  renderTable = () => {
    const {
      modalResultsWeekly: {
        gridPeriodReport: { data },
      },
    } = this.props;
    return (
      <Table>
        <thead>
          <tr>
            <th>Data</th>
            <th>Disparos</th>
            <th>Impressões</th>
            <th>Conversão</th>
            <th>Custo</th>
          </tr>
        </thead>
        <tbody>
          {data.map(event => (
            <tr key={event}>
              <td>{event.dateEvent}</td>
              <td>{event.volume || 0}</td>
              <td>{event.sentQty || 0}</td>
              <td>{event.clickQty || 0}</td>
              <td>{convertFloatReal(event.costVlr || 0)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  };

  renderChartAcumulado = () => {
    const {
      modalResultsWeekly: {
        gridPeriodReport: { accumulated },
      },
    } = this.props;
    return (
      <Row>
        <LineChart
          width={1250}
          height={300}
          data={accumulated}
          margin={{
            top: 5,
            right: 10,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dateEvent" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="volume"
            name="Disparo"
            stroke="#9B34FF"
            activeDot={{ r: 8 }}
          />
          <Line
            type="monotone"
            dataKey="sentQty"
            name="Impressão"
            stroke="#E42792"
          />
          <Line
            type="monotone"
            dataKey="clickQty"
            name="Conversão"
            stroke="#10C8FE"
          />
        </LineChart>
      </Row>
    );
  };

  renderChartEventos = () => {
    const {
      modalResultsWeekly: {
        gridPeriodReport: { data },
      },
    } = this.props;
    return (
      <Row>
        <AreaChart
          width={1250}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 10,
            left: 0,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="dateEvent" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Area
            type="monotone"
            dataKey="volume"
            name="Disparo"
            stroke="#9B34FF"
            fill="#D5ACFC"
          />
          <Area
            type="monotone"
            dataKey="sentQty"
            name="Impressão"
            stroke="#E42792"
            fill="#FB9ED3"
          />
          <Area
            type="monotone"
            dataKey="clickQty"
            name="Conversão"
            stroke="#10C8FE"
            fill="#B2ECFD"
          />
        </AreaChart>
      </Row>
    );
  };

  handleExportToExcel = () => {
    const {
      modalResultsWeekly: {
        gridPeriodReport,
      },
    } = this.props;

    const data = gridPeriodReport.data.map(event => (
      {
        Data: event.dateEvent,
        Disparos: event.volume || 0,
        Impressões: event.sentQty || 0,
        Conversão: event.clickQty || 0,
        Custo: convertFloatReal(event.costVlr || 0),
      }
    ));
    try {
      json2excel({
        data,
        name: 'Relatório',
        formateDate: 'dd/mm/yyyy',
      });
    } catch (e) {
      console.error('export error', e);
    }
  }

  render() {
    const {
      modalResultsWeekly: {
        show,
        periods, periodSelected,
        grids, gridSelected,
        gridPeriodReport,
      },
      changePeriodSelectedModalResultsWeekly,
      changeGridSelectedModalResultsWeekly,
      closeModalResultsWeekly,
    } = this.props;

    return show && (
      <Modal width={1300} height={800} scroll={false} padding={0}>
        <ModalWrap>
          <RowHeader>
            <Row>
              <h1>Relatório</h1>
              <BasePeriodo>
                <label>Semana:</label>
                <Select
                  placeholder="Selecione a semana"
                  value={periodSelected}
                  isClearable={false}
                  onChange={(period) => {
                    // todo: change period
                    changePeriodSelectedModalResultsWeekly(period);
                  }}
                  options={periods}
                />
                <label>Tipo:</label>
                <Select
                  placeholder="Selecione o tipo"
                  value={gridSelected}
                  isClearable={false}
                  onChange={(grid) => {
                    // todo: change period
                    changeGridSelectedModalResultsWeekly(grid);
                  }}
                  options={grids}
                />
              </BasePeriodo>
              <ButtonCloseModal
                type="button"
                onClick={() => {
                  closeModalResultsWeekly();
                }}
              >
                <FaTimes size={30} />
              </ButtonCloseModal>
            </Row>
            {gridPeriodReport && (
              <Row style={{ alignItems: 'center' }}>
                <div className="header-campaign">
                  <h2>{gridPeriodReport.campaign.name}</h2>
                </div>
                <div className="header-chanel">
                  <h2>
                    {`${gridPeriodReport.campaign.channel} (${gridPeriodReport.campaign.zone})`}
                  </h2>
                </div>
                <div className="header-action">
                  <Button
                    bordered
                    icon="FaFileExcel"
                    title="Exportar"
                    onClick={() => {
                      this.handleExportToExcel();
                    }}
                    type="button"
                  />
                </div>
              </Row>
            )}
          </RowHeader>
          <RowContent>
            {!gridPeriodReport && (
              <div className="empty-table-desc">
                <span>A campanha não possui resultados a serem exibidos.</span>
              </div>
            )}
            {gridPeriodReport && (
              <div id="CHART" className="campaign">
                <Row>
                  <BaseCost>
                    <Cost>
                      <Img alt="Push Notification" src={calendarImg} />
                      <div className="desc-box">
                        <p>Data Inicio </p>
                        <span>{moment(gridPeriodReport.campaign.startDate).format('DD/MM/YYYY')}</span>
                      </div>
                    </Cost>
                    <Cost>
                      <div className="desc-box">
                        <p>Data Fim </p>
                        <span>{moment(gridPeriodReport.campaign.endDate).format('DD/MM/YYYY')}</span>
                      </div>
                    </Cost>
                  </BaseCost>
                  {this.renderAudiencia()}
                </Row>
                <h3>Total Efetuado</h3>
                {this.renderEfetuado()}

                <h3>Acumulado</h3>
                {this.renderChartAcumulado()}

                <h3>Eventos</h3>
                {this.renderChartEventos()}

                {this.renderTable()}
              </div>
            )}
          </RowContent>
        </ModalWrap>
      </Modal>
    );
  }
}

ModalResultsWeekly.propTypes = {
  modalResultsWeekly: PropTypes.shape({
    show: PropTypes.bool.isRequired,
    periods: PropTypes.arrayOf(PropTypes.shape()).isRequired,
    periodSelected: PropTypes.shape(),
    gridPeriodReport: PropTypes.shape(),
    gridSelected: PropTypes.shape(),
    grids: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  }).isRequired,
  changePeriodSelectedModalResultsWeekly: PropTypes.func.isRequired,
  changeGridSelectedModalResultsWeekly: PropTypes.func.isRequired,
  closeModalResultsWeekly: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
  modalResultsWeekly: state.cockpitAnalyze.modalResultsWeekly,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    changePeriodSelectedModalResultsWeekly: CockpitAnalyzeActions.changePeriodSelectedModalResultsWeekly,
    changeGridSelectedModalResultsWeekly: CockpitAnalyzeActions.changeGridSelectedModalResultsWeekly,
    closeModalResultsWeekly: CockpitAnalyzeActions.closeModalResultsWeekly,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ModalResultsWeekly);
