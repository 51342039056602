import styled, { css } from 'styled-components';
import { RowStyled } from '~/Components/Styled';

import { hex2rgba } from '~/Utils';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 1250px) {
    .row-1{
      flex-direction: column;
    }
    .row-2{
      flex-direction: column;
    }
    .row-3{
      flex-direction: column;
    }
    .row-4{
      flex-direction: column;
    }
  }
  @media (max-width: 860px) {
    .campaign-columns {
      flex-direction: column;
    }
  }
  @media (max-width: 700px) {
    .campaign-header {
      flex-direction: column;
    }
  }

  .base-atributos {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .base-acompanhamento {
    flex: 1;
    display: flex;
    flex-direction: column;

    max-height: 300px;
    overflow: auto;
  }
`;

export const ItemAtributoVisualizacao = styled.div`
  flex: 1;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin: 5px;
  padding: 10px 0;
  display: flex;

  .base-button-icon-attribute {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    border-right: 2px solid #ccc;

    ${props => !props.editable && css`
      display: none;
    `}
  }

  .value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 15px;
    label {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 0;
    }
  }

  ${props => (props.editable && css`
    border-color: ${props.theme.colors.dark};
    .base-button-icon-attribute {
        border-color: ${props.theme.colors.dark};
    }
    &:hover {
      border-color: ${props.theme.colors.primary};
      .base-button-icon-attribute {
        border-color: ${props.theme.colors.primary};
        cursor: pointer;

        &:hover {
          /* background-color: ${props.theme.colors.lighterWhite}; */
          svg {
            fill: ${props.theme.colors.primary};
          }
        }
      }
    }
  `)};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const HeaderButtons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const BaseLinkHistoryCampaign = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
`;

export const LinkHistoryCampaign = styled.a`
  cursor: pointer;
  color: ${props => props.theme.colors.primary};
  &:hover, &:focus {
    color: ${props => props.theme.colors.primary};
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CampaignData = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

export const CampaignDataColumn = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 15px;
  flex: 1;
`;

export const CampaignDataPreview = styled.div`
  display: flex;
  flex-direction: column;
  width: 350px;
`;

export const Preview = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

export const PreviewImg = styled.img`
  width: 280px;
`;

export const RowScreensContainer = styled(Row)`
  align-items: unset;
  margin: 0;
  > div {
    margin-right: 15px;

    &:last-child {
    margin-right: 0;
  }
  }
`;

export const BenefitBox = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  ul {
    padding-left: 20px;
    li {
      list-style: circle;
    }
  }
`;

export const MessageNoContent = styled.div`
  display: flex;
  flex: 1;
  font-size: 16px;
  font-weight: bold;
  color: ${props => props.theme.colors.primary};
  justify-content: center;
`;

export const ItemAcompanhamentoStatus = styled.div`
  display: flex;

  .status {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 40px;
    height: 40px;
    border: 2px solid ${props => props.colorStatus};
    border-radius: 20px;
    opacity: 0.8;
  }
  .base-body-content {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: flex-start;

    margin-left: 10px;
    margin-right: 5px;

    .data-hora-status {
      width: 100%;
      font-style: italic;
      color: #9d9d9d;
      font-size: 12px;
    }
    p {
      margin-bottom: 0;
      text-align: justify;
    }
    .divider-bottom-acompanhamento {
      width: 100%;
      height: 1px;
      background-color: #ccc;
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }
`;

export const TableStyled = styled.table`
  width: 100%;
  thead {
    tr {
      background-color: ${props => props.theme.colors.white};
      th {
        padding: 9px;
        font-family: "Simplon BP";
        font-size: 18px;
        font-weight: 600;
      }
      th.table-column-center {
        text-align: center;
      }
    }
  }
  tbody tr{
    margin-top:15px;
    margin-bottom:15px;
    border-bottom: 1px solid ${props => props.theme.colors.light};
    td {
      padding: 15px 5px 5px 7.5px;
      &.link{
        /* max-width:350px; */
        /* white-space: nowrap; */
        /* overflow: hidden; */
        /* text-overflow: ellipsis; */
      }
      span{
        font-size:18px;
      }
      p {
        font-size:18px;
      }
    }
    td.table-column-center {
      text-align: center;
    }
  }
`;

export const Table = styled(TableStyled)`

`;
