import React from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';

import Button from '~/Components/Button';
import {
  Container,
  Box,
  BoxTitle,
  BoxContent,
} from './styles';

const ScreenContainer = ({
  children,
  title,
  schema,
  action,
  actionTitle,
  theme,
}) => {
  if (!schema.visible) {
    return null;
  }

  return (
    <>
      <Container>
        <Box>
          <BoxTitle>
            <span>{title}</span>
            {schema.editable && (
              <Button
                small
                bordered
                title={actionTitle}
                onClick={action}
                type="button"
                style={{
                  backgroundColor: theme.colors.white,
                }}
              />
            )}
          </BoxTitle>
          <BoxContent>
            {children}
            {/* {children || items.filter(item => item.visible).map(item => (
              <BoxContentItem>
                <b>{item.title}</b>
                <span>{item.value}</span>
              </BoxContentItem>
            ))} */}
          </BoxContent>
        </Box>
      </Container>
    </>
  );
};

ScreenContainer.propTypes = {
  title: PropTypes.string.isRequired,
  actionTitle: PropTypes.string,
  action: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  schema: PropTypes.shape().isRequired,
  theme: PropTypes.shape().isRequired,
};

ScreenContainer.defaultProps = {
  actionTitle: 'Editar',
  action: () => {},
  children: null,
};

export default withTheme(ScreenContainer);
