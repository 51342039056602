import styled from 'styled-components';

import ModalStyled from '~/Components/Modal';

export const Container = styled.div`
  flex: 2;
  padding-top: 2px;
`;

export const Base = styled.div`
  min-height: 600px;
  margin: 0 5px;
  border: 2px solid #ccc;
  display: flex;
`;

export const BaseNoItemSelected = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;

  padding: 15px;

  justify-content: center;
  align-items: center;

  h1 {
    text-align: center;
    color: ${props => props.theme.colors.regular} !important;
    margin-top: 30px;
  }
`;

export const BaseListItens = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    border-bottom: 1px solid #ccc;
  }
`;

export const ItemShowHide = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
`;

export const CloseHeader = styled.div`
  cursor: pointer;
`;

export const Modal = styled(ModalStyled)``;

export const ContentModal = styled.div`
  margin: -20px;
`;
