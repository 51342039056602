import styled from 'styled-components';
import {
  TableStyled,
} from '~/Components/Styled';


export const Table = styled(TableStyled)`
  margin-top: 5px;
  tbody tr:hover {
    background-color: ${props => props.theme.colors.lighterWhite}
  }
  tbody tr td {
    padding: 5px 5px 5px 15px;
    span {
      font-size: 14px;
      color: ${props => props.theme.colors.dark};
      font-weight: 500;
      font-family: 'Simplon Oi', -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif !important;
    }
  }
`;

export const ButtonDeleteSegment = styled.button.attrs({
  type: 'button',
})`
  margin-right: 10px;
  color: ${props => props.theme.colors.danger2};
  border: 1px solid ${props => props.theme.colors.danger2};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  background-color: transparent;
  outline: none;
`;
