import auth0 from 'auth0-js';

// const AUTH_CONFIG = {
//   domain: 'mobicare.auth0.com',
//   clientId: 'Wrqe5Lr1XsihImR73cxdkqVZZwrXfyL5',
//   originDefaultCallbackUrl: 'http://localhost:3000',
//   callbackUrl: '/callback',
// };

// const PROPS_CONTRACT_UUID = 'https://artemis.mobicare.com.br/contractUuid';
// const PROPS_CREATED_AT = 'https://artemis.mobicare.com.br/createdAt';
// const PROPS_IS_SOCIAL = 'https://artemis.mobicare.com.br/isSocial';
// const PROPS_LAST_PWD_RESET = 'https://artemis.mobicare.com.br/lastPwdReset';
// const PROPS_TENANT_UUID = 'https://artemis.mobicare.com.br/tenantUuid';
const PROPS_USER_UUID = 'https://artemis.mobicare.com.br/userUuid';
const TENANT_UUID = 'https://artemis.mobicare.com.br/tenantUuid';

export default class Auth {
  accessToken;

  idToken;

  expiresAt;

  userProfile;

  auth0;

  push;

  authConfig;

  constructor(push, authConfig) {
    this.push = push;
    this.authConfig = authConfig;

    const { origin } = document.location;
    this.auth0 = new auth0.WebAuth({
      domain: 'akross-tenant.us.auth0.com',
      clientID: authConfig.client_id,
      redirectUri: `${origin}/callback`,
      responseType: 'token id_token',
      scope: 'openid profile read:current_user',
    });
  }

  /* login() {
    this.auth0.authorize({
      connection: 'MarketAnalytics',
    });
  } */
  login = () => {
    this.auth0.authorize({ });
  }

  handleAuthentication = () => new Promise((resolve, reject) => {
    this.auth0.parseHash((err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        resolve(authResult);
      } else if (err) {
        // this.auth0.logout({
        //   returnTo: 'http://data.oiads.com.br/oib2b.web/portal/#/logout',
        // });
        reject(err);
      }
      resolve(null);
    });
  })

  getAccessToken = () => this.accessToken

  getIdToken = () => this.idToken

  getProfile = () => new Promise((resolve, reject) => {
    this.auth0.client.userInfo(this.accessToken, (err, profile) => {
      if (err) {
        reject(err);
      }

      if (profile) {
        this.userProfile = profile;
      }
      resolve(profile);
    });
  })

  getUuidUser = () => new Promise((resolve, reject) => {
    this.auth0.client.userInfo(this.accessToken, (err, profile) => {
      if (err) {
        reject(err);
      }
      resolve(profile ? profile[PROPS_USER_UUID] : null);
    });
  })

  getUuidTenant = () => new Promise((resolve, reject) => {
    this.auth0.client.userInfo(this.accessToken, (err, profile) => {
      if (err) {
        reject(err);
      }
      resolve(profile ? profile[TENANT_UUID] : null);
    });
  })

  setSession = (authResult) => {
    // Set isLoggedIn flag in localStorage
    localStorage.setItem('isLoggedIn', 'true');
    localStorage.setItem('token', authResult.idToken);
    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
    this.accessToken = authResult.accessToken;
    this.idToken = authResult.idToken;
    this.expiresAt = expiresAt;

    // navigate to the home route
    // const pathname = localStorage.getItem('@ArtemisAdmin:path_return');
    const pathname = sessionStorage.getItem('@ArtemisAdmin:path_return');
    this.push(pathname);
  }

  renewSession = () => {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
      } else if (err) {
        this.logout();
        // alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }

  logout = () => {
    const { origin } = document.location;
    this.auth0.logout({
      returnTo: origin,
    });
    // Remove tokens and expiry time
    // this.accessToken = null;
    // this.idToken = null;
    // this.expiresAt = 0;

    // Remove isLoggedIn flag from localStorage
    // localStorage.removeItem('isLoggedIn');

    // this.userProfile = null;

    // navigate to the home route
    // this.push('/home');
  }

  isAuthenticated = () => {
    // Check whether the current time is past the
    // access token's expiry time
    const { expiresAt } = this;
    return new Date().getTime() < expiresAt;
  }
}
