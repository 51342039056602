import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { InternalMediaComponentPreview } from '../../../../Creative/Components/InternalMediaComponent';

const NoCreditBannerTextPreview = ({ data }) => <InternalMediaComponentPreview data={data} />;

NoCreditBannerTextPreview.propTypes = {
  data: PropTypes.shape().isRequired,
};

// eslint-disable-next-line no-unused-vars
const mapStateToProps = state => ({
  noCreditPreviewData: state.campaignEdit.noCreditPreviewData,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {},
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(NoCreditBannerTextPreview);
