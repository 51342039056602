import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

function DataEmpty({ label }) {
  return (
    <Container className="data-empty">
      <p>{label}</p>
    </Container>
  );
}

DataEmpty.defaultProps = {
  label: 'Sem dados para apresentar no momento...',
};

DataEmpty.propTypes = {
  label: PropTypes.string,
};

export default DataEmpty;
