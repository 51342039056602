
/* Types */
export const Types = {
  LOAD_AUDIENCE_TEST: 'audienceTest/LOAD_AUDIENCE_TEST',
  LOAD_AUDIENCE_TEST_LIST: 'audienceTest/LOAD_AUDIENCE_TEST_LIST',
  SHOW_MODAL_AUDIENCE: 'audienceTest/SHOW_MODAL_AUDIENCE',
  DELETE_AUDIENCE: 'audienceTest/DELETE_AUDIENCE',
  FILTER_AUDIENCE: 'audienceTest/FILTER_AUDIENCE',
  SET_FILTER_AUDIENCE: 'audienceTest/SET_FILTER_AUDIENCE',
  CREATE_SUCCESS: 'audienceTest/CREATE_SUCCESS',
  SET_CHECKBOX: 'audienceTest/SET_CHECKBOX',
  HIDE_MODAL_AUDIENCE: 'audienceTest/HIDE_MODAL_AUDIENCE',
  IS_CHECKED_SMS: 'audienceTest/IS_CHECKED_SMS',
  IS_CHECKED_PUSH: 'audienceTest/IS_CHECKED_PUSH',
  IS_CHECKED_NO_DATA: 'audienceTest/IS_CHECKED_NO_DATA',
  IS_CHECKED_SPONSORED: 'audienceTest/IS_CHECKED_SPONSORED',
  SAVE_MODAL_DATA: 'audienceTest/SAVE_MODAL_DATA',
  LOAD_AUDIENCE_USER_DATA: 'audienceTest/LOAD_AUDIENCE_USER_DATA',
  SET_AUDIENCE_USER_DATA: 'audienceTest/SET_AUDIENCE_USER_DATA',
  SET_NEW_AUDIENCE_USER_DATA: 'audienceTest/SET_NEW_AUDIENCE_USER_DATA',
  CLEAN_MODAL_DATA: 'audienceTest/CLEAN_MODAL_DATA',
  RESET_FILTER_AUDIENCE: 'audienceTest/RESET_FILTER_AUDIENCE',
  BACKUP_AUDIENCE_DATA: 'audienceTest/BACKUP_AUDIENCE_DATA',
};


const INITIAL_STATE = {
  audienceUsers: [],
  audienceUsersBackup: [],
  audienceUserData: [],
  loadName: '',
  loadIdentifier: '',
  idAudienceUser: null,
  modalTestStatus: false,
  filter: '',
  valueSms: false,
  valuePush: false,
  valueNoData: false,
  valueSponsored: false,
  createSuccess: false,
  salvar: false,
  loading: false,
};

export default function audienceTest(state = INITIAL_STATE, action) {
  switch (action.type) {
    case Types.LOAD_AUDIENCE_TEST_LIST:
      return {
        ...state,
        audienceUsers: action.payload,
      };
    case Types.SHOW_MODAL_AUDIENCE:
      return {
        ...state,
        modalTestStatus: true,
      };
    case Types.SET_AUDIENCE_USER_DATA:
      return {
        ...state,
        audienceUserData: action.payload,
        idAudienceUser: action.payload.uuid,
        loadName: action.payload.name,
        loadIdentifier: action.payload.identifier,
      };
    case Types.SET_NEW_AUDIENCE_USER_DATA:
      return {
        ...state,
        loadName: action.payload.name,
        loadIdentifier: action.payload.identifier,
      };
    case Types.HIDE_MODAL_AUDIENCE:
      return {
        ...state,
        modalTestStatus: false,
        loadName: null,
        loadIdentifier: null,
        idAudienceUser: null,
        valueSms: false,
        valuePush: false,
        valueNoData: false,
        valueSponsored: false,
      };
    case Types.CLEAN_MODAL_DATA:
      return {
        ...state,
        loadName: '',
        loadIdentifier: '',
        valueSms: false,
        valuePush: false,
        valueNoData: false,
        valueSponsored: false,
      };
    case Types.SET_FILTER_AUDIENCE:
      return {
        ...state,
        filter: action.payload.name,
      };
    case Types.BACKUP_AUDIENCE_DATA:
      return {
        ...state,
        audienceUsersBackup: action.payload,
      };
    case Types.CREATE_SUCCESS:
      return {
        ...state,
        createSuccess: true,
      };
    case Types.IS_CHECKED_SMS:
      return {
        ...state,
        valueSms: !state.valueSms,
      };
    case Types.IS_CHECKED_PUSH:
      return {
        ...state,
        valuePush: !state.valuePush,
      };
    case Types.IS_CHECKED_NO_DATA:
      return {
        ...state,
        valueNoData: !state.valueNoData,
      };
    case Types.IS_CHECKED_SPONSORED:
      return {
        ...state,
        valueSponsored: !state.valueSponsored,
      };
    default:
      return state;
  }
}

/* Actions Creators */
export const Creators = {
  loadAudienceTest: () => ({
    type: Types.LOAD_AUDIENCE_TEST,
  }),
  loadAudienceTestList: audienceUsers => ({
    type: Types.LOAD_AUDIENCE_TEST_LIST,
    payload: audienceUsers,
  }),
  showModalAudience: () => ({
    type: Types.SHOW_MODAL_AUDIENCE,
  }),
  deleteAudience: audienceId => ({
    type: Types.DELETE_AUDIENCE,
    payload: audienceId,
  }),
  filterAudience: (audienceFilter) => ({
    type: Types.FILTER_AUDIENCE,
    payload: audienceFilter,
  }),
  setFilterAudience: filterField => ({
    type: Types.SET_FILTER_AUDIENCE,
    payload: filterField,
  }),
  createSuccess: () => ({
    type: Types.CREATE_SUCCESS,
  }),
  hideModalAudience: () => ({
    type: Types.HIDE_MODAL_AUDIENCE,
  }),
  isCheckedSms: () => ({
    type: Types.IS_CHECKED_SMS,
  }),
  isCheckedPush: () => ({
    type: Types.IS_CHECKED_PUSH,
  }),
  isCheckedNoData: () => ({
    type: Types.IS_CHECKED_NO_DATA,
  }),
  isCheckedSponsored: () => ({
    type: Types.IS_CHECKED_SPONSORED,
  }),
  saveModalData: audienceSave=> ({
    type: Types.SAVE_MODAL_DATA,
    payload: audienceSave,
  }),
  loadAudienceUserData: audienceId => ({
    type: Types.LOAD_AUDIENCE_USER_DATA,
    payload: audienceId,
  }),
  setAudienceUserData: audienceData => ({
    type: Types.SET_AUDIENCE_USER_DATA,
    payload: audienceData,
  }),
  setNewAudienceUserData: (newAudienceUserData) => ({
    type: Types.SET_NEW_AUDIENCE_USER_DATA,
    payload: newAudienceUserData,
  }),
  cleanModalData: () => ({
    type: Types.CLEAN_MODAL_DATA,
  }),
  resetfilterAudience: (backupAudience) => ({
    type: Types.RESET_FILTER_AUDIENCE,
    payload: backupAudience,
  }),
  backupAudienceData: (backupAudience) => ({
    type: Types.BACKUP_AUDIENCE_DATA,
    payload: backupAudience,
  }),
};
