import { call, put, select } from 'redux-saga/effects';

import _ from 'lodash';

import AdManagementService from '~/services/AdManagementService';
import { Creators as AdActions } from '~/redux/ducks/ad';
import {
  startRequest,
  requestSuccess,
  requestError,
} from '~/redux/ducks/loading';

import { Creators as ToastrMessageActions } from '~/redux/ducks/toastrMessage';

export function* loadAdList() {
  yield put(startRequest());
  try {
    const adList = yield call(AdManagementService.list);
    yield put(AdActions.loadAdListSuccess(adList));
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao buscar os anúncios. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* createAd() {
  const { adList, adNew } = yield select(state => state.ad);
  const advertisingPartnerList = yield select(
    state => state.advertisingPartner.advertisingPartnerList,
  );

  // data to new ad
  const partner = advertisingPartnerList.find(
    item => item.id === parseInt(adNew.partner),
  );
  const setup = adList.find((item) => {
    const event = parseInt(item.eventId) === parseInt(adNew.event);
    const channel = item.channel === adNew.channel;
    return event && channel;
  });
  const newDetail = {
    id: -1,
    color: adNew.color,
    enabled: adNew.enabled,
    label: adNew.label,
    minAppVersion: 0,
    partner,
    percentage: 0,
    position: 0,
    properties: adNew.properties,
    setupId: setup.id,
  };
  setup.details = [...setup.details, newDetail];

  yield put(startRequest());
  try {
    yield call(AdManagementService.update, setup);
    yield put(AdActions.changeModalNewAd(false));
    yield put(AdActions.loadAdList());
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao criar o anúncio. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* deleteAd({ setupId, detailId }) {
  const { adList } = yield select(state => state.ad);
  const setup = adList.find(item => item.id === setupId);
  const newDetails = setup.details.filter(item => item.id !== detailId);
  setup.details = newDetails;
  yield put(startRequest());
  try {
    yield call(AdManagementService.update, setup);
    yield put(AdActions.loadAdList());
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao excluir o anúncio. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* loadEditAd({ setupId, detailId }) {
  const { adList } = yield select(state => state.ad);
  const setup = adList.find(item => item.id === setupId);
  const detail = setup.details.find(item => item.id === detailId);

  const adEdit = {
    setupId,
    detailId,
    color: detail.color,
    enabled: detail.enabled,
    label: detail.label,
    partner: detail.partner.id,
    event: setup.eventId,
    channel: setup.channel,
    properties: detail.properties,
  };
  yield put(AdActions.loadEditAdSuccess(adEdit));
}

export function* editAd() {
  const { adList, adEdit } = yield select(state => state.ad);
  const advertisingPartnerList = yield select(
    state => state.advertisingPartner.advertisingPartnerList,
  );
  // data to new ad
  const partner = advertisingPartnerList.find(
    item => item.id === parseInt(adEdit.partner),
  );
  // find setup by id
  const setup = adList.find(item => item.id === parseInt(adEdit.setupId));
  // find detail by id
  let editedDetail = setup.details.find(
    item => item.id === parseInt(adEdit.detailId),
  );
  editedDetail = {
    ...editedDetail,
    color: adEdit.color,
    enabled: adEdit.enabled,
    label: adEdit.label,
    partner,
    partnerId: partner.id,
    properties: adEdit.properties,
    setupId: setup.id,
  };
  const isSameSetup = () => {
    const event = parseInt(setup.eventId) === parseInt(adEdit.event);
    const channel = setup.channel === adEdit.channel;
    return event && channel;
  };

  if (!isSameSetup()) {
    const newSetup = adList.find((item) => {
      const event = parseInt(item.eventId) === parseInt(adEdit.event);
      const channel = item.channel === adEdit.channel;
      return event && channel;
    });
    editedDetail = {
      ...editedDetail,
      setupId: newSetup.id,
    };
  }

  setup.details = setup.details.map(item => (
    item.id === editedDetail.id ? editedDetail : item));

  yield put(startRequest());
  try {
    yield call(AdManagementService.update, setup);
    yield put(AdActions.changeModalEditAd(false));
    yield put(AdActions.loadAdList());
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar o anúncio. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* setupEnableDisable({ setupId }) {
  const { adList } = yield select(state => state.ad);

  const setup = adList.find(item => item.id === setupId);
  setup.enabled = !setup.enabled;

  yield put(startRequest());
  try {
    yield call(AdManagementService.update, setup);
    yield put(AdActions.loadAdList());
    yield put(requestSuccess());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar o anúncio. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* setupEnableToEdit({ setupId }) {
  const { adList } = yield select(state => state.ad);
  const setup = adList.find(item => item.id === setupId);
  yield put(
    AdActions.setupEnableToEditSuccess(
      setup.id,
      setup.audience,
      setup.printingSecs,
      setup.retrySecs,
    ),
  );
}

export function* setupSaveEditing() {
  const { setupEditing, adList } = yield select(state => state.ad);
  const setup = adList.find(item => item.id === setupEditing.id);
  setup.audience = setupEditing.audience;
  setup.printingSecs = setupEditing.printingSecs;
  setup.retrySecs = setupEditing.retrySecs;
  yield put(startRequest());
  try {
    yield call(AdManagementService.update, setup);
    yield put(AdActions.setupDisableToEdit());
    yield put(requestSuccess());
    yield put(AdActions.loadAdList());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar o anúncio. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}

export function* changeDetailPositionInSetup({
  setupId,
  detailId,
  newPosition,
}) {
  const { adList } = yield select(state => state.ad);
  const setupChanged = adList.find(item => item.id === setupId);
  const detailChanged1 = setupChanged.details.find(
    item => item.id === detailId,
  );
  const detailChanged2 = setupChanged.details.find(
    item => item.position === newPosition,
  );
  detailChanged2.position = detailChanged1.position;
  detailChanged1.position = newPosition;
  setupChanged.details.map((item) => {
    if (item.id === detailChanged1.id) {
      return detailChanged1;
    }
    if (item.id === detailChanged2.id) {
      return detailChanged2;
    }
    return item;
  });

  yield put(
    AdActions.loadAdListSuccess(
      adList.map((item) => {
        if (item.id === setupChanged.id) {
          return setupChanged;
        }
        return item;
      }),
    ),
  );
}

export function* setupDisableOrdering() {
  yield put(AdActions.loadAdList());
}
export function* setupSaveOrdering() {
  const { adList, setupOrdering } = yield select(state => state.ad);
  const setupToSave = adList.find(item => item.id === setupOrdering);
  setupToSave.details = yield _.orderBy(
    setupToSave.details,
    ['position'],
    ['asc'],
  );
  yield put(startRequest());
  try {
    yield call(AdManagementService.update, setupToSave);
    yield put(requestSuccess());
    yield put(AdActions.setupDisableOrdering());
  } catch (e) {
    yield put(ToastrMessageActions.errorMessage(
      'Atenção!',
      'Houve um problema ao atualizar a ordenação. Tente novamente mais tarde!',
    ));
    yield put(requestError());
  }
}
