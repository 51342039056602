import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Loader } from 'react-loaders';

import { withTheme } from 'styled-components';

import { LazyLoadImage } from 'react-lazy-load-image-component';

import { Container, BaseLoader } from './styles';

class Image extends Component {
  state = {
    loading: false,
    src: null,
  }

  componentDidMount() {
    const { src } = this.props;
    this.updateSrc(src);
  }

  componentWillReceiveProps = (nextProps) => {
    if (nextProps.src) {
      this.updateSrc(nextProps.src);
    }
  }

  updateSrc(newSrc) {
    const { src } = this.state;
    if (src !== newSrc) {
      this.setState({ src: null });
      setTimeout(() => this.setState({ src: newSrc }), 100);
    }
  }

  render() {
    const {
      alt, className, style, theme,
    } = this.props;
    const { loading, src } = this.state;
    return (
      <Container style={style}>
        {src && (
        <LazyLoadImage
          className={className}
          alt={alt}
          src={src}
          beforeLoad={() => {
            this.setState({ loading: true });
          }}
          afterLoad={() => {
            this.setState({ loading: false });
          }}
        />
        )}
        {loading && <BaseLoader><Loader active type="line-spin-fade-loader" color={theme.colors.primary} /></BaseLoader>}
      </Container>
    );
  }
}

Image.defaultProps = {
  src: null,
  alt: null,
  className: null,
  style: null,
};

Image.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  className: PropTypes.string,
  style: PropTypes.shape(),
  theme: PropTypes.shape().isRequired,
};

export default withTheme(Image);
