import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import moment from 'moment';
import FileSaver from 'file-saver';
import { jsonToCSV } from 'react-papaparse';
import {
  Button, RadialChart, LineChart,
} from '../../Components';
import Table from './Components/Table';
import { general } from '~/Styles';
import {
  Row,
  Column,
  CheckButton,
  MediaImagemPreview,
  Select,
} from '../../styles';
import { getFormattedValueByType } from '~/Utils';
import DataEmpty from '../../Components/DataEmpty';

const MODE_DAILY_EVENTOS = 'events';
const MODE_DAILY_ACUMULADO = 'accumuled';

const downloadCsv = (data) => {
  const dataConvert = [
    ...data.map(item => [
      item.date,
      getFormattedValueByType('decimal', item.impressions),
      getFormattedValueByType('decimal', item.clicks),
      `${getFormattedValueByType('decimal', item.ctrPercent)}%`,
    ]),
  ];
  const fields = ['Data', 'Impressão', 'Cliques', 'Convertido'];
  const csvContent = new Blob(['\ufeff', jsonToCSV({ fields, data: dataConvert })], { type: 'text/csv;charset=utf-8' });
  FileSaver.saveAs(csvContent, 'dados.csv');
};
function MediaImagem({ media }) {
  const [propNameDataDaily, setPropNameDataDaily] = useState('clicks');
  const [modeDataDaily, setModeDataDaily] = useState(MODE_DAILY_EVENTOS);
  const [dataDaily, setDataDaily] = useState(null);

  const [dataTable, setDataTable] = useState([]);

  const {
    title, type: typeMedia,
    content, dailyResultsFile,
    dailyMediaKPI,
    mediaKPI: {
      clicks: { value: clicks },
      ctrPercent,
      impressions,
    },
  } = media;
  let labelTypeMedia = '';
  switch (typeMedia) {
    case 'image':
      labelTypeMedia = 'Imagem';
      break;
    default:
  }

  const titleData = title || `Mídia ${labelTypeMedia}`;
  const contentImage = content || null;

  function getNameByProps(propName) {
    switch (propName) {
      case 'clicks':
        return 'Cliques';
      case 'impressions':
        return 'Impressões';
      default:
        return '';
    }
  }

  useEffect(() => {
    function makeDataChartDaily(data) {
      const dataDailyUpdate = {
        labels: [],
        values: {
          events: [],
          accumuled: [],
        },
      };
      setDataDaily(dataDailyUpdate);

      data.forEach((d) => {
        const value = d[propNameDataDaily];

        dataDailyUpdate.labels.push(moment(d.date, 'YYYY-MM-DD').format('DD/MM'));

        dataDailyUpdate.values.events.push(value);

        const lastAccumuled = dataDailyUpdate.values.accumuled[dataDailyUpdate.values.accumuled.length - 1] || 0;
        dataDailyUpdate.values.accumuled.push(lastAccumuled + value);
      });
      setDataDaily(dataDailyUpdate);
    }
    makeDataChartDaily(dailyMediaKPI);
  }, [dailyMediaKPI, propNameDataDaily]);

  useEffect(() => {
    function makeDataTable(data) {
      setDataTable(data.map(d => ({
        ...d,
        date: moment(d.date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      })));
    }
    makeDataTable(dailyMediaKPI);
  }, [dailyMediaKPI]);

  function handleDownloadImage() {
    const link = document.createElement('a');
    link.href = contentImage;
    link.download = 'download';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <>
      <div className="media-imagem">
        <div className="media-imagem-grid">
          <MediaImagemPreview className="media-imagem-preview" image={contentImage}>
            {!contentImage && <span className="preview-no-content">Sem preview disponível</span>}
            <Row className="title-action">
              <span className="bigger">{titleData}</span>
              {/* <ButtonExpand onClick={() => { }}>
                <img src={general.imageCampaign['icon-expand.png']} alt="Expandir Visualização" />
              </ButtonExpand> */}
            </Row>
          </MediaImagemPreview>


          <Column className="box media-imagem-download">
            <Row className="space">
              <div className="type-box">
                <img src={general.imageCampaign['icon-download.png']} alt="Download" />
              </div>
              <h2>DownLoad Imagem</h2>
            </Row>
            <Button disabled={!contentImage} title="Fazer Download" onClick={handleDownloadImage} />
          </Column>

          <div className="box media-imagem-ctr">
            <Row className="ctr-space">
              <Column>
                <Row className="title-ctr">
                  <img src={general.imageCampaign['icon-ctr.png']} alt="CTR" />
                  <h2>CTR</h2>
                </Row>
                <Column className="values">
                  <label>Cliques por Entrega:</label>
                  <span>
                    <span className="bigger">{getFormattedValueByType('decimal', clicks)}</span>
                    /
                    {getFormattedValueByType('decimal', impressions)}
                  </span>
                </Column>
              </Column>
              <Column className="chart">
                <RadialChart
                  size={130}
                  values={[ctrPercent]}
                  showDataLabels
                  padding={{
                    top: -20,
                    bottom: -20,
                  }}
                />
              </Column>

            </Row>
          </div>
          <div className="box media-imagem-dados">
            <Row className="header">
              <Row>
                <h2>Dados</h2>
                {/* <Button onClick={() => { }} className="btn-anchor">
                  <img src={general.imageCampaign['icon-anchor-link.png']} alt="" />
                </Button> */}
              </Row>

              <Button
                disabled={!(dataTable && dataTable.length > 0)}
                tooltip={!(dataTable && dataTable.length > 0) ? 'Não existe arquivo para download no momento' : null}
                title="Download Dados"
                onClick={() => downloadCsv(dataTable)}
              >
                <img src={general.imageCampaign['icon-download-white.png']} alt="" />
              </Button>
            </Row>

            <Table data={dataTable} />
          </div>

          <div className="box media-imagem-eventos">
            <Row className="space">
              <img src={general.imageCampaign['icon-money.png']} alt="" />

              <Select value={propNameDataDaily} onChange={e => setPropNameDataDaily(e.target.value)}>
                <option value="clicks">{getNameByProps('clicks')}</option>
                <option value="impressions">{getNameByProps('impressions')}</option>
              </Select>

              <CheckButton onClick={() => setModeDataDaily(MODE_DAILY_EVENTOS)} checked={modeDataDaily === MODE_DAILY_EVENTOS}>
                <span>Eventos</span>
              </CheckButton>
              <CheckButton onClick={() => setModeDataDaily(MODE_DAILY_ACUMULADO)} checked={modeDataDaily === MODE_DAILY_ACUMULADO}>
                <span>Acumulado</span>
              </CheckButton>

            </Row>
            <Row className="chart-compare">
              {dataDaily && dataDaily.labels && dataDaily.labels.length > 1 ? (
                <Column className="chart-line">
                  <LineChart
                    height={150}
                    mode="area"
                    values={[
                      {
                        name: getNameByProps(propNameDataDaily),
                        data: dataDaily.values[modeDataDaily],
                      },
                    ]}

                    labels={dataDaily.labels}
                  />
                </Column>
              ) : (
                <DataEmpty />
              )}
              {/* <Column className="result-day">
                <h2>Resultados/Dia</h2>

                <label>Média:</label>
                <span className="primary bigger">1205</span>

                <label>Maior Dia:</label>
                <span className="primary bigger">12/05</span>

                <label>Menor Dia:</label>
                <span className="primary bigger">12/05</span>


              </Column> */}
            </Row>
            {/* <Row className="subtitle-line-chart">
              <div className="line primary " />
              <label>Cliques</label>
            </Row> */}
          </div>


          <div className="box-empty" />


        </div>


      </div>

      {/* MODAL PRVIEW IMAGEM CAMPANHA */}

      {/* <ModalPreview>
                <img src={general.imageCampaign['test-preview.jpeg']} alt=" Imagem" />
            </ModalPreview> */}
    </>
  );
}

MediaImagem.propTypes = {
  media: PropTypes.shape().isRequired,
};


export default withTheme(MediaImagem);
