import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  ContainerStyled,
  TableStyled,
  RowStyled,
  InputStyled,
  FormGroupStyled,
  SelectStyled,
  TextareaStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)`
  //h1{
  //  margin: 0 0 30px 0;
  //}

  .campaign {
    border: solid 2px ${props => props.theme.colors.light};
    border-radius: 2px;
    padding: 20px;
    width: 100%;
  }

  .campaign-desc {
    h1 {
      margin-bottom: 5px;
    }
    span {
      font-size: 18px;
      font-weight: 200;
      color: ${props => props.theme.colors.regular};
    }
  }
`;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const Input = styled(InputStyled)``;

export const LinkStyled = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.colors.primary};
  font-size: 18px;
`;

export const FormGroup = styled(FormGroupStyled)``;

export const Table = styled(TableStyled)`
  thead {
    tr {
      background-color: transparent;
      color: ${props => props.theme.colors.regular};
    }
  }
  tbody {
    tr {
      td {
        padding-top: 0;
      }
    }
  }
`;

export const Select = styled(SelectStyled)``;

export const Img = styled.img`
  max-width: 250px;
`;

export const BaseLabel = styled.div`
  > label {
    display: block;
  }
`;

export const BaseTitle = styled.div`
  display: flex;
`;

export const Textarea = styled(TextareaStyled)``;

export const TitleItemResume = styled.p`
  font-size: 20px;
  font-weight: bold;
`;
