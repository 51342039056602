import styled from 'styled-components';
import {
  ContainerStyled,
  RowStyled,
} from '~/Components/Styled';


export const Container = styled(ContainerStyled)``;

export const Row = styled(RowStyled)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
  .header-form-box {
    flex-wrap: wrap;
    width: 100%;
  }
  .header-action-buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 1148px) {
      flex: 1;
    }
  }
  .header-button-box {
    display: flex;
    > button {
      align-self: flex-end;
    }
  }
`;

export const FilesBox = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.colors.primary};
  border-radius: 5px;
  width: 100%;
  margin-bottom: 20px;

  .files-box-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.colors.primary};
    background-color: ${props => props.theme.colors.primaryHex}30;
    height: 40px;
    padding: 10px;
    > h2 {
      font-weight: bold;
      color: ${props => props.theme.colors.primary};
      font-size: 16px;
    }

    .files-box-subtitle {
      span {
        font-size: 18px;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
      }
    }

    button {
      height: 30px;
      font-size: 15px;
    }
  }
  .files-box-content {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    overflow-x: auto;
    width: 100%;
    padding: 20px 0;

    .files-box-content-files {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      width: fit-content;
      padding-left: 20px;
    }
  }
`;
