/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Creators as AudienceActions } from '~/redux/ducks/audience';

import {
  ProfileResult,
  SelectZone,
} from './styles';

const Footer = ({
  zones,
  zoneSelected,
  audienceSetZoneSelected,
  onChange,
  totalEstimatedAudienceByZone,
  totalEstimatedAudience,
}) => {
  const listZones = [
    { label: 'TOTAL', value: null },
    ...zones.map(item => ({
      ...item,
      label: item.description,
      value: item.uuid,
    })),
  ];
  const zoneValue = listZones.find(item => item.value === zoneSelected);
  return (
    <ProfileResult>
      <div className="item-result-zone">
        <h3>AUDIÊNCIA ESTIMADA</h3>
        <SelectZone
          placeholder="Selecione o canal"
          value={zoneValue || null}
          onChange={(zone) => {
            audienceSetZoneSelected(zone.value);
            onChange(zone);
          }}
          menuPlacement="top"
          options={listZones}
        />
        <h3>:</h3>
        {!!zoneSelected && (<h4>{totalEstimatedAudienceByZone}</h4>)}
        {!zoneSelected && (<h4>{totalEstimatedAudience}</h4>)}
      </div>
      {!!zoneSelected && (
        <div className="item-result-total">
          <h3>AUDIÊNCIA ESTIMADA TOTAL:</h3>
          <h4>{totalEstimatedAudience}</h4>
        </div>
      )}
    </ProfileResult>
  );
};

Footer.defaultProps = {};

Footer.propTypes = {
  zoneSelected: PropTypes.string.isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  audienceSetZoneSelected: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  totalEstimatedAudienceByZone: PropTypes.string.isRequired,
  totalEstimatedAudience: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
  zones: state.audience.zones,
  zoneSelected: state.audience.zoneSelected,
  totalEstimatedAudienceByZone: state.audience.totalEstimatedAudienceByZone,
  totalEstimatedAudience: state.audience.totalEstimatedAudience,
});

const mapDispatchToProps = dispatch => bindActionCreators(
  {
    audienceSetZoneSelected: AudienceActions.audienceSetZoneSelected,
  },
  dispatch,
);

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Footer);
